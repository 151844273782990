import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  template: '<app-feature-form [featureId]= "featureId" [isEditMode]="true"></app-feature-form>'
})

export class EditFeatureComponent implements OnInit {

    featureId: number;
    constructor(private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.route.params
        .pipe(take(1))
        .subscribe((params) => {
            this.featureId = params['id'];
        });
    }
}
