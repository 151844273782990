import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AdminEntityLevel,
  EditEntityLevel,
  EntityLevel,
  REGEX_PATTERNS,
  REGEX_VALIDATION_MESSAGE
} from 'src/app/core/constants';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { ReportsService } from 'src/app/services/report.services';
import { IReport, ReportCreateUpdateReq, ReportResolved } from 'src/app/shared/report';
import { ISuggestions } from 'src/app/shared/suggestion';
import { Location } from '@angular/common';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
})
export class ReportFormComponent implements OnInit, OnDestroy {
  @Input() isEditMode: boolean;
  @Input() entities: (
    | string
    | AdminEntityLevel
    | EditEntityLevel
    | EntityLevel
  )[];

  @Output() onErrorEvent = new EventEmitter<string>();

  isSubmitted: boolean = false;

  isAdmin: boolean = false;

  reportDetail: IReport;
  reportForm: FormGroup;

  organizationSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  formSuggestion: ISuggestions;
  selectedOrgId: number = null;
  selectedEntityNameValId: number = null;

  isShowEntityName: boolean = false;
  regexValidationMessage: any;
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private projectService: ProjectsService,
    private reportService: ReportsService,
    private permissionService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.initForm();
    if (this.isEditMode) {
      this.initializeEditConfigurations();
    } else {
      this.intializeCreateConfigurations();
    }

    this.getOrganizationSuggestions();
  }

  get organization() {
		return this.reportForm.get('organization');
	}

  get entitiyLevel() {
		return this.reportForm.get('entityLevel');
	}

  get entityName() {
		return this.reportForm.get('entityName');
	}

  get reportName(){
    return this.reportForm.get('reportName');
  }

  get reportDescription(){
    return this.reportForm.get('reportDescription');
  }

  get reportURL(){
    return this.reportForm.get('reportURL');
  }

  get isVisible(){
    return this.reportForm.get('isVisible');
  }


  intializeCreateConfigurations(): void {
    const entityLevelCtrl = this.reportForm.controls.entityLevel;
    entityLevelCtrl.enable();
    const orgTitle = this.permissionService.userData.organizationTitle;
    this.organization.setValue(orgTitle);
    this.selectedOrgId = this.permissionService.userData.organization;
    entityLevelCtrl.updateValueAndValidity();
  }

  initializeEditConfigurations(): void {
    this.getConfigurationById();
    this.patchForm();

    const orgTitle = this.permissionService.userData.organizationTitle;
    this.organization.setValue(orgTitle);
    this.selectedOrgId = this.permissionService.userData.organization;
  }

  getConfigurationById(): void {
    const resolvedData: ReportResolved =
      this.route.snapshot.data['resolvedData'];
    this.setErrorMessage(resolvedData.error);
    this.reportDetail = resolvedData.report;
  }

  patchForm() {
    this.reportForm.patchValue({
      organization: this.reportDetail.organizationTitle,
      entityName: this.reportDetail.entityName,
      entityLevel: this.reportDetail.level,
      reportName: this.reportDetail.reportName,
      reportDescription: this.reportDetail.reportDescription,
      isVisible: this.reportDetail.visibility,
      reportURL: this.reportDetail.reportURL
    });
  }

  initForm(): void {

  //   this.clientForm = this.fb.group({
  //     name: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.text), Validators.maxLength(this._maxLength.Client.Name), NoWhitespaceValidator.validateWhiteSpaces]],
  //     description: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.text), Validators.maxLength(this._maxLength.Client.Description), NoWhitespaceValidator.validateWhiteSpaces]],
  //     addressLine1: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.text)]],
  //     addressLine2: ['',[Validators.pattern(REGEX_PATTERNS.text)]],
  //     city: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.Name)]],
  //     state: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.Name)]],
  //     zipCode: ['',[Validators.pattern(REGEX_PATTERNS.zipCode)]],
  //     country: ['',[Validators.pattern(REGEX_PATTERNS.Name)]],
  //     contactName:['',[Validators.pattern(REGEX_PATTERNS.Name), Validators.maxLength(this._maxLength.Client.ContactName)]],
  //     contactNumber:['',[Validators.pattern(REGEX_PATTERNS.Mobile)]],
  //     organization: [(this.organizationDetails.organizationTitle || ''), [Validators.required]]
  // });

    this.reportForm = this.fb.group({
      organization: [{ value: '', disabled: this.isEditMode }, [Validators.required, Validators.pattern(REGEX_PATTERNS.organization_name_validator)]],
      entityLevel: [{ value: '', disabled: true }, [Validators.required]],
      entityName: [{ value: '', disabled: this.isEditMode}],
      reportName: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator)]],
      reportDescription: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator)]],
      reportURL:['',[Validators.required, Validators.pattern(REGEX_PATTERNS.url)]],
      isVisible: false
    });
  }

  isLevelOrgOrSystem(): boolean {
    const isOrgOrSystem =
      this.reportDetail.level.toLowerCase() == 'system' ||
      this.reportDetail.level.toLowerCase() == 'organization';
    return isOrgOrSystem;
  }

  getOrganizationSuggestions() {
    this.projectService.getSuggestions('organizations', '').subscribe(
      (suggestions) => {
        this.organizationSuggestion = suggestions;
      },
      (error) => this.setErrorMessage(error)
    );
  }

  cancelBtnHandler(): void {
    this.navigationHandler();
  }

  submitBtnHandler(): void {
    this.isSubmitted = true;
    if (this.reportForm.valid) {
      const rq = this.populateEditConfigParams();

      if(this.isEditMode){
        this.updateReport(rq);
      } else {
        this.createReport(rq);
      }
    }
  }

  fetchOrgId(orgVal: string): number {
    const suggest = this.organizationSuggestion as any;
    const org = suggest.filter(org => org.title === orgVal);
    return org.length !== 0 ? org[0].id: -1;
  }

  populateEditConfigParams(): ReportCreateUpdateReq {
    let req = new ReportCreateUpdateReq();

    if (this.isEditMode) {
      req.id = +this.route.snapshot.params.id;
    }

    // req.organizationId = this.organization.value ? this.fetchOrgId(this.organization.value): null;
    req.reportName = this.reportName.value;
    req.reportDescription = this.reportDescription.value;
    req.reportURL = this.reportURL.value;
    req.visibility = this.isVisible.value;

    if(!this.isEditMode){
    if(this.entitiyLevel.value.toLowerCase() === 'organization'){
      req.organizationId = this.selectedOrgId ? this.selectedOrgId : this.fetchOrgId(this.organization.value);
    } else if (this.entitiyLevel.value.toLowerCase() === 'product') {
      req.productId = this.selectedEntityNameValId ? this.selectedEntityNameValId: +this.reportDetail.productId;
    } else if (this.entitiyLevel.value.toLowerCase() === 'form') {
      req.formId = this.selectedEntityNameValId ? this.selectedEntityNameValId: +this.reportDetail.formId;
    }
  }
  else {

    if(this.entitiyLevel.value.toLowerCase() === 'organization'){
      req.organizationId = this.reportDetail.organizationId;
    } else if (this.entitiyLevel.value.toLowerCase() === 'product') {
      req.productId =  this.reportDetail.productId;
    } else if (this.entitiyLevel.value.toLowerCase() === 'form') {
      req.formId =  this.reportDetail.formId;
    }

  }
    return req;
  }

  updateReport(request): void {
    this.reportService.updateReport(request).subscribe(
      (updatedConfig) => this.onSaveComplete(),
      (error) => {
        console.log('error', error);
        this.setErrorMessage(error);
      });
  }

  createReport(request): void {
    this.reportService.createReport(request).subscribe(
      (createdConfig) => this.onCreateComplete(request.reportName),
      (error) => this.setErrorMessage(error)
    );
  }

  onSaveComplete(): void {
    this.reportService.isReportSaved = `The Report '${this.reportDetail.reportName}' has been updated.`;
    this.navigationHandler();
  }

  onCreateComplete(reportName: string): void {
    this.reportService.isReportCreated = `The Report '${reportName}' has been created.`;
    this.navigationHandler();
  }

  onOrganizationSelect(event: any) {
    this.selectedOrgId = +event.item.id;
  }

  onEntityNameSelect(event: any){
    this.selectedEntityNameValId = +event.item.id;
  }

  getProductSuggestions() {
    this.projectService.getSuggestions('products', this.selectedOrgId, "orgId").subscribe(
      suggestions => this.productSuggestion = suggestions,
      error => this.setErrorMessage(error));
  }

  getFormSuggestions() {
    this.projectService.getSuggestions("forms", this.selectedOrgId, "orgId").subscribe(
      suggestions => this.formSuggestion = suggestions,
      error => this.setErrorMessage(error));
  }

  onEntityLevelChange() {
    let level: string = this.entitiyLevel.value;

    this.isShowEntityName  = (this.entitiyLevel.value == "Product" || this.entitiyLevel.value == "Form") && this.selectedOrgId > 0;

    if(level.toLowerCase() == 'product'){
      this.getProductSuggestions()
    }else{
      this.getFormSuggestions()
    }

  }

  setErrorMessage(err: string): void {
    if(err){
      this.onErrorEvent.emit(err);
    }
  }

  navigationHandler(): void {
    if (this.isEditMode) {
      this.router.navigate(['../../view', this.route.snapshot.params.id], {
        relativeTo: this.route,
      });
    } else {
      // this.router.navigate(['self-service/report']);
      this.location.back();
    }
  }

  ngOnDestroy(): void {}
}
