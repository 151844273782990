import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReservedKey } from '../shared/reservedkey';

@Injectable({
  providedIn: 'root',
})
export class ReservedKeyService {
  private keysAPIURL = 'reservedkeys';

  constructor(private http: HttpClient) {}

  callReservedKeyListData() {
    let url = this.keysAPIURL;
    return this.http.get<IReservedKey>(url);
    //return this.http.get(url);
  }
}
