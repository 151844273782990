import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { PageTemplateService } from '../../../services/page-template.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from 'src/app/loader/loader.component';
import { IPager } from '../../../shared/pagination';
import { IQuery } from '../../../shared/query';
@Component({
  selector: 'app-commitmrnts-by-products',
  templateUrl: './commitmrnts-by-products.component.html',
  styleUrls: ['./commitmrnts-by-products.component.css']
})
export class CommitmrntsByProductsComponent implements OnInit {
  commitmentProducts: any;
  private overlayRef: OverlayRef = undefined;
  @Input() clientId;
  pagerObject: IPager;
  @Input() pageSize: any = 5;
  @Input() toDate: any;
  @Input() bu: any;
  @Input() fromDate: any;
  @Input() products: any;
  pageProducts: any = [];
  @Input() ngShow: boolean = false;
  ngShows: boolean = false;
  //{ 'apiKey': 'Product', 'apiKeyValue': '' }, { 'apiKey': 'CommitmentTitle', 'apiKeyValue': '' }
  formFields: any = [];
  @Input() applyFilter: boolean;

  constructor(private pageTemplateService: PageTemplateService, private readonly overlay: Overlay) { }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if (changes.clientId && changes.clientId.currentValue) {
      this.clientId = changes.clientId.currentValue;
      this.commitmentProducts = [];
      this.commitmentsbyProduct();
    }
    if (changes.toDate && changes.toDate.currentValue ) {
      this.toDate = changes.toDate.currentValue;
    
    }
    if (changes.fromDate && changes.fromDate.currentValue) {
      this.toDate = changes.fromDate.currentValue;
    
    }
    if (changes.applyFilter) {
      this.commitmentProducts = [];
      this.commitmentsbyProduct();
    }
    if (changes.bu && changes.bu.currentValue || (changes.products && changes.products.currentValue)) {
      if (changes.bu && changes.bu.currentValue)
        this.formFields['bu'] = changes.bu.currentValue;
      else
        this.formFields['bu'] = '';
      if (changes.products && changes.products.currentValue)
        this.formFields['product'] = changes.products.currentValue;
      else {
        this.formFields['product'] = '';
      }

    } else {
      this.formFields['product'] = '';
      this.formFields['bu'] = '';
    }
  }
  ngOnInit(): void {
  }
  commitmentsbyProduct() {
    let queryParam: IQuery = Object.assign({}, {

    });
    if (this.toDate && this.toDate!='') {
      queryParam['StartDate'] = this.fromDate;
      queryParam['EndDate'] = this.toDate;
    }
    let formFields = [];
    for (let k in this.formFields) {

      formFields.push({ 'apiKey': k, 'apiKeyValue': this.formFields[k] })

    }
    queryParam.FormFields = JSON.stringify(formFields);
    this.pageTemplateService.getCommitmentsbyProduct(this.clientId, queryParam).pipe(take(1)).subscribe(
      response => {
        if (response == null)
          response=[];
        this.commitmentProducts = response;
        let totalPages = Math.floor(this.commitmentProducts.length / this.pageSize);
        this.ngShows = true;
        if ((totalPages * this.pageSize < this.commitmentProducts.length)) {
          totalPages = totalPages + 1;
        }
        this.pagerObject = { "totalCount": this.commitmentProducts.length, "pageSize": this.pageSize, "currentPage": 1, "totalPages": totalPages }
        this.pageWiseProduct(1);
      },

      (error) => { }
    );
  }
  changePage(page) {
    this.pageWiseProduct(page)
  }
  pageWiseProduct(pageNumber) {
    this.pageProducts = [];
    let start = (pageNumber - 1) * this.pageSize;
    let end = pageNumber * 5;
    for (let k = start; k < end; k++) {
      if (this.commitmentProducts[k])
      this.pageProducts.push(this.commitmentProducts[k])

    }

  }

}
