import { IPager, IPagination } from './pagination';

export enum DocumentFileListItemType {
  document = 1,
  folder = 2,
  projectComment = 3,
  projectResposeComment = 4,
}

export enum DocumentRelationType {
  form = 1,
  response = 2,
  project = 3,
  implementation = 4,
}

export interface ICounter {
  key: string;
  value: number;
}

export interface IDocument {
  id: number;
  guid: any;
  alias: string;
  title: string;
  originalTitle: string;
  fileSize: number;
  contentType: string;
  extension: string;
  url: string;
  isActive: Boolean;
  createdById: number;
  createdOn: string;
  modifiedById?: number;
  modifiedOn: string;
  organizationId?: number;
  folderName: string;
  metadata: any;
  itemType: number;
  subs: any[];
  fileType: string;
  documentType?: string;
}

export interface IDocumentFolderAssignment {
  id: number;
  title: string;
  isFiservImplementationManager: boolean;
  isFiservPortfolioManager: boolean;
  isFiservDivisionAdmin: boolean;
  isClientImplementationManager: boolean;
  isChannelPartner: boolean;
  itemType: number;
}

export interface IDocumentFileListItem extends IDocument {
  fileSizeKb: string;
  modifiedDate: string;
  isSub: boolean;
  hasSub: boolean;
  isGroup: boolean;
  isDragable: boolean;
  imageIcon: string;
}

export interface IDashboardDocument {
  pagination?: IPagination[];
  documents: IDocument[];
}

export interface IDocumentPager extends IPager{
  totalDocumentCount: number;
}
