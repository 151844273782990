 <div class="box-container product-details">
  <div class="product-container">
    <h2>
      Commitments by Products

    </h2>
    <!--<div class="total-count"><div class="count-badge" *ngIf="commitmentProducts">{{commitmentProducts.length}}</div></div>-->
  </div>
  <div role="status" *ngIf="!ngShows" aria-label="Please wait, do not refresh the page" class="ccdrms-loader" >  <div class="box-wrapper" ></div></div>


  <div class="box-wrapper" *ngIf="ngShows">


    <ul class="product-list">



      <li *ngFor="let k of pageProducts">
        <h3>{{k.productName}} </h3>
        <p style="
" title="{{k.productDescription}}">{{k.productDescription}}</p>

        <div class="two-col">
          <div class="name">
            Commitments <span class="badge">{{k.totalCommitments}}</span>


          </div>


        </div>
      </li>



    </ul>

    <app-pagination [template] ="'2'"*ngIf="pagerObject && pagerObject.totalPages > 1 " [pagerObject]="pagerObject"
                    (pagingChanged)="changePage($event)"></app-pagination>




  </div>
</div>
