import { Injectable, Output, EventEmitter } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MasterService } from '../../services/master.service';
import { SurveyService } from '../../services/survey.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyResolver implements Resolve<any> {
  respondentName = new EventEmitter<string>();
  constructor(private surveyService: SurveyService,
    private masterService: MasterService,
    private router: Router) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> {
    const surveyId = this.masterService.getGuid();
    return this.surveyService.getSurveys(surveyId).pipe(
      map((data) => {
        if (data.respondents?.length > 1) {
          this.respondentName.emit(" Multi Respondent");
        }
        else {
          this.respondentName.emit(" " +data.recipient.firstName + " " + data.recipient.lastName);
          data.response.reservedKeys['drmsReservedRespondentName'] = data.recipient.firstName + " " + data.recipient.lastName
        }

        this.surveyService.setIdForSurvey(surveyId);
        this.surveyService.setResponseForSurvey(data.response);
        return data;
      }), catchError(err => this.router.navigate(["survey", surveyId, "expired"])));
  }

}
