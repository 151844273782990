import { Injectable }   from '@angular/core';
import { ISubHeader } from '../Components/subheader/subHeader';
import { Subject } from 'rxjs/internal/Subject';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubHeaderService {

  private subNav = new BehaviorSubject("Manage Forms");
  currentStatusBar = this.subNav.asObservable();

  private sideBarNav = new BehaviorSubject(true);
  currentSideBarNav = this.sideBarNav.asObservable();


  constructor() { }

  updateSubNavigation(subHeaderName:string)
  {
    this.subNav.next(subHeaderName);
  }

  
  updateSideBarNavigation(showSideBarNav:boolean)
  {
    this.sideBarNav.next(showSideBarNav);
  }
  

}
