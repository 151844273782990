export const environment = {
  production: true,
  env: 'qa',
  pageSize: 10,
  pdfSkipFormId: "0",
  clientDashboardUserManualId: 333,
  clientDashboardFAQId: 334,
  clientCommitmentPageTemplateId: 12,
  apibaseurl: "https://drms-qa.implementations.fiservapps.com/api/",
  drmsPowerBIURL: 'https://app.powerbi.com/reportEmbed?reportId=691dbe76-f72b-42d9-b042-6de0f9964d52&autoAuth=true&ctid=11873a1f-4c8d-450d-8dfb-e37a2e2557f8&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZC1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
  appInsights: {
    instrumentationKey: "65c50c4b-9e82-4a46-97b9-a1d5413fec0d"
  },
  msalbc2Config: {
    signUpSignInName: 'B2C_1A_prod_drms_signin',
    signUpSignInAuthority: 'https://fiservimpl360.b2clogin.com/fiservimpl360.onmicrosoft.com/B2C_1A_prod_drms_signin',
    b2cScopes: ['https://fiservimpl360.onmicrosoft.com/f61a0487-ee15-4efc-88da-7d45b24c2ebb/api.full.access'],
    webApiEndpoint: 'https://drms-qa.implementations.fiservapps.com/api/',
    clientId: '02d70e03-27ad-47b3-ae70-d68b26d904b6',
    redirectUri: '/auth',
    postLogoutRedirectUri: 'https://drms-qa.implementations.fiservapps.com/logout.html',
    resetPwdAuthority: 'https://fiservimpl360.b2clogin.com/fiservimpl360.onmicrosoft.com/B2C_1A_prod_drms_signin',
    authorityDomain: 'fiservimpl360.b2clogin.com'
  },
  msalAuthSettings: {
    clientId: '02d70e03-27ad-47b3-ae70-d68b26d904b6',
    authority:
      'https://fiservimpl360.b2clogin.com/fiservimpl360.onmicrosoft.com/B2C_1A_prod_drms_signin',
    validateAuthority: true,
    redirectUri: "https://drms-qa.implementations.fiservapps.com/",
    postLogoutRedirectUri: "https://drms-qa.implementations.fiservapps.com/logout.html",
    navigateToLoginRequestUrl: true
  },
  scopes: 'https://fiservimpl360.onmicrosoft.com/8666402c-c6d0-491b-9856-94e8e3e54966/api.full.acess'
};
