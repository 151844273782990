import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DataTableComponent } from 'src/app/SharedModule/Components/data-table/data-table.component';
import { ISuggestions } from 'src/app/shared/suggestion';
import { ProjectsService } from 'src/app/services/projects.service';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { IPager } from 'src/app/shared/pagination';
import { environment } from 'src/environments/environment';
import { FilterPipe } from 'src/app/SharedModule/Pipes/filter.pipe';
import { FormControl } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'select-forms',
  templateUrl: './select-forms.component.html',
  styleUrls: ['./select-forms.component.css']
})
export class SelectFormsComponent implements OnInit, OnChanges {
  @Input() formList: any[] = [];
  @Input() productSection;
  @Input() productTitle: string;
  searchString: string;
  @Input() allRowsSelection: DataTableComponent
  displayColumns = ['Form Name', 'Description', 'Product Name', 'Due Date'];
  keys = ['title', 'description', 'productTitle', 'dueDate'];
  @Input() formSets;
  @Input() currentIndex: number;
  @Output() onFormDataLoaded = new EventEmitter<any>(); 
  productSuggestion: ISuggestions
  isError: boolean;
  errorMsg: string = "";
  product: FormControl;
  productId: any;
  formType: string = 'Data Gathering';
  sortingObject;
  disable: boolean = false;
  regexValidationMessage: any;
  filteredFormList: any[] = [];
  formPageList: any[] = [];
  pagerObject: IPager;
  private _productCleared: boolean;

  constructor(private _projectService: ProjectsService,
    private filterPipe: FilterPipe) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.initFormPageList();
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.product = new FormControl('');
    this.product.valueChanges.subscribe((value) => {
      if (value === "" && this.productSection && !this._productCleared) {
        this.onProductSearching();
      }
      this._productCleared = false;
    });

    

    if (this.productTitle) {
      this.product.setValue(this.productTitle);
    }

    if (this.productSection){
      this.onProductSearching();
    }
  }

  onOptionsSelected(): void {
    this.formSets[this.currentIndex].type = this.formType;
  }

  ngOnInit(): void {
    this.errorMsg = "";
    this.isError = false;
  }

  private clearFilters() {
    this._productCleared = true;
    this.searchString = '';
    if (this.productTitle) {
      this.product.setValue(this.productTitle);
    } else {
      this.product.setValue('');
    }
  }

  cancelForms() {
    this.clearFilters();
    this.formSets[this.currentIndex].assignedFormsList = [];
    this.formSets[this.currentIndex].disable = false;

    this.formSets[this.currentIndex].formType = this.formType ="Data Gathering";
    this.clearFormSelections();
  }

  private clearFormSelections() {
    this.formList.forEach(form => {
      if (form.selected === true){
        form.selected = false;
      }
    });

    this.filteredFormList.forEach(form => {
      if (form.selected === true){
        form.selected = false;
      }
    });
  }

  isSearchNameValid(filterValue) {
    return !filterValue || (filterValue && REGEX_PATTERNS.name_title_validator.test(filterValue));
  }

  crossModal() {
    this.errorMsg = '';
    this.clearFilters();
  }

  checkDisable(currentIndex) {
    return this.formSets && this.formSets[currentIndex] && this.formSets[currentIndex].disable == true
  }

  assignSelectedForms() {
    if (this.formSets[this.currentIndex].assignedFormsList.length == 0) {
      this.errorMsg='please select a form.';
      return;
    }
    this.errorMsg='';
    this.formSets[this.currentIndex].showData = true;
    $('#selectForms').modal('hide');

    this.clearFilters();
    this.formSets[this.currentIndex].disable = true;
    this.formSets[this.currentIndex].formType = this.formType;
    if (this.productId == undefined) {
      this.productId = this.formSets[this.currentIndex].assignedFormsList[0].productId; 
    }
    this.onFormDataLoaded.emit(this.productId);
  }

  applySorting(event) {
    this.sortingObject = event;
    let isAsc = this.sortingObject.isAsc;
    let direction = isAsc ? 1 : -1;
    const propertyName = this.sortingObject.key;
    this.formList = Object.assign([], this.formList).sort(function (a, b) {
      if (a[propertyName] < b[propertyName]) {
        return -1 * direction;
      }
      
      if (a[propertyName] > b[propertyName]) {
        return 1 * direction;
      }
      
      return 0;
    });
  }

  //when search input gets blank after applying filter.
  onFormNameSearching(searchValue: string): void {
    this.onFormListChanging(true);
    if (searchValue === "") {
      this.allRowsSelection.allSelected = false;
    }
  }

  onFormTypeChanged() {
    this.formSets[this.currentIndex].formType = this.formType;
    this.clearFilters();
    this.onFormListChanging(true);
    this.formSets[this.currentIndex].assignedFormsList = [];
    this.clearFormSelections();
  }

  onProductSearching() {
    this.onFormListChanging(true);
    this.clearFormSelections();
    this.formSets[this.currentIndex].assignedFormsList = [];
    this.getProductSuggestions();
  }

  getProductSuggestions(){
    let params = [];
    if (this.product.value && this.product.value.length > 2) {
      params.push({paramName: "title", value: this.product});
    }

    this._projectService.getProductSuggestions("products", params, 100).subscribe(
      data=> {
        this.productSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  initFormPageList(){
    const pageSize = environment.pageSize;
    const total = this.formList.length;
    this.pagerObject = {
      currentPage: 1,
      totalCount: this.formList.length,
      totalPages: Math.ceil(total / pageSize),
      pageSize: pageSize
    };
    this.filteredFormList = [...this.formList];
    this.formPageList = this.formList.slice(0, pageSize);
  }

  changePage(page) {
    this.pagerObject.currentPage = page;
    this.onFormListChanging();
  }

  onFormListChanging(isFiltering: boolean = false){
    if (isFiltering) {
      this.pagerObject.currentPage = 1;
    }

    this.onFormFiltering();
    const filteredFormList = [...this.filteredFormList];
    this.pagerObject.totalPages = Math.ceil(
      filteredFormList.length / environment.pageSize
    );
    this.pagerObject = {...this.pagerObject};

    const startIdx = this.pagerObject.pageSize * (this.pagerObject.currentPage - 1);
    const endIdx = this.pagerObject.pageSize * this.pagerObject.currentPage;
    this.formPageList = filteredFormList.slice(startIdx, endIdx);
  }

  onFormFiltering() {
    let filteredFormList = [...this.formList];
    if (this.searchString) {
      filteredFormList = this.filterPipe.transform(filteredFormList, 'title', this.searchString);
    }

    if (this.product) {
      filteredFormList = this.filterPipe.transform(filteredFormList, 'productTitle', this.product.value);
    }

    if (this.formType) {
      filteredFormList = this.filterPipe.transform(filteredFormList, 'formType', this.formType);
    }

    this.filteredFormList = [...filteredFormList];
  }
}
