import { Component,Input } from '@angular/core';

import { FormGroup }        from '@angular/forms';
import{FieldBase} from './../fieldClass/field-base';
@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.css']
})
export class TextboxComponent  {
  @Input() field: FieldBase<string>;
  @Input() forms: FormGroup;
  @Input() error: string;
  get isValid() { return this.forms.controls[this.field.key].valid; }
  

}
