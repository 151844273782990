import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IQuery } from '../shared/query';
import { CustomHttpParamEncoder } from '../shared/custom-encoder';
import { InterceptorSkipHeader } from '../core/constants';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService {
  private userNotificationAPI = 'userNotifications';
  constructor(private http: HttpClient) { }

  getUserNotifications(queryParams: any, isRefresh = false, showLoading = true) : Observable<any> {
    let params = isRefresh
      ? new HttpParams({ encoder: new CustomHttpParamEncoder()})
      : new HttpParams();
      
      let options = {
        'Content-Type': 'application/json'
      }
      if (!showLoading) {
        options[InterceptorSkipHeader] = '';
      }
     
      options['refresh'] = 'true';
  
      let httpOptions = {
        headers: new HttpHeaders(options)
      }


    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    return this.http.get(this.userNotificationAPI, {
        headers: httpOptions.headers,
        params: queryParams,
        observe: 'response',
      });
  }

  updateNotificationState(id: string, updateState: any): Observable<any> {
    return this.http.put(this.userNotificationAPI + `/${id}/changeState`, { id: id, state: updateState });
  }

  markAllAsRead(id: string): Observable<any>{
    return this.http.put(this.userNotificationAPI + `/${id}/markAllAsRead`, {});
  }
}
