import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { WorkflowService } from 'src/app/services/workflow.service';
import { MasterService } from 'src/app/services/master.service';
import { IWorkflowContext } from 'src/app/shared/workflow';
import {Location} from '@angular/common';

@Component({
  selector: 'view-workflow-context',
  templateUrl: './view-workflow-context.component.html'
})
export class ViewWorkflowContextComponent implements OnInit {
  workflowContextId: number;
  workflowData: IWorkflowContext;

  constructor(private route: ActivatedRoute,
    private workflowService: WorkflowService,
    private masterService: MasterService,
    private location: Location) { }

  ngOnInit(): void { 
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.workflowContextId = params['id'];
        if (this.workflowContextId > -1) {
          this.getWorkflowData();
        }
      });
  }

  getWorkflowData() {
    this.workflowService.getWorkflowContextById(this.workflowContextId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.workflowData = data;
        }
      );
  }

  goBack(){
    this.masterService.isCancelledClicked(true);
    this.location.back()
  }
}
