<!-- Add class of "back-action-container" when having back button in place like below -->
<div class="heading-container fdl-2" >
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons">
          arrow_back
        </span>
        {{returnPage}}
      </a>
    </div>
    <h1 *ngIf="!isEditMode">Create Self Service Feature</h1>
    <h1 *ngIf="isEditMode">{{featureForm.get('name').value}}</h1>
  </div>
  
  </div>
    <div class="form-container separate-form-container">
      <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
      </div>
    
      <p *ngIf="!isEditMode">Please complete below required information to create a new Self Service Feature.</p>
    
      <div class="pt-2"> 
        <form [formGroup]="featureForm" autocomplete="off">
          <!-- <div class="form-row  mt-3" *ngIf="(isAdmin )">
            <div class="col-md-3 mb-3 typeahead-wrapper" [ngClass]="{'has-error': (!featureForm.get('organization').valid && (featureForm.get('organization').touched  || isSubmitted)) }">
              <label for="organization" class="asterisk">Organization:</label>
              <fdl-type-ahead formControlName="organization" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
              <div class="has-error">                
                <span *ngIf="!featureForm.get('organization').valid && (featureForm.get('organization').touched || isSubmitted)">
                  <i class="material-icons">warning</i> Please select a valid organization name.
                </span>
              </div>
            </div>
          </div> -->
          <div class="form-row">
            <div class="col-md-6 mb-3">
              <label for="name" class="asterisk">Feature Name:</label>
              <input type="text" class="form-control" id="name" formControlName="name">
              <div class="has-error">
                <span *ngIf="!featureForm.get('name').valid && (featureForm.get('name').errors?.required || featureForm.get('name').errors?.validateWhiteSpaces) && (featureForm.get('name').touched || isSubmitted)">
                  <i class="material-icons">warning</i> Please enter a valid feature name.
                </span>
                <span *ngIf="!featureForm.get('name').valid && featureForm.get('name').errors?.pattern && (featureForm.get('name').touched || isSubmitted)">
                  <i class="material-icons">warning</i>  {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
                </span>
                <span *ngIf="!featureForm.get('name').valid && featureForm.get('name').errors?.maxlength && (featureForm.get('name').touched || isSubmitted)">
                  <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.HelpCategory.Name}}, please correct it.
                </span>
              </div>
            </div>

            <div class="col-md-12 mb-3">
              <label for="description" class="asterisk">Feature Description:</label>
              <textarea class="form-control" id="description" formControlName="description"></textarea>
              <div class="has-error">
                <span *ngIf="!featureForm.get('description').valid && (featureForm.get('description').errors?.required || featureForm.get('description').errors?.validateWhiteSpaces) && (featureForm.get('description').touched || isSubmitted)">
                  <i class="material-icons">warning</i> Please enter a valid feature description.
                </span>
                <span *ngIf="!featureForm.get('description').valid && featureForm.get('description').errors?.pattern && (featureForm.get('description').touched || isSubmitted)">
                  <i class="material-icons">warning</i>  {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
                </span>
                <span *ngIf="!featureForm.get('description').valid && featureForm.get('description').errors?.maxlength && (featureForm.get('description').touched || isSubmitted)">
                  <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.HelpCategory.Description}}, please correct it.
                </span>
              </div>
            </div>
          </div>
          <div class="fdl-2 button-container">

            <button type="button" class="btn fdl-btn btn-secondary-light " aria-label="Cancel" (click)="goBack()">Cancel</button>
            <div>
              <button type="button" class="btn fdl-btn btn-primary-orange" *ngIf="!isEditMode" (click)="onSubmit()">Submit</button>
              <button type="button" class="btn fdl-btn btn-primary-orange" *ngIf="isEditMode" (click)="update()">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
