import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UserFilterResolver } from "../services/user.filter.resolver.service";
import { ConsoleListComponent } from "./console-list/console-list.component";

const routes = [
    {
        path: "project-workflow/console-list",
        component: ConsoleListComponent,
        resolve: { userFilter: UserFilterResolver },
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProjectWorkflowRoutingModule {}