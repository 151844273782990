<div class="heading-container fdl-2">
    <div class="back-action-container">
      <div class="back-button">
        <a href="javascript:void(0);" (click)="goBack()">
          <span class="material-icons"> arrow_back </span>
          Feature List
        </a>
      </div>
      <h1>{{featureData?.name || 'N/A'}}</h1>
    </div>
</div>
<div class="form-container">
  <!-- <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="closeSuccessMsg()">×</button>
      <span class="iconLabel" aria-label="sucess"></span>
      <i class="material-icons" aria-hidden="true">check_circle</i>
      <p>{{successMsg}}
    </p>
  </div> -->
  <div class="alert alert-success alert-dismissible" *ngIf="deletedFormMsg">
      <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
      <span class="iconLabel" aria-label="sucess"></span>
      <i class="material-icons" aria-hidden="true">check_circle</i>
      <p>{{deletedFormMsg}}
        
      </p>
  </div>
<div class="alert alert-dismissible alert-danger" *ngIf="isError">
  <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
  <i class="material-icons">warning</i>
  <p [innerHtml]="errorMsg"></p>
</div>
  <div class="form-container separate-form-container">
      <div class="alert alert-success alert-dismissible" *ngIf="showUpdationMessage">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="showUpdationMessage=false">×</button>
          <span class="iconLabel" aria-label="sucess"></span>                
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>            Feature details are updated successfully.   </p>
  </div> 
    <form>
      <div class="biller-survey white-box border-0 fdl-2">
        <button type="button" class="btn fdl-btn btn-primary-dark  pull-right" (click)="goToEdit();">Edit</button>
        <div class="">
          <ul class="">
            <li class=" full-width">
              Description:
              <span class="info">
                {{featureData?.description || 'N/A'}}
              </span>
            </li>
          </ul>
        </div>
      </div>

    </form>
    <div></div>
    <div class="data-table-container">
      <div class="headline-container">
          <h2 class="h4">Subfeatures List</h2>
          <!-- <p>Total {{pagerObject.totalCount}} Workflow Configurations</p> -->
      </div>
      <div class="table-responsive">
          <app-data-table 
              *ngIf="subFeatureData" 
              [rows]="subFeatureData"
              [keys]="keys" 
              [displayColumns]="displayColumns"
              (sortingClicked)="applySorting($event)"
              (buttonClicked)="navigate($event)"
              (deleteBtnClicked)="navigate($event)"
              [showDelete]="true"
              [showCheckbox]="false"
              type="Subfeature List"
          ></app-data-table>
        <!-- <app-pagination
              *ngIf="pagerObject && pagerObject.totalPages > 1 && workflowList.workflows && workflowList.workflows.length > 0"
              [pagerObject]="pagerObject"
              (pagingChanged)="changePage($event)"
          ></app-pagination> -->
      </div>
    </div>
    <div class="fdl-2 button-container">
      <div>
        <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()">Cancel</button>
      </div>
    </div>
  </div>
