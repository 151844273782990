import { Component, OnInit, HostListener, OnDestroy, AfterViewInit } from "@angular/core";
import { ISubHeader } from '../../SharedModule/Components/subheader/subHeader';
import { SubHeaderService } from '../../SharedModule/services/subHeader.service'
import { MsalService } from '@azure/msal-angular';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { IUser } from '../../shared/user';
import { allowedPermissions, PERMISSIONS, QuickSearchType, QuickSearchTypeValues, REGEX_VALIDATION_MESSAGE, ROLES, UserNotificationState, UserNotificationStateValues, UserNotificationType } from '../../core/constants';
import { ProjectsDTOService } from '../../Projects/Services/projectsDTO.service';
import { UserFilterService } from './../../services/user.filter.service';
import { ContentService } from "src/app/services/content.service";
import { HelpService } from "src/app/services/help.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { QuickSearchService } from "src/app/services/quick-search.service";
import { BehaviorSubject, EMPTY, Subject, interval, of } from "rxjs";
import { debounceTime, map, switchMap, takeUntil } from "rxjs/operators";
import { REGEX_PATTERNS } from 'src/app/core/constants';
import { UserNotificationService } from "src/app/services/user-notification.service";

@Component({
  selector: 'header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  iSubHeader: ISubHeader;
  loggedInUser;
  userOrg: string;
  isClientUser: boolean = true;
  showProjectLink: boolean = false;
  showAdminLink: boolean = false;
  showResponsesLink: boolean = false;
  showInitiateDGFLink: boolean = false;
  submitRequestForm: boolean = false;
  clientCommitmentDashboard: boolean = false;
  showClientDashboardLink: boolean = false;
  showMenu: boolean = false;
  isCreateProjectAllowed: boolean = false;
  showDashBoardMenu: boolean = false;
  content: any[];
  popularTopic: any;
  searchString: string = '';
  filterForm: FormGroup;
  helpArticleSuggestion: any;
  clientCommitmentQuickLinks: boolean = false;
  dashboardId: number = 0;
  eCRMFeedId: number = 0;
  clarityFeedId: number = 0;
  clientCommitmentId: number = 0;
  isClientExecutiveUserOnly = true;
  isRMonly: boolean=false;
  clientDashboardUserManualId: number = 0;
  clientDashboardFAQId: number = 0;
  quickSearchType = QuickSearchType;
  searchType = this.quickSearchType.PROJECT;
  searchText = '';
  searchSuggestions: any;
  regexError = '';
  permissionQuickSearchError = '';
  searchTypeValues = QuickSearchTypeValues;
  private destroy$ = new Subject<boolean>();
  private quickSearch$ = new Subject<any>();
  quickSearchSub = this.quickSearch$.pipe(
    debounceTime(500),
    switchMap((_ :any) => {
      return this.doSearch();
    })
  ).subscribe();

  hasMultipleOrgs: boolean = false;
  organizationContext: any[] = [];
  userId: any;
  userNofitications: any;
  notificationCounts: number;

  private intervalNotificationSubject = new BehaviorSubject<number>(30000);
  private intervalNotification$ = this.intervalNotificationSubject.pipe(
    switchMap(duration => interval(duration))
  );


  constructor(private _subHeaderService: SubHeaderService,
    private authService: MsalService,
    private permission: PermissionsService,
    private router: Router,
    private userService: UserService,
    private DTOService: ProjectsDTOService,
    private userFilterService: UserFilterService,
    private contentService: ContentService,
    private helpService: HelpService,
    private route: ActivatedRoute,
    private quickSearchService: QuickSearchService,
    private userNotificationService: UserNotificationService) {
      
  }  

  ngOnInit(): void {
    if(environment.env === 'prod') {
      this.dashboardId = 7;
      this.eCRMFeedId = 21;
      this.clarityFeedId = 38;
      this.clientCommitmentId = 8;
    }
    else if(environment.env === 'app') {
      this.dashboardId = 34;
      this.eCRMFeedId = 237;
      this.clarityFeedId = 297;
      this.clientCommitmentId = 299;
    }
    else if(environment.env === 'dev') {
      this.dashboardId = 1;
      this.eCRMFeedId = 17;
      this.clarityFeedId = 15;
      this.clientCommitmentId = 14;
    }
    else if(environment.env === 'qa') {
      this.dashboardId = 1;
      this.eCRMFeedId = 13;
      this.clarityFeedId = 11;
      this.clientCommitmentId = 12;
    }

    this.clientDashboardUserManualId = environment.clientDashboardUserManualId;
    this.clientDashboardFAQId = environment.clientDashboardFAQId;

    this.initFilterForm();
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    this.getCurrentUser();
    this.getRecommendedTopics();
  }

  getCurrentUser() {
    const user = this.userService.getUserListData();
    if (!user) {
      this.userService.callUserListData().subscribe((data) => {
        this.setUpUserAndPermissions(data);
        this.userNotificationProcessing(data.id);
        this.contentService.setContent().then(data => {
          this.content = this.contentService.getcontent();
        });
      });
    }
    else {
      this.setUpUserAndPermissions(user);
      this.userNotificationProcessing(this.userId);
    }
  }

  userNotificationProcessing(userId) {
    this.getUserNotification(userId);
    this.intervalGetUserNotification(userId);
  }
  
  getUserNotification(userId): any {
    this.userNotificationService.getUserNotifications({userId: userId}, true, false).subscribe(res => {
      console.log(res);
      this.userNofitications = res.body.items;
      this.userNofitications.forEach(element => {
        element.messagesForDisplay = element.messages.replace(element.entityName, `<b>${element.entityName}</b>`);
      });
      this.notificationCounts = this.userNofitications
        .filter(item => item.state === UserNotificationState.New.toString() || item.state === UserNotificationState.UnRead.toString()).length;
    }, (err) => {
      console.log(err);
    });
  }

  intervalGetUserNotification(userId) {
    this.intervalNotification$
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => this.getUserNotification(userId));
  }

  @HostListener('document:click', ['$event'])
  public onGlobalLoad(event: any): void {
    if ($("#quickLinkID").hasClass('d-block')) {
      $("#quickLinkID").removeClass('d-block');
      event.stopPropagation();
    }
  }
  
  public quickLinks($event) {
    if ($("#quickLinkID").hasClass('d-none')) {
      $('#quickLinkID').toggleClass('d-block');
      $event.stopPropagation();
    }
  }

  getRecommendedTopics(){
    this.route.params.subscribe(() => {
      this.helpService.recPopularTopic().subscribe(topics => {
        this.helpArticleSuggestion = topics
      })
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      name: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)]),
    });
  }

  filterList() {
    const filterValues = this.filterForm.value;
    if (!this.filterForm.get('name').valid && this.filterForm.get('name').errors?.pattern){
      return;
    }
    if(filterValues['name'] == ''){
      this.getRecommendedTopics()
    }else{
      this.route.params.subscribe(() => {
      this.helpService.recPopularTopic().subscribe(topics => {
        this.helpArticleSuggestion = topics
        this.helpArticleSuggestion = this.helpArticleSuggestion.filter(x => x.title.toLowerCase().includes(filterValues['name'].toLowerCase()))
      })
    });
    }
  }

  getQuickSearchResults() {
    this.validateTextSearch();
    this.checkPermissionForQuickSearch();
    if (this.regexError || this.permissionQuickSearchError) {
      return;
    }

    this.quickSearch$.next();
  }

  onQuickSearchTypeChanged() {
    this.searchText = '';
    this.getQuickSearchResults();
  }

  private doSearch() {
    const queryParam = { pageSize: 5 };
    return this.quickSearchService.search(this.searchText, this.searchType, queryParam)
    .pipe(
      map(res => {
        this.searchSuggestions = res.body.items;
      }),
      takeUntil(this.destroy$));
  }

  validateTextSearch(): void {
    this.regexError = '';
    let regex;
    switch (this.searchType) {
      case this.quickSearchType.ARTICLE:
        regex = new RegExp(REGEX_PATTERNS.name_title_validator);
        if (this.searchText !== '' && !regex.test(this.searchText)) {
          this.regexError = REGEX_VALIDATION_MESSAGE.NAME_TITLE_VALIDATION_MESSAGE;
        }
        break;

      case this.quickSearchType.RESPONSE:
        regex = new RegExp(REGEX_PATTERNS.form_response_survey_name_validator);
        if (this.searchText !== '' && !regex.test(this.searchText)) {
          this.regexError = REGEX_VALIDATION_MESSAGE.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE;
        }
        break;
    
      case this.quickSearchType.PROJECT:
        regex = new RegExp(REGEX_PATTERNS.project_name_validator);
        if (this.searchText !== '' && !regex.test(this.searchText)) {
          this.regexError = REGEX_VALIDATION_MESSAGE.PROJECT_NAME_VALIDATION_MESSAGE;
        }
        break;
    }
  }

  checkPermissionForQuickSearch(): void {
    this.permissionQuickSearchError = '';
    let regex;
    switch (this.searchType) {
      case this.quickSearchType.ARTICLE:
        break;

      case this.quickSearchType.RESPONSE:
        break;
    
      case this.quickSearchType.PROJECT:
        // if (!this.showProjectLink) {
        //   this.permissionQuickSearchError = 'You do not have permission to access to projects';
        // }
        break;
    }
  }

  goToProjectCreate() {
    this.DTOService.setParentType("list");
    this.router.navigate(['/projects/create/projectinfo']);
  }

  updateSubNavHeader() {
    this.iSubHeader = {
      label: "Administration - Manage Forms",
      linkToNav: "/form/list"
    };
    //this._subHeaderService.updateSubNavigation(this.iSubHeader);
    this._subHeaderService.updateSideBarNavigation(true);
  }

  logout() {
    localStorage.removeItem("drmsToken");
    this.userFilterService.clearFilter();
    this.authService.logoutRedirect();
    //this.logoutService.signOut();
  }

  private setUpUserAndPermissions(user: IUser): void {
    this.userService.setUserListData(user);
    this.permission.setUserData(user);
    let usersPermission = [];
    user.permissions.forEach((ele) => {
      let permission: any = allowedPermissions[ele];
      for (let perm in permission) {
        if (permission[perm])
          usersPermission[perm] = permission[perm]
      }
    });
    this.clientCommitmentDashboard = user.isAdmin;
    let hasOtherRolesThenRM = false;
    let isRM = false;
    for (let p in user.roles) {
      let role = user.roles[p].roleTitle.toLowerCase();
      if (
             role.indexOf("executive") != -1 
          || role == 'admin' 
          || role === "cct admin" 
          || role== "restricted admin" 
          || role === 'division leader' 
          || role === 'bu leader' 
          || role === 'delivery owner' 
          || role === 'delivery leader' 
          || role === 'relationship manager'   
          || role === 'sales executive' 
          || role === 'account team'
      ) {
        this.clientCommitmentDashboard = true;
      }
      if ((role.indexOf("executive") == -1)){
        hasOtherRolesThenRM = true;
      } 
      else if (role.indexOf("executive") != -1) {
        isRM = true;
      }
    }
    
    this.userService.setPermissions(usersPermission);
    this.loggedInUser = user.name;
    this.userOrg = user.organizationTitle;
    this.isClientUser = user.type.toLowerCase() == 'client' ? true: false;
    this.isCreateProjectAllowed = this.permission.isCreateProjectAllowed();
    this.showProjectLink = this.permission.canAccessProjectTab();
    this.showAdminLink = this.permission.canAccessAdministrationTab();
    this.showResponsesLink = this.userService.getPermissions()['showresponseslink'] || user.isAdmin;
    this.showInitiateDGFLink = this.permission.isCreateProjectAllowed();
    this.submitRequestForm = user.isAdmin || user.permissions.indexOf(PERMISSIONS.Sales_Intake_Combo) >= 0;
    this.showClientDashboardLink = user.isAdmin || user.isRestrictedAdmin || user.roles.some(r => r.roleTitle === 'Division Leader' || r.roleTitle === 'BU Leader' || r.roleTitle === 'Delivery Owner' || r.roleTitle === 'Delivery Leader' || r.roleTitle === 'Relationship Manager' || r.roleTitle === 'CCT Admin' || r.roleTitle === 'Sales Executive' || r.roleTitle === 'Account Team');
    if(this.isClientUser && user.roles.length === 1 && user.roles[0].roleTitle === ROLES.Client_Executive)
      this.isClientExecutiveUserOnly = true;
    else
      this.isClientExecutiveUserOnly = false;
 
    if (user.isAdmin 
        || user.isRestrictedAdmin 
        || user.organizationTitle === 'GSO' 
        || user.organizationTitle === 'Sales and Enterprise Accounts' 
        || user.roles.some(r => r.roleTitle === 'Division Leader' || r.roleTitle === 'BU Leader' || r.roleTitle === 'Delivery Owner' || r.roleTitle === 'Delivery Leader' || r.roleTitle === 'Relationship Manager' || r.roleTitle === 'CCT Admin' || r.roleTitle === 'Sales Executive' || r.roleTitle === 'Account Team')
      ) 
    {
      this.clientCommitmentQuickLinks = true;
    }
    if (isRM && !hasOtherRolesThenRM) {
      this.router.navigate(['/commitmentdashboard']);
      this.isRMonly = true;
    }

    user.userRoles.forEach(ur => {
      if(ur.roleTypeTitle === 'Cross Org') this.hasMultipleOrgs = true;
      this.organizationContext.push({organizationId: ur.organizationId, organizationTitle: ur.organizationTitle, isCurrent: ur.organizationId === user.organization ? true : false})
    });
    
    this.userId = user.id;

    // For Quick Search
    if (!this.showProjectLink) {
      this.searchTypeValues = this.searchTypeValues.filter(item => item.value !== this.quickSearchType.PROJECT);
      this.searchType = this.searchTypeValues[0].value;
    }
    if (!this.showResponsesLink) {
      this.searchTypeValues = this.searchTypeValues.filter(item => item.value !== this.quickSearchType.RESPONSE);
      this.searchType = this.searchTypeValues[0].value;
    }
    if (this.isClientUser || this.userService.isFormRespondentAndIntakeSubmitterUserOnly()) {
      this.searchTypeValues = this.searchTypeValues.filter(item => item.value !== this.quickSearchType.ARTICLE);
      this.searchType = this.searchTypeValues[0].value;
    }
  }

  private doShowAdminLink() {
    return (this.userService.getPermissions()['showuserlink'] || this.userService.getPermissions()['showformlink']
      || this.userService.getPermissions()['showclientlink']);
  }

  navigateToDashboard(id: number) {
    let url: string = '/dashboard/report/' + id;
    this.router.navigate([url]);
  }

  navigateToPageResponse(id: number) {
    let url2: string = '/self-service/pageTemplateR/' + id + '/referesh';
    this.router.navigate([url2]);
  }

  getSearchItemUrl(row: any) {
    let url;
    switch (row.type) {
      case 'project':
        url = `/projects/view/${row.id}`;
      break;

      case 'response':
        url = `/response/view/responses/${row.id}`;
      break;

      case 'article':
        url = `/help/topic/${row.id}/category`;    
      break;
    
      default:
        url = `/admin/pages/edit/${row.id}`;
        break;
    }

    return url;
  }

  navigateToQuickSearchPage(): any {
    const params = {
      searchType: this.searchType,
      searchText: this.searchText
    };
    this.router.navigate(['/quicksearch'], {queryParams: params});
  }

  navigateToUserNotificationPage(): any {
    this.router.navigate(['/user-notification']);
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  switchUserOrg(orgId: number) {
    this.userService.updateUserOrganization(this.userId,orgId)
    .subscribe(
      () => { location.reload(); }
      , error => {}
    );
  }

  getNotificationStatusCss(notification: any): any {
    if (notification.state === UserNotificationState.New.toString() || notification.state === UserNotificationState.UnRead.toString()) {
      return 'badge-table-status green';
    }
    return 'badge-table-status gray';
  }

  markAllAsRead(): void {
    this.userNotificationService.markAllAsRead(this.userId)
    .pipe(switchMap(_ => {
      this.getUserNotification(this.userId);
      return EMPTY;
    }),takeUntil(this.destroy$))
    .subscribe(_ => {

    });
  }

  viewUserNotification(item: any): void {
    if (item.state === UserNotificationState.New.toString() || item.state === UserNotificationState.UnRead.toString() ) {
      this.userNotificationService.updateNotificationState(item.id, UserNotificationState.Read.toString())
        .pipe(switchMap(_ => {
          this.getUserNotification(this.userId);
          return EMPTY;
        }),takeUntil(this.destroy$))
        .subscribe();
    }

      
    if (item.type === UserNotificationType.Response) {
      this.router.navigate(['/response/view/responses/'+ item.entityId]);
    } else if (item.type === UserNotificationType.Project) {
      this.router.navigate(['/projects/view/'+ item.entityId]);
    } else if (item.type === UserNotificationType.Article) {
      this.router.navigate(['/projects/view/'+ item.entityId]);
    }
  }
}