import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/internal/operators/take';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { IPageTemplate } from 'src/app/shared/page-template';
import { IQuery } from 'src/app/shared/query';
import { CommitmentsIntakeSearchInput } from './models/commitments-intake-search-input';
import { environment } from 'src/environments/environment';
import { IPager } from 'src/app/shared/pagination';
import { UserService } from 'src/app/services/user.service';
import { ClientCommitmentDTO } from './models/client-commitment-d-t-o';
import { PreferenceService } from 'src/app/services/preference.service';
import { forkJoin } from 'rxjs';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CommitmentsIntakeSearchType } from './enums/commitments-intake-search-type.enum';
@Component({
  selector: 'app-commitments-intake',
  templateUrl: './commitments-intake.component.html',
  styleUrls: ['./commitments-intake.component.css'],
})
export class CommitmentsIntakeComponent implements OnInit {
  isError: boolean = false;
  public errorMsg = '';
  public successMsg = '';
  private pageTemplateId = environment.clientCommitmentPageTemplateId;
  public searchByFormFieldJson: any;
  public pageTemplate: IPageTemplate = {};
  public queryParam: IQuery = {};
  public tabList: string[] = [];
  private selectedTabName = 'All';
  public pageResponseList: ClientCommitmentDTO[] = [];
  public searchFieldData: CommitmentsIntakeSearchInput[] = [];
  public pagerObject: IPager;
  public showSubmitNewCommitmentsIntakeDrawer = false;
  public isEntityLink = false;
  public isDynamicApprover = false;
  selectedOrganizationId = 0;
  canRequest = false;
  listFormFieldJson: any;
  ignoreSortingColumns = [
    'description',
    'formsCount',
    'fileSize',
    'count',
    'formType',
    'level',
    'entityName',
    'status',
    'entityLevel',
    'currentApproverName',
    'editAccessWith',
    'pageTemplateResponseStatusTitle',
    'submittedByName',
  ];
  userData: any;
  isAdmin = false;
  pageTemplateAdmin = false;
  pageTemplateReadAdmin = false;
  customFormFieldListJson: any;
  organizationDetails: any;
  pageResponses: any[] = [];
  showAddColumnOptions: boolean = false;
  displayColumns: any;
  keys: any;
  listName: string = '';
  userId: any;
  preferenceId: any;
  columnPreferences: any;
  prefColumnOptions: any;
  maxColumnCount: any;
  filterExists: boolean = false;
  filtered: boolean = false;
  refreshGrid: boolean = false;
  pageTemplateNameTitle: string;
  pageTemplateNameDescription: string;

  constructor(
    private userService: UserService,
    private permission: PermissionsService,
    private clientCommitmentsService: ClientCommitmentsService,
    private preferenceService: PreferenceService
  ) { }

  ngOnInit(): void {
    this.initQueryParams();
    this.getPageTemplateData();
    this.userData = this.userService.getUserListData();
    this.isAdmin = this.userData.isAdmin;
    this.organizationDetails = Object.assign({}, this.userService.getUserListData());
  }

  private initQueryParams(): void {
    this.queryParam = Object.assign(
      {},
      {
        pageNumber: 1,
        pageSize: environment.pageSize,
      }
    );
  }

  private getPageTemplateData(): void {
    this.clientCommitmentsService
      .getPageTemplateById(this.pageTemplateId)
      .pipe(take(1))
      .subscribe(
        (page) => {
          this.pageTemplate = page.body as IPageTemplate;
          this.pageTemplate.parentPageTemplateList = this.pageTemplate.parentPageTemplateList ?? [];
          this.pageTemplateNameTitle = this.pageTemplate.title;
          this.isDynamicApprover = this.pageTemplate.isDynamicApprover;
          this.isEntityLink = this.pageTemplate.isEntityLink;
          if (this.pageTemplate.formFieldListJson) {
            this.customFormFieldListJson = JSON.parse(
              this.pageTemplate.formFieldListJson
            );
          }

          this.canRequest = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id) || this.permission.userData.isAdmin || (this.userData.isRestrictedAdmin && this.userData.roles.some(r => r.roleId === 10));
          this.pageTemplateAdmin = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id);
          this.pageTemplateReadAdmin = this.pageTemplate.pageTemplateReadAdmins?.some(a => a.userId === this.userData.id);
          if (!this.canRequest) {
            this.selectedOrganizationId = this.organizationDetails?.organization;
          }

          this.callPreferenceGetService();

          if (!this.pageTemplate.searchByFormFieldJson) {
            this.searchByFormFieldJson = JSON.parse('[]');
          } else {
            this.searchByFormFieldJson = JSON.parse(
              this.pageTemplate.searchByFormFieldJson
            );
            this.loadSearchFieldSuggestions();
          }
        },
        (error) => {
          this.errorMsg = error;
        }
      );
  }

  private loadSearchFieldSuggestions(): void {
    if (this.searchByFormFieldJson && this.searchByFormFieldJson.length > 0) {
      this.clientCommitmentsService
        .getFormFieldSuggestionByUser(this.pageTemplateId)
        .subscribe(
          (data) => {
            const sugg: any = data;
            for (let s = 0; s < sugg.length; s++) {
              for (let f = 0; f < this.searchByFormFieldJson.length; f++) {
                if (sugg[s]['apiKey'] === this.searchByFormFieldJson[f]['formfieldapikey']) {
                  this.searchByFormFieldJson[f]['formFieldSuggestion'] = sugg[s].apiKeyValues.filter(r => r.trim() !== '' && r !== 'Invalid date');
                  this.searchByFormFieldJson[f]['data'] = [];
                }
              }
            }

            this.tabList = sugg
              .filter((r) => r.apiKey === 'datasource')
              .map((s) => s.apiKeyValues)[0]
              .filter((r) => r.trim() !== '');

            const index = this.tabList.indexOf('ServiceNow');
            this.tabList[index] = 'Servicenow';
          },
          (error) => {
            // this.isError = true;
            this.errorMsg =
              "Form Field suggestion list can't be loaded. " + error;
          }
        );
    }
  }

  public onSearchClicked(tempSearchFieldData: CommitmentsIntakeSearchInput[]) {
    this.initQueryParams();

    tempSearchFieldData = tempSearchFieldData.filter(
      (r) => r.formFieldApiKey !== 'datasource'
    );
    if (this.selectedTabName !== 'All' &&
      this.selectedTabName !== 'Internal' &&
      this.selectedTabName !== 'Tracked') {
      const temp: CommitmentsIntakeSearchInput = {
        type: 'Data Source',
        title: this.selectedTabName,
        formFieldApiKey: 'datasource',
        typeEnumForSort: CommitmentsIntakeSearchType.DataSource
      };
      tempSearchFieldData.push(temp);
    }

    this.pageResponseList = [];
    // if (tempSearchFieldData.length > 0) {
    const formFieldList: any = [];
    tempSearchFieldData.forEach((item) => {
      const formField = formFieldList.find(
        (f) => f.ApiKey === item.formFieldApiKey
      );
      if (!formField) {
        const formField: any = {
          ApiKey: item.formFieldApiKey,
          ApiKeyValue: [item.title],
        };
        formFieldList.push(formField);
      } else {
        formField.ApiKeyValue.push(item.title);
      }
    });
    if (this.selectedTabName === 'Internal') {
      formFieldList.push({ ApiKey: 'isInternalProject', ApiKeyValue: ['true'] });
    } else if (this.selectedTabName === 'Tracked') {
      formFieldList.push({ ApiKey: 'isTracked', ApiKeyValue: ['true'] });
    }
    // if (formFieldList.length > 0) {
    this.queryParam.formFields = JSON.stringify(formFieldList);
    // }
    // }

    this.searchFieldData = tempSearchFieldData;

    this.search();
  }

  public onTabClicked(tabName: string): void {
    this.selectedTabName = tabName;
    this.onSearchClicked(this.searchFieldData);
  }

  private search(): void {
    this.clientCommitmentsService
      .getPageTemplateResponseList(this.pageTemplateId, this.queryParam)
      .pipe(take(1))
      .subscribe(
        (responses: any) => {
          this.pageResponseList = responses.body.responses as ClientCommitmentDTO[];
          this.pageResponseList.forEach((responseList) => {
            responseList.pageTemplateAdmin = this.pageTemplateAdmin || this.isAdmin;
            responseList.customeFormFieldListJsonAnswer = JSON.parse(
              responseList.answerJSON
            );

            responseList.mappedFulfillments.forEach(item => {
              if (responseList.customeFormFieldListJsonAnswer.datasource === 'clarity' &&
                responseList.customeFormFieldListJsonAnswer.clarityProjectNumberIntake === item.projectNumber) {
                item.canUnmap = false;
              } else {
                item.canUnmap = true;
              }
            });

            if (this.listFormFieldJson && this.listFormFieldJson.length > 0) {
              for (let item of this.listFormFieldJson) {
                const itemApiKey = item['formfieldapikey'];
                if (itemApiKey && responseList.customeFormFieldListJsonAnswer[itemApiKey]) {
                  const formFieldKey = `${itemApiKey}OriginalValue`;
                  responseList.customeFormFieldListJsonAnswer[formFieldKey] = responseList.customeFormFieldListJsonAnswer[itemApiKey];
                }
              }
            }

            const validRoles = [
              'Division Leader',
              'BU Leader',
              'Delivery Owner',
              'Delivery Leader',
              'CCT Admin- Read Only',
            ];
            if (
              this.pageTemplateReadAdmin ||
              this.userData.roles.some(
                (r) => validRoles.indexOf(r.roleTitle) >= 0
              )
            ) {
              responseList.isReadOnlyRole = true;
            }

            if (this.pageTemplateAdmin || this.isAdmin) {
              responseList.isDeleteOrEditable = true;
            }

            responseList.dataSource = responseList.customeFormFieldListJsonAnswer.datasource;
            responseList.productTitle = responseList.customeFormFieldListJsonAnswer.productSIntake;
            responseList.isRisk = responseList.customeFormFieldListJsonAnswer.isRisk;
            responseList.riskReason = responseList.customeFormFieldListJsonAnswer.riskReason;
            responseList.selected = !!responseList.customeFormFieldListJsonAnswer.isTracked;
          });

          if (responses.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
        },
        (error) => {
          this.errorMsg = error;
        }
      );
  }

  public searchFromList(queryParam): void {
    this.queryParam = queryParam;
    this.search();
  }

  public openSubmitNewCommitmentsIntake(): void {
    this.showSubmitNewCommitmentsIntakeDrawer = true;
    document.getElementById('containerPage').classList.add('overflow-hidden');
  }

  public closeSubmitNewCommitmentsDrawers(): void {
    document.getElementById('containerPage').classList.remove('overflow-hidden');
    this.showSubmitNewCommitmentsIntakeDrawer = false;
  }

  callPreferenceGetService() {
    const userId = this.userData.id;
    const listName = PreferenceService.pageTemplateResponseList;
    const fetchPreferenceByUserId =
      this.preferenceService.getPreferenceByUserId(
        userId,
        listName,
        this.pageTemplateId
      );
    const fetchAllPreferenceByListName =
      this.preferenceService.getAllPreferenceByListName(
        listName,
        this.pageTemplateId
      );
    this.showAddColumnOptions = true;
    forkJoin([fetchPreferenceByUserId, fetchAllPreferenceByListName]).subscribe(
      (response: any) => {
        const columnPrefs = response[0];
        const columnList = response[1];
        this.refreshPreference(columnPrefs, columnList);
      },
      (error) => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

  refreshPreference(columnPrefs: any, columnList?: any) {
    this.isError = false;
    const columnOptions = JSON.parse(columnPrefs.columnOptions);
    this.listName = columnPrefs.listName;
    this.userId = columnPrefs.userId;
    this.preferenceId = columnPrefs.id;
    this.columnPreferences =
      this.preferenceService.convertToDropDownItems(columnOptions);
    if (this.columnPreferences?.length < this.maxColumnCount) {
      this.showAddColumnOptions = true;
    } else {
      this.showAddColumnOptions = false;
    }
    if (columnList) {
      const listOfoptions = JSON.parse(columnList.allColumnOptions);
      this.prefColumnOptions =
        this.preferenceService.convertToDropDownItems(listOfoptions);
    }
    const columnPreferences = this.preferenceService.refreshGrid(columnOptions);
    this.initDataTableColumns(columnPreferences);
    this.searchFromList(this.queryParam);
    if (!this.filterExists) {
      this.filtered = false;
    }
    this.refreshGrid = true;
  }

  initDataTableColumns(preferences: any) {
    this.pageTemplateNameTitle = this.pageTemplate.title;
    this.pageTemplateNameDescription = this.pageTemplate.description;
    this.displayColumns = [];
    this.keys = [];

    for (let i = 0; i < preferences.keys.length; i++) {
      if (preferences.keys[i] === 'id') {
        this.keys.push('id');
        this.displayColumns.push(preferences.displayColumns[i]);
      }

      if (preferences.keys[i] === 'title') {
        this.keys.push('title');
        this.displayColumns.push(preferences.displayColumns[i]);
      }
    }

    if (this.pageTemplate.formFieldListJson) {
      this.listFormFieldJson = JSON.parse(
        this.pageTemplate.formFieldListJson
      );
      if (this.listFormFieldJson.length > 0) {
        for (let item of this.listFormFieldJson) {
          this.ignoreSortingColumns.push(item['formfieldapikey']);
        }
      }
    } else {
      this.listFormFieldJson = JSON.parse('[]');
    }

    for (let i = 0; i < preferences.keys.length; i++) {
      if (preferences.keys[i] !== 'id' && preferences.keys[i] !== 'title') {
        this.keys.push(preferences.keys[i]);
        this.displayColumns.push(preferences.displayColumns[i]);
      }
    }
  }
}
