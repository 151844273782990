import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { REGEX_PATTERNS, MAX_LENGTHS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { RolesService } from 'src/app/services/roles.service';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';
import * as $ from 'jquery';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.css']
})
export class RoleFormComponent implements OnInit {

  // ----------  Role Form Attributes --------
  roleForm: FormGroup;
  isSubmitted = false;
  isError: boolean;
  isEditMode: boolean = false;
  isCloneMode: boolean = false;
  isNameError: boolean;
  errorMsg: string;
  errorNameMsg: string;
  _maxLength: any;
  RoleTypes: string[] = ['Fiserv', 'Client'];
  isPermissionFormSubmitted: boolean = false;
  showDrawer: boolean = false;
  selectedPermissionsForDisplay: any = []; //to show on UI
  roleId: number;
  selectedRole: any = {};
  selectedPermissionIds: number[] = [];
  allPermissions: any;
  permissionFields: any;
  permissionFieldsForm: FormGroup;
  isDrawerType: boolean = false;
  searchString: string = '';
  searchPermissionString: string = '';
  moduleNameList = [
    'Forms',
    'Client',
    'Products',
    'Projects',
    'Response',
    'User',
    'Role',
    'HelpTopic',
    'Combo',
    'ResponseComment',
  ];
  // PBI 247450 >> START
  moduleNameLabelList = [
    'Forms',
    'Client',
    'Products',
    'Projects',
    'Response',
    'User',
    'Role',
    'Help Topic',
    'Combo',
    'Response Comment',
  ];
  // PBI 247450 >> END
  moduleNameListAux = [];
  roleTitleListToValidate: any;
  regexValidationMessage = REGEX_VALIDATION_MESSAGE;

  @Input() drawerData: any;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private rolesService: RolesService,
    private router: Router,
    private drawerService: ProjectDrawerService
  ) { }

  ngOnInit(): void {
    this.isEditMode = this.rolesService.isEditMode;
    this.isCloneMode = this.rolesService.isCloneMode;
    this._maxLength = MAX_LENGTHS;
    this.initForm();
    if(window.location.href.indexOf("edit") > -1){
      this.route.params.pipe(take(1)).subscribe((params) => {
        this.roleId = params['id'];
      });
      this.isEditMode = true;
      this.fetchApiDataforEditClone();
    }
    else if(window.location.href.indexOf("clone") > -1){
      this.route.params.pipe(take(1)).subscribe((params) => {
        this.roleId = params['id'];
      });
      this.fetchApiDataforEditClone();
    }
    else{
      this.fetchApiData();
      this.setModuleNameListAux();
    }
    this.setRoleTitleListToValidate();
    this.setRoleListTitleToLocal();
  }

  setRoleTitleListToValidate() {
    this.roleTitleListToValidate = this.rolesService.getRoleTitleListAux();
  }

  setRoleListTitleToLocal(){
    let roleData = this.roleTitleListToValidate != undefined ? this.roleTitleListToValidate : JSON.parse(localStorage.getItem('roleTitleListToValidate'))
    localStorage.setItem('roleTitleListToValidate', JSON.stringify(roleData));
  }

  initForm() {
    this.roleForm = this.fb.group({
      title: new FormControl('', [
        Validators.required,
        Validators.pattern(REGEX_PATTERNS.name_title_validator),
        Validators.maxLength(this._maxLength.Role.Title),
        NoWhitespaceValidator.validateWhiteSpaces,
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.pattern(REGEX_PATTERNS.description_comment),
        Validators.maxLength(this._maxLength.Role.Description),
        NoWhitespaceValidator.validateWhiteSpaces,
      ]),
      canbeAssignedToClient: false,
      displayOrder: new FormControl('', [
        Validators.required,
        Validators.pattern(REGEX_PATTERNS.Numeric)
      ]),
      roleType: '1',
    });
    this.permissionFieldsForm = this.fb.group({
      permissionFields: this.fb.array([]),
    });
  }

  setModuleNameListAux(){
    let moduleNameListHelper = [];
    moduleNameListHelper = this.selectedPermissionsForDisplay.map(m => m.groupName);

    this.moduleNameListAux = this.moduleNameList.filter(m => moduleNameListHelper.includes(m));

    this.splitNames(this.moduleNameListAux)
  }

  splitNames(data: any){
    this.moduleNameListAux = data.map(x => {
      return {
        name:x,
        showName: x.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
          return str.toUpperCase()
        })
      }
    })
  }

  fetchApiData() {
    this.rolesService.fetchPermissionListData().subscribe(
      (data) => {
        this.allPermissions = data;
        this.allPermissions.forEach(permission => {
          if(permission.displayName === "Read Write Products Assigned"){
            permission.displayName = "Read Write Forms Of Assigned Products";
          }
          if(permission.displayName === "Read Products Assigned"){
            permission.displayName = "Read Forms Of Assigned Products";
          }
        });
        this.permissionFields = data;
        this.loadFormCheckboxes();
      },
      (error) => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

  fetchApiDataforEditClone(){
    let apiCall = [];
    apiCall.push(this.rolesService.getRoleById(this.roleId).pipe(take(1)));
    apiCall.push(this.rolesService.fetchPermissionListData());

    forkJoin(apiCall).subscribe(data => {
      this.selectedRole = data[0];
      this.roleForm.setValue({
        title: this.isCloneMode ? "" : this.selectedRole.title,
        description: this.isCloneMode ? "" : this.selectedRole.description,
        displayOrder: this.selectedRole.displayOrder,
        roleType: this.selectedRole.roleType === 3 ? '1' : this.selectedRole.roleType.toString(),
        canbeAssignedToClient: this.selectedRole.canbeAssignedToClient,
      });
      this.selectedPermissionsForDisplay = this.selectedRole.permissionDisplay;
      this.selectedPermissionIds = this.selectedRole.permissionsInRole;
      this.allPermissions = data[1];
      this.permissionFields = data[1];
      this.loadFormCheckboxes();
      this.setmoduleNameListAux();
    },
    (error) => { this.isError = true; this.errorMsg = error; });
  }

  loadFormCheckboxes() {
    this.permissionFields.forEach((i, o) => {
      const control = this.fb.control(
        this.selectedPermissionIds.includes(i.permission)
      );
      (this.permissionFieldsForm.controls.permissionFields as FormArray).push(
        control
      );
    });
  }

  openPermissionsDrawer() {
    $('.container-page').addClass('overflow-hidden');
    this.isPermissionFormSubmitted = false;
    this.showDrawer = true;
  }

  createSelectedPermissionListForDisplay() {
    this.selectedPermissionsForDisplay = this.permissionFields.filter((data) =>
      this.selectedPermissionIds.includes(data.permission)
    );
  }

  removePermission(permissionId) {
    const roleIndex = this.selectedPermissionIds.indexOf(permissionId);
    this.selectedPermissionIds.splice(roleIndex, 1);
    const formArrayIndex = this.permissionFields.findIndex(
      (permission) => permission.permission == permissionId
    );
    this.permissionFieldsForm.controls.permissionFields['controls'][
      formArrayIndex
    ].patchValue(false);
    this.createSelectedPermissionListForDisplay();
    this.setModuleNameListAux();
  }

  setmoduleNameListAux() {
    let moduleNameListHelper = [];
    moduleNameListHelper = this.selectedPermissionsForDisplay.map(
      (m) => m.groupName
    );
    this.moduleNameListAux = this.moduleNameList.filter((m) =>
      moduleNameListHelper.includes(m)
    );

    this.splitNames(this.moduleNameListAux)
  }

  drawerSubmit() {
    this.isPermissionFormSubmitted = true;
    if (this.permissionFieldsForm.valid) {
      this.selectedPermissionIds =
        this.permissionFieldsForm.value.permissionFields;
      this.selectedPermissionIds =
        this.permissionFieldsForm.value.permissionFields
          .map((v, i) => (v ? this.allPermissions[i].permission : null))
          .filter((v) => v !== null);

      this.createSelectedPermissionListForDisplay();
      this.setModuleNameListAux();
      this.closeDrawer();
      this.searchString = '';
      this.searchPermissionString = '';
    }
  }

  drawerCancel() {
    if (this.selectedPermissionIds.length === 0) {
      this.isPermissionFormSubmitted = false;
      this.permissionFieldsForm.reset();
    } else {
      this.resetCheckboxes();
    }
    this.closeDrawer();
    this.searchString = '';
    this.searchPermissionString = '';
  }

  resetCheckboxes() {
    this.permissionFieldsForm.reset();
    this.selectedPermissionsForDisplay.forEach((element) => {
      const formArrayIndex = this.permissionFields.findIndex(
        (permissionField) => permissionField.permission === element.permission
      );
      this.permissionFieldsForm.controls.permissionFields['controls'][
        formArrayIndex
      ].patchValue(true);
    });
  }

  closeDrawer() {
    this.showDrawer = !this.showDrawer;
    $('.container-page').removeClass('overflow-hidden');
  }

  onSubmit(): void {
    if (this.isCloneMode) {
      this.isSubmitted = true;

      if (this.roleForm.valid) {
        let rolePostData = {
          title: this.roleForm.value.title,
          description: this.roleForm.value.description,
          canbeAssignedToClient: this.roleForm.value.canbeAssignedToClient,
          permissionsInRole: this.selectedPermissionIds.toString(),
          permissionDisplay: this.selectedPermissionsForDisplay,
          displayOrder: this.roleForm.value.displayOrder,
          roleType:  this.roleForm.value.canbeAssignedToClient ? 3 : this.roleForm.value.roleType,
        };

        let roleNameList = JSON.parse(localStorage.getItem('roleTitleListToValidate'))
        let roleName = roleNameList.includes(this.roleForm.get('title').value)
        if (roleName) {
          this.isError = true;
          this.errorMsg = 'Please change the Role Name to a New Role Name.';
        } else {
          this.rolesService.createRole(rolePostData).subscribe(
            () => {
              this.rolesService._newCreatedRole = this.roleForm.value['title'];
              this.rolesService._isRoleCreated = true;
              this.router.navigate(['admin/role']);
              this.rolesService.isCloneMode = false;
              this.rolesService.isEditMode = false;
            },
            (error) => {
              this.isError = true;
              this.errorMsg = error;
            }
          );
        }
        localStorage.removeItem("roleTitleListToValidate");
      }
    }
    else if (this.isEditMode) {
      this.isSubmitted = true;
      if (this.roleForm.valid) {
        this.selectedRole.permissionsInRole = this.selectedPermissionIds.toString();
        this.selectedRole.title = this.roleForm.get('title').value;
        this.selectedRole.description = this.roleForm.get('description').value;
        this.selectedRole.displayOrder = this.roleForm.get('displayOrder').value;
        this.selectedRole.roleType = this.roleForm.get('canbeAssignedToClient').value ? 3 : this.roleForm.get('roleType').value;
        this.selectedRole.canbeAssignedToClient = this.roleForm.get('canbeAssignedToClient').value;
        let roleNameList = JSON.parse(localStorage.getItem('roleTitleListToValidate'))
        let roleName = roleNameList.filter(sameName => sameName !== this.roleForm.get('title').value).includes(this.roleForm.get('title').value)
        if (roleName) {
          this.isNameError = true;
          this.errorNameMsg = 'Please change the Role Name to a New Role Name.';
        } else {
          this.rolesService.updateRole(this.roleId, this.selectedRole).subscribe(
            () => {
              this.rolesService._roleUpdated = true;
              this.router.navigate([`admin/role/view/${this.roleId}`]);
              this.rolesService.isCloneMode = false;
              this.rolesService.isEditMode = false;
            },
            (error) => {
              this.isError = true;
              this.errorMsg = error;
            }
          );
        }
        localStorage.removeItem("roleTitleListToValidate");
      }
    }
    else {
      this.isSubmitted = true;
      if (this.roleForm.valid) {
        let rolePostData = {
          title: this.roleForm.value.title,
          description: this.roleForm.value.description,
          canbeAssignedToClient: this.roleForm.value.canbeAssignedToClient,
          permissionsInRole: this.selectedPermissionIds.toString(),
          permissionDisplay: this.selectedPermissionsForDisplay,
          displayOrder: this.roleForm.value.displayOrder,
          roleType: this.roleForm.value.canbeAssignedToClient ? 3 : this.roleForm.value.roleType,
        };
        let roleNameList = JSON.parse(localStorage.getItem('roleTitleListToValidate'));
        let roleName = roleNameList.includes(this.roleForm.get('title').value);
        if (roleName) {
          this.isNameError = true;
          this.errorNameMsg = 'Please change the Role Name to a New Role Name.';
        } else {
          this.rolesService.createRole(rolePostData).subscribe(
            () => {
              this.rolesService._newCreatedRole = this.roleForm.value['title'];
              this.rolesService._isRoleCreated = true;
              this.router.navigate(['admin/role']);
            },
            (error) => {
              this.isError = true;
              this.errorMsg = error;
            }
          );
        }
        localStorage.removeItem("roleTitleListToValidate");
      }
    }
  }

  goBack() {
    $('.container-page').removeClass('overflow-hidden');
    if (this.isDrawerType == true) {
      this.drawerService.close();
    } else {
      this.router.navigate(['admin/role']);
      this.rolesService.isCloneMode = false;
      this.rolesService.isEditMode = false;
    }
  }

  isAllCheckBoxChecked(moduleName) {
    let permissionByModule = this.getPermissionsByModule(moduleName);
		return  permissionByModule.every(
      (permission) =>
        this.permissionFieldsForm.controls.permissionFields.value[permission.index])
	}

  getPermissionsByModule(moduleName) {
    return this.allPermissions.filter((permission, i) => {
      permission.index = i
      return permission.groupName == moduleName
    })
  }

  toggleFullModule(moduleName) {
      let permissionByModule = this.getPermissionsByModule(moduleName);
      let allPermissionsChecked = this.isAllCheckBoxChecked(moduleName);
      if (allPermissionsChecked) {
        permissionByModule.forEach((permission) => {
         this.permissionFieldsForm.controls.permissionFields['controls'][permission.index].setValue(false);
        });
      } else {
        permissionByModule.forEach((permission) => {
          this.permissionFieldsForm.controls.permissionFields['controls'][permission.index].setValue(true);
        });
      }
  }

  filterByTitle(permissionTitle: string, search: string) {
    return this.searchPermissionString == '' || permissionTitle?.toLowerCase().includes(this.searchPermissionString?.toLowerCase());
  }

  filterByMainTitle(moduleName: string, search: string){
    if(search === ""){
      return false;
    }
    else if(moduleName.toLowerCase().includes(this.searchString?.toLowerCase())){
      return false;
    }
    else {
      return true;
    }
  }

  setRoleType() {
    this.roleForm.patchValue({
      canbeAssignedToClient: false,
    })
  }

  isSearchPermissionsValid(permissionName) {
    return REGEX_PATTERNS.name_title_validator.test(permissionName);
   }

}
