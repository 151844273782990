<div>
  <div class="heading-container align-items-start" style="padding-right: 11px;">
    <div class="back-action-container">
      <div class="back-button">
        <a href="javascript:void(0);"  (click)="goBack()">
          <span class="material-icons"> arrow_back </span> 
          <span>{{pageTemplateTitle}}</span>&nbsp;Submission(s)
        </a>
      </div>
      <h1 *ngIf="!isUpdateTitle"> 
        {{pageTitle}}
        <span *ngIf="!isUpdateTitle" class="material-icons ml-2 mt-1" style="cursor: pointer;" (click)="updateTitle(false)"> edit </span>
      </h1>
      <div class="d-flex">
        <input *ngIf="isUpdateTitle" type="text" class="form-control" name="tempPageTitle" [(ngModel)]="tempPageTitle">
        <span *ngIf="isUpdateTitle" class="material-icons ml-2 mt-1" style="cursor: pointer;" (click)="updateTitle(false)"> cancel </span>
        <span *ngIf="isUpdateTitle" class="material-icons ml-2 mt-1" style="cursor: pointer;" (click)="updateTitle(true)"> check </span>
      </div>
    </div>
  </div>
          <button id="apiCall" style="display:none;" (click)="apiCall()">adfsdf</button>
          <input id="inputapiCall" style="display:none;" />
  <div class="row m-0 mb-0" *ngIf="pageResponse">
    <div id="accordion" class="accordion collapse-view col-md-12 accordian-responses grid-wrapper-section">
      <div id="collapseOne" data-parent="#accordion" class="collapse show">
        <div class="biller-survey">
          <div>
            <ul>
              <li *ngIf="pageResponse?.pageTemplateTitle">Request Template Name<span class="info">{{pageResponse?.pageTemplateTitle || 'N/A'}}</span></li>
              <!-- <li *ngIf="pageResponse?.startDate && pageResponse.startDate!='0001-01-01T00:00:00Z' && pageResponse.startDate!='0001-12-31T00:00:00Z'">Start Date <span class="info">{{pageResponse?.startDate | dateFormat}}</span></li>
              <li *ngIf="pageResponse?.dueDate && pageResponse.dueDate!='0001-01-01T00:00:00Z' && pageResponse.dueDate!='0001-12-31T00:00:00Z'">Due Date <span class="info">{{pageResponse?.dueDate | dateFormat}}</span></li> -->
              <li *ngIf="pageResponse?.pageTemplateResponseStatusTitle && this.pageResponse?.pageTemplateApprovers?.length > 0">Status<span class="info">{{pageResponse?.pageTemplateResponseStatusTitle || 'N/A'}}</span></li>
              <li *ngIf="pageResponse?.currentApproverName">Approver Name(s)<span class="info">{{pageResponse?.currentApproverName || 'N/A'}}</span></li>
              <li *ngIf="pageResponse?.submittedByName">Submitted By<span class="info">{{pageResponse?.submittedByName || 'N/A'}}</span></li>
              <li *ngIf="pageResponse?.title">Submitted On <span class="info">{{pageResponse?.createdOn | dateFormat}}</span></li>
              <li *ngIf="pageResponse?.organizationTitle">Organization Name<span class="info">{{pageResponse?.organizationTitle}}</span></li>
              <li *ngIf="pageResponse?.productTitle">Product Name<span class="info">{{pageResponse?.productTitle}}</span></li>
              <li *ngIf="pageResponse?.clientTitle">Client Name<span class="info">{{pageResponse?.clientTitle}}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-0 separate-form-container " [ngClass]="!hideLeftNav ? 'p-0' : 'p-0'">
      <aside class="aside side-navbar listing col-xl-2 col-3" [ngClass]="!hideLeftNav ? 'col-xl-2 col-3' : 'col-xl-2 col-3 asidebar'" *ngIf="fieldSets && fieldSets.length > 1">
        <div class="left-nav" [ngClass]="!hideLeftNav? '' : 'left-nav-collapse'" role="navigation">
          <div class="left-nav-icon" aria-label="menu" tabindex="" aria-expanded="false" (click)="toggleSideBar()">
            <i class="navbar-icon-menu" [ngClass]="!hideLeftNav ? '' : 'navbar-icon-close' "></i>
            <span class="toggle-heading">&nbsp;</span>
          </div>
          <ol class="timeline">
            <ng-container *ngFor="let fieldset of fieldSets; index as i">
              <li [ngClass]="getClassName(fieldset)" *ngIf="(fieldset.tempshow!=false)">
                <a href="javascript:void(0);" (click)="checkDataLoss('fieldSetChange',i)">{{fieldset.legend}}</a>
              </li>
            </ng-container>
          </ol>
        </div>
      </aside>
      <section class="p-0" [ngClass]="computeClazzForNavigation()">
        <div class="pt-4">
        <div class="alert alert-success alert-dismissible mt-5 ml-3" *ngIf="isSuccess">
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p> {{successMsg}} <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isSuccess=false"> × </button> </p>
        </div>
        <div class="alert alert-dismissible alert-danger mt-5 ml-3" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false"> × </button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>

      </div>
        <div class="form-container pt-4 pl-4"   >
          <div class="formio-form frontend">
            <formio [form]="currentFieldSet" [options]="formbuilder" [submission]=answer  [readOnly]="readonly" (submit)="onSubmit($event,false)" [refresh]="refreshForm" (change)="change($event)" (formLoad)="render($event)" #formio></formio>
            <div class="fdl-2 button-container" id="button_view">
              <div>
                <button type="button" class="btn fdl-btn btn-secondary-light  mr-2" aria-label="Default" (click)="goBack()"> Cancel </button>
                <button type="button" class="btn fdl-btn btn-secondary-light  mr-2" aria-label="Default" *ngIf="((isShowPage) && readonly!=true && currentFieldSetIndex != fieldSets.length-1)" (click)="checkDataLoss('skip')"> Skip </button>
                <button *ngIf="showApproverButtons && currentFieldSetIndex == fieldSets.length-1" type="button" class="btn fdl-btn btn-primary-orange mr-2" aria-label="Default" (click)="checkDataLoss('reject')">Reject</button>
                <button *ngIf="showApproverButtons && currentFieldSetIndex == fieldSets.length-1" type="button" class="btn fdl-btn btn-primary-orange" aria-label="Default" (click)="checkDataLoss('sentBack')">Send Back</button>
              </div>
              
              <div>
                <button type="button" class="btn fdl-btn btn-primary-orange  mr-2" aria-label="Default" *ngIf="((fieldSets.length > 1 && (currentFieldSetIndex > 0)) && readonly==true)" 
                  (click)="checkDataLoss('previous')"> Previous
                </button>

                <button *ngIf="!readonly" type="button" class="btn fdl-btn btn-primary-orange mr-2" aria-label="Save" (click)="saveDraft()">
                  <span> Save </span>
                </button>

                <button *ngIf="!readonly && (currentFieldSetIndex !== fieldSets.length-1 && (isShowPage))" type="button" class="btn fdl-btn btn-primary-orange mr-2" 
                  aria-label="Continue" (click)="saveContinueForm(false)">
                  <span *ngIf="(currentFieldSetIndex !== fieldSets.length-1 && (isShowPage))">Continue</span>
                </button>

                <button *ngIf="doSubmitButton()" type="button" class="btn fdl-btn btn-primary-orange" aria-label=" Submit" (click)="saveContinueForm(true)">
                  Submit
                </button>
                <button *ngIf="readonly==true && (currentFieldSetIndex !== fieldSets.length-1 && (isShowPage))" type="button" class="btn fdl-btn btn-primary-orange" aria-label="Next" (click)="checkDataLoss('next')">
                   Next
                </button>
                <button *ngIf="showApproverButtons && currentFieldSetIndex == fieldSets.length-1" type="button" class="btn fdl-btn btn-primary-orange mr-2" aria-label="Approve" (click)="checkDataLoss('approve')">
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</div>


<!-----      DATA LOSS MODAL BEGIN -------->
<div class="modal fade bd-example-modal-lg" id="dataLoss" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" #content>
  <div class="modal-dialog modal-lg mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Confirm Navigation</h4>
      </div>
      <div class="modal-body">
        <p><b>Are you sure you want to navigate away from this page?</b></p>
        <br>
        <p>
          The page contains unsaved data. If you choose to <b>Leave this page</b>, unsaved data will be lost. Choose <b>Stay on this page</b>
          option to return to form and save the changes
        </p>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class=" btn fdl-btn btn-secondary-light " data-dimiss="modal" (click)="closeDataLossModal()">
          Stay on this Page
        </button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal" (click)="formBtnsHandler(0)">
          Leave this Page
        </button>
      </div>
    </div>
  </div>
</div>
<!---  DATA LOSS MODAL END  -------->




<!---  COMMENTS MODAL STARTS -------->
<div class="modal fade bd-example-modal-lg" id="approvalComments" tabindex="-1" role="dialog" aria-hidden="true" #content *ngIf="showApproverButtons">
  <div class="modal-dialog modal-lg mt-120" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 *ngIf="!isApproved && !isRejected" class="modal-title">
          Please select user(s) to send back the Request
        </h4>
        <h4 *ngIf="isApproved || isRejected" class="modal-title">
          Please add your comments: <span class="asterisk"></span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-dismissible alert-danger" *ngIf="isRejectedError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isRejectedError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>
        <form [formGroup]="approvalForm" autocomplete="off" *ngIf="approvalForm">
          <div class="row" *ngIf="!isApproved && !isRejected">
            <div class="col-md-12 mb-3"  [ngClass]="{'has-error': !approvalForm.get('sentBackLevel').valid && (approvalForm.get('sentBackLevel').touched|| isSubmitted) }">
              <div class="card-container-levels">
                <div class="card-details" *ngFor="let rows of sentBackApprover">
                  <div class="radio">
                    <input name="myRadio" class="excludeForDataLoss" id="myRadio{{rows.id}}" type="radio" value="{{rows.id}}" formControlName="sentBackLevel">
                    <label for="myRadio{{rows.id}}"><span></span></label>
                  </div>
                  <div class="inline-radio-wrapper">
                    <h2>{{rows.level}}</h2>
                    <div class="wrapper">
                      <div class="user-list word-break">
                        <span>{{rows.name}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span *ngIf="!approvalForm.get('sentBackLevel').valid && (approvalForm.get('sentBackLevel').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select user(s) to sent back.
              </span>
            </div>
          </div>
          <div *ngIf="!isApproved && !isRejected" class="row "> <div class="col-md-12 modal-heading ">Comments <span class="asterisk"></span></div></div>

          <div class="row">
            <div class="col-md-12 mb-3"
                 [ngClass]="{'has-error': !approvalForm.get('comments').valid && (approvalForm.get('comments').touched|| isSubmitted) }">
              <textarea type="text" name="comments" id="comments" formControlName="comments" class="form-control"
                        rows="2"></textarea>
              <span *ngIf="!approvalForm.get('comments').valid && approvalForm.get('comments').errors?.required && (approvalForm.get('comments').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please provide comments.
              </span>
              <span *ngIf="!approvalForm.get('comments').valid && approvalForm.get('comments').errors?.maxlength && (approvalForm.get('comments').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is {{_maxLengths.Approval.Comments}}, please correct it.
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal" (click)="closeApprovalCommentsModal()"> Cancel </button>
        <button *ngIf="isRejected" type="button" class=" btn fdl-btn btn-primary-orange mr-2" (click)="submitApprovalForm('rejected')"> Submit </button>
        <button *ngIf="isApproved" type="button" class=" btn fdl-btn btn-primary-orange mr-2" (click)="submitApprovalForm('approved')"> Submit </button>
        <button *ngIf="!isApproved && !isRejected" type="button" class=" btn fdl-btn btn-primary-orange mr-2" (click)="submitApprovalForm('sentback')"> Submit </button>
      </div>
    </div>
  </div>
</div>
<!---  COMMENTS MODAL ENDS -------->