import { NgModule } from '@angular/core';
import { DocumentFileComponent } from './Components/document-file/document-file.component';
import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentDownloadComponent } from './Components/document-download/document-download.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    DocumentFileComponent,
    DocumentDownloadComponent,
  ],
  imports: [
    DocumentsRoutingModule,
    CommonModule
  ],
  providers: [],
})
export class DocumentsModule { }
