import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { IClients } from 'src/app/shared/client';
import { environment } from 'src/environments/environment';
import { ProjectsService } from 'src/app/services/projects.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import { take } from 'rxjs/internal/operators/take';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ClientsDTOService } from 'src/app/shared/clientsDTO.service';
import { MasterService } from 'src/app/services/master.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { FdlFilterUIHepler } from 'src/app/shared/fdl-filter-ui-helper';
import { ClientValidator } from '../validators/client.validators';
declare var $: any;

@Component({
  templateUrl: './client-list.component.html'
})
export class ClientListComponent implements OnInit, AfterViewInit, OnDestroy {
  successMsg: string;


  constructor(private router: Router, private clientService: ClientsService,
    private projectService: ProjectsService, private permission: PermissionsService,
    private clientDto: ClientsDTOService, private masterService: MasterService,
    private userFilterService: UserFilterService, private route: ActivatedRoute,
    private fdlFilterHelper: FdlFilterUIHepler) { }

  filterForm: FormGroup;
  clientList: IClients;
  queryParam: IQuery;
  currentPageNumber: number = 1;
  pagerObject: IPager = {
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  };
  sortingObject;
  isError: boolean;
  errorMsg: string = "";
  displayColumns = ['Client Name','Description', 'GUD ID', "Relationship Manager Name"];
  keys = ['titleWithDuns', 'description', 'gud', 'relationshipManagerName'];
  clientSuggestion: ISuggestions;
  deletedFormMsg: string = "";
  showComment: boolean = true;
  // isCreateClientAllowed: boolean = false;
  organizationSuggestion: ISuggestions;
  relationshipManagerSuggestion: ISuggestions;
  isAdmin: boolean = false;
  listname: string = PreferenceService.clientList;
  filterExists: boolean = false;
  filtered: boolean = false;
  clientText: string = '';
  isSubmitted = false;
  regexValidationMessage: any;
  _maxLength: any;

  ngOnInit(): void {
    this._maxLength = MAX_LENGTHS;
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    // this.isCreateClientAllowed = this.permission.isCreateClientAllowed();
    this.isAdmin = this.permission.userData.isAdmin;
    this.initFilterForm();
    this.initQueryParams();
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    this.initClientList();
    this.getSuggestions();
    this.getOrganizationSuggestions();
    this.getRelationshipManagerSuggestions();
    this.refresh();
    this.filtered = this.filterExists;
    if (this.filterExists)
      this.clientText = this.filterForm.value.ClientTitleWithDuns; // Bug-251267: Retain client name in search box.
  }

  refresh() {
    this.callRefreshClientListService();
  }

  ngAfterViewInit(): void {
    this.deletedFormMsg = '';
    this.successMsg = this.clientDto._isClientCreated ? `The client '${this.clientDto._newCreatedClient}' has been created.` : '';
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      ClientTitleWithDuns: new FormControl(''),
      OrganizationName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]),
      DunsId: new FormControl('', [ClientValidator.validateDunsIdFilter]),
      Gud: new FormControl('', [ClientValidator.validateGudIdFiler]),
      RelationshipManagerName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.relationship_manager_name)]),
    });

  }

  // getSuggestions() {
  //   this.projectService.getSuggestions('clients','')
  //   .subscribe(suggestions => {
  //     this.clientSuggestion = suggestions;
  //   },
  //   (error)=> { this.isError = true; this.errorMsg = error; });
  // }

  typeaheadLoadingClients() {
    this.getSuggestions();
  }

  getSuggestions() {
    let suggestionSubscriber: any;
    let params = [];
    if (this.clientText && this.clientText.length >= 3) {
      params.push({ paramName: "titleWithDuns", value: this.clientText });
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", params, 100);
    }
    else {
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", null, 100);
    }

    suggestionSubscriber.subscribe(
      data => {
        this.clientSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  callClientListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.clientService.getClients(this.queryParam)
      .pipe(take(1))
      .subscribe(
        clients => {
          this.isError = false;
          this.clientList = clients.body as IClients;
          if (clients.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(clients.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  callRefreshClientListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.clientService.getRefreshClients(this.queryParam)
      .pipe(take(1))
      .subscribe(
        clients => {
          this.isError = false;
          this.clientList = clients.body as IClients;
          if (clients.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(clients.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  initClientList() {
    if (!this.filterForm.valid) {
      return;
    }

    const filterValues = this.filterForm.value;

    if (filterValues['ClientTitleWithDuns'])
      this.queryParam.clientTitleWithDuns = encodeURIComponent(filterValues['ClientTitleWithDuns'])
    if (filterValues.OrganizationName)
      this.queryParam.organizationTitle = filterValues['OrganizationName']
    if (filterValues.RelationshipManagerName)
      this.queryParam.relationshipManagerName = filterValues['RelationshipManagerName']
    if (filterValues.Gud)
      this.queryParam.gud = filterValues['Gud']
    if (filterValues.DunsId)
      this.queryParam.dunsId = filterValues['DunsId']

    this.setOrderBy(this.sortingObject);
    this.callClientListService();
  }

  filterList() {
    this.isSubmitted = true;
    if (this.filterForm.valid) {
      this.initQueryParams();
      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initClientList();
      this.filtered = true
    }
  }

  clearFilterList() {
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.userFilterService.clearFilter();
    this.clientText = '';
    this.getSuggestions();
    this.callRefreshClientListService()
    this.fdlFilterHelper.showSearchIconWhenResetFilter();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callClientListService();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callClientListService();
  }

  createClient() {
    this.router.navigate(['admin/client/create']);
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.clientService.exportClients(this.queryParam).subscribe(
      res => {
        console.log(res.body);
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'clients.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  downloadFile(response) {
    let url = window.URL.createObjectURL(response);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  navigate(event) {
    if (event.action === "view") {
      this.masterService.saveFilters(this.filterForm);
      this.router.navigate(['/admin/client/view', event.data]);
    }
    else if (event.action === "delete") {
      this.deletedFormMsg = '';
      let deleteClient = this.clientList.clients.filter(item => item.id === event.data)[0];
      this.clientService.deleteClient(event.data).subscribe(
        () => {
          this.isError = false;
          this.callClientListService();
          this.getSuggestions();
          this.successMsg = '';
          this.deletedFormMsg = `The client '${deleteClient.title}' has been deleted.`
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      )
    }
  }

  ngOnDestroy(): void {
    this.clientDto._isClientCreated = false;
    this.clientDto._newCreatedClient = '';
    this.deletedFormMsg = "";
    this.successMsg = "";
  }

  getOrganizationSuggestions() {
    if (this.isAdmin) {
      this.projectService.getSuggestions("organizations", "")
        .subscribe(suggestions => {
          this.organizationSuggestion = suggestions;
        },
          error => { this.isError = true; this.errorMsg = error; });
    }
  }

  getRelationshipManagerSuggestions() {
    this.clientService.getRelationshipManagerSuggestions("relationship-managers")
      .subscribe(suggestions => {
        this.relationshipManagerSuggestion = suggestions;
      },
        error => { this.isError = true; this.errorMsg = error; });
  }
}
