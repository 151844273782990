import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { ISurvey, IResponseForSurvey } from "../shared/survey";
import { Observable } from "rxjs";

import { MasterService } from "./master.service";
import { catchError } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class SurveyService {
  private getSurveysEndpoint = "surveys/";
  private generateOtpEndpoint = "surveys/GenerateOTP";
  private validateOtpEndpoint = "surveys/ValidateOTP";
  private masterResponseStatusEndpoint = "master/responsestatus";
  private getSurveyRespondentsEndpoint = "surveys/GetSurveyRespondents/";
  private getSurveyRespondentsPostEndpoint = "surveys/GetSurveyRespondentsPost/";
  private respondEndPoint = "respond";
  private renewSurveyTokenEndPoint = "surveys/token/renew"
  private responseForSurvey: IResponseForSurvey;
  private surveyId: string;
  surveyStatus;
  otpValidated: boolean = false;
  error: string = "";
  respondentEmail: string;
  private parentVisited: boolean = false;

  constructor(private http: HttpClient, private masterService: MasterService) { }

  getMasterResponseStatus(): Observable<any> {
    return this.http.get(this.masterResponseStatusEndpoint);
  }

  getSurveys(surveyId: string): Observable<ISurvey> {
    this.getMasterResponseStatus().subscribe(data => {
      this.masterService.setMasterListData(data);
    });
    return this.http.get<ISurvey>(this.getSurveysEndpoint + surveyId);
  }

  generateOtp(surveyId: string): Observable<any> | null {
    return this.http.post(this.generateOtpEndpoint, { surveyId: surveyId, email: this.respondentEmail });
  }

  validateOtp(otp: string, surveyId: string): Observable<any> | null {
    return this.http.post(this.validateOtpEndpoint, {
      surveyId: surveyId,
      otp: otp
    });
  }
  renewToken(): Observable<any> | null {
    return this.http.post(this.renewSurveyTokenEndPoint, {});
  }
  submitSurvey(surveyParams) {
    return this.http.put<any>(
      this.getSurveysEndpoint +
      surveyParams.surveyId +
      "/" +
      this.respondEndPoint,
      surveyParams
    );
  }

  getResponseForSurvey(): IResponseForSurvey {
    return this.responseForSurvey;
  }

  setResponseForSurvey(responseForSurvey: IResponseForSurvey) {
    this.responseForSurvey = responseForSurvey;
  }

  setExpiredSurvey(status: string) {
    this.surveyStatus = status;
  }

  getExpiredSurvey(): string {
    return this.surveyStatus;
  }

  validatedOtp(validated: boolean) {
    this.otpValidated = validated;
  }

  isValidatedOtp(): boolean {
    return this.otpValidated;
  }

  setIdForSurvey(id: string): void {
    this.surveyId = id;
  }

  getIdForSurvey(): string {
    return this.surveyId;
  }

  setError(error: string): void {
    this.error = error;
  }

  getError(): string {
    return this.error;
  }

  setEmail(email: string): void {
    this.respondentEmail = email;
  }

  setParentVisited(visited: boolean) {
    this.parentVisited = visited;
  }

  getParentVisited(): boolean {
    return this.parentVisited;
  }

  /* For Unauthenticated Form Comment History */
  getSurveyForCommentHistory(surveyId: string): Observable<ISurvey> {
    return this.http.get<ISurvey>(this.getSurveysEndpoint + surveyId);
  }

  getSurveyRespondents(surveyId){
    return this.http.get(this.getSurveyRespondentsEndpoint + surveyId, { observe: 'response', responseType: 'text' });
  }
}
