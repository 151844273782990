import { Injectable } from '@angular/core';
import {
  ICreateTopic,
  ICreateTopicForm,
} from '../../manageForms/models/createTopic';

@Injectable({
  providedIn: 'root',
})
export class ManageHelpService {
  private _formVal: ICreateTopicForm;
  private _successMessage: string = '';
  private _flowName: string = '';

  constructor() {}

  get flowName(): string {
    return this._flowName;
  }
  set flowName(value: string) {
    this._flowName = value;
  }
  get successMessage(): string {
    return this._successMessage;
  }
  set successMessage(value: string) {
    this._successMessage = value;
  }
  get formVal(): ICreateTopicForm {
    return this._formVal;
  }
  set formVal(value: ICreateTopicForm) {
    this._formVal = value;
  }

  private _downloadedTopic: ICreateTopic;
  get downloadedTopic(): ICreateTopic {
    return this._downloadedTopic;
  }
  set downloadedTopic(value: ICreateTopic) {
    this._downloadedTopic = value;
  }
}
