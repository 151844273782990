import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import{FieldBase} from './formui/fieldClass/field-base';

@Injectable({
  providedIn: 'root'
})
export class FieldControlServiceService {

  constructor() { }
  toFormGroup(fields: FieldBase<string>[] ) {
    let group: any = {};

    fields.forEach(field => {
      group[field.key] = field.required ? new FormControl(field.value || '', Validators.required)
                                              : new FormControl(field.value || '');
    });
    return new FormGroup(group);
  }
}
