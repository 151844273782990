import { Component, OnInit } from '@angular/core';
import { form, InmemoryDataService } from '../../inmemory-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sprender',
  templateUrl: './sprender.component.html',
  styleUrls: ['./sprender.component.css']
})
export class SprenderComponent implements OnInit {

  forms: form[];
  constructor(private dataService: InmemoryDataService,private router: Router) { }

  ngOnInit(): void {
    this.forms = this.dataService.getAllForms();
  }

  goToForm(formId:string){
    this.router.navigate(['/view/'+ formId]);
  }
    goToEdit(formId:string){
    this.router.navigate(['/edit/'+ formId]);
  }

}
