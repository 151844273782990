import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { title } from "process";


@Injectable({
    providedIn: 'root'
})
export class PreferenceService {

    private preferenceAPIURL = 'preference';
    private allPreferenceAPIURL = 'preference/getallcolumns';
    static projectList = 'ProjectList';
    static productList = 'ProductList';
    static clientList = 'ClientList';
    static userList = 'UserList';
    static roleList = 'RoleList';
    static formTemplateList = 'FormTemplateList';
    static formResponseList = 'FormResponseList';
    static formExtractionTemplateList = 'FormExtractionTemplateList';
    static emailTemplateList = 'EmailTemplateList';
    static fileUploadList = 'FileUploadList';
    static reportList = 'ReportList';
    static resourceCategoryList = 'ResourceCategoryList';
    static resourceArticleList = 'ResourceArticleList';
    static pageTemplateResponseList = 'PageTemplateResponseList';
    static intakeRequestList = 'IntakeRequestList';
    static userGroupList = 'UserGroupList';
    static pageTemplateList = 'PageTemplateList';
    static responseExtractList = 'ResponseExtractList';
    static featureList = 'FeatureList';
    static workflowList = 'WorkflowList';
    static workflowExecutionList = 'WorkflowExecutionList';
    static configurationList = 'ConfigurationList';
    static organizationList = 'OrganizationList';
    static dashboardFavoritesResponseList = 'DashboardFavoritesResponseList';
    static dashboardFavoritesProjectList = 'DashboardFavoritesProjectList';
    static dashboardPendingTaskList = 'DashboardPendingTaskList';
    static maxColumnCount = 8;
    static minColumnCount = 2;
    static formResponseListMaxColumnCount = 12;
    constructor(private http: HttpClient) {
    }

    savePreference(preferenceParams: any) {
        return this.http.post(this.preferenceAPIURL, preferenceParams);
    }

    updatePreference(preferenceParams: any) {
        return this.http.put(this.preferenceAPIURL, preferenceParams);
    }

    getAllPreferenceByListName(listName: any, pageTemplateId: number = 0){
        const preferenceURL = `${this.allPreferenceAPIURL}/${listName}`;
        
        if(pageTemplateId > 0)
            return this.http.get(preferenceURL + "?pageTemplateId=" + pageTemplateId);
        else
            return this.http.get(preferenceURL);
    }

    getPreferenceById(preferenceId: any){
      const preferenceURL = `${this.preferenceAPIURL}/${preferenceId}`;
      return this.http.get(preferenceURL);
    }

    getPreferenceByUserId(userId: any, listName: any, pageTemplateId: number = 0){
        const preferenceURL = `${this.preferenceAPIURL}/${userId}/${listName}`;
        
        if(pageTemplateId > 0)
            return this.http.get(preferenceURL + "?pageTemplateId=" + pageTemplateId);
        else
            return this.http.get(preferenceURL);
    }

    getDefaultPreference(displayColumns: any) {
        let defaultPreference = [];
        let counter = 0;
        let columnPref;
        displayColumns.forEach((column: any) => {
            columnPref = {
                key: column,
                title: column,
                order: counter
            }
            defaultPreference.push(columnPref);
            counter++;
        });
        return defaultPreference;
    }

    checkForDuplicates(preferences: any) {
        let isDuplicate:Boolean = false;
        const uniquePreferences = new Set(preferences.map(v => v.key));
        if (uniquePreferences.size < preferences.length) {
            isDuplicate = true;
        }
        return isDuplicate;
    }

    hasEmptyColumns(preferences: any) {
        return preferences.some(columnOption => !columnOption.id);
    }

    convertToDropDownItems(columnOptions: any) {
        let preferences = [];
        let columnPref;
        columnOptions.forEach((columnOption: any) => {
            columnPref = {
                id: columnOption.key,
                title: columnOption.title,
                order: columnOption.order
            }
            preferences.push(columnPref);
        });
        return preferences;
    }

    getPreferencesToBeSaved(columnOptions: any, columnList: any) {
        let preferences = [];
        let counter = 0;
        columnOptions.forEach((columnOption1: any) => {
            if (!columnOption1.id) {
                return;
            }

            const columnOption = columnList.find(columnOption2 => columnOption2.id === columnOption1.id);
            if (!columnOption) {
                return;
            }

            preferences.push({
                key: columnOption.id,
                title: columnOption.title,
                order: counter
            });
            counter++;
        });
        return preferences;
    }

    refreshGrid(columnOptions: any) {
        let keys = [];
        let displayColumns = [];
        columnOptions.forEach((columnOption: any) => {
            keys.push(columnOption.key);
            displayColumns.push(columnOption.title);
        });
        return {
            keys: keys,
            displayColumns: displayColumns
        }
    }
}
