import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IQuery } from "../shared/query";
import { IGroup } from "../shared/group";

@Injectable({
    providedIn: 'root'
})
export class GroupService {
    private groupAPIURL = 'groups';
    private _isGroupCreated: boolean;
    private _isGroupUpdated: boolean;
    private _isEditMode : boolean;
    private _newCreatedGroup: string;
    private _setGroupData: IGroup;

    constructor(private http: HttpClient) { }

    set isEditMode(msg) {
        this._isEditMode = msg;
    }

    get isEditMode() {
        return this._isEditMode;
    }

    set isGroupUpdated(msg) {
        this._isGroupUpdated = msg;
    }

    get isGroupUpdated() {
        return this._isGroupUpdated;
    }

    set isGroupCreated(msg) {
        this._isGroupCreated = msg;
    }

    get isGroupCreated() {
        return this._isGroupCreated;
    }

    set newCreatedGroup(msg) {
        this._newCreatedGroup = msg;
    }

    get newCreatedGroup() {
        return this._newCreatedGroup;
    }

    set setGroupData(data: IGroup) {
        this._setGroupData = data;
    }

    get getGroupData() {
        return this._setGroupData;
    }

    getGroupList(queryParams: IQuery) {
        let params = new HttpParams();

        for (const key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }

        let options = { 'refresh': 'true' }
        return this.http.get(this.groupAPIURL, { headers: options, params: params, observe: 'response' });
    }

    getGroupById(id: number) {
        return this.http.get<IGroup>(`${this.groupAPIURL}/${id}`);
    }

    createGroup(data) {
        return this.http.post(this.groupAPIURL, data);
    }

    updateGroup(Id: number, data) {
        return this.http.put(`${this.groupAPIURL}/${Id}`, data);
    }

    deleteGroup(Id: number) {
        return this.http.delete(`${this.groupAPIURL}/${Id}`);
    }   
}