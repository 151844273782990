import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import { UserService } from '../services/user.service';
import { allowedPermissions } from '../core/constants';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminRouteGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService, private permissions: PermissionsService) {

  }
  canActivate() {
    const user = this.userService.getUserListData();
    if (!user) {
      return this.userService.callUserListData().pipe(map((data) => {
        this.userService.setUserListData(data);
        this.permissions.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm];
          }
        });
        if (!this.permissions.showFormLink()) {
          if (this.permissions.showUserLink()) {
            this.router.navigate(['admin/user']);
            return false;
          }
          if (this.permissions.showHelpLink()) {
            this.router.navigate(['admin/help']);
            return false;
          }
          this.router.navigate(['dashboard']);
          return false;
        }
        else
          return true;
      }));
    }
    else {
      if (!this.permissions.showFormLink()) {
        if (this.permissions.showUserLink()) {
          this.router.navigate(['admin/user']);
          return false;
        }
        if (this.permissions.showClientLink()) {
          this.router.navigate(['admin/client']);
          return false;
        }
        this.router.navigate(['dashboard']);
        return false;
      }
      else
        return true;
    }
  }

}
