import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder, FormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { FormsService } from 'src/app/services/forms.service';
import { IRespondent } from 'src/app/shared/user';
import { Router, ActivatedRoute } from '@angular/router';
import { ManageFormsService } from 'src/app/admin/manageForms/services/manageForms.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { GUID_EMPTY, MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { ClientsService } from 'src/app/services/clients.service';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { ProgressBarService } from 'src/app/services/progressBar.service';
import { takeUntil } from 'rxjs/operators';
import { MutipleaddComponent } from '../mutipleadd/mutipleadd.component';
import { Directive, HostListener } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { DateFormatPipe } from '../../Pipes/date-format.pipe';
import { PermissionsService } from 'src/app/services/permissions.service';
declare var $: any;

@Component({
  selector: 'app-delegate',
  templateUrl: './delegate.component.html',
  styleUrls: ['./delegate.component.css']
})
export class DelegateComponent implements OnInit, OnDestroy {
  @HostListener('click', ['$event'])
  clickEvent(event) {
    if (event.target.type != 'date' && event.target.attributes.name && event.target.attributes.name.value != 'respondentType') {
      event.preventDefault();
      event.stopPropagation();
      event.currentTarget.disabled = true;
    }
  }
  delegateForm: FormGroup;
  assignedToId;
  isError: boolean = false;
  errorMsg: string = '';
  isClicked: boolean = false;
  delegatedSuccessMsg: string = '';
  respondentUsers: IRespondent;
  respondentUsers2: any;
  @Input() delegateFormData: any;
  todaysdate: string;
  @Input() type: string;
  _maxLength: any;
  @Output() successDelegate = new EventEmitter();
  private destroySubject = new Subject();
  Subscription: Subscription;
  @Input() mode: string = "delegate"; //For switching between delegate and reinitiate
  isDelegate: boolean = true; //
  filedformApproverDetails: any;
  fieldformApproverJson: any;
  isDuplicate: boolean;
  isReady: boolean = false;
  filedformSurveyDetails: any;
  fieldformSurveyJson: any;
  isSurvey: boolean = false;
  isUserLoaded: boolean = false;
  isAddNewUserButtonEnable: boolean = false;
  isSubmitted: boolean = false;
  respondentType: any;
  regexValidationMessage: any;
  @ViewChild('addApprover') callFunction: MutipleaddComponent;
  @ViewChild('addSurvey') addSurvey: MutipleaddComponent;
  constructor(
    private formService: FormsService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _manageForm: ManageFormsService,
    private projectsService: ProjectsService,
    private clientsService: ClientsService,
    private progressBarService: ProgressBarService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public _userService: UserService,
    private _datePipe: DateFormatPipe,
    private permissionService: PermissionsService,
  ) { }


  ngOnChanges() {
    this.isDelegate = (this.mode == "delegate");

    if (this._router.url.indexOf('survey') === -1) {
      this.isReady = false;
      if (Object.keys(this.delegateFormData).length !== 0 && this.delegateFormData.assignedToId !== 0) {
        this.getAllUsers();
      } else if (Object.keys(this.delegateFormData).length !== 0 && this.delegateFormData.assignedToId == 0){
        this.isSurvey = true;
        this.reEvaluateValidators('name',0, 'respondentName');
        if (Object.keys(this.delegateFormData).length !== 0) this.updateSurveyResponder();
      }
    }
  if(this.delegateFormData && this.delegateForm){
    this.delegateForm.controls['dueDate'].setValue(this.getDueDate());

    // We need to reopen the old screen after a new user has been created. If the delegateRespondentId has value that mean 'user' type is selected
    if (this.delegateFormData.delegateRespondentId) {
      this.delegateForm.patchValue({ respondentType: 'user' });
      this.isSurvey = false;
      if (!this.isUserLoaded) {
        this.getAllUsers();
      }
    } else {
      this.delegateForm.patchValue({ respondentType: this.isSurvey ? 'guest' : 'user' });
    }

    //if(!this.isDelegate){
    //  this.delegateForm.patchValue({
    //    'userName': this.delegateFormData.assignedToName,
    //    'userEmail': this.delegateFormData.assignedToEmail
    //  });
    //}
  }
}
ngOnDestroy() {
  if (this.type === "viewResponse") {
    this.progressBarService.userId$.next("-1");
    this.Subscription.unsubscribe();
  }
  }
  initiateForm() {

    this.delegateForm = new FormGroup({
      'respondentName': this.fb.array([]),
      'guest': this.fb.array([]),
      'userEmail': new FormControl(''),
      'dueDate': new FormControl(this.getDueDate()),
      'comments': new FormControl('', [Validators.required, Validators.maxLength(this._maxLength.Delegate.Comments), NoWhitespaceValidator.validateWhiteSpaces,Validators.pattern(REGEX_PATTERNS.description_comment)]),
      'respondentType': new FormControl('')
    });
  }
    requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.todaysdate = this.projectsService.getTodaysDate();
    this._maxLength = MAX_LENGTHS;
    this.filedformApproverDetails = [{ 'name': 'name', 'type': 'fdl','showEmailAsLabel':'no', 'label': 'User Name', 'isRequired': true, 'fdldata': this.respondentUsers2, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select Respondent.' } },
      { 'name': 'emails', 'type': 'text', 'isDisabled': 'yes', 'label': 'Email', "className": "col-md-12" }, { 'name': 'id', 'type': 'fdlid' }];

    this.fieldformApproverJson = {

      'json': {
        name: ['', this.formValidators["name"]],
        emails: '',
        id: '',



      }
    }
    this.initiateForm();



    if (this.type === "viewResponse") {
      this.Subscription = this.progressBarService.userId$.subscribe(userId => {
        if (userId != '-1') {
          // let assignedToId = this.projectsService.fetchIdForRequest(this.respondentUsers, this.delegateForm.value.userName)
          let data = {
            "responseIds": [this.delegateFormData.id],
            "assignedToId": userId,
            "comments": "assigning to myself" + this.delegateForm.value.userName
          }
          this.callDelegateService(data)
        }
      });
    }

    this.isAddNewUserButtonEnable = this.permissionService.isAddNewUserFromDelegateScreenAllowed();

  }

  selectUser(data: any) {
    if (data.item && data.item.id) {
      this.assignedToId = data.item.id;
      this.delegateForm.patchValue({ 'userEmail': data.item.email })
    }
  }

  formValidators = {
    email: [this.requiredIfValidator(() => this.isSurvey === true), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
    firstName: [this.requiredIfValidator(() => this.isSurvey === true), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
    lastName: [this.requiredIfValidator(() => this.isSurvey === true), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
    'name': [this.requiredIfValidator(() => this.isSurvey === false)]
    }
  updateSurveyResponder() {
    this.filedformSurveyDetails = [
      { 'name': 'email', 'type': 'email', 'label': 'Respondent Email', 'isRequired': true, "className": "col-md-8", "erroeMessage": { 'valid': this.regexValidationMessage.EMAIL_VALIDATION_MESSAGE  } },
      { 'name': 'firstName', 'type': 'text', 'label': 'Respondent First Name', 'isRequired': true, "className": "col-md-8", "erroeMessage": { 'valid': this.regexValidationMessage.USERNAME_VALIDATION_MESSAGE } },
      { 'name': 'lastName', 'type': 'text', 'label': 'Respondent Last Name', 'isRequired': true, "className": "col-md-8", "erroeMessage": { 'valid': this.regexValidationMessage.USERNAME_VALIDATION_MESSAGE } }]

    let responder = [];
    if (this.delegateFormData.respondents && this.delegateFormData.respondents.length > 0) {
      for (let k in this.delegateFormData.respondents) {
        let userObj = this.delegateFormData.respondents[k];

        if (userObj && userObj.email) {
          responder.push({
            email: userObj.email,
            firstName: userObj.firstName ? userObj.firstName : '' ,
            lastName: this.delegateFormData.respondents[k].lastName ? this.delegateFormData.respondents[k].lastName : '' })
        }
      }
    }

    this.fieldformSurveyJson = {
      "dataToEdit": responder,
      'json': {
        'email': ['', this.formValidators["email"]],
        'firstName': ['', this.formValidators["firstName"]],
        'lastName': ['', this.formValidators["lastName"]],

      }
    }
    this.isReady = true;
    if (this.addSurvey)
      this.addSurvey.resetForm();

  }


  getAllUsers() {
    this.filedformApproverDetails = [{ 'name': 'name', 'type': 'fdl', 'showEmailAsLabel': 'no', 'label': 'User Name', 'isRequired': true, 'fdldata': this.respondentUsers2, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select Respondent.' } },
    { 'name': 'emails', 'type': 'text', 'isDisabled': 'yes', 'label': 'Email', "className": "col-md-12" }, { 'name': 'id', 'type': 'fdlid' }]
    this.isSurvey = false;
    this.isReady = false;
    this.isUserLoaded = true;
    let usersByOrganization;
    let usersByClient
    let respondentApiArr = [this.formService.getUsersByOrganizationId(this.delegateFormData.organizationId)];
    if (this.delegateFormData.clientId && this.delegateFormData.clientId !== null)
      respondentApiArr.push(this.clientsService.getClientDetailsById(this.delegateFormData.clientId, "respondents"));

    forkJoin(respondentApiArr).subscribe(userData => {
      usersByOrganization = userData[0];
      usersByClient = userData[1];
      const allUsers = (usersByClient !== undefined) ? usersByOrganization.concat(usersByClient) : usersByOrganization;

      this.respondentUsers = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);
      this.respondentUsers2 = { 'data': this.respondentUsers };

      // Find the new user has been created from user page and add to the current respondents
      const tempRespondent = allUsers.find(user => user.id === this.delegateFormData.delegateRespondentId);
      if (tempRespondent) {
        tempRespondent.assignedTo = {
          name: tempRespondent.name,
          email: tempRespondent.email,
          assignedToId: tempRespondent.id
        };
        tempRespondent.assignedToId = tempRespondent.id;
        this.delegateFormData.respondents.push(tempRespondent);

      }
      this.reponderDataUpdate();



      this.isReady = true;
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }
  reponderDataUpdate() {
    let responder = [];
    if (this.delegateFormData.respondents && this.delegateFormData.respondents.length > 0) {
      for (let k in this.delegateFormData.respondents) {
        let userObj = this.projectsService.fetchDetailsForUserById(this.respondentUsers, this.delegateFormData.respondents[k].assignedToId);
        if (userObj != '-1')
          responder.push({ 'name': userObj.name, 'emails': userObj.email, id: this.delegateFormData.respondents[k].assignedToId })
        else if (this.delegateFormData.respondents[k].assignedTo) {
          responder.push({ 'name': this.delegateFormData.respondents[k].assignedTo.name, 'emails': this.delegateFormData.respondents[k].assignedTo.email, id: this.delegateFormData.respondents[k].assignedToId })

        }
      }
    }
    if (responder.length > 0) {
      this.fieldformApproverJson = {
        "dataToEdit": responder,
        'json': {
          name: ['', Validators.required],
          emails: '',
          id: ''
        }
      }
    }

    if (this.callFunction)
      this.callFunction.resetForm();

  }

  handleDelegateForm() {
    $('#delegateForms').modal('hide');
    this.isError = false;
    this.isClicked = false;
    this.isReady = false;
    this.isSubmitted = false;
    this.delegateFormData.delegateRespondentId = null;

   this.delegateForm.patchValue({ 'dueDate': this.getDueDate() });
  //  this.delegateForm.patchValue({ 'comments': '' });
    this.delegateForm.controls['comments'].reset();
    if (this.isSurvey) {
      //this.updateSurveyResponder();
      this.addSurvey.resetForm();
    } else {
      this.reponderDataUpdate();
      this.callFunction.resetForm();
    }
  }

  checkDate(dueDate) {
    if (dueDate.value < this.todaysdate) {
      this.delegateForm.controls['comments'].invalid;
      return false;
    }
    else {
      this.delegateForm.controls['comments'].valid;
      return true;
    }
  }

  clickDelegateForm() {
    
   // if (this.delegateForm.controls["respondentName"]['controls'].respondentName)
   // this.removeValidators(this.delegateForm.controls["respondentName"]['controls'].respondentName.controls[0], this.isSurvey);

    if (!this.delegateForm.valid || this.delegateForm.controls['dueDate'].value < this.todaysdate
            || (this.callFunction !== undefined && !this.callFunction.isInputValid())
      || (this.addSurvey !== undefined && !this.addSurvey.isInputValid())) {
      this.isClicked = true;
      if (this.callFunction) {
        this.callFunction.isErrorOn(true);
      }
      if (this.addSurvey) {
        this.addSurvey.isErrorOn(true);
      }
      return false;
    }
    else {
      this.isSubmitted = true;
     // let assignedToId = this.projectsService.fetchIdForRequest(this.respondentUsers, this.delegateForm.value.userName)
      if (this.mode == "reInitiate")
        if (this.isSurvey) {
          this.callUpdateResponder(this.delegateForm.value['guest']['guest']);
        }
        else {
          this.callResend(this.getResponderArray(this.delegateForm.value['respondentName']['respondentName']));
        }
      else {
        let respondentArray = this.getResponderArray(this.delegateForm.value['respondentName']['respondentName'])
        if (this.isSurvey) {
          this.callUpdateResponder(this.delegateForm.value['guest']['guest']);
        } else {
          this.callUpdateResponder(respondentArray);
        }
      }
    }
  }

  private callUpdateResponder(assignedToId){
    let data = {
      "respondents": assignedToId,

      "comments": this.delegateForm.get('comments').value,
      "dueDate": this.delegateForm.get('dueDate').value
    }
    this.callDelegateService(data)
  }
  private callResend(assignedToId) {


    let subscription;
    if (this.isSurvey) {
      let requestBody = {
        "surveyId ": this.delegateFormData.surveyId ,
        "dueDate": this.delegateForm.get('dueDate').value,
        "comments": this.delegateForm.get('comments').value,
        "respondents": assignedToId,
      };
      subscription = this.formService.resendSurvey(this.delegateFormData.surveyId, requestBody)
    } else {
      let requestBody = {
        "id": this.delegateFormData.id,
        "dueDate": this.delegateForm.get('dueDate').value,
        "comments": this.delegateForm.get('comments').value,
        "respondents": assignedToId,
      };
      subscription = this.formService.resendResponses(this.delegateFormData.id, requestBody)
    }

    subscription.subscribe(
        () => {
          this.isError = false;
          this.handleDelegateForm();
          this.delegatedSuccessMsg = `The form '${this.delegateFormData.title}' has been re-initiated.`
          this.successDelegate.emit({ delegateSuccessMsg: this.delegatedSuccessMsg });
        },
        (error) => { this.isError = true; this.errorMsg = error; this.isSubmitted = false;});
  }
  getResponderArray(responderTemp: any) {

    let responderUserId = [];
    for (let k in responderTemp) {
      if (responderTemp[k].id != '')
        responderUserId.push({

          "assignedToId": responderTemp[k].id,


        })
    }
    return responderUserId;

  }
  callDelegateService(data: any) {
    let subscription;
    if (!this.isSurvey) {
      subscription = this.formService.updateResponsedelegate(this.delegateFormData.id, data);
    } else if (this.delegateFormData.surveyId && this.delegateFormData.surveyId !== GUID_EMPTY) {
      subscription = this.formService.updateSurveyResponsedelegate(this.delegateFormData.surveyId, data)
    } else {
      // Create new surveys like data gathering
      const data = this.constructSurveys();
      subscription = this.formService.publishForms_nonuser_group(data);
    }

    subscription.subscribe(
      response => {
        this.isError = false;
        this.handleDelegateForm();
        if (this.mode == "reInitiate") {
          this.delegatedSuccessMsg = `The form '${this.delegateFormData.title}' has been re-initiated.`
        } else {
          this.delegatedSuccessMsg = `The form '${this.delegateFormData.title}' has been delegated.`
        }
        if (this.type === "viewProject" || this.type === "viewFormResponses") {
          this.successDelegate.emit({ delegateSuccessMsg: this.delegatedSuccessMsg });
        }
        else if (this.type === "viewResponse") {
          this._manageForm.setDelegateSuccessMsg(this.delegatedSuccessMsg);
          this._router.navigate(["response/list"]);
        }

      },
      error => { this.isError = true, this.errorMsg = error; this.isSubmitted = false; }
    )
  }

  getDueDate(){
    const todaysdate = this.projectsService.getTodaysDate();
    let result = todaysdate;
    if(this.delegateFormData && this.delegateFormData.dueDate){
      const duedate = this.delegateFormData.dueDate.split('T')[0];
      if (new Date(duedate).getTime() > new Date(todaysdate).getTime())
        result = duedate;
    }
    return result;
  }


/*********************Multiple Responder******************************/
  formInitializedDelegate(name: string, formSet: any, form: any) {
    this.delegateForm.setControl(name, form);
    // this.updateFormValidators(formSet);
    this.reEvaluateValidators(name, 0);

    // this.settingUnauthenticatedSurveyType( formSet);



  }


  validation(controlName: string, values: FormArray): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      //  return { 'duplicate': true };

      let array: any = [];

      if (values && values[controlName]) {

        for (let k in values[controlName]) {
          let keyName = '';
          let fieldValue = values[controlName][k];
          if (controlName == 'respondentName') {
            keyName = fieldValue.id;
          }
          else if (controlName == 'guest') {
            keyName = fieldValue.email;
            keyName=keyName.toLocaleLowerCase();
          }else {
            this.isDuplicate = false;
            return null;
          }


          if (fieldValue.name != '') {
           if (array[keyName])
              array[keyName].push({ "index": k });
            else {
              array[keyName] = [];
              array[keyName].push({ "index": k });
            }
          }
        }
      }
      for (let i in array) {
        if (array[i].length > 1) {
          this.isDuplicate = true;
          return { 'duplicate': true };
        }
      }
      this.isDuplicate = false;
      return null;
    }

  }

  /**********Removing validations on change of radio button selection **********/
  removeValidators(formGrup, fieldName) {
    for (const key in formGrup.controls) {
      if (key == 'name' && this.isSurvey) {

        formGrup.get(key).clearValidators();
        formGrup.get(key).updateValueAndValidity();
        const fieldCtrl = formGrup.controls[key];
        fieldCtrl.setValidators(this.formValidators[fieldName]);
      }

    }
  }


/********** For revaluation of the validations applied in formFields **********/
  reEvaluateValidators(fieldName, setNo, isNested = 'no') {
    if (isNested != 'no') {
      const fieldCtrlGroup = this.delegateForm.controls[isNested]['controls'][isNested]


      if (fieldCtrlGroup) {
        for (let k in fieldCtrlGroup.controls) {
          let fieldCtrl = fieldCtrlGroup.controls[k].controls[fieldName];
          fieldCtrl.setValidators(this.formValidators[fieldName]);
        }


      }


    } else {
      const fieldCtrl = this.delegateForm.controls[fieldName];

      fieldCtrl.setValidators(this.validation(fieldName, this.delegateForm.get(fieldName).value));

      fieldCtrl.updateValueAndValidity();
     // this.cdr.detectChanges();
    }
  }
  /*********************************************************************/

  public addNewUsers(event: any): void {
    if (event.action === 'addUser') {
      const params = {
        projectId :  this.delegateFormData.projectId,
        formId : this.delegateFormData.id,
        isDelegate: true,
        returnUrl: ''
      };
      this._router.navigate(['/admin/user/create'], {queryParams: params });
    }
  }

  selectedRespondentTypeChanged(value) {
    this.isSurvey = value === 'guest';
    if (this.isSurvey) {
      this.updateSurveyResponder();
    }
    else if (!this.isUserLoaded) {
      this.getAllUsers();
    }
  }

  constructSurveys(): any {
    const email = this._userService.getUserListData().email || -1;
      const guests = this.delegateForm.value.guest?.guest;
      const approvers = [];
      this.delegateFormData.approvers.forEach(approver => {
        approvers.push({
          approverId: approver.approverId,
          level: approver.level,
          email: approver.user.email
        });
    });
      const surveys = [{
        "name": this.delegateFormData.title,
        "description": this.delegateForm.value.comments,
        "formId": this.delegateFormData.formId,
        "dueDate": this._datePipe.transform(this.delegateForm.value.dueDate).replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T00:00:00Z",
        "recipients": guests || undefined,
        "type": "Anonymous",
        "approvers": approvers
      }];

      const data = {
        "projectId": this.delegateFormData.projectId,
        "clientId": this.delegateFormData.clientId || 0, // form response may not have client id
        "organizationId": this.delegateFormData.organizationId,
        "initiator": email,
        "fiservImplementationManager": email,
        "surveys": surveys,
        "recieverOnComplete": email
      }

      return data;
  }

}
