import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PageTemplateService } from '../../../services/page-template.service';
import { ICommitments } from '../../../shared/form';
import { IPager } from '../../../shared/pagination';
import { IQuery } from '../../../shared/query';
import * as $ from 'jquery';
import { ContentService } from '../../../services/content.service';
declare var $: any;
import Chart from 'chart.js/auto'
import { Router } from '@angular/router';
import { ProjectsService } from '../../../services/projects.service';
import { ExportToExcelService } from '../../../services/export-to-excel.service';
import { RiskReasons } from 'src/app/core/constants'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { IUser } from '../../../shared/user';
import { UserService } from '../../../services/user.service';
import { ROLES } from 'src/app/core/constants';
import { CommitmentReviewDTO } from './commitment-review-d-t-o';
import { IPageTemplate } from 'src/app/shared/page-template';

@Component({
  selector: 'app-commitment-dashboard',
  templateUrl: './commitment-dashboard.component.html',
  styleUrls: ['./commitment-dashboard.component.css']
})
export class CommitmentDashboardComponent implements OnInit {
  selectedState: string;
  public selectedStateForAutoTagged = '';
  public commitmentTypeSearchForAutoTagged = '';
  public commitmentStatusSearchForAutoTagged = '';

  headerColumns = ['Title', 'Type', 'State', 'Target Delivery Date', 'Status', 'Product'];
  keys = ['commitmentTitle', 'commitmentType', 'commitmentStatus', 'plannedCommittedDeliveryDate', 'isRisk', 'product'];
  public autoTaggedCommitmentsHeaderColumns = ['Title', 'Type', 'State', 'Target Delivery Date', 'Status', 'Product'];
  public autoTaggedCommitmentsKeys = ['commitmentTitle', 'commitmentType', 'commitmentStatus', 'plannedCommittedDeliveryDate', 'isRisk', 'product'];
  public autoTaggedCommitmentsPagerObject: IPager = {
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  };
  private isDeleteMethodCalledFromAutoTagged = false;
  public commitmentReviews: CommitmentReviewDTO[] = [];
  public queryParamForAutoTagged: IQuery = {};
  queryParam: IQuery;
  pagerObject: IPager = {
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  };
  clientId: number = 0;
  clntSuggestion: any = {};
  clientText: any = ''
  selectedFormFieldApiKey: string = '';
  formFieldSuggestion: any;
  commitmentTitles: any;
  autoTaggedCommitmentTitles: any;
  searchFilter: any = [];
  clientTypeSearch: any = 'duns';
  rows: any;
  autoTaggedCommitmentsRows: any;
  currentPageNumber: any = 1;
  sortingObject;
  filterActive: boolean = false;
  isSubmitted: boolean = false;
  options: any;
  drawerActive: boolean = false;
  commitmentsProduct: boolean = false;
  detailsCommitment: any;
  stillLoading: boolean = true;
  content: any;
  summary: any = {};
  slaDashActive: boolean = true;
  products: any = '';
  commitmentType: any;
  autoTaggedCommitmentTypes: any[];
  commitmentTypeSearch: any;
  productsOption: any;
  commitmentStatusSearch: any = "";
  commitmentStats: any = { 'Active': 0, 'Canceled': 0, "Completed": 0, "On Hold": 0, "Total": 0 }
  isSla: boolean = false;
  slaProductSearch: any;
  selectedSlaProduct: any = '';
  slaData: any[] = [];
  monthOfData: number = 12;
  counter: number;
  monthlyData: any = [];
  monthlyPData: any = [];
  productsd: any;
  dataCommitmentCalendarError: boolean = false;
  isPageLoad: boolean = true;
  slaPercentage: any;
  labels: any[];
  chart: any;
  chart2: any;
  showGraph: boolean = true;
  isRowsLoading: boolean = true;
  isProductsLoading: boolean = true;
  autoTaggedCommitmentsIsRowsLoading: boolean = true;
  commitmentProducts: any;
  isError: boolean;
  errorMsg: any;
  reasonForm: FormGroup;
  paramsCal = { dateRangeType: '', startDate: '', endDate: '', FormFields: '', searchClientBy: 'duns' }
  formValidators = {
    reason: [
      requiredIfValidator(
        () => this.reasonForm.get('isRisk').value == true
      ),
    ],
    description: [
      requiredIfValidator(
        () => this.reasonForm.get('reason').value == 'other'
      ),
    ]
  }
  previousSelected: any;
  LastLoginTimeStamp: string;
  typeOfCommitmentCalander: any = 'qtr';
  year: any = [2015, 2016, 2017, 2018, 2019, '2020', '2021', '2022', 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  fromYear: any = new Date().getFullYear() - 5;
  toYear: any = new Date().getFullYear() + 5;
  month: any = new Date().getMonth();
  fromMonth: any = '';
  toMonth: any = '';
  commitmentCalendarLabel: any = [];
  commitmentCalendarNumbers: any = [];
  commitmentCalendarData: any = [];
  commitmentCalendar: boolean = false;
  filterActivePage: boolean = false;
  filterPage: any = [];
  isRm: boolean = false;
  isClient: boolean = false;
  isAdmin: boolean = false;
  LOB: any = [];
  BU: any = [];
  commitmentStatuses: any = [];
  autoTaggedCommitmentStatuses: any = [];
  selectedLOB: any = '';
  selectedBU: any = '';
  commitmentStatsCount: any;
  applyFilter: boolean = false;
  pagerObjectProduct: IPager = {
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  };
  pageSizeProduct: any = 7;
  ngShows: boolean = false;
  pageProducts: any = [];
  isClientExecutive: boolean = false;
  cBProductsError: boolean = false;
  commitmentsError: boolean = false;

  clientCommitmentId: number = 0;
  pageTemplate: IPageTemplate;
  pageTemplateAdmin: boolean = false;
  userData: any;
  isRestrictedAdmin: boolean = false;
  isCCTAdmin: boolean = false;
  isAutoTaggedAdvanceSearched: boolean = false;
  isPdfExport: boolean = false;
  exportedCommitments: any[];

  constructor(private projectService: ProjectsService,
    private pageTemplateService: PageTemplateService,
    private contentService: ContentService,
    private router: Router,
    private excelService: ExportToExcelService,
    private fb: FormBuilder,
    private userService: UserService) {
  }

  slaActiveTab(value: boolean) {
    this.slaDashActive = value;
  }

  cancelSearch(type: any = '') {
    this.currentPageNumber = 1;
    this.queryParam.pageNumber = this.currentPageNumber;
    if (type == 'pageFilters') {
      this.getDataDateWise();
      this.filterPage = [];
      this.getMonth();
    }
    if (type == 'main') {
      this.selectedLOB = '';
      this.products = '';
      this.fromMonth = '';
      this.toMonth = '';
      delete this.paramsCal['startDate'];
      delete this.paramsCal['endDate'];
      this.getDataDateWise();
      return;
    }
    if (type != 'All')
      delete this.searchFilter[type];
    else {
      //  delete this.searchFilter['Product'];
      delete this.searchFilter['commitmentType'];
      delete this.searchFilter['commitmentStatus'];
      this.commitmentStatusSearch = '';
      this.commitmentTypeSearch = '';
      this.selectedState = '';
      delete this.searchFilter['CommitmentTitle'];
      //  this.products = '';
    }
    if (type == 'Product') {
      this.products = '';
    }
    else if (type == 'commitmentType') {
      this.commitmentTypeSearch = '';
    }
    else {
      this.commitmentStatusSearch = '';
    }

    this.initateFilterSearch();
    this.getSuggestions(this.queryParam);
  }

  getPageTemplateData() {
    this.pageTemplateService.getPageTemplateById(this.clientCommitmentId)
      .pipe(take(1))
      .subscribe(
        page => {
          this.pageTemplate = page as IPageTemplate;
          this.pageTemplateAdmin = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id);
        },
        (error) => { this.errorMsg = error; });
  }

  advanceSearch() {
    delete this.searchFilter['Product'];
    delete this.searchFilter['commitmentType'];
    delete this.searchFilter['commitmentStatus'];
    if (this.commitmentTypeSearch && this.commitmentTypeSearch != '') {
      this.searchFilter['commitmentType'] = this.commitmentTypeSearch;
    } if (this.commitmentStatusSearch && this.commitmentStatusSearch != '') {
      this.searchFilter['commitmentStatus'] = this.commitmentStatusSearch;
    }
    //if (this.products && this.products != '') {
    //  this.searchFilter['Product'] = this.products;
    //}
    this.filterActive = !this.filterActive;
    this.initateFilterSearch();
    this.getSuggestions(this.queryParam);
  }

  public applySortingForAutoTagged(event): void {
    if (event) {
      this.queryParamForAutoTagged.orderBy = (event.isAsc) ? event.key : event.key + ' desc';
      this.advanceSearchForAutoTagged();
    }
  }

  public changePageForAutoTagged(page): void {
    this.queryParamForAutoTagged.pageNumber = page;
    this.advanceSearchForAutoTagged();
  }

  public cancelSearchForAutoTagged(): void {
    this.selectedStateForAutoTagged = '';
    this.commitmentTypeSearchForAutoTagged = '';
    this.commitmentStatusSearchForAutoTagged = '';
    this.advanceSearchForAutoTagged();
    this.getAutoTaggedSuggestions(this.queryParamForAutoTagged);
  }

  public advanceSearchForAutoTagged(isInAdvancedSearch: boolean = false): void {
    this.isAutoTaggedAdvanceSearched = isInAdvancedSearch;
    const formFields = [];
    // for (let k in this.searchFilter) {
    //   if (this.searchFilter[k] != '') {
    //     formFields.push({ 'apiKey': k, 'apiKeyValue': this.searchFilter[k] });
    //   }
    // }
    if (this.selectedStateForAutoTagged !== '') {
      formFields.push({ 'apiKey': 'CommitmentTitle', 'apiKeyValue': this.selectedStateForAutoTagged });
    }
    if (this.commitmentTypeSearchForAutoTagged !== '') {
      formFields.push({ 'apiKey': 'CommitmentType', 'apiKeyValue': this.commitmentTypeSearchForAutoTagged });
    }
    if (this.commitmentStatusSearchForAutoTagged !== '') {
      formFields.push({ 'apiKey': 'CommitmentStatus', 'apiKeyValue': this.commitmentStatusSearchForAutoTagged });
    }
    // if (this.queryParam['StartDate']) {
    //   this.queryParamForAutoTagged['StartDate'] = this.queryParam['StartDate'];
    // } else {
    //   delete this.queryParamForAutoTagged['StartDate'];
    // }
    // if (this.queryParam['EndDate']) {
    //   this.queryParamForAutoTagged['EndDate'] = this.queryParam['EndDate'];
    // } else {
    //   delete this.queryParamForAutoTagged['EndDate'];
    // }
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'false' });
    this.queryParamForAutoTagged.FormFields = JSON.stringify(formFields);

    this.autoTaggedCommitmentsIsRowsLoading = true;
    this.pageTemplateService.getclientdashboardCommitments(this.clientId, this.queryParamForAutoTagged).pipe(take(1))
      .subscribe(response => {
        const temp = response.body as ICommitments;
        this.autoTaggedCommitmentsRows = temp.commitments.filter((data) => {
          if (data.commitmentStatus == 'Completed' || data.commitmentStatus == 'Canceled') {
            data.isRisk = 'blank';
          }
          return true;
        });
        if (response.headers.get('X-pagination')) {
          this.autoTaggedCommitmentsPagerObject = Object.assign({}, JSON.parse(response.headers.get('X-pagination')) as IPager);
        }
        this.autoTaggedCommitmentsIsRowsLoading = false;
      },
        (error) => {
          this.commitmentsError = true;
        }
      );

    this.getAutoTaggedSuggestions(this.queryParamForAutoTagged);
  }

  getMonth(monthToAdd = 10) {
    let month = new Date().getMonth() + 1;
    let m = '';
    if (month < 10)
      m = '0' + month;
    else
      m = month as unknown as string;
    // this.fromMonth = new Date().getFullYear() + '-' + m;
    // this.toMonth = new Date().getFullYear() + monthToAdd + '-' + m;
    return m;
  }
  clientTypeChange() {
    this.clientId = -1;
    this.initData();
    this.clientText = '';

    this.getRmClients();
  }

  public onReviewCommitmentModalSubmit(): void {
    this.autoTaggedCommitmentsRows = [];
    this.autoTaggedCommitmentsPagerObject = {
      totalCount: 0,
      pageSize: 0,
      currentPage: 0,
      totalPages: 0
    };
    this.getDataDateWise(true);
  }

  getDataDateWise(load = false) {
    $('.nav-tabs a[href="#tab13"]').tab('show');
    const monthNamesFilter = { "01": "Jan", "02": "Feb", "03": "Mar", "04": "Apr", "05": "May", "06": "Jun", "07": "Jul", "08": "Aug", "09": "Sep", "10": "Oct", "11": "Nov", "12": "Dec" };
    let fromDate: any = '';
    let toDate: any = '';
    this.currentPageNumber = 1;
    this.queryParam.pageNumber = this.currentPageNumber;
    if (this.fromMonth != '') {
      let getYearAndMonth = this.fromMonth.split('-');
      // let tempDate = new Date(getYearAndMonth[0], getYearAndMonth[1], 1)
      // fromDate = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
      fromDate = Number(getYearAndMonth[0]) + '-' + Number(getYearAndMonth[1]) + '-1';
    }
    if (this.toMonth != '') {
      let toDateYearAndMonth = this.toMonth.split('-');
      let tempToDate = new Date(toDateYearAndMonth[0], toDateYearAndMonth[1], 0)
      toDate = tempToDate.getFullYear() + '-' + (tempToDate.getMonth() + 1) + '-' + tempToDate.getDate();
    } if (!load) {
      this.applyFilter = false;
      delete this.queryParam['StartDate'];
      delete this.queryParam['EndDate'];
      delete this.queryParam['bu'];
      delete this.queryParam['lob'];
      delete this.queryParam['product'];
      this.filterPage = [];
      toDate = '';
      fromDate = '';
    }
    else {
      this.queryParam['StartDate'] = fromDate;
      this.queryParam['EndDate'] = toDate;
      this.filterPage = [];
      if (this.selectedLOB != '')
        this.filterPage['lob'] = this.selectedLOB;
      if (this.products != '')
        this.filterPage['product'] = this.products;
      this.applyFilter = true;
    }
    this.initateFilterSearch();

    this.getSuggestions(this.queryParam);
    this.calanderCommitment(this.typeOfCommitmentCalander, fromDate, toDate);
    // this.clientCommitments();
    this.clientCommitmentSummaray();
    this.commitmentsbyProduct();
    this.filterActivePage = false;

    this.getCommitmentReviewsByClientId();
  }

  private getCommitmentReviewsByClientId(): void {
    this.commitmentReviews = [];

    this.pageTemplateService.getCommitmentReviewsByClientId(this.clientId).subscribe(result => {
      this.commitmentReviews = result.body as CommitmentReviewDTO[];
    });
  }

  openDrawer() {
    this.reasonForm = this.fb.group({
      reason: new FormControl(this.detailsCommitment.riskReason || '', [requiredIfValidator(() => this.reasonForm.get('isRisk').value == true)]),
      description: new FormControl(this.detailsCommitment.riskComments || '', [requiredIfValidator(() => this.reasonForm.get('reason').value.toLowerCase() === "other")]),
      isRisk: [this.detailsCommitment.isRisk == 'true' ? true : false],
    });
    this.drawerActive = true;

    window.scroll(0, 0);

    setTimeout(function () {
      $('html, body').addClass('overflow-hidden');
      $('#containerPage').addClass('overflow-hidden');
    }, 0);
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
      $('#commitmentTypeTooltip').tooltip({ placement: 'auto', title: '<p class="typeTooltip">1. Base/New Product Feature - Commitment to deliver Standard Product offering or New product feature</p><p class="typeTooltip">2. Custom Feature and Enhancement - Commitment to deliver custom functionality</p><p class="typeTooltip"> 3. Implementation - Commitment to configure, implement the product offerings</p><p class="typeTooltip">4. Advisory - Commitment to provide advisory or consultation services</p><p class="typeTooltip">   5. Staff Augmentation - Commitment to provide required staffing</p>', html: true });
    });
  }

  openFilter() {
    this.filterActive = !this.filterActive;
  }

  closeDrawer() {
    this.detailsCommitment = [];
    this.drawerActive = false;
    setTimeout(function () {
      $('html, body').removeClass('overflow-hidden');
      $('#containerPage').removeClass('overflow-hidden');
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
      window.scroll(0, document.body.scrollHeight);
    }, 0);
  }

  public openDeleteModal(): void {
    $('#deleteWarningModal').show();
    $("#deleteWarningModal").addClass('show');
    $('body').addClass('modal-open');
  }

  public deletePageResponse(): void {
    this.pageTemplateService.deletePageResponse(this.detailsCommitment.responseId).subscribe(() => {
      if (this.isDeleteMethodCalledFromAutoTagged) {
        this.advanceSearchForAutoTagged(true);
      } else {
        this.getDataDateWise(true);
      }
      this.closeDrawer();
    });
  }

  navigate(event: any, mode: boolean) {
    this.isDeleteMethodCalledFromAutoTagged = mode;
    this.detailsCommitment = event.data;
    this.openDrawer();
  }

  initQueryParams(filter = '') {
    let formFields = [];
    if (filter != '') {
      formFields = JSON.parse(this.queryParam.FormFields)
    }
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize,
      FormFields: JSON.stringify(formFields),
      searchClientBy: this.clientTypeSearch
    });

    this.queryParamForAutoTagged = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize,
    });
  }

  getSuggestions(queryParams: IQuery = Object.assign({}, {})) {
    const queryParam: IQuery = Object.assign({}, queryParams);
    const suggestionFields = [
      { 'apiKey': 'Product', 'apiKeyValue': '' },
      { 'apiKey': 'CommitmentTitle', 'apiKeyValue': '' },
      { 'apiKey': 'commitmentType', 'apiKeyValue': '' },
      { 'apiKey': 'LOB', 'apiKeyValue': '' },
      { 'apiKey': 'CommitmentStatus', 'apiKeyValue': '' }
    ];
    if (this.clientId === -1) {
      this.commitmentTitles = [];
      this.productsOption = [];
      this.commitmentType = [];
      this.LOB = [];
      this.BU = [];
      return;
    }

    queryParam['searchClientBy'] = this.clientTypeSearch;
    this.pageTemplateService.getclientdashboardSuggestion(this.clientId, suggestionFields, queryParam).subscribe(
      data => {
        this.commitmentTitles = [];
        this.productsOption = [];
        this.commitmentType = [];
        this.commitmentStatuses = [];
        this.LOB = [];
        this.BU = [];

        this.formFieldSuggestion = data;
        if (!data) {
          return;
        }

        const commitMentTypes = [];
        for (const i in data) {
          if (!commitMentTypes[data[i].apiKey]) {
            commitMentTypes[data[i].apiKey] = [];
          }
          for (const k in data[i].apiKeyValues) {
            if (data[i].apiKeyValues[k] != '') {
              commitMentTypes[data[i].apiKey].push({ 'key': k, 'title': data[i].apiKeyValues[k] });
            }
          }
        }
        for (const k in commitMentTypes) {
          switch (k) {
            case 'CommitmentTitle':
              this.commitmentTitles = commitMentTypes[k];
              break;
            case 'Product':
              this.productsOption = commitMentTypes[k];
              break;
            case 'commitmentType':
              this.commitmentType = commitMentTypes[k];
              break;
            case 'LOB':
              this.LOB = commitMentTypes[k];
              break;
            case 'CommitmentStatus':
              if (commitMentTypes[k]) {
                const orderedStates = [
                  "In Progress",
                  "On Hold",
                  "Completed",
                  "Canceled",
                ];

                for (const state of orderedStates) {
                  const currentStatus = commitMentTypes[k].find(x => x.title === state);
                  if (currentStatus) {
                    this.commitmentStatuses.push(currentStatus);
                  }
                }
              }
              else {
                this.commitmentStatuses = [];
              }
              break;
          }
        }
      },
      (error) => { }
    );
  }

  getAutoTaggedSuggestions(queryParams: IQuery = Object.assign({}, {})) {
    const queryParam: IQuery = Object.assign({}, queryParams);
    const suggestionFields = [
      { 'apiKey': 'CommitmentTitle', 'apiKeyValue': '' },
      { 'apiKey': 'commitmentType', 'apiKeyValue': '' },
      { 'apiKey': 'CommitmentStatus', 'apiKeyValue': '' }
    ];
    if (this.clientId === -1) {
      this.autoTaggedCommitmentTitles = [];
      return;
    }
    queryParam['searchClientBy'] = this.clientTypeSearch;
    this.pageTemplateService.getclientdashboardSuggestion(this.clientId, suggestionFields, queryParam).subscribe(
      data => {
        this.autoTaggedCommitmentTitles = [];
        this.autoTaggedCommitmentTypes = [];
        this.autoTaggedCommitmentStatuses = [];
        if (!data) {
          return;
        }

        const commitMentTypes = [];
        for (const i in data) {
          if (!commitMentTypes[data[i].apiKey]) {
            commitMentTypes[data[i].apiKey] = [];
          }
          for (const k in data[i].apiKeyValues) {
            if (data[i].apiKeyValues[k] != '') {
              commitMentTypes[data[i].apiKey].push({ 'key': k, 'title': data[i].apiKeyValues[k] });
            }
          }
        }
        for (const k in commitMentTypes) {
          switch (k) {
            case 'CommitmentTitle':
              this.autoTaggedCommitmentTitles = commitMentTypes[k];
              break;
            case 'commitmentType':
              this.autoTaggedCommitmentTypes = commitMentTypes[k];
              break;
            case 'CommitmentStatus':
              if (commitMentTypes[k]) {
                const orderedStates = [
                  "In Progress",
                  "On Hold",
                  "Completed",
                  "Canceled",
                ];

                for (const state of orderedStates) {
                  const currentStatus = commitMentTypes[k].find(x => x.title === state);
                  if (currentStatus) {
                    this.autoTaggedCommitmentStatuses.push(currentStatus);
                  }
                }
              }
              else {
                this.autoTaggedCommitmentStatuses = [];
              }
              break;
          }
        }
      },
      (error) => { }
    );
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.clientCommitments();
  }

  slaGraphChange() {
    //this.overlayRef = this.overlay.create({
    //  positionStrategy: this.overlay
    //    .position()
    //    .global()
    //    .centerHorizontally()
    //    .centerVertically(),
    //  hasBackdrop: true,
    //});
    //this.overlayRef.attach(new ComponentPortal(LoaderComponent));

    this.showGraph = false;
    let productValue = this.selectedSlaProduct;
    if (productValue == '')
      this.createChart(this.labels, this.monthlyData, this.monthlyPData, 'all')
    else {
      let monthlyData = this.slaData[productValue].Data;
      let monthlyPData = this.slaData[productValue].pData;
      this.createChart(this.labels, monthlyData, monthlyPData, productValue)
    }
    this.showGraph = true;
  }

  clientCommitmentSummaray() {
    this.stillLoading = true;
    let queryParam: IQuery = Object.assign({}, {
    });
    if (this.queryParam['StartDate']) {
      queryParam['StartDate'] = this.queryParam['StartDate'];

    }
    if (this.queryParam['EndDate']) {
      queryParam['EndDate'] = this.queryParam['EndDate'];
    }
    queryParam['searchClientBy'] = this.clientTypeSearch;
    let formFields = [];
    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] })
    }
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    queryParam.FormFields = JSON.stringify(formFields);
    queryParam['searchClientBy'] = this.clientTypeSearch;
    this.pageTemplateService.getclientdashboardSummary(this.clientId, queryParam).subscribe(
      data => {
        this.commitmentStats = { 'In Progress': 0, 'Canceled': 0, "Completed": 0, "On Hold": 0, "Total": 0 }
        this.commitmentStatsCount = { 'In Progress': 0, 'Canceled': 0, "Completed": 0, "On Hold": 0, "Total": 0 };
        this.stillLoading = false;
        this.summary = data;
        if (this.summary && this.summary.website && this.summary.website != null) {
          if (this.summary.website.indexOf('https://') == '-1') {
            this.summary.website = 'https://' + this.summary.website;
          }
        }
        if (!this.summary)
          return
        if (this.summary && this.summary.relationshipManagerName) {
          let initials = this.summary.relationshipManagerName.split(' ');
          this.summary.rmIn = initials[0][0];
          if (initials[1] && initials[1][0])
            this.summary.rmIn = initials[0][0] + initials[1][0];
        }

        if (this.summary && this.summary.salesExecutiveName) {
          let seinitials = this.summary.salesExecutiveName.split(' ');
          this.summary.seIn = seinitials[0][0];
          if (seinitials[1] && seinitials[1][0])
            this.summary.seIn = seinitials[0][0] + seinitials[1][0];
        }

        this.commitmentStats['Total'] = this.summary.commitmentStatuses[0].commitmentCount + this.summary.commitmentStatuses[1].commitmentCount + this.summary.commitmentStatuses[2].commitmentCount + this.summary.commitmentStatuses[3].commitmentCount;

        for (let k in this.summary.commitmentStatuses) {
          if (this.summary.commitmentStatuses[k].commitmentCount > 0)
            this.commitmentStats[this.summary.commitmentStatuses[k].commitmentStatus] = (this.summary.commitmentStatuses[k].commitmentCount / this.commitmentStats['Total']) * 100
          //          this.commitmentStats['Total'] = this.commitmentStats['Total']+this.summary.commitmentStatuses[k].commitmentCount
          this.commitmentStatsCount[this.summary.commitmentStatuses[k].commitmentStatus] = (this.summary.commitmentStatuses[k].commitmentCount);
          this.commitmentStats[this.summary.commitmentStatuses[k].commitmentStatus] = parseFloat(this.commitmentStats[this.summary.commitmentStatuses[k].commitmentStatus]).toFixed(2);
        }

        if (this.summary.slmData) {
          this.slaProductSearch = [];
          this.slaData = [];
          this.monthlyData = [];
          this.isSla = true;
          for (let k in this.summary.slmData) {
            let data = this.summary.slmData[k]
            if (!this.slaData[data['slmSLAName']]) {
              this.slaData[data['slmSLAName']] = [];
              this.slaProductSearch.push({ 'key': k, 'title': data['slmSLAName'] });
              if (!this.slaData[data['slmSLAName']]['p'])
                this.slaData[data['slmSLAName']]['p'] = []
            }
            if (this.slaData[data['slmSLAName']] && !this.slaData[data['slmSLAName']][data['slmYear']]) {
              this.slaData[data['slmSLAName']][data['slmYear']] = [];
              this.slaData[data['slmSLAName']]['p'][data['slmYear']] = [];
            }
            this.slaData[data['slmSLAName']][data['slmYear']][data['slmMonth']] = data['slmSLAGoal']
            this.slaData[data['slmSLAName']]['p'][data['slmYear']][data['slmMonth']] = data['slmFinalPerformanceScore']
          }
        }
        this.stillLoading = false;
        this.graphInit();
      },
      (error) => { }
    );
  }

  clientCommitments() {
    this.isRowsLoading = true;
    this.commitmentsError = false;
    this.pageTemplateService.getclientdashboardCommitments(this.clientId, this.queryParam).pipe(take(1)).subscribe(
      response => {
        this.formFieldSuggestion = response.body as ICommitments;
        this.rows = this.formFieldSuggestion.commitments.filter((data) => {
          if (data.commitmentStatus == 'Completed' || data.commitmentStatus == 'Canceled') {
            data.isRisk = 'blank';
          }
          return true;
        });
        if (response.headers.get('X-pagination')) {
          this.pagerObject = Object.assign({}, JSON.parse(response.headers.get('X-pagination')) as IPager);
        }
        this.isRowsLoading = false;
      },
      (error) => {
        this.commitmentsError = true;
      }
    );
  }

  graphInit() {
    var date = new Date();
    let lastYearDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.labels = [];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    this.monthlyPData = [];
    this.monthlyData = [];
    this.counter = 0;
    let lastYearMonth = lastYearDate.setMonth(lastYearDate.getMonth() - 12);
    for (let i = 0; i < 13; i++) {
      this.labels.push(monthNames[lastYearDate.getMonth()] + '-' + lastYearDate.getFullYear())
      for (let k in this.slaData) {
        if (!this.slaData[k].Data) {
          this.slaData[k].Data = [];
          this.slaData[k].pData = [];
        }
        let data_to_push = 0;
        let performance_data_to_push = 0;
        let slaCat = this.slaData[k];
        let slaPerCat = this.slaData[k]['p']
        if (slaCat[lastYearDate.getFullYear()] && slaCat[lastYearDate.getFullYear()][lastYearDate.getMonth()]) {
          data_to_push = parseInt(slaCat[lastYearDate.getFullYear()][lastYearDate.getMonth()]);
          this.counter = this.counter + 1;
        }
        let index = lastYearDate.getFullYear() + '.' + lastYearDate.getMonth();
        if (!this.monthlyData[index])
          this.monthlyData[index] = 0;

        this.monthlyData[index] = this.monthlyData[index] + data_to_push;
        this.slaData[k].Data.push(data_to_push)
        if (slaPerCat[lastYearDate.getFullYear()] && slaPerCat[lastYearDate.getFullYear()][lastYearDate.getMonth()])
          performance_data_to_push = parseInt(slaPerCat[lastYearDate.getFullYear()][lastYearDate.getMonth()]);

        if (!this.monthlyPData[index])
          this.monthlyPData[index] = 0;

        this.monthlyPData[index] = parseInt(this.monthlyPData[index]) + performance_data_to_push;

        if (slaPerCat[lastYearDate.getFullYear()] && slaPerCat[lastYearDate.getFullYear()][lastYearDate.getMonth()])
          this.slaData[k].pData.push(performance_data_to_push)
      }
      lastYearDate.setMonth(lastYearDate.getMonth() + 1);
    }
    this.createChart(this.labels, this.monthlyData, this.monthlyPData, 'all')
  }

  createChart(labels: any, monthArray: any = this.monthlyData, monthlyPdata: any = this.monthlyPData, performance: any = 'all') {
    let dataMonthArray = [];
    let label = ''
    for (let k in monthArray) {
      if (performance == 'all') {
        let monthCount: number = monthArray[k] as unknown as number;
        dataMonthArray.push((monthCount / this.slaProductSearch.length).toFixed(2));
      } else {
        dataMonthArray.push(monthArray[k]);
      }
    }
    let count: number = 0;
    let total: number = 0;
    for (let k in monthlyPdata) {
      count = count + 1;
      total = total + parseInt(monthlyPdata[k]);
    }
    if (performance == 'all') {
      count = this.counter;
      label = 'All';
    } else {
      label = performance
    }
    let slaPercentage: any;
    slaPercentage = total / count;
    this.slaPercentage = parseFloat(slaPercentage).toFixed(2)

    let data: any,
      options: any,
      ctx: any = document.getElementById('areaChart') as HTMLElement;

    // JSON:
    // Uncomment below and import * as data from 'json-path.json'.
    // Or Angular 14, create anonymous JSON array and fetch with http
    // constructor(private _http; HttpClient) {} ...
    // Replace datasets with dataArray

    // for (let key in chartData.items) {
    //   if (chartData.items.hasOwnProperty(key)) {
    //     this.dataArray.push(chartData.items[key]);
    //   }
    // }

    data = {
      labels: labels,
      datasets: [{
        label: '', // Name the series
        data: dataMonthArray, // Specify the data values array
        fill: true,
        borderColor: '#2196f3', // Add custom color border (Line)
        backgroundColor: 'orange', // Add custom color background (Points and Fill)
        borderWidth: 1 // Specify bar border width
      },
      ]
    };

    options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          onClick: function (e) {

            e.stopPropagation();
          },
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'white',
            fontSize: 0,
          },
        },
        animation: {
          duration: 0
        }
      },
    };
    if (this.chart) this.chart.destroy();

    this.chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $(".cct-table").tooltip({ selector: '[data-toggle=tooltip]' });
      $('reviewCommitmentsBtn').tooltip({
        selector: "[data-tooltip=tooltip]",
        container: "body"
      });
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.options = Object.values(RiskReasons).filter(value => typeof value !== 'number');
    this.clientId = 0;
    const user = this.userService.getUserListData();
    if (!user) {
      this.userService.callUserListData().subscribe((data) => {
        this.setUserData(data);
        this.initData();
      });
    }
    else {
      this.setUserData(user);
      this.initData();
    }

    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    // if (!this.isClientExecutive && !this.keys.includes('reviewedDate')) {
    //   this.keys.push('reviewedDate');
    //   this.headerColumns.push('Reviewed Date');
    // } else {
    //   this.keys = this.keys.filter(r => r !== 'reviewedDate');
    //   this.headerColumns = this.headerColumns.filter(r => r !== 'Reviewed Date');
    // }

    if (environment.env === 'prod') {
      this.clientCommitmentId = 8;
    }
    else if (environment.env === 'app') {
      this.clientCommitmentId = 299;
    }
    else if (environment.env === 'dev') {
      this.clientCommitmentId = 14;
    }
    else if (environment.env === 'qa') {
      this.clientCommitmentId = 12;
    }
    this.getPageTemplateData();
  }

  private setUserData(user: IUser) {
    this.userData = user;
    this.getLoginTimestamp(user);
    if (user.type.toLowerCase() === 'client') {
      this.clientId = user.organization;
      this.clientText = user.organizationTitle;
      this.isClient = true;
    }
    this.isAdmin = false;
    this.isCCTAdmin = false;
    this.isRm = false;
    this.isClientExecutive = false;
    for (let p in user.roles) {
      const role = user.roles[p].roleTitle.toLowerCase();
      switch (role) {
        case 'admin':
          this.isAdmin = true;
          break;
        case 'cct admin':
          this.isCCTAdmin = true;
          break;
        case 'relationship manager':
          this.isRm = true;
          break;
        case ROLES.Client_Executive.toLowerCase():
          this.isClientExecutive = true;
          break;
        default:
          break;
      }
    }
  }

  initData() {
    this.filterPage = '';
    this.slaActiveTab(true);
    delete this.paramsCal['startDate'];
    delete this.paramsCal['endDate'];
    this.commitmentTitles = [];
    this.autoTaggedCommitmentTitles = [];
    delete this.searchFilter['commitmentType'];
    delete this.searchFilter['commitmentStatus'];
    this.commitmentStatusSearch = '';
    this.commitmentTypeSearch = '';
    this.selectedState = '';
    delete this.searchFilter['CommitmentTitle'];
    this.selectedLOB = '';
    this.products = '';
    this.toMonth = '';
    this.fromMonth = '';
    this.initQueryParams();
    this.getMonth();
    const formFields = [{ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' }];
    this.getSuggestions({ formFields: JSON.stringify(formFields) });
    this.getDataDateWise(false);
    this.getRmClients();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      // this.queryParam.pageNumber = 1;
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.clientCommitments();
  }

  search() {
    if (this.selectedState && this.selectedState != '') {
      this.previousSelected = this.selectedState;
      this.searchFilter['CommitmentTitle'] = this.selectedState;
      //  this.initateFilterSearch();
    }
    else if (this.previousSelected && this.previousSelected != '') {
      delete this.searchFilter['CommitmentTitle'];
      //  this.initateFilterSearch();
    }
  }

  initateFilterSearch() {
    let formFields: any = [];
    //this.initQueryParams();
    for (let k in this.searchFilter) {
      if (this.searchFilter[k] != '') {
        formFields.push({ 'apiKey': k, 'apiKeyValue': this.searchFilter[k] })
      }
    }

    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] })
    }

    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    this.queryParam.FormFields = JSON.stringify(formFields);

    this.clientCommitments();
  }

  getRmClients() {
    let suggestionSubscriber: any;
    let queryParam: IQuery = Object.assign({}, {});

    queryParam['searchClientBy'] = this.clientTypeSearch;
    if (this.isAdmin || this.isCCTAdmin)
      suggestionSubscriber = this.pageTemplateService.getClientSuggestionsAdmin(queryParam);
    else if (this.isRm)
      suggestionSubscriber = this.pageTemplateService.getClientSuggestionsRm(queryParam);
    else
      suggestionSubscriber = this.pageTemplateService.getClientSuggestionsRm(queryParam);

    suggestionSubscriber.subscribe(
      data => {
        this.clntSuggestion = data;
      },
      error => { }
    );
  }

  typeaheadLoadingClients(searchValue: string) {
    return;
    let suggestionSubscriber: any;
    let params = [];
    if (searchValue && searchValue.length >= 3) {
      params.push({ paramName: "title", value: searchValue });
      suggestionSubscriber = this.projectService.getClientSuggestionsWithMDM("clients", params, 100);
    }
    suggestionSubscriber.subscribe(
      data => {
        this.clntSuggestion = data;
      },
      error => { }
    );
  }

  clientSelected() {
    let foundObj: any = this.projectService.fetchIdForClient(this.clntSuggestion, this.clientText);
    if (foundObj != -1) {
      if (this.clientTypeSearch == 'duns')
        this.clientId = foundObj.id;
      else
        this.clientId = foundObj.gud;
      this.checkRestrictedAdmin();
      this.initData();
      if (!this.isClientExecutive) {
        this.advanceSearchForAutoTagged();
      }
    } else {
      this.clientId = -1;
      this.initData();
    }
  }

  checkRestrictedAdmin() {
    if (this.userData.isRestrictedAdmin === true) {
      let isExists: boolean = false;
      let sortOrder = ['Read', 'Write'];
      let groups = this.userData.groups.sort((a, b) => { return sortOrder.indexOf(a.accessLevel) - sortOrder.indexOf(b.accessLevel); });

      for (let g in groups) {
        isExists = false;

        if (this.clientId > 0) {
          if (groups[g].clients.some(clnt => clnt.clientId === this.clientId)) {
            isExists = true;
          }
        }

        if (isExists) {
          this.isRestrictedAdmin = groups[g].accessLevel === "Write" ? true : false;
        }
      }
    }
  }

  exportExcel(isAutoTaggedCommitments: boolean) {
    let exportResponses = [];

    const temp = { ...this.queryParam };
    temp.FormFields = '';
    if (isAutoTaggedCommitments) {
      temp.FormFields = JSON.stringify([{ 'apiKey': 'isReviewed', 'apiKeyValue': 'false' }]);
    } else {
      temp.FormFields = JSON.stringify([{ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' }]);
    }

    this.pageTemplateService.exportClientdashboardCommitments(this.clientId, temp).subscribe(
      responses => {
        let exportResponseList = responses.body as ICommitments;
        let len = Object.keys(exportResponseList).length;
        for (let i = 0; i < len; i++) {
          exportResponses[i] = [];
          exportResponses[i]['Title'] = exportResponseList[i]['commitmentTitle'];
          exportResponses[i]['Type'] = exportResponseList[i]['commitmentType'];
          exportResponses[i]['State'] = exportResponseList[i]['commitmentStatus'];
          // exportResponses[i]['Target Delivery Date'] = this.datePipe.transform(exportResponseList[i]['plannedCommittedDeliveryDate'], 'MM/dd/yyyy');
          exportResponses[i]['Target Delivery Date'] = exportResponseList[i]['plannedCommittedDeliveryDate'];
          exportResponses[i]['Product'] = exportResponseList[i]['product'];
          // if (!this.isClientExecutive) {
          //   exportResponses[i]['Reviewed Date'] = exportResponseList[i]['reviewedDate'];
          //   exportResponses[i]['Reviewed By'] = exportResponseList[i]['reviewedByName'];
          // }
        }
        this.excelService.exportAsExcelFile(exportResponses, this.clientText + '_commitments');
      },
      (error) => { this.errorMsg = error; });
  }

  public showDrawerForReviewTrailer(): void {
    if (this.clientId !== 0 && this.clientId !== -1) {
      $('#containerPage').scrollTop(0);
      $('#drawerDialog').removeClass('d-none');
      $('#drawerDialog').addClass('d-block');
      $('#containerPage').addClass('overflow-hidden');
    }
  }

  public close(): void {
    $('#drawerDialog').removeClass('d-block');
    $('#drawerDialog').addClass('d-none');
    $('#containerPage').removeClass('overflow-hidden');
  }

  public navigateToPageResponse(): void {
    let clientCommitmentId = 0;
    if (environment.env === 'prod') {
      clientCommitmentId = 8;
    } else if (environment.env === 'app') {
      clientCommitmentId = 299;
    } else if (environment.env === 'dev') {
      clientCommitmentId = 14;
    }
    const url2 = '/self-service/pageTemplateR/' + clientCommitmentId + '/referesh';
    this.router.navigate([url2]);
  }

  //Conditional Validator for ClientId
  submitRisk() {
    this.reEvaluateValidators('reason');
    this.reEvaluateValidators('description');
    let resId = this.detailsCommitment.responseId;
    let riskReason = '';
    let riskDescription = '';
    if (this.reasonForm.get('isRisk').value == false) {
      riskReason = '';
      riskDescription = '';

    } else {
      riskReason = this.reasonForm.get('reason').value
      if (this.reasonForm.get('isRisk').value == true && riskReason == 'other') {
        riskDescription = this.reasonForm.get('description').value;
      }
    }
    let params = {
      "isRisk": this.reasonForm.get('isRisk').value,
      "riskReason": riskReason,
      "riskDescription": riskDescription
    };

    if (this.reasonForm.valid) {
      this.pageTemplateService.updateCommitmentRisk(resId, params).subscribe(date => {
        this.closeDrawer();
        this.initateFilterSearch();
      })
    }
  }
  reEvaluateValidators(fieldName, isNested: any = 'no') {
    let fieldCtrl: any;
    fieldCtrl = this.reasonForm.controls[fieldName];
    fieldCtrl.setValidators(this.formValidators[fieldName]);
    fieldCtrl.updateValueAndValidity();
  }
  /*******************/
  calanderCommitment(mode, fromDate = '', toDate = '') {

    this.typeOfCommitmentCalander = mode;
    let type = '';
    if (mode == 'year') {
      type = 'Year';
    }

    if (mode == 'month') {
      type = 'Month'
    }
    if (mode == 'qtr') {
      type = 'Quarter'
    }
    this.paramsCal.dateRangeType = type;
    if (fromDate != '') {
      this.paramsCal.startDate = fromDate;
    } if (toDate != '') {
      this.paramsCal.endDate = toDate;
    }
    let formFields = [];
    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] })
    }
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    this.paramsCal.FormFields = JSON.stringify(formFields);
    this.paramsCal.searchClientBy = this.clientTypeSearch;
    this.commitmentsCalendarApi(this.paramsCal);
  }
  onToYearSelect(event) {
    let params = {
      startDate: this.fromYear + '-01-01',
      endDate: this.toYear + '-12-31',
      dateRangeType: 'Year',
      'LOB': this.selectedLOB,
      'Product': this.products,
    }
    this.commitmentsCalendarApi(params);
  }
  commitmentsCalendarApi(params) {
    if (!this.commitmentCalendar && !this.dataCommitmentCalendarError && !this.isPageLoad) {
      console.log('returned')
      return;
    }
    this.commitmentCalendar = false;
    this.commitmentCalendarLabel = [];
    this.commitmentCalendarNumbers = [];
    this.commitmentCalendarData = [];
    const commitmentCalendarData_inProgressCommitments = [];
    const commitmentCalendarData_onHoldCommitments = [];
    const commitmentCalendarData_canceledCommitments = [];
    const commitmentCalendarData_completedCommitments = [];
    this.dataCommitmentCalendarError = false;
    this.pageTemplateService.commitmentCalander(this.clientId, params).subscribe(
      data => {
        this.isPageLoad = false;
        this.commitmentCalendar = true;
        for (let k in data) {
          if (this.typeOfCommitmentCalander == 'qtr') {
            this.commitmentCalendarLabel.push(data[k].plannedCommittedDeliveryDateYear + '-' + data[k].plannedCommittedDeliveryDateQuarter);
          }
          else if (this.typeOfCommitmentCalander == 'month') {

            this.commitmentCalendarLabel.push(data[k].plannedCommittedDeliveryDateYear + '-' + data[k].plannedCommittedDeliveryDateMonth);
          }
          else {
            this.commitmentCalendarLabel.push(data[k].plannedCommittedDeliveryDateYear);
          }

          this.commitmentCalendarNumbers.push(data[k].numberOfCommitments);
          commitmentCalendarData_inProgressCommitments.push(data[k].inProgressCommitments);
          commitmentCalendarData_onHoldCommitments.push(data[k].onHoldCommitments);
          commitmentCalendarData_canceledCommitments.push(data[k].canceledCommitments);
          commitmentCalendarData_completedCommitments.push(data[k].completedCommitments);
        }
        let data2 = [
          //{
          //  label: 'Total Commitments',
          //  data: this.commitmentCalendarData,
          //  backgroundColor: '#237Cb8',
          //},
          {
            label: 'In Progress',
            data: commitmentCalendarData_inProgressCommitments,
            backgroundColor: '#0080BF',
          },
          {
            label: 'Completed',
            data: commitmentCalendarData_completedCommitments,
            backgroundColor: '#54B34A',
          },
          {
            label: 'On Hold',
            data: commitmentCalendarData_onHoldCommitments,
            backgroundColor: '#FFC626',
          },
          {
            label: 'Canceled',
            data: commitmentCalendarData_canceledCommitments,
            backgroundColor: '#757575',
          }
        ]
        this.commitMentCalendarGraph(this.commitmentCalendarLabel, data2);
        this.commitmentCalendarData = data;
      }, (error) => {

        this.dataCommitmentCalendarError = true;
      })
  }
  /***Commitments By Products */
  commitmentsbyProduct() {
    this.isProductsLoading = true;
    let queryParam: IQuery = Object.assign({}, {});
    if (this.queryParam['StartDate']) {
      queryParam['StartDate'] = this.queryParam['StartDate'];
    } if (this.queryParam['EndDate']) {
      queryParam['EndDate'] = this.queryParam['EndDate'];
    }
    let formFields = [];
    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] });
    }
    queryParam['searchClientBy'] = this.clientTypeSearch;
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    queryParam.FormFields = JSON.stringify(formFields);
    this.ngShows = false;
    this.cBProductsError = false;
    this.pageTemplateService.getCommitmentsbyProduct(this.clientId, queryParam).pipe(take(1)).subscribe(
      response => {
        if (response == null)
          response = [];
        this.commitmentProducts = response;

        let totalPages = Math.floor(this.commitmentProducts.length / this.pageSizeProduct);
        this.ngShows = true;
        if ((totalPages * this.pageSizeProduct < this.commitmentProducts.length)) {
          totalPages = totalPages + 1;
        }
        this.pagerObjectProduct = { "totalCount": this.commitmentProducts.length, "pageSize": this.pageSizeProduct, "currentPage": 1, "totalPages": totalPages }
        this.pageWiseProduct(1);
        this.isProductsLoading = false;
      },
      (error) => {
        this.ngShows = true;
        this.cBProductsError = true;
        this.isProductsLoading = false;
      }
    );
  }
  changePageProduct(page) {
    this.pageWiseProduct(page)
  }
  pageWiseProduct(pageNumber) {
    this.pageProducts = [];
    let start = (pageNumber - 1) * this.pageSizeProduct;
    let end = pageNumber * this.pageSizeProduct;
    for (let k = start; k < end; k++) {
      if (this.commitmentProducts[k])
        this.pageProducts.push(this.commitmentProducts[k])
    }
  }

  /**
   * *
   * @param labels
   * @param Month
   * @param type
   */
  commitMentCalendarGraph(labels, Month) {
    const ctx2: any = document.getElementById('areaChart2') as HTMLElement;
    const data = {
      labels: labels,
      datasets: Month
    };

    const options: any = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      },
      animation: false,
      maintainAspectRatio: false,
      scaleLabel: function (label) {
        return Math.round(label.value);
      }, ticks: {
        precision: 0
      },
      plugins: {
        animation: {
          duration: 0
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            padding: 5,
            fontColor: 'white',
            fontSize: 0,
          },
        },
      },
    };
    if (this.chart2) this.chart2.destroy();

    this.chart2 = new Chart(ctx2, {
      type: 'bar',
      data: data,
      options: options,
    });
  }
  setDateTo($event) {
    //this.calanderCommitment(this.typeOfCommitmentCalander)
  }
  setDateFrom($event) {
    // this.calanderCommitment(this.typeOfCommitmentCalander)
  }
  getLoginTimestamp(user: IUser) {
    this.userService.getLoginData(user.userId).subscribe((data) => {
      moment.locale(this.content['locale']);
      this.LastLoginTimeStamp = moment.utc(data.body).local().format("dddd D MMMM YYYY HH:mm");
    },
      (error) => console.log("Error"));
  }
  statusClass(status: any) {
    let data = status.replace('Fulfillment - ', '');

    let color = '';
    switch (data) {
      case "Active":
        color = "blue";
        break;
      case "Completed":
        color = "green";
        break;
      case "On Hold":
        color = "yellow";
        break;
      case "Canceled":
        color = "grey";
        break;
      case "Red":
        color = "red";
        break;
      case "Green":
        color = "green";
        break;
      case "Amber":
        color = "yellow";
        break;
      default:
        color = "yellow";
    }

    return color;

  }
  openFilterPage() {
    this.filterActivePage = !this.filterActivePage;
  }

  renderPdfExport() {
    this.isPdfExport = true;
    this.pageTemplateService
      .exportClientdashboardCommitments(this.clientId, this.queryParam)
      .pipe(take(1))
      .subscribe(
        (response) => {
          const commitments = response.body as any[];
          this.exportedCommitments = commitments.filter((data) => {
            if (data.commitmentStatus == 'Completed' || data.commitmentStatus == 'Canceled') {
              data.isRisk = 'blank';
            }
            return true;
          });

          setTimeout(() => {
            this.exportToPDF();
          }, 300);
        },
        (error) => {
          this.commitmentsError = true;
          this.isPdfExport = false;
        }
      );
  }

  public deleteLatestCommitmentIsReviewed(): void {
    this.advanceSearch();
    this.advanceSearchForAutoTagged(true);
    this.getCommitmentReviewsByClientId();
  }

  exportToPDF() {
    const exportingHtml = $('.exporting-container')[0].outerHTML;
    const query = {
      htmlString: exportingHtml
    };

    this.pageTemplateService.exportClientDashboardPDF(this.clientId, query).subscribe(
      response => {
        let newBlob = new Blob([response.body], { type: "application/pdf" });
        const nav = window.navigator as any;
        if (nav && nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(newBlob);
        } else {
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
          const link = document.createElement('a');
          link.href = data;
          link.download = `clientdashboard-${this.clientText}-${this.clientId}.pdf`;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);

          this.isPdfExport = false;
          this.exportedCommitments = [];
        }
      },
      (error) => {
        this.isPdfExport = false;
        this.errorMsg = error;
      });
  }
}

function requiredIfValidator(predicate) {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }

    console.log('predicate()' + predicate())
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  })
}
