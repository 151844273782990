import { Injectable } from '@angular/core';
import { DateFormatPipe } from 'src/app/SharedModule/Pipes/date-format.pipe';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private _datePipe: DateFormatPipe) { }

   //Atmost 15 pages should be displayed
   pageRange(page, pageCount) {
    var start = page - 5,
        end = page + 5;
    if (end > pageCount) {
        start -= (end - pageCount);
        end = pageCount;
    }
    if (start <= 0) {
        end += ((start - 1) * (-1));
        start = 1;
    }
    end = end > pageCount ? pageCount : end;
    return {
        start: start,
        end: end
    };
  }
 
  isValidDate(dateString): boolean{
    // First check for the pattern
    if(!dateString) return false;
  
    if (!/^\d{1,2}[\/]\d{1,2}[\/]\d{4}$/.test(dateString))
    return false;
  
  // Parse the date parts to integers
  let parts;
  if (dateString.indexOf("/") > -1) 
    parts = dateString.split("/");
  else
    return false;
  let day = parseInt(parts[1], 10);
  let month = parseInt(parts[0], 10);
  let year = parseInt(parts[2], 10);
  
  // Check the range of month
  if (month == 0 || month > 12)
    return false;
  
  let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  
  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29;
  
    //checking for past date
    if(new Date(this._datePipe.transform(dateString)) < new Date(this._datePipe.transform(new Date()))){
      return false;
    }
  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
  }; 

  convertModelToFormData(model: any, form: FormData = null, namespace = ''): FormData {
    let formData = form || new FormData();
    for (let propertyName in model) {
        if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
        let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
        if (model[propertyName] instanceof Date)
            formData.append(formKey, model[propertyName].toISOString());
        else if (model[propertyName] instanceof Array) {
            model[propertyName].forEach((element, index) => {
                const tempFormKey = `${formKey}[${index}]`;
                this.convertModelToFormData(element, formData, tempFormKey);
            });
        }
        else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
          this.convertModelToFormData(model[propertyName], formData, formKey);
        }
        else if (model[propertyName] instanceof File) {
          formData.append(formKey, model[propertyName]);
        }
        else {
          formData.append(formKey, model[propertyName].toString());
        }    
    }

    return formData;
  }

  uniqueBy(a, field) {
    return a.filter((e, i) => a.findIndex(e2 => e[field] == e2[field]) === i);
  }

}
