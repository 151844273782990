import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, of } from 'rxjs';
import { debounceTime, switchMap, map, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PERMISSIONS, QuickSearchType, QuickSearchTypeValues, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from '../core/constants';
import { QuickSearchService } from '../services/quick-search.service';
import { IPager } from '../shared/pagination';
import { IQuery } from '../shared/query';
import { PermissionsService } from '../services/permissions.service';
import { IUser } from '../shared/user';
import { UserService } from '../services/user.service';
import { ProjectsService } from '../services/projects.service';
import { PutProjectStatusComponent } from '../SharedModule/Components/put-project-status/put-project-status.component';
import { ICreateForm } from '../admin/manageForms/models/createForm';
import { ManageFormsService } from '../admin/manageForms/services/manageForms.service';
import { FormsService } from '../services/forms.service';
import { EditFormDetailsComponent } from '../SharedModule/Components/edit-form-details/edit-form-details.component';
import { PutOnHoldComponent } from '../SharedModule/Components/put-on-hold/put-on-hold.component';
import { HelpArticleService } from '../self-service/components/manageHelpArticle/helpArticle.service';
import { IForm } from '../shared/form';
declare var $: any;

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.css']
})
export class QuickSearchComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<boolean>();
  private quickSearch$ = new Subject<any>();
  quickSearchType = QuickSearchType;
  searchType = this.quickSearchType.PROJECT;
  searchText = '';
  currentPageNumber = 1;
  pageSize = 10;
  searchSuggestions: any;
  queryParam: IQuery;
  pagerObject: IPager;
  isLoading = false;
  displayColumns = ['ID','Name', 'Status', 'Last Updated User', 'Last Updated Date'];
  keys = ['id','title', 'status', 'modifiedByName', 'modifiedOn'];
  displayType = 'Project';
  searchFieldHelpText = 'Search for Project by Project Name, Client Name, Product Name, Status or Duns Id';
  sortingObject: any;
  isEditProjectAllowed: boolean = false;
  isCreateProjectAllowed: boolean = false;
  isDeleteResponseAllowed: boolean = false;
  userData: IUser;
  successMsg = '';
  errorMsg = '';
  isError = false;
  projectData: any;
  isAdmin = false;
  isReinitiateAllowed: boolean = false;
  isViewHistoryAllowed: boolean = false;
  isViewTimelineAllowed: boolean = false;
  isEditFormDetailsAllowed: boolean = false;
  isChangeApproverAllowed: boolean = false;
  showProjectFilter: boolean = false;
  responseData: any = {};
  filteredResponseList: any = [];
  public delegateFormId: any;
  public delegateRespondentId: any;
  public currentUrl = 'response/list';
  public canAccessToProject = false;
  public showProjectLink = false;
  showResponsesLink: boolean = false;
  isClientUser: boolean = true;
  responseMode: string;
  @ViewChild('putOnHold') putOnHold: PutOnHoldComponent;
  @ViewChild('editformdetails') editformdetails:EditFormDetailsComponent;
  @ViewChild('putProjectStatus') putProjectStatus: PutProjectStatusComponent;
  public canEditArticle = false;
  searchTypeValues = QuickSearchTypeValues;

  quickSearchSub = this.quickSearch$.pipe(
    debounceTime(500),
    tap(_ => this.isLoading = true),
    switchMap((cmd :any) => {
      return this.doSearch(cmd.isRefresh);
    })
  ).subscribe(_ => this.isLoading = false);
 
  constructor(private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private projectService: ProjectsService,
    private formsService: FormsService,
    private _manageForm: ManageFormsService,
    private helpArticleService: HelpArticleService,
    private permissionService: PermissionsService,
    private quickSearchService: QuickSearchService) {
  }

  ngOnInit(): void {
    this.initQueryParams();
    this.initUrlQueryParams();

    // Project
    this.isCreateProjectAllowed = this.permissionService.isCreateProjectAllowed();
    this.isEditProjectAllowed = this.permissionService.isEditProjectAllowed();
    this.isDeleteResponseAllowed = false;
    this.userData = this.userService.getUserListData();

    // Response
    const user = this.permissionService.userData;
    this.isAdmin = user.isAdmin;
    this.isViewHistoryAllowed = !this.userService.isClient && !this.userService.isRespondent;
    this.isViewTimelineAllowed = !this.userService.isRespondent;
    this.isEditFormDetailsAllowed = !this.userService.isClient && !this.userService.isRespondent;
    this.isReinitiateAllowed = this.userService.hasChannelPartnerRole() || (!this.userService.isClient && !this.userService.isRespondent);
    this.isChangeApproverAllowed = !this.userService.isClient && !this.userService.isRespondent;
    this.showProjectFilter = this.permissionService.showProjectFilter();
    this.canAccessToProject = this.isAdmin && user.permissionsForUI.some(group => group.groupName === "Projects");
    this.showProjectLink = this.permissionService.canAccessProjectTab();
    this.showResponsesLink = this.userService.getPermissions()['showresponseslink'] || this.isAdmin;
    this.isClientUser = user.type.toLowerCase() == 'client' ? true: false;
    
    
    // Article
    this.canEditArticle = this.userService.getPermissions()["showhelpArticlelink"];

    if (!this.showProjectLink) {
      this.searchTypeValues = this.searchTypeValues.filter(item => item.value !== this.quickSearchType.PROJECT);
      this.searchType = this.searchTypeValues[0].value;
    }
    if (!this.showResponsesLink) {
      this.searchTypeValues = this.searchTypeValues.filter(item => item.value !== this.quickSearchType.RESPONSE);
      this.searchType = this.searchTypeValues[0].value;
    }
    if (this.isClientUser || this.userService.isFormRespondentAndIntakeSubmitterUserOnly()) {
      this.searchTypeValues = this.searchTypeValues.filter(item => item.value !== this.quickSearchType.ARTICLE);
      this.searchType = this.searchTypeValues[0].value;
    }
  }

  initUrlQueryParams(): any {
    this.route.queryParams.pipe(switchMap((params: Params) => {
      if (params) {
        this.searchType = params.searchType && Object.values(QuickSearchType).some(type => type === params.searchType) ? params.searchType : this.searchType;
        this.searchText = params.searchText ? params.searchText : this.searchText;
      }
      this.search(true);
      return of(null);
    }),takeUntil(this.destroy$))
    .subscribe();
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  private doSearch(isRefresh = false) {
    return this.quickSearchService.search(this.searchText, this.searchType, this.queryParam, isRefresh)
    .pipe(
      map(res => {
        $('#refreshTask').removeClass('fa-rotation');
        this.setDisplayColumns();
        this.searchSuggestions = res.body.items;

        if (this.searchType === QuickSearchType.ARTICLE) {
          this.searchSuggestions.forEach(item => {
            if ((this.isAdmin) || (item.cretedById === this.userData.id)) {
              item.showDeleteButton = true;
            }
            else
              item.showDeleteButton = false;
          });
        } else if (this.searchType === QuickSearchType.RESPONSE) {
          this.searchSuggestions.forEach(responseList => {
            responseList.delegateLink = false;
            responseList.updateApproverLink = false;
          });
          for (let responseForm of this.searchSuggestions) {
            const responseStatusTitle = responseForm.status.trim().toLowerCase();
            if (["under review", "cancel", "completed", "on hold", "rejected"].indexOf(responseStatusTitle) >= 0) {
              responseForm.delegateLink = false;
              if(responseForm.editAccessWith) {
                responseForm.editAccessWith = `${responseForm.currentLevel}-${responseForm.editAccessWith}`;
              }
            } else {
              responseForm.delegateLink = true;
            }
  
            if (responseForm.answerJSON != null && responseForm.answerJSON != undefined) {
              responseForm.customeFormFieldListJsonAnswer = JSON.parse(responseForm.answerJSON).answer;
            }
            else {
              responseForm.customeFormFieldListJsonAnswer = {};
            }
  
            if (["completed", "overdue", "cancel", "on hold", "rejected"].indexOf(responseStatusTitle) >= 0) {
              responseForm.reInitiateLink = true;
            }
  
            if (["completed", "on hold", "rejected"].indexOf(responseStatusTitle) < 0) {
              responseForm.updateApproverLink = true;
            }
            else {
              responseForm.updateApproverLink = false;
            }
  
            responseForm.delegateLink = responseForm.delegateLink
              && (this.allowDelete(responseForm)
                || responseForm.assignedToName.indexOf(this.userData.userId) >= 0
                || responseForm.respondents.some((rs) => rs.assignedToId == this.userData.id));
            responseForm.updateApproverLink = responseForm.updateApproverLink && this.isallowedChangeApproverReinitiate(responseForm);
            responseForm.reInitiateLink = responseForm.reInitiateLink && this.isallowedChangeApproverReinitiate(responseForm);
            responseForm.disableReinstate = responseForm.hideReInitiate;
        }
      }
        
        if (res.headers.get('X-pagination')) {
          this.pagerObject = Object.assign({}, JSON.parse(res.headers.get('X-pagination')) as IPager);
        }
      }),
      takeUntil(this.destroy$));
  }

  private isallowedChangeApproverReinitiate(form: IForm): boolean {
    let isAllowed = false;
    const user = this.userService.getUserListData();
    if (this.permissionService.isChangeApproverReinitiateAllowed(form.organizationId)) {
      isAllowed = true;
    }
    else if (this.userService.hasChannelPartnerRole()){
      isAllowed = true;
    }
    else {
      isAllowed = form.fiservImplementationManagerId === +user.id;
    }
    return isAllowed;
  }

  setDisplayColumns(): void {
    switch (this.searchType) {
      case this.quickSearchType.ARTICLE:
        this.displayColumns = ['ID', 'Article Name', 'Article Description', 'Resource Category', 'Entity Level', 'Entity Name', 'Status'];
        this.keys = ['id', 'title', 'description', 'helpCategoryName', 'entityLevel', 'entityName', 'isPublishable'];
        this.displayType ="Resource Article";
        this.searchFieldHelpText = 'Search for Resource Article by Article Name, Article Description, Resource Category Name, Entity Name or Status';
        break;

      case this.quickSearchType.RESPONSE:
        this.displayColumns = ['ID', 'Form Name', 'Respondent Name', 'Approver Name', 'Current Assignee', 'Status', 'Last Updated User', 'Last Updated Date'];
        this.keys = ['id', 'title', 'assignedToName', 'currentApproverName', 'editAccessWith', 'status', 'modifiedByName', 'modifiedOn'];
        this.displayType = "Response";
        this.searchFieldHelpText = 'Search for Response by Form Name, Respondent Name, Approver Name,  Current Assignee or Status';
        break;
    
      case this.quickSearchType.PROJECT:
        this.displayColumns = ['ID','Project Name', 'Client Name', 'Product Name', 'Status', 'Last Updated User', 'Last Updated Date'];
        this.keys = ['id','title', 'clientTitle', 'productTitle', 'status', 'modifiedByName', 'modifiedOn'];
        this.displayType = "Project";
        this.searchFieldHelpText = 'Search for Project by Project Name, Client Name, Product Name, Status or Duns Id';
        break;
    }
  }

  onSearchTypeChanged() {
    this.setDisplayColumns();
    this.initQueryParams();
    this.searchText = '';
    this.search();
  }

  onSearchTextChanged() {
    this.validateRegex();
    if (!this.isError) {
      this.initQueryParams();
      this.search();
    }   
  }

  search(refresh = false) {
    const command = { isRefresh: refresh };
    this.quickSearch$.next(command);
  }

  refresh() {
    $('#refreshTask').addClass('fa-rotation');
    this.initQueryParams();
    this.search(true);
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.search();
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.search(true);
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  reset(): void {
    this.initQueryParams();
    this.searchText = '';
    this.search();
  }

  // For Project
  canDeleteProject = (data: any): boolean => {
    if (data.fiservImplementationManagerId == this.userData.id) {
      return true;
    }
    else if (this.userData.permissions.includes(PERMISSIONS.Projects_ReadWrite_All) && data.organizationId == this.userData.organization) {
      return true;
    }

    return this.userData.isAdmin;
  }

  canUpdateProjectStatus = (data: any, statusLabel: string): boolean => {
 
    if (statusLabel === 'On Hold'
      && (data.status === 'On Hold'
        || data.status === 'Completed'
        || data.status === 'Rejected')) {
      return false;
    }

    if (statusLabel === 'Rejected'
      && (data.status === 'Completed' || data.status === 'Rejected')) {
      return false;
    }

    if ((data.fiservImplementationManagerId == this.userData.id)
      || (this.userData.permissions.includes(PERMISSIONS.Projects_ReadWrite_All) && data.organizationId == this.userData.organization)
      || (this.permissionService.isFiservImpAndAbove())) {
      return true;
    }

    return this.userData.isAdmin;
  }

  projectAction(event) {
    if (event.action === "view") {
      this.router.navigate(["/projects/view", event.data]);
    }
    else if (event.action === "viewProjectTimeline") {
      this.router.navigate(['/projects/viewTimeline', event.data]);
    }
    else if (event.action === "edit") {
      this.router.navigate(["/projects/edit/projectinfo", event.data]);
    } else if (event.action === "delete") {
      this.successMsg = "";
      this.projectService.deleteProject(event.data).subscribe(
        data => {
          this.isError = false;
          this.refresh();
          this.successMsg = `The project '${event.title}' has been deleted.`;
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
    } else if (event.action === "onHold" || event.action === "reject") {
      this.projectData = this.searchSuggestions.find(item => item.id === event.data);
      this.putProjectStatus.initCommentForm(event.action);
      $('#projectStatusComments').modal('show');
    }
  }

  onUpdateProjectStatusSuccess(event: any): any {
    this.successMsg = event.message;
    $('#projectStatusComments').modal('hide');
    this.refresh();
  }

  // For Response
  responseAction(event) {
    if (event.action === "requestExtract") {
      this.router.navigate(['/self-service/requestExtracts/extractionRequest/', event.data]);
    }
    else if (event.action === "view") {
      this.router.navigate(['/response/view/responses/', event.data]);
    }
    else if (event.action === "edit") {
      this.formsService.getResponseJson(event.data).subscribe(
        data => {
          let filedSets = JSON.parse(data.formJSON)
          let fieldSets2 = filedSets.components.filter((data) => {
            //
            if (data.type == 'panel' || data.type == 'fieldset') {
              data.type = "panel"
              data.legend = data.title
              return true;
            }
          });
          filedSets.components = fieldSets2;
          let dataForm: ICreateForm = {
            id: event.data,
            formDes: data.description,
            orgName: data.organizationTitle,
            productName: data.productTitle,
            productId: data.productId,
            templateOption: '',
            formTemplate: '',
            formName: data.title,
            initialDataJSON: data.formJSON,
            json: JSON.stringify(filedSets),
            isExist: true,
            isResponse: true,
            rowVersion: data.rowVersion,
            answerJSON: data.answerJSON,
            orgId: data.organizationId,
            projectTitle: data.projectTitle,
            clientTitle: data.clientTitle,
            assignedToName: data.assignedToName,
            fiservImplementationManagerName: data.fiservImplementationManagerName,
            currentApproverName: data.currentApproverName,
            dueDate: data.dueDate,
            responseStatusId: data.responseStatusId
          }
          this._manageForm.saveFormValues(dataForm);
          this.router.navigate(['/admin/form/template']);
        });


    }
    else if (event.action === "delegate" || event.action === "reInitiate") {
      this.responseMode = event.action;
      let responseDetail = this.searchSuggestions.find(response => response.id === event.data);
      this.responseData = {
        id: responseDetail.id,
        projectId: responseDetail.projectId,
        organizationId: responseDetail.organizationId,
        clientId: responseDetail.clientId,
        title: responseDetail.title,
        dueDate: responseDetail.dueDate,
        assignedToEmail: responseDetail.assignedToEmail,
        assignedToName: responseDetail.assignedToName,
        approvers: responseDetail.approvers,
        respondents: responseDetail.respondents,
        assignedToId: responseDetail.assignedToId,
        surveyId: responseDetail.surveyId,
        delegateRespondentId: this.delegateRespondentId,
        returnUrl: this.currentUrl,
        formId: responseDetail.formId
      }
      $('#delegateForms').modal('show');
    }
    else if (event.action === "hold" || event.action === "cancel") {
      this.clearMessage();
      $('#rejectedComments').modal('show');
      this.putOnHold.initHoldCommentsForm(event.action);
      let responseDetail = this.searchSuggestions.find(response => response.id === event.data);

      this.responseData = {
        id: responseDetail.id,
        projectId: responseDetail.projectId,
        organizationId: responseDetail.organizationId,
        clientId: responseDetail.clientId,
        title: responseDetail.title,
        dueDate: responseDetail.dueDate,
        assignedToEmail: responseDetail.assignedToEmail,
        assignedToName: responseDetail.assignedToName,
        approvers: responseDetail.approvers,
        respondents: responseDetail.respondents,
        assignedToId: responseDetail.assignedToId,
        surveyId: responseDetail.surveyId,

      }
      this.clearMessage();
    }
    else if (event.action === "editFormDetails") {
    this.clearMessage();
    $('#editFormDetailforms').modal('show');
    this.editformdetails.initiateForm();
    let responseDetail = this.searchSuggestions.find(response => response.id === event.data);
    this.formsService.getResponseJson(event.data).subscribe(
      data => {

      this.responseData = {
        id: responseDetail.id,
        title: responseDetail.title,
        dueDate: responseDetail.dueDate,
        rowVersion: data.rowVersion,
        organizationId: responseDetail.organizationId,
    }
  });
    this.clearMessage();
  }
    else if (event.action === "changeApprover") {
      this.clearMessage();
      let responseDetail = this.searchSuggestions.find(response => response.id === event.data);
      this.responseData = {
        id: responseDetail.id,
        projectId: responseDetail.projectId,
        organizationId: responseDetail.organizationId,
        clientId: responseDetail.clientId,
        title: responseDetail.title,
        approvers: responseDetail.approvers,
        approvalSchema: responseDetail.approvalSchema
      }
      $('#changeApprover').modal('show');
    }

    else if (event.action === "delete") {
      this.clearMessage();
      this.formsService.deleteForm(event.data).subscribe(
        data => {
          this.isError = false;
          this.refresh();
          this.successMsg = `The form '${event.title}' has been deleted.`
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      )
    }
    else if (event.action === "viewHistory") {
      let responseDetail = this.searchSuggestions.find(response => response.id === event.data);
      let responseData = {
        historyName: responseDetail.title,
        organizationId: parseInt(responseDetail.organizationId),
        respondentName: responseDetail.assignedToName,
        approverName: responseDetail.currentApproverName,
        currentAssignee: responseDetail.editAccessWith,
        responseStatusTitle: responseDetail.responseStatusTitle,
        dueDate: responseDetail.dueDate
      };
      this.formsService.historyData = responseData;
      this.router.navigate(['/response/history/list', event.data]);
    }
    else if (event.action === "viewProjectTimeline") {
      this.router.navigate(['/projects/viewTimeline', event.data]);
    }
    else if (event.action === "viewTimeline") {
      let responseDetail = this.searchSuggestions.find(response => response.id === event.data);
      let responseData = {
        historyName: responseDetail.title,
        organizationId: parseInt(responseDetail.organizationId),
        respondentName: responseDetail.assignedToName,
        approverName: responseDetail.currentApproverName,
        currentAssignee: responseDetail.editAccessWith,
        responseStatusTitle: responseDetail.status,
        dueDate: (responseDetail.dueDate.split('-')[0] != '0001') ? responseDetail.dueDate : ""
      };
      this.formsService.historyData = responseData;
      this.router.navigate(['/response/timeline/list', event.data]);
    }
    else if (event.action === "showProject") {
      this.router.navigate(["/projects/view", event.data]);
    }
  }

  successUpdateApproverClick(evt) {
    this.refresh();
    this.successMsg = evt.updateApproverSuccessMsg;
  }

  successDelegateClick(evt) {
    this.refresh();
    this.successMsg = evt.delegateSuccessMsg;
  }

  clearMessage() {
    this.successMsg = '';
    this.errorMsg = '';
  }

  allowDelete = (data: any): boolean => {

    if (this.userData.isAdmin) {
      return true;
    }
    else if (data.fiservImplementationManagerId == this.userData.id
      && this.userData.permissions.includes(PERMISSIONS.Projects_Edit_Assigned)) {
      return true;
    }
    else if (this.userData.permissions.includes(PERMISSIONS.Forms_ReadWrite_All)
      && this.userData.organization == data.organizationId) {
      return true;
    }

    return false;
  }

  // Article
  acticleAction(event) {
    if (event.action === 'view') {
      this.router.navigate(['/self-service/helpTopic/view', event.data]);
    }
    else if (event.action === 'delete') {
      this.successMsg = '';
      const deleteHelpCategory = this.searchSuggestions.filter(item => item.id === event.data)[0];
      this.helpArticleService.deleteHelpArticle(event.data).subscribe(
        () => {
          this.isError = false;
          this.refresh();
          this.successMsg = `The Resource '${deleteHelpCategory.title}' has been deleted.`;
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
    }
  }

  validateRegex(): void {
    this.isError = false;
    this.errorMsg = '';
    let regex;
    switch (this.searchType) {
      case this.quickSearchType.ARTICLE:
        regex = new RegExp(REGEX_PATTERNS.name_title_validator);
        if (this.searchText !== '' && !regex.test(this.searchText)) {
          this.errorMsg = REGEX_VALIDATION_MESSAGE.NAME_TITLE_VALIDATION_MESSAGE;
          this.isError = true;
        }
        break;

      case this.quickSearchType.RESPONSE:
        regex = new RegExp(REGEX_PATTERNS.form_response_survey_name_validator);
        if (this.searchText !== '' && !regex.test(this.searchText)) {
          this.errorMsg = REGEX_VALIDATION_MESSAGE.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE;
          this.isError = true;
        }
        break;
    
      case this.quickSearchType.PROJECT:
        regex = new RegExp(REGEX_PATTERNS.project_name_validator);
        if (this.searchText !== '' && !regex.test(this.searchText)) {
          this.errorMsg = REGEX_VALIDATION_MESSAGE.PROJECT_NAME_VALIDATION_MESSAGE;
          this.isError = true;
        }
        break;
    }
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
