<div class="heading-container fdl-2">
  <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="editForm()">
        <span class="material-icons">
          arrow_back
        </span>
        Back
      </a>
    </div>
    <h1 class="text-truncate" data-toggle="tooltip" data-placement="top" title="{{topicName}}">{{topicName}}</h1>
  </div>

</div>

<div class="form-container separate-form-container ">
  <form>
    <div class="biller-survey upload-header" >
      <div style="display:block">
        <ul>
          <li>Topic Description: <span class="info">{{formDescription}}</span></li>
          <li *ngIf="productName">
            Product:
            <span class="info">{{productName}}</span>
          </li>
          <li *ngIf="category">
            Category:
            <span class="info">{{category}}</span>
          </li>
        </ul>
      </div>
     
    </div>
    <br>
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
      <i class="material-icons">warning</i>
      <p [innerHtml]="errorMsg"></p>
    </div>
    <div class="alert alert-success alert-dismissible" *ngIf="showSuccessMsg">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="showSuccessMsg=false">×</button>
      <span class="iconLabel" aria-label="sucess"></span>
      <i class="material-icons" aria-hidden="true">check_circle</i>
      <p>
        {{successMsg}}
   
      </p>
    </div>
  
    <!--<formio-wizard [form]="form" (change)="onChange($event)"></formio-wizard>-->


    <form-builder [form]="form" [options]="formbuilder" (change)="onChange($event)"></form-builder>

    <div class="fdl-2 button-container">
      <button type="button" class="btn fdl-btn btn-secondary-light " aria-label="Cancel"(click)="cancel()">Cancel</button>
      <div>
      
        <button type="button" class="btn fdl-btn btn-primary-orange" (click)="submitForm()">Submit</button>

      </div>
    </div>
  </form>
</div>

<!----- DATA LOSS MODAL BEGIN -------->

<!---  DATA LOSS MODAL END  -------->
<!-- Reserved Keys Modal Starts -->

