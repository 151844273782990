<div class="heading-container fdl-2">
    <h1>Response Extracts</h1>
    <div class="col-md-6 float-right pt-4" *ngIf="isCreateExtractionAllowed">
        <button type="button" class="pull-right btn fdl-btn btn-primary-orange ml-2 pl-2 pr-2 mb-3" (click)="RequestExtract()">
            <span class="material-icons">add</span>
            Request Response Extract
        </button>
    </div>
</div>

<div class="form-container">
    <!-- Error Msg Alert -->
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>
    <!-- Success Msg Alert -->
    <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="setSuccessMsg()">×</button>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p [innerHtml]="successMsg"></p>
    </div>

    <form class="form form-border mb-3 collapse-view" autocomplete="off" [formGroup]="filterForm">
        <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
        <div class="filter-form-container-column2 collapse" id="collapseFilter">

          <div class="filter-form-control pr-4">
            <label for="extractionStatus">Extraction Status</label>
            <div class="fdl-select">
              <select name="extractionStatus" class="form-control" aria-invalid="false" formControlName="extractionStatus">
                <option value="">All</option>
                <option *ngFor="let status of extractionStatus | keyvalue" [value]="status.value">{{status.value}}</option>
              </select>
            </div>
          </div>
          <!-- <div class="filter-form-control pr-4 typeahead-wrapper">
              <fdl-type-ahead label="Project Name" formControlName="projectName" [collection]="projectSuggestion" optionField="title"
              placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
          </div>
          <div class="filter-form-control pr-4 typeahead-wrapper">
              <fdl-type-ahead label="Form Name" formControlName="formName" [collection]="formNameSuggestion" optionField="title"
              placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
          </div>
          <div class="filter-form-control pr-4 typeahead-wrapper">
              <fdl-type-ahead label="Product Name" formControlName="productName" [collection]="productSuggestion" optionField="title"
              placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
          </div>
          <div class="filter-form-control pr-4 typeahead-wrapper">
              <fdl-type-ahead label="Client Name" formControlName="clientName" [collection]="clientSuggestion" optionField="title"
              placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
          </div> -->
          <div>
              <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterList()">Search</button>
          </div>
          <div class="ml-2" *ngIf="filtered">
            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset" (click)="clearFilterList()">Reset</button>
        </div>
        </div>
    </form>

    <div class="data-table-container">
        <div class="justify-content-between d-flex">
          <div class="headline-container">
            <h2 class="h4">Response Extracts List</h2>
            <p>Total {{pagerObject.totalCount}} Items</p>
          </div>
          <div class="d-flex">
            <div class="refresh-icon-link mt-1 mr-2">
              <!-- add class of 'fa-rotation' on click of the refresh icon -->
              <a  title="Refresh Response Extract Request List" id="refreshTask">
                <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
              </a>
            </div>
            <app-prefernce-icon></app-prefernce-icon>
            </div>
        </div>
        <div class="table-scroll-x">
            <app-data-table
                *ngIf="extractList.requests"
                [rows]="extractList.requests"
                [keys]="keys"
                [displayColumns]="displayColumns"
                (sortingClicked)="applySorting($event)"
                [showCheckbox]="false"
                [showViewEdit]="true"
                [showAssetOptions]="true"
                [showDownload]="true"
                (deleteBtnClicked)="navigate($event)"
                (buttonClicked)="navigate($event)"
                type="Response Extract" [listname]="listname"
            ></app-data-table>
            <app-pagination
                *ngIf="pagerObject && pagerObject.totalPages > 1 && extractList.requests && extractList.requests.length > 0"
                [pagerObject]="pagerObject"
                (pagingChanged)="changePage($event)"
            ></app-pagination>
        </div>
    </div>
</div>

<!-- Copy Link Modal code -->
<div class="modal fade bd-example-modal-lg" id="copyLink" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title strong">Get Link - <span [innerHtml]="asset?.extractRequestName"></span></h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="link">Link:</label>
            <input type="text" id="link" class="form-control" [value]="asset?.extractResponseFinalUrl" readonly #linkInput>
            <p class="text-success" *ngIf="showMsg">Link is copied successfully.</p>
          </div>
        </div>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal">Cancel</button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" (click)="copyLink();">Copy</button>
      </div>
    </div>
  </div>
</div>
<!-- Copy Link Modal code -->
