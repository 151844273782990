<div class="heading-container fdl-2">
  <h1>Email Templates</h1>
  <button type="button"  class="btn fdl-btn btn-primary-orange" (click)="createEmailTemplateNav()">
      <span class="material-icons">add</span>
      Create New Email Template</button>
  <!-- *ngIf="isCreateEmailTemplateAllowed" -->
</div>

<div class="main-container-sub" style="position: static;">
<div class="form-container">
  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="successMsg = ''">×</button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>{{successMsg}}
         </p>
  </div>
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
  </div>
<form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
      <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
      <div class="filter-form-container-column2 collapse search-filter" id="collapseFilter">
          <div class="filter-form-control pr-4 typeahead-wrapper">
            <div class="has-error">
              <span *ngIf="!filterForm.get('templateName').valid && filterForm.get('templateName').errors?.pattern && filterForm.get('templateName').dirty">
                <i class="material-icons">warning</i>Please enter a valid Template Name</span>
            </div>
            <fdl-type-ahead label="Template Name:" formControlName="templateName" [collection]="templateSuggestion"  optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
            </fdl-type-ahead>
          </div>
          <div class="filter-form-control pr-4 typeahead-wrapper">
            <div class="has-error">
              <span *ngIf="!filterForm.get('eventName').valid && filterForm.get('eventName').errors?.pattern && filterForm.get('eventName').dirty">
                <i class="material-icons">warning</i>Please enter a valid Event Name</span>
            </div>
            <fdl-type-ahead label="Event Name:" formControlName="eventName" [collection]="eventSuggestion"  optionField="displayName" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
            </fdl-type-ahead>
          </div>
          <div class="filter-form-control pr-4">
              <label for="level">Entity Level:</label>
              <div class="fdl-select">
                  <select name="level" class="form-control" aria-invalid="false" formControlName="level" [(ngModel)]='selectedLevelValue' >
                      <option value="">All</option>
                      <option *ngFor="let level of entityLevel | keyvalue" [value]="level.value">{{level.value}}</option>
                  </select>
              </div>
          </div>
          <div class="filter-form-control pr-4"  *ngIf="(selectedLevelValue == 'Product' || selectedLevelValue == 'Form' || selectedLevelValue == 'PageTemplate')">
              <label for="level">Entity Name:</label>
              <div class="fdl-select">
                <div class="has-error">
                  <span *ngIf="(!filterForm.get('productSearch').valid && filterForm.get('productSearch').errors?.pattern && filterForm.get('productSearch').dirty) && selectedLevelValue == 'Product'">
                    <i class="material-icons">warning</i>Please enter a valid Product Name</span>
                </div>
                <fdl-type-ahead *ngIf="selectedLevelValue=='Product'" formControlName="productSearch"
                  [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                  [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityProductSelect($event)">
                </fdl-type-ahead>
                <div class="has-error">
                  <span *ngIf="(!filterForm.get('formSearch').valid && filterForm.get('formSearch').errors?.pattern && filterForm.get('formSearch').dirty) && selectedLevelValue == 'Form'">
                    <i class="material-icons">warning</i>Please enter a valid Form Name</span>
                </div>
                <fdl-type-ahead *ngIf="selectedLevelValue=='Form'" formControlName="formSearch"
                  [collection]="formSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                  [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityFormSelect($event)">
                </fdl-type-ahead>
                <div class="has-error">
                  <span *ngIf="(!filterForm.get('pageTemplateSearch').valid && filterForm.get('pageTemplateSearch').errors?.pattern && filterForm.get('pageTemplateSearch').dirty) && selectedLevelValue == 'PageTemplate'">
                    <i class="material-icons">warning</i>Please enter a valid Page Template Name</span>
                </div>
                <fdl-type-ahead *ngIf="selectedLevelValue=='PageTemplate'" formControlName="pageTemplateSearch"
                  [collection]="pageTemplateSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                  [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityPageTemplateSelect($event)">
                </fdl-type-ahead>
              </div>
          </div>
          <div class="filter-form-control pr-4">
                  <label for="status">Status:</label>
                  <div class="fdl-select">
                      <select name="status" class="form-control" aria-invalid="false" formControlName="status">
                          <!-- <option value="">All</option> -->
                          <option *ngFor="let status of StatusTypes" [value]="status">{{status}}</option>
                      </select>
                  </div>
          </div>
          <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showOrganizationFilter">
            <div class="has-error">
              <span *ngIf="!filterForm.get('organizationTitle').valid && filterForm.get('organizationTitle').errors?.pattern && filterForm.get('organizationTitle').dirty">
                <i class="material-icons">warning</i>Please enter a valid Organization Name</span>
            </div>
            <fdl-type-ahead label="Organization Name:" formControlName="organizationTitle" [collection]="organizationSuggestion"  optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
            </fdl-type-ahead>
          </div>
          <div>
              <button type="button" class="btn fdl-btn btn-primary-dark ml-3 buttom-wrapper" aria-label="Search"
                  (click)="filterList()">Search</button>
          </div>
          <div class="ml-2 buttom-wrapper" *ngIf="filtered">
              <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset" (click)="clearFilterList()">Reset</button>
          </div>
      </div>
  </form>
  <div class="data-table-container">
    <div class="justify-content-between d-flex">
      <div class="headline-container">
        <h2 class="h4">
          Email Template List
        </h2>
        <p>Total {{pagerObject.totalCount}} Email Templates</p>
      </div>
      <div class="d-flex">
          <div>
              <div class="filter-control-wrapper mt-2" *ngIf="!statusToggle">
                  <div class="favorite-switcher-wrapper switcher-wrapper">
                      <div class="fdl-switch fdl-2">
                          <input [disabled]="emailSets[0].selectedEmailTemplateList.length <= 0" id="switch2"  name="switch2" type="checkbox" aria-labelledby="desc12" (click)="toggleStatus()" checked>
                          <label [(title)]="toggleStatusTitle" for="switch2">
                          </label>
                      </div>
                      <label for="switch2" class="label-text cursor-pointer">{{StatusText}}</label>
                  </div>
              </div>
          </div>
          <div>
            <div class="filter-control-wrapper mt-2" *ngIf="statusToggle">
                <div class="favorite-switcher-wrapper switcher-wrapper">
                    <div class="fdl-switch fdl-2">
                        <input [disabled]="emailSets[0].selectedEmailTemplateList.length <= 0" id="switch2"  name="switch2" type="checkbox" aria-labelledby="desc12" (click)="toggleStatus()">
                        <label [(title)]="toggleStatusTitle" for="switch2">
                        </label>
                    </div>
                    <label for="switch2" class="label-text cursor-pointer">{{StatusText}}</label>
                </div>
            </div>
          </div>
           <div>
              <button type="button" [disabled]="emailSets[0].selectedEmailTemplateList.length <= 0" class="btn fdl-btn btn-primary-dark ml-3 mr-4" aria-label="CopyTemplates" (click)="copyTemplates()">
                Copy Templates
              </button>
            </div>
          <div class="refresh-icon-link mt-1 mr-2">
              <!-- add class of 'fa-rotation' on click of the refresh icon -->
              <a title="Refresh Email Template List" id="refreshTask">
              <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
              </a>
          </div>
          <app-prefernce-icon></app-prefernce-icon>
      </div>
      </div>
      <div class="table-scroll-x">
        <app-data-table *ngIf="emailTemplateList"
          [rows]="emailTemplateList"
          [keys]="keys"
          [counter]="currentIndex"
          [displayColumns]="displayColumns"
          (buttonClicked)="navigate($event)"
          (sortingClicked)="applySorting($event)"
          [showCheckbox]="!!(emailTemplateList.length>0)"
          [showViewEdit]="true"
          type="Email Template"
          [(emailSets)]="emailSets"
          (deleteBtnClicked)="navigate($event)"
          [showTemplateDelete]="true"
          [showTemplateSend]="true"
          [listname]="listname">
        </app-data-table>


        <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && emailTemplateList && emailTemplateList.length > 0"
                        [pagerObject]="pagerObject"
                        (pagingChanged)="changePage($event)"></app-pagination>
      </div>
    </div>
</div>

<!-- Starts Email Template Copy Template Modal-->
<div *ngIf="loadDrawer">
  <app-project-drawer-container componentName="client" CancelText="Back" state="CLIENT"></app-project-drawer-container>
</div>
<div *ngIf="emailTemplateDrawer">
<div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" tabindex="-1" aria-labelledby="myModalLabel"
      aria-hidden="true" role="region">

  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12 project-drawer p1">
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
      <button type="button" class="close" data-dismiss="alert" aria-label="close"
              (click)="isError=false">
        ×
      </button>
      <i class="material-icons">warning</i>
      <p [innerHtml]="errorMsg"></p>
    </div>
    <div class="row">
      <div class="col-md-12 mb-1">
        <h3>Copy Email Template</h3>
      </div>
    </div>

    <div class="">
      <div class="form-container p-1">
        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close"
                  (click)="isError=false">
            ×
          </button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>
        <form [formGroup]="createEmailTemplate" autocomplete="off">
        <div class="form-row">
          <div class="col-md-6 mb-3 typeahead-wrapper" *ngIf="!isEdit" (change)="onEntityLevelSelect()"
            [ngClass]="{'has-error': !createEmailTemplate.get('entityLevel').valid && (createEmailTemplate.get('entityLevel').touched || isSubmitted)}">
            <label for="entityLevel" class="asterisk">Entity Level:</label>
            <div class="fdl-select">
              <select name="entityLevel" class="form-control" aria-invalid="false" formControlName="entityLevel"
                [(ngModel)]='selectedEntityLevelValue'>
                <option value="">Please select</option>
                <option *ngFor="let level of entityLevel | keyvalue" [value]="level.value">
                  {{level.value}}
                </option>
              </select>
            </div>
            <div class="has-error">
              <span  *ngIf="(!createEmailTemplate.get('entityLevel').valid || invalidEntityLevel) && (createEmailTemplate.get('entityLevel').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select entity level.
              </span>
            </div>
          </div>

          <div class="col-md-6 mb-3 typeahead-wrapper" *ngIf="isEdit">
            <label for="entityLevel" class="asterisk">Entity Level:</label>
            <input name="entityLevel" type="text" class="form-control" formControlName="entityLevel"
              [(ngModel)]='selectedEntityLevelValue' readonly>
          </div>

         <div class="col-md-6 mb-3 typeahead-wrapper"
            *ngIf="isEdit && (selectedEntityLevelValue != 'Organization' && selectedEntityLevelValue != 'System')">
            <label for="entityName" class="asterisk">Entity Name:</label>
            <input *ngIf="selectedEntityLevelValue=='Product'" name="entityName" type="text" class="form-control"
              formControlName="entityNameProduct" [(ngModel)]='readOnlyEntityName' readonly>
            <input *ngIf="selectedEntityLevelValue=='Form'" name="entityName" type="text" class="form-control"
              formControlName="entityNameForm" [(ngModel)]='readOnlyEntityName' readonly>
            <input *ngIf="selectedEntityLevelValue=='PageTemplate'" name="entityName" type="text" class="form-control"
              formControlName="entityNamePageTemplate" [(ngModel)]='readOnlyEntityName' readonly>
          </div>

          <div class="col-md-6 mb-3 typeahead-wrapper" *ngIf="!isEdit">
            <label
              *ngIf="selectedEntityLevelValue && selectedEntityLevelValue != 'Organization' && selectedEntityLevelValue != 'System'"
              for="EntityName" class="asterisk">Entity Name:</label>
            <fdl-type-ahead *ngIf="selectedEntityLevelValue=='Product'" formControlName="entityNameProduct"
              [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
              [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityProductSelect($event)">
            </fdl-type-ahead>
            <fdl-type-ahead *ngIf="selectedEntityLevelValue=='Form'" formControlName="entityNameForm"
              [collection]="formSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
              [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityFormSelect($event)">
            </fdl-type-ahead>
            <fdl-type-ahead *ngIf="selectedEntityLevelValue=='PageTemplate'" formControlName="entityNamePageTemplate"
              [collection]="pageTemplateSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
              [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityPageTemplateSelect($event)">
            </fdl-type-ahead>
            <div class="has-error">
              <span *ngIf="invalidProductName || selectedEntityLevelValue=='Product' && !createEmailTemplate.get('entityNameProduct').valid && createEmailTemplate.get('entityNameProduct').errors?.required && (createEmailTemplate.get('entityNameProduct').dirty || isSubmitted)">
                <i class="material-icons">warning</i> Please select a product.
              </span>
              <span *ngIf="!createEmailTemplate.get('entityNameProduct').valid && createEmailTemplate.get('entityNameProduct').errors?.pattern && (createEmailTemplate.get('entityNameProduct').dirty || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.PRODUCT_NAME_VALIDATION_MESSAGE}}
              </span>
              <span *ngIf="invalidFormName || selectedEntityLevelValue=='Form' && !createEmailTemplate.get('entityNameForm').valid && createEmailTemplate.get('entityNameForm').errors?.required && (createEmailTemplate.get('entityNameForm').dirty || isSubmitted)">
                <i class="material-icons">warning</i> Please select a form.
              </span>
              <span *ngIf="!createEmailTemplate.get('entityNameForm').valid && createEmailTemplate.get('entityNameForm').errors?.pattern && (createEmailTemplate.get('entityNameForm').dirty || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
              </span>
              <span *ngIf="invalidPageTemplateName || selectedEntityLevelValue=='PageTemplate' && !createEmailTemplate.get('entityNamePageTemplate').valid && createEmailTemplate.get('entityNamePageTemplate').errors?.required && (createEmailTemplate.get('entityNamePageTemplate').dirty || isSubmitted)">
                <i class="material-icons">warning</i> Please select a page template.
              </span>
              <span *ngIf="!createEmailTemplate.get('entityNamePageTemplate').valid && createEmailTemplate.get('entityNamePageTemplate').errors?.pattern && (createEmailTemplate.get('entityNamePageTemplate').dirty || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.PAGE_NAME_VALIDATOR_VALIDATION_MESSAGE}}
              </span>
            </div>
          </div>
        </div>
      <!-- </form> -->
        <div class="form-row">
          <div class="col-md-12 mb-1">
            <h3>{{slectedEmailTemplatecount}}  Templates Selected to Copy</h3>
          </div>
        </div>
        <p class="mb-0" *ngIf="emailSets[0].selectedEmailTemplateList.length >= 1">
          <!-- <b>Note:</b> To edit the Template Name, double-click the respective record.</p> -->

        <fdl-data-grid [rows]="emailSets[0].selectedEmailTemplateList" [columns]="columns" emptyMessage="No Data Available" columnMode="standard">
        </fdl-data-grid>

        <div class="fdl-2 button-container">
          <button class="btn fdl-btn btn-secondary-light" (click)="clearForm()">
            Cancel
          </button>
          <div>
            <button type="button" class="btn fdl-btn btn-primary-orange" (click)="emailTemplateSubmit()">Submit</button>
          </div>
        </div>
      </form>
      </div>
    </div>

  </div>
</div>
<div class="backdrop-space">
  <div class="backdrop"></div>
</div>
</div>

<!-- Ends Email Template Copy Template Modal-->
<!-- Starts Email Template Share Modal-->
<div id="shareModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" class="modal fade bd-example-modal-md" style="display: none;">
  <div role="document" class="modal-dialog modal-md mt-120" style="max-width: 1000px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title strong">Send Test Email</h4>
        <button type="button" data-dismiss="modal" aria-label="Close popup" class="close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-md-12 mb-3">
            <form [formGroup]="emailAddressForm" autocomplete="off">
              <div class="form-row">
                  <div class="col-md-12 mb-3"
                     [ngClass]="{'has-error': !emailAddressForm.get('emailAddress').valid && (emailAddressForm.get('emailAddress').touched || isSubmitted)}">
                  <label for="emailAddress" class="asterisk">Email Address:</label>
                  <input type="text" class="form-control" id="emailAddress" formControlName="emailAddress">
                  <span *ngIf="!emailAddressForm.get('emailAddress').valid && (emailAddressForm.get('emailAddress').errors?.required || emailAddressForm.get('emailAddress').errors?.validateWhiteSpaces) && (emailAddressForm.get('emailAddress').touched || isSubmitted)">
                    <i class="material-icons">warning</i> Please enter an email address.
                  </span>
                  <span *ngIf="!emailAddressForm.get('emailAddress').valid && emailAddressForm.get('emailAddress').errors?.pattern && (emailAddressForm.get('emailAddress').touched || isSubmitted)">
                    <i class="material-icons">warning</i> {{regexValidationMessage.EMAIL_VALIDATION_MESSAGE}}
                  </span>
                </div>
              </div>
            </form>
        </div>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" data-dismiss="modal" class="btn fdl-btn btn-secondary-light">Cancel</button>
        <button type="button" class="btn fdl-btn btn-primary-orange ml-2" [disabled]="!emailAddressForm.get('emailAddress').valid" (click)="sendEmail()">Send</button>
      </div>
    </div>
  </div>
</div>
</div>
