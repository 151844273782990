<!-- Add class of "back-action-container" when having back button in place like below -->
<div class="heading-container fdl-2" *ngIf="!isDrawerType">
    <div class="back-action-container">
      <div class="back-button">
        <a href="javascript:void(0);" (click)="goBack()">
          <span class="material-icons">
            arrow_back
          </span>
          Roles
        </a>
      </div>
      <h1 *ngIf="!isEditMode">Create New Role</h1>
      <h1 *ngIf="isEditMode">{{roleForm.get('title').value}}</h1>
    </div>
  </div>

  <div class="form-container separate-form-container">
    <p *ngIf="!isEditMode">Please complete below required information to create a new Role.</p>


    <div class="pt-2">
      <form [formGroup]="roleForm"  autocomplete="off">
        <div class="form-row" *ngIf="!isCloneMode">
          <div class="col-md-6 mb-3"  style="padding-right: 0px !important;"
            [ngClass]="{'has-error': !roleForm.get('title').valid && (roleForm.get('title').touched || isSubmitted)}">
            <label for="title" class="asterisk">Role Name:</label>

            <input type="text" class="form-control mb-1" id="title" formControlName="title">
            <span
              *ngIf="!roleForm.get('title').valid && (roleForm.get('title').errors?.required || roleForm.get('title').errors?.validateWhiteSpaces) && (roleForm.get('title').touched || isSubmitted)">
              <i class="material-icons">warning</i> Please enter a Role Name.</span>
            <span
              *ngIf="!roleForm.get('title').valid && roleForm.get('title').errors?.maxlength && (roleForm.get('title').touched || isSubmitted)">
              <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Role.Title}}, please
              correct it.</span>
              <span style="color: #dd3435;"
              *ngIf="isNameError">
              <i class="material-icons">warning</i>{{errorNameMsg}}</span>
              <span *ngIf="!roleForm.get('title').valid && roleForm.get('title').errors?.pattern && (roleForm.get('title').touched || isSubmitted)"><i class="material-icons">warning</i>  {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}</span>
          </div>
        </div>
        <div class="form-row" *ngIf="isCloneMode">
          <div class="col-md-6 mb-3" style="padding-right: 0px !important;"
            [ngClass]="{'has-error': !roleForm.get('title').valid && (roleForm.get('title').touched || isSubmitted)}">
            <label for="title" class="asterisk">Role Name:</label>
            <input type="text" class="form-control" id="title" formControlName="title">
            <span style="color: red;"
              *ngIf="!roleForm.get('title').valid && (roleForm.get('title').errors?.required || roleForm.get('title').errors?.validateWhiteSpaces) && (roleForm.get('title').touched || isSubmitted)">
              <i class="material-icons">warning</i> Please enter a Role Name.</span>
            <span style="color: red;"
              *ngIf="!roleForm.get('title').valid && roleForm.get('title').errors?.maxlength && (roleForm.get('title').touched || isSubmitted)">
              <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Role.Title}}, please
              correct it.</span>
              <span style="color: red;"
              *ngIf=" roleForm.get('title').value == selectedRole.title">
              <i class="material-icons">warning</i> Please enter a new Role Name.</span>
              <span *ngIf="!roleForm.get('title').valid && roleForm.get('title').errors?.pattern && (roleForm.get('title').touched || isSubmitted)"><i class="material-icons">warning</i>  {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 mb-3"  style="padding-right: 0px !important;"
            [ngClass]="{'has-error': !roleForm.get('description').valid && (roleForm.get('description').touched || isSubmitted)}">
            <label for="description" class="asterisk">Description:</label>
            <!-- <input type="text" class="form-control" id="description" formControlName="description"> -->
            <textarea name="" class="form-control"  id="description" formControlName="description" cols="30" rows="2"></textarea>
            <span
              *ngIf="!roleForm.get('description').valid && (roleForm.get('description').errors?.required || roleForm.get('description').errors?.validateWhiteSpaces) && (roleForm.get('description').touched || isSubmitted)">
              <i class="material-icons">warning</i> Please enter a role description.</span>
              <span *ngIf="!roleForm.get('description').valid && roleForm.get('description').errors?.maxlength && (roleForm.get('description').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Role.Description}}, please correct it.
              </span>
              <span *ngIf="!roleForm.get('description').valid && roleForm.get('description').errors?.pattern && (roleForm.get('description').touched || isSubmitted)"><i class="material-icons">warning</i> {{regexValidationMessage.DESCRIPTION_COMMENT_VALIDATION_MESSAGE}}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 mb-3"  style="padding-right: 0px !important;"
            [ngClass]="{'has-error': !roleForm.get('displayOrder').valid && (roleForm.get('displayOrder').touched || isSubmitted)}">
            <label for="displayOrder" class="asterisk">Display Order:</label>

            <input type="number" class="form-control" id="displayOrder" formControlName="displayOrder" min="1">
            <span
              *ngIf="!roleForm.get('displayOrder').valid && (roleForm.get('displayOrder').errors?.required || roleForm.get('displayOrder').errors?.validateWhiteSpaces) && (roleForm.get('displayOrder').touched || isSubmitted)">
              <i class="material-icons">warning</i> Please enter the Display Order.</span>
            <span
              *ngIf="!roleForm.get('displayOrder').valid && roleForm.get('displayOrder').errors?.maxlength && (roleForm.get('displayOrder').touched || isSubmitted)">
              <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Role.Name}}, please
              correct it.</span>
              <span style="color: red;" *ngIf="(roleForm.get('displayOrder').errors?.min  && roleForm.get('displayOrder').touched) || roleForm.get('displayOrder').hasError('pattern')">
                <i class="material-icons">warning</i>{{regexValidationMessage.NUMERIC_VALIDATION_MESSAGE}}</span>
          </div>
        </div>

          <div class="col-md-6 box mb-3">
              <div class="col-md-6 mb-3 px-0">
                <label for="roleType" class="asterisk">Role Type:</label>
                <div class="radio-group d-flex">
                  <div class="radio mr-3">
                    <input type="radio" id="fiserv" name="roleType" value="1"
                      formControlName="roleType" (change)="setRoleType()">
                    <label for="fiserv"><span>Fiserv</span></label>
                  </div>
                  <div class="radio">
                    <input type="radio" id="client" name="roleType" value="2"
                      formControlName="roleType" (change)="setRoleType()">
                    <label for="client"><span>Client</span></label>
                  </div>
                </div>
              </div>
              <div class="form-check d-flex pl-1" *ngIf="roleForm.get('roleType').value == 1">
                <input class="" type="checkbox" value="" id="canbeAssignedToClient"  formControlName="canbeAssignedToClient" style="scale: 1.4;">
                <label class="form-check-label" for="canbeAssignedToClient" style="padding-left: 10px;">
                  Can be assigned to client
                </label>
              </div>
          </div>
      </form>
      <form action="">
        <div class="">
          <div class="col-md-12 box mb-1">
            <div class="col-md-12 p-0  fdl-2">
              <label aria-describedby="addAccount" class="w-100 mb-1 asterisk">Select/De-Select Permissions:
              </label>
              <button class="btn fdl-btn btn-primary-dark" data-toggle="modal" data-target="#toFormModal"
                id="addAccount" (click)="openPermissionsDrawer()">
                Select Permissions
              </button>

              <!-- Selected Permission buttons -->
              <div class="filter-wrapper">
                <div class="mt-3 mb-1 pl-0 col-xs-12 col-sm-12 col-md-12" *ngFor="let moduleName of moduleNameListAux;">
                  <p class="moduleName">{{moduleName.showName}} Permissions</p>
                  <div class="d-flex flex-wrap">
                    <div class="filter-tag d-inline mr-0" *ngFor="let selectedPermission of selectedPermissionsForDisplay; let i = index; ">
                      <div class="tag" data-toggle="tooltip" data-placement="top" title="{{selectedPermission.description}}"  *ngIf="selectedPermission.groupName == moduleName.name;">
                        {{selectedPermission.displayName}} <a href="javascript:void(0)"
                          (click)="removePermission(selectedPermission.permission)" aria-label="Close" class="tag-close">
                          <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3" class="has-error" *ngIf="selectedPermissionsForDisplay.length === 0 && isSubmitted">
            <span>
              <i class="material-icons">warning</i> Please select at least one Permission.
            </span>
          </div>
        </div>
      </form>
      <div class="fdl-2 button-container" *ngIf="!isDrawerType">
        <button type="button" class="btn fdl-btn btn-secondary-light" (click)="goBack()" aria-label="Cancel">Cancel</button>
        <button type="button" class="btn fdl-btn btn-primary-orange" (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </div>

  <!-- Drawer -->
  <div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" id="createRoleDrawer" tabindex="-1"
    aria-labelledby="addNewRole" aria-expanded="true" role="region" *ngIf="showDrawer">
    <div class="drawer-contents position-relative w-100 col-xs-12 col-sm-12 col-md-12">
      <div class="position-sticky bg-white" style="z-index: 1;">
        <h3>Select Permissions</h3>
        <p>Please complete below required information to select/de-select permissions.</p>
        <div class="row" [ngClass]="{'has-error': !isSearchPermissionsValid(searchString) || !isSearchPermissionsValid(searchPermissionString)}" *ngIf="allPermissions.length > 0">
          <div class="input-group search-box mb-3 col-md-12">
            <input type="text" class="form-control" placeholder="Search Module..." id="searchString" name="searchString" [(ngModel)]="searchString">
          </div>
          <span class="pl-3 mb-2" *ngIf="!isSearchPermissionsValid(searchString)">
            <i class="material-icons">warning</i>  {{ regexValidationMessage.NAME_VALIDATION_MESSAGE }}</span>
          <div class="input-group search-box mb-3 col-md-12">
            <input type="text" class="form-control" placeholder="Search Permission..." id="searchString" name="searchString" [(ngModel)]="searchPermissionString">
          </div>
          <span class="pl-3" *ngIf="!isSearchPermissionsValid(searchPermissionString)">
            <i class="material-icons">warning</i>  {{ regexValidationMessage.NAME_VALIDATION_MESSAGE }}</span>
        </div>
      </div>
      <form [formGroup]="permissionFieldsForm">
        <fieldset>
          <legend class="border-0"></legend>
          <div class="mb-1" *ngFor="let moduleName of moduleNameList; let j = index">
            <div class="box collapse-view" style="margin-bottom: 10px;" [hidden]="filterByMainTitle(moduleName, searchString)">
              <a class="font-weight-bold collapse-arrow collapse-title" style="text-decoration: none; color:black;" data-toggle="collapse" aria-expanded="true" [hidden]="filterByMainTitle(moduleName, searchString)" [href]="'#collapseSubModules' + j"> Select/De-Select {{ moduleNameLabelList[j] }} Permission</a>
              <div id="{{'collapseSubModules' + j}}" class="collapse show mt-3" [hidden]="filterByMainTitle(moduleName, searchString)">
              <div class="checkbox pl-2" *ngIf="searchPermissionString === ''">
                <input type="checkbox" [checked]="isAllCheckBoxChecked(moduleName)" (change)="toggleFullModule(moduleName)" id="{{moduleName}}"/>
                <label style="padding-left: 25px;" title="{{moduleName}}" for="{{moduleName}}"><span>Select All</span></label>
              </div>
              <div class="control" *ngFor="let control of permissionFieldsForm.controls.permissionFields['controls']; let i = index">
                  <div class="checkbox pl-2" formArrayName="permissionFields" *ngIf="allPermissions[i].groupName == moduleName && filterByTitle(allPermissions[i].displayName, searchPermissionString)">
                    <input type="checkbox" [formControlName]="i" id="{{'chk'+ i}}" ng-checked="check"/>
                    <label style="padding-left: 25px;" title="{{allPermissions[i].description}}"
                      for="{{'chk'+ i}}"><span>{{allPermissions[i].displayName}}</span></label>
                  </div>
              </div>
            </div>
            </div>
          </div>
        </fieldset>
        <div class="pt-3" style="position: sticky !important; bottom: 0;">
          <div class="fdl-2 button-container bg-white w-100"  style="z-index: 2;">
            <button class="btn fdl-btn btn-secondary-light"  type="button" (click)="drawerCancel()">Cancel</button>
            <button class="btn fdl-btn btn-primary-orange" type="button" (click)="drawerSubmit()">Assign</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="backdrop-space" *ngIf="showDrawer">
    <div class="backdrop"></div>
  </div>
