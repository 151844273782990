import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";
import { REGEX_PATTERNS, MAX_LENGTHS, MimeTypeConstants } from 'src/app/core/constants';

let Extensions = [ "jpg" ,"jpeg" , "png" ,"tif" ,"tiff" ,"ai" , "bmp" ,"gif" ,"ico" ,"psd" ,"svg" ,"txt" ,"doc" ,
"docx" ,"xls" ,"xlsx" ,"ppt" ,"pptx" ,"html" ,"htm" ,"csv" ,"log" ,"xml" ,"eml" ,"email" ,"emlx" ,"avi" , "msg" ,
"3gp" ,"flv" ,"mp4" ,"mpeg" ,"mpg" ,"swf", "pdf", "zip", "json", "tsu"]

let ValidContentType = ["application/javascript", "application/json", "application/ld+json", "application/msword", "application/pdf", "application/sql",
"application/vnd.api+json", "application/vnd.ms-excel", "application/vnd.ms-powerpoint", "application/vnd.oasis.opendocument.text",
"application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
"application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/x-www-form-urlencoded", "application/xml", "application/zip",
"application/zstd", "audio/mpeg", "audio/ogg", "image/avif", "image/jpeg", "image/gif", "image/png", "image/svg+xml", "multipart/form-data", "text/css",
"text/csv", "text/html", "text/xml", "text/plain", "application/zip", "application/x-zip-compressed" , "application/octet-stream", "video/mp4", ""]


export class FileValidators {

	private static maxFileSize = MAX_LENGTHS.File.Size;
	static ValidateFileSize(control: AbstractControl) {
		const file: File = control.value;
		if (file == null) return;

		const fileSize = +(file.size / (1024 * 1024)).toFixed(2);
		if (fileSize > FileValidators.maxFileSize) {
			return { invalidSize: true };
		}

		return null;
	}

	static validateFileSize2(file: File) {
		const fileSize = +(file.size / (1024 * 1024)).toFixed(2);
		if (fileSize > FileValidators.maxFileSize) {
			return { invalidSize: true };
		}
		return null;
	}

	static ValidateExtension(control: AbstractControl) {
		const file: File = control.value;
		if (file == null) return;

		//let ext = ["exe"];
		let lastIndex = file.name.lastIndexOf(".");
		let fileExtension = file.name.substring(lastIndex + 1);

		if (!Extensions.includes(fileExtension.toLowerCase())) {
			return { invalidExt: true };
		}
		return null;
	}

	static validateExtensionFile(file: File) {
		let lastIndex = file.name.lastIndexOf(".");
		let extension = file.name.substring(lastIndex + 1);
		if (!Extensions.includes(extension.toLocaleLowerCase())) {
			return { invalidExt: true }
		} 
    	return { invalidExt: false };
	}

	static checkAllowedExtensions(extensions: string[]): ValidatorFn {

		return (control: AbstractControl): ValidationErrors | null => {
			const file: File = control.value;
			if (file == null) return;

			let lastIndex = file.name.lastIndexOf(".");
			let fileExtension = file.name.substring(lastIndex + 1);

			if (!extensions.includes(fileExtension.toLowerCase())) {
				return { invalidExt: true };
			}
			return null;
		}
	}

	static ValidateContentTypeFile() {
		return (file: File): ValidationErrors | null => {
			if (!ValidContentType.includes(file.type)) {
				return { invalidType: true };
			}
      		return { invalidType: false };
		}
	}

		
	static ValidateContentType(): ValidatorFn {
	
		return (control: AbstractControl): ValidationErrors | null => {
			const file: File = control.value;
			if (file == null) return;
			
			if (!ValidContentType.includes(file.type.toLowerCase()))
				return { invalidType: true };
			
				return null;
		}

	}

	static CheckAllowedContentType(event : any , contentType: string) {
		const target: DataTransfer = <DataTransfer>event.target;
		let fileList: FileList = target.files;
		if (fileList.length > 0) {
			const file = fileList.item(0);
		
			if (file == null) return;
			
			if (contentType.match(file.type.toLowerCase()))
				return  true ;
			else
				return false;
		}
			return false;
	}

	static ValidateFileName(fileName : string){
		return REGEX_PATTERNS.FileName.test(fileName);
	}
		  
}
