import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MasterService } from 'src/app/services/master.service';
import { FormExtractionTemplateService } from 'src/app/services/form-extraction-template.service';
import { IFormExtraction } from 'src/app/shared/formExtraction';

@Component({
  selector: 'form-template-view',
  templateUrl: './form-template-view.component.html'
})
export class FormTemplateViewComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private formExtractionService: FormExtractionTemplateService,
    private masterService: MasterService) { }

  formTemplateId: number
  formExtraction: IFormExtraction

  ngOnInit(): void {
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.formTemplateId = params['id'];
        if (this.formTemplateId > -1) {
          this.getFormTemplateData();
        }
      });
  }

  getFormTemplateData() {
    this.formExtractionService.getFormExtractionById(this.formTemplateId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.formExtraction = data;
        }
      );
  }

  goBack(){
    this.masterService.isCancelledClicked(true);
    this.router.navigate(['/self-service/formExtracts']);
  }
}
