import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import {HelpCategoryService } from '../../helpCategory.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { ISuggestion } from '../../../../../dashboard/shared/dashboard';
import { ProjectsService } from '../../../../../services/projects.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { IHelpCategory } from '../../../../../shared/helpCategory';
@Component({
  selector: 'app-helpCategory-form',
  templateUrl: './helpCategory-form.component.html'
})
export class HelpCategoryFormComponent implements OnInit, OnDestroy {

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private helpCategoryService: HelpCategoryService,
    private router: Router,
    private userService: UserService, private projectService: ProjectsService) { }

    @Input() helpCategoryId: number;
    @Input() isEditMode: boolean;
    invalidOrganizationName: boolean = false;
    helpCategoryForm: FormGroup;
    Form: IHelpCategory;
    isSubmitted = false;
    isError: boolean = false;
    errorMsg: string;
    organizationId: number;
    _maxLength: any;
    isAdmin: boolean;
    returnPage: string;
    organizationSuggestion: ISuggestion[];
    organizationDetails: any;
    regexValidationMessage: any;

  ngOnInit(): void {
      this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
      this._maxLength = MAX_LENGTHS;
      this.isAdmin = this.userService.getUserListData().isAdmin;
      this.organizationDetails = Object.assign({}, this.userService.getUserListData());
      this.initForm();
      if (this.helpCategoryId > 0) {
        this.helpCategoryService.getHelpCategoryDetailsById(this.helpCategoryId).subscribe(
          result => {
            this.isError = false;
            this.Form = result;
            this.helpCategoryService.getOrganizationDetailsById(this.Form.organizationId).subscribe(
              result => {
                this.Form.organizationName = result.title;
                this.patchForm();
              },
              (error) => { this.isError = true; this.errorMsg = error; }
            );
            this.patchForm();
          },
          (error) => { this.isError = true; this.errorMsg = error; });
      }
      if (this.isAdmin)
        this.getOrganizationSuggestions();

      if (this.helpCategoryService.tempData)
        this.returnPage = 'Resource Category';
      else
        this.returnPage = 'Self Service';
    }

    ngOnDestroy(): void {
      this.helpCategoryService.tempData = "";
  }

  patchForm() {
    this.helpCategoryForm = this.fb.group({
      name: [this.Form.name, [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator), Validators.maxLength(this._maxLength.HelpCategory.Name), NoWhitespaceValidator.validateWhiteSpaces]],
      description: [this.Form.description, [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator), Validators.maxLength(this._maxLength.HelpCategory.Description), NoWhitespaceValidator.validateWhiteSpaces]],
      isCommonCategory: this.Form.isCommonCategory,
      organization: [this.Form.organizationName, [Validators.required, Validators.pattern(REGEX_PATTERNS.organization_name_validator)]],
      organizationId: this.Form.organizationId
    });
  }

    initForm() {
      this.helpCategoryForm = this.fb.group({
        name: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator),
          Validators.maxLength(this._maxLength.HelpCategory.Name), NoWhitespaceValidator.validateWhiteSpaces]],
        description: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator),
          Validators.maxLength(this._maxLength.HelpCategory.Description), NoWhitespaceValidator.validateWhiteSpaces]],
        isCommonCategory: false,
        organization: [this.userService.getUserListData().organizationTitle, [Validators.required, Validators.pattern(REGEX_PATTERNS.organization_name_validator)]],
        organizationId: this.userService.getUserListData().organization
      });
    }

  onSubmit(): void {
    this.isSubmitted = true;
    var value = this.helpCategoryForm.get('organization').value;

    if (this.isAdmin)
      this.organizationId = this.projectService.fetchIdForRequest(this.organizationSuggestion, (value || ''));
    else
      this.organizationId = this.userService.getUserListData().organization;

    this.helpCategoryForm.addControl('organizationId', new FormControl('', Validators.required));
    this.helpCategoryForm.patchValue({
      organizationId: this.organizationId
    });

    if (this.helpCategoryForm.valid) {
      this.helpCategoryService.createHelpCategory(this.helpCategoryForm.value)
        .subscribe(() => {
          this.helpCategoryService._newCreatedHelpCategory = this.helpCategoryForm.value['name'];
          this.helpCategoryService._isHelpCategoryCreated = true;
          this.router.navigate(['self-service/helpCategory']);
          this.isError = false;
        },
          error => {
            this.isError = true;
            this.errorMsg = error;
          });
    }
  }

  update() {
    this.isSubmitted = true;
    var value = this.helpCategoryForm.get('organization').value;
    if (this.isAdmin)
      this.organizationId = this.projectService.fetchIdForRequest(this.organizationSuggestion, (value || ''));
    else
      this.organizationId = this.userService.getUserListData().organization;

    this.helpCategoryForm.addControl('organizationId', new FormControl('', Validators.required));
    this.helpCategoryForm.patchValue({
      organizationId: this.organizationId
    });

    if (this.helpCategoryForm.valid) {
      const updateHelpCategory = {... this.helpCategoryForm.value, id: this.helpCategoryId}
      this.helpCategoryService.updateHelpCategory(this.helpCategoryId, updateHelpCategory).subscribe(
        () => {
          this.helpCategoryService._helpCategoryUpdated = true;
          this.router.navigate(['/self-service/helpCategory/view', this.helpCategoryId]);
          this.isError = false;
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        });
    }
  }

  getOrganizationSuggestions() {
    this.projectService.getSuggestions('organizations', '')
      .subscribe(suggestions => {
        this.organizationSuggestion = suggestions as unknown as ISuggestion[];
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  goBack() {
    this.helpCategoryService.tempData = "";
    if (this.isEditMode) {
      this.router.navigate(['/self-service/helpCategory/view', this.helpCategoryId]);
    } else {
      // this.router.navigate(['self-service/helpCategory']);
      this.location.back();
    }
  }

  get isCommonCategory(){
    return this.helpCategoryForm.get('isCommonCategory');
  }
}


