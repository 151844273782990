<div>
    <!-- Error Msg Alert -->
    <div class="alert alert-dismissible alert-danger" *ngIf="errorMsg">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="errorMsg=''">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>
    <!-- Success Msg Alert -->
    <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="successMsg=''">×</button>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p [innerHtml]="successMsg"></p>
    </div>
</div>
<div class="justify-content-between d-flex">
    <div class="headline-container mb-1">
        <p *ngIf="pagerObject">Total {{pagerObject.totalCount | number}} items</p>
    </div>
    <div class="d-flex">
        <div class="action-container fdl-2">
            <button (click)="onSave()" [disabled]="!enableSaveButton" class="btn fdl-btn btn-primary-orange mr-2"
                id="actionUpdateCommitmentsDwDialog" type="button">Save</button>
        </div>
        <div class="action-container fdl-2">
        </div>
        <div class="refresh-icon-link mt-1">
            <a id="refreshTask" title="Refresh eCRM Feed List" class=""><i aria-hidden="true" class="fa fa-refresh"
                    (click)="refresh()"></i></a>
        </div>

        <div class="dropdown downloadDropdownMenuButton mt-0">
            <button type="button" title="Download Extract" id="downloadDropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="true" class="btn btn fdl-btn btn-primary-dark btn-addcomment">
                <span class="material-icons material-icons-wrapper"> get_app </span>
            </button>
            <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
                <a class="dropdown-item" (click)="exportExcel()">Export List</a>
            </div>
        </div>
        <!-- <div class="refresh-icon-link mt-1">
            <a href="javascript:void(0);" data-toggle="modal" class="clickable"><span
                    class="material-icons">settings</span></a>
        </div> -->
    </div>
</div>
<p>
    Commitments listed below are Flagged for Tracking and visible on Client commitment dashboard. Please review each
    commitment and Edit any missing information as required related to a commitment. Clarity details are made available
    for review purpose only. Use 'Select' checkbox and 'At Risk' checkbox to tag commitment's for Risk. Click on the
    Save button once done.
</p>
<div class="table-responsive overflow-auto pt-2">
    <table id="parent" aria-describedby="tblDesc" class="f-12 table table-hover main-nested-table table-label"
        *ngIf="data && data.length > 0">
        <thead class="parent-thead">
            <tr role="row">
                <th>

                </th>
                <th>Track</th>
                <th>Is Risk</th>
                <th>Risk Reason</th>
                <th>Title</th>
                <th>Commitment Title</th>
                <th>Product</th>
                <th>Other Product Name</th>
                <th>Client Name</th>
                <th>DUNS</th>
                <th>Data Source</th>
                <th>Relationship Manager</th>
                <th>Commitment Status</th>
                <th>Calculated Risk Status</th>
                <th>Calculated Risk Status Reason</th>
                <th role="columnheader" scope="col">
                    <span> Status </span>
                </th>
                <th style="background: white; position: sticky; right: 0;" role="columnheader" scope="col">Actions</th>
            </tr>
        </thead>
        <tbody class="parent-tbody" *ngFor="let item of data; let i = index">
            <tr role="row" class="tr-parent grey collapsed">
                <td role="cell">
                    <div>
                        <a id="{{'arrow'+ i}}" href="{{'#tableParentAccordion'+ i}}"
                            class="material-icons td-parent-accordion collapsed" data-toggle="collapse"
                            aria-expanded="false"></a>
                    </div>

                </td>
                <td role="cell">
                    <div class="checkbox">
                        <input [disabled]="item.isInternalProject" type="checkbox" id="{{'isTracked'+ i}}"
                            [(ngModel)]="item.selected" (change)="onFieldChanged($event, item, 'isTracked')">
                        <label for="{{'isTracked'+ i}}"></label>
                    </div>
                </td>
                <td role="cell">
                    <div class="checkbox">
                        <input type="checkbox" id="{{'isRisk'+ i}}" [(ngModel)]="item.isRisk"
                            (change)="onFieldChanged($event, item, 'isRisk')">
                        <label for="{{'isRisk'+ i}}"></label>
                    </div>
                </td>
                <td>
                    <div class="fdl-select"
                        [ngClass]="{'has-error': checkDependentFieldHasError(item, 'isRisk', 'riskReason')}">
                        <select type="text" class="data-input form-control" [(ngModel)]="item.riskReason"
                            (change)="onFieldChanged($event, item, 'riskReason')" [disabled]="!item.isRisk">
                            <option *ngFor="let riskReason of riskReasons">
                                {{riskReason.text}}
                            </option>
                        </select>
                    </div>
                </td>
                <td>
                    <label title="{{item.title}}">
                        {{item.title}}
                    </label>
                </td>
                <td>
                    <label>
                        {{item.customeFormFieldListJsonAnswer?.commitmentTitleIntake}}
                    </label>
                </td>
                <td>
                    <label title="{{item.productTitle}}">
                        {{item.productTitle}}
                    </label>
                </td>
                <td>
                    <label>
                        {{item.customeFormFieldListJsonAnswer?.otherProductName}}
                    </label>
                </td>
                <td>
                    <label>{{item.customeFormFieldListJsonAnswer?.drmsReservedClientName}} </label>
                </td>
                <td>
                    <label>
                        {{item.customeFormFieldListJsonAnswer?.dUNSC ? item.customeFormFieldListJsonAnswer?.dUNSC :
                        item.customeFormFieldListJsonAnswer?.drmsReservedClientDunsId}}
                    </label>
                </td>
                <td>
                    <label> {{transformDataSourceString(item.dataSource)}} </label>
                </td>
                <td>
                    <label>
                        {{item.customeFormFieldListJsonAnswer?.relationshipManagerAccountExecutiveIntake}}
                    </label>
                </td>
                <td role="cell">
                    <label for="myCheckboxZero" class="badge-table">
                        {{item.customeFormFieldListJsonAnswer?.statusIntake}}
                    </label>
                </td>
                <td>
                    <label
                        [ngStyle]="{'color': item.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor != '' ? item.customeFormFieldListJsonAnswer?.drmsReservedRowHighlightColor : ''}">
                        {{item.customeFormFieldListJsonAnswer?.hdnRiskStatus}}
                    </label>
                </td>
                <td>
                    <label>
                        {{item.customeFormFieldListJsonAnswer?.hdnRiskStatusReason}}
                    </label>
                </td>
                <td role="cell">
                    <label for="myCheckboxZero" class="badge-table">
                        <span [ngClass]="getStatusCSSClass(item.pageTemplateResponseStatusTitle)"></span>
                        {{item.pageTemplateResponseStatusTitle}}
                    </label>
                </td>
                <td (click)="addZIndex(i)" [attr.id]="'actions-' + i" class="actions" role="cell">
                    <div (click)="addZIndex(i)" class="kebab-menu dropleft">
                        <a href="javascript:void(0);" (click)="addZIndex(i)" data-toggle="dropdown" id="headerSwitcher"
                            aria-expanded="false">
                            <span class="material-icons"> more_vert </span>
                        </a>
                        <div x-placement="left-start"
                            class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher">
                            <div class="dropdown-list-option">
                                <a class="dropdown-item" (click)="onViewClicked(item.id)">View &amp; Edit</a>
                                <a *ngIf="item.isDeleteOrEditable" class="dropdown-item"
                                    (click)="onEditClicked(item.id)">Edit</a>
                                <a *ngIf="!item.isReadOnlyRole && canDelegate(item)" class="dropdown-item"
                                    data-toggle="modal" data-target="#delegateCommitment"
                                    (click)="openDelegateModal(item.id)">Delegate</a>
                                <!-- <a *ngIf="item.isInternalProject" class="dropdown-item" data-toggle="modal"
                                    data-target="#copyModal" (click)="onCopyClicked(item)">Copy </a> -->
                                <a *ngIf="!item.isReadOnlyRole && !item.isInternalProject" class="dropdown-item"
                                    (click)="openMapFulfillment(item.id, item.title, true, '', !item.isReadOnlyRole)">Map
                                    Fulfillment</a>
                                <a *ngIf="item.isDeleteOrEditable" (click)="onDeleteModalClicked(item.id)"
                                    data-toggle="modal" class="dropdown-item" data-target="#deleteModal_one">Delete</a>
                            </div>
                        </div>
                    </div>
                </td>

            </tr>
            <tr id="{{'tableParentAccordion' + i}}" class="tr-child grey collapse">
                <td colspan="17" class="td-child">

                    <!-- Mapped Opportunity -->
                    <div class="table-child-container accordion collapse-view white">
                        <div class="nested-table-header">
                            <a class="table-child-heading" data-toggle="collapse"
                                href="{{'#tableChildOpportunityAccordion'+ i}}" aria-expanded="true">
                                <span>Mapped Opportunity</span>
                            </a>
                        </div>

                        <div class="filter-wrapper collapse show" id="{{'tableChildOpportunityAccordion'+ i}}">
                            <div *ngIf="item.mappedOpportunities">
                                <div class="filter-tag filter-action action"
                                    *ngFor="let opportunity of item.mappedOpportunities;">
                                    <div class="tag actionDwDialog">
                                        <div class="data-wrapper">
                                            <span class="label">Opportunity ID</span>
                                            <span class="value">{{opportunity.OpportunityId}}</span>

                                        </div>
                                        <div class="data-wrapper">

                                            <span class="label">Opportunity System</span>
                                            <span class="value">{{opportunity.opportunitySystem}}</span>
                                        </div>
                                        <a *ngIf="item.dataSource !== 'ecrm'" href="javascript:void(0)"
                                            aria-label="Close" class="tag-close"><i tabindex="0" aria-hidden="true"
                                                class="material-icons">cancel</i></a>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!item.mappedOpportunities || item.mappedOpportunities.length === 0"
                                class="two-col-nested-child">
                                <div class="filter-wrapper collapse show">
                                    <div class="filter-tag not-avil-message ">
                                        <div class="tag">
                                            <div class="data-wrapper"> <span class="label">Not Available.</span> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mt-3 get_suggestion" (click)="getOpportunitySuggestions(item)">
                                <a><span><i class="material-icons get_suggestion_icon">vertical_align_bottom</i> Get
                                        Opportunity Suggestions</span></a>
                            </div>
                        </div>
                    </div>

                    <!-- Mapped Fulfillment -->
                    <div class="table-child-container accordion collapse-view grey">
                        <div class="nested-table-header">
                            <a class="table-child-heading" data-toggle="collapse"
                                href="{{'#tableChildMappedFulfillmentAccordion'+ i}}" aria-expanded="true">
                                <span>Mapped Fulfillment</span>
                            </a>
                        </div>
                        <div class="filter-wrapper collapse show" id="{{'tableChildMappedFulfillmentAccordion'+ i}}">
                            <div *ngIf="item.mappedFulfillments" class="row">
                                <div class="col-md-12">
                                    <div class="filter-tag filter-action action"
                                        *ngFor="let mappedFulfillment of item.mappedFulfillments; let i = index"
                                        (click)="openMapFulfillment(item.id, '', false, mappedFulfillment.projectNumber, !item.isReadOnlyRole && mappedFulfillment.canUnmap)">
                                        <div class="tag actionDwDialog">
                                            <div class="data-wrapper">
                                                <span class="label">Project Number</span>
                                                <span class="value">{{mappedFulfillment.projectNumber}}</span>
                                            </div>
                                            <a href="javascript:void(0)" *ngIf="mappedFulfillment.canUnmap === true"
                                                aria-label="Close" class="tag-close"><i tabindex="0" aria-hidden="true"
                                                    class="material-icons">cancel</i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!item.mappedFulfillments || item.mappedFulfillments.length === 0"
                                class="two-col-nested-child">
                                <div class="filter-wrapper collapse show">
                                    <div class="filter-tag not-avil-message ">
                                        <div class="tag">
                                            <div class="data-wrapper"> <span class="label">Not Available.</span> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mt-3 get_suggestion" (click)="getFulfillmentSuggestions(item)">
                                <a><span><i class="material-icons get_suggestion_icon">vertical_align_bottom</i> Get
                                        Fulfillment Suggestions</span></a>
                            </div>
                        </div>
                    </div>

                    <!-- Matching Suggestions -->
                    <div *ngIf="currentIndex === item.id" class="table-child-container accordion collapse-view white">
                        <div>
                            <div class="nested-table-header">
                                <a class="table-child-heading" data-toggle="collapse"
                                    href="{{'#tableChildSuggestionsAccordion'+ i}}" aria-expanded="true">
                                    <span *ngIf="getFulfillmentSuggestionsClicked">Matching Fulfillment
                                        Suggestions</span>
                                    <span *ngIf="getOpportunitySuggestionsClicked">Matching Opportunity
                                        Suggestions</span>
                                </a>
                            </div>
                        </div>

                        <div style="max-height: 300px; overflow-y: scroll;">
                            <table class="f-12 table child-nested-table table-label collapse show"
                                id="{{'tableChildSuggestionsAccordion'+ i}}">

                                <!-- For Fulfillment Suggestions -->
                                <tbody
                                    *ngIf="item.fulfillmentSuggestions && item.fulfillmentSuggestions.length > 0 && getFulfillmentSuggestionsClicked"
                                    class="child-tbody">
                                    <tr>
                                        <td colspan="4">
                                            <div class="link-action">
                                                <a (click)="removeSuggestions(item)">
                                                    <i class="material-icons remove_suggestion_icon">delete</i>
                                                    <span>Remove Suggestions</span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style="background: #FAFAFA; position: sticky; top: 0; z-index: 3;">
                                        <th><label style="margin-left: 38px;">Project Title</label></th>
                                        <th><label>Project Number</label></th>
                                        <th><label>Product</label></th>
                                        <th><label></label></th>
                                    </tr>
                                    <tr style="z-index: 1;"
                                        *ngFor="let suggestion of item.fulfillmentSuggestions; let i = index">
                                        <td>
                                            <div class="d-flex justify-content-start">
                                                <a class="link" title="View details"
                                                    (click)="openMapFulfillment(item.id, item.title, true, suggestion.projectNumber, !item.isReadOnlyRole)">
                                                    <div class="material-icons grey mt-1">chevron_right</div>
                                                </a>
                                                <div class="map_div_icon"></div>
                                                <label>{{suggestion.title}}</label>
                                            </div>
                                        </td>
                                        <td><label>{{suggestion.projectNumber}}</label></td>
                                        <td><label>{{suggestion.products}}</label></td>
                                        <td><label></label></td>
                                    </tr>
                                </tbody>

                                <!-- For Opportunity Suggestions -->
                                <tbody
                                    *ngIf="item.opportunitySuggestions && item.opportunitySuggestions.length > 0 && getOpportunitySuggestionsClicked"
                                    class="child-tbody">
                                    <tr>
                                        <td style="border: none;">
                                            <div class="link-action">
                                                <a (click)="removeSuggestions(item)">
                                                    <i class="material-icons remove_suggestion_icon">delete</i>
                                                    <span>Remove Suggestions</span>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><label style="margin-left: 38px;">Opportunity</label></th>
                                        <th><label>ID</label></th>
                                        <th><label>Product</label></th>
                                        <th><label></label></th>
                                    </tr>
                                    <tr *ngFor="let suggestion of item.opportunitySuggestions; let i = index">
                                        <td>
                                            <div class="d-flex justify-content-start">
                                                <a class="link" title="View details"
                                                    (click)="openMapFulfillment(item.id, item.title, true, suggestion.projectNumber, !item.isReadOnlyRole)">
                                                    <div class="material-icons grey mt-1">chevron_right</div>
                                                </a>
                                                <div class="map_div_icon"></div>
                                                <label>{{suggestion.title}}</label>

                                            </div>
                                        </td>

                                        <td><label>{{suggestion.opportunityId}}</label></td>
                                        <td><label>{{suggestion.products}}</label></td>
                                        <td><label></label></td>
                                    </tr>
                                </tbody>

                                <!-- No results -->
                                <tbody
                                    *ngIf="((!item.opportunitySuggestions || item.opportunitySuggestions.length === 0) && getOpportunitySuggestionsClicked)
                                    || ((!item.fulfillmentSuggestions || item.fulfillmentSuggestions.length === 0) && getFulfillmentSuggestionsClicked)"
                                    class="child-tbody">
                                    <tr>
                                        <td colspan="4">
                                            <div class="filter-wrapper collapse show">
                                                <div class="filter-tag not-avil-message ">
                                                    <div class="tag">
                                                        <div class="data-wrapper"> <span class="label">Not
                                                                Available.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </td>
            </tr>
        </tbody>
        <div style="width: max-content;" *ngIf="!data || data.length === 0" class="headline-container mb-1 text-center">
            <p>No data available</p>
        </div>
    </table>
</div>
<div *ngIf="pagerObject">
    <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 " [pagerObject]="pagerObject"
        (pagingChanged)="changePage($event)"></app-pagination>
</div>

<app-commitments-update-modal *ngIf="showUpdateCommitmentsIntakeDrawer" [updatedCommitments]="commimentsTobeUpdated"
    [untrackedCommitments]="commimentsTobeUntracked" (closeModal)="closeUpdateCommitmentModal()"
    (updateCommitment)="submitRequest()"></app-commitments-update-modal>

<app-commitments-delegate *ngIf="showDelegateModal" (delegateCompleted)="successDelegateClick($event)"
    [pageTemplateResponseId]="responseId" [pageTemplate]="pageTemplate"></app-commitments-delegate>
<!-- <app-commitment-copy *ngIf="showCopyModal" [selectedForCopy]="selectedForCopy"
    (submit)="onComponentSubmited()"></app-commitment-copy> -->
<app-map-fulfilment *ngIf="showMapFulfilmentModal" [responseId]="responseId" [projectId]="projectId"
    [title]="fulfillmentTitle" [isCalledForMap]="isCalledForMap" [canMapOrUnmap]="canMapOrUnmap"
    (submit)="onMapFulfilmentComponentSubmited()" (cancel)="closeMapFulfilmentDrawer()"></app-map-fulfilment>
<app-commitments-intake-delete-modal [id]="idForDelete"
    (delete)="onComponentSubmited()"></app-commitments-intake-delete-modal>