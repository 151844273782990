<div class="heading-container align-items-start" style="padding-right: 11px;">
  <div class="back-action-container">
    <div class="back-button" *ngIf="!isUnauthenticated">
      <a href="javascript:void(0);" (click)="cancel()">
        <span class="material-icons">
          arrow_back
        </span>
        Forms
      </a>
    </div>

    <h1>{{headerVal}}</h1>
  </div>
  <div class="d-flex progress-bar-wrapper">
    <div class="linear-loader-container" *ngIf="!isIntake">
      <div class="linear-loader">
        <div class="indicator" attr.style="width: {{percentage | number:'1.0-2'}}%;"></div>
      </div>
      <span>{{percentage | number:'1.0-0'}}%</span>
    </div>
    <div class="action-wrapper" *ngIf="!showAction && !isHistory && !isIntake">
      <div class="dropdown">
        <button class="btn fdl-btn btn-primary-dark  dropdown-toggle action-button" type="button"
          id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          Take Action <i class="material-icons arrow-opener">arrow_drop_down</i>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="bottom-start">

          <a class="dropdown-item" (click)="pdfOnClick('json')">Export JSON</a>

          <!-- <a class="dropdown-item"  (click)="pdfOnClick('pdf')">Download PDF</a> -->
        </div>
      </div>
    </div>
    <div id="progress-bar-options" class="action-wrapper" *ngIf="showAction">
      <div class="dropdown">
        <button class="btn fdl-btn btn-primary-dark  dropdown-toggle action-button" type="button"
          id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          More Options <i class="material-icons arrow-opener">arrow_drop_down</i>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="bottom-start">
          <a class="dropdown-item" *ngIf="isTakeDelegateAction()" [attr.disabled]="isDelegateDisabled()"
            (click)="delegateAction()">Delegate Form</a>
          <a class="dropdown-item" (click)="pdfOnClick('exportAnswer')">Export Response Data</a>

          <a class="dropdown-item" [attr.disabled]="isUploadAnswerDisabled()"
            (click)="pdfOnClick('importAnswer')">Import Response Data</a>
          <!--<a class="dropdown-item"  (click)="pdfOnClick('pdf')">Download PDF</a>-->

        </div>
      </div>


    </div>
    <app-delegate type="viewResponse" [delegateFormData]="delegateFormData" mode="delegate"></app-delegate>
  </div>
</div>