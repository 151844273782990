import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { IPager } from 'src/app/shared/pagination';
import { UtilService } from 'src/app/services/util.service';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})

export class PaginationComponent implements OnInit, OnChanges {

  constructor(private utilService: UtilService, private contentService: ContentService){}
  @Input() template: any = 1;
  @Input() pagerObject: IPager
  @Output() pagingChanged = new EventEmitter();
  pageCountArray:number[] = [];
  content: any = [];

  ngOnInit(): void {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    this.calculatePages();
  }
  ngOnChanges() {
    this.calculatePages();
  }

  setPage(number){
    if(this.pagerObject.currentPage === number) return;
    this.pagerObject.currentPage = number;
    this.triggerEvent();
  }
  
  nextPage(){
    if(this.pagerObject.currentPage === this.pagerObject.totalPages) return;
    this.pagerObject.currentPage++;
    this.triggerEvent();
  }

  prevPage(){
    if(this.pagerObject.currentPage === 1) return;
    this.pagerObject.currentPage--;
    this.triggerEvent();
  }

  goToFirst(){
    if(this.pagerObject.currentPage === 1) return;
    this.pagerObject.currentPage = 1;
    this.triggerEvent();
  }

  goToLast(){
    if(this.pagerObject.currentPage === this.pagerObject.totalPages) return;
    this.pagerObject.currentPage = this.pagerObject.totalPages;
    this.triggerEvent();
  }

  triggerEvent(){
    this.pagingChanged.emit(this.pagerObject.currentPage);
  }


  calculatePages(){
    this.pageCountArray = [];
    const range = this.utilService.pageRange(this.pagerObject.currentPage, this.pagerObject.totalPages);
    for(var i = range.start; i <= range.end; i++){
      this.pageCountArray.push(i);
    }
  }

}
