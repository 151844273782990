import { Injectable } from '@angular/core';
import { IStakeHolder } from 'src/app/shared/stakeholder';
import { threadId } from 'worker_threads';



@Injectable({
  providedIn: 'root'
})

export class ProjectsDTOService {
  private createProjectStatus: boolean;
  private editProjectStatus: boolean;
  private parentType: string;
  private projectFacts: any = {};
  private oldProjectFacts: any = {};
  private projectResponse: string;
  private changedProjManager: string;
  private publishForms: boolean;
  private assignFormSection: boolean;
  private initiateDGFData;
  private assignedForms;
  private publishRequestForms;
  private projectStakeholders: IStakeHolder[]

  constructor() { }

  setProjectStakeHolders(stakeholders: IStakeHolder[])  {
    this.projectStakeholders = stakeholders;
  }

  getProjectStakeHolders(): IStakeHolder[] {
    return this.projectStakeholders;
  }

  getPublishForms() {
    return this.publishForms;
  }
  setPublishForms(publishForms: boolean) {
    this.publishForms = publishForms;
  }
  getPublishRequestForms() {
    return this.publishRequestForms;
  }
  setPublishRequestForms(publishForms: boolean) {
    this.publishRequestForms = publishForms;
  }
  getAssignFormSection() {
    return this.assignFormSection;
  }

  setAssignFormSection(assignForm: boolean) {
    this.assignFormSection = assignForm;

  }
  getChangedProjectManager() {
    return this.changedProjManager;
  }
  setChangedProjectManager(newManager: string) {
    this.changedProjManager = newManager
  }
  getProjectResponse(): string {
    return this.projectResponse;
  }

  setProjectResponse(title: string) {
    this.projectResponse = title;
  }
  getProjectFacts(): any {
    return this.projectFacts;
  }

  setProjectFacts(facts: any) {
    this.projectFacts = facts;
  }

  getOldProjectFacts(): any {
    return this.oldProjectFacts;
  }

  setOldProjectFacts(title: any) {
    this.oldProjectFacts = title;
  }

  getCreateProjectStatus() {
    return this.createProjectStatus;
  }

  setCreateProjectStatus(type: boolean) {
    this.createProjectStatus = type;
  }


  getEditProjectStatus() {
    return this.editProjectStatus;
  }

  setEditProjectStatus(type: boolean) {
    this.editProjectStatus = type;
  }

  getParentType() {
    return this.parentType;
  }

  setParentType(parent: string) {
    this.parentType = parent
  }

  setInitiateDgfData(data){
    this.initiateDGFData = data;
  }
  getInitiateDgfData(){
    return this.initiateDGFData;
  }

  alreadyAssignedForms(){
    return this.assignedForms;
  }
  
  totalAssignedForms(totalAssignedForms){
     this.assignedForms = totalAssignedForms;
  }
}

