import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { FormExtractionTemplateService } from 'src/app/services/form-extraction-template.service';
import { MasterService } from 'src/app/services/master.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { ResponseExtractService } from 'src/app/services/response-extract.service';
import { UserService } from 'src/app/services/user.service';
import { IForms } from 'src/app/shared/form';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { IResponseStatusResloved, IStatus } from 'src/app/shared/status';
import { ISuggestions } from 'src/app/shared/suggestion';
import { IUser } from 'src/app/shared/user';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'extraction-request',
  templateUrl: './extraction-request.component.html',
  styleUrls: ['./extraction-request.component.css']
})
export class ExtractionRequestComponent implements OnInit, OnDestroy {
  _maxLength: any;
  filterForm: FormGroup;
  responseList: IForms = { forms: [] };
  currentPageNumber: number = 1;
  formStatus: IStatus[] = [];
  sortingObject;
  errorMsg: string;
  responseExtract: any;
  organizationDetails: any;

  userData: IUser;
  curentUserRoles: any = [];
  queryParam: IQuery;
  pagerObject: IPager;
  userSuggestion: ISuggestions;
  clientSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  projectSuggestion: ISuggestions;
  responsesSuggestion: ISuggestions;
  formsSuggestion: ISuggestions;
  organizationSuggestion: ISuggestions;

  isError: boolean;
  isAdmin: boolean;
  isSubmitted: boolean;
  isSearched: boolean;
  isPDF: boolean;
  showProductFilter: boolean;
  showProjectFilter: boolean;
  showClientFilter: boolean ;
  showAssignedToFilter: boolean;
  showOrganizationFilter: boolean;

  formSets: any = [];
  currentIndex: number;
  responseId: number;
  extResponseRequest: boolean;
  formSelected: boolean;
  projectSelected: boolean;
  selectedFormName: string;
  selectedProjectName: string;
  selectedOrgName: string;
  selectedOrgId: number;
  selectedFormId: number;
  selectedProjectId: number;
  templateList: any;
  selectedTemplateType: string;
  selectedTemplateId: any;
  requestPostData: any;
  responseCount: number;
  returnPage: string;
  canExtractResponsesByProject: Boolean = false;

  displayColumns = ['ID', 'Form Name', 'Product Name', 'Client Name', 'Project Name','Respondent Name', 'Last Updated User', 'Last Updated Date'];
  keys = ['id', 'title', 'productTitle', 'clientTitle', 'projectTitle', 'assignedToName', 'modifiedByName', 'modifiedOn'];

  todaysdate: string;
  selectedDeliveryMethod: string;
  regexValidationMessage: any;

  constructor(private projectService: ProjectsService,
    private location: Location,
    private masterService: MasterService,
    private router: Router,
    private permissionService: PermissionsService,
    private formExtractionService: FormExtractionTemplateService,
    private responseExtractService: ResponseExtractService,
    private userService: UserService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {this._maxLength = MAX_LENGTHS;}

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.todaysdate = this.projectService.getTodaysDate();
    this.initProperties();
    this.initFilterForm();
    this.initiatingFormGroup();
    this.initQueryParams();
    this.getOrgSuggestions();
    this.getSuggestions();
    this.filterResponse();
    this.formSets[this.currentIndex].request.controls['OrganizationTitle'].setValue(this.organizationDetails.organizationTitle);

    if (this.extResponseRequest)
      this.returnPage = 'Form Responses';
    else if (this.responseExtractService.tempData)
      this.returnPage = 'Response Extracts List';
    else
      this.returnPage = 'Self Service';
  }

  ngOnDestroy(): void {
    this.responseExtractService.tempData = "";
  }

  initProperties() {
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.userData = this.userService.getUserListData();
    this.userData.roles.forEach((role) => {
      this.curentUserRoles.push(role.roleTitle);
    });
    this.canExtractResponsesByProject =
      this.curentUserRoles.includes('Admin') ||
      this.curentUserRoles.includes('Fiserv Division Admin') ||
      this.curentUserRoles.includes('Fiserv Portfolio Manager') ||
      this.curentUserRoles.includes('Fiserv Implementation Manager') || this.curentUserRoles.includes('Client Implementation Manager') //Added as part of Bug249924
      this.curentUserRoles.includes('Fiserv Portfolio Manager - Read Only');
    const resolvedData: IResponseStatusResloved = this.route.snapshot.data['resolveData'];
    this.organizationDetails = Object.assign({}, this.userService.getUserListData());
    if (this.userData.type == 'Fiserv')
      this.selectedOrgId = this.organizationDetails.organization;
    else
      this.selectedOrgId = this.organizationDetails.clientOrgId;
    this.selectedOrgName = this.organizationDetails.organizationTitle;
    this.formStatus = resolvedData.responseStatus;
    this.showProjectFilter = this.permissionService.showProjectFilter();
    this.showClientFilter = this.permissionService.isClientFilterAllowed();
    this.showProductFilter = this.permissionService.showProductFilter();
    this.showAssignedToFilter = this.permissionService.showProjectFilter();
    this.currentIndex = 0;
    this.isSearched = false;
    this.isError = false;
    this.isSubmitted = false;
    this.isPDF = false;
    this.extResponseRequest = false;
    this.formSelected = false;
    this.projectSelected = false;
    this.selectedFormName = "";
    this.selectedProjectName = ""
    this.selectedTemplateType = "";
    this.selectedTemplateId = "";
    this.errorMsg = "";
    this.selectedDeliveryMethod = "";
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      id: new FormControl(''),
      ProjectTitle: new FormControl(''),
      ProductTitle: new FormControl(''),
      ClientTitle: new FormControl(''),
      responseTitle: new FormControl(''),
      AssignedTo: new FormControl(''),
      ResponseStatusTitle: new FormControl('')
      // OrganizationTitle: new FormControl('')
    });
  }

  initiatingFormGroup() {
    this.formSets[this.currentIndex] = {
      "assignedFormsList": [],
      "request": this.fb.group({
        formTitle: [""],
        formName: [""],
        projectTitle: [""],
        projectName: [""],
        OrganizationTitle: ["", [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]],
        extractionSource: ["Form Template"],
        extractType: [""],
        extractFileName: [
          "",
          [
            Validators.required,
            Validators.pattern(REGEX_PATTERNS.name_title_validator),
            Validators.maxLength(MAX_LENGTHS.File.Alias),
            NoWhitespaceValidator.validateWhiteSpaces
          ]
        ],
        includeCoverSheet:[false],
        includeComments: [false],
        combinedPDF:[false],
        "requestType": "Manual",
        scheduleExtract:[false],
        scheduledExtractionDate: [''],
        extractionDeliveryMethod: ['']
      })
    };
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  filterResponse() {
    this.route.params
    .pipe(take(1))
    .subscribe((params) => {
      this.responseId = params['id'];
      let formTitle = params['formName'] || params['title'] ;
      let projectTitle = params['projectName'] || params['title'] ;

      if (this.responseId > -1) {
        this.extResponseRequest = true;
        this.isSearched = true;
        this.filterForm.controls['id'].setValue(this.responseId);
        this.onFormSelect({ 'item' : {id: params['formId'], title: formTitle }})
        this.onProjectSelect({ 'item' : {id: params['projectId'], title: projectTitle }})
      }
    });
  }

  onOrgSelect(event: any) {
    this.selectedOrgId = event.item.id;
    this.selectedOrgName = event.item.title;
    this.selectedFormName = "";
    this.selectedProjectName = "";
    this.selectedFormId = 0;
    this.selectedProjectId = 0;
    this.formSelected = false;
    this.isSearched = false;
    this.formSets[this.currentIndex].request.controls['formName'].setValue("");
    this.formSets[this.currentIndex].request.controls['projectName'].setValue("");
    this.formSets[this.currentIndex].assignedFormsList = [];
    this.getSuggestions();
    this.closeError();
  }

  onFormSelect(event: any) {
    this.filterForm.controls['responseTitle'].setValue("");
    this.formSets[this.currentIndex].assignedFormsList = [];
    this.selectedFormName = event.item.title;
    this.selectedFormId = event.item.id;
    this.formSelected = true;
    this.isSearched = true;
    this.closeError();
    this.filterList();
    this.formExtractionService.getExtractionTemplateByFormId(this.selectedFormId)
      .subscribe( formExtractions => {
        this.templateList = formExtractions; },
        error => { this.isError = true; this.errorMsg = error; });

    this.projectService.getSuggestions("responses", this.selectedFormId)
    .subscribe(
      data => this.responsesSuggestion = data,
      (error) => { this.isError = true; this.errorMsg = error; }
    );
  }

  onProjectSelect(event: any) {
    this.filterForm.controls['responseTitle'].setValue("");
    this.formSets[this.currentIndex].assignedFormsList = [];
    this.selectedProjectName = event.item.title;
    this.selectedProjectId = event.item.id;
    this.projectSelected = true;
    this.isSearched = true;
    this.closeError();
    this.filterList();
    this.formExtractionService.getExtractionTemplateByFormId(this.selectedFormId)
      .subscribe( formExtractions => {
        this.templateList = formExtractions; },
        error => { this.isError = true; this.errorMsg = error; });

    this.projectService.getSuggestions("responses", this.selectedProjectId)
    .subscribe(
      data => this.responsesSuggestion = data,
      (error) => { this.isError = true; this.errorMsg = error; }
    );

  }

  onExtractSelect(event) {
    this.closeError();
    let template = event.target.value;
    this.selectedTemplateType = template.split("-").pop();
    this.selectedTemplateId = template.split("-").shift();

    if (this.selectedTemplateType === this.selectedTemplateId ) { this.selectedTemplateId = ""; }

    if (this.selectedTemplateType === "Pdf") { this.isPDF = true; }
    else { this.isPDF = false; }
  }

  filterList() {
    this.initQueryParams();
    let filterValues = this.filterForm.value;

    if(this.extResponseRequest) {
      if (filterValues['id'])
        this.queryParam.id = filterValues['id'];
    }
    else {
      // this.queryParam.title =  this.selectedFormName;
      this.queryParam.responseStatusTitle = "Completed";
      if(this.selectedFormId > 0)
        this.queryParam.formId =  this.selectedFormId;
      if(this.selectedProjectId > 0)
        this.queryParam.projectId =  this.selectedProjectId;
      //this.queryParam.organizationTitle = this.selectedOrgName;

      if (filterValues['ProjectTitle'])
        this.queryParam.projectTitle = filterValues['ProjectTitle'];
      if (filterValues['ProductTitle'])
        this.queryParam.productTitle = filterValues['ProductTitle'];
      if (filterValues['ClientTitle'])
        this.queryParam.clientTitle = filterValues['ClientTitle'];
      if (filterValues['responseTitle'])
        this.queryParam.title = filterValues['responseTitle'];
      if (filterValues['AssignedTo'])
        this.queryParam.assignedTo = filterValues['AssignedTo'];
      if (filterValues['id'])
        this.queryParam.id = filterValues['id'];
    }
    this.setOrderBy(this.sortingObject);
    this.callResponseListService();
  }

  getOrgSuggestions() {
    if (this.isAdmin) {
      this.projectService.getSuggestions("organizations", "")
        .subscribe(suggestions => {
          this.organizationSuggestion = suggestions;
        },
          error => { this.isError = true; this.errorMsg = error; });
    }
  }

  getSuggestions() {
    if (this.showProductFilter) {
      this.projectService.getSuggestions('products', this.selectedOrgId, "orgId")
      .subscribe(
        data => this.productSuggestion = data,
        (error) => { this.isError = true; this.errorMsg = error; }
      );
    }

    if (this.showProjectFilter) {
      this.projectService.getSuggestions("projects", this.selectedOrgId, "orgId")
      .subscribe(
        data => this.projectSuggestion = data,
        (error) => { this.isError = true; this.errorMsg = error; }
      );
    }

    if (this.showClientFilter) {
      this.projectService.getSuggestions("clients", this.selectedOrgId, "orgId")
      .subscribe(
        data => this.clientSuggestion = data,
        (error) => { this.isError = true; this.errorMsg = error; }
      );
    }

    if (this.showAssignedToFilter) {
      this.projectService.getSuggestions("users", "Form Respondent", "role")
      .subscribe(
        data => this.userSuggestion = data,
        (error) => { this.isError = true; this.errorMsg = error; }
      );
    }

    this.projectService.getSuggestions("forms", this.selectedOrgId, "orgId")
      .subscribe(suggestions => {
        this.formsSuggestion = suggestions;
      },
        error => { this.isError = true; this.errorMsg = error; });
  }

  callResponseListService() {
    this.projectService.getResponseList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.isError = false;
          this.responseList = responses.body as IForms;
          this.responseList.forms.forEach(ele => {ele.selected = false;});

          for (let i = 0; i < this.responseList.forms.length; i++) {
            let idInResponse = false;
            this.formSets[this.currentIndex].assignedFormsList.forEach(row => {
              if(this.formSets[this.currentIndex].request.get('extractionSource').value === 'Form Template') {
                if (row.id == this.responseList.forms[i].id) {
                  idInResponse = true;
                  this.responseList.forms[i].selected = true;
                }
              } else {
                if (row.projectId == this.responseList.forms[i].projectId) {
                  idInResponse = true;
                  this.responseList.forms[i].selected = true;
                }
              }
            })
            if (!idInResponse && this.responseList.forms[i].selected)
              this.formSets[this.currentIndex].assignedFormsList.push(this.responseList.forms[i]);
          }

          if (responses.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }


  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callResponseListService();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callResponseListService();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  goBack() {
    this.resetFormProperties();
    this.responseExtractService.tempData = "";
    // this.router.navigate(['/self-service/requestExtracts']);
    this.location.back();
  }

  Submit() {
    if ((!this.selectedFormName && !this.selectedProjectName) && !this.extResponseRequest) {
      this.onError("Please select the form template/project before requesting for an extract.");
      return;
    }

    if (!this.selectedTemplateType) {
      this.onError("Please select the extract type before requesting for an extract.");
      return;
    }

    this.responseExtract = this.formSets[this.currentIndex];

    if (this.responseExtract.request.value['scheduleExtract'] && this.responseExtract.request.value['scheduledExtractionDate'] === '') {
      this.onError("Please select the scheduled extraction date before requesting for an extract.");
      return;
    }

    if (this.responseExtract.request.value['scheduleExtract'] && this.selectedDeliveryMethod === '') {
      this.onError("Please select the extract delivery method before requesting for an extract.");
      return;
    }

    if (this.responseExtract.assignedFormsList.length === 0 && !this.extResponseRequest) {
      this.onError("Please select the response(s) first before requesting for an extract.");
      return;
    }

    if (this.responseCount > 10) {
      this.onError("You can select maximum 10 response(s) to request for an extract.");
      return;
    }

    this.isSubmitted = true;
    if (this.responseExtract.request.valid) {
      this.createPostData();
      this.responseExtractService.createResponseExtract(this.requestPostData)
      .subscribe(
        () => {
          this.onCreateComplete();
          this.resetFormProperties();
          this.router.navigate(['/self-service/requestExtracts']);
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        });
    }
    else {
      this.onError("Please provide the Extract File Name");
    }
  }

  private createPostData(): void {
    let assignedResponsesList: any = [];
    if (this.extResponseRequest)
    {
      assignedResponsesList.push({
        "responseId": this.responseId
      })
    }
    else{
      for (let i in this.responseExtract.assignedFormsList)
      {
        assignedResponsesList.push({
          "responseId": this.responseExtract.assignedFormsList[i].id
        })
      }
    }

    if (this.selectedTemplateId == "")
    {
      this.requestPostData = {
        'responses': assignedResponsesList,
        'extractRequestName': this.responseExtract.request.value['extractFileName'],
        'extractType': this.selectedTemplateType,
        'requestType': "Manual",
        'includeComments': this.responseExtract.request.value['includeComments'],
        'includeCoverSheet': this.responseExtract.request.value['includeCoverSheet'],
        'combinedPDF': this.responseExtract.request.value['combinedPDF'],
        'scheduleExtract': this.responseExtract.request.value['scheduleExtract'],
        'scheduledExtractionDate': this.responseExtract.request.value['scheduledExtractionDate'],
        'extractionDeliveryMethod': this.selectedDeliveryMethod,
      };
    }
    else {
      this.requestPostData = {
        'responses': assignedResponsesList,
        'extractRequestName': this.responseExtract.request.value['extractFileName'],
        'extractType': this.selectedTemplateType,
        'extractionTemplateId': Number(this.selectedTemplateId),
        'requestType': "Manual",
        'includeComments': this.responseExtract.request.value['includeComments'],
        'includeCoverSheet': this.responseExtract.request.value['includeCoverSheet'],
        'combinedPDF': this.responseExtract.request.value['combinedPDF'],
        'scheduleExtract': this.responseExtract.request.value['scheduleExtract'],
        'scheduledExtractionDate': this.responseExtract.request.value['scheduledExtractionDate'],
        'extractionDeliveryMethod': this.selectedDeliveryMethod,
      };
    }
  }

  private resetFormProperties(): void {
    this.closeError();
    this.isSubmitted = false;
    this.selectedFormName = "";
    this.selectedProjectName = "";
    this.selectedOrgName = "";
    this.selectedTemplateType = "";
    this.selectedTemplateId = "";
    this.requestPostData = "";
    this.formSets[this.currentIndex].assignedFormsList = [];
    this.formSets[this.currentIndex].request.reset();
  }

  private onCreateComplete(): void {
    this.responseExtractService.isExtractCreated = "Response Extract request '" + this.responseExtract.request.value['extractFileName'] + "' has been created. Please check after sometime when it is ready for download.";
  }

  onError(msg: string) {
      this.isError = true;
      this.errorMsg = msg;
  }

  closeError() {
    this.isError = false;
    this.errorMsg = "";
  }

  updateResponseCount(event) {
    this.responseCount = event;
  }

  onChangeExtractionSource() {
    this.responseList = { forms: [] }
    this.selectedProjectId =  undefined;
    this.selectedFormId =  undefined;
  }

  onExtractionDeliveryMethodSelect(event: any) {
    this.closeError();
    let method = event.target.value;
    this.selectedDeliveryMethod = method;
  }
}
