<div class="help-container main-container-sub responsive-container">
  <div class="heading-container align-items-start">
    <div class="back-action-container">
      <div class="back-button">
        <a class="nav-link pl-0" href="javascript:void(0);" routerLink="/help" routerLinkActive="active">
          <span class="material-icons">
            arrow_back
          </span> Resource Center
        </a>
      </div>

      <h1>{{catName}}</h1>
    </div>
    <div class="d-flex create-new-topic-btn fdl-2" *ngIf="canCreateHelp()">
      <!-- remove onclick  -->
      <button type="button" class="btn fdl-btn btn-primary-orange" style="margin-top: -16px;" (click)="navigateTo('create')">
        <span class="material-icons">add</span> Create New Article
      </button>
    </div>
  </div>


  <div class="row m-0 height-100">

    <!-- Sidebar Item -->
    <aside class="side-navbar">
      <div class="main">
        <div role="navigation" class="left-nav">

          <div class="help-recommended-topics">
            <h4>Recommended Article</h4>

            <div class="help-body">
              <a *ngFor="let row of popularTopic; let i = index;" href="javascript:void(0);" routerLink="/help/topic/{{row.id}}" routerLinkActive="active" class="description-image">{{row.title}}</a>
            </div>
          </div>
        </div>
      </div>
    </aside>

    <section class="right-section">
      <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
        <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
        <span class="iconLabel" aria-label="sucess"></span>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p>
          {{successMsg}}

        </p>
      </div>
      <div>

        <div class="form-container help-accordion-container">
          <div class="search-container">
            <div class="search-label">Search:</div>
            <div class="help-search">
              <input type="text" [(ngModel)]="searchString" placeholder="Search Article"><button><i class="material-icons icon-after">search</i></button>
            </div>
          </div>

          <div class="data-table-container">
            <div class="headline-container">

            </div>

            <!-- Accordion START -->
            <div class="help-topic-accordion">
              <div class="panel-group" id="accordion">
                <div class="panel panel-default" *ngFor="let row of topics | helpFilter:  ['title','description']:searchString; let i = index;">
                  <div class="panel-heading accordion-toggle collapsed" aria-expanded="false">
                      <a  class="panel-title category-link " href="javascript:void(0);" routerLink="/help/topic/{{row.id}}/category">
                        {{row.title}}
                      </a> 
                    <div class="panel-sub-text">

                      {{row.description}}
                    </div>
                    <div class="kebab-menu dropleft">
                      <a href="javascript:void(0);" data-toggle="dropdown" id="headerSwitcherAccordion" aria-expanded="false">
                        <span class="material-icons">
                          more_vert
                        </span>
                      </a>
                      <div class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher" x-placement="left-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-117px, 5px, 0px);">
                        <div class="dropdown-list-option">
                          <a class="dropdown-item" href="javascript:void(0);" routerLink="/help/topic/{{row.id}}/category" routerLinkActive="active">View</a>
                          <a class="dropdown-item" href="javascript:void(0);" *ngIf="row.editAccessWith && canCreateHelp()" (click)="downloadPDF(row.id)">Download PDF</a>
                          <a class="dropdown-item" href="javascript:void(0);" *ngIf="row.editAccessWith && canCreateHelp()" routerLink="/self-service/helpTopic/edit/{{row.id}}">Edit</a>
                          <a class="dropdown-item" href="javascript:void(0);" *ngIf="row.editAccessWith && canCreateHelp()" (click)="deleteTopic(row.id,row.title)">Remove</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--<div id="helpTopic1" class="panel-collapse collapse">
                    <div class="panel-body">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse vel orci vitae mi fermentum fermentum. Nunc et
                        rutrum sem. Sed faucibus iaculis urna, ut facilisis ligula Lorem
                        ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        vel orci vitae mi fermentum fermentum. Nunc et rutrum sem. Sed
                        faucibus iaculis urna, ut facilisis ligula.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse vel orci vitae mi fermentum fermentum. Nunc et
                        rutrum sem. Sed faucibus iaculis urna, ut facilisis ligula.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse vel orci vitae mi fermentum fermentum. Nunc et
                        rutrum sem. Sed faucibus iaculis urna, ut facilisis ligula.
                      </p>
                    </div>
                  </div>-->
                </div>

              </div>
            </div>
            <!-- Accordion END -->
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<app-pdf-genrate #pdfGenerated (onFormLoad)="onPdfLoad($event)"></app-pdf-genrate>
<div class="modal fade bd-example-modal-md" id="deleteModal" data-backdrop="static" data-keyboard="false"
     tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-md mt-120" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Delete Article</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to delete article <b>{{name}}</b>? </p>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal">Cancel</button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal" (click)="onClick_delete()">Ok</button>
      </div>
    </div>
  </div>
</div>
