import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { ICreateForm } from '../models/createForm';
import { tap } from 'rxjs/internal/operators/tap';
import { HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { UserService } from 'src/app/services/user.service';
import { IForm } from 'src/app/shared/form';
import { catchError } from 'rxjs/operators';
import { InterceptorSkipHeader } from 'src/app/core/constants';
import { error } from "@angular/compiler/src/util";

@Injectable({
  providedIn: 'root'
})
export class ManageFormsService {
  private _formVal: ICreateForm;

  private formValue = new Subject();
  formValueObserver = this.formValue.asObservable();
  private productUrl: string = "products/2/forms";
  private createFormUrl: string = "forms";
  private createFormLockUrl: string = "formtemplatelock/insertformlock";
  private getFormLockUrl: string = "formtemplatelock/getformlock";
  private responseAPIURL = 'responses'
  private pagetemplateResponseAPIURL = 'pagetemplateresponse';
  userData: any;
  private _delegateSuccessMsg: string = "";
  private _approvalMsg: string = '';
  private _isPreview: boolean = false;
  private _historyName: string = "";
  private formData: any;
  private _warningMessage?: string;
  UserIds: any = [];

  wb: XLSX.WorkBook;
  // /api/organizations/{orgId}/forms


  constructor(private http: HttpClient, private _userService: UserService) { }

  setDelegateSuccessMsg(msg: string) {
    this._delegateSuccessMsg = msg;
  }
  setResponseData(val: any) {
    this.formData = val;
  }
  getResponseData() {
    return this.formData;
  }
  getDelegateSuccessMsg() {
    return this._delegateSuccessMsg;
  }
  saveFormValues(status: ICreateForm) {
    this._formVal = status;
  }
  get warningMessage(): string {
    return this._warningMessage;
  }
  set warningMessage(value: string) {
    this._warningMessage = value;
  }

  get formVal(): ICreateForm {
    return this._formVal;
  }
  set formVal(value: ICreateForm) {
    this._formVal = value;
  }
  setXls(data: XLSX.WorkBook) {
    this.wb = data;
  }
  getXls() {
    return this.wb;
  }
  getFormsByOrgId(): Observable<any> {
    return this.http.get<any>(this.productUrl).pipe(
      tap()
    );
  }

  getFormsByproductId(): Observable<any> {
    return this.http.get<any>(this.productUrl).pipe(
      tap()
    );
  }

  getFormFieldsByformId(formId: number) {
    return this.http.get("formfields/" + formId + "?formId=" + formId, {observe: 'response', responseType: 'blob'});
  }

  /* Get the Form Template for the Selecte form*/
  getFormByformId(formId: number): Observable<any> {
    return this.http.get<any>(this.createFormUrl + "/" + formId).pipe(
      tap()
    );
  }

  /* delete the form */
  deleteForm(formId: number): Observable<IForm> {
    return this.http.delete<IForm>(`${this.createFormUrl}\\${formId}`);
  }

  createForm(isSkip = false, observable: boolean = false): Observable<any> {
    this.userData = this._userService.getUserListData();

    let body = {
      "title": this.formVal.formName,
      "description": this.formVal.formDes,
      "organizationId": this.userData['organization'],
      "formJSON": this.formVal.json,
      "formFields": this.formVal.formFields,
      "IsPublishable": this.formVal.IsPublishable,
      "formType": this.formVal.formType,
      "formSubTypeId": this.formVal.formSubType,
      "isSensitiveData": this.formVal.isSensitiveData,
      "IsLocked":this.formVal.isenableTemplateLock,
      "enableEmailNotifications": this.formVal.enableEmailNotifications,
       "searchByFormFieldJson": this.formVal.searchByFormFieldJson,
      "formFieldListJson": this.formVal.formFieldListJson,
    };
    
    if (this.userData['isAdmin']){
      body['organizationId'] = this.formVal.orgId;
    }

    if (this.formVal.parentIds && this.formVal.parentIds.length > 0) {
      body['parentIds'] = this.formVal.parentIds;
    }
    if (this.formVal.productId && this.formVal.productId > 0) {
      body["productId"] = this.formVal.productId;
    }

    if (this.formVal.connectedForms && this.formVal.connectedForms.length > 0) {
      body["connectedForms"] = this.formVal.connectedForms;
    }
    if (this.formVal.formType != 'DataGathering') {
      body["approvers"] = this.formVal.approvers;
      body["recieverOnComplete"] = this.formVal.recieverOnComplete;
    }
    if (this.formVal.formType == 'Intake') {
      body["isIntakeForm"] = 'true'
      body["approvalSchema"] = this.formVal.approvalSchema
    }
    
    let options = {
      'Content-Type': 'application/json'
    }

    if (isSkip) {
      options[InterceptorSkipHeader] = '';
    }

    let httpOptions = {
      headers: new HttpHeaders(options)
    };
    if (observable) {
      httpOptions["observe"] = 'response';
    }

    return this.http.post<any>(this.createFormUrl, body, httpOptions).pipe(
      tap(response => {
        debugger;
        const data = observable ? response.body : response;
        this.formVal.id = data['id'];
        this.formVal.initialDataJSON = data['formJSON'];
        this.formVal.isActive = data['isActive'];
        this.formVal.orgId = data['organizationId'];
        if (!isSkip) {
          this.formVal.isSuccess = true;
        }
        if (this.formVal.isenableTemplateLock == "true") {
          this.insertTemplateLock().subscribe(
            response => {
              console.log(response);
            },
            error => {
              console.log(error);
            }
          );
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  insertTemplateLock() {       
    return this.http.post<any>(`${this.createFormLockUrl}?FormId=${this.formVal.id}`, this.UserIds);
  }

  getTemplateLock(id: number) {
    return this.http.get<any>(`${this.getFormLockUrl}/`+id);
  }
 

  editResponse(isSkip = false, type = 'form', observable: boolean = false): Observable<any> {
    let body = {
      "formJSON": this.formVal.json,
      "answerJSON": this.formVal.answerJSON,
      "id": this.formVal.id,
      "title": this.formVal.formName,
      "rowVersion": this.formVal.rowVersion
    };
    if (type === 'page') {
      body['pageTemplateResponseStatusId'] = this.formVal.responseStatusId;
      body['updateType'] = 'form';
    }
    else {
      body['responseStatusId'] = this.formVal.responseStatusId;
    }
      
    let options = {
      'Content-Type': 'application/json'
    }

    if (isSkip) {
      options[InterceptorSkipHeader] = '';
    }

    let httpOptions = {};
    if (observable) {
      httpOptions['observe'] = 'response';
    }

    this.userData = this._userService.getUserListData();
    if (type === 'page')  {
      return this.http.put<any>(`${this.pagetemplateResponseAPIURL}/${+this.formVal.id}`, body, httpOptions);
    }
    
    return this.http.put<any>(`${this.responseAPIURL}/${this.formVal.id}`, body, httpOptions);
  }

  editForm(isSkip = false, observable: boolean = false): Observable<any> {
    this.userData = this._userService.getUserListData();

    let body = {
      "id": this.formVal.id,
      "title": this.formVal.formName,
      "description": this.formVal.formDes,
      "organizationId": this.formVal.orgId,
      "initialDataJSON": this.formVal.initialDataJSON,
      "formJSON": this.formVal.json,
      "isActive": this.formVal.isActive,
      "formFields": this.formVal.formFields,
      "IsPublishable": this.formVal.IsPublishable,
      "formType": this.formVal.formType,
      "formSubTypeId": this.formVal.formSubType,
      "isSensitiveData": this.formVal.isSensitiveData,
      "IsLocked": this.formVal.isenableTemplateLock,
      "enableEmailNotifications": this.formVal.enableEmailNotifications,
      "searchByFormFieldJson": this.formVal.searchByFormFieldJson,
      "formFieldListJson": this.formVal.formFieldListJson,
    };
    
  
    if (this.formVal.parentIds && this.formVal.parentIds.length > 0) {
      body['parentIds'] = this.formVal.parentIds;
    }
    if (this.formVal.productId && this.formVal.productId > 0) {
      body["productId"] = this.formVal.productId;
    }
    
    if (this.formVal.connectedForms && this.formVal.connectedForms.length > 0) {
      body["connectedForms"] = this.formVal.connectedForms;
    }

    if (this.formVal.formType != 'DataGathering') {
      body["approvers"] = this.formVal.approvers;
      body["recieverOnComplete"] = this.formVal.recieverOnComplete;
    }
    if (this.formVal.formType == 'Intake') {
      body["isIntakeForm"] = 'true'
      body["approvalSchema"] = this.formVal.approvalSchema
    }

    let options = { 'Content-Type': 'application/json' };
    if (isSkip) {
      options[InterceptorSkipHeader] = '';
    }

    let httpOptions = {
      headers: new HttpHeaders(options),
    };

    if (observable) {
      httpOptions['observe'] = 'response';
    }


    return this.http.put<any>(`${this.createFormUrl}/${this.formVal.id}`, body, httpOptions).pipe(
      tap(response => {
        const data = observable ? response.body : response;
        this.formVal.id = data['id'];
        this.formVal.initialDataJSON = data['initialDataJSON'];
        this.formVal.isActive = data['isActive'];
        if (!isSkip) {
          this.formVal.isSuccess = true;
        }
        if (this.formVal.isenableTemplateLock == "true") {
          this.insertTemplateLock().subscribe(
            response => {
              console.log(response);
            },
            error => {
              console.log(error);
            }
          );
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
    );
  }

  set approvalMsg(message) {
    this._approvalMsg = message;
  }

  get approvalMsg() {
    return this._approvalMsg;
  }
  set isPreview(value) {
    this._isPreview = value;
  }
  get isPreview() {
    return this._isPreview;
  }

  get historyName() {
    return this._historyName;
  }

  set historyName(name: string) {
    this._historyName = name;
  }
}
