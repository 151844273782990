import { Component, OnInit, AfterContentInit, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { filter, map, mergeMap } from "rxjs/operators";

import { MasterService } from "../../services/master.service";
import { SurveyService } from "../../services/survey.service";
import { LoaderService } from '../../loader/loader.service';
import { SurveyResolver } from './survey-resolver.service';

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html"
})
export class SurveyComponent implements OnInit, AfterContentInit, OnDestroy {
  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private masterService: MasterService,
    private surveyService: SurveyService,
    private loader: LoaderService,
    private surveyResolver: SurveyResolver
  ) { }
  ngOnDestroy(): void {
    this.loader.hide();
  }

  loggedInName: string = "";

  ngOnInit(): void {
    this.surveyResolver.respondentName.subscribe(name => {
      this.loggedInName = name;
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(event =>
        this.titleService.setTitle(event["title"] ? event["title"] : "Fiserv")
      );

    const loc = this.location.path().split("/");
    // const locSub = loc.substring(loc.indexOf("survey")+7);
    // const surveyId = locSub.indexOf("/") > 0 ? locSub.substring(0, locSub.lastIndexOf("/")): locSub;
    const surveyId = loc[loc.indexOf("survey") + 1];
    this.masterService.setGuid(surveyId);
    this.surveyService.setParentVisited(true);

    this.loader.hide();
    this.router.navigate(["survey", surveyId, "generate-otp"]);
    //this.surveyService.getSurveys(surveyId).subscribe(
    //  data => {
    //    this.loggedInName = " " + data.recipient.firstName + " " + data.recipient.lastName;
    //    this.surveyService.setIdForSurvey(surveyId);
    //    this.surveyService.setResponseForSurvey(data.response);
    //    this.surveyService.setParentVisited(true);
    //  },
    //  err => {
    //    this.surveyService.setParentVisited(true);
    //    this.surveyService.setError(err);
    //    this.router.navigate(["survey", surveyId, "generate-otp"]);
    //  },
    //  () => {
    //    this.router.navigate(["survey", surveyId, "generate-otp"]);
    //  },
    //);
  }
  ngAfterContentInit(): void {

  }
}
