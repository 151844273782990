<div class="heading-container fdl-2">
    <h1>Reports</h1>
    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="createReport();" *ngIf="isCreateReportAllowed">
        <span class="material-icons">add</span>
        Add New Report</button>
</div>
<div class="form-container">
        <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="closeSuccessMsg()">×</button>
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>{{successMsg}}
          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="deletedFormMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>{{deletedFormMsg}}

            </p>
        </div>
<div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
</div>
<form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
    <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
    <div class="filter-form-container-column2 collapse" id="collapseFilter">
      <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('reportName').valid && (filterForm.get('reportName').touched || filterForm.get('reportName').dirty)}">
        <div class="has-error">
          <span *ngIf="filterForm.get('reportName').invalid && filterForm.get('reportName').errors?.pattern && (filterForm.get('reportName').touched || filterForm.get('reportName').dirty)">
            <i class="material-icons">warning</i>Please enter a valid Report Name
          </span>
        </div>
        <fdl-type-ahead label="Report Name:" class="force-hide-asterist" formControlName="reportName" [collection]="reportSuggestion" optionField="reportName" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (input)="validateFormInput($event, 'reportName')">
        </fdl-type-ahead>
      </div>
      <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showOrganizationFilter" [ngClass]="{'has-error': !filterForm.get('organizationName').valid && (filterForm.get('organizationName').touched || filterForm.get('organizationName').dirty)}">
        <div class="has-error">
          <span *ngIf="filterForm.get('organizationName').invalid && filterForm.get('organizationName').errors?.pattern && (filterForm.get('organizationName').touched || filterForm.get('organizationName').dirty)">
            <i class="material-icons">warning</i>Please enter a valid Organization Name
          </span>
        </div>
        <fdl-type-ahead label="Organization Name" class="force-hide-asterist" formControlName="organizationName" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onOrgSelect($event)" (input)="validateFormInput($event, 'organizationName')">
        </fdl-type-ahead>
      </div>
        <div>
          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
                  (click)="filterList()">
            Search
          </button>
        </div>
        <div class="ml-2" *ngIf="filtered">
          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset"
                  (click)="clearFilterList()">
            Reset
          </button>
        </div>
      </div>
</form>
<div class="data-table-container">
  <div class="justify-content-between d-flex">
    <div class="headline-container">
      <h2 class="h4">Report List</h2>
      <p>Total {{pagerObject.totalCount}} Reports</p>
    </div>
    <div class="d-flex">
      <div class="refresh-icon-link mt-1 mr-2">
        <!-- add class of 'fa-rotation' on click of the refresh icon -->
        <a title="Refresh Report List" id="refreshTask">
          <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
        </a>
      </div>
      <app-prefernce-icon></app-prefernce-icon>
    </div>
  </div>

      <div class="table-scroll-x">
        <app-data-table
            *ngIf="reportList"
            [rows]="reportList.reports"
            [keys]="keys"
            [displayColumns]="displayColumns"
            (sortingClicked)="applySorting($event)"
            [showCheckbox]="false"
            [showViewEdit]="isCreateReportAllowed"
            (buttonClicked)="navigate($event)"
            [showDelete]="isCreateReportAllowed"
            (deleteBtnClicked)="navigate($event)"
            type="Report"
            [listname]="listname"
           >
        </app-data-table>
    </div>
    <div>
        <app-pagination
                *ngIf="pagerObject && pagerObject.totalPages > 1 && reportList && reportList.reports.length > 0"
                [pagerObject]="pagerObject"
                (pagingChanged)="changePage($event)"
            ></app-pagination>
    </div>
</div>
</div>
