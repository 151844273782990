import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FdlTypeaheadModule } from "fdl-ngx11-bootstrap4";
import { ConsoleListComponent } from "./console-list/console-list.component";
import { ProjectWorkflowRoutingModule } from "./project-workflow-routing.module";
import { SharedModule } from '../SharedModule/shared.module';

@NgModule({
    declarations: [
        ConsoleListComponent
    ],
    imports: [
        CommonModule,
        ProjectWorkflowRoutingModule,
        FdlTypeaheadModule,
        SharedModule
    ],
    exports: [
        ProjectWorkflowRoutingModule
    ]
})
export class ProjectWorkflowModule {}