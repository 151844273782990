import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IUser, IUsersList, IUserLoginDetails } from '../shared/user';
import { Observable } from 'rxjs';
import { ISuggestion } from '../shared/suggestion';
import { debug } from 'console';
import { query } from '@angular/animations';
import moment from 'node_modules/moment';
import { error } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userAPIURL = 'users/me';
  private gerLoginDetailAPIURL = 'users/GetLoginTimeStamp';
  private setLoginDetailAPIURL = 'users/SetLoginTimeStamp';
  private exportUserAPIURL = 'users/Export';
  private userSuggestionsAPIURL = 'users/suggestions';
  private usersAPIURL = 'users';
  private usersOrgAPIURL = 'users/UpdateOrg';
  private enabledisableURL = 'users/DisableUser';
  private roleAPIURL = 'roles';
  private usersList: IUser;
  private _tempData: any;
  private userAllowedPermissions: any[];
  private _isRespondent: boolean = false;
  private _isClient: boolean = false;
  private _isFiserv: boolean = false;

  constructor(private http: HttpClient) {}
  callorgSpecificConfiguration(param) {
    return this.http.get('/assets/configuration/' + param + '.json');
  }
  callUserListData(): Observable<IUser> {
    return this.http.get<IUser>(this.userAPIURL);
  }

  setUserListData(data: IUser) {
    this.usersList = data;
    this._isClient = data.type !== 'Fiserv';
    this._isFiserv = data.type == 'Fiserv';
  }

  organizationDetails(organizationDetails) {
    this.usersList.organizationTitle = organizationDetails.organizationTitle;
    this.usersList.organization = organizationDetails.organizationId;
    this.updateUserOrg(this.usersList).subscribe(
      () => { }, error => { return false; }
    );
    return true;
  }

  updateUserOrg(user) {
    var data;
    return this.http.put(`${this.usersOrgAPIURL}/${user.id}/${user.organization}/`, data);
  }

  updateUserOrganization(userId, organizationId) {
    var data;
    return this.http.put(`${this.usersOrgAPIURL}/${userId}/${organizationId}/`, data);
  }

  getUserListData() {
    return this.usersList;
  }

  getLoginData(userId: string) {
    let params = new HttpParams().set('userId', userId.toString());
    return this.http.get(this.gerLoginDetailAPIURL, {
      params: params,
      observe: 'response',
      responseType: 'text',
    });
  }

  setLoginData(user: IUser, drmsToken) {
    return this.http.post(this.setLoginDetailAPIURL, {
      Name: user.name.toString(),
      UserId: user.userId.toString(),
      LoginTimeStamp: moment().format('YYYY-MM-DDTHH:mm:ss'),
      drmsToken: drmsToken,
    });
  }

  setPermissions(permission) {
    this.userAllowedPermissions = permission;
  }

  getPermissions() {
    return this.userAllowedPermissions;
  }

  getUsersList(queryParams) {
    let params = new HttpParams();

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.usersAPIURL, {
      params: params,
      observe: 'response',
    });
  }

  getRefreshUsersList(queryParams) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: 'true',
      }),
    };
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.usersAPIURL, {
      headers: httpOptions.headers,
      params: params,
      observe: 'response',
    });
  }

  exportUsersList(queryParams) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: 'true',
      }),
    };
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.exportUserAPIURL, {
      headers: httpOptions.headers,
      params: params,
      observe: 'response',
      responseType: 'blob',
    });
  }

  getUserById(userId: number) {
        return this.http.get<IUser>(`${this.usersAPIURL}/${userId}`);
  }
  
  getUserByName(userName: string) {
      return this.http.get<IUsersList>(`${this.usersAPIURL}?Name=${userName}`);
  }
  
  getRoles(userType = '') {
      return this.http.get<ISuggestion[]>(`${this.roleAPIURL}?type=${userType}`);
  }
    // New function created
  getRolesSuggestions(userType = '') {
      let httpOptions = {
        headers: new HttpHeaders({
          refresh: "true"
        })
      };
      return this.http.get<ISuggestion[]>(`${this.roleAPIURL}/rolesuggestions?type=${userType}`,{ headers: httpOptions.headers} );
  }
  
  createUser(data) {
        return this.http.post<IUser>(this.usersAPIURL, data);
  }
  
  updateUser(userId, data) {
        return this.http.put<IUser>(`${this.usersAPIURL}/${userId}`, data);
  }
  
  deleteUser(userId) {
    return this.http.delete<IUser>(`${this.usersAPIURL}/${userId}`);
  }
  
  enableDisableUser(userId,status) {
    return this.http.delete<IUser>(`${this.enabledisableURL}/${userId}/${status}`);
  }
  
  set tempData(value) {
    this._tempData = value;
  }
  
  get tempData() {
    return this._tempData;
  }

  get isRespondent() {
    return this._isRespondent;
  }

  set isRespondent(respondent: boolean) {
    this._isRespondent = respondent;
  }

  get isClient() {
    return this._isClient;
  }

  set isClient(client: boolean) {
    this._isClient = client;
  }
  get isFiserv() {
    return this._isFiserv;
  }

  set isFiserv(client: boolean) {
    this._isFiserv = client;
  }

  getUserDetails(useremail) {
    let data = {"email":useremail};
    return this.http.post('https://prod-25.westus.logic.azure.com:443/workflows/0766fa71362b47438f192d4822b67fe8/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Jp1GmigKVPvGXnEGKz2qrg1uyXKw4YjijNwQji5umWQ', data);
  }

  hasChannelPartnerRole(){
    let currentUserRoles: any = [];
    this.usersList.roles.forEach((role) => {
      currentUserRoles.push(role.roleTitle);
    });

    return currentUserRoles.includes('Channel Partner');
  }

  isFormRespondentAndIntakeSubmitterUserOnly(){
    const respondentRole = this.usersList.roles.find(role => role.roleTitle === 'Form Respondent');
    const intakeSubmitterRole = this.usersList.roles.find(role => role.roleTitle === 'Intake Form Submitter');
    if (this.usersList.roles.length == 1 && respondentRole
      || (this.usersList.roles.length == 2 && respondentRole && intakeSubmitterRole)) {
        return true;
    }

    return false;
  }
}