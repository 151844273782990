<div class="timeline-main-container">
   <!--Project Timeline Page starts -->
  <div class="heading-container ">
     <div class="back-action-container">
        <div class="back-button">
           <a href="javascript:void(0);" (click)="goBack()">
              <span class="material-icons">
                 arrow_back
              </span>
              Projects
           </a>
        </div>
        <h1>{{filteredTimelineList.projectName}}</h1>
        <small>Last updated on {{filteredTimelineList.modifiedOn | dateFormat}}</small>
     </div>
<div class="d-flex mr-3">
  <div class="refresh-icon-link mt-1">
     <!-- add class of 'fa-rotation' on click of the refresh icon -->
     <a  title="Refresh Projects List" id="refreshTask">
       <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
     </a>
   </div>
  <button (click)="openAddCommentModal()" type="button" data-toggle="modal" title="Add/Upload Comments" class="btn fdl-btn btn-primary-dark btn-addcomment">
     <span class="material-icons material-icons-wrapper">chat</span>
     <div class="badge badge-danger">{{commentBadgeCount}}</div>
  </button>
</div>
  </div>

  <div class="main-container-sub responsive-container m-0">
     <div class="row m-0">
        <section class="right-section full-width col-12">
           <div class="form-container p-0">
              <div class="data-table-container mb-0 pb-0 timeline-wrapper">
                 <div class="page-status-container fdl-2">
                    <div class="timeline-info-container">
                       <div class="single-timeline-content d-flex">
                          <div class="timeline-text">
                             <p>Project Status</p>
                             <h6>
                                <span [ngClass]="getStatusCSSClass(filteredTimelineList.projectStatus)"></span>
                                <span title="{{project.statusTitle}}">
                                  {{project.statusTitle}}
                                </span>
                             </h6>
                          </div>
                       </div>
                       <div class="single-timeline-content d-flex">
                          <div class="timeline-text ">
                             <p>Project Manager</p>
                             <h6>
                               <span title="{{project.fiservImplementationManagerName}}">
                                 {{project.fiservImplementationManagerName}}
                               </span>
                             </h6>
                          </div>
                       </div>

                       <div class="single-timeline-content d-flex">
                        <div class="timeline-text ">
                           <p>Client Implementation Manager</p>
                           <h6>
                             <span title="{{project.clientImplementationManagerName}}">
                               {{project?.clientImplementationManagerName || 'N/A'}}
                             </span>
                           </h6>
                        </div>
                        </div>

                       <div class="single-timeline-content d-flex">
                          <div class="timeline-text ">
                             <p>Client Name</p>
                             <h6>
                               <span title="{{project.clientTitleWithDuns}}">
                                 {{project?.clientTitleWithDuns || 'N/A'}}
                               </span>
                             </h6>
                          </div>
                       </div>
                       <div class="single-timeline-content d-flex">
                          <div class="timeline-text ">
                             <p>Product Name</p>
                             <h6>
                               <span title="{{project.productTitle}}">
                                 {{project.productTitle}}
                               </span>
                             </h6>
                          </div>
                       </div>
                       <div class="single-timeline-content d-flex">
                          <div class="timeline-text ">
                             <p>Start Date</p>
                             <h6>
                               <span title="{{project.startDate | dateFormat}}">
                                 {{project.startDate | dateFormat}}
                               </span>
                             </h6>
                          </div>
                       </div>
                       <div class="single-timeline-content d-flex">
                          <div class="timeline-text ">
                             <p>End Date</p>
                             <h6>
                                <span title="{{projectEndDate | dateFormat}}">
                                {{projectEndDate | dateFormat}}
                                </span>
                             </h6>
                          </div>
                       </div>
                    </div>
                    <div class="event-status-container">
                       <div class="status">
                          <p>Total Comments</p>
                          <h2>{{totalComments}}</h2>
                       </div>
                       <div class="status">
                          <p>Total Events</p>
                          <h2>{{totalEventCount}}</h2>
                          <p></p>
                       </div>
                    </div>
                 </div>

                 <div class="page-status-container timelineStatus fdl-2">
                    <div class="summary-container">
                       <ul class="summary-tiles">
                          <li class="list orange">
                             <p class="label">Total Forms</p>
                             <div class="value">
                                <p>{{totalFormsPublished}}</p>
                             </div>
                          </li>
                          <li class="list blue">
                             <p class="label">Not Started</p>
                             <div class="value">
                                <p>{{totalNotStartedForms}}</p>
                             </div>
                          </li>
                          <li class="list yellow">
                             <p class="label">In Progress</p>
                             <div class="value">
                                <p>{{totalInProgressForms}}</p>
                             </div>
                          </li>
                          <li class="list keppel">
                             <p class="label">Under Review</p>
                             <div class="value">
                                <p>{{totalUnderReviewForms}}</p>
                             </div>
                          </li>
                          <li class="list green">
                             <p class="label">Completed</p>
                             <div class="value">
                                <p>{{toalCompletedForms}}</p>
                             </div>
                          </li>
                          <li class="list red">
                             <p class="label">Overdue</p>
                             <div class="value">
                                <p> {{totalOverdueForms}}</p>
                             </div>
                          </li>
                          <li class="list hold">
                             <p class="label">On Hold</p>
                             <div class="value">
                                <p>{{totalOnHoldForms}}</p>
                             </div>
                          </li>
                          <li class="list rejected">
                             <p class="label">Rejected</p>
                             <div class="value">
                                <p>{{totalRejectedForms}}</p>
                             </div>
                          </li>
                       </ul>
                    </div>
                 </div>
                 <section class="timeline_area section_padding_130">
                    <h2 class="timeline-heading">Project Timeline</h2>
                    <div>
                       <div class="row">
                          <div class="col-12">
                             <div class="apland-timeline-area">
                                <div class="single-timeline-area blue header-timeline">
                                   <div class="row timeline-header">
                                      <div class="col-12 col-md-2 col-lg-2">
                                         <div class="single-timeline-content d-flex">
                                          <div class="timeline-text">
                                             <p>
                                                <a class="sorting-action" href="javascript:void(0);" (click)="sort()">
                                                   Modified Date and Time
                                                   <i class="material-icons msort14 ml-1" aria-hidden="true" *ngIf="isAsc">arrow_upward</i>
                                                   <i class="material-icons msort14 ml-1" aria-hidden="true" *ngIf="!isAsc">arrow_downward</i>
                                                 </a>
                                             </p>
                                          </div>
                                         </div>
                                      </div>
                                      <div class="col-12 col-md-3 col-lg-3" >
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                               <p>Event Name and Description</p>
                                            </div>
                                         </div>
                                      </div>
                                      <div class="col-12 col-md-3 col-lg-3">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                               <p>Modified By</p>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div class="single-timeline-area disabled" [ngClass]="getSortClass()" *ngIf="filteredTimelineList.projectStatus != 'Completed' && !isAsc">
                                   <div class="row">
                                      <div class="timeline-date"></div>
                                      <div class="col-12 col-md-2 col-lg-2">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text opacity-05">
                                               <h6>MM/DD/YYYY</h6>
                                               <p>hh:mm ET</p>
                                            </div>
                                         </div>
                                      </div>
                                      <div class="col-12 col-md-3 col-lg-3">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text opacity-05">
                                               <h6>Project Completed</h6>
                                               <p>STATUS UPDATED - Completed</p>
                                            </div>
                                         </div>
                                      </div>
                                      <div class="col-12 col-md-3 col-lg-3">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text opacity-05">
                                               <h6>--</h6>
                                               <p></p>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div [ngClass]="getTimelineStatusCSSClass(timeline.projectStatus)" *ngFor='let timeline of projectTimelineList'>
                                   <div class="row">
                                      <div class="timeline-date"></div>
                                      <div [ngClass]="getStatusClass(timeline.eventName, timeline.percentageComplete, timeline.status, 'dateType')">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                               <h6>{{timeline.modifiedOn | dateFormat}}</h6>
                                               <p>{{timeline.modifiedOn | formatDateTime}} ET</p>
                                            </div>
                                         </div>
                                      </div>

                                      <div class="col-12 col-md-3 col-lg-3"
                                         *ngIf="((timeline.eventName == 'FORM PUBLISHED' || timeline.eventName == 'FORM RE-INITIATED') && (timeline.status != 'Not Started') && timeline.isFormAssigned == true)">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text action" data-toggle="modal" data-target="#formResponseTimeline" (click)="callResponseTimelineListService(timeline.id, timeline.description)">
                                               <h6>{{timeline.eventName}}</h6>
                                               <div><p>{{timeline.description}}</p></div>
                                               <div *ngIf="timeline.eventName == 'FORM PUBLISHED' || timeline.eventName == 'FORM RE-INITIATED'">
                                                  <span class="badge" [ngClass]="getStatusCSSClass(timeline.status)"> {{timeline.status}}</span>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                      <div class="col-12 col-md-3 col-lg-3"
                                         *ngIf="(timeline.eventName == 'FORM PUBLISHED' || timeline.eventName == 'FORM RE-INITIATED') && (timeline.status != 'Not Started') && timeline.isFormAssigned == false">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                               <h6>{{timeline.eventName}}</h6>
                                               <div><p>{{timeline.description}}</p></div>
                                               <div *ngIf="timeline.eventName == 'FORM PUBLISHED' || timeline.eventName == 'FORM RE-INITIATED'">
                                                  <span class="badge" [ngClass]="getStatusCSSClass(timeline.status)"> {{timeline.status}}</span>
                                               </div>
                                            </div>
                                         </div>
                                      </div>


                                      <div [ngClass]="getStatusClass(timeline.eventName, timeline.percentageComplete, timeline.status)"
                                          *ngIf="!((timeline.eventName == 'FORM PUBLISHED' || timeline.eventName == 'FORM RE-INITIATED') && (timeline.status != 'Not Started'))">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                               <h6>{{timeline.eventName}}
                                                  <button class="btn btn-link p-1 pb-0" *ngIf="timeline.attachment && timeline.eventName.includes('PROJECT COMMENT')" type="button"
                                                     aria-hidden="true" title="{{timeline.attachment?.split('/').pop().replace('%20',' ')}}" (click)="onAttachmentClick(timeline.attachment)">
                                                     <span class="f-22 material-icons blue-color"> attach_file </span>
                                                  </button>
                                               </h6>
                                               <p>{{timeline.description}}</p>
                                               <div *ngIf="timeline.status && timeline.eventName != 'FORM PUBLISHED' && timeline.eventName != 'FORM RE-INITIATED'">
                                                  <span class="badge" [ngClass]="getStatusCSSClass(timeline.status, timeline.percentageComplete)">{{timeline.status}}</span>
                                               </div>
                                            </div>
                                         </div>
                                      </div>

                                      <div class="col-12 col-md-3 col-lg-3"
                                          *ngIf="!((timeline.eventName == 'FORM PUBLISHED' || timeline.eventName == 'FORM RE-INITIATED') && (timeline.status == 'Not Started'))">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                               <h6>{{timeline.modifiedBy}}</h6>
                                            </div>
                                         </div>
                                      </div>
                                      <div [ngClass]="getStatusClass(timeline.eventName, timeline.percentageComplete, timeline.status)"
                                          *ngIf="((timeline.eventName == 'FORM PUBLISHED' || timeline.eventName == 'FORM RE-INITIATED') && (timeline.status == 'Not Started'))">
                                         <div class="single-timeline-content d-flex">
                                            <div class="timeline-text">
                                               <h6>--</h6>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div class="single-timeline-area disabled" [ngClass]="getSortClass()" *ngIf="filteredTimelineList.projectStatus != 'Completed' && isAsc">
                                 <div class="row">
                                    <div class="timeline-date"></div>
                                    <div class="col-12 col-md-2 col-lg-2">
                                       <div class="single-timeline-content d-flex">
                                          <div class="timeline-text opacity-05">
                                             <h6>MM/DD/YYYY</h6>
                                             <p>hh:mm ET</p>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-3 col-lg-3">
                                       <div class="single-timeline-content d-flex">
                                          <div class="timeline-text opacity-05">
                                             <h6>Project Completed</h6>
                                             <p>STATUS UPDATED - Completed</p>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-12 col-md-3 col-lg-3">
                                       <div class="single-timeline-content d-flex">
                                          <div class="timeline-text opacity-05">
                                             <h6>--</h6>
                                             <p></p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </section>
              </div>
           </div>
        </section>
     </div>
  </div>
   <!--Project Timeline Page Ends-->

   <!--Response Timeline Modal Starts-->
  <div id="formResponseTimeline" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" class="modal full-width form-response-timeline-wrapper in position-right" style="display: none;" aria-hidden="true">
      <div role="document" class="modal-dialog">
        <div class="modal-content">
           <div class="modal-header white-modal-header">
              <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup" class="close" (click)="closeResponseTimeline()">
                 <span class="material-icons">arrow_back</span>
              </button>
              <h4 class="modal-title"> {{responseTitle}}
                 <small>Latest Updated on {{responseLastUpdated | dateFormat}}</small>
              </h4>
           </div>
           <div class="modal-body">
              <div class="response-timeline-body-content">
                 <h2>Form Response Timeline</h2>
                 <div class="event-status-container">
                    <div class="status">
                       <p>Total Events</p><h2>{{totalResponseEventCount}}</h2>
                    </div>
                 </div>
              </div>

              <div class="timeline-content">
                 <div class="row">
                    <div class="col-12">
                       <div class="apland-timeline-area">
                           <div class="single-timeline-area blue header-timeline">
                             <div class="row timeline-header">
                                <div class="col-12 col-md-2 col-lg-3" >
                                   <div class="single-timeline-content d-flex">
                                    <div class="timeline-text overflow-hidden">
                                       <p>
                                           <a class="sorting-action" href="javascript:void(0);" (click)="sortResponse()">
                                             Date and Time
                                             <i class="material-icons msort14 ml-1" aria-hidden="true" *ngIf="isAscResp">arrow_upward</i>
                                             <i class="material-icons msort14 ml-1" aria-hidden="true" *ngIf="!isAscResp">arrow_downward</i>
                                           </a>
                                       </p>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-12 col-md-3 col-lg-5" >
                                   <div class="single-timeline-content d-flex">
                                      <div class="timeline-text">
                                         <p>Event Name and Description</p>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-12 col-md-3 col-lg-4">
                                   <div class="single-timeline-content d-flex">
                                      <div class="timeline-text">
                                         <p>Modified By</p>
                                      </div>
                                   </div>
                                </div>
                             </div>
                           </div>

                           <div class="single-timeline-area disabled" [ngClass]="getSortRespClass()" *ngIf="responseStatus != 'Completed' && !isAscResp">
                              <div class="row">
                                 <div class="timeline-date"></div>
                                 <div class="col-12 col-md-2 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                       <div class="timeline-text opacity-05">
                                          <h6>MM/DD/YYYY</h6>
                                          <p>hh:mm ET</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 col-md-3 col-lg-5">
                                    <div class="single-timeline-content d-flex">
                                       <div class="timeline-text opacity-05">
                                          <h6>Form Response Completed</h6>
                                          <p>STATUS UPDATED - Completed</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 col-md-3 col-lg-4">
                                    <div class="single-timeline-content d-flex">
                                       <div class="timeline-text opacity-05">
                                          <h6>--</h6>
                                          <p></p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div [ngClass]="getTimelineStatusCSSClass(response.status)" *ngFor='let response of responseTimelineList'>
                             <div class="row">
                                <div class="timeline-date"></div>
                                <div class="col-12 col-md-2 col-lg-3">
                                   <div class="single-timeline-content d-flex">
                                      <div class="timeline-text">
                                         <h6>{{response.modifiedOn | dateFormat}}</h6>
                                         <p>{{response.modifiedOn | formatDateTime}} ET</p>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-12 col-md-3 col-lg-5">
                                   <div class="single-timeline-content d-flex">
                                      <div class="timeline-text">
                                         <h6>{{response.event}}
                                            <button class="btn btn-link p-1 pb-0" *ngIf="response.attachment && response.event.includes('RESPONSE COMMENT')" type="button"
                                               aria-hidden="true" title="{{response.attachment?.split('/').pop().replace('%20',' ')}}" (click)="onAttachmentClick(response.attachment)">
                                               <span class="f-22 material-icons blue-color"> attach_file </span>
                                            </button>
                                         </h6>
                                         <p>{{response.description}}</p>
                                         <div *ngIf="response.status && !response.event.includes('RESPONSE COMMENT') && !response.event.includes('RESPONSE EXTRACT') && !response.event.includes('RESPONSE TITLE')">
                                            <h6>
                                              <span class="badge" [ngClass]="getStatusCSSClass(response.status)">{{response.status}}</span>
                                            </h6>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-12 col-md-3 col-lg-4">
                                   <div class="single-timeline-content d-flex">
                                      <div class="timeline-text">
                                         <h6>{{response.modifyBy}}</h6>
                                      </div>
                                   </div>
                                </div>
                             </div>
                           </div>

                           <div class="single-timeline-area disabled" [ngClass]="getSortRespClass()" *ngIf="responseStatus != 'Completed' && isAscResp">
                              <div class="row">
                                 <div class="timeline-date"></div>
                                 <div class="col-12 col-md-2 col-lg-3">
                                    <div class="single-timeline-content d-flex">
                                       <div class="timeline-text opacity-05">
                                          <h6>MM/DD/YYYY</h6>
                                          <p>hh:mm ET</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 col-md-3 col-lg-5">
                                    <div class="single-timeline-content d-flex">
                                       <div class="timeline-text opacity-05">
                                          <h6>Form Response Completed</h6>
                                          <p>STATUS UPDATED - Completed</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-12 col-md-3 col-lg-4">
                                    <div class="single-timeline-content d-flex">
                                       <div class="timeline-text opacity-05">
                                          <h6>--</h6>
                                          <p></p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
  <!--Response Timeline Modal Ends-->
</div>


<!--Add Comment Modal Starts-->
<div class="modal fade bd-example-modal-lg" id="addcomment" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" style="max-width: 1280px;" role="document">
     <div class="modal-content">
        <div class="modal-header">
           <h4 class="modal-title" id="myLargeModalLabel">Add Comment</h4>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close popup">
              <span aria-hidden="true" (click)="cancelCommentHandle();">×</span>
           </button>
        </div>
        <div class="modal-body">
           <div class="alert alert-dismissible alert-danger" *ngIf="isCommentError">
              <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isCommentError=false">×</button> <i class="material-icons">warning</i>
              <p [innerHtml]="commentErrorMsg"></p>
           </div>
           <div class="row ">
              <div class="col-md-4 mb-3 sd">
                 <label for="formType" >Comment Category:</label>
                 <div class="fdl-select">
                    <select name="formType" class="form-control" aria-invalid="false" [(ngModel)]="commentCat">
                       <option value="Comment">Comment</option>
                       <option value="Issue">Issue</option>

                       <option value="Question">Question</option>
                       <option value="Risk">Risk</option>
                    </select>
                 </div>
              </div>
           </div>
           <div class="row ">
              <div class="col-md-12 mb-3" [ngClass]="{'has-error': !commentControl.valid && (commentControl.touched || commentControl.dirty) }">
                 <label for="txtComment" >Comment:</label>
                 <textarea #commentControl="ngModel" [pattern]="validators.description_comment" type="text" [(ngModel)]="commentValue" class="form-control" rows="4" id="txtComment" maxlength="2000" (keyup)="commentCount()"></textarea>
                 <div class="float-right"><span>{{commentCnt}} / 2000</span></div>
                 <span *ngIf="!commentControl.valid && (commentControl.touched || commentControl.dirty)">
                  <i class="material-icons">warning</i> {{regexValidationMessage.DESCRIPTION_COMMENT_VALIDATION_MESSAGE}}
                </span>
              </div>
           </div>
           <div class="row">
              <div class="col-md-12 mb-3">
                 <label for="commentFileName" >Upload File:
                 <span _ngcontent-yvp-c156="" data-toggle="tooltip"
                    title="File size Limit - 50MB"
                    class="material-icons btn-secondary f-18">info
                 </span>
                 </label>
                 <div class="custom-file">

                 <label for="commentFileName" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="commentFileName"> {{ commentFileName }}</label>
                 <input #inputCommentFile type="file" class="custom-file-input form-control" multiple="false" (change)="onCommentFileChange($event)">
                 </div>
                <div>
                  <span class="has-error" style="color: red;" *ngIf="(!validFileName)">
                      <i class="material-icons">warning</i> Please select valid file name.
                  </span>
                  <span class="has-error" style="color: red;" *ngIf="(!validFile)">
                    <i class="material-icons">warning</i> Please select valid file.
                </span>
                <span class="has-error" style="color: red;" *ngIf="(!validFileType)">
                  <i class="material-icons">warning</i> Please select valid file type.
              </span>
              </div>
              </div>
           </div>
           <div class="fdl-2 w-100 text-right mt-0">
              <button type="button" class="btn fdl-btn btn-secondary-light  mr-2" data-dismiss="modal" (click)="cancelCommentHandle()">Cancel</button>
              <button type="button" class="btn fdl-btn btn-primary-orange" (click)="submitCommentHandle()">Save</button>
           </div>

           <div>
              <label for="searchForm"><b>Project Comments:</b></label>
              <hr />
              <div class="form-row align-items-end mb-3">
                <div class="col-md-5">
                  <label for="organizationTitle" >Comment Category:</label>
                  <div class="fdl-select">
                    <select name="formType" class="form-control" aria-invalid="false" [(ngModel)]="commentCatHistory">
                      <option value="">All</option>
                      <option value="Comment">Comment</option>
                      <option value="Issue">Issue</option>
                      <option value="Question">Question</option>
                      <option value="Risk">Risk</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="assign-modal-table" [ngClass]="getCommentClass(commentBadgeCount)">
                <app-data-table [rows]="commentsOnProjects"
                                [keys]="commentProjectKeys"
                                [displayColumns]="commentProjectHeaders"
                                [enableSorting]="false"
                                searchStringFieldName="pageName"
                                [showCheckbox]="false"
                                [category]="commentCatHistory"
                                [showMenu]="true"
                                [showAttachmentOptions]="true"
                                [showViewEdit]="true"
                                [showAttachment]="true"
                                (attachmentClicked)="downloadAttachment($event)"
                                (buttonClicked)="commentActions($event)"
                                >
                </app-data-table>
              </div>
           </div>

           <div class="mt-3" *ngIf="formComments">
              <label for="searchForm"><b>Form Comments:</b></label>
              <hr />
              <div class="form-row align-items-end mb-3">
                <div class="col-md-5">
                  <label for="searchForm">Page Name:</label>
                  <fdl-type-ahead [(ngModel)]="commentHistSelectedPgName" name="commentHistSelectedPgName" optionField="title" placeholder="Type to search"
                                  [collection]="commentHistFilterOptions" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                  </fdl-type-ahead>

                </div>
                <div class="col-md-5">
                  <label for="searchForm">Form Name:</label>
                  <fdl-type-ahead [(ngModel)]="commentHistSelectedformName" name="commentHistSelectedformName" optionField="title" placeholder="Type to search"
                                  [collection]="commentHistFilterformNameOptions" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                  </fdl-type-ahead>

                </div>

              </div>
              <div *ngIf="showCommentBadge" class="assign-modal-table" [ngClass]="getCommentClass(commentLength)">

                <app-data-table [rows]="commentHistoryList" [keys]="commentTableKeys"
                                [displayColumns]="commentTableHeaders" [enableSorting]="false"
                                [searchString]="commentHistSelectedPgName" searchStringFieldName="pageName" [formName]="commentHistSelectedformName" [showCheckbox]="false"
                                [showMenu]="false"
                                [showAttachment]="true"
                                (attachmentClicked)="downloadAttachment($event)">
                </app-data-table>
              </div>
           </div>

        </div>
     </div>
  </div>
</div>
<!--Add Comment Modal Ends-->

<replace-attachment #replaceAttachment
[id]="commentId"
[formId]="commentResponseId"
[type]="'project'"
(updateAttachment)="updateAttachmentSuccess($event);">
</replace-attachment>

<delete-warning
 [componentId]="'deleteWarningModal'"
 [type]="'comment'"
 (okBtnClicked)="deleteComment(eventData)">
</delete-warning>

<delete-warning
[componentId]="'removeAttachmentWarningModal'"
[type]="'file'"
(okBtnClicked)="removeAttachment(eventData)">
</delete-warning>
