<aside class="side-navbar">
  <div class="main">
    <div role="navigation" class="left-nav">

      <div class="help-recommended-topics">
        <h4>Recommended Topics</h4>
        <div class="help-body">
          <a href="#" class="description-image">Create a Project</a><a href="#" class="description-image">Create an Implementation Template</a><a href="#" class="description-image">Change/Edit a Client</a>
          <a href="#" class="description-image">Add a Task to the project</a><a href="#" class="description-image">Other Topics</a>
        </div>
      </div>
    </div>
  </div>
</aside>
