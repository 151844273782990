<div class="heading-container fdl-2">
  <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="cancel()">
        <span class="material-icons">
          arrow_back
        </span>
        {{returnPage}}
      </a>
    </div>
    <h1 class="text-truncate" data-toggle="tooltip" data-placement="top" title="{{templateName}}">{{templateName}}</h1>
  </div>
  <div>
    <div class="fdl-2 pull-right" *ngIf="showPreviewBtn && !isResponse">

      <button type="button" class="btn fdl-btn btn-primary-dark mr-2" (click)="openDrawer();">
        Change Page Order
      </button>
      <button type="button"  class="btn fdl-btn btn-primary-dark" (click)="previewForm();">
        <span class="material-icons mr-2">
          remove_red_eye
        </span>Preview
      </button>
    </div>
  </div>
  </div>
<div class="" *ngIf="isResponse">
  <div id="accordion" class="accordion collapse-view col-md-12 accordian-responses">
    <div data-toggle="collapse" href="#collapseOne" class="page-heading-container fdl-2" aria-expanded="true">
      <h2 class="h4 heading-level-2">Form Details</h2> 
    </div>

    <div id="collapseOne" data-parent="#accordion" class="collapse show">
      <div class="biller-survey">
        <div class="fdl-2 pull-right" *ngIf="isResponse">
          <button type="button" class="btn fdl-btn btn-primary-dark float-right d-inline ml-3 mr-1" data-toggle="modal" data-target="#reservedKeysModal">Reserved Keys</button>
        </div>
        <div class="mt-2">
          <ul>
            <li *ngIf="formDetails.projectTitle">Project Name <span class="info">{{formDetails?.projectTitle || 'N/A'}}</span></li>
            <li *ngIf="formDetails.clientTitle">Client Name <span class="info">{{formDetails?.clientTitle || 'N/A'}}</span></li>
            <li *ngIf="formDetails.assignedToName">Respondent Name <span class="info">{{formDetails?.assignedToName || 'N/A'}}</span></li>
            <li *ngIf="formDetails.fiservImplementationManagerName">Project Manager Name <span class="info">{{formDetails?.fiservImplementationManagerName || 'N/A'}}</span></li>
            <li *ngIf="formDetails.currentApproverName">Approver Name <span class="info">{{formDetails?.currentApproverName || 'N/A'}}</span></li>
            <li *ngIf="formDetails.dueDate">Due Date <span class="info">{{formDetails?.dueDate | dateFormat}}</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-container separate-form-container ">
  <form>
    <div class="biller-survey upload-header" *ngIf="!isResponse">
      <div>
        <ul>
          <li class="pl-0">Form Description: <span class="info">{{formDescription}}</span></li>
          <li *ngIf="productName">
            Product:
            <span class="info">{{productName}}</span>
          </li>
          <li *ngIf="templateOpton">
            Template Option:
            <span class="info">{{templateOpton}}</span>
          </li>
        </ul>
      </div>
      <div class="fdl-2 pull-right">
        <button type="button" class="btn fdl-btn btn-primary-dark float-right d-inline ml-3 mr-1" data-toggle="modal" data-target="#reservedKeysModal">Reserved Keys</button>

        <button type="button" class="btn fdl-btn btn-primary-dark float-right d-inline ml-3" (click)="goToManageFileUploads();">Manage File Uploads</button>
        <button type="button" class="btn fdl-btn btn-primary-dark  float-right d-inline  ml-3" (click)="editForm();">Edit</button>
      </div>
    </div>
    <br>
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
      <i class="material-icons">warning</i>
      <p [innerHtml]="errorMsg"></p>
    </div>
    <div class="alert alert-success alert-dismissible" *ngIf="showSuccessMsg">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="showSuccessMsg=false">×</button>
      <span class="iconLabel" aria-label="sucess"></span>
      <i class="material-icons" aria-hidden="true">check_circle</i>
      <p>
        {{successMsg}}
      </p>
    </div>
    <div class="alert alert-warning alert-dismissible" *ngIf="showWarningMsg">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="showWarningMsg=false">×</button>
      <span class="iconLabel" aria-label="warning"></span>
      <i class="material-icons" aria-hidden="true">check_circle</i>
      <p>
        {{warningMsg}}
      </p>
    </div>
    <div class="form form-border collapse-view" style="margin-bottom:10px" *ngIf="selectedFormsData && selectedFormsData.length!=0">
      <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Update Connected form</h2>
      <div class="filter-form-container-column2 collapse" id="collapseFilter">
        <div class="filter-form-control pr-4 typeahead-wrapper" style="width:100%;padding-left:0px;margin-left:0px">
          <div class="row" style="padding-bottom:10px">
            <div class="col-md-5" style="padding-left:15px">
              <label for="searchForm">Form Name:</label>
              <input type="text" id="searchForm" class="form-control" placeholder="Type to search"
                     [(ngModel)]="searchString" name="searchString"
                     autocomplete="off">

            </div>
          </div>
          <div class="col-md-12" style="height:150px;overflow-y:auto;padding-left:0px">
            <app-data-table [rows]="selectedFormsData"
                            [keys]="keys" [enableSorting]="false" [searchString]="searchString"
                            [displayColumns]="displayColumns" [formFetchAction]="true" [showCheckbox]="false" [showViewEdit]="true" [showMenu]="false" (connectedFormClicked)="takeAction($event)" [listname]="listname">
            </app-data-table>
          </div>

        </div>
      </div>


    </div>
    <!--<formio-wizard [form]="form" (change)="onChange($event)"></formio-wizard>-->


    <form-builder [form]="form" [options]="formbuilder"  [refresh]="refreshForm" (change)="onChange($event)"></form-builder>

    <div class="fdl-2 button-container">
      <button type="button" class="btn fdl-btn btn-secondary-light " aria-label="Cancel" (click)="cancel()">Cancel</button>
      <div>
        <button type="button" class="btn fdl-btn btn-primary-orange mr-3" (click)="submitForm(false);">Save</button>
        <button type="button" class="btn fdl-btn btn-primary-orange" (click)="submitForm()">Submit</button>

      </div>
    </div>
  </form>
</div>

<!----- DATA LOSS MODAL BEGIN -------->
<div class="modal fade bd-example-modal-lg" id="dataLoss" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Confirm Navigation</h4>
      </div>
      <div class="modal-body">
        <p><b>Are you sure you want to navigate away from this page?</b></p>
        <br>
        <p>
          The page contains unsaved data. If you choose to <b>Leave this page</b>, unsaved data will be lost. Choose <b>Stay on this page</b> option to return to form and save the changes
        </p>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class=" btn fdl-btn btn-secondary-light " data-dismiss="modal">
          Stay on this Page
        </button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal" (click)="proceed()">
          Leave this Page
        </button>
      </div>
    </div>
  </div>
</div>
<!---  DATA LOSS MODAL END  -------->


<!-- Reserved Keys Modal Starts -->
<div id="reservedKeysModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg  ">
  <div role="document" class="modal-dialog modal-lg">
     <div class="modal-content">
        <div class="modal-header">
           <h4 id="myLargeModalLabel" class="modal-title">Reserved Keys</h4>
           <!-- <span>Reserved Keys can be used to pre-populate data in the while Form Template components.</span> -->
           <button type="button" data-dismiss="modal" aria-label="Close popup" class="close"><span aria-hidden="true">×</span></button>
        </div>
        <div class="modal-body">
          <div class="overflow-300 assign-modal-table table-responsive">
            <app-data-table [rows]="reservedVariables?.reservedKey" [keys]="reservedkeys" [enableSorting]="false"
                            [displayColumns]="displayReservedKeyColumns" [showCheckbox]="false" [showActionButton]="true" [actionName]="actionName"
                            (actionBtnClicked)="copyVariable($event)" [showMenu]="false">
            </app-data-table>

          </div>
        </div>
        <div class="modal-footer fdl-2 button-container">
          <input type="text" style="height: 2.0rem; width: 275px; background-color: #eeeeee; border: none;"
                 id="idCopiedSysVar" [ngModel]="copiedSysVar" readonly="readonly" />
          <p class="text-success mt-2" *ngIf="showCopyMsg">Variable is copied successfully.</p>
          <button type="button" class="btn fdl-btn btn-secondary-light " (click)="crossModal()" data-dismiss="modal">
            Close
          </button>
        </div>
     </div>
  </div>
</div>
<div *ngIf="initatorDrawer">
  <div>
    <div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" tabindex="-1" aria-labelledby="addNewAccount"
         aria-expanded="true" role="region">

      <div class="drawer-contents col-xs-12 col-sm-12 col-md-12 project-drawer p1">
        <h3>Change Page Order</h3>
        <span>Drag and Drop to change the page order as required.</span>


        <div class="">
          <div class="form-container p-1">



            <div class="form-row">
              <div class="col-md-12 mb-1">

              </div>
            </div>
            <style>
              .example-container {
                width: 400px;
                max-width: 100%;
                margin: 0 25px 25px 0;
                display: inline-block;
                vertical-align: top;
              }

              .example-list {
                border: solid 1px #ccc;
                min-height: 60px;
                background: white;
                border-radius: 4px;
                overflow: hidden;
                display: block;
              }

              .example-box {
                padding: 20px 10px;
                border-bottom: solid 1px #ccc;
                color: rgba(0, 0, 0, 0.87);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                cursor: move;
                background: white;
                font-size: 14px;
              }

              .cdk-drag-preview {
                box-sizing: border-box;
                border-radius: 4px;
                box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
              }

              .cdk-drag-placeholder {
                opacity: 0;
              }

              .cdk-drag-animating {
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
              }

              .example-box:last-child {
                border: none;
              }

              .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
              }
            </style>
            <div cdkDropList
                 [cdkDropListData]="pageOrderData"
                 class="example-list"
                 (cdkDropListDropped)="drop($event)">
              <div class="example-box" *ngFor="let item of pageOrderData" cdkDrag>{{item.title}}</div>
            </div>
          </div>



        </div>
        <div class="fdl-2 button-container">
          <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal" (click)="clear()">
            Cancel
          </button>
          <div>
            <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" (click)="setOrder()">
              Ok
            </button>
          </div>
        </div>
      </div>
  
    </div>
  </div>
  <div class="backdrop-space">
    <div class="backdrop"></div>
  </div>
</div>
