import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { IQuery } from "../shared/query";
import { IResponseExtract, IResponseExtractList } from "../shared/response-extract";

type ExtractData = {
    id: number;
    extractRequestGuid: string;
    extractRequestName: string;
    status: string;
    extractResponseFinalUrl: string;
  }

@Injectable({
    providedIn: 'root'
})
export class ResponseExtractService {
    private responseExtractAPIURL = 'responseextractrequest';
    private responseExtractList: IResponseExtractList;
    private _tempData: string = "";
    private userAllowedPermissions: any[]
    private _isExtractCreated: string = '';
    private _extractDetails: ExtractData;

    constructor(private http: HttpClient) { }

    set tempData(value) {
        this._tempData = value;
    }

    get tempData() {
        return this._tempData;
    }

    get isExtractCreated(){
        return this._isExtractCreated;
    }

    set isExtractCreated(msg: string){
        this._isExtractCreated = msg;
    }

    get getExtractDetails(){
        return this._extractDetails;
    }

    set setExtractDetails(data: ExtractData){
        this._extractDetails = data;
    }

    setPermissions(permission) {
        this.userAllowedPermissions = permission;
    }

    getPermissions() {
        return this.userAllowedPermissions;
    }

    callResponseExtractList(): Observable<IResponseExtract> {
        return this.http.get<IResponseExtract>(this.responseExtractAPIURL);
    }

    getResponseExtractList(queryParams: IQuery) {
        let params = new HttpParams();

        for (const key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }

        let options = { 'refresh': 'true' }

        return this.http.get(this.responseExtractAPIURL, { headers: options, params: params, observe: 'response' });
    }

    deleteResponseExtract(Id: number) {
        return this.http.delete(`${this.responseExtractAPIURL}/${Id}`);
    }  

    createResponseExtract(data) {
        return this.http.post<IResponseExtract>(`${this.responseExtractAPIURL}`, data);
    }

    getResponseExtractById(id: number) {
        return this.http.get<IResponseExtract>(`${this.responseExtractAPIURL}/${id}`);
    }

    getResponsesList(extractRequestId: number, queryParams: IQuery) {
        let params = new HttpParams();
        for (const key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }
        let url = this.responseExtractAPIURL + "/" + extractRequestId + "/responses"
        
        return this.http.get(url, {params: params});
    }
}

