import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs/operators";
import { FormsService } from "src/app/services/forms.service";
import { ProjectsService } from "src/app/services/projects.service";
import { IProjectTimeline, IResponseTimeline} from "src/app/shared/timeline";
import * as J$ from 'jquery';
import { IForm } from "src/app/shared/form";
import { Location } from "@angular/common";
import { FileValidators } from "src/app/self-service/components/manageFileUploads/validators/file-validators";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
declare var $: any;

@Component({
    selector: "project-timeline",
    templateUrl: './project-timeline.component.html',
    styleUrls: ["./project-timeline.component.css"]
})

export class ProjectTimelineComponent implements OnInit, OnDestroy {
    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private projectService: ProjectsService,
        private formsService: FormsService
    ) { }

    regexValidationMessage: any;
    isAsc: boolean = false;
    isAscResp: boolean = false;
    isError: boolean = false;
    errorMsg: string = "";
    totalResponseEventCount: any;
    totalEventCount: any;
    totalComments: any;
    totalFormsPublished: any;
    totalOnHoldForms: any;
    totalRejectedForms: any;
    toalCompletedForms: any;
    totalUnderReviewForms: any;
    totalInProgressForms: any;
    totalNotStartedForms: any;
    totalOverdueForms: any;
    totalInitiatedForms: any;
    projectEndDate: string;
    responseStatus: string;

    projectId: number = 0;
    projectTimelineList: IProjectTimeline[];
    filteredTimelineList: IProjectTimeline;
    responseTimelineList: IResponseTimeline[];
    filteredResponseTimelineList: IResponseTimeline;

    responseTitle: string;
    responseLastUpdated: any;
    showCommentBadge: boolean = false;
    commentBadgeCount: number = 0;

    commentsOnProjects: any = {};
    commentProjectHeaders = ["Project Name", "Category", "Comment", "Added On", "Added By"];
    commentProjectKeys = ["pageName", "category", "comment", "createdOn", "createdByName"];
    commentTableHeaders = ["Form Name", "Page Name", "Category", "Comment", "Added On", "Added By"];
    commentTableKeys = ["formName", "pageName", "category", "comment", "createdOn", "createdByName"];

    responseId: number;
    project: any;
    commentCat: string = 'Comment';
    commentValue: string;
    commentCnt: number = 0;
    commentFile: any;
    commentFileName: string;
    commentId: number;
    commentResponseId:number;
    isCommentError: boolean = false;
    commentErrorMsg: string = "";
    isCommentSubmit: boolean = false;
    commentLength: number;
    commentCatHistory: string = '';
    commentHistSelectedPgName: string;
    displayResponseList: IForm[];
    commentHistoryList: any;
    formComments: boolean = false;
    commentHistFilterOptions: string[];
    commentHistFilterformNameOptions: string[];
    commentHistSelectedformName: any;
    formsList: any = [];
    eventData: any;

    validFile: boolean = true;
    validFileType : boolean = true;
    validFileName : boolean = true;
    submitted: boolean = false;
    commentForm: FormGroup
    validators = REGEX_PATTERNS;
    private fb: FormBuilder
    @ViewChild('inputCommentFile') commentFileInput: ElementRef;

    ngOnInit(): void {
      this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
      this.formsList = [];

      this.route.params
      .pipe(take(1))
      .subscribe(
          (params) => {
              this.projectId = params['id'];
          this.callProjectTimelineListService();
          this.getProjectData();
          });
      this.initFilterForm();
    }

    initFilterForm() {
      this.commentForm = this.fb.group({
        commentFileName: [null, []]
      });
    }

    sortResponse() {
        this.isAscResp = !this.isAscResp;
        this.callResponseTimelineListService(this.responseId, this.responseTitle);
    }

    sort() {
        this.isAsc = !this.isAsc;
        this.callProjectTimelineListService();
    }

    ngOnDestroy(): void {
    }

  refresh() {

    this.callProjectTimelineListRefreshService();
    }

    getSortClass(){
        if (this.isAsc) return "sorting";
    }

    getSortRespClass(){
        if (this.isAscResp) return "sorting";
    }

  callProjectTimelineListService() {

        $('.refresh-icon-link').addClass('fa-rotation');
        let sorting = this.isAsc? "ASC": "DESC";

        this.projectService.getProjectTimeline(this.projectId, sorting)
        .pipe(take(1))
        .subscribe(
          timelines => {
            this.getProjectData();
            this.isError = false;
            this.projectTimelineList = timelines;
            this.totalEventCount = this.projectTimelineList.length;
            if (this.totalEventCount < 10) {
                this.totalEventCount = '0' + this.totalEventCount;
            }
            this.calculateEvents();
            $('.refresh-icon-link').removeClass('fa-rotation');
        },
        error => {
            this.isError = true;
            this.errorMsg = error;
        }
        );
  }

  callProjectTimelineListRefreshService() {

    $('.refresh-icon-link').addClass('fa-rotation');
    let sorting = this.isAsc ? "ASC" : "DESC";

    this.projectService.getProjectTimeline(this.projectId, sorting)
      .pipe(take(1))
      .subscribe(
        timelines => {
          this.getProjectData();
          this.isError = false;
          this.projectTimelineList = timelines;
          this.totalEventCount = this.projectTimelineList.length;
          if (this.totalEventCount < 10) {
            this.totalEventCount = '0' + this.totalEventCount;
          }
          this.calculateEventsAfterRefresh();
          $('.refresh-icon-link').removeClass('fa-rotation');
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }
  calculateEventsAfterRefresh() {
    if (!this.isAsc)
      this.filteredTimelineList = this.projectTimelineList[0];
    else
      this.filteredTimelineList = this.projectTimelineList[this.totalEventCount - 1];
    this.totalFormsPublished = 0;
    this.totalOnHoldForms = 0;
    this.totalRejectedForms = 0;
    this.toalCompletedForms = 0;
    this.totalUnderReviewForms = 0;
    this.totalInProgressForms = 0;
    this.totalNotStartedForms = 0;
    this.totalOverdueForms = 0;
    this.totalInitiatedForms = 0;

    // if (this.filteredTimelineList.projectEndDate > this.filteredTimelineList.projectStartDate)
    if (this.filteredTimelineList.projectEndDate != '0001-01-01T00:00:00Z' && this.filteredTimelineList.projectEndDate != '0001-12-31T00:00:00Z')
      this.projectEndDate = this.filteredTimelineList.projectEndDate.split('T', 10)[0];

    this.projectTimelineList.forEach((responseObj: any) => {
      // if (responseObj.eventName.includes("PROJECT COMMENT")) {
      //     this.totalComments = this.totalComments + 1;
      // }

      if (responseObj.eventName === "FORM PUBLISHED" && responseObj.isResponseActive) {
        if (this.formsList.indexOf(responseObj.id) === -1)
          this.formsList.push(responseObj.id);
          this.totalFormsPublished = this.totalFormsPublished + 1;
          switch (responseObj.status) {
            case 'In Progress':
              this.totalInProgressForms = this.totalInProgressForms + 1;
              break;
            case 'Initiated':
              this.totalInitiatedForms = this.totalInitiatedForms + 1;
              break;
            case 'Completed':
              this.toalCompletedForms = this.toalCompletedForms + 1;
              break;
            case 'Overdue':
              this.totalOverdueForms = this.totalOverdueForms + 1;
              break;
            case 'Not Started':
              this.totalNotStartedForms = this.totalNotStartedForms + 1;
              break;
            case 'On Hold':
              this.totalOnHoldForms = this.totalOnHoldForms + 1;
              break;
            case 'Rejected':
              this.totalRejectedForms = this.totalRejectedForms + 1;
              break;
            case 'Under Review':
              this.totalUnderReviewForms = this.totalUnderReviewForms + 1;
              break;
            default:
              break;
          }
      }
    });

    // this.totalComments = this.commentBadgeCount;
    // if (this.totalComments < 10) {
    //     this.totalComments = '0' + this.totalComments;
    // }

    if (this.totalFormsPublished < 10) {
      this.totalFormsPublished = '0' + this.totalFormsPublished;
    }

    if (this.totalOnHoldForms < 10) {
      this.totalOnHoldForms = '0' + this.totalOnHoldForms;
    }

    if (this.totalRejectedForms < 10) {
      this.totalRejectedForms = '0' + this.totalRejectedForms;
    }

    if (this.toalCompletedForms < 10) {
      this.toalCompletedForms = '0' + this.toalCompletedForms;
    }

    if (this.totalUnderReviewForms < 10) {
      this.totalUnderReviewForms = '0' + this.totalUnderReviewForms;
    }

    if (this.totalInProgressForms < 10) {
      this.totalInProgressForms = '0' + this.totalInProgressForms;
    }

    if (this.totalNotStartedForms < 10) {
      this.totalNotStartedForms = '0' + this.totalNotStartedForms;
    }

    if (this.totalOverdueForms < 10) {
      this.totalOverdueForms = '0' + this.totalOverdueForms;
    }

    if (this.totalInitiatedForms < 10) {
      this.totalInitiatedForms = '0' + this.totalInitiatedForms;
    }
  }

  calculateEvents() {
    if (!this.isAsc)
        this.filteredTimelineList = this.projectTimelineList[0];
    else
        this.filteredTimelineList = this.projectTimelineList[this.totalEventCount-1];
      this.totalFormsPublished = 0;
      this.totalOnHoldForms = 0;
      this.totalRejectedForms = 0;
      this.toalCompletedForms = 0;
      this.totalUnderReviewForms = 0;
      this.totalInProgressForms = 0;
      this.totalNotStartedForms = 0;
      this.totalOverdueForms = 0;
      this.totalInitiatedForms = 0;

      // if (this.filteredTimelineList.projectEndDate > this.filteredTimelineList.projectStartDate)
      if (this.filteredTimelineList.projectEndDate != '0001-01-01T00:00:00Z' && this.filteredTimelineList.projectEndDate!='0001-12-31T00:00:00Z')
          this.projectEndDate = this.filteredTimelineList.projectEndDate.split('T',10)[0];

      this.projectTimelineList.forEach((responseObj: any) => {
          // if (responseObj.eventName.includes("PROJECT COMMENT")) {
          //     this.totalComments = this.totalComments + 1;
          // }

          if (responseObj.eventName === "FORM PUBLISHED" && responseObj.isResponseActive) {
              if(this.formsList.indexOf(responseObj.id) === -1) {
                  this.formsList.push(responseObj.id);
                  this.totalFormsPublished = this.totalFormsPublished + 1;

                  switch (responseObj.status) {
                      case 'In Progress':
                          this.totalInProgressForms = this.totalInProgressForms + 1;
                          break;
                      case 'Initiated':
                          this.totalInitiatedForms = this.totalInitiatedForms + 1;
                          break;
                      case 'Completed':
                          this.toalCompletedForms = this.toalCompletedForms + 1;
                          break;
                      case 'Overdue':
                          this.totalOverdueForms = this.totalOverdueForms + 1;
                          break;
                      case 'Not Started':
                          this.totalNotStartedForms = this.totalNotStartedForms + 1;
                          break;
                      case 'On Hold':
                          this.totalOnHoldForms = this.totalOnHoldForms + 1;
                          break;
                      case 'Rejected':
                          this.totalRejectedForms = this.totalRejectedForms + 1;
                          break;
                      case 'Under Review':
                          this.totalUnderReviewForms =  this.totalUnderReviewForms + 1;
                          break;
                      default:
                          break;
                  }
              }
          }
      });

      // this.totalComments = this.commentBadgeCount;
      // if (this.totalComments < 10) {
      //     this.totalComments = '0' + this.totalComments;
      // }

      if (this.totalFormsPublished < 10) {
          this.totalFormsPublished = '0' + this.totalFormsPublished;
      }

      if (this.totalOnHoldForms < 10) {
          this.totalOnHoldForms = '0' + this.totalOnHoldForms;
      }

      if (this.totalRejectedForms < 10) {
          this.totalRejectedForms = '0' + this.totalRejectedForms;
      }

      if (this.toalCompletedForms < 10) {
          this.toalCompletedForms = '0' + this.toalCompletedForms;
      }

      if (this.totalUnderReviewForms < 10) {
          this.totalUnderReviewForms = '0' + this.totalUnderReviewForms;
      }

      if (this.totalInProgressForms < 10) {
          this.totalInProgressForms = '0' + this.totalInProgressForms;
      }

      if (this.totalNotStartedForms < 10) {
          this.totalNotStartedForms = '0' + this.totalNotStartedForms;
      }

      if (this.totalOverdueForms < 10) {
          this.totalOverdueForms = '0' + this.totalOverdueForms;
      }

      if (this.totalInitiatedForms < 10) {
          this.totalInitiatedForms = '0' + this.totalInitiatedForms;
      }
  }

  goBack() {
      // this.router.navigate(["/projects/list"]);
      this.location.back();
  }

  getStatusClass(eventName: any, percentageComplete: any, status: any, type: any = "") {
      if ((eventName === 'FORM PUBLISHED' || eventName == 'FORM RE-INITIATED' || eventName === 'FORM DELETED') && percentageComplete === 0 && (status === 'Not Started')) {
          if (type) {return "col-12 col-md-3 col-lg-2 opacity-05"; } else {return "col-12 col-md-3 col-lg-3 opacity-05"; }
      }
      else {
      if (type) {return "col-12 col-md-3 col-lg-2"; } else {return "col-12 col-md-3 col-lg-3";}
      }
  }

  getStatusCSSClass(status: any, percentageComplete: any = 100) {
      let statusColor;
      switch (status) {
        case 'In Progress':
          if (percentageComplete > 0){
              statusColor = 'status yellow';
          }
          else {
              statusColor = 'status yellow opacity-05';
          }
          // statusColor = 'status yellow';
          break;
        case 'Initiated':
          if (percentageComplete > 0){
              statusColor = 'status orange';
          }
          else {
              statusColor = 'status orange opacity-05';
          }
          // statusColor = 'status orange';
          break;
        case 'Completed':
          if (percentageComplete > 0){
              statusColor = 'status green';
          }
          else {
              statusColor = 'status green opacity-05';
          }
          // statusColor = 'status green';
          break;
        case 'Overdue':
          if (percentageComplete > 0){
              statusColor = 'status red';
          }
          else {
              statusColor = 'status red opacity-05';
          }
          // statusColor = 'status red';
          break;
        case 'Not Started':
          if (percentageComplete > 0){
              statusColor = 'status blue';
          }
          else {
              statusColor = 'status blue opacity-05';
          }
          // statusColor = 'status blue';
          break;
        case 'On Hold':
          if (percentageComplete > 0){
              statusColor = 'status hold';
          }
          else {
              statusColor = 'status hold opacity-05';
          }
          break;
        case 'Cancel':
          if (percentageComplete > 0) {
            statusColor = 'status grey';
          }
          else {
            statusColor = 'status grey opacity-05';
          }
          // statusColor = 'status hold';
          break;
        case 'Rejected':
          if (percentageComplete > 0){
              statusColor = 'status rejected';
          }
          else {
              statusColor = 'status rejected opacity-05';
          }
          // statusColor = 'status rejected';
          break;
        case 'Under Review':
          if (percentageComplete > 0){
              statusColor = 'status keppel';
          }
          else {
              statusColor = 'status keppel opacity-05';
          }
          // statusColor = 'status keppel';
          break;
        default:
          if (percentageComplete > 0){
              statusColor = 'status orange';
          }
          else {
              statusColor = 'status orange opacity-05';
          }
          // statusColor = 'status orange';
          break;
      }
      return statusColor;
  }

  getTimelineStatusCSSClass(status: any) {
      let statusColor;
      switch (status) {
      case 'In Progress':
          statusColor = 'single-timeline-area yellow';
          break;
      case 'Initiated':
          statusColor = 'single-timeline-area orange';
          break;
      case 'Completed':
          statusColor = 'single-timeline-area green';
          break;
      case 'Overdue':
          statusColor = 'single-timeline-area red';
          break;
      case 'Not Started':
          statusColor = 'single-timeline-area blue';
          break;
      case 'On Hold':
          statusColor = 'single-timeline-area hold';
          break;
      case 'Cancel':
          statusColor = 'single-timeline-area grey';
          break;
      case 'Rejected':
          statusColor = 'single-timeline-area rejected';
          break;
      case 'Under Review':
          statusColor = 'single-timeline-area keppel';
          break;
      default:
          statusColor = 'single-timeline-area orange';
          break;
      }
      return statusColor;
  }

  callResponseTimelineListService(responseId, responseTitle) {
      J$('.container-page').addClass('overflow-hidden');
      this.responseId = responseId;
      this.responseTitle = responseTitle;
      let sorting = this.isAscResp? "ASC": "DESC";
      this.formsService
      .getResponsesTimeline(responseId, sorting)
      .pipe(take(1))
      .subscribe(
          responses => {
              this.isError = false;
              this.responseTimelineList = responses;
              this.totalResponseEventCount = this.responseTimelineList.length;
              if (!this.isAscResp){
                  this.filteredResponseTimelineList = this.responseTimelineList[0];
                  this.responseLastUpdated = this.responseTimelineList[0].modifiedOn;
              }
              else {
                  this.filteredResponseTimelineList = this.responseTimelineList[this.totalResponseEventCount-1];
                  this.responseLastUpdated = this.responseTimelineList[this.totalResponseEventCount-1].modifiedOn;
              }
              // this.filteredResponseTimelineList = this.responseTimelineList[0];
              // this.responseLastUpdated = this.responseTimelineList[0].modifiedOn;
              if (this.totalResponseEventCount < 10) {
                  this.totalResponseEventCount = '0' + this.totalResponseEventCount;
              }
              this.getResponse();
          },
          error => {
              this.isError = true;
              this.errorMsg = error;
          }
      );
    }

    closeResponseTimeline() {
        J$('.container-page').removeClass('overflow-hidden');
    }

    openAddCommentModal() {
        this.getProjectData();
        $('#addcomment').modal('show');
        this.commentValue = '';
        this.commentCat = 'Comment';
        this.commentHistSelectedPgName = '';
    }


    cancelCommentHandle() {
        this.validFile = true;
        this.validFileName = true;
        this.commentValue = '';
        this.commentFile = '';
        this.commentFileName = '';
        this.isCommentError = false;
        this.commentErrorMsg = "";
        this.isCommentSubmit = false;
        this.commentFileInput.nativeElement.value = "";
        this.callProjectTimelineListService();
    }

    onCommentFileChange(event) {
        const target: DataTransfer = <DataTransfer>event.target;
        this.commentFile = target.files;
        let maxFileSize = MAX_LENGTHS.File.Size;

        if (this.commentFile.item(0).size <= (maxFileSize * 1024 * 1024)) {
            if (this.commentFile.length > 0) {
            this.commentFileName = this.commentFile.item(0).name;
            this.validFileName = FileValidators.ValidateFileName(this.commentFileName)

            let lastIndex = this.commentFileName.lastIndexOf(".");
            let extension = this.commentFileName.substring(lastIndex + 1);
            this.validFile = FileValidators.validateExtensionFile(this.commentFile.item(0).name)!!.invalidExt || false;
            if (this.validFile == true){
              this.validFileType = FileValidators.CheckAllowedContentType(event,  extension.toUpperCase());
            }
            }
        }
        else {
            this.isCommentError = true;
            this.commentErrorMsg = "File size exceeds maximum size limit i.e. 50 MB";
            this.commentFileInput.nativeElement.value = "";
            this.commentFile = '';
            this.commentFileName = '';
        }
    }

    submitCommentHandle() {
      if(!this.validators.description_comment.test(this.commentValue)) return;
      this.validFile = true;
      this.validFileName = true;
        if (this.commentValue?.trim() != '') {
            this.isCommentSubmit = true;
            let uploadedFile: any;
            if (!this.commentFileName) {
                uploadedFile = "";
            }
            else {
                uploadedFile = this.commentFile.item(0);
            }
            let body = {
                "comment": this.commentValue,
                "pageName": this.filteredTimelineList.projectName,
                "category": this.commentCat?.trim(),
                "projectId": this.projectId,
                "file": uploadedFile
            };

            this.projectService.postResponseComment(body, this.projectId).subscribe(
            (data) => {
                this.callProjectTimelineListService();
                this.getProjectData();
                this.commentValue = '';
                this.commentFile = '';
                this.commentFileName = '';
                this.commentCat = 'Comment';
                this.isCommentError = false;
                this.commentErrorMsg = "";
                this.isCommentSubmit = false;
                this.commentFileInput.nativeElement.value = "";
                this.commentCnt = 0;
            },
            (error) => {
                this.commentValue = '';
                this.commentFile = '';
                this.commentFileName = '';
                this.isCommentError = true;
                this.commentErrorMsg = error;
                this.isCommentSubmit = false;
                this.commentFileInput.nativeElement.value = "";
                this.commentCnt = 0;
            }
            );
        }
        else {
            this.isCommentError = true;
            this.commentErrorMsg = "Please enter the comment to save.";

        }
    }

    onAttachmentClick(attachment) {
        window.open(attachment, "_blank");
    }

    downloadAttachment(event) {
        window.open(event.data, "_blank");
    }

    getCommentClass(cnt) {
        if (cnt > 5) {
          return 'table-responsive mb-0';
        }
    }

    getProjectData() {
        this.commentCnt = 0;
        this.projectService.getProjectById(this.projectId)
        .pipe(take(1))
        .subscribe(
          project => {
            this.project = project;
            this.displayResponseList = Object.assign([], project.responses);
            this.displayResponseList.forEach(responseList => {
                responseList.delegateLink = false;
                responseList.updateApproverLink = false;
            });
            let commentArray = []
            for (let i in this.displayResponseList) {
              for (let k in this.displayResponseList[i].responseComments) {
                let commentHistory: any = this.displayResponseList[i].responseComments[k];
                commentHistory['formName'] = this.displayResponseList[i].title;
                commentArray.push(commentHistory)
            }}

            if (project.comments.length > 0) {
                this.commentsOnProjects = project.comments;
                this.showCommentBadge = true;
                this.commentBadgeCount = project.comments.length;
            }
            else {
                this.commentsOnProjects = {};
                this.showCommentBadge = false;
                this.commentBadgeCount = 0
            }

            if (commentArray.length > 0) {
                this.commentLength = commentArray.length;
                this.commentHistoryList = commentArray;
                this.showCommentBadge = true;
                this.commentBadgeCount = this.commentBadgeCount + commentArray.length;
                this.formComments = true;
                let options: string[] = this.commentHistoryList.filter(el => el.pageName != null && el.pageName.trim() != '').map(item => item.pageName);
                this.commentHistFilterOptions = [...new Set(options)];
                let formoptions: string[] = this.commentHistoryList.filter(el => el.formName != null && el.formName.trim() != '').map(item => item.formName);
                this.commentHistFilterformNameOptions = [...new Set(formoptions)];
            }

            this.totalComments = this.commentBadgeCount;
            if (this.totalComments < 10) {
                this.totalComments = '0' + this.totalComments;
            }
        });
    }

    commentActions(event) {
        switch (event.action) {
          case "replace":
            this.commentId = event.data;
            this.commentResponseId = this.projectId;
            //$('#replaceAttachment').modal('show');
            $('#replaceAttachment').show();
            $("#replaceAttachment").addClass('show');
            $('body').addClass('modal-open');
            break;
            case "delete":
              this.eventData = event.data;
              $('#deleteWarningModal').show();
              $("#deleteWarningModal").addClass('show');
              $('body').addClass('modal-open');
              break;
            case "remove":
              this.eventData = event.data;
              $('#removeAttachmentWarningModal').show();
              $("#removeAttachmentWarningModal").addClass('show');
              $('body').addClass('modal-open');
              break;
          default:
            break;
        }
    }

    deleteComment(id) {
        this.projectService.deleteResponseComment(id, this.projectId)
          .subscribe(
          () => {
            this.getProjectData();
          },
          error => {
            this.isCommentError = true;
            this.commentErrorMsg = error;
          }
        );
    }

    removeAttachment(id) {
    let body = {
        "comment": this.commentValue,
        "pageName": this.filteredTimelineList.projectName,
        "category": this.commentCat?.trim(),
        "projectId": this.projectId,
        "file": ""
    };

    this.projectService.updateResponseComment(body, id, this.projectId)
    .subscribe(
        () => {
        this.getProjectData();
        },
        error => {
        this.isCommentError = true;
        this.commentErrorMsg = error;
        });
    }

    updateAttachmentSuccess(event) {
        this.getProjectData();
        // $("#replaceAttachment").modal('hide');
        $("#replaceAttachment").hide();
        $('.modal-backdrop').remove();
        $('body').addClass('modal-open');
        $("#replaceAttachment").removeClass('show');
    }

    commentCount() {
        this.commentCnt = this.commentValue.length;
    }

    getResponse() {
        this.formsService
          .getResponseJson(this.responseId)
          .pipe(take(1))
          .subscribe(
            responses => {
              this.isError = false;
              this.responseStatus = responses.responseStatusTitle;
            },
            error => {
              this.isError = true;
              this.errorMsg = error;
            }
          );
      }
}


