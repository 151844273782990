
import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, EventEmitter, forwardRef, Input, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-type-ahead',
  templateUrl: './type-ahead.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeAheadComponent),
      multi: true
    }
  ]
})
export class TypeAheadComponent implements ControlValueAccessor {
  @Input() options: any;
  @Input() placeholder: any;
  titleArray: any = [];
  @Input() optionField: string = "title"
  @Input() label;
  @Output() selectDone: any = new EventEmitter();
  clearClick: boolean = false;
  @ViewChild('search') searchElement: ElementRef;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes)
    if (changes.options && changes.options.currentValue && changes.options.currentValue.length > 0) {
      this.calCulateOptions();
    } else {
      this.titleArray = [];
    }
  }

  onChange: any = () => { }
  onTouch: any = () => { }
  val = ""
  click() {

  }
  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val
      this.onChange(val)
      this.onTouch(val)
    }
    else if (this.clearClick) {
      this.clearClick = true;
      this.val = val
      this.onChange(val)
      this.onTouch(val)
    }

  }
  onSelect(event) {
    console.log(event);
    if (this.selectDone)
      this.selectDone.emit();
  }
  clearVal() {
    this.clearClick = true;
    this.val = '';
    this.value = '';

    this.writeValue('');

    this.searchElement.nativeElement.focus();//.click();
  }
  calCulateOptions() {
    this.titleArray = [];
    for (let k in this.options) {
      this.titleArray.push(this.options[k][this.optionField])

    }

  }
  writeValue(value: any) {
    this.value = value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

}
