
<div class="form-textfield textfield" *ngIf="showGroup()">


  <div class="form-panel-content">


    <div ref="element">

      <p *ngIf="!isEditable()">
        {{mask}}  <a *ngIf="!isEditable() && !isReadOnly" (click)="toggleEdit()" id="edit" class="hidden" title="Edit Information"><i class="material-icons" tabindex="0">edit</i></a>
      </p>
      <input [disabled]="isReadOnly"id="{{id}}" value="{{value2}}" spellcheck="true" lang="en" class="form-control " type="text" ([ngModel])="value2" [hidden]="!isEditable()" (ngModelChange)="userNamengmodelchange($event)" (blur)='blur($event.target.value)' (change)="changeFn($event.target.value)" ref="input"/>
    </div>
    <!--<div *ngIf="!isEditable() && !isReadOnly" [ngClass]="!isEditable() && !isReadOnly?'col-lg-4 col-md-4':'col-lg-6 col-md-6'">
    <label for="email" aria-label="email">Action</label>
    <p></p>
    <a *ngIf="!isEditable() && !isReadOnly" (click)="toggleEdit()" id="edit" class="hidden" title="Edit Information"><i class="material-icons" tabindex="0">edit</i></a>
  </div>-->


  </div>

</div>
<div *ngIf="errorMessage!=''">{{errorMessage}}</div>
<div class="formio-errors invalid-feedback" ref="messageContainer">

</div>
