import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';
declare var $: any;

@Component({
  selector: 'app-loading',
  templateUrl: './loader.component.html'
})
export class LoaderComponent  {
  
  constructor() {
    
  }

 

}
