import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICreateTopic, ICreateTopicForm, ITopics } from '../../../admin/manageForms/models/createTopic';
import { HelpService } from '../../../services/help.service';
import { IUser } from 'src/app/shared/user';
import { UserService } from '../../../services/user.service';
import { PERMISSIONS } from '../../../core/constants';
import { ProjectsService } from '../../../services/projects.service';
import { IQuery } from '../../../shared/query';
import { take } from 'rxjs/operators';
import { IhelpCategories, IHelpCategory } from '../../../shared/helpCategory';
import { IPager } from '../../../shared/pagination';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HelpCategoryService } from 'src/app/self-service/components/manageHelpCategory/helpCategory.service';
import { ManageHelpService } from '../../../admin/help/services/manageHelp.service';
import { ManageHelpArticleService } from 'src/app/self-service/components/manageHelpArticle/components/services/manageHelp.service';
import { forEachChild } from 'typescript';
import { REGEX_PATTERNS } from "src/app/core/constants";
declare var $: any;
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  filterForm: FormGroup;
  category: any;
  searchString: any;
  popularTopic: ICreateTopic;
  userData: IUser;
  queryParam: IQuery;
  pagerObject: IPager;
  currentPageNumber = 1;
    isError: boolean;
  errorMsg: any;
  helpCategoryList: ITopics;
  successMsg: any = '';
  isAdmin: boolean = false;
  isEdit: boolean = false;
  isDomainAdmin: boolean = false;
  name: string = '';
    deleteId: any;
  constructor(private helpService: HelpService, private projectService: ProjectsService, private router: Router, private userService: UserService,
    private helpCategoryService: HelpCategoryService, private _manageHelp: ManageHelpService, private manageHelpArticleService: ManageHelpArticleService) { }
  initQueryParams(name:string='') {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
    if (name != '')
      this.queryParam.title = name
  }
  onChanges(): void {
    this.filterForm.get('name').valueChanges.subscribe(val => {
      if (!this.filterForm.get('name').valid && this.filterForm.get('name').errors?.pattern){
        return;
      }
      if (val.length > 3) {
        this.initQueryParams(val);
        this.getHelpCategories();
      } else if (val.length ==0) {
        this.initQueryParams();
        this.getHelpCategories();
      }
    });
  }
  onClick_delete() {

    this.helpService.deleteHelpTopic(this.deleteId).subscribe(
      () => {

        this.isError = false;
        this.getHelpCategories();
        this.successMsg = `The Resource '${this.name}' has been deleted.`;
        this.name = '';
        this.deleteId = '';
      },
      error => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }
  deleteTopic(id: any, name: any): void {
    this.name = name;
    this.deleteId = id;
    $('#deleteModal').modal('show');
  }
  getHelpCategories() {
    this.helpService.getTopic(this.queryParam)
      .pipe(take(1))
      .subscribe(
        projects => {
          this.isError = false;
          this.helpCategoryList = projects.body as ITopics;

          for (var i = 0; i < this.helpCategoryList.helpTopics.length; i++) {
            if (this.isAdmin)
              this.helpCategoryList.helpTopics[i].editAccessWith = true;
            else if (this.isDomainAdmin && !this.helpCategoryList.helpTopics[i].isCommonCategory && this.helpCategoryList.helpTopics[i].cretedById == this.userData.id) {
              this.helpCategoryList.helpTopics[i].editAccessWith = true;
            }
            else if (this.helpCategoryList.helpTopics[i].cretedById == this.userData.id) {
              this.helpCategoryList.helpTopics[i].editAccessWith = true;
            }
            else
              this.helpCategoryList.helpTopics[i].editAccessWith = false;
            //Bug-220581: API code is fixed to send only users having DisplayToUser = true, so no need to set this logic in UI.
            //this.helpCategoryList.helpTopics[i].DisplayToUser = true;
          }
          //Bug-220581: API code is fixed to send only users having DisplayToUser = true, so no need to set this logic in UI.
          //this.helpCategoryList.helpTopics = this.helpCategoryList.helpTopics.filter(ht => ht.DisplayToUser = true);

          if (projects.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(projects.headers.get('X-pagination')) as IPager);
          }
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }
  ngOnInit(): void {
    this.searchString = '';
    this.userData = this.userService.getUserListData();
    this.isEdit = this.userService.getPermissions()["showhelpArticlelink"];
    this.isAdmin = this.userData.isAdmin;
    for (var i = 0; i < this.userData.roles.length; i++) {
      if (this.userData.roles[i].roleId == 2)
        this.isDomainAdmin = true;
    }
    this.isEdit = this.userService.getPermissions()["showhelpArticlelink"];
    this.initQueryParams();
    this.getHelpCategories();
    this.filterForm = new FormGroup({
      name: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)])
    });
    this.onChanges();
    this.helpService.getPopularArticle().subscribe(suggestions => {

      this.popularTopic = suggestions;
    })

  }
  changePage(page) {
    this.queryParam.pageNumber = page;
    this.getHelpCategories();
  }

  canCreateHelp(): boolean {

    if (this.userData && this.userData.permissions.includes(PERMISSIONS.HelpTopics_ReadWrite_All) && this.isEdit) {
      return true;
    }

    return this.userData && this.userData.isAdmin;
  }
  navigateTo(value: any) {
    switch (value) {
      case "create":
        let a: ICreateTopicForm;
        this._manageHelp.formVal = a;
        this.manageHelpArticleService.returnPage = '/help/category';
        this.router.navigate(['/self-service/helpTopic/create']);
        break;
      default:

        break;



    }

  }

}
