<table class="table ">
  <thead>
    <tr>
      <th>FormName</th>
      <th>FormId</th>
      <th>View</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let form of forms" >
      <td>{{form.formName}}</td>
      <td >{{form.formId}}</td>
      <td><button type="button" class="btn btn-primary" (click)="goToForm(form.formId)">View</button></td>
	  <td><button type="button" class="btn btn-primary" (click)="goToEdit(form.formId)">Edit</button></td>
    </tr>
  </tbody>
</table>
