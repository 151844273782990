import { Injectable } from '@angular/core';
import { IQuery } from 'src/app/shared/query';
import { HttpParams, HttpClient } from '@angular/common/http';
import { IHelpCategory } from 'src/app/shared/helpCategory';
import { Observable } from 'rxjs';
import { IOrganization } from '../../../shared/organization';

@Injectable({
  providedIn: 'root'
})
export class HelpCategoryService {

  private helpCategoryAPIURL = 'helpCategories';
  private orgAPIURL = 'organizations';
  private exportHelpCategoryAPIURL = 'helpCategories/ExporthelpCategories';
  private helpCategoryCreated: string;
  private isHelpCategoryCreated: boolean;
  private helpCategoryUpdated: boolean;
  private _tempData: string = "";

  constructor(private http: HttpClient) {}

  get tempData () {
    return this._tempData;
  }

  set tempData (msg: any) {
    this._tempData = msg;
  }

  getHelpCategories(queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }

    let options = { 'refresh': 'true' }
    return this.http.get(this.helpCategoryAPIURL, { headers: options, params, observe: 'response' });
  }

  exportHelpCategories(queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.exportHelpCategoryAPIURL, { params, observe: 'response',responseType: 'blob'  });
  }

  getHelpCategoryDetailsById(helpCategoryId: number) {
    return this.http.get<IHelpCategory>(`${this.helpCategoryAPIURL}/${helpCategoryId}`);
  }

  getOrganizationDetailsById(orgId: number) {
    return this.http.get<IOrganization>(`${this.orgAPIURL}/${orgId}`);
  }

  createHelpCategory(data) {
    return this.http.post<IHelpCategory>(this.helpCategoryAPIURL, data);
  }

  updateHelpCategory(helpCategoryId, data) {
    return this.http.put<IHelpCategory>(`${this.helpCategoryAPIURL}/${helpCategoryId}`, data);
  }

  deleteHelpCategory(helpCategoryId: number): Observable<IHelpCategory> {
    return this.http.delete<IHelpCategory>(`${this.helpCategoryAPIURL}\\${helpCategoryId}`);
  }

  set _newCreatedHelpCategory(value){
    this.helpCategoryCreated= value;
  }

  get _newCreatedHelpCategory(){
    return this.helpCategoryCreated;
  }

  set _isHelpCategoryCreated(value){
    this.isHelpCategoryCreated= value;
  }

  get _isHelpCategoryCreated(){
    return this.isHelpCategoryCreated;
  }

  set _helpCategoryUpdated(value){
    this.helpCategoryUpdated= value;
  }

  get _helpCategoryUpdated(){
    return this.helpCategoryUpdated;
  }

}
