import { ApplicationInsights, SeverityLevel, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class LoggingService {
  appInsights: ApplicationInsights;
  constructor(private logger: NGXLogger) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(ex: Error, severityLevel?: number) {
    let exception : IExceptionTelemetry = {
      exception : ex
    };
    // Log Exception in the Insight if the Region is Prod only.
    if(environment.production)
    this.appInsights.trackException(exception );
    else
    this.logger.log(ex);
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, properties);
  }
}
