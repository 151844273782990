import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './powerBI-internal-dashboard-component.html'
})
export class PowerBIInternalComponent implements OnInit {
  organizationSuggestions: ISuggestions;
  isError: boolean;
  errorMsg: string;
  _messageOrg: string;
  isOrganizationSet: boolean = false;
  invalidOrganization: boolean = false;
  organizationDetails = {
    organizationTitle: "",
    organizationId: -1
  };

  reportUrl: string = environment.drmsPowerBIURL;
  urlSafe: SafeResourceUrl;
  constructor(private _projectService: ProjectsService,
    private _permissionService: PermissionsService,
    private _userService: UserService,
    public sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.reportUrl);

  }

  openSite() {
    window.open(this.reportUrl, '_blank');
  }

}
