<div class="heading-container fdl-2">
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span> 
        Template(s) List 
      </a>
    </div>
    <h1>{{formExtraction?.templateName || 'N/A'}}</h1>
    </div>
</div>
<div class="form-container separate-form-container">
    <form>
        <div class="biller-survey white-box fdl-2">
            <div>
                <ul>
                    <li>
                        Form Name:
                        <span class="info">{{formExtraction.formTitle || 'N/A'}}</span>
                    </li>
                    <li>
                        Template Url:
                        <span class="info">{{formExtraction.templateUrl || 'N/A'}}</span>
                    </li>
                    <li>
                        Status:
                        <span class="info">{{formExtraction.isActive || 'N/A'}}</span>
                    </li>
                    <li>
                        Extraction Type:
                        <span class="info">{{formExtraction.extractionType || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="biller-survey white-box fdl-2">
            <div>
                <ul>
                    <li>
                        Created On:
                        <span class="info">{{formExtraction.createdON | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                    </li>
                    <li>
                        Created By:
                        <span class="info">{{formExtraction.createdByName || 'N/A'}}</span>
                    </li>
                    <li>
                        Modified On:
                        <span class="info">{{formExtraction.modifiedON | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                    </li>
                    <li>
                        Modified By:
                        <span class="info">{{formExtraction.modifiedByName || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </form>
    <div class="fdl-2 button-container">
        <div>
            <button type="button" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
        </div>
    </div>
</div>