import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { IClients } from 'src/app/shared/client';
import { environment } from 'src/environments/environment';
import { ProjectsService } from 'src/app/services/projects.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import { take } from 'rxjs/internal/operators/take';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ReportsDTOService } from 'src/app/shared/reportsDTO.service';
import { MasterService } from 'src/app/services/master.service';
import { ReportsService } from 'src/app/services/report.services';
import { IReport, IReports } from 'src/app/shared/report';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { UserService } from 'src/app/services/user.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
declare var $: any;

@Component({
  templateUrl: './report-list.component.html'
})
export class ReportListComponent implements OnInit, AfterViewInit, OnDestroy {
  successMsg: string;


  constructor(
    private router: Router,
    private reportService: ReportsService,
    private projectService: ProjectsService,
    private permission: PermissionsService,
    private reportDto:ReportsDTOService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private userFilterService: UserFilterService,
    private userService: UserService
  ) { }

  filterExists: boolean = false;
  filterForm: FormGroup;
  reportList: IReports;
  queryParam: IQuery;
  currentPageNumber: number = 1;
  pagerObject:IPager;
  sortingObject;
  isError:boolean;
  errorMsg: string = "";
  displayColumns = ['Report Name', 'Description', 'EntityLevel', 'EntityName', 'Display on Dashboard' , 'Last Updated User', 'Last Updated Date'];
  keys = ['reportName', 'reportDescription', 'entityLevel', 'entityName', 'visibility' , 'modifiedByName', 'modifiedOn'];
  reportSuggestion: ISuggestions;
  deletedFormMsg: string = "";
  showComment: boolean = true;
  isCreateReportAllowed: boolean = false;
  organizationSuggestion: ISuggestions;
  showOrganizationFilter: boolean = false;
  isAdmin: boolean = false;
  selectedOrgId: number = 0;
  organizationDetails: any;
  listname: string = PreferenceService.reportList;
  filtered: boolean = false;
  regexValidationMessage: any;

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.isCreateReportAllowed = this.permission.isCreateReportAllowed();
    this.showOrganizationFilter = this.permission.userData.isAdmin;
    this.initFilterForm();
    this.initQueryParams();
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    this.isAdmin = this.userService.getUserListData().isAdmin;
    // this.masterService.loadAndApplyFilters(this.queryParam, this.filterForm);
    this.filterList();
    this.getSuggestions();
    this.getOrganizationSuggestions();
    this.successMsg = this.reportService.isReportCreated;
    if(!this.isAdmin) {
      this.organizationDetails = Object.assign({}, this.userService.getUserListData());
      this.selectedOrgId = this.organizationDetails.organization;
      this.getReportSuggestionsbyOrgId();
    }
    this.filtered = this.filterExists
  }

  closeSuccessMsg() {
    this.reportService.isReportCreated = "";
  }

  ngAfterViewInit(): void {
    this.deletedFormMsg = '';
  }

  initQueryParams() {
    this.queryParam = Object.assign({},{
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      reportName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)]),
      organizationName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)])
    });
  }

  validateFormInput(event, name) {
    this.fixInvalidAlignment(`.force-hide-asterist[formControlName='${name}'] .custom-search-input div:not(.input-group)`, this.filterForm.get(name).invalid);
  }

  fixInvalidAlignment(selector: string | HTMLElement, isInvalid: boolean) {
    const element = (typeof selector === "string" ? document.querySelector(selector) : selector);
    if (element) {
      (element as HTMLElement).style.display = (isInvalid ? 'flex' : 'block');
    }
  }

  getSuggestions() {
    this.reportService.getSuggestions('reports','')
    .subscribe(suggestions => {
      this.reportSuggestion = suggestions;
    },
    (error)=> { this.isError = true; this.errorMsg = error; });
  }

  getReportSuggestionsbyOrgId() {
    this.reportService.getSuggestions('reports', this.selectedOrgId, "orgId")
    .subscribe(suggestions => {
      this.reportSuggestion = suggestions;
    },
    (error)=> { this.isError = true; this.errorMsg = error; });
  }

  refresh() {
    this.getReportList();
  }

  getReportList() {
    $('#refreshTask').addClass('fa-rotation');
    this.reportService.getReports(this.queryParam)
      .pipe(take(1))
      .subscribe(
        reports => {
          this.isError = false;
          this.reportList = reports.body as IReports;
          if(reports.headers.get('X-pagination')){
            this.pagerObject = Object.assign({},JSON.parse(reports.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }

  filterList(){
    // this.masterService.saveFilters(this.filterForm);
    this.userFilterService.saveFilter(this.route, this.filterForm);
    this.initQueryParams();
    let filterValues = this.filterForm.value;

    if (filterValues.reportName)
      this.queryParam.reportTitle = filterValues['reportName']
    if (filterValues.organizationName)
      this.queryParam.organizationTitle = filterValues['organizationName']

    this.setOrderBy(this.sortingObject);
    this.getReportList();

    if (filterValues['organizationName'] === "") {
      this.selectedOrgId = 0;
      this.getSuggestions();
      this.getOrganizationSuggestions();
    }
    this.filtered = true
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.userFilterService.clearFilter()
    this.getReportList();
  }

  changePage(page){
    this.queryParam.pageNumber = page;
    this.getReportList();
  }

  setOrderBy(sortingData){
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  applySorting(event){
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.getReportList();
  }

  createReport(){
    this.reportService.tempData = "Reports";
    this.router.navigate(['self-service/report/create']);
  }

  // downLoadType(event: any) {
  //   if (event.type == 'xlsx') {
  //     this.exportExcel();
  //   }
  // }

  // exportExcel() {
  //   this.reportService.exportClients(this.queryParam).subscribe(
  //     res => {
  //       console.log(res.body);
  //       let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
  //       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //         window.navigator.msSaveOrOpenBlob(newBlob);
  //       } else {
  //         const data = window.URL.createObjectURL(newBlob);
  //         const filename = 'clients.xlsx';
  //         const link = document.createElement('a');
  //         link.href = data;
  //         link.download = filename;
  //         link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  //         setTimeout(function () {
  //           window.URL.revokeObjectURL(data);
  //         }, 100);
  //       }
  //     },
  //     error => { this.isError = true; this.errorMsg = error; }
  //   );
  // }

  // downloadFile(response) {
  //   let url = window.URL.createObjectURL(response);
  //   let pwa = window.open(url);
  //   if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
  //     alert('Please disable your Pop-up blocker and try again.');
  //   }
  // }

  navigate(event){
    if (event.action === "view") {
      // this.masterService.saveFilters(this.filterForm);
      this.router.navigate(['/self-service/report/view', event.data]);
    }
    else if (event.action === "delete") {
      // this.masterService.saveFilters(this.filterForm);
      this.deletedFormMsg = '';
      let deleteReport = this.reportList.reports.filter(item => item.id === event.data)[0];
      this.reportService.deleteReport(event.data).subscribe(
        () => {
          this.isError = false;
          this.getReportList();
          this.getSuggestions();
          this.successMsg = '';
          this.deletedFormMsg = `The report '${deleteReport.reportName}' has been deleted.`
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      )
    }
  }

  ngOnDestroy(): void {
    // this.masterService.saveFilters(this.filterForm);
    this.deletedFormMsg = "";
    this.successMsg = "";
  }

  getOrganizationSuggestions() {
    if(this.showOrganizationFilter){
    this.projectService.getSuggestions("organizations", "")
      .subscribe(suggestions =>{
        this.organizationSuggestion = suggestions;
      },
      error => { this.isError = true; this.errorMsg = error; });
    }
  }

  onOrgSelect(event: any) {
    if(event.item.id > 0)
    {
      this.selectedOrgId = event.item.id;
      this.filterForm.get('reportName').setValue("");
      this.getReportSuggestionsbyOrgId();
    }
  }
}
