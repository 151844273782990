<div class="heading-container fdl-2">
    <h1>Roles</h1>
    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="createRole()">
      <span class="material-icons">add</span>
      Create New Role</button>
</div>
<div class="form-container">
<form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
  <h2 class="h4 collapse-arrow" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse" href="#collapseFilter">Search</h2>
  <div class="filter-form-container-column2 search-filter collapse" [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">
      <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('Title').valid && (filterForm.get('Title').touched || filterForm.get('Title').dirty  || isSubmitted)}">
          <fdl-type-ahead label="Role Name:" formControlName="Title" [collection]="roleSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
          <span *ngIf="!filterForm.get('Title').valid && filterForm.get('Title').errors?.pattern && (filterForm.get('Title').touched || filterForm.get('Title').dirty  || isSubmitted)"><i class="material-icons">warning</i>{{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}</span>
      </div>
      <div class="buttom-wrapper">
          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
          (click)="filterList()">Search</button>
      </div>
      <div class="ml-2 buttom-wrapper" *ngIf="filtered">
        <button type="button" class="btn fdl-btn btn-primary-dark" aria-label="Reset"
                (click)="clearFilterList()">
          Reset
        </button>
      </div>
  </div>
</form>
<div class="data-table-container">
    <div class="justify-content-between d-flex">
      <div class="headline-container">
        <h2 class="h4">Role(s) List</h2>
        <p>Total {{pagerObject.totalCount}} Roles</p>
      </div>
      <div class="d-flex">
        <div class="refresh-icon-link mt-1">
          <!-- add class of 'fa-rotation' on click of the refresh icon -->
          <a title="Refresh Role List" id="refreshTask" (click)="refresh()">
            <i aria-hidden="true" class="fa fa-refresh"></i>
          </a>
        </div>

        <div class="dropdown downloadDropdownMenuButton" *ngIf="showComment">
          <button *ngIf="showComment"
                  type="button" title="Download Extract" class="btn btn fdl-btn btn-primary-dark btn-addcomment"
                  id="downloadDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <span class="material-icons material-icons-wrapper">
              get_app
            </span>
          </button>

          <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
            <a class="dropdown-item" (click)="downLoadType({'type':'xlsx'})">Export List</a>
          </div>
        </div>
        <app-prefernce-icon></app-prefernce-icon>
      </div>
      </div>
      <div class="table-responsive">
        <app-data-table *ngIf="roleList"
                        [rows]="roleList.roles"
                        [keys]="keys"
                        [displayColumns]="displayColumns"
                        (buttonClicked)="navigate($event)"
                        (deleteBtnClicked)="navigate($event)"
                        [showCheckbox]="false"
                        (sortingClicked)="applySorting($event)"
                        [showDelete]="true"
                        [showEdit]="true"
                        [showEdit]="showCreateButton"
                        [showDelete]="showCreateButton"
                        type="Role"
                        [listname]="listname">
        </app-data-table>

        <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && roleList && roleList.roles.length > 0"
          [pagerObject]="pagerObject"
          (pagingChanged)="changePage($event)"></app-pagination>
      </div>
    </div>
  </div>

<div class="modal fade bd-example-modal-sm" id="errorMessage" tabindex="-1" role="dialog"
     aria-labelledby="mySmallModalLabel" aria-hidden="true" #content>
  <div class="modal-dialog modal-sm mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="mySmallModalLabel">Delete Role</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal()"
                aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p style="color: red;">Cannot delete role: The selected role has already users assigned</p>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-primary-orange ml-2 mr-2" data-dimiss="modal" (click)="closeModal()">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>

