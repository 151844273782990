import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAX_LENGTHS, REGEX_PATTERNS } from '../../../core/constants';
import { ProjectsService } from 'src/app/services/projects.service';
import { Subscription } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-put-project-status',
  templateUrl: './put-project-status.component.html'
})
export class PutProjectStatusComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Output() updateStatusSuccess = new EventEmitter
  commentForm: FormGroup;
  isSubmitted: boolean;
  _maxLengths: any;
    isError: boolean;
  errorMsg: any;
  action: any;
  isHoldError: any;
  subs: Array<Subscription> = [];

  constructor(private projectService: ProjectsService) { }

  ngOnInit(): void {
    this.initCommentForm();
  }

  initCommentForm(action: any = '') {
    this.action = action;
    this._maxLengths = MAX_LENGTHS;
    let validatorArray = [Validators.maxLength(this._maxLengths.Approval.Comments), Validators.pattern(REGEX_PATTERNS.description_comment)]

    validatorArray.push(Validators.required);
    this.commentForm = new FormGroup({
      comments: new FormControl('', validatorArray)
    });
    this.isSubmitted = false;
  }

  submitcommentForm() {
    this.isSubmitted = true;
    if (this.commentForm.valid) {
      const body = {
        status: this.action,
        comments: this.commentForm.get('comments').value
      };

      let subscriber;
      let successMessage;
      if (this.action === 'onHold') {
        subscriber = this.projectService.hold(this.data.id, body);
        successMessage = `The project '${this.data.title}' has been put on hold.`;
      } else if (this.action === 'reject') {
        subscriber = this.projectService.reject(this.data.id, body);
        successMessage = `The project '${this.data.title}' has been rejected.`;
      }
      subscriber.subscribe(
        () => {
          this.isError = false;
          this.isSubmitted = false;
          this.updateStatusSuccess.emit({ message: successMessage });
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
      this.subs.push(subscriber);
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub =>{
      if(sub.unsubscribe) {
        sub.unsubscribe()
      }
    });
  }
}
