<div>


  <table class="sjs-table">
    <tbody>
      <tr *ngFor="let row of sheets;let indexOfelement=index;">
        <td>
          <a (click)="onDetail(indexOfelement)">{{row}}</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
