import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectListComponent } from './Components/project-list/project-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectMainComponent } from './Components/project-main/project-main.component';
import { ProjectInfoComponent } from './Components/project-info/project-info.component';
import { ProjectsRoutingModule} from './projects-routing.module';
import { SharedModule } from '../SharedModule/shared.module';
import { FormioModule } from 'angular-formio';
import { ViewProjectComponent } from './Components/view-project/view-project.component';
import { CreateProjectMainComponent } from './Components/create-project-main/create-project-main.component';
import { ClientInfoComponent } from './Components/client-info/client-info.component';
import { ProjectsDTOService } from './Services/projectsDTO.service';
import { FdlTypeaheadModule} from 'fdl-ngx11-bootstrap4';
import { FdlDataGridModule} from 'fdl-ngx11-bootstrap4';
import { PublishFormsComponent } from './Components/publish-forms/publish-forms.component';
import { NormalSurveyComponent } from './Components/normal-survey/normal-survey.component';
import { AnonymousSurveyComponent } from './Components/anonymous-survey/anonymous-survey.component';
import { EmailgroupMembersComponent } from './Components/emailgroup-members/emailgroup-members.component';
import { SelectFormsComponent } from './Components/select-forms/select-forms.component';
import { UtilService } from '../services/util.service';
import { DateFormatPipe } from '../SharedModule/Pipes/date-format.pipe';
import { LongDateTimeFormatPipe } from '../SharedModule/Pipes/long-date-time-format.pipe';
import { AssociateIntakeRequestComponent } from './Components/associate-intake-request/associate-intake-request.component';
import { ProjectTimelineComponent } from './Components/project-timeline/project-timeline.component';
import { PreferenceService } from '../services/preference.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FilterPipe } from '../SharedModule/Pipes/filter.pipe';

@NgModule({
  declarations: [
    ProjectListComponent, 
    ProjectMainComponent, 
    ProjectInfoComponent, 
    ViewProjectComponent,
    CreateProjectMainComponent,
    ClientInfoComponent,
    PublishFormsComponent,
    NormalSurveyComponent,
    AnonymousSurveyComponent,
    SelectFormsComponent,
    EmailgroupMembersComponent,
    AssociateIntakeRequestComponent,
    ProjectTimelineComponent
  ],
  imports: [
    ProjectsRoutingModule,
    SharedModule,
    FormsModule,
    CommonModule,
    FormioModule,
    ReactiveFormsModule,
    FdlTypeaheadModule,
    FdlDataGridModule,
    NgSelectModule
  ],
  providers: [DateFormatPipe, LongDateTimeFormatPipe, ProjectsDTOService, UtilService, PreferenceService, FilterPipe],
})
export class ProjectsModule { }
