import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeSpace',
})
export class EncodeSpacePipe implements PipeTransform {
  constructor() {}
  transform(value) {
    if (isNaN(value) && /\s/g.test(value)) {
      return value.replaceAll(' ', '&nbsp;');
    }
    return value;
  }
}
