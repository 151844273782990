<div class="heading-container fdl-2">
    <!-- <div class="back-action-container">
        <div class="back-button">
            <a href="javascript:void(0);" (click)="goBack()">
                <span class="material-icons"> arrow_back </span>
                Extraction Requests
            </a>
        </div>
        <h1>Form Extraction Template</h1>
    </div> -->
    <h1>Form Extraction Template</h1>
    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="uploadTemplate()">
        <span class="material-icons">add</span>
        Upload Template
    </button>
</div>

<div class="form-container">
    <!-- Error Msg Alert -->
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>
    <!-- Success Msg Alert -->
    <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="setSuccessMsg()">×</button>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p [innerHtml]="successMsg"></p>
    </div>
    <form class="form form-border mb-3 collapse-view" autocomplete="off" [formGroup]="filterForm">
        <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
        <div class="filter-form-container-column2 search-filter collapse" id="collapseFilter">
            <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="isAdmin">
              <div class="has-error">
                <span *ngIf="(!filterForm.controls.organizationName.valid && filterForm.controls.organizationName.errors?.pattern && filterForm.controls.organizationName.dirty) ">
                  <i class="material-icons">warning</i>Please enter a valid Organization Name</span>
              </div>
              <fdl-type-ahead label="Organization Name" formControlName="organizationName" [collection]="organizationSuggestion" optionField="title"
                placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onOrgSelect($event)">
              </fdl-type-ahead>
            </div>
            <div class="filter-form-control pr-4 typeahead-wrapper">
              <div class="has-error">
                <span *ngIf="(!filterForm.controls.productName.valid && filterForm.controls.productName.errors?.pattern && filterForm.controls.productName.dirty) ">
                  <i class="material-icons">warning</i>Please enter a valid Product Name</span>
              </div>
              <fdl-type-ahead label="Product Name" formControlName="productName" [collection]="productSuggestion" optionField="title"
                placeholder="Type to search" [scrollable]="true" [optionsLimit]="100" [minLength]="0" (input)="typeaheadLoadingProducts()">
              </fdl-type-ahead>
            </div>
            <div class="filter-form-control pr-4 typeahead-wrapper">
              <div class="has-error">
                <span *ngIf="(!filterForm.controls.formName.valid && filterForm.controls.formName.errors?.pattern && filterForm.controls.formName.dirty) ">
                  <i class="material-icons">warning</i>Please enter a valid Form Name</span>
              </div>
              <fdl-type-ahead label="Form Name" formControlName="formName" [collection]="formNameSuggestion" optionField="title"
                placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>
            <div class="buttom-wrapper">
                <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterList()">Search</button>
            </div>
            <div class="ml-2" *ngIf="filtered" class="buttom-wrapper">
                <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset" (click)="clearFilterList()">Reset</button>
            </div>
        </div>
    </form>

    <div class="data-table-container">
      <div class="justify-content-between d-flex">
        <div class="headline-container">
          <h2 class="h4">Form Extraction Template List</h2>
          <p>Total {{pagerObject.totalCount}} Items</p>
        </div>
        <div class="d-flex">
          <div class="refresh-icon-link mt-1 mr-2">
            <!-- add class of 'fa-rotation' on click of the refresh icon -->
            <a title="Refresh Form Extraction Template List" id="refreshTask">
              <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
            </a>
          </div>
          <app-prefernce-icon></app-prefernce-icon>
          </div>
        </div>
        <div class="table-responsive">
          <app-data-table *ngIf="formExtractionList.formExtractions"
                          [rows]="formExtractionList.formExtractions"
                          [keys]="keys"
                          [displayColumns]="displayColumns"
                          (sortingClicked)="applySorting($event)"
                          [showCheckbox]="false"
                          [showDelete]="true"
                          [showUpdate]="false"
                          [showDownload]="true"
                          (deleteBtnClicked)="navigate($event)"
                          type="Form Extraction Template"
                          (buttonClicked)="navigate($event)"
                          [listname]="listname"></app-data-table>
          <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && formExtractionList.formExtractions && formExtractionList.formExtractions.length > 0"
                          [pagerObject]="pagerObject"
                          (pagingChanged)="changePage($event)"></app-pagination>
        </div>
      </div>
</div>
