<div class="commitments-intake-page">
    <div class="heading-container fdl-2">
        <div class="back-action-container">
            <h1>Commitments Intake</h1>
        </div>
        <div>
            <button type="button" class="btn fdl-btn btn-primary-orange mt-1"
                (click)="openSubmitNewCommitmentsIntake()"><span class="material-icons">add</span>
                Submit New Commitments Intake</button>
        </div>
    </div>
    <div class="form-container">
        <app-commitments-intake-search [searchFields]="searchByFormFieldJson"
            (search)="onSearchClicked($event)"></app-commitments-intake-search>
        <div class="commitments-tabs-wrapper">
            <ul class="nav nav-tabs scoped-tabs" role="tablist">
                <li class="nav-item"><a class="nav-link active show" role="tab" aria-selected="true" href="#tab1"
                        data-toggle="tab" (click)="onTabClicked('All')">All</a></li>
                <li class="nav-item"><a class="nav-link" role="tab" aria-selected="false" href="#tab1" data-toggle="tab"
                        (click)="onTabClicked('Tracked')">Tracked</a></li>
                <li class="nav-item" *ngFor="let item of tabList">
                    <a class="nav-link" role="tab" href="#tab1" aria-selected="false" data-toggle="tab"
                        (click)="onTabClicked(item)">{{item}}</a>
                </li>
                <!-- <li class="nav-item"><a class="nav-link" role="tab" aria-selected="false" href="#tab1" data-toggle="tab"
                    (click)="onTabClicked('Internal')">Internal</a></li> -->
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab-1">
                    <div class="data-table-container">
                        <app-commitments-intake-list [data]="pageResponseList"
                            [pageTemplateNameTitle]="pageTemplateNameTitle" [queryParam]="queryParam"
                            [pagerObject]="pagerObject" [customFormFieldListJson]="customFormFieldListJson"
                            [pageTemplate]="pageTemplate" [listFormFieldJson]="listFormFieldJson"
                            (search)="searchFromList($event)"></app-commitments-intake-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-new-commitments-intake-drawer *ngIf="showSubmitNewCommitmentsIntakeDrawer" [isEntityLink]="isEntityLink"
    [isDynamicApprover]="isDynamicApprover" [pageTemplateNameTitle]="pageTemplateNameTitle"
    [organizationDetails]="organizationDetails"
    (cancel)="closeSubmitNewCommitmentsDrawers()"></app-new-commitments-intake-drawer>