<nav role="navigation" class="navbar navbar-expand navbar-light navbar-internal">
  <a class="navbar-brand cursor-default">
    <!--<img alt="i360" src="assets/images/i360_Logo_White.png">-->
    <img alt="fiserv" src="assets/images/Fiserv Logo.png">
  </a>
</nav>

<section class="document-download-page mt-3">
    <div class="form-container">
      <div class="alert alert-dismissible alert-secondary" *ngIf="!isError && !isSucceed">
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            (click)="closeAlert()"
          >
            ×
          </button>
          <p>Downloading...</p>
      </div>
      <div class="alert alert-dismissible alert-success" *ngIf="isSucceed">
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="close"
          (click)="closeAlert()"
        >
          ×
        </button>
        <p [innerHtml]="successMsg"></p>
    </div>
      <!-- Error Msg Alert -->
      <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="close"
            (click)="closeAlert()"
          >
            ×
          </button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
      </div>
    </div>
</section>
