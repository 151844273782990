import { Injectable } from '@angular/core';
import { IUser } from './user';
import { IClient, IClientAddresses } from './client';

@Injectable({
  providedIn: 'root'
})

export class ClientsDTOService {

  clientData: IClient
  clientAddress: IClientAddresses
  
  private clientUpdated: boolean = false;
  private isEdit: boolean = false;
  private clientCreated: boolean = false;
  private newlyCreatedClient: string = '';

  constructor() { }

  setClientData(data: IClient){
      this.clientData = data;
  }

  getClientData(): IClient{
      return this.clientData;
  }

  set _isClientUpdated(value){
    this.clientUpdated= value;
  }

  get _isClientUpdated(){
    return this.clientUpdated;
  }

  set _isEditMode(value){
    this.isEdit = value
  }

  get _isEditMode(){
    return this.isEdit;
  }

  set _isClientCreated(value){
    this.clientCreated = value
  }

  set _newCreatedClient(value){
    this.newlyCreatedClient =  value;
  }

  get _isClientCreated(){
    return this.clientCreated;
  }

  get _newCreatedClient(){
    return this.newlyCreatedClient;
  }

  setClientAddress(data){
    this.clientAddress = data;
  }

  getClientAddress(){
    return this.clientAddress;
  }
}