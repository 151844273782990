<div class="heading-container fdl-2" >
    <div class="back-action-container">
        <div class="back-button">
            <a href="javascript:void(0);" (click)="goBack()">
                <span class="material-icons">arrow_back</span>
                    Workflows
            </a>
        </div>
    </div>
</div>

<div class="form-container">
    <!-- Error Msg Alert -->
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button> 
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>

    <form class="form form-border"  [formGroup]="workflow" autocomplete="off">
        <div class = "headline-container">
            <h2 class="h4 font-weight-bold">Create Workflow</h2>
            <!-- <h2 class="h4 collapse-arrow collapsed font-weight-bold" data-toggle="collapse" href="#createworkflow">Create Workflow</h2>
            <i>Please click on header to expand/collapse and create a new workflow.</i> -->
        </div>
        <br>
        <div class="create-workflow">
            <div class="form-row">
                <div class="col-md-6 mb-3">
                <label for="Name" class="asterisk">Name</label>
                <input id="Name" type="text" class="form-control" name="Name" formControlName="Name">
                <div class="has-error">
                    <span *ngIf="(workflow.get('Name').errors?.required || workflow.get('Name').errors?.validateWhiteSpaces) 
                        && (workflow.get('Name').touched || isSubmitted)">
                    <i class="material-icons">warning</i> Please enter workflow name.
                    </span>  
                    <span *ngIf="workflow.get('Name').invalid && workflow.get('Name').errors?.pattern 
                        && (workflow.get('Name').touched || isSubmitted)">
                    <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
                    </span>
                    <span *ngIf="workflow.get('Name').errors?.maxlength && (workflow.get('Name').touched || isSubmitted)">
                    <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Form.Name}}, please correct it.
                    </span>
                </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-md-6 mb-3">
                <label for="Description" class="asterisk">Description</label>
                <input  id="Description" type="textarea" class="form-control" name="Description" formControlName="Description">
                <div class="has-error">
                    <span *ngIf="(workflow.get('Description').errors?.required) 
                        && (workflow.get('Description').touched || isSubmitted)">
                    <i class="material-icons">warning</i> Please enter workflow description.
                    </span>
                    <span *ngIf="workflow.get('Description').invalid && workflow.get('Description').errors?.pattern 
                        && (workflow.get('Description').touched || isSubmitted)">
                    <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
                    </span>
                    <span *ngIf="workflow.get('Description').errors?.maxlength && (workflow.get('Description').touched || isSubmitted)">
                    <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Form.Description}}, please correct it.
                    </span>
                </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-md-6 mb-3">
                <label for="Environment" class="asterisk">Environment</label>
                <input id="Environment" type="text" class="form-control" name="Environment" formControlName="Environment">
                <div class="has-error">
                        <span *ngIf="(workflow.get('Environment').errors?.required || workflow.get('Environment').errors?.validateWhiteSpaces) 
                            && (workflow.get('Environment').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter workflow environment.
                        </span>  
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-md-6 mb-3">
                <label for="FlowId" class="asterisk">FlowId</label>
                <input id="FlowId" type="text" class="form-control" name="FlowId" formControlName="FlowId">
                <div class="has-error">
                        <span *ngIf="(workflow.get('FlowId').errors?.required || workflow.get('FlowId').errors?.validateWhiteSpaces) 
                            && (workflow.get('FlowId').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter workflow FlowId.
                        </span>  
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-md-6 mb-3">
                    <label for="FlowUrl" class="asterisk">FlowUrl</label>
                    <input id="FlowUrl" type="text" class="form-control" name="FlowUrl" formControlName="FlowUrl">
                    <div class="has-error">
                        <span *ngIf="(workflow.get('FlowUrl').errors?.required || workflow.get('FlowUrl').errors?.validateWhiteSpaces) 
                            && (workflow.get('FlowUrl').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter workflow FlowUrl.
                        </span>  
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-md-2 mb-2 checkbox">
                    <input type="checkbox" class="ng-untouched ng-pristine ng-valid" value="false" id="IsSystemWorkflow" name="IsSystemWorkflow" 
                    formControlName="IsSystemWorkflow" (click)="onCheckBoxSelect()">
                    <label for="IsSystemWorkflow" class="input-label"> 
                        Is System Workflow
                    </label>
                </div>  
            </div>
            <div class="form-row">
                <div class="col-md-2 mb-2 checkbox">
                    <input type="checkbox" class="ng-untouched ng-pristine ng-valid" value="false" id="IsPageTemplateFlow" name="IsPageTemplateFlow" 
                    formControlName="IsPageTemplateFlow" (click)="onPageCheckBoxSelect()">
                    <label for="IsPageTemplateFlow" class="input-label"> 
                        Is Page Template Flow
                    </label>
                </div>  
            </div>
            <div class="form-row" *ngIf = "!workflow.get('IsSystemWorkflow').value">
                <div class="col-md-6 mb-3 typeahead-wrapper">
                    <label for="entityLevel" class="asterisk">
                        Entity Level:
                    </label>
                    <div class="fdl-select">
                        <select name="entityLevel" class="form-control" aria-invalid="false" formControlName="entityLevel" [(ngModel)]='selectedEntityLevelValue'
                        (change)="setEntityValues()">
                            <option value="">Please select</option>
                            <option *ngFor="let level of entityLevel | keyvalue" [value]="level.value">
                                {{level.value}}
                            </option>
                        </select>
                    </div>
                    <div class="has-error">
                        <span *ngIf="!workflow.get('entityLevel').valid && (workflow.get('entityLevel').touched || isSubmitted)">
                            <i class="material-icons">warning</i> Please select entity level.
                        </span>
                    </div>
                </div> 
            </div>
            <div class="form-row" *ngIf="selectedEntityLevelValue && !workflow.get('IsSystemWorkflow').value">
                <div class="col-md-6 mb-3 typeahead-wrapper">
                    <label *ngIf="selectedEntityLevelValue" for="EntityName" class="asterisk">
                        Entity Name:
                    </label>
                    <fdl-type-ahead *ngIf="selectedEntityLevelValue == 'Product'" formControlName="entityNameProduct"
                        [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                        [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityProductSelect($event)">
                    </fdl-type-ahead>
                    <fdl-type-ahead *ngIf="selectedEntityLevelValue == 'Form'" formControlName="entityNameForm"
                        [collection]="formSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                        [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityFormSelect($event)">
                    </fdl-type-ahead>
                    <fdl-type-ahead *ngIf="selectedEntityLevelValue == 'Organization'" formControlName="entityNameOrg"
                        [collection]="orgSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                        [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityOrgSelect($event)">
                    </fdl-type-ahead>
                    <fdl-type-ahead *ngIf="selectedEntityLevelValue == 'PageTemplate'" formControlName="entityNamePage"
                        [collection]="pageTemplateSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                        [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityPageTemplateSelect($event)">
                    </fdl-type-ahead>
                    <div class="has-error">
                        <span *ngIf="(invalidProductName || !workflow.get('entityNameProduct').valid) && (workflow.get('entityNameProduct').touched || isSubmitted) && selectedEntityLevelValue == 'Product'">
                            <i class="material-icons">warning</i> Please select valid product.
                        </span>
                        <span *ngIf="(invalidFormName || !workflow.get('entityNameForm').valid) && (workflow.get('entityNameForm').touched || isSubmitted) && selectedEntityLevelValue == 'Form'">
                            <i class="material-icons">warning</i> Please select valid form.
                        </span>
                        <span *ngIf="(invalidPageName || !workflow.get('entityNamePage').valid) && (workflow.get('entityNamePage').touched || isSubmitted) && selectedEntityLevelValue == 'PageTemplate'">
                            <i class="material-icons">warning</i> Please select valid page template.
                        </span>
                        <span *ngIf="(invalidOrgName || !workflow.get('entityNameOrg').valid) && (workflow.get('entityNameOrg').touched || isSubmitted) && selectedEntityLevelValue == 'Organization'">
                            <i class="material-icons">warning</i> Please select valid organization.
                        </span>
                    </div>
                </div>
            </div>
            <div class="fdl-2 button-container">
                <button type="button" aria-label="Cancel" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
                <button type="submit" aria-label="Default" class="btn fdl-btn btn-primary-orange float-right" (click)="Submit()">Submit</button>
            </div>
        </div>
    </form>
</div>