import { Component, OnInit, Renderer2, ViewChild, ElementRef } from "@angular/core";
import { DecimalPipe } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";

import { ProjectsService } from "../../services/projects.service";
import { ISuggestions } from "../../shared/suggestion";
import { IPager } from "../../shared/pagination";
import { IProjects, IProject } from "../../shared/project";
import { ProjectsDTOService } from "../../Projects/Services/projectsDTO.service";
import { DashboardCommonLogic } from "../shared/dashboard-common-logic";
import { PermissionsService } from "../../services/permissions.service";
import { UserService } from "../../services/user.service";
import { UtilService } from 'src/app/services/util.service';
import { IProjectStatusResloved } from '../../shared/status';

@Component({
  selector: "app-dashboard-project",
  templateUrl: "./dashboard-project.component.html"
})
export class DashboardProjectComponent extends DashboardCommonLogic
  implements OnInit {
  filterProjects: FormGroup;

  projectList: IProjects;
  filteredProjects: IProjects;
  xPagination: IPager;
  paginationArr: any;
  queryParam: any;

  projectSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  clientSuggestion: ISuggestions;
  assignedToSuggestion: ISuggestions;
  organizationSuggestion: ISuggestions;

  isDashboardFilterPopover: boolean = false;
  statusBlockClicked: string = "Total Projects";
  switchVal: string = "project";
  private static readonly pageSize: number = 6;
  pageNumber: number = 1;
  tagsArray: any[] = [];

  filterTooltip: string = "Select Filter";
  userDetails: any;

  showProductFilter: boolean = false;
  showProjectFilter: boolean = false;
  isClientFilterAllowed: boolean = false;
  isAdmin: boolean = false;


  @ViewChild('formSwitch') formSwitch: ElementRef;

  constructor(
    private projectService: ProjectsService,
    private r: Router,
    private projectDtoService: ProjectsDTOService,
    private permissionService: PermissionsService,
    private userService: UserService,
    private utilService: UtilService,
    private renderer: Renderer2,
    private decimalPipe: DecimalPipe
  ) {
    super(r);
  }

  ngOnInit(): void {
    this.userDetails = this.userService.getUserListData();
    // TODO: Need to remove below code
    if (this.userDetails) {
      this.isAdmin = this.userDetails.isAdmin;
      let isNonrespondent = this.userDetails.permissionsForUI.some(group => group.groupName === "Projects");
      if (!this.userDetails.isAdmin && !isNonrespondent) {
        this.r.navigate(["dashboard/form"]);
        return;
      }
    }
    this.showProductFilter = this.permissionService.showProductFilter();
    this.showProjectFilter = this.permissionService.showProjectFilter();
    this.isClientFilterAllowed = this.permissionService.isClientFilterAllowed();
    this.initFilterProjects();
    this.initQueryParams();
    this.callProjectListService();
    this.getSuggestions();
  }

  getSuggestions() {
    this.projectService.getSuggestions("users", "Fiserv Implementation Manager", "role").subscribe(suggestion => this.assignedToSuggestion = suggestion);
    if (this.showProjectFilter) this.projectService.getSuggestions("projects", "").subscribe(suggestion => this.projectSuggestion = suggestion);
    if (this.showProductFilter) this.projectService.getSuggestions("products", "").subscribe(suggestion => this.productSuggestion = suggestion);
    if (this.isClientFilterAllowed) this.projectService.getSuggestions("clients", "").subscribe(suggestion => this.clientSuggestion = suggestion);
    if (this.isAdmin) this.projectService.getSuggestions("organizations", "").subscribe(suggestion => this.organizationSuggestion = suggestion);
  }

  initQueryParams() {
    this.queryParam = Object.assign(
      {},
      {
        pageNumber: this.pageNumber,
        pageSize: DashboardProjectComponent.pageSize
      }
    );
  }

  callProjectListService(isNotAddTotal: boolean = false): void {
    this.projectService
      .getProjectList(this.queryParam)
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.xPagination = JSON.parse(response.headers.get("X-Pagination"));
          if (response.headers.get("X-pagination")) {
            this.xPagination = Object.assign({}, JSON.parse(
              response.headers.get("X-pagination")
            ) as IPager);
          }
          
          this.projectList = response.body as IProjects;
          
          this.projectList.projects.map(project => ({
            ...project,
            startDate: project.startDate
          }) as IProject);
            
          if (!isNotAddTotal && !this.isTotalExistInCounter()) {
            this.projectList.counters.unshift({
              key: "Total Projects",
              value: this.xPagination.totalCount
            });
          }

          this.calculatePages();
          this.filteredProjects = JSON.parse(JSON.stringify(this.projectList));
        }
      });
  }

  initFilterProjects(): void {
    this.filterProjects = new FormGroup({
      title: new FormControl(""),
      productTitle: new FormControl(""),
      clientTitle: new FormControl(""),
      fiservImplementationManagerName: new FormControl(""),
      organizationTitle: new FormControl('')
    });
  }

  togglePopoverContainer(): void {
    this.isDashboardFilterPopover = !this.isDashboardFilterPopover;
  }

  filterOnStatusBlock(status): void {
    
    // Once a status is clicked, then this method should not be called as all other status are removed
    let isFilteredOnStatus = this.tagsArray.some(ele => ele.key === 'status');    
    if(isFilteredOnStatus || (status.indexOf("Total") > -1)) return;

    this.queryParam["pageNumber"] = 1;
    this.pageNumber = 1;
    Object.assign(this.queryParam, { status: status });
      this.tagsArray.push({
        key: "status",
        value: status
      });
    this.filteredProjects.counters.shift();
    this.callProjectListService(true);
  }

  calculateClasses(status): string {
    return new DashboardCommonLogic(this.r).calculateClasses(status);
  }

  dashBoardBtnTxtOnStat(status): string {
    return new DashboardCommonLogic(this.r).dashBoardBtnTxtOnStat(status);
  }

  activate(view: string): void {
    return new DashboardCommonLogic(this.r).activateRoute(view);
  }

  filterClickHandler(): void {
    this.queryParam["pageNumber"] = 1;
    this.pageNumber = 1;
    let initialQueryParam = JSON.parse(JSON.stringify(this.queryParam));
    let filterValues = this.filterProjects.value;
    if (filterValues["title"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "title", filterValues);
      if (!valueFound) {
        this.queryParam.title = filterValues["title"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "title");
          this.tagsArray[objIndex].value = filterValues["title"];
        } else {
          this.tagsArray.push({ key: "title", value: filterValues["title"] });
        }
      }
    }
    if (filterValues["productTitle"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "productTitle", filterValues);
      if (!valueFound) {
        this.queryParam.productTitle = filterValues["productTitle"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "productTitle");
          this.tagsArray[objIndex].value = filterValues["productTitle"];
        } else {
          this.tagsArray.push({ key: "productTitle", value: filterValues["productTitle"] });
        }
      }
    }
    if (filterValues["clientTitle"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "clientTitle", filterValues);
      if (!valueFound) {
        this.queryParam.clientTitle = filterValues["clientTitle"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "clientTitle");
          this.tagsArray[objIndex].value = filterValues["clientTitle"];
        } else {
          this.tagsArray.push({ key: "clientTitle", value: filterValues["clientTitle"] });
        }
      }
    }
    if (filterValues["fiservImplementationManagerName"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "fiservImplementationManagerName", filterValues);
      if (!valueFound) {
        this.queryParam.fiservImplementationManagerName = filterValues["fiservImplementationManagerName"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "fiservImplementationManagerName");
          this.tagsArray[objIndex].value = filterValues["fiservImplementationManagerName"];
        } else {
          this.tagsArray.push({ key: "fiservImplementationManagerName", value: filterValues["fiservImplementationManagerName"] });
        }
      }
    }
    if (filterValues["organizationTitle"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "organizationTitle", filterValues);
      if (!valueFound) {
        this.queryParam.organizationTitle = filterValues["organizationTitle"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "organizationTitle");
          this.tagsArray[objIndex].value = filterValues["organizationTitle"];
        } else {
          this.tagsArray.push({ key: "organizationTitle", value: filterValues["organizationTitle"] });
        }
      }
    }
    let isRemoveTotal = this.isRemoveTotalFromStatusArea();
    const isCallProjectListApi = new DashboardCommonLogic(this.r).isEquivalent(
      initialQueryParam,
      this.queryParam
    );
    if (!isCallProjectListApi) this.callProjectListService(isRemoveTotal);
    this.togglePopoverContainer();
  }

  resetClickHandler(): void {
    this.statusBlockClicked = "Total Projects";
    this.initFilterProjects();
    this.initQueryParams();
    this.tagsArray = [];
    this.callProjectListService();
    this.togglePopoverContainer();
  }

  paginateClickHandler(idx: number): void {
    if (this.pageNumber === idx) return;
    this.pageNumber = idx;
    this.queryParam["pageNumber"] = this.pageNumber;
    let isRemoveTotal = this.isRemoveTotalFromStatusArea();
    this.callProjectListService(isRemoveTotal);   
  }

  cardBtnClickHandler(proj: IProject): void {
    switch (proj.statusTitle) {
      case "Not Started":
      case "In Progress":
      case "Completed":
        this.r.navigate(["/projects/view", proj.id]);
        break;
      default:
        this.r.navigate(["/projects/view", proj.id]);
        break;
    }
  }

  viewAllProjectsClickHandler(): void {
    this.r.navigate(["projects/list"]);
  }

  removeTagClickHandler(filterCriteriaRemove: string): void {
    for (let i = 0; i < this.tagsArray.length; i++) {
      if (this.tagsArray[i].key === filterCriteriaRemove) {
        this.tagsArray.splice(i, 1);
        this.filterProjects.reset({ filterCriteriaRemove: "" });
      }
    }
    delete this.queryParam[filterCriteriaRemove];
    this.statusBlockClicked = "Total Projects";
    let isRemoveTotal = this.isRemoveTotalFromStatusArea();
    this.callProjectListService(isRemoveTotal);
  }

  isRemoveTotalFromStatusArea(): boolean {
    return this.tagsArray.some(ele => ele.key === 'status');
  }

  private isTotalExistInCounter(): boolean {
    let isValueFound = this.projectList.counters.find(el => el.key == "Total Projects");
    return isValueFound ? true : false;
  }

  calculatePages(){
    this.paginationArr = [];
    const range = this.utilService.pageRange(this.pageNumber, this.xPagination.totalPages);
    for(var i = range.start; i <= range.end; i++){
      this.paginationArr.push(i);
    }
  }

  nextPage(){    
    if(this.pageNumber === this.xPagination.totalPages) return;
    const nextPage = this.pageNumber + 1;
    this.paginateClickHandler(nextPage);
  }

  previousPage(){
    if(this.pageNumber === 1) return;
    const prevPage = this.pageNumber - 1;
    this.paginateClickHandler(prevPage);
  }

  firstPage(){
    if(this.pageNumber === 1) return;
    this.paginateClickHandler(1);
  }

  lastPage(){
    const lastPage = this.xPagination.totalPages;
    if(this.pageNumber === lastPage) return;
    this.paginateClickHandler(this.xPagination.totalPages);
  }

  isFirstPage(){
    return (this.pageNumber === 1);
  }

  isLastPage(){
    return (this.pageNumber === this.xPagination.totalPages);
  }

  navigateToProjectForm(proj: IProject): void {
    this.projectService.setProjectDetails({id: proj.id, title: proj.title});
    let inputElement: HTMLElement = this.formSwitch.nativeElement as HTMLElement;
    inputElement.click();
  }

  isShowViewFormLnk(proj: IProject): boolean {
    if(proj.statusTitle === 'Not Started' || proj.responses.length === 0) return false;
    return true;
  }

  populateStatusOfProject(proj: IProject): string {
    let status = ""
    status = proj.statusTitle || 'Not Started';
    if(status === 'In Progress') status += " " + "(" + this.decimalPipe.transform(proj.percentageComplete, '1.0-0') + "%)";
    return status;
  }
  
}
