import { IEmailTemplateDetails } from './../models/email-template';
import { EmailTemplateService } from './../services/email-template.service';
import { Component, OnInit, OnDestroy, Input  } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ISuggestions } from 'src/app/shared/suggestion';
import { ProjectsService } from 'src/app/services/projects.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MasterService } from 'src/app/services/master.service';
import { REGEX_PATTERNS, WorkflowContextEntityLevel, WorkflowEntityLevel, REGEX_VALIDATION_MESSAGE, AdminWorkflowEntityLevel  } from 'src/app/core/constants';
import { IEmailTemplate } from '../models/email-template';
import { EmailTemplateDTOService } from 'src/app/shared/email-templateDTO.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { UserService } from 'src/app/services/user.service';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { DataTableComponent } from 'src/app/SharedModule/Components/data-table/data-table.component';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { Filter } from 'src/app/shared/user.filter';
declare var $: any;

@Component({
  templateUrl: './email-template-list.component.html'
})

export class EmailTemplateListComponent implements OnInit, OnDestroy {

  @Input() allEmailsSelected: DataTableComponent;

  constructor(private emailTemplateService: EmailTemplateService,
    private router: Router, private fb: FormBuilder,
    private projectService: ProjectsService, private emailTemplateDTO: EmailTemplateDTOService,
    private permissionService: PermissionsService, private masterService: MasterService, private userService: UserService,
    private userFilterService: UserFilterService, private route: ActivatedRoute) {}

  showOrganizationFilter: boolean = false;
  entityLevel: any;
  productEntityNames:  ISuggestions;
  organizationSuggestion: ISuggestions;
  eventSuggestion: ISuggestions;
  templateSuggestion: ISuggestions;
  StatusTypes: string[] = ['Enabled','Disabled'];

  displayColumns = ['Template Name', 'Event Name', 'Entity Level'];
  keys = ['emailTitle', 'eventName', 'level'];

  isTestEmailSubmitted: boolean = false;
  SentTestEmail: FormGroup;
  filterForm: FormGroup;
  emailAddressForm: FormGroup;
  emailTemplateList: IEmailTemplate[];
  queryParam: IQuery;
  currentPageNumber: number = 1;
  pagerObject:IPager;
  sortingObject;
  isError:boolean;
  errorMsg: string = "";
  showCreateButton: boolean = false;
  successMsg: string;
  isAdmin: boolean = false;
  showComment: boolean = true;
  listname:string = PreferenceService.emailTemplateList;
  filtered: boolean = false;
  filterExists: boolean = false;

  statusToggle: boolean = true;
  //emailSets: any = [];
  @Input() emailSets : any = [];
  @Input() currentIndex: number;
  StatusText: string = "";
  toggleStatusTitle: string = "Disable Email Template";
  emailDetail: IEmailTemplateDetails;
  selectedEventDescription:any;
  emailTemplateData: IEmailTemplateDetails;
  selectedEntityLevelValue: any;
  selectedLevelValue: any;
  organizationDetails: any;
  userFieldsForm: FormGroup;
  userFieldsCCForm: FormGroup;
  createEmailTemplate: FormGroup;
  emailConfigDetailId: number;
  slectedEmailTemplatecount: number = 0;
  loadDrawer: boolean;
  emailTemplateDrawer: boolean = false;
  invalidFormName: boolean = false;
  formSuggestion: ISuggestions;
  invalidProductName: boolean = false;
  productSuggestion: ISuggestions;
  invalidPageTemplateName: boolean = false;
  pageTemplateSuggestion: ISuggestions;
  invalidOrganizationName: boolean = false;
  isSubmitted: boolean = false;
  isEdit: boolean = false;
  readOnlyEntityName: any;

  readOnlyEventName: any;
  selectedOrgId: number;

  userFields: any;
  selectedToIds: number[] = [];
  SelectedCCIds: number[] = [];
  selectedToForDisplay: any = []; //to show on UI
  selectedCCForDisplay = [];
  invalidToEmail=false;
  invalidEventName: boolean = false;
  toEmailValidationMessage ='Please select or specify either "To Names" or "Additional To Names"';
  toEmailToolTipMessage ='Either "To Names" or "Additional To Names" is required';
  initatorDrawer: boolean = false;
  invalidEntityLevel: boolean = false;
  regexValidationMessage: any;
  columns = [
      { prop: 'emailTitle', name: 'Template Name', // inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.text },
        width: 400 }
    , { prop: 'eventName', name: 'Event Name', // inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.text },
        width: 400 }
    // , { prop: 'level', name: 'Entity Level', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.text }, width: 250 }
  ]

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.isEdit = this.emailTemplateDTO._isEditMode;
    this.getStaticUserFields();
    if(this.isAdmin){
      this.entityLevel = Object.values(AdminWorkflowEntityLevel).filter(value => typeof value !== 'number');
    }
    else
    {
      this.entityLevel = Object.values(WorkflowContextEntityLevel).filter(value => typeof value !== 'number');
    }
    this.emailSets[0] = {
      'selectedEmailTemplateList': []
    }
    this.showCreateButton = this.permissionService.isCreateEmailTemplateAllowed();
    this.showOrganizationFilter = this.permissionService.userData.isAdmin;

    this.currentIndex = 0;
    this.initFilterForm();
    this.initEmailAddressForm();
    this.initQueryParams();
    // this.masterService.loadAndApplyFilters(this.queryParam, this.filterForm);
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    const userFilter: Filter[] = this.route.snapshot.data['userFilter'];
    // The default filter status is 'Enabled' so mark it as no filter exists
    if (userFilter && userFilter.length === 1 && userFilter[0].key === 'status' && userFilter[0].value === 'Enabled'){
      this.filterExists = false;
    }

    this.filterList();
    // this.callEmailTemplateListService();
    this.successMsg = this.emailTemplateDTO._isemailTemplateCreated ? `The Email Template '${this.emailTemplateDTO._newCreatedemailTemplate}' has been created`: '' ;
    this.getOrganizationSuggestions();
    this.getEventNameSuggestions();
    this.getTemplateNameSuggestions();

    if (this.isEdit) {
      this.emailTemplateData = this.emailTemplateDTO.getemailTemplateData()
        ? this.emailTemplateDTO.getemailTemplateData() : <IEmailTemplateDetails>{};
      this.organizationDetails = { "organizationTitle": this.emailTemplateData.organizationTitle };
      this.readOnlyEventName = { "displayName": this.emailTemplateData.template.displayName };
      this.readOnlyEntityName = this.emailTemplateData.entityName;
      this.patchForm();
    }
    else {
      this.organizationDetails = Object.assign({}, this.userService.getUserListData());
      this.selectedEntityLevelValue = "";
      this.selectedLevelValue = "";
      this.selectedOrgId = this.organizationDetails.organization;
    }

    this.initForm();
    this.getProductSuggestions();
    this.getFormSuggestions();
    this.getPageTemplateSuggestions();
    this.onSearchChange("");
    this.filtered = this.filterExists;
  }

  onSearchChange(searchValue: string): void {
    if (searchValue === "" && this.allEmailsSelected) {
      this.allEmailsSelected.allSelected = false;
    }
  }

  initQueryParams() {
    this.queryParam = Object.assign({},{
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      templateName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.email_template_name_validator)]),
      eventName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)]),
      level: new FormControl(''),
      productSearch: new FormControl(''),
      formSearch: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      pageTemplateSearch: new FormControl('', [Validators.pattern(REGEX_PATTERNS.page_name_validator)]),
      organizationTitle: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]),
      status: new FormControl('Enabled')
    });
  }

  initCreateEmailTemplateForm() {
    this.createEmailTemplate = this.fb.group({
      organization: [this.organizationDetails.organizationTitle],
      eventName: [''],
      entityLevel: [''],
      entityNameProduct: ['', [Validators.required]],
      entityNameForm: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]],
      entityNamePageTemplate: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.page_name_validator)]],
      templateName: [''],
      subject: [''],
      body: [''],
      additionalData: [''],
      disclaimer: [''],
      privacyPolicy: [''],
      additionalToEmail: [''],
      additionalCCEmail: [''],
      copyFromTemplate: [],
      isEnabled: ['1'],
      isCopyEnabled: ['0'],
      description: [''],
    });
  }

  initForm() {
    this.initCreateEmailTemplateForm();

    this.SentTestEmail = this.fb.group({
      emailAddress: ['']
    });

    this.userFieldsForm = this.fb.group({userFields: this.fb.array([])});
    this.userFieldsCCForm = this.fb.group({userFields: this.fb.array([])});
  }

  initEmailAddressForm() {
    this.emailAddressForm = new FormGroup({
      emailAddress: new FormControl('', [
        Validators.required,
        Validators.pattern(REGEX_PATTERNS.Email),
        NoWhitespaceValidator.validateWhiteSpaces])
    });
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.emailTemplateService.exportEmailTemplates(this.queryParam).subscribe(
      res => {
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'Email-Templates.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  refresh() {
    this.callEmailTemplateListService();
  }

  callEmailTemplateListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.emailTemplateService.getEmailTemplates(this.queryParam)
      .pipe(take(1))
      .subscribe(
        (emailTemplates) => {
          this.isError = false;
          this.emailTemplateList = emailTemplates.body as IEmailTemplate[];
          this.emailTemplateList.forEach(item => {
            item['isDelete'] = item.level == 'Product' || item.level =='Form' ? true : false;
          })
          if(emailTemplates.headers.get('X-pagination')){
            this.pagerObject = Object.assign({},JSON.parse(emailTemplates.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }

  filterList(toggle?: Boolean){
    if(this.filterForm.valid) {
      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initQueryParams();
      let filterValues = this.filterForm.value;

      if (filterValues['templateName'])
        this.queryParam.emailTitle = filterValues['templateName']
      if (filterValues['status'])
      {
        if (filterValues['status'] === "Enabled"){
          this.queryParam.isEnabled = true;
          // change the toggle to enable
          this.statusToggle = true;
          this.toggleStatusTitle = "Disable Email Template";
          $("#switch2").prop('checked', false);
        }
        if (filterValues['status'] === "Disabled"){
          this.queryParam.isEnabled = false;
          // change the toggle to disable
          this.statusToggle = false;
          this.toggleStatusTitle = "Enable Email Template";
          $("#switch2").prop('checked', true);
        }
      }
      this.queryParam.isEnabled = this.statusToggle;
      if (filterValues['organizationTitle'])
        this.queryParam.organizationTitle = filterValues['organizationTitle'];
      if (filterValues['eventName'])
        this.queryParam.eventName = filterValues['eventName']
      if (filterValues['level'])
        this.queryParam.level = filterValues['level']
      if (filterValues['productSearch'])
        this.queryParam.productTitle = filterValues['productSearch']
      if (filterValues['formSearch'])
        this.queryParam.formTitle = filterValues['formSearch']
      if (filterValues['pageTemplateSearch'])
        this.queryParam.pageTemplateTitle = filterValues['pageTemplateSearch']

      this.setOrderBy(this.sortingObject);
      this.callEmailTemplateListService();
      this.filtered = !toggle;
   }
  }

  clearFilterList(){
    this.filtered = false;
    this.selectedLevelValue = '';
    this.statusToggle = true;
    this.initQueryParams()
    this.initFilterForm();
    this.filterList(true);
  }

  navigate(event){
    if (event.action === "view") {
      this.masterService.saveFilters(this.filterForm);
      this.router.navigate(['/self-service/emailTemplate/view', event.data]); //event.data
    } else if(event.action === "edit"){
      this.navigateToEdit(event.data);
    }
    else if (event.action === "sendEmail") {
      this.emailConfigDetailId = event.data;
      $('#shareModal').modal('show');
    }
    else if (event.action === "delete") {
      this.successMsg = '';
      this.emailTemplateService.deleteEmailTemplate(event.data).subscribe(
        data => {
          this.callEmailTemplateListService();
          this.getTemplateNameSuggestions();
          this.successMsg = `An Email Template '${event.title}' has been deleted.`
        },
        error =>{
          this.isError = true; this.errorMsg = error;
        }
      )
    }
  }

  applySorting(event){
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callEmailTemplateListService();
  }

  createEmailTemplateNav(){
    this.emailTemplateService.tempData = "Email Templates";
     this.router.navigate(['self-service/emailTemplate/create']);
  }

  changePage(page){
    this.queryParam.pageNumber = page;
    this.callEmailTemplateListService();
  }

  setOrderBy(sortingData){
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  navigateToEdit(id: number): void {
    this.emailTemplateService.getEmailTemplateDetailsById(id)
      .pipe(take(1))
      .subscribe(
        data => {
           this.emailTemplateDTO._isEditMode = true;
           this.emailTemplateDTO.setemailTemplateData(data as IEmailTemplateDetails);
           this.router.navigate(['/self-service/emailTemplate/edit']);
        }
      );
  }

  ngOnDestroy(): void {
    this.emailTemplateDTO._isemailTemplateCreated = false;
    this.emailTemplateDTO._newCreatedemailTemplate = '';
  }

  getEventNameSuggestions(){
    this.emailTemplateService.getEventNameSuggestions('emailevents','')
    .subscribe(suggestions => {
      this.eventSuggestion = suggestions;
    },
    (error)=> { this.isError = true; this.errorMsg = error; });
  }

  getTemplateNameSuggestions(){
    this.emailTemplateService.getTemplateNameSuggestions('emailTemplates','')
    .subscribe(suggestions => {
      this.templateSuggestion = suggestions;
    },
    (error)=> { this.isError = true; this.errorMsg = error; });
  }

  getOrganizationSuggestions() {
    if(this.showOrganizationFilter){
    this.projectService.getSuggestions("organizations", "")
      .subscribe(suggestions =>{
        this.organizationSuggestion = suggestions;
      },
      error => { this.isError = true; this.errorMsg = error; });
    }
  }

  toggleStatus() {
    this.initFilterForm();
    this.statusToggle = !this.statusToggle;

    if(this.statusToggle){
      this.toggleStatusTitle = "Disable Email Template";
    }else{
      this.toggleStatusTitle = "Enable Email Template";
    }

    if(this.emailSets[0].selectedEmailTemplateList.length > 0) {
      this.emailSets[0].selectedEmailTemplateList.forEach(emailTemplate => {
        this.emailTemplateService.getEmailTemplateDetailsById(emailTemplate.id).subscribe(templateConfigInfo => {
          /*
          if(templateConfigInfo.organizationTitle === null){
            templateConfigInfo.organizationTitle = "";
          }
          if(templateConfigInfo.entityName !== null){
            templateConfigInfo.organizationTitle = templateConfigInfo.entityName;
            templateConfigInfo.organizationId = templateConfigInfo.entityId;
          }
          if(templateConfigInfo.entityName === null){
            templateConfigInfo.entityName = "";
          }
          if(templateConfigInfo.level === "System"){
            templateConfigInfo.organizationTitle = "System";
            templateConfigInfo.entityName = "System";
            templateConfigInfo.organizationId = 1;
          }
          */
          templateConfigInfo.template.isEnabled = !templateConfigInfo.template.isEnabled;
          this.emailTemplateService.updateEmailTemplate(templateConfigInfo.template.emailConfigDetailId, templateConfigInfo).subscribe(template => {
            this.filterList(true);
          });
        });
      });
    }

    this.emailSets[0] = {
      'selectedEmailTemplateList': []
    };
    this.filterList(true);
  }

  onEventSelect(event: any) {
    this.selectedEventDescription = event.item.description;
  }

  patchForm() {
    this.createEmailTemplate.patchValue({
      eventName: this.emailTemplateData?.template?.displayName,
      entityNameProduct: this.emailTemplateData?.productId ? this.emailTemplateData.entityName : '',
      entityNameForm: this.emailTemplateData?.formId ? this.emailTemplateData.entityName : '',
      entityNamePageTemplate: this.emailTemplateData?.pageTemplateId ? this.emailTemplateData.entityName : '',
      templateName: this.emailTemplateData?.template.emailTitle,
      subject: this.emailTemplateData?.template.emailSubject,
      body: this.emailTemplateData?.template.emailBody,
      additionalData: this.emailTemplateData?.additionalData,
      disclaimer: this.emailTemplateData?.disclaimer,
      privacyPolicy: this.emailTemplateData?.privacyPolicy,
      additionalToEmail: this.emailTemplateData?.template?.additionalToEmails,
      additionalCCEmail: this.emailTemplateData?.template?.additionalCcEmails,
      organization: this.organizationDetails.organization,
      isEnabled: this.emailTemplateData?.template?.isEnabled ? '1' : '0'
    })

    this.selectedEntityLevelValue = this.emailTemplateData?.level;
    this.selectedEventDescription = this.emailTemplateData?.template?.description;
  }

  sendEmail(){
    this.emailTemplateService.getEmailTemplateDetailsById(this.emailConfigDetailId).pipe(take(1))
        .subscribe(
          data => {
            this.emailDetail = data;
            let submitData = {
              "toEmail": this.emailAddressForm.value.emailAddress,
              "CCEmail": this.emailAddressForm.value.emailAddress,
              "EmailSubject":  this.emailDetail.template.emailSubject,
              "EmailBody":  this.emailDetail.template.emailBody,
              "HasAttachment": false,
              "Attachment" : "",
              "AttachmentName" : "",
              "NotificationPriority": 2
            }

            this.emailTemplateService.sendEmail(submitData).subscribe(
              data => {
                this.successMsg = `Test Email template(s) have been sent.`
                $('#shareModal').modal('hide');
                this.callEmailTemplateListService();
                this.emailAddressForm.reset();
              },
              error => {
                this.isError = true;
                this.errorMsg = error;
                $('#shareModal').modal('hide');
                this.emailAddressForm.reset();
              }
            );
          },
          (error) => { this.isError = true; this.errorMsg = error; });
  }

  copyTemplates() {
    this.resetEmailTemplateErrors();
    $('.container-page').addClass('overflow-hidden');
    this.slectedEmailTemplatecount = this.emailSets[0].selectedEmailTemplateList.length;
    this.loadDrawer = true;
    this.emailTemplateDrawer = true;
  }

  resetEmailTemplateErrors() {
    this.invalidOrganizationName = false;
    this.invalidProductName = false;
    this.invalidFormName = false;
    this.invalidPageTemplateName = false;
    this.invalidEntityLevel = false;
    this.isError = false;
    this.errorMsg = '';
  }

  validateEmailTemplateSubmit(){
    if (this.createEmailTemplate.valid){
      return true;
    }

    const entityLevelValue = this.createEmailTemplate.get('entityLevel').value;
    let entityNameProduct = this.createEmailTemplate.get('entityNameProduct');
    let entityNameForm = this.createEmailTemplate.get('entityNameForm');
    let entityNamePageTemplate = this.createEmailTemplate.get('entityNamePageTemplate');
    switch(entityLevelValue) {
      case 'Organization':
        if (entityNameProduct?.errors?.required) {
          entityNameProduct.setErrors(null);
        }

        if (entityNameForm?.errors?.required) {
          entityNameForm.setErrors(null);
        }
        
        if (entityNamePageTemplate?.errors?.required) {
          entityNamePageTemplate.setErrors(null);
        }
        break;
      case 'Product':
        if (entityNameProduct?.errors?.required) {
          return false;
        }

        if (entityNameForm?.errors?.required) {
          entityNameForm.setErrors(null);
        }
        
        if (entityNamePageTemplate?.errors?.required) {
          entityNamePageTemplate.setErrors(null);
        }
        break;
      case 'Form':
        if (entityNameForm?.errors?.required) {
          return false;
        }

        if (entityNameProduct?.errors?.required) {
          entityNameProduct.setErrors(null);
        }
        
        if (entityNamePageTemplate?.errors?.required) {
          entityNamePageTemplate.setErrors(null);
        }
        break;
      case 'PageTemplate':
        if (entityNamePageTemplate?.errors?.required) {
          return false;
        }

        if (entityNameProduct?.errors?.required) {
          entityNameProduct.setErrors(null);
        }
        
        if (entityNameForm?.errors?.required) {
          entityNameForm.setErrors(null);
        }
        break;
    }

    return this.createEmailTemplate.valid;
  }

  emailTemplateSubmit() {
    this.isSubmitted = true;
    let productId: any;
    let formId: any;
    let pageTemplateId: any;
    let orgId: any;
    this.resetEmailTemplateErrors();
    const isSystem: boolean = this.selectedEntityLevelValue != 'System' ? false : true;

    if(this.selectedEntityLevelValue === '') {
      this.invalidEntityLevel = true;
    }

    const isFormValid = this.validateEmailTemplateSubmit();
    if (isFormValid) {
      if (!isSystem && this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue == 'Product') {
        const productName = this.createEmailTemplate.value.entityNameProduct;
        productId = this.projectService.fetchIdForRequest(this.productSuggestion, productName);
        if (productId < 1) {
          this.invalidProductName = true;
        }
      }

      if (!isSystem && this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue == 'Form') {
        const formName = this.createEmailTemplate.value.entityNameForm;
        formId = this.emailTemplateService.fetchIdForRequestByTitle(this.formSuggestion, formName);
        if (formId < 1) {
          this.invalidFormName = true;
        }
      }

      if (!isSystem && this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue == 'PageTemplate') {
        const pageTemplateName = this.createEmailTemplate.value.entityNamePageTemplate;
        pageTemplateId = this.emailTemplateService.fetchIdForRequestByTitle(this.pageTemplateSuggestion, pageTemplateName);
        if (pageTemplateId < 1) {
          this.invalidPageTemplateName = true;
        }
      }

      if (this.invalidEventName || this.invalidProductName || this.invalidFormName || this.invalidPageTemplateName || this.invalidEntityLevel) {
        return;
      }

      let organizationName = this.organizationDetails.organizationTitle;
      if (this.isAdmin) {
        orgId = this.emailTemplateService.fetchIdForRequestByTitle(this.organizationSuggestion, organizationName);
      }
      else {
        orgId = this.userService.getUserListData() ? this.userService.getUserListData().organization : -1;
      }
      let data = [];
      var emailTemplateList = this.emailSets[0].selectedEmailTemplateList;

      emailTemplateList.forEach(element => {
        const submitData = {
          "organizationId": this.selectedEntityLevelValue !== 'PageTemplate' && orgId > 0 ? orgId : null,
          "productId": this.selectedEntityLevelValue === 'Product' && productId > 0 ? productId : null,
          "formId": this.selectedEntityLevelValue === 'Form' && formId > 0 ? formId : null,
          "pageTemplateId": this.selectedEntityLevelValue === 'PageTemplate' && pageTemplateId > 0 ? pageTemplateId : null,
          "isPageTemplate": this.selectedEntityLevelValue === 'PageTemplate' && pageTemplateId > 0 ? true : false,
          "emailConfigDetailId": element.emailConfigDetailId,
          "level": this.selectedEntityLevelValue,
          "templateName" : element.emailTitle
        }
        data.push(submitData)
      });

      this.createTemplate(data);
     }
  }

  createTemplate(data) {
    this.emailTemplateService.createMultipleEmailTemplates(data).subscribe(
      data => {
        this.emailTemplateDTO._isemailTemplateCreated = true;
        this.successMsg = `Email template(s) have been copied and created.`;
        this.clearForm();
        this.callEmailTemplateListService();
        this.emailSets[0].selectedEmailTemplateList = [];
      },
      error => {
        this.clearForm();
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

 clearForm() {
    $('.container-page').removeClass('overflow-hidden');
    this.loadDrawer = false;
    this.emailTemplateDrawer = false;
    this.createEmailTemplate.reset();
    this.selectedEntityLevelValue = "";
    this.resetEmailTemplateErrors();
  }

  ngAfterViewInit() {
    this.loadDrawer = false;
    this.emailTemplateDrawer = false;
  }

  onEntityFormSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidFormName = false;
    }
  }

  onEntityPageTemplateSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidPageTemplateName = false;
    }
  }

  onEntityProductSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidProductName = false;
    }
  }

  onEntityLevelSelect() {
    this.resetEmailTemplateErrors();
  }

  onOrganizationSelect(event: any) {
    this.selectedOrgId = event.item.id;
    this.getFormSuggestions();
    this.getProductSuggestions();
  }

  getFormSuggestions() {
    this.projectService.getSuggestions("forms", this.selectedOrgId, "orgId").subscribe(
      suggestions => this.formSuggestion = suggestions,
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  createSelectedToListForDisplay() {
    this.selectedToForDisplay = this.userFields.filter(
      data => this.selectedToIds.includes(data.id)
    );
  }

  getProductSuggestions() {
    this.projectService.getSuggestions('products', this.selectedOrgId, "orgId")
      .subscribe(suggestions => {
        this.productSuggestion = suggestions;
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  getPageTemplateSuggestions() {
    this.projectService.getSuggestions('pagetemplate', "")
      .subscribe(suggestions => {
        this.pageTemplateSuggestion = suggestions;
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  getStaticUserFields() {
    this.emailTemplateService.getUserFields().subscribe(
      data => {
        this.userFields = data;
        if(this.isEdit)
        {
          this.SelectedListOfUserFieldForEdit();
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    )
  }

  private SelectedListOfUserFieldForEdit() {
    this.selectedToIds = [];
    this.SelectedCCIds = [];
    if(this.emailTemplateData.template.toEmail != ""){
      const toEmailFields = this.emailTemplateData.template.toEmail.split(', ');
      if (toEmailFields.length > 0) {
        toEmailFields.forEach(element => {
          const selectedRole = this.userFields.find(item => (item.fieldName.toLowerCase().indexOf(element.toLowerCase()) > -1));
          if (selectedRole)
            this.selectedToIds.push(selectedRole.id);
        });
        this.createSelectedToListForDisplay();
      }
    }

    if (this.emailTemplateData.template.ccEmail != "") {
      const ccEmailFields = this.emailTemplateData.template.ccEmail.split(', ');
      if (ccEmailFields.length > 0) {
        ccEmailFields.forEach(element => {
          const selectedRole = this.userFields.find(item => (item.fieldName.toLowerCase().indexOf(element.toLowerCase()) > -1));
          if (selectedRole)
            this.SelectedCCIds.push(selectedRole.id);
        });
      };
      this.selectedCCForDisplay = this.userFields.filter(
        data => this.SelectedCCIds.includes(data.id)
      );
    }
  }
}
