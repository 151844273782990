import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { SurveyService } from "../../../services/survey.service";
import { MasterService } from "../../../services/master.service";

@Component({
  selector: "app-survey-verification",
  templateUrl: "./survey-verification.component.html"
})
export class SurveyVerificationComponent implements OnInit {

  isError: boolean = false;
  errorMsg: string = "";
  otpForm: FormGroup;
  isSaved: boolean = false;
  invalidOtpMessage: string;
  isSuccess: boolean = false;
  successMsg: string = "";
  surveyId: string;

  constructor(
    private surveyService: SurveyService,
    private masterService: MasterService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const error = this.surveyService.getError();
    if (error) {
      this.populateErrorMsg(error);
      return;
    }
    this.createOtpForm();
    this.surveyId = this.masterService.getGuid();
  }

  createOtpForm(): void {
    this.otpForm = new FormGroup({
      otp: new FormControl("", Validators.required)
    });
    this.otpForm.get('otp').valueChanges.subscribe(value => this.invalidOtpMessage = "")
  }

  resendOtp(): void {
    this.otpForm.reset();
    this.invalidOtpMessage = "";
    this.isSaved = false;
    this.surveyService.generateOtp(this.surveyId).subscribe(
      result => {
        if (result.isSuccess) {
          this.isSuccess = true;
          this.successMsg = result.message;
        }
        else {
          this.invalidOtpMessage = result.message;
        }

      },
      err => {
        this.populateErrorMsg(err);
      }
    );
  }

  validateOtp() {

    if (!this.otpForm.valid) {
      this.isSaved = true;
      return false;
    }
    else {
      this.isError = false;
      this.errorMsg = "";
      this.invalidOtpMessage = "";
      let otpVal = this.otpForm.get("otp").value;
      this.surveyService.validateOtp(otpVal, this.surveyId).subscribe(
        data => {
          if (data.isSuccess) {
            sessionStorage.setItem("surveyToken", data.token);
            localStorage.setItem("drmsToken", 'Bearer ' + data.token);
            document.body.classList.remove('otp');
            this.surveyService.validatedOtp(true);
            this.router.navigate(["../respond"], { relativeTo: this.route });
          } else {
            this.invalidOtpMessage = data.message;
          }
        },
        err => {
          this.isError = true;
          this.errorMsg = err;

        }
      );
    }
  }

  populateErrorMsg(msg: string = "") {
    let isExpired = this.surveyService.getExpiredSurvey();
    if (isExpired === "expired") {
      this.router.navigate(["../expired"], { relativeTo: this.route });
    }
    else {
      this.isError = true;
      this.errorMsg = msg;
    }
  }

}
