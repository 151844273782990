import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ManageHelpService } from '../../../admin/help/services/manageHelp.service';
import { ICreateTopic } from '../../../admin/manageForms/models/createTopic';
import { HelpService } from '../../../services/help.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-topic-details',
  templateUrl: './topic-details.component.html',
  styleUrls: ['./topic-details.component.css']
})
export class TopicDetailsComponent implements OnInit {
  id: any;
  topics: ICreateTopic;
  json: any;
  searchString: any = '';
  i: number = 0;
  successMessage: string = '';
  popularTopic: ICreateTopic;
  isCommonCategory: boolean = false;
  isClientUser: boolean = false;
  clientDashboardUserManualId: number = 0;
  clientDashboardFAQId: number = 0;
  constructor(private route: ActivatedRoute, private router: Router, private helpService: HelpService, private _manageHelp: ManageHelpService, private userService: UserService) { }

  ngOnInit(): void {
    this.isClientUser = this.userService.getUserListData().type.toLowerCase() == 'client' ? true: false;
    this.clientDashboardUserManualId = environment.clientDashboardUserManualId;
    this.clientDashboardFAQId = environment.clientDashboardFAQId;
    this.successMessage = this._manageHelp.successMessage;
    this._manageHelp.successMessage = '';
    this.route.params.subscribe((params) => {
      this.id = params['id'];
     
      if (params.flowname)
        this._manageHelp.flowName = params.flowname;
      else {
        this._manageHelp.flowName = '';
      }
      this.getTopicListDetails(this.id)
      
    });

  }
  getTopicListDetails(id: any) {

    const category = this.helpService.getTopicById(id).subscribe(suggestions => {

      this.topics = suggestions;
      this.json = JSON.parse(this.topics.formJSON)
      this.isCommonCategory = this.topics.isCommonCategory;
      this.helpService.recommendedTopic(this.topics.helpCategoryId, this.isCommonCategory).subscribe(suggestions => {
        this.popularTopic = suggestions;
      })

    })
  }
  navigateBack() {
    this.router.navigate(['/help/topic/' + this.id]);
  }
  navigateTo() {
    {
      //this.router.navigate(['/admin/help']);

    }
  }
  back() {
    if (this._manageHelp.flowName == '')
      this.router.navigate(['/help/category']);
    else {
      this._manageHelp.flowName = ''
      this.router.navigate(['/help/category/' + this.topics.helpCategoryId]);
    }

  }
}
