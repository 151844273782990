<div class="heading-container fdl-2">
  <h1>Projects</h1>
  <button type="button" *ngIf="isCreateProjectAllowed" class="btn fdl-btn btn-primary-orange" (click)="goToProjectCreate();">
    <span class="material-icons">add</span>
    Create New Project
  </button>
</div>

<div class="main-container-sub responsive-container col-settings-drawer-alignment">
  <div class="form-container">
    <div class="row">
      <section class="col-lg-12 p-0">
        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>


        <div class="alert alert-success alert-dismissible" *ngIf="newProjectManagerStatus">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="newProjectManagerStatus=''">×</button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{newProjectManagerStatus}}

          </p>
        </div>

        <div class="alert alert-success alert-dismissible" *ngIf="deletedProjMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
          <span class="iconLabel" aria-label="sucess">
          </span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{deletedProjMsg}}

          </p>
        </div>

        <div class="alert alert-success alert-dismissible" *ngIf="updateStatusProjectSuccessMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
          <span class="iconLabel" aria-label="sucess">
          </span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{updateStatusProjectSuccessMsg}}
          </p>
        </div>

        <form class="form-border mb-3 box collapse-view" [formGroup]="filterForm" autocomplete="off">
          <h2 class="h4 collapse-arrow" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse" href="#collapseFilter">Search</h2>
          <div class="filter-form-container filter-form-container-column2 search-filter collapse" [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">

            <div class="filter-form-control pr-4 typeahead-wrapper">
              <label for="Status">ID:</label>
              <input type="number" min="0" name="id" placeholder="Type to search" class="form-control" aria-invalid="false" formControlName="id" />

            </div>


              <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('Title').valid && (filterForm.get('Title').touched || filterForm.get('Title').dirty || isSubmitted)}">
                <span *ngIf="!filterForm.get('Title').valid && filterForm.get('Title').errors?.pattern && (filterForm.get('Title').touched || filterForm.get('Title').dirty  || isSubmitted)">
                  <i class="material-icons">warning</i>Please enter a valid Project Name</span>
                <fdl-type-ahead class="filter-typeahead" label="Project Name:" formControlName="Title" [collection]="projectSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                </fdl-type-ahead>
              </div>

              <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showClientFilter" [ngClass]="{'has-error': !filterForm.get('ClientTitleWithDuns').valid && (filterForm.get('ClientTitle').touched || filterForm.get('ClientTitle').dirty || isSubmitted)}">
                <span *ngIf="!filterForm.get('ClientTitleWithDuns').valid && filterForm.get('ClientTitleWithDuns').errors?.pattern && (filterForm.get('ClientTitleWithDuns').touched || filterForm.get('ClientTitleWithDuns').dirty  || isSubmitted)">
                  <i class="material-icons">warning</i>Please enter a valid Client Name</span>
                  <fdl-type-ahead class="filter-typeahead" label="Client Name/DUNS:" formControlName="ClientTitleWithDuns" [collection]="clientSuggestion" optionField="titleWithDuns" placeholder="Type to search" [scrollable]="true" [optionsLimit]="100" [minLength]="0"  (input)="typeaheadLoadingClients()">
                  </fdl-type-ahead>
              </div>
              <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showProductFilter" [ngClass]="{'has-error': !filterForm.get('ProductTitle').valid && (filterForm.get('ProductTitle').touched || filterForm.get('ProductTitle').dirty || isSubmitted)}">
                <span *ngIf="!filterForm.get('ProductTitle').valid && filterForm.get('ProductTitle').errors?.pattern && (filterForm.get('ProductTitle').touched || filterForm.get('ProductTitle').dirty  || isSubmitted)">
                  <i class="material-icons">warning</i>Please enter a valid Product Name</span>
                <fdl-type-ahead class="filter-typeahead" label="Product Name:" formControlName="ProductTitle" [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                [optionsLimit]="100" [minLength]="0" (input)="typeaheadLoadingProducts()">
                </fdl-type-ahead>
              </div>
              <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('FiservImplementationManagerName').valid && (filterForm.get('FiservImplementationManagerName').touched || filterForm.get('FiservImplementationManagerName').dirty || isSubmitted)}">
                <span *ngIf="!filterForm.get('FiservImplementationManagerName').valid && filterForm.get('FiservImplementationManagerName').errors?.pattern && (filterForm.get('FiservImplementationManagerName').touched || filterForm.get('FiservImplementationManagerName').dirty  || isSubmitted)">
                  <i class="material-icons">warning</i>Please enter a valid Project Manager</span>
                <fdl-type-ahead class="filter-typeahead" label="Project Manager:" formControlName="FiservImplementationManagerName" [collection]="userSuggestion" optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                </fdl-type-ahead>
              </div>

              <div class="filter-form-control pr-4">
                <label for="Status">Status:</label>
                <div class="fdl-select">
                  <select name="Status" class="form-control" aria-invalid="false" formControlName="Status">
                    <option value="">All</option>
                    <option *ngFor="let status of projectStatus" [value]="status.title">{{status.title}}</option>
                  </select>
                </div>
              </div>
              <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showOrganizationFilter" [ngClass]="{'has-error': !filterForm.get('OrganizationTitle').valid && (filterForm.get('OrganizationTitle').touched || filterForm.get('OrganizationTitle').dirty || isSubmitted)}">
                <span *ngIf="!filterForm.get('OrganizationTitle').valid && filterForm.get('OrganizationTitle').errors?.pattern && (filterForm.get('OrganizationTitle').touched || filterForm.get('OrganizationTitle').dirty  || isSubmitted)">
                  <i class="material-icons">warning</i>Please enter a valid Organization Name</span>
                <fdl-type-ahead class="filter-typeahead" label="Organization Name:" formControlName="OrganizationTitle" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search"
                  [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onOrgSelect($event)">
                </fdl-type-ahead>
              </div>
              <div class="buttom-wrapper">
                <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterList()">Search</button>
              </div>
              <div class="ml-2 buttom-wrapper" *ngIf="filtered">
                <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset" (click)="clearFilterList()">Reset</button>
              </div>
            </div>
</form>
        <div class="data-table-container">
          <div class="justify-content-between d-flex">
          <div class="headline-container">
            <h2 class="h4">Project List</h2>
            <p *ngIf="pagerObject">Total {{pagerObject.totalCount}} Projects</p>
          </div>

          <div class="d-flex">
            <div class="refresh-icon-link mt-1">
              <!-- add class of 'fa-rotation' on click of the refresh icon -->
              <a title="Refresh Project List" id="refreshTask" (click)="refresh()">
                <i aria-hidden="true" class="fa fa-refresh"></i>
              </a>
            </div>
            <div class="dropdown downloadDropdownMenuButton" *ngIf="showComment">
              <button *ngIf="showComment"
                      type="button" title="Download Extract" class="btn btn fdl-btn btn-primary-dark btn-addcomment"
                      id="downloadDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <span class="material-icons material-icons-wrapper">
                  get_app
                </span>
              </button>

              <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
                <a class="dropdown-item" (click)="downLoadType({'type':'xlsx'})">Export List</a>
              </div>
            </div>
            <app-prefernce-icon></app-prefernce-icon>
          </div>

          </div>
          <!-- <div class="filter-control-wrapper"> -->
            <!-- <div class="favorite-switcher-wrapper"  >

        <div class="fdl-switch fdl-2"  >

               <input id="switch2" name="switch2" type="checkbox" aria-labelledby="desc12">
               <label for="switch2">

               </label>
             </div>
             <label for="switch2" class="label-text cursor-pointer" >

        Show All Favorite Projects
    </label>
        </div> -->
             <!-- <div class="settings-wrapper">
       <div class="cta-view-all-action-btn-container">

         <a href="javascript:void(0);" data-toggle="modal" class="clickable" data-target="#columnSettings">
           Column Settings
           <span class="material-icons">navigate_next</span>
         </a>
       </div>
     </div>

          </div> -->
          <div class="table-responsive form-responces s-overflow-auto">
            <div>
              <app-data-table *ngIf="projectList"
                              [rows]="projectList.projects"
                              [keys]="keys"
                              [displayColumns]="displayColumns"
                              (buttonClicked)="navigate($event)"
                              (deleteBtnClicked)="navigate($event)"
                              (sortingClicked)="applySorting($event)"
                              [showCheckbox]="false"
                              [showViewEdit]="isEditProjectAllowed"
                              [showDelete]="false"
                              [canDeleteEntity]="canDeleteProject"
                              [canUpdateProjectStatus]="canUpdateProjectStatus"
                              [showViewProjectTimeline]="true"
                              type="Project"
                              [listname]="listname">
              </app-data-table>
            </div>

            <div *ngIf="pagerObject">
              <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && projectList && projectList.projects.length > 0" [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- <app-footer></app-footer> -->
</div>


<app-put-project-status (updateStatusSuccess)="onUpdateProjectStatusSuccess($event)" [data]="projectData" #putProjectStatus></app-put-project-status>
