import { Component, OnInit } from '@angular/core';
import { SubHeaderService } from '../../services/subHeader.service'
import { Observable } from 'rxjs';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html'
})
export class SubheaderComponent implements OnInit {

  navigateToMain: string ='Dashboard';
  navigateToSub: string ='';
  subModuleLabel: string ='Create New Form - Form Builder';
  subModuleHeading: string ='Administration';

  subHeader$ = new Observable();

  constructor(private subHeaderService: SubHeaderService) { }

  //currentStatusBar

  ngOnInit(): void {

    this.subHeader$ =this.subHeaderService.currentStatusBar;
    
  }

}
