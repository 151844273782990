import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FormsService } from 'src/app/services/forms.service';
import { FormBuilder, Validators, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IForms } from 'src/app/shared/form';
import { DataTableComponent } from 'src/app/SharedModule/Components/data-table/data-table.component';
import { REGEX_PATTERNS, MAX_LENGTHS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { DateFormatPipe } from 'src/app/SharedModule/Pipes/date-format.pipe';
import { ClientsService } from 'src/app/services/clients.service';
import { FileValidators } from 'src/app/self-service/components/manageFileUploads/validators/file-validators';
import { forkJoin } from 'rxjs';
import { MasterService } from 'src/app/services/master.service';
import { MutipleaddComponent } from '../../../SharedModule/Components/mutipleadd/mutipleadd.component';
import { LoaderService } from '../../../loader/loader.service';
import { ProjectsDTOService } from 'src/app/Projects/Services/projectsDTO.service';
import { forEachChild } from 'typescript';
import { take } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-edit-forms',
  templateUrl: './edit-forms.component.html',
  providers: [DateFormatPipe]
})
export class EditFormsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() projectId: number;
  @Input() assignedFormsList: any = [];
  @Output() editFormProgess = new EventEmitter<any>()
  formsUsersDetails: any = {};
  updatedProjectData: any = {};
  isError: boolean = false;
  formSets: any = [];
  currentSet: number = 0;
  assignedForms: IForms;
  errorMsg: string;
  isSubmitted: boolean;
  dueDateMsg: string = '';
  filedJson: any;
  dummyJson: any;
  filedformApproverJson: any;
  guestUserForm: any;
  filedDetails: any;
  filedformApproverDetails: any;
  guestfieldJson: any;
  @ViewChild('selectedRows') allRowsSelection: DataTableComponent;
  @ViewChildren('templateInput') templateInput: QueryList<ElementRef>;
  columns = [{ prop: 'title', name: 'Form Name', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.form_response_survey_name_validator }, width: 500 },
  { prop: 'description', name: 'Description', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.description_validator }, width: 500 }
  ]
  _maxLength: any;
  currentIndex;
  selectForms: boolean;
  bulkRequest;
  msgTitle: string;
  userRequest;
  productSelection: boolean = false;
  initiateProjId: number = -1; //blank -1, incorrect -1
  ResponderApproverList;
  prodFormsList;
  clientUsersList;
  initiateDGF: boolean = false;
  initiateDGF_clientId: number;
  approverUserId: number = null;
  templateName: string;
  clientId: number;
  nonuserGroupRequest;
  alreadyAssignedForms: any = [];
  group_id: number = 0;
  respondentIndex: number = 0;
  approverIndex: number = 0;
  regexValidationMessage: any;
  validFile: boolean = false;
  validFileType: boolean = true;
  validFileName: boolean = true;
  todaysdate: any;
  displayColumnsEditResponse = ["Form Name", "Client Name"];
  keysEditResponse = ['title', 'clientTitle'];
  msgSuccess: string;


  @ViewChildren('addResponder') addResponder: QueryList<MutipleaddComponent>;
  @ViewChildren('addApprover') addApprover: QueryList<MutipleaddComponent>;
  @ViewChildren('addGuest') addGuest: QueryList<MutipleaddComponent>;

  constructor(private _formService: FormsService,
    private _router: Router,
    private _projectDto: ProjectsDTOService,
    private _projectService: ProjectsService,
    public _userService: UserService,
    private _utilService: UtilService,
    private _drawerService: ProjectDrawerService,
    private _permissionService: PermissionsService,
    private _datePipe: DateFormatPipe,
    private _clientService: ClientsService,
    private _masterService: MasterService,
    private loaderService: LoaderService,
    private fb: FormBuilder, private route: ActivatedRoute) { }

  public ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {   

    this.getUserData();
    this.todaysdate = this._projectService.getTodaysDate();
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    
    this.filedJson = {
      'json': {
        name: ['', this.formValidators["formResponder"]],
        emails: '',
        id: ''
      }
    }

    this.filedformApproverJson = {
      'json': {
        name: [''],
        emails: '',
        id: '',
        level: ['1']
      }
    }
    this.guestfieldJson = {
      'json': {
        'emailAddress': ['', this.formValidators["emailAddress"]],
        'firstName': ['', this.formValidators["firstName"]],
        'lastName': ['', this.formValidators["lastName"]],
      }
    }

    this.filedDetails = [{ 'name': 'name', 'type': 'fdl', 'label': 'Form Respondent', 'isRequired': true, 'fdldata': this.formsUsersDetails.usersData, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select Respondent.' } },
    { 'name': 'emails', 'type': 'emailshow', 'isDisabled': 'yes', 'label': 'Email', }, { 'name': 'id', 'type': 'fdlid' },
    ]
    this.filedformApproverDetails = [{
      'name': 'name', 'type': 'fdl', 'label': 'Approver', 'isRequired': false, 'fdldata': this.formsUsersDetails.usersData, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select an Approver.' }
      
    },
    { 'name': 'emails', 'type': 'emailshow', 'isDisabled': 'yes', 'label': 'Email', }, { 'name': 'id', 'type': 'fdlid' }, { 'name': 'level', "className": "col-md-4", 'label': "Level", "erroeMessage": { 'valid': 'Please enter Approver level.' }, 'type': 'text', 'isRequired': false, }]

    this.guestUserForm = [
      { 'name': 'emailAddress', 'type': 'email', 'label': 'Respondent Email', 'isRequired': true, "className": "col-md-11", "erroeMessage": { 'valid': this.regexValidationMessage.EMAIL_VALIDATION_MESSAGE } },
      { 'name': 'firstName', 'type': 'text', 'label': 'Respondent First Name', 'isRequired': true, "className": "col-md-11", "erroeMessage": { 'valid': this.regexValidationMessage.USERNAME_VALIDATION_MESSAGE } },
      { 'name': 'lastName', 'type': 'text', 'label': 'Respondent Last Name', 'isRequired': true, "className": "col-md-11", "erroeMessage": { 'valid': this.regexValidationMessage.USERNAME_VALIDATION_MESSAGE } }]

    /****************** Begin Initiate DGF ****************/
    this.productSelection = true;
    this.initiatingAnonymousFormGroup();
    this.noClientProjectSelection();
    this._projectService.clientVisibility$.subscribe(clientId => {
      this.initiateDGF_clientId = clientId;
      this.formSets.length = 0;
      this.currentSet = 0
      this.initiatingAnonymousFormGroup();
      if (this.initiateDGF_clientId > 0) {
        this._projectDto.setInitiateDgfData({ clientId: this.initiateDGF_clientId });
        return this.clientRespApproverApi(this.initiateDGF_clientId, this._formService.formsUsersData())
      }
      else if (this.initiateDGF_clientId === -1) {
        this.noClientProjectSelection();
      }
      else {
        this._projectService.projVisibility$.subscribe(projectId => {
          this.initiateProjId = projectId;
          this.formSets.length = 0;
          this.currentSet = 0
          this.initiatingAnonymousFormGroup();
          if (this.initiateProjId > -1) {
            this._projectService.getProjectById(this.initiateProjId).subscribe(
              project => {
                const projectDetails = project;
                if (projectDetails && projectDetails.clientId) this._projectDto.setInitiateDgfData(projectDetails);
                return this.apiToGetFormsUsers(projectDetails);
              }
            )
          }
          else {
            this.noClientProjectSelection();
          }
        });
      }
    })
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.projectId = params['id'];
        this._projectService.getProjectById(this.projectId).subscribe(
          project => {
            const projectDetails = project;
            this.apiToGetFormsUsers(projectDetails);
          }
        )
      });
  }

  requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  /****************** Begin Initiate DGF ****************/
  initiatingAnonymousFormGroup() {
    this.formSets[this.currentSet] = {
      "title": 'Form Set' + " " + (this.currentSet + 1),
      "assignedFormsList": this.assignedFormsList,
      "selectedUnauthenticatedType": 'user',
      "assignForm": this.fb.group({
        'unauthenticatedType': ['user'],
        'approverSchema': ['OR'],
        'formResponder': this.fb.array([]),
        'formApprover': this.fb.array([]),
        'guestResponder': this.fb.array([]),
        'dueDate': this.todaysdate
      },
      )
    }
  }

  /********** BEGIN BLANK/WRONG project & Client **********/
  noClientProjectSelection() {
    this.productSelection = true;
    let initiateApiData = this._formService.formsUsersData();
    this.formsUsersDetails.forms = initiateApiData?.orgForms?.forms;
    this.formsUsersDetails.users = initiateApiData.orgUsers;
    // this.formsUsersDetails.usersData = { 'data': initiateApiData.orgUsers };
    this.formsUsersDetails.emailGroup = initiateApiData.emailGroup;
  }
  /********** END BLANK/WRONG project & Client **********/

  clientRespApproverApi(clientId, b) {
    this._clientService.getClientDetailsById(clientId, "respondents").subscribe(
      usersByClient => {
        this.clientUsersList = usersByClient;
        const allUsers = (this.clientUsersList !== undefined) ? b.orgUsers.concat(this.clientUsersList) : b.orgUsers;
        this.ResponderApproverList = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);
        this._formService.formsUsersDetail({ orgForms: b.orgForms, prodForms: b.prodForms, allUsers: this.ResponderApproverList, orgUsers: b.orgUsers, emailGroup: b.emailGroup });
        this.formsUsersDetails.users = this._formService.formsUsersData().allUsers;
        this.formsUsersDetails.usersData = { 'data': this._formService.formsUsersData().allUsers };
      })
  }


  apiToGetFormsUsers(projectDetails) {
    /*let b = this._formService.formsUsersData();*/
    if (projectDetails.productId && projectDetails.productId !== null) {
      this.productSelection = false;
      //this._formService.getFormsByproductId(projectDetails.productId, projectDetails.clientId).subscribe(
      //  formsByProduct => {
      //    this.prodFormsList = formsByProduct;
      //    this._formService.formsUsersDetail({ orgForms: b.orgForms, prodForms: this.prodFormsList, orgUsers: b.orgUsers, emailGroup: b.emailGroup });
      //    this.formsUsersDetails.forms = this._formService.formsUsersData().prodForms?.forms;
      //  })
      this._formService.getRespondentsUsersByProductId(0, projectDetails.productId).subscribe(
        data => {
          this.formsUsersDetails.usersData = { 'data': data };
        },
        error => {
          console.log(error);
        }
      );
    }
    else {
      this.productSelection = true;
      this.formsUsersDetails.forms = this._formService.formsUsersData().orgForms?.forms;
    }
    /****************** End Initiate DGF ****************/
  }

  clearTemplateInput(i) {
    this.formSets[i].assignForm.get('formTemplate').reset();
    this.formSets[i].templateName = "";
    if (this.templateInput.find((element, index) => index === i)) {
      this.templateInput.find((element, index) => index === i).nativeElement.value = '';
    }
  }

  viewLink() {
    $('#emailGroupMembers').modal('show');
  }

  /********** For removing a particular formset **********/
  closeFormset(formsetToBeRemoved) {
    this.currentSet--;
    this.formSets.splice(formsetToBeRemoved, 1);
    this.formSets.map((formset, index) => {
      return formset.title = "Form Set " + (index + 1);
    });
  }

  /********** For downloading form template file **********/
  downloadTemplate() {
    let link = document.createElement("a");
    link.setAttribute('type', 'hidden');
    link.download = "Bulk Data Gathering Form Publish Template.xlsx";
    link.href = "assets/Bulk Data Gathering Form Publish Template.xlsx";
    link.click();
    link.remove();
  }

  validation(controlName: string, values: FormArray): ValidationErrors {

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      //  return { 'duplicate': true };

      let array: any = [];

      if (controlName != 'formApprover' && controlName != 'formResponder' && controlName != 'guestResponder') {


        return null;
      }
      for (let k in values[controlName]) {
        let keyName = '';
        let fieldValue = values[controlName][k];
        if (controlName == 'formApprover') {
          //keyName = fieldValue.id + '##' + fieldValue.level;
          if (fieldValue.level > 0)
            keyName = fieldValue.id + '##' + fieldValue.level;
          else
            return { 'invalid': true };
        } else if (controlName == 'formResponder') {
          keyName = fieldValue.id
        } else if (controlName == 'guestResponder') {
          keyName = fieldValue.emailAddress
          keyName = keyName.toLocaleLowerCase();
        } else {
          return null;
        }


        if (fieldValue.name != '') {
          if (array[keyName])
            array[keyName].push({ "index": k });
          else {
            array[keyName] = [];
            array[keyName].push({ "index": k });


          }


        }
      }
      for (let i in array) {
        if (array[i].length > 1) {
          return { 'duplicate': true };
        }
      }
      return null;
    }

  }
  reEvaluateValidators(fieldName, setNo, isNested = 'no') {
    if (isNested != 'no') {
      if (this.formSets[setNo].assignForm.controls[isNested]) {
        let fieldCtrlParent = this.formSets[setNo].assignForm.controls[fieldName]
        if (fieldCtrlParent && fieldCtrlParent.controls && fieldCtrlParent.controls[fieldName].length > 0) {

          fieldCtrlParent.setValidators(this.validation(fieldName, this.formSets[setNo].assignForm ? this.formSets[setNo].assignForm.get(fieldName).value : []));
        }
        let fieldCtrlArray = this.formSets[setNo].assignForm.controls[isNested].controls[isNested].controls;
        for (let m in fieldCtrlArray) {
          let fieldCtrl = fieldCtrlArray[m].controls[fieldName]
          fieldCtrl.setValidators(this.formValidatorsDynamic(fieldName, setNo));
          fieldCtrl.updateValueAndValidity();
        }

      }

    } else {
      const fieldCtrl = this.formSets[setNo].assignForm.controls[fieldName];
      fieldCtrl.setValidators(this.formValidatorsDynamic(fieldName, setNo));
      if (fieldName != 'formTemplate')
        fieldCtrl.setValidators(this.validation(fieldName, this.formSets[setNo].assignForm ? this.formSets[setNo].assignForm.get(fieldName).value : []));
      fieldCtrl.updateValueAndValidity();
    }
  }

  /********** On manually adding/removing the value from Respondent users **********/
  respondentEmailValidator(control: AbstractControl): { [key: string]: boolean } {

    return;

  }

  /********** On manually adding/removing the value from Respondent users **********/
  approverEmailValidator(control: AbstractControl): { [key: string]: boolean } {

    return;

  }

  formValidators = {
    formResponder: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "user"), this.respondentEmailValidator.bind(this)],
    emailAddress: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
    firstName: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
    lastName: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
    formTemplate: [this.requiredIfValidator(() => this.formSets[this.currentSet].assignForm.get('unauthenticatedType').value === "bulk"), FileValidators.checkAllowedExtensions(['xlsx'])]
  }

  formValidatorsDynamic(fieldName, setNo) {
    let formValidators = {
      formResponder: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "user"), this.respondentEmailValidator.bind(this)],
      emailAddress: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
      firstName: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      lastName: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      formTemplate: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "bulk"), FileValidators.checkAllowedExtensions(['xlsx'])]
    }

    return formValidators[fieldName];

  }

  /**Update For Valida*/
  updateFormValidators(setNo) {
    this.formValidators = {
      formResponder: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "user"), this.respondentEmailValidator.bind(this)],
      emailAddress: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(MAX_LENGTHS.User.Email)],
      firstName: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      lastName: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "nonuser"), Validators.pattern(REGEX_PATTERNS.UserName), Validators.maxLength(MAX_LENGTHS.User.Name)],
      formTemplate: [this.requiredIfValidator(() => this.formSets[setNo].assignForm.get('unauthenticatedType').value === "bulk"), FileValidators.checkAllowedExtensions(['xlsx'])]
    }
    switch (this.formSets[setNo].assignForm.get('unauthenticatedType').value) {
      case 'user':
        this.reEvaluateValidators('formResponder', setNo);
        this.reEvaluateValidators('formApprover', setNo);
        break;
      case 'nonuser':
        this.reEvaluateValidators('guestResponder', setNo);
        this.reEvaluateValidators('formApprover', setNo);
        break;
    }

  }

  /**********Removing validations on change of radio button selection **********/
  removeValidators(formGrup, index) {
    for (const key in formGrup.controls) {
      formGrup.get(key).clearValidators();
      formGrup.get(key).updateValueAndValidity();
      if (key === "formTemplate") {
        this.clearTemplateInput(index);
      }
    }
  }

  /********** For revaluation of the validations applied in formFields **********/
  setUnauthenticatedType(drmsType, index) {
    this.isSubmitted = false;
    this.formSets[index].respondentEmail = '';
    this.formSets[index].approverEmail = '';
    this.formSets[index].approverSchema = 'OR';

    this.formSets[index].assignForm.reset({
      'unauthenticatedType': this.formSets[index].assignForm.get('unauthenticatedType').value,
      'formRespondentEmailGroup': "",
      'approverSchema': 'OR'
    })
    this.removeValidators(this.formSets[index].assignForm, index);
    switch (drmsType) {
      case 'user':
        this.reEvaluateValidators('formResponder', index);
        this.reEvaluateValidators('formApprover', index);
        //  this.approverAndResponderValidator('formResponder', 'formApprover', index)
        break;
      case 'nonuser':
        this.reEvaluateValidators('emailAddress', index, 'guestResponder');
        this.reEvaluateValidators('firstName', index, 'guestResponder');
        this.reEvaluateValidators('lastName', index, 'guestResponder');
        break;
    }
  }

  /********** on change of radio button selection **********/
  settingUnauthenticatedSurveyType(index) {
    this.group_id = index;
    this.formSets[index].selectedUnauthenticatedType = this.formSets[index].assignForm.get('unauthenticatedType').value;

    this.setUnauthenticatedType(this.formSets[index].assignForm.get('unauthenticatedType').value, index);

  }

  /********** it will navigate on success of API & and on click of Cancel **********/
  cancel() {
    $('#editFormModal').hide();
  }

  /********** Due Date Validation Check **********/
  dueDateValidation(setNo, date) {
    const dateObj = new Date(date).toLocaleDateString();
    let due_date = this._utilService.isValidDate(dateObj);
    if (!due_date) {
      this.msgTitle = this.formSets[setNo].title;
      this.dueDateMsg = "Due date is mandatory and cannot be in the past for " + "<b>" + this.msgTitle + "</b>";
      return false;
    }
    else return true;
  }

  /*******All Sets Validation */
  allSetValidation() {
    for (let p = 0; p < this.formSets.length; p++) {
      if (this.addApprover['_results'][p]) {
        this.addApprover['_results'][p].isErrorOn();
      }
      if (this.addResponder['_results'][p]) {
        this.addResponder['_results'][p].isErrorOn();

      }
    }
  }

  resetError() {
    this.isError = false;
    this.errorMsg = "";
  }

  /********** on click of Submit **********/
  submitForm() {
    this.resetError();
    this.isSubmitted = true;
    this.userRequest = null;
    this.selectForms = false;
    this.dueDateMsg = '';
    let userResponses: any = [];
    let approvers: any = [];
    let respondents: any = [];
    this._projectService.isCopyForms = false;
    this._projectService.selectedProjectCopyForms = 0;
    let approverUserId: any = [];
    let approverEmail: any = [];
    let approverEmailId: any = [];
    

    let recipients: any = [];

    /********** iterate through no.of formsets created **********/
    for (let p = 0; p < this.formSets.length; p++) {
      if (this.formSets[p].assignedFormsList.length === 0) {
        this.msgTitle = this.formSets[p].title;
        this.selectForms = true;
        this.isSubmitted = false;
        return false;
      }
      

      if ((this.addApprover['_results'][p] && !this.addApprover['_results'][p].isInputValid())) {
        this.allSetValidation();
      }
      if ((this.addResponder['_results'][p] && !this.addResponder['_results'][p].isInputValid())) {
        this.allSetValidation();
      }

      /********** checking validity of Reactive form for each formset **********/
      if (!this.formSets[p].assignForm.valid) {
        this.isSubmitted = true;
        this.allSetValidation();
        return false;
      }
      else {
        /********** iterate through no.of forms selected **********/
        for (let i in this.formSets[p].assignedFormsList) {
          this.clientId = this.updatedProjectData.clientId || 0;

          /******* for user, API Request *******/
          if (this.formSets[p].selectedUnauthenticatedType === 'user') {
           
            const formRespondentValue = this.formSets[p].assignForm.value['formResponder']['formResponder'];
            const formApproverValue = this.formSets[p].assignForm.value['formApprover']['formApprover'];
            respondents = this.getResponderArray(formRespondentValue);
            approvers = this.getApproverArray(formApproverValue);
            this.formSets[p].assignedFormsList[i].dueDate = this.formSets[p].assignForm.value.dueDate;
            this.formSets[p].assignedFormsList[i].approvalSchema = this.formSets[p].assignForm.value.approverSchema;
            this.formSets[p].assignedFormsList[i].respondents = respondents;
            this.formSets[p].assignedFormsList[i].approvers = approvers;
            this.formSets[p].assignedFormsList[i].assignedToName = this.getAssignedToName(formRespondentValue);
            this.formSets[p].assignedFormsList[i].currentApproverName = this.getCurrentApproverName(formApproverValue);;

            userResponses.push(this.formSets[p].assignedFormsList[i]);

          }
          if (this.formSets[p].selectedUnauthenticatedType === 'nonuser') {
            debugger;
              approverUserId = this.getApproverArray(this.formSets[p].assignForm.value['formApprover']['formApprover'])

              approverEmail = this.getApproverArray(this.formSets[p].assignForm.value['formApprover']['formApprover'])/* Validation method for due date */
              approverEmailId = null;
              if (approverEmail[0]) {
                approverEmailId = approverEmail[0].emailAddress;
            }
              
            //if (!this.dueDateValidation(p, this.formSets[p].assignedFormsList[i].dueDate)) {
            //  return;
            //}

            const formApproverValue = this.formSets[p].assignForm.value['formApprover']['formApprover'];
            recipients= this.getGuestrArray(this.formSets[p].assignForm.value['guestResponder']['guestResponder'])
            let currentAssignlist: any = '';
            let currentApproverlist: any = '';
            for (var ii = 0; ii < recipients.length; ii++) {
              let counter = ii + 1;
              currentAssignlist = currentAssignlist + counter + "-" + recipients[ii].firstName + " " + recipients[ii].lastName + " ; " ;
            }

            for (var ii = 0; ii < formApproverValue.length; ii++) {
              debugger;
              if (formApproverValue[0].name != "") {
                let counter = ii + 1;
                currentApproverlist = currentApproverlist + formApproverValue[ii].level + "-" + formApproverValue[ii].name + " ; ";
              }
            }

            this.formSets[p].assignedFormsList[i].dueDate = this.formSets[p].assignForm.value.dueDate;
            this.formSets[p].assignedFormsList[i].approvalSchema = this.formSets[p].assignForm.value.approverSchema;
            this.formSets[p].assignedFormsList[i].recipients = recipients;
            this.formSets[p].assignedFormsList[i].respondents = recipients;
            this.formSets[p].assignedFormsList[i].approvers = approverEmail;
            this.formSets[p].assignedFormsList[i].assignedToId = 0;
            this.formSets[p].assignedFormsList[i].assignedToName = currentAssignlist.slice(0, currentAssignlist.length - 2);
            this.formSets[p].assignedFormsList[i].currentApproverName = currentApproverlist.slice(0, currentApproverlist.length - 2);

            userResponses.push(this.formSets[p].assignedFormsList[i]);

          }
        }
      }

      this.editFormProgess.emit(userResponses);
      $("#editFormModal").modal('hide');
    }
  }

  getApproverArray(approverTemp: any) {
    let approvers = [];
    for (let k in approverTemp) {
      if (approverTemp[k].id != '')
        approvers.push({
          "approverId": approverTemp[k].id,
          "level": approverTemp[k].level != 0 && approverTemp[k].level != '' ? approverTemp[k].level : 1,
          "email": approverTemp[k].emails
        })
    }
    return approvers;
  }

  getResponderArray(reponderTemp: any) {
    let responders = [];
    for (let k in reponderTemp) {
      responders.push({
        'assignedToId': reponderTemp[k].id
      })
    }
    return responders;
  }

  getGuestrArray(guestTemp: any) {

    let guestTempUserId = [];
    for (let k in guestTemp) {

      guestTempUserId.push({
        "firstName": guestTemp[k].firstName,
        "lastName": guestTemp[k].lastName,
        "email": guestTemp[k].emailAddress,
      })
    }
    return guestTempUserId;

  }

  getAssignedToName(reponderTemp: Array<any>) {
    const array = [];
    reponderTemp.forEach(element => {
      array.push(element.name);
    });
    const result = array.join(', ');
    return result;
  }

  getCurrentApproverName(approverTemp: Array<any>) {
    const array = [];
    if (approverTemp[0].name != "") { 
    approverTemp.forEach(element => {
      array.push(`${element.level}-${element.name}`);
    });
    }
    const result = array.join(', ');
    return result;
  }

  /**********DRAWER CHANGES END **********/
  formInitialized(name: string, formSet: any, form: any) {
    this.formSets[formSet].assignForm.setControl(name, form);
    this.updateFormValidators(formSet);
  }

  getUserData() {
    const formUsers = this._formService.formsUsersData().users;
    this.formsUsersDetails.usersData = { 'data': formUsers };
  }

  getDueDate() {
    return this._projectService.getTodaysDate();
  }

  checkDate(dueDate) {
    if (dueDate.value < this.todaysdate) {
      this.formSets.assignForm.controls['comments'].invalid;
      return false;
    }
    else {
      this.formSets.assignForm.controls['comments'].valid;
      return true;
    }
  }

  onError(): void {
    this.msgSuccess = '';
    this.editFormProgess.emit(false);
  }

  ngOnDestroy(): void {
    this.assignedFormsList = [];
  }

}
