import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ProjectsDTOService } from '../../Services/projectsDTO.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormsService } from 'src/app/services/forms.service';
import { ClientsService } from 'src/app/services/clients.service';
import { forkJoin, Subject } from 'rxjs';
import { IRespondent } from 'src/app/shared/user';
import { ProjectsService } from 'src/app/services/projects.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';
import { takeUntil } from 'rxjs/operators';
declare var $: any;
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnonymousSurveyComponent } from '../anonymous-survey/anonymous-survey.component';
import { REGEX_PATTERNS } from 'src/app/core/constants';

@Component({
  selector: 'app-publish-forms',
  templateUrl: './publish-forms.component.html',
  styleUrls: ['./publish-forms.component.css']
})
export class PublishFormsComponent implements OnInit, AfterViewInit, OnDestroy {
  userDetails: any = {};
  updatedProjectData: any = {};
  projectSuggestion: any = {};
  clientSuggestion: any = {};
  usersByOrganization: any = [];
  usersByClient: any = [];
  ResponderApproverList: IRespondent;
  isError: boolean;
  errorMsg: string;
  formsList: any;
  // emailGroup: any;
  loadDrawer: boolean = false;
  showSuccessMsg: boolean = false;
  private destroySubject = new Subject();
  childComponent;/* Not in use */
  successMsg: string;
  orgFormsList: any;
  prodFormsList: any
  projectId: number = -1;
  clientId: number = 0;
  publishForm: FormGroup
  clientUsersList: any;
  orgUsersList: any;
  isInitiateDGF: boolean = false;
  isClientDisabled: boolean = false;
  isProjectDisabled: boolean = false;
  formsetDetails: boolean = false;
  @ViewChild(AnonymousSurveyComponent) surveyComponent: AnonymousSurveyComponent;
  client_loadDrawer: boolean = false
  clientNameFromDrawer: string;
  createClientAllowed: boolean = false;
  clientText: string;
  selectedClientValue: string = '';
  invalidClientName: boolean = false;

  public drawerType = false;

  constructor(private _userService: UserService,
    private _projectDto: ProjectsDTOService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formService: FormsService,
    private _clientService: ClientsService,
    private _projectService: ProjectsService,
    private _permissionService: PermissionsService,
    private _drawerService: ProjectDrawerService) { }

  ngOnInit(): void {

    if (this._router.url.includes('projects/edit/publish-forms')) {
      this.drawerType = true;
    } else {
      this.drawerType = false;
    }

    this.createClientAllowed = this._permissionService.isCreateClientAllowed();
    /********* Creating Form Group *********/
    this.publishForm = new FormGroup({
      'projectName': new FormControl(''),
      'clientName': new FormControl('', Validators.pattern(REGEX_PATTERNS.client_name_validator))
    });

    this.publishForm.get('projectName').valueChanges.subscribe(
      (value) => {
        value = value === null || value === "null" ? '' : value;
        this._projectDto.setInitiateDgfData('');
        if (value) {
          this.isClientDisabled = true;
          this.projectId = this._projectService.fetchIdForRequest(this.projectSuggestion, value);
          this._projectService.setProjVisibility(this.projectId);
        }
        else {
          this.isClientDisabled = false;
          this._projectService.setProjVisibility(-1);

        }
      });

    this.publishForm.get('clientName').valueChanges.subscribe(
      (value) => {
        value = value === null || value === "null" ? '' : value;
        this._projectDto.setInitiateDgfData('');
        if (value) {
          this.isProjectDisabled = true;
          this.clientId = this._projectService.fetchIdForRequest(this.clientSuggestion, value);
          this._projectService.clientVisibility(this.clientId);
        }
        else {
          this.isProjectDisabled = false;
          this._projectService.clientVisibility(0);
        }
      });

    //*********End Form Group *********/


    /**** to fetch organization from user/me api ****/
    this.userDetails = this._userService.getUserListData();

    this.updatedProjectData = this._projectDto.getProjectFacts();
    if (Object.keys(this.updatedProjectData).length === 0 && this.updatedProjectData.constructor === Object) {
      this.updatedProjectData = '';
    }

    if (this._router.url.indexOf('initiateDGF') !== -1 && !this.updatedProjectData) {
      /********* Initiate DGF ****************/
      this.isInitiateDGF = true;
      this.apiData_initiateSurvey();
    }
    else {
      /********** Assign Form Section *************/
      this.apiData_survey();
    }
  }

  apiData_initiateSurvey() {
    let initiateDGF_api: any = [];
    let params = [];
    initiateDGF_api = [this._projectService.getSuggestions("projects", "")];
    if (this.clientText && this.clientText.length >= 3) {
      params.push({ paramName: "title", value: this.clientText });
      initiateDGF_api.push(this._projectService.getClientSuggestions("clients", params, 100));
    }
    else {
      initiateDGF_api.push(this._projectService.getClientSuggestions("clients", null, 100));
    }

    // initiateDGF_api.push(this._projectService.getSuggestions("emailgroups", ""));
    initiateDGF_api.push(this._formService.getUsersByOrganizationId(this.userDetails.organization));
    initiateDGF_api.push(this._formService.getFormsByOrganizationId(this.userDetails.organization, null));
    forkJoin(initiateDGF_api).subscribe(data => {
      this.projectSuggestion = data[0];
      this.clientSuggestion = data[1];
      // this.emailGroup = data[2];
      this.orgUsersList = data[2];
      this.orgFormsList = data[3]

      /******** Setting API Data into Service to be used by Child Component ********/
      this._formService.formsUsersDetail({ orgForms: this.orgFormsList, prodForms: this.prodFormsList, orgUsers: this.orgUsersList/*, emailGroup: this.emailGroup*/ });

      /********* Loading of Component for formset details *********/
      this.formsetDetails = true;
      // this.activate('anonymous');
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }



  apiData_survey() {
    let formsUsersApi: any = [];
    formsUsersApi = [this._formService.getUsersByOrganizationId(this.userDetails.organization)];
    // formsUsersApi.push(this._projectService.getSuggestions("emailgroups", ""));
    if (this.updatedProjectData.productId && this.updatedProjectData.productId !== null) {
      formsUsersApi.push(this._formService.getFormsByOrganizationId(this.userDetails.organization, null));
    }
    else if (this.userDetails) {
      formsUsersApi.push(this._formService.getFormsByOrganizationId(this.userDetails.organization, this.updatedProjectData.clientId));
    }
    if (this.updatedProjectData.clientId && this.updatedProjectData.clientId !== null) {
      formsUsersApi.push(this._clientService.getClientDetailsById(this.updatedProjectData.clientId, "respondents"));
    }

    let projectData = this.updatedProjectData;
    let projectFormId = this.updatedProjectData.responses.map(({ formId }) => formId)

    forkJoin(formsUsersApi).subscribe(data => {
      this.usersByOrganization = data[0];
      // this.emailGroup = data[1];
      let response = data[1];
      this.usersByClient = data[2];

      const allUsers = (this.usersByClient !== undefined) ? this.usersByOrganization.concat(this.usersByClient) : this.usersByOrganization;
      this.ResponderApproverList = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);

      this.formsList = Object.assign({}, response);

      if (projectData.sameForms == "No") {
        this.formsList.forms = this.formsList.forms.filter(({ id }) => !projectFormId.includes(id));
      }

      /******** Setting API Data into Service to be used by Child Component ********/
      this._formService.formsUsersDetail({ forms: this.formsList, users: this.ResponderApproverList/*, emailGroup: this.emailGroup*/ });

      /********* Loading of Component for formset details *********/
      this.formsetDetails = true;
      // this.activate('normal');
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }


  /********************DRAWER CHANGES BEGIN*************************/
  onRouterOutletActivate(event: any) { /* Not in use */
    this.childComponent = event;
  }

  setClientName() {
    if (this.clientNameFromDrawer) {
      this.publishForm.controls['clientName'].setValue(this.clientNameFromDrawer);
      this.clientNameFromDrawer = "";
    }
  }

  closeUserClientDrawer() {
    this._drawerService.closeDrawer$.
      pipe(
        takeUntil(this.destroySubject)
      ).
      subscribe(postData => {
        if (postData) {
          if (this.client_loadDrawer) {
            this.client_loadDrawer = false;
            if (postData['submitPressed']) {
              this.successMsg = `Client has been added successfully.`;
              this.showSuccessMsg = true;
              let params = [];
              let clientSugg;
              if (this.clientText && this.clientText.length >= 3) {
                params.push({ paramName: "titleWithDuns", value: this.clientText });
                clientSugg = this._projectService.getClientSuggestions("clients", params, 100);
              }
              else {
                clientSugg = this._projectService.getClientSuggestions("clients", null, 100);
              }
              clientSugg.subscribe(
                data => {
                  this.isError = false;
                  this.clientSuggestion = data;
                  this.setClientName();
                },
                error => { this.isError = true, this.errorMsg = error }
              );

              if (postData['entityName']) { }
              this.clientNameFromDrawer = postData['entityName'];
            }
          }
          else {
            $('body').removeClass("overflow-hidden");
            this.loadDrawer = false;
            if (postData['submitPressed']) {
              this.showSuccessMsg = true;
              const tempData = postData['additionalData'];
              let keyword = 'Form Respondent';
              if (tempData && tempData['ctrlName'] && tempData['ctrlName'].toLowerCase().indexOf('approver') > -1) {
                keyword = 'Approver';
              }
              this.successMsg = `The User is created successfully and added to the Respondent/Approver dropdown list.`;
              //if (this.surveyComponent)
              //  this.surveyComponent.setFormControlValue(tempData['index'], tempData['ctrlName'], postData['entityName'], postData['entityEmail']);
              //
              this.refreshApproverResponderList();
            }
          }
        }
      });
  }
  ngAfterViewInit() {
    const isUserCreateAllowed = this._permissionService.isCreateUserAllowed();
    if (this.createClientAllowed || isUserCreateAllowed) {
      this.closeUserClientDrawer();
    }
    if (isUserCreateAllowed) {
      this.subscribeDrawerService();
    }
  }

  getClientSuggestions() {
    let suggestionSubscriber;
    if (this.clientText && this.clientText.length >= 3) {
      let params = [];
      params.push({ paramName: "title", value: this.clientText });
      suggestionSubscriber = this._projectService.getClientSuggestions("clients", params, 100);
    }
    else {
      suggestionSubscriber = this._projectService.getClientSuggestions("clients", null, 100);
    }

    suggestionSubscriber.subscribe(
      data => {
        this.clientSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );

  }
  typeaheadLoadingClients() {
    if (this.clientText.length >= 3) {
      this.getClientSuggestions();
    }
  }

  private async subscribeDrawerService() {
    this._drawerService.openDrawer$.
      pipe(
        takeUntil(this.destroySubject)
      ).
      subscribe(data => {
        if (data) {
          $('body').addClass("overflow-hidden");
          $(window).scrollTop(45);
          this.loadDrawer = true;
          let tempdata = {};
          this.updatedProjectData = this.isInitiateDGF ? this._projectDto.getInitiateDgfData() : this._projectDto.getProjectFacts();

          if (this.updatedProjectData && this.updatedProjectData.clientId && this.updatedProjectData.clientId !== null) {
            tempdata['isFiservOnly'] = false;
            tempdata['clientId'] = this.updatedProjectData.clientId;
            tempdata['clientTitle'] = this.updatedProjectData.clientTitle;
            tempdata['clientTitleWithDuns'] = this.updatedProjectData.clientTitleWithDuns;
          }
          else {
            tempdata['isFiservOnly'] = true;
          }
          this._userService.tempData = tempdata;
        }
      });
  }
  ngOnDestroy() {
    this.formsetDetails = false;
    if ($('body').hasClass("overflow-hidden")) {
      $('body').removeClass("overflow-hidden");
    }
    this._userService.tempData = null;
    this._projectDto.setInitiateDgfData('');
    this._drawerService.clear();
    this.destroySubject.next();
    this.destroySubject.complete();
    this.selectedClientValue = '';
  }
  refreshApproverResponderList() {
    this.updatedProjectData = this.isInitiateDGF ? this._projectDto.getInitiateDgfData() : this._projectDto.getProjectFacts();
    let formsUsersApi = [this._formService.getUsersByOrganizationId(this.userDetails.organization)];
    if (this.updatedProjectData && this.updatedProjectData.clientId && this.updatedProjectData.clientId !== null) {
      formsUsersApi.push(this._clientService.getClientDetailsById(this.updatedProjectData.clientId, "respondents"));
    }
    forkJoin(formsUsersApi).subscribe(data => {
      this.usersByOrganization = data[0];
      this.usersByClient = data[1];
      const allUsers = (this.usersByClient !== undefined) ? this.usersByOrganization.concat(this.usersByClient) : this.usersByOrganization;
      this.ResponderApproverList = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);
      this._formService.updateUsersList(this.ResponderApproverList);
      if (this.surveyComponent) {
        this.surveyComponent.updateUsers();
      }
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }
  /********************DRAWER CHANGES END*************************/


  /******** Identify the activation of Survey: Authenticated/UnAuthenticated *******/
  activate(route): void {
    this._router.navigate([route], { relativeTo: this._activatedRoute });
  }


  /******** On selection of Project:::: Initiate DGF ********/
  selectProject(projectData: any) {
    if (projectData.item && projectData.item.id) {
      this.projectId = projectData.item.id;
      this.isClientDisabled = true;
      this._projectDto.setInitiateDgfData('');
      this._projectService.setProjVisibility(this.projectId);
    }
  }

  /******** On selection of Client:::: Initiate DGF ********/
  selectClient(clientData: any) {
    if (clientData.item && clientData.item.id) {
      this.clientId = clientData.item.id;
      this.isProjectDisabled = true;
      this._projectDto.setInitiateDgfData('');
      this._projectService.clientVisibility(this.clientId);
      this.selectedClientValue = clientData.item.titleWithDuns;
      this.invalidClientName = false;
    }
  }

  /********** DRAWER CHANGES BEGIN **********/
  openClientAddDrawer() {
    this.client_loadDrawer = true;
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
  }
  /**********DRAWER CHANGES END **********/



}
