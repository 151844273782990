import NativePromise from 'native-promise-only';
import { environment } from '../../environments/environment';

const extractAccessToken = () => {
  if (sessionStorage.getItem('surveyToken')) {
    return sessionStorage.getItem('surveyToken');
  }
  else if (localStorage.getItem('drmsToken')) {
    return localStorage.getItem('drmsToken').replace('Bearer ', '');
  }

  return "";
}
const xhrRequest = (url, name, query, data, options?, onprogress?) => {
  url = `${environment.apibaseurl}files`;
  return new NativePromise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const json = (typeof data === 'string');
    const fd = new FormData();
    if (typeof onprogress === 'function') {
      xhr.upload.onprogress = onprogress;
    }

    if (!json) {
      for (const key in data) {
        fd.append(key, data[key]);
      }
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        // Need to test if xhr.response is decoded or not.
        let respData: any = {};
        try {
          respData = (typeof xhr.response === 'string') ? JSON.parse(xhr.response) : {};
          respData = (respData && respData.data) ? respData.data : respData;
        }
        catch (err) {
          respData = {};
        }

        // Get the url of the file.
        let respUrl = respData.hasOwnProperty('url') ? respData.url : `${xhr.responseURL}/${name}`;

        // If they provide relative url, then prepend the url.
        if (respUrl && respUrl[0] === '/') {
          respUrl = `${url}${respUrl}`;
        }
        resolve({ url: respUrl, data: respData });
      }
      else {
        reject(xhr.response || 'Unable to upload file');
      }
    };

    xhr.onerror = () => reject(xhr);
    xhr.onabort = () => reject(xhr);

    let requestUrl = url;
    xhr.open('POST', requestUrl);
    if (json) {
      xhr.setRequestHeader('Content-Type', 'application/json');
    }


    const token = extractAccessToken();
    if (token) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    }

    //Overrides previous request props
    if (options) {
      const parsedOptions = typeof options === 'string' ? JSON.parse(options) : options;
      for (const prop in parsedOptions) {
        xhr[prop] = parsedOptions[prop];
      }
    }

    xhr.send(json ? data : fd);
  });
};

export class DrmsFileService {
  uploadFile(storage, file, fileName, dir, evt, url, options): any {
    const uploadRequest = function (form) {
      return xhrRequest(url, fileName, {}, {
        file,
        fileName,
        dir
      }, options, evt).then(response => {
        // Store the project and form url along with the metadata.
        //return response.data;
        response.data = response.data || {};
        // response.data.baseUrl = Formio.projectUrl;
        // response.data.project = form ? form.project : '';
        // response.data.form = form ? form._id : '';
        return {
          storage: 'url',
          name: response.data.name,
          url: response.url,
          size: file.size,
          type: file.type,
          data: response.data
        };
      });
    };

    return uploadRequest(null);
  }
  deleteFile(fileInfo) {
    fileInfo.url = `${environment.apibaseurl}files`;
    return new NativePromise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('DELETE', fileInfo.url, true);
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 400) {
          resolve('File deleted');
        }
        else {
          reject(xhr.response || 'Unable to delete file');
        }
      };


      const token = extractAccessToken();
      if (token) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      }

      xhr.send(null);
    });
  }
  downloadFile(file, options) {
    if (file.private) {
      return xhrRequest(file.url, file.name, {}, JSON.stringify(file)).then(response => response.data);
    }

    // Return the original as there is nothing to do.
    return NativePromise.resolve(file);
  }
}
