<form [formGroup]="reportForm" autocomplete="off">
  <!-- // Edit Configuration -->
  <div *ngIf="isEditMode">
    <div class="form-row">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="organization" class="asterisk">Organization:</label>
        <input type="text" name="organization" readonly class="form-control" id="organization" formControlName="organization" />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="entityLevel" class="asterisk">Entity Level:</label>
        <input name="entityLevel" type="text" class="form-control" formControlName="entityLevel" readonly />
      </div>
      <div class="col-md-6 mb-3 typeahead-wrapper" *ngIf="!isLevelOrgOrSystem()" >
        <label for="entityName" class="asterisk">Entity Name:</label>
        <input name="entityName" type="text" class="form-control" formControlName="entityName" readonly />
      </div>
    </div>
  </div>

  <div *ngIf="!isEditMode">
    <div class="form-row" *ngIf="isAdmin">
      <div class="col-md-6 mb-3 typeahead-wrapper" [ngClass]="{'has-error': !reportForm.get('organization').valid && (reportForm.get('organization').touched || reportForm.get('organization').dirty || isSubmitted)}">
        <label for="organization" class="asterisk">Organization:</label>
        <fdl-type-ahead formControlName="organization" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                        [optionsLimit]="500" [minLength]="0" (onSelect)="onOrganizationSelect($event)">
        </fdl-type-ahead>
        <div class="has-error">
          <span *ngIf="reportForm.get('organization').invalid && reportForm.get('organization').errors?.pattern && (reportForm.get('organization').touched || reportForm.get('organization').dirty || isSubmitted)">
            <i class="material-icons">warning</i>Please enter a valid Organization Name
          </span>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="!isAdmin">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="organization" class="asterisk">Organization:</label>
        <input type="text" name="organization" readonly class="form-control" id="organization" formControlName="organization"/>
      </div>
    </div>

    <div class="form-row">
      <!-- Entity Level -->
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="entityLevel" class="asterisk">Entity Level:</label>
        <div class="fdl-select">
          <select name="entityLevel" class="form-control" formControlName="entityLevel" (change)="onEntityLevelChange()">
            <option value="">Please select</option>
            <option *ngFor="let level of entities | keyvalue" [value]="level.value"> {{ level.value }} </option>
          </select>
        </div>
        <div class="has-error">
          <span *ngIf=" !entitiyLevel.valid && (entitiyLevel.touched || isSubmitted)">
            <i class="material-icons">warning</i> Please select entity level.
          </span>
        </div>
      </div>

      <!-- Entity Name -->
      <div class="col-md-6 mb-3 typeahead-wrapper" *ngIf="isShowEntityName" [ngClass]="{'has-error': !reportForm.get('entityName').valid && (reportForm.get('entityName').touched  || reportForm.get('entityName').dirty || isSubmitted) }">
        <label for="EntityName" class="asterisk">Entity Name:</label>
        <fdl-type-ahead *ngIf="entitiyLevel.value == 'Product'" formControlName="entityName" [collection]="productSuggestion" optionField="title"
                        placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityNameSelect($event)">
        </fdl-type-ahead>
        <fdl-type-ahead *ngIf="entitiyLevel.value == 'Form'" formControlName="entityName" [collection]="formSuggestion" optionField="title"
                        placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityNameSelect($event)">
        </fdl-type-ahead>
        <!-- <span *ngIf="reportForm.get('entityName').invalid && reportForm.get('entityName').errors?.pattern && (reportForm.get('entityName').touched || reportForm.get('entityName').dirty || isSubmitted)">
          <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
        </span> -->
      </div>

    </div>
  </div>

  <div  class="form-row"
  [ngClass]="{'has-error': !reportForm.get('reportName').valid && (reportForm.get('reportName').touched|| isSubmitted) }">
    <div class="col-md-6 mb-3 typeahead-wrapper">
      <div>
      <label for="reportName" class="asterisk">Report Name:</label>
      <input name="reportName" type="text" class="form-control" formControlName="reportName" />
    </div>   
      <div class="has-error">
        <span *ngIf="!reportForm.get('reportName').valid && reportForm.get('reportName').errors?.required && (reportForm.get('reportName').touched || isSubmitted)">
          <i class="material-icons">warning</i> Please enter report Name.
        </span>
        <span *ngIf="reportForm.get('reportName').invalid && reportForm.get('reportName').errors?.pattern && (reportForm.get('reportName').touched || reportForm.get('reportName').dirty || isSubmitted)">
          <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
        </span>
      </div>
   
  </div> 
  </div>

 
  <div  class="form-row"
  [ngClass]="{'has-error': !reportForm.get('reportURL').valid && (reportForm.get('reportURL').touched|| isSubmitted) }">
    <div class="col-md-6 mb-3 typeahead-wrapper">
      <div>
      <label for="reportURL">Report URL:</label>
      <input name="reportURL" type="text" class="form-control" formControlName="reportURL" />
    </div>
    <div class="has-error">
      <span
      *ngIf="!reportForm.get('reportURL').valid && reportForm.get('reportURL').errors?.required && (reportForm.get('reportURL').touched || isSubmitted)">
      <i class="material-icons">warning</i> Please enter report URL.</span>

      <span
      *ngIf="!reportForm.get('reportURL').valid && reportForm.get('reportURL').errors?.pattern && (reportForm.get('reportURL').touched || isSubmitted)">
      <i class="material-icons">warning</i> {{regexValidationMessage.URL_VALIDATION_MESSAGE}} </span>

    </div>
  </div>
  </div> 

  <div  class="form-row"
  [ngClass]="{'has-error': !reportForm.get('reportDescription').valid && (reportForm.get('reportDescription').touched|| isSubmitted) }"> 
    <div class="col-md-6 mb-3 typeahead-wrapper">
      <div>
      <label for="reportDescription">Report Description:</label>
      <textarea class="form-control" id="reportDescription" formControlName="reportDescription"></textarea>
    </div>
      <div class="has-error">
        <span *ngIf="!reportForm.get('reportDescription').valid && reportForm.get('reportDescription').errors?.required && (reportForm.get('reportDescription').touched || isSubmitted)">
          <i class="material-icons">warning</i> Please enter report Description.
        </span>
        <span *ngIf="!reportForm.get('reportDescription').valid && reportForm.get('reportDescription').errors?.pattern && (reportForm.get('reportDescription').touched || isSubmitted)">
          <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
        </span>
      </div>
  </div>
  </div> 

 

  <div class="form-row ">  
      <div class="col-md-6 mb-3 checkbox">
          <input type="checkbox" class="ng-untouched ng-pristine ng-valid" value="false" id="isVisible" name="isVisible" formControlName="isVisible"/>
          <label for="isVisible" class="input-label pt-1 pl-4"> 
             Is Ready to be Displayed on Dashboard?
          </label>
      </div>    
  </div>

  <div class="fdl-2 button-container">
    <button type="button" class="btn fdl-btn btn-secondary-light " (click)="cancelBtnHandler()" aria-label="Cancel"> Cancel </button>
    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="submitBtnHandler()"> Submit </button>  
  </div>
</form>