import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectsDTOService } from 'src/app/Projects/Services/projectsDTO.service';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-create-project-main',
  templateUrl: './create-project-main.component.html'
})
export class CreateProjectMainComponent implements OnInit, OnDestroy {
  // createdProjName: string = '';
  assignFormSection: boolean = false;
  prevUrl: string = "";
  constructor(private DTOService: ProjectsDTOService, private _router: Router, private _projectService: ProjectsService) { }

  ngOnInit(): void {
    this.assignFormSection = this.DTOService.getAssignFormSection();
    if (this.DTOService.getParentType() === "edit") {
      // let createdProjectData = this.DTOService.getProjectFacts();
      // this.createdProjName = createdProjectData.title;
    }
  }

  ngOnDestroy(): void {
    if (this.DTOService.getParentType() !== 'edit' || !this.prevUrl.endsWith("publish-forms")) {
      this.DTOService.setAssignFormSection(false);
      this.DTOService.setProjectFacts({});
      this.DTOService.setProjectStakeHolders([]);
    }
    this.prevUrl = "";
  }

  public createdProjName(): string {
    const createdProjectData = this.DTOService.getProjectFacts();
    return createdProjectData?.title ? createdProjectData.title : 'Create New Project';
  }

  goBack() {
    this.prevUrl = this._router.url;
    this._projectService.isCopyForms = false;
    this._projectService.selectedProjectCopyForms = 0;
    //this._router.navigate([this.routeEventsService.previousRoutePath.value]);
    if (this.DTOService.getParentType() === 'edit') {
      let createdProjectData = this.DTOService.getProjectFacts();
      this._router.navigate(['/projects/view', createdProjectData.id]);
    }
    else {
      this._router.navigate(['/projects/list']);
    }
  }

}
