<section class="col-lg-12 right-section">
    <div class="form-container">
        <form class="p-0 border-0">
            <h2 class="h3">
                Client Information</h2>
            <p>Please complete client information to complete this section.</p>

            <div class="alert alert-dismissible alert-danger" *ngIf="isError">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="isError=false">×</button>
                <i class="material-icons">warning</i>
                <p [innerHtml]="errorMsg"></p>
            </div>

            <div class="alert alert-success alert-dismissible" *ngIf="showSuccessMsg">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="showSuccessMsg=false">×</button>
                <span class="iconLabel" aria-label="sucess"></span>
                <i class="material-icons" aria-hidden="true">check_circle</i>
                <p>{{successMsg}}

                </p>
            </div>

            <form [formGroup]="clientForm" autocomplete="off">

                <div class="form-row align-items-end">
                    <div class="col-md-6 typeahead-wrapper">
                        <label for="client">Client Name/DUNS:</label>
                        <fdl-type-ahead formControlName="clientName" [collection]="clientSuggestion"
                            (ngModelChange)="onClientNameChange($event)"
                            optionField="titleWithDuns" placeholder="Type to search" [scrollable]="true"
                            [optionsLimit]="1000" [minLength]="0" (onSelect)="onClientSelect($event)"
                            (input)="typeaheadLoadingClients($event.target.value)"></fdl-type-ahead>
                    </div>

                    <div class="col-md-1 pl-0 pt-3 align-self-center" *ngIf="createClientAllowed">
                        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Client"
                            (click)="openAddClientDrawer()"><i class="material-icons f-30">add_circle_outline</i></a>
                    </div>
                </div>
                <div class="has-error">
                    <span
                        *ngIf="!clientForm.get('clientName').valid && clientForm.get('clientName').errors?.pattern && (clientForm.get('clientName').dirty || isSubmitted)">
                        <i class="material-icons">warning</i>Please enter a valid Client Name</span>
                </div>
                <div formGroupName="clientDetails">
                    <!-- <div class="form-row mt-4">
                            <div class="col-md-6 mb-3">
                                <label for="dunsId">Gud Id:</label>
                                <input type="text" class="form-control" disabled="" formControlName="dunsId"
                                    id="dunsId">
                            </div>
                        </div> -->
                    <div class="form-row mt-4">
                        <div class="col-md-6 mb-3">
                            <label for="streetAddressOne">Street Address 1:</label>
                            <input type="text" class="form-control" disabled="" formControlName="streetAddress1"
                                id="streetAddressOne">
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="streetAddressTwo">Street Address 2:</label>
                            <input type="text" class="form-control" disabled="true" formControlName="streetAddress2"
                                id="streetAddressTwo">
                        </div>

                        <div class="col-md-6 mb-3">
                            <label for="city">City:</label>
                            <input type="text" class="form-control" disabled="" formControlName="city" id="city">
                        </div>

                        <div class="col-md-6 mb-3">
                            <label for="stateProvince:">State/Province:</label>
                            <input type="text" class="form-control" disabled="" formControlName="state"
                                id="stateProvince">
                        </div>

                        <div class="col-md-3 mb-3  ">
                            <label for="postalzipCode:">Postal/ZIP Code:</label>
                            <input type="text" class="form-control" disabled="" formControlName="zipCode"
                                id="postalzipCode">
                        </div>
                        <div class="col-md-6 offset-md-3 mb-3">
                            <label for="country">Country:</label>
                            <input type="text" class="form-control" disabled="" formControlName="country" id="country">
                        </div>
                    </div>
                </div>
                <div class="form-row align-items-end">
                    <div class="col-md-6 typeahead-wrapper">
                        <label for="clientContactPerson">Client Implementation Manager:</label>
                        <fdl-type-ahead formControlName="clientContactName" [collection]="clientContactList"
                            (ngModelChange)="onClientContactNameChange($event)"
                            optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500"
                            [minLength]="0" (onSelect)="getContactEmail()"></fdl-type-ahead>
                        <div class="mt-1"><span *ngIf="contactEmail && contactEmail != '-1'">{{contactEmail}}</span>
                        </div>
                    </div>
                </div>

            </form>

            <div class="fdl-2 button-container">


                <button type="button" class="btn fdl-btn btn-secondary-light " aria-label="Default"
                    (click)="cancelForm();">Cancel</button>
                <div>

                    <div class="btn-group btn-split" href="#">
                        <a href="javascript:void(0)" class="btn fdl-btn btn-primary-orange"
                            (click)="saveAndContinueClientForm();">Submit</a>
                    </div>
                </div>
            </div>

        </form>
    </div>
</section>
<!-- Drawer Container -->
<div *ngIf="loadDrawer">
    <app-project-drawer-container componentName="client" state="CLIENT"></app-project-drawer-container>
</div>