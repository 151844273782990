import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IQuery } from '../../../shared/query';
import { select } from "d3-selection";
import { scaleBand, scaleLinear } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { stack } from "d3-shape";
import { sum, transpose } from "d3-array";

@Component({
  selector: 'app-commitment-dashboard-exporting',
  templateUrl: './commitment-dashboard-exporting.component.html',
  styleUrls: ['./commitment-dashboard-exporting.component.css'],
})
export class CommitmentDashboardExportingComponent implements OnInit, OnChanges {
  headerColumns = [
    'Title',
    'Type',
    'State',
    'Target Delivery Date',
    'Status',
    'Product',
  ];
  keys = [
    'commitmentTitle',
    'commitmentType',
    'commitmentStatus',
    'plannedCommittedDeliveryDate',
    'isRisk',
    'product',
  ];
  @Input() clientId: number = 0;
  @Input() summary: any = {};
  @Input() commitmentProducts: any;
  @Input() commitmentCalendarNumbers: any = [];
  @Input() commitmentCalendarData: any = [];
  @Input() cBProductsError: boolean = false;
  @Input() searchFilterForm: any = [];
  @Input() pageFilterForm: any = [];
  @Input() clientTypeSearch: any = 'duns';
  @Input() commitments: any;
  @Input() typeOfCommitmentCalander: any = 'qtr';
  @Input() commitmentCalendar: boolean = false;
  @Input() calendarCommitments: any = [];
  @Input() isPdfExport: boolean = false;

  private queryParam: IQuery;
  private isPageLoad: boolean = true;

  dataCommitmentCalendarError: boolean = false;
  errorMsg: any;
  commitmentStatsCount: any = [];
  applyFilter: boolean = false;
  pageProducts: any = [];
  commitmentsError: boolean = false;
  commitmentStatuses: any = [];
  commitmentStats: any = {
    Active: 0,
    Canceled: 0,
    Completed: 0,
    'On Hold': 0,
    Total: 0,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }
    if (changes.commitmentCalendar?.currentValue) {
      this.loadCommitmentCalendar(this.typeOfCommitmentCalander);
    }

    if (changes.isPdfExport?.currentValue) {
      this.initData();
    }
  }

  ngOnInit(): void {
    // this.initData();
  }

  initData() {
    this.pageFilterForm = '';
    this.initQueryParams();
    this.getDataDateWise();
  }

  getDataDateWise() {
    this.applyFilter = false;
    this.loadCommitmentCalendar(this.typeOfCommitmentCalander);
    this.clientCommitmentSummaray();
    this.commitmentsbyProduct();
  }

  initQueryParams(filter = '') {
    let formFields = [];
    if (filter != '') {
      formFields = JSON.parse(this.queryParam.FormFields);
    }
    formFields.push({ apiKey: 'isReviewed', apiKeyValue: 'true' });
    this.queryParam = Object.assign(
      {},
      {
        FormFields: JSON.stringify(formFields),
        searchClientBy: this.clientTypeSearch,
      }
    );
  }

  clientCommitmentSummaray() {
    this.commitmentStats = {
      'In Progress': 0,
      Canceled: 0,
      Completed: 0,
      'On Hold': 0,
      Total: 0,
    };
    this.commitmentStatsCount = {
      'In Progress': 0,
      Canceled: 0,
      Completed: 0,
      'On Hold': 0,
      Total: 0,
    };
    if (this.summary && this.summary.website && this.summary.website != null) {
      if (this.summary.website.indexOf('https://') == '-1') {
        this.summary.website = `https://${this.summary.website}`;
      }
    }
    if (!this.summary) {
      return;
    }
    if (this.summary && this.summary.relationshipManagerName) {
      let initials = this.summary.relationshipManagerName.split(' ');
      this.summary.rmIn = initials[0][0];
      if (initials[1] && initials[1][0]) {
        this.summary.rmIn = initials[0][0] + initials[1][0];
      }
    }

    if (this.summary && this.summary.salesExecutiveName) {
      let seinitials = this.summary.salesExecutiveName.split(' ');
      this.summary.seIn = seinitials[0][0];
      if (seinitials[1] && seinitials[1][0]) {
        this.summary.seIn = seinitials[0][0] + seinitials[1][0];
      }
    }

    if (!this.summary.commitmentStatuses) {
      return;
    }

    this.commitmentStats['Total'] =
      this.summary.commitmentStatuses[0].commitmentCount +
      this.summary.commitmentStatuses[1].commitmentCount +
      this.summary.commitmentStatuses[2].commitmentCount +
      this.summary.commitmentStatuses[3].commitmentCount;

    for (let k in this.summary.commitmentStatuses) {
      const commitmentStatus = this.summary.commitmentStatuses[k].commitmentStatus;
      if (this.summary.commitmentStatuses[k].commitmentCount > 0) {
        this.commitmentStats[commitmentStatus] = (this.summary.commitmentStatuses[k].commitmentCount / this.commitmentStats['Total']) * 100;
      }
        
      this.commitmentStatsCount[commitmentStatus] = this.summary.commitmentStatuses[k].commitmentCount;
      this.commitmentStats[commitmentStatus] = parseFloat(this.commitmentStats[commitmentStatus]).toFixed(2);
    }
  }

  /*******************/
  loadCommitmentCalendar(mode) {
    this.typeOfCommitmentCalander = mode;
    if (!this.commitmentCalendar && !this.dataCommitmentCalendarError && !this.isPageLoad) {
      return;
    }

    if (!this.commitmentCalendarData) {
      this.calendarCommitments  = [];
    }
    else {
      this.calendarCommitments = [...this.commitmentCalendarData];
    }
    
    this.isPageLoad = false;
    const commitmentCalendarLabels = [];
    const commitmentCalendarData_inProgressCommitments = [];
    const commitmentCalendarData_onHoldCommitments = [];
    const commitmentCalendarData_canceledCommitments = [];
    const commitmentCalendarData_completedCommitments = [];

    for (let k in this.calendarCommitments) {
      if (this.typeOfCommitmentCalander == 'qtr') {
        commitmentCalendarLabels.push(`${this.calendarCommitments[k].plannedCommittedDeliveryDateYear}-${this.calendarCommitments[k].plannedCommittedDeliveryDateQuarter}`);
      }
      else if (this.typeOfCommitmentCalander == 'month') {
        commitmentCalendarLabels.push(`${this.calendarCommitments[k].plannedCommittedDeliveryDateYear}-${this.calendarCommitments[k].plannedCommittedDeliveryDateMonth}`);
      }
      else {
        commitmentCalendarLabels.push(this.calendarCommitments[k].plannedCommittedDeliveryDateYear);
      }

      commitmentCalendarData_inProgressCommitments.push(this.calendarCommitments[k].inProgressCommitments);
      commitmentCalendarData_onHoldCommitments.push(this.calendarCommitments[k].onHoldCommitments);
      commitmentCalendarData_canceledCommitments.push(this.calendarCommitments[k].canceledCommitments);
      commitmentCalendarData_completedCommitments.push(this.calendarCommitments[k].completedCommitments);
    }

    const commitmentCalendarData = [
      {
        label: 'In Progress',
        values: commitmentCalendarData_inProgressCommitments,
        color: '#0080BF',
      },
      {
        label: 'Completed',
        values: commitmentCalendarData_completedCommitments,
        color: '#54B34A',
      },
      {
        label: 'On Hold',
        values: commitmentCalendarData_onHoldCommitments,
        color: '#FFC626',
      },
      {
        label: 'Canceled',
        values: commitmentCalendarData_canceledCommitments,
        color: '#757575',
      },
    ];

    const svg = select("svg#deliveryCalendarChart");
    svg.selectAll("*").remove();
    setTimeout(() => {
      this.commitMentCalendarGraph(
        commitmentCalendarLabels,
        commitmentCalendarData
      );
    }, 300);
  }

  /***Commitments By Products */
  commitmentsbyProduct() {
    if (!this.commitmentProducts) {
      this.pageProducts = [];
      return;
    }
    this.pageProducts = [...this.commitmentProducts];
  }
 
  calculateSemiDonutRotation(status: string){
    return Math.round((-45 + this.commitmentStats[status] * 1.8) * 100) / 100;
  }

  /**
   * *
   * @param labels
   * @param Month
   * @param type
   */
  commitMentCalendarGraph(monthLabels, data) {
    const svg = select("svg#deliveryCalendarChart");
    if (!svg.node()) {
      return;
    }

    const margins = [5, 5, 50, 20];
    const { clientWidth, clientHeight } = svg.node();
    const innerWidth = clientWidth - margins[1] - margins[3];
    const innerHeight = clientHeight - margins[0] - margins[2];

    const sums = monthLabels.map((_, i) => sum(data.map(({ values }) => values[i])));
    const max = Math.max(...sums);
    
    // Rendering the legend items to show the notes
    const legendItems = select("#legend")
      .selectAll("li")
      .data(data);

    legendItems
      .enter()
      .append("li")
      .text(({ label }) => label)
      .append("span")
      .style("background-color", ({ color }) => color);

    legendItems
      .select("li")
      .text(({ label }) => label);

      // Rendering the xAxis & yAxis
    const scaleX = scaleBand().padding(0.3).domain(monthLabels).range([0, innerWidth]);
    const scaleY = scaleLinear()
      .domain([0, max])
      .nice()
      .range([innerHeight, 0]);

    const xAxisWrapper = svg
      .append("g")
      .attr("class", "xaxis")
      .attr("transform", `translate(${margins[3]}, ${margins[0] + innerHeight})`);
    const yAxisWrapper = svg
      .append("g")
      .attr("transform", `translate(${margins[3]}, ${margins[0]})`);

    let xAxisTickIndex = 0;
    const xAxis = axisBottom(scaleX).tickFormat((value) => {
      xAxisTickIndex += 1;
      if (monthLabels.length >= 25 && monthLabels.length < 50 && (xAxisTickIndex - 1) % 2 !== 0) {
        return null;
      }

      if (monthLabels.length >= 50 && monthLabels.length < 75 && (xAxisTickIndex - 1) % 4 !== 0) {
        return null;
      }

      if (monthLabels.length >= 75 && monthLabels.length < 100 && (xAxisTickIndex - 1) % 5 !== 0) {
        return null;
      }

      if (monthLabels.length >= 100 && (xAxisTickIndex - 1) % 10 !== 0) {
        return null;
      }

      return value;
    }).tickPadding(8).tickSize(0);

    const yAxis = axisLeft(scaleY)
      .tickFormat((value) => {
        if (value % 1 !== 0) {
          return null;
        }

        return `${Math.round(value)}`
      }).tickPadding(8).tickSize(0);

    xAxisWrapper.call(xAxis);
    yAxisWrapper.call(yAxis);

    // Re-calculate the transformation (translation & rotation) of the xAxis's ticks
    const xAxisBar = svg.select(".xaxis");
    if (!xAxisBar.node()) {
      return;
    }

    const xAxisTickText = xAxisBar.selectAll(".tick text").node();
    if (!xAxisTickText) {
      return;
    }

    const xAxisBarWidth = xAxisBar.node().getBoundingClientRect().width;
    const xAxisTickLabelWidth = xAxisTickText.getBoundingClientRect().width;
    const xAxisTickLabelHeight = xAxisTickText.getBoundingClientRect().height;
    const maxRotationAngle = xAxisBarWidth / xAxisTickLabelHeight;

    const xAxisTicks = xAxisBar.selectAll(".tick");
    const xAxisTicksTransforms = xAxisTicks.nodes().map((t) => {
      const transform = t.getAttribute("transform");
      const transformValue = transform.split('(').pop().split(')')[0];
      return Number.parseFloat(transformValue);
    });

    xAxisTicks.attr("transform", (_, i) => {
      const xAxisTickTranslate = xAxisTicksTransforms[i] - (xAxisTickLabelWidth - (xAxisTickLabelWidth * 0.5));
      return `translate(${xAxisTickTranslate}, 12) rotate(${-maxRotationAngle})`;
    });

    // Rendering the grid lines
    const xGridGroup = svg.append('g').attr('class', 'grid')
      .attr("transform", `translate(${margins[3]}, ${margins[0] + innerHeight})`);

    const yGridGroup = svg.append('g').attr('class', 'grid')
      .attr("transform", `translate(${margins[3]}, ${margins[0]})`);

    // Create a grid line generator for the x-axis and y-axis.
    const xGridLine = axisBottom(scaleX).tickFormat(() => null).tickSize(-clientHeight);
    const yGridLine = axisLeft(scaleY).tickFormat(() => null).tickSize(-clientWidth);
    
    xGridGroup.call(xGridLine);
    yGridGroup.call(yGridLine);

    // Rendering the bars chart
    const dataMatrix = transpose(data.map((type) => type.values));
    const stackData = stack().keys(Object.keys(data))(dataMatrix);

    const chartRoot = svg
      .append("g")
      .attr("class", "chart")
      .attr("transform", `translate(${margins[3]}, ${margins[0]})`);

    const stacks = chartRoot.selectAll(".layer").data(stackData);

    const layer = stacks
      .enter()
      .append("g")
      .attr("class", "layer")
      .attr("fill", (_, i) => data[i].color);

    const bandWidth = scaleX.bandwidth();
    layer
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (_, i) => scaleX(monthLabels[i]))
      .attr("width", bandWidth)
      .attr("y", ([, end]) => scaleY(end))
      .attr("height", ([start, end]) => scaleY(start) - scaleY(end));
  }
}
