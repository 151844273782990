import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPageTemplate, IPageTemplateResponse } from 'src/app/shared/page-template';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { take } from 'rxjs/operators';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'view-page',
  templateUrl: './view-page.component.html'
})
export class ViewPageTemplateComponent implements OnInit {
  errorMsg: string = "";
  formJson: any;
  answerJson: any;
  pageTemplateId: number;
  pageTemplateResponseId: number;
  pageResponse: IPageTemplateResponse;
  pageTemplate: IPageTemplate;
  reservedKeysData: any;
  isReady:boolean = false;
  readOnly:boolean = false;
  isAdmin:boolean = false;
  isRestrictedAdmin:boolean = false;
  userData: any;
  pageTemplateResponseFormValue: IPageTemplateResponse;
  
  constructor(
    private route: ActivatedRoute,
    private permission: PermissionsService,
    private router: Router,
    private pageTemplateService: PageTemplateService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.isAdmin = this.permission.userData.isAdmin;
    this.isRestrictedAdmin = this.permission.userData.isRestrictedAdmin;
    this.userData = this.userService.getUserListData();
    this.pageTemplateResponseFormValue = this.pageTemplateService.formVal;
    this.route.params
    .pipe(take(1))
    .subscribe((params) => {
      if (this.router.url.indexOf('response') !== -1)
        this.pageTemplateResponseId = params['id'];
      else
        this.pageTemplateId = params['id'];

      if (this.pageTemplateResponseId > -1)
        this.getPageResponseData();
      if (this.pageTemplateId > -1)
        this.getPageTemplateData();
    });
  }

  getPageTemplateData() {
    this.pageTemplateService.getPageTemplateById(this.pageTemplateId)
    .pipe(take(1))
      .subscribe(
        response => {
          this.pageTemplate = response as IPageTemplate;
          this.formJson = JSON.parse(this.pageTemplate.formJSON);
          this.isReady = true;
          this.populateReservedKeysData();
        },
      (error)=> { this.errorMsg = error; });
  }

  populateReservedKeysData() {
    const reservedData: {[key: string]: any} = {} 
    if(this.pageTemplateResponseFormValue) {
      if(this.pageTemplateResponseFormValue?.clientTitle)
        reservedData['drmsReservedClientName'] = this.pageTemplateResponseFormValue.clientTitle;
      if(this.pageTemplateResponseFormValue?.productTitle)
        reservedData['drmsReservedProductName'] = this.pageTemplateResponseFormValue.productTitle;
      if(this.pageTemplateResponseFormValue?.organizationTitle)
        reservedData['drmsReservedOrganizationName'] = this.pageTemplateResponseFormValue.organizationTitle;
      if(this.pageTemplateResponseFormValue?.mdmClientMasterId)
        reservedData['drmsReservedMdmClientMasterId'] = this.pageTemplateResponseFormValue.mdmClientMasterId;
      if(this.pageTemplateResponseFormValue?.clientDunsId)
        reservedData['drmsReservedClientDunsId'] = this.pageTemplateResponseFormValue.clientDunsId;
      if(this.pageTemplateResponseFormValue?.relationshipManagerName)
        reservedData['drmsReservedRelationshipManagerName'] = this.pageTemplateResponseFormValue.relationshipManagerName;
      if(this.pageTemplateResponseFormValue?.relationshipManagerEmail)
        reservedData['drmsReservedRelationshipManagerEmail'] = this.pageTemplateResponseFormValue.relationshipManagerEmail;
      if(this.pageTemplateResponseFormValue?.clientGudId)
        reservedData['drmsReservedClientGudId'] = this.pageTemplateResponseFormValue.clientGudId;
    }
    this.reservedKeysData = reservedData;
  }

  getPageResponseData() {
    this.pageTemplateService.getPageTemplateResponseById(this.pageTemplateResponseId)
    .pipe(take(1))
      .subscribe(
        response => {
          this.pageResponse = response as IPageTemplateResponse;
          this.formJson = JSON.parse(this.pageResponse.formJSON);
          this.answerJson = JSON.parse(this.pageResponse.answerJSON);
          this.pageTemplateId = this.pageResponse.pageTemplateId;
          this.isReady = true;
          this.populateReservedKeysData();
          if (this.pageResponse?.accessLevel === 'Read' || this.answerJson?.drmsReservedIsReadOnlyResponse?.toString() === 'true')
          {
            this.readOnly = true;
            this.formJson['isReadOnly'] = true;
          }

          /* -- Commented due to PBI-260094
          this.pageResponse.accessLevel = 'Write';
          this.updateAccessLevel();

          if (this.pageResponse?.pageTemplateResponseStatusId === 6 || this.answerJson?.drmsReservedIsReadOnlyResponse?.toString() === 'true')
          {
            this.readOnly = true;
            this.formJson['isReadOnly'] = true;
          }
          else if (!(this.isAdmin || this.pageResponse?.pageTemplateAdmins?.some(a => a.userId === this.userData.id && a.isReadAccess == false) || (this.isRestrictedAdmin && this.pageResponse?.accessLevel === 'Write')))
          {         
            if (this.pageResponse?.pageTemplateResponseStatusId === 5
              || (
                    this.pageResponse?.pageTemplateResponseStatusId === 3
                    && !(this.pageResponse?.pageTemplateApprovers?.some(a => a.approverId === this.userData.id && a.level === this.pageResponse?.currentLevel && a.isReviewComplete === false))
                  )
              || (
                    (this.pageResponse?.pageTemplateResponseStatusId === 1 || this.pageResponse?.pageTemplateResponseStatusId === 2 || this.pageResponse?.pageTemplateResponseStatusId === 4) 
                    && !(this.pageResponse?.submittedById === this.userData.id)
                  )
              ) {
              this.readOnly = true;
              this.formJson['isReadOnly'] = true;
            }
            else if(
               this.pageResponse?.pageTemplateReadAdmins?.some(a => a.userId === this.userData.id && a.isReadAccess)
            && !(this.pageResponse?.submittedById === this.userData.id)
            && (
                this.pageResponse?.pageTemplateResponseStatusId === 3
                && !(this.pageResponse?.pageTemplateApprovers?.some(a => a.approverId === this.userData.id && a.level === this.pageResponse?.currentLevel && a.isReviewComplete === false))
              )
            )
            {
              this.readOnly = true;
              this.formJson['isReadOnly'] = true;
            }
          }
          -- Commented due to PBI-260094 */
        },
      (error)=> { this.errorMsg = error; });
  }

  updateAccessLevel() {
    if(this.userData.isRestrictedAdmin === true){
      let isExists: boolean = false;
      let isApprover: boolean = false;
      let sortOrder = ['Read','Write'];
      let groups = this.userData.groups.sort((a,b)=>{return sortOrder.indexOf(a.accessLevel) - sortOrder.indexOf(b.accessLevel);});
     
      isApprover = this.pageResponse.pageTemplateApprovers.some(a => a.approverId === this.userData.id && a.level === this.pageResponse.currentLevel) && this.pageResponse.pageTemplateResponseStatusId === 3;

      if(!isApprover && this.pageResponse.submittedById !== this.userData.id)
      { 
        for (let g in groups) {
          isExists = false;
          if(this.pageResponse.organizationId > 0) {
            if(groups[g].organizations.some(org => org.organizationId === this.pageResponse.organizationId)) {
              isExists = true;
            }
          }

          if(this.pageResponse.productId > 0) {
            if(groups[g].products.some(prd => prd.productId === this.pageResponse.productId)) {
              isExists = true;
            }
          }

          if(this.pageResponse.clientId > 0) {
            if(groups[g].clients.some(clnt => clnt.clientId === this.pageResponse.clientId)) {
              isExists = true;
            }
          }

          if(isExists) {
            this.pageResponse.accessLevel = groups[g].accessLevel;
          }
        }
      }
    }
  }
}