import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { CommitmentsIntakeSearchInput } from '../models/commitments-intake-search-input';
import { CommitmentsIntakeSearchType } from '../enums/commitments-intake-search-type.enum';

@Component({
  selector: 'app-commitments-intake-search',
  templateUrl: './commitments-intake-search.component.html',
  styleUrls: ['./commitments-intake-search.component.css']
})
export class CommitmentsIntakeSearchComponent implements OnInit {

  public showResetButton = false;
  public commitmentsIntakeSearchInputs: CommitmentsIntakeSearchInput[] = [];

  @Input()
  public searchFields: any;

  @Output()
  private search = new EventEmitter<CommitmentsIntakeSearchInput[]>();

  constructor() { }

  ngOnInit(): void {
  }

  public onSearchFilterChanged(data: string[], searchType: string, formFieldApiKey: string): void {
    this.commitmentsIntakeSearchInputs = this.commitmentsIntakeSearchInputs.filter(item => item.type !== searchType);
    let typeEnumForSort = CommitmentsIntakeSearchType.ClarityProjectNumber;

    switch (searchType) {
      case 'Client Name':
        typeEnumForSort = CommitmentsIntakeSearchType.ClientName;
        break;
      case 'Clarity Project Number':
        typeEnumForSort = CommitmentsIntakeSearchType.ClarityProjectNumber;
        break;
      case 'Commitment Title':
        typeEnumForSort = CommitmentsIntakeSearchType.CommitmentTitle;
        break;
      case 'Project Status':
        typeEnumForSort = CommitmentsIntakeSearchType.ProjectStatus;
        break;
      case 'Commitment Type':
        typeEnumForSort = CommitmentsIntakeSearchType.CommitmentType;
        break;
      case 'Commitment Status':
        typeEnumForSort = CommitmentsIntakeSearchType.CommitmentStatus;
        break;
      case 'Opportunity ID':
        typeEnumForSort = CommitmentsIntakeSearchType.OpportunityID;
        break;
      case 'Opportunity TCV':
        typeEnumForSort = CommitmentsIntakeSearchType.OpportunityTCV;
        break;
      case 'DUNS':
        typeEnumForSort = CommitmentsIntakeSearchType.DUNS;
        break;
      case 'MC':
        typeEnumForSort = CommitmentsIntakeSearchType.MC;
        break;
      case 'Relationship Manager':
        typeEnumForSort = CommitmentsIntakeSearchType.RelationshipManager;
        break;
      case 'Committed Delivery Date':
        typeEnumForSort = CommitmentsIntakeSearchType.CommittedDeliveryDate;
        break;
      default:
        typeEnumForSort = CommitmentsIntakeSearchType.DataSource;
        break;
    }

    data.forEach(item => {
      const temp: CommitmentsIntakeSearchInput = {
        title: item,
        type: searchType,
        formFieldApiKey: formFieldApiKey,
        typeEnumForSort: typeEnumForSort
      };
      this.commitmentsIntakeSearchInputs.push(temp);
    });
    this.commitmentsIntakeSearchInputs = this.commitmentsIntakeSearchInputs.sort((a, b) => (a.typeEnumForSort < b.typeEnumForSort ? -1 : 1));
  }

  public onDeleteSearchFilter(data: CommitmentsIntakeSearchInput): void {
    this.commitmentsIntakeSearchInputs = this.commitmentsIntakeSearchInputs.filter(r => r !== data);

    for (let i = 0; i < this.searchFields.length; i++) {
      if (this.searchFields[i].formfieldlabel === data.type) {
        this.searchFields[i].data = this.searchFields[i].data.filter(r => r !== data.title);
        break;
      }
    }
  }

  public onSearchClicked(): void {
    if (this.commitmentsIntakeSearchInputs.length > 0) {
      this.showResetButton = true;
    } else {
      this.showResetButton = false;
    }
    this.search.emit(this.commitmentsIntakeSearchInputs);
  }

  public onResetClicked(): void {
    this.commitmentsIntakeSearchInputs = [];
    this.searchFields.forEach(item => {
      item.data = [];
    });
    this.onSearchClicked();
  }
}
