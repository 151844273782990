<div class="heading-container fdl-2">
  <div class="back-action-container">
  <div class="back-button">
    <a href="javascript:void(0);" (click)="goBack()">
      <span class="material-icons"> arrow_back </span> 
      Email Templates
    </a>
  </div>
  <h1>{{emailTemplateData.template?.emailTitle || 'N/A'}}</h1>
</div>
</div>


<div class="form-container separate-form-container">
  <div class="alert alert-success alert-dismissible" *ngIf="showUpdationMessage">
    <button type="button" class="close" data-dismiss="alert" aria-label="close"
        (click)="showUpdationMessage=false">×</button>
    <span class="iconLabel" aria-label="sucess"></span>
    <i class="material-icons" aria-hidden="true">check_circle</i>
    <p>An email template details are updated successfully.
      
    </p>
  </div>
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>

  <form>
    <div class="biller-survey white-box border-0 fdl-2">
      <button type="button" class="btn fdl-btn btn-primary-dark pull-right" *ngIf="showEditButton"
        (click)="goToEdit();">Edit</button>
    </div>
    <div class="biller-survey white-box border-0 fdl-2">
      <div class="">
        <ul class="">
          <li class=" full-width">
            Event Name:
            <span class="info">
              {{emailTemplateData.template?.displayName || 'N/A'}}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="biller-survey white-box border-0 fdl-2">
      <div class="">
        <ul class="">
          <li class=" full-width">
            Event Description:
            <span class="info">
              {{emailTemplateData.template?.description || 'N/A'}}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="biller-survey white-box">
      <div class="">
        <ul class="">
          <li class=" full-width">
            Entity Level:
            <span class="info">
              {{emailTemplateData?.level || 'N/A'}}
            </span>
          </li>

          <li class=" full-width">
            Entity Name:
            <span class="info">
              {{emailTemplateData?.entityName || 'N/A'}}
            </span>
          </li>

        </ul>
      </div>
    </div>

    <div class="biller-survey white-box"></div>

    <div id="accordion" class="accordion collapse-view">
      <div class="page-heading-container fdl-2 mt-4" data-toggle="collapse" href="#collapseOne">
        <h2 class="h5 font-weight-bold">Email Content</h2>
      </div>
      <div class="col-md-12 pt-3 pl-0 pr-0 collapse show" id="collapseOne" data-parent="#accordion">
        <div class="biller-survey white-box border-0 fdl-2">
          <div class="">
            <ul class="">
              <li class=" full-width">
                Subject:
                <span class="info" style="pointer-events: none;">
                  {{emailTemplateData.template?.emailSubject || 'N/A'}}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="biller-survey white-box border-0 fdl-2">
          <div class="">
            <ul class="">
              <li class=" full-width">
                Email Body:
                <span class="info">
                  <p style="pointer-events: none;" [innerHtml]="emailTemplateData.template?.emailBody || 'N/A'"></p>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="biller-survey white-box border-0 fdl-2" *ngIf="isAdmin">
              <div class="">
                <ul class="">
                  <li class=" full-width">
                  Additional Data:
                    <span class="info">
                      {{emailTemplateData?.additionalData || 'N/A'}}
                    </span>
                  </li>
                </ul>
              </div>
            </div> -->
        <div class="biller-survey white-box border-0 fdl-2" *ngIf="isAdmin">
          <div class="">
            <ul class="">
              <li class=" full-width">
                Disclaimer:
                <span class="info">
                  <p style="pointer-events: none;" [innerHtml]="emailTemplateData?.disclaimer || 'N/A'"></p>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="biller-survey white-box border-0 fdl-2" *ngIf="isAdmin">
          <div class="">
            <ul class="">
              <li class=" full-width">
                Privacy Policy:
                <span class="info">
                  <p style="pointer-events: none;" [innerHtml]="emailTemplateData?.privacyPolicy || 'N/A'"></p>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      
      <div class="page-heading-container fdl-2 mt-4" data-toggle="collapse" href="#collapseTwo">
        <h2 class="h5 font-weight-bold">Distribution List</h2>
      </div>

      <div class="col-md-12 pt-3 pl-0 pr-0 collapse show" id="collapseTwo" data-parent="#accordion">
        <div class="biller-survey white-box border-0 fdl-2">
          <div class="">
            <ul class="">
              <li class=" full-width">
                "To" Names:
                <span class="info">
                  {{emailTemplateData.template?.toEmail || 'N/A'}}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="biller-survey white-box border-0 fdl-2">
          <div class="">
            <ul class="">
              <li class=" full-width">
                "CC" Names:
                <span class="info">
                  {{emailTemplateData.template?.ccEmail || 'N/A'}}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="biller-survey white-box border-0 fdl-2">
          <div class="">
            <ul class="">
              <li class=" full-width">
                Additional "To" Names:
                <span class="info">
                  {{emailTemplateData.template?.additionalToEmails || 'N/A'}}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="biller-survey white-box border-0 fdl-2">
          <div class="">
            <ul class="">
              <li class=" full-width">
                Additional "CC" Names:
                <span class="info">
                  {{emailTemplateData.template?.additionalCcEmails || 'N/A'}}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>

    <div class="fdl-2 button-container">
      <div>
        <button type="button" class="btn fdl-btn btn-secondary-light mr-2" (click)="goBack()">Cancel</button>
      </div>
    </div>

  </form>

</div>