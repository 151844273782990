<div class="modal fade bd-example-modal-lg" id="selectForms" tabindex="-1" role="dialog" data-backdrop="static"
    data-keyboard="false" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myLargeModalLabel">Select Forms to Assign
                  <small class="d-block f-14">
                    Only a single <strong>'Form Type'</strong> can be selected and published.
                  </small>
                </h4>
                <button type="button" class="close" (click)="crossModal()" data-dismiss="modal" aria-label="Close popup"><span
                        aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <div class="alert alert-dismissible alert-danger" *ngIf="errorMsg">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                        (click)="errorMsg=''">
                  ×
                </button>
                <i class="material-icons">warning</i>
                <p>{{errorMsg}}</p>
              </div>
              <div class="form-row mb-3 search-filter">
                <div class="col-md-4">
                  <label for="searchForm">Form Name:</label>
                  <input type="type" id="searchForm" class="form-control" placeholder="Type to search"
                         [(ngModel)]="searchString" name="searchString"
                         (input)="onFormNameSearching($event.target.value)" autocomplete="off">
                  <div class="has-error">
                    <span class="pl-3 mb-2" *ngIf="!isSearchNameValid(searchString)"><i class="material-icons">warning</i>Please enter a valid Form Name</span>
                  </div>
                </div>


                <div class="col-md-4 typeahead-wrapper" *ngIf="productSection">
                  <label for="product">Product Name:</label>
                  <fdl-type-ahead [formControl]="product" name="product" optionField="title" placeholder="Type to search"
                                  [collection]="productSuggestion" [scrollable]="true" (onSelect)="onProductSearching()" [optionsLimit]="100" [minLength]="0"
                                  (input)="onProductSearching()" >
                  </fdl-type-ahead>
                </div>
                <div class="filter-form-control col-md-4 pr-4">
                  <label for="Status">Form Type:</label>
                  <div class="fdl-select">
                    <select name="Status" class="form-control" [disabled]="checkDisable(currentIndex)" (change)="onFormTypeChanged()" aria-invalid="false" [(ngModel)]="formType">
                      <option value="Data Gathering" selected>Data Gathering</option>
                      <option value="intake">Intake</option>
                      <option value="Project Workflow Template">Project Workflow Template</option>
                    </select>
                  </div>
                </div>

              </div>
              <div *ngIf="formPageList" class="overflow-300 assign-modal-table table-responsive">

                <app-data-table [rows]="formPageList" [(formSets)]="formSets" [counter]="currentIndex"
                                [searchString]="searchString" [keys]="keys" [enableSorting]="true"
                                [displayColumns]="displayColumns" [showCheckbox]="!!(formPageList.length>0)" 
                                [showMenu]="false" (sortingClicked)="applySorting($event)">
                </app-data-table>

                <div>
                  <app-pagination
                    *ngIf="
                      pagerObject &&
                      pagerObject.totalPages > 1 &&
                      formPageList &&
                      formPageList.length > 0 
                    "
                    [pagerObject]="pagerObject"
                    (pagingChanged)="changePage($event)"
                  ></app-pagination>
                </div>
              </div>
            </div>
            <div class="modal-footer fdl-2">
              <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal"
              (click)="cancelForms();">Cancel</button>
              <button type="button" class=" btn fdl-btn btn-primary-orange ml-2"
              (click)="assignSelectedForms();">Assign</button>

            </div>
        </div>

    </div>
</div>
