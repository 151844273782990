export interface IReport {
  id: number,
  reportName: string,
  reportDescription: string,
  reportURL: string,
  organizationId?: number,
  organizationTitle: string
  productId?: number,
  productTitle: string
  formId?: number,
  formTitle: string,
  level: string;
  entityName: string;
  visibility: boolean;
  modifiedByName: string;
  modifiedOn: string;
  entityLevel: string;
}

export interface IReports{
  reports: IReport[];
}

// export interface ConfigurationResp {
//   id: number;
//   key: string;
//   value: string;
//   level: string;
//   entityName: string;
//   modifiedOn: string;
//   modifiedBy: string;
//   organizationId?: number;
//   organizationTitle?: string;
//   productId?: number;
//   productTitle?: string;
//   formId?: number;
//   formTitle?: string;
// }

export class ReportCreateUpdateReq {
  id?: number = 0;
  reportName: string;
  reportDescription: string;
  reportURL: string;
  organizationId?: number;
  organizationTitle: string;
  productId?: number;
  productTitle: string;
  formId?: number;
  formTitle: string;
  visibility: boolean
}

export interface ReportResolved {
  report: IReport;
  error?: any;
}
