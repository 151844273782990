import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProjectsDTOService } from '../../Services/projectsDTO.service';

@Component({
  templateUrl: './project-main.component.html'
})
export class ProjectMainComponent implements OnInit, OnDestroy {

  constructor(private DTOService: ProjectsDTOService) { }


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.DTOService.setEditProjectStatus(false);
  }
}
