import { Injectable } from "@angular/core";
import { PERMISSIONS, ROLES } from '../core/constants';
import { UserService } from './user.service';
import { IUser } from '../shared/user';
import { IForm } from '../shared/form';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  userData: IUser;
  constructor(private userService: UserService) { }

  setUserData(data: IUser) {
    this.userData = data;
  }

  isCreateProjectAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["projectcreate"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isDeleteProjectAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["projectdelete"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isEditProjectAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["projectedit"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isCreateFormAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["formcreate"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isEditFormAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["formedit"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isDeleteFormAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["formdelete"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isDeleteResponseAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["responsedelete"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showProductFilter() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showproductlink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }


  showProjectFilter() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showprojectlink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isClientFilterAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userData && this.userData.type.toLowerCase() === "fiserv") {
      isAllowed = true;
    }
    return isAllowed;
  }

  isAssignResponsesAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && (this.userService.getPermissions()["projectedit"] || this.userService.getPermissions()["responsecreate"])) {
      isAllowed = true;
    }
    return isAllowed;
  }
  showUserLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showuserlink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  // Bug 249350 - START
  showRoleLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    return isAllowed;
  }
  // Bug 249350 - END

  showFormLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showformlink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isCreateUserAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["usercreate"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isCreateRoleAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["rolecreate"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isCreateClientAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["clientcreate"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isEditClientAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["clientedit"]) {
      isAllowed = true;
    }
    return isAllowed;
  }
  
  isCreateProductAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()['productcreate']) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showClientLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showclientlink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showReportLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showreportlink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showHelpLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["helpCreate"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showProductLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showproductlink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showManageAssets() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else {
      isAllowed = (this.userService.getPermissions()['showmanageassets']);
    }
    return isAllowed;
  }

  isCreateGroupAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else {
      isAllowed = (this.userService.getPermissions()['showgroup']);
    }
    return isAllowed;
  }

  isChangeApproverReinitiateAllowed(organizationId: number) {
    let isAllowed = false;
    const user = this.userService.getUserListData();
    const allowedPermissions = [PERMISSIONS.Responses_ReadWrite_All, PERMISSIONS.Responses_Edit_All];
    if (user.isAdmin) {
      isAllowed = true;
    }
    else if (organizationId === user.organization) {
      isAllowed = user.permissions.some(value => allowedPermissions.includes(value));
    }
    return isAllowed;
  }

  isCreateFIMAllowed() {
    let isAllowed = false;
    const editAllowedPermission = [PERMISSIONS.Projects_ReadWrite_All];
    const user = this.userService.getUserListData();
    if (user.isAdmin) {
      isAllowed = true;
    }
    else isAllowed = user.permissions.some(value => editAllowedPermission.includes(value));
    return isAllowed;
  }

  isCreateEmailTemplateAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["usercreate"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showWorkflowLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin
    ) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showWorkflowLink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showRequestExtractsLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showResponseExtractsLink"]
    ) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showHelpCategoryLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showhelpcategorylink"]
    ) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showHelpArticleLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showhelpArticlelink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showResourceCenterLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showResourceCenterLink"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showformExtractionTemplateLink() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["showformExtractionTemplateLink"]
    ) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isCreateReportAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["reportcreate"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isDeleteReportAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["reportdelete"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isEditReportAllowed() {
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin) {
      isAllowed = true;
    }
    else if (this.userService.getPermissions() && this.userService.getPermissions()["reportedit"]) {
      isAllowed = true;
    }
    return isAllowed;
  }

  isPublishCountViewAllowed() {
    let isAllowed = false;
    for (let role of this.userData.roles) {
      isAllowed = role.roleTitle === ROLES.Admin ||
                  role.roleTitle === ROLES.Fiserv_Division_Admin ||
                  role.roleTitle === ROLES.Fiserv_Portfolio_Manager;
      if (!isAllowed) {
        break;
      }
    }
    return isAllowed;
  }
      
  isUpdateInflightResponsesAllowed() {
    return this.isFiservImpAndAbove();
  }

  isAddNewUserFromDelegateScreenAllowed() {
    return this.isFiservImpAndAbove();
  }

  canUpdateProjectStatus() {
    return this.isFiservImpAndAbove();
  }

  isFiservImpAndAbove() {
    const validRoles = ["Admin", "Fiserv Division Admin", "Fiserv Portfolio Manager", "Fiserv Implementation Manager"];
    let isAllowed = false;
    if (this.userData && this.userData.isAdmin && this.userData.roles.some(role => validRoles.includes(role.roleTitle))) {
      isAllowed = true;
    }
    return isAllowed;
  }
  
  canAccessAdministrationTab() {
    const validRoles = ["Admin", "Fiserv Division Admin", "Fiserv Portfolio Manager", "Fiserv Portfolio Manager - Read Only"];
    let isAllowed = false;
    if (this.userData && this.userData.roles.some(role => validRoles.includes(role.roleTitle))) {
      isAllowed = true;
    }
    return isAllowed;
  }

  canAccessProjectTab() {
    const validRoles = [ROLES.Admin.toString(),
       ROLES.Fiserv_Division_Admin.toString(),
       ROLES.Fiserv_Portfolio_Manager.toString(),
       ROLES.Fiserv_Portfolio_Manager_Read_Only.toString(),
       ROLES.Channel_Partner.toString(),
       ROLES.Client_Implementation_Manager.toString(),
       ROLES.Fiserv_Implementation_Manager.toString()];
    let isAllowed = false;
    if (this.userData && this.userData.roles.some(role => validRoles.includes(role.roleTitle))) {
      isAllowed = true;
    }
    return isAllowed;
  }

  showConfigurationLink() {
    let isAllowed = false;
    if (this.userData && (this.userData.isAdmin || this.userData.roles.some(role => role.roleTitle === 'Fiserv Division Admin')
    )) {
      isAllowed = true;
    }
    return isAllowed;
  }  
}
