import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../SharedModule/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormioModule } from 'angular-formio';
import { FdlDataGridModule, FdlTypeaheadModule } from 'fdl-ngx11-bootstrap4';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { CategoryComponent } from 'src/app/help/components/category/category.component';
import { HelpComponent } from './help.component';
import { HelpRoutingModule } from './help-routing.module';
import { TopicListComponent } from './components/topic-list/topic-list.component';
import { TopicSideBarComponent } from './components/topic-side-bar/topic-side-bar.component';
import { TopicDetailsComponent } from './components/topic-details/topic-details.component';

@NgModule({
  declarations: [
    CategoryComponent, 
    HelpComponent, 
    TopicListComponent,
    TopicSideBarComponent, 
    TopicDetailsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    HelpRoutingModule,
    FormioModule,
    FormsModule,
    ReactiveFormsModule,
    FdlTypeaheadModule,
    AngularEditorModule,
    FdlDataGridModule,
  ],
})
export class HelpModule { }
