import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, ReplaySubject, of } from "rxjs";
import { IProjects, IProject } from '../shared/project';
import { ISuggestions } from '../shared/suggestion';
import { IForm, IForms } from '../shared/form';
import { CustomHttpParamEncoder } from '../shared/custom-encoder';
import { FileUploadHeader } from "../core/constants";
import { IProjectTimeline } from "../shared/timeline";
import { IUser, IUsersList } from "../shared/user";
import { IInputStakeholder, IStakeHolder } from "../shared/stakeholder";
import { IQuery } from '../shared/query';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { map } from "rxjs-compat/operator/map";
import { catchError, first } from "rxjs/operators";
import internal from "events";

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  private projectAPIURL = 'projects';
  private exportprojectAPIURL = 'projects/ExportProjects';
  private responsesAPIURL = 'responses';
  private exportresponsesAPIURL = 'responses/ExportFormResponses';
  private exportProjectFormsURL = 'projects/ExportProjectForms'
  private formsAPIURL = 'forms';
  private exportformsAPIURL = 'forms/ExportForms';
  private organizationAPIURL = 'organizations';
  private managerAPIURL = 'managers';
  private stakeholdersUsersAPIURL = 'stakeholders';
  private projectDetails: any = {};
  public projVisibility$ = new BehaviorSubject<number>(-1);
  public clientVisibility$ = new BehaviorSubject<number>(0)

  // START | PBI 168948
  private stakeholdersAPIURL = "projectstakeholders";
  // END | PBI 168948

  // PBI 170532 >> START
  private _isCopyForms: boolean = false;
  private _selectedProjectCopyForms: number = 0;
  // PBI 170532 >> END

  constructor(private http: HttpClient) {
  }

  // PBI 170532 >> START
  set isCopyForms(value) {
    this._isCopyForms = value;
  }

  get isCopyForms() {
    return this._isCopyForms;
  }

  set selectedProjectCopyForms(value) {
    this._selectedProjectCopyForms = value;
  }

  get selectedProjectCopyForms() {
    return this._selectedProjectCopyForms;
  }
  // PBI 170532 >> END

  postResponseComment(params, id: number) {
    let httpOptions = {
      headers: new HttpHeaders({
        [FileUploadHeader]: ""
      })
    };
    const fd = new FormData();
    for (let key of Object.keys(params)) {
      let value = params[key];
      fd.append(key, value);
    }

    return this.http.post<any>(`${this.projectAPIURL}\\${id}\\comments`, fd, httpOptions);
  }

  deleteResponseComment(Id: number, formId: number) {
    return this.http.delete(`${this.projectAPIURL}\\${formId}\\comments\\${Id}`);
  }

  updateResponseComment(formsParams, Id: number, formId: number) {
    let httpOptions = {
      headers: new HttpHeaders({
        [FileUploadHeader]: ""
      })
    };
    const fd = new FormData();
    for (let key of Object.keys(formsParams)) {
      let value = formsParams[key];
      fd.append(key, value);
    }
    return this.http.put<any>(`${this.projectAPIURL}\\${formId}\\comments\\${Id}`, fd, httpOptions);
  }

  getProjectsByUser(pageNo: number = 1, pageSize: number = 30): Observable<HttpResponse<IProjects>> {
    let url = this.projectAPIURL + `?pageNumber=${pageNo}&pageSize=${pageSize}`;
    return this.http.get<IProjects>(url, { observe: 'response' });
  }

  getProjectsByUserToCopyProject(): Observable<HttpResponse<IProjects>> {
    let url = this.projectAPIURL
    return this.http.get<IProjects>(url, { observe: 'response' });
  }

  createProject(projParams) {
    return this.http.post<IProjects>(this.projectAPIURL, projParams);
  }



  updateProject(projectId: number, updatedRequest) {
    return this.http.put<IProject>(this.projectAPIURL + "/" + projectId, updatedRequest);
  }
  assignIntakeToProject(projectId: number, updateRequest) {
    return this.http.put<IForms>(this.projectAPIURL + "/" + projectId + "/intake", updateRequest);
  }
  getProjectList(queryParams) {

    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });


    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    return this.http.get(this.projectAPIURL, { params: params, observe: 'response' });
  }

  getRefreshProjectList(queryParams) {

    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };


    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    return this.http.get(this.projectAPIURL, { headers: httpOptions.headers, params: params, observe: 'response' });
  }

  exportProjectList(queryParams) {

    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });

    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    return this.http.get(this.exportprojectAPIURL, { headers: httpOptions.headers, params: params, observe: 'response', responseType: 'blob' });

    //return this.http.put<any>(`${this.exportprojectAPIURL}`, params,

    //  {

    //    observe: 'response', responseType: 'blob' as 'json'

    //  });
  }

  // PBI 170532 >> START
  getProjectsByProductTitle(productTitle: string) {
    return this.http.get<IProjects>(this.projectAPIURL + "?ProductTitle=" + productTitle);
  }

  getResponsesByProjectId(projectId: number) {
    return this.http.get(this.responsesAPIURL + "?ProjectId=" + projectId);
  }
  // PBI 170532 >> END

  getProjectById(projectId: number) {
    return this.http.get<IProject>(this.projectAPIURL + '/' + projectId);
  }

  getSuggestionsResponse(entity, titleValue, paramName = 'title', queryParams: IQuery, ignoreFavorites: boolean = true) {
    let options = { 'refresh': 'true' }
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    const suggestionAPIURL = `${entity}/paginatedSuggestions?${paramName}=${encodeURIComponent(titleValue)}&ignoreFavorites=${ignoreFavorites}`;
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options, params, observe: 'response' });
  }

  getSuggestionsResponseWithOrgId(
    entity: string,
    titleValue: string,
    paramName = 'title',
    queryParams: IQuery,
    ignoreFavorites: boolean = true,
    orgId: number = 0) {

    const options = { 'refresh': 'true' }
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    const suggestionAPIURL = `${entity}/paginatedSuggestions?${paramName}=${encodeURIComponent(titleValue)}&ignoreFavorites=${ignoreFavorites}&orgId=${orgId}`;
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options, params, observe: 'response' });
  }

  getSuggestionsWithoutFavoritesResponse(entity, paramName, paramValue, titleValue, ignoreFavorites: boolean = false) {
    const suggestionAPIURL = `${entity}/paginatedSuggestions?${paramName}=${paramValue}&title=${encodeURIComponent(titleValue)}&ignoreFavorites=${ignoreFavorites}&takeNumber=100`;
    return this.http.get<ISuggestions>(suggestionAPIURL, { observe: 'response' });
  }

  getSuggestions(entity, titleValue, paramName = 'title') {
    let options = { 'refresh': 'true' }
    const suggestionAPIURL = `${entity}/suggestions?${paramName}=${encodeURIComponent(titleValue)}`;
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options });
  }
  getProductSuggestions(entity, params: Array<{ paramName: string, value: string | number }>, takeNumber?: number) {
    let options = { 'refresh': 'true' }
    let suggestionAPIURL = `${entity}/paginatedSuggestions`;
    if (params && params.length > 0) {
      suggestionAPIURL = suggestionAPIURL + '?';
      params.forEach(element => {
        suggestionAPIURL = suggestionAPIURL + (params.indexOf(element) > 0 ? '&' : '') + `${element.paramName}=${encodeURIComponent(element.value)}`
      });
    }
    if (takeNumber) {
      suggestionAPIURL = suggestionAPIURL + (params && params.length > 0 ? '&' : '?') + `takeNumber=${encodeURIComponent(takeNumber)}`
    }
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options });
  }

  getClientSuggestions(entity, params: Array<{ paramName: string, value: string | number }>, takeNumber?: number) {
    let options = { 'refresh': 'true' }
    let suggestionAPIURL = `${entity}/paginatedSuggestions`;
    if (params && params.length > 0) {
      suggestionAPIURL = suggestionAPIURL + '?';
      params.forEach(element => {
        suggestionAPIURL = suggestionAPIURL + (params.indexOf(element) > 0 ? '&' : '') + `${element.paramName}=${encodeURIComponent(element.value)}`
      });
    }

    if (takeNumber) {
      suggestionAPIURL = suggestionAPIURL + (params && params.length > 0 ? '&' : '?') + `takeNumber=${encodeURIComponent(takeNumber)}`
    }
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options });
  }

  getClientSuggestionsWithMDM(entity, params: Array<{ paramName: string, value: string | number }>, takeNumber?: number) {
    let options = { 'refresh': 'true' }
    let suggestionAPIURL = `${entity}/paginatedsuggestionswithmdm`;
    if (params && params.length > 0) {
      suggestionAPIURL = suggestionAPIURL + '?';
      params.forEach(element => {
        suggestionAPIURL = suggestionAPIURL + (params.indexOf(element) > 0 ? '&' : '') + `${element.paramName}=${encodeURIComponent(element.value)}`
      });
    }

    if (takeNumber) {
      suggestionAPIURL = suggestionAPIURL + (params && params.length > 0 ? '&' : '?') + `takeNumber=${encodeURIComponent(takeNumber)}`
    }
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options });
  }

  getPaginatedSuggestions(entity, params: Array<{ paramName: string, value: string | number }>, takeNumber?: number) {
    let options = { 'refresh': 'true' }
    let suggestionAPIURL = `${entity}/paginatedSuggestions`;
    if (params && params.length > 0) {
      suggestionAPIURL = suggestionAPIURL + '?';
      params.forEach(element => {
        suggestionAPIURL = suggestionAPIURL + (params.indexOf(element) > 0 ? '&' : '') + `${element.paramName}=${encodeURIComponent(element.value)}`
      });
    }

    if (takeNumber) {
      suggestionAPIURL = suggestionAPIURL + (params && params.length > 0 ? '&' : '?') + `takeNumber=${encodeURIComponent(takeNumber)}`
    }
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options });
  }

  getPackageSuggestions(entity, titleValue, paramName = 'title') {
    let options = { 'refresh': 'true' }
    const suggestionAPIURL = `${entity}/packagesuggestions?${paramName}=${encodeURIComponent(titleValue)}`;
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options });
  }

  getResponseList(queryParams) {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.responsesAPIURL, { headers: httpOptions.headers, params: params, observe: 'response' });
  }

  getRefreshResponseList(queryParams) {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.responsesAPIURL, { headers: httpOptions.headers, params: params, observe: 'response' });
  }

  exportResponseList(queryParams) {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });

    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.exportresponsesAPIURL, { headers: httpOptions.headers, params: params, observe: 'response', responseType: 'blob' });

    //return this.http.put<any>(`${this.exportresponsesAPIURL}`, params,

    //  {

    //    observe: 'response', responseType: 'blob' as 'json'

    //  });
  }

  exportProjectFormsById(projectId: number) {
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    return this.http.get(this.exportProjectFormsURL + '/' + projectId, { headers: httpOptions.headers, observe: 'response', responseType: 'blob' });
    //var params = new HttpParams ();
    //return this.http.put<any>(`${this.exportProjectFormsURL}/` + projectId, params,
    //  {

    //    observe: 'response', responseType: 'blob' as 'json'

    //  });
  }

  getFormList(queryParams, ignoreFavorites: boolean = false) {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    if (ignoreFavorites) {
      params = params.append('ignoreFavorites', ignoreFavorites.toString());
    }
    return this.http.get(this.formsAPIURL, { params: params, observe: 'response' });
  }

  getRefreshFormList(queryParams, ignoreFavorites: boolean = false) {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });

    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    if (ignoreFavorites) {
      params = params.append('ignoreFavorites', ignoreFavorites.toString());
    }
    return this.http.get(this.formsAPIURL, { headers: httpOptions.headers, params: params, observe: 'response' });
  }

  exportFormList(queryParams, ignoreFavorites: boolean = false) {
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    if (ignoreFavorites) {
      params = params.append('ignoreFavorites', ignoreFavorites.toString());
    }
    return this.http.get(this.exportformsAPIURL, {
      headers: httpOptions.headers, params: params, observe: 'response', responseType: 'blob'
    });

    //return this.http.put<any>(`${this.exportformsAPIURL}`, params,

    //  {

    //    observe: 'response', responseType: 'blob' as 'json'

    //  });
  }

  getFormSuggestions(userInput, productTitle, ignoreFavorites: boolean = false) {
    if (!productTitle) productTitle = "";
    let formSuggestionURL = `${this.formsAPIURL}?title=${encodeURIComponent(userInput)}&ProductTitle=${encodeURIComponent(productTitle)}`;
    if (ignoreFavorites)
      formSuggestionURL += `&ignoreFavorites=${encodeURIComponent(ignoreFavorites)}`;
    return this.http.get<IForms>(formSuggestionURL);
  }

  getFormById(Id: number) {
    const formURL = `${this.formsAPIURL}/${Id}`;
    return this.http.get<IForm>(formURL);
  }

  getProjectsByOrgId(orgId: number) {
    const orgProjectsURL = `${this.organizationAPIURL}/${orgId}/${this.managerAPIURL}`;
    return this.http.get<ISuggestions>(orgProjectsURL);
  }

  getProjectsByProductId(orgId: number, productId: number) {
    const orgProjectsURL = `${this.organizationAPIURL}/${orgId}/${productId}/${this.managerAPIURL}`;
    return this.http.get<ISuggestions>(orgProjectsURL);
  }

  getProjectsStakeHolders(orgId: number, productId: number) {
    const orgProjectsURL = `${this.organizationAPIURL}/${orgId}/${productId}/${this.stakeholdersUsersAPIURL}`;
    return this.http.get<ISuggestions>(orgProjectsURL);
  }

  fetchIdForRequest(suggestions: any, selectedVal: string): number {
    let suggestionArr = [];
    suggestionArr = suggestions;
    let foundObj = suggestionArr.find(suggestion => suggestion.title ? (suggestion.title === selectedVal) : (suggestion.name === selectedVal));
    return foundObj ? foundObj.id : -1;
  }
  fetchClientIdByTitleWithDuns(suggestions: any, selectedVal: string): number {
    let suggestionArr = [];
    suggestionArr = suggestions;
    let foundObj = suggestionArr.find(suggestion => suggestion.titleWithDuns ? (suggestion.titleWithDuns === selectedVal) : (suggestion.title === selectedVal));
    return foundObj ? foundObj.id : -1;
  }
  fetchIdForClient(suggestions: any, selectedVal: string): number {
    let suggestionArr = [];
    suggestionArr = suggestions;
    let foundObj = suggestionArr.find(suggestion => suggestion.titleWithDuns ? (suggestion.titleWithDuns === selectedVal) : (suggestion.title === selectedVal));
    return foundObj ? foundObj : -1;
  }
  fetchDetailsForUserById(suggestions: any, selectedVal: string): any {
    let suggestionArr = [];
    suggestionArr = suggestions;
    let foundApproverObj = suggestionArr.find(suggestion => (suggestion.id === selectedVal));

    return foundApproverObj ? foundApproverObj : -1;
  }

  fetchDetailsForUserByUserId(suggestions: any, userId: number): any {
    let suggestionArr = [];
    suggestionArr = suggestions;
    let foundApproverObj = suggestionArr.find(suggestion => (suggestion.id === userId));

    return foundApproverObj ? foundApproverObj : -1;
  }

  fetchDetailsForUserByEmail(suggestions: any, selectedVal: string): any {
    let suggestionArr = [];
    suggestionArr = suggestions;
    let foundApproverObj = suggestionArr.find(suggestion => (suggestion.email === selectedVal));
    return foundApproverObj ? foundApproverObj : -1;
  }
  fetchEmailForApprover(suggestions: any, selectedVal: string): any {
    let suggestionArr = [];
    suggestionArr = suggestions;
    let foundApproverObj = suggestionArr.find(suggestion => suggestion.title ? (suggestion.title === selectedVal) : (suggestion.name === selectedVal));
    return foundApproverObj ? foundApproverObj.email : -1;
  }
  fetchEmail(suggestions: any, value: string): any {
    let suggestionArr = [];
    suggestionArr = suggestions;
    let foundObjForEmail = suggestionArr.find(suggestion => suggestion.title ? (suggestion.title === value) : (suggestion.name === value));
    return foundObjForEmail ? foundObjForEmail.email : -1;
  }


  deleteProject(projectId: number): Observable<any> {
    return this.http.delete<any>(`${this.projectAPIURL}\\${projectId}`);
  }

  getProjectDetails(): string {
    return this.projectDetails;
  }

  setProjectDetails(projectDetails: any = {}): void {
    this.projectDetails = projectDetails;
  }


  setProjVisibility(projId: number) {
    this.projVisibility$.next(projId);
  }

  clientVisibility(clientId: number) {
    this.clientVisibility$.next(clientId);
  }

  getSuggestionsWithoutFavorites(entity, paramName, paramValue) {
    const suggestionAPIURL = `${entity}/suggestions?${paramName}=${paramValue}&ignoreFavorites=true`;
    return this.http.get<ISuggestions>(suggestionAPIURL);
  }

  getTodaysDate() {
    const today = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + new Date().getDate()).slice(-2);
    return today;
  }

  getProjectTimeline(projectId: number, sorting: string): Observable<IProjectTimeline[]> {
    let options = { 'refresh': 'true' }
    return this.http.get<IProjectTimeline[]>(this.projectAPIURL + "/projectTimeline/" + projectId + "?sorting=" + sorting, { headers: options });

    // return this.http.get<IProjectTimeline[]>(this.projectAPIURL + "/projectTimeline/" + projectId + "?sorting=" + sorting + "&ignore=" + Math.random());
  }

  bulkRequest(reqType, formData) {
    let httpOptions = {
      headers: new HttpHeaders({
        [FileUploadHeader]: ""
      })
    };

    let url: string;
    if (reqType === 'Project')
      url = this.projectAPIURL + '/bulkprojectrequest';
    else if (reqType === 'Form')
      url = 'prepopulate/stagebulkformload';

    const fd = new FormData();
    for (let key of Object.keys(formData)) {
      let value = formData[key];
      fd.append(key, value);
    }

    return this.http.post(url, fd, httpOptions);
  }
  // START | PBI-168948
  getUsersForOrgId(orgId: number) {
    return this.http.get<IUser[]>(`organizations/${orgId}/users?IncludeClientUsers=false`);
    //return this.http.get<IUser[]>("/api/projectstakeholders/username/suggestions");
  }

  getStakeholders(id: number) {
    return this.http.get<IStakeHolder[]>(`${this.stakeholdersAPIURL}/${id}`);
  }

  addStakeholders(stakeholders: IInputStakeholder[], productId: number) {
    return this.http.post(this.stakeholdersAPIURL + "?ProductId=" + productId, stakeholders);
  }

  deleteStakeholder(id: number) {
    return this.http.delete(`${this.stakeholdersAPIURL}/${id}`);
  }

  setStakeholders(id: number, stakeholders: IInputStakeholder[], productId: number) {
    return this.http.put<IStakeHolder[]>(`${this.stakeholdersAPIURL}/${id}?ProductId=${productId}`, stakeholders);
  }

  // END | PBI-168948

  // START | BUG FIX - 246304
  // function to get project by title
  getProjectByTitle(projectTitle: string) {
    return this.http.get<IProjects>(this.projectAPIURL + '?' + "Title=" + projectTitle);
  }
  // END | BUG FIX - 246304

  hold(id, params) {
    return this.http.put<any>(`${this.projectAPIURL}/${id}/hold`, params);
  }

  reject(id, params) {
    return this.http.put<any>(`${this.projectAPIURL}/${id}/reject`, params);
  }
}
