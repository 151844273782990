import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { take } from 'rxjs/internal/operators/take';
import { MasterService } from 'src/app/services/master.service';
import { ResponseExtractService } from 'src/app/services/response-extract.service';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { IResponseExtract, IResponses } from 'src/app/shared/response-extract';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'view-responses',
  templateUrl: './view-responses.component.html'
})

export class ViewResponsesComponent implements OnInit {
  isError: boolean = false;
  errorMsg: string = '';
  sortingObject;
  queryParam: IQuery;
  currentPageNumber = 1;
  pagerObject: IPager;
  extractRequestName: string;
  extractRequestId: number;
  responsesList: IResponses;
  responseExtract: IResponseExtract;
  displayColumns = ['ID', 'Project Title', 'Form Name', 'Client Name', 'Respondent Name', 'Approver Name', 'Current Assignee','Package Name','Status', 'Due Date', 'Last Updated User', 'Last Updated Date'];
  keys = ['responseId', 'projectTitle', 'responseTitle', 'clientTitle', 'assignedToName', 'currentApproverName', 'editAccessWith','package' ,'responseStatusTitle', 'dueDate', 'modifiedByName', 'modifiedOn'];
  extractType: string;
  isCoverSheet: string;
  isComment: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private responseExtractService: ResponseExtractService,
    private masterService: MasterService
  ) { }

  ngOnInit(): void {
    this.route.params
    .pipe(take(1))
    .subscribe((params) => {
      if (this.responseExtractService.getExtractDetails) {
        this.extractRequestName = this.responseExtractService.getExtractDetails.extractRequestName;
        this.extractRequestId = this.responseExtractService.getExtractDetails.id;
      }
      else {
        this.extractRequestId = params['id'];
      }
    });
    this.initQueryParams();
    this.callExtractionListService();
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize,
      extractRequestId: this.extractRequestId
    });
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callExtractionListService();
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callExtractionListService();
  }

  setOrderBy(sortingData) {
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  refresh() {
    this.callExtractionListService();
  }

  callExtractionListService() {
    this.responseExtractService.getResponsesList(this.extractRequestId, this.queryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.isError = false;
          this.responsesList = responses as IResponses;
          this.masterService.resetFiltersCancelledFlag();
        },
      (error)=> {
        this.isError = true;
        this.errorMsg = error;
      });

    this.responseExtractService.getResponseExtractById(this.extractRequestId)
      .pipe(take(1))
      .subscribe(
        response => {
          this.responseExtract = response;
          this.extractRequestName = response.extractRequestName;
          this.extractType = response.formExtractionTemplateId > 0
                              ? (response.extractType.toString() === '1' ? 'Customised PDF Extract' : response.extractType.toString() === '2' ? 'Customised Excel Extract' : response.extractType.toString() === '3' ? 'Customised CSV Extract' :
                                response.extractType.toString() === '4' ? 'Customised Json Extract': response.extractType.toString() === '5' ? 'Customised Word Extract' : response.extractType.toString() === '6' ? 'Customised XML Extract' : 'N/A')
                              : (response.extractType.toString() === '1' ? 'PDF Extract' : response.extractType.toString() === '2' ? 'Excel Extract' : response.extractType.toString() === '3' ? 'CSV Extract' :
                                response.extractType.toString() === '4' ? 'Json Extract': response.extractType.toString() === '5' ? 'Word Extract' : response.extractType.toString() === '6' ? 'XML Extract' : 'N/A');
          this.isCoverSheet = response.includeCoverSheet ? 'Yes' : 'No';
          this.isComment = response.includeComments ? 'Yes' : 'No';
        },
      (error)=> {
        this.isError = true;
        this.errorMsg = error;
      });
  }

  goBack() {
    this.router.navigate(['/self-service/requestExtracts']);
  }
}
