import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IQuickSearchItems } from '../admin/quick-search/quick-search-item';
import { IQuery } from '../shared/query';
import { CustomHttpParamEncoder } from '../shared/custom-encoder';

@Injectable({
  providedIn: 'root',
})
export class QuickSearchService {
  private quickSearchApi = 'quickSearch';
  constructor(private http: HttpClient) { }

  public search(searchText: string, searchType: string, queryParam: IQuery, isRefresh = false): Observable<any> {
    let params = isRefresh
      ? new HttpParams({ encoder: new CustomHttpParamEncoder()})
      : new HttpParams();

    let httpOptions = {
        headers: new HttpHeaders({
          refresh: isRefresh ? "true" : "false"
        })
      };

    const queryParams = {
      searchText,
      searchType,
      pageSize: queryParam.pageSize,
      pageNumber: queryParam.pageNumber,
      orderBy: queryParam.orderBy ? queryParam.orderBy : 'modifiedOn desc'
    }

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    };

    return this.http.get<IQuickSearchItems>(this.quickSearchApi, {
      headers: httpOptions.headers,
      params,
      observe: 'response',
    });
  }
}
