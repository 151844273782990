<div class="heading-container fdl-2">
  <h1>{{pageTitle}}</h1>
</div>


<div class="form-container  separate-form-container">
  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="successMsg=''">×</button>
    <span class="iconLabel" aria-label="sucess"></span>
    <i class="material-icons" aria-hidden="true">check_circle</i>
    <p>{{successMsg}}
     
    </p>
  </div>
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>

  <app-report-form [isEditMode]="isEditMode" [entities]="entities" (onErrorEvent)="onError($event)"></app-report-form>

</div>

