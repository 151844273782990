import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageTemplateService } from 'src/app/services/page-template.service';

@Component({
  selector: 'app-review-commitment-modal',
  templateUrl: './review-commitment-modal.component.html',
  styleUrls: ['./review-commitment-modal.component.css']
})
export class ReviewCommitmentModalComponent implements OnInit {

  @Input()
  public clientId = 0;

  @Output()
  private submit = new EventEmitter<void>();

  public comment = '';
  public isReviewed = false;

  constructor(private pageTemplateService: PageTemplateService) { }

  ngOnInit(): void {
  }

  public onSubmit(): void {
    if (this.isReviewed && this.comment.trim() !== '') {
      this.pageTemplateService.updateReviewForAllCommitmentsByClientId(this.clientId, this.comment)
        .subscribe(() => {
          this.isReviewed = false;
          this.comment = '';
          this.submit.emit();
        });
    }
  }
}
