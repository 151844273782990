import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-editable-field',
  templateUrl: './editable-field.component.html',
  styleUrls: ['./editable-field.component.css']
})
export class EditableFieldComponent implements OnInit {

  constructor(private fb: FormBuilder) { }

  @Input() value: string = '';
  @Input() key: string = '';
  @Input() id: any;
  @Output() editField = new EventEmitter<{ id: any, key: string, value: string}>();
  showLabel = true;
  fieldFormGroup: FormGroup;

  ngOnInit(): void {
  }

  switchLabel() {
    this.showLabel = !this.showLabel;
    if (!this.showLabel) {
      this.fieldFormGroup = this.fb.group({
        label: new FormControl(this.value)
      });
    } else {
      this.value = this.fieldFormGroup.controls["label"].value;
      this.editField.emit({id: this.id, key: this.key, value: this.value})
    }
  }

  keyPress(event: KeyboardEvent) {
    if(event.code === "Enter") {
      this.switchLabel();
    }
  }

}