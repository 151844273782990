import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../SharedModule/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FdlDataGridModule, FdlTypeaheadModule } from 'fdl-ngx11-bootstrap4';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { QuickSearchComponent } from './quick-search.component';
import { QuickSearchRoutingModule } from './quick-search-routing.module';

@NgModule({
  declarations: [
    QuickSearchComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    QuickSearchRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FdlTypeaheadModule,
    AngularEditorModule,
    FdlDataGridModule,
  ],
})
export class QuickSearchModule { }
