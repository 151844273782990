import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { MasterService } from "src/app/services/master.service";
import { IReport, ReportResolved } from "src/app/shared/report";
import { ReportsService } from "src/app/services/report.services";

@Component({
  templateUrl: './report-view.component.html'
})
export class ReportViewComponent implements OnInit, OnDestroy {

  reportDetail: IReport;

  showEditButton: boolean = true;

  isError: boolean = false;
  errorMessage: string;
  successMsg: string = '';
 


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private masterService: MasterService,
    private reportService: ReportsService ) { }

  ngOnInit(): void {
    const resolvedData: ReportResolved = this.route.snapshot.data['resolvedData'];
    this.setErrorMessage(resolvedData.error);
    this.onReportRetrieved(resolvedData.report);
    this.successMsg = this.reportService.isReportSaved;
  }


  onReportRetrieved(data: IReport): void {

    if(data.organizationId != null &&  data.organizationId != undefined ){
     data.level = 'organization';
     data.entityName = data.organizationTitle;
    } else if (data.productId != null &&  data.productId != undefined ) {
      data.level = 'product';
      data.entityName = data.productTitle;
    } else if (data.formId != null &&  data.formId != undefined ) {
      data.level = 'form';
      data.entityName = data.formTitle;
    }  else {
      data.level = 'system';
    }

    this.reportDetail = data;
  }

  setErrorMessage(err: string ){
    if(err){
      this.errorMessage = err;
      this.isError = true;
    }
  }

  goBack() {
    this.masterService.isCancelledClicked(true);
    this.router.navigate(['self-service/report']);
  }

  goToEdit() {
    this.router.navigate(["../../edit", this.route.snapshot.params.id], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.reportService.isReportSaved = "";
    this.isError = false;
    this.errorMessage = "";
    this.successMsg = "";
  }

}
