import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.css']
})
export class SelfServiceComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
