export const drawerConstant = {
    'FISV_IMPL_MGR': {
        heading: "Create New Project Manager",
        drawerData: {
            roleKeywords: ['fiserv implementation manager', 'respondent'],
            userTypeTooltip: 'To create a client user, please select a client on the previous page.',
            hideUserControl: true,
            addNewFiservManager: true
        }
    },
    'RESPONDENT': {
        heading: "Create New User (Respondent/Approver)",
        drawerData: {
            roleKeywords: ['respondent'],
            userTypeTooltip: 'To create a client user, please select a client on the previous page.',
            hideUserControl: false,
            addNewFiservManager: false
        }
    },
    'RESPONDENT_FOR_EDIT_PROJECT_DGF_CREATE_NEW_USER': {
        heading: "Create New User (Respondent/Approver)",
        drawerData: {
            roleKeywords: ['fiserv implementation manager', 'respondent'],
            userTypeTooltip: 'To create a client user, please select a client first.',
            hideUserControl: false,
            addNewFiservManager: false
        }
    },
    'CLIENT': {
        heading: "Create New Client"
    },
    // START | PBI-168948
    'FISV_PROJECT_STKHL': {
        heading: "Create New Project Stakeholder",
        drawerData: {
            roleKeywords: ['fiserv implementation manager'],
            userTypeTooltip: 'To create a client user, please select a client on the previous page.',
            hideUserControl: true,
            addNewFiservManager: false
        }
    }
    // END | PBI-168948
}
