<div class="heading-container fdl-2">
    <h1>Workflows</h1>
    <div class="col-md-6 float-right pt-4">
        <button *ngIf="isAdmin" type="button" class="pull-right btn fdl-btn btn-primary-orange ml-2 pl-2 pr-2 mb-3" (click)="createWorkflow()">
            <span class="material-icons">add</span>
            Create Workflow
        </button>
    </div>
</div>

<div class="form-container">
    <!-- Error Msg Alert -->
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>
    <!-- Success Msg Alert -->
    <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="setSuccessMsg()">×</button>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p [innerHtml]="successMsg"></p>
    </div>

    <form class="form form-border mb-3 collapse-view"  [formGroup]="filterForm" autocomplete="off">
        <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
        <div class="filter-form-container-column2 collapse" id="collapseFilter">
            <div class="filter-form-control pr-4 typeahead-wrapper">
                <fdl-type-ahead
                    label="Workflow Name:"
                    formControlName="WorkflowName"
                    [collection]="workflowSuggestion"
                    optionField="name"
                    placeholder="Type to search"
                    [scrollable]="true"
                    [optionsLimit]="500"
                    [minLength]="0"
                ></fdl-type-ahead>
            </div>
            <div class="filter-form-control typeahead-wrapper pr-4">
                <label for="FlowId">Flow Id:</label>
                <div class="">
                    <input type="string" name="FlowId" placeholder="Type to search" class="form-control" aria-invalid="false" formControlName="FlowId">
                </div>
            </div>
            <div>
                <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterList()">Search</button>
            </div>
            <div class="ml-2" *ngIf="filtered">
                <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset" (click)="clearFilterList()">Reset</button>
            </div>
        </div>
    </form>
    <div></div>
    <div class="data-table-container">
        <div class="justify-content-between d-flex">
            <div class="headline-container">
                <h2 class="h4">Workflow List</h2>
                <p>Total {{pagerObject.totalCount}} Workflows</p>
            </div>
            <div class="d-flex">
                <div class="refresh-icon-link mt-1 mr-2">
                <!-- add class of 'fa-rotation' on click of the refresh icon -->
                <a  title="Refresh Workflow List" id="refreshTask">
                  <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
                </a>
                </div>
                <app-prefernce-icon></app-prefernce-icon>
            </div>

        </div>
        <div class="table-responsive">
            <app-data-table
                *ngIf="workflowList.workflows"
                [rows]="workflowList.workflows"
                [keys]="keys"
                [displayColumns]="displayColumns"
                (buttonClicked)="navigate($event)"
                (deleteBtnClicked)="navigate($event)"
                (sortingClicked)="applySorting($event)"
                [showCheckbox]="false"
                [showViewEdit]="true"
                [showDelete]="isAdmin"
                [listname]="listname"
                type="Workflow"
            ></app-data-table>
          <app-pagination
                *ngIf="pagerObject && pagerObject.totalPages > 1 && workflowList.workflows && workflowList.workflows.length > 0"
                [pagerObject]="pagerObject"
                (pagingChanged)="changePage($event)"
            ></app-pagination>
        </div>
    </div>
</div>
