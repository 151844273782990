<div class="heading-container fdl-2">
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span>
        Resource Article
      </a>
    </div>
    <h1>{{helpArticleData?.title || 'N/A'}}</h1>
  </div>
  </div>
  
<div class="form-container separate-form-container help-form-io-wrapper">
  <div class="alert alert-success alert-dismissible" *ngIf="showUpdationMessage">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="showUpdationMessage=false">×</button>
    <span class="iconLabel" aria-label="sucess"></span>
    <i class="material-icons" aria-hidden="true">check_circle</i>
    <p>
      The Resource Article details are updated successfully.

    </p>
  </div>
  <form>
    <div class="biller-survey grid-wrapper-section pt-2 pb-0 pb-2 mb-2 fdl-2">
      <button type="button" class="btn fdl-btn btn-primary-dark mt-1 pull-right" (click)="goToEdit();" *ngIf="showEditButton">Edit</button>
      <div class="">
        <ul class="mb-0">
          <li class=" full-width">
            Description
            <span class="info">
              {{helpArticleData?.description || 'N/A'}}
            </span>
          </li>
          <li class=" full-width">
            Article Category
            <span class="info">
              {{helpArticleData?.helpCategoryName || 'N/A'}}
            </span>
          </li>
          <li class=" full-width">
            Entity Level
            <span class="info">
              {{helpArticleData?.entityLevel || 'N/A'}}
            </span>
          </li>
          <li class=" full-width">
            Entity Name
            <span class="info">
              {{helpArticleData?.entityName || 'N/A'}}
            </span>
          </li>
          <li class=" full-width">
            Publish
            <span class="info">
              {{helpArticleData?.isPublishable}}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="form-container p-0 help-accordion-container help-form-io-container">
      <formio [form]="json" #formio></formio>

    </div>
    <div class="fdl-2 button-container">
      <div>
        <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()">Cancel</button>
      </div>
    </div>
  </form>
</div>
