import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })

export class CacheRegistrationService  {
    
    private cachedData = new Map<string, {}>();

    constructor(){}

    put(url: string, eTag : string, response: HttpResponse<any>): void {  
        this.cachedData.set(url, {
            'eTag': eTag,
            'response': response
          });
      }  
      
      get(url: string):any | undefined {  
        return this.cachedData.get(url);
      }  
      
      invalidateCache(): void {  
        this.cachedData.clear();
        
      }
}