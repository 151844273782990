<div class="heading-container">
    <h1>{{formTemplateName}} History</h1>
</div>

<div class="main-container-sub responsive-container">
    <div class="form-container">
        <div class="row">
            <section class="col-lg-12 p-0">
                <div class="alert alert-success alert-dismissible" *ngIf="approvalMsg">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="approvalMsg=''">×</button>
                    <span class="iconLabel" aria-label="sucess"></span>
                    <i class="material-icons" aria-hidden="true">check_circle</i>
                    <p>{{approvalMsg}}
                     
                    </p>
                </div>

                <div class="alert alert-dismissible alert-danger" *ngIf="isError">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
                    <i class="material-icons">warning</i>
                    <p [innerHtml]="errorMsg"></p>
                </div>

                <form class="form-border mb-3 box collapse-view" [formGroup]="filterFormsVersions" autocomplete="off">
                    <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
                    <div class="filter-form-container collapse" id="collapseFilter">
                        <div class="filter-form-control pr-4">
                            <label for="Status">From Date:</label>
                            <input type="text" class="form-control" formControlName="fromDate" id="fromDateId" placeholder="MM/DD/YYYY">
                        </div>
                        <div class="filter-form-control pr-4">
                            <label for="Status">To Date:</label>
                            <input type="text" class="form-control" formControlName="toDate" id="toDateId" placeholder="MM/DD/YYYY">
                        </div>
                        <div>
                            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterFormsHistory()">Search</button>
                        </div>
                    </div>
                </form>

                <div class="data-table-container history-table">
                    <div class="headline-container"><h2 class="h4">Form History List</h2><p>Total {{pagerObject.totalCount}} Form Responses History</p></div>
                    <div class="table-responsive form-responces">
                    <app-data-table *ngIf="formHistoryList" [rows]="filteredFormHistoryList" [keys]="keys" [displayColumns]="displayColumns"
                        (buttonClicked)="navigate($event)" (sortingClicked)="applySorting($event)" [showEdit]="false" [showRevert]="true" [showViewCompare]="true"
                        [showDelegate]="false" [showCheckbox]="false" [showDelete]="false" [isHistory]="true" type="Form">
                    </app-data-table>
                    <div>
                        <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && filteredFormHistoryList && filteredFormHistoryList.length > 0"
                            [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
                    </div>
                </div>
                <div class="fdl-2 button-container" id="button_view">
                    <button type="button"  class="btn fdl-btn btn-secondary-light  mr-2" aria-label="Default" (click)="goBack()">Cancel</button>
                </div>
                </div>
            </section>
        </div>
    </div>
    <!-- <app-footer></app-footer> -->
</div>

<!--Restore Confimation Modal: START-->

<div class="modal fade bd-example-modal-md" id="restoreForm" data-backdrop="static" data-keyboard="false"
     tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-md mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Restore Form</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <p>Data from the selected version of the Form will be copied over to the current version. Are you sure you want to continue to restore the form data?</p>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn fdl-btn btn-primary-orange" data-dismiss="modal" (click)="restoreOk()">Continue</button>
      </div>
    </div>
  </div>
</div>

<!--Restore Confimation Modal: END-->
