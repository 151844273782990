import { FormGroup } from "@angular/forms";

export class ValidationErrorHandler {
  static handleValidationErrors(validationResult: any): string {
    for (var property in validationResult) {
      if (validationResult.hasOwnProperty(property)) {
        for (var validationError of validationResult[property]) {
          return validationError.message;
        }
      }
    }
  }
}
