import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormListComponent } from './manageForms/form-list/form-list.component';
import { CreateFormComponent } from './manageForms/create-form/create-form.component';
import { AdminComponent } from './admin.component';
import { SharedModule } from '../SharedModule/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { FormTemplateComponent } from './manageForms/form-template/form-template.component';
import { FormioModule } from 'angular-formio';
import { RoleListComponent } from './manageRoles/components/role-list/role-list.component';
import { ViewUserComponent, } from './manageUsers/view-user/view-user.component';
import { UserListComponent } from './manageUsers/user-list/user-list.component';
import { UserFormComponent } from './manageUsers/user-form/user-form.component';
import { FdlDataGridModule, FdlTypeaheadModule } from 'fdl-ngx11-bootstrap4';
import { ClientListComponent } from './manageClients/client-list/client-list.component';
import { ClientFormComponent } from './manageClients/client-form/client-form.component';
import { ViewClientComponent } from './manageClients/view-client/view-client.component';
import { SetOrganizationComponent } from './manageOrganization/set-organization/set-organization.component';
import { FormsHistoryComponent } from './forms-history/forms-history.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PowerBIInternalComponent } from './powerBI/powerBI-internal-dashboard-component';
import { CreateTopicComponent } from './help/create-topic/create-topic.component';
import { CreateTopicTemplateComponent } from './help/create-topic-template/create-topic-template.component';
import { ViewUserGroupComponent } from './manageUserGroups/view-user-group/view-user-group.component';
import { UserGroupListComponent } from './manageUserGroups/user-group-list/user-group-list.component';
import { UserGroupFormComponent } from './manageUserGroups/user-group-form/user-group-form.component';
import { RoleViewComponent } from './manageRoles/components/role-view/role-view.component';
import { RoleFormComponent } from './manageRoles/components/role-form/role-form.component';
import { FeatureListComponent } from './manageFeature/feature-list/feature-list.component';
import { FeatureFormComponent } from './manageFeature/feature-form/feature-form.component';
import { ViewFeatureComponent } from './manageFeature/feature-view/feature-view.component';
import { EditFeatureComponent } from './manageFeature/feature-edit/feature-edit.component';
import { SubfeatureViewComponent } from './manageFeature/subfeature-view/subfeature-view.component';
import { UpdateInflightFormsComponent } from './manageForms/update-inflight-forms/update-inflight-forms.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    AdminComponent,
    CreateFormComponent, FormTemplateComponent, FormListComponent,
    RoleListComponent,
    UserListComponent, ViewUserComponent, UserFormComponent,
    ClientListComponent, ViewClientComponent, ClientFormComponent, SetOrganizationComponent, FormsHistoryComponent,
    PowerBIInternalComponent, CreateTopicComponent, CreateTopicTemplateComponent,
    UserGroupListComponent, ViewUserGroupComponent, UserGroupFormComponent, RoleViewComponent, RoleFormComponent,
    FeatureListComponent, FeatureFormComponent, ViewFeatureComponent, EditFeatureComponent, SubfeatureViewComponent, UpdateInflightFormsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AdminRoutingModule,
    FormioModule,
    FormsModule,
    ReactiveFormsModule,
    FdlTypeaheadModule,
    AngularEditorModule,
    FdlDataGridModule,
    DragDropModule
  ],
})
export class AdminModule { }
