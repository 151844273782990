import { OnInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DocumentService } from 'src/app/services/document.service';
import { take } from 'rxjs/operators';
import { IQuery } from 'src/app/shared/query';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-document-file',
  templateUrl: './document-file.component.html',
  styleUrls: ['./document-file.component.css']
})
export class DocumentFileComponent implements OnInit, OnDestroy {
  routeSubscription: Subscription;
  getFileSubscription: Subscription;
  documentGuid: any;
  folderId: number;
  folderName: string = "";
  queryParam: IQuery;
  isError: boolean = false;
  errorMsg: string;
  isSucceed: boolean = false;
  successMsg: string;

  constructor(private documentService: DocumentService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.params
    .pipe(take(1))
    .subscribe((params) => {
      this.documentGuid = params['guid'];
      if (!this.documentGuid){
        this.folderId = params['folderId'];
      }

      if (this.documentGuid){
        this.getFileSubscription = this.documentService.downloadFile(this.documentGuid).subscribe(response => {
          this.download(response);
        }, error => {
          this.isError = true;
          this.errorMsg = error;
        });
      }
      else {
        this.getFileSubscription = this.documentService.downloadFolder(this.folderId).subscribe(response => {
          this.download(response);
        }, error => {
          this.isError = true;
          this.errorMsg = error;
        });
      }
    });
  }

  private download(response: HttpResponse<Blob>){
    const contentDisposition = response.headers.get('content-disposition');
    const fileName = contentDisposition.split(';')[1].split('=')[1].replace(/"/g, '');
    this.isSucceed = true;
    this.successMsg = `File ${fileName} downloaded successfully`
    this.openToDownload(fileName, response.body);
  }

  private openToDownload(fileName: string, reponseBlob: Blob){
    reponseBlob.arrayBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: reponseBlob.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    this.getFileSubscription?.unsubscribe();
  }

  closeAlert(){
    this.isError = false;
  }
}
