<div class="timeline-main-container">
  <div class="heading-container">
      <div class="back-action-container">
        <div class="back-button">
          <a href="javascript:void(0);" (click)="goBack()">
            <span class="material-icons">
              arrow_back
            </span>
            {{previousPage}}
          </a>
        </div>
        <h1>{{formTemplateName}}</h1>
        <small *ngIf="filteredTimelineList">Latest Updated on {{filteredTimelineList.modifiedOn | dateFormat}}</small>
      </div>
      <div class="d-flex mr-3">
        <div class="refresh-icon-link mt-1">
          <!-- add class of 'fa-rotation' on click of the refresh icon -->
          <a  title="Refresh Response Timeline" id="refreshTask">
            <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
          </a>
        </div>
        <button (click)="openAddCommentModal()" type="button" data-toggle="modal" title="Add/Upload Comments" class="btn fdl-btn btn-primary-dark btn-addcomment">
          <span class="material-icons material-icons-wrapper">chat</span>
          <div class="badge badge-danger">{{commentBadgeCount}}</div>
        </button>
      </div>
  </div>

<div class="main-container-sub responsive-container m-0">
  <div class="row m-0">
    <section class="right-section full-width col-12">
      <div class="form-container p-0">
        <div class="data-table-container mb-0 pb-0 timeline-wrapper">
          <div class="page-status-container fdl-2">
            <div class="timeline-info-container">
              <div class="single-timeline-content d-flex">
                <div class="timeline-text ">
                   <p>Response Status</p>
                   <h6>
                      <span [ngClass]="getStatusCSSClass(responseStatusTitle)"></span>
                      <span title="{{responseStatusTitle}}">
                        {{responseStatusTitle}}
                      </span>
                   </h6>
                </div>
              </div>
              <div class="single-timeline-content d-flex">
                <div class="timeline-text">
                    <p>Respondent Name</p>
                    <h6>
                      <span title="{{respondentName}}">
                          {{respondentName}}
                      </span>
                    </h6>
                </div>
              </div>
              <div class="single-timeline-content d-flex">
                <div class="timeline-text ">
                    <p>Approver Name</p>
                    <h6>
                      <span title="{{approverName}}">
                          {{approverName}}
                      </span>
                    </h6>
                </div>
              </div>
              <div class="single-timeline-content d-flex">
                <div class="timeline-text ">
                    <p>Current Assignee</p>
                    <h6>
                      <span title="{{currentAssignee}}">
                          {{currentAssignee}}
                      </span>
                    </h6>
                </div>
              </div>
              <div class="single-timeline-content d-flex">
                <div class="timeline-text ">
                    <p>Due Date</p>
                    <h6>
                      <span title="{{dueDate | dateFormat}}">
                          {{dueDate | dateFormat}}
                      </span>
                    </h6>
                </div>
              </div>
            </div>
            <div class="event-status-container">
              <div class="status">
                <p>Recent Events (Last 24 Hours)</p>
                <h2>{{recentEventCount}}</h2>
              </div>
              <div class="status">
                <p>Total Events</p>
                <h2>{{totalEventCount}}</h2>
                <p></p>
              </div>
            </div>
          </div>
          <section class="timeline_area section_padding_130">
            <div class="justify-content-between d-flex">
              <h2 class="timeline-heading">Form Response Timeline</h2>

              </div>
            <div class=" ">
              <div class="row">
                <div class="col-12">
                  <!-- Timeline Area-->
                  <div class="apland-timeline-area">
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area blue header-timeline">
                      <div class="timeline-date  "></div>
                      <div class="row timeline-header">
                        <div class="col-12 col-md-2 col-lg-2">
                          <div class="single-timeline-content d-flex">
                            <div class="timeline-text overflow-hidden">
                              <p>
                                  <a class="sorting-action" href="javascript:void(0);" (click)="sort()">
                                    Modified Date and Time
                                    <i class="material-icons msort14 ml-1" aria-hidden="true" *ngIf="isAsc">arrow_upward</i>
                                    <i class="material-icons msort14 ml-1" aria-hidden="true" *ngIf="!isAsc">arrow_downward</i>
                                  </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                          <div class="single-timeline-content d-flex ">
                            <div class="timeline-text overflow-hidden">
                              <p>Event Name and Description</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                          <div class="single-timeline-content d-flex ">
                            <div class="timeline-text overflow-hidden">
                              <p>Modified By</p>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-md-3 col-lg-2">
                          <div class="single-timeline-content d-flex ">
                            <div class="timeline-text overflow-hidden">
                              <p>Status</p>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="single-timeline-area disabled" [ngClass]="getSortClass()" *ngIf="responseStatusTitle != 'Completed' && !isAsc">
                      <div class="row">
                         <div class="timeline-date"></div>
                         <div class="col-12 col-md-2 col-lg-2">
                            <div class="single-timeline-content d-flex">
                               <div class="timeline-text opacity-05">
                                  <h6>MM/DD/YYYY</h6>
                                  <p>hh:mm ET</p>
                               </div>
                            </div>
                         </div>
                         <div class="col-12 col-md-3 col-lg-3">
                            <div class="single-timeline-content d-flex">
                               <div class="timeline-text opacity-05">
                                  <h6>Form Response Completed</h6>
                                  <p>STATUS UPDATED - Completed</p>
                               </div>
                            </div>
                         </div>
                         <div class="col-12 col-md-3 col-lg-3">
                            <div class="single-timeline-content d-flex">
                               <div class="timeline-text opacity-05">
                                  <h6>--</h6>
                                  <p></p>
                               </div>
                            </div>
                         </div>
                      </div>
                    </div>
                   <div [ngClass]="getTimelineStatusCSSClass(response.status)" *ngFor='let response of responseTimelineList'>
                      <div class="row">
                        <div class="timeline-date"></div>
                        <div class="col-12 col-md-2 col-lg-2">
                          <div class="single-timeline-content d-flex">
                            <div class="timeline-text overflow-hidden">
                              <h6>{{response.modifiedOn | dateFormat}}</h6>
                              <p>{{response.modifiedOn | formatDateTime}} ET</p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                          <div class="single-timeline-content d-flex ">
                            <div class="timeline-text overflow-hidden">
                              <h6>{{response.event}}
                                <button class="btn btn-link p-1 pb-0" *ngIf="response.attachment && response.event.includes('RESPONSE COMMENT')" type="button"
                                  aria-hidden="true" title="{{response.attachment?.split('/').pop().replace('%20',' ')}}" (click)="onAttachmentClick(response.attachment)">
                                  <span class="f-22 material-icons blue-color"> attach_file </span>
                                </button>
                              </h6>
                              <p>{{response.description}}</p>
                              <div *ngIf="response.status && !response.event.includes('RESPONSE COMMENT') && !response.event.includes('RESPONSE EXTRACT') && !response.event.includes('RESPONSE TITLE')">
                                <h6>
                                  <span class="badge" [ngClass]="getStatusCSSClass(response.status)"> {{response.status}} </span>
                                </h6>
                             </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                          <div class="single-timeline-content d-flex ">
                            <div class="timeline-text overflow-hidden">
                              <h6>{{response.modifyBy}}</h6>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-12 col-md-3 col-lg-2">
                          <div class="single-timeline-content d-flex ">
                            <div class="timeline-text overflow-hidden">
                              <h6>
                                <span [ngClass]="getStatusCSSClass(response.status)"></span>{{response.status}}
                              </h6>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="single-timeline-area disabled" [ngClass]="getSortClass()" *ngIf="responseStatusTitle != 'Completed' && isAsc">
                      <div class="row">
                         <div class="timeline-date"></div>
                         <div class="col-12 col-md-2 col-lg-2">
                            <div class="single-timeline-content d-flex">
                               <div class="timeline-text opacity-05">
                                  <h6>MM/DD/YYYY</h6>
                                  <p>hh:mm ET</p>
                               </div>
                            </div>
                         </div>
                         <div class="col-12 col-md-3 col-lg-3">
                            <div class="single-timeline-content d-flex">
                               <div class="timeline-text opacity-05">
                                  <h6>Form Response Completed</h6>
                                  <p>STATUS UPDATED - Completed</p>
                               </div>
                            </div>
                         </div>
                         <div class="col-12 col-md-3 col-lg-3">
                            <div class="single-timeline-content d-flex">
                               <div class="timeline-text opacity-05">
                                  <h6>--</h6>
                                  <p></p>
                               </div>
                            </div>
                         </div>
                      </div>
                    </div>
                    <!-- Single Timeline Content-->
                  </div>
                </div>
              </div>
              <!-- <div id="button_view" class="fdl-2 button-container mt-0">
                <div>
                  <button type="button" aria-label="Default" class="btn fdl-btn btn-secondary-light mr-2" (click)="goBack()">
                    <span>Cancel</span>
                  </button>
                </div>
              </div> -->
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</div>
</div>

<div class="modal fade bd-example-modal-lg" id="addcomment" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
     aria-labelledby="myLargeModalLabel" aria-hidden="true" #content>
  <div class="modal-dialog modal-lg" style="max-width: 1280px;" role="document" [ngClass]="{'mt-120': !showCommentBadge}">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Add Comment</h4>
        <button type="button" class="close" data-dismiss="modal"
                aria-label="Close popup">
          <span aria-hidden="true" (click)="cancelCommentHandle();">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-dismissible alert-danger" *ngIf="isCommentError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isCommentError=false">×</button> <i class="material-icons">warning</i>
          <p [innerHtml]="commentErrorMsg"></p>
        </div>
        <div class="row ">
          <div class="col-md-4 mb-3 sd" >
            <label for="organizationTitle">Comment Category:</label>
            <div class="fdl-select">
              <select name="formType" class="form-control" aria-invalid="false" [(ngModel)]="commentCat">
                <option value="Comment">Comment</option>
                <option value="Issue">Issue</option>
                <option value="Question">Question</option>
                <option value="Risk">Risk</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-12 mb-3" [ngClass]="{'has-error': !commentControl.valid && (commentControl.touched || commentControl.dirty) }">
            <label for="txtComment" >Comment:</label>
            <textarea  #commentControl="ngModel" [pattern]="validators.description_comment" type="text" [(ngModel)]="commentValue" class="form-control" rows="4" id="email" maxlength="2000" (keyup)="commentCount()"></textarea>
              <div class="float-right"><span>{{commentCnt}} / 2000</span></div>
              <span *ngIf="!commentControl.valid && (commentControl.touched || commentControl.dirty)">
                <i class="material-icons">warning</i> {{regexValidationMessage.DESCRIPTION_COMMENT_VALIDATION_MESSAGE}}
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="commentFileName" >Upload File:
                <span _ngcontent-yvp-c156="" data-toggle="tooltip"
                  title="File size Limit - 50MB"
                  class="material-icons btn-secondary f-18">info
                </span>
            </label>
            <div class="custom-file">
              <label for="commentFileName" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="commentFileName"> {{ commentFileName }}</label>
              <input #inputCommentFile type="file" class="custom-file-input form-control" multiple="false" (change)="onCommentFileChange($event)">
            </div>
          </div>
        </div>
        <div class="fdl-2 w-100 text-right mt-0">
          <button type="button" class="btn fdl-btn btn-secondary-light  mr-2" data-dismiss="modal" (click)="cancelCommentHandle()">
            Cancel
          </button>
          <button type="button" class="btn fdl-btn btn-primary-orange" [disabled]="isCommentSubmit" (click)="submitCommentHandle()">
            Save
          </button>
        </div>

        <div *ngIf="showCommentBadge">
          <hr />
          <div class="form-row align-items-end mb-3">
            <div class="col-md-5">
              <label for="searchForm">Page Name:</label>
              <fdl-type-ahead [(ngModel)]="commentHistSelectedPgName" name="commentHistSelectedPgName" optionField="title" placeholder="Type to search"
                              [collection]="commentHistFilterOptions" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>
            <div class="col-md-5">

                <label for="organizationTitle" >Comment Category:</label>
                <div class="fdl-select">
                  <select name="formType" class="form-control" aria-invalid="false" [(ngModel)]="commentCatHistory">
                    <option value="">All</option>
                    <option value="Comment">Comment</option>
                    <option value="Issue">Issue</option>
                    <option value="Question">Question</option>
                    <option value="Risk">Risk</option>
                  </select>
              </div>
            </div>
          </div>
          <div class="assign-modal-table" [ngClass]="getCommentClass(commentLength)">
            <app-data-table *ngIf="commentHistoryList" [rows]="commentHistoryList" [keys]="commentTableKeys"
                            [displayColumns]="commentTableHeaders"  [enableSorting]="false"
                            [searchString]="commentHistSelectedPgName" searchStringFieldName="pageName" [category]="commentCatHistory" [showCheckbox]="false"
                            [showMenu]="true"
                            [showAttachmentOptions]="true"
                            [showViewEdit]="true"
                            [showAttachment]="true"
                            (attachmentClicked)="downloadAttachment($event)"
                            (buttonClicked)="commentActions($event)">
            </app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<replace-attachment #replaceAttachment
[id]="commentId"
[formId]="commentResponseId"
[type]="'response'"
(updateAttachment)="updateAttachmentSuccess($event);">
</replace-attachment>

<delete-warning
 [componentId]="'deleteWarningModal'"
 [type]="'comment'"
 (okBtnClicked)="deleteComment(eventData)">
</delete-warning>

<delete-warning
[componentId]="'removeAttachmentWarningModal'"
[type]="'file'"
(okBtnClicked)="removeAttachment(eventData)">
</delete-warning>
