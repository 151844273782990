import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IUser, IUsersList, IUserLoginDetails } from '../shared/user';
import { Observable } from 'rxjs';
import { ISuggestion } from '../shared/suggestion';
import { debug } from "console";
import { query } from "@angular/animations";
import moment from "node_modules/moment";
import { IResponses } from "../shared/response-extract";

@Injectable({
    providedIn: 'root'
})
export class ResponsesService {

  private responseAPIURL = 'responses'

    constructor(private http: HttpClient) { }
    //https://localhost:44365/api/responses/42919
    getResponseById(responseId: number) {
        return this.http.get<IResponses>(`${this.responseAPIURL}/${responseId}`);
    }

    
  public updateInflightResponses(formId: number, formData: any) {
    return this.http.put<any>(`${this.responseAPIURL}/${formId}/inflightResponses/`, formData);
  }

}

