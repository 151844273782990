import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ClientsService } from 'src/app/services/clients.service';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE, MAX_LENGTHS, MimeTypeConstants } from 'src/app/core/constants';
import { Router } from '@angular/router';
import { ClientsDTOService } from 'src/app/shared/clientsDTO.service';
import { IClientAddresses, IClient, IContacts } from 'src/app/shared/client';
import { UserService } from 'src/app/services/user.service';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { ISuggestion } from 'src/app/shared/suggestion';
import * as $ from 'jquery';
import { FileValidators } from 'src/app/self-service/components/manageFileUploads/validators/file-validators';
import { ClientValidator } from '../validators/client.validators';
@Component({
  templateUrl: './client-form.component.html'
})
export class ClientFormComponent implements OnInit, OnDestroy {
  clientForm : FormGroup
  isSubmitted: boolean = false;
  isError: boolean;
  isEditMode: boolean;
  errorMsg: string;
  organizationDetails:any;
  clientData: IClient;
  clientAddress: IClientAddresses;
  _maxLength: any;
  regexValidationMessage: any;
  isAdmin: boolean = false;
  eCRMClient: boolean = false;
  organizationTitle: string = '';

  @Input() isDrawerType: boolean = false;
  @Input() CancelText = "Cancel";
  @Input() isChannelPartner: boolean = false;
  organizationSuggestion: ISuggestion[];

  /* Client Contact Code */
  clientContactHeaders: any = ["Name", "Email", "Phone"];
  clientContactKeys: any = ["name", "email", "phone"];
  addContactFlag: boolean = false
  currentContactSet: number = 0;
  contactFormSets: any = [];
  clientContactErrorMsg: string = "";
  isContactSubmitted: boolean = false;
  clientContactList: any = [];
  singleContactEdit: boolean = false;
  /* Client Contact Code */

  /* Client Contact Code */
  @ViewChild('inputPhoneNumber') inputPhoneNumberElementRef: ElementRef;
  /* Client Alias Code */
  selectedClientAliasIds: number[] = [];
  selectedClientAliasForDisplay = [];
  clientAliasSuggestions = [];
  clientAlias: FormGroup;
  duplicateClientAlias: boolean = false;
  /* Client Alias Code */

  clientListFlag: boolean = false;
  clientListHeaders: any = ["Client Name", "Client Description", "DUNS", "GUD", "eCRMAccountId", "ClientAddress"];
  clientListKeys: any = ["clientName", "clientDescription", "dunsId", "gud", "eCRMAccountId", "clientAddress"];
  clientList: any;

  // Logo
  public newLogoFile: any;
  @ViewChild('inputLogoFile') inputLogoFileElementRef: ElementRef;
  logoErrMsg = '';

  // Scroll fix
  @ViewChild('createClientFormContainer') createClientFormContainerRef: ElementRef;

  constructor(private fb:FormBuilder, private clientService: ClientsService,
    private router: Router, private clientDto: ClientsDTOService,
    private userService: UserService,
    private permissionService: PermissionsService,
    private drawerService: ProjectDrawerService,
    private projectService: ProjectsService) { }

  ngOnInit(): void {
    this.isEditMode = this.clientDto._isEditMode;
    this._maxLength = MAX_LENGTHS;
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.organizationDetails = Object.assign({}, this.userService.getUserListData());
    if(this.isEditMode) {
      this.clientData = this.clientDto.getClientData() ? this.clientDto.getClientData() : <IClient>{};
      this.organizationTitle = this.clientData.organizationTitle;
      this.clientContactList = this.clientData?.contacts;
      this.clientAliasSuggestions = this.clientData?.alias ? this.clientData?.alias : [];
      this.clientAddress = this.clientDto.getClientAddress() ? this.clientDto.getClientAddress() : <IClientAddresses>{};
      this.organizationDetails.organizationTitle = this.clientData?.organizationTitle;
      this.eCRMClient = this.clientData?.ecrm?.length > 0;
      this.initForm();
      this.patchForm();
    }
    else
    {
      this.initForm();
      this.organizationTitle = this.organizationDetails.organizationTitle;
    }

    if(this.isAdmin) this.getOrganizationSuggestions();
    this.getClientAlias();
  }

  initForm(){
    this.clientForm = this.fb.group({
        name: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.client_name_validator), Validators.maxLength(this._maxLength.Client.Name), NoWhitespaceValidator.validateWhiteSpaces]],
        gud: ['', [Validators.pattern(REGEX_PATTERNS.gud_id)]],
        dunsId: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.duns_id), NoWhitespaceValidator.validateWhiteSpaces]],
        description: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.client_name_validator), Validators.maxLength(this._maxLength.Client.Description), NoWhitespaceValidator.validateWhiteSpaces]],
        addressLine1: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.street_validator)]],
        addressLine2: ['',[Validators.pattern(REGEX_PATTERNS.street_validator)]],
        city: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.Name)]],
        state: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.Name)]],
        zipCode: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.zipCode)]],
        country: ['',[Validators.required, Validators.pattern(REGEX_PATTERNS.Name)]],
        // contactName:['',[Validators.pattern(REGEX_PATTERNS.Name), Validators.maxLength(this._maxLength.Client.ContactName)]],
        // contactNumber:['',[Validators.pattern(REGEX_PATTERNS.Mobile)]],
        organization: [(this.organizationDetails.organizationTitle || ''), [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]],
        organizationReadOnly: [(this.organizationTitle)],
        gudReadOnly: [(this.clientData?.gud)],
        clientReadOnly: [(this.clientData?.title)],
        dunsReadOnly: [(this.clientData?.dunsId)],
        addressLine1ReadOnly: [(this.clientAddress?.streetAddress1)],
        addressLine2ReadOnly: [(this.clientAddress?.streetAddress2)],
        cityReadOnly: [(this.clientAddress?.city)],
        stateReadOnly: [(this.clientAddress?.state)],
        zipCodeReadOnly: [(this.clientAddress?.zipCode)],
        countryReadOnly: [(this.clientAddress?.country)]
    });

    this.initializeContactSets();
    this.initializeClientAlias();
  }

  patchForm(){
    this.clientForm.patchValue({
      name: this.clientData.title || '',
      dunsId: this.clientData.dunsId,
      gud: this.clientData.gud,
      description: this.clientData.description || '',
      addressLine1: this.clientAddress.streetAddress1 || '',
      addressLine2: this.clientAddress.streetAddress2 || '',
      city: this.clientAddress.city || '',
      state: this.clientAddress.state || '',
      zipCode: this.clientAddress.zipCode  || '',
      country: this.clientAddress.country || '',
      // contactName:this.clientData.clientContactName || '',
      // contactNumber: this.clientData.clientContactNumber || '',
      organization: this.clientData.organizationTitle
    })
  }

  onSubmit():void {
    this.isSubmitted = true;
    if(!this.clientForm.valid || this.logoErrMsg) return;
  
    let clientPostData = {
      "title": this.clientForm.value.name,
      "dunsId": this.clientForm.value.dunsId,
      "gud": this.clientForm.value.gud,
      "description": this.clientForm.value.description,
      // "clientContactName": this.clientForm.value.contactName,
      // "clientContactNumber": this.clientForm.value.contactNumber
      "alias": this.clientAliasSuggestions,
      "contacts": this.clientContactList,
      "logo": this.clientData?.logo,
      "logoFile": this.newLogoFile
    };

    let addressPostData = {
      "streetAddress1": this.clientForm.value.addressLine1,
      "streetAddress2": this.clientForm.value.addressLine2,
      "city": this.clientForm.value.city,
      "state": this.clientForm.value.state,
      "zipCode": this.clientForm.value.zipCode,
      "country": this.clientForm.value.country,
      "addressType": "primary",
      "entityType": 1
    };

    let gudAddress: any = [];
    gudAddress.push(addressPostData);
    clientPostData['gudAddress'] = gudAddress;

    if(this.isEditMode) {
      clientPostData["id"] = addressPostData["clientId"] = this.clientData.id;
      clientPostData["organizationId"] = this.clientData?.organizationId > 0 ? this.clientData?.organizationId : this.organizationDetails.organization;
      clientPostData["relationshipManagerEmail"] = this.clientData.relationshipManagerEmail;
      clientPostData["relationshipManagerName"] = this.clientData.relationshipManagerName;

      this.updateClient(clientPostData, addressPostData);
    }
    else {
      let organizationId = -1;
      if(this.isAdmin)
        organizationId = this.projectService.fetchIdForRequest(this.organizationSuggestion, this.clientForm.controls['organization'].value);
      else if (this.isChannelPartner || this.userService.getUserListData().type == 'Client')
        organizationId = this.userService.getUserListData() ? this.userService.getUserListData().clientOrgId : -1;
      else
        organizationId = this.userService.getUserListData() ? this.userService.getUserListData().organization : -1;

      clientPostData["organizationId"] = organizationId;

      this.createClient(clientPostData, addressPostData);
    }
    $('.container-page').removeClass('overflow-hidden');
  }

  createClient(createClientData, createAddressData){
    this.clientService.createClient(createClientData).subscribe(
      data => {
        this.clientDto._newCreatedClient = createClientData.title;
            if(this.isDrawerType == true){
              this.drawerService.save(createClientData.title + " [DUNS: " + createClientData.dunsId + "]", createClientData.description, data["id"]);
              return;
            }
            this.clientDto._isClientCreated = true;
            this.router.navigate(['admin/client/list']);
      },
      error => {
        this.isError = true;
        this.errorMsg = error;
        if(this.isDrawerType == true){
          $('.container-page').addClass('overflow-hidden');
        }
      }
    );
  }

  updateClient(clientPostData, addressPostData){
    this.clientService.updateClient(clientPostData.id, clientPostData).subscribe(
      data => {
        this.clientDto._isClientUpdated = true;
        this.router.navigate(['admin/client/view', this.clientData.id]);
      },
      error => {

        this.isError = true;
          error;
        if(this.isDrawerType == true) {
          $('.container-page').addClass('overflow-hidden');
        }
      }
    );
  }

  goBack(){

    var currURL = this.router.url;
    if(this.isEditMode){
      this.router.navigate(['/admin/client/view', this.clientData.id]);
    }
    else if(this.isDrawerType == true){
      this.drawerService.close();
     if(currURL == '/submit-request'){
      $('.container-page').addClass('overflow-hidden');
     }
     else{
      $('.container-page').removeClass('overflow-hidden');
     }
    }
    else{
      this.router.navigate(['/admin/client/list']);
    }
  }

  ngOnDestroy(): void {
    this.clientDto._isEditMode = false;
    this.clientDto.setClientAddress(<IClientAddresses>{});
    this.clientDto.setClientData(<IClient>{});
  }

  getOrganizationSuggestions() {
    this.projectService.getSuggestions('organizations', '')
      .subscribe(suggestions => {
        this.organizationSuggestion = suggestions as unknown as ISuggestion[];
      },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  /************** Contact Drawer Code *********************/
  initializeContactSets(contact: any = undefined) {
    let contactPhone = this.formatPhoneNumberOnLoad(contact?.phone);
    this.contactFormSets[this.currentContactSet] = {
      "title": 'Contact Person' + " " + (this.currentContactSet + 1),
      "contacts": this.fb.group({
        'id': [contact?.id || ''],
        'name': [contact?.name || '', [Validators.required, Validators.pattern(REGEX_PATTERNS.Name), Validators.maxLength(this._maxLength.Client.ContactName)]],
        'email': [contact?.email || '', [Validators.required, Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(this._maxLength.User.Email)]],
        'number': [contactPhone || '', Validators.pattern(REGEX_PATTERNS.USMobile)]
      })
    }
  }

  closeContactFormset(formsetToBeRemoved) {
    this.currentContactSet--;
    this.contactFormSets.splice(formsetToBeRemoved, 1);
    this.contactFormSets.map((currentContactSet, index) => {
      return this.contactFormSets.title = "Contact Person " + (index + 1);
    });
  }

  updateContact(event) {
    let contact: IContacts = this.clientContactList.filter(
      item => item.id === event.data
    )[0];

    if (event.action === "edit") {
      this.singleContactEdit = true;
      this.initializeContactSets(contact);
      this.addContactFlag = true;
      if (this.isDrawerType) {
        // there should be another way to do this
        this.createClientFormContainerRef.nativeElement.parentElement.parentElement.scrollTo(0, 0);
        this.createClientFormContainerRef.nativeElement.parentElement.parentElement.classList.add('overflow-hidden');
      } else {
        $('#containerPage').scrollTop(0);
        $('.container-page').addClass('overflow-hidden');
      }

      $('.dw-client-content').scrollTop(0);
      $('.dw-client-content').addClass('overflow-hidden');
    }
    else if (event.action === "delete") {
      const index: number = this.clientContactList.findIndex(item => item.id === contact.id)
      if (index !== -1) {
          this.clientContactList.splice(index, 1);
      }
    }
  }

  addClientContact() {
    this.initializeContactSets();
    this.addContactFlag = true;

    if (this.isDrawerType) {
      // there should be another way to do this
      this.createClientFormContainerRef.nativeElement.parentElement.parentElement.scrollTo(0, 0);
      this.createClientFormContainerRef.nativeElement.parentElement.parentElement.classList.add('overflow-hidden');
    } else {
      $('#containerPage').scrollTop(0);
      $('.container-page').addClass('overflow-hidden');
    }

    $('.dw-client-content').scrollTop(0);
    $('.dw-client-content').addClass('overflow-hidden');

    $(document).ready(function () {
      $("button").click(function () {
        $(document).scrollTop($(document).height());
      });
    });
  }

  cancelContactForm() {
    this.addContactFlag = false;

    for(let i=this.currentContactSet; i>=0; i--) {
      this.contactFormSets[i].contacts.reset();
      if(i !== 0)
        this.closeContactFormset(i);
    }

    this.isContactSubmitted = false;
    this.singleContactEdit = false;
    this.currentContactSet = 0;
    if (this.isDrawerType) {
      // there should be another way to do this
      this.createClientFormContainerRef.nativeElement.parentElement.parentElement.classList.remove('overflow-hidden');
    } else {
      $('.container-page').removeClass('overflow-hidden');
      $('.dw-client-content').removeClass('overflow-hidden');
    }
  }

  submitContactForm() {
    this.isContactSubmitted = true;
    let tempClientContactList: any = [];

    for(let i=0; i<=this.currentContactSet; i++) {
      if(!this.contactFormSets[i].contacts.valid) {
        return;
      }
      else if (this.singleContactEdit) {
        for(let c=0; c<this.clientContactList.length; c++) {
          if(this.clientContactList[c].id === this.contactFormSets[i].contacts.value['id']) {
            this.clientContactList[c].name = this.contactFormSets[i].contacts.value['name'];
            this.clientContactList[c].email = this.contactFormSets[i].contacts.value['email'];
            this.clientContactList[c].phone = this.contactFormSets[i].contacts.value['number'];
          }
        }
      }
      else {
        let newContact: IContacts = {
          id: Math.floor((Math.random()*1000000)+1),
          name: this.contactFormSets[i].contacts.value['name'],
          email: this.contactFormSets[i].contacts.value['email'],
          phone: this.contactFormSets[i].contacts.value['number'],
          clientId: this.clientData?.id
        }
        if(this.clientContactList.some(c => c.email === newContact.email)) {
          this.clientContactErrorMsg = "Contact with same email already exists. Please add a new contact.";
          return;
        }
        else if(tempClientContactList.some(c => c.email === newContact.email)) {
          this.clientContactErrorMsg = "Multiple contacts have duplicate email. Please update the correct email.";
          return;
        }
        else {
          tempClientContactList.push(newContact);
        }
      }
    }

    tempClientContactList.forEach(element => {
      this.clientContactList.push(element);
    });

    this.cancelContactForm();
  }

  anotherContactSet() {
    this.currentContactSet++;
    let newFormSet = {
      "title": 'Contact Person' + " " + (this.currentContactSet + 1),
      "contacts": this.fb.group({
        'id': [''],
        'name': ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.Name), Validators.maxLength(this._maxLength.Client.ContactName)]],
        'email': ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.Email), Validators.maxLength(this._maxLength.User.Email)]],
        'number': ['', Validators.pattern(REGEX_PATTERNS.Mobile)]
      })
    }

    $(document).ready(function () {
      $("button").click(function () {
        $(document).scrollTop($(document).height());
      });
    });

    this.contactFormSets.push(newFormSet);
  }
  /************** Contact Drawer Code *********************/

  /************** Client Alias Code *********************/
  getClientAlias(){
    if (this.isEditMode && this.clientAliasSuggestions.length > 0) {
      this.clientAliasSuggestions.forEach(row => {
        row['selected'] = true;
      });

      // this.createSelectedClientAliasListForDisplay();
    }
    else {
      this.clientAliasSuggestions = [];
    }
  }

  removeAlias(title: string) {
    // const removedClientAlias = this.clientAliasSuggestions.find(items => items.subSystemClientRefrenceValue === title);
    // removedClientAlias.selected = false;
    // const index = this.selectedClientAliasIds.indexOf(removedClientAlias.title);
    // this.selectedClientAliasIds.splice(index, 1);

    const index: number = this.clientAliasSuggestions.findIndex(item => item.subSystemClientRefrenceValue === title)
    if (index !== -1) {
        this.clientAliasSuggestions.splice(index, 1);
    }

    // this.createSelectedClientAliasListForDisplay();
  }

  createSelectedClientAliasListForDisplay() {
    const selected = this.clientAliasSuggestions.filter(items => items.selected);
    this.selectedClientAliasForDisplay = [];
    this.selectedClientAliasForDisplay = selected;
  }

  addClientAlias() {
    if (!this.isAdmin) {
      return;
    }
    
    if(this.clientAlias.controls['alias'].value == null || (this.clientAlias.controls['alias'].value?.trim() === '' || undefined)) {
      return;
    }

    this.duplicateClientAlias = false;
    let alias = {
      'id': 0,
      'subSystemClientRefrenceValue': this.clientAlias.controls['alias'].value,
      'selected': true
    }

    if(this.clientAliasSuggestions && this.clientAliasSuggestions?.find(items => items.subSystemClientRefrenceValue === alias.subSystemClientRefrenceValue)) {
        this.duplicateClientAlias = true;
        return;
    }
    else {
      this.clientAliasSuggestions.push(alias);
      this.clientAlias.controls['alias'].reset();
      // this.createSelectedClientAliasListForDisplay();
    }
  }

  initializeClientAlias() {
    this.clientAlias = new FormGroup({
      'alias': new FormControl('')
    });
  }
  /************** Client Alias Code *********************/

  getClientList() {
    let clientName = this.clientForm.value.name;
    if(clientName) {
      this.clientService.getClientList(clientName).subscribe(
        data => {
          this.clientList = data;
          if(this.clientList.length > 0) {
            this.clientListFlag = true;
          }
          else {
            this.closeClientList();
          }
        }
      );
    }
  }

  closeClientList() {
    this.clientListFlag = false;
  }

  viewClient(event) {
    if (event.action === "view") {
      $('.container-page').removeClass('overflow-hidden');
      this.router.navigate(['/admin/client/view', event.data]);
    }
  }

  public onLogoChanged(event: any) {
    const logoFiles = event.target.files;
    if (!logoFiles || !logoFiles[0]) {
      return;
    }

    const logoFile = logoFiles[0];
    if (!FileValidators.ValidateFileName(logoFile.name)) {
      this.logoErrMsg = this.regexValidationMessage.FILENAME_VALIDATION_MESSAGE;
      this.inputLogoFileElementRef.nativeElement.value = "";
      this.decorInputLogoElement(true);
      return;
    }

    if (logoFile.size >= (MAX_LENGTHS.Image.Size * 1024 * 1024)) {
      this.logoErrMsg = "File size exceeds maximum size limit i.e. 10 MB";
      this.inputLogoFileElementRef.nativeElement.value = "";
      this.decorInputLogoElement(true);
      return;
    }

    const validPhotoExtensions = ["png", "jpg", "jpeg", "gif"];
    if (!validPhotoExtensions.some(x => logoFile.name.toLowerCase().endsWith(x))) {
      this.logoErrMsg = this.regexValidationMessage.IMAGE_EXTENSION_VALIDATION_MESSAGE;
      this.inputLogoFileElementRef.nativeElement.value = "";
      this.decorInputLogoElement(true);
      return;
    }

    const validPhotoMineTypes = [MimeTypeConstants.IMAGE_JPG, MimeTypeConstants.IMAGE_PNG, MimeTypeConstants.IMAGE_GIF];
    if (!validPhotoMineTypes.some(x => logoFile.type.includes(x))) {
      this.logoErrMsg =  this.regexValidationMessage.IMAGE_CONTENT_VALIDATION_MESSAGE;
      this.inputLogoFileElementRef.nativeElement.value = "";
      this.decorInputLogoElement(true);
      return;
    }

    this.logoErrMsg = '';
    this.decorInputLogoElement(false);
    this.newLogoFile = logoFile;
    const reader = new FileReader();
    reader.onload = (prEvent: ProgressEvent) => {
      this.newLogoFile.source = (<FileReader>prEvent.target).result;
    }
    reader.readAsDataURL(this.newLogoFile);
  }

  decorInputLogoElement(hasError: boolean): void {
    const inputLogoElement = <HTMLElement>document.getElementsByClassName('custom-file-label')[0];
    if (inputLogoElement) {
      inputLogoElement.style.borderColor = hasError ? 'red' : '#9e9e9e';
    }
  }

  public onRemoveLogo(): void {
    this.newLogoFile = null;
    this.inputLogoFileElementRef.nativeElement.value = '';
    this.clientData.logo = null;
  }

  public formatPhoneNumber() {
    var country, city, number;
    var numericPhoneNumber =this.inputPhoneNumberElementRef.nativeElement.value.replace(/[^0-9]/g,"");
      if (numericPhoneNumber.length === 10) {
          country = 1;
          city = numericPhoneNumber.slice(0, 3);
          number = numericPhoneNumber.slice(3);
      }
      else {
        this.inputPhoneNumberElementRef.nativeElement.value = numericPhoneNumber;
      }

      if (country === 1) {
          country = "";
      }

      number = number.slice(0, 3) + '-' + number.slice(3);
      this.inputPhoneNumberElementRef.nativeElement.value = ( country + "(" +city + ")" + " " + number).trim();
  }

  public formatPhoneNumberOnLoad(phoneNumber: string): string {
    var country, city, number;
    var numericPhoneNumber = phoneNumber?.replace(/[^0-9]/g,"");
      if (numericPhoneNumber?.length === 10) {
          country = 1;
          city = numericPhoneNumber.slice(0, 3);
          number = numericPhoneNumber.slice(3);
      }
      else {
          return phoneNumber;
      }

      if (country === 1) {
          country = "";
      }

      number = number.slice(0, 3) + '-' + number.slice(3);
      return( country + "(" +city + ")" + " " + number).trim();
  }

  allowNumericDigitsKeypress(event){
    return (event.charCode === 8 || event.charCode === 0 || event.charCode === 13)
          ? null : event.charCode >= 48 && event.charCode <= 57
  }
}
