<div class="help-landing-container heading-container">
    <h1>User Notification</h1>
</div>
<div class="help-landing-page main-container-sub responsive-container separate-form-container">
    <section class="right-section assign-container col-12 pl-2 pr-2" style="max-width: 100%;">

      <div class="p-0" autocomplete="off">
        <div class="landing-search-wrapper">
            <div class="search-container">
                <div class="help-search">
                    <input type="text" [(ngModel)]="searchText" (ngModelChange)="onSearchTextChanged()"
                        placeholder="Type to search">
                        <button style="cursor: pointer" *ngIf="searchText" (click)="reset()"><i class="material-icons icon-after">clear</i></button>
                </div>
            </div>
        </div> 
        <div style="margin-top: -2em" class="text-center mb-5">
            <small class="">Search for Title, Message, Type, Severity or Status</small>
        </div>
        
    </div>

        <div class="alert alert-success alert-dismissible" *ngIf="!isError && successMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
            <span class="iconLabel" aria-label="sucess">
            </span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>
              {{successMsg}}
  
            </p>
        </div>
        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
            <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="errorMsg"></p>
          </div>

        <div class="data-table-container">
            <div class="justify-content-between d-flex">
            <div class="headline-container">
              <p *ngIf="pagerObject">Total {{pagerObject.totalCount | number}} items</p>
            </div>
            <div class="refresh-icon-link mt-1">
                <!-- add class of 'fa-rotation' on click of the refresh icon -->
                <a title="Refresh List" id="refreshTask">
                  <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
                </a>
              </div>
        </div>
        <div class="table-responsive form-responces s-overflow-auto">
            <div>
              <app-data-table *ngIf="notifications && notifications.length > 0"
                [rows]="notifications"
                [keys]="keys"
                [showCheckbox]="false"
                [enableSorting]="true"
                [displayColumns]="displayColumns"
                type="userNotification"
                (sortingClicked)="applySorting($event)"
                (buttonClicked)="onActionClicked($event)">
              </app-data-table>
            </div>

            <div class="text-center" *ngIf="(!notifications || notifications.length === 0)">
                No records available.
            </div>

            <div *ngIf="pagerObject">
                <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 " [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
            </div>
        </div>
        </div>
    </section>
</div>