<main class="dashboard-container">
  <div>
    <section class="top-container">
      <article class="left-container">
        <!-- <h1>Welcome {{userDetails.name}},</h1> -->
        <p>
          <!--Last logged on <span>{{LastLoginTimeStamp}}</span><br>-->
          {{content['dashboardLastLogged']}} <span>{{LastLoginTimeStamp}}</span>
        </p>
      </article>
      <article class="right-container">
        <!-- <div class="icon-container">
            <a href="#" class="action-link"><span class="material-icons">settings</span></a>
        </div> -->
      </article>
    </section>
    <section class="dashboard-content-area">
      <article class="dashboard-tab-container">
        <ul role="tablist" class="nav nav-tabs scoped-tabs">
          <li class="nav-item">
            <a id="tab-1" role="tab" aria-selected="true" href="#tab13" data-toggle="tab"
               class="nav-link active show"> {{content['dashboardHeaderDashboard']}} </a>
          </li>
          <li class="nav-item" *ngIf="!isRespondent">
            <a id="tab-2" role="tab" routerLink="/dashboard/analyticsSummary" routerLinkActive="active" aria-selected="false" data-toggle="tab"
               class="nav-link" active show> Analytics </a>
          </li>
          <li class="nav-item" *ngIf="canAccessReports">
            <a id="tab-3" role="tab" routerLink="/dashboard/report" routerLinkActive="active" aria-selected="false" data-toggle="tab"
               class="nav-link" active show> Reports </a>
          </li>
        </ul>
        <div id="myTabContent" class="tab-content">
          <div id="tab13" role="tabpanel" aria-labelledby="tab-1" class="tab-pane fade active show">
            <div class="tabl-heading">
              <h2>{{content['dashboardHeaderDashboard']}}</h2>
            </div>
            <section class="tab-content-container">
              <article class="container-right ">
                <div class="tile-container">
                  <div class="tile-header"><h3>{{content['pendingTasksHeaderDashboard']}}</h3>
                    <div class="d-flex">
                      <div id="myAssignedRefresh" class="refresh-icon-link mt-1 mr-2">
                        <!-- add class of 'fa-rotation' on click of the refresh icon -->
                        <a  title="Refresh My Pending Tasks" id="refreshTask"  (click)="refreshMyAssignedTasks()" >
                          <i aria-hidden="true" class="fa fa-refresh" ></i>
                        </a>
                      </div>
                      <app-prefernce-icon [listname]="pendinglistname"></app-prefernce-icon>
                    </div>
                  </div>
                </div>
                <div class="tile-table-container">
                  <form class="form-filter-container filter-bar" [formGroup]="filterForm" autocomplete="off">
                    <div id="category-filter-dashboard" class="filter-form-control">
                      <label for="Category">{{content['categoryFilterDashboard']}}:</label>
                      <div class="fdl-select">
                        <select name="category" aria-invalid="false" class="form-control" formControlName="category">
                          <option value="">{{content['categoryAllFilterDashboard']}}</option>
                          <option value="Data Gathering">{{content['categoryDataFilterDashboard']}}</option>
                          <option value="intake">{{content['categoryIntakeFilterDashboard']}}</option>
                          <option value="project workflow template">{{content['categoryProjectFilterDashboard']}}</option>
                        </select>
                      </div>

                    </div>
                    <div class="filter-form-control" [ngClass]="{'has-error': !filterForm.get('title').valid && (filterForm.get('title').touched || filterForm.get('title').dirty  || isSubmitted)}">
                      <span *ngIf="!filterForm.get('title').valid && filterForm.get('title').errors?.pattern && (filterForm.get('title').touched || filterForm.get('title').dirty  || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter a valid Form Name </span>
                      <label>{{content['formNameColumnDashboard']}}:</label>
                      <fdl-type-ahead  class="filter-typeahead" formControlName="title" [collection]="responsesSuggestion" optionField="title" [placeholder]="content['placeholderSearchFormResponse']"
                      [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                      </fdl-type-ahead>
                    </div>
                    <div id="project-filter-dashboard" class="filter-form-control" [ngClass]="{'has-error': !filterForm.get('projectTitle').valid && (filterForm.get('projectTitle').touched || filterForm.get('projectTitle').dirty  || isSubmitted)}">
                      <span *ngIf="!filterForm.get('projectTitle').valid && filterForm.get('projectTitle').errors?.pattern && (filterForm.get('projectTitle').touched || filterForm.get('projectTitle').dirty  || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter a valid Project Name </span>
                      <label>{{content['projectColumnDashboard']}}:</label>
                      <fdl-type-ahead class="filter-typeahead" formControlName="projectTitle" [collection]="projectSuggestion" optionField="title"
                        placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                      </fdl-type-ahead>
                    </div>
                    <div class="filter-form-control">

                      <label for="Status">{{content['statusColumnDashboard']}}:</label>
                      <div class="fdl-select">
                        <select name="Status" class="form-control" aria-invalid="false" formControlName="responseStatusTitle">
                          <option value="">All</option>
                          <!--<option *ngFor="let status of formStatus" [value]="status.title">{{status.title}}</option>-->
                          <option value="Not Started">Not Started</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Under Review">Under Review</option>
                          <option value="Overdue">Overdue</option>
                        </select>
                      </div>

                    </div>
                    <div class="filter-form-control action-button button-filter">
                      <button type="button" class="btn fdl-btn btn-primary-dark" aria-label="Search" (click)="filterList()">{{content['searchFormResponse']}}</button>
                    </div>
                    <div class="filter-form-control action-button ml-2 buttom-wrapper button-filter" *ngIf="filtered">
                      <button type="button" class="btn fdl-btn btn-primary-dark" aria-label="Reset" (click)="clearFilterList()">Reset</button>
                    </div>

                  </form>
                  <div class=" form-responces overflow-auto">
                    <div>
                      <app-data-table *ngIf="formList"
                                      [rows]="formList.forms"
                                      [keys]="myAssignKeys"
                                      [displayColumns]="myAssignDisplayColumns"
                                      (buttonClicked)="myAssignNavigate($event)"
                                      (deleteBtnClicked)="myAssignNavigate($event)"
                                      (sortingClicked)="myAssignApplySorting($event)"
                                      [showCheckbox]="false"
                                      [showDelete]="false"
                                      [showEdit]="false"
                                      [showViewHistory]="false"
                                      [showMyAssignedActionButton]="true"
                                      [showMenu]="false"
									                    [showViewTimeline]="false"
                                      [listname]="pendinglistname"
                                      type="Response">
                      </app-data-table>
                    </div>

                  </div>
                </div>
                <div class="cta-view-all-action-btn-container" *ngIf="myAssignPagerObject && myAssignPagerObject.totalPages > 1 && formList && formList.forms.length > 0">
                  <div class="line-top" *ngIf="myAssignPagerObject && myAssignPagerObject.totalPages > 1 && formList && formList.forms.length > 0"></div>
                  <div>
                    <app-pagination *ngIf="myAssignPagerObject && myAssignPagerObject.totalPages > 1 && formList && formList.forms.length > 0"
                                    [pagerObject]="myAssignPagerObject"
                                    (pagingChanged)="myAssignChangePage($event)"></app-pagination>
                  </div>
                </div>
              </article>
              <article class="container-left ">
                <div class="tile-container">
                  <div class="tile-header"><h3>{{content['favoritesHeaderDashboard']}}</h3>
                                           <div class="d-flex">
                                             <div id="myFavs" class="refresh-icon-link mt-1 mr-2">
                                               <!-- add class of 'fa-rotation' on click of the refresh icon -->
                                               <a title="Refresh My Favorites" id="refreshTask" (click)="refreshMyFavs()">
                                                 <i aria-hidden="true" class="fa fa-refresh"></i>
                                               </a>
                                             </div>
                                             <app-prefernce-icon *ngIf="favCategory == 'Responses'" [listname]="favoriteresponselistname"></app-prefernce-icon>
                                             <app-prefernce-icon *ngIf="favCategory == 'Projects'" [listname]="favoriteprojectlistname"></app-prefernce-icon>

                                           </div>
                  </div>
                </div>
                <div class="tile-table-container">
                  <div id="filter-favorites-dashboard" class="filter-form-control">
                    <label for="Category:">{{content['categoryColumnDashboard']}}:</label>
                    <div class="fdl-select">

                      <select name="category" aria-invalid="false" class="form-control ng-pristine ng-valid ng-touched" [(ngModel)]="favCategory" (change)="onFavCategoryChange()">
                        <option *ngFor="let cat of favcategories | keyvalue" [value]="cat.value">{{cat.value}}</option>
                      </select>

                    </div>
                  </div>
                  <div class="form-responces overflow-auto">

                    <div *ngIf="favCategory == 'Responses'">
                      <app-data-table *ngIf="favoriteResponseList.favorites"
                                      [rows]="favoriteResponseList.favorites"
                                      [keys]="favResponseKeys"
                                      [displayColumns]="favResponseDisplayColumns"
                                      (sortingClicked)="favResponseApplySorting($event)"
                                      [showCheckbox]="false"
                                      [showViewEdit]="false"
                                      [showDelete]="false"
                                      (deleteBtnClicked)="favResponseNavigate($event)"
                                      type="Response"
                                      (buttonClicked)="favResponseNavigate($event)"
                                      [showFavoriteIcon]="true"
                                      [showViewTimeline]="isViewTimelineAllowed"
                                      [showEditFormDetails]="isEditFormDetailsAllowed"
                                      [showViewProjectTimeline]="true"
                                      [listname]="favoriteresponselistname"
                                      (favButtonClicked)="removeFavoriteResponse($event)"
                                   >
                      </app-data-table>
                    </div>
                    <div class="cta-view-all-action-btn-container" *ngIf="favCategory == 'Responses'">
                      <div class="line-top" *ngIf="favResponsePagerObject && favResponsePagerObject.totalPages > 1 && favoriteResponseList && favoriteResponseList.favorites.length > 0"></div>
                      <div *ngIf="favCategory == 'Responses'">
                        <app-pagination *ngIf="favResponsePagerObject && favResponsePagerObject.totalPages > 1 && favoriteResponseList && favoriteResponseList.favorites.length > 0"
                                        [pagerObject]="favResponsePagerObject"
                                        (pagingChanged)="favResponseChangePage($event)"></app-pagination>
                      </div>
                    </div>
                    <div *ngIf="favCategory == 'Projects'">
                      <app-data-table *ngIf="favoriteProjectList.favorites"
                                      [rows]="favoriteProjectList.favorites"
                                      [keys]="favProjectKeys"
                                      [displayColumns]="favProjectDisplayColumns"
                                      (sortingClicked)="favProjectApplySorting($event)"
                                      [showCheckbox]="false"
                                      [showViewEdit]="false"
                                      [showDelete]="false"
                                      (deleteBtnClicked)="favProjectNavigate($event)"
                                      type="Project"
                                      (buttonClicked)="favProjectNavigate($event)"
                                      [showFavoriteIcon]="true"
                                      [showViewProjectTimeline]="true"
                                      [listname]="favoriteprojectlistname"
                                      (favButtonClicked)="removeFavoriteProject($event)"
                                    >
                      </app-data-table>
                    </div>

                  </div>
                </div>

                <div class="cta-view-all-action-btn-container" *ngIf="favCategory == 'Projects'">
                  <div class="line-top" *ngIf="favProjectPagerObject && favProjectPagerObject.totalPages > 1 && favoriteProjectList && favoriteProjectList.favorites.length > 0"></div>
                  <div *ngIf="favCategory == 'Projects'">
                    <app-pagination *ngIf="favProjectPagerObject && favProjectPagerObject.totalPages > 1 && favoriteProjectList && favoriteProjectList.favorites.length > 0" [pagerObject]="favProjectPagerObject"
                                    (pagingChanged)="favProjectChangePage($event)"></app-pagination>
                  </div>
                </div>
              </article>
            </section>
          </div>

        </div>
      </article>
    </section>
  </div>
  <app-edit-form-details (successUpdate)="successUpdateApproverClick($event)" [data]="responseData" #editformdetails></app-edit-form-details>
  <!-- <section class="filter-panel-container">
      <nav class="sidebar d-none active">
          <div class="sidebar-header">
              <button id="closeFilter" class="btn btn-secondary-transparent filter-icon-horizontal"><span
                      class="filter-nav-text"> Filters</span><span class="right-arrow-back"></span></button>
          </div>
          <form  class="form form-border pd-25 ng-untouched ng-pristine ng-valid">
              <div>
                  <div class="filter-form-control row">
                      <div class="form-group col-lg-12">
                          <div class="custom-search-input">
                              <div class="input-group">
                                  <input type="text" id="search" placeholder="Search"
                                      class="search-query form-control" />
                                  <span class="input-group-btn">
                                      <button type="button" class="btn"><i
                                              class="material-icons icon-after">search</i></button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div class="filter-form-control row">
                      <div class="col-lg-12 mb-3">
                          <label for="project">Project</label>
                          <div class="fdl-select border-0">
                              <select id="project" aria-invalid="false" class="form-control">
                                  <option>All</option>
                                  <option></option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div class="filter-form-control row">
                      <div class="col-lg-12 mb-3">
                          <label for="program">Program</label>
                          <div class="fdl-select border-0">
                              <select id="program" aria-invalid="false" class="form-control">
                                  <option>All</option>
                                  <option></option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div class="filter-form-control row">
                      <div class="col-lg-12 mb-3">
                          <label for="product">Product</label>
                          <div class="fdl-select border-0">
                              <select id="product" aria-invalid="false" class="form-control">
                                  <option>All</option>
                                  <option></option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div class="filter-form-control row">
                      <div class="col-lg-12 mb-3">
                          <label for="version">Version</label>
                          <div class="fdl-select border-0">
                              <select id="version" aria-invalid="false" class="form-control">
                                  <option>All</option>
                                  <option></option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div class="filter-form-control row">
                      <div class="col-lg-12 mb-3">
                          <label for="implementationState">Implementation State</label>
                          <div class="fdl-select border-0">
                              <select id="implementationState" aria-invalid="false" class="form-control">
                                  <option>All</option>
                                  <option></option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div class="filter-form-control row">
                      <div class="col-lg-12 mb-3">
                          <label for="projectStatus">Project Status</label>
                          <div class="fdl-select border-0">
                              <select id="projectStatus" aria-invalid="false" class="form-control">
                                  <option>All</option>
                                  <option></option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div class="filter-form-control row">
                      <div class="col-lg-12 mb-3">
                          <label for="implemntationManager">Implementation Manager</label>
                          <div class="fdl-select border-0">
                              <select id="implemntationManager" aria-invalid="false" class="form-control">
                                  <option>All</option>
                                  <option></option>
                              </select>
                          </div>
                      </div>
                  </div>
                  <div class="filter-form-control row fdl-2">
                      <div class="col-lg-6 mb-3 float-left"><button type="button" tabindex="0" aria-label="Filter"
                              class="btn btn-primary-orange">Filter</button></div>
                      <a href="" class="btn btn-secondary-transparent text-right pt-0 pb-3"
                          style="display: flex; align-items: center;">Reset<span
                              class="material-icons icon-after float-right ml-41"> chevron_right </span></a>
                  </div>
              </div>
          </form>
      </nav>
      <nav class="sidebar-wrapped">
          <button type="button" id="sidebarCollapse"
              class="btn btn-secondary-transparent left-arrow-back sidebar-collapse-btn"></button>
          <button class="btn btn-secondary-transparent filter-icon-vertical"><span class="filter-right-nav-text">
              My Dashboard Settings</span></button>
      </nav>
  </section> -->
</main>
