<div class="heading-container fdl-2">
    <div class="back-action-container">
        <div class="back-button">
            <a href="javascript:void(0);" (click)="goBack()">
                <span class="material-icons"> arrow_back </span>
                Users
            </a>
        </div>
        <h1>{{userData?.name || 'N/A'}}</h1>
    </div>
</div>

<div class="form-container separate-form-container">
    <div class="alert alert-success alert-dismissible" *ngIf="showUpdationMessage">
        <button type="button" class="close" data-dismiss="alert" aria-label="close"
            (click)="showUpdationMessage=false">×</button>
        <span class="iconLabel" aria-label="sucess"></span>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p>User details updated successfully.

        </p>
    </div>
    <form>
        <div class="biller-survey white-box fdl-2" *ngIf="userData">
            <button type="button" class="btn fdl-btn btn-primary-dark  pull-right" *ngIf="showEditButton"
                (click)="goToEdit()">Edit</button>
            <div>
                <ul>
                    <li>
                        User Id:
                        <span class="info">{{userData.userId || 'N/A'}}</span>
                    </li>
                    <li>
                        Email:
                        <span class="info">{{userData.email || 'N/A'}}</span>
                    </li>
                    <li>
                        Contact Number:
                        <span class="info">{{(userData.phone | phone) || 'N/A'}}</span>
                    </li>
                    <li *ngIf="userData.clientTitleWithDuns">
                        Client:
                        <span class="info">{{userData.clientTitleWithDuns || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="intake-request-form intake-request-form-wrapper client-form-wrapper full-view mb-2">
                <div class="box accordion collapse-view org-accordion">
                    <div data-toggle="collapse" href="#collapseOne" aria-expanded="true"
                        *ngIf="userData.type === 'Fiserv'"
                        class="h6 org-heading heading-level-3 mt-0 mb-0 d-flex align-items-center justify-content-between">
                        <strong> Organization Type : Base
                        </strong>
                    </div>
                    <div id="collapseOne" class="mt-3 collapse show">
                        <label class="w-100" *ngIf="userData.type === 'Fiserv'">Organization
                            Name</label>
                        <div class="filter-wrapper mb-1 pb-2" *ngIf="userData.type === 'Fiserv'">
                            <div class="filter-tag">
                                <div class="tag pr-2 pr-2">{{userData.organizationTitle}}
                                </div>
                            </div>
                        </div>
                        <label class="w-100"> Roles</label>
                        <div class="filter-wrapper mb-1 pb-2 ">
                            <div class="filter-tag" *ngFor="let role of userData.roles">
                                <div class="tag" data-toggle="tooltip" data-placement="top" title="{{role.roleTitle}}">
                                    {{role.roleTitle}}
                                </div>
                            </div>
                        </div>
                        <label class="w-100" *ngIf="userData.products && userData.products.length > 0"> Assigned
                            Products</label>
                        <div class="filter-wrapper" *ngIf="userData.products && userData.products.length > 0">
                            <div class="filter-tag" *ngFor="let product of userData.products">
                                <div class="tag" data-toggle="tooltip" data-placement="top"
                                    title="{{product.productTitle}}">
                                    {{product.productTitle}}
                                </div>
                            </div>
                        </div>
                        <label class="w-100" *ngIf="userData.groups && userData.groups.length > 0"> Groups</label>
                        <div class="filter-wrapper mt-2" *ngIf="userData.groups && userData.groups.length > 0">
                            <div class="filter-tag" *ngFor="let group of userData.groups">
                                <div class="tag" data-toggle="tooltip" data-placement="top"
                                    title="{{group.groupTitle}}">
                                    {{group.groupTitle}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="intake-request-form intake-request-form-wrapper client-form-wrapper full-view mb-2"
                *ngFor="let item of userData.crossOrganizations; let i = index">
                <div class="box accordion collapse-view org-accordion">
                    <div data-toggle="collapse" attr.href="#collapse{{i}}" aria-expanded="true"
                        class="h6 org-heading heading-level-3 mt-0 mb-0 d-flex align-items-center justify-content-between">
                        <strong> Organization Type : Cross
                        </strong>
                    </div>
                    <div attr.id="collapse{{i}}" class="mt-3 collapse show">
                        <label class="w-100">Organization Name</label>
                        <div class="filter-wrapper mb-1 pb-2 ">
                            <div class="filter-tag">
                                <div class="tag pr-2 pr-2">{{item.organizationName}}
                                </div>
                            </div>
                        </div><label class="w-100"> Roles</label>
                        <div class="filter-wrapper mb-1 pb-2 ">
                            <div class="filter-tag" *ngFor="let crossOrganizationRole of item.roles">
                                <div _ngcontent-jpx-c382="" data-toggle="tooltip" data-placement="top" class="tag"
                                    title="{{crossOrganizationRole.roleTitle}}">
                                    {{crossOrganizationRole.roleTitle}}
                                </div>
                            </div>
                        </div>
                        <label class="w-100" *ngIf="item.products && item.products.length > 0"> Assigned
                            Products</label>
                        <div class="filter-wrapper" *ngIf="item.products && item.products.length > 0">
                            <div class="filter-tag" *ngFor="let crossOrganizationProduct of item.products">
                                <div _ngcontent-jpx-c382="" data-toggle="tooltip" data-placement="top" class="tag"
                                    title="{{crossOrganizationProduct.productTitle}}">
                                    {{crossOrganizationProduct.productTitle}}
                                </div>
                            </div>
                        </div>
                        <label class="w-100" *ngIf="item.groups && item.groups.length > 0"> Assigned Groups</label>
                        <div class="filter-wrapper" *ngIf="item.groups && item.groups.length > 0">
                            <div class="filter-tag" *ngFor="let crossOrganizationGroup of item.groups">
                                <div _ngcontent-jpx-c382="" data-toggle="tooltip" data-placement="top" class="tag"
                                    title="{{crossOrganizationGroup.groupTitle}}">
                                    {{crossOrganizationGroup.groupTitle}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 box mb-0" *ngIf="pageTemplateDataForDisplay.length > 0">
            <div class="col-md-12 p-0 fdl-2">
                <label aria-describedby="addAccount" class="w-100">Page Template:</label>
                <!-- Selected Page Templates buttons -->
                <div class="filter-wrapper mt-2">
                    <div class="filter-tag" *ngFor="let pageTemplate of pageTemplateDataForDisplay">
                        <div class="tag" data-toggle="tooltip" data-placement="top"
                            title="{{pageTemplate.title}} - {{translatePageTemplateAccessType(pageTemplate.accessType)}}">
                            {{pageTemplate.title}} - {{translatePageTemplateAccessType(pageTemplate.accessType)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <h2 class="h5 font-weight-bold ">Role(s) Assigned</h2>
        <p class="pt-1 f-12">
            <span class="iconLabel alert-success" aria-label="success"><i class="material-icons"
                    aria-hidden="true">check_circle</i></span>
            {{userData?.roles.length}} Role(s) Assigned
        </p>
        <div class="table-responsive">
            <app-data-table *ngIf="userData" [customClasses]="'table-layout-fixed'" [rows]="userData.roles"
                [keys]="keys" [displayColumns]="displayColumns" [showMenu]="false" [enableSorting]="false"
                [showCheckbox]="false">
            </app-data-table>
        </div>
        <ng-container *ngIf="userData?.products?.length > 0">
            <h2 class="h5 font-weight-bold ">Favorite Product(s) Assigned</h2>
            <p class="pt-1 f-12">
                <span class="iconLabel alert-success" aria-label="success"><i class="material-icons"
                        aria-hidden="true">check_circle</i></span>
                {{userData?.products?.length}} Product(s) Assigned
            </p>
            <div class="table-responsive">
                <app-data-table *ngIf="userData" [customClasses]="'table-layout-fixed'" [rows]="userData.products"
                    [keys]="productKeys" [displayColumns]="productDisplayColumns" [showMenu]="false"
                    [enableSorting]="false" [showCheckbox]="false">
                </app-data-table>
            </div>
        </ng-container> -->
        <ng-container *ngIf="userData?.clients?.length > 0">
            <h2 class="h5 font-weight-bold "> Client(s) Assigned</h2>
            <p class="pt-1 f-12">
                <span class="iconLabel alert-success" aria-label="success"><i class="material-icons"
                        aria-hidden="true">check_circle</i></span>
                {{userData?.clients?.length}} Client(s) Assigned
            </p>
            <div class="table-responsive">
                <app-data-table *ngIf="userData" [customClasses]="'table-layout-fixed'" [rows]="userData.clients"
                    [keys]="clientKeys" [displayColumns]="clientDisplayColumns" [showMenu]="false"
                    [enableSorting]="false" [showCheckbox]="false">
                </app-data-table>
            </div>
        </ng-container>
        <!-- <ng-container *ngIf="userData?.groups?.length > 0">
            <h2 class="h5 font-weight-bold ">Group(s) Assigned</h2>
            <p class="pt-1 f-12">
                <span class="iconLabel alert-success" aria-label="success"><i class="material-icons"
                        aria-hidden="true">check_circle</i></span>
                {{userData?.groups?.length}} Group(s) Assigned
            </p>
            <div class="table-responsive">
                <app-data-table *ngIf="userData" [customClasses]="'table-layout-fixed'" [rows]="userData.groups"
                    [keys]="groupKeys" [displayColumns]="groupDisplayColumns" [showMenu]="false" [enableSorting]="false"
                    [showCheckbox]="false">
                </app-data-table>
            </div>
        </ng-container> -->
        <div class="fdl-2 button-container">
            <div>
                <button type="button" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
            </div>
        </div>
    </form>
</div>