<div>
  <progress-bar [answer]="completedForm" [steps]="pageCount" [isUnauthenticated]="isUnauthenticated" [delegateFormData]="formdata"
                [headerVal]="formdata.title" [readonly]="readonly" (pdfDownloadClicked)="downLoadType($event)"></progress-bar>
  <div class=" main-container-sub m-0 ">
    <div class="row m-0 mb-0" *ngIf="(isResponse || isHistory) && !isUnauthenticated && isShowProjectDetail">
      <div id="accordion" class="accordion collapse-view col-md-12 accordian-responses grid-wrapper-section">
        <div id="collapseOne" data-parent="#accordion" class="collapse show">
          <div class="biller-survey">
            <div>
              <ul>
                <li *ngIf="formdata.projectTitle">Project Name <span class="info">{{formdata?.projectTitle || 'N/A'}}</span></li>
                <li *ngIf="formdata.clientTitle">Client Name <span class="info">{{formdata?.clientTitleWithDuns || 'N/A'}}</span></li>
                <li *ngIf="formdata.assignedToName">Respondent Name <span class="info">{{formdata?.assignedToName || 'N/A'}}</span></li>
                <li *ngIf="formdata.fiservImplementationManagerName && formdata.projectTitle">Project Manager Name <span class="info">{{formdata?.fiservImplementationManagerName || 'N/A'}}</span></li>
                <li *ngIf="formdata.currentApproverName">Approver Name <span class="info">{{formdata?.currentApproverName || 'N/A'}}</span></li>
                <li *ngIf="formdata.recieverOnComplete">Receiver on Complete <span class="info">{{formdata?.recieverOnCompleteName || 'N/A'}}</span></li>
              </ul>
              <ul>
                <li *ngIf="formdata.dueDate && formdata.dueDate!='0001-01-01T00:00:00Z' && formdata.dueDate!='0001-12-31T00:00:00Z'">Due Date <span class="info">{{formdata?.dueDate | dateFormat}}</span></li>
                <li *ngIf="formdata.createdByName">Initiated By <span class="info">{{formdata?.createdByName || 'N/A'}}</span></li>
                <li *ngIf="formdata.createdOn && formdata.createdOn!='0001-01-01T00:00:00Z' && formdata.createdOn!='0001-12-31T00:00:00Z'">Initiated Date <span class="info">{{formdata?.createdOn | dateFormat}}</span></li>
                <li *ngIf="formdata.modifiedByName">Last Updated User <span class="info">{{formdata?.modifiedByName || 'N/A'}}</span></li>
                <li *ngIf="formdata.modifiedOn && formdata.modifiedOn!='0001-01-01T00:00:00Z' && formdata.modifiedOn!='0001-12-31T00:00:00Z'">Last Updated Date <span class="info">{{formdata?.modifiedOn | dateFormat}}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 separate-form-container " [ngClass]="!hideLeftNav ? 'p-0' : 'p-0'">
      <aside class="aside side-navbar listing col-xl-2 col-3" [ngClass]="!hideLeftNav ? 'col-xl-2 col-3' : 'col-xl-2 col-3 asidebar'"
             *ngIf="fieldSets && fieldSets.length > 1">
        <div class="left-nav" [ngClass]="!hideLeftNav? '' : 'left-nav-collapse'" role="navigation">
          <div class="left-nav-icon" aria-label="menu" tabindex="" aria-expanded="false" (click)="toggleSideBar()">
            <i class="navbar-icon-menu" [ngClass]="!hideLeftNav ? '' : 'navbar-icon-close' "></i>
            <span class="toggle-heading">&nbsp;</span>
          </div>
          <ol class="timeline">
            <ng-container *ngFor="let fieldset of fieldSets; index as i">
              <li [ngClass]="getClassName(fieldset)" *ngIf="(fieldset.tempshow!=false || showConditionalPages==true)">
                <a href="javascript:void(0);" (click)="checkDataLoss('fieldSetChange',i)">{{fieldset.legend}}</a>
              </li>
            </ng-container>
          </ol>
        </div>
      </aside>
      <section class="p-0" [ngClass]="computeClazzForNavigation()">
        <div class="btn-outer d-flex">


          <button type="button" title="Add To Favorite" class="btn fdl-btn btn-primary-dark btn-addcomment mr-1 add-to-favorite" id="addToFavorite" *ngIf="showFav()" (click)="addRemoveFavorites()">
            <span aria-hidden="true" class="star fa fa-star-o" *ngIf="!isFavorite"></span>
            <span aria-hidden="true" class="star fa fa-star" *ngIf="!!isFavorite"></span>
          </button>

          <button type="button" (click)="openAddCommentModal()" *ngIf="showComment" [ngClass]="{'mr-1': !showCommentBadge }" data-toggle="modal" title="Add/View/Upload Comments" class="btn fdl-btn btn-primary-dark btn-addcomment mr-1" id=" {{currentFieldSet.components[0].key}}">
            <span class="material-icons material-icons-wrapper">chat</span>
            <div *ngIf="showCommentBadge" class="badge badge-danger" [innerHtml]="commentBadgeCount"></div>
          </button>

          <div class="dropdown downloadDropdownMenuButton"  *ngIf="showComment">
            <button *ngIf="showComment"
                    type="button" title="Download Extract" class="btn btn fdl-btn btn-primary-dark btn-addcomment"
                    id="downloadDropdownMenuButton"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <span class="material-icons material-icons-wrapper">
                get_app
              </span>
            </button>

            <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">

              <a class="dropdown-item" (click)="downLoadType({'type':'pdf'})">Download response PDF</a>
              <!-- <a class="dropdown-item" (click)="downLoadType({'type':'attachments'})">Download all attached files</a> -->
              <!-- <a class="dropdown-item">Download CSV</a> -->
            </div>
          </div>

          <button *ngIf=" assignMe && isTakeDelegateAction() && !isDelegateDisabled() && !isUnauthenticated" type="button" aria-label="Default brand color secondary blue" (click)="assignToMe()" class="btn fdl-btn btn-primary-dark " style="margin-left: 10px; margin-right:10px;">
            <b>
              Request
              Edit Access
            </b>
          </button>
          <button *ngIf="!showComment" type="button" aria-label="Default brand color secondary blue" (click)="showConditional()" class="btn fdl-btn btn-primary-dark " style="margin-left: 10px; margin-right:10px;">
            <b>
              Toggle Conditional Elements
            </b>
          </button>









          <!--<button *ngIf="showComment" type="button" data-toggle="modal" style="margin-left:10px;"
                  class="btn btn-secondary addComment" aria-label="Default brand color secondary blue"
                  click="commentDefaultValue()" id=" {{currentFieldSet.components[0].key}}">
            Add/View Comment
          </button>-->
        </div>
        <div class="pt-4">
          <div class="alert alert-success alert-dismissible mt-5 ml-3" *ngIf="showSuccess">
            <button type="button" class="close" data-dismiss="alert"
                    aria-label="close">
              ×
            </button>
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>
              {{content['formContainerSavedResponse']}}
            </p>
          </div>
          <div class="alert alert-success alert-dismissible mt-5 ml-3" *ngIf="isshowDelegateMessage">
            <button type="button" class="close" data-dismiss="alert"
                    aria-label="close">
              ×
            </button>
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>
              {{showDelegateMessage}}.
            </p>
          </div>
          <div class="alert alert-success alert-dismissible mt-5 ml-3" *ngIf="showSuccessDraft">
            <button type="button" class="close" data-dismiss="alert"
                    aria-label="close">
              ×
            </button>
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>
              {{draftModeMessage}}
            </p>
          </div>

          <div class="alert alert-dismissible alert-danger mt-5 ml-3" *ngIf="formIncomplete">
            <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
            <i class="material-icons">warning</i>
            <p>{{missingMessage}}</p>
          </div>
          <div class="alert alert-dismissible alert-danger mt-5 ml-3" *ngIf="isError">
            <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="errorMsg"></p>
          </div>
        </div>
        <div class="form-container pt-4 pl-4">
          <button id="changeDetect" style="display:none" (click)="changeSDetected(true)">a</button>
          <input type="hidden" id="columnno" />
          <input type="hidden" id="rowno" />
          <div style="display:none" *ngFor="let k of  tables;let m = index">
            <button id="table_{{m}}" (click)="addColunm(m)">asd add column {{m}}</button>
            <button id="tablerow_{{m}}" (click)="addRow(m)">rows {{m}}</button>
            <button id="tablen_{{m}}" (click)="nextTable(m)">next column {{m}}</button>
            <button id="tablep_{{m}}" (click)="preTable(m)">next column {{m}}</button>
            <button id="tableDynamicn_{{m}}" (click)="nextDynamicTable(m)">next column {{m}}</button>
            <button id="tableDynamicp_{{m}}" (click)="preDynamicTable(m)">next column {{m}}</button>
            <button id="tablerremove_column_{{m}}" (click)="removeColumTable(m)">removecolumn {{m}}</button>
            <button id="tablerremove_row_{{m}}" (click)="removeRowTable(m)">removerow {{m}}</button>
          </div>
          <button id="mockClick" style="display:none;" (click)="comparePopup()">adfsdf</button>
          <input id="inputmockClick" style="display:none;" />
          <button id="apiCall" style="display:none;" (click)="apiCall()">adfsdf</button>
          <input id="inputapiCall" style="display:none;" />
          <button id="refereshClick" style="display:none;" (click)="refereshClick()">adfsdf</button>


          <!-- <button id="commentValue" (click)="commentDefaultValue()" style="display:none">fsdfs</button> -->
          <div class="formio-form frontend" id="dvCurrent">

            <formio [form]="currentFieldSet2" [options]="formbuilder" [readOnly]="readonly && showComment" [submission]=answer (submit)="onSubmit($event,false)" [refresh]="refreshForm" (change)="change($event)" (ready)="render2($event)" (formLoad)="render($event)" #formio></formio>
            <div class="fdl-2 button-container bottom-0" id="button_view">
              <div>

                <button type="button" *ngIf="!isUnauthenticated" class="btn fdl-btn btn-secondary-light  mr-2" aria-label="Default" (click)="goBack()">
                  <span *ngIf="isPreview">Continue Editing</span>
                  <span *ngIf="!isPreview && !isIntake">Cancel</span>
                  <span *ngIf="isIntake">Back to Intake List Screen</span>


                </button>

                <button id="skip-button-form-container" type="button" class="btn fdl-btn btn-secondary-light  mr-2" aria-label="Default" *ngIf="((isShowPage) && readonly!=true)" (click)="checkDataLoss('skip')">Skip</button>
                <button *ngIf="showApproverButtons && !isShowPage" type="button" class=" btn fdl-btn btn-primary-orange mr-2" aria-label="Default" (click)="checkDataLoss('reject')">Reject</button>
                <button *ngIf="showApproverButtons && !isShowPage" type="button" class="btn fdl-btn btn-primary-orange" aria-label="Default" (click)="checkDataLoss('sendBack')">Send Back</button>
              </div>

              <div>

                <button type="button" class="btn fdl-btn btn-primary-orange  mr-2" aria-label="Default" *ngIf="((fieldSets.length > 1 && (currentFieldSetIndex > 0)) && readonly==true)" (click)="checkDataLoss('previous')">Previous</button>
                <button id="save-button-form-container" *ngIf="!readonly" type="button" class="btn fdl-btn btn-primary-orange mr-2" aria-label="Save" (click)="saveDraft()">
                  <span>Save</span>
                </button>

                <button *ngIf="!readonly && (currentFieldSetIndex !== fieldSets.length-1 && (isShowPage))" type="button" class="btn fdl-btn btn-primary-orange mr-2" aria-label="Continue" (click)="saveContinueForm(false)">
                  <span *ngIf="(currentFieldSetIndex !== fieldSets.length-1 && (isShowPage))">Continue</span>
                </button>
                <button *ngIf="doSubmitButton()" type="button" class="btn fdl-btn btn-primary-orange" aria-label=" Submit" (click)="saveContinueForm(true)">
                  Submit
                </button>
                <button *ngIf="readonly == true && (currentFieldSetIndex !== fieldSets.length-1 && (isShowPage))" type="button" class="btn fdl-btn btn-primary-orange"
                        aria-label="Next" (click)="saveContinueForm(false)">
                  Next
                </button>
                <button *ngIf="showApproverButtons && !isShowPage" type="button" class="btn fdl-btn btn-primary-orange mr-2" aria-label="Approve" (click)="checkDataLoss('approve')">Approve</button>
              </div>

            </div>
          </div>
        </div>


      </section>

    </div>
    <div *ngIf="showComment">
      <!-- <app-footer></app-footer> -->
    </div>
  </div>
</div>
<!-- Add Comment Modal Start -->
<div class="modal fade bd-example-modal-lg" id="addcomment" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
     aria-labelledby="myLargeModalLabel" aria-hidden="true" #content>
  <div class="modal-dialog modal-lg" style="max-width: 1280px;" role="document" [ngClass]="{'mt-120': !showCommentBadge}">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Add Comment</h4>
        <button type="button" class="close" data-dismiss="modal"
                aria-label="Close popup">
          <span aria-hidden="true" (click)="cancelCommentHandle();">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-dismissible alert-danger" *ngIf="isCommentError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isCommentError=false">×</button> <i class="material-icons">warning</i>
          <p [innerHtml]="commentErrorMsg"></p>
        </div>
        <div class="row ">
          <div class="col-md-4 mb-3 sd">
            <label for="organizationTitle">Comment Category:</label>
            <div class="fdl-select">
              <select name="formType" class="form-control" aria-invalid="false" [(ngModel)]="commentCat">
                <option value="Comment">Comment</option>
                <option value="Issue">Issue</option>

                <option value="Question">Question</option>
                <option value="Risk">Risk</option>
                <!--<option value="Both">Data Gathering & Intake</option>-->
              </select>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-12 mb-3" [ngClass]="{'has-error': !commentControl.valid && (commentControl.touched || commentControl.dirty) }">
            <label for="txtComment">Comment:</label>
            <textarea #commentControl="ngModel" [pattern]="validators.description_comment" type="text" [(ngModel)]="commentValue" class="form-control" rows="4" id="email" [disabled]="isHistory" maxlength="2000" (keyup)="commentCount()"></textarea>
            <div class="float-right"><span>{{commentCnt}} / 2000</span></div>
            <span *ngIf="!commentControl.valid && (commentControl.touched || commentControl.dirty)">
              <i class="material-icons">warning</i> {{regexValidationMessage.DESCRIPTION_COMMENT_VALIDATION_MESSAGE}}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="commentFileName">
              Upload File:
              <span _ngcontent-yvp-c156="" data-toggle="tooltip"
                    title="File size Limit - 50MB"
                    class="material-icons btn-secondary f-18">
                info
              </span>
            </label>
            <div class="custom-file">
              <!-- <label for="commentFileName" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="commentFileName"> {{ commentFileName }}</label>
              <input #inputCommentFile type="file" class="custom-file-input form-control" multiple="false" (change)="onCommentFileChange($event)"> -->
              <form class="form form-border collapse-view" [formGroup]="commentAnswer" autocomplete="off">
                <div [ngClass]="{'has-error': !commentAnswer.controls.commentCtrl.valid && (commentAnswer.controls.commentCtrl.touched)}">
                  <div class="custom-file">
                    <label for="file" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="filename">{{filename}}</label>
                    <input type="file" class="custom-file-input form-control" id='fileUpload' multiple="false" (change)="onCommentFileChange($event)">
                  </div>

                  <div class="has-error" *ngIf="commentAnswer.controls.commentCtrl.invalid && (commentAnswer.controls.commentCtrl.touched || isCommentSubmit) || !validCommentFileExtension || !validCommentFileType || !validCommentFileName">
                    <span *ngIf="commentAnswer.controls.fileAlias?.errors?.pattern && (commentAnswer.controls.commentCtrl.touched || isCommentSubmit)">
                      <i class="material-icons">warning</i> Please select a valid file name.
                    </span>
                    <span *ngIf="commentAnswer.controls.commentCtrl.errors?.invalidExt && (commentAnswer.controls.commentCtrl.touched || isCommentSubmit) || !validCommentFileExtension">
                      <i class="material-icons">warning</i> Please select a valid file.
                    </span>
                    <span *ngIf="commentAnswer.controls.commentCtrl.errors?.invalidType  && (commentAnswer.controls.commentCtrl.touched || isCommentSubmit) || !validCommentFileType">
                      <i class="material-icons">warning</i> Please select a valid file type.
                    </span>
                    <span *ngIf="commentAnswer.controls.commentCtrl.errors?.invalidSize && (commentAnswer.controls.commentCtrl.touched || isCommentSubmit)">
                      <i class="material-icons">warning</i> Maximum file size limit is {{_maxLength.File.Size}}MB, please correct it.
                    </span>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div class="fdl-2 w-100 text-right mt-0">
          <button type="button" class="btn fdl-btn btn-secondary-light  mr-2" data-dismiss="modal" (click)="cancelCommentHandle()">
            Cancel
          </button>
          <button id="save-button-form-container" type="button" class="btn fdl-btn btn-primary-orange" [disabled]="isHistory || isCommentSubmit" (click)="submitCommentHandle()">
            Save
          </button>
        </div>
        <!-- Comment History Table Section-->
        <div *ngIf="showCommentBadge">
          <hr />
          <div class="form-row align-items-end mb-3">
            <div class="col-md-5">
              <label for="searchForm">Page Name:</label>
              <fdl-type-ahead [(ngModel)]="commentHistSelectedPgName" name="commentHistSelectedPgName" optionField="title" placeholder="Type to search"
                              [collection]="commentHistFilterOptions" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>
            <div class="col-md-5">

              <label for="organizationTitle">Comment Category:</label>
              <div class="fdl-select">
                <select name="formType" class="form-control" aria-invalid="false" [(ngModel)]="commentCatHistory">
                  <option value="">All</option>
                  <option value="Comment">Comment</option>
                  <option value="Issue">Issue</option>

                  <option value="Question">Question</option>
                  <option value="Risk">Risk</option>

                  <!--<option value="Both">Data Gathering & Intake</option>-->
                </select>

              </div>
            </div>
          </div>
          <div class="assign-modal-table" [ngClass]="getCommentClass(commentLength)">
            <app-data-table *ngIf="commentHistoryList" [rows]="commentHistoryList" [keys]="commentTableKeys"
                            [displayColumns]="commentTableHeaders" [enableSorting]="false"
                            [searchString]="commentHistSelectedPgName" searchStringFieldName="pageName" [category]="commentCatHistory" [showCheckbox]="false"
                            [showMenu]="true"
                            [showAttachmentOptions]="true"
                            [showViewEdit]="true"
                            [showAttachment]="true"
                            (attachmentClicked)="downloadAttachment($event)"
                            (buttonClicked)="commentActions($event)">
            </app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add Comment Modal End -->
<!--submit form response Modal Begin--->

<div class="modal fade bd-example-modal-lg" id="submitFormResponse" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
     aria-hidden="true" #content>
  <div class="modal-dialog modal-lg mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Submit Form Response</h4>

      </div>
      <div class="modal-body">
        <p><b>Are you sure you want to submit the form response?</b></p>
        <br>
        <p>Once submitted, you will not be able to edit the form anymore.</p>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class=" btn fdl-btn btn-secondary-light " data-dismiss="modal" (click)="isResponseModalShow=false">
          Cancel
        </button>
        <button type="button" class=" btn fdl-btn btn-primary-orange mr-2" data-dismiss="modal" (click)="saveContinueForm(isSubmitClicked)">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
<!------------------------------Upload Answer-->
<div class="modal fade bd-example-modal-lg" id="uploadAnswer" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
     aria-hidden="true" #content>
  <div class="modal-dialog modal-lg mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Import Response Data</h4>
        <button type="button" class="close" data-dismiss="modal"
                aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">

        <form class="form form-border collapse-view" [formGroup]="uploadAnswer" autocomplete="off">

          <div class="filter-form-container-column2 collapse-file-upload">
            <!-- Organization -->

            <div class="row align-items-baseline">


              <div class="col-md-4" [ngClass]="{'has-error': !uploadAnswer.controls.fileCtrl.valid && (uploadAnswer.controls.fileCtrl.touched)}">
                <label for="excelinput" class="asterisk">Select Response Data:</label>
                <div class="custom-file">
                  <label for="file" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="filename">{{filename}}</label>
                  <input type="file" class="custom-file-input form-control" id='fileUpload' (click)="$event.target.value=null" multiple="false" (change)="onFileChange($event)">
                </div>

                <div class="has-error" *ngIf="uploadAnswer.controls.fileCtrl.invalid && (uploadAnswer.controls.fileCtrl.touched)">
                  <span *ngIf="uploadAnswer.controls.fileCtrl.errors?.required && (uploadAnswer.controls.fileCtrl.touched)">
                    <i class="material-icons">warning</i> Please select a file.
                  </span>
                  <span *ngIf="uploadAnswer.controls.fileAlias?.errors?.pattern && (uploadAnswer.controls.fileCtrl.touched)">
                    <i class="material-icons">warning</i> Please select a valid file name.
                  </span>
                  <span *ngIf="uploadAnswer.controls.fileCtrl.errors?.invalidExt && (uploadAnswer.controls.fileCtrl.touched)">
                    <i class="material-icons">warning</i> Please select a valid file.
                  </span>
                  <span *ngIf="uploadAnswer.controls.fileCtrl.errors?.invalidType  && (uploadAnswer.controls.fileCtrl.touched)">
                    <i class="material-icons">warning</i> Please select a valid file type.
                  </span>
                  <span *ngIf="uploadAnswer.controls.fileCtrl.errors?.invalidSize && (uploadAnswer.controls.fileCtrl.touched)">
                    <i class="material-icons">warning</i> Maximum file size limit is {{_maxLength.File.Size}}MB, please correct it.
                  </span>
                </div>
              </div>

            </div>
          </div>


        </form>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class=" btn fdl-btn btn-secondary-light " (click)="cancelAnswerData()">
          Cancel
        </button>
        <button type="button" class=" btn fdl-btn btn-primary-orange mr-2" (click)="uploadJsonAnswerData()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
<!--------------------------------End ANswer-->
<!--Submit form Response Modal End-->
<!-----      DATA LOSS MODAL BEGIN -------->
<div class="modal fade bd-example-modal-lg" id="dataLoss" tabindex="-1" role="dialog"
     aria-labelledby="myLargeModalLabel" aria-hidden="true" #content>
  <div class="modal-dialog modal-lg mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Confirm Navigation</h4>
      </div>
      <div class="modal-body">
        <p><b>Are you sure you want to navigate away from this page?</b></p>
        <br>
        <p>
          The page contains unsaved data. If you choose to <b>Leave this page</b>, unsaved data will be lost. Choose <b>Stay on this page</b> option to return to form and save the changes
        </p>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class=" btn fdl-btn btn-secondary-light " data-dimiss="modal" (click)="closeDataLossModal()">
          Stay on this Page
        </button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal" (click)="formBtnsHandler()">
          Leave this Page
        </button>
      </div>
    </div>
  </div>
</div>

<!---  DATA LOSS MODAL END  -------->
<div class="modal fade bd-example-modal-lg" id="rejectedComments" tabindex="-1" role="dialog" aria-hidden="true" #content *ngIf="showApproverButtons">
  <div class="modal-dialog modal-lg mt-120" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          Please add your comments:<span class="asterisk"></span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-dismissible alert-danger" *ngIf="isRejectedError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isRejectedError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>
        <form [formGroup]="approvalForm" autocomplete="off" *ngIf="approvalForm">
          <div class="row">
            <div class="col-md-12 mb-3"
                 [ngClass]="{'has-error': !approvalForm.get('comments').valid && (approvalForm.get('comments').touched|| isSubmitted) }">
              <textarea type="text" name="comments" id="comments" formControlName="comments" class="form-control"
                        rows="2"></textarea>
              <span *ngIf="!approvalForm.get('comments').valid && approvalForm.get('comments').errors?.required && (approvalForm.get('comments').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please provide comments.
              </span>
              <span *ngIf="!approvalForm.get('comments').valid && approvalForm.get('comments').errors?.maxlength && (approvalForm.get('comments').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is
                {{_maxLengths.Approval.Comments}}, please correct it.
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal">
          Cancel
        </button>
        <button *ngIf="isRejected" type="button" class=" btn fdl-btn btn-primary-orange mr-2" (click)="submitApprovalForm('rejected')">
          Submit
        </button>
        <button *ngIf="!isRejected" type="button" class=" btn fdl-btn btn-primary-orange mr-2" (click)="submitApprovalForm('hold')">
          Submit
        </button>
      </div>
    </div>
  </div>


</div>

<div class="modal fade bd-example-modal-lg" id="approvalComments" tabindex="-1" role="dialog" aria-hidden="true" #content *ngIf="showApproverButtons">
  <div class="modal-dialog modal-lg mt-120" role="document" style="padding: 10px; max-width: 1100px;">
    <div class="modal-content">
      <div class="modal-header">

        <h4 *ngIf="!isApproved" class="modal-title" [ngClass]="{'has-error': isApproved }">
          Please select user(s) to send back the Form<span class=""></span>
        </h4>
        <h4 *ngIf="isApproved" class="modal-title" [ngClass]="{'has-error': isApproved }">
          Please add your comments:<span class="asterisk"
                                         *ngIf="!isApproved"></span>
        </h4>

      </div>
      <div class="modal-body">
        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>
        <form [formGroup]="approvalForm" autocomplete="off" *ngIf="approvalForm">
          <div class="row" *ngIf="!isApproved">
            <div class="col-md-12 mb-3" [ngClass]="{'has-error': !approvalForm.get('sentBackLevel').valid && (approvalForm.get('sentBackLevel').touched|| isSubmitted) }">
              <div class="card-container-levels">
                <div class="card-details" *ngFor="let rows of sentBackApprover">
                  <div class="radio">
                    <input name="myRadio" class="excludeForDataLoss" id="myRadio{{rows.id}}" type="radio" value="{{rows.id}}" formControlName="sentBackLevel">
                    <label for="myRadio{{rows.id}}"><span></span></label>
                    <!--Form/Intake Submitter-->
                    <!--Approver Level 1-->
                  </div>

                  <div class="inline-radio-wrapper">
                    <h2>{{rows.level}}</h2>

                    <div class="wrapper">
                      <div class="user-list">
                        <span>{{rows.name}}</span>
                      </div>


                    </div>
                  </div>
                </div>

              </div>
              <span *ngIf="!approvalForm.get('sentBackLevel').valid && (approvalForm.get('sentBackLevel').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select user(s) to sent back.
              </span>
            </div>
          </div>
          <!--    <div *ngIf="!isApproved" class="row">
                   <div class="col-md-12 mb-3">
              <app-data-table [rows]="sentBackApprover" [keys]="keys" [customClasses]="'send-back-table'" [(selectedRadioValue)]="selectedRadioValue"
                              [displayColumns]="displayColumns" [showMenu]="false"
                              [showCheckbox]="false" [showRadio]="true">
              </app-data-table>
            </div>
          </div>-->
          <div *ngIf="!isApproved" class="row "> <div class="col-md-12 modal-heading ">Comments<span class="asterisk"></span></div></div>
          <div class="row">
            <div class="col-md-12 mb-3"
                 [ngClass]="{'has-error': !approvalForm.get('comments').valid && (approvalForm.get('comments').touched|| isSubmitted) }">
              <textarea type="text" name="comments" id="comments" formControlName="comments" class="form-control"
                        rows="2"></textarea>
              <span *ngIf="!approvalForm.get('comments').valid && approvalForm.get('comments').errors?.required && (approvalForm.get('comments').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please provide comments.
              </span>
              <span *ngIf="!approvalForm.get('comments').valid && approvalForm.get('comments').errors?.maxlength && (approvalForm.get('comments').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is
                {{_maxLengths.Approval.Comments}}, please correct it.
              </span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal">
          Cancel
        </button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" (click)="submitApprovalForm()">
          Submit
        </button>
      </div>
    </div>
  </div>


</div>
<div class="modal fade bd-example-modal-lg" id="delegateMe" data-backdrop="static" data-keyboard="false"
     tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-lg mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Request Edit Access</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closeDelegateForm()"
                aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">


        <div class="row">
          <div class="col-md-12 ">
            <p>Currently, the Form is assigned to <b>{{formdata.editAccessWith}}</b>. Click <b>"Request Edit Access"</b> to have the Form assigned to you.</p>
            <p>Please note, once the Form is assigned to you, <b>{{formdata.editAccessWith}}</b> will lose edit access but can get edit access back by clicking <b>"Request Edit Access"</b> button or you can assign back Form by using the <b>"Delegate"</b> function.</p>
          </div>
        </div>

      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal" (click)="closeDelegateForm()">
          Cancel
        </button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2 mr-2" (click)="assignToServiceCall()">
          Request Edit Access
        </button>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<app-pdf-genrate #pdfGenerated (buttonClicked)="navigate($event)"></app-pdf-genrate>

<replace-attachment #replaceAttachment
                    [id]="commentId"
                    [formId]="commentResponseId"
                    [type]="'response'"
                    (updateAttachment)="updateAttachmentSuccess($event);">
</replace-attachment>

<delete-warning
 [componentId]="'deleteWarningModal'"
 [type]="'comment'"
 (okBtnClicked)="deleteComment(eventData)">
</delete-warning>

<delete-warning
[componentId]="'removeAttachmentWarningModal'"
[type]="'file'"
(okBtnClicked)="removeAttachment(eventData)">
</delete-warning>

<div class="modal fade bd-example-modal-lg" id="popup" data-backdrop="static" data-keyboard="false"
     tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-lg mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Compare Field</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closePopUp()"
                aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">


        <div class="row">
          <div class="col-md-12 ">
            <pre>  {{elementToDisplay | json}}
  </pre>
          </div>


      </div>
      <div class="modal-footer fdl-2">

        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2 mr-2" (click)="closePopUp()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
