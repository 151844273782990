import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'emailgroup-members',
  templateUrl: './emailgroup-members.component.html',
  styleUrls: ['./emailgroup-members.component.css']
})
export class EmailgroupMembersComponent implements OnInit {
@Input() emailGroupDetails:any =[]; 
@Input() emailGroupTitle: string;
displayColumns = ['Name', 'Email Address'];

keys = ['name', 'emailAddress'];
  constructor() { }

  ngOnInit(): void {
    console.log(this.emailGroupTitle);
  }

}
