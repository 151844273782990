<div class="modal fade bd-example-modal-lg" id="replaceAttachment" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-md mt-120" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title strong">Replace File:</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close popup" (click)="cancel();"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="alert alert-dismissible alert-danger" *ngIf="isError">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button> <i class="material-icons">warning</i>
                    <p [innerHtml]="errorMsg"></p>
                </div>
                <h5 class="strong">Select a new file to replace:
                    <span _ngcontent-yvp-c156="" data-toggle="tooltip"
                        title="File size Limit - 50MB"
                        class="material-icons btn-secondary f-18">info
                    </span>
                </h5>
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <div class="custom-file">
                            <label for="excelinput" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="fileName">{{fileName}}</label>
                            <input type="file" class="custom-file-input form-control" multiple="false" (change)="onFileChange($event)" formControlName="fileCtrl" id="fileCtrl" #inputFileControl>
                        </div>
                        <div class="has-error" *ngIf="assetForm.controls.fileCtrl.invalid && !isError">
                          <span *ngIf="assetForm.controls.fileCtrl.errors?.required && (assetForm.controls.fileCtrl.touched || submitted)">
                            <i class="material-icons">warning</i> Please select a file.
                          </span>
                          <span *ngIf="assetForm.controls.fileCtrl.errors?.invalidExt && (assetForm.controls.fileCtrl.touched || submitted)">
                            <i class="material-icons">warning</i> Please select a valid file.
                          </span>
                          <span *ngIf="assetForm.controls.fileCtrl.errors?.invalidSize && (assetForm.controls.fileCtrl.touched || submitted)">
                            <i class="material-icons">warning</i> Maximum file size limit is {{_maxLength.File.Size}}MB, please correct it.
                          </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer fdl-2">
                <button type="button" class="btn fdl-btn btn-secondary-light" data-dismiss="modal"  (click)="cancel();">Cancel</button>
                <button type="button" [disabled]="assetForm.controls.fileCtrl.invalid" class="btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal" (click)="confirm();">Upload</button>
            </div>
        </div>
    </div>
</div>
<delete-warning #replaceWarningModal
  [componentId]="'replaceWarningModal'"
  [type]="'attachment'"
  (okBtnClicked)="upload()">
</delete-warning>
