import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectMainComponent } from './Components/project-main/project-main.component';
import { ProjectListComponent } from './Components/project-list/project-list.component';
import { ProjectInfoComponent } from './Components/project-info/project-info.component';
import { ViewProjectComponent } from './Components/view-project/view-project.component';
import { CreateProjectMainComponent } from './Components/create-project-main/create-project-main.component';
import { ClientInfoComponent } from './Components/client-info/client-info.component';
import { MsalGuard } from '@azure/msal-angular';
import { DirectAccessGuard } from '../SharedModule/Auth/direct-access.guard';
import { PublishFormsComponent } from './Components/publish-forms/publish-forms.component';
import { ResponsesHistoryComponent } from 'src/app/responses-history/responses-history.component';
import { ViewComponent } from 'src/app/formui/view/view.component';
import { FormTemplateComponent } from '../admin/manageForms/form-template/form-template.component';
import { CanAccessProjectGuard } from '../dashboard/can-access-project.guard';
import { MasterProjectStatusResolver } from '../services/master-project-status-reslover.service';
import { CanCreateProjectGuard } from './can-create-project.guard';
import { UserInitializeGuard } from '../core/user-initialize.guard';
import { MasterResponseStatusResolver } from '../services/master-response-status-reslover.service';
import { OnlyAdminAccessGuard } from '../core/only-admin-access.guard';
import { AssociateIntakeRequestComponent } from './Components/associate-intake-request/associate-intake-request.component';
import { UserFilterResolver } from '../services/user.filter.resolver.service';
import { ResponseTimelineComponent } from '../response-timeline/response-timeline.component';
import { ProjectTimelineComponent } from './Components/project-timeline/project-timeline.component';

const routes: Routes = [
  {
    path: 'projects',
    component: ProjectMainComponent,

    canActivateChild: [MsalGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: ProjectListComponent,
        data: { title: 'Projects List', breadCrumb: 'Projects' },
        canActivate: [CanAccessProjectGuard],
        resolve: {
          resolveData: MasterProjectStatusResolver,
          userFilter: UserFilterResolver,
        },
      },
      {
        path: 'create',
        component: CreateProjectMainComponent,
        data: { breadCrumb: 'Create a Project' },
        resolve: { resolveData: MasterProjectStatusResolver },
        children: [
          {
            path: 'projectinfo',
            component: ProjectInfoComponent,
            data: { title: 'Project Info' },
            canActivate: [CanCreateProjectGuard],
          },
          {
            path: 'clientinfo',
            component: ClientInfoComponent,
            data: { title: 'Client Info' },
            canActivate: [CanCreateProjectGuard],
          },
        ],
      },
      {
        path: 'edit',
        component: CreateProjectMainComponent,
        resolve: { resolveData: MasterProjectStatusResolver },
        data: { breadCrumb: 'Edit Project' },
        children: [
          {
            path: 'projectinfo/:id',
            component: ProjectInfoComponent,
            data: { title: 'Project Info' },
            canActivate: [CanCreateProjectGuard],
          },
          {
            path: 'clientinfo',
            component: ClientInfoComponent,
            data: { title: 'Client Info' },
            canActivate: [CanCreateProjectGuard],
          },
          {
            path: 'publish-forms',
            component: PublishFormsComponent,
            data: { title: 'Publish Forms' },
            canActivate: [DirectAccessGuard],
          },
          {
            path: 'associate-intake',
            component: AssociateIntakeRequestComponent,
            data: { title: 'Intake Request' },
            canActivate: [DirectAccessGuard],
          },
        ],
      },
      {
        path: 'view/:id',
        component: ViewProjectComponent,
        data: { title: 'View Project', breadCrumb: 'View Project' },
        resolve: { resolveData: MasterResponseStatusResolver },
        canActivate: [CanAccessProjectGuard],
      },
      {
        path: 'view/:id/:cid',
        component: ViewProjectComponent,
        data: { title: 'View Project', breadCrumb: 'View Project' },
        resolve: { resolveData: MasterResponseStatusResolver },
        canActivate: [CanAccessProjectGuard],
      },
      {
        path: 'viewTimeline/:id',
        component: ProjectTimelineComponent,
        data: {
          title: 'View Project Timeline',
          breadCrumb: 'View Project Timeline',
        },
        resolve: { resolveData: MasterResponseStatusResolver },
        canActivate: [CanAccessProjectGuard],
      },
      {
        path: 'view/:projectId/responses/:id/:edit',
        canActivate: [CanAccessProjectGuard],
        children: [
          {
            path: 'list',
            component: ResponsesHistoryComponent,
            data: { title: 'Response History List', from: 'Project' },
            resolve: { resolveData: MasterResponseStatusResolver },
          },
          {
            path: 'timeline',
            component: ResponseTimelineComponent,
            data: { title: 'Form Responses Timeline', from: 'Project' },
            canActivate: [UserInitializeGuard],
          },
          {
            path: 'version/view',
            component: ViewComponent,
            data: { title: 'History List', from: 'Project' },
            resolve: { resolveData: MasterResponseStatusResolver },
          },
          {
            path: 'template',
            component: FormTemplateComponent,
            data: { title: 'Manage Form Template', from: 'Project' },
            canActivate: [OnlyAdminAccessGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsRoutingModule { }
