import { Component, OnInit } from '@angular/core';
import { ProjectsService } from '../../../services/projects.service';
import { FormsService } from '../../../services/forms.service';
import { UserService } from '../../../services/user.service';
import { forkJoin, Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DateFormatPipe } from '../../../SharedModule/Pipes/date-format.pipe';
import { MasterService } from '../../../services/master.service';
import { IPager } from '../../../shared/pagination';
import { IQuery } from '../../../shared/query';
import { environment } from 'src/environments/environment';
import { ISubmitRequest } from '../../../shared/submit-request';
import { Sort } from "src/app/shared/sort";
import { ProjectDrawerService } from '../../../SharedModule/Components/project-drawer-container/project-drawer.service';
import { takeUntil } from 'rxjs/operators';
import { PermissionsService } from '../../../services/permissions.service';
declare var $: any;
@Component({
  selector: 'app-associate-intake-request',
  templateUrl: './associate-intake-request.component.html',
  styleUrls: ['./associate-intake-request.component.css']
})
export class AssociateIntakeRequestComponent implements OnInit {
  submitFormRequest: FormGroup;
  isError: boolean;
  errorMsg: string;
  productSuggestion: any = {};
  sortingObject;
  displayColumns = ["Form Name", "Description", "Product Name", "Due Date"];
  keys = ['title', 'description', 'productTitle', 'dueDate'];
  responseStatusData;
  pageOfItems: Array<any>;
  clientSuggestion: any = {};
  pagerObject: IPager;
  paginationArr: any;
  queryParam: IQuery;
  currentPageNumber: number = 1;
  submitRequestList: ISubmitRequest[];
  filteredSubmitRequestList: ISubmitRequest[];
  sortedSubmitRequestList: ISubmitRequest[];
  loadDrawer: boolean;
  createClientAllowed: boolean;
  private _destroySubject = new Subject();
  successMsg: string;
  showSuccessMsg: boolean;
  clientNameFromDrawer: string;
  isSubmitted: boolean = false;
  selectedRadioValue: any = { "value": '' };
  selectedForm: any = [];
  isErrorRequest: any;
  constructor(private _projectService: ProjectsService,
    private _formService: FormsService,
    private _userService: UserService,
    private _router: Router,
    private _datePipe: DateFormatPipe,
    private _masterService: MasterService,
    private _drawerService: ProjectDrawerService,
    private _permissionService: PermissionsService) { }


  ngOnInit(): void {
    this.responseStatusData = this._masterService.getResponseStatusList().find(data => data.title.trim().toLowerCase() === "not started");
   // this.initFilterSubmitRequest();
    this.apiFormData();
  }
  clearValue() {
    console.log(this.selectedRadioValue.value);

    if (this.selectedForm && this.selectedForm.id != '')
      this.selectedRadioValue = { 'value': this.selectedForm[0].id }
    else
      this.selectedRadioValue = { 'value': '' }
  }
  selectedFormRequest() {
    if (this.selectedRadioValue.value == '') {
      this.isErrorRequest = true;
      return
    }
    for (let k in this.filteredSubmitRequestList) {
      if (this.filteredSubmitRequestList[k].id == this.selectedRadioValue.value) {
        this.selectedForm = [];
        this.selectedForm.push(this.filteredSubmitRequestList[k]);
        this.selectedForm[0].id = this.selectedRadioValue.value;
      }
    }
    $('#selectForms').modal('hide');
  }
  apiFormData() {
    let apiCall = [];
    let formsData;
    apiCall.push(this._projectService.getSuggestions("products", ""));
    apiCall.push(this._projectService.getSuggestions("clients", ""));
    apiCall.push(this._formService.getFormsByOrganizationId(this._userService.getUserListData().organization, null));
    forkJoin(apiCall).subscribe(data => {
      this.productSuggestion = data[0];
      this.clientSuggestion = data[1];
      formsData = data[2];
      this.submitRequestList = formsData?.forms;
      this.filteredSubmitRequestList = JSON.parse(JSON.stringify(this.submitRequestList));
      this.sortedSubmitRequestList = JSON.parse(JSON.stringify(this.submitRequestList));
      this.pagerObject = {
        currentPage: this.currentPageNumber,
        pageSize: environment.pageSize,
        totalCount: this.filteredSubmitRequestList.length,
        totalPages: Math.ceil(this.filteredSubmitRequestList.length / environment.pageSize)
      }
      for (let i in this.submitRequestList) {
        this.filteredSubmitRequestList[i].dueDate = this._datePipe.transform(this.filteredSubmitRequestList[i].dueDate);
        this.sortedSubmitRequestList[i].dueDate = this._datePipe.transform(this.sortedSubmitRequestList[i].dueDate);
      }
      this.filteredSubmitRequestList = this.filteredSubmitRequestList.slice(0, this.pagerObject.pageSize);

      const isFilterRequired = Object.keys(this.submitFormRequest.value).every(filter => this.submitFormRequest.value[filter] === "");
    
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }
  selectFormBtn() {


    $('#selectForms').modal('show');
  }
  navigationForm() { }
  applySorting(event) {
    this.sortingObject = event;
    const sort = new Sort();
    const order = this.sortingObject.isAsc ? "asc" : "desc";
    const propertyName = this.sortingObject.key;
    this.sortedSubmitRequestList.sort(sort.startSort(this.sortingObject.key, order, this.sortingObject.key));
  //  this.filterSubmitRequest();
  }
  submitForm() {
    let api_call: any;
    if (this.submitFormRequest.valid) {

    } else {
      this.isSubmitted = true;
      return;
    }
    //this._userService.getUserListData().id
    if (this.submitFormRequest.value['requestorType'] != 'guest') {

    }
    if (this.submitFormRequest.value['requestorType'] == 'guest') {



    }
    api_call.subscribe(
      response => {
        if (response[0].id)
          this._router.navigate(['/response/view/responses/', response[0].id]);
        else {
        //  this._projectDto.setPublishRequestForms(true);
          this._router.navigate(["response/list"]);
        }

      }
    );
  }
  takeAction(event) {
    let launchForms = [];
    launchForms.push({
      "assignedToId": this._userService.getUserListData().id,
      "responseStatusId": this.responseStatusData.id,
      "startDate": new Date().toISOString(),
      "dueDate": this._datePipe.transform(event.data.dueDate).replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T00:00:00Z",
      "formId": event.data.id,
      "title": event.data.title,
      "description": event.data.description,
      'currentApproverId': null
    })

    let launchFormRequest = {
      'clientId': this.submitFormRequest.get('clientName').value ? this._projectService.fetchIdForRequest(this.clientSuggestion, this.submitFormRequest.get('clientName').value) : 0,
      'responses': launchForms
    }
    this._formService.publishForms_user(launchFormRequest, 0).subscribe(
      response => {
        this._router.navigate(['/response/view/responses/', response[0].id]);
      }
    );
  }


}
