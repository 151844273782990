import { Injectable } from '@angular/core';
import { IQuery } from 'src/app/shared/query';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { IHelpArticle } from 'src/app/shared/helpArticle';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpArticleService {

  private helpArticleAPIURL = 'helptopics';
  private myHelpArticleAPIURL = 'helptopics/GetMyHelpTopics';
  private helpArticleCreated: string;
  private isHelpArticleCreated: boolean;
  private helpArticleUpdated: boolean;
  private exportHelpArticleAPIURL = '/helptopics/ExportMyHelpTopics';

  constructor(private http: HttpClient) {}

  getHelpArticles(queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }

    let options = { 'refresh': 'true' }

    return this.http.get(this.myHelpArticleAPIURL, { headers: options, params, observe: 'response' });
  }

 
  getHelpArticleDetailsById(helpArticleId: number) {
    return this.http.get<IHelpArticle>(`${this.helpArticleAPIURL}/${helpArticleId}`);
  }

  createHelpArticle(data) {
    return this.http.post<IHelpArticle>(this.helpArticleAPIURL, data);
  }

  updateHelpArticle(helpArticleId, data) {
    return this.http.put<IHelpArticle>(`${this.helpArticleAPIURL}/${helpArticleId}`, data);
  }

  deleteHelpArticle(helpArticleId: number): Observable<IHelpArticle> {
    return this.http.delete<IHelpArticle>(`${this.helpArticleAPIURL}\\${helpArticleId}`);
  }

  set _newCreatedHelpArticle(value){
    this.helpArticleCreated= value;
  }

  get _newCreatedHelpArticle(){
    return this.helpArticleCreated;
  }

  set _isHelpArticleCreated(value){
    this.isHelpArticleCreated= value;
  }

  get _isHelpArticleCreated(){
    return this.isHelpArticleCreated;
  }

  set _helpArticleUpdated(value){
    this.helpArticleUpdated= value;
  }

  get _helpArticleUpdated(){
    return this.helpArticleUpdated;
  }
  exportHelpArticles(queryParams) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: 'true',
      }),
    };
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.exportHelpArticleAPIURL, {
      headers: httpOptions.headers,
      params: params,
      observe: 'response',
      responseType: 'blob',
    });
  }

}
