import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';

@Component({
  selector: 'app-undo-commitment-modal',
  templateUrl: './undo-commitment-modal.component.html',
  styleUrls: ['./undo-commitment-modal.component.css']
})
export class UndoCommitmentModalComponent implements OnInit {

  @Input()
  public clientId = 0;

  @Output()
  private submit = new EventEmitter<void>();

  constructor(private clientCommitmentsService: ClientCommitmentsService) { }

  ngOnInit(): void {
  }

  public onSubmit(): void {
    this.clientCommitmentsService.deleteLatestCommitmentReviewByClientId(this.clientId).subscribe(() => {
      this.submit.emit();
    });
  }
}
