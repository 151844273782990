import { Component, OnInit, OnDestroy  } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ISuggestions } from 'src/app/shared/suggestion';
import { ProjectsService } from 'src/app/services/projects.service';
import { MasterService } from 'src/app/services/master.service';
import { GroupService } from 'src/app/services/groups.service';
import { IGroup, IGroupsList } from 'src/app/shared/group';
import { PreferenceService } from 'src/app/services/preference.service';
import { FdlFilterUIHepler } from 'src/app/shared/fdl-filter-ui-helper';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
declare var $: any;

@Component({
  templateUrl: './user-group-list.component.html'
})

export class UserGroupListComponent implements OnInit, OnDestroy {
  constructor(
    private groupService: GroupService,
    private router: Router,
    private projectService: ProjectsService,
    private masterService: MasterService,
    private fdlFilterHelper: FdlFilterUIHepler
  ) { }

  filterForm: FormGroup;
  groupList: IGroupsList;
  queryParam: IQuery;
  currentPageNumber: number = 1;
  pagerObject:IPager;
  sortingObject;
  isError:boolean;
  errorMsg: string = "";
  groupSuggestion: ISuggestions;
  successMsg: string;
  displayColumns = ['ID', 'Name', 'Description', 'Access Level', 'Status'];
  keys = ['id', 'name', 'description', 'accessLevel', 'isActive'];
  listname: string = PreferenceService.userGroupList;
  filtered: boolean = false
  regexValidationMessage: any;
  groupText: string = '';

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.initFilterForm();
    this.initQueryParams();
    this.getSuggestions();
    this.callUserGroupListService();
    this.successMsg = this.groupService.isGroupCreated ? `The user group '${this.groupService.newCreatedGroup}' has been created`: '' ;
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      Name: new FormControl('', Validators.pattern(REGEX_PATTERNS.client_name_validator)),
    });
  }

  initQueryParams() {
    this.queryParam = Object.assign({},{
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  typeaheadLoadingGroups() {
    this.getSuggestions();
  }

  getSuggestions() {
    let suggestionSubscriber: any;
    let params = [];
    if(this.groupText && this.groupText.length >= 3) {
      params.push({paramName: "title", value: this.groupText});
      suggestionSubscriber = this.projectService.getPaginatedSuggestions("groups", params, 100);
    }
    else {
      suggestionSubscriber = this.projectService.getPaginatedSuggestions("groups", null, 100);
    }

    suggestionSubscriber.subscribe(
      data=> {
        this.groupSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  callUserGroupListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.groupService.getGroupList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        groups => {
          this.isError = false;
          this.groupList = groups.body as IGroupsList;
          if(groups.headers.get('X-pagination')){
            this.pagerObject = Object.assign({},JSON.parse(groups.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }

  filterList(){
    this.initQueryParams();
    let filterValues = this.filterForm.value;

    if (filterValues['Name'])
      this.queryParam.name = encodeURIComponent(filterValues['Name']);

    this.setOrderBy(this.sortingObject);
    this.callUserGroupListService();
    this.filtered = true
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.callUserGroupListService();
    this.fdlFilterHelper.showSearchIconWhenResetFilter();
  }

  refresh() {
    this.callUserGroupListService();
  }

  navigate(event){
    if (event.action === "view") {
      this.router.navigate(['/admin/usergroup/view', event.data]);
    }
    else if (event.action === "edit") {
      this.navigateToUserEdit(event.data);
    }
    else if (event.action === "delete") {
      this.successMsg = '';
      this.groupService.deleteGroup(event.data).subscribe(
        data => {
          this.callUserGroupListService();
          this.successMsg = `The user group '${event.title}' has been deleted.`
        },
        error => {
          this.isError = true; this.errorMsg = error;
        }
      )
    }
  }

  applySorting(event){
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callUserGroupListService();
  }

  createUserGroup(){
    this.router.navigate(['admin/usergroup/create']);
  }

  changePage(page){
    this.queryParam.pageNumber = page;
    this.callUserGroupListService();
  }

  setOrderBy(sortingData){
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  navigateToUserEdit(groupId: number): void {
    this.groupService.getGroupById(groupId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.groupService.isEditMode = true;
          this.groupService.setGroupData = data as IGroup;
          this.router.navigate(['/admin/usergroup/edit']);
        }
      );
  }

  ngOnDestroy(): void {
    this.setSuccessMsg();
  }

  setSuccessMsg() {
    this.groupService.isGroupCreated = false;
    this.groupService.newCreatedGroup = '';
    this.successMsg = '';
  }
}
