import { EmailTemplateDTOService } from 'src/app/shared/email-templateDTO.service';
import { IEmailTemplateDetails } from './../models/email-template';
import { MasterService } from 'src/app/services/master.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { EmailTemplateService } from '../services/email-template.service';

@Component({
  templateUrl: './email-template-view.component.html'
})
export class EmailTemplateViewComponent implements OnInit {

  constructor(private router: Router, private permission: PermissionsService,
    private route: ActivatedRoute, private masterService: MasterService,
    private emailTemplateService: EmailTemplateService, private emailTemplateDTOService: EmailTemplateDTOService) { }

  showUpdationMessage:boolean = false;
  isError: boolean;
  isAdmin: boolean;
  errorMsg: string;
  showEditButton:boolean = false;

  emailConfigDetailId: number;
  emailTemplateData: IEmailTemplateDetails;

  ngOnInit(): void {
    this.showEditButton = this.permission.isCreateEmailTemplateAllowed();
    this.isAdmin = this.permission.userData.isAdmin;
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.emailConfigDetailId = params['id'];
        if (this.emailConfigDetailId > -1) {
          this.getemailTemplateData();
        }
      });
      this.showUpdationMessage = this.emailTemplateDTOService._isemailTemplateUpdated;
  }

  getemailTemplateData() {
    this.emailTemplateService.getEmailTemplateDetailsById(this.emailConfigDetailId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.emailTemplateData = data;
        },
        (error)=> { this.isError = true; this.errorMsg = error; });
  }

  
  goToEdit(){
    this.emailTemplateDTOService._isEditMode = true;
    this.emailTemplateDTOService.setemailTemplateData(this.emailTemplateData);
    this.router.navigate(['self-service/emailTemplate/edit']);
  }

  ngOnDestroy(): void {
    this.emailTemplateDTOService._isemailTemplateUpdated = false;
  }

  goBack(){
    this.masterService.isCancelledClicked(true);
    this.router.navigate(['self-service/emailTemplate']);
  }
}
