<div class="heading-container fdl-2">
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span> 
        Workflow Context List
      </a>
    </div>
    <h1>{{workflowData?.workflowName || 'N/A'}}</h1>
    </div>
</div>

<div class="form-container separate-form-container">
    <form>
        <div class="biller-survey white-box fdl-2">
            <div>
                <ul>
                    <li>
                        Organization Name:
                        <span class="info">{{workflowData?.organizationTitle || 'N/A'}}</span>
                    </li>
                    <li>
                        Product Name:
                        <span class="info">{{workflowData?.productTitle || 'N/A'}}</span>
                    </li>
                    <li>
                        Client Name:
                        <span class="info">{{workflowData?.clientTitle || 'N/A'}}</span>
                    </li>
                    <li>
                        Form Name:
                        <span class="info">{{workflowData?.formTitle || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        Created On:
                        <span class="info">{{workflowData?.createdOn | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                    </li>
                    <li>
                        Modified On:
                        <span class="info">{{workflowData?.modifiedOn | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                    </li>
                    <li>
                        Created By:
                        <span class="info">{{workflowData?.createdByName || 'N/A'}}</span>
                    </li>
                    <li>
                        Modified By:
                        <span class="info">{{workflowData?.modifiedByName || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </form>
    <div class="fdl-2 button-container">
        <div>
            <button type="button" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
        </div>
    </div>
</div>