import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { HelpArticleService } from '../../helpArticle.service';
import { IHelpArticle } from 'src/app/shared/helpArticle';
import { UserService } from 'src/app/services/user.service';
import { IUser } from 'src/app/shared/user';

@Component({
  selector: 'app-view-helpArticle',
  templateUrl: './view-helpArticle.component.html'
})
export class ViewHelpArticleComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,
    private helpArticleService: HelpArticleService,
    private userService: UserService) { }

    helpArticleId: number;
    helpArticleData: IHelpArticle;
    showEditButton: boolean = false;
    isEdit: boolean = false;
    isAdmin: boolean = false;
    isCommonCategory: boolean = false;
  showUpdationMessage = false;
  isError: boolean;
  errorMsg: string;
  json: any;
  currentUser: IUser;
  ngOnInit(): void {
    this.currentUser = this.userService.getUserListData();
    this.isAdmin = this.currentUser.isAdmin;
    this.isEdit = this.userService.getPermissions()["showhelpArticlelink"];    

    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.helpArticleId = params['id'];
        if (this.helpArticleId > -1) {
          this.getHelpArticleData();          
        }
      });
      this.showUpdationMessage = this.helpArticleService._helpArticleUpdated;
  }

  ngOnDestroy(): void {
    this.helpArticleService._helpArticleUpdated = false;
  }

  getHelpArticleData() {
    this.helpArticleService.getHelpArticleDetailsById(this.helpArticleId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.helpArticleData = data;
          this.isCommonCategory = data.isCommonCategory;
          // Bug 283250: Only super admin OR author can edit an article
          if (this.isAdmin || (this.helpArticleData.cretedById === this.currentUser.id)) {
            this.showEditButton = true;
          } else {
            this.showEditButton = false;
          }
            
          this.json = JSON.parse(this.helpArticleData.formJSON);
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  goBack() {
    this.router.navigate(['self-service/helpTopic']);
  }

  goToEdit() {
    this.router.navigate([`self-service/helpTopic/edit/${this.helpArticleId}`]);
  }
}
