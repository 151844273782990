import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { HelpCategoryService } from '../../helpCategory.service';
import { IHelpCategory } from 'src/app/shared/helpCategory';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-helpCategory',
  templateUrl: './view-helpCategory.component.html'
})
export class ViewHelpCategoryComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,
    private helpCategoryService: HelpCategoryService,
    private userService: UserService) { }

    helpCategoryId: number;
    helpCategoryData: IHelpCategory;

  showUpdationMessage = false;
  isError: boolean;
  errorMsg: string;
  isAdmin: boolean;

  ngOnInit(): void {
    this.isAdmin = this.userService.getUserListData().isAdmin;
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.helpCategoryId = params['id'];
        if (this.helpCategoryId > -1) {
          this.getHelpCategoryData();
        }
      });
      this.showUpdationMessage = this.helpCategoryService._helpCategoryUpdated;
  }

  ngOnDestroy(): void {
    this.helpCategoryService._helpCategoryUpdated = false;
  }

  getHelpCategoryData() {
    this.helpCategoryService.getHelpCategoryDetailsById(this.helpCategoryId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.helpCategoryData = data;
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  goBack() {
    this.router.navigate(['self-service/helpCategory']);
  }

  goToEdit() {
    this.router.navigate([`self-service/helpCategory/edit/${this.helpCategoryId}`]);
  }
}
