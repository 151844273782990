<div id="reviewCommitments" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" style="display: none;"
    aria-hidden="true">
    <div role="document" class="modal-dialog review-commitments-modal modal-lg mt-120" style="max-width: 550px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="myLargeModalLabel" class="modal-title">
                    Authorization
                    <p class="note">Once you submit, all the displayed auto-tagged commitments will be marked as
                        reviewed and will be available for client view.</p>
                </h4>
                <button type="button" data-dismiss="modal" aria-label="Close popup" class="close"><span
                        aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body pt-2">
                <div class="row">
                    <div class="col-md-12 pb-3">
                        <div class="checkbox pt-2">
                            <input type="checkbox" id="myCheckbox" name="myCheckbox" value="myCheckbox"
                                [(ngModel)]="isReviewed">
                            <label id="myCheckboxLabel" for="myCheckbox">
                                <span class="d-flex pl-4">
                                    I have reviewed the auto-tagged commitments and authorize to move them to the client
                                    view.
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-5">
                        <label for="txtComment">Comment:</label><span class="asterisk"></span>
                        <textarea type="text" rows="4" id="comment" name="comment" maxlength="2000"
                            [(ngModel)]="comment" class="form-control">
                        </textarea>
                    </div>
                </div>
                <div class="fdl-2 w-100 text-right mt-0">
                    <button type="button" data-dismiss="modal"
                        class="btn fdl-btn btn-secondary-light mr-2">Cancel</button>
                    <button data-dismiss="modal" id="save-button-form-container" type="button"
                        [disabled]="!isReviewed || comment.trim() === ''" class="btn fdl-btn btn-primary-orange"
                        (click)="onSubmit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>