import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IQuery } from "src/app/shared/query";
import { IPager } from "src/app/shared/pagination";
import { MasterService } from "src/app/services/master.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormsService } from "src/app/services/forms.service";
import { PermissionsService } from "src/app/services/permissions.service";
import { IHistorySummary } from "src/app/shared/history-summary";
import { Location } from "@angular/common";
import { IResponseTimeline } from "src/app/shared/timeline";
import moment from 'moment';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from "../core/constants";
import { FileValidators } from "../self-service/components/manageFileUploads/validators/file-validators";

declare var $: any;

@Component({
  selector: "app-response-timeline",
  templateUrl: "./response-timeline.component.html",
  styleUrls: ["./response-timeline.component.css"]
})
export class ResponseTimelineComponent implements OnInit, OnDestroy {
  isError: boolean;
  isAsc: boolean = false;
  filterForm: FormGroup;
  responseTimelineList: IResponseTimeline[];
  filteredFormHistoryList: IHistorySummary[];
  filteredTimelineList: IResponseTimeline;
  sortedFormHistoryList: IHistorySummary[];
  queryParam: IQuery;
  pagerObject: IPager;
  isAdmin = false;
  displayColumns = ["Date and Time", "Event Name and Description", "Modified By", "Status"];
  keys = ["version", "modifiedOn", "modifyBy"];
  currentPageNumber: number = 1;
  formTemplateName: string = "";
  respondentName: string = "";
  approverName: string = "";
  currentAssignee: string = "";
  responseStatusTitle: string = "";
  dueDate: string = "";
  regexValidationMessage: any;
  sortingObject;
  errorMsg: string = "";
  approvalMsg: string = "";
  formId: any;
  restoreData_id: number;
  restoreData_on: string;
  totalEventCount: any;
  recentEventCount: any;

  filterFormsVersions: FormGroup;

  showCommentBadge: boolean = false;
  commentCatHistory: string = '';
  commentBadgeCount: number = 0;
  commentValue: string;
  commentHistoryList;
  commentTableHeaders = ["Page Name", "Category", "Comment", "Added On", "Added By"];
  commentTableKeys = ["pageName", "category", "comment", "createdOn", "createdByName"];
  commentHistFilterOptions: string[] = [];
  commentHistSelectedPgName: string = "";
  commentCat: string = 'Comment';

  commentFile: any;
  commentFileName: string;
  commentId: number;
  commentResponseId: number;
  isCommentError: boolean = false;
  commentErrorMsg: string = "";
  isCommentSubmit: boolean = false;
  commentLength: number;
  commentCnt: number = 0;
  previousPage: string;
  eventData: any;

  validators = REGEX_PATTERNS;

  @ViewChild('inputCommentFile') commentFileInput: ElementRef;


  constructor(
    private masterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private formService: FormsService,
    private formsService: FormsService,
    private permissionService: PermissionsService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    const loc = this.location.path();
    if (this.router.url.indexOf('projects') !== -1)
      this.previousPage = 'Projects';
    else
      this.previousPage = 'Responses';
    this.formId = this.route.snapshot.params["id"];
    if(this.formsService.historyData != undefined) {
      this.formTemplateName = this.formsService.historyData.historyName;
      this.respondentName = this.formsService.historyData.respondentName;
      this.approverName =this.formsService.historyData.approverName;
      this.currentAssignee =this.formsService.historyData.currentAssignee;
      this.responseStatusTitle = this.formsService.historyData.responseStatusTitle;
      this.dueDate = this.formsService.historyData.dueDate;
    }
    else {
      this.getResponse();
    }
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.callResponseTimelineListService();
  }

  sort() {
    this.isAsc = !this.isAsc;
    this.callResponseTimelineListService();
  }

  refresh () {
    this.callResponseTimelineListService();
  }

  getResponse() {
    this.formsService
      .getResponseJson(this.formId)
      .pipe(take(1))
      .subscribe(
        response => {
          this.isError = false;
          this.manipulateResponse(response);
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  private manipulateResponse(response) {
    this.formTemplateName = response.title;
    this.respondentName = response.assignedToName;
    this.approverName = response.currentApproverName;
    this.currentAssignee = response.editAccessWith;
    this.responseStatusTitle = response.responseStatusTitle;
    this.dueDate = response.dueDate;
  }

  getSortClass(){
    if (this.isAsc) return "sorting";
  }

  callResponseTimelineListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.updateCommentHistory(this.formId);
    let sorting = this.isAsc? "ASC": "DESC";

    this.formsService
      .getResponsesTimeline(this.formId, sorting)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.isError = false;
          this.responseTimelineList = responses;
          this.totalEventCount = this.responseTimelineList.length;
          if (this.totalEventCount < 10) {
            this.totalEventCount = '0' + this.totalEventCount;
          }

          if (!this.isAsc){
            this.filteredTimelineList = this.responseTimelineList[0];
          }
          else {
              this.filteredTimelineList = this.responseTimelineList[this.totalEventCount-1];
          }

          this.calculateRecentEvents();
          $('#refreshTask').removeClass('fa-rotation');
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  calculateRecentEvents() {
    // this.filteredTimelineList = this.responseTimelineList[0];
    let count = 0;
    this.responseTimelineList.forEach((responseObj: any) => {
      let hours = moment().diff(moment(responseObj.modifiedOn), 'hours');
      if (hours < 24) {
        count = count + 1;
      }
    });

    this.recentEventCount = count;
    if (count > 0 && count < 10) {
      this.recentEventCount = '0' + count;
    }
  }

  getStatusCSSClass(status: any) {
    let statusColor;
    switch (status) {
      case 'In Progress':
        statusColor = 'status yellow';
        break;
      case 'Initiated':
        statusColor = 'status orange';
        break;
      case 'Completed':
        statusColor = 'status green';
        break;
      case 'Overdue':
        statusColor = 'status red';
        break;
      case 'Not Started':
        statusColor = 'status blue';
        break;
      case 'On Hold':
        statusColor = 'status hold';
        break;
      case 'Cancel':
        statusColor = 'status grey';
        break;
      case 'Rejected':
        statusColor = 'status rejected';
        break;
      case 'Under Review':
        statusColor = 'status keppel';
        break;
      default:
        statusColor = 'status orange';
        break;
    }
    return statusColor;
  }

  getEventTime(eventDate: any) {
    let eventTime = new Date(eventDate).getTime();
    return eventTime;
  }

  getTimelineStatusCSSClass(status: any) {
    let statusColor;
    switch (status) {
      case 'In Progress':
        statusColor = 'single-timeline-area yellow';
        break;
      case 'Initiated':
        statusColor = 'single-timeline-area orange';
        break;
      case 'Completed':
        statusColor = 'single-timeline-area green';
        break;
      case 'Overdue':
        statusColor = 'single-timeline-area red';
        break;
      case 'Not Started':
        statusColor = 'single-timeline-area blue';
        break;
      case 'On Hold':
        statusColor = 'single-timeline-area hold';
        break;
      case 'Cancel':
        statusColor = 'single-timeline-area grey';
        break;
      case 'Rejected':
        statusColor = 'single-timeline-area rejected';
        break;
      case 'Under Review':
        statusColor = 'single-timeline-area keppel';
        break;
      default:
        statusColor = 'single-timeline-area orange';
        break;
    }
    return statusColor;
  }

  navigate(event) {
    if (event.action === "view") {
      this.masterService.saveFiltersDup(
        this.filterFormsVersions,
        "formsHistory"
      );
      this.formsService.versionModifiedOn = event.data.modifiedOn;
      this.router.navigate(['/admin/form/history/view', event.data.id]);
    }

    if (event.action === "revert") {
      this.restoreData_id = event.data.id;
      this.restoreData_on = event.data.modifiedOn;
      $('#restoreForm').modal('show');
    }
  }

  changePage(page) {
    this.pagerObject.currentPage = page;
    this.filterFormsHistory();
  }

  filterFormsHistory() {
    let filterValues = this.filterFormsVersions.value;
    this.isError = false;
    this.errorMsg = '';
    let fromDate = new Date(filterValues["fromDate"]);
    let toDate = new Date(filterValues["toDate"]);
    if (fromDate.getTime() > toDate.getTime()) {
      this.errorMsg = "'To Date' which is in past of 'From date' not allowed.";
      this.isError = true;
      return;
    }

    this.filteredFormHistoryList = JSON.parse(JSON.stringify(this.sortedFormHistoryList));
    if (filterValues["fromDate"]) {
      this.filteredFormHistoryList = this.filteredFormHistoryList.filter(
        response =>
          new Date(response.modifiedOn).getTime() >
          new Date(filterValues["fromDate"]).getTime()
      );
    }

    if (filterValues["toDate"]) {
      let toDate = new Date(new Date(filterValues["toDate"]).getTime() + 24 * 60 * 60 * 999.99);
      this.filteredFormHistoryList = this.filteredFormHistoryList.filter(
        response =>
          new Date(response.modifiedOn).getTime() <
          new Date(toDate).getTime()
      );
    }
    this.pagerObject.totalPages = Math.ceil(this.filteredFormHistoryList.length / environment.pageSize);
    this.pagerObject = JSON.parse(JSON.stringify(this.pagerObject));
    const startIdx = this.pagerObject.pageSize * (this.pagerObject.currentPage - 1);
    const endIdx = (this.pagerObject.pageSize * this.pagerObject.currentPage);
    this.filteredFormHistoryList = this.filteredFormHistoryList.slice(startIdx, endIdx);

  }

  goBack() {
    this.masterService.isCancelledClicked(true);
    if (this.route.snapshot.data["from"] === "Project") {
      this.router.navigate(["../../../../"], { relativeTo: this.route });
    } else {
      this.router.navigate(["../../../list"], { relativeTo: this.route });
    }
  }

  ngOnDestroy(): void {
  }



  onAttachmentClick(attachment) {
    window.open(attachment, "_blank");
  }

  openAddCommentModal() {
    this.updateCommentHistory(this.formId);
    $('#addcomment').modal('show');
    this.commentValue = '';
    this.commentHistSelectedPgName = '';
    this.commentCat = 'Comment';
    this.commentCnt = 0;
  }

  getCommentClass(cnt) {
    if (cnt > 5) {
        return 'table-responsive mb-0';
    }
  }

  onCommentFileChange(event) {
    const target: DataTransfer = <DataTransfer>event.target;
    this.commentFile = target.files;
    const validSize = FileValidators.ValidateFileSize(this.commentFile.item(0));
    if (!validSize || !validSize.invalidSize) {
      // Is this kind of validations really necesary? We check the file size before checking if there are any files,
      // so here we asume there is at least one file, so maybe cheking if there are any files after the size checking might not be necesary.
      if (this.commentFile.length > 0) {
        this.commentFileName = this.commentFile.item(0).name;
        if (!REGEX_PATTERNS.FileName.test(this.commentFileName)) {
          this.isCommentError = true;
          this.commentErrorMsg = this.regexValidationMessage.FILENAME_VALIDATION_MESSAGE;
          this.commentFileInput.nativeElement.value = "";
          return;
        }
        let vct = FileValidators.ValidateContentType();
        if (vct(this.commentFile.item(0)).invalidType || FileValidators.ValidateExtension(this.commentFile.item(0))) {
          this.isCommentError = true;
          this.commentErrorMsg = "Invalid file type or extension";
          this.commentFileInput.nativeElement.value = "";
        }
      }
    }
    else {
      this.isCommentError = true;
      this.commentErrorMsg = "File size exceeds maximum size limit i.e. 50 MB";
      this.commentFileInput.nativeElement.value = "";
    }
  }

  downloadAttachment(event) {
    window.open(event.data, "_blank");
  }

  commentActions(event) {
    switch (event.action) {
      case "replace":
        this.commentId = event.data;
        this.commentResponseId = this.formId;
        //$('#replaceAttachment').modal('show');
        $('#replaceAttachment').show();
        $("#replaceAttachment").addClass('show');
        $('body').addClass('modal-open');
        break;
      case "delete":
        this.eventData = event.data;
        $('#deleteWarningModal').show();
        $("#deleteWarningModal").addClass('show');
        $('body').addClass('modal-open');
        break;
      case "remove":
        this.eventData = event.data;
        $('#removeAttachmentWarningModal').show();
        $("#removeAttachmentWarningModal").addClass('show');
        $('body').addClass('modal-open');
        break;
default:
        break;
    }
  }

  deleteComment(id) {
    this.formService.deleteResponseComment(id, this.formId)
      .subscribe(
      () => {
        this.updateCommentHistory(this.formId);
      },
      error => {
        this.isCommentError = true;
        this.commentErrorMsg = error;
      }
    );
  }

  removeAttachment(id) {
    let body = {
      "responseId": this.formId,
      "comment": this.commentValue,
      "pageName": this.formTemplateName,
      "category": this.commentCat?.trim() || '',
      "file": ""
    };

    this.formService.updateResponseComment(body, id, this.formId)
    .subscribe(
      () => {
        this.updateCommentHistory(this.formId);
      },
      error => {
        this.isCommentError = true;
        this.commentErrorMsg = error;
      }
    );
  }

  updateAttachmentSuccess(event) {
    this.updateCommentHistory(this.formId);
    // $("#replaceAttachment").modal('hide');
    $("#replaceAttachment").hide();
    $('.modal-backdrop').remove();
    $('body').addClass('modal-open');
    $("#replaceAttachment").removeClass('show');
  }

  cancelCommentHandle() {
    this.commentValue = '';
    this.commentFile = '';
    this.commentFileName = '';
    this.isCommentError = false;
    this.commentErrorMsg = "";
    this.isCommentSubmit = false;
    this.commentFileInput.nativeElement.value = "";
    this.callResponseTimelineListService();
  }

  submitCommentHandle(pageName: any = 'No', cat: any = '') {
    if(!this.validators.description_comment.test(this.commentValue)){
      return;
    }
    this.resetErrorAndSuccessMsg();
    if (this.commentValue?.trim() != '') {
      this.isCommentSubmit = true;
      if (cat == '') {
        cat = this.commentCat?.trim()
      }

      let uploadedFile: any;
      if (!this.commentFileName) {
          uploadedFile = "";
      }
      else {
        uploadedFile = this.commentFile.item(0);
      }

      let body = {
        "responseId": this.formId,
        "comment": this.commentValue,
        "pageName": this.formTemplateName,
        "category": cat,
        "file": uploadedFile
      };

      if (pageName != 'No') {
        body.pageName = pageName;
        body.category = "Comment";
      }

      this.formService.postResponseComment(body, this.formId).subscribe(
        (data) => {
          this.updateCommentHistory(this.formId);
          this.commentCat = 'Comment';
          this.commentValue = '';
          this.commentFile = '';
          this.commentFileName = '';
          this.isCommentError = false;
          this.commentErrorMsg = "";
          this.isCommentSubmit = false;
          this.commentFileInput.nativeElement.value = "";
          this.commentCnt = 0;
        },
        (error) => {
          this.commentValue = '';
          this.commentFile = '';
          this.commentFileName = '';
          this.isCommentError = true;
          this.commentErrorMsg = error;
          this.isCommentSubmit = false;
          this.commentFileInput.nativeElement.value = "";
          this.commentCnt = 0;
        }
      );
    }
    else{
      this.isCommentError = true;
      this.commentErrorMsg = "Please enter the comment to save.";
  }
  }

  resetErrorAndSuccessMsg() {
    this.isCommentError = false;
    this.commentErrorMsg = "";
  }

  updateCommentHistory(responseId) {
    this.resetErrorAndSuccessMsg();
    this.formService.getResponseJson(responseId).subscribe(
      (response) => {
        this.commentHistoryList = response.responseComments;
        this.manipulateResponse(response);
        this.setBadgeCount();
      }
    );
  }

  setBadgeCount() {
    if (this.commentHistoryList.length > 0) {
      this.commentLength = this.commentHistoryList.length;
      this.showCommentBadge = true;
      this.commentBadgeCount = this.commentHistoryList.length;
      let options: string[] = this.commentHistoryList.filter(el => el.pageName != null && el.pageName.trim() != '').map(item => item.pageName);
      this.commentHistFilterOptions = [...new Set(options)];
    }
  }

  commentCount() {
    this.commentCnt = this.commentValue.length;
}
}
