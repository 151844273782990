import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../SharedModule/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FdlDataGridModule, FdlTypeaheadModule } from 'fdl-ngx11-bootstrap4';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { UserNotificationComponent } from './user-notification.component';
import { UserNotificationRoutingModule } from './user-notification-routing.module';

@NgModule({
  declarations: [
    UserNotificationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UserNotificationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FdlTypeaheadModule,
    AngularEditorModule,
    FdlDataGridModule,
  ],
})
export class UserNotificationModule { }
