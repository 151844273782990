import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { PageTemplateResponseDetailsDTO } from '../models/page-template-response-details-d-t-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map-fulfilment',
  templateUrl: './map-fulfilment.component.html',
  styleUrls: ['./map-fulfilment.component.css']
})
export class MapFulfilmentComponent implements OnInit {
  @Input()
  public title = '';

  @Input()
  public responseId = 0;

  @Input()
  public canMapOrUnmap = false;

  @Input()
  public set projectId(value: string) {
    this.selectedProjectId = value;
    this.showProjectIdSection = true;
    if (value !== '') {
      this.showProjectIdSection = false;
      this.loadProjectDetailForMapFulfilment();
    }
  }

  public errorMsg = '';
  public selectedProjectId = '';

  @Input()
  public set isCalledForMap(value: boolean) {
    this.calledForMap = value;
    this.showProjectIdSection = true;
    if (!this.calledForMap) {
      this.showProjectIdSection = false;
    }
  };

  public calledForMap = true;
  public showProjectIdSection = true;

  @Output()
  private submit = new EventEmitter<void>();

  @Output()
  private cancel = new EventEmitter<void>();

  public pageTemplateResponseDetailsDTO: PageTemplateResponseDetailsDTO | null = null;

  constructor(private clientCommitmentsService: ClientCommitmentsService) { }

  ngOnInit(): void {
  }

  public loadProjectDetailForMapFulfilment(): void {
    this.errorMsg = '';
    this.clientCommitmentsService.getProjectDetailForMapFulfilment(
      environment.clientCommitmentPageTemplateId,
      this.selectedProjectId).
      subscribe(
        data => {
          this.pageTemplateResponseDetailsDTO = data as unknown as PageTemplateResponseDetailsDTO;
          if (!this.calledForMap) {
            this.title = this.pageTemplateResponseDetailsDTO.title;
          }
        },
        () => { });
  }

  public closeDrawer(): void {
    this.cancel.emit();
  }

  public onSubmit(): void {
    this.errorMsg = '';
    if (this.calledForMap) {
      this.clientCommitmentsService.mapPageTemplateResponse(
        environment.clientCommitmentPageTemplateId,
        this.responseId,
        this.selectedProjectId).subscribe(() => {
          this.submit.emit();
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  public unmap(): void {
    this.submit.emit();
  }
}
