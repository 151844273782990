import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { MasterService } from 'src/app/services/master.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { RolesService } from 'src/app/services/roles.service';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import {
  IRole,
  IRoleList,
} from 'src/app/shared/role';
import { ISuggestions } from 'src/app/shared/suggestion';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
})
export class RoleListComponent implements OnInit, OnDestroy {
  filterForm: FormGroup;
  roleList: IRoleList;
  roleTotal: number;
  queryParam: IQuery;
  currentPageNumber: number = 1;
  pagerObject: IPager;
  sortingObject;
  isError: boolean;
  errorMsg: string = '';
  roleSuggestion: ISuggestions;
  showCreateButton: boolean = false;
  showCheckbox: boolean = false;
  showComment: boolean = true;
  successMsg: string;
  displayColumns = ['ID', 'Role Name', 'Description', 'Role Type', 'Display Order', 'Can be Assigned To Client'];
  keys = ['id', 'title', 'description', 'roleType', 'displayOrder', 'canbeAssignedToClient'];
  listname: string = PreferenceService.roleList;
  filtered: boolean = false;
  filterExists: boolean = false;
  isSubmitted = false;
  regexValidationMessage = REGEX_VALIDATION_MESSAGE;

  constructor(
    private rolesService: RolesService,
    private router: Router,
    private projectService: ProjectsService,
    private permissionService: PermissionsService,
    private masterService: MasterService,
    private userFilterService: UserFilterService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.rolesService.isCloneMode = false;
    this.rolesService.isEditMode = false;
    this.showCreateButton = this.permissionService.isCreateRoleAllowed();
    this.initFilterForm();
    this.initQueryParams();
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    this.getRoles();
    this.getRolesTitles();
    this.getSuggestions();
    this.refresh();
    this.filtered = this.filterExists;
  }

  refresh() {
    this.getRefreshRoles();
  }

  ngOnDestroy(): void {
    this.rolesService._isRoleCreated = false;
    this.rolesService._newCreatedRole = '';
    this.successMsg = '';
  }

  initQueryParams() {
    this.queryParam = Object.assign({},
      {
        pageNumber: this.currentPageNumber,
        pageSize: environment.pageSize,
      }
    );
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      Title: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)]),
      id: new FormControl(''),
    });
  }

  getSuggestions() {
    this.projectService.getSuggestions('roles', '').subscribe(
      (suggestions) => {
        this.roleSuggestion = suggestions;
      },
      (error) => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

  getRolesTitles() {
    $('#refreshTask').addClass('fa-rotation');
    this.rolesService
      .getRoleList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        (roles) => {
          this.isError = false;
          let rolesToWorkWith = roles.body as any[];
          let titles = rolesToWorkWith.map(r => r.title);
          this.rolesService.setRoleTitleListAux(titles);
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  getRolesForList() {
    $('#refreshTask').addClass('fa-rotation');
    this.rolesService
      .getRoleList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        (roles) => {
          this.isError = false;
          this.roleList.roles = roles.body as IRole[];
          console.log("this.roleList", this.roleList);
          if (roles.headers.get('X-pagination')) {
            this.pagerObject = Object.assign(
              {},
              JSON.parse(roles.headers.get('X-pagination')) as IPager
            );
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  getRoles() {
    $('#refreshTask').addClass('fa-rotation');
    this.rolesService
      .getPagedRoleList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        (roles) => {
          this.isError = false;
          this.roleList = roles.body as IRoleList;
          //this.roleTotal = (roles.body as IRole[]).length;
          if (roles.headers.get('X-pagination')) {
            this.pagerObject = Object.assign(
              {},
              JSON.parse(roles.headers.get('X-pagination')) as IPager
            );
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  getRefreshRoles() {
    $('#refreshTask').addClass('fa-rotation');
    this.rolesService
      .getRefreshRoles(this.queryParam)
      .pipe(take(1))
      .subscribe(
        (projects) => {
          this.isError = false;
          this.roleList = projects.body as IRoleList;
          if (projects.headers.get('X-pagination')) {
            this.pagerObject = Object.assign(
              {},
              JSON.parse(projects.headers.get('X-pagination')) as IPager
            );
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  filterList() {
    this.isSubmitted = true;
    if(this.filterForm.valid) {
      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initQueryParams();
      let filterValues = this.filterForm.value;
      if (filterValues['Title']) {
        this.queryParam.title = filterValues['Title'];
      }
      if (filterValues['id']) {
        this.queryParam.id = filterValues['id'];
      }
      this.setOrderBy(this.sortingObject);
      this.refresh();
      this.filtered = true;
    }
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.userFilterService.clearFilter();
    this.getRefreshRoles();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.getRoles();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = sortingData.isAsc
        ? sortingData.key
        : sortingData.key + ' desc';
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.getRoles();
  }

  createRole() {
    this.router.navigate(['admin/role/create']);
  }

  closeModal(){
    $('#errorMessage').modal('hide');
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.rolesService.exportRolesList(this.queryParam).subscribe(
      res => {
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'Roles.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  navigate(event) {
    this.masterService.saveFilters(this.filterForm);
    this.rolesService.setRoleId(event.data);
    if(event.action === "view"){
      this.router.navigate(['/admin/role/view', event.data]);
    }
    else if (event.action === "edit") {
      this.navigateToRoleEdit(event.data);
    }
    else if (event.action === "clone") {
      this.navigateToRoleClone(event.data);
    }
    else if (event.action === "delete") {
      this.successMsg = '';
      this.rolesService.deleteRole(event.data).subscribe(
        () => {
          this.getRefreshRoles();
          this.getSuggestions();
          this.successMsg = `The role has been deleted.`
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
          $('#errorMessage').modal('show');
        }
      )
    } else {
      this.router.navigate(['/admin/role/' + event.action, event.data]);
    }
  }

  navigateToRoleEdit(roleId: number): void {
    this.rolesService.isEditMode = true;
    this.router.navigate(['/admin/role/edit', roleId]);
  }

  navigateToRoleClone(roleId: number): void {
    this.rolesService.isCloneMode = true;
    this.router.navigate(['/admin/role/clone', roleId]);
  }
}
