import { Injectable } from '@angular/core';
import { IEmailTemplate, IEmailTemplateDetails } from '../self-service/components/manageEmailTemplates/models/email-template';

@Injectable({
  providedIn: 'root'
})

export class EmailTemplateDTOService {

  emailTemplateData: IEmailTemplate
  emailTemplateDetails: IEmailTemplateDetails
 
  private emailTemplateUpdated: boolean = false;
  private isEdit: boolean = false;
  private emailTemplateCreated: boolean = false;
  private newlyCreatedemailTemplate: string = '';

  constructor() { }

  setemailTemplateData(data: IEmailTemplateDetails){
      this.emailTemplateDetails = data;
  }

  getemailTemplateData(): IEmailTemplateDetails{
      return this.emailTemplateDetails;
  }

  set _isemailTemplateUpdated(value){
    this.emailTemplateUpdated= value;
  }

  get _isemailTemplateUpdated(){
    return this.emailTemplateUpdated;
  }

  set _isEditMode(value){
    this.isEdit = value
  }

  get _isEditMode(){
    return this.isEdit;
  }

  set _isemailTemplateCreated(value){
    this.emailTemplateCreated = value
  }

  set _newCreatedemailTemplate(value){
    this.newlyCreatedemailTemplate =  value;
  }

  get _isemailTemplateCreated(){
    return this.emailTemplateCreated;
  }

  get _newCreatedemailTemplate(){
    return this.newlyCreatedemailTemplate;
  }
}