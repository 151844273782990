
<!------    Another Set Button ------->
<div class="col-md-12 float-right text-right pr-0">
    <button class="pull-right btn btn-secondary pl-2 pr-2 mb-3" type="button" (click)="anotherSet();">
        <span class="material-icons f-22"> library_add </span>
        Create Another Set
    </button>
    <div>
    </div>
</div>
   


   <!--------------FormSets------------>

   <div class="clearfix"></div>
   <div class="alert alert-dismissible alert-danger" *ngIf="selectForms">
    <button type="button" class="close" data-dismiss="alert" aria-label="close"
        (click)="selectForms=false">×</button>
    <i class="material-icons">warning</i>
    <p>Please assign forms by using "Select Forms" of {{msgTitle}}</p>
</div>
<div class="alert alert-dismissible alert-danger" *ngIf="dueDateMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close"
        (click)="dueDateMsg=''">×</button>
    <i class="material-icons">warning</i>
    <p [innerHTML]="dueDateMsg"></p>
</div>
<div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close"
        (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
</div>


<div class="clearfix"></div>

<div class="col-md-12 box" id="formSet" *ngFor="let formSet of formSets; let i=index;  let first = first">
    <a *ngIf="!first" href="javascript:void(0);" class="close pull-right" aria-label="close" (click)="closeFormset(i);">&times;</a>
    <h3 class="h5 heading-level-3 mt-0">{{formSet.title}}</h3>

    <p class="mt-1">Select/De-select Forms to Assign:</p>
    <button class="btn btn-secondary" type="button" data-toggle="modal" (click)="selectFormBtn(i)">
        Select Data Gathering Forms
    </button>

    <div class="col-md-12 p-0 mt-3"   *ngIf="formSets[i].showData">
        <p *ngIf="formSets[i].assignedFormsList.length > 0"> <b>Note:</b> To edit the Form Name, Description or Due Date, double-click the respective record.</p>
        <fdl-data-grid [rows]="formSets[i].assignedFormsList" [columns]="columns"
            emptyMessage="No Data Available" columnMode="standard">
        </fdl-data-grid>
    </div>


    <form [formGroup]="formSet.assignForm" autocomplete="off">
        <div class="form-set-form-container row align-items-start">
            <div class="col-md-5 typeahead-wrapper" [ngClass]="{'has-error': !formSet.assignForm.get('formResponder').valid && 
            (formSet.assignForm.get('formResponder').touched || isSubmitted)}">
                <label for="formResponder" class="asterisk">Form Respondent:</label>
                <fdl-type-ahead formControlName="formResponder"  [collection]="formsUsersDetails.users"
                    optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
                <span
                    *ngIf="!formSet.assignForm.get('formResponder').valid &&  (formSet.assignForm.get('formResponder').touched || isSubmitted)">
                    <i class="material-icons">warning</i> Please select Respondent.</span>
            </div>
            <div class="col-md-1 pl-0 pt-3 align-self-center" *ngIf="isCreateUserAllowed">
                <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Form Respondent" (click)="openAddDrawer(i, 'formResponder')"><i class="material-icons f-30">add_circle_outline</i></a>
            </div>
            <div class="col-md-5 typeahead-wrapper">
                <label for="formApprover">Approver:</label>
                <fdl-type-ahead formControlName="formApprover"  [collection]="formsUsersDetails.users"
                    optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
            </div>
            <div class="col-md-1 pl-0 pt-3 align-self-center" *ngIf="isCreateUserAllowed">
                <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Approver" (click)="openAddDrawer(i, 'formApprover')"><i class="material-icons f-30">add_circle_outline</i></a>                    
            </div>
        </div>
    </form>
</div>



<div class="fdl-2 button-container">
    <button type="button" class="btn btn-secondary" aria-label="Default" (click)="navigationForm()">Cancel</button>
    <div>
        <div class="btn-group btn-split">
            <a href="javascript:void(0)" class="btn btn-primary-blue" (click)="submitForm()">Submit</a>
        </div>
    </div>
</div>


<!---------------------Select Form Modal----------------------->
<select-forms #selectedRows  [allRowsSelection]="allRowsSelection" [productSection]="productSelection" [formList]="formsUsersDetails.forms" [formSets]="formSets" [currentIndex]="currentIndex"></select-forms>

