import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomComponentsService {
  private data: any;
  private sensitiveData: any;
  private responseEncryptedFieldApi = "responses/encryptedField";
  private responseId = 0;
  private _respondentEmailAddress: string;
  public responseEncryptedFieldsDataInSession: any = [];
  public saveData$ = new BehaviorSubject<any>('-1');
  public saveData2$ = new BehaviorSubject<any>('-1');
  private isReadOnly: boolean=false;
  constructor(private http: HttpClient) { }
  public setData(data) {
    this.data = data;
  }
  public getData() {
    return this.data;
  }
  public setSensitiveData(data) {
    this.sensitiveData = data;
  }
  public getSensitiveData() {
    return this.sensitiveData;
  }
  public setResponseId(id) {
    this.responseId = id;
  }
  public getRespondentEmailAddress(): string {
    return this._respondentEmailAddress;
  }
  public setRespondentEmailAddress(emailAddress): void {
    this._respondentEmailAddress = emailAddress;
  }
  public getResponseId() {
    return this.responseId;
  }
  public getApiData(api) {
  
    let options = { 'refresh': 'true' }
    return this.http.get<any[]>(api, { headers: options });
  }
  public setDataReadOnly(data: boolean) {
    
    this.isReadOnly = data;
  }
  public getDataReadOnly() {
    return this.isReadOnly;
  }
  public getFieldData(params) {
    return this.http.get<any>(this.responseEncryptedFieldApi, { params: params});
  }
}
