<div class="heading-container fdl-2">
  <h1>Form Templates</h1>
  <button type="button" *ngIf="isCreateFormAllowed" class="btn fdl-btn btn-primary-orange" (click)="createForm();">
    <span class="material-icons">
      add
    </span>
    Create New Form
  </button>
</div>
<div class="form-container">
  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
    <span class="iconLabel" aria-label="sucess"></span>
    <i class="material-icons" aria-hidden="true">check_circle</i>
    <p>
      {{successMsg}}
    </p>
  </div>
  <div class="alert alert-warning alert-dismissible" *ngIf="warningMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
    <span class="iconLabel" aria-label="warning"></span>
    <i class="material-icons" aria-hidden="true">check_circle</i>
    <p>
      {{warningMsg}}
    </p>
  </div>
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>
  <form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
    <h2 class="h4 collapse-arrow collapsed" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse" href="#collapseFilter">Search</h2>
    <div class="filter-form-container-column2 search-filter collapse" [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">
      <div class="filter-form-control typeahead-wrapper pr-4" [ngClass]="{'has-error': !filterForm.get('id').valid && (filterForm.get('id').touched || filterForm.get('id').dirty || isSubmitted)}">
        <label for="Status">ID:</label>
        <div class="">
          <input type="number" min="0" name="id" placeholder="Type to search" class="form-control" aria-invalid="false" formControlName="id" />
          <span *ngIf="!filterForm.get('id').valid && filterForm.get('id').errors?.pattern && (filterForm.get('id').touched || filterForm.get('id').dirty || isSubmitted)">
            <i class="material-icons">warning</i> {{regexValidationMessage.NUMERIC_VALIDATION_MESSAGE}}</span>
        </div>
      </div>
      <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('title').valid && (filterForm.get('title').dirty || isSubmitted)}">
        <span *ngIf="!filterForm.get('title').valid && filterForm.get('title').errors?.pattern && (filterForm.get('title').dirty || isSubmitted)">
          <i class="material-icons">warning</i>Please enter a valid Form Name
        </span>
        <fdl-type-ahead class="filter-typeahead" label="Form Name:" formControlName="title" [collection]="formSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
        </fdl-type-ahead>
      </div>
      <div class="filter-form-control pr-4">
        <label for="formType">Form Type:</label>
        <div class="fdl-select">
          <select name="formType" class="form-control" aria-invalid="false" formControlName="formType">
            <option value="">All</option>
            <option value="Data Gathering">Data Gathering</option>
            <option value="Intake">Intake</option>
            <option value="Project Workflow Template">Project Workflow Template</option>
            <!--<option value="Both">Data Gathering & Intake</option>-->
          </select>
        </div>
      </div>
      <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showProductFilter"  [ngClass]="{'has-error': !filterForm.get('ProductTitle').valid && (filterForm.get('ProductTitle').dirty || isSubmitted)}">
        <fdl-type-ahead class="filter-typeahead" label="Product Name:" formControlName="ProductTitle" [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="100" [minLength]="0"
          (input)="typeaheadLoadingProducts()">
        </fdl-type-ahead>
        <span *ngIf="!filterForm.get('ProductTitle').valid && filterForm.get('ProductTitle').errors?.pattern && (filterForm.get('ProductTitle').dirty || isSubmitted)"><i class="material-icons">warning</i> {{regexValidationMessage.PRODUCT_NAME_VALIDATION_MESSAGE}} </span>
      </div>
      <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showOrganizationFilter" [ngClass]="{'has-error': !filterForm.get('OrganizationTitle').valid && (filterForm.get('OrganizationTitle').dirty || isSubmitted)}">
        <span *ngIf="!filterForm.get('OrganizationTitle').valid && filterForm.get('OrganizationTitle').errors?.pattern && (filterForm.get('OrganizationTitle').dirty || isSubmitted)">
          <i class="material-icons">warning</i>Please enter a valid Organization Name </span>
        <fdl-type-ahead class="filter-typeahead" label="Organization Name:" formControlName="OrganizationTitle" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
          [optionsLimit]="500" [minLength]="0" (onSelect)="onOrgSelect($event)">
        </fdl-type-ahead>
      </div>
      <div>
        <button type="button" class="btn fdl-btn btn-primary-dark buttom-wrapper" aria-label="Search"
                (click)="filterList()">
          Search
        </button>
      </div>
      <div class="ml-2" *ngIf="filtered">
        <button type="button" class="btn fdl-btn btn-primary-dark buttom-wrapper" aria-label="Reset"
                (click)="clearFilterList()">
          Reset
        </button>
      </div>
    </div>
  </form>
  <div class="data-table-container">
    <div class="justify-content-between d-flex">
      <div class="headline-container">
        <h2 class="h4">Form Template List</h2>
        <p>Total {{pagerObject.totalCount}} Form Templates</p>
      </div>
      <div class="d-flex">
        <div class="refresh-icon-link mt-1">
          <!-- add class of 'fa-rotation' on click of the refresh icon -->
          <a title="Refresh Form Template List" id="refreshTask" (click)="refresh()">
            <i aria-hidden="true" class="fa fa-refresh"></i>
          </a>
        </div>
        <div class="dropdown downloadDropdownMenuButton" *ngIf="showComment">
          <button *ngIf="showComment"
                  type="button" title="Download Extract" class="btn btn fdl-btn btn-primary-dark btn-addcomment"
                  id="downloadDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <span class="material-icons material-icons-wrapper">
              get_app
            </span>
          </button>

          <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
            <a class="dropdown-item" (click)="downLoadType({'type':'xlsx'})">Export List</a>
          </div>


        </div><app-prefernce-icon></app-prefernce-icon>
      </div>
      </div>
      <div class=" form-template-list-content">
        <div class="form-list-table table-scroll-x mh300">
          <app-data-table *ngIf="formList"
                          [rows]="formList.forms"
                          [keys]="keys"
                          [displayColumns]="displayColumns"
                          (buttonClicked)="navigate($event)"
                          (deleteBtnClicked)="navigate($event)"
                          (sortingClicked)="applySorting($event)"
                          [showCheckbox]="false"
                          [showDelete]="isDeleteFormAllowed"
                          [showEdit]="isEditFormAllowed"
                          [showViewHistory]="isViewHistoryAllowed"
                          [showViewTimeline]="isViewTimelineAllowed"
                          [downloadFormFields]="true"
                          [showExtractionTemplate]="true"
                          [columnChecker]="columDisplayChecker.bind(this)"
                          type="Form" [listname]="listname">
          </app-data-table>
        </div>
        <div>
          <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && formList && formList.forms.length > 0"
                          [pagerObject]="pagerObject"
                          (pagingChanged)="changePage($event)"></app-pagination>
        </div>
      </div>
    </div>
  </div>


  <div #updateInflightForms id="updateInflightForms" data-backdrop="static" data-keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="myLargeModalLabel" class="modal show fade bd-example-modal-md" aria-hidden="true">
    <div role="document" class="modal-dialog modal-lg" style="max-width: 1280px;">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h4 id="myLargeModalLabel" class="modal-title">
            <span>Update inflight responses for {{this.selectedForm.title}}</span>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          </h4>
        </div>

        <div class="modal-body">
          <div class="table-responsive client-list-table mh200 overflow-auto">
            <div class="assign-modal-table">
              <app-update-inflight-forms *ngIf="this.selectedForm.id" #updateInflightComponent [formId]="this.selectedForm.id"></app-update-inflight-forms>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

