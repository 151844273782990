<div class="heading-container fdl-2" >
    <div class="back-action-container">
        <div class="back-button">
            <a href="javascript:void(0);" (click)="goBack()">
                <span class="material-icons">arrow_back</span>
                    Workflows
            </a>
        </div>
        <h1>{{workflowData?.name || 'N/A'}}</h1>
    </div>
</div>

<div class="form-container">
     <!-- Error Msg Alert -->
     <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button> 
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>
    <!-- Success Msg Alert -->
    <div class="alert alert-success alert-dismissible" *ngIf="isSuccess">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isSuccess=false">×</button>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p [innerHtml]="successMsg"></p>
    </div>

    <form class="form form-border"  autocomplete="off">
        <div id="accordion" class="accordion collapse-view">
            <div class="headline-container collapseList">
                <h2 class="h4 collapsed collapse-arrow font-weight-bold" data-toggle="collapse" href="#collapseOne">Workflow Data</h2>
                <!-- <i>Please click on header to expand/collapse to view workflow data.</i> -->
            </div>
            <div class="biller-survey white-box fdl-2  mt-2" id ="collapseOne">
                <div>
                    <ul>
                        <li>
                            Description:
                            <span class="info">{{workflowData?.description || 'N/A'}}</span>
                        </li>
                        <li>
                            IsActive:
                            <span class="info">{{workflowData?.isActive}}</span>
                        </li>
                        <li>
                            Is System Workflow:
                            <span class="info">{{workflowData?.isSystemWorkflow}}</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            Environment:
                            <span class="info">{{workflowData?.environment || 'N/A'}}</span>
                        </li>
                        <li>
                            Created On:
                            <span class="info">{{workflowData?.createdOn | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                        </li>
                        <li>
                            Created By:
                            <span class="info">{{workflowData?.createdByName || 'N/A'}}</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            FlowRunId:
                            <span class="info">{{workflowData?.flowId || 'N/A'}}</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            FlowRunUrl:
                            <span class="info"><a href="{{workflowData?.flowUrl || '#'}}" target="_blank">{{workflowData?.flowUrl || 'N/A'}}</a></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </form>
    <div></div>
    <form class="form form-border" [formGroup]="workflowContext" autocomplete="off">
        <div id="accordion" class="accordion collapse-view">
            <div class = "headline-container collapseList">
                <h2 class="h4 collapsed collapse-arrow font-weight-bold" data-toggle="collapse" href="#createworkflow">Configure Workflow</h2>
                <!-- <i>Please click on header to expand/collapse and configure the workflow.</i> -->
            </div>
            <div class="form-row" class="create-workflow-context" id="createworkflow">
                <div class="col-md-6 mb-3 mt-3 typeahead-wrapper">
                    <label *ngIf="isAdmin" for="entityLevel">
                        Organization:
                    </label>
                    <fdl-type-ahead *ngIf="isAdmin" formControlName="entityNameOrg"
                        [collection]="orgSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                        [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityOrgSelect($event)">
                    </fdl-type-ahead>
                    <div class="has-error">
                        <span *ngIf="isAdmin && (invalidOrgName || !workflowContext.get('entityNameOrg').valid) && (workflowContext.get('entityNameOrg').touched || isSubmitted)">
                            <i class="material-icons">warning</i> Please select valid organization.
                        </span>
                    </div>
                </div>
                <div class="col-md-6 mb-3 mt-3 typeahead-wrapper">
                    <label for="entityLevel">
                        Entity Level:
                    </label>
                    <div class="fdl-select">
                        <select name="entityLevel" class="form-control" aria-invalid="false" formControlName="entityLevel" [(ngModel)]='selectedEntityLevelValue'
                        (change)="onEntitySelect()">
                            <option value="">Please select</option>
                            <option *ngFor="let level of entityLevel | keyvalue" [value]="level.value">
                                {{level.value}}
                            </option>
                        </select>
                    </div>
                    <div class="has-error">
                        <span *ngIf="!workflowContext.get('entityLevel').valid && !isAdmin && (workflowContext.get('entityLevel').touched || isSubmitted)">
                            <i class="material-icons">warning</i> Please select entity level.
                        </span>
                    </div>
                </div>  
                <div class="col-md-6 mb-3 typeahead-wrapper">
                    <label *ngIf="selectedEntityLevelValue" for="EntityName" class="asterisk">
                        Entity Name:
                    </label>
                    <fdl-type-ahead *ngIf="selectedEntityLevelValue == 'Product'" formControlName="entityNameProduct"
                        [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                        [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityProductSelect($event)">
                    </fdl-type-ahead>
                    <fdl-type-ahead *ngIf="selectedEntityLevelValue == 'Form'" formControlName="entityNameForm"
                        [collection]="formSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                        [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityFormSelect($event)">
                    </fdl-type-ahead>
                    <fdl-type-ahead *ngIf="selectedEntityLevelValue == 'PageTemplate'" formControlName="entityNamePage"
                        [collection]="pageTemplateSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                        [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityPageSelect($event)">
                    </fdl-type-ahead>
                   
                    <div class="has-error">
                        <span *ngIf="selectedEntityLevelValue == 'Product' && (invalidProductName || !workflowContext.get('entityNameProduct').valid) && (workflowContext.get('entityNameProduct').touched || isSubmitted)">
                            <i class="material-icons">warning</i> Please select valid product.
                        </span>
                        <span *ngIf="selectedEntityLevelValue == 'Form' && (invalidFormName || !workflowContext.get('entityNameForm').valid) && (workflowContext.get('entityNameForm').touched || isSubmitted)">
                            <i class="material-icons">warning</i> Please select valid form.
                        </span>
                        <span *ngIf="selectedEntityLevelValue == 'PageTemplate' && (invalidPageName || !workflowContext.get('entityNamePage').valid) && (workflowContext.get('entityNamePage').touched || isSubmitted)">
                            <i class="material-icons">warning</i> Please select valid page template.
                        </span>
                    </div>
                </div>
                <div class="fdl-2 button-container">
                    <button type="button" aria-label="Cancel" class="btn fdl-btn btn-secondary-light" (click)="Cancel()">Cancel</button>
                    <button type="submit" aria-label="Default" class="btn fdl-btn btn-primary-orange float-right" (click)="Submit()">Submit</button>
                </div>
            </div>
        </div>
    </form>
    <div></div>
    <div class="data-table-container">
        <div class="headline-container">
            <h2 class="h4">Workflow Configuration List</h2>
            <p>Total {{pagerObject.totalCount}} Workflow Configurations</p>
        </div>
        <div class="table-responsive">
            <app-data-table 
                *ngIf="workflowList.workflows" 
                [rows]="workflowList.workflows"
                [keys]="keys" 
                [displayColumns]="displayColumns"
                (buttonClicked)="navigate($event)" 
                (sortingClicked)="applySorting($event)"
                (deleteBtnClicked)="navigate($event)"
                [showDelete]="true"
                [showCheckbox]="false"
                type="Workflow Context"
            ></app-data-table>
          <app-pagination
                *ngIf="pagerObject && pagerObject.totalPages > 1 && workflowList.workflows && workflowList.workflows.length > 0"
                [pagerObject]="pagerObject"
                (pagingChanged)="changePage($event)"
            ></app-pagination>
        </div>
    </div>
</div>
