import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ICreateTopicForm } from '../../../../../admin/manageForms/models/createTopic';
import { ManageHelpArticleService } from '../services/manageHelp.service';

@Component({
  template: '<app-create-topic></app-create-topic>'
})

export class EditHelpArticleComponent implements OnInit {

  helpArticleId: number;
  constructor(private route: ActivatedRoute, private _manageHelp: ManageHelpArticleService, private manageHelpArticleService: ManageHelpArticleService) { }

  ngOnInit(): void {
   
    let a: ICreateTopicForm;
    this._manageHelp.formVal = a;
    this.manageHelpArticleService.returnPage = 'Resource Center';
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.helpArticleId = params['id'];
      });
  }
}
