import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ProjectDrawerHostDirective } from './project-drawer-host.directive';
import { ProjectDrawerService } from './project-drawer.service';
import { drawerConstant } from './project-drawer-constant';

@Component({
  selector: 'app-project-drawer-container',
  templateUrl: './project-drawer-container.component.html'
})
export class ProjectDrawerContainerComponent implements OnInit {

  @ViewChild(ProjectDrawerHostDirective, { static: true }) drawerHost: ProjectDrawerHostDirective;
  @Input() componentName: string;
  @Input() state: string;
  @Input() CancelText: string = "Cancel";
  @Input() isChannelPartner: boolean = false;
  isDrawerType: boolean = false;

  headingText: string;

  constructor(private projectDrawerService: ProjectDrawerService) {}

  ngOnInit(): void {
    const viewContainerRef = this.drawerHost.viewContainerRef;
    this.projectDrawerService.loadComponent(viewContainerRef, this.componentName).then(result => {
  
        result.instance['isDrawerType'] = true;
        this.isDrawerType = true;
      result.instance['CancelText'] = this.CancelText;
      result.instance['isChannelPartner'] = this.isChannelPartner;
      const drawerData = this.getDrawerData(this.state);
      if(drawerData){
        result.instance['drawerData'] = this.getDrawerData(this.state);
      }
    });
  }

  private getDrawerData(state: string){
    this.headingText = drawerConstant[state]['heading'];
    return drawerConstant[state]['drawerData'];
  }
}
