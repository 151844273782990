<div class="commitment-intake-container" *ngIf="isPdfExport">
  <div class="box-container statistics pl-0">
    <div class="separate-form-container exporting-container">
      <section class="summary-main-container">
        <div class="app-col-1">
          <div class="box-container statistics pl-0">
            <h2>Commitment Statistics</h2>

            <div class="box-wrapper">
              <div class="swtich-container">
                <div class="total-count d-flex">
                  <div class="value">Total Commitment(s):</div>
                  <div class="count-badge">
                    {{ commitmentStats["Total"] }}
                  </div>
                </div>
              </div>
              <div
                class="scroll-horizontal non-sla d-flex flex-column">
                <ul class="box commitment-status">
                  <li class="box active">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status active"><i></i>In Progress</div>

                        <div class="status-count">
                          {{ this.commitmentStatsCount["In Progress"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="chart">
                            <div class="semi-donut margin">
                              <span class="semi-donut-percentage">{{ commitmentStats["In Progress"] }}%</span>
                              <i style="transform: rotate({{calculateSemiDonutRotation('In Progress')}}deg)"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="box fullfilled">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status green"><i></i>Completed</div>
                        <div class="status-count">
                          {{ this.commitmentStatsCount["Completed"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="semi-donut margin">
                            <span class="semi-donut-percentage">{{ this.commitmentStats["Completed"] }}%</span>
                            <i style="transform: rotate({{calculateSemiDonutRotation('Completed')}}deg)"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="box on-hold">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status yellow"><i></i>On Hold</div>
                        <div class="status-count">
                          {{ this.commitmentStatsCount["On Hold"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="semi-donut margin">
                            <span class="semi-donut-percentage">{{ this.commitmentStats["On Hold"] }}%</span>
                            <i style="transform: rotate({{calculateSemiDonutRotation('On Hold')}}deg)"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="box cancelled">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status grey"><i></i>Canceled</div>
                        <div class="status-count">
                          {{ this.commitmentStatsCount["Canceled"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="semi-donut margin">
                            <span class="semi-donut-percentage">{{ this.commitmentStats["Canceled"] }}%</span>
                            <i style="transform: rotate({{calculateSemiDonutRotation('Canceled')}}deg)"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="legend-wrapper">
                <span class="legend-text">Overall Progress</span>
              </div>
            </div>
          </div>

          <div class="delivery-and-contacts-container box-container pl-0 mt-19">
            <div class="box-container commitments-calendar pl-0">
              <h2>Delivery Calendar</h2>
              <div
                class="box-wrapper"
                *ngIf="
                  commitmentCalendarNumbers.length == 0 &&
                  commitmentCalendar &&
                  !dataCommitmentCalendarError
                "
              >
                <div class="no-data-message-container">
                  <div class="product-wrapper no-data-message">
                    <h3>No Data Available</h3>
                  </div>
                </div>
              </div>
              <div class="box-wrapper" *ngIf="dataCommitmentCalendarError">
                <div class="no-data-message-container">
                  <div class="product-wrapper no-data-message">
                    <h3>There was a problem. Please try again later.</h3>
                  </div>
                </div>
              </div>
              <div
                class="box-wrapper"
                [ngClass]="
                  (commitmentCalendarNumbers.length != 0 || !commitmentCalendar) &&
                  !dataCommitmentCalendarError
                    ? 'd-flex'
                    : 'd-none'
                "
              >
                <div class="filter-area">
                  <div class="swtich-container mb-0">
                    <div class="switch-wrapper">
                      <div
                        class="tab-switch"
                        id="month"
                        [ngClass]="
                          typeOfCommitmentCalander == 'month' ? 'active' : ''
                        "
                        (click)="loadCommitmentCalendar('month')"
                      >
                        Monthly
                      </div>
                      <div
                        class="tab-switch"
                        id="qtr"
                        [ngClass]="
                          typeOfCommitmentCalander == 'qtr' ? 'active' : ''
                        "
                        (click)="loadCommitmentCalendar('qtr')"
                      >
                        Quarterly
                      </div>
                      <div
                        class="tab-switch"
                        id="year"
                        [ngClass]="
                          typeOfCommitmentCalander == 'year' ? 'active' : ''
                        "
                        (click)="loadCommitmentCalendar('year')"
                      >
                        Yearly
                      </div>
                    </div>
                  </div>
                </div>

                <div class="chart" *ngIf="!commitmentCalendar">
                  <div
                    role="status"
                    aria-label="Please wait, do not refresh the page"
                    class="ccdrms-loader"
                    style="position: relative; margin-top: 20%"
                  ></div>
                </div>
                <div class="chart" [ngClass]="commitmentCalendar ? 'd-flex' : 'd-none'">
                  <figure class="deliveryCalendarData">
                    <svg id="deliveryCalendarChart"></svg>
                    <caption>
                      <ul id="legend"></ul>
                    </caption>
                  </figure>
                </div>
              </div>
            </div>
            <div class="box-container contacts">
              <h2>Contacts</h2>
              <div class="box-wrapper">
                <div class="content-details">
                  <ul class="contact-list">
                    <li
                      style="
                        flex-direction: row;
                        justify-content: space-between;
                      "
                    >
                      <div class="industry-info">
                        <div class="title">Industry</div>
                        <div class="content" style="font-weight: 700">
                          {{
                            summary && summary.industry
                              ? summary.industry
                              : "Not Available"
                          }}
                        </div>
                      </div>
                      <div class="gud-info">
                        GUD
                        <span>{{
                          summary && summary.gud ? summary.gud : "Not Available"
                        }}</span>
                      </div>
                    </li>
                    <li>
                      <div style="width: 100%">
                        <div class="title">Website</div>
                        <div class="content" *ngIf="summary && summary.website">
                          <a
                            href="{{
                              summary && summary.website ? summary.website : ''
                            }}"
                            target="_blank"
                            class="text-truncate"
                            title="{{
                              summary && summary.website ? summary.website : ''
                            }}"
                            >{{
                              summary && summary.website ? summary.website : ""
                            }}</a
                          >
                        </div>
                        <div
                          class="content"
                          *ngIf="!(summary && summary.website)"
                        >
                          Not Available
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="title">Region</div>
                      <div class="content">
                        <div
                          class="text-truncate"
                          title="{{
                            summary && summary.region
                              ? summary.region
                              : 'Not Available'
                          }}"
                        >
                          {{
                            summary && summary.region
                              ? summary.region
                              : "Not Available"
                          }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <hr />
                <div class="content-details">
                  <ul class="contact-list">
                    <li>
                      <div class="title">Relationship Manager</div>
                      <div class="initial-wrapper">
                        <div class="contact-initial">
                          <div class="initial">
                            {{ summary && summary.rmIn ? summary.rmIn : "NA" }}
                          </div>
                        </div>
                        <div
                          class="contact-name text-truncate"
                          *ngIf="summary && summary.relationshipManagerName"
                        >
                          <div class="title">
                            <div
                              class="text-truncate"
                              title="{{ summary.relationshipManagerName }}"
                            >
                              {{ summary.relationshipManagerName }}
                            </div>
                          </div>
                          <div class="content email">
                            <div class="text-truncate">
                              <a
                                href="mailto:{{
                                  summary.relationshipManagerEmail
                                }}"
                                title="{{ summary.relationshipManagerEmail }}"
                              >
                                {{ summary.relationshipManagerEmail }}</a
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          class="contact-name"
                          style="padding-top: 10px"
                          *ngIf="!(summary && summary.relationshipManagerName)"
                        >
                          <div class="title">Not Available</div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div class="title">Sales Executive</div>
                      <div class="initial-wrapper">
                        <div class="contact-initial">
                          <div class="initial">
                            {{ summary && summary.seIn ? summary.seIn : "NA" }}
                          </div>
                        </div>
                        <div
                          class="contact-name text-truncate"
                          *ngIf="summary && summary.salesExecutiveName"
                        >
                          <div class="title">
                            <div
                              class="text-truncate"
                              title="{{ summary.salesExecutiveName }}"
                            >
                              {{ summary.salesExecutiveName }}
                            </div>
                          </div>
                          <div class="content email">
                            <div class="text-truncate">
                              <a
                                href="mailto:{{ summary.salesExecutiveEmail }}"
                                title="{{ summary.salesExecutiveEmail }}"
                              >
                                {{ summary.salesExecutiveEmail }}</a
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          class="contact-name"
                          style="padding-top: 10px"
                          *ngIf="!(summary && summary.salesExecutiveName)"
                        >
                          <div class="title">Not Available</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="box-container commitments-table">
            <div class="commitments-tabs-wrapper">
              <ul class="nav nav-tabs scoped-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    role="tab"
                    aria-selected="false"
                    href="#tab13"
                    data-toggle="tab"
                    >Commitments
                    <span class="total-count">
                      <span class="count-badge">{{ commitments?.length || 0 }}</span>
                    </span>
                  </a>
                </li>
              </ul>
              <!-- Tab panes -->
              <div>
                <div
                  class="tab-pane"
                >
                  <div class="box-container commitments-table mt-0">
                    <div class="box-wrapper table-container">
                      <div class="table-section cct-table s-overflow-auto">
                        <div
                          class="no-data-message-container p-0 pt-2"
                          *ngIf="commitments && commitments.length == 0 && !commitmentsError"
                        >
                          <div class="product-wrapper no-data-message">
                            <h3>No Data Available</h3>
                          </div>
                        </div>
                        <div
                          class="no-data-message-container p-0 pt-2"
                          *ngIf="commitmentsError"
                        >
                          <div class="product-wrapper no-data-message">
                            <h3>
                              There was a problem. Please try again later.
                            </h3>
                          </div>
                        </div>
                        <app-data-table
                          *ngIf="commitments && commitments.length > 0"
                          [displayColumns]="headerColumns"
                          [keys]="keys"
                          [rows]="commitments"
                          [showCheckbox]="false"
                          [showActionButton]="false"
                          [showMenu]="false"
                          [enableSorting]="false"
                        ></app-data-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box-container product-details pl-0">
            <div class="product-container">
              <h2>Commitments by Products</h2>
            </div>
            <div class="box-wrapper">
              <ul
                class="product-list"
                [ngClass]="
                  commitmentProducts && commitmentProducts.length > 7
                    ? 'border-bottom'
                    : ''
                "
              >
                <li *ngFor="let k of pageProducts">
                  <div class="product-wrapper">
                    <h3 title="{{ k.productName }}">{{ k.productName }}</h3>
                    <p title="{{ k.productDescription }}">
                      {{ k.productDescription }}
                    </p>
                  </div>
                  <div class="two-col">
                    <div class="name">
                      <span class="badge">{{ k.totalCommitments }}</span>
                    </div>
                  </div>
                </li>

                <!-- No data available -->
                <li
                  *ngIf="commitmentProducts && commitmentProducts.length == 0"
                >
                  <div class="product-wrapper no-data-message">
                    <h3>No Data Available</h3>
                  </div>
                </li>
                <li *ngIf="cBProductsError">
                  <div class="product-wrapper no-data-message">
                    <h3>There was a problem.<br />Please try again later.</h3>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- Basic Info Page Ends  -->
</div>
