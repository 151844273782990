import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {


  private progressStatusBar = new BehaviorSubject('0');
  currentStatusBar = this.progressStatusBar.asObservable();

  public userId$ = new BehaviorSubject<string>('-1');
  constructor() { }
  getuserId(): Observable<string> {
    return this.userId$.asObservable();
  }
  setuserId(userId: string) {
    this.userId$.next(userId);
  }
  updateProgressBar(status: string) {
    this.progressStatusBar.next(status);
  }


}
