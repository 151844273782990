<div class="heading-container fdl-2">
  <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span>
        View History
      </a>
    </div>
    <h1>{{formTemplateName}} - Comparison</h1>
  </div>
</div>
<button id="mockClick" style="display:none;" (click)="comparePopup()">adfsdf</button>
<input id="input" style="display:none;" />
<div class="main-container-sub  m-0">

  <div class="row m-0 separate-form-container " [ngClass]="!hideLeftNav ? 'p-0' : 'p-0'">
    <aside class="aside side-navbar listing col-xl-2 col-3" [ngClass]="!hideLeftNav ? 'col-xl-2 col-3' : 'col-xl-2 col-3 asidebar'"
           *ngIf="allpages && allpages.length > 1">
      <div class="left-nav" role="navigation">
        <div class="left-nav-icon" aria-label="menu" tabindex="" aria-expanded="false" (click)="toggleSideBar()">
          <i class="navbar-icon-menu" [ngClass]="!hideLeftNav ? '' : 'navbar-icon-close' "></i>
          <span class="toggle-heading">&nbsp;</span>
        </div>
        <ol class="timeline">
          <ng-container *ngFor="let fieldset of allpages; index as i">
            <li [ngClass]="getClassName(i)" *ngIf="(fieldset.tempShow!=false)">
              <a href="javascript:void(0);" (click)="changePage(i)" [ngClass]="getClassNameLink(i)">{{fieldset.pageName}}</a>
            </li>
          </ng-container>
        </ol>
      </div>
    </aside>
    <!-- Please DO THE TOGGLE CLASS AS PER THE FOLLOWING:
        1) Add a class of 'compare-form-io-wrapper-full' when side navbar NOT display
        2) Add a class of 'compare-form-io-wrapper' when side navbar IS display
        3) Add a class of 'compare-form-io-wrapper-nav-collapsed' when side navbar IS display with a collapsed mode
    -->
    <section class="col-lg-12 p-0 compare-form-io-wrapper">
      <!-- Please add class of 'pl-4' in below element when the side navbar display
            example:  <div class="legend-wrapper pl-4">
        -->
      <div class="legend-wrapper">
        <div class="title">Color Comparison Indicator:</div>
        <ul class="legend">
          <li class="label changed"><span></span>Updated</li>
          <li class="label removed"><span></span>Removed</li>
          <li class="label added"><span></span>Added</li>
        </ul>
      </div>
      <div class="data-table-container">


        <div class="table-responsive form-responces">

          <!-- Compare Form starts -->
          <!-- <table width="100%" height="100%" >
    <tbody>
      <tr>
        <td width="50%" valign="top" id="frmPrev">
          <div>
            <div #myDOMElementPrev id="dvPrev">
              <formio [form]="formJsonPrev" [options]="formbuilder" [readOnly]="readonly" (ready)="render1($event)" #formio></formio>
            </div>
          </div>
        </td>
        <td>&nbsp;</td>
        <td width="50%" valign="top" id="frmCurrent">
          <div>
            <div #myDOMElementCurrent>
              <formio [form]="formJsonCurrent" [options]="formbuilder" (ready)="render2($event)" [readOnly]="readonly" #formio></formio>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
   -->
          <div class="comparison-container">
            <div class="comparison-container-content" id="frmPrev">

              <div class="comparison-container-content-col" #myDOMElementPrev id="dvPrev">
                <p><strong><u>Previous Version</u></strong></p>
                <formio [form]="previousFieldSet" [submission]=answerPrev [options]="formbuilder" [readOnly]="readonly" *ngIf="previousPageExits" (ready)="render1($event)"
                        #formio></formio>
                <div *ngIf="!previousPageExits">Page does not exist</div>
              </div>


              <div class="comparison-container-content-col" #myDOMElementCurrent id="dvCurrent">
                <p><strong><u>Current Version</u></strong></p>
                <formio [form]="currentFieldSet" [options]="formbuilder" [submission]=answerCurrent *ngIf="currentPageExits" (ready)="render2($event)"
                        [readOnly]="readonly" #formio></formio>
                <div *ngIf="!currentPageExits">Page does not exist</div>
              </div>
            </div>
          </div>
          <!-- Compare Form ends -->
        </div>


        <div class="fdl-2 button-container" id="button_view">
          <button type="button" class="btn fdl-btn btn-secondary-light  ml-3" aria-label="Default"
                  (click)="goBack()">
            Cancel
          </button>
        </div>

      </div>

    </section>
  </div>
</div>


<div class="modal fade bd-example-modal-lg" id="popup" data-backdrop="static" data-keyboard="false"
     tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-lg mt-120" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Compare Field</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closePopUp()"
                aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">


        <div class="row">
          <div class="col-md-6 ">
            <pre>  {{currentFieldToCompare | json}}
  </pre>
          </div>
          <div class="col-md-6 ">
            <pre>{{previousFieldToCompare |json}}</pre>
          </div>
        </div>

      </div>
      <div class="modal-footer fdl-2">

        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2 mr-2" (click)="closePopUp()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!-- <app-footer></app-footer> -->
