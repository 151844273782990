import { Component, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from 'src/app/services/user.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProgressBarService } from 'src/app/services/progressBar.service';
import { RouteEventsService } from "src/app/services/routerEvents.service";
import { MasterService } from "../../services/master.service";
declare var $: any;
@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html'
})
export class ProgressBarComponent {
  @Input() answer: any;
  @Input() steps: any;
  @Input() headerVal: string;
  @Input() delegateFormData: any
  @Input() isUnauthenticated: boolean;
  @Input() readonly: boolean = false;
  percentage: number = 0;
  showAction: boolean = true;
  isClient: boolean = false;
  isAdmin: boolean = false;
  assignMe: boolean = false;
  activePages: number = 0;
  isHistory: boolean = false;
  isIntake: boolean = false;
  @Output() pdfDownloadClicked = new EventEmitter<{}>();
  numberOfSteps(obj: any) {
    let pages = this.steps.filter((data) => {
      if (data.show) {

        return true;
      }
    });
    return pages.length;
  }
  pdfOnClick(type) {

    this.pdfDownloadClicked.emit({ 'type': type })

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.answer && changes.answer.currentValue) {
      this.answer = changes.answer.currentValue;

      //  this.percentage = (changes.answer.currentValue / this.numberOfSteps(this.steps)) * 100;
    }
    if (changes.steps && changes.steps.currentValue) {
      this.steps = changes.steps.currentValue;

    }
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
    let steps = this.steps;
    this.percentage = (this.answer / steps) * 100;


  }
  constructor(private _router: Router, private userService: UserService, private permissionService: PermissionsService, private location: Location,
    private progressBarService: ProgressBarService, private route: ActivatedRoute, private routeEventsService: RouteEventsService, private masterService: MasterService,) { }

  ngOnInit() {
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.isClient = this.userService.isClient && !this.userService.isRespondent
    this.isHistory = this.route.snapshot.params["edit"] === "history";
    console.log(this.delegateFormData);

    if (this._router.url.indexOf('admin') !== -1 || this.isHistory) {
      this.showAction = false;
    } else {
      this.isAdmin = false;
    }

    if (this._router.url.indexOf('intake') !== -1) {
      this.isIntake = true;
      this.showAction = false;
    }
    if (this.permissionService.userData.id != this.delegateFormData.assignedToId) {
      this.assignMe = true;
    }
  }

  isTakeDelegateAction(): any {
    return (this.showAction && !this.isUnauthenticated)
  }
  delegateAction() {
    let delegateDataCopy = JSON.parse(JSON.stringify(this.delegateFormData))
    this.delegateFormData = {};
    this.delegateFormData = delegateDataCopy;

    $('#delegateForms').modal('show');
  }

  isDelegateDisabled() {
    if (this.delegateFormData.responseStatusTitle.trim().toLowerCase() === 'completed' ||
      this.delegateFormData.responseStatusTitle.trim().toLowerCase() === 'under review' || this.delegateFormData.responseStatusTitle.trim().toLowerCase() === 'on hold' || this.delegateFormData.responseStatusTitle.trim().toLowerCase() === 'rejected' ) {
      return true;
    }
    
    return null;
  }
  isUploadAnswerDisabled() {
    if (this.delegateFormData.responseStatusTitle.trim().toLowerCase() === 'completed' ||
      this.delegateFormData.responseStatusTitle.trim().toLowerCase() === 'under review' || this.readonly) {
      return true;
    }
    
    return null;
  }

  cancel() {
    if (this.route.snapshot.params["edit"] === "history") {
      this.masterService.isCancelledClickedHisotry(true);
      this.location.back();
      return;
    } 
    
    if (this.route.snapshot.params["edit"] === "responses") {
      this.masterService.isCancelledClicked(true);

      // Bug 282755: Should back to forms list
      // this.location.back();
      this._router.navigate(['/response/list']);
      return;
    }

    const urlSegments = this.route.snapshot.url;
    const basePath = `${urlSegments[0].path}/${urlSegments[1].path}`;
    if (urlSegments && basePath === "form/view") {
      this._router.navigate(['/admin/form/list']);
      return;
    }
    
    this.location.back();
  }
}
