import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-commitments-update-modal',
  templateUrl: './commitments-update-modal.component.html',
  styleUrls: ['./commitments-update-modal.component.css'],
})
export class CommitmentsUpdateModalComponent implements OnInit {
  @Input() updatedCommitments: any[] = [];
  @Input() untrackedCommitments: any[] = [];
  @Output() private closeModal = new EventEmitter();
  @Output() updateCommitment = new EventEmitter<{ updatedCommitments: any[], untrackedCommitments: any[] }>();
  constructor() {}

  ngOnInit(): void {}

  closeDrawer() {
    this.closeModal.emit();
    $('.container-page').removeClass('overflow-hidden');
  }

  submit() {
    this.updateCommitment.emit({updatedCommitments: this.updatedCommitments, untrackedCommitments: this.untrackedCommitments});
  }

  getStatusCSSClass(row: any) {
    if (row.statusA == 'update') return 'alert-message success';
  }
}
