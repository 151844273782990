<div class="heading-container">
    <div class="back-action-container">
        <div class="back-button">
            <a href="javascript:void(0);" (click)="goBack()">
                <span class="material-icons"> arrow_back </span>
                {{returnPage}}
            </a>
        </div>
        <h1>Request Response(s) Extract</h1>
    </div>
</div>

<div class="main-container-sub responsive-container">
    <div class="form-container">
        <div class="row">
            <section class="col-lg-12 p-0">
                <div class="alert alert-dismissible alert-danger" *ngIf="isError">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
                    <i class="material-icons">warning</i>
                    <p [innerHtml]="errorMsg"></p>
                </div>

                <form class="form-border" [formGroup]="this.formSets[this.currentIndex].request" autocomplete="off">
                    <p><b>Note:</b> Only Extracts requested in the last 30 days will be displayed on 'Response Extracts List' screen. Any requests older than 30 days would be purged in the backend.</p>

                    <div class="form-row mt-3">
                        <div class="filter-form-control pr-4 typeahead-wrapper col-md-4 mb-2" *ngIf="isAdmin && !extResponseRequest">
                            <fdl-type-ahead label="Organization Name:" formControlName="OrganizationTitle" [collection]="organizationSuggestion"
                            optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onOrgSelect($event)">
                            </fdl-type-ahead>
                       
                            <div class="has-error">
                                <span *ngIf="!this.formSets[this.currentIndex].request.get('OrganizationTitle').valid 
                                            && this.formSets[this.currentIndex].request.get('OrganizationTitle').errors?.pattern 
                                            && (this.formSets[this.currentIndex].request.get('OrganizationTitle').touched || this.formSets[this.currentIndex].request.get('OrganizationTitle').dirty)">
                                    <i class="material-icons">warning</i> Please enter a valid Organization Name
                                </span>
                            </div>
                        </div>
                    </div>


                    <!-- <div class="form-row" *ngIf="canExtractResponsesByProject">
                      <div class="col-md-6 mb-2">
                        <label for="validationCustom01" class="asterisk">Extraction Source:</label>
                        <fieldset>
                          <div class="radio-group d-flex">
                            <div class="radio mr-3">
                              <input type="radio" id="formTemplate" name="extractionSource" value="Form Template" formControlName="extractionSource" (change)="onChangeExtractionSource()">
                              <label for="formTemplate"><span>Form Template</span></label>
                            </div>
                            <div class="radio">
                              <input type="radio" id="project" name="extractionSource" value="Project" formControlName="extractionSource" (change)="onChangeExtractionSource()">
                              <label for="project"><span>Project</span></label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div> -->
                    <div class="form-row" *ngIf="this.formSets[this.currentIndex].request.get('extractionSource').value === 'Form Template'">
                        <div class="filter-form-control pr-4 typeahead-wrapper col-md-4 mb-2">
                            <label for="formTitle" class="asterisk">Form Template Name:</label>
                            <fdl-type-ahead id="formTitle" formControlName="formTitle" [collection]="formsSuggestion" optionField="title"
                                placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onFormSelect($event)" *ngIf="!extResponseRequest">
                            </fdl-type-ahead>
                            <input type="text" [(ngModel)]="selectedFormName" class="form-control" id="formName" name="formName" formControlName="formName" *ngIf="extResponseRequest" disabled>
                        
                            <!-- <div class="has-error">
                                <span *ngIf="this.formSets[this.currentIndex].request.get('formName').errors?.pattern 
                                && (this.formSets[this.currentIndex].request.get('formName').touched || isSubmitted)">
                                    <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
                                </span>
                            </div> -->
                        </div>
                    </div>
                    <div class="form-row" *ngIf="this.formSets[this.currentIndex].request.get('extractionSource').value === 'Project'">
                      <div class="filter-form-control pr-4 typeahead-wrapper col-md-4 mb-2">
                          <label for="projectTitle" class="asterisk">Project Name:</label>
                          <fdl-type-ahead id="projectTitle" formControlName="projectTitle" [collection]="projectSuggestion" optionField="title"
                          placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onProjectSelect($event)" *ngIf="!extResponseRequest">
                          </fdl-type-ahead>
                          <input type="text" [(ngModel)]="selectedProjectName" class="form-control" id="projectName" name="projectName" formControlName="projectName" *ngIf="extResponseRequest" disabled>
                      </div>
                  </div>
                    <div class="form-row">
                        <div class="col-md-4 mb-2">
                            <label for="extractFileName" class="asterisk">Extract File Name:</label>
                            <input type="text" ngModel id="extractFileName" class="form-control" name="extractFileName" formControlName="extractFileName">
                            <div class="has-error">
                                <span *ngIf="(this.formSets[this.currentIndex].request.get('extractFileName').errors?.required ||
                                this.formSets[this.currentIndex].request.get('extractFileName').errors?.validateWhiteSpaces)
                                && (this.formSets[this.currentIndex].request.get('extractFileName').touched || isSubmitted)">
                                <i class="material-icons">warning</i> Please enter extraction template name.
                                </span>
                                <span *ngIf="this.formSets[this.currentIndex].request.get('extractFileName').errors?.pattern &&
                                (this.formSets[this.currentIndex].request.get('extractFileName').touched || isSubmitted)">
                                <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
                                </span>
                                <span *ngIf="this.formSets[this.currentIndex].request.get('extractFileName').errors?.maxlength &&
                                (this.formSets[this.currentIndex].request.get('extractFileName').touched || isSubmitted)">
                                <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.File.Alias}}, please correct it.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-4 mb-2">
                            <label for="extractType" class="asterisk">Extract Type:
                                <!-- <span data-toggle="tooltip" title="For project level extraction only two formats are available." class="material-icons btn-secondary f-18"> info</span> -->
                            </label>
                            <div class="fdl-select">
                                <select id="extractType" class="form-control" name="extractType" aria-invalid="false" formControlname="extractType" (change)="onExtractSelect($event)">
                                    <option value="">Please select</option>
                                    <option value="Pdf">Complete PDF</option>
                                    <option value="Json">Answer JSON</option>
                                    <!-- <option value="Excel">Excel</option>
                                    <option value="Word">Word</option> -->
                                    <optgroup label="Customized Template(s)" *ngIf="templateList && this.formSets[this.currentIndex].request.get('extractionSource').value === 'Form Template'">
                                        <option value="{{template.id}}-{{template.extractionType}}" *ngFor="let template of templateList"> {{ template.extractionType}} - {{ template.templateName }}</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="form-row">
                        <div class="col-md-4 mb-2">
                            <label for="scheduleExtract" class="asterisk">Schedule Extraction:</label>
                            <div class="radio-group d-flex">
                                <div class="radio mr-3">
                                <input type="radio" id="scheduleExtractYes" formControlName="scheduleExtract" [value]="true"/>   <label for="scheduleExtractYes"><span>Yes</span></label>
                                </div>
                                <div class="radio">
                                <input type="radio" id="scheduleExtractNo" formControlName="scheduleExtract" [value]="false"/>  <label for="scheduleExtractNo"><span>No</span></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row" *ngIf="this.formSets[this.currentIndex].request.get('scheduleExtract').value">
                        <div class="col-md-4 mb-2">
                          <label for="scheduledExtractionDate" class="asterisk">Scheduled Extraction Date:</label>
                          <input type="date" class="form-control" id="scheduledExtractionDate" [min]="todaysdate" formControlName="scheduledExtractionDate">
                            <div class="has-error">
                                <span *ngIf="this.formSets[this.currentIndex].request.get('scheduleExtract').value
                                && this.formSets[this.currentIndex].request.get('scheduledExtractionDate').value == ''
                                && (this.formSets[this.currentIndex].request.get('scheduledExtractionDate').touched || isSubmitted)">
                                <i class="material-icons">warning</i> Please select scheduled extraction date.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="form-row" *ngIf="this.formSets[this.currentIndex].request.get('scheduleExtract').value">
                        <div class="col-md-4 mb-2">
                            <label for="extractionDeliveryMethod" class="asterisk">Extraction Delivery Method:</label>
                            <div class="fdl-select">
                                <select id="extractionDeliveryMethod" class="form-control" name="extractionDeliveryMethod" aria-invalid="false"
                                formControlname="extractionDeliveryMethod" (change)="onExtractionDeliveryMethodSelect($event)">
                                    <option value="">Please select</option>
                                    <option value="email">Email</option>
                                </select>
                            </div>
                        </div>
                    </div> -->

                    <fieldset class="fieldset-container" *ngIf="isPDF">
                        <legend>
                            Select from below options to include in extracts:
                        </legend>
                        <div class="form-row mb-2">
                        <div class="col-md-4 checkbox" *ngIf="isPDF">
                            <input type="checkbox" class="ng-untouched ng-pristine ng-valid" value="false" id="includeCoverSheet" name="includeCoverSheet" formControlName="includeCoverSheet"/>
                            <label for="includeCoverSheet" class="input-label">
                                CoverSheet
                            </label>
                        </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="col-md-4 checkbox" *ngIf="isPDF && !(selectedTemplateId > 0)">
                                <input type="checkbox" class="ng-untouched ng-pristine ng-valid" value="false" id="includeComments" name="includeComments" formControlName="includeComments"/>
                                <label for="includeComments" class="input-label">
                                    Response Comments
                                </label>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="col-md-4 checkbox" *ngIf="isPDF && responseCount > 1">
                                <input type="checkbox" class="ng-untouched ng-pristine ng-valid" value="false" id="combinedPDF" name="combinedPDF" formControlName="combinedPDF"/>
                                <label for="combinedPDF" class="input-label">
                                    combined PDF for all selected response(s)
                                </label>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="fieldset-container mt-4" >
                        <legend>
                            Select Response(s)
                        </legend>
                        <!-- <div class="data-table-container" > -->
                            <form class="form-border mb-3 box collapse-view" [formGroup]="filterForm" autocomplete="off" *ngIf= "!extResponseRequest && isSearched">
                                <h2 class="h4  collapse-arrow" data-toggle="collapse" href="#collapseFilter">Advance Filter</h2>
                                <!-- <i>Please click on header to expand/collapse. User can provide more criteria(s) to narrow down response(s) list for extraction.</i> -->
                                <div class="filter-form-container collapse" id="collapseFilter">
                                    <div class="filter-form-control typeahead-wrapper pr-4">
                                        <label for="Status">ID:</label>
                                        <div class="">
                                            <input type="number" name="id" placeholder="Type to search" class="form-control" aria-invalid="false" formControlName="id">
                                        </div>
                                    </div>
                                    <div class="filter-form-control pr-4 typeahead-wrapper">
                                        <fdl-type-ahead label="Form Name:" formControlName="responseTitle" [collection]="responsesSuggestion" optionField="title"
                                        placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                                        </fdl-type-ahead>
                                    </div>
                                    <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showProjectFilter">
                                        <fdl-type-ahead label="Project Name:" formControlName="ProjectTitle" [collection]="projectSuggestion" optionField="title"
                                        placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                                        </fdl-type-ahead>
                                    </div>
                                    <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showClientFilter">
                                        <fdl-type-ahead label="Client Name:" formControlName="ClientTitle" [collection]="clientSuggestion" optionField="title"
                                        placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                                        </fdl-type-ahead>
                                    </div>
                                    <!-- <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showProductFilter">
                                        <fdl-type-ahead label="Product Name:" formControlName="ProductTitle" [collection]="productSuggestion" optionField="title"
                                        placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                                        </fdl-type-ahead>
                                    </div> -->
                                    <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="showAssignedToFilter">
                                        <fdl-type-ahead label="Respondent Name:" formControlName="AssignedTo" [collection]="userSuggestion" optionField="name"
                                        placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                                        </fdl-type-ahead>
                                    </div>
                                    <!-- <div class="filter-form-control pr-4">
                                        <label for="Status">Status:</label>
                                        <div class="fdl-select">
                                            <select name="Status" class="form-control" aria-invalid="false" formControlName="ResponseStatusTitle">
                                                <option value="">All</option>
                                                <option *ngFor="let status of formStatus" [value]="status.title">{{status.title}}</option>
                                            </select>
                                        </div>
                                    </div> -->
                                    <div>
                                        <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterList()">Search</button>
                                    </div>
                                </div>
                            </form>
                            <!-- <div class="headline-container collapseList">
                                <h2 class="collapse-arrow" data-toggle="collapse" href="#collapseList">Form Response List</h2>
                                <i>Please click on header to expand/collapse the responses list.</i>
                                <p *ngIf="pagerObject">Total {{pagerObject.totalCount}} Responses</p>
                            </div> -->
                            <p *ngIf="!isSearched">No record(s) found.</p>
                            <div class="table-responsive form-responces" id="collapseList" *ngIf="isSearched">
                                <p>Note: Extract would be availbale as .zip if multiple responses are selected.</p>
                                <app-data-table
                                *ngIf="responseList"
                                [rows]="responseList.forms"
                                [keys]="keys"
                                [showCheckbox]="!extResponseRequest"
                                [displayColumns]="displayColumns"
                                (sortingClicked)="applySorting($event)"
                                [showMenu]="false"
                                [(formSets)]="formSets"
                                [counter]="currentIndex"
                                (responsesCount)="updateResponseCount($event);"
                                >
                                </app-data-table>
                                <div>
                                    <app-pagination
                                        *ngIf="pagerObject && pagerObject.totalPages > 1 && responseList && responseList.forms.length > 0"
                                        [pagerObject]="pagerObject"
                                        (pagingChanged)="changePage($event)">
                                    </app-pagination>
                                </div>
                            </div>
                        <!-- </div> -->
                    </fieldset>

                    <div class="fdl-2 button-container">
                        <button type="button" aria-label="Default" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
                        <button type="button" aria-label="Default" class="btn fdl-btn btn-primary-orange float-right" (click)="Submit()">Submit</button>
                    </div>
                </form>


            </section>
        </div>
    </div>
</div>
