import { AbstractControl, ValidationErrors } from '@angular/forms';
import { REGEX_PATTERNS } from 'src/app/core/constants';


export class ClientValidator {
    static validateDunsIdFilter(control: AbstractControl) :  ValidationErrors | null {
        if (!control || !control.value) {
            return null;
        }
        const numberOnlyRegex = new RegExp(REGEX_PATTERNS.Numeric);
        const areCharactersValid = numberOnlyRegex.test(control.value.toString());
        if(!areCharactersValid){
            return { invalidCharacters: true };
        }

        const lengthRegex = new RegExp(REGEX_PATTERNS.filter_duns_id);
        const lessThan9Digits = lengthRegex.test(control.value.toString());
        if(!lessThan9Digits){
            return { invalidMaxium9Digits: true };
        }
        return null;
    }

    static validateGudIdFiler(control: AbstractControl) :  ValidationErrors | null {
        if (!control || !control.value) {
            return null;
        }
        const numberOnlyRegex = new RegExp(REGEX_PATTERNS.Numeric);
        const areCharactersValid = numberOnlyRegex.test(control.value.toString());
        if(!areCharactersValid){
            return { invalidCharacters: true };
        }

        const lengthRegex = new RegExp(REGEX_PATTERNS.filter_gud_id);
        const lessThan9Digits = lengthRegex.test(control.value.toString());
        if(!lessThan9Digits){
            return { invalidMaxium9Digits: true };
        }
        return null;
    }
}

