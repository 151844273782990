import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { IDashboardAnalyticsSummary } from '../shared/dashboardAnalyticsSummaryDTO';
import { ISuggestions } from '../shared/suggestion';
import { IForms } from '../shared/form';
import { CustomHttpParamEncoder } from '../shared/custom-encoder';
import { IFavorite } from "../shared/favorite";


@Injectable({
    providedIn: 'root'
})
export class FavoritesService {

    private favoritesEndpointAPIURL = "favorites";
    private favoritesProjectsAPIURL = 'favorites/projects';
    private favoritesResponsesAPIURL = 'favorites/responses';
  
   constructor(private http: HttpClient) {
    }

    getAllFavories() {
        return this.http.get(this.favoritesEndpointAPIURL);
    }
  
    getAllFavorieProjects(queryParams) {
        let params = new HttpParams({
            encoder: new CustomHttpParamEncoder()
          });
         for (let key of Object.keys(queryParams)) {
          let value = queryParams[key];
          params = params.append(key, value);
          }
        return this.http.get(this.favoritesProjectsAPIURL, { params: params, observe: 'response' });
    }

    getFavoriteProjects(projectId: number) {
        return this.http.get<IFavorite>(`${this.favoritesProjectsAPIURL}/${projectId}`);
    }

    isFavoriteProjects(projectId: number) {
        return this.http.get<boolean>(`${this.favoritesProjectsAPIURL}/${projectId}/isfavorite`);
    }

    saveFavoriteProject(projectId: number) {
        return this.http.post<any>(this.favoritesProjectsAPIURL + "/" + projectId , null)
    }

    removeFavoriteProject(projectId: number) : Observable<any> {
        return this.http.delete<any>(`${this.favoritesProjectsAPIURL}\\${projectId}`);
    }

    getAllFavorieResponses(queryParams) {
        let params = new HttpParams({
            encoder: new CustomHttpParamEncoder()
          });
         for (let key of Object.keys(queryParams)) {
          let value = queryParams[key];
          params = params.append(key, value);
          }
        return this.http.get(this.favoritesResponsesAPIURL, { params: params, observe: 'response' });
    }

    getResponsesFavorites(responseId: number) {
        return this.http.get<IFavorite>(`${this.favoritesResponsesAPIURL}/${responseId}`);
    }

    isResponsesFavorites(responseId: number) {
        return this.http.get<boolean>(`${this.favoritesResponsesAPIURL}/${responseId}/isfavorite`);
    }   

    saveFavoriteResponse(responseId: number) {
        return this.http.post<any>(this.favoritesResponsesAPIURL + "/" + responseId , null)
    }    

    removeFavoriteResponse(responseId: number) {
        return this.http.delete<any>(`${this.favoritesResponsesAPIURL}\\${responseId}`);
    }

}
