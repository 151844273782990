<div id="undoCommitments" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" style="display: none;"
    aria-hidden="true">
    <div role="document" class="modal-dialog undo-commitments-modal modal-lg mt-120" style="max-width: 550px;">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="myLargeModalLabel" class="modal-title">
                    Alert
                </h4>
                <button type="button" data-dismiss="modal" aria-label="Close popup" class="close"><span
                        aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body pt-2">
                <div class="row">
                    <div class="col-md-12 pb-3">
                        <p>
                            Are you sure you want to undo the review of the commitments and mark them as pending for
                            review under the auto-tagged commitments?
                        </p>
                    </div>
                </div>
                <div class="fdl-2 w-100 text-right mt-0">
                    <button type="button" data-dismiss="modal"
                        class="btn fdl-btn btn-secondary-light mr-2">Close</button>
                    <button data-dismiss="modal" id="save-button-form-container" type="button"
                        class="btn fdl-btn btn-primary-orange" (click)="onSubmit()">Undo</button>
                </div>
            </div>
        </div>
    </div>
</div>