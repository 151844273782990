import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatDateTime"
})

export class FormatDateTimePipe implements PipeTransform {
  transform(value: any): any {
    let hours = new Date(value).getHours();
    const minutes = new Date(value).getMinutes();
    let hoursString = hours.toString();
    let minutesString = minutes.toString();
    if (hours < 10) {
      hoursString = '0' + hoursString;
    }

    if (minutes < 10) {
      minutesString = '0' + minutesString;
    }

    let finalTime = hoursString + ':' + minutesString;
    return finalTime;
  }
}