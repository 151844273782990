import { IEmailTemplateDetails, IEmailTemplateVariable } from './../models/email-template';
import { environment } from 'src/environments/environment';
import { EmailTemplateService } from './../services/email-template.service';
import { ISuggestions, ISuggestion } from 'src/app/shared/suggestion';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, FormArray, FormControl } from '@angular/forms';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { Router } from '@angular/router';
import { EntityLevel, AdminEntityLevel, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE, WorkflowEntityLevel, WorkflowContextEntityLevel, AdminWorkflowEntityLevel } from 'src/app/core/constants';
import { EmailTemplateDTOService } from 'src/app/shared/email-templateDTO.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';
import { IQuery } from 'src/app/shared/query';

@Component({
  selector: 'app-email-template-create',
  templateUrl: './email-template-create.component.html',
  styleUrls: ['./email-template-create.component.css']
})
export class EmailTemplateCreateComponent implements OnInit, OnDestroy {

  constructor(
    private location: Location,
    private router: Router, private fb: FormBuilder,
    private projectService: ProjectsService, private permissionService: PermissionsService,
    private userService: UserService, private emailTemplateService: EmailTemplateService,
    private emailTemplateDTOService: EmailTemplateDTOService) { }

  filteredVariableNames: boolean = false;
  filterForm: FormGroup;
  queryParam: IQuery;
  sortingObject;
  currentPageNumber: number = 1;
  isEdit: boolean = false;
  isAdmin: boolean = false;
  subHeader: string;
  createEmailTemplate: FormGroup;
  organizationSuggestion: ISuggestions;
  eventSuggestion: ISuggestions;
  isError: boolean = false;
  errorMsg: string = "";
  isSubmitted: boolean = false;
  entityLevel: any;
  productSuggestion: ISuggestions;
  formSuggestion: ISuggestions;
  pageTemplateSuggestion: ISuggestions;
  emailTemplateSuggestion: ISuggestions;
  invalidProductName: boolean = false;
  invalidOrganizationName: boolean = false;
  invalidFormName: boolean = false;
  invalidPageTemplateName: boolean = false;
  invalidEventName: boolean = false;
  allSelected: boolean = false;
  organizationDetails: any;
  readOnlyEventName: any;
  readOnlyEntityName: any;
  emailTemplateData: IEmailTemplateDetails;
  systemVariables: IEmailTemplateVariable[];
  systemVariablesAux: IEmailTemplateVariable[];
  selectedEventName:any;
  selectedEventDescription:any;
  invalidToEmail=false;
  toEmailValidationMessage ='Please select or specify either "To Names" or "Additional To Names"';
  toEmailToolTipMessage ='Either "To Names" or "Additional To Names" is required';
  productText: string;

  //Rich text editor
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter email body here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  privacyPolicyConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter privacy policy here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  disclaimerConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Enter disclaimer here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  // To Names
  isDrawerType: boolean = false;
  showDrawer: boolean = false;
  showRoleDrawer: boolean = false;
  userFields: any;
  selectedToIds: number[] = [];
  selectedToForDisplay: any = []; //to show on UI
  isRoleFormSubmitted: boolean = false;  //For roleForm
  userFieldsForm: FormGroup;
  userFieldsCCForm: FormGroup;
  hideUserControl: boolean = false;
  @Input() drawerData: any;
  selectedEntityLevelValue: any;
  selectedValue: any;
  displayColumns = ['Variable Name', 'Description'];
  keys = ['emailVariableName', 'description'];
  actionName = "Copy";
  showCopyMsg: boolean = false;
  isCopy: boolean = false;
  selectedOrgId: number;
  copiedSysVar: string = '';
  returnPage: string;
  regexValidationMessage: any;

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.isEdit = this.emailTemplateDTOService._isEditMode;
    this.getStaticUserFields();
    this.subHeader = "Create New Email Template"
    if (this.isAdmin) {
      this.entityLevel = Object.values(AdminWorkflowEntityLevel).filter(value => typeof value !== 'number');
    }
    else if (!this.isAdmin && this.isEdit) {
      this.entityLevel = Object.values(WorkflowEntityLevel).filter(value => typeof value !== 'number');
    }
    else {
      this.entityLevel = Object.values(WorkflowContextEntityLevel).filter(value => typeof value !== 'number');
    }

    this.initForm();
    this.initFilterForm();
    this.getOrganizationSuggestions();
    this.getEventNameSuggestions();
    this.getEmailTemplatesSuggestion();
    this.getEmailTemplateVariables();

    if (this.isEdit) {
      this.emailTemplateData = this.emailTemplateDTOService.getemailTemplateData()
        ? this.emailTemplateDTOService.getemailTemplateData() : <IEmailTemplateDetails>{};
      this.organizationDetails = { "organizationTitle": this.emailTemplateData.organizationTitle };
      this.readOnlyEventName = { "displayName": this.emailTemplateData.template.displayName };
      this.readOnlyEntityName = this.emailTemplateData.entityName;
      if(this.emailTemplateData.productId > 0) this.productText = this.emailTemplateData.entityName;
      this.patchForm();
    }
    else {
      this.organizationDetails = Object.assign({}, this.userService.getUserListData());
      this.selectedEntityLevelValue = "";
      this.selectedOrgId = this.organizationDetails.organization;
    }

    this.getProductSuggestions();
    this.getFormSuggestions();
    this.getPageTemplateSuggestions();

    if (this.emailTemplateService.tempData)
      this.returnPage = 'Email Templates';
    else
      this.returnPage = 'Self Service';
  }

  copyVariable(event) {
    this.showCopyMsg = true;
    this.copiedSysVar = event.data.emailVariableValue;
    const el: any = document.getElementById('idCopiedSysVar');
    el.value = event.data.emailVariableValue;
    el.select();
    document.execCommand('copy');

  }

  crossModal() {
    this.showCopyMsg = false;
    this.filterForm.controls['VariableName'].setValue('');
    this.filteredVariableNames = false
    this.initQueryParams();
    this.initFilterForm();
    this.getEmailTemplateVariables();
  }
  private getEmailTemplateVariables() {
    this.emailTemplateService.getSystemVariables()
      .subscribe(data => {
        this.systemVariables = data;
        this.systemVariablesAux = data;
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  onCopyTemplateSelect = (event: any) => {

    let templateId = event.item.id;
    if (templateId > -1) {
      this.emailTemplateService.getEmailTemplateDetailsById(templateId).pipe(take(1))
        .subscribe(
          data => {
            this.emailTemplateData = data;
            this.copyEmailTemplate();
          },
          (error) => { this.isError = true; this.errorMsg = error; });
    }
  }

  onEntityProductSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidProductName = false;
    }
  }

  onEntityFormSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidFormName = false;
    }
  }

  onEntityPageTemplateSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidPageTemplateName = false;
    }
  }

  onOrganizationSelect(event: any) {
    this.selectedOrgId = event.item.id;
    this.getFormSuggestions();
    this.getProductSuggestions();
  }

  onEventSelect(event: any) {
    this.selectedEventDescription = event.item.description;
  }

  patchForm() {
    this.createEmailTemplate.patchValue({
      eventName: this.emailTemplateData.template.displayName,
      entityNameProduct: this.emailTemplateData.productId ? this.emailTemplateData.entityName : '',
      entityNameForm: this.emailTemplateData.formId ? this.emailTemplateData.entityName : '',
      templateName: this.emailTemplateData.template.emailTitle,
      subject: this.emailTemplateData.template.emailSubject,
      body: this.emailTemplateData.template.emailBody,
      additionalData: this.emailTemplateData.additionalData,
      disclaimer: this.emailTemplateData.disclaimer,
      privacyPolicy: this.emailTemplateData.privacyPolicy,
      additionalToEmail: this.emailTemplateData.template.additionalToEmails,
      additionalCCEmail: this.emailTemplateData.template.additionalCcEmails,
      organization: this.organizationDetails,
      isEnabled: this.emailTemplateData.template.isEnabled ? '1' : '0'
    })

    this.selectedEntityLevelValue = this.emailTemplateData.level;
    this.selectedEventDescription = this.emailTemplateData.template.description;
  }

  private getEmailTemplatesSuggestion() {
    this.emailTemplateService.getTemplateNameSuggestions('emailtemplates', '')
      .subscribe(suggestions => {
        this.emailTemplateSuggestion = suggestions;
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }
  private copyEmailTemplate() {
    this.createEmailTemplate.patchValue({
      subject: this.emailTemplateData.template.emailSubject,
      body: this.emailTemplateData.template.emailBody,
      additionalData: this.emailTemplateData.additionalData,
      disclaimer: this.emailTemplateData.disclaimer,
      privacyPolicy: this.emailTemplateData.privacyPolicy,
      additionalToEmail: this.emailTemplateData.template.additionalToEmails,
      additionalCCEmail: this.emailTemplateData.template.additionalCcEmails,
    });
    this.isCopy = true;
    this.SelectedListOfUserFieldForEdit();

  }
  private SelectedListOfUserFieldForEdit() {
    this.selectedToIds = [];
    this.SelectedCCIds = [];
    if(this.emailTemplateData.template.toEmail != ""){
      const toEmailFields = this.emailTemplateData.template.toEmail.split(', ');
      if (toEmailFields.length > 0) {
        toEmailFields.forEach(element => {
          const selectedRole = this.userFields.find(item => (item.fieldName.toLowerCase().indexOf(element.toLowerCase()) > -1));
          if (selectedRole)
            this.selectedToIds.push(selectedRole.id);
        });
        this.createSelectedToListForDisplay();
      }
    }

    if (this.emailTemplateData.template.ccEmail != "") {
      const ccEmailFields = this.emailTemplateData.template.ccEmail.split(', ');
      if (ccEmailFields.length > 0) {
        ccEmailFields.forEach(element => {
          const selectedRole = this.userFields.find(item => (item.fieldName.toLowerCase().indexOf(element.toLowerCase()) > -1));
          if (selectedRole)
            this.SelectedCCIds.push(selectedRole.id);
        });
      };
      this.selectedCCForDisplay = this.userFields.filter(
        data => this.SelectedCCIds.includes(data.id)
      );
    }
  }

  getProductSuggestions(){
		let suggestionSubscriber: any;
		let params = [];
		if (this.productText && this.productText.length > 2) {
			params.push({paramName: "title", value: this.productText});
			params.push({paramName: "orgId", value: this.selectedOrgId});
			suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
		}
		else {
			params.push({paramName: "orgId", value: this.selectedOrgId});
			suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
		}

		suggestionSubscriber.subscribe(
			data=> {
			this.productSuggestion = data;
			},
			error => { this.isError = true; this.errorMsg = error; }
		);
	}

	typeaheadLoadingProducts() {
		this.getProductSuggestions();
	}

  getFormSuggestions() {
    this.projectService.getSuggestions("forms", this.selectedOrgId, "orgId").subscribe(
      suggestions => this.formSuggestion = suggestions,
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  getPageTemplateSuggestions() {
    this.projectService.getSuggestions("pagetemplate", "").subscribe(
      suggestions => this.pageTemplateSuggestion = suggestions,
      error => { this.isError = true; this.errorMsg = error; }
    );
  }


  initForm() {
    this.createEmailTemplate = this.fb.group({
      organization: ['', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]],
      eventName: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator)]],
      entityLevel: ['', [Validators.required]],
      entityNameProduct: [''],
      entityNameForm: ['', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]],
      entityNamePageTemplate: ['', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]],
      templateName: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.email_template_name_validator)]],
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
      additionalData: [''],
      disclaimer: ['', [Validators.pattern(REGEX_PATTERNS.disclaimer_validator)]],
      privacyPolicy: ['', [Validators.pattern(REGEX_PATTERNS.disclaimer_validator)]],
      additionalToEmail: ['', [Validators.pattern(REGEX_PATTERNS.SemiColonSeperatedEmail)]],
      additionalCCEmail: ['', [Validators.pattern(REGEX_PATTERNS.SemiColonSeperatedEmail)]],
      copyFromTemplate: ['', [Validators.pattern(REGEX_PATTERNS.email_template_name_validator)]],
      isEnabled: ['1'],
      isCopyEnabled: ['0'],
      description: ['', [Validators.pattern(REGEX_PATTERNS.description_validator)]],
    });

    this.userFieldsForm = this.fb.group({userFields: this.fb.array([])});
    this.userFieldsCCForm = this.fb.group({userFields: this.fb.array([])});
  }
  isCopyEnabled: boolean;
  handleChange($event) {
    this.isCopyEnabled = parseInt(this.createEmailTemplate.value.isCopyEnabled) > 0 ? true : false;
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      VariableName: new FormControl(''),
      Description: new FormControl('')
    });
  }

  initQueryParams() {
    this.queryParam = Object.assign({},{
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  filterList(){
    this.initQueryParams();
    let filterValues = this.filterForm.value;

    if (filterValues.VariableName) {
      this.getEmailTemplateVariablesByVariableName(filterValues['VariableName']);
    }
    else {
      this.getEmailTemplateVariables();
    }
    this.filteredVariableNames = true;
  }

  clearFilterVariableNameList(){
    this.filteredVariableNames = false;
    this.initQueryParams()
    this.initFilterForm();
    this.getEmailTemplateVariables();
  }

  private getEmailTemplateVariablesByVariableName(variableName) {
    this.systemVariablesAux = this.systemVariables.filter(x => {
      return x.emailVariableName.includes(variableName);
    })
  }

  private getEmailTemplateVariablesByDescription(description) {
    this.systemVariablesAux = this.systemVariables.filter(x => {
      return x.description.includes(description);
    })
  }

  setOrderBy(sortingData){
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  getOrganizationSuggestions() {
    this.projectService.getSuggestions("organizations", "")
      .subscribe(suggestions => {
        this.organizationSuggestion = suggestions;
      },
        error => { this.isError = true; this.errorMsg = error; });
  }

  getEventNameSuggestions() {
    this.emailTemplateService.getEventNameSuggestions('emailevents', '')
      .subscribe(suggestions => {
        this.eventSuggestion = suggestions;
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  goBack() {
    this.emailTemplateService.tempData = "";
    if (this.isEdit) {
      this.router.navigate(['/self-service/emailTemplate/view', this.emailTemplateData.template.emailConfigDetailId]);
    }
    else {
      // this.router.navigate(['/self-service/emailTemplate/list']);
      this.location.back();
    }
  }

  resetEmailTemplateErrors() {
    this.invalidOrganizationName = false;
    this.invalidProductName = false;
    this.invalidFormName = false;
    this.invalidPageTemplateName = false;
    this.createEmailTemplate.controls['entityNameForm'].setValue("");
    this.createEmailTemplate.controls['entityNameProduct'].setValue("");
    this.createEmailTemplate.controls['entityNamePageTemplate'].setValue("");
    this.isError = false;
    this.errorMsg = '';
  }

  onEntityLevelSelect() {
    this.resetEmailTemplateErrors();
  }

  emailTemplateSubmit() {
    this.isSubmitted = true;
    let isSystem: boolean;
    let productId: any;
    let formId: any;
    let pageTemplateId: any;
    let eventId: any;
    let orgId: any;
    this.invalidOrganizationName = false;
    this.invalidEventName = false;
    this.invalidToEmail = false;
    this.invalidProductName = false;
    this.invalidFormName = false;
    isSystem = this.selectedEntityLevelValue != 'System' ? false : true;

    if (this.createEmailTemplate.valid) {

      if (!isSystem && this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue == 'Product') {
        let productName = this.createEmailTemplate.value.entityNameProduct;
        productId = this.projectService.fetchIdForRequest(this.productSuggestion, productName);
        if (productId < 1) {
          this.invalidProductName = true;
          this.errorMsg = "Invalid product name.";
          this.isError = true;
        }
      }

      if (!isSystem && this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue == 'Form') {
        let formName = this.createEmailTemplate.value.entityNameForm;
        formId = this.emailTemplateService.fetchIdForRequestByTitle(this.formSuggestion, formName);
        if (formId < 1) {
          this.invalidFormName = true;
          this.errorMsg = "Invalid form name.";
          this.isError = true;
        }
      }

      if (!isSystem && this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue == 'PageTemplate') {
        let pageTemplateName = this.createEmailTemplate.value.entityNamePageTemplate;
        pageTemplateId = this.emailTemplateService.fetchIdForRequestByTitle(this.pageTemplateSuggestion, pageTemplateName);
        if (pageTemplateId < 1) {
          this.invalidPageTemplateName = true;
          this.errorMsg = "Invalid page template name.";
          this.isError = true;
        }
      }

      let emailEventName = this.createEmailTemplate.value.eventName;
      eventId = this.emailTemplateService.fetchIdForRequest(this.eventSuggestion, emailEventName);
      if (eventId < 1) {
        this.invalidEventName = true;
        this.errorMsg = "Invalid event name.";
        this.isError = true;
      }

      if(this.selectedToForDisplay.length==0 && !this.createEmailTemplate.value.additionalToEmail){
        this.invalidToEmail = true;
        this.errorMsg = this.toEmailValidationMessage;
        this.isError = true;
      }

      if (this.invalidEventName || this.invalidProductName || this.invalidFormName || this.invalidPageTemplateName || this.invalidToEmail) {
        return;
      }

      let organizationName = this.createEmailTemplate.value.organization;
      if (this.isAdmin) {
        orgId = this.emailTemplateService.fetchIdForRequestByTitle(this.organizationSuggestion, organizationName);
      }
      else {
        orgId = this.userService.getUserListData() ? this.userService.getUserListData().organization : -1;
      }

      let selectUserFieldValues: string[] = [];
      this.selectedToForDisplay.forEach(element => {
        selectUserFieldValues.push(element.fieldName);
      });

      let selectUserFieldCCValues: string[] = [];
      this.selectedCCForDisplay.forEach(element => {
        selectUserFieldCCValues.push(element.fieldName);
      });

      let submitData = {
        "organizationId": this.selectedEntityLevelValue !== 'PageTemplate' && orgId > 0 ? orgId : null,
        "productId": this.selectedEntityLevelValue === 'Product' && productId > 0 ? productId : null,
        "formId": this.selectedEntityLevelValue === 'Form' && formId > 0 ? formId : null,
        "pageTemplateId": this.selectedEntityLevelValue === 'PageTemplate' && pageTemplateId > 0 ? pageTemplateId : null,
        "isPageTemplate": this.selectedEntityLevelValue === 'PageTemplate' && pageTemplateId > 0 ? true : false,
        "level": this.selectedEntityLevelValue,
        "disclaimer": this.createEmailTemplate.value.disclaimer,
        "privacyPolicy": this.createEmailTemplate.value.privacyPolicy,
        "css": null,
        "additionalData": this.createEmailTemplate.value.additionalData,
        "template": {
          "emailEventId": eventId ? eventId : null,
          "emailTitle": this.createEmailTemplate.value.templateName,
          "emailSubject": this.createEmailTemplate.value.subject,
          "emailBody": this.createEmailTemplate.value.body,
          "description": this.createEmailTemplate.value.description,
          "toEmail": selectUserFieldValues.join(', ').toString(),
          "ccEmail": selectUserFieldCCValues.join(', ').toString(),
          "additionalToEmails": this.createEmailTemplate.value.additionalToEmail,
          "additionalCcEmails": this.createEmailTemplate.value.additionalCCEmail,
          "isEnabled": parseInt(this.createEmailTemplate.value.isEnabled) > 0 ? true : false,
        }
      }
      if (this.isEdit) {
        this.updateEmailTemplate(submitData);
      }
      else {
        this.createTemplate(submitData);
      }
    }
  }

  updateEmailTemplate(data) {
    let emailConfigDetailId = this.emailTemplateData.template.emailConfigDetailId;
    this.emailTemplateService.updateEmailTemplate(emailConfigDetailId, data).subscribe(
      data => {
        this.emailTemplateDTOService._isemailTemplateUpdated = true;
        this.router.navigate(['/self-service/emailTemplate/view', emailConfigDetailId]);
      },
      error => {
        this.isError = true;
        this.errorMsg = error;
      }
    )
  }

  createTemplate(data) {
    let createdTemplateName = data.template.emailTitle;
    this.emailTemplateService.createEmailTemplate(data).subscribe(
      data => {
        this.emailTemplateDTOService._isemailTemplateCreated = true;
        this.emailTemplateDTOService._newCreatedemailTemplate = createdTemplateName;
        this.router.navigate(['/self-service/emailTemplate/list']);
      },
      error => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

  /********************** DRAWER CHANGES To Names START ******************************/
  openUserFieldsDrawer() {
    this.showRoleDrawer = true;
    if (this.isEdit) {
      this.addCheckboxes();
      this.createSelectedToListForDisplay();
      this.toggleDrawer();
    }
    else {
      this.getUserFields();
    }
  }

  toggleDrawer() {
    this.showDrawer = !this.showDrawer;
  }
  private addCheckboxes() {
    if (this.selectedToIds.length == 0 || this.isCopy || this.isEdit) {
      (this.userFieldsForm.controls.userFields as FormArray).clear();
      this.userFields.forEach((i, o) => {
        const control = this.fb.control(((this.isEdit || this.isCopy) && this.selectedToIds.includes(i.id)));
        (this.userFieldsForm.controls.userFields as FormArray).push(control);
      });
    }
  }

  getUserFields() {
    if (this.isEdit) {
      this.addCheckboxes();
      this.createSelectedToListForDisplay();
    }
    else if (this.isCopy) {
      this.isRoleFormSubmitted = false;
      this.addCheckboxes();
      this.createSelectedToListForDisplay()
      this.isCopy = false;
      this.showDrawer = true;
    }
    else {
      this.isRoleFormSubmitted = false;
      this.addCheckboxes();
      this.showDrawer = true;
    }
  }

  createSelectedToListForDisplay() {
    this.selectedToForDisplay = this.userFields.filter(
      data => this.selectedToIds.includes(data.id)
    );
  }

  removeRole(id) {
    const roleIndex = this.selectedToIds.indexOf(id);
    this.selectedToIds.splice(roleIndex, 1);

    const formArrayIndex = this.userFields.map(function (e) { return e.id; }).indexOf(id);
    this.userFieldsForm.controls.userFields['controls'][formArrayIndex].patchValue(false);
    this.createSelectedToListForDisplay();
  }

  drawerSubmit() {
    this.isRoleFormSubmitted = true;
    if (this.userFieldsForm.valid) {

      this.selectedToIds = this.userFieldsForm.value.userFields
        .map((v, i) => v ? this.userFields[i].id : null)
        .filter(v => v !== null);

      this.createSelectedToListForDisplay();
      this.toggleDrawer();
    }
  }

  drawerCancel() {
    if (this.selectedToIds.length === 0) {
      this.isRoleFormSubmitted = false;
      this.userFieldsForm.reset();
    }
    else {
      this.resetCheckboxes();
    }
    this.toggleDrawer();
  }

  resetCheckboxes() {
    this.userFieldsForm.reset();
    this.selectedToIds.forEach(element => {
      const formArrayIndex = this.userFields.map(function (e) { return e.id; }).indexOf(element);
      this.userFieldsForm.controls.userFields['controls'][formArrayIndex].patchValue(true);
    });
  }

  /********************** DRAWER CHANGES END ******************************/

  /********************** CC Name CHANGES ******************************/
  userFieldsCC;
  SelectedCCIds: number[] = [];
  selectedCCForDisplay = [];
  searchString: string = '';

  openUserFieldsCCDrawer() {
    this.showRoleDrawer = false;
    if (this.isEdit) {
      this.createSelectedCCListforDisplay();
      this.toggleDrawer();
    }
    else {
      this.getUserFieldsCC();
    }
  }

  private addCCCheckboxes() {
    if (this.SelectedCCIds.length == 0 || this.isCopy || this.isEdit) {
      (this.userFieldsCCForm.controls.userFields as FormArray).clear();
      this.userFields.forEach((i, o) => {
        const control = this.fb.control(((this.isEdit || this.isCopy) && this.SelectedCCIds.includes(i.id)));
        (this.userFieldsCCForm.controls.userFields as FormArray).push(control);
      });
    }
  }

  getUserFieldsCC(keepDrawerClose = false) {
        this.userFieldsCC = this.userFields;
        if (this.isEdit || this.isCopy) {
          this.createSelectedCCListforDisplay();
          this.isCopy
        }
        if (!this.isEdit && !keepDrawerClose)
        {
          this.showDrawer = true;
          this.addCCCheckboxes();
        }
      }

  drawerUserFieldsCCCancel() {
    this.toggleDrawer();
    this.resetSelection();
  }

  drawerUserFieldCCSubmit() {
    if (this.userFieldsCCForm.valid) {

      this.SelectedCCIds = this.userFieldsCCForm.value.userFields
        .map((v, i) => v ? this.userFields[i].id : null)
        .filter(v => v !== null);

      this.createSelectedCCListforDisplay();
      this.toggleDrawer();
    }
  }

  createSelectedCCListforDisplay() {
    this.selectedCCForDisplay = this.userFields.filter(
      data => this.SelectedCCIds.includes(data.id)
    );
  }

  removeUserFieldsCC(id) {
    const roleIndex = this.SelectedCCIds.indexOf(id);
    this.SelectedCCIds.splice(roleIndex, 1);

    const formArrayIndex = this.userFields.map(function (e) { return e.id; }).indexOf(id);
    this.userFieldsCCForm.controls.userFields['controls'][formArrayIndex].patchValue(false);
    this.createSelectedCCListforDisplay();
  }

  resetSelection() {
    this.userFieldsCCForm.reset();
    this.SelectedCCIds.forEach(element => {
      const formArrayIndex = this.userFields.map(function (e) { return e.id; }).indexOf(element);
      this.userFieldsCCForm.controls.userFields['controls'][formArrayIndex].patchValue(true);
    });
  }
  /********************** DRAWER CHANGES END ******************************/

  ngOnDestroy(): void {
    this.emailTemplateService.tempData = "";
    this.emailTemplateDTOService._isEditMode = false;
    this.emailTemplateDTOService.setemailTemplateData(<IEmailTemplateDetails>{});
  }

  getStaticUserFields() {
    this.emailTemplateService.getUserFields().subscribe(
      data => {
        this.userFields = data;
        if(this.isEdit)
        {
          this.SelectedListOfUserFieldForEdit();
          this.addCheckboxes();
          this.addCCCheckboxes();
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    )
  }

  isEntityNameValid(entityName) {
    return REGEX_PATTERNS.name_title_validator.test(entityName);
   }

}

//Atleast 1 checkbox selected validator
function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);
    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}
