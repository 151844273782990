<header>
    <nav role="navigation" class="navbar navbar-expand-md navbar-light">
      <a class="navbar-brand" href="javascript:void(0);" routerLink="/dashboard">
        <!--<img alt="i360" src="assets/images/i360_Logo_White.png">-->
        <img alt="fiserv" src="assets/images/Fiserv Logo.png">
      </a>

        <div id="inverseNavbarSupportedContent" style="margin-right: 0;">
            <ul class="navbar-nav navbar-main">
                <li class="nav-item dropdown show navigation">
                    <button type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler collapsed">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div id="navbarCollapse" class="navbar-collapse collapse">
                        <ul class="navbar-nav mr-auto"></ul>
                    </div>
                </li>
                <li class="nav-item dropdown right-side-dropdown-menu">
                    <div class="utilities-wrapper">
                        <div id="navbarCollapseForms" class="navbar-collapse">
                            <ul class="navbar-nav"></ul>
                        </div>
                    </div>
                    <div class="nav-item form-responses-width user-dropdown-container">
                        <a href="javascript:void(0);" id="loggedInUser" class="nav-link dropdown-toggle user-dropdown">
                            <img src="assets/images/thumbnail-150x150.png" alt="" class="thumbnail img-w28 mr-1">
                            <span class="uname">
                                {{loggedInUser}}
                            </span>
                            <i class="material-icons caret-custom">expand_more</i>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
    <div id="download-fileModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" class="modal full-width fade position-right">
        <div role="document" class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header white-modal-header">
                    <h4 id="myModalLabel" class="modal-title download-file-image">download-files <span class="badge rounded-pill bg-danger">2</span></h4>
                    <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup" class="close"><span aria-hidden="true">×</span></button>
                </div>
                <div class="modal-body">
                    <div class="container description-image">
                        <h5>New client created. Review pending.</h5>
                        <p class="description">
                            Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel orci vitae mi fermentum fermentum. Nunc et rutrum sem. Sed faucibus iaculis urna, ut facilisis ligula.
                        </p>
                    </div>
                    <div class="container description-image">
                        <h5>New client created. Review pending.</h5>
                        <p class="description">
                            Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel orci vitae mi fermentum fermentum. Nunc et rutrum sem. Sed faucibus iaculis urna, ut facilisis ligula.
                        </p>
                    </div>
                    <div class="container description-image">
                        <h5>New client created. Review pending.</h5>
                        <p class="description">
                            Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel orci vitae mi fermentum fermentum. Nunc et rutrum sem. Sed faucibus iaculis urna, ut facilisis ligula.
                        </p>
                    </div>
                    <a href="#">All download-files <span class="arrow-right-image small-image"></span></a>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="container-fluid download-file-container">
    <div class="col-sm-12 col-md-12 col-lg-12 download-file-box login-wrapper">
        <div class="download-file-box-content-area pt-5">
            <h1>Download File</h1>
            <p class="spacer mb-1">To download a file, either copy the file's URL or press the button to download the attachment directly.</p>
            <div class="row justify-content-center">
                <div class="col-md-12 mb-3 pt-2">
                    <input type="text" id="link" class="form-control text-cener" readonly="" value="{{apiUrl}}{{downloadUrl}}">
                </div>

                <div class="col-md-6 fdl-2 mb-2">
                    <button type="button" class="btn fdl-btn btn-primary-orange upload-button" (click)="download()"><span class="material-icon-download"></span>Download File</button>
                </div>
            </div>
            <p></p>

            <p>To get access, please <a href="#">contact us</a>.</p>
            <div class="buttons mt-3">
                <button id="next" type="submit" class="btn fdl-btn btn-primary-dark" form="localAccountForm" (click)="goBack()">Go Back</button>
            </div>
        </div>
    </div>
</div>
