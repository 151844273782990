<div class="modal fade bd-example-modal-md" id="editFormDetailforms" data-backdrop="static" data-keyboard="false"
  tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-md" role="document" style="max-width:700px">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
            <span>Edit Form Details</span>
          </h4>
      </div>
      <div class="modal-body"  style="max-height: 612px; overflow: auto;">
        <form [formGroup]="editFormDetails" autocomplete="off">
            <div class="row">
              <div class="col-md-12">
                  <label class="asterisk" for="formName">Form Name:</label>
                  <input type="text" class="form-control" formControlName="formName" id="formName">
              </div>
              <div class="has-error">
                <span class="pl-3" *ngIf="!editFormDetails.get('formName').valid && editFormDetails.get('formName').errors?.pattern && (editFormDetails.get('formName').touched || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}} </span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-6">
                <label class="asterisk" for="dueDate">Due Date:</label>
                <input type="date" onkeydown="return false" class="form-control" [min]="todaysdate" formControlName="dueDate" id="dueDate">
              </div>
            </div>
        </form>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal" (click)="handleCancelForm()">
          Cancel</button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" (click)="clickSaveForm()">
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
