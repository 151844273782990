import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { take } from 'rxjs/internal/operators/take';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { ISuggestions } from 'src/app/shared/suggestion';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MasterService } from 'src/app/services/master.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { ResponseExtractService } from 'src/app/services/response-extract.service';
import { IResponseExtract, IResponseExtractList } from 'src/app/shared/response-extract';
import { ExtractionStatuses } from 'src/app/core/constants';
import { PreferenceService } from 'src/app/services/preference.service';
declare var $: any;

@Component({
  selector: 'request-extracts',
  templateUrl: './request-extracts.component.html'
})
export class RequestExtractsComponent implements OnInit {
  isCreateExtractionAllowed: boolean = false;
  filterForm: FormGroup;
  queryParam: IQuery;
  currentPageNumber = 1;
  pagerObject: IPager;
  projectSuggestion : ISuggestions;
  formNameSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  clientSuggestion: ISuggestions;
  isError: boolean;
  successMsg: string;
  errorMsg: '';
  sortingObject;
  extractList: IResponseExtractList;
  displayColumns = ['Extract Name', 'Extraction Status', 'Requested User','Requested On'];
  keys = ['extractRequestName', 'status','createdByName', 'createdON'];
  extractionStatus: any;
  listname: string = PreferenceService.responseExtractList;
  filtered: boolean = false;

  @Input() asset: IResponseExtract;
	@ViewChild("linkInput") linkInput: ElementRef;
	showMsg: boolean = false;

  constructor(
    private router: Router,
    private projectService: ProjectsService,
    private responseExtractService: ResponseExtractService,
    private permission: PermissionsService,
    private masterService: MasterService) { }

  ngOnInit(): void {
  this.extractionStatus = Object.values(ExtractionStatuses).filter(value => typeof value !== 'number');
  this.isCreateExtractionAllowed = this.permission.showRequestExtractsLink();
  this.successMsg = this.responseExtractService.isExtractCreated;
  this.initFilterForm();
  this.initQueryParams();
  this.masterService.loadAndApplyFilters(this.queryParam, this.filterForm);
  // this.getClientSuggestions();
  // this.getProductSuggestions();
  // this.getFormSuggestions();
  // this.getProjectSuggestions();
  this.callExtractionListService();
  }
refresh() {
  this.callExtractionListService();
}
  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      projectName: new FormControl(''),
      formName: new FormControl(''),
      productName : new FormControl(''),
      clientName : new FormControl(''),
      extractionStatus: new FormControl('')
    });
  }

  getProjectSuggestions() {
    this.projectService.getSuggestions('projects','')
    .subscribe(suggestions => {
        this.projectSuggestion = suggestions;
    },
    (error) => {this.isError= true; this.errorMsg=error;});
  }

  getFormSuggestions() {
    this.projectService.getSuggestions('forms','')
    .subscribe(suggestions => {
    this.formNameSuggestion = suggestions;
    },
    (error) => { this.isError = true; this.errorMsg = error; });
  }

  getProductSuggestions() {
    this.projectService.getSuggestions('products', true, "ignoreFavorites")
    .subscribe(suggestions => {
    this.productSuggestion = suggestions;
    },
    (error) => { this.isError = true; this.errorMsg = error; });
  }

  getClientSuggestions() {
    this.projectService.getSuggestions('clients','')
    .subscribe(suggestions => {
        this.clientSuggestion = suggestions;
    },
    (error)=> { this.isError = true; this.errorMsg = error; });
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callExtractionListService();
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callExtractionListService();
  }

  setOrderBy(sortingData) {
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  navigate(event) {
    let assetId = event.data;
		let asset: IResponseExtract = this.extractList.requests.filter(
			item => item.id == assetId
		)[0];
    switch (event.action) {
      // case "copy":
      //   this.showMsg = false;
			// 	this.asset = asset;
			// 	$("#copyLink").modal("show");
      //   break;
      case "preview":
        window.open(asset.extractResponseFinalUrl, "_blank");
        break;
      case "download":
        window.open(asset.extractResponseFinalUrl, "_blank");
        break;
      case "viewResponseDetails":
        let extractData = {
          'id': asset.id,
          'extractRequestGuid': asset.extractRequestGuid,
          'extractRequestName': asset.extractRequestName,
          'status': asset.status,
          'extractResponseFinalUrl': asset.extractResponseFinalUrl
        }
        this.responseExtractService.setExtractDetails = extractData;
        this.router.navigate(['/self-service/requestExtracts/viewResponses/', asset.id]);
        break;
      case "delete":
        this.deleteExtract(asset);
        break;
      default:
        break;
    }
  }

  filterList(){
    this.initQueryParams();
    let filterValues = this.filterForm.value;

    if (filterValues['projectName'])
      this.queryParam.projectTitle = filterValues['projectName']
    if(filterValues['formName'])
      this.queryParam.formTitle = filterValues['formName']
    if (filterValues['productName'])
      this.queryParam.productTitle = filterValues['productName']
    if (filterValues['clientName'])
      this.queryParam.clientTitle = filterValues['clientName']
    if (filterValues['extractionStatus'] && filterValues['extractionStatus'] != 'All')
      this.queryParam.status = filterValues['extractionStatus']

    this.setOrderBy(this.sortingObject);
    this.callExtractionListService();
    this.filtered = true
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.callExtractionListService();
  }

  callExtractionListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.responseExtractService.getResponseExtractList (this.queryParam)
      .pipe(take(1))
      .subscribe(
        extractions => {
          this.isError = false;
          this.extractList = extractions.body as IResponseExtractList;
          if(extractions.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({},JSON.parse(extractions.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
      (error)=> {
        this.isError = true;
        this.errorMsg = error;
      });
  }

  RequestExtract() {
    this.responseExtractService.tempData = "ExtractionList";
    this.router.navigate(['/self-service/requestExtracts/extractionRequest']);
  }

  setSuccessMsg() {
    this.responseExtractService.isExtractCreated = "";
    this.successMsg = "";
  }

  deleteExtract(asset) {
    this.responseExtractService.deleteResponseExtract(asset.id)
    .subscribe(
      () => {
        this.callExtractionListService();
        this.successMsg = `The Form Extraction Template - '${asset.extractRequestName}' has been deleted.`;
      },
      error => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

  copyLink() {
		this.linkInput.nativeElement.select();
		document.execCommand("copy");
		this.showMsg = true;
	}
}
