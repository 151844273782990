<!-- Add class of "back-action-container" when having back button in place like below -->
<div class="heading-container fdl-2" *ngIf="!isDrawerType">
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons">
          arrow_back
          </span>
          Clients
      </a>
    </div>
      <h1 *ngIf="!isEditMode" class="pl-1">Create New Client</h1>
    <h1 *ngIf="isEditMode" class="pl-1">{{clientForm.get('name').value}}</h1>
  </div>
</div>

<div #createClientFormContainer class="form-container separate-form-container" [ngClass]="{'p-1': isDrawerType}">
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>

    <p *ngIf="!isEditMode">Please complete below required information to create a new client.</p>

    <div class="pt-2">
      <form [formGroup]="clientForm" autocomplete="off">

        <div class="row mb-2">
          <div class="intake-request-form intake-request-form-wrapper client-form-wrapper" style="flex: 0 0 100%; max-width: 100%;">
            <div class="box">
              <h3 class="h5 heading-level-3 mt-0 mb-2">General Information</h3>
              <div class="row mb-2 ">

                <!-- <div class="form-row" *ngIf="(isAdmin && isEditMode)"> -->
                  <div class="col-md-6 mb-3" *ngIf="(isAdmin && isEditMode)">
                      <label for="organizationTitle">Organization:</label>
                      <input type="text" name="text" formControlName="organizationReadOnly" readonly class="form-control" id="organizationTitle">
                  </div>
                <!-- </div> -->          

                <!-- <div class="form-row" *ngIf="(isAdmin && !isEditMode)"> -->
                  <div *ngIf="(isAdmin && !isEditMode)" class="col-md-6 mb-3 typeahead-wrapper">
                    <label for="organization">Organization:</label>
                    <fdl-type-ahead formControlName="organization" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
                    <div class="has-error">
                      <!-- <span *ngIf="!clientForm.get('organization').valid && clientForm.get('organization').errors?.required || isSubmitted">
                        <i class="material-icons">warning</i> Please select organization name. </span> -->
                      <span *ngIf="!clientForm.get('organization').valid && clientForm.get('organization').errors?.pattern && (clientForm.get('organization').dirty || isSubmitted)">
                        <i class="material-icons">warning</i>Please enter a valid Organization Name</span>
                    </div>
                  </div>
                  <!-- </div> -->
                  <div class="col-md-6 mb-3" *ngIf="(isEditMode && eCRMClient)">
                    <label for="gudIdValue">GUD ID:</label>
                    <input type="text" name="text" formControlName="gudReadOnly" readonly class="form-control" id="gudIdValue">
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(!eCRMClient)">
                    <label for="gud">GUD ID:</label>
                    <input class="form-control" type="text" formControlName="gud">
                    <div class="has-error">
                      <span *ngIf="!this.clientForm.get('gud').valid && this.clientForm.get('gud').errors?.pattern && (this.clientForm.get('gud').touched || isSubmitted)">
                        <i class="material-icons">warning</i> GUD ID must be {{_maxLength.Client.DunsId}} digits.
                      </span>
                    </div>
                  </div>
                </div>
              <div class="row mb-2 ">
                <!-- <div class="form-row"> -->
                  <div class="col-md-6 mb-3" *ngIf="(isEditMode && eCRMClient)">
                    <label for="clientTitle">Client Name:</label>
                    <input type="text" name="text" formControlName="clientReadOnly" readonly class="form-control" id="clientTitle">
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(!eCRMClient)">
                    <label for="name" class="asterisk">Client Name:</label>
                    <input type="text" class="form-control" formControlName="name" (blur)="getClientList()">
                    <div class="has-error">
                      <span *ngIf="!clientForm.get('name').valid && (clientForm.get('name').errors?.required || clientForm.get('name').errors?.validateWhiteSpaces) && (clientForm.get('name').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter the client name.
                      </span>
                      <span *ngIf="!clientForm.get('name').valid && clientForm.get('name').errors?.pattern && (clientForm.get('name').touched || isSubmitted)">
                        <i class="material-icons">warning</i> {{regexValidationMessage.CLIENT_NAME_VALIDATION_MESSAGE}}
                      </span>
                      <span *ngIf="!clientForm.get('name').valid && clientForm.get('name').errors?.maxlength && (clientForm.get('name').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Client.Name}}, please correct it.
                      </span>
                    </div>                                                                    
                    
                  </div>

                  <div class="col-md-6 mb-3" *ngIf="(isEditMode && eCRMClient)">
                    <label for="dunsValue">DUNS ID:</label>
                    <input type="text" name="text" formControlName="dunsReadOnly" readonly class="form-control" id="dunsValue">
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(!eCRMClient)">
                    <label for="dunsId" class="asterisk">DUNS ID:</label>
                    <input class="form-control" type="text" id="dunsId" name="dunsId" formControlName="dunsId">
                    <div class="has-error">
                      <span *ngIf="!this.clientForm.get('dunsId').valid && (this.clientForm.get('dunsId').errors?.required || this.clientForm.get('dunsId').errors?.validateWhiteSpaces) 
                          && (this.clientForm.get('dunsId').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter client DUNS ID.
                      </span>
                      <span *ngIf="!this.clientForm.get('dunsId').valid && this.clientForm.get('dunsId').errors?.pattern && (this.clientForm.get('dunsId').touched || isSubmitted)">
                        <i class="material-icons">warning</i> DUNS ID must be {{_maxLength.Client.DunsId}} digits.
                      </span>
                    </div>
                  </div>
                </div>
                <!-- </div> -->

                <!-- <div class="form-row"> -->
                <div class="row mb-2 ">
                  <div class="col-md-6">
                    <label for="description" class="asterisk">Description:</label>
                    <textarea class="form-control" name="description" formControlName="description"></textarea>
                    <div class="has-error">
                      <span *ngIf="!clientForm.get('description').valid && (clientForm.get('description').errors?.required || clientForm.get('description').errors?.validateWhiteSpaces) && (clientForm.get('description').touched || clientForm.get('description').dirty || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter a description.
                      </span>
                      <span *ngIf="!clientForm.get('description').valid && clientForm.get('description').errors?.pattern && (clientForm.get('description').touched || isSubmitted)">
                        <i class="material-icons">warning</i> {{regexValidationMessage.CLIENT_NAME_VALIDATION_MESSAGE}}
                      </span>
                      <span *ngIf="!clientForm.get('description').valid && clientForm.get('description').errors?.maxlength && (clientForm.get('description').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Client.Description}}, please correct it.
                      </span>
                    </div>
                  </div>
              </div>
              
              <h3 class="h6 heading-level-3 mt-0 mb-2">Address Information </h3>

              <div class="row mb-2">
                <!-- <div class="form-row mb-0 ml-0 mt-1"> -->
                  <div class="col-md-6 mb-3" *ngIf="(isEditMode && eCRMClient)">
                    <label for="addressLine1Value">Street Address 1:</label>
                    <input type="text" name="text" formControlName="addressLine1ReadOnly" readonly class="form-control" id="addressLine1Value">
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(!eCRMClient)">
                    <label for="addressLine1" class="asterisk">Street Address 1:</label>
                    <input type="text" class="form-control" formControlName="addressLine1">
                    <div class="has-error">
                      <span *ngIf="!clientForm.get('addressLine1').valid && clientForm.get('addressLine1').errors?.required && (clientForm.get('addressLine1').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter an address.
                      </span>
                      <span *ngIf="!clientForm.get('addressLine1').valid && clientForm.get('addressLine1').errors?.pattern && (clientForm.get('addressLine1').touched || isSubmitted)">
                        <i class="material-icons">warning</i> {{regexValidationMessage.STREET_VALIDATOR_VALIDATION_MESSAGE}}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(isEditMode && eCRMClient)">
                    <label for="addressLine2Value">Street Address 2:</label>
                    <input type="text" name="text" formControlName="addressLine2ReadOnly" readonly class="form-control" id="addressLine2Value">
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(!eCRMClient)">
                    <label for="addressLine2">Street Address 2:</label>
                    <input type="text" class="form-control" formControlName="addressLine2">
                    <div class="has-error">
                      <span *ngIf="!clientForm.get('addressLine2').valid && clientForm.get('addressLine2').errors?.pattern && (clientForm.get('addressLine2').touched || isSubmitted)">
                        <i class="material-icons">warning</i> {{regexValidationMessage.STREET_VALIDATOR_VALIDATION_MESSAGE}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6 mb-3" *ngIf="(isEditMode && eCRMClient)">
                    <label for="cityValue">City:</label>
                    <input type="text" name="text" formControlName="cityReadOnly" readonly class="form-control" id="cityValue">
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(!eCRMClient)">
                    <label for="city" class="asterisk">City:</label>
                    <input type="text" class="form-control" formControlName="city">
                    <div class="has-error">
                      <span *ngIf="!clientForm.get('city').valid && clientForm.get('city').errors?.required && (clientForm.get('city').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter a city.
                      </span>
                      <span *ngIf="!clientForm.get('city').valid && clientForm.get('city').errors?.pattern && (clientForm.get('city').touched || isSubmitted)">
                        <i class="material-icons">warning</i> {{regexValidationMessage.NAME_VALIDATION_MESSAGE}}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(isEditMode && eCRMClient)">
                    <label for="stateValue">State:</label>
                    <input type="text" name="text" formControlName="stateReadOnly" readonly class="form-control" id="stateValue">
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(!eCRMClient)">
                    <label for="state" class="asterisk">State:</label>
                    <input type="text" class="form-control" formControlName="state">
                    <div class="has-error">
                      <span *ngIf="!clientForm.get('state').valid && clientForm.get('state').errors?.required && (clientForm.get('state').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter a state.
                      </span>
                      <span *ngIf="!clientForm.get('state').valid && clientForm.get('state').errors?.pattern && (clientForm.get('state').pristine || clientForm.get('state').touched || isSubmitted)">
                        <i class="material-icons">warning</i> {{regexValidationMessage.NAME_VALIDATION_MESSAGE}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6 mb-3" *ngIf="(isEditMode && eCRMClient)">
                    <label for="zipCodeValue">Zip Code:</label>
                    <input type="text" name="text" formControlName="zipCodeReadOnly" readonly class="form-control" id="zipCodeValue">
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(!eCRMClient)">
                    <label for="zipCode" class="asterisk">Zip Code:</label>
                    <input type="text" class="form-control" formControlName="zipCode">
                    <div class="has-error">
                      <span *ngIf="!clientForm.get('zipCode').valid && clientForm.get('zipCode').errors?.required && (clientForm.get('zipCode').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter a zip code.
                      </span>
                      <span *ngIf="!clientForm.get('zipCode').valid && clientForm.get('zipCode').errors?.pattern && (clientForm.get('zipCode').touched || isSubmitted)">
                        <i class="material-icons">warning</i> {{regexValidationMessage.ZIPCODE_VALIDATION_MESSAGE}}
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(isEditMode && eCRMClient)">
                    <label for="countryValue">Country:</label>
                    <input type="text" name="text" formControlName="countryReadOnly" readonly class="form-control" id="countryValue">
                  </div>
                  <div class="col-md-6 mb-3" *ngIf="(!eCRMClient)">
                    <label for="country" class="asterisk">Country:</label>
                    <input type="text" class="form-control" formControlName="country">
                    <div class="has-error">
                      <span *ngIf="!clientForm.get('country').valid && clientForm.get('country').errors?.required && (clientForm.get('country').touched || isSubmitted)">
                        <i class="material-icons">warning</i> Please enter a country.
                      </span>
                      <span *ngIf="!clientForm.get('country').valid && clientForm.get('country').errors?.pattern && (clientForm.get('country').touched || isSubmitted)">
                        <i class="material-icons">warning</i> {{regexValidationMessage.NAME_VALIDATION_MESSAGE}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <!-- YOGESH> -->
                    <div class="col-md-6 mb-3">
                      <label for="logo">Logo: &nbsp;</label><small><i class="fa fa-exclamation-circle btn-secondary f-16" title="Only JPG and PNG image format support for logo, Image Size - 320px Wide and 70-100px High" aria-hidden="true"></i></small>
                      <div class="custom-file">
                        <label for="logoFileName" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="newLogoFile?.name"> {{ newLogoFile?.name
                        }}</label>
                        <input #inputLogoFile type="file" class="custom-file-input form-control" accept="image/*" multiple="false" (change)="onLogoChanged($event)">
                        <div class="has-error">
                          <span  *ngIf="logoErrMsg">
                            <i class="material-icons">warning</i> {{logoErrMsg}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 d-flex justify-content-start align-items-end" *ngIf="newLogoFile?.source || clientData?.logo">
                      <div>
                        <ng-container *ngIf="newLogoFile?.source else showClientLogo">
                          <img [src]="newLogoFile.source" style="max-width: 320px; max-height: 70px;">
                        </ng-container>
                        <ng-template #showClientLogo>
                          <img [src]="clientData.logo" style="max-width: 320px; max-height: 70px;">
                        </ng-template>  
                      </div>
                      <div>
                        <button tabindex="0" class="btn btn-sm btn-primary-dark ml-3" (click)="onRemoveLogo()">Remove</button>
                      </div>          
                    </div>
                  </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="intake-request-form intake-request-form-wrapper client-form-wrapper" style="flex: 0 0 100%; max-width: 100%;">
            <div class="box">
              <h3 class="h5 heading-level-3 mt-0 mb-2 d-flex justify-content-between">Client Contact Person Details
                <button tabindex="0" class="btn fdl-btn btn-primary-dark" (click)="addClientContact()">
                  <span class="material-icons"> add </span>Add
                </button>
              </h3>

              <div class="assign-modal-table">
                <app-data-table *ngIf="clientContactList" 
                                [rows]="clientContactList" 
                                [keys]="clientContactKeys"
                                [displayColumns]="clientContactHeaders"  
                                [enableSorting]="false"
                                [showCheckbox]="false"
                                [showView]="false"
                                [showEdit]="true"
                                [showDelete]="true"
                                (buttonClicked)="updateContact($event)"
                                (deleteBtnClicked)="updateContact($event)"
                                type="Client Contact"
                                tableId="clientContact"
                              >
                </app-data-table>
              </div>

            </div>
          </div>
        </div>

        <!-- <div class="row mb-2">
          <div class="intake-request-form intake-request-form-wrapper client-form-wrapper" style="flex: 0 0 100%; max-width: 100%;">
            <div class="box">
              <h3 class="h5 heading-level-3 mt-0 mb-2">ECRM</h3>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label for="searchTags">ECRM AccountId</label>           
                  <div class="form-row" *ngIf="clientData?.ecrm.length > 0">
                    <div class="col-md-12 mb-3">
                      <div class="tag-wrapper" style="">
                        <div class="filter-wrapper">
                          <div class="filter-tag" *ngFor="let ecrm of clientData?.ecrm">
                            <div class="tag" data-toggle="tooltip" data-placement="top" title="{{ecrm.subSystemClientRefrenceValue}}">
                              {{ecrm.subSystemClientRefrenceValue}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>               
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="intake-request-form intake-request-form-wrapper client-form-wrapper" style="flex: 0 0 100%; max-width: 100%;">
            <div class="box">
              <h3 class="h5 heading-level-3 mt-0 mb-2">Clarity</h3>
              <div class="row">
                  <div class="col-md-12 mb-3">
                    <label for="searchTags">Company Id</label>           
                    <div class="form-row" *ngIf="clientData?.clarity?.length > 0">
                      <div class="col-md-12 mb-3">
                        <div class="tag-wrapper" style="">
                          <div class="filter-wrapper">
                            <div class="filter-tag" *ngFor="let clarity of clientData?.clarity">
                              <div class="tag" data-toggle="tooltip" data-placement="top" title="{{clarity.subSystemClientRefrenceValue}}">
                                {{clarity.subSystemClientRefrenceValue}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>               
                  </div>
                </div>
            </div>
          </div>
        </div> -->

        <div class="row mb-2">
          <div class="intake-request-form intake-request-form-wrapper client-form-wrapper" style="flex: 0 0 100%; max-width: 100%;" *ngIf="isAdmin">
            <form [formGroup]="clientAlias" autocomplete="off">
              <div id="formSet" class="box">
                <h3 class="h5 heading-level-3 mt-0 mb-2">Client Alias</h3>
                <div class="form-row mt-3">
                  <div class="col-md-3 mb-2">
                    <label for="alias">Name</label>
                    <div class="custom-add-tag">
                      <button class="add-tag-btn" (click)="addClientAlias()">Add</button>
                      <input type="text" multiple="false" class="form-control" id="alias" formControlName="alias">
                      <div *ngIf="duplicateClientAlias" class="has-error">
                        <span>
                          <i class="material-icons">warning</i> You can't add duplicate alias.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row" *ngIf="clientAliasSuggestions.length > 0">
                  <div class="col-md-12 mb-3">
                    <div class="tag-wrapper">
                      <div class="filter-wrapper">
                        <div class="filter-tag" *ngFor="let alias of clientAliasSuggestions">
                          <div class="tag" data-toggle="tooltip" data-placement="top" title="{{alias.subSystemClientRefrenceValue}}">
                            {{alias.subSystemClientRefrenceValue}}
                            <a href="javascript:void(0)" (click)="removeAlias(alias.subSystemClientRefrenceValue)" aria-label="Close" class="tag-close">
                              <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="fdl-2 button-container">
          <button type="button" class="btn fdl-btn btn-secondary-light " aria-label="Cancel" (click)="goBack()">{{CancelText}}</button>
          <div>
            <button type="submit" class="btn fdl-btn btn-primary-orange" (click)="onSubmit()">Submit</button>
          </div>
        </div>
      </form>
    </div>
</div>

<!-- Starts Add Client Contact Drawer -->
<div *ngIf="addContactFlag">
  <div [ngClass]="isDrawerType ? 'dw-client-details' : ''" class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-5 fold open" tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region" >
    <div class="drawer-contents create-new-client-drawer col-xs-12 col-sm-12 col-md-12">
      <div class="alert alert-dismissible alert-danger" *ngIf="clientContactErrorMsg">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="clientContactErrorMsg=''">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="clientContactErrorMsg"></p>
      </div>

      <h3 class="d-flex justify-content-between"> Add Client Contact Person </h3>

      <p> Please complete below required information to add a new client contact person.</p>
      <div id="contactFormSet" class="box" *ngFor="let formSet of contactFormSets; let i=index; let first = first">
        <a *ngIf="!first" href="javascript:void(0);" class="close pull-right mt-3" aria-label="close" (click)="closeContactFormset(i);">&times;</a>
        <h5 class="h5 heading-level-3 mt-0">{{formSet.title}}</h5>

        <form [formGroup]="formSet.contacts" autocomplete="off">
          <div class="row">
            <div class="col-md-12 mb-3 mt-1">
              <label for="name_{{i}}" class="asterisk">Name</label>
              <input class="form-control" type="text" id="name_{{i}}" name="name_{{i}}" formControlName="name">
              <div class="has-error">
                <span *ngIf="!this.formSet.contacts.get('name').valid && this.formSet.contacts.get('name').errors?.required && (this.formSet.contacts.get('name').touched || isContactSubmitted)">
                  <i class="material-icons">warning</i> Please enter the contact name.
                </span>
                <span *ngIf="!this.formSet.contacts.get('name').valid && this.formSet.contacts.get('name').errors?.pattern && (this.formSet.contacts.get('name').touched || isContactSubmitted)">
                  <i class="material-icons">warning</i> {{regexValidationMessage.NAME_VALIDATION_MESSAGE}}
                </span>
                <span *ngIf="!this.formSet.contacts.get('name').valid && this.formSet.contacts.get('name').errors?.maxlength && (this.formSet.contacts.get('name').touched || isContactSubmitted)">
                  <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Client.ContactName}}, please correct it.
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="email_{{i}}" class="asterisk">Email</label>
              <input class="form-control" type="email" id="email_{{i}}" name="email_{{i}}" formControlName="email">
              <div class="has-error">
                <span *ngIf="!this.formSet.contacts.get('email').valid && this.formSet.contacts.get('email').errors?.required && (this.formSet.contacts.get('email').touched || isContactSubmitted)">
                  <i class="material-icons">warning</i> Please enter an email.
                </span>
                <span *ngIf="!this.formSet.contacts.get('email').valid && this.formSet.contacts.get('email').errors?.pattern && (this.formSet.contacts.get('email').touched || isContactSubmitted)">
                  <i class="material-icons">warning</i> {{regexValidationMessage.EMAIL_VALIDATION_MESSAGE}}
                </span>
                <span *ngIf="!this.formSet.contacts.get('email').valid && this.formSet.contacts.get('email').errors?.maxlength && (this.formSet.contacts.get('email').touched || isContactSubmitted)">
                  <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.User.Email}}, please correct it.
                </span>
              </div>
            </div>

            <div class="col-md-6">
              <label for="number_{{i}}">Phone</label>
              <input #inputPhoneNumber class="form-control" type="phone" (blur)="formatPhoneNumber()" id="number_{{i}}" name="number_{{i}}" formControlName="number"
                (keypress)="allowNumericDigitsKeypress($event)">
              <div class="has-error">
                <span *ngIf="!this.formSet.contacts.get('number').valid && this.formSet.contacts.get('number').errors?.pattern && (this.formSet.contacts.get('number').touched || isContactSubmitted)">
                  <i class="material-icons">warning</i> Phone Number must be 10 digits.
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex justify-content-end mt-2 mr-3">
        <button tabindex="0" class="btn fdl-btn btn-primary-dark" (click)="anotherContactSet()" *ngIf="!singleContactEdit">
          <span class="material-icons"> add </span>Add
        </button>
      </div>
      <div class="pt-3">
          <div class="fdl-2 button-container">
            <button class="btn fdl-btn btn-secondary-light" (click)="cancelContactForm()">Cancel</button>
            <div>
              <button *ngIf="singleContactEdit" type="button" class="btn fdl-btn btn-primary-orange float-right" (click)="submitContactForm()">Update</button>
              <button *ngIf="!singleContactEdit" type="button" class="btn fdl-btn btn-primary-orange float-right" (click)="submitContactForm()">Add</button>
            </div>
          </div>
      </div>
    </div>
  </div>
  <!-- Backdrop transparent background -->
  <div class="backdrop-space">
    <div class="backdrop"></div>
  </div>
</div>
<!-- Ends Add Client Contact Drawer -->

 <!-- View Client modal dialog -->
 <div *ngIf="clientListFlag">
  <div id="clientListDialog" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" class="modal show fade bd-example-modal-md" style="display: block;" aria-hidden="true">
    <div role="document" class="modal-dialog modal-lg" style="max-width: 1280px;">
      <div class="modal-content">
        <div class="modal-header">
          <h4 id="myLargeModalLabel" class="modal-title">
            <span>Client List</span>
          </h4>
          <!-- <button type="button" (click)="closeClientList()" aria-label="Close popup" class="close">
            <span aria-hidden="true">×</span>
          </button> -->
        </div>

        <div class="modal-body">
          <span>
            We have found below clients that already exists in System with this name. If you still want to create a new client, click on Continue button.
          </span>
        </div>

        <div class="modal-body" style="max-height: 300px; overflow: auto;">
            <div class="table-responsive client-list-table mh200 overflow-auto">
              <div class="assign-modal-table">
                <app-data-table 
                  *ngIf="clientList"
                  [rows]="clientList" 
                  [keys]="clientListKeys"
                  [displayColumns]="clientListHeaders" 
                  [enableSorting]="false"
                  [showCheckbox]="false"
                  [showMenu]="false"
                  [showViewButton]="true"
                  (buttonClicked)="viewClient($event)"
                >
                </app-data-table>
              </div>
            </div>
        </div>


        <div class="modal-footer fdl-2">
          <button type="button" class="btn fdl-btn btn-secondary-light" (click)="closeClientList()">
            <span>Continue</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Ends -->
