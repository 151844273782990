import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'longDateTimeFormat'
})
export class LongDateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any): any {
    const date = super.transform(value, 'yyyy-MM-dd');
    const time = super.transform(value, 'hh.mm.ss a');
    return `${date} at ${time}`;
  }
}