import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { take } from 'rxjs/internal/operators/take';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { ISuggestions } from 'src/app/shared/suggestion';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MasterService } from 'src/app/services/master.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { FormExtractionTemplateService } from 'src/app/services/form-extraction-template.service';
import { IFormExtraction, IFormExtractions } from 'src/app/shared/formExtraction';
import { UserService } from 'src/app/services/user.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
declare var $: any;

@Component({
  selector: 'form-extracts',
  templateUrl: './form-extracts.component.html'
})
export class FormExtractsComponent implements OnInit, OnDestroy {
  filterForm: FormGroup;
  queryParam: IQuery;
  currentPageNumber = 1;
  pagerObject: IPager;

  formExtractionList: IFormExtractions;
  organizationSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  formNameSuggestion: ISuggestions;

  isError: boolean = false;
  isSuccess: boolean = false;
  isAdmin: boolean = false;
  errorMsg: string = "";
  successMsg: string = "";
  sortingObject;
  displayColumns = ['Template Name', 'Form Name', 'Extraction Type', 'Created By','Created On'];
  keys = ['templateName', 'formTitle', 'extractionType', 'createdByName','createdON'];

  selectedOrgId: number;
  listname:string = PreferenceService.formExtractionTemplateList;
  filtered: boolean = false;
  regexValidationMessage: any;

  constructor(
    private router: Router,
    private projectService: ProjectsService,
    private formExtractionService: FormExtractionTemplateService,
    private masterService: MasterService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.isAdmin = this.userService.getUserListData().isAdmin;
    this.selectedOrgId = 0;
    this.initFilterForm();
    this.initQueryParams();
    this.masterService.loadAndApplyFilters(this.queryParam, this.filterForm);
    this.getOrganizationSuggestions();
    this.getProductSuggestions();
    this.getFormSuggestions();
    this.callExtractionListService();
    this.successMsg = this.formExtractionService.isTemplateCreated;
  }

  ngOnDestroy(): void {
    this.setSuccessMsg();
  }

  refresh() {
    this.callExtractionListService();
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      organizationName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]),
      productName: new FormControl(''),
      formName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
    });
  }

  getOrganizationSuggestions() {
    this.projectService.getSuggestions('organizations', '')
      .subscribe(suggestions =>{
        this.organizationSuggestion = suggestions;
      },
      error => { this.isError = true; this.errorMsg = error; });
  }

  getFormSuggestions() {
    this.projectService.getSuggestions('forms', this.selectedOrgId, "orgId")
    .subscribe(suggestions => {
    this.formNameSuggestion = suggestions;
    },
    (error) => { this.isError = true; this.errorMsg = error; });
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callExtractionListService();
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callExtractionListService();
  }

  setOrderBy(sortingData) {
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  navigate(event) {
    let Id = event.data;
		let form: IFormExtraction = this.formExtractionList.formExtractions.filter(
			item => item.id == Id
		)[0];
    switch (event.action) {
      case "view":
        this.router.navigate(['/self-service/formExtracts/view', event.data]);
        break;
      case "delete":
        this.deleteTemplate(Id, form.templateName);
				break;
      case "download":
        window.open(form.templateUrl, "_blank");
				break;
      default:
        break;
    }
  }

  filterList(){
    if (this.filterForm.invalid)
      return;
    this.initQueryParams();
    let filterValues = this.filterForm.value;
    if (filterValues['organizationName'])
    this.queryParam.organizationTitle = filterValues['organizationName']
    if (filterValues['productName'])
      this.queryParam.productTitle = filterValues['productName']
      if(filterValues['formName'])
      this.queryParam.formTitle = filterValues['formName']

    this.setOrderBy(this.sortingObject);
    this.callExtractionListService();

    if (filterValues['organizationName'] === "") {
      this.selectedOrgId = 0;
      this.getProductSuggestions();
      this.getFormSuggestions();
    }
    this.filtered = true
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.callExtractionListService();
  }

  callExtractionListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.formExtractionService.getFormExtractionsList (this.queryParam)
      .pipe(take(1))
      .subscribe(
        extractions => {
          this.isError = false;
          this.formExtractionList = extractions.body as IFormExtractions;
          if(extractions.headers.get('X-pagination')){
            this.pagerObject = Object.assign({},JSON.parse(extractions.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }

  deleteTemplate(id: number, title: string) {
		this.isSuccess = false;
		this.successMsg = "";
		this.formExtractionService.deleteFormTemplate(id).subscribe(
      () => {
        this.callExtractionListService();
				this.isSuccess = true;
				this.successMsg = `The Form Extraction Template - '${title}' has been deleted.`;
			},
			error => {
				this.isError = true;
				this.errorMsg = error;
			}
		);
	}

  uploadTemplate() {
    this.formExtractionService.tempData = "Template List";
    this.router.navigate(['/self-service/formExtracts/formExtractionTemplate']);
  }

  setSuccessMsg() {
    this.formExtractionService.isTemplateCreated = "";
  }

  onOrgSelect(event: any) {
    if(event.item.id > 0)
    {
      this.selectedOrgId = event.item.id;
      this.filterForm.get('productName').setValue("");
      this.filterForm.get('formName').setValue("");
      this.getProductSuggestions();
      this.getFormSuggestions();
    }
  }

  typeaheadLoadingProducts() {
    if(this.filterForm.valid){
      this.getProductSuggestions();
    }
  }

  getProductSuggestions(){
    let suggestionSubscriber: any;
    let params = [];
    let productTitle = this.filterForm.value.productName
    if(productTitle && productTitle.length > 2) {
      params.push({paramName: "title", value: productTitle});
      params.push({paramName: "orgId", value: this.selectedOrgId});
      suggestionSubscriber = this.projectService.getProductSuggestions('products', params, 100);
    }
    else {
      params.push({paramName: "orgId", value: this.selectedOrgId});
      suggestionSubscriber = this.projectService.getProductSuggestions('products', params, 100);
    }

    suggestionSubscriber.subscribe(
      data=> {
        this.productSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }
}
