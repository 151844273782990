import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DocumentService } from 'src/app/services/document.service';
import { take } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-document-download',
  templateUrl: './document-download.component.html',
  styleUrls: ['./document-download.component.css']
})
export class DocumentDownloadComponent implements OnInit {
  loggedInUser: string;
  routeSubscription: Subscription;
  getDocumentSubscription: Subscription;
  getFileSubscription: Subscription;
  documentGuid: any;
  folderId: number;
  isError: boolean;
  errorMsg: string;
  downloadUrl: string;
  apiUrl: string;

  constructor(private userService: UserService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.userService.callUserListData().subscribe(data => {
      this.loggedInUser = data.name;
    });

    this.routeSubscription = this.route.params
    .pipe(take(1))
    .subscribe((params) => {
      this.documentGuid = params['guid'];
      this.apiUrl = this.documentService.getbaseUrl();
      if (this.documentGuid){
        this.downloadUrl = this.documentService.generateFileDownloadApiUrl(this.documentGuid);
      }
      else {
        this.folderId = params['folderId'];
        this.downloadUrl = this.documentService.generateFolderDownloadApiUrl(this.folderId );
      }
    });
  }

  download(){
    if (this.documentGuid){
      this.getFileSubscription = this.documentService.downloadFile(this.documentGuid).subscribe(response => {
        this.openToDownload(response);
      }, error => {
        this.isError = true;
        this.errorMsg = error;
      });
    }
    else {
      this.getFileSubscription = this.documentService.downloadFolder(this.folderId).subscribe(response => {
        this.openToDownload(response);
      }, error => {
        this.isError = true;
        this.errorMsg = error;
      });
    }
  }

  private openToDownload(response: HttpResponse<Blob>){
    const contentDisposition = response.headers.get('content-disposition');
    const filename = contentDisposition.split(';')[1].split('=')[1].replace(/"/g, '');
    const data = response.body;
    data.arrayBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: data.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  }

  goBack(){
    window.location.href = "/";
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    this.getDocumentSubscription?.unsubscribe();
    this.getFileSubscription?.unsubscribe();
  }
}
