import { Component, OnDestroy, OnInit } from "@angular/core";
import { AdminEntityLevel, EntityLevel } from "src/app/core/constants";
import { PermissionsService } from "src/app/services/permissions.service";
import { Location } from "@angular/common";
import { ReportsService } from "src/app/services/report.services";

@Component({
  templateUrl: './report-create.component.html'
})
export class ReportCreateComponent implements OnInit, OnDestroy {

  isAdmin: boolean = false;
  isError: boolean = false;
  errorMsg: string = "";
  entities: (string | AdminEntityLevel | EntityLevel)[] = [];
  returnPage: string;

  constructor(
    private location: Location,
    private permissionService: PermissionsService,
    private reportService: ReportsService
  ){}

  ngOnInit(): void {
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.populateEntityLevels();

    if (this.reportService.tempData)
      this.returnPage = 'Reports';
    else
      this.returnPage = 'Self Service';
  }

  ngOnDestroy(): void {
    this.reportService.tempData = "";
  }
  
  populateEntityLevels(): void {
    if(this.isAdmin) {
      this.entities = Object.values(AdminEntityLevel).filter(value => typeof value !== 'number');
    } else {
    this.entities = Object.values(EntityLevel).filter(value => typeof value !== 'number');
    }
  }

  onError(event: any): void {
    this.isError = true;
    this.errorMsg = event;
  }

  goBack() {
    // this.router.navigate(['self-service/report']);
    this.reportService.tempData = "";
    this.location.back();
  }

}
