

  <div [formGroup]="formControlData">

    <div formGroupName="{{elementName}}">
      <div formArrayName="{{elementName}}">
        <div *ngFor="let element of elements().controls; let elementIndex=index" [ngClass]="{ 'd-flex': overrideRightMargin }">
          <div [formGroupName]="elementIndex" class="row " [ngClass]="{'has-error': (duplicated.includes(elementIndex) && duplicated.length >= 2), 'override-right-margin': overrideRightMargin, 'flex-fill': overrideRightMargin }">
            <div class=" typeahead-container mb-1 mr-sm-0" style="width:{{cssWidth}}" >
              <div class="child">
                <div *ngFor="let fields of filedDetails" class=" {{fields.className}}">

                  <div *ngIf="fields.type!='emailshow' && fields.type!='fdlid' " [ngClass]="{'has-error':  !element.get(fields.name).valid &&  (!element.get(fields.name).pristine || isSubmitted) }">

                    <label for="element" [ngClass]="{'asterisk':fields.isRequired,'visibility-hidden':!(elementIndex==0 && fields.label),'invisible-text':!(elementIndex==0 && fields.label)}">{{fields.label}}:

                    <span *ngIf="fields.tooltip && elementIndex==0" [ngClass]="fields.tooltip.class" data-toggle="tooltip"
                      [title]="fields.tooltip.title"> {{fields.tooltip.icon}}</span>
                    </label>

                    <fdl-type-ahead (ngModelChange)="onRespondentChange($event, fields.name, elementIndex)" *ngIf="(fields.type=='fdl' &&  !(isDisable &&  (element.get('isDisable').value=='true'))) " [collection]="fdlData.data" formControlName="{{fields.name}}"
                                    optionField="name" placeholder="Type to search" (onSelect)="selectRespondent($event, fields.name, elementIndex)" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>

                    <input [attr.disabled]="((fields.isDisabled && fields.isDisabled=='yes') || (isDisable && element.get('isDisable').value=='true'))?true:null" *ngIf="(fields.type!='fdl' || (isDisable &&  (element.get('isDisable').value=='true')))" type="{{fields.type}}" class="form-control" formControlName="{{fields.name}}" />

                    <span *ngIf="(!element.get(fields.name).valid &&  (!element.get(fields.name).pristine || isSubmitted))">
                      <i class="material-icons">warning</i> {{fields.erroeMessage.valid}}
                    </span>

                    <!--(onSelect)="selectRespondent($event,i,responderIndex)" -->

                    <span *ngIf="fields.type=='fdl' && fields.showEmailAsLabel!='no' && element.get(fields.emailField).value!='' && element.get(fields.name).value!=''" style="display: block;padding-top: 5px;color: #4b4a4a;padding-bottom: 5px;padding-left: 10px;font-weight: 500;font-size: 13px;">
                      {{ element.get(fields.emailField).value}}
                    </span>
                    <span *ngIf="(fields.type=='fdl' && fields.showEmailAsLabel!='no' && element.get(fields.emailField).value=='') || fields.type!='fdl'  " style="display: block;padding-top: 5px;color: #4b4a4a;padding-bottom: 5px;padding-left: 10px;font-weight: 500;font-size: 13px;">
                      &nbsp;
                    </span>
                  </div>
                </div>
                <!-- START | PBI-168948 -->
                <ng-container *ngFor="let button of extraButtons; let buttonIndex = index;">
                  <div *ngIf="isPositionForced(button) && button.forceAt == elementIndex" _ngcontent-naq-c135="" class="pr-2 action-link" [ngClass]="{ 'mt-4': (elementIndex == 0 && extraButtons.length == 1), 'd-none': !(button.visible) }">
                    <a _ngcontent-naq-c135="" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" [title]="button.tooltip">
                      <span class="material-icons typeahead-container-icon" style="display:inline !important" (click)="onExtraButtonClick(button.action, elementIndex)">
                        {{ button.icon }}
                      </span>
                    </a>
                  </div>
                  <div *ngIf="!isPositionForced(button)" _ngcontent-naq-c135="" class="pr-2 action-link" [ngClass]="{ 'mt-4': (elementIndex == 0 && extraButtons.length == 1), 'd-none': !(button.visible) }">
                    <a _ngcontent-naq-c135="" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" [title]="button.tooltip">
                      <span class="material-icons typeahead-container-icon" style="display:inline !important" (click)="onExtraButtonClick(button.action, elementIndex)">
                        {{ button.icon }}
                      </span>
                    </a>
                  </div>
                </ng-container>
                <!-- END | PBI-168948-->
                <div _ngcontent-naq-c135="" class="pr-2 action-link"  [ngClass]="{'d-none':!(elementIndex!=0 && !(isDisable && element.get('isDisable').value=='true') )}">
                  <a _ngcontent-naq-c135="" (click)="removeSkill(elementIndex)" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Delete">

                    <span class="material-icons typeahead-container-icon" style="display:inline !important">
                      highlight_off
                    </span>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 mb-2" *ngIf="isAddNewUserButtonEnable">
      <div class="col-md-3">
          <button (click)="onExtraButtonClick('addUser', 0)" tabindex="0" style="width: 171px;" class=" btn fdl-btn btn-primary-dark formio-button-add-row">
              <span class="material-icons">
                  add
              </span>
              {{addUserButtonText}}
          </button>
      </div>
  </div>

  <div class="row mt-2 mb-2" *ngIf="isAddButtonEnable">
      <div class="col-md-3">
          <button (click)="addElements()" tabindex="0"   class=" btn fdl-btn btn-primary-dark formio-button-add-row">
              <span class="material-icons">
                  add
              </span>
              {{buttonText}}
          </button>
      </div>
  </div>
</div>
