import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Console } from 'console';
import { take } from 'rxjs/operators';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RolesService } from 'src/app/services/roles.service';

@Component({
  selector: 'app-role-view',
  templateUrl: './role-view.component.html',
  styleUrls: ['./role-view.component.css']
})
export class RoleViewComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private router: Router,
    private permission: PermissionsService,
  ) { }

  showUpdationMessage: boolean = false;
  showEditButton: boolean = false;
  roleId: number

  selectedRole:any = {};
  canbeAssignedToClientAux: string = '';
  roleType: string = '';
  tableData: any;

  keys = ['description']
  displayColumns = ['Permission(s)']

  ngOnInit(): void {
    this.rolesService.isCloneMode = false;
    this.rolesService.isEditMode = false;
    this.showEditButton = this.permission.isCreateUserAllowed();
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.roleId = params['id'];
        if (this.roleId > -1) {
          this.getRoleData();
        }
      });
  }

  ngOnDestroy(): void {
    this.rolesService._roleUpdated = false;
  }


  getRoleData(){
    this.rolesService.getRoleById(this.roleId).pipe(take(1)).subscribe(role => {
      this.selectedRole = role;
      this.setSelectedRole(this.selectedRole);
      this.setSelectedRoleType(this.selectedRole.roleType)
      this.setPermissionsTableData(this.selectedRole)
    });
  }

  setSelectedRole(data: any){
      if(data.canbeAssignedToClient){
        this.canbeAssignedToClientAux = "Yes";
      } else {
        this.canbeAssignedToClientAux = "No";
      }
  }

  setSelectedRoleType(roleType: any){
      this.roleType = roleType == 1 ? "Fiserv" : roleType == 2 ? "Client" : "NA"
  }

  setPermissionsTableData(data: any){
    const list = data.permissionDisplay.map( i => i.groupName);
    const uniqueList = Array.from(new Set(list));
    const groups = uniqueList.map(c => { return  { groupName:c, permissions:[]} })
    data.permissionDisplay.forEach(d => { groups.find(g => g.groupName === d.groupName).permissions.push({description: d.displayName})})
    this.tableData = groups
    this.tableData.forEach(permission => {
      if(permission.groupName === "Forms") {
        permission.permissions.forEach(p => {
          if(p.description === "Read Write Products Assigned"){
            p.description = "Read Write Forms Of Assigned Products";
          }
          if(p.description === "Read Products Assigned"){
            p.description = "Read Forms Of Assigned Products";
          }
        });
      }
    });
    console.log("this.tableData", this.tableData);
  }

  goToEdit(){
    this.rolesService.isCloneMode = false;
    this.rolesService.isEditMode = true;
    this.router.navigate([`admin/role/edit/${this.selectedRole.id}`]);
  }

  goBack() {
    this.router.navigate(['admin/role']);
  }

}
