<div class="admin-container self-service-container main-container-sub responsive-container col-settings-drawer-alignment">
    <div class="row m-0 height-100">
    <!-- START Side Bar  -->
        <aside class="side-navbar">
            <div class="main">
                <self-service-side-bar></self-service-side-bar>
            </div>
        </aside>
     <!-- ENDS Side Bar -->
         
     <!-- START Self Service Right Section -->
        <section class="right-section">
            <router-outlet></router-outlet>
        </section>
    <!-- ENDS Self Service Right Section -->
    </div>
</div>