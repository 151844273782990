import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
@Injectable()
export class PhonePipe implements PipeTransform {
    transform(phn: any, ...args: any[]) {
        if (phn == null || phn.length === 0) {
            return "";
        }
        
        var value = phn.toString().trim().replace(/[+-./()]/g, '');

        if (value.indexOf("@") != '-1') {
            return phn;
        }

        var country, city, number;
        if (value.length === 10) {
            country = 1;
            city = value.slice(0, 3);
            number = value.slice(3);
        }
        else {
            return phn;
        }

        if (country === 1) {
            country = "";
        }

        number = number.slice(0, 3) + '-' + number.slice(3);
        return ( country + "(" +city + ")" + " " + number).trim();
    }

}

