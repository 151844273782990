import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IReports, IReport} from '../shared/report';
import { ISuggestions } from '../shared/suggestion';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    private reportAPIURL = 'reports';
    //private ExportreportAPIURL = 'reports/ExportReports';
    private managersAPIURL = 'managers';
    private addressAPIURL = 'addresses';
    private _tempData: string = "";
    private _isReportCreated: string = '';
    private _isReportSaved: string = '';

    constructor(private http: HttpClient) {
  }

  get tempData(){
    return this._tempData;
  }

  set tempData(msg: string){
    this._tempData = msg;
  }

  get isReportCreated(){
    return this._isReportCreated;
  }

  set isReportCreated(msg: string){
    this._isReportCreated = msg;
  }

  get isReportSaved(){
    return this._isReportSaved;
  }

  set isReportSaved(msg: string){
    this._isReportSaved = msg;
  }
  getSuggestions(entity, titleValue, paramName = 'title') {
    const suggestionAPIURL = `${entity}/suggestions?${paramName}=${encodeURIComponent(titleValue)}`;
    return this.http.get<ISuggestions>(suggestionAPIURL);
}

  //exportClients(queryParams) {
  //  let params = new HttpParams();

  //  for (let key of Object.keys(queryParams)) {
  //    let value = queryParams[key];
  //    params = params.append(key, value);
  //  }
  //  return this.http.get(this.ExportclientAPIURL, { params: params, observe: 'response', responseType: 'blob'  });
  //}

    getReportSuggestions(){
      return this.http.get(this.reportAPIURL + "/suggestions");
    }

    getReports(queryParams){
        let params = new HttpParams();

        for (let key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }
        return this.http.get(this.reportAPIURL, { params: params, observe: 'response' });
    }

    getReportDetailsById(reportId: number) {
        return this.http.get<IReports>(this.reportAPIURL + "/" + reportId);
    }

    createReport(data){
        return this.http.post<IReport>(this.reportAPIURL + "/CreateReport", data);
    }

    getReportDataById(id:number){
        return this.http.get<IReport>(`${this.reportAPIURL}/${id}`);
    }

    updateReport( data){
        return this.http.put<IReport>(`${this.reportAPIURL}/${data.id}`, data);
    }

    deleteReport(reportId: number): Observable<IReport> {
        return this.http.delete<IReport>(`${this.reportAPIURL}\\${reportId}`);
    }
}
