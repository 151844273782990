import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CustomComponentsService } from 'src/app/services/custom-components.service';

@Component({
  selector: 'app-typeahead-select',
  templateUrl: './typeahead-select.component.html',
  styleUrls: ['./typeahead-select.component.css']
})
export class TypeaheadSelectComponent implements OnInit {

  collections: any = [];
  selected = '';
  private _apiUrl = '';

  @Input()
  public set apiUrl(value: string | undefined | null) {
    if (value !== undefined && value !== null && value.trim().length > 0) {
      const splitedData = value.split('/api/');
      if (splitedData.length === 1) {
        this._apiUrl = value;
      } else {
        this._apiUrl = splitedData[1];
      }
    }
  }

  @Input()
  public optionField = '';

  constructor(private customComponentsService: CustomComponentsService, private currentRef: ElementRef) { }

  ngOnInit(): void {
  }

  public onModelChange() {
    if (this.selected === "") {
      this.collections = [];
      return;
    }

    if (this.selected.trim().length <= 3) {
      return;
    }

    let url = this._apiUrl;
    if (this._apiUrl.includes('?')) {
      url = this._apiUrl + this.selected;
    }

    const currentInput = this.currentRef.nativeElement.getElementsByClassName("fdl-ng-typeahead")[0];
    this.customComponentsService.getApiData(url).subscribe(response => {
      this.collections = response;
      if (!this.collections.some(item => item[this.optionField] === this.selected)) {
        currentInput.click();
      }
    });
  }
}
