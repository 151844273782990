<section class="status-bar col-md-12">
    <div class="section-container ">
        <div class="left-section">
            <h1 class="h1">
                Dashboard
            </h1>
            <!-- Popover -->
            <div class="filter-container">
                <a id="cartPopover" class="btn cart-popover" (click)="togglePopoverContainer()">
                                            <i class="material-icons" [title]="filterTooltip">filter_list</i>
                                        </a>

                <div id="dashboard-filter" class="filter-popover-container filter-popover-container-project" *ngIf="isDashboardFilterPopover"
                    [style.display]="isDashboardFilterPopover ? 'block' : 'none'">
                    <form class="form-group row mb-0" [formGroup]="filterProjects" autocomplete="off">
                        <a href="javascript:void(0)" id="filterClose" aria-label="Close" class="filter-close"><i class="material-icons" tabindex="0" aria-hidden="true" (click)='togglePopoverContainer()'>close</i></a>
                        <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper" *ngIf="showProjectFilter">
                            <fdl-type-ahead label="Project:" formControlName="title" [collection]="projectSuggestion" optionField="title" placeholder="Type to search"
                                [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>

                        <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper" *ngIf="isClientFilterAllowed">
                            <fdl-type-ahead label="Client:" formControlName="clientTitle" [collection]="clientSuggestion" optionField="title" placeholder="Type to search"
                                [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>

                        <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper" *ngIf="showProductFilter">
                            <fdl-type-ahead label="Product:" formControlName="productTitle" [collection]="productSuggestion" optionField="title" placeholder="Type to search"
                                [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>

                        <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper">
                            <fdl-type-ahead label="Project Manager:" formControlName="fiservImplementationManagerName" [collection]="assignedToSuggestion"
                                optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>

                        <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper" *ngIf="isAdmin">
                            <fdl-type-ahead label="Organization:" formControlName="organizationTitle" [collection]="organizationSuggestion"
                                optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>

                        <div class="col-lg-12">
                            <div class="fdl-2 mt-3 ">
                                <button type="button" class="btn font-weight-normal btn-primary-blue" aria-label="Filter" (click)="filterClickHandler()">Filter</button>
                                <button type="button" class="btn btn-link ml-1" aria-label="Default" (click)="resetClickHandler()">Reset</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Popover -->
        </div>
        <div class="right-section">
            <div class="card-switcher">
                <span class="switch" [ngClass]="{'active': switchVal === 'project'}" (click)="switchVal='project'; activate('project')">Projects</span>
                <span #formSwitch class="switch" [ngClass]="{'active': switchVal === 'form'}" (click)="switchVal='form'; activate('form')">Forms</span>
            </div>
            <ul class="status-container" *ngIf="filteredProjects">

                <li class="staus-block" [ngClass]="{'selected': statusBlockClicked === counter.key}" *ngFor="let counter of filteredProjects.counters; let i = index"
                    (click)="statusBlockClicked = counter.key; filterOnStatusBlock(counter.key);">
                    <span class="text-white">{{counter.value}}</span>
                    <span class="status">{{counter.key}}</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">

            <div class="filte-wrapper">
                <div class="filter-tag" *ngFor="let tag of tagsArray; let i = index">
                    <div class="tag" data-toggle="tooltip" data-placement="top" title="{{tag.value}}">
                        {{tag.value}}
                        <a href="javascript:void(0)" aria-label="Close" class="tag-close" (click)="removeTagClickHandler(tag.key)"><i class="material-icons" tabindex="0" aria-hidden="true">close</i></a>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="main-container pb-0">
    <div class="no-recoards-found" *ngIf="filteredProjects && filteredProjects.projects.length === 0">
        <span>There are no Projects Found.</span>
    </div>
    <div class="row card-container" *ngIf="filteredProjects">
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6" *ngFor="let project of filteredProjects.projects; let i = index">
            <section role="region" aria-labelledby="card3Title1" class="cards-3">
                <span class="badge mb-2" [ngClass]="calculateClasses(project.statusTitle)">{{populateStatusOfProject(project)}}</span>
                <h4 id="card3Title1" class="text-truncate" data-toggle="tooltip" data-placement="top" title="{{project.title}}">{{project.title}}</h4>
                <!--<h5 class="fw-600 mb-2 text-truncate" [ngClass]="{'invisible': !project.clientTitle}" data-toggle="tooltip" data-placement="top"
                    title="{{project.clientTitle}}">
                    <span>{{project.clientTitle}}</span>&nbsp;</h5>-->
                
                <div class="view-form" [ngClass]="{'invisible': !isShowViewFormLnk(project)}" >
                    <a href="javascript:void(0);" (click)="navigateToProjectForm(project)" *ngIf="isShowViewFormLnk(project)">View Form(s) <i class="material-icons">&#xE037;</i></a>
                </div>
                <p class="text-truncate mr-100" data-toggle="tooltip" data-placement="top" title="{{project.description}}" [ngClass]="{'invisible': !project.description}">
                    <span>{{project.description}}</span>&nbsp;</p>
                <div class="row duration-info">
                    <div class="col-md">
                        <small class="w-100">Product Name:</small>
                        <span data-toggle="tooltip" data-placement="top" title="{{project.productTitle }}">{{project.productTitle ? project.productTitle : 'N/A'}}</span>
                    </div>
                    <div class="col-md">
                        <small class="w-100">Client Name:</small>
                        <span data-toggle="tooltip" data-placement="top" title="{{project.clientTitle }}">{{project.clientTitle ? project.clientTitle : 'N/A'}}</span>
                    </div>
                    <div class="col-md">
                        <small class="w-100">Initiated Date:</small>
                        <span data-toggle="tooltip" data-placement="top" title="{{project.createdOn | dateFormat }}">{{project.createdOn | dateFormat }}</span>
                    </div>
                </div>

                <div class="c-footer fdl-2">
                    <div class="c-footer-section">
                        <button class="btn btn-primary-blue" (click)="cardBtnClickHandler(project)">
                               {{dashBoardBtnTxtOnStat(project.statusTitle)}}
                            </button>
                    </div>

                    <div class="profile c-footer-section">
                        <span class="profile-disc">
                                    <img src="assets/images/thumbnail-72x72.png" alt="" class="thumbnail img-w28">
                                    <span class="profile-text" data-toggle="tooltip" data-placement="top" title="{{project.fiservImplementationManagerName}}">{{project.fiservImplementationManagerName}}</span>
                        </span>

                    </div>
                </div>
            </section>
        </div>
        <div class="card-footer pagination-wrapper">
            <div class="dashboard-pagination col-md-2 col-sm-2"></div>
            <div class="dashboard-pagination col-md-8 col-sm-8 " *ngIf="paginationArr && paginationArr.length > 1">
                <a href="javascript:void(0);" (click)="firstPage()" [class.disabled]="isFirstPage()">«</a>
                <a href="javascript:void(0);" (click)="previousPage()" [class.disabled]="isFirstPage()">‹</a>
                <a href="javascript:void(0);" [class.selected]="pageNumber === paginate" *ngFor="let paginate of paginationArr" (click)="paginateClickHandler(paginate)"
                    [innerHtml]="paginate"></a>
                <a href="javascript:void(0);" (click)="nextPage()" [class.disabled]="isLastPage()">›</a>
                <a href="javascript:void(0);" (click)="lastPage()" [class.disabled]="isLastPage()">»</a>
            </div>
            <div class="col-md-2 col-sm-4 pt-1 pull-right">
                <button class="btn btn-link float-right d-inline-block" (click)="viewAllProjectsClickHandler()" *ngIf="projectList && projectList.projects.length !== 0">View All Projects</button>
            </div>
        </div>

    </div>

    <!-- <app-footer></app-footer> -->

</div>
