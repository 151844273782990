<div class="heading-container fdl-2">
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span>
        Resource Category
      </a>
    </div>
    <h1>{{helpCategoryData?.name || 'N/A'}}</h1>
  </div>
  </div>
  
  <div class="form-container separate-form-container">
      <div class="alert alert-success alert-dismissible" *ngIf="showUpdationMessage">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="showUpdationMessage=false">×</button>
          <span class="iconLabel" aria-label="sucess"></span>                
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>            The Resource Category details are updated successfully.
         
          </p>
  </div> 
    <form>
      <div class="biller-survey white-box border-0 fdl-2">
        <button type="button" class="btn fdl-btn btn-primary-dark  pull-right" (click)="goToEdit();" *ngIf="(isAdmin && helpCategoryData.isCommonCategory) || !helpCategoryData.isCommonCategory">Edit</button>
        <div class="">
          <ul class="">
            <li class=" full-width">
              Description:
              <span class="info">
                {{helpCategoryData?.description || 'N/A'}}
              </span>
            </li>
          </ul>
        </div>
      </div>
  
      <div class="fdl-2 button-container">
        <div>
          <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
