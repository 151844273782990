import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { InmemoryDataService, form } from '../../inmemory-data.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-spbuilder-excel',
  templateUrl: './spbuilder-excel.component.html',
  styleUrls: ['./spbuilder-excel.component.css']
})
export class SpbuilderExcelComponent implements OnInit {

   form = [];
  formJson = {};
  formName: string = '';
formId:string='';
  constructor(private dataService: InmemoryDataService,     private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
	   this.route.params.subscribe((params) => {
	
      this.formId = params['id'];
	
	
      const json = this.dataService.getFormJson(this.formId);
      if (json !== null) {
      this.form = JSON.parse(json);
      }
    });
	  
  }
  onChange(data: { form: {} }) {
    this.formJson = data.form;
  }

  saveForm() {
    let newForm: form = {
      formId: uuidv4(),
      formName: this.formName,
      formJson: JSON.stringify(this.formJson),
    };

    this.dataService.createForm(newForm);
    this.router.navigate(['/backend/renderer']);
  }

}
