import { Injectable, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectDrawerService {

  private closeDrawer: BehaviorSubject<any> = new BehaviorSubject(null);
  closeDrawer$ = this.closeDrawer.asObservable();

  private openDrawer: BehaviorSubject<any> = new BehaviorSubject(null);
  openDrawer$ = this.openDrawer.asObservable();

  private _tempData: any;

  set tempData(value){
    this._tempData = value;
  }

  get tempData(){
    return this._tempData;
  }

  constructor(private cfr: ComponentFactoryResolver) {}

  async loadComponent(vcr: ViewContainerRef, name: string) {
    
    const { UserFormComponent } = await import('../../../admin/manageUsers/user-form/user-form.component');
    const { ClientFormComponent } = await import('../../../admin/manageClients/client-form/client-form.component');

    vcr.clear();
    let component : any;
    if(name == "user"){
      component = UserFormComponent;
    }
    else if(name == "client"){
      component = ClientFormComponent;
    }
   
    return vcr.createComponent(this.cfr.resolveComponentFactory(component))    
  } 

  close(){
    const postData = {
      submitPressed: false
    };
    this.closeDrawer.next(postData);
  }

  save(entityName, additionalData = null, entityEmail = null, entityId = -1){
    const postData = {
      submitPressed: true,
      entityEmail,
      entityName,
      entityId
    };
    if(additionalData){
      postData['additionalData'] =  additionalData;
    }
    this.closeDrawer.next(postData);
  }

  clear(){
    this.closeDrawer.next(null);
    this.openDrawer.next(false);
  }

  open(){
    this.openDrawer.next(true);
  }
}
