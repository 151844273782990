import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { IUser } from '../shared/user';
import { map } from 'rxjs/operators';
import { PERMISSIONS, allowedPermissions } from '../core/constants';
import { PermissionsService } from '../services/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class QuickSearchGuard implements CanActivate {
  constructor(private _userService: UserService,
    private permissionService: PermissionsService) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree>  {
    const user = this._userService.getUserListData();
    if (user) return of(true);

    this._userService.callUserListData().pipe(map((data) => {
      this._userService.setUserListData(data);
      this.permissionService.setUserData(data);
      let usersPermission = [];
      data.permissions.forEach((ele) => {
        let permission: any = allowedPermissions[ele];
        for (let perm in permission) {
          if (permission[perm])
            usersPermission[perm] = permission[perm]
        }
      });
      this._userService.setPermissions(usersPermission);
      return of(true);
    }));
  }
}
