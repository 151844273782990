import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContentService } from '../../../services/content.service';

@Component({
  selector: 'app-data-table-updated',
  templateUrl: './data-table-updated.component.html',
  styleUrls: ['./data-table-updated.component.css']
})
export class DataTableUpdatedComponent implements OnInit {
  @Input() keys: string[];
  @Input() headerColumns: string[];
  @Output() sortingClicked = new EventEmitter<{ key: string, isAsc: boolean }>();
  @Input() enableSorting: boolean = true;
  @Output() buttonClicked = new EventEmitter<{ action: string, data: string }>();
  @Input() ngShow: boolean = true;
  filterColumn: string;
  isAsc: boolean;

  @Input() rows;
  content: any;
  constructor(private contentService: ContentService) { }
  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes)
    if (changes.ngShow && changes.ngShow.currentValue) {
      this.ngShow = changes.ngShow.currentValue;

    }
  }
  getRows() {

    if (!this.ngShow)
      return [];
    else
      return this.rows;
  }
  statusClass(status: any, key: any) {
    let data = status[key].replace('Fulfillment - ', '');

    let color = '';
    if (key == 'isRisk') {
      if (status['commitmentStatus'] == 'In Progress' && data.toString() === 'true')
        return 'red';
      else if (status['commitmentStatus'] == 'In Progress' && data.toString() !== 'true')
        return 'green';
      else
        return "blank";

    }
    switch (data) {
      case "Active":
        color = "blank";
        break;
      case "Completed":
        color = "blank";
        break;
      case "On Hold":
        color = "blank";
        break;
      case "Canceled":
        color = "blank";
        break;
      case "true":
        color = "red";
        break;
      case "Green":
        color = "blank";
        break;
      case "Amber":
        color = "blank";
        break;
      case "false":
        color = "green";
        break;
      case "":
        color = "green";
        break;
      case "blank":
        color = "blank";
        break;
      default:
        color = "blank";
    }

    return color;

  }
  statusDocument(data: any, key: any) {
    data = data.replace('Fulfillment - ', '');

    let text = data.toString();
    //  console.log(data);
    switch (data) {
      case "true":
        text = "";
        break;
      case "false":
        text = "";
        break;
      case "":
        text = "";
        break;
      case "Amber":
        text = "Amber";
        break;
      case "Active":
        text = "Active";
        break;
      case "Completed":
        text = "Completed";
        break;
      case "On Hold":
        text = "On Hold";
        break;
      case "Canceled":
        text = "Canceled";
        break;
      case "blank":
        text = "";
        break;

      //default:
      //  text = "In Progress";

    }

    return text;

  }
  isStatus(key: any) {
    if (key.toLowerCase().indexOf('status') != '-1' || key.toLowerCase().indexOf('isrisk') != '-1') {
      return true

    }
    return false

  }
  isDate(key) {
    return (key.indexOf('modifiedOn') > -1 ||
      key.indexOf('startDate') > -1 ||
      key.indexOf('modifiedON') > -1 ||
      key.indexOf('createdOn') > -1 ||
      key.indexOf('createdON') > -1 ||
      key.indexOf('startTime') > -1
      // || key.indexOf('plannedCommittedDeliveryDate') > -1
    );
  }
  isDateOnly(key) {
    return key.indexOf('dueDate') > -1 || key.indexOf('modifiedDate') > -1;
  }
  onClick(id, action) {
    this.buttonClicked.emit({ action: action, data: id });
  }
  dataToDisplay(data: any, key: any) {
    let rd = data;
    if (key.toLowerCase().indexOf('status') != '-1') {
      rd = data.replace('Fulfillment - ', '')
    }
    return rd;
  }
  ngOnInit(): void {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
  }
  /**
   * 
   * Sorting Starts
   */

  sort(key) {
    if (this.filterColumn !== key) {
      this.filterColumn = key;
      this.isAsc = true;
    }
    else {
      this.isAsc = !this.isAsc;
    }
    this.sortingClicked.emit({ key: this.filterColumn, isAsc: this.isAsc });
  }
  applySorting(key): boolean {
    return this.enableSorting && (this.rows && this.rows.length > 0) && key != 'isRisk';
  }

  /**
 Sorting ends
 
 */
}
