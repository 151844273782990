<div class="heading-container fdl-2">
    <h1>Self Service Feature List</h1>
    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="createReport();" *ngIf="isCreateReportAllowed">
        <span class="material-icons">add</span>
        Add New Feature</button>
</div>
<div class="form-container">
        <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="closeSuccessMsg()">×</button>
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>{{successMsg}}
          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="deletedFormMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>{{deletedFormMsg}}

            </p>
        </div>
<div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
</div>
<form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
    <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
    <div class="filter-form-container-column2 search-filter collapse" id="collapseFilter">
        <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('name').valid && (filterForm.get('name').touched || filterForm.get('name').dirty || isSubmitted)}">
          <span *ngIf="!filterForm.get('name').valid && filterForm.get('name').errors?.pattern && (filterForm.get('name').touched || filterForm.get('name').dirty || isSubmitted)">
            <i class="material-icons">warning</i>Please enter a valid Feature Name</span>
          <fdl-type-ahead class="filter-typeahead" label="Feature Name:" formControlName="name" [collection]="featureSuggestion" optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
        </div>
        <div class="buttom-wrapper">
            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
                (click)="filterList()">Search</button>
        </div>
        <div class="ml-2 buttom-wrapper" *ngIf="filtered">
            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset"
                (click)="clearFilterList()">Reset</button>
        </div>
    </div>
</form>
<div class="data-table-container">
  <div class="justify-content-between d-flex">
    <div class="headline-container">
      <h2 class="h4">Feature List</h2>
      <!-- <p>Total {{pagerObject.totalCount}} Reports</p> -->
    </div>
    <div class="d-flex">
      <div class="refresh-icon-link mt-1 mr-2">
        <!-- add class of 'fa-rotation' on click of the refresh icon -->
        <a title="Refresh Feature List" id="refreshTask">
          <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
        </a>
      </div>
      <app-prefernce-icon></app-prefernce-icon>
    </div>
  </div>

    <div>
        <app-data-table
            *ngIf="featureList"
            [rows]="featureList"
            [keys]="keys"
            [displayColumns]="displayColumns"
            (sortingClicked)="applySorting($event)"
            [showCheckbox]="false"
            [showViewEdit]="true"
            (buttonClicked)="navigate($event)"
            [showDelete]="true"
            [isHistory]="true"
            (deleteBtnClicked)="navigate($event)"
            [listname]="listname"
            type="Features"
           >
        </app-data-table>
    </div>
    <div>
        <app-pagination
                *ngIf="pagerObject && pagerObject.totalPages > 1 && featureList && featureList.length > 0"
                [pagerObject]="pagerObject"
                (pagingChanged)="changePage($event)"
            ></app-pagination>
    </div>
</div>
</div>
