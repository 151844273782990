<div class="heading-container fdl-2">
  <div class="back-action-container">
  <div class="back-button">
    <a href="javascript:void(0);" (click)="goBack()">
      <span class="material-icons"> arrow_back </span>
      Roles
    </a>
  </div>
  <h1>{{selectedRole?.title || 'N/A'}}</h1>
</div>
</div>

<div class="form-container separate-form-container">
      <div class="alert alert-success alert-dismissible" *ngIf="showUpdationMessage">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="showUpdationMessage=false">×</button>
              <span class="iconLabel" aria-label="sucess"></span>
              <i class="material-icons" aria-hidden="true">check_circle</i>
              <p>User details updated successfully.

              </p>
      </div>
      <form>
        <div class="biller-survey white-box fdl-2" *ngIf="selectedRole">
            <button type="button" class="btn fdl-btn btn-primary-dark  pull-right" *ngIf="showEditButton" (click)="goToEdit()">Edit</button>
            <div>
                <ul>
                    <li>
                        Role Name:
                        <span class="info">{{selectedRole.title || 'N/A'}}</span>
                    </li>
                    <li>
                        Description
                        <span class="info">{{selectedRole.description || 'N/A'}}</span>
                    </li>
                    <li>
                      Can be assigned to Client
                      <span class="info">{{canbeAssignedToClientAux || 'N/A'}}</span>
                  </li>
                  <li>
                    Role type
                    <span class="info">{{roleType || 'N/A'}}</span>
                </li>
                <li>
                  Display order
                  <span class="info">{{selectedRole.displayOrder || 'N/A'}}</span>
              </li>
                </ul>
            </div>
            <div *ngFor="let item of tableData">
              <h2 class="h5 font-weight-bold ">Selected permissions for {{item.groupName}}</h2>
              <p class="pt-1 f-12">
                <span class="iconLabel alert-success" aria-label="success"><i class="material-icons" aria-hidden="true">check_circle</i></span>
                {{item.permissions.length}} Permission(s) Assigned</p>
              <div class="table-responsive">
                  <app-data-table *ngIf="selectedRole" [customClasses]="'table-layout-fixed'" [rows]="item.permissions" [keys]="keys"
                      [displayColumns]="displayColumns" [showMenu]="false" [enableSorting]="false"
                      [showCheckbox]="false">
                  </app-data-table>
              </div>
            </div>

        </div>
    </form>
</div>
