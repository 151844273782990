import { Component, OnInit } from "@angular/core";
import { DecimalPipe } from '@angular/common';
import { Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";

import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";

import { IForms, IForm } from "../../shared/form";
import { IPager } from "../../shared/pagination";
import { ISuggestions } from "../../shared/suggestion";
import { ProjectsService } from "../../services/projects.service";
import { UserService } from "../../services/user.service";
import { DashboardCommonLogic } from "../shared/dashboard-common-logic";
import { PermissionsService } from '../../services/permissions.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: "app-dashboard-form",
  templateUrl: "./dashboard-form.component.html"
})
export class DashboardFormComponent extends DashboardCommonLogic
  implements OnInit {
  filterForms: FormGroup;

  formList: IForms;
  filteredForms: IForms;
  queryParam: any;
  pagerObject: IPager;
  paginationArr: any;

  responsesSuggestion: ISuggestions;
  projectSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  clientSuggestion: ISuggestions;
  assignedToSuggestion: ISuggestions;
  organizationSuggestion: ISuggestions;

  isDashboardFilterPopover: boolean = false;
  statusBlockClicked: string = "Total Forms";

  switchVal: string = "form";
  private static readonly pageSize: number = 6;
  pageNumber: number = 1;

  userType: string;
  userList: any;
  tagsArray: any[] = [];

  filterTooltip: string = "Select Filter";

  showProductFilter: boolean = false;
  showProjectFilter: boolean = false;
  isClientFilterAllowed: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private responsesService: ProjectsService,
    private r: Router,
    private userService: UserService,
    private permissionService: PermissionsService,
    private utilService: UtilService,
    private decimalPipe: DecimalPipe
  ) {
    super(r);
  }

  ngOnInit(): void {
    this.showProductFilter = this.permissionService.showProductFilter();
    this.showProjectFilter = this.permissionService.showProjectFilter();
    this.isClientFilterAllowed = this.permissionService.isClientFilterAllowed();
    this.userList = this.userService.getUserListData();
    if (this.userList) { this.isAdmin = this.userList.isAdmin; }
    this.userType =
      !this.userList.isAdmin &&
      !this.userList.permissionsForUI.some(
        group => group.groupName === "Projects"
      )
        ? "respondent"
        : "nonRespondent";
    this.initFilterForms();
    this.initQueryParams();    
    this.getSuggestions();
    const proDetails = this.responsesService.getProjectDetails();
    if(Object.keys(proDetails).length === 0){
      this.callResponsesListService();
    } else {
      this.getResponseForProject(proDetails);
    }
    
  }

  getSuggestions() {
    this.responsesService.getSuggestions("responses", "").subscribe(suggestion => this.responsesSuggestion = suggestion);
    this.responsesService.getSuggestions("users", "Form Respondent", "role").subscribe(suggestion => this.assignedToSuggestion = suggestion);
    if(this.showProjectFilter) this.responsesService.getSuggestions("projects", "").subscribe(suggestion => this.projectSuggestion = suggestion);
    if(this.showProductFilter) this.responsesService.getSuggestions("products", "").subscribe(suggestion => this.productSuggestion = suggestion);
    if(this.isClientFilterAllowed) this.responsesService.getSuggestions("clients", "").subscribe(suggestion => this.clientSuggestion = suggestion);
    if (this.isAdmin) this.responsesService.getSuggestions("organizations", "").subscribe(suggestion => this.organizationSuggestion = suggestion);
  }

  initQueryParams() {
    this.queryParam = Object.assign(
      {},
      {
        pageNumber: this.pageNumber,
        pageSize: DashboardFormComponent.pageSize
      }
    );
  }

  callResponsesListService(isNotAddTotal: boolean = false): void {
    this.responsesService
      .getResponseList(this.queryParam)
      .pipe(take(1))
      .subscribe(responses => {
        if (responses.headers.get("X-pagination")) {
          this.pagerObject = Object.assign({}, JSON.parse(
            responses.headers.get("X-pagination")
          ) as IPager);
        }
        this.formList = responses.body as IForms;
        
        this.formList.forms.map(form => ({
            ...form,
            startDate: form.startDate,
            dueDate: form.dueDate
          }) as IForm);
        
        if (!isNotAddTotal && !this.isTotalExistInCounter()) {
          this.formList.counters.unshift({
            key: "Total Forms",
            value: this.pagerObject.totalCount
          });
        }
        this.calculatePages();
        this.filteredForms = JSON.parse(JSON.stringify(this.formList));
      });
  }

  initFilterForms(): void {
    this.filterForms = new FormGroup({
      title: new FormControl(""),
      productTitle: new FormControl(""),
      clientTitle: new FormControl(""),
      projectTitle: new FormControl(""),
      assignedTo: new FormControl(""),
      organizationTitle: new FormControl(''),
    });
  }

  togglePopoverContainer(): void {
    this.isDashboardFilterPopover = !this.isDashboardFilterPopover;
  }

  addToQueryString(queryStrObj: any = {}): void {
    Object.assign(this.queryParam, queryStrObj);
  }

  filterOnStatusBlock(status): void {

    // Once a status is clicked, then this method should not be called as all other status are removed
    let isFilteredOnStatus = this.tagsArray.some(ele => ele.key === 'responseStatusTitle');    
    if(isFilteredOnStatus || (status.indexOf("Total") > -1)) return;

    this.queryParam["pageNumber"] = 1;
    this.pageNumber = 1;
    Object.assign(this.queryParam, { responseStatusTitle: status });
      this.tagsArray.push({
        key: "responseStatusTitle",
        value: status
      });
    this.filteredForms.counters.shift();
    this.callResponsesListService(true);
  }

  calculateClasses(status): string {
    return new DashboardCommonLogic(this.r).calculateClasses(status);
  }

  dashBoardBtnTxtOnStat(status): string {
    return new DashboardCommonLogic(this.r).dashBoardBtnTxtOnStat(status);
  }

  activate(view: string): void {
    return new DashboardCommonLogic(this.r).activateRoute(view);
  }

  paginateClickHandler(idx: number): void {
    if (this.pageNumber === idx) return;
    this.pageNumber = idx;
    this.queryParam["pageNumber"] = this.pageNumber;
    let isRemoveTotal = this.isRemoveTotalFromStatusArea();
    this.callResponsesListService(isRemoveTotal);
  }

  cardBtnClickHandler(form: IForm): void {
    this.r.navigate(["response/view/responses/", form.id]);
    switch (status) {
      case "Not Started":
        // this.r.nav
        break;
      case "In Progress":
        // typeOfBadge = "badge-yellow";
        break;
      case "Completed":
        // typeOfBadge = "badge-green";
        break;
    }
  }

  viewAllResponsesClickHandler(): void {
    this.r.navigate(["response/list"]);
  }

  filterResponsesClickHandler(): void {
    this.queryParam["pageNumber"] = 1;
    this.pageNumber = 1;
    let initialQueryParam = JSON.parse(JSON.stringify(this.queryParam));
    let filterValues = this.filterForms.value;
    if (filterValues["title"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "title", filterValues);
      if (!valueFound) {
        this.queryParam.title = filterValues["title"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "title");
          this.tagsArray[objIndex].value = filterValues["title"];
        } else {
          this.tagsArray.push({ key: "title", value: filterValues["title"] });
        }
      }
    }
    if (filterValues["projectTitle"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "projectTitle", filterValues);
      if (!valueFound) {
        this.queryParam.projectTitle = filterValues["projectTitle"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "projectTitle");
          this.tagsArray[objIndex].value = filterValues["projectTitle"];
        } else {
          this.tagsArray.push({ key: "projectTitle", value: filterValues["projectTitle"] });
        }
      }
    }
    if (filterValues["productTitle"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "productTitle", filterValues);
      if (!valueFound) {
        this.queryParam.productTitle = filterValues["productTitle"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "productTitle");
          this.tagsArray[objIndex].value = filterValues["productTitle"];
        } else {
          this.tagsArray.push({ key: "productTitle", value: filterValues["productTitle"] });
        }
      }
    }
    if (filterValues["clientTitle"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "clientTitle", filterValues);
      if (!valueFound) {
        this.queryParam.clientTitle = filterValues["clientTitle"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "clientTitle");
          this.tagsArray[objIndex].value = filterValues["clientTitle"];
        } else {
          this.tagsArray.push({ key: "clientTitle", value: filterValues["clientTitle"] });
        }
      }
    }
    if (filterValues["assignedTo"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "assignedTo", filterValues);
      if (!valueFound) {
        this.queryParam.assignedTo = filterValues["assignedTo"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "assignedTo");
          this.tagsArray[objIndex].value = filterValues["assignedTo"];
        } else {
          this.tagsArray.push({ key: "assignedTo", value: filterValues["assignedTo"] });
        }
      }
    }
    if (filterValues["organizationTitle"]) {
      const { valueFound, keyFound } = new DashboardCommonLogic(this.r).isValueOrKeyFound(this.tagsArray, "organizationTitle", filterValues);
      if (!valueFound) {
        this.queryParam.organizationTitle = filterValues["organizationTitle"];
        if (keyFound) {
          let objIndex = this.tagsArray.findIndex(obj => obj.key == "organizationTitle");
          this.tagsArray[objIndex].value = filterValues["organizationTitle"];
        } else {
          this.tagsArray.push({ key: "organizationTitle", value: filterValues["organizationTitle"] });
        }
      }
    }
    let isRemoveTotal = this.isRemoveTotalFromStatusArea();
    const isCallProjectListApi = new DashboardCommonLogic(this.r).isEquivalent(
      initialQueryParam,
      this.queryParam
    );
    if (!isCallProjectListApi) this.callResponsesListService(isRemoveTotal);
    this.togglePopoverContainer();
  }

  resetResponsesClickHandler(): void {
    this.statusBlockClicked = "Total Forms";
    this.initFilterForms();
    this.initQueryParams();
    this.tagsArray = [];
    this.callResponsesListService();
    this.togglePopoverContainer();
  }

  removeTagClickHandler(filterCriteriaRemove: string): void {
    for (let i = 0; i < this.tagsArray.length; i++) {
      if (this.tagsArray[i].key === filterCriteriaRemove) {
        this.tagsArray.splice(i, 1);
        this.filterForms.reset({ filterCriteriaRemove: "" });
      }
    }
    if(filterCriteriaRemove === 'projectTitle'){
      delete this.queryParam['projectId'];
    }
    delete this.queryParam[filterCriteriaRemove];
    this.statusBlockClicked = "Total Forms";
    let isRemoveTotal = this.isRemoveTotalFromStatusArea();
    this.callResponsesListService(isRemoveTotal);
  }

  isRemoveTotalFromStatusArea(): boolean {
    return this.tagsArray.some(ele => ele.key === 'responseStatusTitle');
  }

  private isTotalExistInCounter(): boolean {
    let isValueFound = this.formList.counters.find(el => el.key == "Total Forms");
    return isValueFound ? true : false;
  }

  calculatePages(){
    this.paginationArr = [];
    const range = this.utilService.pageRange(this.pageNumber, this.pagerObject.totalPages);
    for(var i = range.start; i <= range.end; i++){
      this.paginationArr.push(i);
    }
  }

  nextPage(){    
    if(this.pageNumber === this.pagerObject.totalPages) return;
    const nextPage = this.pageNumber + 1;
    this.paginateClickHandler(nextPage);
  }

  previousPage(){
    if(this.pageNumber === 1) return;
    const prevPage = this.pageNumber - 1;
    this.paginateClickHandler(prevPage);
  }

  firstPage(){
    if(this.pageNumber === 1) return;
    this.paginateClickHandler(1);
  }

  lastPage(){
    const lastPage = this.pagerObject.totalPages;
    if(this.pageNumber === lastPage) return;
    this.paginateClickHandler(this.pagerObject.totalPages);
  }

  isFirstPage(){
    return (this.pageNumber === 1);
  }

  isLastPage(){
    return (this.pageNumber === this.pagerObject.totalPages);
  }

  getResponseForProject(proDetails: any): void {
    this.responsesService.setProjectDetails({});
    let initialQueryParam = JSON.parse(JSON.stringify(this.queryParam));
    this.queryParam.projectTitle = proDetails.title;
    this.queryParam.projectId = proDetails.id;
    this.tagsArray.push({ key: "projectTitle", value: proDetails.title });
    let isRemoveTotal = this.isRemoveTotalFromStatusArea();
    this.filterForms.patchValue({
      projectTitle: proDetails.title
    })
    this.callResponsesListService(isRemoveTotal);
  }

  populateStatusOfForm(form: IForm): string {
    let status = ""
    status = form.responseStatusTitle || 'Not Started';
    if(this.isShowPercentTxt(status)) status += " " + "(" + this.decimalPipe.transform(form.percentageComplete, '1.0-0') + "%)";
    return status;
  }

  isShowPercentTxt(status: string): boolean {
    return status === 'In Progress' || status === 'Overdue';
  }
}
