<table aria-describedby="tblDesc" class="f-12 table table-hover table-label">
  <thead>
    <tr role="row">

      <th scope="col" role="columnheader" *ngFor="let column of headerColumns; let i = index;">
        <span *ngIf="!applySorting(keys[i])" href="javascript:void(0);">
          {{column}}

        </span>

        <a (click)="sort(keys[i])" *ngIf="applySorting(keys[i])" href="javascript:void(0);">
          {{column}}
          <i class="material-icons msort14" aria-hidden="true"
             *ngIf="isAsc && filterColumn == keys[i]">arrow_upward</i>
          <i class="material-icons msort14" aria-hidden="true"
             *ngIf="!isAsc && filterColumn == keys[i]">arrow_downward</i>
        </a>
        <i id="targetDateIcon" *ngIf="column == 'Target Delivery Date'" title="Target delivery date represents Initially Requested Date or a Planned Committed Date, which may be revised based on changes to the project execution plan" 
            class="fa fa-question-circle question-icon text-muted" ref="tooltip" data-toggle="tooltip" data-animation="false"></i>
      </th>
  
       <th scope="col" role="columnheader" >
   
 
      
       </th>
    </tr>
  </thead>
  <tbody  class="cdk-drop-list data-table-body cdk-drop-list-disabled" id="cdk-drop-list-0">
    <tr role="row" *ngFor="let row of getRows(); let i = index;" class="cdk-drag cdk-drag-disabled">

 
      <td role="cell" *ngFor="let key of keys; let j = index;" >
        <label for="myCheckboxZero" data-placement="top" *ngIf="!isStatus(key) && !isDate(key)" title="{{row[key]}}">{{row[key]}} </label>
        <label for="myCheckboxZero" data-placement="top" class="badge-table" *ngIf="isStatus(key)  && !isDate(key)"><span *ngIf="statusClass(row,key)!='blank'" class="badge-table-status {{statusClass(row,key)}}"></span>{{statusDocument(row[key],key)}}</label>
        <label for="myCheckboxZero" *ngIf="isDate(key) &&!isStatus(key) && row[key]!='01/01/0001' && row[key]!='12/31/0001' && row[key]!='0001-01-01T00:00:00Z'" data-placement="top" title="{{row[key] | date: content['dateFormat']}}">{{row[key] | date: content['dateFormat']}}</label>
      </td>
      <!-- <td role="cell"><div class="linear-loader-container"><div class="linear-loader"><div class="indicator" style="width:100%;"></div></div><span>100%</span></div></td>
   -->
      <td>

        <a (click)="onClick(row, 'view')" class="link">
          <div class="material-icons">
            chevron_right
          </div>
        </a>
      </td>
    </tr>

  </tbody>
</table>
<div role="status" *ngIf="!ngShow" aria-label="Please wait, do not refresh the page" class="ccdrms-loader" style="position:relative;margin-top:20%"></div>
