import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { FileUploadHeader } from 'src/app/core/constants';
import {
  DocumentFileListItemType,
  IDashboardDocument,
  IDocument,
} from '../shared/document';
import { Observable } from 'rxjs';
import { ISuggestions } from '../shared/suggestion';
import { IQuery } from '../shared/query';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private documentAPIURL = 'documents';
  constructor(private http: HttpClient) {}

  getbaseUrl() {
    return environment.apibaseurl;
  }

  createFolderParams(formData) {
    let submitData = {
      name: formData.name,
      isFiservImplementationManagerAllowed:
        formData.isFiservImplementationManagerAllowed,
      isFiservPortfolioManagerAllowed: formData.isFiservPortfolioManagerAllowed,
      isFiservDivisionAdminAllowed: formData.isFiservDivisionAdminAllowed,
      isClientImplementationManagerAllowed:
        formData.isClientImplementationManagerAllowed,
      isChannelPartnerAllowed: formData.isChannelPartnerAllowed,
    };
    return submitData;
  }

  createParams(formData, folderSuggestions, documentTypeSuggestions) {
    let submitData = {
      fileAlias: formData.fileAlias,
      file: formData.fileCtrl,
    };

    submitData['folderId'] = 0;
    if (formData.folderName && formData.folderName.length > 0) {
      const selectedFolder = (folderSuggestions as any).filter(
        (folder) => folder['title'] == formData.folderName
      );

      if (selectedFolder && selectedFolder[0]) {
        submitData['folderId'] = selectedFolder[0]['id'];
      }
    }
    submitData['documentTypeId'] = 0;
    if (formData.documentTypeName && formData.documentTypeName.length > 0) {
      const selectedType = (documentTypeSuggestions as any).filter(
        (documentType) => documentType['title'] == formData.documentTypeName
      );

      if (selectedType && selectedType[0]) {
        submitData['documentTypeId'] = selectedType[0]['id'];
      }
    }
    return submitData;
  }

  createReplaceFileParams(formData) {
    const submitData = {
      file: formData.fileCtrl,
    };

    return submitData;
  }

  getFolderSuggestions(titleValue, paramName = 'title', queryParams: IQuery) {
    let options = { refresh: 'true' };
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    const suggestionUrl = `${
      this.documentAPIURL
    }/folders/suggestions?${paramName}=${encodeURIComponent(titleValue)}`;
    return this.http.get<ISuggestions>(suggestionUrl, {
      headers: options,
      params,
      observe: 'response',
    });
  }

  getTypeSuggestions() {
    let options = { refresh: 'true' };
    const suggestionUrl = `${
      this.documentAPIURL
    }/types/suggestions`;
    return this.http.get<ISuggestions>(suggestionUrl, {
      headers: options,
      observe: 'response',
    });
  }

  getSuggestions(
    titleValue,
    paramName = 'title',
    queryParams: IQuery,
    isByFileName: boolean = true
  ) {
    let options = { refresh: 'true' };
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    let suggestionUrl = `${
      this.documentAPIURL
    }/suggestions?${paramName}=${encodeURIComponent(titleValue)}`;
    if (!isByFileName) {
      suggestionUrl += `&isByFileName=false`;
    }
    return this.http.get<ISuggestions>(suggestionUrl, {
      headers: options,
      params,
      observe: 'response',
    });
  }

  createFolder(relationTypeId, request) {
    return this.http.post(
      `${this.documentAPIURL}/${relationTypeId}/folders`,
      request
    );
  }

  generateAccessForFolderParams(formData) {
    let submitData = {
      isFiservImplementationManagerAllowed:
        formData.isFiservImplementationManagerAllowed,
      isFiservPortfolioManagerAllowed: formData.isFiservPortfolioManagerAllowed,
      isFiservDivisionAdminAllowed: formData.isFiservDivisionAdminAllowed,
      isClientImplementationManagerAllowed:
        formData.isClientImplementationManagerAllowed,
      isChannelPartnerAllowed: formData.isChannelPartnerAllowed,
    };
    return submitData;
  }

  assignAccessForDocumentFolder(folderId, request) {
    return this.http.post(
      `${this.documentAPIURL}/folders/${folderId}/accesses`,
      request
    );
  }

  delete(documentId) {
    return this.http.delete(`${this.documentAPIURL}/${documentId}`);
  }

  deleteFolder(folderId) {
    return this.http.delete(`${this.documentAPIURL}/folders/${folderId}`);
  }

  upload(relationTypeId, request) {
    let httpOptions = {
      headers: new HttpHeaders({
        [FileUploadHeader]: '',
      }),
    };
    const formData = new FormData();
    for (let key of Object.keys(request)) {
      let value = request[key];
      formData.append(key, value);
    }

    return this.http.post(
      `${this.documentAPIURL}/${relationTypeId}`,
      formData,
      httpOptions
    );
  }

  replaceFile(documentId: number, request: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        [FileUploadHeader]: '',
      }),
    };
    const formData = new FormData();
    for (let key of Object.keys(request)) {
      let value = request[key];
      formData.append(key, value);
    }

    return this.http.patch(
      `${this.documentAPIURL}/${documentId}/files`,
      formData,
      httpOptions
    );
  }

  update(documentId, updates) {
    return this.http.patch(`${this.documentAPIURL}/${documentId}`, updates);
  }

  updateFolder(folderId, updates) {
    return this.http.patch(
      `${this.documentAPIURL}/folders/${folderId}`,
      updates
    );
  }

  getDocumentUrl(selectedDocument: IDocument) {
    const baseUrl = window.location.origin;
    if (selectedDocument.itemType === DocumentFileListItemType.document) {
      return `${baseUrl}/document-files/${selectedDocument.guid}`;
    }

    if (selectedDocument.itemType === DocumentFileListItemType.folder) {
      return `${baseUrl}/document-folders/${selectedDocument.id}`;
    }

    return selectedDocument.url;
  }

  generateDownloadPageUrl(selectedDocument: IDocument){
    if (selectedDocument.itemType === DocumentFileListItemType.document){
      return `${window.location.origin}/documents/${selectedDocument.guid}`;
    }
    
    if (selectedDocument.itemType === DocumentFileListItemType.folder){
      return `${window.location.origin}/folders/documents/${selectedDocument.id}`;
    }

    return selectedDocument.url;
  }
  
  generateFileDownloadApiUrl(guid: any) {
    return `${this.documentAPIURL}/files/${guid}`;
  }

  generateFolderDownloadApiUrl(folderId: any) {
    return `${this.documentAPIURL}/folders/${folderId}/files`;
  }

  downloadFile(guid: any) {
    const headers = new HttpHeaders({
      refresh: 'true',
    });

    const downloadUrl = this.generateFileDownloadApiUrl(guid);
    return this.http.get(downloadUrl, {
      headers: headers,
      observe: 'response',
      responseType: 'blob',
    });
  }

  downloadFolder(folderId: number) {
    const headers = new HttpHeaders({
      refresh: 'true',
    });

    return this.http.get(`${this.documentAPIURL}/folders/${folderId}/files`, {
      headers: headers,
      observe: 'response',
      responseType: 'blob',
    });
  }

  getInfo(guid: any) {
    const headers = new HttpHeaders({
      refresh: 'true',
    });

    return this.http.get(`${this.documentAPIURL}/${guid}/info`, {
      headers,
    });
  }

  getFolderInfo(id: number) {
    const headers = new HttpHeaders({
      refresh: 'true',
    });
    return this.http.get(`${this.documentAPIURL}/folders/${id}`, {
      headers,
    });
  }

  getList(
    queryParams,
    shouldRefresh: Boolean = false
  ): Observable<HttpResponse<IDashboardDocument>> {
    let params = new HttpParams();

    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    if (shouldRefresh) {
      const headers = new HttpHeaders({
        refresh: 'true',
      });

      return this.http.post<IDashboardDocument>(this.documentAPIURL, null, {
        params: params,
        headers: headers,
        responseType: 'json',
        observe: 'response',
      });
    }

    return this.http.post<IDashboardDocument>(this.documentAPIURL, null, {
      params: params,
      responseType: 'json',
      observe: 'response',
    });
  }
}
