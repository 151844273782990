import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MasterService } from 'src/app/services/master.service';
import { IGroup } from 'src/app/shared/group';
import { GroupService } from 'src/app/services/groups.service';

@Component({
  selector: 'view-user-group',
  templateUrl: './view-user-group.component.html'
})
export class ViewUserGroupComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private masterService: MasterService,
    private groupService: GroupService
  ) { }

  groupId: number
  groupData: IGroup
  orgDisplayColumns = ['Organization Name', 'Description'];
  orgKeys = ['organizationTitle', 'organizationDescription'];
  productDisplayColumns = ['Product Name', 'Description'];
  productKeys = ['productTitle', 'productDescription'];
  clientKeys = ['titleWithDuns', 'clientDescription'];
  clientDisplayColumns = ['Client Name', 'Description'];
  userKeys = ['userName', 'userEmail'];
  userDisplayColumns = ['User Name', 'User Email'];
  showUpdationMessage: boolean = false;
  showEditButton: boolean = false;

  ngOnInit(): void {
    this.showEditButton = true;
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.groupId = params['id'];
        if (this.groupId > -1) {
          this.getGroupData();
        }
      });
      this.showUpdationMessage = this.groupService.isGroupUpdated;
  }

  getGroupData() {
    this.groupService.getGroupById(this.groupId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.groupData = data;
        }
      );
  }

  goToEdit(){
    this.groupService.isEditMode = true;
    this.groupService.setGroupData = this.groupData;
    this.router.navigate(['/admin/usergroup/edit']);
  }

  ngOnDestroy(): void {
    this.groupService.isGroupUpdated = false;
  }

  goBack(){
    this.masterService.isCancelledClicked(true);
    this.router.navigate(['/admin/usergroup/list']);
  }
}