import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { CategoryComponent } from 'src/app/help/components/category/category.component';
import { TopicDetailsComponent } from './components/topic-details/topic-details.component';
import { TopicListComponent } from './components/topic-list/topic-list.component';
import { HelpComponent } from './help.component';
import { HelpGuard } from './help.guard';
const routes: Routes = [
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [MsalGuard],
   
    children: [
      {
        path: '', redirectTo: 'category', pathMatch: 'full'
      },
     
      {
        path: 'category',
        component: CategoryComponent,
        data: { title: 'Category List' },
          canActivate: [HelpGuard],
       
       
        
      },

      {
        path: 'category/:id',
        component: TopicListComponent,
        data: { title: 'Topic List' },
        canActivate: [HelpGuard],


      }, {
        path: 'topic/:id',
        component: TopicDetailsComponent,
        data: { title: 'Topic List' },
        canActivate: [HelpGuard],


      },
      {
        path: 'topic/:id/:flowname',
        component: TopicDetailsComponent,
        data: { title: 'Topic List' },
        canActivate: [HelpGuard],


      }
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HelpRoutingModule {

}
