import { environment } from '../environments/environment';

export const b2cPolicies = {
  names: {
    signUpSignIn: environment.msalbc2Config.signUpSignInName
  },
  authorities: {
    signUpSignIn: {
      authority: environment.msalbc2Config.signUpSignInAuthority
    },
    resetPassword: {
      authority: environment.msalbc2Config.resetPwdAuthority
    },
    authorityDomain:environment.msalbc2Config.authorityDomain
  }
}

export const apiConfig: { b2cScopes: string[], webApi: string } = {
  b2cScopes: [...environment.msalbc2Config.b2cScopes],
  webApi: environment.msalbc2Config.webApiEndpoint
};

/* export const msalConfig: Configuration = {
  auth: {
    clientId: environment.msalbc2Config.clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    redirectUri: environment.msalbc2Config.redirectUri,
    postLogoutRedirectUri: environment.msalbc2Config.postLogoutRedirectUri,
    navigateToLoginRequestUrl: true,
    validateAuthority: false,

  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE,
  },
}
 */
export const loginRequest: { scopes: string[] } = {
  scopes: ['openid', 'profile'],
};

export const tokenRequest: { scopes: string[] } = {
  scopes: apiConfig.b2cScopes
};

export const protectedResourceMap: [string, string[]][] = [
  [environment.msalbc2Config.webApiEndpoint + 'surveys/GenerateOTP', null],
  [environment.msalbc2Config.webApiEndpoint + 'surveys/ValidateOTP', null],
  [apiConfig.webApi, apiConfig.b2cScopes]
];

/* export const msalAngularConfig: MsalAngularConfiguration = {
  popUp: false,
  consentScopes: [
    ...loginRequest.scopes,
    ...tokenRequest.scopes,
  ],
  unprotectedResources: [],
  protectedResourceMap,
  extraQueryParameters: {}
} */
