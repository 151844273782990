import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from '../../../core/constants';
import { PermissionsService } from '../../../services/permissions.service';
import { ProjectsService } from '../../../services/projects.service';
import { NoWhitespaceValidator } from '../../../shared/no-whitespace.validator';

import { ICreateTopic, ICreateTopicForm } from '../../manageForms/models/createTopic';
import { forkJoin } from 'rxjs';
import { HelpService } from '../../../services/help.service';
import { ManageHelpService } from '../services/manageHelp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ISuggestion } from '../../../shared/suggestion';
import { UserService } from '../../../services/user.service';
import { isUndefined } from 'util';
@Component({
  selector: 'app-create-topic',
  templateUrl: './create-topic.component.html',
  styleUrls: ['./create-topic.component.css']
})
export class CreateTopicComponent implements OnInit {
  createFormGroup: FormGroup;
  formVal: ICreateTopicForm;
  isAdmin: boolean = false;
  productSuggestion: any;
  _maxLength: any;
  regexValidationMessage: any;
  productName: string;
  invalidProductName: boolean = false;
  catSuggestion: ISuggestion[];
  isSubmitted: boolean = false;
  invalidCatName: boolean = false;
  invalidOrganizationName: boolean = false;
  organizationSuggestion: ISuggestion[];
  organizationDetails: any;
  isEdit: boolean = false;
  topics: ICreateTopic;
  json: any;
  flowName: string = '';
  isReady: boolean = false;
  constructor(
    private _router: Router,
    private fb: FormBuilder,
    private _permissionService: PermissionsService,
    private projectService: ProjectsService,
    private route: ActivatedRoute,
    private helpService: HelpService,
    private manageHelpService: ManageHelpService,
    private _userService: UserService  ) { }
  initiateForm() {

    this.formVal = this.manageHelpService.formVal;
    this.createFormGroup = this.fb.group({
      formNameCtrl: new FormControl(this.formVal ? this.formVal.topicName : "", [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator), NoWhitespaceValidator.validateWhiteSpaces, Validators.maxLength(MAX_LENGTHS.Form.Name)]),
      formDesCtrl: new FormControl(this.formVal ? this.formVal.topicDes : "", [Validators.required, Validators.pattern(REGEX_PATTERNS.description_comment), NoWhitespaceValidator.validateWhiteSpaces, Validators.maxLength(MAX_LENGTHS.Form.Description)]),
      productNameCtrl: new FormControl(this.formVal ? this.formVal.productName : "", [Validators.required]),
      jsonCtrl: new FormControl(),
      catNameCtrl: new FormControl(this.formVal ? this.formVal.topicCatName : "", [Validators.required]),
      organization: new FormControl(this.organizationDetails.organizationTitle || '', [Validators.required]),
      isRecommended: new FormControl(this.formVal ? this.formVal.isRecommended : "yes"),
      isPublishable: new FormControl(this.formVal ? this.formVal.isPublishable : "true", [Validators.required]),

    });
    this.isReady = true;
    /***************Production Selection *************************/



  }
  ngOnInit(): void {
    this._maxLength = MAX_LENGTHS;
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.isAdmin = this._permissionService.userData.isAdmin;
    this.organizationDetails = Object.assign({}, this._userService.getUserListData());

    /********************************************** */
    if (this._router.url.indexOf('edit') !== -1) {
      this.isEdit = true
      if (this.manageHelpService.formVal) {
        this.initiateForm();
      } else {
        this.route.params.subscribe((params) => {
          const category = this.helpService.getTopicById(params.id).subscribe(suggestions => {

            this.topics = suggestions;
            this.json = JSON.parse(this.topics.formJSON);
            let topicDetails: ICreateTopicForm = {
              id: params.id,
              topicDes: this.topics.description,
              organizationId: this.organizationDetails.organizationId,
              orgName: this.organizationDetails.organizationTitle,
              productName: this.topics.entityName,
              productId: this.topics.entityId,
              topicName: this.topics.title,
              json: this.topics.formJSON,
              topicCate: this.topics.helpCategoryName,
              topicCatName: this.topics.helpCategoryName,
              organization: this.organizationDetails.organizationTitle,
              isPublishable: this.topics.isPublishable.toString(),
            }

            this.manageHelpService.formVal = topicDetails;
            if (params.flowname)
              this.manageHelpService.flowName = params.flowname;
            else {
              this.manageHelpService.flowName = '';
            }
            this.initiateForm();
          });
        })
      }
    } else {
      this.initiateForm();
    }
    const productSuggestion = this.projectService.getSuggestions("products", '');
    const organization = this.projectService.getSuggestions('organizations', '')
    const category = this.projectService.getSuggestions('helpCategories', '')
    forkJoin([
      productSuggestion,
      category,
      organization

    ]).subscribe(suggestions => {
      this.productSuggestion = suggestions[0];
      this.catSuggestion = suggestions[1] as unknown as ISuggestion[];
      this.organizationSuggestion = suggestions[2] as unknown as ISuggestion[];;
      this._initDropdownsValue();
    });
    /*********************************************** */




  }
  _initDropdownsValue() {
    this.productName = this.formVal ? this.formVal.productName : "";

  }

  /********************************************************/
  saveForm() {
    this.invalidProductName = false;
    this.invalidCatName = false;
    this.invalidCatName = false;
    let productName = this.createFormGroup.get('productNameCtrl').value;
    let foundObj: number;
    if (!this.createFormGroup.valid) {
      this.isSubmitted = true;
      return;
    }

    if (productName && productName.trim().length > 0) {
      foundObj = this.projectService.fetchIdForRequest(this.productSuggestion, productName);
      if (foundObj < 1) {
        this.invalidProductName = true;
        return;
      }
    } else {
      this.invalidProductName = true;
      return;
    }
    let orgName = this.createFormGroup.get('organization').value;
    let foundOrgObj: number;

    if (orgName && orgName.trim().length > 0) {
      foundOrgObj = this.projectService.fetchIdForRequest(this.organizationSuggestion, orgName);
      if (foundOrgObj < 1) {
        this.invalidOrganizationName = true;
        return;
      }
    } else {
      this.invalidOrganizationName = true;
      return;
    }



    let catName = this.createFormGroup.get('catNameCtrl').value;
    let foundCatObj: number;
    if (catName && catName.trim().length > 0) {
      foundCatObj = this.projectService.fetchIdForRequest(this.catSuggestion, catName);
      if (foundCatObj < 1) {
        this.invalidCatName = true;
        return;
      }
    } else {
      this.invalidCatName = true;
      return;
    }
    let json = ''
    if (!isUndefined(this.formVal) && this.formVal.json != undefined) {
      json = this.formVal.json;
    }

    if (this.isEdit) {
      this.manageHelpService.formVal.topicDes = this.createFormGroup.get('formDesCtrl').value;
      this.manageHelpService.formVal.productName = productName;
      this.manageHelpService.formVal.productId = foundObj;
      this.manageHelpService.formVal.topicName = this.createFormGroup.get('formNameCtrl').value;
      this.manageHelpService.formVal.json = json;
      this.manageHelpService.formVal.topicCate = foundCatObj;
      this.manageHelpService.formVal.topicCatName = catName;
      this.manageHelpService.formVal.organization = orgName;
      this.manageHelpService.formVal.organizationId = foundOrgObj;
      this.manageHelpService.formVal.isPublishable = this.createFormGroup.get('isPublishable').value;
      this._router.navigate(['/admin/help/templateedit/' + this.manageHelpService.formVal.id]);
    }
    else {
        let values: ICreateTopicForm = {

          topicDes: this.createFormGroup.get('formDesCtrl').value,
          productName: productName,
          productId: foundObj,
          topicName: this.createFormGroup.get('formNameCtrl').value,
          json: json,
          topicCate: foundCatObj,
          topicCatName: catName,
          organization: orgName,
          isPublishable: this.createFormGroup.get('isPublishable').value,



      }
      this.manageHelpService.formVal = values;
        this._router.navigate(['/admin/help/template']);

      }
    }
  back() {
    if (this.manageHelpService.flowName == '')
      this._router.navigate(['/help/category']);
    else {
      this.manageHelpService.flowName = ''
      this._router.navigate(['/help/category/' + this.topics.helpCategoryId]);
    }

  }
}
