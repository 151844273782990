import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  template: '<app-helpCategory-form [helpCategoryId]= "helpCategoryId" [isEditMode]="true"></app-helpCategory-form>'
})

export class EditHelpCategoryComponent implements OnInit {

  helpCategoryId: number;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.helpCategoryId = params['id'];
      });
  }
}
