import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from "src/app/core/constants";
import { FileValidators } from "src/app/self-service/components/manageFileUploads/validators/file-validators";
import { FormsService } from "src/app/services/forms.service";
import { ProjectsService } from "src/app/services/projects.service";
declare var $: any;

@Component({
	selector: "replace-attachment",
	templateUrl: "./replace-attachment.component.html"
})
export class ReplaceAttachmentComponent implements OnInit {
	constructor(
		private formService: FormsService,
		private projectService: ProjectsService
	) {this._maxLength = MAX_LENGTHS;}

	assetForm: FormGroup;
	fileName: string = "";
	submitted: boolean = false;
	isError: boolean;
	errorMsg: string = "";
	_maxLength: any;

	@Input() id: number;
	@Input() formId: number;
	@Input() type:string;
	@Output() updateAttachment = new EventEmitter();
	@ViewChild('inputFileControl') inputFile: ElementRef;

	ngOnInit(): void {
		this._maxLength = MAX_LENGTHS;
		this.assetForm = new FormGroup({
      fileCtrl: new FormControl(null, [Validators.required, FileValidators.ValidateExtension, FileValidators.ValidateFileSize ])
		});
	}

  onFileChange(event) {
	this.isError = false;
	this.errorMsg = "";
    let fileCtrl = this.assetForm.get("fileCtrl");
		if (fileCtrl.untouched) fileCtrl.markAsTouched();
		const target: DataTransfer = <DataTransfer>event.target;
		let fileList: FileList = target.files;
		// Methods were added to work with 'File'
			const validSize = FileValidators.validateFileSize2(fileList.item(0));
			if (!validSize || !validSize.invalidSize) {
			if (fileList.length > 0) {
				const file = fileList.item(0);
				this.fileName = file.name;
				if (!REGEX_PATTERNS.FileName.test(this.fileName)) {
				  this.isError = true;
				  this.errorMsg = REGEX_VALIDATION_MESSAGE.FILENAME_VALIDATION_MESSAGE;
				  this.inputFile.nativeElement.value = "";
				  return;
				}
				let vct = FileValidators.ValidateContentTypeFile();
				if (vct(file).invalidType || FileValidators.validateExtensionFile(file).invalidExt) {
				  this.isError = true;
				  this.errorMsg = "Invalid file type or extension";
				  this.inputFile.nativeElement.value = "";
				}
				fileCtrl.setValue(file);
			} else {
				this.fileName = "";
				fileCtrl.setValue(null);
			}
		}
		else {
			this.isError = true;
			this.errorMsg = "File size exceeds maximum size limit i.e. 50 MB";
			this.inputFile.nativeElement.value = "";
    }
	}

	upload() {
		this.submitted = true;
		if (this.assetForm.valid) {
			const file: File = this.assetForm.get("fileCtrl").value;

			if (this.type === "response") {
				let body = {
					"responseId": this.formId,
					"file": file
				};
				this.formService.updateResponseComment(body, this.id, this.formId)
					.subscribe(
					() => {
							this.cancel();
							this.updateAttachment.emit(file.name);
						},
					(error) => {
						this.isError = true;
						this.errorMsg = error;
						this.inputFile.nativeElement.value = "";
					});
			}
			else if (this.type === "project") {
				let body = {
					"projectId": this.formId,
					"file": file
				};
				this.projectService.updateResponseComment(body, this.id, this.formId)
					.subscribe(
					() => {
							this.cancel();
							this.updateAttachment.emit(file.name);
						},
					(error) => {
						this.isError = true;
						this.errorMsg = error;
						this.inputFile.nativeElement.value = "";
					});
			}
		}
	}

	cancel() {
		this.fileName = '';
		this.assetForm.reset();
		this.submitted = false;
		this.isError= false;
		this.errorMsg = "";
		this.inputFile.nativeElement.value = "";
		$("#replaceAttachment").hide();
		$('.modal-backdrop').remove();
		$('body').addClass('modal-open');
		$("#replaceAttachment").removeClass('show');
	}

  confirm(): void {
    $('#replaceWarningModal').show();
    $("#replaceWarningModal").addClass('show');
    $('body').addClass('modal-open');
  }
}
