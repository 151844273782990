import { NgModule } from  '@angular/core';
import { CommonModule, DecimalPipe } from  '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../SharedModule/shared.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardProjectComponent } from './dashboard-project/dashboard-project.component';
import { DashboardFormComponent } from './dashboard-form/dashboard-form.component';
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { FdlTypeaheadModule } from 'fdl-ngx11-bootstrap4'; 
import { DashboardMyDashboardComponent } from './dashboard-mydashboard/dashboard-mydashboard.component';
import { AnalyticsSummaryComponent } from './dashboard-analytics/analytics-summary.component';
import { AnalyticsFormsComponent } from './dashboard-analytics/analytics-forms.component';
import { AnalyticsProjectsComponent } from './dashboard-analytics/analytics-projects.component';
import { FdlChartsModule } from 'fdl-ngx11-bootstrap4';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DashboardReportComponent } from './dashboard-report/dashboard-report.component';

@NgModule({
  declarations: [
      DashboardComponent,
      DashboardProjectComponent,
      DashboardFormComponent,
      DashboardMyDashboardComponent,
      AnalyticsSummaryComponent,
      AnalyticsFormsComponent,
      AnalyticsProjectsComponent,
      DashboardReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardRoutingModule,
    SharedModule,
    FdlTypeaheadModule,
    FdlChartsModule,
    NgxChartsModule
  ],
  providers: [DecimalPipe]
})
export class DashboardModule{}
