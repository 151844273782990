import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent implements OnInit {
  id: any;
  showId: boolean = true;
  content: any = [];
  constructor(private route: ActivatedRoute, private contentService: ContentService) { }
  ngAfterViewInit() {

  }
  ngOnInit(): void {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.id = params['id'];
        this.showId = true;
      }
    })
  }

}
