<div class="heading-container fdl-2">
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span> 
        User Groups 
      </a>
    </div>
    <h1>{{groupData?.name || 'N/A'}}</h1>
  </div>
  </div>
  
<div class="form-container separate-form-container">
        <div class="alert alert-success alert-dismissible" *ngIf="showUpdationMessage">
            <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="showUpdationMessage=false">×</button>
                <span class="iconLabel" aria-label="sucess"></span>                
                <i class="material-icons" aria-hidden="true">check_circle</i>
                <p>User group details updated successfully.
                   
                </p>
        </div> 
    <form>
        <div class="biller-survey white-box fdl-2" *ngIf="groupData">
            <button type="button" class="btn fdl-btn btn-primary-dark  pull-right" *ngIf="showEditButton" (click)="goToEdit()">Edit</button>
            <div>
                <ul>
                    <li>
                        Description:
                        <span class="info">{{groupData.description || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="biller-survey white-box fdl-2" *ngIf="groupData">
            <div>
                <ul>
                    <li>
                        Access Level:
                        <span class="info">{{groupData.accessLevel || 'Read'}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <ng-container *ngIf="groupData?.organizations?.length > 0">
            <h2 class="h5 font-weight-bold">Organization(s) Assigned</h2>
            <p class="pt-1 f-12">
                <span class="iconLabel alert-success" aria-label="success"><i class="material-icons" aria-hidden="true">check_circle</i></span>
                {{groupData?.organizations?.length}} Organization(s) Assigned</p>
                <div class="table-responsive">
                    <app-data-table *ngIf="groupData" 
                        [customClasses]="'table-layout-fixed'" 
                        [rows]="groupData.organizations" 
                        [keys]="orgKeys"
                        [displayColumns]="orgDisplayColumns" 
                        [showMenu]="false" 
                        [enableSorting]="false"
                        [showCheckbox]="false">
                    </app-data-table>                        
                </div>
        </ng-container>
        <ng-container *ngIf="groupData?.products?.length > 0">
            <h2 class="h5 font-weight-bold">Product(s) Assigned</h2>
            <p class="pt-1 f-12">
                <span class="iconLabel alert-success" aria-label="success"><i class="material-icons" aria-hidden="true">check_circle</i></span>
                {{groupData?.products?.length}} Product(s) Assigned</p>
                <div class="table-responsive">
                    <app-data-table *ngIf="groupData" 
                        [customClasses]="'table-layout-fixed'" 
                        [rows]="groupData.products" 
                        [keys]="productKeys"
                        [displayColumns]="productDisplayColumns" 
                        [showMenu]="false" 
                        [enableSorting]="false"
                        [showCheckbox]="false">
                    </app-data-table>                        
                </div>
        </ng-container>
        <ng-container *ngIf="groupData?.clients?.length > 0">
            <h2 class="h5 font-weight-bold ">Client(s) Assigned</h2>
            <p class="pt-1 f-12">
                <span class="iconLabel alert-success" aria-label="success"><i class="material-icons" aria-hidden="true">check_circle</i></span>
                {{groupData?.clients?.length}} Client(s) Assigned</p>
                <div class="table-responsive">
                    <app-data-table *ngIf="groupData" 
                        [customClasses]="'table-layout-fixed'" 
                        [rows]="groupData.clients" 
                        [keys]="clientKeys"
                        [displayColumns]="clientDisplayColumns" 
                        [showMenu]="false" 
                        [enableSorting]="false"
                        [showCheckbox]="false">
                    </app-data-table>                        
                </div>
        </ng-container>
        <ng-container *ngIf="groupData?.users?.length > 0">
            <h2 class="h5 font-weight-bold ">User(s) Assigned</h2>
            <p class="pt-1 f-12">
                <span class="iconLabel alert-success" aria-label="success"><i class="material-icons" aria-hidden="true">check_circle</i></span>
                {{groupData?.users?.length}} User(s) Assigned</p>
                <div class="table-responsive">
                    <app-data-table *ngIf="groupData"
                        [customClasses]="'table-layout-fixed'" 
                        [rows]="groupData.users" 
                        [keys]="userKeys"
                        [displayColumns]="userDisplayColumns" 
                        [showMenu]="false" 
                        [enableSorting]="false"
                        [showCheckbox]="false">
                    </app-data-table>                        
                </div>
        </ng-container>
        <div class="fdl-2 button-container">
            <div>
                <button type="button" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
            </div>
        </div>
    </form>
</div>