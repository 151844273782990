import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, OnDestroy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import * as $ from 'jquery';
import { FormGroup } from '@angular/forms';



@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent implements OnInit {

  @Input() ids: string;



  @Input() form: FormGroup;
  @Input() defaultvalue: string;
  @Input() formctrlname: string;
  @Input() options: any;
  @Input() type: string;
  filterOtions = [];
  dropDownValue;



  constructor(private cdRef: ChangeDetectorRef) {
  }



  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultvalue) {
      this.dropDownValue = changes.defaultvalue.currentValue;
    }
    //  this.dropDownValue = changes.currentValue; 
  }



  onBlur() {

    if (!($('#' + this.ids + '_dropdown').is(':visible'))) {
      $('#' + this.ids + '_dropdown').addClass('d-none');
      $('#' + this.ids + '_dropdown').removeClass('d-block');
    }
  }
  onKey(event: any) { // without type info
    setTimeout(() => {

      let value = $('#' + this.ids + '_dropdown_input').val();
      let temparray = [];
      if (value.length > 1) {
        for (let key in this.options) {
          this.options[key].title = this.options[key].title ? this.options[key].title : this.options[key].name ;
          let title = this.options[key].title.toLowerCase();
          if (title.indexOf(value.toLowerCase()) != '-1') {
            temparray.push(this.options[key]);
          }
        };
        setTimeout(() => this.filterOtions = temparray, 10);
        


      } else {
        this.filterOtions = this.options;
        this.form.patchValue({ [this.formctrlname]: null });
        this.defaultvalue = '####';
      }
    });
  }
  onClick() {
    $('#' + this.ids + '_dropdown').removeClass('d-none');
    $('#' + this.ids + '_dropdown').addClass('d-block');



  }



  formCtrlUpdate(value: any) {




    switch (this.type) {
      case 'product':
      case 'client':
      case 'form':
      case 'user':
      case 'responder':
      case 'approver':
      case 'organization': {
        this.form.patchValue({ [this.formctrlname]: value.id });
        break;
      }
      case 'org':
      default: {
        this.form.patchValue({ [this.formctrlname]: value.title ? value.title : value.name });
        break;
      }
    }
  }



  clickOption($event: any, values) {
    values.title = values.title ? values.title : values.name ;
    this.formCtrlUpdate(values);
    this.dropDownValue = values.title;
    //  $('#' + this.ids + '_dropdown_input').val(values.title);
    $('#' + this.ids + '_dropdown').addClass('d-none');
    $('#' + this.ids + '_dropdown').removeClass('d-block');



  }



  ngAfterViewChecked() {
    let idd = this.ids;
    this.filterOtions = this.options;
    //  this.cdRef.detectChanges();
    $(document).on('click', function (event) {
      let idClick = event.target.id;



      if (idClick.indexOf(idd + '_dropdown') == '-1') {



        $('#' + idd + '_dropdown').addClass('d-none');
        $('#' + idd + '_dropdown').removeClass('d-block');



      }
    });
  }
  ngOnDestroy() {
    $(document).off('click')
  }
  ngOnInit(): void {
    this.dropDownValue = this.defaultvalue;
  }



}