<div>
  <div class="heading-container">
    <h1>Confirm your Identity</h1>
  </div>


  <div class=" main-container-sub pt-3 separate-form-container m-0 pb-2">

    <div class="row">
      <div class="right-section assign-container col-12 pl-2 " style="max-width: 100%;flex: 0 0 100%;">
        <form class="form-container" [formGroup]="validateIdentity">
          <div class="alert alert-dismissible alert-danger" *ngIf="isError">
            <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
            <i class="material-icons">warning</i>
            <p>{{errorMsg}}</p>
          </div>
          <p class="otp-underlined otp-generate mb-3 strong">
            We need to confirm your identity. Please provide one of the E-mail address from below masked ones and click Next to generate a verification code that will be sent to your email.
          </p>
          <p class="otp-underlined otp-generate mb-3 strong">
            As per our records below E-mail addresses have access: <br>
            {{emailAddress}}
          </p>
          <div>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label for="email" class="asterisk">Email:</label>
                <input type="text" class="form-control" name="email" formControlName="respondentEmail" />
              </div>
            </div>

            <div class="fdl-2 button-container justify-content-end">
              <button type="button" class="btn fdl-btn btn-primary-orange"
                      (click)="generateOtp()" [disabled]="validateIdentity.invalid">
                Next
              </button>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
