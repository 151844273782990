export enum CommitmentsIntakeSearchType {
    ClientName,
    ClarityProjectNumber,
    CommitmentTitle,
    ProjectStatus,
    CommitmentType,
    CommitmentStatus,
    OpportunityID,
    OpportunityTCV,
    DUNS,
    MC,
    RelationshipManager,
    CommittedDeliveryDate,
    DataSource
}