import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validator, AbstractControl, Validators, NG_VALIDATORS, FormControl, ValidationErrors, FormGroup } from '@angular/forms';


export class NoWhitespaceValidator {

    static validateWhiteSpaces(control: AbstractControl) : ValidationErrors | null{
        const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'validateWhiteSpaces': true };
      }
}

