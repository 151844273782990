import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProjectsService } from 'src/app/services/projects.service';
import { take } from 'rxjs/operators';
import { IProjects } from 'src/app/shared/project';
import { environment } from 'src/environments/environment';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { IStatus, IProjectStatusResloved } from 'src/app/shared/status';
import { MasterService } from 'src/app/services/master.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import { ProjectsDTOService } from 'src/app/Projects/Services/projectsDTO.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from '../../../services/user.service';
import { PERMISSIONS } from '../../../core/constants';
import { IUser } from '../../../shared/user';
import { UserFilterService } from './../../../services/user.filter.service';
import { PreferenceService } from 'src/app/services/preference.service';
//import * as $ from 'jquery';
declare var $: any;
import { PutProjectStatusComponent } from 'src/app/SharedModule/Components/put-project-status/put-project-status.component';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { FdlFilterUIHepler } from 'src/app/shared/fdl-filter-ui-helper';
@Component({
  templateUrl: './project-list.component.html'
})

export class ProjectListComponent implements OnInit, OnDestroy {
  isError: boolean;
  isProjectCreated: boolean = false;
  userData: IUser;
  listname: string = PreferenceService.projectList;
  regexValidationMessage: any;

  constructor(private projectService: ProjectsService, private router: Router,
    private masterService: MasterService, private DTOService: ProjectsDTOService,
    private permissionService: PermissionsService,
    private userService: UserService,
    private route: ActivatedRoute,
    private userFilterService: UserFilterService,
    private fdlFilterHelper: FdlFilterUIHepler) { }

  selectedOrgId: number;
  filterForm: FormGroup;
  showComment: boolean = true;
  projectList: IProjects = { projects: [] };
  queryParam: IQuery;
  currentPageNumber: number = 1;
  pagerObject: IPager;
  projectName: string;

  projectStatus: IStatus[] = [];

  displayColumns = ['ID','Project Name', 'Client Name', 'Product Name', 'Project Manager', 'Status', 'Last Updated User', 'Last Updated Date'];
  keys = ['id','title', 'clientTitleWithDuns', 'productTitle', 'fiservImplementationManagerName', 'statusTitle', 'modifiedByName', 'modifiedOn'];
  userSuggestion: ISuggestions;
  projectSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  clientSuggestion: ISuggestions;
  sortingObject;
  errorMsg: string = "";
  deletedProjMsg: string = "";
  isCreateProjectAllowed: boolean = false;
  isEditProjectAllowed: boolean = false;
  isDeleteResponseAllowed: boolean = false;
  showClientFilter: boolean = false;
  showProductFilter: boolean = false;
  showStatusFilter: boolean = false;
  newProjectManagerStatus: string;
  showOrganizationFilter: boolean = false;
  organizationSuggestion: ISuggestions;
  filterExists: boolean = false;
  filtered: boolean = false;
  clientText: string;
  productText: string;
  isSubmitted = false;

  updateStatusProjectSuccessMsg: string;
  projectData: any;
  @ViewChild('putProjectStatus') putProjectStatus: PutProjectStatusComponent;

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.selectedOrgId = 0;
    this.userData = this.userService.getUserListData();
    this.newProjectManagerStatus = this.DTOService.getChangedProjectManager();
    this.initFilterForm();
    this.initQueryParams();

    const resolvedData: IProjectStatusResloved = this.route.snapshot.data['resolveData'];
    this.projectStatus = resolvedData.projectStatus;

    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route,this.filterForm, this.queryParam);
    this.callProjectListService();

    this.getSuggestions();
    this.isCreateProjectAllowed = this.permissionService.isCreateProjectAllowed();
    this.isEditProjectAllowed = this.permissionService.isEditProjectAllowed();
    this.isDeleteResponseAllowed = false;

    this.filtered = this.filterExists
  }

  canDeleteProject = (data: any): boolean => {
    if (data.fiservImplementationManagerId == this.userData.id) {
      return true;
    }
    else if (this.userData.permissions.includes(PERMISSIONS.Projects_ReadWrite_All) && data.organizationId == this.userData.organization) {
      return true;
    }

    return this.userData.isAdmin;
  }
  refresh() {
    this.callRefreshProjectListService();
  }

  getProductSuggestions(){
    if (this.showProductFilter) {
      let suggestionSubscriber: any;
      let params = [];
      let productTitle = this.filterForm.value.ProductTitle
      if(productTitle && productTitle.length > 1) {
        params.push({paramName: "title", value: productTitle});
        params.push({paramName: "orgId", value: this.selectedOrgId});
        suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
      }
      else {
        params.push({paramName: "orgId", value: this.selectedOrgId});
        suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
      }

      suggestionSubscriber.subscribe(
        data=> {
          this.productSuggestion = data;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
    }
  }

  typeaheadLoadingProducts() {
    if(this.filterForm.valid){
      this.getProductSuggestions();
    }

  }

  getSuggestions() {
    this.showClientFilter = this.permissionService.isClientFilterAllowed();
    this.showProductFilter = this.permissionService.showProductFilter();
    this.showOrganizationFilter = this.permissionService.userData.isAdmin;
    this.getProjectSuggestions();
    this.getProductSuggestions();
    this.getClientSuggestions();

    /*if (this.showClientFilter) {
      this.projectService.getSuggestions("clients", "")
        .subscribe(suggestions => {
          this.clientSuggestion = suggestions;
        },
          error => { this.isError = true; this.errorMsg = error; });
    }*/

    if (this.showOrganizationFilter) {
      this.projectService.getSuggestions("organizations", "")
        .subscribe(suggestions => {
          this.organizationSuggestion = suggestions;
        },
          error => { this.isError = true; this.errorMsg = error; });
    }

    this.projectService.getSuggestions("users", "Fiserv Implementation Manager", "role")
      .subscribe(suggestions => {
        this.userSuggestion = suggestions;
      },
        error => { this.isError = true; this.errorMsg = error; });
  }

  getClientSuggestions(){
    let suggestionSubscriber: any;
    let params = [];
    let clientTitle = this.filterForm.value.ClientTitleWithDuns
    if(clientTitle && clientTitle.length >= 3) {
      params.push({paramName: "titleWithDuns", value: clientTitle});
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", params, 100);
    }
    else {
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", null, 100);
    }

    suggestionSubscriber.subscribe(
      data=> {
        this.clientSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );

  }
  typeaheadLoadingClients() {
    if(this.filterForm.valid){
      this.getClientSuggestions();
    }

  }


  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {

    this.filterForm = new FormGroup({
      Title: new FormControl('', [Validators.pattern(REGEX_PATTERNS.project_name_validator),]),
      ProductTitle: new FormControl(''),
      ClientTitleWithDuns: new FormControl('', [Validators.pattern(REGEX_PATTERNS.client_name_validator),]),
      FiservImplementationManagerName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator),]),
      Status: new FormControl(''),
      OrganizationTitle: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator),]),
      id: new FormControl('')
    });

  }

  filterList() {
    if(this.filterForm.valid){
      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initQueryParams();
      let filterValues = this.filterForm.value;
      if (filterValues['Title'])
        this.queryParam.title = filterValues['Title']
      if (filterValues['ProductTitle'])
        this.queryParam.productTitle = filterValues['ProductTitle']
      if (filterValues['ClientTitleWithDuns'])
        this.queryParam.clientTitleWithDuns = filterValues['ClientTitleWithDuns']
      if (filterValues['FiservImplementationManagerName'])
        this.queryParam.fiservImplementationManagerName = filterValues['FiservImplementationManagerName']
      if (filterValues['Status'])
        this.queryParam.status = filterValues['Status'];
      if (filterValues['OrganizationTitle'])
        this.queryParam.organizationTitle = filterValues['OrganizationTitle'];
      if (filterValues['id'])
        this.queryParam.id = filterValues['id'];
      this.setOrderBy(this.sortingObject);

      this.callProjectListService();

      if (filterValues['OrganizationTitle'] === "") {
        this.selectedOrgId = 0;
        this.getProjectSuggestions();
        this.getProductSuggestions();
      }
      this.filtered = true
    }

  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.userFilterService.clearFilter()
    this.callProjectListService();
    this.productText = '';
    this.getProductSuggestions();
    this.fdlFilterHelper.showSearchIconWhenResetFilter();
  }

  goToProjectCreate() {
    this.DTOService.setParentType("list");
    this.router.navigate(['/projects/create/projectinfo']);
  }

  callProjectListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.projectService.getProjectList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        projects => {
          this.isError = false;
          this.projectList = projects.body as IProjects;
          if (projects.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(projects.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }

  callRefreshProjectListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.projectService.getRefreshProjectList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        projects => {
          this.isError = false;
          this.projectList = projects.body as IProjects;
          if (projects.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(projects.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.projectService.exportProjectList(this.queryParam).subscribe(
      res => {
        console.log(res.body);
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'projects.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }



  navigate(event) {
    if (event.action === "view") {
      this.router.navigate(["/projects/view", event.data]);
    }
    else if (event.action === "viewProjectTimeline") {
      this.router.navigate(['/projects/viewTimeline', event.data]);
    }
    else if (event.action === "edit") {
      this.router.navigate(["/projects/edit/projectinfo", event.data]);
    } else if (event.action === "delete") {
      this.deletedProjMsg = "";
      this.isProjectCreated = false;
      this.newProjectManagerStatus = '';
      this.projectService.deleteProject(event.data).subscribe(
        data => {
          this.isError = false;
          this.callProjectListService();
          this.getProjectSuggestions();
          this.deletedProjMsg = `The project '${event.title}' has been deleted.`;
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
    } else if (event.action === "onHold" || event.action === "reject") {
      this.projectData = this.projectList.projects.find(item => item.id === event.data);
      this.putProjectStatus.initCommentForm(event.action);
      $('#projectStatusComments').modal('show');
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callProjectListService();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callProjectListService();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  getProjectStatus() {
    let list: IStatus[] = this.masterService.getProjectStatusList();
    if (list && list.length > 0) {
      this.projectStatus = list;
    }
    else {
      this.masterService.callMasterListData().subscribe(
        data => {
          this.masterService.setMasterListData(data);
          this.projectStatus = this.masterService.getProjectStatusList();
        });
    }
  }
  ngOnDestroy(): void {
    this.DTOService.setChangedProjectManager("");
  }

  getProjectSuggestions() {
    this.projectService.getSuggestions("projects", this.selectedOrgId, "orgId")
      .subscribe(suggestions => {
        this.projectSuggestion = suggestions;
      },
        error => { this.isError = true; this.errorMsg = error; });
  }

  onOrgSelect(event: any) {
    if(event.item.id > 0)
    {
      this.selectedOrgId = event.item.id;
      this.filterForm.get('Title').setValue("");
      this.filterForm.get('ProductTitle').setValue("");
      this.getProjectSuggestions();
      this.getProductSuggestions();
    }
  }

  onUpdateProjectStatusSuccess(event: any): any {
    this.updateStatusProjectSuccessMsg = event.message;
    $('#projectStatusComments').modal('hide');
    this.projectList = null;
    this.refresh();
  }

  canUpdateProjectStatus = (data: any, statusLabel: string): boolean => {
    if (statusLabel === 'On Hold'
      && (data.statusTitle === 'On Hold'
        || data.statusTitle === 'Completed'
        || data.statusTitle === 'Rejected')) {
      return false;
    }

    if (statusLabel === 'Rejected'
      && (data.statusTitle === 'Completed' || data.statusTitle === 'Rejected')) {
      return false;
    }

    if ((data.fiservImplementationManagerId == this.userData.id)
      || (this.userData.permissions.includes(PERMISSIONS.Projects_ReadWrite_All) && data.organizationId == this.userData.organization)
      || (this.permissionService.isFiservImpAndAbove())) {
      return true;
    }

    return this.userData.isAdmin;
  }
}
