<div id="mapFulfilmentMainDiv" class="map-dw d-done">
    <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
        class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open">
        <div class="drawer-contents commitment-dw  col-xs-12 col-sm-12 col-md-12 ">
            <div class="dw-header">
                <div class="modal-header-container">
                    <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup"
                        (click)="closeDrawer()" class="arrow-dw"><span class="material-icons">
                            arrow_back
                        </span></button>
                    <h4 class="modal-title" attr.title="{{title}}">
                        {{title}}
                    </h4>
                </div>
            </div>
            <div class="form-row mt-3" *ngIf="showProjectIdSection === true">
                <div class="col-md-7 mb-3 typeahead-wrapper">
                    <label for="selectedProjectId">Project ID</label>
                    <div class="custom-search-input">
                        <div class="input-group">
                            <input class="search-query form-control fdl-ng-typeahead" placeholder="Project Id"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedProjectId" type="text">
                        </div>
                    </div>
                </div>
                <div class="col-md-2 typeahead-wrapper pt-3 pl-0 mt-1 fdl-2">
                    <button type="button" class="btn fdl-btn btn-primary-orange" [disabled]="selectedProjectId === ''"
                        (click)="loadProjectDetailForMapFulfilment()">GO</button>
                </div>
            </div>
            <!-- Error Msg Alert -->
            <div class="alert alert-dismissible alert-danger" *ngIf="errorMsg">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="errorMsg=''">×</button>
                <i class="material-icons">warning</i>
                <p [innerHtml]="errorMsg"></p>
            </div>
            <div class="dw-body" [ngClass]="{'dw-error-stage' : (errorMsg !== '')}">
                <ng-container *ngIf="pageTemplateResponseDetailsDTO !== null">
                    <div class="dw-box">
                        <div class="dw-box-title-container" role="button" data-toggle="collapse"
                            data-target="#collapseStepOne" aria-expanded="true">
                            <h3>Commitment</h3>
                        </div>
                        <div class="dw-box-content-container collapse show" id="collapseStepOne"
                            aria-labelledby="headingOne">
                            <ul class="content-list">
                                <li class="col-width col-66">
                                    <div class="title">Clarity Project Title
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.title}} </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">
                                        Project number/ID
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.id}}</div>
                                </li>
                            </ul>
                            <ul class="content-list">
                                <li class="flex-column">
                                    <div class="title">Clarity Project Description
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.description}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="dw-box">
                        <div class="dw-box-title-container" role="button" data-toggle="collapse"
                            data-target="#collapseStepTwo" aria-expanded="true">
                            <h3>Client Details</h3>
                        </div>
                        <div class="dw-box-content-container collapse show" id="collapseStepTwo"
                            aria-labelledby="headingOne">
                            <ul class="content-list">
                                <li class="col-width col-33">
                                    <div class="title">Client Name
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.clientName}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Relationship Manager/ Account Executive
                                    </div>
                                    <div class="content">
                                        {{pageTemplateResponseDetailsDTO.clientDetails.relationshipManagerName}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Commitment Type </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.commitmentType}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Segment</div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.segment}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">GlobalUltimateDUNS__c</div>
                                    <div class="content">
                                        {{pageTemplateResponseDetailsDTO.clientDetails.globalUltimateDunsC}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">D_U_N_S__c</div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.dunsC}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing Street
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.billingStreet}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing City
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.billingCity}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing Postal Code
                                    </div>
                                    <div class="content">
                                        {{pageTemplateResponseDetailsDTO.clientDetails.billingPostalCode}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing State
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.billingState}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Phone
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.phone}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing Country
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.billingCountry}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Website
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clientDetails.website}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="dw-box">
                        <div class="dw-box-title-container" role="button" data-toggle="collapse"
                            data-target="#collapseStepThree" aria-expanded="true">
                            <h3>Clarity Details
                            </h3>
                        </div>
                        <div class="dw-box-content-container collapse show" id="collapseStepThree"
                            aria-labelledby="headingOne">
                            <ul class="content-list">
                                <li class="col-width col-33">
                                    <div class="title">BU Responsible for Delivery </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clarityDetails.bu}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Product/s </div>
                                    <div class="content">
                                        {{pageTemplateResponseDetailsDTO.clarityDetails.product}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Project Manager
                                    </div>
                                    <div class="content">
                                        {{pageTemplateResponseDetailsDTO.clarityDetails.projectManager}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">MC</div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clarityDetails.mc}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Resource Manager Level4</div>
                                    <div class="content">
                                        {{pageTemplateResponseDetailsDTO.clarityDetails.resourceManagerLevel4}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Delivery Owner
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clarityDetails.deliveryOwner}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Planned/ Committed Delivery Date</div>
                                    <div class="content">
                                        {{pageTemplateResponseDetailsDTO.clarityDetails.plannedCommittedDeliveryDate}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Resource Manager Level5
                                    </div>
                                    <div class="content">
                                        {{pageTemplateResponseDetailsDTO.clarityDetails.resourceManagerLevel5}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Project Status
                                    </div>
                                    <div class="content">{{pageTemplateResponseDetailsDTO.clarityDetails.projectStatus}}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
                <div class="pt-3">
                    <div class="fdl-2 button-container">
                        <button class="btn fdl-btn btn-secondary-light dCancel" (click)="closeDrawer()"
                            id="dCancel">Cancel</button>
                        <button *ngIf="calledForMap && canMapOrUnmap" type="button"
                            class="btn fdl-btn btn-primary-orange float-right mr-4"
                            [disabled]="selectedProjectId === '' || pageTemplateResponseDetailsDTO === null"
                            (click)="onSubmit()"> Map
                        </button>
                        <button *ngIf="!calledForMap && canMapOrUnmap" type="button"
                            class="btn fdl-btn btn-primary-orange float-right mr-4"
                            [disabled]="selectedProjectId === '' || pageTemplateResponseDetailsDTO === null"
                            data-toggle="modal" data-target="#unmapModal_one"> Unmap
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="backdrop-space">
        <div class="backdrop"></div>
    </div>
</div>
<app-unmap-fulfillment-modal [responseId]="responseId"
    [projectName]="pageTemplateResponseDetailsDTO === null ? '' : pageTemplateResponseDetailsDTO.title"
    [projectId]="selectedProjectId" (unmap)="unmap()"></app-unmap-fulfillment-modal>