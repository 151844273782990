<main class="dashboard-container">
  <!-- below class (analytics-container-with-sidebar) will be use if sidebar persist -->
  <div>
    <section class="top-container">
      <article class="left-container">
        <!-- <h1>Welcome {{userDetails.name}},</h1> -->
        <p>Last login on <span>{{LastLoginTimeStamp}}</span></p>
      </article>
      <article class="right-container">
        <div class="icon-container">
          <!-- <a href="#" class="action-link"><span class="material-icons">settings</span></a> -->
        </div>
      </article>
    </section>
    <section class="dashboard-content-area">
      <article class="dashboard-tab-container">
        <ul role="tablist" class="nav nav-tabs scoped-tabs">
          <li class="nav-item">
            <a id="tab-1" role="tab" aria-selected="true" routerLink="/dashboard/mydashboard" routerLinkActive="active" data-toggle="tab"
               class="nav-link "> My Dashboard </a>
          </li>
          <li class="nav-item" *ngIf="!isRespondent">
            <a id="tab-2" role="tab" routerLink="/dashboard/analyticsSummary" routerLinkActive="active" aria-selected="false" data-toggle="tab"
               class="nav-link active show"> Analytics </a>
          </li>
          <li class="nav-item" *ngIf="canAccessReports">
            <a id="tab-3" role="tab" routerLink="/dashboard/report" routerLinkActive="active" aria-selected="false" data-toggle="tab"
               class="nav-link" active show> Reports </a>
          </li>
        </ul>
        <div id="myTabContent" class="tab-content">

          <div id="tab14" role="tabpanel" aria-labelledby="tab-2" class="tab-pane fade active show">
            <div class="tabl-heading">
              <ul class="analytics-tab-container">
                <li class="list" style="display: flex;"><a href="#" routerLink="/dashboard/analyticsSummary" routerLinkActive="active"> Summary </a></li>
                <li class="list"><a href="#" routerLink="/dashboard/analyticsProjects" routerLinkActive="active">Projects</a></li>
                <li class="list"><a href="#" routerLink="/dashboard/analyticsForms" routerLinkActive="active">Responses</a></li>
              </ul>
            </div>
            <div class="summary-container">
              <!-- <h3>Forms</h3> -->
              <ul class="summary-tiles">
                <li class="list orange">
                  <p class="label">Total Responses</p>
                  <div class="value"><p>{{totalForms}}</p></div>
                </li>
                <li class="list blue">
                  <p class="label">Not Started</p>
                  <div class="value"><p>{{notStartedForms}} </p></div>
                </li>
                <li class="list yellow">
                  <p class="label">In Progress</p>
                  <div class="value"><p>{{inProgressForms}} </p></div>
                </li>
                <li class="list keppel">
                  <p class="label">Under Review</p>
                  <div class="value"><p>{{underReviewForms}} </p></div>
                </li>
                <li class="list green">
                  <p class="label">Completed</p>
                  <div class="value"><p>{{completedForms}} </p></div>
                </li>
                <li class="list red">
                  <p class="label">Overdue</p>
                  <div class="value">
                    <p>
                      {{overdueForms}}
                    </p>
                  </div>
                </li>
                <li class="list hold">
                  <p class="label">On Hold</p>
                  <div class="value">
                    <p>
                      {{onHoldForms}}
                    </p>
                  </div>
                </li>
                <li class="list rejected">
                  <p class="label">Rejected</p>
                  <div class="value"><p>{{rejectedForms}} </p></div>
                </li>
              </ul>
            </div>
            <section class="tab-content-container">
              <article class="column-container column-2">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Responses By Status</h3>
                      <!-- <div class="action-icon">
                           <a href="#"><span class="material-icons">settings</span></a>
                      </div> -->
                    </div>
                    <!-- <div class="title-header-container">
                        <div class="value"> {{clientsCount}}</div>
                    </div> -->
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady">
                  <ngx-charts-pie-chart [view]="[containerRef.offsetWidth, 400]"
                                        [results]="responseByStatus"
                                        [legend]="true"
                                        [labels]="true"
                                        [doughnut]="true"
                                        [trimLabels]="false"
                                        [legendPosition]="legendPosition"
                                        [legendTitle]="'Response By Status'"
                                        [animations]="false"
                                        [customColors]="customColors"
                                        [labelFormatting]="pieChartResponseBysStatusLabel.bind(this, responseByStatus)">
                  </ngx-charts-pie-chart>

                </div>
              </article>

              <article class="column-container column-2" style="margin-right: 0;">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Responses Till Date By Status</h3>
                      <!--<div class="action-icon" style="margin-right: 0;">
                           
                      </div> -->
                    </div>
                    <div class="title-header-container">
                      <div><p class="mb-0">Last Six Months Data</p></div>
                    </div>
                    <!-- <div class="title-header-container">
                        <div class="value"> {{clientsCount}}</div>
                    </div> -->
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady" id="responseChart">
                  <ngx-charts-bar-vertical-stacked [view]="[containerRef.offsetWidth, 400]"
                                                   [scheme]="verticalBarColorScheme"
                                                   [results]="responseStausCountMonthwise"
                                                   [gradient]="gradient"
                                                   [xAxis]="showXAxis"
                                                   [yAxis]="showYAxis"
                                                   [legend]="showLegend"
                                                   [legendPosition]="legendPosition"
                                                   [legendTitle]="'Response Till Date By Status'"
                                                   [showXAxisLabel]="showXAxisLabel"
                                                   [showYAxisLabel]="showYAxisLabel"
                                                   [animations]="false"
                                                 
                                                   [xAxisLabel]="xAxisLabel"
                                                   [yAxisLabel]="yAxisLabel">
                  </ngx-charts-bar-vertical-stacked>
                </div>
              </article>
              <article class="column-container column-2">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Responses Published By Respondent Type</h3>
                      <!-- <div class="action-icon">
                           <a href="#"><span class="material-icons">settings</span></a>
                      </div> -->
                    </div>
                    <div class="title-header-container">
                      <div><p class="mb-0">Last Six Months Data</p></div>
                    </div>
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady">
                  <ngx-charts-bar-vertical-stacked [view]="[containerRef.offsetWidth, 400]"
                                                   [scheme]="userColorScheme"
                                                   [results]="repondentTypeMonthwise"
                                                   [gradient]="gradient"
                                                   [xAxis]="showXAxis"
                                                   [yAxis]="showYAxis"
                                                   [legend]="showLegend"
                                                   [legendPosition]="legendPosition"
                                                   [legendTitle]="'Responses Published By Respondent Type'"
                                                   [showXAxisLabel]="showXAxisLabel"
                                                   [showYAxisLabel]="showYAxisLabel"
                                                   [animations]="false"
                                                   [xAxisLabel]="xAxisLabel"
                                                   [yAxisLabel]="yAxisLabel">
                  </ngx-charts-bar-vertical-stacked>
                </div>
              </article>

              <article class="column-container column-2" style="margin-right: 0;">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Responses Published By Form Type</h3>
                      <!-- <div class="action-icon">
                           <a href="#"><span class="material-icons">settings</span></a>
                      </div> -->
                    </div>
                    <div class="title-header-container">
                      <div><p class="mb-0">Last Six Months Data</p></div>
                    </div>
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady">
                  <ngx-charts-bar-vertical-stacked [view]="[containerRef.offsetWidth, 400]"
                                                   [scheme]="formColorScheme"
                                                   [results]="responsesByFormTypeMonthwise"
                                                   [gradient]="gradient"
                                                   [xAxis]="showXAxis"
                                                   [yAxis]="showYAxis"
                                                   [legend]="showLegend"
                                                   [legendPosition]="legendPosition"
                                                   [legendTitle]="'Responses Published By Form Type'"
                                                   [showXAxisLabel]="showXAxisLabel"
                                                   [showYAxisLabel]="showYAxisLabel"
                                                   [xAxisLabel]="xAxisLabel"
                                                   [animations]="false"
                                                   [yAxisLabel]="yAxisLabel">
                  </ngx-charts-bar-vertical-stacked>
                </div>
              </article>

            </section>
          </div>
        </div>
      </article>
    </section>
  </div>
  <section class="filter-panel-container">
    <nav class="sidebar d-none active">
      <div class="sidebar-header">
        <button id="closeFilter" class="btn btn-secondary-transparent filter-icon-horizontal">
          <span class="filter-nav-text"> Filters</span><span class="right-arrow-back"></span>
        </button>
      </div>
      <form class="form form-border pd-25 ng-untouched ng-pristine ng-valid">
        <div>
          <div class="filter-form-control row">
            <div class="form-group col-lg-12">
              <div class="custom-search-input">
                <div class="input-group">
                  <input type="text" id="search" placeholder="Search"
                         class="search-query form-control" />
                  <span class="input-group-btn">
                    <button type="button" class="btn">
                      <i class="material-icons icon-after">search</i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="project">Project</label>
              <div class="fdl-select border-0">
                <select id="project" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="program">Program</label>
              <div class="fdl-select border-0">
                <select id="program" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="product">Product</label>
              <div class="fdl-select border-0">
                <select id="product" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="version">Version</label>
              <div class="fdl-select border-0">
                <select id="version" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="implementationState">Implementation State</label>
              <div class="fdl-select border-0">
                <select id="implementationState" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="projectStatus">Project Status</label>
              <div class="fdl-select border-0">
                <select id="projectStatus" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="implemntationManager">Implementation Manager</label>
              <div class="fdl-select border-0">
                <select id="implemntationManager" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row fdl-2">
            <div class="col-lg-6 mb-3 float-left">
              <button type="button" tabindex="0" aria-label="Filter"
                      class="btn btn-primary-orange">
                Filter
              </button>
            </div>
            <a href="" class="btn btn-secondary-transparent text-right pt-0 pb-3"
               style="display: flex; align-items: center;">
              Reset<span class="material-icons icon-after float-right ml-41"> chevron_right </span>
            </a>
          </div>
        </div>
      </form>
    </nav>
    <nav class="sidebar-wrapped d-none">
      <button type="button" id="sidebarCollapse"
              class="btn btn-secondary-transparent left-arrow-back sidebar-collapse-btn"></button>
      <button class="btn btn-secondary-transparent filter-icon-vertical">
        <span class="filter-right-nav-text">
          Filters
        </span>
      </button>
    </nav>
  </section>
</main>
