<div style=" max-height: 500px; overflow: auto;"
    class="main-container-sub responsive-container col-settings-drawer-alignment">
    <div class="form-container">
        <div class="row">
            <section class="col-lg-12 p-0">
                <div class="mb-3">
                    <div class="alert alert-success alert-dismissible" *ngIf="successMsg && !isError">
                        <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
                        <span class="iconLabel" aria-label="sucess"></span>
                        <i class="material-icons" aria-hidden="true">check_circle</i>
                        <p>
                          {{successMsg}}
                        </p>
                      </div>
                    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
                        <button type="button" class="close" data-dismiss="alert" aria-label="close"
                            (click)="isError=false">×</button>
                        <i class="material-icons">Warning</i>
                        <p [innerHtml]="errorMsg"></p>
                    </div>
                </div>

                <form class="form-border mb-3 box collapse-view" [formGroup]="filterForm" autocomplete="off">
                    <div class="">
                        <div>
                            <h2 class="h4  collapse-arrow" [ngClass]="[showResetFilterButton ? '':'collapsed']"
                                data-toggle="collapse" href="#collapseFilterInflight">Search</h2>
                        </div>
                    </div>

                    <div class="filter-form-container collapse filter-form-container-column2 search-filter" [ngClass]="[showResetFilterButton ? 'show':'']"
                        id="collapseFilterInflight">
                        <div class="filter-form-control typeahead-wrapper pr-4">
                            <label for="Status">ID:</label>
                            <div class="">
                                <input type="number" min="0" name="id" placeholder="Type to search" class="form-control"
                                    aria-invalid="false" formControlName="id" />

                            </div>

                        </div>
                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <div *ngIf="isSuggestionLoading">
                                <ng-template  *ngTemplateOutlet="loadingIndicator"></ng-template>
                            </div>
                            <fdl-type-ahead *ngIf="!isSuggestionLoading" label="Title:" formControlName="title" optionField="title"
                                placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" [collection]="responsesSuggestion">
                            </fdl-type-ahead>
                            <div class="has-error">
                              <span *ngIf="!filterForm.get('title').valid && filterForm.get('title').errors?.pattern && (filterForm.get('title').dirty)"><i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}</span>
                            </div>
                        </div>
                        <div id="form-responses-assignedTo-filter" class="filter-form-control pr-4 typeahead-wrapper">
                            <div *ngIf="isSuggestionLoading">
                                <ng-template  *ngTemplateOutlet="loadingIndicator"></ng-template>
                            </div>
                            <fdl-type-ahead *ngIf="!isSuggestionLoading" label="Current Assigned To:" formControlName="assignedTo"
                                optionField="name" placeholder="Type to search" [scrollable]="true"
                                [optionsLimit]="100" [minLength]="0" [collection]="usersSuggestion">
                            </fdl-type-ahead>
                            <div class="has-error">
                              <span *ngIf="!filterForm.get('assignedTo').valid && filterForm.get('assignedTo').errors?.pattern && (filterForm.get('assignedTo').dirty)"><i class="material-icons">warning</i> {{regexValidationMessage.USER_NAME_VALIDATION_MESSAGE}}</span>
                            </div>
                        </div>
                        <div id="form-responses-respondent-filter" class="filter-form-control pr-4 typeahead-wrapper">
                            <div *ngIf="isSuggestionLoading">
                                <ng-template  *ngTemplateOutlet="loadingIndicator"></ng-template>
                            </div>
                            <fdl-type-ahead  *ngIf="!isSuggestionLoading" label="Respondent:" formControlName="respondent" optionField="name"
                                placeholder="Type to search" [scrollable]="true" [optionsLimit]="100" [minLength]="0" [collection]="usersSuggestion">
                            </fdl-type-ahead>
                            <div class="has-error">
                              <span *ngIf="!filterForm.get('respondent').valid && filterForm.get('respondent').errors?.pattern && (filterForm.get('respondent').dirty)"><i class="material-icons">warning</i>{{regexValidationMessage.USER_NAME_VALIDATION_MESSAGE}}</span>
                            </div>
                        </div>
                        <div id="form-responses-approver-filter" class="filter-form-control pr-4 typeahead-wrapper">
                            <div *ngIf="isSuggestionLoading">
                                <ng-template  *ngTemplateOutlet="loadingIndicator"></ng-template>
                            </div>

                            <fdl-type-ahead  *ngIf="!isSuggestionLoading" label="Approver:" formControlName="approver" optionField="name"
                                placeholder="Type to search" [scrollable]="true" [optionsLimit]="100" [minLength]="0" [collection]="usersSuggestion">
                            </fdl-type-ahead>
                            <div class="has-error">
                              <span *ngIf="!filterForm.get('approver').valid && filterForm.get('approver').errors?.pattern && (filterForm.get('approver').dirty)"><i class="material-icons">warning</i> {{regexValidationMessage.USER_NAME_VALIDATION_MESSAGE}}</span>
                            </div>
                        </div>

                        <div class="filter-form-control pr-4">
                            <label for="Status">Status:</label>
                            <div class="">
                                <select name="Status" class="form-control" aria-invalid="false"
                                    formControlName="status">
                                    <option value="All">All</option>
                                    <option *ngFor="let status of formStatus" [value]="status.title">{{status.title}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="buttom-wrapper">
                            <button type="button" class="btn fdl-btn btn-primary-dark" aria-label="Search"
                                (click)="doSearch()">Search</button>
                        </div>

                        <div class="ml-2 buttom-wrapper" *ngIf="showResetFilterButton">
                            <button type="button" class="btn fdl-btn btn-primary-dark" aria-label="Reset"
                                (click)="resetFilter()">Reset</button>
                        </div>
                    </div>
                </form>
                <div class="d-flex">
                    <div class="mr-3" *ngIf="isSearching else totalText">
                        <ng-template *ngTemplateOutlet="loadingIndicator"></ng-template>
                    </div>
                    <ng-template #totalText>
                        <div class="">
                            <p>Total {{pagerObject.totalCount}} Form Responses</p>
                        </div>
                    </ng-template>
                </div>

                <div class="table-responsive form-responces s-overflow-auto">
                    <app-data-table [rows]="inflightForms" [keys]="inflightFormsKeys"
                        [displayColumns]="inflightFormsHeaders" [enableSorting]="false" [showCheckbox]="true"
                        [showMenu]="false" [showViewButton]="false" [counter]="0" (responsesCount)="onSelectedChanged($event)">
                    </app-data-table>
                    <div>
                        <app-pagination
                            *ngIf="pagerObject && pagerObject.totalPages > 1 && inflightForms && inflightForms.length > 0"
                            [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div class="modal-footer fdl-2">
        <button type="button" (click)="onCloseListModal()" class="btn fdl-btn btn-secondary-light"
            data-dismiss="modal">Cancel</button>
        <button [disabled]="!this.isUpdateAllowed || !this.hasSelectedItems || this.isSearching"  type="button" (click)="onConfirm()"
            class="btn fdl-btn btn-primary-orange"><span>Update</span></button>
    </div>
</div>

<div #confirmInflightForms id="confirmInflightForms" data-backdrop="static" data-keyboard="false" tabindex="-1"
    role="dialog" aria-labelledby="myLargeModalLabel" class="modal show fade bd-example-modal-md" aria-hidden="true">
    <div role="document" class="modal-dialog modal-md" style="max-width: 680px;">
        <div class="modal-content">
            <div class="modal-body text-center mt-3">
                Are you sure you want to update the selected responses ?
            </div>

            <div class="modal-footer fdl-2">
                <button type="button" (click)="onCloseConfirmModal()" class="btn fdl-btn btn-secondary-light">Cancel</button>

                <div class="mr-3" *ngIf="isUpdating else submitText">
                    <ng-template *ngTemplateOutlet="loadingIndicator"></ng-template>
                </div>
                <ng-template #submitText>
                    <button type="button" (click)="onUpdate()"
                    class="btn fdl-btn btn-primary-orange"><span>Submit</span></button>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingIndicator>
    <div class="refresh-icon-link">
        <a title="Refresh Form Template List" id="refreshTask" class="fa-rotation"><i
                aria-hidden="true" class="fa fa-refresh"></i></a>
    </div>
</ng-template>
