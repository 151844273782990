import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { ClientsService } from "../../../services/clients.service";
import { IClients } from 'src/app/shared/client';
import { FormGroup, FormControl } from '@angular/forms';
import { ProjectsService } from 'src/app/services/projects.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import { ProjectsDTOService } from 'src/app/Projects/Services/projectsDTO.service';
import { UserService } from 'src/app/services/user.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css']

})
export class ClientInfoComponent implements OnInit {
  clientList: IClients;
  projectData: any;
  projectValues: any;
  clientInfo: any;
  updatedProject: any = {};
  clientForm: FormGroup;
  clientNames: ISuggestions;
  isSubmitted: boolean = false;
  clientSuggestion: ISuggestions;
  filteredClientList: any = [];
  clientContactList: ISuggestions = null;
  CCL: any = [];
  clientsParam: any = {};
  userData: any = {};
  clientName_defaultValue: string;
  clientManager_defaultValue: string;
  isError: boolean;
  errorMsg: string = '';

  successMsg: string;
  clientNameFromDrawer: string;
  loadDrawer: boolean = false;
  showSuccessMsg: boolean = false;
  createClientAllowed: boolean = false;
  clientAddressDetails: any;
  contactEmail: string = '';
  isCopyProject: boolean = false;
  selectCopyProject: any = {};
  showClear: boolean = false;
  selectedClientValue: string ='';
  selectedClientId: number = 0;
  private destroySubject = new Subject();

  constructor(private router: Router, private clientsService: ClientsService,
    private projectsService: ProjectsService, private DTOService: ProjectsDTOService,
    private userService: UserService, private permissionService: PermissionsService,
    private drawerService: ProjectDrawerService) { }


  ngOnInit(): void {
    this.selectCopyProject = this.projectsService.getProjectDetails();
    $('#step1').attr('class', 'completed');
    $('#step2').attr('class', 'active');

    this.userData = this.userService.getUserListData();

    this.projectValues = this.DTOService.getProjectFacts();
    this.getSuggestions(this.projectValues?.clientTitle);

    /********************EDIT BEGIN*************************/

    if (this.DTOService.getParentType() === "edit" &&
      this.projectValues && this.projectValues.clientTitle && this.projectValues.clientTitle !== null) {
      this.clientsService.getClientContactsById(this.projectValues.clientId).subscribe(
        clients => {
          this.isError = false;
          this.clientContactList = clients;

         this.getContactEmail();
        },
        error => { this.isError = true, this.errorMsg = error }
      )
    }
    /********************EDIT END*************************/


    this.clientForm = new FormGroup({
      'clientName': new FormControl(this.projectValues?.clientTitleWithDuns || ''),
      'clientDetails': new FormGroup({
        'dunsId': new FormControl(this.projectValues?.dunsId || ''),
        'streetAddress1': new FormControl(this.projectValues?.streetAddress1 || ''),
        'streetAddress2': new FormControl(this.projectValues?.streetAddress2 || ''),
        'city': new FormControl(this.projectValues?.city || ''),
        'state': new FormControl(this.projectValues?.state || ''),
        'zipCode': new FormControl(this.projectValues?.zipCode || ''),
        'country': new FormControl(this.projectValues?.country || ''),
      }),
      'clientContactName': new FormControl(this.projectValues?.clientImplementationManagerId ? this.projectValues?.clientImplementationManagerName : '')
    });

    if (this.DTOService.getParentType() !== "edit") {
      if (this.selectCopyProject.id !== 0) {
        this.clientForm.controls['clientName'].setValue(this.selectCopyProject.clientTitleWithDuns);
        this.onClientAutofill(this.selectCopyProject.clientId);
      }
      if (this.selectCopyProject.id !== null && this.selectCopyProject.id !== undefined) {
        this.isCopyProject = true;        
      }      
    }

    //this.clientForm.get('clientName').valueChanges.subscribe(
    //  (value) => {
        
    //    value = value === null || value === "null" ? '' : value;
    //    if (value !== '') {
          
    //    }
    //    else { ///this is applied when clientName is set as blank or null                   
    //      this.clientForm.get('clientDetails').reset();
    //      this.clientManager_defaultValue = '';
    //      this.clientForm.get('clientContactName').reset();  //value of CM gets hidden but the form retain its value so, reset.                
    //      this.clientContactList = null;
    //    }
    //  })
  }

  // autoFill(){
  //   this.clientForm.controls['clientName'].setValue(this.selectCopyProject.clientTitle);
  //   this.clientForm.controls['dunsId'].setValue(this.selectCopyProject.clientAddress.dunsId);
  //   this.clientForm.controls['streetAddress1'].setValue(this.selectCopyProject.clientAddress.streetAddress1);
  //   this.clientForm.controls['streetAddress2'].setValue(this.selectCopyProject.clientAddress.streetAddress2);
  //   this.clientForm.controls['city'].setValue(this.selectCopyProject.clientAddress.city);
  //   this.clientForm.controls['state'].setValue(this.selectCopyProject.clientAddress.state);
  //   this.clientForm.controls['zipCode'].setValue(this.selectCopyProject.clientAddress.zipCode);
  //   this.clientForm.controls['country'].setValue(this.selectCopyProject.clientAddress.country);
  // }

  // clearForm(){
  //   this.clientForm.controls['clientName'].setValue('');
  //   this.clientForm.controls['dunsId'].setValue('');
  //   this.clientForm.controls['streetAddress1'].setValue('');
  //   this.clientForm.controls['streetAddress2'].setValue('');
  //   this.clientForm.controls['city'].setValue('');
  //   this.clientForm.controls['state'].setValue('');
  //   this.clientForm.controls['zipCode'].setValue('');
  //   this.clientForm.controls['country'].setValue('');
  //   this.showClear = false;
  // }

  onClientContactNameChange(event) {
    if (event === '') {
      this.contactEmail = '';
    }
  }

  getContactEmail() {
    if (this.clientForm.get('clientContactName').value) {
      this.contactEmail = this.projectsService.fetchEmail(this.clientContactList, this.clientForm.get('clientContactName').value);
    }
    else this.contactEmail = '';
  }

  onClientAutofill(id) {
    this.clientsService.getClientDetailsById(id, "addresses").subscribe(
      clients => {        
        this.isError = false;
        this.clientList = clients;
        this.filteredClientList = clients;
        this.filteredClientList = this.filteredClientList.filter(clientAddr => clientAddr.addressType.toLowerCase() === "primary");
        this.selectedClientValue = this.selectCopyProject.clientTitleWithDuns;
        this.selectedClientId = this.selectCopyProject.clientId;
        this.clientForm.patchValue({
          'clientDetails': {
            'dunsId': this.filteredClientList[0]?.dunsId || '',
            'streetAddress1': this.filteredClientList[0]?.streetAddress1 || '',
            'streetAddress2': this.filteredClientList[0]?.streetAddress2 || '',
            'city': this.filteredClientList[0]?.city || '',
            'state': this.filteredClientList[0]?.state || '',
            'zipCode': this.filteredClientList[0]?.zipCode || '',
            'country': this.filteredClientList[0]?.country || ''
          },
          'clientContactName': this.filteredClientList[0].clientImplementationManagerName ? this.filteredClientList[0].clientImplementationManagerName : ''        })
        this.loadCC(id);
      },
      error => { this.isError = true, this.errorMsg = error });
  }

  loadCC(id) {
    this.clientsService.getClientContactsById(id).subscribe(
      clients => {
        console.log("clients", clients);
        this.isError = false;
        this.clientContactList = clients;
        this.CCL = clients;
        this.clientForm.get('clientContactName').reset();
        if (this.CCL.findIndex(x => x.id == this.selectCopyProject.clientImplementationManagerId) != -1) {
          this.clientForm.get('clientContactName').patchValue(this.selectCopyProject.clientImplementationManagerName);
        }
        this.getContactEmail();
      },
      error => { this.isError = true, this.errorMsg = error }
    )
  }

  onClientNameChange(event) {
    if (event === '') {
      this.clientForm.get('clientContactName').setValue('');
      this.clientForm.get('clientDetails').reset();
      this.selectedClientId = 0;
    }
  }

  onClientSelect = (event: any) => {
    this.selectedClientId = event.item.id;
    this.clientsService.getClientDetailsById(event.item.id, "addresses").subscribe(
      clients => {
        this.isError = false;
        this.clientList = clients;
        this.filteredClientList = clients;
        this.filteredClientList = this.filteredClientList.filter(clientAddr => clientAddr.addressType.toLowerCase() === "primary");
        this.selectedClientValue = event.item.titleWithDuns;
        this.clientForm.patchValue({
          'clientDetails': {
            'dunsId': this.filteredClientList[0]?.dunsId || '',
            'streetAddress1': this.filteredClientList[0]?.streetAddress1 || '',
            'streetAddress2': this.filteredClientList[0]?.streetAddress2 || '',
            'city': this.filteredClientList[0]?.city || '',
            'state': this.filteredClientList[0]?.state || '',
            'zipCode': this.filteredClientList[0]?.zipCode || '',
            'country': this.filteredClientList[0]?.country || ''
          },
          'clientContactName': this.filteredClientList[0].clientImplementationManagerName ? this.filteredClientList[0].clientImplementationManagerName : ''
        });
        this.contactEmail = '';
      },
      error => { this.isError = true, this.errorMsg = error });

    this.clientsService.getClientContactsById(event.item.id).subscribe(
      clients => {
        this.isError = false;
        this.clientContactList = clients;
        this.clientForm.get('clientContactName').reset();
        this.clientForm.get('clientContactName').patchValue(clients[0].name);
        this.getContactEmail();
      },
      error => { this.isError = true, this.errorMsg = error }
    )
  }

  typeaheadLoadingClients(searchValue : string) {
    this.getSuggestions(searchValue);
  }
  getSuggestions(searchValue : string = '') {
    let params = [];
    if(searchValue && searchValue.length >= 3) {
      params.push({paramName: "titleWithDuns", value: searchValue});
      this.projectsService.getClientSuggestions("clients", params, 100).subscribe(
        data => {
          this.isError = false;
          this.clientSuggestion = data;
          this.setClientName();
        },
        error => { this.isError = true, this.errorMsg = error }
      );
    }
    else {
      this.projectsService.getClientSuggestions("clients", null, 100).subscribe(
        data => {
          this.isError = false;
          this.clientSuggestion = data;
          this.setClientName();
        },
        error => { this.isError = true, this.errorMsg = error }
      );
    }
  }

  cancelForm() {
    if (this.DTOService.getParentType() === "edit") {
      this.router.navigate(['/projects/view', this.projectValues.id]);
    }
    else {
      this.router.navigate(['/projects/list']);
    }
  }

  saveAndContinueClientForm() {
    this.isSubmitted = true;
    if (!this.clientForm.valid) {
      return false;
    }

    let clientId = -1, clientContactId = -1;
    let clientValues = this.clientForm.value;
    if(this.selectedClientId > 0)
      clientId = this.selectedClientId
    else
      clientId = clientValues['clientName'] && this.projectsService.fetchClientIdByTitleWithDuns(this.clientSuggestion, clientValues['clientName']);
   
    clientContactId = clientValues['clientContactName'] && this.projectsService.fetchIdForRequest(this.clientContactList, clientValues['clientContactName']);

    this.clientsParam = Object.assign({}, {
      "id": this.projectValues?.id || '',
      "title": this.projectValues?.title || '',
      "description": this.projectValues?.description,
      "organizationId": this.userData?.organization || '',
      "productId": this.projectValues?.productId || '',
      "clientId": clientId,
      "startDate": this.projectValues?.startDate,
      "dueDate": this.projectValues?.dueDate,
      "fiservImplementationManagerId": this.projectValues?.fiservImplementationManagerId || '',
      "clientImplementationManagerId": clientContactId || '',
      "statusId": this.projectValues?.statusId || '',
      "rowVersion": this.projectValues?.rowVersion || '',
      "sameForms": this.projectValues?.sameForms || false
    });

    this.projectsService.updateProject(this.projectValues.id, this.clientsParam).subscribe(
      clients => {
        this.isError = false;
        this.updatedProject = clients;
        this.DTOService.setProjectFacts(this.updatedProject);
        if (this.DTOService.getParentType() !== "edit") {
          this.DTOService.setCreateProjectStatus(true);
        }
        this.projectsService.setProjectDetails();
        
        if (this.isCopyProject)
          this.router.navigate(['/projects/view', this.projectValues.id, this.selectCopyProject.id]);
        else        
          this.router.navigate(['/projects/view', this.projectValues.id]);
      },
      error => { this.isError = true, this.errorMsg = error; }
    );
  }
  /********************DRAWER CHANGES BEGIN*************************/
  openAddClientDrawer() {
    $('#containerPage').scrollTop(0);
    $('.container-page').addClass('overflow-hidden');
    this.loadDrawer = true;
  }
  ngAfterViewInit() {
    this.createClientAllowed = this.permissionService.isCreateClientAllowed();
    if (this.createClientAllowed) {
      this.subscribeDrawerService();
    }
  }
  private async subscribeDrawerService() {
    this.drawerService.closeDrawer$
      .pipe(
        takeUntil(this.destroySubject)
      )
      .subscribe(postData => {
        if (postData) {
          this.loadDrawer = false;
          if (postData['submitPressed']) {
            this.successMsg = `Client has been added.`;
            this.showSuccessMsg = true;
            this.getSuggestions(postData['entityName']);
         
            if (postData['entityName'])
              this.clientNameFromDrawer = postData['entityName'];
            if (postData['entityName'])
              this.clientNameFromDrawer = postData['entityName'];
          }
        }
      });
  }
  ngOnDestroy() {
    this.drawerService.clear();
    this.destroySubject.next();
    this.destroySubject.complete();
    this.selectedClientValue = '';
  }

  setClientName() {
    if (this.clientNameFromDrawer) {
     
      this.clientForm.controls['clientName'].setValue(this.clientNameFromDrawer);
      let clientId = this.clientNameFromDrawer && this.projectsService.fetchClientIdByTitleWithDuns(this.clientSuggestion, this.clientNameFromDrawer);
      this.onClientSelect({ 'item': { 'id': clientId}})
    }
  }
  /********************DRAWER CHANGES END*************************/
}
