import {
  Component,
  OnInit,
  EventEmitter,
  Output
} from '@angular/core';
import { PdfGenerateService } from '../services/pdf-generate.service';
declare var $: any;
import { interval, Subject, Subscription } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { FormsService } from '../services/forms.service';
import { ManageFormsService } from '../admin/manageForms/services/manageForms.service';

@Component({
  selector: 'app-pdf-genrate',
  templateUrl: './pdf-genrate.component.html',
  styleUrls: ['./pdf-genrate.component.css'],
})
export class PdfGenrateComponent implements OnInit {
  allFormData: any;
  submission: any;
  formData: any;
  formReady: boolean = false;
  spinnerSubscription: Subscription;
  killTrigger$: Subject<void> = new Subject();
  @Output() buttonClicked = new EventEmitter<{ done: boolean }>();
  @Output() onFormLoad = new EventEmitter();
  constructor(
    private _pdfGenerateService: PdfGenerateService,
    private loaderService: LoaderService,
    private formService: FormsService,
    private _manageForm: ManageFormsService,
  ) { }

  triggerPDF(formData: { form: any, submission: any, id: any, title: string, isSkip?: boolean }) {
    if (formData) {
      this.formData = formData;
    }

    this.allFormData = this.formData.form;
    this.submission = this.formData.submission;
    this.spinnerSubscription = this.loaderService.spinner$.subscribe();
    this.formReady = true;
  }

  ngOnInit(): void {
    this._pdfGenerateService.generatePdf$.subscribe((generatePdf) => {
      if (generatePdf != '-1') {
        this.triggerPDF(this.formData);
      }
    });
  }

  onFormReady(event: any) {
    this.initiateForm(event);
  }

  initiateForm(event: any) {
    let a = interval(5000).subscribe((x) => {
      const formHtml = this.processHtml();
      this.onFormLoad.emit({ formHtml, formData: this.formData });
      this.formData = {};
      this.formReady = false;
      this.spinnerSubscription.unsubscribe();
      a.unsubscribe();
    });
  }

  private processHtml() {
    $('#xyz').find('input')
      .each(function (index) {
        $(this).attr('value', $(this).val());
        const type = $(this).attr('type');
        if (type == 'radio' || type == 'checkbox') {
          const checked = $(this).is(':checked');
          if (checked) {
            $(this).attr('checked', true);
          } else {
            $(this).attr('checked', false);
          }
        }
        if (type == 'password') {
          $(this).attr('value', '********');
        }
        if (type == 'time') {
          let valueForTime = '';
          if ($(this).val() != '') {
            const timeSplit = $(this).val().split(':');
            let hours = timeSplit[0];
            const minutes = timeSplit[1];
            let meridian: string;
            if (hours > 12) {
              meridian = 'PM';
              hours -= 12;
            } else if (hours < 12) {
              meridian = 'AM';
              if (hours == 0) {
                hours = 12;
              }
            } else {
              meridian = 'PM';
            }
            valueForTime = `${hours}:${minutes} ${meridian}`;
          }
          $(this).attr('type', 'text');
          $(this).attr('value', valueForTime);
        }
        if ($(this).hasClass('formio-select-autocomplete-input')) {
          $(this).remove();
        }
      });

    $('#xyz').find('a')
      .each(function (index) {
        // //console.log(index + ": " + $(this).val());
        var href = $(this).attr('href');
        $(this).html($(this).html());
        if (href != undefined && href != '') {
          $(this).after(`<p>( ${href} )</p>`);
        } else {
          $(this).after('<p></p>');
        }
      });
    $('#xyz').find('.action-menu').remove();
    $('#xyz').find('.input-group-append').remove();
    $('#xyz').find('.choices__list--dropdown').remove();
    $('#xyz').find('button').remove();
    $('#xyz').find('select').attr('disabled', 'disabled')
    $('#xyz').find('checkbox').attr('disabled', 'disabled')

    $('#xyz').find('.formio-component-day').find('select').each(function (index) { $(this).find('option[value="' + $(this).val() + '"]').attr('selected', true) });
    // $('#xyz').find('.formio-component-day').find('select').find('option[value="' + $(this).val() + '"]').attr('selected', true);

    var $main = $('#xyz').find('table');

    let form_html = $('#xyz').html();


    if (!(this.formData.isSkip == 'true')) {
      const body = {
        htmlString: form_html,
        formTitle: this.formData.title,

        ResponseId: this.formData.id,

      };

      const fileName =  `${this.formData.title}.pdf`;
      this.formService.exportToPdf(body).subscribe(
        res => {
          // // //console.log(res);
          let newBlob = new Blob([res.body], { type: "application/pdf" });
          const nav = window.navigator as any;
          if (nav && nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(newBlob);
            //alert('done');
          } else {
            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data);
            }, 100);
            this.formData = this._manageForm.setResponseData({});
            this.formReady = false;
            //   this._router.navigate(['response/view/responses/' + this.formData.id ]);
          }
        },
        error => { }
      );


    } else {
      const body = {
        htmlString: form_html,
        formTitle: this.formData.title,

        ResponseId: this.formData.id
      }

      this.formService.exportPdfWithoutSpinner(body).subscribe(
        res => {
          this.formData = this._manageForm.setResponseData({});
          this.formReady = false;
          // //console.log(res);
          this.buttonClicked.emit({ done: true })
        }, error => {

          this.formData = this._manageForm.setResponseData({});
          this.formReady = false;
          this.buttonClicked.emit({ done: true })

        });;

      return $('#xyz').html();
    }
  }
}
