import { Component, OnInit, Renderer2, ViewChild, ElementRef } from "@angular/core";
import { DecimalPipe } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";

import { DashboardService } from "../../services/dashboard.service";
import { DashboardCommonLogic } from "../shared/dashboard-common-logic";
import { UserService } from "../../services/user.service";
import { IProjectStatusResloved } from '../../shared/status';

import { single, multi } from './component';
import { IDashboardAnalyticsSummary } from "src/app/shared/dashboardAnalyticsSummaryDTO";
import { Console } from "console";
import moment, { Moment } from 'moment';
import { IUser } from "../../shared/user";

@Component({
  selector: "app-dashboard-analytics",
  templateUrl: "./analytics-summary.component.html"
})
export class AnalyticsSummaryComponent extends DashboardCommonLogic
  implements OnInit {
  isReady: boolean = false;
  clients: any = [];
  forms: any = [];
  product: any = [];
  respondentType: any = [];
  formType: any = [];
  user: any = [];
  label: string;
  userDetails: any;
  dashboardAnalyticsSummary: IDashboardAnalyticsSummary;
  LastLoginTimeStamp: any;
  productsCount: number;
  usersCount: number;
  projectsCount: number;
  responsesCount: number;
  clientsCount: number;
  formsCount: number;

  title = 'TestCharts';
  multiClient: any = [];
  multiForm: any = [];
  multiProduct: any = [];
  view: any[] = [];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendPosition: string = 'below';
  showXAxisLabel = false;
  xAxisLabel = 'Months';
  showYAxisLabel = false;
  yAxisLabel = 'Counts';
  colorScheme = {
    domain: ['#527AA3']
  };
  autoScale = true;
  today: any;
  //Response by User Type, doughnut chart
  doughnutView: any[] = [450, 400];
  single: any = [];
  showLabels = true;
  
  constructor(
    private r: Router,
    private userService: UserService,
    private dashboardService: DashboardService
  ) {
    super(r);
  }

  ngOnInit() {
    this.userDetails = this.userService.getUserListData();
    this.today = moment().format("dddd D MMMM YYYY HH:mm");
    this.userService.callUserListData().subscribe((data) => {      
      this.getLoginTimestamp(data);
    });
    this.dashboardService.getDashBoardAnalyticsSuammary().subscribe(data => {
      this.dashboardAnalyticsSummary = data;

      //Exracted counts
      this.productsCount = this.dashboardAnalyticsSummary.productsCount;
      this.usersCount = this.dashboardAnalyticsSummary.usersCount;
      this.projectsCount = this.dashboardAnalyticsSummary.projectsCount;
      this.responsesCount = this.dashboardAnalyticsSummary.responsesCount;
      this.clientsCount = this.dashboardAnalyticsSummary.clientsCount;
      this.formsCount = this.dashboardAnalyticsSummary.formsCount;

      //Client Count of last six months
      this.dashboardAnalyticsSummary.dashboardClientData.forEach(x =>
        this.clients.push({ name: x.monthName + ' ' + x.year, value: x.count }));

      this.multiClient.push({
        name: 'Last 6 Months Trend',
        series: this.clients
      });

      //Product Count of last six months
      this.dashboardAnalyticsSummary.dashboardProductData.forEach(x =>
        this.product.push({ name: x.monthName + ' ' + x.year, value: x.count }));

      this.multiProduct.push({
        name: 'Last 6 Months Trend',
        series: this.product
      });

      //Form Count of last six months
      this.dashboardAnalyticsSummary.dashboardFormData.forEach(x => this.forms.push({ name: x.monthName + ' ' + x.year, value: x.count }));
      this.multiForm.push({
        name: 'Last 6 Months Trend',
        series: this.forms
      });

      //Response by User Type
      this.dashboardAnalyticsSummary.dashboardResponseByUserTypeData.forEach(x =>
        this.user.push({ name: x.title == 'Fiserv' ? 'Fiserv Users' : x.title == 'Client' ? 'Client Users' : x.title, value: x.count, label: x.label }));

      //Responses by Respondent Type
      this.dashboardAnalyticsSummary.dashboardResponseByRespondentTypeData.forEach(x =>
        this.respondentType.push({ name: x.title, value: x.count, label: x.label }));

      //Responses by Form Type
      this.dashboardAnalyticsSummary.dashboardResponseByFormTypeData.forEach(x =>
       this.formType.push({ name: x.type, value: x.count, label: x.label }));
      
        console.log(this.dashboardAnalyticsSummary);
      this.isReady = true;
    });
  }

  // view is the variable used to change the chart size (Ex: view = [width, height])
  pieChartUserLabel(series: any[], name: string): string {
    const item = series.filter((data) => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    return name;
  }

  getLoginTimestamp(user: IUser) {
    this.userService.getLoginData(user.userId).subscribe((data) => {
      this.LastLoginTimeStamp = moment.utc(data.body).local().format("dddd D MMMM YYYY HH:mm");
    },
      (error) => console.log("Error"));
  }

  pieChartRespondentTypeLabel(series: any[], name: string): string {
    const item = series.filter((data) => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    return name;
  }

  pieChartFormTypeLabel(series: any[], name: string): string {
    const item = series.filter((data) => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    return name;
  }

  formCustomColors() {
    let result: any[] = [];
    for (let i = 0; i < this.formType.length; i++) {
      if (this.formType[i].name == "Intake") {
        result.push({ "name": this.formType[i].name, "value": "#527AA3", "label": this.formType[i].label });
      }
      else if (this.formType[i].name == "DataGathering") {
        result.push({ "name": this.formType[i].name, "value": "#FF6600", "label": this.formType[i].label });
      }
      else {
        result.push({ "name": this.formType[i].name, "value": "#fdb81e", "label": this.formType[i].label });
      }
    }
    return result;
  }

   respondantCustomColors() {
      let result: any[] = [];
      for (let i = 0; i < this.respondentType.length; i++) {
         if (this.respondentType[i].name == "Registered Users") {
            result.push({"name": this.respondentType[i].name,"value": "#527AA3", "label": this.respondentType[i].label});
         }
          else
          {
            result.push({"name": this.respondentType[i].name,"value": "#FF6600", "label": this.respondentType[i].label});
          }
      }
      return result;
 }
 
  userCustomColors() {
    let result: any[] = [];
    for (let i = 0; i < this.user.length; i++) {
      if (this.user[i].name == "Client Users") {
          result.push({"name": this.user[i].name,"value": "#527AA3", "label": this.user[i].label});
      }
        else if (this.user[i].name == "Fiserv Users") 
        {
          result.push({"name": this.user[i].name,"value": "#FF6600", "label": this.user[i].label});
        }
        else 
          {
            result.push({"name": this.user[i].name,"value": "#fdb81e", "label": this.user[i].label});
          }
    }
    return result;
  }
}
