import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ProjectsService } from "../services/projects.service";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IQuery } from "../shared/query";
import { IPager } from "../shared/pagination";
import { MasterService } from "../services/master.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormsService } from "../services/forms.service";
import { PermissionsService } from "../services/permissions.service";
import { ManageFormsService } from "../admin/manageForms/services/manageForms.service";
import { IUser } from "../shared/user";
import { UserService } from "../services/user.service";
import { IHistorySummary, IHistorySummarys } from "../shared/history-summary";
import { Location } from "@angular/common";
import { IStatus, IResponseStatusResloved } from "src/app/shared/status";
import { Sort } from "src/app/shared/sort";
import { PERMISSIONS } from '../core/constants';

declare var $: any;

@Component({
  selector: "app-responses-history",
  templateUrl: "./responses-history.component.html"
})
export class ResponsesHistoryComponent implements OnInit, OnDestroy {
  isError: boolean;
  filterForm: FormGroup;
  responseHistoryList: IHistorySummary[];
  filteredResponseHistoryList: IHistorySummary[];
  sortedResponseHistoryList: IHistorySummary[];
  queryParam: IQuery;
  pagerObject: IPager;
  isAdmin = false;
  displayColumns = ["Version", "Modified Date", "Modified By", "Form Status"];
  keys = ["version", "modifiedOn", "modifyBy", "status"];
  currentPageNumber: number = 1;
  formResponseName: string = "";

  sortingObject;
  errorMsg: string = "";
  approvalMsg: string = "";
  userData: IUser;
  responseId: any;
  userSuggestion: Array<string> = new Array();
  responsesHistoryStatus: IStatus[] = [];
  isRevertAllowed: boolean = false;
  restoreData_id: number;
  restoreData_on: string;
  version: string;


  filterResponsesVersions: FormGroup;

  constructor(
    private projectService: ProjectsService,
    private masterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private formsService: FormsService,
    private permissionService: PermissionsService,
    private _manageForm: ManageFormsService,
    private userService: UserService,
    private location: Location,
    private formService: FormsService,
  ) { }

  ngOnInit(): void {
    this.responseId = this.route.snapshot.params["id"];
    this.userData = this.userService.getUserListData();
    this.isAdmin = this.permissionService.userData.isAdmin;
    const isSameOrg = this.formsService.historyData.organizationId === this.userService.getUserListData().organization
      && (this.userData.permissions.includes(PERMISSIONS.Responses_ReadWrite_All));
    this.isRevertAllowed = this.isAdmin || (!this.userService.isClient && !this.userService.isRespondent && isSameOrg);
    this.formResponseName = this.formsService.historyData.historyName;
    this.initFilterResponsesVersions();
    this.masterService.loadAndApplyFiltersDup(
      this.filterResponsesVersions,
      "responsesHistory"
    );
    this.callResponseHistoryListService();
    this.getResponseData();
  }

  initFilterResponsesVersions() {
    this.filterResponsesVersions = new FormGroup({
      fromDate: new FormControl(""),
      toDate: new FormControl(""),
      modifiedBy: new FormControl(""),
      status: new FormControl("")
    });
  }
  getResponseData() {
    this.formService.getResponseJson(this.responseId).subscribe(
      data => {
        this.isRevertAllowed = this.isRevertAllowed
          || (this.userData.permissions.includes(PERMISSIONS.Responses_ReadWrite_Manager)
            && data.fiservImplementationManagerId === +this.userData.id)
      }
    );
  }
  callResponseHistoryListService() {
    this.formsService
      .getResponsesHistory(this.responseId)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.isError = false;
          this.responseHistoryList = responses;
          this.filteredResponseHistoryList = JSON.parse(JSON.stringify(this.responseHistoryList));
          this.sortedResponseHistoryList = JSON.parse(JSON.stringify(this.responseHistoryList));
          this.loadSuggestions();
          this.pagerObject = {
            totalCount: this.filteredResponseHistoryList.length,
            pageSize: environment.pageSize,
            currentPage: this.currentPageNumber,
            totalPages: Math.ceil(this.filteredResponseHistoryList.length / environment.pageSize)
          };
          this.filteredResponseHistoryList = this.filteredResponseHistoryList.slice(0, this.pagerObject.pageSize);
          this.masterService.resetFiltersCancelledFlagHistory();
          const isFilterRequired = Object.keys(this.filterResponsesVersions.value).every(filter => this.filterResponsesVersions.value[filter] === "");
          if (!isFilterRequired) {
            this.filterResponsesHistory();
          }
          this.applySorting( {key: 'version', isAsc: false})
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  loadSuggestions() {
    this.getFormStatus();
    const responseHistoryListCopy = JSON.parse(JSON.stringify(this.responseHistoryList));
    this.userSuggestion = Array.from(new Set(responseHistoryListCopy.map(response => response.modifyBy)));
  }

  getFormStatus() {
    const resolvedData: IResponseStatusResloved = this.route.snapshot.data['resolveData'];
    let list: IStatus[] = resolvedData.responseStatus;
    if (list && list.length > 0) {
      this.responsesHistoryStatus = list;
    } else {
      this.masterService.callMasterListData().subscribe(
        data => {
          this.masterService.setMasterListData(data);
          this.responsesHistoryStatus = this.masterService.getResponseStatusList();
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
    }
  }

  navigate(event) {
    if (event.action === "view") {
      this.masterService.saveFiltersDup(
        this.filterResponsesVersions,
        "responsesHistory"
      );
      this.formsService.version = event.data.modifiedOn;
      if (this.route.snapshot.data.from === "Project") {
        this.router.navigate(["../version/view"], { relativeTo: this.route });
      } else {
        this.router.navigate(["../../../view", "history", event.data.id], { relativeTo: this.route });
      }
    }

    if (event.action === "revert") {
      this.restoreData_id = event.data.id;
      this.version = event.data.modifiedOn;
      $('#restoreResponse').modal('show');
    }
     else if (event.action === "viewCompare") {

      this.formsService.version = event.data.modifiedOn;
      this.router.navigate(['/response/compare', event.data.id]);
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    const sort = new Sort();
    const order = this.sortingObject.isAsc ? "asc" : "desc";
    this.sortedResponseHistoryList.sort(sort.startSort(this.sortingObject.key, order, this.sortingObject.key));
    this.filterResponsesHistory();
  }

  changePage(page) {
    this.pagerObject.currentPage = page;
    this.filterResponsesHistory();
  }

  filterResponsesHistory() {
    let filterValues = this.filterResponsesVersions.value;
    this.isError = false;
    this.errorMsg = '';
    let fromDate = new Date(filterValues["fromDate"]);
    let toDate = new Date(filterValues["toDate"]);
    if (fromDate.getTime() > toDate.getTime()) {
      this.errorMsg = "'To Date' which is in past of 'From date' not allowed.";
      this.isError = true;
      return;
    }

    this.filteredResponseHistoryList = JSON.parse(
      JSON.stringify(this.sortedResponseHistoryList)
    );

    if (filterValues["fromDate"]) {
      this.filteredResponseHistoryList = this.filteredResponseHistoryList.filter(
        response =>
          new Date(response.modifiedOn).getTime() >
          new Date(filterValues["fromDate"]).getTime()
      );
    }

    if (filterValues["toDate"]) {
      let toDate = new Date(new Date(filterValues["toDate"]).getTime() + 24 * 60 * 60 * 999.99);
      this.filteredResponseHistoryList = this.filteredResponseHistoryList.filter(
        response =>
          new Date(response.modifiedOn).getTime() <
          new Date(toDate).getTime()
      );
    }

    if (filterValues["modifiedBy"]) {
      this.filteredResponseHistoryList = this.filteredResponseHistoryList.filter(
        response => response.modifyBy === filterValues["modifiedBy"]
      );
    }
    if (filterValues["status"]) {
      this.filteredResponseHistoryList = this.filteredResponseHistoryList.filter(
        response => response.status === filterValues["status"]
      );
    }
     this.pagerObject.totalPages = Math.ceil(this.filteredResponseHistoryList.length / environment.pageSize);
    this.pagerObject = JSON.parse(JSON.stringify(this.pagerObject));
    if (this.pagerObject.currentPage > this.pagerObject.totalPages)
      this.pagerObject.currentPage=this.pagerObject.totalPages;
    if (this.pagerObject.currentPage < 1)
      this.pagerObject.currentPage=1;

    const startIdx = this.pagerObject.pageSize * (this.pagerObject.currentPage - 1);
    const endIdx = (this.pagerObject.pageSize * this.pagerObject.currentPage);
    if (this.pagerObject.totalPages > 1)
      this.filteredResponseHistoryList = this.filteredResponseHistoryList.slice(startIdx, endIdx);
    else
      this.filteredResponseHistoryList = this.filteredResponseHistoryList.slice(0, 10);


  }

  goBack() {
    this.masterService.isCancelledClicked(true);

    if (this.route.snapshot.data["from"] === "Project") {
      this.router.navigate(["../../../../"], { relativeTo: this.route });
    } else {
      this.router.navigate(["../../../list"], { relativeTo: this.route });
    }
  }

  ngOnDestroy(): void {
    this._manageForm.setDelegateSuccessMsg("");
    this._manageForm.approvalMsg = "";
  }


  restoreOk() {
    this.formsService
      .updateResponsesHistoryVersion(this.restoreData_id, this.version)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.isError = false;
          this.approvalMsg = "Form has been reverted to previous version.";
          this.masterService.resetFiltersCancelledFlag();
          this.callResponseHistoryListService();
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }
}
