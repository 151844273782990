import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ISuggestions } from 'src/app/shared/suggestion';
import { ProjectsService } from 'src/app/services/projects.service';
import { MasterService } from 'src/app/services/master.service';
import { IWorkflowExecutionList } from 'src/app/shared/workflow';
import { WorkflowService } from 'src/app/services/workflow.service';
import { PreferenceService } from 'src/app/services/preference.service';
declare var $: any;
@Component({
  selector: 'workflow-execution',
  templateUrl: './workflow-execution.component.html',
  styleUrls: ['./workflow-execution.component.css']
})
export class WorkflowExecutionComponent  implements OnInit{

  constructor(
    private router: Router,
    private projectService: ProjectsService,
    private workflowService: WorkflowService,
    private masterService: MasterService,
    private preferenceService: PreferenceService
  ) { }

    filterForm: FormGroup;
    workflowList: IWorkflowExecutionList;
    queryParam: IQuery;
    currentPageNumber: number = 1;
    pagerObject:IPager;
    sortingObject;
    isError:boolean;
    errorMsg: string = "";
    successMsg: string;
    clientSuggestion: ISuggestions;
    organizationSuggestion: ISuggestions;
    productSuggestion: ISuggestions;
    formSuggestion: ISuggestions;
    jobSuggestion: ISuggestions;
    listname: any;
    displayColumns = ['Job Name', 'Start Time', 'End Time', 'Status', 'Reference Id', 'Reference Type', 'Correlation Id'];
    keys = ['name', 'startTime', 'endTime', 'status', 'refrenceId', 'refrenceType','correlationId'];

    selectedOrgId: number;
    filtered: boolean = false

    ngOnInit(): void {
      this.selectedOrgId = 0;
      this.initFilterForm();
      this.initQueryParams();
      this.masterService.loadAndApplyFilters(this.queryParam, this.filterForm);
      this.listname = PreferenceService.workflowExecutionList;
      this.getClientSuggestions();
      this.getOrganizationSuggestions();
      this.getProductSuggestions();
      this.getFormSuggestions();
      this.getJobSuggestions();
      this.callWorkflowListService();
  }

  refresh() {
    this.callWorkflowListService();
  }
    initFilterForm() {
      this.filterForm = new FormGroup({
        OrgTitle: new FormControl(''),
        Product: new FormControl(''),
        ClientTitle:new FormControl(''),
        FormTitle: new FormControl(''),
        JobTitle: new FormControl(''),
        CorrelationId: new FormControl(''),
        ReferenceId: new FormControl('')
      });
    }

    initQueryParams() {
      this.queryParam = Object.assign({},{
        pageNumber: this.currentPageNumber,
        pageSize: environment.pageSize
      });
    }

    filterList(){
      this.initQueryParams();
      let filterValues = this.filterForm.value;
      if (filterValues['OrgTitle'])
        this.queryParam.organizationTitle = filterValues['OrgTitle']
      if (filterValues['Product'])
        this.queryParam.productTitle = filterValues['Product']
      if (filterValues['ClientTitle'])
        this.queryParam.clientTitle = filterValues['ClientTitle']
      if (filterValues['FormTitle'])
        this.queryParam.formTitle = filterValues['FormTitle']
      if(filterValues['JobTitle'])
        this.queryParam.jobTitle = filterValues['JobTitle']
      if(filterValues['CorrelationId'])
        this.queryParam.id = filterValues['CorrelationId']
      if(filterValues['ReferenceId'])
        this.queryParam.referenceId = filterValues['ReferenceId']

      this.setOrderBy(this.sortingObject);
      this.callWorkflowListService();

      if (filterValues['OrgTitle'] === "") {
        this.selectedOrgId = 0;
        this.getProductSuggestions();
        this.getFormSuggestions();
      }
      this.filtered = true
    }

    clearFilterList(){
      this.filtered = false
      this.initQueryParams()
      this.initFilterForm();
      this.callWorkflowListService();
    }

    callWorkflowListService() {
      $('#refreshTask').addClass('fa-rotation');
      this.workflowService.getWorkflowExecutionList (this.queryParam)
        .pipe(take(1))
        .subscribe(
          workflows => {
            this.isError = false;
            this.workflowList = workflows.body as IWorkflowExecutionList;
            if(workflows.headers.get('X-pagination')){
              this.pagerObject = Object.assign({},JSON.parse(workflows.headers.get('X-pagination')) as IPager);
            }
            this.masterService.resetFiltersCancelledFlag();
            $('#refreshTask').removeClass('fa-rotation');
          },
        (error)=> { this.isError = true; this.errorMsg = error; });
    }

    navigate(event){
      if (event.action === "view") {
        this.masterService.saveFilters(this.filterForm);
        this.router.navigate(['/self-service/workflowExecution', event.data,'view']);
      }
    }

    setOrderBy(sortingData){
      if(sortingData){
        this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
      }
    }

    applySorting(event){
      this.sortingObject = event;
      this.setOrderBy(this.sortingObject);
      this.callWorkflowListService();
    }

    changePage(page){
      this.queryParam.pageNumber = page;
      this.callWorkflowListService();
    }

    getClientSuggestions(){
      this.projectService.getSuggestions('clients','')
      .subscribe(suggestions => {
        this.clientSuggestion = suggestions;
      },
      (error)=> { this.isError = true; this.errorMsg = error; });
    }

    getOrganizationSuggestions() {
      this.projectService.getSuggestions('organizations', '')
        .subscribe(suggestions =>{
          this.organizationSuggestion = suggestions;
        },
        error => { this.isError = true; this.errorMsg = error; });
    }

    getProductSuggestions() {
      this.projectService.getSuggestions('products', this.selectedOrgId, "orgId")
        .subscribe(suggestions => {
          this.productSuggestion = suggestions;
        },
          (error) => { this.isError = true; this.errorMsg = error; });
    }

    getFormSuggestions() {
      this.projectService.getSuggestions('forms', this.selectedOrgId, "orgId")
      .subscribe(suggestions => {
        this.formSuggestion = suggestions;
      },
        error => { this.isError = true; this.errorMsg = error; });
    }

    getJobSuggestions() {
      this.projectService.getSuggestions('workflowExecution', '')
      .subscribe(suggestions => {
        this.jobSuggestion = suggestions;
      },
        error => { this.isError = true; this.errorMsg = error; });
    }

    onOrgSelect(event: any) {
      if(event.item.id > 0)
      {
        this.selectedOrgId = event.item.id;
        this.filterForm.get('Product').setValue("");
        this.filterForm.get('FormTitle').setValue("");
        this.getProductSuggestions();
        this.getFormSuggestions();
      }
    }
}
