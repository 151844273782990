import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ISuggestions } from 'src/app/shared/suggestion';
import { MAX_LENGTHS, REGEX_PATTERNS, TemplateType, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { FormExtractionTemplateService } from 'src/app/services/form-extraction-template.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { FileValidators } from 'src/app/self-service/components/manageFileUploads/validators/file-validators';

@Component({
  selector: 'formExtractionTemplate',
  templateUrl: './form-template.component.html'
})
export class FormExtractionTemplateComponent implements OnInit, OnDestroy {
  returnPage: string;
  _formData: any;
  templates: any;
  formNameSuggestion: ISuggestions;
  fileTemplateForm: FormGroup;

  submitted: boolean = false;
	isError: boolean = false;
  isSuccess: boolean = false;
  validFile: boolean = false;
	errorMsg: string = "";
  successMsg: string = "";
  _maxLength: any;
  selectedFormId: any;
  selectedFormName: string;
  selectedTemplateType: string;
  selectedTemplate: string;
  selectedFileName: string;
  selectedTemplateName: string;
  formId: number;
  @ViewChild('inputFileControl') inputFile: ElementRef;
  validCommentFileName: boolean = true;
  regexValidationMessage: any;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private formExtractionService: FormExtractionTemplateService,
    private projectService: ProjectsService,
    private fb: FormBuilder
  )  {this._maxLength = MAX_LENGTHS;};

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.templates = Object.values(TemplateType).filter(value => typeof value !== 'number');

    this.selectedFormId = "";
    this.selectedFormName = "";
    this.selectedTemplateType = "";
    this.selectedTemplate = "";
    this.selectedFileName = "";
    this.selectedTemplateName = "";

    this.initFilterForm();
    this.getFormSuggestions();
    this.filterForm();
    this._formData = this.formExtractionService.formData;
    if (this._formData) {
      this.isSuccess = true;
      this.successMsg = "The Form '" + this._formData.title +  (this._formData.created ? "' has been updated." : "' has been created.") + " Please upload the Form Extraction Template.";
      this.selectForm();
    }

    if (this.formId > -1)
      this.returnPage = 'Form Templates';
    else // if (this.formExtractionService.tempData)
      this.returnPage = 'Template(s) List';
    // else this.returnPage = 'Self Service';
  }

  ngOnDestroy(): void {
    this.resetFiles();
    this.formExtractionService.formData = "";
    this.isSuccess = false;
    this.successMsg = "";
    this.formExtractionService.tempData = "";
  }

  NotNow() {
    this.resetFiles();
    this.formExtractionService.formData = "";
    this.isSuccess = false;
    this.successMsg = "";
    this.router.navigate(['admin/form/list']);
  }

  selectForm() {
    this.formId = this._formData.id;
    this.projectService.getFormById(this.formId)
    .pipe(take(1))
    .subscribe(
      form => {
        this.fileTemplateForm.controls['formName'].setValue(form.title);
        this.selectedFormId = this.formId;
        this.selectedFormName = form.title;
      },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  filterForm() {
    this.route.params
    .pipe(take(1))
    .subscribe((params) => {
      this.formId = params['id'];
      if (this.formId > -1) {
        this.projectService.getFormById(this.formId)
        .pipe(take(1))
        .subscribe(
          form => {
            this.fileTemplateForm.controls['formName'].setValue(form.title);
            this.selectedFormId = this.formId;
            this.selectedFormName = form.title;
          },
          (error) => { this.isError = true; this.errorMsg = error; });
      }
    });
  }

  initFilterForm() {
		this.fileTemplateForm = this.fb.group({
			fileName: new FormControl('', [Validators.required]),
      formName: new FormControl('', [Validators.required, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator), NoWhitespaceValidator.validateWhiteSpaces]),
      templateType: new FormControl({ value: ''}, [Validators.required]),
      templateName: ["", [Validators.required, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator), Validators.maxLength(MAX_LENGTHS.File.Alias), NoWhitespaceValidator.validateWhiteSpaces]],
		});
	}

  getFormSuggestions() {
    this.projectService.getSuggestions('forms','')
    .subscribe(suggestions => {
    this.formNameSuggestion = suggestions;
    },
    (error) => { this.isError = true; this.errorMsg = error; });
  }

  goBack() {
    this.formExtractionService.tempData = ""
    this.router.navigate(['/self-service/formExtracts']);
  }

  Submit() {
		this.submitted = true;
    if (this.fileTemplateForm.invalid) {
      return false;
    }
    
    if (this.selectedFileName && this.selectedFormId && this.selectedTemplateName && this.selectedTemplateType && this.validFile && this.validCommentFileName) {
      this.isError = false;
      this.errorMsg= "";
      const submitData = this.setFormParams();
      this.formExtractionService.createFormTemplate(submitData)
      .subscribe(
				() => {
					this.onCreateComplete();
          if (!this._formData){
            this.formExtractionService.isTemplateCreated = `The Form Extraction Template - '${this.selectedTemplateName}' has been created.`;
            this.goBack();
          }
          else {
            this.NotNow();
          }
				},
				error => {
					this.isError = true;
					this.errorMsg = error;
				}
      );
    }
    else {
      this.isSuccess = false;
      this.isError = true;
      this.errorMsg= "Please fix the below errors.";
    }
  }

  private setFormParams(){
    let formData = {
			file: this.fileTemplateForm.controls.fileName.value,
      formId: this.selectedFormId,
      templateName: this.selectedTemplateName,
      templateUrl: "",
      isActive: true,
      extractionType: this.selectedTemplateType,
    }
    return formData;
  }

  private onCreateComplete(): void {
    this.resetError();
    this.submitted = false;
    this.resetFiles();
  }

  onFileChange(event) {
    this.resetError();
    let fileName = this.fileTemplateForm.get("fileName");
		if (fileName.untouched) fileName.markAsTouched();

    const target: DataTransfer = <DataTransfer>event.target;
		let fileList: FileList = target.files;
    let maxFileSize = MAX_LENGTHS.File.Size;
    if (fileList.item(0).size <= (maxFileSize * 1024 * 1024)) {
      if (fileList.length > 0) {
        const file = fileList.item(0);
        this.selectedFileName = file.name;
        fileName.setValue(file);
        this.validFile = this.validateFileExtension(this.selectedFileName, this.selectedTemplate);
        this.validCommentFileName = FileValidators.ValidateFileName(file.name);
      }
      else {
        this.resetFiles();
        fileName.setValue(null);
      }
    }
    else {
			this.isError = true;
			this.errorMsg = "File size exceeds maximum size limit i.e. 50 MB";
			this.inputFile.nativeElement.value = "";
		}
  }

  onFormSelect(event: any) {
    this.resetError();
    this.selectedFormId = event.item.id;
    this.selectedFormName = event.value;
  }

  onTemplateSelect(event: any) {
    let templateType = this.fileTemplateForm.get("templateType");
    this.resetError();
    this.resetFiles();

    if (event.target.value != '') {
		  if (templateType.untouched) templateType.markAsTouched();
      this.selectedTemplateType = event.target.value;
      this.selectedTemplate = this.selectedTemplateType === 'CSV' ? "csv" : this.selectedTemplateType === 'Excel' ? "xls" : this.selectedTemplateType === 'Xml' ? "xml" : this.selectedTemplateType === 'Pdf' ? "pdf" : this.selectedTemplateType === 'Json' ? "json" : this.selectedTemplateType === 'Word' ? "doc" :"";
      this.inputFile.nativeElement.value = "";
    }
    else {
      if (templateType.touched) templateType.markAsUntouched();
      this.selectedTemplateType = "";
      this.selectedTemplate = "";
    }
  }

  validateFileExtension(name: String, fileExt: String) {
    let ext: string = name.substring(name.lastIndexOf('.') + 1);
    if (fileExt === 'pdf' && ext.toLowerCase().includes('doc'))
      return true;
    else
      return (ext.toLowerCase().includes(fileExt.valueOf()));
  }

  resetFiles() {
    this.selectedFileName = "";
    this.fileTemplateForm.controls.fileName.setValue(null);
    this.fileTemplateForm.controls.fileName.markAsUntouched();
    this.submitted = false;
    this.validFile = false;
    this.validCommentFileName = false;
  }

  resetError() {
    this.isError = false;
    this.errorMsg= "";
  }

  downloadTemplate() {
    let link = document.createElement("a");
    link.setAttribute('type', 'hidden');
    if (this.selectedTemplateType === 'CSV') {
      link.download = "Csv_Form_Extraction_Template.csv";
      link.href = "assets/Csv_Form_Extraction_Template.csv";
    }
    else if (this.selectedTemplateType === 'Excel') {
      link.download = "Excel_Form_Extraction_Template.xlsx";
      link.href = "assets/Excel_Form_Extraction_Template.xlsx";
    }
    else if (this.selectedTemplateType === 'Xml') {
      link.download = "Xml_Form_Extraction_Template.xml";
      link.href = "assets/Xml_Form_Extraction_Template.xml";
    }
    else if (this.selectedTemplateType === 'Pdf' || this.selectedTemplateType === 'Word') {
      link.download = "Word_Form_Extraction_Template.docx";
      link.href = "assets/Word_Form_Extraction_Template.docx";
    }
    else if (this.selectedTemplateType === 'Json') {
      link.download = "Json_Form_Extraction_Template.json";
      link.href = "assets/Json_Form_Extraction_Template.json";
    }
    link.click();
    link.remove();
  }
}
