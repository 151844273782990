<div class="heading-container">
  <div class="back-action-container">
      <div class="back-button">
          <a href="javascript:void(0);" (click)="goBack()">
              <span class="material-icons"> arrow_back </span> 
              Response Extracts List 
          </a>
      </div>
      <h1>{{extractRequestName}}</h1>
  </div>
</div>

<div>
    <div class="form-row">
        <div class="col-md-3 mb-2 ml-3">
            <span> <b> Extract Type: </b> </span> {{extractType}}
        </div>
        <div class="col-md-3 mb-2 ml-3">
            <span> <b> Is CoverSheet Required: </b> </span> {{isCoverSheet}}
        </div>
        <div class="col-md-3 mb-2 ml-3">
            <span> <b> Is Comment Needed: </b> </span> {{isComment}}
        </div>
    </div>
</div>

<div class="form-container">
    <div class="data-table-container">
        <div class="justify-content-between d-flex">
          <div class="headline-container">
            <h2 class="h4">Requested Response(s) List</h2>
            <!-- <p>Total {{responsesList.Count}} Items</p> -->
          </div>
        </div>
        <div class="table-responsive overflow-auto requested-responses-list">
            <app-data-table
                *ngIf="responsesList" 
                [rows]="responsesList"
                [keys]="keys"
                [displayColumns]="displayColumns"
                (sortingClicked)="applySorting($event)"
                [showCheckbox]="false"
                [showMenu]="false"
                type="Response Extract"
            ></app-data-table>
            <!-- <app-pagination
                *ngIf="pagerObject && pagerObject.totalPages > 1 && responsesList.requests && responsesList.requests.length > 0"
                [pagerObject]="pagerObject"
                (pagingChanged)="changePage($event)"
            ></app-pagination> -->
        </div>
    </div>
</div>