import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { UserService } from "../../services/user.service";
import { DashboardService } from "../../services/dashboard.service";
import { DashboardCommonLogic } from "../shared/dashboard-common-logic";
import { IDashboardAnalyticsForm } from "src/app/shared/dashboardAnalyticsFormDTO";
import moment from 'moment';
import { IUser } from "../../shared/user";

@Component({
  selector: 'app-analytics-forms',
  templateUrl: './analytics-forms.component.html'
})
export class AnalyticsFormsComponent extends DashboardCommonLogic
  implements OnInit {

  //Class Level Variables
  isReady: boolean = false;
  userDetails: any;
  dashboardAnalyticsForm: IDashboardAnalyticsForm;
  LastLoginTimeStamp: any;
  responseByStatus: any = [];
  responseStausCountMonthwise: any = [];
  repondentTypeMonthwise: any = [];
  responsesByFormTypeMonthwise: any = [];

  totalForms: number;
  completedForms: number;
  inProgressForms: number;
  notStartedForms: number;
  overdueForms: number;
  underReviewForms: number;
  onHoldForms: number;
  rejectedForms: number;

  monthwiseCustomColors: any;
  customColors: any;

  isRespondent: boolean = false;
  canAccessReports: boolean = false;

  title = 'TestCharts';
  multiClient: any = [];
  multiForm: any = [];
  view: any[] = [680, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  legendPosition: string = 'below';
  showXAxisLabel = false;
  xAxisLabel = 'Months';
  showYAxisLabel = false;
  yAxisLabel = 'Counts';
  colorScheme = {
    domain: ['#527AA3']
  };
  autoScale = true;
  verticalBarColorScheme = {
    domain: ['#2e8540', '#fdb81e', '#0071bc', '#16414E', '#cd2026', '#752F00', '#800080']
  };

  responsesColorScheme = {
    domain: ['#FF6600']
  };
  userColorScheme = {
    domain: ['#FF6600', '#527AA3', '#293D52']
  };
  formColorScheme = {
    domain: ['#527AA3', '#FF6600', '#293D52']
  }; 

  roleToAccessReports = ["Division Leader", "BU Leader", "Delivery Owner", "Delivery Leader", "Relationship Manager", "CCT Admin", "Restricted Admin", "Sales Executive", "Account Team"];
  //Response by User Type, doughnut chart
  single: any = [];
  showLabels = true;

  today: string;

  constructor(
    private r: Router,
    private userService: UserService,
    private dashboardService: DashboardService
  ) {
    super(r);
   
  }

  ngOnInit(): void {
    this.userDetails = this.userService.getUserListData();
    this.today = moment().format("dddd D MMMM YYYY HH:mm");
    this.userService.callUserListData().subscribe((data) => {
      this.setUpUserAndPermissions(data);
      this.getLoginTimestamp(data);
    });
    this.dashboardService.getDashBoardAnalyticsForms().subscribe(data => {
      this.dashboardAnalyticsForm = data;

      //Response By Status
      this.dashboardAnalyticsForm.dashboardResponseByStatusData.forEach(x =>
      this.responseByStatus.push({ name: x.title, value: x.count, label: x.label }));
        
      //Extracted Response Counts by Status wise  
      this.notStartedForms = this.dashboardAnalyticsForm.dashboardResponseByStatusData.filter(x => (x.title == 'Not Started'), take(1)).map(x => x.count)[0];
      this.inProgressForms = this.dashboardAnalyticsForm.dashboardResponseByStatusData.filter(x => (x.title == 'In Progress'), take(1)).map(x => x.count)[0];
      this.overdueForms = this.dashboardAnalyticsForm.dashboardResponseByStatusData.filter(x => (x.title == 'Overdue'), take(1)).map(x => x.count)[0];
      this.completedForms = this.dashboardAnalyticsForm.dashboardResponseByStatusData.filter(x => (x.title == 'Completed'), take(1)).map(x => x.count)[0];
      this.underReviewForms = this.dashboardAnalyticsForm.dashboardResponseByStatusData.filter(x => (x.title == 'Under Review'), take(1)).map(x => x.count)[0];
      this.onHoldForms = this.dashboardAnalyticsForm.dashboardResponseByStatusData.filter(x => (x.title == 'On Hold'), take(1)).map(x => x.count)[0];
      this.rejectedForms = this.dashboardAnalyticsForm.dashboardResponseByStatusData.filter(x => (x.title == 'Rejected'), take(1)).map(x => x.count)[0];

      this.notStartedForms = this.notStartedForms==undefined ? 0 : this.notStartedForms;
      this.inProgressForms= this.inProgressForms==undefined ? 0 : this.inProgressForms;
      this.overdueForms= this.overdueForms==undefined ? 0 : this.overdueForms;
      this.completedForms= this.completedForms==undefined ? 0 : this.completedForms;
      this.underReviewForms= this.underReviewForms==undefined ? 0 : this.underReviewForms;
      this.onHoldForms= this.onHoldForms==undefined ? 0 : this.onHoldForms;
      this.rejectedForms= this.rejectedForms==undefined ? 0 : this.rejectedForms;

      this.totalForms = this.notStartedForms + this.inProgressForms + this.overdueForms + this.completedForms
        + this.underReviewForms + this.onHoldForms + this.rejectedForms;

      //Response Till Date By Status   
      let groupedStatusMonthwise = this.dashboardAnalyticsForm.dashboardresponseByStatusMonthwiseData.reduce(function (x, y) {
        let groupKey = y.monthName + '-' + y.year;
        (x[groupKey] ? x[groupKey] : (x[groupKey] = null || [])).push(y);
        return x;
      }, {});

      for (const key in groupedStatusMonthwise) {
        let responseStausCount = [];
        for (let i = 0; i <= groupedStatusMonthwise[key].length - 1; i++) {
          let curr = groupedStatusMonthwise[key][i];
          responseStausCount.push({ name: curr.title, value: curr.count });
        }
        this.responseStausCountMonthwise.push({ name: key, series: responseStausCount });
      }
      this.responseStausCountMonthwise = this.finalResponseStausCountMonthwise();
     
      //Responses Published By Respondent Type 
      let groupedRespondentTypeMonthwise = this.dashboardAnalyticsForm.dashboardresponseByRespondentTypeMonthwiseData.reduce(function (x, y) {
        let groupKey = y.monthName + '-' + y.year;
        (x[groupKey] ? x[groupKey] : (x[groupKey] = null || [])).push(y);
        return x;
      }, {});

      for (const key in groupedRespondentTypeMonthwise) {
        let respondentTypeCount = [];
        for (let i = 0; i <= groupedRespondentTypeMonthwise[key].length - 1; i++) {
          let curr = groupedRespondentTypeMonthwise[key][i];
          respondentTypeCount.push({ name: curr.title, value: curr.count });
        }
        this.repondentTypeMonthwise.push({ name: key, series: respondentTypeCount });
      }
      //Responses Published By Form Type
      let groupedResponsesByFormTypeMonthwise = this.dashboardAnalyticsForm.dashboardresponseByFormTypeMonthwiseData.reduce(function (x, y) {
        let groupKey = y.monthName + '-' + y.year;
        (x[groupKey] ? x[groupKey] : (x[groupKey] = null || [])).push(y);
        return x;
      }, {});

      for (const key in groupedResponsesByFormTypeMonthwise) {
        let responsesByFormTypeCount = [];
        for (let i = 0; i <= groupedResponsesByFormTypeMonthwise[key].length - 1; i++) {
          let curr = groupedResponsesByFormTypeMonthwise[key][i];
          responsesByFormTypeCount.push({ name: curr.title, value: curr.count });
        }
        this.responsesByFormTypeMonthwise.push({ name: key, series: responsesByFormTypeCount });
      }

      this.customColors = this.responseCustomColors();
      this.isReady = true;
    });
  }

  private setUpUserAndPermissions(user: IUser): void {
    this.userService.setUserListData(user);
    this.userDetails = this.userService.getUserListData();
    if (!this.userDetails) {
      return;
    }
    
    let isNonrespondent = this.userDetails.permissionsForUI.some(group => group.groupName === "Projects");
    if ((!this.userDetails.isAdmin && !isNonrespondent) || (this.userDetails.type == "Client")) {
      this.isRespondent = true;
    }
    this.canAccessReports = !this.isRespondent || this.userDetails.roles.some(r => this.roleToAccessReports.indexOf(r.roleTitle) >= 0);
  }

  //Set the value of arc of pie charts
  pieChartResponseBysStatusLabel(series: any[], name: string): string {
    const item = series.filter((data) => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    
    return name;
  }

  getLoginTimestamp(user: IUser) {
    this.userService.getLoginData(user.userId).subscribe((data) => {
      this.LastLoginTimeStamp = moment.utc(data.body).local().format("dddd D MMMM YYYY HH:mm");
    },
      (error) => console.log("Error"));
  }

  //Set the reseponse by Status pie chart custom colors
  responseCustomColors() {
    let result: any[] = [];
    for (let i = 0; i < this.responseByStatus.length; i++) {
      switch (this.responseByStatus[i].name)
      {
        case "Completed":
          result.push({"name": this.responseByStatus[i].name,"value": "#2e8540", "label": this.responseByStatus[i].label});
          break;
        case "In Progress":
          result.push({"name": this.responseByStatus[i].name,"value": "#fdb81e", "label": this.responseByStatus[i].label});
          break;
        case "Not Started":
          result.push({"name": this.responseByStatus[i].name,"value": "#0071bc", "label": this.responseByStatus[i].label});
          break;
        case "Overdue":
          result.push({"name": this.responseByStatus[i].name,"value": "#cd2026", "label": this.responseByStatus[i].label});
          break;
        case "On Hold":
          result.push({"name": this.responseByStatus[i].name,"value": "#16414E", "label": this.responseByStatus[i].label});
          break;
        case "Rejected":
          result.push({"name": this.responseByStatus[i].name,"value": "#752F00", "label": this.responseByStatus[i].label});
          break;
        case "Under Review":
        result.push({"name": this.responseByStatus[i].name,"value": "#800080", "label": this.responseByStatus[i].label});
        break;
      }
    }
    return result;
  }

 //Organize the response status wise.
 finalResponseStausCountMonthwise() {
    let emptyArray: any[] =[];
    emptyArray.push({"name": "Completed", "value": 0 });
    emptyArray.push({"name": "In Progress", "value": 0 });
    emptyArray.push({"name": "Not Started", "value": 0 });
    emptyArray.push({"name": "On Hold", "value": 0 });
    emptyArray.push({"name": "Overdue", "value": 0 });
    emptyArray.push({"name": "Rejected", "value": 0 });
    emptyArray.push({"name": "Under Review", "value": 0 });

    let main: any[] = [];
    let item: any[];
    for (let j=0; j < this.responseStausCountMonthwise.length; j++)
    {
      let result : any[] =[];
      for (let i = 0; i < emptyArray.length; i++) {
        switch (emptyArray[i].name)
        {
          case "Completed":
            {
              item = this.filter(this.responseStausCountMonthwise[j].series, emptyArray[i].name);
              if (item.length > 0) 
                result.push({"name": item[0].name,"value": item[0].value});
              else
                result.push({"name": emptyArray[i].name,"value": 0});
            break;
            }
          case "In Progress":
              {
                item = this.filter(this.responseStausCountMonthwise[j].series, emptyArray[i].name);
                if (item.length > 0) 
                  result.push({"name": item[0].name,"value": item[0].value});
                else
                  result.push({"name": emptyArray[i].name,"value": 0});
              break;
              }
          case "Not Started":
              {
                item = this.filter(this.responseStausCountMonthwise[j].series, emptyArray[i].name);
                if (item.length > 0) 
                  result.push({"name": item[0].name,"value": item[0].value});
                else
                  result.push({"name": emptyArray[i].name,"value": 0});
              break;
              }
          case "On Hold":
            {
              item = this.filter(this.responseStausCountMonthwise[j].series, emptyArray[i].name);
              if (item.length > 0) 
                result.push({"name": item[0].name,"value": item[0].value});
              else
                result.push({"name": emptyArray[i].name,"value": 0});
              break;
            }
          case "Overdue":
            {
              item = this.filter(this.responseStausCountMonthwise[j].series, emptyArray[i].name);
              if (item.length > 0) 
                result.push({"name": item[0].name,"value": item[0].value});
              else
                result.push({"name": emptyArray[i].name,"value": 0});
              break;
            }
          case "Rejected":
            {
              item = this.filter(this.responseStausCountMonthwise[j].series, emptyArray[i].name);
              if (item.length > 0) 
                result.push({"name": item[0].name,"value": item[0].value});
              else
                result.push({"name": emptyArray[i].name,"value": 0});
              break;
            }
          case "Under Review":
            {
              item = this.filter(this.responseStausCountMonthwise[j].series, emptyArray[i].name);
              if (item.length > 0) 
                result.push({"name": item[0].name,"value": item[0].value});
              else
                result.push({"name": emptyArray[i].name,"value": 0});
              break;
            }
        }
      }
      main.push({"name": this.responseStausCountMonthwise[j].name, series: result});
    }
    return main;
  }

  filter(series: any[], name: string){
      const item = series.filter((data) => data.name === name);
      return item;
  }
}
