import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { QuickSearchComponent } from './quick-search.component';
import { QuickSearchGuard } from './quick-search.guard';
const routes: Routes = [
  {
    path: 'quicksearch',
    component: QuickSearchComponent,
    canActivate: [MsalGuard, QuickSearchGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuickSearchRoutingModule {

}
