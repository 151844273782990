import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IFormExtraction } from "../shared/formExtraction";
import { Observable } from 'rxjs';
import { IQuery } from "../shared/query";
import { FileUploadHeader } from "../core/constants";

@Injectable({
    providedIn: 'root'
})
export class FormExtractionTemplateService {
    private formExtractionsAPIURL = 'formExtractionTemplate';
    private _tempData: any;
    private userAllowedPermissions: any[]
    private _isTemplateCreated: string = '';
    private _formData: any = '';

    constructor(private http: HttpClient) { }

    get isTemplateCreated(){
        return this._isTemplateCreated;
    }

    set isTemplateCreated(msg: string){
        this._isTemplateCreated = msg;
    }

    get formData() {
        return this._formData;
    }

    set formData(data: any) {
        this._formData = data;
    }

    setPermissions(permission) {
        this.userAllowedPermissions = permission;
    }

    getPermissions() {
        return this.userAllowedPermissions;
    }

    createFormTemplate(formData) {
        let httpOptions = {
            headers: new HttpHeaders({
              [FileUploadHeader]: ""
            })
          };
          
        const fd = new FormData();
        for (let key of Object.keys(formData)) {
            let value = formData[key];
            fd.append(key, value);
        }

        return this.http.post(this.formExtractionsAPIURL, fd, httpOptions);
    }

    updateFormTemplate(Id: number, formData) {
        return this.http.put<IFormExtraction>(this.formExtractionsAPIURL + "/" + Id, formData);
    }

    /*
    deleteFormTemplate(Id: number): Observable<IFormExtraction> {
        return this.http.delete<IFormExtraction>(`${this.formExtractionsAPIURL}/${Id}`);
    }
    */

    deleteFormTemplate(Id: number) {
        return this.http.delete(`${this.formExtractionsAPIURL}/${Id}`);
    }  

    getFormExtractionsList(queryParams: IQuery) {
        let params = new HttpParams();

        for (const key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }

        let options = { 'refresh': 'true' }

        return this.http.get(this.formExtractionsAPIURL, { headers: options, params: params, observe: 'response' });
    }

    getFormExtractionById(id: number) {
        return this.http.get<IFormExtraction>(`${this.formExtractionsAPIURL}/${id}`);
    }

    getExtractionTemplateByFormId(id: number) {
        let options = { 'refresh': 'true' }
        return this.http.get(`${this.formExtractionsAPIURL}/form/${id}`, { headers: options});
    }

    set tempData(value) {
        this._tempData = value;
    }

    get tempData() {
        return this._tempData;
    }
}