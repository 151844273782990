import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { IClients } from 'src/app/shared/client';
import { environment } from 'src/environments/environment';
import { ProjectsService } from 'src/app/services/projects.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import { take } from 'rxjs/internal/operators/take';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ReportsDTOService } from 'src/app/shared/reportsDTO.service';
import { MasterService } from 'src/app/services/master.service';
import { ReportsService } from 'src/app/services/report.services';
import { IReport, IReports } from 'src/app/shared/report';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { UserService } from 'src/app/services/user.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { FeaturesService } from 'src/app/services/feature.service';
import { IFeature } from 'src/app/shared/selfservice-feature';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { FdlFilterUIHepler } from 'src/app/shared/fdl-filter-ui-helper';
declare var $: any;

@Component({
  templateUrl: './feature-list.component.html'
})
export class FeatureListComponent implements OnInit, AfterViewInit, OnDestroy {
  successMsg: string;

  constructor(
    private router: Router,
    private reportService: ReportsService,
    private projectService: ProjectsService,
    private permission: PermissionsService,
    private reportDto:ReportsDTOService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private userFilterService: UserFilterService,
    private userService: UserService,
    private featuresService: FeaturesService,
    private fdlFilterHelper: FdlFilterUIHepler
  ) { }

  filterExists: boolean = false;
  filterForm: FormGroup;
  featureList: IFeature[];
  queryParam: IQuery;
  currentPageNumber: number = 1;
  pagerObject:IPager;
  sortingObject;
  isError:boolean;
  errorMsg: string = "";
  displayColumns = ['Name', 'Description', 'Created By', 'Created On', 'Last Updated By', 'Last Updated On'];
  keys = ['name', 'description', 'createdByName', 'createdOn', 'modifiedByName', 'modifiedOn'];
  reportSuggestion: ISuggestions;
  featureSuggestion: any;
  deletedFormMsg: string = "";
  showComment: boolean = true;
  isCreateReportAllowed: boolean = true;
  organizationSuggestion: ISuggestions;
  isAdmin: boolean = false;
  selectedOrgId: number = 0;
  filtered: boolean = false
  organizationDetails: any;  
  listname: any;
  isSubmitted = false;
  regexValidationMessage: any;

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.initFilterForm();
    this.initQueryParams();
    this.masterService.loadAndApplyFilters(this.queryParam, this.filterForm);
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    this.isAdmin = this.userService.getUserListData().isAdmin;
    this.listname = PreferenceService.featureList;
    this.filterList();
    this.getSuggestions();
    if(this.featuresService.isFeatureSuccess) {
      this.successMsg = `A new feature ${this.featuresService.featureList.name} was created`;
    }

    this.filtered = this.filterExists
  }

  closeSuccessMsg() {
    this.reportService.isReportCreated = "";
  }

  ngAfterViewInit(): void {
    this.deletedFormMsg = '';
  }

  initQueryParams() {
    this.queryParam = Object.assign({},{
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize,
      orderBy: 'modifiedOn desc'
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      name: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)])
    });
  }

  getSuggestions() {
    this.featuresService.getSuggestions('selfservicefeature')
    .subscribe(suggestions => {
      this.featureSuggestion = suggestions;
    },
    (error)=> { this.isError = true; this.errorMsg = error; });
  }

  refresh() {
    this.getFeatureList();
  }

  getFeatureList() {
    $('#refreshTask').addClass('fa-rotation');
    this.featuresService.getFeatures(this.queryParam)
      .pipe(take(1))
      .subscribe(
        features => {
          this.isError = false;
          this.featureList = features.body as IFeature[];
          if(features.headers.get('X-pagination')){
            this.pagerObject = Object.assign({},JSON.parse(features.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }

  filterList() {
    this.isSubmitted = true;

    if (this.filterForm.valid) {
    this.masterService.saveFilters(this.filterForm);
    this.userFilterService.saveFilter(this.route, this.filterForm);
    this.initQueryParams();
    let filterValues = this.filterForm.value;

    if (filterValues.name)
      this.queryParam.name = filterValues['name']

    this.setOrderBy(this.sortingObject);
    this.getFeatureList();
    this.filtered = true
    }
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.userFilterService.clearFilter()
    this.getFeatureList();
    this.fdlFilterHelper.showSearchIconWhenResetFilter();
  }

  changePage(page){
    this.queryParam.pageNumber = page;
    this.getFeatureList();
  }

  setOrderBy(sortingData){
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  applySorting(event){
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.getFeatureList();
  }

  createReport(){
    this.reportService.tempData = "Reports";
    this.router.navigate(['admin/feature/create']);
  }

  navigate(event){
    if (event.action === "view") {
      this.router.navigate(['/admin/feature/view', event.data]);
    }
    else if (event.action === "delete") {
      this.deletedFormMsg = '';
      let deleteReport = this.featureList.filter(item => item.id === event.data)[0];
      this.featuresService.deleteFeature(event.data).subscribe(
        () => {
          this.isError = false;
          this.getFeatureList();
          this.getSuggestions();
          this.successMsg = '';
          this.deletedFormMsg = `The feature '${deleteReport.name}' has been deleted.`
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      )
    }
  }

  ngOnDestroy(): void {
    this.deletedFormMsg = "";
    this.successMsg = "";
  }

}
