import { Injectable } from '@angular/core';
import { IUser } from './user';

@Injectable({
  providedIn: 'root'
})

export class UsersDTOService {

  userData: IUser
  private userUpdated: boolean = false;
  private isEdit: boolean = false;
  private isClone: boolean = false;
  private userCreated: boolean = false;
  private newlyCreatedUser: string = '';

  constructor() { }

  setUserData(data: IUser){
      this.userData = data;
  }

  getUserData(): IUser{
      return this.userData;
  }

  set _isUserUpdated(value){
    this.userUpdated= value;
  }

  get _isUserUpdated(){
    return this.userUpdated;
  }

  set _isEditMode(value){
    this.isEdit = value
  }

  get _isEditMode(){
    return this.isEdit;
  }

  set _isCloneMode(value) {
    this.isClone = value
  }

  get _isCloneMode() {
    return this.isClone;
  }

  set _isUserCreated(value){
    this.userCreated = value
  }

  set _newCreatedUser(value){
    this.newlyCreatedUser =  value;
  }

  get _isUserCreated(){
    return this.userCreated;
  }

  get _newCreatedUser(){
    return this.newlyCreatedUser;
  }
}

