<div class="heading-container ">
  <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons">
          arrow_back
        </span>
        Projects
      </a>
    </div>
    <h1>{{projectData?.title}}</h1>
  </div>
  <div class="col-md-6 fdl-2 float-right pr-0 pt-4">
    <button type="button" class="pull-right btn fdl-btn btn-primary-orange ml-2 pl-2 pr-2 mb-3"
            (click)="viewProjectTimeline()">
      <span class="material-icons pb-1">visibility</span>
      Project Timeline
    </button>
  </div>
</div>

<div class=" main-container-sub responsive-container m-0">
  <div class="row m-0">
    <section class="right-section full-width col-12">
      <div class="form-container separate-form-container">
        <div class="alert alert-success alert-dismissible" *ngIf="isProjectCreated && !isCopyFormsSuccessful">
          <button type="button" class="close" data-dismiss="alert" aria-label="close"
                  (click)="isProjectCreated=false">
            ×
          </button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            The Project '{{projectName}}' has been created.

          </p>
        </div>

        <div class="alert alert-success alert-dismissible" *ngIf="isCopyFormsSuccessful">
          <button type="button" class="close" data-dismiss="alert" aria-label="close"
                  (click)="isProjectCreated=false">
            ×
          </button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            The Project '{{projectData.title}}' has been created and Form responses has been assigned.

          </p>
        </div>

        <div class="alert alert-success alert-dismissible" *ngIf="isProjectUpdated">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isProjectUpdated=false">
            ×
          </button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            The Project '{{oldProjectData?.title}}' has been updated.
          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="isFormsPublished">
          <button type="button" class="close" data-dismiss="alert" aria-label="close"
                  (click)="setFormsPublishedToFalse()">
            ×
          </button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            Your form publish request has been submitted. It may take some time to process your request, you will be
            notified once it is successfully processed and published to respondents.

          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="delegatedFormMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="delegatedFormMsg=''">
            ×
          </button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{delegatedFormMsg}}

          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="reinitiatedFormMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="reinitiatedFormMsg=''">
            ×
          </button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{reinitiatedFormMsg}}

          </p>
        </div>
        <div class="alert alert-dismissible alert-danger mt-3" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">
            ×
          </button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>

        <div class="alert alert-success alert-dismissible mt-3" *ngIf="deletedFormMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="clearDeleteFromMsg()">×</button>
          <span class="iconLabel" aria-label="sucess">
          </span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{deletedFormMsg}}
          </p>
        </div>
        <div class="alert alert-success alert-dismissible mt-3" *ngIf="isIntakePublished">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="clearIntakeResponseLinked()">
            ×
          </button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{intakeMessage}}
          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="updateApproverMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="updateApproverMsg=''">
            ×
          </button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{updateApproverMsg}}

          </p>
        </div>
        <form>

          <div id="accordion" class="accordion collapse-view">
            <div class="page-heading-container fdl-2" data-toggle="collapse" href="#collapseOne">
              <h2 class="h4 heading-level-2 strong">Project Information</h2>

              <div class="d-flex noaction">
                <button type="button" title="Add To Favorite" id="addToFavoriteViewProject"
                        class="btn fdl-btn btn-primary-dark btn-addcomment mr-1 add-to-favorite "
                        (click)="addRemoveFavorites($event)">
                  <span aria-hidden="true" *ngIf="!isFavorite" class="star fa fa-star-o"></span>
                  <span aria-hidden="true" *ngIf="isFavorite" class="star fa fa-star"></span>
                </button>
                <button type="button" (click)="openAddCommentModal()" data-toggle="modal"
                        title="Add/View/Upload Comments" class="btn fdl-btn btn-primary-dark btn-addcomment"
                        id="projectsComment">
                  <span class="material-icons material-icons-wrapper">chat</span>
                  <div *ngIf="showCommentBadge" class="badge badge-danger">{{commentBadgeCount}}</div>
                </button>
                <button type="button" *ngIf="isEditProjectAllowed" class="btn fdl-btn btn-primary-dark ml-2"
                        (click)="navigateToEditProject(1)">
                  Edit
                </button>
              </div>
            </div>

            <div class="collapse show" id="collapseOne" data-parent="#accordion">
              <div class="biller-survey white-box ">
                <div>
                  <ul>
                    <li>
                      Description:
                      <span class="info">{{projectData?.description || 'N/A' }} </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Product Name: <span class="info">{{projectData?.productTitle || 'N/A'}}</span>
                    </li>
                    <li>
                      Project Manager: <span class="info">{{projectData?.fiservImplementationManagerName}}</span>
                    </li>
                    <li *ngIf="stakeholdersForDisplay">
                      Project Stakeholder: <span class="info">{{stakeholdersForDisplay}}</span>
                    </li>
                    <li *ngIf="!stakeholdersForDisplay">
                      Initiated Date: <span class="info">{{projectData?.startDate | dateFormat}}</span>
                    </li>
                  </ul>
                  <ul>
                    <li *ngIf="projectData?.externalSysRefId">
                      External Reference Id: <span class="info">{{projectData?.externalSysRefId}}</span>
                    </li>
                    <li>
                      Last Updated User: <span class="info">{{projectData?.modifiedByName}}</span>
                    </li>
                    <li>
                      Last Updated Date: <span class="info">{{projectData?.modifiedOn | dateFormat}}</span>
                    </li>
                    <li *ngIf="stakeholdersForDisplay">
                      Initiated Date: <span class="info">{{projectData?.startDate | dateFormat}}</span>
                    </li>
                    <!--<li>
                      Start Date: <span class="info">{{projectData?.startDate | dateFormat}}</span>
                    </li>
                    <li>
                      End Date: <span class="info">{{projectData?.dueDate | dateFormat}}</span>
                    </li>-->
                  </ul>
                  <ul>
                    <li>
                      Same Form allowed to be published more than once? <span class="info">
                        {{
projectData?.sameForms ||
                        'N/A'
                        }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- <div class="page-heading-container fdl-2 mt-4">-->
              <!--<button type="button" class="btn btn-secondary float-right"
              (click)="navigateToEditProject(2)">Edit</button> -->
              <!-- </div>-->
              <h2 class="h5 font-weight-bold pl-3">Client Information</h2>

              <div class="biller-survey white-box">
                <div>
                  <ul>
                    <li>
                      Client Name: <span class="info">{{projectData?.clientTitleWithDuns || 'N/A' }}</span>
                    </li>
                    <li>
                      Address:
                      <span class="info">
                        {{_ClientAddress || 'N/A'}}
                      </span>
                    </li>
                    <li>
                      Client Implementation Manager:
                      <span class="info">
                        {{
projectData?.clientImplementationManagerId ?
                        projectData?.clientImplementationManagerName : 'N/A'
                        }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="page-heading-container fdl-2 mt-4" data-toggle="collapse" href="#collapseThree">


              <h2 class="h5 font-weight-bold">Initiated Data Gathering Forms</h2>
              <div class="noaction">
                <!-- <button type="button" *ngIf="isAssignResponsesAllowed" class="btn fdl-btn btn-primary-orange float-right"
                        (click)="showModalCopyForms()">
                  Copy Forms from existing project
                </button> -->
                <button type="button" *ngIf="isAssignResponsesAllowed"
                        class="btn fdl-btn btn-primary-orange float-right mr-2" (click)="navigateToEditProject(2)"
                        [disabled]="isProjectOnholdOrRejected">
                  Initiate Data Gathering
                </button>

                <button type="button" *ngIf="isAssignResponsesAllowed"
                        class="btn fdl-btn btn-secondary-light  float-right mr-2" (click)="navigateToEditProject(3)"
                        [disabled]="isProjectOnholdOrRejected">
                  Link Intake Response
                </button>

              </div>

            </div>

            <div class="col-md-12 pt-3 pl-0 pr-0 collapse show" id="collapseThree" data-parent="#accordion">
              <div class="filter-option">
                <p class="pt-1 mb-0">
                  <span class="iconLabel alert-success" aria-label="success">
                    <i class="material-icons" aria-hidden="true">check_circle</i>
                  </span>
                  {{projectData?.responses.length}} forms published
                </p>
                <div class="filter-container">

                  <div class="refresh-icon-link">
                    <!-- add class of 'fa-rotation' on click of the refresh icon -->
                    <a title="Refresh Task" id="refreshTask">
                      <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
                    </a>
                  </div>

                  <div class="dropdown downloadDropdownMenuButton" *ngIf="showComment">
                    <button *ngIf="showComment" type="button" title="Download Extract"
                            class="btn btn fdl-btn btn-primary-dark btn-addcomment" id="downloadDropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <span class="material-icons material-icons-wrapper">
                        get_app
                      </span>
                    </button>

                    <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
                      <a class="dropdown-item" (click)="downLoadType({'type':'xlsx'})">Export List</a>
                    </div>
                  </div>

                  <div class="col-setting-icon-wrapper">
                    <app-prefernce-icon [addViewProjectClass]="true"></app-prefernce-icon>
                  </div>

                  <a id="cartPopover" class="btn cart-popover" (click)="showFilters = !showFilters"
                     *ngIf="projectData?.responses.length > 0" title="Select Filter">
                    <i class="material-icons">filter_list</i>
                  </a>

                  <div class="filter-popover-container open-left"
                       [ngClass]="{'d-block' : showFilters, 'd-none' : !showFilters}" [formGroup]="filterForm">
                    <a href="javascript:void(0);" aria-label="Close" class="filter-close" (click)="showFilters = false">
                      <i class="material-icons" tabindex="0" aria-hidden="true">close</i>
                    </a>
                    <div class="d-flex">
                      <div class="col-lg-6 pr-0">
                        <label for="assignedToName" aria-label="name">Respondent Name:</label>
                        <div class="fdl-select">
                          <select class="form-control" formControlName="assignedToName" name="assignedToName"
                                  id="assignedToName">
                            <option value="">All</option>
                            <option *ngFor="let assignee of assigneeList" [value]="assignee">
                              {{assignee}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 pr-0">
                        <label for="responseStatusTitle" aria-label="name">Status:</label>
                        <div class="fdl-select">
                          <select class="form-control" formControlName="responseStatusTitle" name="responseStatusTitle"
                                  id="responseStatusTitle">
                            <option value="">All</option>
                            <option *ngFor="let status of formStatus" [value]="status.title">
                              {{status.title}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="fdl-2 mt-3">
                        <button type="button" class="font-weight-normal btn fdl-btn btn-primary-orange"
                                aria-label="Filter" (click)="filterResponses()">
                          Filter
                        </button>
                        <button type="button" class="btn btn-link ml-1" aria-label="Default" (click)="reset()">
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-scroll-x mh200">
                <app-data-table *ngIf="projectData" [rows]="displayResponseList" [keys]="keys"
                                [displayColumns]="displayColumns" (buttonClicked)="takeAction($event)"
                                (deleteBtnClicked)="takeAction($event)" (sortingClicked)="applySorting($event)" [showCheckbox]="false"
                                [showDelegate]="true" [showDelete]="isDeleteResponseAllowed"
                                [showChangeApprover]="isChangeApproverAllowed" [showReinitiate]="isReinitiateAllowed"
                                [showViewHistory]="isViewHistoryAllowed" [showViewHistory]="isViewHistoryAllowed"
                                [showViewTimeline]="isViewTimelineAllowed" [showDelink]="isAssignResponsesAllowed"
                                [showEdit]="isAdmin" type="Responses" [showEditFormDetails]="isEditFormDetailsAllowed"
                                [showRequestExtract]="true" [listname]="listname" [addViewProjectClass]="true">
                </app-data-table>
              </div>
              <app-delegate type="viewProject" [delegateFormData]="responseData" [mode]="mode"
                            (successDelegate)="successDelegateClick($event)">
              </app-delegate>
              <app-update-approver type="viewProject" [updateApproverFormData]="responseData"
                                   (successUpdateApprover)="successUpdateApproverClick($event)">
              </app-update-approver>
              <app-put-on-hold [data]="responseData" (successUpdateApprover)="successUpdateApproverClick($event)"
                               #putOnHold>
              </app-put-on-hold>
            </div>
          </div>
          <div class="fdl-2 button-container" id="button_view">
            <button type="button" class="btn fdl-btn btn-secondary-light  mr-2" aria-label="Default"
                    (click)="goBack()">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </section>
  </div>
  <!-- Request Modal Start-->
  <div class="modal fade bd-example-modal-lg" id="selectForms" tabindex="-1" role="dialog" data-backdrop="static"
       data-keyboard="false" aria-hidden="true" style="display: none; margin-left: -600px ">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content request-modal">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">Select Intake Response</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close popup" (click)="clearIntakeForms()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <form class="form-filter-container filter-bar" [formGroup]="linkIntakeForm" autocomplete="off">
          <div class="modal-body">
            <div class="alert alert-dismissible alert-danger" *ngIf="isErrorRequest">
              <button type="button" class="close" data-dismiss="alert" aria-label="close"
                      (click)="isError=false">
                ×
              </button>
              <i class="material-icons">warning</i>
              <p>Please select a Intake response.</p>
            </div>

            <div class="form-row align-items-end mb-3">

              <div class="row m-0">
                <!-- <div class="col-md-2 filter-form-control typeahead-wrapper pr-4" *ngIf="showOrganizationFilter">
                  <fdl-type-ahead label="Organization Name" formControlName="OrganizationName" [collection]="organizationSuggestion" optionField="title" placeholder="{{userData.organizationTitle}}" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                  </fdl-type-ahead>
                </div> -->
                <div class="filter-form-control col-md-1 typeahead-wrapper pr-4">
                  <label for="Status"> ID:</label>
                  <div class="">
                    <input type="number" name="id" min="0" placeholder="Type to search" class="form-control"
                           formControlName="id" class="form-control" aria-invalid="false" (ngModelChange)="filterData()" />
                  </div>

                </div>
                <div class="filter-form-control col-md-2 typeahead-wrapper"
                     [ngClass]="{'has-error': !linkIntakeForm.get('searchString').valid && (linkIntakeForm.get('searchString').touched || linkIntakeForm.get('searchString').dirty)}">
                  <fdl-type-ahead label="Form Name:" formControlName="searchString" name="searchString"
                                  [collection]="formNameSuggestion" optionField="title" placeholder="Type to search"
                                  [scrollable]="true" [optionsLimit]="500" [minLength]="0" (ngModelChange)="filterData()">
                  </fdl-type-ahead>
                  <span *ngIf="!linkIntakeForm.get('searchString').valid && linkIntakeForm.get('searchString').errors?.pattern && (linkIntakeForm.get('searchString').touched || linkIntakeForm.get('searchString').dirty)">
                    <i class="material-icons">warning</i>Please enter a valid Form Name
                  </span>
                </div>

                <div class="filter-form-control col-md-2 typeahead-wrapper"
                     [ngClass]="{'has-error': !linkIntakeForm.get('productText').valid && (linkIntakeForm.get('productText').touched || linkIntakeForm.get('searchString').dirty)}">
                  <fdl-type-ahead label="Product Name:" name="product" [collection]="productSuggestion"
                                  optionField="productTitle" placeholder="Type to search" [scrollable]="true" [optionsLimit]="100"
                                  [minLength]="0" formControlName="productText"
                                  (ngModelChange)="filterData()">
                  </fdl-type-ahead>
                  <span *ngIf="!linkIntakeForm.get('productText').valid && linkIntakeForm.get('productText').errors?.pattern && (linkIntakeForm.get('productText').touched || linkIntakeForm.get('productText').dirty)">
                    <i class="material-icons">warning</i>
                    {{regexValidationMessage.PRODUCT_NAME_VALIDATION_MESSAGE}}
                  </span>
                </div>
                <div class="filter-form-control col-md-2 typeahead-wrapper"
                     [ngClass]="{'has-error': !linkIntakeForm.get('clientIntake').valid && (linkIntakeForm.get('clientIntake').touched || linkIntakeForm.get('clientIntake').dirty)}">
                  <fdl-type-ahead label="Client Name:" name="client" [collection]="clientSuggestion" optionField="clientTitleWithDuns"
                                  placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"
                                  formControlName="clientIntake" (input)="typeaheadLoadingClients($event.target.value)"
                                  (ngModelChange)="filterData()">
                  </fdl-type-ahead>
                  <span *ngIf="!linkIntakeForm.get('clientIntake').valid && linkIntakeForm.get('clientIntake').errors?.pattern && (linkIntakeForm.get('clientIntake').touched || linkIntakeForm.get('clientIntake').dirty)">
                    <i class="material-icons">warning</i>Please enter a valid Client Name
                  </span>
                </div>
                <div class="col-md-2 filter-form-control typeahead-wrapper pr-4"
                     [ngClass]="{'has-error': !linkIntakeForm.get('package').valid && (linkIntakeForm.get('package').touched || linkIntakeForm.get('package').dirty)}">
                  <fdl-type-ahead label="Package:" name="package" [collection]="packageSuggestion" optionField="package"
                                  placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"
                                  formControlName="package" (input)="typeaheadLoadingPackages($event.target.value)"
                                  (ngModelChange)="filterData()">
                  </fdl-type-ahead>
                  <span *ngIf="!linkIntakeForm.get('package').valid && linkIntakeForm.get('package').errors?.pattern && (linkIntakeForm.get('package').touched || linkIntakeForm.get('package').dirty)">
                    <i class="material-icons">warning</i>Please enter a valid Package Name
                  </span>
                </div>
                <div class="col-md-2 typeahead-wrapper"
                     [ngClass]="{'has-error': !linkIntakeForm.get('assignTo').valid && (linkIntakeForm.get('assignTo').touched || linkIntakeForm.get('assignTo').dirty)}">
                  <fdl-type-ahead label="Respondent Name:" formControlName="assignTo" name="assignTo"
                                  [collection]="userSuggestion" optionField="assignedToName" placeholder="Type to search" [scrollable]="true"
                                  [optionsLimit]="500" [minLength]="0" (ngModelChange)="filterData()">
                  </fdl-type-ahead>
                  <span *ngIf="!linkIntakeForm.get('assignTo').valid && linkIntakeForm.get('assignTo').errors?.pattern && (linkIntakeForm.get('assignTo').touched || linkIntakeForm.get('assignTo').dirty)">
                    <i class="material-icons">warning</i>Please enter a valid Respondent Name
                  </span>
                </div>
                <div class="col-md-1 typeahead-wrapper">
                  <label for="Status">Status:</label>
                  <div class="fdl-select">
                    <select class="form-control" aria-invalid="false" formControlName="ResponseStatusTitle"
                            name="ResponseStatusTitle" (ngModelChange)="filterData()">
                      <option value="">All</option>
                      <option *ngFor="let status of formStatus" [value]="status.title">{{status.title}}</option>
                    </select>
                  </div>
                </div>

                <!--<div  class="col-md-3">
                  <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterList();">Search</button>
                </div>-->
              </div>


            </div>
            <div *ngIf="submitRequestList" class="overflow-300 assign-modal-table table-responsive">
              <!-- <app-data-table [rows]="filteredSubmitRequestList" [keys]="keysIntake" [(selectedRadioValue)]="selectedRadioValue"
                              [displayColumns]="displayColumnsIntake" (sortingClicked)="applySorting($event)" [showMenu]="false"
                              [showCheckbox]="true" [showRadio]="false"
                              [searchString]="searchString" [product]="product" [respondentName]="assignTo" [status]="ResponseStatusTitle"
                              [enableSorting]="false" [id]="id" [formSets]="formSets" [hideAllCheck]="true"  [counter]="0" [package]="package" [clientTitle]="clientIntake">
              </app-data-table> -->
              <app-data-table [rows]="pagedSubmitRequestList" [keys]="keysIntake"
                              [(selectedRadioValue)]="selectedRadioValue" [displayColumns]="displayColumnsIntake"
                              (sortingClicked)="applySorting($event)" [showMenu]="false" [showCheckbox]="true" [showRadio]="false"
                              [enableSorting]="false" [id]="id" [formSets]="formSets" [hideAllCheck]="true" [counter]="0">
              </app-data-table>
                <div>
                  <app-pagination
                    *ngIf="pagerObject &&
                      pagerObject.totalPages > 1 &&
                      pagedSubmitRequestList &&
                      pagedSubmitRequestList.length > 0"
                    [pagerObject]="pagerObject"
                    (pagingChanged)="changePage($event)"
                  ></app-pagination>
                </div>
            </div>
          </div>
          <div class="modal-footer fdl-2">
            <!--(click)="clearValue()"-->
            <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal"
                    (click)="clearIntakeForms()">
              Cancel
            </button>
            <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" (click)="linkIntakeFormResponse()">
              Link
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>
  <!-- Request Modal End -->
  <!-- Copy Response Modal Start-->
  <div class="modal fade bd-example-modal-lg" id="copyResponses" tabindex="-1" role="dialog" data-backdrop="static"
       data-keyboard="false" aria-hidden="true" style="display: none; margin-left: -600px ">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content request-modal">
        <form class="form-filter-container filter-bar" [formGroup]="linkIntakeForm" autocomplete="off">
          <div class="modal-header">
            <h4 id="myLargeModalLabel" class="modal-title">Copy Form Responses<small class="d-block f-14"> Only <b>Data Gathering</b> Forms are listed below. Please select at least one form to assign and publish. </small></h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close popup">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-dismissible alert-danger" *ngIf="isErrorRequest">
              <button type="button" class="close" data-dismiss="alert" aria-label="close"
                      (click)="isError=false">
                ×
              </button>
              <i class="material-icons">warning</i>
              <p>Please select a response to copy.</p>
            </div>
            <div class="button-option fdl-2">
              <button [disabled]="!formSets || !formSets[0].assignedFormsList || formSets[0].assignedFormsList.length === 0" type="button" class="btn fdl-btn btn-primary-orange float-right mb-3" data-toggle="modal" (click)="EditFormResponse()">
                <i class="material-icons">edit</i> Edit Form
              </button>
            </div>
            <div class="overflow-300 assign-modal-table table-responsive">
              <app-data-table [rows]="filteredResponseList" [keys]="keysCPResponse"
                              [(selectedRadioValue)]="selectedRadioValue" [displayColumns]="displayColumnsCPResponse"
                              (sortingClicked)="applySorting($event)" [showMenu]="false" [showCheckbox]="true" [showRadio]="false"
                              [enableSorting]="false" [(formSets)]="formSets" [hideAllCheck]="false" [counter]="0">
              </app-data-table>
            </div>
          </div>
          <div class="modal-footer fdl-2">
            <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal"
                    (click)="cancelCopyForms()">
              Cancel
            </button>
            <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" [disabled]="!formSets || !formSets[0].assignedFormsList || formSets[0].assignedFormsList.length === 0" (click)="CopyFormResponses()">
              Assign
            </button>

          </div>
        </form>
      </div>

    </div>
  </div>
  <!-- Copy Response Modal End -->
  <!-- De-Link Form Modal Start-->
  <div class="modal fade bd-example-modal-md" id="delinkModal" data-backdrop="static" data-keyboard="false"
       tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-md mt-120" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">Delink Form</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close popup">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <p>
            Are you sure you want to delink form <strong>{{delinkResponse?.title}}</strong> from the current project?
          </p>
        </div>
        <div class="modal-footer fdl-2">
          <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal"
                  (click)="resetDelinkResponse()">
            Cancel
          </button>
          <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal"
                  (click)="delinkIntakeResponse()">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--  De-Link Form Modal End -->
  <!-- PBI 170532 >> START -->
  <!-- Copy Form Responses Modal START-->
  <div class="modal fade bd-example-modal-md" id="copyFormModal" data-backdrop="static" data-keyboard="false"
       tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-md mt-120" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">Copy Form Responses</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close popup">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="form-filter-container" [formGroup]="projectCopyFormsForm" autocomplete="off">
            <p>Do you want to copy responses from the <strong>'{{copyProjectName}}'</strong> project?</p>

          </form>
        </div>
        <div class="modal-footer fdl-2">
          <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal">No</button>
          <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal" (click)="initiateForms()">Yes</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Copy Form Responses Modal END-->
  <!-- PBI 170532 >> END -->
  <!-- <app-footer></app-footer> -->
</div>

<div class="modal fade bd-example-modal-lg" id="addcomment" data-backdrop="static" data-keyboard="false" tabindex="-1"
     role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" #content>
  <div class="modal-dialog modal-lg" style="max-width: 1280px;" role="document"
       [ngClass]="{'mt-120': !showCommentBadge}">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Add Comment</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup">
          <span aria-hidden="true" (click)="cancelCommentHandle();">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form (ngSubmit)="submitCommentHandle()" #commentsForm="ngForm">

          <div class="alert alert-dismissible alert-danger" *ngIf="isCommentError">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="isCommentError=false">
              ×
            </button> <i class="material-icons">warning</i>
            <p [innerHtml]="commentErrorMsg"></p>
          </div>

          <div class="row ">
            <div class="col-md-4 mb-3 sd">
              <label for="organizationTitle">Comment Category:</label>
              <div class="fdl-select">
                <select name="formType" class="form-control" aria-invalid="false" [(ngModel)]="commentCat">
                  <option value="Comment">Comment</option>
                  <option value="Issue">Issue</option>

                  <option value="Question">Question</option>
                  <option value="Risk">Risk</option>
                  <!--<option value="Both">Data Gathering & Intake</option>-->
                </select>
              </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-12 mb-3"
                 [ngClass]="{'has-error': !commentControl.valid && (commentControl.touched || commentControl.dirty) }">
              <label for="txtComment">Comment in View Project:</label>
              <textarea #commentControl="ngModel" [pattern]="validators.description_comment" name="commentsControl"
                        type="text" [(ngModel)]="commentValue" class="form-control" rows="4" id="txtComment" maxlength="2000"
                        (keyup)="commentCount()"></textarea>
              <div class="float-right"><span>{{commentCnt}} / 2000</span></div>
              <span *ngIf="!commentControl.valid && (commentControl.touched || commentControl.dirty)">
                <i class="material-icons">warning</i> {{regexValidationMessage.DESCRIPTION_COMMENT_VALIDATION_MESSAGE}}
              </span>

            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="commentFileName">
                Upload File:
                <span _ngcontent-yvp-c156="" data-toggle="tooltip" title="File size Limit - 50MB"
                      class="material-icons btn-secondary f-18">
                  info
                </span>
              </label>
              <div class="custom-file">
                <label for="commentFileName" class="custom-file-label text-truncate pr-80" data-toggle="tooltip"
                       data-placement="top" [title]="commentFileName"> {{ commentFileName }}</label>
                <input #inputCommentFile type="file" class="custom-file-input form-control" multiple="false"
                       (change)="onCommentFileChange($event)">
              </div>
            </div>
          </div>
          <div class="fdl-2 w-100 text-right mt-0">
            <button type="button" class="btn fdl-btn btn-secondary-light  mr-2" data-dismiss="modal"
                    (click)="cancelCommentHandle()">
              Cancel
            </button>
            <button type="submit" class="btn fdl-btn btn-primary-orange" [disabled]="isCommentSubmit">
              Save
            </button>
          </div>
        </form>
        <!-- Comment History Table Section-->
        <div *ngIf="projectComment">
          <label for="searchForm"><b>Project Comments:</b></label>
          <hr />
          <div class="form-row align-items-end mb-3">
            <div class="col-md-5">

              <label for="organizationTitle">Comment Category:</label>
              <div class="fdl-select">
                <select name="formType" class="form-control" aria-invalid="false" [(ngModel)]="commentCatHistory">
                  <option value="">All</option>
                  <option value="Comment">Comment</option>
                  <option value="Issue">Issue</option>

                  <option value="Question">Question</option>
                  <option value="Risk">Risk</option>

                  <!--<option value="Both">Data Gathering & Intake</option>-->
                </select>

              </div>
            </div>
          </div>
          <div class="assign-modal-table" [ngClass]="getCommentClass(projectCommentLength)">


            <app-data-table [rows]="commentsOnProjects" [keys]="commentProjectKeys"
                            [displayColumns]="commentProjectHeaders" [enableSorting]="false" searchStringFieldName="pageName"
                            [showCheckbox]="false" [category]="commentCatHistory" [showMenu]="true" [showAttachmentOptions]="true"
                            [showViewEdit]="true" [showAttachment]="true" (attachmentClicked)="downloadAttachment($event)"
                            (buttonClicked)="commentActions($event)">
            </app-data-table>
          </div>
        </div>
        <div class="mt-3" *ngIf="formComments">
          <label for="searchForm"><b>Form Comments:</b></label>
          <hr />
          <div class="form-row align-items-end mb-3">
            <div class="col-md-5">
              <label for="searchForm">Page Name:</label>
              <fdl-type-ahead [(ngModel)]="commentHistSelectedPgName" name="commentHistSelectedPgName"
                              optionField="title" placeholder="Type to search" [collection]="commentHistFilterOptions"
                              [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>

            </div>
            <div class="col-md-5">
              <label for="searchForm">Form Name:</label>
              <fdl-type-ahead [(ngModel)]="commentHistSelectedformName" name="commentHistSelectedformName"
                              optionField="title" placeholder="Type to search" [collection]="commentHistFilterformNameOptions"
                              [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>

            </div>

          </div>
          <div *ngIf="showCommentBadge" class="assign-modal-table" [ngClass]="getCommentClass(commentLength)">

            <app-data-table [rows]="commentHistoryList" [keys]="commentTableKeys" [displayColumns]="commentTableHeaders"
                            [enableSorting]="false" [searchString]="commentHistSelectedPgName" searchStringFieldName="pageName"
                            [formName]="commentHistSelectedformName" [showCheckbox]="false" [showMenu]="false" [showAttachment]="true"
                            (attachmentClicked)="downloadAttachment($event)">
            </app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<replace-attachment #replaceAttachment [id]="commentId" [formId]="commentResponseId" [type]="'project'"
                    (updateAttachment)="updateAttachmentSuccess($event);">
</replace-attachment>

<delete-warning [componentId]="'deleteWarningModal'" [type]="'comment'" (okBtnClicked)="deleteComment(eventData)">
</delete-warning>

<delete-warning [componentId]="'removeAttachmentWarningModal'" [type]="'file'"
                (okBtnClicked)="removeAttachment(eventData)">
</delete-warning>
<app-edit-form-details (successUpdate)="successUpdateApproverClick($event)" [data]="responseData"
                       #editformdetails></app-edit-form-details>

<div id="editFormModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" style="display: none;" aria-hidden="true">
  <div role="document" class="modal-dialog" style="max-width: 1000px">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="editFormSingleDialogModalLabel" class="modal-title">Edit Form </h4>
        <button type="button" data-dismiss="modal" aria-label="Close popup" class="close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body" style="overflow-y: scroll; max-height: 500px; overflow-x: hidden;">
        <form novalidate="" autocomplete="off" ng-reflect-form="[object Object]">
          <app-edit-forms *ngIf="formsetDetails && formSets[0].assignedFormsList.length !== 0"
                          (editFormProgess)="onEditFormsProgress($event)"
                          [projectId]="copyProjectId" [assignedFormsList]="formSets[0].assignedFormsList" #editFormsComponent></app-edit-forms>
        </form>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" data-dismiss="modal" class="btn fdl-btn btn-secondary-light">Cancel</button>
        <button type="button" (click)="onEditForms()" class="btn fdl-btn btn-primary-orange ml-2">Save</button>
      </div>
    </div>
  </div>
</div>
<!-- Edit Form Modal Ends-->
