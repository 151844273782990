import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $: any;

@Component({
  selector: 'delete-warning',
  templateUrl: './delete-warning.component.html'
})
export class DeleteWarningComponent implements OnInit {
  @Output() okBtnClicked = new EventEmitter<{ action: string }>();
  @Input() type: string = '';
  @Input() componentId: string = '';
  @Input() clientDashboard: boolean = false;
  
  targetDeletableRow: any = {};
  constructor() { }

  ngOnInit(): void {
  }

  ok() {
    this.okBtnClicked.emit({ action: "continue" });
    this.cancel();
  }

  cancel() {
    $("#".concat(this.componentId)).hide();
    $('.modal-backdrop').remove();
    $('body').addClass('modal-open');
    $("#".concat(this.componentId)).removeClass('show');
  }
}
