import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { MasterService } from "../../../services/master.service";
import { SurveyService } from "../../../services/survey.service";
import { LoaderService } from '../../../loader/loader.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';

@Component({
  selector: "app-survey-generate-otp",
  templateUrl: "./survey-generate-otp.component.html"
})
export class SurveyGenerateOtpComponent implements OnInit, AfterViewInit {
  surveyId: string;
  isError: boolean = false;
  errorMsg: string = "";
  validateIdentity: FormGroup;
  emailAddress: string;

  constructor(
    private surveyService: SurveyService,
    private router: Router,
    private route: ActivatedRoute,
    private masterService: MasterService,
    private loader: LoaderService,
    private builder: FormBuilder
  )
  {
    document.body.classList.add('otp');
  }

  ngOnInit(): void {
   
    this.validateIdentity = this.builder.group(
      {
        respondentEmail: new FormControl('', [Validators.required, Validators.email])
      });

    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.surveyId = params['id'];
        this.masterService.setGuid(this.surveyId);
        this.surveyService.setParentVisited(true);
        this.getSurveyRespondents(this.surveyId);
      });
    
  }
  ngAfterViewInit(): void {
    this.loader.hide();
  }
  generateOtp(): void {
    this.surveyService.setEmail(this.validateIdentity.get('respondentEmail').value);
    this.surveyService.generateOtp(this.surveyId).subscribe(
      result => {
        if (result.isSuccess) {
          this.surveyService.validatedOtp(true);
          this.router.navigate(["../verification"], { relativeTo: this.route });
        } else if (!result.isSuccess) {
          this.populateErrorMsg(result.message);
        }
      },
      err => {
        this.populateErrorMsg(err);
      }
    );
  }

  populateErrorMsg(msg: string = "") {
    let isExpired = this.surveyService.getExpiredSurvey();
    if (isExpired === "expired") {
      this.router.navigate(["../expired"], { relativeTo: this.route });
    }
    else {
      this.isError = true;
      this.errorMsg = msg;
    }
  }
  getSurveyRespondents(surveyId) { 
    this.surveyService.getSurveyRespondents(surveyId).subscribe(
      res => {
        this.emailAddress = res.body;
        this.emailAddress = this.emailAddress.substr(0, this.emailAddress.length - 2);
      },
      err => {
        this.populateErrorMsg(err);
      }
    );    
  }
}
