<div class="heading-container fdl-2">
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span> 
        Reports 
      </a>
    </div>
    <h1>{{reportDetail?.reportName || 'N/A'}}</h1>
  </div>
  </div>
  
<div class="form-container separate-form-container">
  <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="successMsg=''">×</button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>{{successMsg}}
            
          </p>
  </div>
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMessage"></p>
  </div>
  <form>
      <div class="biller-survey white-box border-0 fdl-2">
          <button type="button" class="btn fdl-btn btn-primary-dark pull-right" *ngIf="showEditButton" (click)="goToEdit();">Edit</button>
        
          <div class="mt-2">
              <ul class="">
                  <li>
                      Entity Level:
                      <span class="info">
                          {{reportDetail?.level}}
                      </span>
                  </li>                 
              </ul>
          </div>
          <div class="">
            <ul class="">
                <li class=" full-width">
                    Entity Name:
                    <span class="info">
                        {{reportDetail?.entityName || 'N/A'}}
                    </span>
                </li>
            </ul>
        </div>
          <div class="mt-2">
            <ul class="">
              
                <li>
                  Report Name:
                  <span class="info">
                      {{reportDetail?.reportName}}
                  </span>
              </li>             
            </ul>
        </div>
        <div class="mt-2">
            <ul class=""> 
              <li>
                  Report Description:
                  <span class="info">{{reportDetail?.reportDescription || 'N/A'}}</span>
              </li>
            </ul>
        </div>
        <div class="mt-2">
            <ul class="">
              <li>
                  Report URL:
                  <span class="info">{{reportDetail?.reportURL}}</span>
              </li>
            </ul>
        </div>
        <div class="mt-2">
            <ul class="">
              <li>
                  Is Ready to be Displayed on Dashboard?
                  <span class="info">{{reportDetail?.visibility }}</span>
              </li>
            </ul>
        </div>
      </div>
      <div class="fdl-2 button-container">
          <div>
              <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()">Cancel</button>
          </div>
      </div>
  </form>
</div>
