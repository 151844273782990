<div class="heading-container fdl-2" *ngIf="!isDrawerType">
  <div class="back-action-container">
    <div class="back-button" >
        <a href="javascript:void(0);" (click)="goBack()">
          <span class="material-icons">arrow_back</span>
          User Groups
        </a>
    </div>
    <h1 *ngIf="!isEdit">Create New User Group</h1>
    <h1 *ngIf="isEdit">{{groupForm.get('name').value}}</h1>
  </div>
</div>

<div class="form-container " [ngClass]="{'p-1': isDrawerType}">
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>

    <p *ngIf="!isEdit">Please complete below required information to create a new user group.</p>

    <div class="pt-2">
      <form [formGroup]="groupForm" autocomplete="off" >
        <div class="form-row">
          <div class="col-md-6 mb-3" [ngClass]="{'has-error': !groupForm.get('name').valid && (groupForm.get('name').touched|| isSubmitted) }">
            <label for="name" class="asterisk">Name:</label>
            <input type="text" class="form-control" name="name" formControlName="name">
            <span *ngIf="!groupForm.get('name').valid && groupForm.get('name').errors?.required && (groupForm.get('name').touched || isSubmitted)">
              <i class="material-icons">warning</i> Please enter group name.
            </span>
            <span *ngIf="!groupForm.get('name').valid && groupForm.get('name').errors?.pattern && (groupForm.get('name').touched || isSubmitted)">
              <i class="material-icons">warning</i> {{regexValidationMessage.CLIENT_NAME_VALIDATION_MESSAGE}}
            </span>
            <span *ngIf="!groupForm.get('name').valid && groupForm.get('name').errors?.maxlength && (groupForm.get('name').touched || isSubmitted)">
              <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Form.Name}}, please correct it.
            </span>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-6 mb-3"
               [ngClass]="{'has-error': !groupForm.get('description').valid && (groupForm.get('description').touched|| isSubmitted) }">
            <label for="name" class="asterisk">Description:</label>
            <textarea class="form-control" id="description" name="description" formControlName="description"></textarea>
            <div class="has-error">
              <span *ngIf="!groupForm.get('description').valid && groupForm.get('description').errors?.required && (groupForm.get('description').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please enter group description.
              </span>
              <span *ngIf="!groupForm.get('description').valid && groupForm.get('description').errors?.pattern && (groupForm.get('description').touched || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.CLIENT_NAME_VALIDATION_MESSAGE}}
              </span>
              <span *ngIf="!groupForm.get('description').valid && groupForm.get('description').errors?.maxlength && (groupForm.get('description').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Form.Description}}, please correct it.
              </span>
            </div>
          </div>
        </div>
        
        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="Template">Access Level:</label>
            <div class="radio-group d-flex">
              <div class="radio mr-3">
                <input type="radio" id="read" formControlName="accessLevel" value="read"/>   <label for="read"><span>Read</span></label>
              </div>
              <div class="radio">
                <input type="radio" id="write" formControlName="accessLevel" value="write"/>  <label for="write"><span>Write</span></label>
              </div>
            </div>
          </div>
        </div>
      </form>
      
      <div class="col-md-12 box mb-0 mt-3">
        <div class="col-md-12 p-0 fdl-2">
          <label aria-describedby="addOrganizations" class="w-100">Select/De-Select Organizations to Assign:</label>
          <button class="btn fdl-btn btn-secondary-light " id="addOrganizations" (click)="openOrgDrawer()">Select Organizations</button>
          <div class="filter-wrapper mt-2">
            <div class="filter-tag" *ngFor="let org of selectedOrgForDisplay">
              <div class="tag" data-toggle="tooltip" data-placement="top" title="{{org.title}}">
                {{org.title}} <a href="javascript:void(0)" (click)="removeOrg(org.id)" aria-label="Close" class="tag-close">
                  <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 box mb-0 mt-3">
        <div class="col-md-12 p-0 fdl-2">
          <label aria-describedby="addProducts" class="w-100">Select/De-Select Products to Assign:</label>
          <button class="btn fdl-btn btn-secondary-light " id="addProducts" (click)="openProductsDrawer()">Select Products</button>
          <div class="filter-wrapper mt-2">
            <div class="filter-tag" *ngFor="let product of selectedProductsForDisplay">
              <div class="tag" data-toggle="tooltip" data-placement="top" title="{{product.title}}">
                {{product.title}} <a href="javascript:void(0)" (click)="removeProduct(product.id)" aria-label="Close" class="tag-close">
                  <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 box mb-0 mt-3">
        <div class="col-md-12 p-0 fdl-2">
          <label aria-describedby="addClients" class="w-100">Select/De-Select Clients to Assign:</label>
          <button class="btn fdl-btn btn-secondary-light " id="addClients" (click)="openClientDrawer()">Select Clients</button>
          <div class="filter-wrapper mt-2">
            <div class="filter-tag" *ngFor="let client of selectedClientForDisplay">
              <div class="tag" data-toggle="tooltip" data-placement="top" title="{{client.titleWithDuns}}">
                {{client.title}}
                <a href="javascript:void(0)" (click)="removeClient(client.id)" aria-label="Close" class="tag-close">
                  <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 box mb-0 mt-3">
        <div class="col-md-12 p-0 fdl-2">
          <label aria-describedby="addUsers" class="w-100">Select/De-Select Users to Assign:</label>
          <button class="btn fdl-btn btn-secondary-light " id="addUsers" (click)="openUserDrawer()">Select Users</button>
          <div class="filter-wrapper mt-2">
            <div class="filter-tag" *ngFor="let user of selectedUserForDisplay">
              <div class="tag" data-toggle="tooltip" data-placement="top" title="{{user.name}}">
                {{user.name}}
                <a href="javascript:void(0)" (click)="removeUser(user.id)" aria-label="Close" class="tag-close">
                  <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                </a>
              </div>
            </div>
          </div>

          <div class="row mt-2" *ngIf="selectedUserIds.length > 0">
            <div class="col-lg-9">
              <div class="alert alert-info alert-dismissible">
                <span aria-label="sucess" class="iconLabel"></span><i aria-hidden="true" class="material-icons">info</i>
                <p class="mb-1">Restricted Admin role will be updated to the added user's profile and any existing Admin role will be removed. </p>
              </div>
            </div>  
          </div>

          <!-- <div class="col-md-6 mb-3" class="has-error" *ngIf="selectedUserIds.length == 0 && isSubmitted">
            <span>
              <i class="material-icons">warning</i> Please assign at least one user.
            </span>
          </div> -->
        </div>
      </div>
    </div>

    <div class="fdl-2 button-container" *ngIf="!isDrawerType">
        <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()" aria-label="Cancel">Cancel</button>
        <div>
            <button type="button" class="btn fdl-btn btn-primary-orange" (click)="groupFormSubmit()">Submit</button>
        </div>
    </div>
</div>

<!-- Buttons For Drawer -->
<div class="fdl-2 button-container" *ngIf="isDrawerType">
    <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()" aria-label="Cancel">Cancel</button>
    <div>
        <button type="button" class="btn fdl-btn btn-primary-orange" (click)="groupFormSubmit()">Submit</button>
    </div>
</div>

<!-- Drawer Start -->
<div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" id="addDependentForms" tabindex="-1" aria-labelledby="addNewAccount" aria-expanded="true" role="region" *ngIf="showDrawer">
  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12" *ngIf="showOrgDrawer">
    <h3>Select Organizations to Assign</h3>
    <p>Below are the available organizations to assign to a group. You can select multiple organizations for a single group.</p>
    <div class="row">
      <div class="input-group search-box mb-3 col-md-11">
        <input type="text" class="form-control" placeholder="Search..." id="searchOrgString" name="searchOrgString" [(ngModel)]="searchOrgString">
      </div>
    </div>
    <fieldset>
      <legend class="border-0"></legend>
      <div class="checkbox" *ngFor="let org of orgSuggestions | filter: 'title': searchOrgString ; let i = index">
        <div class="position-relative">
          <input type="checkbox" [(ngModel)]="org.selected" id="{{'chk'+ i}}" />
          <label for="{{'chk'+ i}}"><span>{{org.title}}</span></label>
        </div>
      </div>
    </fieldset>
    <p *ngIf="orgSuggestions.length == 0">No records available.</p>
    <div class=" pt-3">
      <div class="fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerOrgCancel()">Cancel</button>
        <div>  <button type="button" (click)="drawerOrgSubmit()" class="btn fdl-btn btn-primary-orange float-right">Assign</button></div>
      </div>
    </div>
  </div>

  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12" *ngIf="showProductDrawer">
    <h3>Select Products to Assign</h3>
    <p>Below are the available products to assign to a group. You can select multiple products for a single group.</p>
    <div class="row">
      <div class="input-group search-box mb-3 col-md-11">
        <input type="text" class="form-control" placeholder="Search..." id="searchProductString" name="searchProductString" [(ngModel)]="searchProductString"
        (input)="onSearchChange($event.target.value)">
      </div>
      <!-- <span class="pl-3" *ngIf="!isSearchProductValid(searchProductString)"><i class="material-icons">warning</i> 
        {{ regexValidationMessage.NAME_VALIDATION_MESSAGE }}
      </span> -->
    </div>
    <fieldset>
      <legend class="border-0"></legend>
      <div class="checkbox" *ngFor="let product of productSuggestions | filter: 'title': searchProductString ; let i = index">
        <div class="position-relative">
          <input type="checkbox" [(ngModel)]="product.selected" id="{{'chk'+ i}}" />
          <label for="{{'chk'+ i}}"><span>{{product.title}}</span></label>
        </div>
      </div>
    </fieldset>
    <p *ngIf="productSuggestions?.length == 0">No records available.</p>
    <div class=" pt-3">
      <div class="fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerProductCancel()">Cancel</button>
        <div>  <button type="button" (click)="drawerProductSubmit()" class="btn fdl-btn btn-primary-orange float-right">Assign</button></div>
      </div>
    </div>
  </div>

  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12" *ngIf="showClientDrawer">
    <h3>Select Clients to Assign</h3>
    <p>Below are the available clients to assign to a group. You can select multiple clients for a single group.</p>
    <div class="row">
      <div class="input-group search-box mb-3 col-md-11">
        <input type="text" class="form-control" placeholder="Search..." id="searchClientString" name="searchClientString" [(ngModel)]="searchClientString" (input)="onClientSearchChange($event.target.value)">
      </div>
      <!-- <div class="d-flex">
        <div class="refresh-icon-link mt-1">
          <a title="Reset Client List" id="refreshTask" (click)="resetClientList()">
            <i aria-hidden="true" class="fa fa-refresh"></i>
          </a>
        </div>
      </div> -->
    </div>
    <fieldset>
      <legend class="border-0"></legend>
      <div class="checkbox" *ngFor="let client of clientSuggestions | filter: 'titleWithDuns': searchClientString ; let i = index">
        <div class="position-relative">
          <input type="checkbox" [(ngModel)]="client.selected" id="{{'chk'+ i}}" />
          <label for="{{'chk'+ i}}"><span>{{client.titleWithDuns}}</span></label>
        </div>
      </div>
    </fieldset>
    <p *ngIf="clientSuggestions.length == 0">No records available.</p>
    <div class=" pt-3">
      <div class="fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerClientCancel()">Cancel</button>
        <div>  <button type="button" (click)="drawerClientSubmit()" class="btn fdl-btn btn-primary-orange float-right">Assign</button></div>
      </div>
    </div>
  </div>

  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12" *ngIf="showUserDrawer">
    <h3>Select Users to Assign</h3>
    <p>Below are the available users to assign to a group. You can select multiple users for a single group.</p>
    <div class="row">
      <div class="input-group search-box mb-3 col-md-11">
        <input type="text" class="form-control" placeholder="Search..." id="searchUserString" name="searchUserString" [(ngModel)]="searchUserString">
      </div>
    </div>
    <fieldset>
      <legend class="border-0"></legend>
      <div class="checkbox" *ngFor="let user of userSuggestions | filter: 'name': searchUserString ; let i = index">
        <div class="position-relative">
          <input type="checkbox" [(ngModel)]="user.selected" id="{{'chk'+ i}}" />
          <label for="{{'chk'+ i}}"><span>{{user.name}}</span></label>
        </div>
      </div>
    </fieldset>
    <p *ngIf="userSuggestions.length == 0">No records available.</p>
    <div class=" pt-3">
      <div class="fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerUserCancel()">Cancel</button>
        <div>  <button type="button" (click)="drawerUserSubmit()" class="btn fdl-btn btn-primary-orange float-right">Assign</button></div>
      </div>
    </div>
  </div>
</div>
<div class="backdrop-space" *ngIf="showDrawer">
    <div class="backdrop"></div>
</div>
<!-- Drawer End -->
