import { Injector } from '@angular/core'
import {
  FormioCustomComponentInfo,
  registerCustomFormioComponent,
  Components
} from 'angular-formio'
import { SensitiveInformationComponent } from './sensitive-information.component'


const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'sensitiveinformation', // custom type. Formio will identify the field with this type.
  selector: 'sensitive-information', // custom selector. Angular Elements will create a custom html tag with this selector
  title: 'Sensitive Information', // Title of the component
  group: 'basic', // Build Group
  icon: 'check', // Icon
  fieldOptions: ['label', 'value', 'key', 'hidden', 'buttonStyle', 'inline', 'typeOfSensitiveInformation', 'labelPosition', 'readonly','validate'],
  editForm: editForm, // Use editForm from Radio buttons
  //  template: 'button', // Optional: define a template for the element. Default: input
  changeEvent: 'valueChange', // Optional: define the changeEvent when the formio updates the value in the state. Default: 'valueChange',
//  documentation: '', // Optional: define the documentation of the field
//weight: 0, // Optional: define the weight in the builder group
//  schema: {}, // Optional: define extra default schema for the field
//  extraValidators: [], // Optional: define extra validators  for the field
//  emptyValue: '' // Optional: the emptyValue of the field
}

function editForm() {

  return {
    components: [
      { key: 'type', type: 'hidden' },
      {
        label: "Tabs",
        components: [
          displayTab(),
          dataTab(),
          validationTab(),
          apiTab(),
          conditionalTab(),
          //layoutTab()
        ],
        key: "tabs",
        type: "tabs",
        input: false,
        tableView: false
      },
    ],
  };
}

function displayTab() {
  return {
    label: "Display",
    key: "display",
    components: [
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'label',
        label: 'Label',
        placeholder: 'Field Label',
        tooltip: 'The label for this field that will appear next to it.',
        validate: {
          required: true
        }, defaultValue: "Sensitive Information",
      }, {
        "label": "Select Types of Sensitive Information",
        "widget": "choicesjs",
        "tableView": true,
        validate: {
          required: true
        },
        "data": {
          "values": [
            {
              "label": "Social Security Number",
              "value": "SSN",
              "shortcut": ""
            },
            {
              "label": "Credit Card Numbers",
              "value": "CARD",
              "shortcut": ""

            },
            {
              "label": "Non-US Tax Id",
              "value": "NonUsTaxId",
              "shortcut": ""
            },

            {
              "label": "Personal Health Information",
              "value": "PHI",
              "shortcut": ""
            },

            {
              "label": "Financial/Banking Account Number",
              "value": "FBAN",
              "shortcut": ""

           },
           {
             "label": "Other",
             "value": "OTHER",
             "shortcut": ""
           }
          ]
        },
        "key": "typeOfSensitiveInformation",
        "type": "select",
        "input": true,
        "defaultValue": ""
      
      },
      {
        type: 'select',
        input: true,
        key: 'labelPosition',
        label: 'Label Position',
        tooltip: 'Position for the label for this field.',
        weight: 20,
        defaultValue: 'top',
        dataSrc: 'values',
        data: {
          values: [
            { label: 'Top', value: 'top' },
            { label: 'Left (Left-aligned)', value: 'left-left' },
            { label: 'Left (Right-aligned)', value: 'left-right' },
            { label: 'Right (Left-aligned)', value: 'right-left' },
            { label: 'Right (Right-aligned)', value: 'right-right' },
            { label: 'Bottom', value: 'bottom' }
          ]
        }
      },
      {
        type: 'number',
        input: true,
        key: 'labelWidth',
        label: 'Label Width',
        tooltip: 'The width of label on line in percentages.',
        clearOnHide: false,
        weight: 30,
        placeholder: '30',
        suffix: '%',
        validate: {
          min: 0,
          max: 100
        },
        conditional: {
          json: {
            and: [
              { '!==': [{ var: 'data.labelPosition' }, 'top'] },
              { '!==': [{ var: 'data.labelPosition' }, 'bottom'] },
            ]
          }
        }
      },
      {
        type: 'number',
        input: true,
        key: 'labelMargin',
        label: 'Label Margin',
        tooltip: 'The width of label margin on line in percentages.',
        clearOnHide: false,
        weight: 30,
        placeholder: '3',
        suffix: '%',
        validate: {
          min: 0,
          max: 100
        },
        conditional: {
          json: {
            and: [
              { '!==': [{ var: 'data.labelPosition' }, 'top'] },
              { '!==': [{ var: 'data.labelPosition' }, 'bottom'] },
            ]
          }
        }
      },
      {
        weight: 200,
        type: 'textarea',
        input: true,
        key: 'description',
        label: 'Description',
        placeholder: 'Description for this field.',
        tooltip: 'The description is text that will appear below the input field.',
        editor: 'ace',
        as: 'html',
        wysiwyg: {
          minLines: 3,
          isUseWorkerDisabled: true,
        },
      },
      {
        weight: 300,
        type: 'textarea',
        input: true,
        key: 'tooltip',
        label: 'Tooltip',
        placeholder: 'To add a tooltip to this field, enter text here.',
        tooltip: 'Adds a tooltip to the side of this field.',
        editor: 'ace',
        as: 'html',
        wysiwyg: {
          minLines: 3,
          isUseWorkerDisabled: true,
        },
      },
      {
        weight: 500,
        type: 'textfield',
        input: true,
        key: 'customClass',
        label: 'Custom CSS Class',
        placeholder: 'Custom CSS Class',
        tooltip: 'Custom CSS class to add to this component.'
      },
      {
        weight: 1100,
        type: 'checkbox',
        label: 'Hidden',
        tooltip: 'A hidden field is still a part of the form, but is hidden from view.',
        key: 'hidden',
        input: true
      },
      {
        weight: 1200,
        type: 'checkbox',
        label: 'Hide Label',
        tooltip: 'Hide the label (title, if no label) of this component. This allows you to show the label in the form builder, but not when it is rendered.',
        key: 'hideLabel',
        input: true
      },
      {
        weight: 1400,
        type: 'checkbox',
        label: 'Disabled',
        tooltip: 'Disable the form input.',
        key: 'disabled',
        input: true
      },
    ]
  }
}

function dataTab() {
  return {
    "key": "data",
    "components": [ {
      "type": "select",
      "input": true,
      "label": "Storage Type",
      "key": "dataType",
      "clearOnHide": true,
      "tooltip": "The type to store the data. If you select something other than autotype, it will force it to that type.",
      "weight": 12,
      "template": "<span>{{ item.label }}</span>",
      "dataSrc": "values",
      "data": {
        "values": [{
          "label": "Autotype",
          "value": "auto"
        }, {
          "label": "String",
          "value": "string"
        }, {
          "label": "Number",
          "value": "number"
        }, {
          "label": "Boolean",
          "value": "boolean"
        }, {
          "label": "Object",
          "value": "object"
        }
        ]
      }
    }, {
      "weight": 30,
      "type": "radio",
      "label": "Persistent",
      "tooltip": "A persistent field will be stored in database when the form is submitted.",
      "key": "persistent",
      "input": true,
      "inline": true,
      "defaultValue": true,
      "values": [{
        "label": "None",
        "value": false
      }, {
        "label": "Server",
        "value": true
      }, {
        "label": "Client",
        "value": "client-only"
      }
      ]
    }, {
      "weight": 150,
      "type": "checkbox",
      "label": "Protected",
      "tooltip": "A protected field will not be returned when queried via API.",
      "key": "protected",
      "input": true
    }, {
      "type": "checkbox",
      "input": true,
      "weight": 200,
      "key": "dbIndex",
      "label": "Database Index",
      "tooltip": "Set this field as an index within the database. Increases performance for submission queries."
    }, {
      "weight": 400,
      "type": "checkbox",
      "label": "Encrypted (Enterprise Only)",
      "tooltip": "Encrypt this field on the server. This is two way encryption which is not suitable for passwords.",
      "key": "encrypted",
      "input": true
    }, {
      "type": "select",
      "input": true,
      "key": "redrawOn",
      "label": "Redraw On",
      "weight": 600,
      "tooltip": "Redraw this component if another component changes. This is useful if interpolating parts of the component like the label.",
      "dataSrc": "custom",
      "valueProperty": "value",
      "data": {},
      "conditional": {
        "json": {
          "!": [{
            "var": "data.dataSrc"
          }
          ]
        }
      }
    }, {
      "weight": 700,
      "type": "checkbox",
      "label": "Clear Value When Hidden",
      "key": "clearOnHide",
      "defaultValue": true,
      "tooltip": "When a field is hidden, clear the value.",
      "input": true
    }, {
      "type": "panel",
      "title": "Custom Default Value",
      "theme": "default",
      "collapsible": true,
      "collapsed": true,
      "key": "customDefaultValuePanel",
      "weight": 1000,
      "components": [{
        "type": "htmlelement",
        "tag": "div",
        "content": "<p>The following variables are available in all scripts.</p><table class=\"table table-bordered table-condensed table-striped\"><tr><th>form</th><td>The complete form JSON object</td></tr><tr><th>submission</th><td>The complete submission object.</td></tr><tr><th>data</th><td>The complete submission data object.</td></tr><tr><th>row</th><td>Contextual \"row\" data, used within DataGrid, EditGrid, and Container components</td></tr><tr><th>component</th><td>The current component JSON</td></tr><tr><th>instance</th><td>The current component instance.</td></tr><tr><th>value</th><td>The current value of the component.</td></tr><tr><th>moment</th><td>The moment.js library for date manipulation.</td></tr><tr><th>_</th><td>An instance of <a href=\"https://lodash.com/docs/\" target=\"_blank\">Lodash</a>.</td></tr><tr><th>utils</th><td>An instance of the <a href=\"http://formio.github.io/formio.js/docs/identifiers.html#utils\" target=\"_blank\">FormioUtils</a> object.</td></tr><tr><th>util</th><td>An alias for \"utils\".</td></tr></table><br/>"
      }, {
        "type": "panel",
        "title": "JavaScript",
        "collapsible": true,
        "collapsed": false,
        "style": {
          "margin-bottom": "10px"
        },
        "key": "customDefaultValue-js",
        "components": [{
          "type": "textarea",
          "key": "customDefaultValue",
          "rows": 5,
          "editor": "ace",
          "hideLabel": true,
          "input": true
        }, {
          "type": "htmlelement",
          "tag": "div",
          "content": "<p>Enter custom javascript code.</p><p><h4>Example:</h4><pre>value = data.firstName + \" \" + data.lastName;</pre></p>"
        }
        ]
      }, {
        "type": "panel",
        "title": "JSONLogic",
        "collapsible": true,
        "collapsed": true,
        "key": "customDefaultValue-json",
        "components": [{
          "type": "htmlelement",
          "tag": "div",
          "content": "<p>Execute custom logic using <a href=\"http://jsonlogic.com/\" target=\"_blank\">JSONLogic</a>.</p><p>Full <a href=\"https://lodash.com/docs\" target=\"_blank\">Lodash</a> support is provided using an \"_\" before each operation, such as <code>{\"_sum\": {var: \"data.a\"}}</code></p><p><h4>Example:</h4><pre>{\"cat\": [{\"var\": \"data.firstName\"}, \" \", {\"var\": \"data.lastName\"}]}</pre>"
        }, {
          "type": "textarea",
          "key": "customDefaultValue",
          "rows": 5,
          "editor": "ace",
          "hideLabel": true,
          "as": "json",
          "input": true
        }
        ]
      }
      ]
    }, {
      "type": "panel",
      "title": "Calculated Value",
      "theme": "default",
      "collapsible": true,
      "collapsed": true,
      "key": "calculateValuePanel",
      "weight": 1100,
      "components": [{
        "type": "htmlelement",
        "tag": "div",
        "content": "<p>The following variables are available in all scripts.</p><table class=\"table table-bordered table-condensed table-striped\"><tr><th>token</th><td>The decoded JWT token for the authenticated user.</td></tr><tr><th>form</th><td>The complete form JSON object</td></tr><tr><th>submission</th><td>The complete submission object.</td></tr><tr><th>data</th><td>The complete submission data object.</td></tr><tr><th>row</th><td>Contextual \"row\" data, used within DataGrid, EditGrid, and Container components</td></tr><tr><th>component</th><td>The current component JSON</td></tr><tr><th>instance</th><td>The current component instance.</td></tr><tr><th>value</th><td>The current value of the component.</td></tr><tr><th>moment</th><td>The moment.js library for date manipulation.</td></tr><tr><th>_</th><td>An instance of <a href=\"https://lodash.com/docs/\" target=\"_blank\">Lodash</a>.</td></tr><tr><th>utils</th><td>An instance of the <a href=\"http://formio.github.io/formio.js/docs/identifiers.html#utils\" target=\"_blank\">FormioUtils</a> object.</td></tr><tr><th>util</th><td>An alias for \"utils\".</td></tr></table><br/>"
      }, {
        "type": "panel",
        "title": "JavaScript",
        "collapsible": true,
        "collapsed": false,
        "style": {
          "margin-bottom": "10px"
        },
        "key": "calculateValue-js",
        "components": [{
          "type": "textarea",
          "key": "calculateValue",
          "rows": 5,
          "editor": "ace",
          "hideLabel": true,
          "input": true
        }, {
          "type": "htmlelement",
          "tag": "div",
          "content": "<p>Enter custom javascript code.</p><p><h4>Example:</h4><pre>value = data.a + data.b + data.c;</pre></p>"
        }
        ]
      }, {
        "type": "panel",
        "title": "JSONLogic",
        "collapsible": true,
        "collapsed": true,
        "key": "calculateValue-json",
        "components": [{
          "type": "htmlelement",
          "tag": "div",
          "content": "<p>Execute custom logic using <a href=\"http://jsonlogic.com/\" target=\"_blank\">JSONLogic</a>.</p><p>Full <a href=\"https://lodash.com/docs\" target=\"_blank\">Lodash</a> support is provided using an \"_\" before each operation, such as <code>{\"_sum\": {var: \"data.a\"}}</code></p><p><h4>Example:</h4><pre>{\"+\": [{\"var\": \"data.a\"}, {\"var\": \"data.b\"}, {\"var\": \"data.c\"}]}</pre><p><a target=\"_blank\" href=\"http://formio.github.io/formio.js/app/examples/calculated.html\">Click here for an example</a></p>"
        }, {
          "type": "textarea",
          "key": "calculateValue",
          "rows": 5,
          "editor": "ace",
          "hideLabel": true,
          "as": "json",
          "input": true
        }
        ]
      }
      ]
    }, {
      "type": "checkbox",
      "input": true,
      "weight": 1100,
      "key": "calculateServer",
      "label": "Calculate Value on server",
      "tooltip": "Checking this will run the calculation on the server. This is useful if you wish to override the values submitted with the calculations performed on the server."
    }, {
      "type": "checkbox",
      "input": true,
      "weight": 1200,
      "key": "allowCalculateOverride",
      "label": "Allow Manual Override of Calculated Value",
      "tooltip": "When checked, this will allow the user to manually override the calculated value."
    }
    ],
    "label": "Data",
    "weight": 10
  }
}

function validationTab() {
  return {
    label: "Validation",
    key: "validation",
    components: [
      {
        weight: 10,
        type: 'checkbox',
        label: 'Required',
        tooltip: 'A required field must be filled in before the form can be submitted.',
        key: 'validate.required',
        input: true
      },
      {
        weight: 190,
        type: 'textfield',
        input: true,
        key: 'errorLabel',
        label: 'Error Label',
        placeholder: 'Error Label',
        tooltip: 'The label for this field when an error occurs.'
      },
      {
        weight: 200,
        key: 'validate.customMessage',
        label: 'Custom Error Message',
        placeholder: 'Custom Error Message',
        type: 'textfield',
        tooltip: 'Error message displayed if any error occurred.',
        input: true
      }
      ,
      {
        "type": "panel",
        "title": "Custom Validation",
        "theme": "default",
        "collapsible": true,
        "collapsed": true,
        "key": "customErrorPanel",
        "weight": 1100,
        "components": [ {
          "type": "panel",
          "title": "JavaScript",
          "collapsible": true,
          "collapsed": false,
          "style": {
            "margin-bottom": "10px"
          },
          "key": "customError-js",
          "components": [{
            "type": "textarea",
            "key": "validate.custom",
            "rows": 5,
            "editor": "ace",
            "hideLabel": true,
            "input": true
          }, {
            "type": "htmlelement",
            "tag": "div",
            "content": "<p>Enter custom validation code.</p><p>You must assign the valid variable as either true or an error message if validation fails.</p><p><h4>Example:</h4><pre>valid = (input === 'Joe') ? true : 'Your name must be \"Joe\"';</pre></p>"
          }
          ]
        }, 
        ]
      },
    ]
  }
}

function apiTab() {
  return {
    label: "API",
    key: "api",
    components: [
      {
        weight: 0,
        type: 'textfield',
        input: true,
        key: 'key',
        label: 'Property Name',
        tooltip: 'The name of this field in the API endpoint.',
        validate: {
          pattern: '(\\w|\\w[\\w-.]*\\w)',
          patternMessage: 'The property name must only contain alphanumeric characters, underscores, dots and dashes and should not be ended by dash or dot.',
          required: true
        }
      },
      {
        weight: 100,
        type: 'tags',
        input: true,
        label: 'Field Tags',
        storeas: 'array',
        tooltip: 'Tag the field for use in custom logic.',
        key: 'tags'
      },
      {
        weight: 200,
        type: 'datamap',
        label: 'Custom Properties',
        tooltip: 'This allows you to configure any custom properties for this component.',
        key: 'properties',
        valueComponent: {
          type: 'textfield',
          key: 'value',
          label: 'Value',
          placeholder: 'Value',
          input: true
        }
      },
    ]
  }
}

function conditionalTab() {
  return {
    label: "Conditional",
    key: "conditional",
    components: [
      {
        type: 'panel',
        title: 'Simple',
        key: 'simple-conditional',
        theme: 'default',
        components: [
          {
            type: 'select',
            input: true,
            label: 'This component should Display:',
            key: 'conditional.show',
            dataSrc: 'values',
            data: {
              values: [
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' }
              ]
            }
          },
          {
            type: 'select',
            input: true,
            label: 'When the form component:',
            key: 'conditional.when',
            dataSrc: 'custom',
            valueProperty: 'value',
            data: {
              custom(context) {
                return getContextComponents(context);
              }
            }
          },
          {
            type: 'textfield',
            input: true,
            label: 'Has the value:',
            key: 'conditional.eq'
          }
        ]
      },
    ]
  }
}

function layoutTab() {
  return {
    label: "Layout",
    key: "layout",
    components: [
      {
        label: 'HTML Attributes',
        type: 'datamap',
        input: true,
        key: 'attributes',
        keyLabel: 'Attribute Name',
        valueComponent: {
          type: 'textfield',
          key: 'value',
          label: 'Attribute Value',
          input: true
        },
        tooltip: 'Provide a map of HTML attributes for component\'s input element (attributes provided by other component settings or other attributes generated by form.io take precedence over attributes in this grid)',
        addAnother: 'Add Attribute',
      },
    ]
  }
}

function getContextComponents(context) {
  const values = [];

  context.utils.eachComponent(context.instance.options.editForm.components, (component, path) => {
    if (component.key !== context.data.key) {
      values.push({
        label: `${component.label || component.key} (${path})`,
        value: path,
      });
    }
  });

  return values;
}

export function registerSensitiveComponent(injector: Injector) {
  registerCustomFormioComponent(COMPONENT_OPTIONS, SensitiveInformationComponent, injector)
}
