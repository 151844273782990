<!-- Page Title (Rectangle) -->
<div class="heading-container fdl-2" *ngIf="formReady">
  <h1 *ngIf="!isEdit">{{subHeader}}</h1>
  <h1 *ngIf="isEdit">{{createFormGroup.get('formNameCtrl').value}}</h1>
</div>
<div class="form-container" *ngIf="formReady">
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>

  <p> Please complete below required information to create a new form.</p>

  <div class="form-row" *ngIf="(isAdmin && isEdit)">
    <div class="col-md-6 mb-3">
      <label for="organizationTitle" class="asterisk">Organization:</label>
      <input type="text" name="text" [(ngModel)]="organizationDetails.organizationTitle"
             readonly class="form-control" id="organizationTitle">
    </div>
  </div>

  <form [formGroup]="createFormGroup" autocomplete="off">
    <div class="form-row" *ngIf="(isAdmin && !isEdit)">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="organization" class="asterisk">Organization:</label>
        <fdl-type-ahead formControlName="organization" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
        <div class="has-error">
          <span *ngIf="invalidOrganizationName || (!createFormGroup.get('organization').valid && createFormGroup.get('organization').errors?.pattern && createFormGroup.get('organization').dirty) "><i class="material-icons">warning</i>Please enter a valid Organization Name</span>
          <span *ngIf="!createFormGroup.get('organization').valid  && createFormGroup.get('organization').errors?.required && createFormGroup.get('organization').dirty"><i class="material-icons">warning</i> Please select organization name. </span>
        </div>
      </div>
    </div>
    <div class="row">
      <!--<div class="col-md-6 mb-3">
        <label for="Organization">Organization:</label>
        <div class="fdl-select">
            <select id="Organization" class="form-control" aria-invalid="false" formControlName="orgNameCtrl">
                <option value="">Select</option>
                <option *ngFor="let org of orgNames" [value]="org.org_name">
                    {{org.org_name}}
                </option>
            </select>
           </div>
    </div> -->

      <div class="col-md-6 mb-3">
        <label class="asterisk" for="nameP">Form Name:</label>
        <input type="text" class="form-control" id="formName" formControlName="formNameCtrl">
        <div class="has-error">
          <span *ngIf="!createFormGroup.get('formNameCtrl').valid && (createFormGroup.get('formNameCtrl').errors?.required || createFormGroup.get('formNameCtrl').errors?.validateWhiteSpaces) && createFormGroup.get('formNameCtrl').touched">
            <i class="material-icons">warning</i> Please enter a form name.
          </span>
          <span *ngIf="!createFormGroup.get('formNameCtrl').valid && createFormGroup.get('formNameCtrl').errors?.pattern && createFormGroup.get('formNameCtrl').touched">
            <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
          </span>
          <span *ngIf="!createFormGroup.get('formNameCtrl').valid && createFormGroup.get('formNameCtrl').errors?.maxlength && createFormGroup.get('formNameCtrl').touched">
            <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Form.Name}}, please correct it.
          </span>

        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-6 mb-3">
        <label class="asterisk" for="nameP">Form Description:</label>
        <textarea class="form-control" id="formDes" formControlName="formDesCtrl"></textarea>
        <div class="has-error">
          <span *ngIf="!createFormGroup.get('formDesCtrl').valid && (createFormGroup.get('formDesCtrl').errors?.required || createFormGroup.get('formDesCtrl').errors?.validateWhiteSpaces) && createFormGroup.get('formDesCtrl').touched">
            <i class="material-icons">warning</i> Please enter the form description.
          </span>
          <span *ngIf="!createFormGroup.get('formDesCtrl').valid && createFormGroup.get('formDesCtrl').errors?.pattern && createFormGroup.get('formDesCtrl').touched">
            <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
          </span>
          <span *ngIf="!createFormGroup.get('formDesCtrl').valid && createFormGroup.get('formDesCtrl').errors?.maxlength && createFormGroup.get('formDesCtrl').touched">
            <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Form.Description}}, please correct it.
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="Product" class="asterisk">Product:</label>
        <fdl-type-ahead formControlName="productNameCtrl" [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" 
        [optionsLimit]="100" [minLength]="0" (input)="typeaheadLoadingProducts()" (onSelect)="updateRespondents()"></fdl-type-ahead>
        <div class="has-error">
          <span *ngIf="(!createFormGroup.get('productNameCtrl').valid && createFormGroup.get('productNameCtrl').errors?.pattern && (createFormGroup.get('productNameCtrl').dirty))">
            <i class="material-icons">warning</i>{{regexValidationMessage.PRODUCT_NAME_VALIDATION_MESSAGE}}
          </span>
          <span *ngIf="invalidProductName || (!createFormGroup.get('productNameCtrl').valid && createFormGroup.get('productNameCtrl').errors?.required && (createFormGroup.get('productNameCtrl').touched || createFormGroup.get('productNameCtrl').dirty))">
            <i class="material-icons">warning</i> Please select Product name from the list.
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="intake-request-form  intake-request-form-wrapper   mb-10">
        <div class="box">
          <div class="col-md-12 mb-3 pl-0 typeahead-wrapper">
            <label for="formType" class="asterisk">Form Type</label>
            <fieldset>
              <!--<div class="radio-group d-flex">
              <div class="radio mr-3">
                <input type="radio" id="isRequestInTakeTwo" name="isRequestInTake" value="true" formControlName="isRequestInTake">
                <label for="isRequestInTakeTwo"><span>Yes</span></label>
              </div>
              <div class="radio">
                <input type="radio" id="isRequestInTakeOne" name="isRequestInTake" value="false" formControlName="isRequestInTake">

                <label for="isRequestInTakeOne"><span>No</span></label>
              </div>
            </div>-->
              <div class="fdl-select">
                <select name="formType" class="form-control" aria-invalid="false" formControlName="formType">
                  <option value="DataGathering">Data Gathering</option>
                  <option value="Intake">Intake</option>
                  <option value="ProjectWorkflowTemplate">Project Workflow Template</option>
                  <!--<option value="Both">Data Gathering & Intake</option>-->
                </select>
              </div>
            </fieldset>

          </div>

          <div class="col-md-12 mb-3 pl-0 typeahead-wrapper">
            <label for="formSubType" class="asterisk">Form Sub Type</label>
            <fieldset>
              <div class="fdl-select">
                <select name="formSubType" class="form-control" aria-invalid="false" formControlName="formSubType">
                  <!--<option *ngFor="let formSubType of formSubTypes" [value]="formSubType.id"> {{formSubType.title}} </option>-->
                  <option value="1">None</option>
                  <option value="2">RPA</option>
                  <option value="3">Service Point</option>
                  <option value="4">Clarity</option>
                  <option value="5">Configuration</option>
                </select>
              </div>
            </fieldset>

          </div>

          <div class="col-md-12 mb-3 pl-0 typeahead-wrapper">
            <label for="Template">Does your form have Sensitive Data?</label>
            <div class="radio-group d-flex">
              <div class="radio mr-3">
                <input type="radio" id="isSensitiveDataTwo" name="isSensitiveDataCtrl" value="1" formControlName="isSensitiveDataCtrl">
                <label for="isSensitiveDataTwo"><span>Yes</span></label>
              </div>
              <div class="radio">
                <input type="radio" id="isSensitiveDataOne" name="isSensitiveDataCtrl" value="0" formControlName="isSensitiveDataCtrl">
                <label for="isSensitiveDataOne"><span>No</span></label>
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-3 pl-0 typeahead-wrapper">
            <label for="Template">Do you want to enable Email Notifications?</label>
            <div class="radio-group d-flex">
              <div class="radio mr-3">
                <input type="radio" id="enableEmailNotificationsTwo" name="enableEmailNotificationsCtrl" value="1" formControlName="enableEmailNotificationsCtrl">
                <label for="enableEmailNotificationsTwo"><span>Yes</span></label>
              </div>
              <div class="radio">
                <input type="radio" id="enableEmailNotificationsOne" name="enableEmailNotificationsCtrl" value="0" formControlName="enableEmailNotificationsCtrl">
                <label for="enableEmailNotificationsOne"><span>No</span></label>
              </div>
            </div>
          </div>

          <div *ngIf="(createFormGroup.controls['formType'].value =='Intake')" class="col-md-12 pl-0 mb-3 "
               [ngClass]="{'has-error': ( createFormGroup.get('notificationUser').errors?.duplicate || (!createFormGroup.get('notificationUser').valid && createFormGroup.get('notificationUser').touched)) }">
            <div class="col-md12">
              <label for="Product" class="">
                Who should be informed on Intake Request submission?
              </label>
            </div>
            <app-mutipleadd #addmultiple2 (formReady)="formInitialized('notificationUser', $event)" buttonText="Add User" [isFdl]="true" [fdlData]="userData2" [filedJson]="JsonForNotifyUser" elementName="notificationUser" [filedDetails]="JsonForNotifyUserFieldDetails" [formControlData]="createFormGroup"></app-mutipleadd>
            <span *ngIf="(createFormGroup.get('notificationUser').errors?.duplicate)">
              <i class="material-icons">warning</i>Duplicate
            </span>

          </div>

          <div class="d-flex mb-12 updateApprover" *ngIf="(createFormGroup.controls['formType'].value =='Intake')">
            <div class="col-md-6 mb-3 pr-0 pt-1 pl-0"
                 [ngClass]="{'has-error': ( createFormGroup.get('approver').errors?.duplicate || (!createFormGroup.get('approver').valid && createFormGroup.get('approver').touched)) }">
              <div class="mb-1">
                <label for="Product" class="">
                  Who should approve the Intake Request?
                </label>
              </div>
              <app-mutipleadd #addmultiple (formReady)="formInitialized('approver', $event)" buttonText="Add Approver" [isFdl]="true" [fdlData]="userData2" [filedJson]="filedJson" elementName="approver" [filedDetails]="filedDetails" [formControlData]="createFormGroup"></app-mutipleadd>

              <span *ngIf="(createFormGroup.get('approver').errors?.duplicate)">
                <i class="material-icons">warning</i>The Approver can not be duplicated at the same level.
              </span>
            </div>
            <div class="form-typeahead-wrapper p-0 .select-approver-criteri-wrapper" style="left: 90px;top: 20px;">
              <label class="mt-2">Select Approver Criteria: <span data-toggle="tooltip" title="Select Approval Criteria if Multiple Approvers at same level." class="material-icons btn-secondary f-18"> info</span></label>


              <div>

                <div class="fdl-select">
                  <select class="form-control" formControlName="approverSchema"
                          name="approverSchema">
                    <option value="OR">ANY</option>
                    <option value="AND">ALL</option>

                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />


    <div class="row">
      <div class="intake-request-form  intake-request-form-wrapper   mb-10">
        <div class="box">
          <div class="col-md-12 mb-3 pl-0 typeahead-wrapper">
            <label for="Template">Do you want to assign co-author to this form?</label>
            <div class="radio-group d-flex">
              <div class="radio mr-3">
                <input type="radio" id="enableTemplateLockTwo" name="enableTemplateLockCtrl" value="1" formControlName="enableTemplateLockCtrl">
                <label for="enableTemplateLockTwo"><span>Yes</span></label>
              </div>
              <div class="radio">
                <input type="radio" id="enableTemplateLockOne" name="enableTemplateLockCtrl" value="0" formControlName="enableTemplateLockCtrl">
                <label for="enableTemplateLockOne"><span>No</span></label>
              </div>
            </div>
          </div>

            <div class="col-md-12 mb-3 pl-0 typeahead-wrapper" *ngIf="(createFormGroup.controls['enableTemplateLockCtrl'].value==1)">
              <label aria-describedby="addUsers" class="w-100">Select/De-Select Users :</label>
              <button class="btn fdl-btn btn-secondary-light " id="addUsers" (click)="openUserDrawer()">Select Co-Author User</button>
              <div class="filter-wrapper mt-2">
                <div class="filter-tag" *ngFor="let user of selectedUserForDisplay">
                  <div class="tag" data-toggle="tooltip" data-placement="top" title="{{user.name}}">
                    {{user.name}}
                    <a href="javascript:void(0)" (click)="removeUser(user.id)" aria-label="Close" class="tag-close">
                      <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>


          <div class="row" style="display:none">
            <div class="col-md-6 mb-3">
              <label for="Template">Form Publish:</label>
              <div class="radio-group d-flex">
                <div class="radio mr-3">
                  <input type="radio" formControlName="formPublishCtrl" value="true" />   <label><span>Yes</span></label>
                </div>
                <div class="radio">
                  <input type="radio" formControlName="formPublishCtrl" value="false" />  <label><span>No</span></label>

                </div>
              </div>
            </div>

          </div>
          <!-- Dependent Form Section Start -->
          <div class="row mt-3">
            <div class="intake-request-form intake-request-form-wrapper  mb-10">
              <div class="box">
                <div class="col-md-12  pl-0 typeahead-wrapper"
                     [ngClass]="{'has-error': ( (!createFormGroup.get('hasDependentForms').valid && createFormGroup.get('hasDependentForms').touched)) }">
                  <label for="hasDependentForms" class="asterisk">Do you have Dependent Forms?</label>
                  <fieldset>
                    <div class="radio-group d-flex">
                      <div class="radio mr-3">
                        <input type="radio" id="hasDependentFormsTwo" name="hasDependentForms" value="true" formControlName="hasDependentForms" (change)="resetAllSelection()">
                        <label for="hasDependentFormsTwo"><span>Yes</span></label>
                      </div>
                      <div class="radio">
                        <input type="radio" id="hasDependentFormsOne" name="hasDependentForms" value="false" formControlName="hasDependentForms" (change)="resetAllSelection()">
                        <label for="hasDependentFormsOne"><span>No</span></label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div class="col-md-12   pl-0 mt-3" *ngIf="(createFormGroup.controls['hasDependentForms'].value=='true')">
                  <div class="col-md-12 p-0 fdl-2">
                    <label aria-describedby="addAccount" class="w-100 asterisk">Select/De-Select Dependent Forms:</label>
                    <button class="btn fdl-btn btn-secondary-light " id="addAccount" (click)="openDrawer()">Select Dependent Form</button>
                    <!-- Selected Forms buttons -->
                    <!--<div class="col-md-12 mb-10" *ngIf="selectedDependentForms?.length>0">
                  <app-data-table [rows]="selectedDependentForms"
                                  [keys]="keys" [enableSorting]="false"
                                  [displayColumns]="displayColumns" [formFetchAction]="true" [showCheckbox]="false" [showViewEdit]="false" [showMenu]="false" (removeClicked)="removeForm($event)">



                  </app-data-table>
                </div>-->
                    <div class="filter-wrapper mt-2">
                      <div class="filter-tag" *ngFor="let form of selectedDependentForms">
                        <div class="tag" data-toggle="tooltip" data-placement="top" title="{{form.title}}">
                          {{form.title}} <a href="javascript:void(0)" (click)="removeForm(form)" aria-label="Close" class="tag-close">
                            <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="has-error">
                    <span *ngIf="selectDependentFormError && selectedDependentForms.length==0">
                      <i class="material-icons">warning</i> Please select dependent forms.
                    </span>
                  </div>

                </div>


              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3 mt-3">
              <label for="searchByFormFieldJson">Provide the Form Fields JSON to allow Search By:</label>
              <textarea type="text" id="searchByFormFieldJson" formControlName="searchByFormFieldJson" class="form-control"></textarea>
            </div>
            <span *ngIf="invalidJSON && fieldType=='Search'">
              <i class="material-icons">warning</i>Please provide the form fields in valid JSON format as available in sample Form Fields JSON.
            </span>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3 mt-3">
              <label for="formFieldListJson">Provide the Form Fields JSON you want to list values:</label>
              <textarea type="text" id="formFieldListJson" formControlName="formFieldListJson" class="form-control"></textarea>
            </div>
            <span *ngIf="invalidJSON && fieldType=='List'">
              <i class="material-icons">warning</i>Please provide the form fields in valid JSON format as available in sample Form Fields JSON.
            </span>
          </div>

          <div class="d-inline">
            <p><a href="javascript:void(0);" (click)="formFields()">Click here</a> to download and view the sample Form Fields JSON</p>
          </div>
          <!-- Dependent Form Section End -->
          <!--<div class="row">
          <div class="col-md-10 ml-3 box mb-10">
            <div class="col-md-10 p-0">
              <label aria-describedby="addAccount" class="w-100">Select/De-Select Connected Forms:</label>
              <button class="btn btn-secondary" id="addAccount" (click)="openDrawer(true)">Select Connected Form</button>
              <div class="col-md-12 mb-10" *ngIf="selectedForms?.length>0">
                <app-data-table [rows]="selectedForms"
                                [keys]="keys" [enableSorting]="false"
                                [displayColumns]="displayColumns" [formFetchAction]="true" [showCheckbox]="false" [showViewEdit]="false" [showMenu]="false" (removeClicked)="removeConnectedForm($event)">



                </app-data-table>
              </div>

            </div>
          </div>
        </div>
        <div class="row">


      </div>-->
          <div class="row" style="padding-top: 10px;">
            <div class="col-md-6 mb-3">
              <label for="Template">Template Options:</label>
              <div class="fdl-select">
                <select id="Template" class="form-control" aria-invalid="false" (change)="displayTemplateOptions()" formControlName="templateOptionCtrl">
                  <option value="">None</option>
                  <option *ngFor="let template of templates" [value]="template.template_name">
                    {{template.template_name}}
                  </option>
                </select>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-md-6 mb-3" *ngIf="displayExcel">
              <label for="excelinput" class="asterisk ">Excel Template:</label>
              <div class="custom-file">
                <label for="excelinput" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="filename">{{filename}}</label>
                <input type="file" class="custom-file-input form-control" multiple="false" (change)="onFileChange($event)" id="excelinput" formctrlname="fileCtrl" />

                <div class="has-error">

                  <span *ngIf="(!validFileName) &&  createFormGroup.get('fileCtrl').touched">
                    <i class="material-icons">warning</i> {{regexValidationMessage.FILENAME_VALIDATION_MESSAGE}}
                  </span>
                  <span *ngIf="(!validFileExt) && createFormGroup.get('fileCtrl').touched">
                    <i class="material-icons">warning</i> Please select a valid template file.
                  </span>
                  <span *ngIf="(!validFileType) && createFormGroup.get('fileCtrl').touched">
                    <i class="material-icons">warning</i> Please select a valid file type.
                  </span>
                  <span *ngIf="(!validFileSize) && createFormGroup.get('fileCtrl').touched">
                    <i class="material-icons">warning</i> Maximum file size limit is {{_maxLength.File.Size}}MB, please correct it.
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3" *ngIf="displayJson">
              <label for="excelinput" class="asterisk ">Json File:</label>
              <div class="custom-file">
                <label for="excelinput" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="filename">{{jsonFileName}}</label>
                <input type="file" class="custom-file-input form-control" multiple="false" (change)="onJsonChange($event)" id="excelinput" formctrlname="jsonCtrl" />

                <div class="has-error">

                  <span *ngIf="(!validFileName) &&  createFormGroup.get('fileCtrl').touched">
                    <i class="material-icons">warning</i> {{regexValidationMessage.FILENAME_VALIDATION_MESSAGE}}
                  </span>
                  <span *ngIf="(!validFileExt) && createFormGroup.get('jsonCtrl').touched">
                    <i class="material-icons">warning</i> Please select a valid JSON template file.
                  </span>
                  <span *ngIf="(!validFileType) && createFormGroup.get('fileCtrl').touched">
                    <i class="material-icons">warning</i> Please select a valid file type.
                  </span>
                  <span *ngIf="(!validFileSize) && createFormGroup.get('fileCtrl').touched">
                    <i class="material-icons">warning</i> Maximum file size limit is {{_maxLength.File.Size}}MB, please correct it.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3 pl-0 typeahead-wrapper" *ngIf="displayForm">
            <label for="form" class="asterisk">Form Template:</label>
            <fdl-type-ahead formControlName="templateNameCtrl" [collection]="formSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
            <div class="has-error">
              <span *ngIf="!createFormGroup.get('templateNameCtrl').valid && createFormGroup.get('templateNameCtrl').touched">
                <i class="material-icons">warning</i> Please select a form template.
              </span>
            </div>
          </div>
          <div class="d-inline" *ngIf="displayExcel">
            <p><a href="javascript:void(0);" (click)="downloadTemplate()">Click here</a> to download sample Form template.</p>
          </div>



          <div class="button-container fdl-2">
            <button class="btn fdl-btn btn-secondary-light " (click)="cancel()" type="button">Cancel</button>
            <button class="btn fdl-btn btn-primary-orange float-right" (click)="saveForm()" type="button">Next</button>
          </div>
</form>

</div>

<!-- Drawer Start -->
<div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open " tabindex="-1" id="addDependentForms"
     aria-expanded="true" role="region" *ngIf="showDrawer">
  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12">
    <h3 >Select/De-Select Dependent Forms</h3>
    <p>Below are the available Forms to select. You can select multiple dependent forms for a single form.</p>
    <form [formGroup]="dependentFormGroup">
      <div class="row" *ngIf="formSuggestion.length > 0">
        <div class="input-group search-box mb-3 col-md-11">
          <input type="text" class="form-control" placeholder="Search..." id="searchString" formControlName="searchString">
          <span class="input-group-addon">
            <button type="submit" class="search-close">
              <i class="fa fa-search" aria-hidden="true" *ngIf="!showClearInputBtn()"> </i>
              <i class="fa fa-times" aria-hidden="true" *ngIf="showClearInputBtn()" (click)="clearSearchField()"> </i>
            </button>
          </span>
        </div>
      </div>
      <fieldset *ngIf="formSuggestion.length > 0" [ngClass]="{'edit': isEdit}">
        <div class="checkbox" formArrayName="forms"
             *ngFor="let control of dependentFormGroup.controls.forms['controls'] | formFilter:dependentFormGroup.controls.searchString.value:formSuggestion; let i = index">
          <div class="position-relative" *ngIf="formSuggestion[i].isFiltered && (formSuggestion[i].id != formVal?.id)">
            <input type="checkbox" [formControlName]="i" id="{{'chk'+ i}}">
            <label for="{{'chk'+ i}}"><span>{{formSuggestion[i].title}}</span></label>
          </div>
        </div>
      </fieldset>
      <p *ngIf="formSuggestion.length == 0">No records available.</p>
      <div class=" pt-3">
        <div class="fdl-2 button-container">
          <button class="btn fdl-btn btn-secondary-light " (click)="drawerCancel()">Cancel</button>
         <div>
          <button *ngIf="formSuggestion.length > 0" type="button" (click)="drawerSubmit()" class="btn fdl-btn btn-primary-orange float-right">Save</button>
         </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" tabindex="-1" id="addForms"
     aria-expanded="true" role="region" *ngIf="showDrawer2">
  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12">
    <h3>Select Forms</h3>
    <p>Below are the available Forms to select. You can select multiple  forms for a single form.</p>
    <form [formGroup]="formsGroup">
      <div class="row" *ngIf="formSuggestion.length > 0">
        <div class="input-group search-box mb-3 col-md-11">
          <input type="text" class="form-control" placeholder="Search..." id="searchString" formControlName="searchString">
          <span class="input-group-addon">
            <button type="submit" class="search-close">
              <i class="fa fa-search" aria-hidden="true" *ngIf="!showClearInputBtn()"> </i>
              <i class="fa fa-times" aria-hidden="true" *ngIf="showClearInputBtn()" (click)="clearSearchField()"> </i>
            </button>
          </span>
        </div>
      </div>
      <fieldset *ngIf="formSuggestion.length > 0" [ngClass]="{'edit': isEdit}">
        <div class="checkbox" formArrayName="forms"
             *ngFor="let control of formsGroup.controls.forms['controls'] | formFilter:formsGroup.controls.searchString.value:formSuggestion; let i = index">
          <div class="position-relative" *ngIf="formSuggestion[i].isFiltered && (formSuggestion[i].id != formVal?.id)">
            <input type="checkbox" [formControlName]="i" id="{{'chk'+ i}}">
            <label for="{{'chk'+ i}}"><span>{{formSuggestion[i].title}}</span></label>
          </div>
        </div>
      </fieldset>
      <p *ngIf="formSuggestion.length == 0">No records available.</p>
      <div class=" pt-3">
        <div class="fdl-2 button-container">
          <button class="btn fdl-btn btn-secondary-light " (click)="drawerCancel(true)">Cancel</button>
          <div>
          <button *ngIf="formSuggestion.length > 0" type="button" (click)="drawerSubmit(true)" class="btn fdl-btn btn-primary-orange float-right">Save</button>
        </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="backdrop-space" *ngIf="showDrawer">
  <div class="backdrop"></div>
</div>

<div class="backdrop-space" *ngIf="showUserDrawer">
  <div class="backdrop"></div>
</div>

<div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" *ngIf="showUserDrawer" >
  <div class="drawer-contents col-xs-12 col-sm-12 col-md-12">
    <h3>Select Users to Assign</h3>
    <p>Below are the available users to assign to a group. You can select multiple users for a single group.</p>
    <div class="row">
      <div class="input-group search-box mb-3 col-md-11">
        <input type="text" class="form-control" placeholder="Search..." id="searchUserString" name="searchUserString" [(ngModel)]="searchUserString">
      </div>
    </div>
    <fieldset>
      <legend class="border-0"></legend>
      <div class="checkbox" *ngFor="let user of userSuggestions | filter: 'name': searchUserString ; let i = index" >
        <div class="position-relative">
          <input type="checkbox" [(ngModel)]="user.selected" id="{{'chk'+ i}}" />
          <label for="{{'chk'+ i}}"><span>{{user.name}}</span></label>
        </div>
      </div>
    </fieldset>
    <p *ngIf="userSuggestions.length == 0">No records available.</p>
    <div class=" pt-3">
      <div class="fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerUserCancel()">Cancel</button>
        <div>  <button type="button" (click)="drawerUserSubmit()" class="btn fdl-btn btn-primary-orange float-right">Assign</button></div>
      </div>
    </div>
  </div>
</div>
<!-- Drawer End -->

