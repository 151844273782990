<div class="heading-container fdl-2">
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span> 
        Subfeatures List
      </a>
    </div>
    <h1>{{subFeatureData?.name || 'N/A'}}</h1>
    </div>
</div>

<div class="form-container separate-form-container">
    <form>
        <div class="biller-survey white-box fdl-2">
            <div>
                <ul>
                    <li>
                        Page Template Title:
                        <span class="info">{{subFeatureData?.pageTemplateTitle || 'N/A'}}</span>
                    </li>
                    <li>
                        Subfeature URL:
                        <span class="info">{{subFeatureData?.selfServiceSubFeatureURL || 'N/A'}}</span>
                    </li>
                    <li>
                        Organization ID:
                        <span class="info">{{subFeatureData?.pageTemplateOrganizationId || 'N/A'}}</span>
                    </li>
                    <li>
                        Sequence Number:
                        <span class="info">{{subFeatureData?.sequenceNumber || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        Created On:
                        <span class="info">{{subFeatureData?.createdOn | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                    </li>
                    <li>
                        Modified On:
                        <span class="info">{{subFeatureData?.modifiedOn | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                    </li>
                    <li>
                        Created By:
                        <span class="info">{{subFeatureData?.createdByName || 'N/A'}}</span>
                    </li>
                    <li>
                        Modified By:
                        <span class="info">{{subFeatureData?.modifiedByName || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </form>
    <div class="fdl-2 button-container">
        <div>
            <button type="button" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
        </div>
    </div>
</div>