import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldBase } from './../fieldClass/field-base';
import { FieldControlServiceService } from './../../field-control-service.service';
import { FormsService } from 'src/app/services/forms.service';
import { SurveyService } from 'src/app/services/survey.service';
import { IResponseForSurvey } from "src/app/shared/survey";
import { Title } from '@angular/platform-browser';
declare var $: any;
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { SubHeaderService } from '../../SharedModule/services/subHeader.service';
import { IHistorySummary, IHistorySummarys } from "src/app/shared/history-summary";
import { IQuery } from "src/app/shared/query";
import { IPager } from "src/app/shared/pagination";
import { environment } from "src/environments/environment";
import { MasterService } from "src/app/services/master.service";
import { Sort } from "src/app/shared/sort";
import { DrmsFileService } from '../../services/file.service';
import { ManageFormsService } from '../../admin/manageForms/services/manageForms.service';
import { Formio, Utils, Components, ExtendedComponentSchema } from 'formiojs';


@Component({
  selector: 'app-view',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css'],
})
export class FormsCompareComponent implements OnInit, OnDestroy {
  @ViewChild('myDOMElementPrev', { static: true }) MyDOMElementPrev: ElementRef;
  @ViewChild('myDOMElementCurrent', { static: true }) MyDOMElementCurrent: ElementRef;
  private renewlTokenCounter: number;
  private subscription: Subscription;
  formJsonCurrent: any;
  formJsonPrev: any;
  formHistoryList: IHistorySummary[];
  filteredFormHistoryList: IHistorySummary[];
  sortedFormHistoryList: IHistorySummary[];
  queryParam: IQuery;
  pagerObject: IPager;
  currentPageNumber: number = 1;
  sortingObject;
  title = 'test';
  fields: FieldBase<any>[];
  form: FormGroup;
  payLoad: string;
  error = false;
  readonly = false;
  formData: any;
  formDataPrev: any;
  previousPageExits: boolean = true;
  currentPageExits: boolean = true;
  conntectedResponses;
  formbuilder = {
    fileService: new DrmsFileService()
  };
  currentFieldToCompare: any = {};
  previousFieldToCompare: any = {};
  formId: number;
  // fieldData: any = {data: {}};
  filterFormsVersions: FormGroup;
  formTemplateName: string = "";
  oldHtml: any;
  newHtml: any;
  responseForSurvey: IResponseForSurvey;
  fieldSetsCurrent: any;
  currentFieldSet: any;
  fieldSetsPrevious: any;
  previousFieldSet: any;
  previousformElements: any[];
  currentformElements: any[];
  allComponents: any[];
  currentFormReady: boolean = false;
  currentPageToCompare: number = 0;
  prevFormReady: boolean = false;
  allpages: any;
  hideLeftNav: boolean = false;
  version: string = "";
  isError: boolean;
  errorMsg: string = "";
  currentVersion: string = "";
  constructor(
    private route: ActivatedRoute,
    private masterService: MasterService,
    private formService: FormsService,
    private formField: FieldControlServiceService,
    private surveyService: SurveyService,
    private titleService: Title,
    private fb: FormBuilder,
    private formsService: FormsService,
    private _subHeaderService: SubHeaderService,
    private _manageForm: ManageFormsService,
    private router: Router) { }
  ngOnDestroy(): void {

    sessionStorage.removeItem("surveyToken");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this._manageForm.setDelegateSuccessMsg("");
    this._manageForm.approvalMsg = "";
  }

  cssClassName(obj: string, type: string, key: string) {
    if (obj != 'undefined' && obj != null && obj != '') {
      obj = obj + ' ' + 'custom-' + type + '-inline ' + 'c' + key;
    } else {
      obj = 'custom-' + type + '-inline ' + 'c' + key;
    }
    return obj;
  }
  renewToken() {
    this.surveyService.renewToken().subscribe(data => {
      sessionStorage.setItem("surveyToken", data.token);
      localStorage.setItem("drmsToken", 'Bearer ' + data.token);
      this.startTokenRenewCountdown();
    });
  }
  startTokenRenewCountdown() {
    const minutes = interval(60000);
    const fiftyFiveMinutes = minutes.pipe(take(54));
    this.subscription = fiftyFiveMinutes
      .subscribe(null, null, () => {
        this.renewToken();
      });
  }
  ngOnInit() {
    this.allComponents = []
    if (this.route.snapshot.data.isUnauthenticated && !this.surveyService.isValidatedOtp()) {
      let navigationToUrl = this.router.url.substring(1, this.router.url.lastIndexOf("/"));
      this.router.navigate([navigationToUrl]);
      return;
    }
    this.fields = [];

    this.route.params.subscribe((params) => {

      if (this.formService.versionModifiedOn != "") {
        localStorage.setItem('modifiedOn', this.formService.versionModifiedOn);
      }
      let modifiedOn = localStorage.getItem('modifiedOn');

      if (this.formService.version != "") {
        localStorage.setItem('version', this.formService.version);
      }
      this.version = localStorage.getItem('version');
      this.formId = params['id'];
      if (params['edit'] == 'preview') {
        $('.navbar-nav').css('display', 'none');
      }
      if (this.router.url.indexOf('intake') !== -1) {
        this._subHeaderService.updateSideBarNavigation(false);
      }
      this.readonly = true;
      this.formJsonCurrent = "";

      this.formService.getFormJson(this.formId).subscribe(
        data => {
          this.formTemplateName = data.title;
          this.titleService.setTitle(this.formTemplateName);
          this.formJsonCurrent = JSON.parse(data.formJSON);
          this.fieldSetsCurrent = this.formJsonCurrent.components.filter((data) => {
            if (data.type == 'panel' || data.type == 'fieldset') {
              data.type = "fieldset"
              data.legend = data.title
              let flowName = 'response';

            }


            return true;
          })
          this.currentFormReady = true;

          this.doFormComapre();
        });

      this.formJsonPrev = "";
      this.formService.getFormsVersionHistory(this.formId, this.version, modifiedOn).subscribe(
        data => {
          this.formJsonPrev = JSON.parse(data.formJSON);
          this.fieldSetsPrevious = this.formJsonPrev.components.filter((data) => {
            if (data.type == 'panel' || data.type == 'fieldset') {
              data.type = "fieldset"
              data.legend = data.title
              let flowName = 'response';

            }


            return true;
          })
          this.prevFormReady = true;
          this.doFormComapre();
        });

        this.formsService
        .getFormsHistory(this.formId)
        .pipe(take(1))
        .subscribe(
          responses => {
            this.isError = false;
            this.formHistoryList = responses;
            this.filteredFormHistoryList = JSON.parse(
              JSON.stringify(this.formHistoryList)
            );
            if(this.filteredFormHistoryList.length > 0) {
              this.currentVersion = this.filteredFormHistoryList[0].version;
            }
          },
          error => {
            this.isError = true;
            this.errorMsg = error;
          }
        );

    });

  }
  getPreviousFormPageSet(index: number) {
    this.previousFieldSet = { components: [] };
    let pagesString = JSON.stringify(this.fieldSetsPrevious[index]);
    pagesString = pagesString.split('condition').join('condition2')
    pagesString = pagesString.split('customConditional').join('customConditional2')
    this.previousFieldSet.components.push(JSON.parse(pagesString));

    this.previousformElements = []
    for (let k in this.previousFieldSet.components) {
      Utils.eachComponent(this.previousFieldSet.components[k].components, (component) => {
        if (!this.allComponents.includes(component.key)) {

          this.allComponents.push(component.key)
        }
        if (!this.previousformElements[component.key]) {
          this.previousformElements[component.key] = [];
        }
        this.previousformElements[component.key].push(component)
        //   formFields.push({ "fieldName": component.label, "fieldType": component.type, "fieldKey": component.key, "fieldDefaultValue": JSON.stringify(component.defaultValue), "fieldValueOptions": JSON.stringify(component.values), "isEncrypted": true })



      }, true)
    }
  }
  getCurrentFormPageSet(index: number) {
    this.currentFieldSet = { components: [] };
    let pagesString = JSON.stringify(this.fieldSetsCurrent[index]);
    
    pagesString = pagesString.split('condition').join('condition2')
    pagesString = pagesString.split('customConditional').join('customConditional2')
    this.currentFieldSet.components.push(JSON.parse(pagesString));
    
    this.currentformElements = []
    for (let k in this.currentFieldSet.components) {
      Utils.eachComponent(this.currentFieldSet.components[k].components, (component) => {

        if (!this.allComponents.includes(component.key)) {
          this.allComponents.push(component.key)
        }
        if (!this.currentformElements[component.key]) {
          this.currentformElements[component.key] = [];
        }
        this.currentformElements[component.key].push(component)
        //   formFields.push({ "fieldName": component.label, "fieldType": component.type, "fieldKey": component.key, "fieldDefaultValue": JSON.stringify(component.defaultValue), "fieldValueOptions": JSON.stringify(component.values), "isEncrypted": true })



      }, true)
    };
  }
  getCompletePages() {
    let currentFormPageLength: number = this.fieldSetsCurrent.length;
    let previousFormPageLength: number = this.fieldSetsPrevious.length;
    let currentFormPages: any = []
    let previousFormPages: any = []
    let completePagesLength: number = currentFormPageLength;
    let allPages: any = []; let continueLoop: boolean = true;
    let totalNumberOfPages = 0;
    //while (continueLoop) {
    //  if (this.fieldSetsCurrent[totalNumberOfPages].key == this.previousFieldSet[totalNumberOfPages].key) {
    //    allPages[totalNumberOfPages] = { 'currentPageIndex': totalNumberOfPages, 'previousPage': totalNumberOfPages }


    //  }
    //  totalNumberOfPages++;
    //  if (!this.fieldSetsCurrent[totalNumberOfPages] && !this.previousFieldSet[totalNumberOfPages]) {
    //    continueLoop = false;

    //  }

    //}
    for (let i = 0; i < currentFormPageLength; i++) {
      if (!allPages[this.fieldSetsCurrent[i].key]) {
        allPages[this.fieldSetsCurrent[i].key] = { 'currentPageIndex': i, 'previousPage': 'doesNotExits', 'pageName': this.fieldSetsCurrent[i].legend }
        totalNumberOfPages = totalNumberOfPages + 1
      }



    }
    for (let i = 0; i < previousFormPageLength; i++) {
      if (!allPages[this.fieldSetsPrevious[i].key]) {
        allPages[this.fieldSetsPrevious[i].key] = { 'previousPage': i, 'currentPageIndex': 'doesNotExits', 'pageName': this.fieldSetsPrevious[i].legend }
        totalNumberOfPages = totalNumberOfPages + 1;
      }
      if (allPages[this.fieldSetsPrevious[i].key])
        allPages[this.fieldSetsPrevious[i].key].previousPage = i;



    }
    this.allpages = [];
    for (let k in allPages) {
      this.allpages.push({ 'keyName': k, 'previousPage': allPages[k].previousPage, 'currentPageIndex': allPages[k].currentPageIndex, 'pageName': allPages[k].pageName })

    }


  }
  getName(k) { }
  getClassName(k) {
    if (k == this.currentPageToCompare)
      return 'active';
    //if (fieldSet.status === 'Completed')
    //  return 'completed';
    //if (fieldSet.status === 'Skipped')
    //  return 'error';
  }
  getClassNameLink(k) {
    let previousPageIndex = this.allpages[k].previousPage;
    let currentPageIndex = this.allpages[k].currentPageIndex;
    if (currentPageIndex == 'doesNotExits') {
      return "removed"
    }
    else if (previousPageIndex == 'doesNotExits') {
      return "added"
    } else if (JSON.stringify(this.fieldSetsPrevious[previousPageIndex]) != JSON.stringify(this.fieldSetsCurrent[currentPageIndex])) {
      return "changed";
    }

  }
  changePage(k) {
    this.currentPageExits = true;
    this.previousPageExits = true;
    let previousPageIndex = this.allpages[k].previousPage;
    let currentPageIndex = this.allpages[k].currentPageIndex;
    if (currentPageIndex == 'doesNotExits') {
      this.currentPageExits = false;
    } else {
      this.getCurrentFormPageSet(currentPageIndex)

    }
    if (previousPageIndex == 'doesNotExits') {
      this.previousPageExits = false;
    } else {
      this.getPreviousFormPageSet(previousPageIndex)

    }
    this.currentPageToCompare = k
  }
  toggleSideBar() {
    this.hideLeftNav = !this.hideLeftNav;
  }
  doFormComapre() {
    if (!(this.prevFormReady && this.currentFormReady))
      return;
    this.getCompletePages();
    this.changePage(this.currentPageToCompare)



  }

  render1(evt: any) {
    //alert(this.MyDOMElementPrev.nativeElement.innerHTML);
  }
  closePopUp() {
    $('#popup').modal('hide');
  }
  comparePopup() {
   // alert($('#input').val())
    let previousPageIndex = this.allpages[this.currentPageToCompare].previousPage;
    let currentPageIndex = this.allpages[this.currentPageToCompare].currentPageIndex;
    this.currentFieldToCompare = this.currentformElements[$('#input').val()]
    this.previousFieldToCompare = this.previousformElements[$('#input').val()]
    $('#popup').modal('show')
  }
  render2(evt: any) {
    const appPrev = document.getElementsByTagName("formio")[0];
    const appCurr = document.getElementsByTagName("formio")[1];
    for (let k in this.allComponents) {
      let kk = this.allComponents[k]
      if (this.previousformElements[kk] && this.currentformElements[kk]) {
        if (JSON.stringify(this.previousformElements[kk]) != JSON.stringify(this.currentformElements[kk])) {

          $('#dvCurrent').find("div.formio-component-" + kk).each(function (index) {

            $(this).addClass('hightlight-element-yellow');
            $(this).bind('click', function (e) {
              e.stopPropagation()
              e.preventDefault();

              $('#input').val(kk);
              $('#mockClick').trigger('click');


            })
          })
          $('#dvPrev').find("div.formio-component-" + kk).each(function (index) {
            $(this).bind('click', function (e) {
              e.stopPropagation()
              e.preventDefault();

              $('#input').val(kk);
              $('#mockClick').trigger('click');


            })
            $(this).addClass('hightlight-element-yellow')
          })
        }
      } else if (!this.previousformElements[kk] && this.currentformElements[kk]) {
        $('#dvCurrent').find("div.formio-component-" + kk).each(function (index) {
          $(this).addClass('hightlight-element-green')
        })

      } else if (this.previousformElements[kk] && !this.currentformElements[kk]) {
        $('#dvPrev').find("div.formio-component-" + kk).each(function (index) {
          $(this).addClass('hightlight-element-red').append('Either Api key is changed or its removed from current version')
        })

      }


    }
  }

  goBack() {
    this.masterService.isCancelledClicked(true);
    this.router.navigate(['/admin/form/history/list', this.formId]);
  }
  onSubmit() {
    if (this.form.valid)
      this.payLoad = JSON.stringify(this.form.getRawValue());
    else {
      this.error = true;

    }
  }
}
