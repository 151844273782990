import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.css']
})
export class ReloadComponent implements OnInit {

  constructor(private route: ActivatedRoute,

    private router: Router) {
    
  }

  ngOnInit(): void {
  
    this.route.params.subscribe((params) => {
      this.router.navigate(['/response/view/responses/', params['id']]);
    });
  }

}
