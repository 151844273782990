<div class="heading-container fdl-2">
    <h1>Clients</h1>
    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="createClient();" *ngIf="isAdmin">
        <span class="material-icons">add</span>
        Create New Client</button>
</div>
<div class="form-container">
        <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>{{successMsg}}
           </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="deletedFormMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>{{deletedFormMsg}}

            </p>
        </div>
<div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
</div>
<form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
    <h2 class="h4 collapse-arrow" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse" href="#collapseFilter">Search</h2>
    <div class="filter-form-container-column2 search-filter collapse" [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">
        <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('ClientTitleWithDuns').valid && (filterForm.get('ClientTitleWithDuns').touched || filterForm.get('ClientTitleWithDuns').dirty || isSubmitted)}">
          <div class="has-error">
            <span *ngIf="!filterForm.get('ClientTitleWithDuns').valid && filterForm.get('ClientTitleWithDuns').errors?.pattern && (filterForm.get('ClientTitleWithDuns').touched || filterForm.get('ClientTitleWithDuns').dirty || isSubmitted)">
              <i class="material-icons">warning</i> Please enter a valid Client Name 
            </span>
          </div>
          <fdl-type-ahead class="filter-typeahead" [(ngModel)]="clientText" label="Client Name/DUNS:" formControlName="ClientTitleWithDuns" [collection]="clientSuggestion" optionField="titleWithDuns" placeholder="Type to search" 
             [scrollable]="true" [optionsLimit]="100" [minLength]="0" (input)="typeaheadLoadingClients()">
          </fdl-type-ahead>
        </div>
        <div class="filter-form-control typeahead-wrapper pr-4">
          <div class="has-error">
            <span *ngIf="!filterForm.get('Gud').valid && filterForm.get('Gud').errors.invalidCharacters && (filterForm.get('Gud').touched || isSubmitted)">
              <i class="material-icons">warning</i> {{regexValidationMessage.NUMERIC_VALIDATION_MESSAGE}}
            </span>
            <span *ngIf="!filterForm.get('Gud').valid && filterForm.get('Gud').errors.invalidMaxium9Digits && (filterForm.get('Gud').touched || isSubmitted)">
              <i class="material-icons">warning</i> GUD ID must be {{_maxLength.Client.Gud}} digits.
            </span>
          </div>
          <label for="Gud">GUD ID:</label>
          <div class="">
            <input type="text" min="0" pattern="[0-9]*" name="Gud" placeholder="Type to search" class="form-control" aria-invalid="false" formControlName="Gud" />
          </div>
        </div>
        <!-- <div class="filter-form-control typeahead-wrapper pr-4">
          <div class="has-error">
            <span *ngIf="!filterForm.get('DunsId').valid && filterForm.get('DunsId').errors.invalidCharacters && (filterForm.get('DunsId').touched || isSubmitted)">
              <i class="material-icons">warning</i> {{regexValidationMessage.NUMERIC_VALIDATION_MESSAGE}}
            </span>
            <span *ngIf="!filterForm.get('DunsId').valid && filterForm.get('DunsId').errors.invalidMaxium9Digits && (filterForm.get('DunsId').touched || isSubmitted)">
              <i class="material-icons">warning</i> DUNS ID must be {{_maxLength.Client.DunsId}} digits.
            </span>
          </div>
          <label for="DunsId">DUNS ID:</label>
          <div class="">
            <input type="text" min="0" pattern="[0-9]*" name="DunsId" placeholder="Type to search" class="form-control" aria-invalid="false" formControlName="DunsId" />
          </div>
        </div> -->
        <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('RelationshipManagerName').valid && (filterForm.get('RelationshipManagerName').touched || filterForm.get('RelationshipManagerName').dirty || isSubmitted)}">
          <div class="has-error">
            <span *ngIf="!filterForm.get('RelationshipManagerName').valid && filterForm.get('RelationshipManagerName').errors?.pattern && (filterForm.get('RelationshipManagerName').touched || filterForm.get('RelationshipManagerName').dirty || isSubmitted)">
            <i class="material-icons">warning</i> {{regexValidationMessage.RELATION_MANAGER_NAME_VALIDATION_MESSAGE}}</span>
          </div>
          <fdl-type-ahead class="filter-typeahead" label="Relationship Manager Name" formControlName="RelationshipManagerName" [collection]="relationshipManagerSuggestion" optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>  
        </div>
        <div class="filter-form-control pr-4 typeahead-wrapper"*ngIf="isAdmin" [ngClass]="{'has-error': !filterForm.get('OrganizationName').valid && (filterForm.get('OrganizationName').touched || filterForm.get('OrganizationName').dirty || isSubmitted)}">
          <div class="has-error">
            <span *ngIf="!filterForm.get('OrganizationName').valid && filterForm.get('OrganizationName').errors?.pattern && (filterForm.get('OrganizationName').touched || filterForm.get('OrganizationName').dirty || isSubmitted)">
            <i class="material-icons">warning</i> Please enter a valid Organization Name </span>
          </div>
          <fdl-type-ahead class="filter-typeahead" label="Organization Name" formControlName="OrganizationName" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>  
        </div>
        <div class="buttom-wrapper">
            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
                (click)="filterList()">Search</button>
        </div>
        <div class="ml-2 buttom-wrapper" *ngIf="filtered">
          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
              (click)="clearFilterList()">Reset</button>
      </div>
    </div>
</form>
<div class="data-table-container">
  <div class="justify-content-between d-flex">
    <div class="headline-container">
      <h2 class="h4">Client List</h2>
      <p>Total {{pagerObject.totalCount}} Clients</p>
    </div>
    <div class="d-flex">
      <div class="refresh-icon-link mt-1">
        <!-- add class of 'fa-rotation' on click of the refresh icon -->
        <a title="Refresh Client List" id="refreshTask" (click)="refresh()">
          <i aria-hidden="true" class="fa fa-refresh"></i>
        </a>
      </div>
      <div class="dropdown downloadDropdownMenuButton" *ngIf="showComment && isAdmin">
        <button *ngIf="showComment"
                type="button" title="Download Extract" class="btn btn fdl-btn btn-primary-dark btn-addcomment"
                id="downloadDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          <span class="material-icons material-icons-wrapper">
            get_app
          </span>
        </button>

        <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
          <a class="dropdown-item" (click)="downLoadType({'type':'xlsx'})">Export List</a>
        </div>
      </div>
      <app-prefernce-icon></app-prefernce-icon>
    </div>
  </div>
  <div class="table-scroll-x">
    <app-data-table *ngIf="clientList"
                    [rows]="clientList.clients"
                    [keys]="keys"
                    [displayColumns]="displayColumns"
                    (sortingClicked)="applySorting($event)"
                    [showCheckbox]="false"
                    [showViewEdit]="isAdmin"
                    (buttonClicked)="navigate($event)"
                    [showDelete]="isAdmin"
                    (deleteBtnClicked)="navigate($event)"
                    type="Client"
                    [listname]="listname">
    </app-data-table>
  </div>
  <div>
    <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 &&clientList && clientList.clients.length > 0"
                    [pagerObject]="pagerObject"
                    (pagingChanged)="changePage($event)"></app-pagination>
  </div>
</div>
</div>