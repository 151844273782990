import { Component, OnInit, OnDestroy } from '@angular/core';
import { ManageHelpService } from '../services/manageHelp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from 'src/app/SharedModule/services/subHeader.service';
import { manageFormsConst } from 'src/app/core/constants';
import { environment } from '../../../../environments/environment';

import { DrmsFileService } from '../../../services/file.service';
import { Formio, Utils, Components, ExtendedComponentSchema } from 'formiojs';
import { ISuggestion } from '../../../shared/suggestion';
import { ProjectsService } from '../../../services/projects.service';
import { HelpService } from '../../../services/help.service';
import { ICreateTopic, ICreateTopicForm } from '../../manageForms/models/createTopic';
import { UserService } from '../../../services/user.service';
declare var $: any;

@Component({
  selector: 'app-create-topic-template',
  templateUrl: './create-topic-template.component.html',
  styleUrls: ['./create-topic-template.component.css']
})
export class CreateTopicTemplateComponent implements OnInit {

  form = {};

  isError: boolean = false;
  successMsg: any = '';
  errorMsg: string = '';
  nextState: string = '';
  isResponse: boolean = false;
  selectedFormsId: any = [];
  initiateJson: any;
  formbuilder = {
    builder: {
      basic: {
        components: {
          htmlelement: true,
          content: true,
          textfield: false,
          number: false,
          textarea: false,
          password: false,
          checkbox: false,
          selectbox: false,
          select: false,
          day: false,
          radio: false,
          button: false,
          selectboxes:false

        }
      },

      advanced: false,
      layout: {
        components: {
          panel: true,
          tabs: false,
          well: false,
          table: false,
          fieldset: true,
          columns: true,

          hidden: false,
        }
      },
      data: false,




      premium: false,



    },
    fileService: new DrmsFileService()
  };
  formJson = {};
  formName: string = '';
  selectedFormsData: any = [];
  orgName: string = '';
  productName: string = '';
  templateOpton: string = '';
  templateName: string = '';
  formDescription: string = '';
  searchString: string = '';
  formDetails: ICreateTopicForm;
  showSuccessMsg: boolean = false;
    copyFormJson: any;
    topicName: string;
    category: any;
  isEdit: boolean = false;
  topics: ICreateTopic;
  json: any;
  organizationDetails: any;
  constructor(private _manageHelp: ManageHelpService,
    private _router: Router,
    private _subHeaderService: SubHeaderService,
    private route: ActivatedRoute,
    private projectService: ProjectsService,
    private helpService: HelpService,
    private _userService: UserService    ) {
     }
  topicDetails: ICreateTopicForm;


  initiateForm() {
    if (this._manageHelp.formVal) {
      this.formDetails = JSON.parse(JSON.stringify(this._manageHelp.formVal));
      this.productName = this._manageHelp.formVal.productName;
      this.category = this._manageHelp.formVal.topicCatName;
      this.formDescription = this._manageHelp.formVal.topicDes;
      this.topicName = this._manageHelp.formVal.topicName;

      this.formJson = this.form = this.copyFormJson = this._manageHelp.formVal.json ?
      JSON.parse(this._manageHelp.formVal.json) :
      {
        title: this.productName,
        type: 'form',
        display: 'form',
        components: [{
          "label": "HTML",
          "attrs": [
            {
              "attr": "",
              "value": ""
            }
          ],
          "content":"Dummy content",
          "refreshOnChange": false,
          "key": "html",
          "type": "htmlelement",
          "input": false,
          "tableView": false
        },  {
            title: 'HyperLink',
            key: 'link',
            icon: 'link',

            schema: {
              label: 'HyperLink',
              tableView: false,
              key: 'link',
              type: 'htmlelement',
              input: false,
              "tag": "a",
              "attrs": [
                {
                  "attr": "href",
                  "value": ""
                },
                {
                  "attr": "target",
                  "value": "_blank"
                }
              ],
              "content": "click here",
              "refreshOnChange": false,

            }
          }]
      };





    }


  }
  ngOnInit(): void {
    this.organizationDetails = Object.assign({}, this._userService.getUserListData());
    if (this._router.url.indexOf('edit') !== -1) {
      this.isEdit = true
      //this.route.params.subscribe((params) => {
      //const category = this.helpService.getTopicById(params.id).subscribe(suggestions => {

      //  this.topics = suggestions;
      //  this.json = JSON.parse(this.topics.formJSON);
      //  let topicDetails: ICreateTopicForm = {
      //    id: params.id,
      //    topicDes: this.topics.description,
      //    orgId: this.organizationDetails.organizationId,
      //    orgName: this.organizationDetails.organizationTitle,
      //    productName: this.topics.entityName,
      //    productId: this.topics.entityId,
      //    topicName: this.topics.title,
      //    json: this.topics.formJSON,
      //    topicCate: this.topics.helpCategoryName,
      //    topicCatName: this.topics.helpCategoryName,
      //    organization: this.organizationDetails.organizationTitle
      //  }
      //  this._manageHelp.formVal = topicDetails;
      //  this.initiateForm();
      //});
      //})
    }
    this.initiateForm();


    //this.saveInterval = setInterval(() => {
    //  this.submitForm(false, true);
    //}, this.intervalTime);
  }
  onChange(data: { form: {} }) {
    this.formJson =  data.form;
  }

  editForm() {
    this._manageHelp.formVal.json = JSON.stringify(this.form);
    if (!this.isEdit)
      this._router.navigate(['admin/help']);
    else this._router.navigate(['admin/help/edit/' + this._manageHelp.formVal.id]);
  }

  cancel() {
    let a: ICreateTopicForm;
    this._manageHelp.formVal = a;
    this._router.navigate(['help/']);
  }

  validateFormJson(formJson: any) {


    let json: any;
    this.isError = false;
    this.errorMsg = '';
    if (formJson === undefined) {
      this.isError = true;
      this.errorMsg = manageFormsConst.errorMessages.undefinedFormJsonMessage;
      return;
    }
    else {
      if (formJson.components !== undefined) {
        json = formJson['components'][0];
        if (json.components.length === 0) {
          this.isError = true;
          this.errorMsg = this._manageHelp.formVal.topicName + manageFormsConst.errorMessages.blankFormMessage;
          return;
        }
      }
      else {
        this.isError = true;
        this.errorMsg = this._manageHelp.formVal.topicName + manageFormsConst.errorMessages.blankFormMessage;
        return;
      }
    }
    this.isError = false;
    this.errorMsg = '';
  }

  ngOnDestroy() {
   // clearInterval(this.saveInterval);
  }

  /*********  Preview & Manage Asset Changes  ***********/
  previewForm() {
    if (this.showDataLossModal) {
      this.nextState = "preview";
      $('#dataLoss').modal('show');
    }
    else {
      this.proceedToPreview();
    }
  }

  get showDataLossModal() {
    return !(JSON.stringify(this.copyFormJson) === JSON.stringify(this.formJson));
  }

  proceedToPreview() {

  }



  proceed() {

  }
  submitForm(val: boolean = true) {

    let body = {
      "title": this._manageHelp.formVal.topicName,
      "entityLevel": "Product",
      "description": this._manageHelp.formVal.topicDes,
      "formJSON": JSON.stringify(this.form),
      "helpCategoryId": this._manageHelp.formVal.topicCate,
      "productId": this._manageHelp.formVal.productId,
      "isPublishable": this._manageHelp.formVal.isPublishable

    }
    if (this.isEdit) {
      body['id'] = this._manageHelp.formVal.id;

      body["helpCategoryId"] = this._manageHelp.formVal.topicCate,
        this.helpService.updateTopic(body).subscribe(data => {
          let a: ICreateTopicForm;
          this._manageHelp.successMessage = `Article '${this._manageHelp.formVal.topicName} ' has been updated.`;
          this._manageHelp.formVal = a;


          this._router.navigate(['/help/topic/' + body['id']]);

        });

    } else {
      this.helpService.createTopic(body).subscribe(data => {
        let a: ICreateTopicForm;
        this._manageHelp.formVal = a;
        this._router.navigate(['help/category']);

      });
    }
    }
}
