import { CommonModule } from '@angular/common';
import { NgModule, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { registerSensitiveComponent } from './sensitive-information/sensitive-information.formio'
import { registerButtonComponent } from './button-click/button-click.formio'


import { SensitiveInformationComponent } from './sensitive-information/sensitive-information.component';
import { ButtonClickComponent } from './button-click/button-click.component';
import { TypeaheadSelectComponent } from './typeahead-select/typeahead-select.component';
import { registerTypeaheadSelectComponent } from './typeahead-select/typeahead-select.formio';
import { FdlTypeaheadModule } from 'fdl-ngx11-bootstrap4';

@NgModule({
  declarations: [
    ButtonClickComponent,
    SensitiveInformationComponent,
    TypeaheadSelectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FdlTypeaheadModule
  ],
  entryComponents: [
    ButtonClickComponent,
    SensitiveInformationComponent,
  ]
})
export class CustomComponentsModule {
  constructor(injector: Injector) {
    registerSensitiveComponent(injector);
    registerButtonComponent(injector);
    registerTypeaheadSelectComponent(injector);
  }
}
