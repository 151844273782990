<div>
  <div class=row>
    <div class="col-md-2"></div>
    <div class="col-md-2">Form Name</div>
    
    <div class="col-md-2"><button type="button" class="btn btn-primary" (click)="saveForm()">Save Form</button></div>
    <div class="col-md-2"><button type="button" class="btn btn-primary">Cancel</button></div>
  </div>
  <br>
  <form-builder [form]="form" (change)="onChange($event)"></form-builder>


</div>
