import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IWorkflowExecution, IWorkflowIntegrations, IWorkflow, IWorkflowContext } from '../shared/workflow';
import { Observable } from 'rxjs';
import { IQuery } from "../shared/query";

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {
    private workflowAPIURL = 'workflow';
    private workflowExecutionAPIURL = 'workflowExecution';
    private workflowContextAPIURL = 'workflowContext';
    private workflowIntegrationsAPIURL = 'workflowIntegrations';
  private workflowIntegrationsResponsefromRPAURL = 'workflowIntegrations/ResponsefromRPA';
    private workflowExecutionList: IWorkflowExecution;
    private _isWorkflowCreated: string;
    private userAllowedPermissions: any[]

    constructor(private http: HttpClient) { }

    set isWorkflowCreated(msg) {
        this._isWorkflowCreated = msg;
    }

    get isWorkflowCreated() {
        return this._isWorkflowCreated;
    }

    setPermissions(permission) {
        this.userAllowedPermissions = permission;
    }

    getPermissions() {
        return this.userAllowedPermissions;
    }


    // Workflow Service Code
    callWorkflowList(): Observable<IWorkflow> {
        return this.http.get<IWorkflow>(this.workflowAPIURL);
    }

    getWorkflowList(queryParams: IQuery) {
        let params = new HttpParams();

        for (const key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }
        return this.http.get(this.workflowAPIURL, { params: params, observe: 'response' });
    }

    deleteWorkflow(Id: number) {
        return this.http.delete(`${this.workflowAPIURL}/${Id}`);
    }  

    createWorkflow(data) {
        return this.http.post<IWorkflow>(this.workflowAPIURL, data);
    }

    getWorkflowById(id: number) {
        return this.http.get<IWorkflow>(`${this.workflowAPIURL}/${id}`);
    }

  getStatusofRPAId(id: any) {
    let params = new HttpParams().set("responseId", id);
    return this.http.get(this.workflowIntegrationsResponsefromRPAURL, { params: params,  observe: 'response' });
    }

    // Workflow Service Code


    // Workflow Context Service Code
    getWorkflowContextList(workflowId: string,queryParams: IQuery) {
        let params = new HttpParams();

        for (const key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }
        params = params.append("workflowId", workflowId);

        let options = { 'refresh': 'true' }

        return this.http.get(this.workflowContextAPIURL + "/workflowId/" + workflowId , { headers: options, params: params, observe: 'response' });
    }
       
    deleteWorkflowContext(Id: number) {
        return this.http.delete(`${this.workflowContextAPIURL}/${Id}`);
    }  

    createWorkflowContext(data) {
        return this.http.post<IWorkflowContext>(this.workflowContextAPIURL, data);
    }

    getWorkflowContextById(id: number) {
        return this.http.get<IWorkflowContext>(`${this.workflowContextAPIURL}/${id}`);
    }
    // Workflow Context Service Code


    
    // Workflow Execution Service Code
    
    /*
    callWorkflowExecutionListData(): Observable<IWorkflowExecution> {
        return this.http.get<IWorkflowExecution>(this.workflowExecutionAPIURL);
    }

    getWorkflowExecutionListData() {
        return this.workflowExecutionList;
    }
    */

    getWorkflowExecutionList(queryParams: IQuery) {
        let params = new HttpParams();

        for (const key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }
        return this.http.get(this.workflowExecutionAPIURL, { params: params, observe: 'response' });
    }

    getWorkflowExecutionById(id: number) {
        return this.http.get<IWorkflowExecution>(`${this.workflowExecutionAPIURL}/${id}`);
    }
    // Workflow Execution Service Code

      

    // Workflow Integrations Service Code
    getWorkflowIntegrationsById(id: number) {
        return this.http.get<IWorkflowIntegrations>(`${this.workflowIntegrationsAPIURL}/${id}`);
    }
    // Workflow Integrations Service Code
}

