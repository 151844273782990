import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { HelpCategoryService } from 'src/app/self-service/components/manageHelpCategory/helpCategory.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import { ProjectsService } from 'src/app/services/projects.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { IFeature } from 'src/app/shared/selfservice-feature';
import { FeaturesService } from 'src/app/services/feature.service';
@Component({
  selector: 'app-feature-form',
  templateUrl: './feature-form.component.html'
})
export class FeatureFormComponent implements OnInit, OnDestroy {

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private helpCategoryService: HelpCategoryService,
    private featureService: FeaturesService,
    private router: Router,
    private userService: UserService, private projectService: ProjectsService) { }

    @Input() helpCategoryId: number;
    @Input() featureId: number;
    @Input() isEditMode: boolean;
    invalidOrganizationName: boolean = false;
    featureForm: FormGroup;
    Form: IFeature;
    isSubmitted = false;
    isError: boolean = false;
    errorMsg: string;
    organizationId: number;
    _maxLength: any;
    isAdmin: boolean;
    returnPage: string;
    featureSuggestion: ISuggestions;
    sequenceNumber: number;
    regexValidationMessage: any;
    
  ngOnInit(): void {
      this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
      this._maxLength = MAX_LENGTHS;
      this.isAdmin = this.userService.getUserListData().isAdmin;
      this.initForm();

      if (this.featureId > 0) {
        this.featureService.getFeaturesDetailsById(this.featureId).subscribe(
          result => {
            this.isError = false;
            this.Form = result;
            this.patchForm();
          },
          (error) => { this.isError = true; this.errorMsg = error; });
      }

      this.getSuggestions();

      if (this.featureService.tempData)
        this.returnPage = 'Feature List';
      else
        this.returnPage = 'Self Service';
    }

    ngOnDestroy(): void {
      this.featureService.tempData = "";
  }

  patchForm() {
    this.featureForm = this.fb.group({
      name: [this.Form.name, [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator),
        Validators.maxLength(this._maxLength.Form.Name), NoWhitespaceValidator.validateWhiteSpaces]],
      description: [this.Form.description, [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator),
        Validators.maxLength(this._maxLength.Form.Description), NoWhitespaceValidator.validateWhiteSpaces]],
        sequenceNumber: [this.Form.sequenceNumber, [Validators.required, Validators.pattern(REGEX_PATTERNS.Numeric)]],
    })
  }

    initForm() {
      this.featureForm = this.fb.group({
        name: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator),
          Validators.maxLength(this._maxLength.Form.Name), NoWhitespaceValidator.validateWhiteSpaces]],
        description: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator),
          Validators.maxLength(this._maxLength.Form.Description), NoWhitespaceValidator.validateWhiteSpaces]],
          sequenceNumber: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.Numeric)]],
      });
    }

  onSubmit(): void {

    this.isSubmitted = true;

    this.featureForm.addControl('sequenceNumber', new FormControl('', Validators.required));
    this.featureForm.patchValue({
      sequenceNumber: this.sequenceNumber+1
    });

    if (this.featureForm.valid) {
      this.featureService.createFeature(this.featureForm.value)
        .subscribe(() => {
          this.featureService.isFeatureCreated = this.featureForm.value['name'];
          this.router.navigate(['admin/feature/list']);
          this.isError = false;
        },
          error => {
            this.isError = true;
            this.errorMsg = error;
          });
    }
  }

  update() {
    this.isSubmitted = true;

    if (this.featureForm.valid) {
      const updateFeature = {... this.featureForm.value, id: Number(this.featureId)}
      this.featureService.updateFeature(updateFeature).subscribe(
        () => {
          this.featureService.isFeatureUpdated = true;
          this.router.navigate(['/admin/feature/view', this.featureId]);
          this.isError = false;
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        });
    }
  }

getSuggestions() {
    this.featureService.getSuggestions('selfservicefeature')
    .subscribe(suggestions => {
      this.sequenceNumber = suggestions[suggestions.length-1].sequenceNumber;
    },
    (error)=> { this.isError = true; this.errorMsg = error; });
  }

  goBack() {
    this.helpCategoryService.tempData = "";
    if (this.isEditMode) {
      this.router.navigate(['/admin/feature/view', this.featureId]);
    } else {
      this.router.navigate(['admin/feature/list']);
    }
  }

}


