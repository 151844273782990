<!-- Add class of "back-action-container" when having back button in place like below -->
<div class="heading-container ">
    <div class="back-action-container">
        <div class="back-button">
            <a href="javascript:void(0);" (click)="goBack()">
                <span class="material-icons">
                    arrow_back
                </span>
                Projects
            </a>
        </div>
        <h1> {{createdProjName()}}</h1>
    </div>
</div>
<div class="main-container-sub responsive-container separate-form-container m-0">
    <div class="row m-0 ">
        <aside class="aside col-lg-3 col-sm-3" *ngIf="!assignFormSection">
            <ol class="timeline">
                <li id="step1" class='active'>
                    Project Information
                </li>
                <li id="step2">
                    Client Information
                </li>
            </ol>
        </aside>
        <router-outlet></router-outlet>
    </div>

</div>
<!-- <app-footer ></app-footer> -->