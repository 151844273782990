<div class="help-landing-container heading-container">
  <h1>Resource Center</h1>

  <div class="heading-wrapper">
    <!--<h2>How can we help you?</h2>
    <p>Choose a category to find the help you need.</p>-->
  </div>
  <div class="d-flex create-new-topic-btn fdl-2" *ngIf="canCreateHelp()">
    <!--remove onclick-->
    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="navigateTo('create')">
      <span class="material-icons">add</span> Create New Article
    </button>
  </div>
</div>
<div class="help-landing-page main-container-sub responsive-container separate-form-container">
  <section class="right-section assign-container col-12 pl-2 pr-2" style="max-width: 100%;">
    <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
      <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
      <span class="iconLabel" aria-label="sucess"></span>
      <i class="material-icons" aria-hidden="true">check_circle</i>
      <p>
        {{successMsg}}

      </p>
    </div>
    <form [formGroup]="filterForm" autocomplete="off" class="p-0">
      <div class="landing-search-wrapper">
        <div>
          <div class="has-error">
            <span *ngIf="!filterForm.get('name').valid && filterForm.get('name').errors?.pattern && filterForm.get('name').dirty">
              <i class="material-icons">warning</i>Please enter a valid Resource Article Name</span>
          </div>
        </div>
      </div>
      <div class="landing-search-wrapper">
        <div class="search-container">
          <div class="search-label">Search:</div>
          <div class="help-search">
            <input type="text" formControlName="name" placeholder="Search Resource Article"><button><i class="material-icons icon-after">search</i></button>
          </div>
        </div>
      </div>
    </form>
    <p *ngIf="!helpCategoryList || helpCategoryList.helpTopics.length == 0">No records available.</p>
    <div *ngIf="helpCategoryList" class="landing-card-list-container">
      <ul class="landing-card-list">

        <li *ngFor="let row of helpCategoryList.helpTopics; let i = index;" class="list">
            <div class="nav-link">
              <h2 title="{{row.title}}">
                <a class="title-nav" href="javascript:void(0);" routerLink="/help/topic/{{row.id}}">
                  {{row.title}}
                </a>
              </h2>

              <div class="description-wrapper">
                <p class="text-truncate-two-lines" title="{{row.description}}">{{row.description}}</p>
              </div>
              <a class="dropdown-item cat-link" href="javascript:void(0);" routerLink="{{row.helpCategoryId}}">

                {{row.helpCategoryName}}
              </a>

              <div class="cat-wrapper">
                <div class="date-wrapper">
                  <span class="date-title">Edited {{row.modifiedOn | dateFormat}}</span>

                </div>
                <div class="profile">
                  <span class="profile-disc">
                    <img src="assets/images/thumbnail-150x150.png" alt="" class="thumbnail img-w28">
                    <span class="profile-text text-truncate " title="{{row.modifiedByName}}">{{row.modifiedByName}}</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="kebab-menu dropleft">
              <a *ngIf="row.editAccessWith  && canCreateHelp()" href="javascript:void(0);" data-toggle="dropdown" id="headerSwitcher" aria-expanded="false">
                <span class="material-icons">
                  more_vert
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher" x-placement="left-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-117px, 5px, 0px);">
                <div class="dropdown-list-option" *ngIf="row.editAccessWith && canCreateHelp()">
                  <!--<a class="dropdown-item" href="javascript:void(0);" routerLink="/help/topic/{{row.id}}" routerLinkActive="active">View</a>-->
                  <a class="dropdown-item" href="javascript:void(0);" *ngIf="row.editAccessWith && canCreateHelp()" routerLink="/self-service/helpTopic/edit/{{row.id}}" routerLinkActive="active">Edit</a>
                  <a class="dropdown-item" href="javascript:void(0);" *ngIf="row.editAccessWith && canCreateHelp()" (click)="deleteTopic(row.id,row.title)">Remove</a>

                </div>
              </div>
            </div>
        </li>


      </ul>
    </div>
    <div class="mb-2">
      <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 " [pagerObject]="pagerObject"
                      (pagingChanged)="changePage($event)"></app-pagination>
    </div>
  </section>

  <section class="landing-page-articles-section">
    <div class="landing-page-article-header">
      <h2>
        Popular Articles
      </h2>

      <div class="article-container">
        <article *ngFor="let row of popularTopic; let i = index;">
          <h3>{{row.helpCategoryName}}</h3>
          <ul class="article-list">
            <li *ngFor="let topics of row.helpTopics; let k = index;">
              <a href="javascript:void(0);" routerLink="/help/topic/{{topics.id}}" routerLinkActive="active" class="description-image">{{topics.title}}</a>
            </li>

          </ul>
        </article>

      </div>
    </div>
  </section>
</div>

<div class="modal fade bd-example-modal-md" id="deleteModal" data-backdrop="static" data-keyboard="false"
     tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-md mt-120" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Delete Article</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to delete article <b>{{name}}</b>? </p>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal">Cancel</button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" data-dismiss="modal" (click)="onClick_delete()">Ok</button>
      </div>
    </div>
  </div>
</div>
