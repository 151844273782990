<div class="main-container-sub responsive-container">
  <section class="right-section col-10 offset-1">


    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
      <i class="material-icons">warning</i>
      <p [innerHtml]="errorMsg"></p>
    </div>

    <div class="alert alert-success alert-dismissible" *ngIf="showSuccessMsg">
      <button type="button" class="close" data-dismiss="alert" aria-label="close"
      (click)="showSuccessMsg=false">
×
</button>
      <span class="iconLabel" aria-label="sucess"></span>
      <i class="material-icons" aria-hidden="true">check_circle</i>
      <p>
        {{successMsg}}
       
      </p>
    </div>



    <!--  <div class="col-md-4" *ngIf="isInitiateDGF && isProjectDisabled">
                <label for="projectName">Select Project:</label>
                <input type="text" class="form-control" disabled="" formControlName="projectName"
                    id="projectName">
            </div>
        <div class="col-md-4 typeahead-wrapper" *ngIf="isInitiateDGF && !isProjectDisabled">
            <label for="projectName">Select Project:</label>
            <fdl-type-ahead formControlName="projectName"
                optionField="title" placeholder="Type to search" (onSelect)="selectProject($event)" [collection]="projectSuggestion" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
        </div>

        <div *ngIf="isInitiateDGF" class="col-xs-1 pt-4 strong"><div class="pt-1">OR</div></div>
    -->
    <div class="form-container">
      <p>Please provide details below to intiate the request.</p>
      <div class="pt-2">
        <form [formGroup]="submitFormRequest" autocomplete="off">
          <!--<div class="form-row">
            <div class="col-md-10 mb-3" [ngClass]="{'has-error': !submitFormRequest.get('isClient').valid && (submitFormRequest.get('isClient').touched|| isSubmitted) }">
              <label for="validationCustom01" class="asterisk">Requesting on someone else behalf ?</label>
              <fieldset>
                <div class="radio-group d-flex">
                  <div class="radio mr-3">
                    <input type="radio" id="userTypeRadioTwo" name="isClient" value="yes" formControlName="isClient">
                    <label for="userTypeRadioTwo"><span>Yes</span></label>
                  </div>
                  <div class="radio">
                    <input type="radio" id="userTypeRadioOne" name="isClient" value="no" formControlName="isClient">

                    <label for="userTypeRadioOne"><span>No</span></label>
                  </div>
                </div>

              </fieldset>
              <span *ngIf="!submitFormRequest.get('isClient').valid && (submitFormRequest.get('isClient').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select user type.
              </span>
            </div>
          </div>-->

          <!--<div class="form-row" *ngIf="submitFormRequest.controls['isClient'].value=='yes'">
            <div class="col-md-10 mb-3" [ngClass]="{'has-error': !submitFormRequest.get('requestorType').valid && (submitFormRequest.get('requestorType').touched|| isSubmitted) }">
              <label for="validationCustom01" class="asterisk">
                Select Requester Type:
              </label>
              <fieldset>
                <div class="radio-group d-flex">
                  <div class="radio mr-3">
                    <input type="radio" id="requestorTypeRadioTwo" (change)="changeUserType();" name="requestorType" value="user" formControlName="requestorType">
                    <label for="requestorTypeRadioTwo"><span>Registered User</span></label>
                  </div>
                  <div class="radio">
                    <input type="radio" id="requestorTypeRadioOne" (change)="changeUserType();" name="requestorType" value="guest" formControlName="requestorType">

                    <label for="requestorTypeRadioOne"><span>Guest</span></label>
                  </div>
                </div>
              </fieldset>
              <span *ngIf="!submitFormRequest.get('requestorType').valid && (submitFormRequest.get('requestorType').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select user type.
              </span>
            </div>
          </div>-->

          <div>
            <p class="mt-1" style="    font-weight: 500;    color: #000000d1;">Select/De-select Forms to Request:</p>
            <button class="btn btn-secondary" id="select" type="button" data-toggle="modal" (click)="selectFormBtn()">
              Select Forms
            </button>
            <!--<app-data-table *ngIf="submitRequestList" [rows]="filteredSubmitRequestList" [keys]="keys"
                            [displayColumns]="displayColumns" (sortingClicked)="applySorting($event)" [showMenu]="false"
                            (launchBtnClicked)="takeAction($event)" [showLaunchButton]="true" [showCheckbox]="false"
                            [enableSorting]="true">
            </app-data-table>-->
          </div>
          <div>

            <app-data-table [rows]="selectedForm" [keys]="keys" [(selectedRadioValue)]="selectedRadioValue"
                            [displayColumns]="displayColumns" (sortingClicked)="applySorting($event)" [showMenu]="false"
                            [showCheckbox]="false" [showRadio]="false"
                            [enableSorting]="false">
            </app-data-table>
          </div>
          <!--<div class="form-row" *ngIf="!(submitFormRequest.controls['requestorType'].value=='guest')">
            <div class="col-md-10 mb-3" [ngClass]="{'has-error': !submitFormRequest.get('requestor').valid && (submitFormRequest.get('requestor').touched|| isSubmitted) }">
              <label for="validationCustom01" class="asterisk">
                Requested for:
              </label>
              <fdl-type-ahead formControlName="requestor" optionField="name" placeholder="Type to search" (onSelect)="selectRequestor($event, i)" (noResults)="noRequestorMatched($event,i)"
                              [collection]="orgUsersList" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
              <span *ngIf="!submitFormRequest.get('requestor').valid && (submitFormRequest.get('requestor').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select user type.
              </span>
            </div>
          </div>-->
          <!-- Drawer Container
            <div class="col-md-2 pl-0 pt-3 align-self-center" *ngIf="isInitiateDGF && !isClientDisabled && createClientAllowed">
              <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Client" (click)="openClientAddDrawer()"><i class="material-icons f-30">add_circle_outline</i></a>
            </div>
          </form>

          <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
          <app-anonymous-survey *ngIf="formsetDetails" #surveyComponent></app-anonymous-survey>
              -->

        </form>
      </div>
      <div class="fdl-2 button-container">
        <button type="button" class="btn btn-secondary" aria-label="Default" (click)="navigationForm()">Cancel</button>
        <div>
          <div class="btn-group btn-split">
            <a href="javascript:void(0)" class="btn btn-primary-blue" (click)="submitForm()">Submit</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!--


  <div *ngIf="client_loadDrawer">
    <app-project-drawer-container componentName="client" state="CLIENT"></app-project-drawer-container>
  </div>

  <!-- Drawer Container
  <div *ngIf="loadDrawer">
    <app-project-drawer-container componentName="user" state="RESPONDENT"></app-project-drawer-container>
  </div>

  <app-footer></app-footer>
</div>






<!-------------------------------------------------------------------------------------------------------------------------------->
<!--<div class="heading-container">
    <h1>Submit Form Request</h1>
</div>

<div class="container main-container-sub">
    <div class="form-container">

        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
                (click)="isError=false">×</button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="errorMsg"></p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="showSuccessMsg">
            <span class="iconLabel" aria-label="sucess"></span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>{{successMsg}}
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="showSuccessMsg=false">×</button>
            </p>
        </div>

        <div class="row" [formGroup]="submitFormRequest">
            <section class="col-lg-12 p-0">
               <div class="row">
                   <div class="col-md-10 pb-3">
                    <div class="row">
                    <div class="col-md-4 typeahead-wrapper">
                        <label for="clientName">Select Client:</label>
                        <fdl-type-ahead formControlName="clientName" optionField="title" placeholder="Type to search"
                            [collection]="clientSuggestion" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                        </fdl-type-ahead>
                    </div>
                    <div class="col-md-2 pl-0 pt-3 align-self-center" *ngIf="createClientAllowed">
                        <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Client"
                            (click)="openAddClientDrawer()"><i class="material-icons f-30">add_circle_outline</i></a>
                    </div>
    </div>
                   </div>
               </div>
                <form class="form-border mb-3 box collapse-view" [formGroup]="submitFormRequest" autocomplete="off">
                    <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
                    <div class="filter-form-container collapse" id="collapseFilter">

                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <fdl-type-ahead label="Form Name:" formControlName="title"
                                [collection]="submitRequestList" optionField="title"
                                placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>

                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <fdl-type-ahead label="Product Name:" formControlName="productTitle"
                                [collection]="productSuggestion" optionField="title" placeholder="Type to search"
                                [scrollable]="true" [optionsLimit]="500" [minLength]="0">
                            </fdl-type-ahead>
                        </div>

                        <div>
                            <button type="button" class="btn btn-secondary ml-3" aria-label="Search"
                                (click)="filterSubmitRequest(true)">Search</button>
                        </div>
                    </div>
                </form>

                <div class="table-responsive form-responces">
                    <app-data-table *ngIf="submitRequestList" [rows]="filteredSubmitRequestList" [keys]="keys"
                        [displayColumns]="displayColumns" (sortingClicked)="applySorting($event)" [showMenu]="false"
                        (launchBtnClicked)="takeAction($event)" [showLaunchButton]="true" [showCheckbox]="false"
                        [enableSorting]="true">
                    </app-data-table>

                    <div>
                        <app-pagination
                            *ngIf="pagerObject && pagerObject.totalPages > 1 && filteredSubmitRequestList && filteredSubmitRequestList.length > 0"
                            [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <div *ngIf="loadDrawer">
        <app-project-drawer-container componentName="client" state="CLIENT"></app-project-drawer-container>
    </div>
    <app-footer></app-footer>
</div><!-- Drawer Container
  #############################################################################-->
<div class="modal fade bd-example-modal-lg" id="selectForms" tabindex="-1" role="dialog" data-backdrop="static"
     data-keyboard="false" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Select Forms to Request</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-dismissible alert-danger" *ngIf="isErrorRequest">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
          <i class="material-icons">warning</i>
          <p>Please Select a form to request.</p>
        </div>
        <div class="form-row align-items-end mb-3">
          <!--<div class="col-md-5">
            <label for="searchForm">Form Name:</label>
            <input type="type" id="searchForm" class="form-control" placeholder="Type to search"
                   [(ngModel)]="searchString" name="searchString"
                   (input)="onSearchChange($event.target.value)" autocomplete="off">

          </div>-->
          <!--<div class="col-md-5 typeahead-wrapper" *ngIf="productSection">
            <label for="product">Product Name:</label>
            <fdl-type-ahead [(ngModel)]="product" name="product" optionField="title" placeholder="Type to search"
                            [collection]="productSuggestion" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
            </fdl-type-ahead>
          </div>-->


        </div>
        <div *ngIf="submitRequestList" class="overflow-300 assign-modal-table table-responsive">
          <app-data-table [rows]="filteredSubmitRequestList" [keys]="keys" [(selectedRadioValue)]="selectedRadioValue"
                          [displayColumns]="displayColumns" (sortingClicked)="applySorting($event)" [showMenu]="false"
                          [showCheckbox]="false" [showRadio]="true"
                          [enableSorting]="true">
          </app-data-table>
        </div>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clearValue()">
          Cancel
        </button>
        <button type="button" class=" btn btn-primary-blue ml-2" (click)="selectedFormRequest()">
          Request
        </button>

      </div>
    </div>

  </div>
</div>
