import { Pipe, PipeTransform } from '@angular/core';
import { ISuggestion } from 'src/app/shared/suggestion';

@Pipe({
  name: 'formFilter'
})
export class FormFilterPipe implements PipeTransform {

  transform(items: any[], searchString: string, suggestions:ISuggestion[]): unknown {
    if (!items) {
      return [];
    }
    
    if (!searchString) {
      let filteredItems = items.filter((singleItem, index) => {
        suggestions[index].isFiltered = true;
        return true;
      });
      return filteredItems;
    }

    let filteredItems = items.filter((singleItem, index) => {
      let isMatched = suggestions[index].title.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
      suggestions[index].isFiltered = isMatched;
      return true;
    });
    return filteredItems;
  }
}
