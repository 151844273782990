<div class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open" tabindex="-1" aria-labelledby="addNewAccount"
aria-expanded="true" role="region">
    <div id="createNewUserDw" class="drawer-contents col-xs-12 col-sm-12 col-md-12 project-drawer">
        <h3>{{headingText}}</h3>
        <ng-template appProjectDrawerHost></ng-template>
    </div>
</div>
<div class="backdrop-space">
    <div class="backdrop"></div>
</div>

