<ul class=" breadcrumb breadcrumbs col-md-12" aria-label="breadcrumbs">
    <li aria-label="Current Page, transactions">
        <a href="dashboard.html" tabindex="0">{{navigateToMain}}</a>
    </li>
    <li aria-label="Current Page, transactions" *ngIf="subHeader$ | async as subHeader">
        <a href="dashboard.html" tabindex="0">{{subHeader.label}}</a>
    </li>
    <li aria-label="Current Page, transactions">
        {{subModuleLabel}}
    </li>
</ul>

<!-- Heading main -->
<div class="heading-container">
    <h1>{{subModuleHeading}}</h1>
</div>