<section class="col-lg-12 right-section">
  <div class="form-container">
    <form class="border-0">
      <h2 class="h4 strong">Project Information</h2>
      <p>Please complete below required information to get started.</p>

      <div class="alert alert-dismissible alert-danger" *ngIf="isError || isProjectManagerError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p *ngIf="errorMsg !== 'Name is required.'" [innerHtml]="errorMsg"></p>
        <p *ngIf="errorMsg === 'Name is required.'">Project Name is required.</p>
      </div>

      <div class="alert alert-success alert-dismissible" *ngIf="showSuccessMsg">
        <button type="button" class="close" data-dismiss="alert" aria-label="close"
          (click)="showSuccessMsg=false">×</button>
        <span class="iconLabel" aria-label="sucess"></span>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p>{{successMsg}}

        </p>
      </div>

      <form class="form-filter-container" [formGroup]="projectForm" autocomplete="off">
        <div *ngIf="userAuth && DTOService.getParentType() !== 'edit'" class="form-row">
          <div class="col-md-12 mb-3">
            <label for="validationCustom01">
              Do you want to copy existing project details?
            </label>
            <fieldset>
              <div class="radio-group d-flex">
                <div class="radio mr-3">
                  <input type="radio" id="copyProjectYes" name="copyProject" value="1" formControlName="copyProject"
                    *ngIf="canCopyProject" (change)="showProjects()">
                  <input disabled="disabled" type="radio" id="copyProjectYes" name="copyProject" value="1" formControlName="copyProject"
                    *ngIf="canCopyProject === false">
                  <label for="copyProjectYes"><span>Yes</span></label>
                </div>
                <div class="radio">
                  <input type="radio" id="copyProjectNo" name="copyProject" value="0" formControlName="copyProject"
                    (change)="showProjects()">
                  <label for="copyProjectNo"><span>No</span></label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="form-row" *ngIf="showExistingProjects">
          <div class="col-md-6 mb-3 typeahead-wrapper"
            [ngClass]="{'has-error': !projectForm.get('copyProjectName').valid && (projectForm.get('copyProjectName').touched || projectForm.get('copyProjectName').dirty || isSubmitted)}">
            <label for="copyProjectName" class="asterisk">Existing Project Name:</label>
            <fdl-type-ahead formControlName="copyProjectName" [collection]="projectSuggestion" optionField="title"
              placeholder="Type to search" [scrollable]="true" [optionsLimit]="100" [minLength]="0"
              (onSelect)="onCopyProjectClick($event)">
            </fdl-type-ahead>
            <div class="has-error">
              <span
                *ngIf="!projectForm.get('copyProjectName').valid && (projectForm.get('copyProjectName').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select Project name.
              </span>
              <span
                *ngIf="!projectForm.get('copyProjectName').valid && (projectForm.get('copyProjectName').touched || projectForm.get('copyProjectName').dirty || isSubmitted)">
                <i class="material-icons">warning</i> Please select Project name.
              </span>
            </div>
          </div>
          <!--<div class="filter-form-control col-md-6 mb-3">
      <label for="existingProject" class="asterisk">Existing Project:</label>
      <div class="fdl-select">
        <select name="copyProject" class="form-control" aria-invalid="false"
          formControlName="copyProject" (change)="onCopyProjectClick($event.target.value)">
          <option *ngFor="let project of projectList.projects" [value]="project.id">{{ project.title }}</option>
        </select>
      </div>
    </div>-->
        </div>

        <div class="form-row">
          <div class="col-md-6 mb-3 typeahead-wrapper">
            <label for="product" class="asterisk">Product:</label>
            <fdl-type-ahead formControlName="productName" [collection]="productSuggestion"
              (input)="typeaheadLoadingProducts($event.target.value)" optionField="title" placeholder="Type to search"
              [scrollable]="true" [optionsLimit]="100" [minLength]="0" (onSelect)="onProductClick($event)">
            </fdl-type-ahead>
            <div class="has-error">
              <span *ngIf="!projectForm.get('productName').valid && (projectForm.get('productName').touched || projectForm.get('productName').dirty || isSubmitted)">
                <i class="material-icons">warning</i> Please select Product name.
              </span>
              <span *ngIf="!projectForm.get('productName').valid && projectForm.get('productName').errors?.pattern && (projectForm.get('productName').dirty  || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.PRODUCT_NAME_VALIDATION_MESSAGE}}
              </span>
              <span *ngIf="isProductDisabled">
                <i class="material-icons">warning</i>The product from the previous project is not longer active
              </span>
              <span *ngIf="isProductUnavailable">
                <i class="material-icons">warning</i>The product from the previous project is not available. Please select product from available list.
              </span>

            </div>
          </div>

          <div class="col-md-6 mb-3">
            <label for="projectName" class="asterisk">Project Name:</label>

            <input type="text" class="form-control" id="projectName" formControlName="projectName">
            <div class="has-error">
              <span
                *ngIf="!projectForm.get('projectName').valid && (projectForm.get('projectName').errors?.required || projectForm.get('projectName').errors?.validateWhiteSpaces) && (projectForm.get('projectName').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please enter project name.
              </span>
              <span
                *ngIf="!projectForm.get('projectName').valid && projectForm.get('projectName').errors?.pattern && (projectForm.get('projectName').touched || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.PROJECT_NAME_VALIDATION_MESSAGE}}
              </span>
              <!--<span
                *ngIf="!projectForm.get('projectName').valid && projectForm.get('projectName').errors?.projectTitleExists && (projectForm.get('projectName').touched)">
                <i class="material-icons">warning</i> Project name already exists, please add a new one.
              </span>-->
              <span
                *ngIf="!projectForm.get('projectName').valid && projectForm.get('projectName').errors?.maxlength && (projectForm.get('projectName').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is
                {{_maxLength.Project.Name}}, please correct it.
              </span>
            </div>
          </div>
        </div>
        <div class="form-row">

          <div class="col-md-12 mb-3">
            <label for="description" class="asterisk">Description:</label>
            <textarea class="form-control" id="description" formControlName="description"></textarea>
            <div class="has-error">
              <span
                *ngIf="!projectForm.get('description').valid && (projectForm.get('description').errors?.required || projectForm.get('description').errors?.validateWhiteSpaces) && (projectForm.get('description').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please enter description.
              </span>
              <span
                *ngIf="!projectForm.get('description').valid && projectForm.get('description').errors?.pattern && (projectForm.get('description').touched)">
                <i class="material-icons">warning</i> {{regexValidationMessage.PROJECT_NAME_VALIDATION_MESSAGE}}
              </span>
              <span
                *ngIf="!projectForm.get('description').valid && projectForm.get('description').errors?.maxlength && (projectForm.get('description').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is
                {{_maxLength.Project.Description}}, please correct it.
              </span>
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <label for="validationCustom01">
              Do you need to publish the same Form more than once in this project?
            </label>
            <fieldset>
              <div class="radio-group d-flex">
                <div class="radio mr-3">
                  <input type="radio" id="rdFormYes" name="sameForms" value="1" formControlName="sameForms">
                  <label for="rdFormYes"><span>Yes</span></label>
                </div>
                <div class="radio">
                  <input type="radio" id="rdFormNo" name="sameForms" value="0" formControlName="sameForms">
                  <label for="rdFormNo"><span>No</span></label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 mb-3 mr-1" *ngIf="!isFIMCreate">
            <label for="projectManager" class="asterisk">Project Manager:</label>
            <input type="text" class="form-control" disabled="" id="projectManager" formControlName="projectManager">
            <p style="color:grey;font-size:12px">Initiator / Technical Consultant</p>
            <span *ngIf="PMEmail && PMEmail != '-1'">{{PMEmail}}</span>
          </div>
          <div class="row col-md-6 mb-3 pr-lg-1 pr-0 d-flex flex-nowrap" *ngIf="isFIMCreate">
            <div class="typeahead-wrapper col-11" style="padding-right: 0;"
                 [ngClass]="{'has-error': !projectForm.get('projectManager').valid && (projectForm.get('projectManager').touched || projectForm.get('projectManager').dirty || isSubmitted) || isProjectManagerError}">
              <label for="projectManager" class="asterisk">Project Manager:</label>
              <fdl-type-ahead formControlName="projectManager" [collection]="userSuggestion"
                              (onSelect)="selectPM($event)" optionField="name" placeholder="Type to search" [scrollable]="true"
                              [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
              <p style="color:grey;font-size:12px">Initiator / Technical Consultant</p>
              <span *ngIf="!projectForm.get('projectManager').valid && (projectForm.get('projectManager').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select Project Manager name.
              </span>
              <span *ngIf="PMEmail && PMEmail != '-1'">{{PMEmail}}</span>
              <span *ngIf="isPMNotFiserv">
                <i class="material-icons">warning</i>The project manager from the previous project is not longer a
                Fiserv user
              </span>
              <span *ngIf="isPMDiffOrg">
                <i class="material-icons">warning</i>The project manager from the previous project is not from your
                company
              </span>
              <span *ngIf="isPMDiffProduct">
                <i class="material-icons">warning</i>The project manager from the previous project is not linked to the Product you selected
              </span>
              <span *ngIf="isPMNotAllowRole">
                <i class="material-icons">warning</i>The project manager from the previous project does not longer has
                the permission to be a Project Manager
              </span>
              <span *ngIf="isPMDisabled">
                <i class="material-icons">warning</i>The project manager from the previous project is no longer an
                active user
              </span>
              <span *ngIf="!projectForm.get('projectManager').valid && projectForm.get('projectManager').errors?.pattern && (projectForm.get('projectManager').touched || projectForm.get('projectManager').dirty  || isSubmitted)">
                <i class="material-icons">warning</i> Please enter a valid Project Manager Name
              </span>

            </div>
            <div class="col-1" *ngIf="createUserAllowed">
              <a href="javascript:void(0);" style="position: relative; top:25px; padding-right: 12px"
                data-toggle="tooltip" data-placement="top" title="Add New Project Manager"
                (click)="openAddUserDrawer()">
                <i class="material-icons f-30">add_circle_outline</i></a>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="row col-md-6 pr-lg-1 pr-0 d-flex flex-nowrap mb-3">
            <div class="typeahead-wrapper col-11" style="padding-right: 0;">
              <!-- START | PBI-168948-->
              <div class="mt-3" [ngClass]="{'has-error': invalidStakeholder }">
                <app-mutipleadd #addProjectStakeholder [overrideRightMargin]="true" buttonText="Add Stakeholder"
                                (formReady)="formInitialized('projectStakeholder', $event)"
                                (respondentSelected)="handleRespondentSelected($event)" [duplicateTestField]="'id'"
                                [isAddButtonEnable]="true" [isSubmitted]="isSubmitted" [isFdl]="true" elementName="projectStakeholder"
                                [fdlData]="formsStakeholderDetails.usersData" [filedJson]="filedStakeholderJson"
                                [filedDetails]="filedformStakeholderDetails" [formControlData]="stakeholderControlData"
                                [cssWidth]="'100%'" (extraButtonClick)="onExtraButtonClick($event)" [allowDuplicates]="false"
                                (duplicateFound)="handleDuplicates($event)">
                </app-mutipleadd>
                <div class="has-error">
                  <span *ngIf="stakeholderSameAsPm" style="color: #dd3435;">
                    <i class="material-icons">warning</i>The project manager can't be a stakeholder
                  </span>
                </div>
                <span *ngIf="duplicatedStakeholder" style="color: #dd3435;">
                  <i class="material-icons">warning</i>Duplicated stakeholder
                </span>
                <span *ngIf="invalidStakeholder" style="color: #dd3435;">
                  <i class="material-icons">warning</i>Stakeholder's selected are no longer valid. Please select valid stakeholder's
                </span>
              </div>
              <!--
                        <span *ngIf="stakeholderSameAsPm">
                          <i class="material-icons">warning</i>The project manager can't be a stakeholder
                        </span>
                        -->
              <!-- END | PBI-168948-->
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="projectStartDate">Project Start Date:</label><br>
            <span>{{currentDate | date: 'MM/dd/yyyy'}}</span>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-6 mb-3" [ngClass]="{'has-error': !projectForm.get('projectClosureDate').valid}">
            <label for="projectClosureDate">Tentative Project End Date:</label>
            <input type="date" class="form-control" id="projectClosureDate" [min]="todaysdate"
              formControlName="projectClosureDate">
            <div class="has-error" *ngIf="projectForm.get('projectClosureDate').invalid">
              <span class="has-error" *ngIf="projectForm.controls.projectClosureDate.invalid">
                <i class="material-icons">warning</i> Tentative Project End Date cannot be less than Project Start Date.
              </span>
            </div>
          </div>
        </div>
      </form>
      <div class="fdl-2 button-container">


        <button type="button" class="btn fdl-btn btn-secondary-light " aria-label="Default"
          (click)="cancelForm();">Cancel</button>
        <div>

          <div class="btn-group btn-split" href="#">
            <a href="javascript:void(0)" class="btn fdl-btn btn-primary-orange"
              (click)="saveAndContinueProjectForm();">Save and Continue</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<!-- Drawer Container -->
<div *ngIf="loadDrawer">
  <app-project-drawer-container componentName="user" state="FISV_IMPL_MGR"></app-project-drawer-container>
</div>
<!-- START | PBI-168948 -->
<div *ngIf="loadDrawerStakeholder">
  <app-project-drawer-container componentName="user" state="FISV_PROJECT_STKHL"></app-project-drawer-container>
</div>
<!-- END | PBI-168948 -->
