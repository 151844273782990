import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, ValidatorFn, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { FormsService } from 'src/app/services/forms.service';
import { IRespondent } from 'src/app/shared/user';
import { Router, ActivatedRoute } from '@angular/router';
import { ManageFormsService } from 'src/app/admin/manageForms/services/manageForms.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { MAX_LENGTHS } from 'src/app/core/constants';
import { forkJoin } from 'rxjs';
import { ClientsService } from 'src/app/services/clients.service';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { MutipleaddComponent } from '../mutipleadd/mutipleadd.component';
import { Directive, HostListener } from '@angular/core';
import { PageTemplateService } from 'src/app/services/page-template.service';
declare var $: any;

@Component({
  selector: 'app-update-approver',  
  templateUrl: './update-approver.component.html'
})
export class UpdateApproverComponent implements OnInit {
  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.disabled = true;
  }
  updateApproverForm: FormGroup;
  assignedToId;
  isError: boolean = false;
  errorMsg: string = '';
  isClicked: boolean = false;
  updateApproverSuccessMsg: string = '';
  approverUsers: IRespondent;
  approverUsers2: any;
  fieldformApproverJson: any;
  filedformApproverDetails: any;
  @Input() updateApproverFormData: any;
  ready: boolean = false;
  isSubmitted: boolean = false;
  isDuplicate = false;
  @Input() type: string;
  _maxLength: any;
  @Output() successUpdateApprover = new EventEmitter
  @ViewChildren('addApprover') addApprover: QueryList<MutipleaddComponent>;

  constructor(
    private formService: FormsService,
    private _router: Router,
    private _manageForm: ManageFormsService,
    private projectsService: ProjectsService,
    private clientsService: ClientsService,
    private fb: FormBuilder,
    private pageTemplateService: PageTemplateService
  ) { }

  ngOnChanges() {
    if (Object.keys(this.updateApproverFormData).length !== 0) {
      this.ready = false;


      this.getAllUsers();
    }
  }
  getApproverArray(approverTemp: any, id: any) {

    let approverUserId = [];
    for (let k in approverTemp) {
      if (approverTemp[k].id != '')
        approverUserId.push({
          "id": approverTemp[k].tempid,
          "approverId": approverTemp[k].id,
          "level": approverTemp[k].level != 0 && approverTemp[k].level != '' ? approverTemp[k].level : 1,
          "isReviewComplete": approverTemp[k].isDisable,

        })
    }
    return approverUserId;

  }
  ngOnInit(): void {
    this._maxLength = MAX_LENGTHS;
    this.filedformApproverDetails = [
      { 'name': 'name', 'type': 'fdl', 'label': 'Approver', 'isRequired': false, 'fdldata': this.approverUsers2, 'emailField': 'emails', "className": "col-md-12", "erroeMessage": { 'valid': 'Please select an Approver.' } },
      { 'name': 'emails', 'type': 'emailshow', 'isDisabled': 'yes', 'label': 'Email', }, 
      { 'name': 'id', 'type': 'fdlid' }, 
      { 'name': 'level', "className": "col-md-4", 'label': "Level", "erroeMessage": { 'valid': 'Please enter Approver level.' }, 'type': 'text', 'isRequired': false, }
    ]

    this.fieldformApproverJson = {

      'json': {
        name: [''],
        emails: '',
        id: '',
        level: ['1'],
        'isDisable': 'false',
        'tempid': '0'


      }
    }
    this.updateApproverForm = this.fb.group({
      'formApprover': this.fb.array([]),
      'approverSchema': ['OR'],

    })



  }
  formInitialized(name: string, formSet: any, form: any) {
    this.updateApproverForm.setControl(name, form);
    // this.updateFormValidators(formSet);
    this.reEvaluateValidators(name, 0);
    // this.settingUnauthenticatedSurveyType( formSet);



  }

  selectUser(data: any) {
    if (data.item && data.item.id) {
      this.assignedToId = data.item.id;
      this.updateApproverForm.patchValue({ 'userEmail': data.item.email })
    }
  }

  getAllUsers() {
    this.updateApproverForm = this.fb.group({
      'formApprover': this.fb.array([]),
      'approverSchema': ['OR'],

    })
    let usersByOrganization;
    let usersByClient
    let approverApiArr = [this.formService.getUsersByOrganizationId(this.updateApproverFormData.organizationId)];
    if (this.updateApproverFormData.clientId && this.updateApproverFormData.clientId !== null)
      approverApiArr.push(this.clientsService.getClientDetailsById(this.updateApproverFormData.clientId, "respondents"));

    forkJoin(approverApiArr).subscribe(userData => {
      usersByOrganization = userData[0];
      usersByClient = userData[1];
      const allUsers = (usersByClient !== undefined) ? usersByOrganization.concat(usersByClient) : usersByOrganization;
      this.approverUsers = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);
      this.approverUsers2 = { 'data': this.approverUsers }
      let approver = [];
      if (this.updateApproverFormData.approvers && this.updateApproverFormData.approvers.length > 0) {
        for (let k in this.updateApproverFormData.approvers) {
          let userObj = this.projectsService.fetchDetailsForUserById(this.approverUsers, this.updateApproverFormData.approvers[k].approverId);

          approver.push({ 'name': userObj.name, 'emails': userObj.email, id: this.updateApproverFormData.approvers[k].approverId, level: this.updateApproverFormData.approvers[k].level, isDisable: this.updateApproverFormData.approvers[k].isReviewComplete, 'tempid': this.updateApproverFormData.approvers[k].id })
        }
      }
      if (this.updateApproverFormData.approvalSchema && this.updateApproverFormData.approvalSchema!=null) {
        this.updateApproverForm.patchValue({ 'approverSchema': this.updateApproverFormData.approvalSchema })
      }
      this.fieldformApproverJson = {
        "dataToEdit": approver,
        'json': {
          name: [''],
          emails: '',
          id: '',
          level: ['1'],
          'isDisable': 'false',
          'tempid': '0'


        }
      }
      this.ready = true;

    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }


  cancelHandler() {
    $('#changeApprover').modal('hide');
    this.isError = false;
    this.isClicked = false;
    this.updateApproverForm.reset();

  }

  updateApproverHandler() {
    this.addApprover['_results'][0].isInputValid()
    if (!this.updateApproverForm.valid) {
      this.isClicked = true;
      return false;
    }
    else {
      let assignedToId = this.projectsService.fetchIdForRequest(this.approverUsers, this.updateApproverForm.value.userName)

      let data = {
        "approvers": this.getApproverArray(this.updateApproverForm.value['formApprover']['formApprover'], this.updateApproverFormData.id),
        "approvalSchema": this.updateApproverForm.value['approverSchema'],
        "comments": ""
      }
      
      let updateApproverService: any;
      if(this.type === 'viewPageResponses')
        updateApproverService = this.pageTemplateService.updatePageResponseApprover(this.updateApproverFormData.id, data);
      else 
        updateApproverService = this.formService.updateResponseApprover(this.updateApproverFormData.id, data);
      
      updateApproverService.subscribe(
        response => {
          this.isError = false;
          this.cancelHandler();
          this.updateApproverSuccessMsg = `The Approver(s) for the '${this.updateApproverFormData.title}' has been changed.`
          if (this.type === "viewProject" || this.type === "viewFormResponses" || this.type === 'viewPageResponses') {
            this.successUpdateApprover.emit({ updateApproverSuccessMsg: this.updateApproverSuccessMsg });
          }
          else if (this.type === "viewResponse") {
            this._router.navigate(["response/list"]);
          }
        },
        error => { this.isError = true, this.errorMsg = error }
      )
    }
  }

  validation(controlName: string, values: FormArray): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      //  return { 'duplicate': true };

      let array: any = [];

      if (values && values[controlName]) {

        for (let k in values[controlName]) {
          let keyName = '';
          let fieldValue = values[controlName][k];
          if (controlName == 'formApprover') {
            keyName = fieldValue.id + '##' + fieldValue.level;
          } else if (controlName == 'formResponder') {
            keyName = fieldValue.id
          } else if (controlName == 'guestResponder') {
            keyName = fieldValue.email
          } else {
            this.isDuplicate = false;
            return null;
          }

          if (fieldValue.name != '') {
            if (array[keyName])
              array[keyName].push({ "index": k });
            else {
              array[keyName] = [];
              array[keyName].push({ "index": k });
            }
          }
        }
      }
      for (let i in array) {
        if (array[i].length > 1) {
          this.isDuplicate = true;
          return { 'duplicate': true };
        }
      }
      this.isDuplicate = false;
      return null;
    }

  }
  reEvaluateValidators(fieldName, setNo, isNested = 'no') {
    if (isNested != 'no') {

    } else {
      const fieldCtrl = this.updateApproverForm.controls[fieldName];

      fieldCtrl.setValidators(this.validation(fieldName, this.updateApproverForm.get(fieldName).value));
      fieldCtrl.updateValueAndValidity();
    }
  }
}
