import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from "./loader.service";
import { InterceptorSkipHeader } from "../core/constants";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor(private loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }
    const spinnerSubscription: Subscription = this.loaderService.spinner$.subscribe();
    return next.handle(req).pipe(
      finalize(() => {
        spinnerSubscription.unsubscribe();
        //setTimeout(() => {
        //  spinnerSubscription.unsubscribe()
        //}, 1000);
      })
    );
  }
}
