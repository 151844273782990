import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


import { ReportResolved } from '../shared/report';
import { ReportsService } from './report.services';

@Injectable({
  providedIn: 'root',
})
export class ReportResolver implements Resolve<ReportResolved> {
  constructor(private reportService: ReportsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ReportResolved> {
    const id = route.paramMap.get('id');
   

    return this.reportService.getReportDataById(+id)
      .pipe(
        map(report => ({ report })),
        catchError(error => {
          const message = `Retrieval error: ${error}`;
          return of({ report: null, error: message });
        })
      );
  }
}
