
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IForms, IForm } from '../shared/form';
import { ProjectsService } from '../services/projects.service';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IQuery } from '../shared/query';
import { IPager } from '../shared/pagination';
import { MasterService } from '../services/master.service';
import { IStatus, IResponseStatusResloved } from '../shared/status';
import { ISuggestions } from 'src/app/shared/suggestion';
import { Router, ActivatedRoute } from '@angular/router';
import { FormsService } from '../services/forms.service';
import { PermissionsService } from '../services/permissions.service';
import { ManageFormsService } from '../admin/manageForms/services/manageForms.service';
import { IRespondent, IUser } from '../shared/user';
import { UserService } from '../services/user.service';
import { ContentService } from "src/app/services/content.service";
import { ICreateForm } from '../admin/manageForms/models/createForm';
import { ProjectsDTOService } from '../Projects/Services/projectsDTO.service';
import { UserFilterService } from './../services/user.filter.service';
import { PERMISSIONS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from '../core/constants';
import { PutOnHoldComponent } from '../SharedModule/Components/put-on-hold/put-on-hold.component';
import { EditFormDetailsComponent } from '../SharedModule/Components/edit-form-details/edit-form-details.component';
import { WorkflowService } from '../services/workflow.service';
import { PreferenceService } from '../services/preference.service';
import { Location } from '@angular/common';
import { FdlFilterUIHepler } from '../shared/fdl-filter-ui-helper';
declare var $: any;

@Component({
  templateUrl: './form-responses.component.html'
})
export class FormResponsesComponent implements OnInit, OnDestroy {
  isError: boolean;
  filterForm: FormGroup;
  responseList: IForms = { forms: [] };
  queryParam: IQuery;
  pagerObject: IPager;
  productSuggestion: ISuggestions;
  projectSuggestion: ISuggestions;
  clientSuggestion: ISuggestions;
  packageSuggestion: ISuggestions;
  responsesSuggestion: ISuggestions;
  formTemplateSuggestion: ISuggestions;
  isAdmin = false;
  isDeleteResponseAllowed: boolean = false;
  showProductFilter: boolean = false;
  showProjectFilter: boolean = false;
  isClientFilterAllowed: boolean = false;
  showAssignedToFilter: boolean = false;
  userSuggestion: ISuggestions;
  respondentUsers: IRespondent;
  displayColumns = ['ID', 'Form Name', 'Client Name', 'Respondent Name', 'Approver Name', 'Current Assignee','Package Name','Status', 'Due Date', 'Last Updated User', 'Last Updated Date'];
  keys = ['id', 'title', 'clientTitleWithDuns', 'assignedToName', 'currentApproverName', 'editAccessWith','package' ,'responseStatusTitle', 'dueDate', 'modifiedByName', 'modifiedOn'];
  currentPageNumber: number = 1;
  showComment: boolean = true;
  formStatus: IStatus[] = [];
  sortingObject;
  errorMsg: string = "";
  deletedFormMsg: string = "";
  listname:any='';
  reinitiatedFormMsg: string = "";
  delegatedFormMsg: string = "";
  updateApproverMsg: string = "";
  filteredResponseList: any = [];
  responseData: any = {};
  approvalMsg: string = "";
  userData: IUser;
  showOrganizationFilter: boolean = false;
  organizationSuggestion: ISuggestions;
  isReinitiateAllowed: boolean = false;
  isViewHistoryAllowed: boolean = false;
  isViewTimelineAllowed: boolean = false;
  isEditFormDetailsAllowed: boolean = false;
  isChangeApproverAllowed: boolean = false;
  isPublishForms: boolean;
  message: string;
  mode: string = "";
  regexValidationMessage: any;
  isRejectedError: boolean = false;
  @ViewChild('putOnHold') putOnHold: PutOnHoldComponent;
  @ViewChild('editformdetails') editformdetails:EditFormDetailsComponent
  filterExists: boolean = false;
  filtered: boolean = false;
  rowVersion?: string;
  responseStatusId: number;
  selectedOrgId: number;
  content: any;
  clientText: string = '';

  public delegateFormId: any;
  public delegateRespondentId: any;
  public currentUrl = 'response/list';

  productText: string;
  customFormFieldListJson: any;
  ignoreSortingColumns: any;
  formTemplatedSelected: boolean = false;
  constructor(private projectService: ProjectsService,
    private masterService: MasterService, private router: Router,
    private formsService: FormsService, private permissionService: PermissionsService,
    private _manageForm: ManageFormsService, private userService: UserService,
    private _projectDto: ProjectsDTOService,
    private route: ActivatedRoute,
    private userFilterService: UserFilterService,
    private workflowService: WorkflowService,
    private contentService: ContentService,
    private readonly location: Location,
    private fdlFilterHelper: FdlFilterUIHepler) {
  }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.userData = this.userService.getUserListData();
    this.content = this.contentService.getcontent();

    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    this.selectedOrgId = 0;
    const resolvedData: IResponseStatusResloved = this.route.snapshot.data['resolveData'];
    this.formStatus = resolvedData.responseStatus;
    this.delegatedFormMsg = this._manageForm.getDelegateSuccessMsg();
    this.isDeleteResponseAllowed = false;
    this.showProductFilter = this.permissionService.showProductFilter();
    this.showProjectFilter = this.permissionService.showProjectFilter();
    this.isClientFilterAllowed = this.permissionService.isClientFilterAllowed();
    this.showAssignedToFilter = this.permissionService.showProjectFilter();
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.isPublishForms = this._projectDto.getPublishForms();
    this.listname = PreferenceService.formResponseList;
    this.message = ' Your form publish request has been submitted. It may take some time to process your request, you will be notified once it is successfully processed and published to respondents.';
    if (this._projectDto.getPublishRequestForms()) {
      this.isPublishForms = true;
      this.message = 'Your Intake Form assignment request has been submitted. It may take some time to process your request, you will be notified once it is successfully processed and published to respondents.';

    }
    this.initFilterForm();
    this.initQueryParams();

    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    this.getSuggestions();
    if (this.filterForm.value['formTemplate'] && this.filterForm.value["formId"]) {
      this.formTemplatedSelected = true;
    }
    this.initResponseList();
    this.approvalMsg = this._manageForm.approvalMsg;
    this.isViewHistoryAllowed = !this.userService.isClient && !this.userService.isRespondent;
    this.isViewTimelineAllowed = !this.userService.isRespondent; // && !this.userService.isClient;
    this.isEditFormDetailsAllowed = !this.userService.isClient && !this.userService.isRespondent;
    this.isReinitiateAllowed = this.userService.hasChannelPartnerRole() || (!this.userService.isClient && !this.userService.isRespondent);
    this.isChangeApproverAllowed = !this.userService.isClient && !this.userService.isRespondent;

    this.filtered = this.filterExists;

    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.getDelegateUserParams(params);
      });
  }

  private getDelegateUserParams(params: any): void {
    if (params['delegateFormId'] && params['delegateRespondentId']) {
      this.delegateFormId = +params['delegateFormId'];
      this.delegateRespondentId = +params['delegateRespondentId'];
      this.queryParam.id = this.delegateFormId;
      this.callResponseListService();
      this.location.replaceState(this.currentUrl);
    }
  }

  refresh() {
    this.callRefreshResponseListService();
  }
  allowDelete = (data: any): boolean => {
    if (this.userData.isAdmin) {
      return true;
    }

    if (data.fiservImplementationManagerId == this.userData.id
      && this.userData.permissions.includes(PERMISSIONS.Projects_Edit_Assigned)) {
      return true;
    }

    if (this.userData.permissions.includes(PERMISSIONS.Forms_ReadWrite_All)
      && this.userData.organization == data.organizationId) {
      return true;
    }

    return false;
  }

  typeaheadLoadingClients() {
    if (this.filterForm.controls.ClientTitleWithDuns.invalid) { 
      return; 
    }
    this.getSuggestions();
  }

  getSuggestions() {
    // new changes
    this.getResponseSuggestions();
    this.getProductSuggestions();
    this.getProjectSuggestions();
    this.getClientSuggestions();

    if (this.showAssignedToFilter) {
      this.projectService.getSuggestions("users", "Form Respondent", "role").subscribe(
        data => this.userSuggestion = data,
        (error) => { this.isError = true; this.errorMsg = error; }
      );
    }

    if (this.isAdmin) {
      this.projectService.getSuggestions("organizations", "")
        .subscribe(suggestions => {
          this.organizationSuggestion = suggestions;
        },
          error => { this.isError = true; this.errorMsg = error; });
    }
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      id: new FormControl('', [Validators.pattern(REGEX_PATTERNS.Numeric)]),
      ProjectTitle: new FormControl('', [Validators.pattern(REGEX_PATTERNS.project_name_validator)]),
      ProductTitle: new FormControl(''),
      ClientTitleWithDuns: new FormControl('', [Validators.pattern(REGEX_PATTERNS.client_name_validator)]),
      title: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      AssignedTo: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      ResponseStatusTitle: new FormControl(''),
      OrganizationTitle: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]),
      formTemplate: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      package: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      formId: new FormControl(''),
    });
  }

  successDelegateClick(evt) {
    this.callRefreshResponseListService();
    this.deletedFormMsg = '';
    this.delegatedFormMsg = evt.delegateSuccessMsg;
    this.delegateFormId = this.delegateRespondentId = null;
  }

  successUpdateApproverClick(evt) {
    this.callResponseListService();
    this.updateApproverMsg = '';
    this.updateApproverMsg = evt.updateApproverSuccessMsg;
  }

  callResponseListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.projectService.getResponseList(this.queryParam)
      .pipe(take(1))
      .subscribe(responses => {
        this.isError = false;
        this.responseList = responses.body as IForms;
        this.filteredResponseList = this.responseList.forms;
        this.responseList.forms.forEach(responseList => {
          responseList.delegateLink = false;
          responseList.updateApproverLink = false;
        });
        for (const responseForm of this.responseList.forms) {
          const responseStatusTitle = responseForm.responseStatusTitle.trim().toLowerCase();
          if (["under review", "cancel", "completed", "on hold", "rejected"].indexOf(responseStatusTitle) >= 0) {
            responseForm.delegateLink = false;
          } else {
            responseForm.delegateLink = true;
          }

          if (["under review"].indexOf(responseStatusTitle) >= 0) {
            if(responseForm.editAccessWith) {
              responseForm.editAccessWith = `${responseForm.currentLevel}-${responseForm.editAccessWith}`;
            }
          }

          if (!!responseForm.formFieldListJson && this.formTemplatedSelected) {
            this.customFormFieldListJson = JSON.parse(responseForm.formFieldListJson.split('isEditable').join('isEditable2'));
          }
          else {
            this.customFormFieldListJson = undefined;
          }

          if (responseForm.answerJSON != null && responseForm.answerJSON != undefined) {
            responseForm.customeFormFieldListJsonAnswer = JSON.parse(responseForm.answerJSON).answer;
          }
          else {
            responseForm.customeFormFieldListJsonAnswer = {};
          }

          if (["completed", "overdue", "cancel", "on hold", "rejected"].indexOf(responseStatusTitle) >= 0) {
            responseForm.reInitiateLink = true;
          }

          if (["completed", "on hold", "rejected"].indexOf(responseStatusTitle) < 0) {
            responseForm.updateApproverLink = true;
          }
          else {
            responseForm.updateApproverLink = false;
          }

          responseForm.delegateLink = responseForm.delegateLink
            && (this.allowDelete(responseForm)
              || responseForm.assignedToName.indexOf(this.userData.userId) >= 0
              || responseForm.respondents.some((rs) => rs.assignedToId == this.userData.id));
          responseForm.updateApproverLink = responseForm.updateApproverLink && this.isallowedChangeApproverReinitiate(responseForm);
          responseForm.reInitiateLink = responseForm.reInitiateLink && this.isallowedChangeApproverReinitiate(responseForm);
          responseForm.disableReinstate = responseForm.hideReInitiate;
        }

        if (responses.headers.get('X-pagination')) {
          this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
        }

        this.masterService.resetFiltersCancelledFlag();
        if (this.delegateFormId && this.delegateRespondentId) {
          this.navigate({action: 'delegate', data: this.delegateFormId});
          this.delegateFormId = this.delegateRespondentId = null;
        }

        $('#refreshTask').removeClass('fa-rotation');
      }, (error) => {
        this.isError = true; this.errorMsg = error;
      });

    if (this.customFormFieldListJson != undefined && this.customFormFieldListJson.length > 0) {
      for (let item of this.customFormFieldListJson) {
        // this.displayColumns.push(item['formfieldlabel']);
        // this.keys.push(item['formfieldapikey']);
        this.ignoreSortingColumns.push(item['formfieldapikey']);
      }
    }
  }

  callRefreshResponseListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.projectService.getRefreshResponseList(this.queryParam)
      .pipe(take(1))
      .subscribe(responses => {
        this.isError = false;
        this.responseList = responses.body as IForms;
        this.filteredResponseList = this.responseList.forms;

        this.responseList.forms.forEach(responseList => {
          responseList.delegateLink = false;
          responseList.updateApproverLink = false;
        });
        for (let responseForm of this.responseList.forms) {
          const responseStatusTitle = responseForm.responseStatusTitle.trim().toLowerCase();
          if (["under review", "cancel", "completed", "on hold", "rejected"].indexOf(responseStatusTitle) >= 0) {
            responseForm.delegateLink = false;
          } else {
            responseForm.delegateLink = true;
          }

          if (["under review"].indexOf(responseStatusTitle) >= 0) {
            if(responseForm.editAccessWith) {
              responseForm.editAccessWith = `${responseForm.currentLevel}-${responseForm.editAccessWith}`;
            }
          }

          if (["completed", "overdue", "cancel", "on hold", "rejected"].indexOf(responseStatusTitle) >= 0) {
            responseForm.reInitiateLink = true;
          }

          if (["completed", "on hold", "rejected"].indexOf(responseStatusTitle) < 0) {
            responseForm.updateApproverLink = true;
          }
          else {
            responseForm.updateApproverLink = false;
          }

          responseForm.delegateLink = responseForm.delegateLink
            && (this.allowDelete(responseForm)
              || responseForm.assignedToName.indexOf(this.userData.userId) >= 0
              || responseForm.respondents.some((rs) => rs.assignedToId == this.userData.id));
          responseForm.updateApproverLink = responseForm.updateApproverLink && this.isallowedChangeApproverReinitiate(responseForm);
          responseForm.reInitiateLink = responseForm.reInitiateLink && this.isallowedChangeApproverReinitiate(responseForm);
          responseForm.disableReinstate = responseForm.hideReInitiate;
        }

        if (responses.headers.get('X-pagination')) {
          this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
        }
        this.masterService.resetFiltersCancelledFlag();
        $('#refreshTask').removeClass('fa-rotation');
      }, (error) => {
        this.isError = true; this.errorMsg = error;
      });
  }

  private formTemplateSelectedHandling(){
    if (this.filterForm.value['formTemplate']) {
      const foundObj = this.projectService.fetchIdForRequest(this.formTemplateSuggestion,  this.filterForm.value['formTemplate']);
      if (foundObj > 0) {
        this.formTemplatedSelected = true;
        this.filterForm.patchValue({ formId : foundObj });
      }
    }
    else {
      this.formTemplatedSelected = false;
      this.filterForm.patchValue({ formId : null });
    }
  }

  initResponseList() {
    if (!this.filterForm.valid) {
      return;
    }

    const filterValues = this.filterForm.value;
    if (filterValues['ProjectTitle'])
      this.queryParam.projectTitle = filterValues['ProjectTitle']
    if (filterValues['ProductTitle'])
      this.queryParam.productTitle = filterValues['ProductTitle']
    if (filterValues['ClientTitleWithDuns'])
      this.queryParam.clientTitleWithDuns = filterValues['ClientTitleWithDuns']
    if (filterValues['title'])
      this.queryParam.title = filterValues['title']
    if (filterValues['ResponseStatusTitle'])
      this.queryParam.responseStatusTitle = filterValues['ResponseStatusTitle'];
    if (filterValues['AssignedTo'])
      this.queryParam.assignedTo = filterValues['AssignedTo'];
    if (filterValues['OrganizationTitle'])
      this.queryParam.organizationTitle = filterValues['OrganizationTitle'];
    if (filterValues['OrganizationTitle'])
      this.queryParam.organizationTitle = filterValues['OrganizationTitle'];
    if (filterValues['package'])
      this.queryParam.package = filterValues['package'];
    if (filterValues['id'])
      this.queryParam.id = filterValues['id'];
    if (filterValues['formId'])
      this.queryParam.formId = filterValues['formId'];

    this.setOrderBy(this.sortingObject);

    this.callResponseListService();
  }

  filterList() {
    if (this.filterForm.invalid) {
      return;
    }

    this.formTemplateSelectedHandling();
    this.userFilterService.saveFilter(this.route, this.filterForm);
    this.initQueryParams();
    this.initResponseList();

    const filterValues = this.filterForm.value;
    if (filterValues['OrganizationTitle'] === "") {
      this.selectedOrgId = 0;
      this.getResponseSuggestions();
      this.getProductSuggestions(true);
      this.getProjectSuggestions();
    }
    this.filtered = true
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.userFilterService.clearFilter()
    this.callResponseListService();
    this.fdlFilterHelper.showSearchIconWhenResetFilter();
  }

  clearMessage() {
    this.deletedFormMsg = '';
    this.delegatedFormMsg = '';
    this.updateApproverMsg = '';
    this.reinitiatedFormMsg = '';
  }

  navigate(event) {
    if (event.action === "requestExtract") {
      this.router.navigate(['/self-service/requestExtracts/extractionRequest/', event.data]);
    }
    else if (event.action === "view") {
      this.router.navigate(['/response/view/responses/', event.data]);
    }
    else if (event.action === "edit") {
      this.formsService.getResponseJson(event.data).subscribe(
        data => {
          let filedSets = JSON.parse(data.formJSON)
          let fieldSets2 = filedSets.components.filter((data) => {
            //
            if (data.type == 'panel' || data.type == 'fieldset') {
              data.type = "panel"
              data.legend = data.title
              return true;
            }
          });
          filedSets.components = fieldSets2;
          let dataForm: ICreateForm = {
            "id": event.data,
            formDes: data.description,
            orgName: data.organizationTitle,
            productName: data.productTitle,
            productId: data.productId,
            templateOption: '',
            formTemplate: '',
            formName: data.title,
            initialDataJSON: data.formJSON,
            json: JSON.stringify(filedSets),
            isExist: true,
            isResponse: true,
            rowVersion: data.rowVersion,
            answerJSON: data.answerJSON,
            orgId: data.organizationId,
            projectTitle: data.projectTitle,
            clientTitle: data.clientTitle,
            assignedToName: data.assignedToName,
            fiservImplementationManagerName: data.fiservImplementationManagerName,
            currentApproverName: data.currentApproverName,
            dueDate: data.dueDate,
            responseStatusId: data.responseStatusId
          }
          this._manageForm.saveFormValues(dataForm);
          this.router.navigate(['/admin/form/template']);
        });
    }
    else if (event.action === "delegate" || event.action === "reInitiate") {
      this.mode = event.action;
      let responseDetail = this.filteredResponseList.find(response => response.id === event.data);
      this.responseData = {
        id: responseDetail.id,
        projectId: responseDetail.projectId,
        organizationId: responseDetail.organizationId,
        clientId: responseDetail.clientId,
        title: responseDetail.title,
        dueDate: responseDetail.dueDate,
        assignedToEmail: responseDetail.assignedToEmail,
        assignedToName: responseDetail.assignedToName,
        approvers: responseDetail.approvers,
        respondents: responseDetail.respondents,
        assignedToId: responseDetail.assignedToId,
        surveyId: responseDetail.surveyId,
        delegateRespondentId: this.delegateRespondentId,
        returnUrl: this.currentUrl,
        formId: responseDetail.formId
      }
      $('#delegateForms').modal('show');
    }
    else if (event.action === "hold" || event.action === "cancel") {
      this.clearMessage();
      $('#rejectedComments').modal('show');
      this.putOnHold.initHoldCommentsForm(event.action);
      let responseDetail = this.filteredResponseList.find(response => response.id === event.data);

      this.responseData = {
        id: responseDetail.id,
        projectId: responseDetail.projectId,
        organizationId: responseDetail.organizationId,
        clientId: responseDetail.clientId,
        title: responseDetail.title,
        dueDate: responseDetail.dueDate,
        assignedToEmail: responseDetail.assignedToEmail,
        assignedToName: responseDetail.assignedToName,
        approvers: responseDetail.approvers,
        respondents: responseDetail.respondents,
        assignedToId: responseDetail.assignedToId,
        surveyId: responseDetail.surveyId,

      }
      this.clearMessage();
    }
    else if (event.action === "editFormDetails") {
      this.clearMessage();
      $('#editFormDetailforms').modal('show');
      this.editformdetails.initiateForm();
      let responseDetail = this.filteredResponseList.find(response => response.id === event.data);
      this.formsService.getResponseJson(event.data).subscribe(
        data => {
        this.responseData = {
          id: responseDetail.id,
          title: responseDetail.title,
          dueDate: responseDetail.dueDate,
          rowVersion: data.rowVersion,
          organizationId: responseDetail.organizationId,
        }
      });
      this.clearMessage();
    }
    else if (event.action === "changeApprover") {
      this.deletedFormMsg = '';
      this.delegatedFormMsg = '';
      this.updateApproverMsg = '';
      this.reinitiatedFormMsg = ''
      let responseDetail = this.filteredResponseList.find(response => response.id === event.data);
      this.responseData = {
        id: responseDetail.id,
        projectId: responseDetail.projectId,
        organizationId: responseDetail.organizationId,
        clientId: responseDetail.clientId,
        title: responseDetail.title,
        approvers: responseDetail.approvers,
        approvalSchema: responseDetail.approvalSchema
      }
      $('#changeApprover').modal('show');
    }
    else if (event.action === "delete") {
      this.deletedFormMsg = '';
      this.delegatedFormMsg = '';
      this.updateApproverMsg = '';
      this.reinitiatedFormMsg = '';
      this.formsService.deleteForm(event.data).subscribe(
        data => {
          this.isError = false;
          this.callResponseListService();
          this.getResponseSuggestions();
          this.deletedFormMsg = `The form '${event.title}' has been deleted.`
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      )
    }
    else if (event.action === "viewHistory") {
      let responseDetail = this.filteredResponseList.find(response => response.id === event.data);
      let responseData = {
        historyName: responseDetail.title,
        organizationId: parseInt(responseDetail.organizationId),
        respondentName: responseDetail.assignedToName,
        approverName: responseDetail.currentApproverName,
        currentAssignee: responseDetail.editAccessWith,
        responseStatusTitle: responseDetail.responseStatusTitle,
        dueDate: responseDetail.dueDate
      };
      this.formsService.historyData = responseData;
      this.masterService.saveFilters(this.filterForm);
      this.router.navigate(['/response/history/list', event.data]);
    }
    else if (event.action === "viewProjectTimeline") {
      this.router.navigate(['/projects/viewTimeline', event.data]);
    }
    else if (event.action === "viewTimeline") {
      let responseDetail = this.filteredResponseList.find(response => response.id === event.data);
      let responseData = {
        historyName: responseDetail.title,
        organizationId: parseInt(responseDetail.organizationId),
        respondentName: responseDetail.assignedToName,
        approverName: responseDetail.currentApproverName,
        currentAssignee: responseDetail.editAccessWith,
        responseStatusTitle: responseDetail.responseStatusTitle,
        dueDate: (responseDetail.dueDate.split('-')[0] != '0001') ? responseDetail.dueDate : ""
      };
      this.formsService.historyData = responseData;
      this.masterService.saveFilters(this.filterForm);
      this.router.navigate(['/response/timeline/list', event.data]);
    }
    else if (event.action === "showProject") {
      this.router.navigate(["/projects/view", event.data]);
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callResponseListService();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callResponseListService();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  checkresponse(responseId): boolean {
    var result;
    this.workflowService.getStatusofRPAId(responseId).subscribe(
      res => {
        result = res.body;
        console.log(result);
      },
      error => { this.isError = true; this.errorMsg = error; });
    return result;
  }

  getFormStatus() {
    const resolvedData: IResponseStatusResloved = this.route.snapshot.data['resolveData'];
    this.formStatus = resolvedData.responseStatus;
  }

  ngOnDestroy(): void {
    this._manageForm.setDelegateSuccessMsg("");
    this._manageForm.approvalMsg = "";
    this._projectDto.setPublishForms(false);
    this._projectDto.setPublishRequestForms(false);
  }

  getResponseSuggestions() {
    this.projectService.getSuggestions("responses", this.selectedOrgId, "orgId").subscribe(
      data => this.responsesSuggestion = data,
      (error) => { this.isError = true; this.errorMsg = error; }
    );

    this.projectService.getSuggestions("forms", this.selectedOrgId, "orgId").subscribe(
      data => this.formTemplateSuggestion = data,
      (error) => { this.isError = true; this.errorMsg = error; }
    );

    this.projectService.getPackageSuggestions("responses", "").subscribe(
      data => this.packageSuggestion = data,
      (error) => { this.isError = true; this.errorMsg = error; }
    );
  }
  
  getClientSuggestions(){
    let suggestionSubscriber: any;
    let params = [];
    let clientTitle = this.filterForm.value.ClientTitleWithDuns;
    if(clientTitle && clientTitle.length >= 3) {
      params.push({paramName: "titleWithDuns", value: clientTitle});
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", params, 100);
    }
    else {
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", null, 100);
    }

    suggestionSubscriber.subscribe(
      data=> {
        this.clientSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  getProductSuggestions(ignoreProductTitle: boolean = false) {
    if (this.showProductFilter) {
      let suggestionSubscriber: any;
      let params = [];
      let productTitle = this.filterForm.value.ProductTitle
      if (!ignoreProductTitle && productTitle && productTitle.length > 1) {
        params.push({paramName: "title", value: productTitle});
        params.push({paramName: "orgId", value: this.selectedOrgId});
        suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
      }
      else {
        params.push({paramName: "orgId", value: this.selectedOrgId});
        suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
      }

      suggestionSubscriber.subscribe(
        data=> {
          this.productSuggestion = data;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
    }
  }

  typeaheadLoadingProducts() {
    if (this.filterForm.controls.ProductTitle.invalid) {
      return;
    }
    this.getSuggestions();
  }

  getProjectSuggestions() {
    if (this.showProjectFilter) {
      this.projectService.getSuggestions("projects", this.selectedOrgId, "orgId").subscribe(
        data => this.projectSuggestion = data,
        (error) => { this.isError = true; this.errorMsg = error; }
      );
    }
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.projectService.exportResponseList(this.queryParam).subscribe(
      res => {
        console.log(res.body);
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'Form Responses.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  private isallowedChangeApproverReinitiate(form: IForm): boolean {
    let isAllowed = false;
    const user = this.userService.getUserListData();
    if (this.permissionService.isChangeApproverReinitiateAllowed(form.organizationId)) {
      isAllowed = true;
    }
    else if (this.userService.hasChannelPartnerRole()){
      isAllowed = true;
    }
    else {
      isAllowed = form.fiservImplementationManagerId === +user.id;
    }
    return isAllowed;
  }

  updateSuccess(event) {
    this.callResponseListService();
    // $("#replaceAttachment").modal('hide');
    $("#editFormDetailforms").hide();
    $('.modal-backdrop').remove();
    $('body').addClass('modal-open');
    $("#editFormDetailforms").removeClass('show');
  }

  onOrgSelect(event: any) {
    if(event.item.id > 0)
    {
      this.selectedOrgId = event.item.id;
      this.filterForm.get('title').setValue("");
      this.filterForm.get('ProjectTitle').setValue("");
      this.filterForm.get('ProductTitle').setValue("");
      this.filterForm.get('formTemplate').setValue("");
      this.getResponseSuggestions();
      this.getProductSuggestions();
      this.getProjectSuggestions();
    }
  }
}
