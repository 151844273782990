import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IQuery } from '../shared/query';
import { IPermission, IPermissionList, IRole, IRoleList } from '../shared/role';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  // Service Flags
  private _isEditMode : boolean;
  private _isCloneMode : boolean;
  // API endpoint
  private roleAPIURL = 'roles';
  private exportRoleAPIURL = 'roles/Export';
  private permissionAPIURL = 'permissions';
  private roleId: number;
  // List of roles (Models)
  private rolesList: IRole;
  private permissionsList: IPermissionList;
  private userAllowedPermissions: any[]
  private roleTitleListAux: any[];
  private rolesListv2: IRoleList;
  // Variables aux
  private _tempData: any;
  // Access variables
  /// ... in working
  /*
    private userAllowedPermissions: any[]
    private _isRespondent: boolean = false;
    private _isClient: boolean = false;
    private _isFiserv: boolean = false;
  */
    private roleCreated: string;
    private roleUpdated: boolean;
    private isRoleCreated: boolean;

  constructor(private http: HttpClient) { }


  fetchRoleListData(): Observable<IRole> {
    return this.http.get<IRole>(this.roleAPIURL);
  }

  fetchPermissionListData() {
    return this.http.get(this.permissionAPIURL);
  }

  setPermissionListData(data: IPermissionList) {
    this.permissionsList = data;
  }

  getPermissionListData() {
    return this.permissionsList;
  }

  setRoleListData(data: IRole) {
    this.rolesList = data;
  }

  setRoleTitleListAux(data)  {
    this.roleTitleListAux = data;
  }

  getRoleTitleListAux() {
    return this.roleTitleListAux;
  }

  getRoleListData() {
    return this.rolesList;
  }

  set _newCreatedRole(value){
    this.roleCreated = value;
  }

  get _newCreatedRole(){
    return this.roleCreated;
  }

  set _isRoleCreated(value){
    this.isRoleCreated = value;
  }

  get _isRoleCreated(){
    return this.isRoleCreated;
  }

  set _roleUpdated(value){
    this.roleUpdated= value;
  }

  get _roleUpdated(){
    return this.roleUpdated;
  }

  set isEditMode(msg) {
    this._isEditMode = msg;
  }

  get isEditMode() {
    return this._isEditMode;
  }

  set isCloneMode(msg) {
    this._isCloneMode = msg;
  }

  get isCloneMode() {
    return this._isCloneMode;
  }

  getRoleList(queryParams: IQuery) {
    let params = new HttpParams();

    for (let key of Object.keys(queryParams)) {
        let value = queryParams[key];
        params = params.append(key, value);
    }
    return this.http.get(this.roleAPIURL, { params: params, observe: 'response' });
  }

  getPagedRoleList(queryParams: IQuery) {
    let params = new HttpParams();

    for (let key of Object.keys(queryParams)) {
        let value = queryParams[key];
        params = params.append(key, value);
    }
    return this.http.get(`${this.roleAPIURL}/paginationlist`, { params: params, observe: 'response' });
  }

  exportRolesList(queryParams){
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.exportRoleAPIURL, { headers: httpOptions.headers, params: params, observe: 'response', responseType: 'blob' });

  }

  createRole(data) {
    return this.http.post<IRole>(this.roleAPIURL, data);
  }

  set tempData(value) {
    this._tempData = value;
  }

  get tempData() {
    return this._tempData;
  }

  setRoleId(id:number) {
    this.roleId = id;
  }

  getRoleId() {
    return this.roleId;
  }

  getRoleById(id:number) {
    return this.http.get(`${this.roleAPIURL}/${id}`);
  }

  setPermissions(permission) {
    this.userAllowedPermissions = permission;
  }

  getPermissions() {
    return this.userAllowedPermissions;
  }

  updateRole(roleId, data) {
    console.log('data role', data);
    return this.http.put<IRole>(`${this.roleAPIURL}/${roleId}`, data);
  }

  getRefreshRoles(queryParams: IQuery) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(`${this.roleAPIURL}/paginationlist`, { headers:httpOptions.headers ,params: params, observe: 'response' });
  }

  deleteRole(roleId) {
    return this.http.delete(`${this.roleAPIURL}/${roleId}`);
  }
}
