import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { Filter } from '../shared/user.filter';
import { UserFilterService } from './user.filter.service';

@Injectable({
  providedIn: 'root'
})
export class UserFilterResolver implements Resolve<Filter[]>{

  constructor(private userFilterService: UserFilterService){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Filter[] | Observable<Filter[]> | Promise<Filter[]> {
    return this.userFilterService.getUserFilterFoRoute(state.url);
  }
}
