import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DashboardGuard } from "./dashboard.guard";
import { DashboardComponent } from "./dashboard.component";
import { DashboardProjectComponent } from "./dashboard-project/dashboard-project.component";
import { DashboardFormComponent } from "./dashboard-form/dashboard-form.component";
import { MsalGuard } from "@azure/msal-angular";
import { CanAccessProjectGuard } from './can-access-project.guard';
import { UserInitializeGuard } from '../core/user-initialize.guard';
import { DashboardMyDashboardComponent } from "./dashboard-mydashboard/dashboard-mydashboard.component";
import { AnalyticsSummaryComponent } from "./dashboard-analytics/analytics-summary.component";
import { AnalyticsFormsComponent } from './dashboard-analytics/analytics-forms.component';
import { AnalyticsProjectsComponent } from './dashboard-analytics/analytics-projects.component';
import { MasterResponseStatusResolver } from "../services/master-response-status-reslover.service";
import { UserFilterResolver } from "../services/user.filter.resolver.service";
import { DashboardReportComponent } from "./dashboard-report/dashboard-report.component";

const routes: Routes = [
  {
    path: "dashboard/report/:id",
    component: DashboardReportComponent,
    resolve: {   userFilter: UserFilterResolver},
    //canActivate: [CanAccessProjectGuard],
    // canActivate: [UserInitializeGuard],
    data: { title: 'Report' }
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    //canActivate: [MsalGuard, UserInitializeGuard],
    canActivateChild: [MsalGuard],
    children: [
      { path: "", redirectTo: "mydashboard", pathMatch: "full" },
      {
        path: "project",
        component: DashboardProjectComponent,
        canActivate: [CanAccessProjectGuard],
        data: { title: 'Dashboard' }
      },
      {
        path: "form",
        component: DashboardFormComponent,
        data: { title: 'Dashboard' },
      },
      {
        path: "mydashboard",
        component: DashboardMyDashboardComponent,
        resolve: {
          resolveData: MasterResponseStatusResolver,
          userFilter: UserFilterResolver
        },
        //canActivate: [CanAccessProjectGuard],
        canActivate: [UserInitializeGuard],
        data: { title: 'Dashboard' }
      },
      {
        path: "analyticsSummary",
        component: AnalyticsSummaryComponent,
      //  canActivate: [CanAccessProjectGuard],
        data: { title: 'Dashboard' }
      },
      {
        path: "analyticsProjects",
        component: AnalyticsProjectsComponent,
       // canActivate: [CanAccessProjectGuard],
        data: { title: 'Projects' }
      },
      {
        path: "analyticsForms",
        component: AnalyticsFormsComponent,
        //canActivate: [CanAccessProjectGuard],
        data: { title: 'Forms' }
      },
      {
        path: "report",
        component: DashboardReportComponent,
        resolve: {   userFilter: UserFilterResolver   },
        //canActivate: [CanAccessProjectGuard],
       // canActivate: [UserInitializeGuard],
        data: { title: 'Report' }
      } 
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }