<!-- Add class of "back-action-container" when having back button in place like below -->
<div class="heading-container fdl-2" >
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons">
          arrow_back
        </span>
        {{returnPage}}
      </a>
    </div>
    <h1 *ngIf="!isEditMode">Create Resource Category</h1>
    <h1 *ngIf="isEditMode">{{helpCategoryForm.get('name').value}}</h1>
  </div>

  </div>
    <div class="form-container separate-form-container">
      <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
      </div>

      <p *ngIf="!isEditMode">Please complete below required information to create a new Resource Category.</p>


      <div class="pt-2">
        <form [formGroup]="helpCategoryForm"  autocomplete="off">
          <div class="form-row  mt-3" *ngIf="(isAdmin )">
            <div class="col-md-3 mb-3 typeahead-wrapper" [ngClass]="{'has-error': (!helpCategoryForm.get('organization').valid && (helpCategoryForm.get('organization').touched  || isSubmitted)) }">
              <label for="organization" class="asterisk">Organization:</label>
              <fdl-type-ahead formControlName="organization" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
              <div class="has-error">
                <span *ngIf="!helpCategoryForm.get('organization').valid && helpCategoryForm.get('organization').errors?.required && (helpCategoryForm.get('organization').touched || isSubmitted)">
                  <i class="material-icons">warning</i> Please select an Organization.
                </span>
                <span *ngIf="!helpCategoryForm.get('organization').valid && helpCategoryForm.get('organization').errors?.pattern && (helpCategoryForm.get('organization').dirty || isSubmitted)">
                  <i class="material-icons">warning</i>Please enter a valid Organization Name
                </span>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 mb-3" [ngClass]="{'has-error': !helpCategoryForm.get('name').valid && (helpCategoryForm.get('name').touched || isSubmitted)}">
              <label for="name" class="asterisk">Category Name:</label>

              <input type="text" class="form-control" id="name" formControlName="name">
              <span *ngIf="!helpCategoryForm.get('name').valid && (helpCategoryForm.get('name').errors?.required || helpCategoryForm.get('name').errors?.validateWhiteSpaces) && (helpCategoryForm.get('name').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please enter a resource category name.
              </span>
              <span *ngIf="!helpCategoryForm.get('name').valid && helpCategoryForm.get('name').errors?.pattern && (helpCategoryForm.get('name').touched || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
              </span>
              <span *ngIf="!helpCategoryForm.get('name').valid && helpCategoryForm.get('name').errors?.maxlength && (helpCategoryForm.get('name').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.HelpCategory.Name}}, please correct it.
              </span>

            </div>

            <div class="col-md-12 mb-3" [ngClass]="{'has-error': !helpCategoryForm.get('description').valid && (helpCategoryForm.get('description').touched || isSubmitted)}">
              <label for="description" class="asterisk">Category Description:</label>
              <textarea class="form-control" id="description" formControlName="description"></textarea>
              <span *ngIf="!helpCategoryForm.get('description').valid && (helpCategoryForm.get('description').errors?.required || helpCategoryForm.get('description').errors?.validateWhiteSpaces) && (helpCategoryForm.get('description').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please enter a resource category description.
              </span>
              <span *ngIf="!helpCategoryForm.get('description').valid && helpCategoryForm.get('description').errors?.pattern && (helpCategoryForm.get('description').touched || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
              </span>
              <span *ngIf="!helpCategoryForm.get('description').valid && helpCategoryForm.get('description').errors?.maxlength && (helpCategoryForm.get('description').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.HelpCategory.Description}}, please correct it.
              </span>

            </div>
          </div>
          <div class="form-row" *ngIf="isAdmin">
            <div class="col-md-6 mb-3 checkbox">
              <input type="checkbox" class="ng-untouched ng-pristine ng-valid" value="false" id="isCommonCategory" name="isCommonCategory" formControlName="isCommonCategory" />
              <label for="isCommonCategory" class="input-label pt-1 pl-4">
                Is Common Category
              </label>
            </div>
          </div>
          <div class="fdl-2 button-container">

            <button type="button" class="btn fdl-btn btn-secondary-light " aria-label="Cancel" (click)="goBack()">Cancel</button>
            <div>
              <button type="button" class="btn fdl-btn btn-primary-orange" *ngIf="!isEditMode" (click)="onSubmit()">Submit</button>
              <button type="button" class="btn fdl-btn btn-primary-orange" *ngIf="isEditMode" (click)="update()">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
