import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IReports, IReport} from '../shared/report';
import { ISuggestions } from '../shared/suggestion';
import { Observable } from 'rxjs';
import { IFeature, ISubFeature } from "../shared/selfservice-feature";
import { tap } from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class FeaturesService {

    private featureAPIURL = 'selfservicefeature';
    private subFeatureAPIURL = 'selfservicesubfeature';
    private _tempData: string = "";
    private _isFeatureCreated: string = '';
    private _isFeatureUpdated: boolean = false;
    private _isFeatureSuccess: boolean = false;
    private _featureList: IFeature;

    constructor(private http: HttpClient) {
    }

    get tempData(){
      return this._tempData;
    }

    set tempData(msg: string){
      this._tempData = msg;
    }

    get isFeatureCreated(){
      return this._isFeatureCreated;
    }

    set isFeatureCreated(msg: string){
      this._isFeatureCreated = msg;
    }

    get isFeatureUpdated(){
      return this._isFeatureUpdated;
    }

    set isFeatureUpdated(msg: boolean){
      this._isFeatureUpdated = msg;
    }

    get isFeatureSuccess() {
      return this._isFeatureSuccess;
    }

    set isFeatureSuccess(msg: boolean){
      this._isFeatureSuccess = msg;
    }

    get featureList() {
      return this._featureList;
    }

    set featureList(msg: IFeature) {
      this._featureList = msg;
    }

    getSuggestions(entity) {
      const suggestionAPIURL = `${entity}/suggestions`;
      return this.http.get<any[]>(suggestionAPIURL);
    }

    getFeatures(queryParams){
        let params = new HttpParams();
        let httpOptions = {
          headers: new HttpHeaders({
            refresh: "true"
          })
        };

        for (let key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }
        return this.http.get<IFeature[]>(this.featureAPIURL, { headers: httpOptions.headers, params: params, observe: 'response' });
    }

    getFeaturesDetailsById(featureId: number) {
      return this.http.get<IFeature>(this.featureAPIURL + "/" + featureId);
    }

    createFeature(data){
      return this.http.post<IFeature>(this.featureAPIURL, data)
      .pipe(
        tap(features => {
          this._featureList =  features;
          this._isFeatureSuccess = true;
        })
      );
    }

    getFeatureDataById(id:number){
        return this.http.get<IFeature>(`${this.featureAPIURL}/${id}`);
    }

    updateFeature(data){
        return this.http.put<IFeature>(`${this.featureAPIURL}/${data.id}`, data);
    }

    deleteFeature(reportId: number): Observable<IFeature> {
      return this.http.delete<IFeature>(`${this.featureAPIURL}/${reportId}`);
    }
  
  getSubFeatures(featureId: number){
    return this.http.get<ISubFeature[]>(`${this.subFeatureAPIURL}/feature/${featureId}`);
  }

  getSubFeaturesWithParams(featureId: number, queryParams){

    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };

    for (let key of Object.keys(queryParams)) {
        let value = queryParams[key];
        params = params.append(key, value);
    }

    return this.http.get<ISubFeature[]>(`${this.subFeatureAPIURL}/feature/${featureId}`, { headers: httpOptions.headers, params: params, observe: 'response' });
  }

  getSubFeaturesDetailsById(subfeatureId: number) {
    return this.http.get<ISubFeature>(this.subFeatureAPIURL + "/" + subfeatureId);
  }

  createSubFeature(data){
      return this.http.post<ISubFeature>(this.subFeatureAPIURL, data);
  }

  getSubFeatureDataById(id:number){
      return this.http.get<ISubFeature>(`${this.subFeatureAPIURL}/${id}`);
  }

  updateSubFeature(data){
      return this.http.put<ISubFeature>(`${this.subFeatureAPIURL}/${data.id}`, data);
  }

  deleteSubFeature(subfeatureId: number): Observable<ISubFeature> {
    return this.http.delete<ISubFeature>(`${this.subFeatureAPIURL}/${subfeatureId}`);
  }
}
