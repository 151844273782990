<div class="modal fade bd-example-modal-md" id="changeApprover" data-backdrop="static" data-keyboard="false"
     tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-md" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Select User(s) to Change Approver</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="cancelHandler()"
                aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body" style="max-height: 612px; overflow: auto;">
        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>
        <form [formGroup]="updateApproverForm" autocomplete="off">
          <div class="row mb-3 updateApprover" style=" margin-left: 5px; ">
            <div [ngClass]="{'has-error': ( isDuplicate ) }">
              <app-mutipleadd *ngIf="ready==true" #addApprover isDisable="true" buttonText="Add Approver" [isAddButtonEnable]="true" (formReady)="formInitialized('formApprover',0 ,$event)" [isSubmitted]="isSubmitted" [isFdl]="true" [fdlData]="approverUsers2" [filedJson]="fieldformApproverJson" elementName="formApprover" [filedDetails]="filedformApproverDetails" [formControlData]="updateApproverForm"></app-mutipleadd>
              <span *ngIf="isDuplicate">
                <i class="material-icons">warning</i>The Approver can not be duplicated at the same level.
              </span>
            </div>
            <div class="form-typeahead-wrapper p-0 ml-3" style="left: 85px;top: -11px;">
              <label class="mt-2">Select Approver Criteria:<span _ngcontent-edj-c316="" data-toggle="tooltip" title="Select Approval Criteria if Multiple Approvers at same level." class="material-icons btn-secondary f-18"> info</span></label>


              <div>

                <div class="fdl-select">
                  <select class="form-control" formControlName="approverSchema"
                          name="approverSchema">
                    <option value="OR">ANY</option>
                    <option value="AND">ALL</option>

                  </select>
                </div>
              </div>
            </div>

          </div>
        </form>

      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal" (click)="cancelHandler()">
          Cancel
        </button>
        <button type="button" class=" btn fdl-btn btn-primary-orange ml-2" (click)="updateApproverHandler()">
          Change Approver
        </button>


      </div>
    </div>
  </div>
</div>
