import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { IForm } from 'src/app/shared/form';
import { take } from 'rxjs/operators';
import { IProject, IProjects } from 'src/app/shared/project';
import { IStatus, IResponseStatusResloved } from 'src/app/shared/status';
import { MasterService } from 'src/app/services/master.service';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ProjectsDTOService } from 'src/app/Projects/Services/projectsDTO.service';
import { FormsService } from "../../../services/forms.service";
import { PermissionsService } from 'src/app/services/permissions.service';
import { IRespondent, IUser } from 'src/app/shared/user';
import { UserService } from 'src/app/services/user.service';
import { ICreateForm } from '../../../admin/manageForms/models/createForm';
import { ManageFormsService } from '../../../admin/manageForms/services/manageForms.service';
import { ISubmitRequest } from '../../../shared/submit-request';
import { Subscription, forkJoin } from 'rxjs';
import { IPager } from '../../../shared/pagination';
import { DateFormatPipe } from '../../../SharedModule/Pipes/date-format.pipe';
import { ISuggestions } from '../../../shared/suggestion';
import { PERMISSIONS, ResponsesStatuses } from '../../../core/constants';
import { PutOnHoldComponent } from '../../../SharedModule/Components/put-on-hold/put-on-hold.component';
import { FavoritesService } from 'src/app/services/favorites.service';
import { EditFormDetailsComponent } from 'src/app/SharedModule/Components/edit-form-details/edit-form-details.component';
import { PreferenceService } from 'src/app/services/preference.service';
import { FileValidators } from 'src/app/self-service/components/manageFileUploads/validators/file-validators';
import { IStakeHolder } from 'src/app/shared/stakeholder';
import { LoggingService } from 'src/app/services/logging.service';
import { Location } from '@angular/common';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { AnonymousSurveyComponent } from '../anonymous-survey/anonymous-survey.component';
import { ClientsService } from 'src/app/services/clients.service';
import { EditFormsComponent } from '../../../SharedModule/Components/edit-forms/edit-forms.component';
import { environment } from 'src/environments/environment';
import { FilterPipe } from 'src/app/SharedModule/Pipes/filter.pipe';
import { UtilService } from 'src/app/services/util.service';


declare var $: any;
@Component({
  templateUrl: './view-project.component.html'
})
export class ViewProjectComponent implements OnInit, OnDestroy {
  commentFile: any;
  commentFileName: string;
  commentId: number;
  commentResponseId: number;
  isCommentError: boolean = false;
  commentErrorMsg: string = "";
  isCommentSubmit: boolean = false;
  projectCommentLength: number;
  commentLength: number;
  commentCnt: number = 0;
  showComment: boolean = true;
  id: string = '';
  formStatus: IStatus[] = [];
  assigneeList: string[] = [];
  filterForm: any;
  filterForm2: FormGroup;
  private _linkIntakeProductSubscription: Subscription;
  linkIntakeForm: FormGroup;
  copyResponse: FormGroup;
  isAdmin: boolean;
  commentValue: string;
  commentHistSelectedPgName: string;
  isCopyFormsSuccessful: boolean;
  showCommentBadge: boolean = false;
  commentBadgeCount: number = 0;
  commentHistFilterOptions: string[];
  commentHistFilterformNameOptions: string[];
  commentProjectOptions: string[];
  projectComment: boolean = false;
  formComments: boolean = false;
  organizationSuggestion: ISuggestions;
  showOrganizationFilter: boolean = false;
  package: string = '';
  orgId: number;
  isShowExternalId: boolean;
  listname: string = PreferenceService.formResponseList;
  regexValidationMessage: any;
  constructor(private route: ActivatedRoute, private projectService: ProjectsService, private permission: PermissionsService,
    private router: Router, private masterService: MasterService, private DTOService: ProjectsDTOService,
    private formsService: FormsService, private permissionService: PermissionsService,
    private userService: UserService, private _manageForm: ManageFormsService, private favoritesService: FavoritesService,
    private _datePipe: DateFormatPipe, private loggingService: LoggingService, private projectsService: ProjectsService,
    private _formService: FormsService, private _clientService: ClientsService, private readonly location: Location,
    private filterPipe: FilterPipe, private utilService: UtilService) { }
  submitFormRequest: FormGroup;
  projectId: number = -1;
  userData: IUser;
  projectData: IProject;
  oldProjectData: IProject;
  copyProjectData: IProject;
  copyProjectName: string = '';
  projectStakeholders: IStakeHolder[];
  stakeholdersForDisplay: string;
  displayResponseList: IForm[];
  showFilters: boolean = false;
  searchString: any;
  product: any;
  assignTo: any;
  ResponseStatusTitle: any;
  commentCatHistory: string = '';
  deletedFormMsg: string;
  reinitiatedFormMsg: string;
  isIntakePublished: boolean = false;
  isFavorite: boolean = false;

  @Input() formSets: any = [];
  @Input() currentIndex: number;
  commentTableHeaders = ["Form Name", "Page Name", "Category", "Comment", "Added On", "Added By"];
  commentTableKeys = ["formName", "pageName", "category", "comment", "createdOn", "createdByName"];
  commentProjectHeaders = ["Project Name", "Category", "Comment", "Added On", "Added By"];
  commentProjectKeys = ["pageName", "category", "comment", "createdOn", "createdByName"];
  commentCat: string = 'Comment';
  /*************inTake Request ***********************************************/
  filteredSubmitRequestList: ISubmitRequest[];
  pagedSubmitRequestList: ISubmitRequest[];
  selectedSubmitRequestList: ISubmitRequest[];
  filteredResponseList: IForm[];
  sortingObject;
  displayColumnsIntake = ["ID", "Form Name", "Product Name", "Client Name", "Respondent Name", 'Package', "Status"];
  keysIntake = ['id', 'title', 'productTitle', 'clientTitleWithDuns', 'assignedToName', 'package', 'responseStatusTitle'];
  selectedRadioValue: any = { "value": '' };
  selectedForm: any = [];
  isErrorRequest: boolean;
  submitRequestList: ISubmitRequest[];
  currentPageNumber: number = 1;
  sortedSubmitRequestList: ISubmitRequest[];
  productSuggestion: any = [];
  clientSuggestion: any = [];
  packageSuggestion: any = [];
  formNameSuggestion: any = [];
  pagerObject: IPager;
  clientIntake: any = '';
  commentsOnProjects: any = {};
  /*********************************************************************/
  already;
  totalAssignedForms: any = [];
  isProjectUpdated: boolean = false;
  isFormsPublished: boolean = false;
  isError: boolean;
  errorMsg;
  @ViewChild(EditFormsComponent) editFormsComponent: EditFormsComponent;
  isEditProjectAllowed: boolean = false;
  isDeleteResponseAllowed: boolean = false;
  isAssignResponsesAllowed: boolean = false;
  isEditFormDetailsAllowed: boolean = false;
  isProjectOnholdOrRejected = false;
  usersByOrganization: any;
  usersByClient: any;
  respondentUsers: IRespondent;
  userSuggestion: any;
  responseData: any = {};
  delegatedFormMsg: string = "";
  updateApproverMsg: string = "";
  intakeMessage: string = "";
  intakeFormTitle: string = "";
  projectName: string;
  isProjectCreated: boolean;
  mode: string = "";
  commentHistoryList: any;
  copyProjectId: number = 0;
  isCopyProject: boolean = false;
  commentHistSelectedformName: any;
  displayColumns = ['ID', 'Form Name', 'Form Description', 'Respondent Name', 'Approver Name', 'Last Updated User', 'Last Updated Date', 'Status'];
  keys = ['id', 'title', 'description', 'assignedToName', 'currentApproverName', 'modifiedByName', 'modifiedOn', 'responseStatusTitle'];

  displayColumnsCPResponse = ["Form Name", "Respondent", "Approver", 'Due Date'];
  keysCPResponse = ['title', 'assignedToName', 'currentApproverName', 'dueDate'];

  displayColumnsEditResponse = ["Form Name", "Client Name"];
  keysEditResponse = ['title', 'clientTitle'];

  columnsNew = [{
    prop: 'title', name: 'Form Name', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.form_response_survey_name_validator },
    width: 250
  },
  {
    prop: 'clientTitle', name: 'Client Name', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.client_name_validator },
    width: 250
  }
  ];

  isReinitiateAllowed: boolean = false;
  isChangeApproverAllowed: boolean = false;
  isViewHistoryAllowed: boolean = false;
  isViewTimelineAllowed: boolean = false;
  delinkResponse: { id: number, title: string };
  eventData: any;

  // PBI 170532 >> START
  projectSuggestion: any;
  projectList: IProjects = { projects: [] };
  showExistingProjects: Boolean = false;
  projectCopyFormsForm: FormGroup;
  formsList: any;
  formsetDetails = false;
  showEditFormsModal = false;
  // PBI 170532 >> END

  validators = REGEX_PATTERNS;
  public delegateFormId: any;
  public delegateRespondentId: any;
  public currentUrl = 'projects/view';

  @ViewChild('putOnHold') putOnHold: PutOnHoldComponent;
  @ViewChild('inputCommentFile') commentFileInput: ElementRef;
  @ViewChild('editformdetails') editformdetails: EditFormDetailsComponent;
  @ViewChild('commentsForm') commentsForm: NgForm;
  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    $(window).scrollTop(0);

    this.showOrganizationFilter = this.permission.userData.isAdmin;
    $(".noaction").click(function (e) {
      e.stopPropagation();
    });
    const resolvedData: IResponseStatusResloved = this.route.snapshot.data['resolveData'];
    this.formStatus = resolvedData.responseStatus;
    this.userData = this.userService.getUserListData();
    this.projectName = this.DTOService.getProjectResponse();
    this.isProjectCreated = this.DTOService.getCreateProjectStatus();
    this.isViewHistoryAllowed = !this.userService.isClient && !this.userService.isRespondent;
    this.isViewTimelineAllowed = !this.userService.isRespondent;
    this.isEditFormDetailsAllowed = !this.userService.isClient && !this.userService.isRespondent;
    this.isAdmin = this.permissionService.userData.isAdmin;

    this.formSets[0] = {
      'assignedFormsList': []
    }

    this.initFilterForm();
    //this.apiFormData();
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.projectId = params['id'];
        if (params['cid']) {
          this.copyProjectId = params['cid']
          this.isCopyProject = true;
          this.getCopyProjectResponsesData();
        }

        this.getDelegateUserParams(params);

        if (this.projectId > -1) {
          this.getProjectData();
        }
      });
    this.isProjectFavorites();
    // PBI 170532 >> START
    this.initCopyFormsForm();
    //this.getProjectSuggestions();
    if (this.isCopyProject) {
      $('#copyFormModal').modal('show');
    }
    // PBI 170532 >> END
  }

  private getDelegateUserParams(params: any): void {
    if (params['delegateFormId'] && params['delegateRespondentId']) {
      this.delegateFormId = +params['delegateFormId'];
      this.delegateRespondentId = +params['delegateRespondentId'];
      this.location.replaceState(`${this.currentUrl}/${this.projectId}`);
    }
  }

  initiateForms() {
    this.getCopyProjectResponsesData();
    $('#copyResponses').modal('show');

  }

  onEditFormsProgress(updatedResponses: any[]): void {
    this.formSets[0].assignedFormsList.forEach(element => {
      const matchedItem = updatedResponses.find(i => i.id === element.id);
      if (matchedItem) {
        element.approvalSchema = matchedItem.approvalSchema;
        element.dueDate = matchedItem.dueDate;
        element.description = matchedItem.description;
        element.title = matchedItem.title;
        element.respondents = matchedItem.respondents;
        element.approvers = matchedItem.approvers;
        element.assignedToName = matchedItem.assignedToName;
        element.currentApproverName = matchedItem.currentApproverName;
      }
    });
  }

  refresh() {
    this.getProjectData();
  }

  canEditProject(): boolean {

    if (this.projectData.fiservImplementationManagerId == +this.userData.id) {
      return true;
    }
    else if (this.userData.permissions.includes(PERMISSIONS.Projects_ReadWrite_All) && this.projectData.organizationId == this.userData.organization) {
      return true;
    }

    return this.userData.isAdmin;
  }
  get _ClientAddress() {
    let address = 'N/A';
    if (this.projectData && this.projectData.clientAddress) {
      let addressObject = this.projectData.clientAddress;
      address = addressObject.streetAddress1;
      if (addressObject.streetAddress2) {
        address += ', ' + addressObject.streetAddress2;
      }
      address += ', ' + addressObject.city + ', ' + addressObject.state;
      if (addressObject.zipCode) {
        address += ', ' + addressObject.zipCode;
      }
      if (addressObject.country) {
        address += ', ' + addressObject.country;
      }
    }
    return address;
  }

  takeAction(event) {
    if (event.action === "view") {
      this.router.navigate(['/response/view/responses/', event.data]);
    }
    else if (event.action === "delegate" || event.action === "reInitiate") {
      this.mode = event.action;
      let responseDetail = this.projectData.responses.find(data => data.id === event.data)

      this.responseData = {
        id: event.data,
        projectId: this.projectData.id,
        organizationId: this.projectData.organizationId,
        clientId: this.projectData.clientId,
        title: responseDetail.title,
        dueDate: responseDetail.dueDate,
        assignedToEmail: responseDetail.assignedToEmail,
        assignedToName: responseDetail.assignedToName,
        respondents: responseDetail.respondents,
        assignedToId: responseDetail.assignedToId,
        surveyId: responseDetail.surveyId,
        delegateRespondentId: this.delegateRespondentId,
        returnUrl: this.currentUrl,
        formId: responseDetail.formId,
        approvers: responseDetail.approvers,
      }
      $('#delegateForms').modal('show');
    }
    else if (event.action === "changeApprover") {
      this.clearSuccessMsg();
      let responseDetail = this.projectData.responses.find(response => response.id === event.data);
      this.responseData = {
        id: event.data,
        projectId: this.projectData.id,
        organizationId: this.projectData.organizationId,
        clientId: this.projectData.clientId,
        title: responseDetail.title,
        approvers: responseDetail.approvers,
        approvalSchema: responseDetail.approvalSchema
      }
      $('#changeApprover').modal('show');
    }
    else if (event.action === "hold" || event.action === "cancel") {
      this.clearSuccessMsg();

      let responseDetail = this.projectData.responses.find(response => response.id === event.data);
      this.responseData = {
        id: event.data,
        projectId: this.projectData.id,
        organizationId: this.projectData.organizationId,
        clientId: this.projectData.clientId,
        title: responseDetail.title,
        approvers: responseDetail.approvers
      }
      this.putOnHold.initHoldCommentsForm(event.action);
      $('#rejectedComments').modal('show');
    }
    else if (event.action === "delete") {
      this.formsService.deleteForm(event.data).subscribe(
        data => {
          this.isError = false;
          this.clearSuccessMsg();
          this.getProjectData();
          this.deletedFormMsg = `The form '${event.title}' has been deleted.`
        },
        error => { this.isError = true, this.errorMsg = error });
    }
    else if (event.action === "viewHistory") {
      let responseDetail = this.projectData.responses.find(response => response.id === event.data);
      let responseData = {
        historyName: responseDetail.title,
        organizationId: this.projectData.organizationId,
        respondentName: responseDetail.assignedToName,
        approverName: responseDetail.currentApproverName,
        currentAssignee: responseDetail.editAccessWith,
        responseStatusTitle: responseDetail.responseStatusTitle,
        dueDate: responseDetail.dueDate
      };
      this.formsService.historyData = responseData;
      this.masterService.saveFilters(this.filterForm);
      this.router.navigate(['/projects/view', this.projectId, 'responses', event.data, 'history', 'list']);
    }
    else if (event.action === "requestExtract") {
      this.router.navigate(['/self-service/requestExtracts/extractionRequest/', event.data]);
    }
    else if (event.action === "viewTimeline") {
      let responseDetail = this.projectData.responses.find(response => response.id === event.data);
      let responseData = {
        historyName: responseDetail.title,
        organizationId: this.projectData.organizationId,
        respondentName: responseDetail.assignedToName,
        approverName: responseDetail.currentApproverName,
        currentAssignee: responseDetail.editAccessWith,
        responseStatusTitle: responseDetail.responseStatusTitle,
        dueDate: responseDetail.dueDate
      };
      this.formsService.historyData = responseData;
      this.masterService.saveFilters(this.filterForm);
      this.router.navigate(['/projects/view', this.projectId, 'responses', event.data, 'history', 'timeline']);
    }
    else if (event.action === "edit") {
      this.formsService.getResponseJson(event.data).subscribe(
        data => {
          let filedSets = JSON.parse(data.formJSON)
          let fieldSets2 = filedSets.components.filter((data) => {
            //
            if (data.type == 'panel' || data.type == 'fieldset') {
              data.type = "panel"
              data.legend = data.title
              return true;
            }
          });
          filedSets.components = fieldSets2;
          let dataForm: ICreateForm = {
            "id": event.data,
            formDes: data.description,
            orgName: data.organizationTitle,
            productName: data.productTitle,
            productId: data.productId,
            templateOption: '',
            formTemplate: '',
            formName: data.title,
            initialDataJSON: data.formJSON,
            json: JSON.stringify(filedSets),
            isExist: true,
            isResponse: true,
            rowVersion: data.rowVersion,
            answerJSON: data.answerJSON,
            orgId: data.organizationId,
            projectTitle: data.projectTitle,
            clientTitle: data.clientTitle,
            assignedToName: data.assignedToName,
            fiservImplementationManagerName: data.fiservImplementationManagerName,
            currentApproverName: data.currentApproverName,
            dueDate: data.dueDate
          }
          this._manageForm.saveFormValues(dataForm);
          this.router.navigate(['/projects/view', this.projectId, 'responses', event.data, 'form', 'template']);
        });


    }
    else if (event.action === "editFormDetails") {
      $('#editFormDetailforms').modal('show');
      this.editformdetails.initiateForm();
      let responseDetail = this.projectData.responses.find(response => response.id === event.data);
      this.formsService.getResponseJson(event.data).subscribe(
        data => {

          this.responseData = {
            id: responseDetail.id,
            title: responseDetail.title,
            dueDate: responseDetail.dueDate,
            rowVersion: data.rowVersion
          }
        });

    }
    else if (event.action === "delink") {
      let responseDetail = this.projectData.responses.find(response => response.id === event.data);
      this.delinkResponse = {
        id: event.data,
        title: responseDetail.title
      };
      setTimeout(function () {
        $('#delinkModal').modal('show');
      }, 0);
    }
  }

  successDelegateClick(evt) {
    this.getProjectData();
    this.clearSuccessMsg();
    this.delegatedFormMsg = evt.delegateSuccessMsg;
    this.delegateFormId = this.delegateRespondentId = null;
  }

  successUpdateApproverClick(evt) {
    this.clearSuccessMsg();
    this.getProjectData();
    this.updateApproverMsg = evt.updateApproverSuccessMsg;
  }

  getProjectData() {
    $('.refresh-icon-link').addClass('fa-rotation');
    forkJoin([this.projectService.getProjectById(this.projectId), this.projectService.getStakeholders(this.projectId)])
      //this.projectService.getProjectById(this.projectId)
      .pipe(take(1))
      .subscribe(
        //project => {
        result => {
          const project = result[0];
          this.projectStakeholders = result[1];
          if (this.projectStakeholders.length > 0) {
            var count = this.projectStakeholders.filter(ps => ps.userId.toString() == this.userData.id).length;
            if (count > 0)
              this.isEditFormDetailsAllowed = false;
          }
          this.getStakeholderNames();
          this.commentBadgeCount = 0;
          this.projectData = project;
          this.isProjectOnholdOrRejected = this.projectData.statusTitle === ResponsesStatuses.OnHold || this.projectData.statusTitle === ResponsesStatuses.Rejected;
          this.isShowExternalId = (this.projectData.organizationTitle.toLowerCase().indexOf('gbs') >= 0);
          this.isEditProjectAllowed = this.isAdmin || this.canEditProject();
          this.isDeleteResponseAllowed = this.isAdmin || this.canEditProject();
          this.isAssignResponsesAllowed = this.isAdmin || this.canEditProject();
          this.isReinitiateAllowed = this.isAdmin || this.canEditProject() || this.userService.hasChannelPartnerRole();
          this.isChangeApproverAllowed = this.isAdmin || this.canEditProject();
          this.projectData.sameForms = project.sameForms ? "Yes" : "No";
          this.displayResponseList = Object.assign([], project.responses);
          this.displayResponseList.forEach(responseList => {
            responseList.delegateLink = false;
            responseList.updateApproverLink = false;
            responseList.clientTitleWithDuns = responseList.clientTitleWithDuns === ' [DUNS: ]' ? '' : responseList.clientTitleWithDuns;
          });
          if (project.comments.length > 0) {
            this.commentsOnProjects = project.comments;
            this.projectCommentLength = project.comments.length;
            this.showCommentBadge = true;
            this.commentBadgeCount = this.commentBadgeCount + project.comments.length;
            this.showCommentBadge = true;
            this.projectComment = true;
            let options: string[] = this.commentsOnProjects.filter(el => el.pageName != null && el.pageName.trim() != '').map(item => item.pageName);
            this.commentProjectOptions = [...new Set(options)];

          }
          else {
            this.commentsOnProjects = {};
            this.showCommentBadge = false;
            this.projectComment = false;
            this.projectCommentLength = 0;
          }
          let commentArray = []
          for (const responseForm of this.displayResponseList) {
            for (let k in responseForm.responseComments) {
              let commentHistory: any = responseForm.responseComments[k];
              commentHistory['formName'] = responseForm.title;
              commentArray.push(commentHistory);
            }

            const responseStatusTitle = responseForm.responseStatusTitle.trim().toLowerCase();
            if (["under review", "cancel", "completed", "on hold", "rejected"].indexOf(responseStatusTitle) >= 0) {
              responseForm.delegateLink = false;
            }
            else { 
              responseForm.delegateLink = true;
            }

            if (["under review"].indexOf(responseStatusTitle) >= 0) {
              if(responseForm.editAccessWith) {
                responseForm.editAccessWith = `${responseForm.currentLevel}-${responseForm.editAccessWith}`;
              }
            }

            if (["completed", "overdue", "on hold", "rejected", "cancel"].indexOf(responseStatusTitle) >= 0) {
              responseForm.reInitiateLink = true;
            }

            if (["completed", "on hold", "rejected"].indexOf(responseStatusTitle) < 0) {
              responseForm.updateApproverLink = true;
            }
            else { 
              responseForm.updateApproverLink = false; 
            }

            responseForm.delegateLink = responseForm.delegateLink &&
              (this.canEditProject()
                || responseForm.assignedToName.indexOf(this.userData.userId) >= 0
                || responseForm.respondents.some((rs) => rs.assignedToId == this.userData.id));

            responseForm.updateApproverLink = responseForm.updateApproverLink && this.isallowedChangeApproverReinitiate(responseForm);
            responseForm.reInitiateLink = responseForm.reInitiateLink && this.isallowedChangeApproverReinitiate(responseForm);
            responseForm.disableReinstate = responseForm.hideReInitiate;
          }

          this.isEditProjectAllowed = this.canEditProject();
          if (commentArray.length > 0) {
            this.commentLength = commentArray.length;
            this.commentHistoryList = commentArray;
            this.showCommentBadge = true;
            this.commentBadgeCount = this.commentBadgeCount + commentArray.length;
            this.formComments = true;
            let options: string[] = this.commentHistoryList.filter(el => el.pageName != null && el.pageName.trim() != '').map(item => item.pageName);
            this.commentHistFilterOptions = [...new Set(options)];
            let formoptions: string[] = this.commentHistoryList.filter(el => el.formName != null && el.formName.trim() != '').map(item => item.formName);
            this.commentHistFilterformNameOptions = [...new Set(formoptions)];
          }
          this.getAssignees();
          this.isProjectUpdated = this.DTOService.getEditProjectStatus();
          this.oldProjectData = this.DTOService.getOldProjectFacts();
          this.isFormsPublished = this.DTOService.getPublishForms();

          if (this.delegateFormId && this.delegateRespondentId) {
            this.takeAction({ action: 'delegate', data: this.delegateFormId });
          }

          $('.refresh-icon-link').removeClass('fa-rotation');
        },
        error => {
          this.router.navigate(["/projects/list"]);
        }
      );
  }

  getCopyProjectResponsesData() {
    $('.refresh-icon-link').addClass('fa-rotation');
    this.projectService.getProjectById(this.copyProjectId)
      .pipe(take(1))
      .subscribe(
        project => {
          this.copyProjectData = project;
          this.copyProjectName = this.copyProjectData.title;
          this.prepareDataForEditFormResponses();
          this.filteredResponseList = Object.assign([], project.responses);
          this.filteredResponseList = this.filteredResponseList.filter(item => item.isIntakeForm == false)
          console.log(this.filteredResponseList);

          $('.refresh-icon-link').removeClass('fa-rotation');
        },
        error => {
          this.router.navigate(["/projects/list"]);
        }
      );
  }

  private getStakeholderNames() {
    const stakeholderNames = [];
    this.projectStakeholders.forEach(item => stakeholderNames.push(item.userName));
    this.stakeholdersForDisplay = stakeholderNames.join(', ');
  }

  navigateToEditProject(step: number) {

    if (step === 1) {
      this.DTOService.setParentType("edit");
      if (!this.projectData || !this.projectData.id) {
        this.loggingService.logException(new Error("242002 - ProjectData is null or empty when step is 1, " + JSON.stringify(this.projectData)));
      }
      this.DTOService.setProjectFacts(this.projectData);
      this.DTOService.setProjectStakeHolders(this.projectStakeholders);
      this.router.navigate(['/projects/edit/projectinfo', this.projectId]);
    }
    else if (step === 2) {
      this.DTOService.setParentType("edit");
      this.DTOService.setProjectStakeHolders(this.projectStakeholders);
      if (!this.projectData || !this.projectData.id) {
        this.loggingService.logException(new Error("242002 - ProjectData is null or empty when step is 2, " + JSON.stringify(this.projectData)));
      }
      this.DTOService.setProjectFacts(this.projectData);
      this.DTOService.setAssignFormSection(true);
      this.router.navigate(["/projects/edit/publish-forms"]);
    }
    else if (step === 3) {
      if (this.projectData.productTitle) {
        this.linkIntakeForm.get("productText").setValue(this.projectData.productTitle);
      }

      this.getOrganizationSuggestions();
      this.apiFormData();

      this.searchString = '';
      this.assignTo = '';
      this.ResponseStatusTitle = '';
      this.formSets[0] = {
        'assignedFormsList': []
      }

      // $('#selectForms').modal('show');
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    let isAsc = this.sortingObject.isAsc;
    let direction = isAsc ? 1 : -1;
    const propertyName = this.sortingObject.key;
    this.displayResponseList = Object.assign([], this.displayResponseList).sort(function (a, b) {
      if (a[propertyName] < b[propertyName]) {
        return -1 * direction;
      }
      else if (a[propertyName] > b[propertyName]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }

  getAssignees() {
    //  let totalAlreadyAssignedForms = [...new Set(this.projectData.responses.map(data => data.id))]
    this.already = this.DTOService.totalAssignedForms([...new Set(this.projectData.responses.map(data => data))]);
    let assignees = [...new Set(this.projectData.responses.map(data => data.assignedToName))];
    this.assigneeList = assignees;
  }

  reset() {
    this.filterForm.setValue({ assignedToName: '', responseStatusTitle: '' });
    this.displayResponseList = Object.assign([], this.projectData.responses);
    this.applySorting(this.sortingObject);
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      assignedToName: new FormControl(''),
      responseStatusTitle: new FormControl('')
    });

    this.filterForm2 = new FormGroup({
      ProductTitle: new FormControl(''),
      title: new FormControl(''),
      AssignedTo: new FormControl(''),
      ResponseStatusTitle: new FormControl(''),
    });

    this.inititalLinkIntakeForm();
  }

  inititalLinkIntakeForm() {
    this.linkIntakeForm = new FormGroup({
      id: new FormControl('', [Validators.pattern(REGEX_PATTERNS.Numeric),]),
      searchString: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator),]),
      productText: new FormControl(''),
      clientIntake: new FormControl('', [Validators.pattern(REGEX_PATTERNS.client_name_validator),]),
      package: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator),]),
      assignTo: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator),]),
      ResponseStatusTitle: new FormControl(''),
    });
  }

  filterResponses() {
    let query = this.filterForm.value;
    let responseListCopy = Object.assign([], this.projectData.responses);

    var result = responseListCopy.filter(item => {
      for (let key in query) {
        if (!!query[key]) {
          if (item[key] === 'undefined' || item[key] != query[key])
            return false;
        }
      }
      return true;
    });

    this.displayResponseList = Object.assign([], result);
    if (!!this.sortingObject) {
      this.applySorting(this.sortingObject);
    }
    this.showFilters = false;
  }

  goBack() {
    this.masterService.isCancelledClicked(true);
    this.router.navigate(["/projects/list"]);
  }

  public setFormsPublishedToFalse(): void {
    this.isFormsPublished = false;
    this.DTOService.setPublishForms(false);
  }

  public clearIntakeResponseLinked(): void {
    this.isIntakePublished = false;
  }

  ngOnDestroy(): void {
    this.clearSuccessMsg();
  }

  clearDeleteFromMsg() {
    this.deletedFormMsg = '';
  }

  clearSuccessMsg() {
    this.deletedFormMsg = '';
    this.delegatedFormMsg = '';
    this.updateApproverMsg = '';
    this.reinitiatedFormMsg = '';
    this.intakeMessage = '';
    this.DTOService.setCreateProjectStatus(false);
    this.DTOService.setEditProjectStatus(false);
    this.DTOService.setPublishForms(false);
    this.clearIntakeResponseLinked();
  }

  private isallowedChangeApproverReinitiate(form: IForm): boolean {
    let isAllowed = false;
    const user = this.userService.getUserListData();
    if (this.permissionService.isChangeApproverReinitiateAllowed(this.projectData.organizationId)) {
      isAllowed = true;
    }
    else if (this.userService.hasChannelPartnerRole()) {
      isAllowed = true;
    }
    else {
      isAllowed = this.projectData.fiservImplementationManagerId === +user.id;
    }
    return isAllowed;
  }
  /**********************Intake Request *************************************/
  linkIntakeFormResponse() {
    if (this.linkIntakeForm.valid) {
      this.intakeFormTitle = '';
      if (this.formSets[0]['assignedFormsList'].length == 0) {
        this.isErrorRequest = true;
        return
      }
      this.selectedForm = [];
      for (let k = 0; k < this.formSets[0]['assignedFormsList'].length; k++) {
        this.intakeFormTitle += (this.formSets[0]['assignedFormsList'][k].title).trim();
        this.selectedForm.push(this.formSets[0]['assignedFormsList'][k].id);
        if (k != (this.formSets[0]['assignedFormsList'].length - 1))
          this.intakeFormTitle += ', ';
      }
      this.projectService.assignIntakeToProject(this.projectId, this.selectedForm).subscribe(data => {
        this.intakeMessage = `Intake Response '${this.intakeFormTitle}' has been linked to the project.`;
        this.getProjectData();
        this.selectedRadioValue = { 'value': '' }
        $('#selectForms').modal('hide');
        this.inititalLinkIntakeForm();
        this.isIntakePublished = true;
        this.formSets[0] = {
          'assignedFormsList': []
        }
      },
      (error) => {
          this.isError = true; this.errorMsg = error;
          $('#selectForms').modal('hide');
          this.clearSuccessMsg();
          this.getProjectData();
      });
      this.id = ''
      this.package = ''
      this.clientIntake = null;
    }
  }

  typeaheadLoadingClients(searchString: string = '') {
    if (this.linkIntakeForm.controls.clientIntake.valid) {
      let suggestionSubscriber: any;
      let params = [];
      let clientIntake = this.linkIntakeForm.value.clientIntake
      if (clientIntake && clientIntake.length >= 3) {
        params.push({ paramName: "title", value: clientIntake });
        suggestionSubscriber = this.projectService.getClientSuggestions("clients", params, 100);
      }
      else {
        suggestionSubscriber = this.projectService.getClientSuggestions("clients", null, 100);
      }

      suggestionSubscriber.subscribe(
        data => {
          this.clientSuggestion = data;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
    }
  }

  typeaheadLoadingPackages(searchString: string = '') {
    if (this.linkIntakeForm.controls.package.valid) {
      let suggestionSubscriber: any;
      let params = [];
      let packageName = this.linkIntakeForm.value.package
      if (packageName && packageName.length >= 3) {
        params.push({ paramName: "title", value: packageName });
        suggestionSubscriber = this.projectService.getPackageSuggestions("responses", params);
      }
      else {
        suggestionSubscriber = this.projectService.getPackageSuggestions("responses", null);
      }

      suggestionSubscriber.subscribe(
        data => {
          this.clientSuggestion = data;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
    }
  }

  apiFormData() {
    if (this.isAdmin) {
      this.orgId = this.userData.organization
    }
    else {
      this.orgId = 0;
    }

    this.formsService.getIntakeForm(this.projectId, this.orgId).subscribe(data => {
      const formsData: any = data;
      this.submitRequestList = formsData;

      this.filteredSubmitRequestList = JSON.parse(JSON.stringify(this.submitRequestList));
      this.sortedSubmitRequestList = JSON.parse(JSON.stringify(this.submitRequestList));

      for (let i in this.submitRequestList) {
        this.filteredSubmitRequestList[i].dueDate = this._datePipe.transform(this.filteredSubmitRequestList[i].dueDate);
        this.filteredSubmitRequestList[i].idString = this.filteredSubmitRequestList[i].id.toString();
        this.sortedSubmitRequestList[i].dueDate = this._datePipe.transform(this.sortedSubmitRequestList[i].dueDate);
      }

      this.initPageList();
      $('#selectForms').modal('show');
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }
  /*******************************************************************/

  filterData() {
    if (this.linkIntakeForm.valid) {
        let intakeValues = this.linkIntakeForm.value;
        this.selectedSubmitRequestList = [...this.filteredSubmitRequestList];

        if (intakeValues['id'].toString().length > 3) {
          this.selectedSubmitRequestList = this.selectedSubmitRequestList.filter(x => x.id.toString().includes(intakeValues['id']));
        }
        if (intakeValues['searchString']) {
          this.selectedSubmitRequestList = this.filterPipe.transform(this.selectedSubmitRequestList, 'title', intakeValues['searchString']);
        }
        if (intakeValues['productText']) {
          this.selectedSubmitRequestList = this.filterPipe.transform(this.selectedSubmitRequestList, 'productTitle', intakeValues['productText']);
        }
        if (intakeValues['clientIntake']) {
          this.selectedSubmitRequestList = this.filterPipe.transform(this.selectedSubmitRequestList, 'clientTitleWithDuns', intakeValues['clientIntake']);
        }
        if (intakeValues['package'].toString().length > 3) {
          this.selectedSubmitRequestList = this.filterPipe.transform(this.selectedSubmitRequestList, 'package', intakeValues['package']);
        }
        if (intakeValues['assignTo']) {
          this.selectedSubmitRequestList = this.filterPipe.transform(this.selectedSubmitRequestList, 'assignedToName', intakeValues['assignTo']);
        }
        if (intakeValues['ResponseStatusTitle']) {
          this.selectedSubmitRequestList = this.filterPipe.transform(this.selectedSubmitRequestList, 'responseStatusTitle', intakeValues['ResponseStatusTitle']);
        }

        this.pagerObject = {
          currentPage: 1,
          totalCount: this.selectedSubmitRequestList.length,
          totalPages: Math.ceil(this.selectedSubmitRequestList.length / environment.pageSize),
          pageSize: environment.pageSize
        };

        this.changePage(1);
    }

  }

  setIntakeFilterData(data: any) {
    this.submitRequestList = data as any;

    this.filteredSubmitRequestList = JSON.parse(JSON.stringify(this.submitRequestList));
    this.sortedSubmitRequestList = JSON.parse(JSON.stringify(this.submitRequestList));

    for (let i in this.submitRequestList) {
      this.filteredSubmitRequestList[i].dueDate = this._datePipe.transform(this.filteredSubmitRequestList[i].dueDate);
      this.filteredSubmitRequestList[i].idString = this.filteredSubmitRequestList[i].id.toString();
      this.sortedSubmitRequestList[i].dueDate = this._datePipe.transform(this.sortedSubmitRequestList[i].dueDate);
    }

    this.changePage(1);
  }

  initPageList(){
    this.selectedSubmitRequestList = [...this.filteredSubmitRequestList];
    this.filterSuggestions();
    const pageSize = environment.pageSize;
    const total = this.selectedSubmitRequestList.length;
    this.pagerObject = {
      currentPage: 1,
      totalCount: total,
      totalPages: Math.ceil(total / pageSize),
      pageSize: pageSize
    };
    this.pagedSubmitRequestList = this.selectedSubmitRequestList.slice(0, pageSize);
  }

  filterSuggestions() {
    this.formNameSuggestion = this.utilService.uniqueBy(this.submitRequestList.filter(x => x["title"] != "" && x["title"] != null), "title");
    this.userSuggestion =  this.utilService.uniqueBy(this.submitRequestList.filter(x => x["assignedToName"] != "" && x["assignedToName"] != null), "assignedToName");
    this.packageSuggestion = this.utilService.uniqueBy(this.submitRequestList.filter(x => x["package"] != "" && x["package"] != null), "package");
    this.productSuggestion = this.utilService.uniqueBy(this.submitRequestList.filter(x => x["productTitle"] != "" && x["productTitle"] != null), "productTitle");
    this.clientSuggestion = this.utilService.uniqueBy(this.submitRequestList.filter(x => x["clientTitleWithDuns"] != "" && x["clientTitleWithDuns"] != null), "clientTitleWithDuns");
  }


  changePage(page) {
    this.pagerObject.currentPage = page;
    this.onPageListChanging();
  }

    onPageListChanging(){
    const startIdx = this.pagerObject.pageSize * (this.pagerObject.currentPage - 1);
    const endIdx = this.pagerObject.pageSize * this.pagerObject.currentPage;
    this.pagedSubmitRequestList = this.selectedSubmitRequestList.slice(startIdx, endIdx);
  }

  clearIntakeForms() {
    this.id = '';
    this.package = '';
    this.clientIntake = null;
    this.selectedRadioValue = { 'value': '' }
    $('#selectForms').modal('hide');
    this.inititalLinkIntakeForm();
    this._linkIntakeProductSubscription.unsubscribe();
  }

  delinkIntakeResponse() {
    if (this.delinkResponse != null) {
      this.formsService.unlinkResponse(this.delinkResponse.id).subscribe(
        () => {
          this.clearSuccessMsg();
          this.deletedFormMsg = `Form '${this.delinkResponse.title}' has been de-linked from the project.`;
          this.getProjectData();
          this.resetDelinkResponse();
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
          this.resetDelinkResponse();
        });
    }
  }

  resetDelinkResponse() {
    this.delinkResponse = null;
  }

  addRemoveFavorites(event) {

    if (!this.isFavorite) {
      this.favoritesService.saveFavoriteProject(this.projectId).subscribe(data => {
        this.isFavorite = true;
        this.isProjectFavorites();
      });
    } else {
      this.favoritesService.removeFavoriteProject(this.projectId).subscribe(data => {
        this.isFavorite = false;
        this.isProjectFavorites();
      });

    }
    event.stopPropagation();
  }

  isProjectFavorites() {
    this.favoritesService.isFavoriteProjects(this.projectId).subscribe(data => {
      this.isFavorite = data;
    });
  }

  /***************************************************************/
  openAddCommentModal() {
    $('#addcomment').modal('show');
    this.commentValue = '';
    this.commentHistSelectedPgName = '';
    this.commentCat = 'Comment';
    this.commentCnt = 0;
  }

  // PBI # 169191 changes start
  getCommentClass(cnt) {
    if (cnt > 5) {
      return 'table-responsive mb-0';
    }
  }

  onCommentFileChange(event) {
    this.isCommentError = false;
    this.commentErrorMsg = "";
    const target: DataTransfer = <DataTransfer>event.target;
    this.commentFile = target.files;

    const validSize = FileValidators.ValidateFileSize(this.commentFile);
    if (!validSize || !validSize.invalidSize) {
      if (this.commentFile.length > 0) {
        this.commentFileName = this.commentFile.item(0).name;
        if (!FileValidators.ValidateFileName(this.commentFileName)) {
          this.isCommentError = true;
          this.commentErrorMsg = REGEX_VALIDATION_MESSAGE.FILENAME_VALIDATION_MESSAGE;
          this.commentFileInput.nativeElement.value = "";
          return;
        }
        let vct = FileValidators.ValidateContentType();
        if (vct(this.commentFile.item(0)).invalidType || FileValidators.ValidateExtension(this.commentFile.item(0))) {
          this.isCommentError = true;
          this.commentErrorMsg = "Invalid file type or extension";
          this.commentFileInput.nativeElement.value = "";
        }
      }
    }
    else {
      this.isCommentError = true;
      this.commentErrorMsg = "File size exceeds maximum size limit i.e. 50 MB";
      this.commentFileInput.nativeElement.value = "";
    }
  }

  downloadAttachment(event) {
    window.open(event.data, "_blank");
  }

  commentActions(event) {
    switch (event.action) {
      case "replace":
        this.commentId = event.data;
        this.commentResponseId = this.projectId;
        //$('#replaceAttachment').modal('show');
        $('#replaceAttachment').show();
        $("#replaceAttachment").addClass('show');
        $('body').addClass('modal-open');
        break;
      case "delete":
        this.eventData = event.data;
        $('#deleteWarningModal').show();
        $("#deleteWarningModal").addClass('show');
        $('body').addClass('modal-open');
        break;
      case "remove":
        this.eventData = event.data;
        $('#removeAttachmentWarningModal').show();
        $("#removeAttachmentWarningModal").addClass('show');
        $('body').addClass('modal-open');
        break;
      default:
        break;
    }
  }

  deleteComment(id) {
    this.projectService.deleteResponseComment(id, this.projectId)
      .subscribe(
        () => {
          this.getProjectData();
        },
        error => {
          this.isCommentError = true;
          this.commentErrorMsg = error;
        }
      );
  }

  removeAttachment(id) {
    let body = {
      "comment": this.commentValue,
      "pageName": (this.projectData.title || ''),
      "category": this.commentCat?.trim(),
      "projectId": this.projectId,
      "file": ""
    };

    this.projectService.updateResponseComment(body, id, this.projectId)
      .subscribe(
        () => {
          this.getProjectData();
        },
        error => {
          this.isCommentError = true;
          this.commentErrorMsg = error;
        }
      );
  }

  updateAttachmentSuccess(event) {
    this.getProjectData();
    // $("#replaceAttachment").modal('hide');
    $("#replaceAttachment").hide();
    $('.modal-backdrop').remove();
    $('body').addClass('modal-open');
    $("#replaceAttachment").removeClass('show');
  }

  cancelCommentHandle() {
    this.commentValue = '';
    this.commentFile = '';
    this.commentFileName = '';
    this.isCommentError = false;
    this.commentErrorMsg = "";
    this.isCommentSubmit = false;
    this.commentFileInput.nativeElement.value = "";
  }
  // PBI # 169191 changes end

  submitCommentHandle(pageName: any = 'No') {
    if (this.commentValue?.trim() === '') {
      this.isCommentError = true;
      this.commentErrorMsg = "Please enter the comment to save.";
      return;
    }
    if (this.commentsForm.valid) {
      this.isCommentSubmit = true;
      let uploadedFile: any;
      if (!this.commentFileName) {
        uploadedFile = "";
      }
      else {
        uploadedFile = this.commentFile.item(0);
      }
      let body = {
        "comment": this.commentValue,
        "pageName": (this.projectData.title || ''),
        "category": this.commentCat?.trim(),
        "projectId": this.projectId,
        "file": uploadedFile
      };

      this.projectService.postResponseComment(body, this.projectId).subscribe(
        (data) => {
          this.getProjectData();
          this.commentValue = '';
          this.commentFile = '';
          this.commentFileName = '';
          this.commentCat = 'Comment';
          this.isCommentError = false;
          this.commentErrorMsg = "";
          this.isCommentSubmit = false;
          this.commentFileInput.nativeElement.value = "";
          this.commentCnt = 0;
        },
        (error) => {
          this.commentValue = '';
          this.commentFile = '';
          this.commentFileName = '';
          this.isCommentError = true;
          this.commentErrorMsg = error;
          this.isCommentSubmit = false;
          this.commentFileInput.nativeElement.value = "";
          this.commentCnt = 0;
        }
      );
    }


  }
  /*****************************************************************/

  viewProjectTimeline() {
    this.router.navigate(['/projects/viewTimeline', this.projectId]);
  }

  commentCount() {
    this.commentCnt = this.commentValue.length;
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.projectService.exportProjectFormsById(this.projectId).subscribe(
      res => {
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        const nav = window.navigator as any;
        if (nav && nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'Form Responses.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }
  getOrganizationSuggestions() {
    if (this.showOrganizationFilter) {
      this.projectService.getSuggestions("organizations", "")
        .subscribe(suggestions => {
          this.organizationSuggestion = suggestions;
        },
          error => { this.isError = true; this.errorMsg = error; });
    }
  }

  getProductSuggestions() {
    this.getProductSuggestionSubscriber().subscribe(
      data => {
        this.productSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  private getProductSuggestionSubscriber() {
    let params = [];
    const productText = this.linkIntakeForm.get("productText").value;
    if (productText && productText.length > 1) {
      params.push({ paramName: "title", value: productText });
    }

    return this.projectService.getProductSuggestions("products", params, 100);
  }

  private getClientSuggestionSubscriber() {
    let params = [];
    if (this.projectData?.clientTitle != null) {
      params.push({ paramName: "title", value: this.projectData?.clientTitle });
    }
    return this.projectService.getClientSuggestions("clients", params, 100);
  }

  typeaheadLoadingProducts() {
    if (this.linkIntakeForm.controls.productText.valid) {
      this.getProductSuggestions();
    }
  }

  callProjectListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.projectsService
      .getProjectsByUserToCopyProject()
      .pipe(take(1))
      .subscribe(
        (projects) => {
          this.isError = false;
          this.projectList = projects.body as IProjects;
          this.projectList.projects = this.projectList.projects.filter(
            (project) => project.organizationId === this.userData.organization
          );
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  getProjectSuggestions() {
    this.projectsService.getProjectsByProductTitle(this.projectData.productTitle)
      .subscribe(suggestions => {
        this.projectSuggestion = suggestions.projects;
        this.projectSuggestion = this.projectSuggestion.filter(project => project.responses.length !== 0);
      },
        error => { this.isError = true; this.errorMsg = error; });
  }

  // PBI 170532 >> START
  initCopyFormsForm() {
    this.projectCopyFormsForm = new FormGroup({
      copyProjectName: new FormControl(''),
      copyProject: new FormControl('0'),
    });
  }

  showProjects() {
    if (this.projectCopyFormsForm.value.copyProject === '1') {
      this.callProjectListService();
      this.getProjectSuggestions();
      this.showExistingProjects = true;
    } else if (this.projectCopyFormsForm.value.copyProject === '0') {
      this.showExistingProjects = false;
    }
  }

  showModalCopyForms() {
    this.getCopyProjectResponsesData();
    $('#copyFormModal').modal('show');
  }

  onCopyProjectClick(event: any) {
    this.projectsService.isCopyForms = true;
    this.projectsService.selectedProjectCopyForms = event.item.id;
    this.navigateToEditProject(2);
  }

  cancelCopyForms() {
    $('#copyResponses').modal('hide');
    this.router.navigate(['/projects/view', this.projectId]);
  }

  CopyFormResponses() {
    let failesResponses = [];
    var ids = [];
    if (this.formSets[0].assignedFormsList.length == 0) {
      this.isErrorRequest = true;
      return;
    }
    else {
      this.isErrorRequest = false;
    }

    $('#copyResponses').modal('hide');
    $('#refreshTask').addClass('fa-rotation');
    let launchRegForms = [];
    let array = [];
    let hasRegForms = false;

    for (var i = 0; i < this.formSets[0].assignedFormsList.length; i++) {
      this.formSets[0].assignedFormsList[i].assignedToId = this.formSets[0].assignedFormsList[i].assignedToId == null ? 0 : this.formSets[0].assignedFormsList[i].assignedToId;
      var dueDate = this.formSets[0].assignedFormsList[i].dueDate != null && this.formSets[0].assignedFormsList[i].dueDate != '' ? this.formSets[0].assignedFormsList[i].dueDate : new Date().toISOString();
      ids.push(this.formSets[0].assignedFormsList[i].formId);
      if (this.formSets[0].assignedFormsList[i].assignedToId > 0
        || (this.formSets[0].assignedFormsList[i].respondents.length > 0
          && this.formSets[0].assignedFormsList[i].respondents[0].assignedToId > 0
          && this.formSets[0].assignedFormsList[i].respondents[0].assignedToId != undefined
          && this.formSets[0].assignedFormsList[i].respondents[0].assignedToId != null
        )) {
        launchRegForms.push({
          "respondents": this.formSets[0].assignedFormsList[i].respondents,
          "approvalSchema": this.formSets[0].assignedFormsList[i].approvalSchema == null ? "OR" : this.formSets[0].assignedFormsList[i].approvalSchema,
          "approvers": this.formSets[0].assignedFormsList[i].approvers == null ? array : this.formSets[0].assignedFormsList[i].approvers,
          "startDate": new Date().toISOString(),
          "dueDate": this._datePipe.transform(dueDate).replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T00:00:00Z",
          "formId": this.formSets[0].assignedFormsList[i].formId,
          "title": this.formSets[0].assignedFormsList[i].title,
          "description": this.formSets[0].assignedFormsList[i].description,
          "responseStatusId": 1,
          "assignedToId": this.formSets[0].assignedFormsList[i].assignedToId == 0 ? this.formSets[0].assignedFormsList[i].respondents[0].assignedToId : this.formSets[0].assignedFormsList[i].assignedToId
        })
        hasRegForms = true;
      }
      else {
        let survey = [];

        survey.push({
          "name": this.formSets[0].assignedFormsList[i].title,
          "description": this.formSets[0].assignedFormsList[i].description,
          "formId": this.formSets[0].assignedFormsList[i].formId,
          "dueDate": this._datePipe.transform(dueDate).replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T00:00:00Z",
          "recipients": this.formSets[0].assignedFormsList[i].respondents,
          "approvers": this.formSets[0].assignedFormsList[i].approvers == null ? array : this.formSets[0].assignedFormsList[i].approvers,
          "approvalSchema": this.formSets[0].assignedFormsList[i].approvalSchema
        })

        let nonuserGroupRequest = {
          "projectId": this.projectId,
          "clientId": this.projectData.clientId == null ? 0 : this.projectData.clientId,
          "organizationId": this.userService.getUserListData().organization,
          "initiator": this.userService.getUserListData() ? this.userService.getUserListData().email : -1,
          "fiservImplementationManager": this.projectData.fiservImplementationManagerEmail,
          "surveys": survey,
          "recieverOnComplete": this.userService.getUserListData() ? this.userService.getUserListData().email : -1
        }

        this.formsService.publishForms_nonuser_group(nonuserGroupRequest).subscribe(
          result => {
            console.log("Success" + result);
            this.refresh();
            this.isCopyFormsSuccessful = true;
          },
          err => {
            this.isError = true;
            this.errorMsg = err;
            this.isCopyFormsSuccessful = false;
            console.log("failed" + err);
            failesResponses.push(this.formSets[0].assignedFormsList[i].formId);
          }
        );

      }
    }
    if (hasRegForms) {
      let launchFormRequest = {
        'clientId': this.projectData.clientId == null ? 0 : this.projectData.clientId,
        'responses': launchRegForms
      }
      this.formsService.publishForms_user(launchFormRequest, this.projectId).subscribe(
        result => {
          console.log("Success" + result);
          this.refresh();
          this.isCopyFormsSuccessful = true;
        },
        err => {
          this.isError = true;
          this.errorMsg = err;
          this.isCopyFormsSuccessful = false;
          console.log("failed" + err);
          failesResponses.push(this.formSets[0].assignedFormsList[i].formId);
        }
      );

    }    
    this.updatePublishCount(ids);
    if (failesResponses.length > 0) {
      console.log(failesResponses);
    }
    else {
      this.isCopyFormsSuccessful = true;
    }
  }

  updatePublishCount(ids : any[]) {
    this._formService.updateFormPublishCount(ids).subscribe(
      () => {
        this.isError = false;

      },
      (error) => { this.isError = true; this.errorMsg = error; }
    );
  }

  isCommentValid(comment) {
    return REGEX_PATTERNS.description_comment.test(comment);
  }

  EditFormResponse() {
    this.editFormsComponent.ngOnInit();
    $('#editFormModal').modal('show');
  }

  prepareDataForEditFormResponses() {
    let formsUsersApi: any = [];
    formsUsersApi = [this._formService.getUsersByOrganizationId(this.userData.organization)];
    if (this.copyProjectData.productId && this.copyProjectData.productId !== null) {
      formsUsersApi.push(this._formService.getFormsByOrganizationId(this.userData.organization, null));
    }
    else if (this.userData) {
      formsUsersApi.push(this._formService.getFormsByOrganizationId(this.userData.organization, this.copyProjectData.clientId));
    }
    if (this.copyProjectData.clientId && this.copyProjectData.clientId !== null) {
      formsUsersApi.push(this._clientService.getClientDetailsById(this.copyProjectData.clientId, "respondents"));
    }

    let projectData = this.copyProjectData;
    let projectFormId = this.copyProjectData.responses.map(({ formId }) => formId)

    forkJoin(formsUsersApi).subscribe(data => {
      this.usersByOrganization = data[0];
      const response = data[1];
      this.usersByClient = data[2];

      const allUsers = this.usersByClient !== undefined && this.projectData?.clientId ? this.usersByOrganization.concat(this.usersByClient) : this.usersByOrganization;
      const responderApproverList = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);

      this.formsList = Object.assign({}, response);

      if (projectData.sameForms == "No") {
        this.formsList.forms = this.formsList.forms.filter(({ id }) => !projectFormId.includes(id));
      }

      /******** Setting API Data into Service to be used by Child Component ********/
      this._formService.formsUsersDetail({ forms: this.formsList, users: responderApproverList/*, emailGroup: this.emailGroup*/ });

      /********* Loading of Component for formset details *********/
      this.formsetDetails = true;
    },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  onEditForms(): void {
    this.editFormsComponent.submitForm();
  }
  // PBI 170532 >> END
}
