import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IUsersList, IUser } from 'src/app/shared/user';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ISuggestions, ISuggestion } from 'src/app/shared/suggestion';
import { ProjectsService } from 'src/app/services/projects.service';
import { UsersDTOService } from 'src/app/shared/usersDTO.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MasterService } from 'src/app/services/master.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { FdlFilterUIHepler } from 'src/app/shared/fdl-filter-ui-helper';
declare var $: any;

@Component({
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit, OnDestroy {

  constructor(private userService: UserService, private router: Router,
    private projectService: ProjectsService, private userDTO: UsersDTOService,
    private permissionService: PermissionsService, private masterService: MasterService,
    private userFilterService: UserFilterService, private route: ActivatedRoute,
    private fdlFilterHelper: FdlFilterUIHepler) { }

  filterForm: FormGroup;
  userList: IUsersList;
  queryParam: IQuery;
  currentPageNumber: number = 1;
  pagerObject: IPager = {
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  };
  sortingObject;
  isError: boolean;
  errorMsg: string = "";
  userSuggestion: ISuggestions;
  emailSuggestion: ISuggestion[];
  clientSuggestion: ISuggestions;
  roleSuggestions: ISuggestions;
  showCreateButton: boolean = false;
  successMsg: string;
  displayColumns = ['Name', 'Email', 'Login Id', 'User Type', 'Client Name'];
  keys = ['name', 'email', 'userId', 'type', 'clientTitleWithDuns'];
  UserTypes: string[] = ['Fiserv','Client'];
  isAdmin: boolean = false;
  organizationSuggestion: ISuggestions;
  listname: string = PreferenceService.userList;
  filterExists: boolean = false;
  filtered: boolean = false;
  clientText: string = '';
  isSubmitted = false;
  regexValidationMessage = REGEX_VALIDATION_MESSAGE;

  ngOnInit(): void {
    this.showCreateButton = this.permissionService.isCreateUserAllowed();
    this.isAdmin = this.permissionService.userData.isAdmin;
    this.initFilterForm();
    this.initQueryParams();
    // BUG 249095 >> START
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    //this.masterService.loadAndApplyFilters(this.queryParam, this.filterForm);
    // BUG 249095 >> END
    if (this.isAdmin) {
      this.displayColumns.push('Status');
      this.keys.push('isActive');
      this.displayColumns.push('IsAdmin');
      this.keys.push('isAdmin');
    }

    this.refresh();
    this.getSuggestions();
    this.getRoleSuggestions();
    this.successMsg = this.userDTO._isUserCreated ? `The user '${this.userDTO._newCreatedUser}' has been created` : '';
    this.getClientSuggestions();
    this.getOrganizationSuggestions();

    this.filtered = this.filterExists;
  }

  refresh() {
    this.callRefreshUserListService();
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {

    this.filterForm = new FormGroup({
      Name: new FormControl('', [Validators.pattern(REGEX_PATTERNS.user_name_validator)]),
      Type: new FormControl(''),
      Email: new FormControl('', [Validators.pattern(REGEX_PATTERNS.Email)]),
      ClientTitleWithDuns: new FormControl(''),
      OrganizationTitle: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]),
      RoleTitle: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)])
    });

  }

  getSuggestions() {
    this.projectService.getSuggestions('users', '')
      .subscribe(suggestions => {
        this.userSuggestion = suggestions;
        this.sortAndFilterEmailSuggestions(suggestions);
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  sortAndFilterEmailSuggestions(suggestions) {
    const emailsuggestion = JSON.parse(JSON.stringify(suggestions));
    this.emailSuggestion = (emailsuggestion as unknown as ISuggestion[]);
    this.emailSuggestion = this.emailSuggestion.filter((v, i, a) => a.findIndex(t => (t['email'].toLowerCase() === v['email'].toLowerCase())) === i);
    this.emailSuggestion.sort((a, b) => a['email'].toLowerCase() < b['email'].toLowerCase() ? -1 : a['email'].toLowerCase() > b['email'].toLowerCase() ? 1 : 0);
  }

  callUserListService() {

    $('#refreshTask').addClass('fa-rotation');
    this.userService.getUsersList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        users => {
          this.isError = false;
          this.userList = users.body as IUsersList;
          if (users.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(users.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  callRefreshUserListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.userService.getRefreshUsersList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        users => {
          this.isError = false;
          this.userList = users.body as IUsersList;
          if (users.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(users.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  filterList() {
    this.isSubmitted = true;
    if (this.filterForm.valid) {

      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initQueryParams();
      let filterValues = this.filterForm.value;

      if (filterValues['Name'])
        this.queryParam.name = filterValues['Name']
      if (filterValues['Type'])
        this.queryParam.type = filterValues['Type']
      if (filterValues['OrganizationTitle'])
        this.queryParam.organizationTitle = filterValues['OrganizationTitle'];
      if (filterValues['Email'])
        this.queryParam.email = filterValues['Email']
      if (filterValues['ClientTitleWithDuns'])
        this.queryParam.clientTitleWithDuns = filterValues['ClientTitleWithDuns']
      if (filterValues['RoleTitle'])
        this.queryParam.roleTitle = filterValues['RoleTitle']

      this.setOrderBy(this.sortingObject);


      this.callUserListService();

      this.filtered = true
    }
  }

  clearFilterList() {
    this.filtered = false;
    this.initQueryParams();
    this.initFilterForm();
    this.userFilterService.clearFilter();
    this.callRefreshUserListService();
    this.fdlFilterHelper.showSearchIconWhenResetFilter();
  }

  navigate(event) {
    if (event.action === "view") {
      this.masterService.saveFilters(this.filterForm);
      this.router.navigate(['/admin/user/view', event.data]);
    } else if (event.action === "edit") {
      this.navigateToUserEdit(event.data);
    }
    else if (event.action === "delete") {
      this.successMsg = '';
      this.userService.deleteUser(event.data).subscribe(
        data => {
          this.callRefreshUserListService();
          this.getSuggestions();
          this.successMsg = `The user '${event.title}' has been deleted.`
        },
        error => {
          this.isError = true; this.errorMsg = error;
        }
      )
    }
    else if (event.action === "disable") {
      this.successMsg = '';
      this.userService.enableDisableUser(event.data, "disable").subscribe(
        data => {
          this.callRefreshUserListService();
          this.getSuggestions();
          this.successMsg = `The user '${event.title}' has been disabled.`
        },
        error => {
          this.isError = true; this.errorMsg = error;
        }
      )
    }
    else if (event.action === "enable") {
      this.successMsg = '';
      this.userService.enableDisableUser(event.data.id, "enable").subscribe(
        data => {
          this.callRefreshUserListService();
          this.getSuggestions();
          this.successMsg = `The user '${event.data.name}' has been enabled.`
        },
        error => {
          this.isError = true; this.errorMsg = error;
        }
      )
    }
    else if (event.action === "clone") {
      this.navigateToUserClone(event.data);
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callUserListService();
  }

  createUser() {
    this.router.navigate(['admin/user/create']);
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callUserListService();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.userService.exportUsersList(this.queryParam).subscribe(
      res => {
        console.log(res.body);
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'Users.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  downloadFile(response) {
    let url = window.URL.createObjectURL(response);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  navigateToUserEdit(userId: number): void {
    this.userService.getUserById(userId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.userDTO._isEditMode = true;
          this.userDTO.setUserData(data as IUser);
          this.router.navigate(['/admin/user/edit']);
        }
      );
  }

  navigateToUserClone(userId: number): void {
    this.userService.getUserById(userId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.userDTO._isCloneMode = true;
          this.userDTO.setUserData(data as IUser);
          this.router.navigate(['/admin/user/clone']);
        }
      );
  }

  ngOnDestroy(): void {
    this.userDTO._isUserCreated = false;
    this.userDTO._newCreatedUser = '';
  }

  getOrganizationSuggestions() {
    if (this.isAdmin) {
      this.projectService.getSuggestions("organizations", "")
        .subscribe(suggestions => {
          this.organizationSuggestion = suggestions;
        },
          error => { this.isError = true; this.errorMsg = error; });
    }
  }

  getRoleSuggestions() {
    this.projectService.getSuggestions("roles", "").subscribe(
      data => this.roleSuggestions = data,
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  typeaheadLoadingClients() {
    this.getClientSuggestions();
  }

  getClientSuggestions() {
    let suggestionSubscriber: any;
    let params = [];
    let clientTitle = this.filterForm.value.ClientTitleWithDuns;
    if (clientTitle && clientTitle.length >= 3) {
      params.push({ paramName: "titleWithDuns", value: clientTitle });
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", params, 100);
    }
    else {
      suggestionSubscriber = this.projectService.getClientSuggestions("clients", null, 100);
    }

    suggestionSubscriber.subscribe(
      data => {
        this.clientSuggestion = data;
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }
}
