import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { ADMIN_MODULES, PERMISSIONS, allowedPermissions } from '../core/constants';
import { map } from 'rxjs/operators';
import { PermissionsService } from '../services/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class AdminModuleAccessGuard implements CanActivate {
  constructor(private userService: UserService, private permissionService: PermissionsService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const module = next.data.module;
    let user = this.userService.getUserListData();
    if (!user) {
      return this.userService.callUserListData().pipe(map((data) => {
        user = data;
        this.userService.setUserListData(data);
        this.permissionService.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm]
          }
        });

        this.userService.setPermissions(usersPermission);
        let allow = false;
        switch (module) {
          case ADMIN_MODULES.Users:
            allow = user.isAdmin
              || user.permissions.includes(PERMISSIONS.Users_ReadWrite_All)
            break;
          case ADMIN_MODULES.Roles:
            allow = user.isAdmin
            // Bug 249350 - START
            || (user.isAdmin && user.permissions.includes(PERMISSIONS.Roles_ReadWrite_All))
            || (user.isAdmin && user.permissions.includes(PERMISSIONS.Roles_Read_All))
            // Bug 249350 - END
            break;
          case ADMIN_MODULES.Clients:
            allow = user.isAdmin
              || user.permissions.includes(PERMISSIONS.Clients_ReadWrite_All)
              || user.permissions.includes(PERMISSIONS.Clients_Read_All)
              || user.permissions.includes(PERMISSIONS.Clients_Create_Only);
            break;
          case ADMIN_MODULES.EmailGroups:
            allow = user.isAdmin || user.permissions.includes(PERMISSIONS.Users_ReadWrite_All);
            break;
          case ADMIN_MODULES.FileAssets:
            allow = user.isAdmin || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All);
            break;
          case ADMIN_MODULES.Forms:
            allow = user.isAdmin || user.isRestrictedAdmin || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All);
            break;
          case ADMIN_MODULES.Intake:
            return user.isAdmin
              || user.permissions.includes(PERMISSIONS.Sales_Intake_Combo)
            break;
          case ADMIN_MODULES.Products:
            allow = user.isAdmin
            || user.permissions.includes(PERMISSIONS.Products_ReadWrite_All)
            || user.permissions.includes(PERMISSIONS.Products_Read_All);
            break;
          case ADMIN_MODULES.Help:
            allow =   user.isAdmin
              || user.permissions.includes(PERMISSIONS.HelpTopics_Create_Only)
              || user.permissions.includes(PERMISSIONS.HelpTopics_ReadWrite_All);
            break;
            case ADMIN_MODULES.Report:
            allow = user.isAdmin
            break;
          case ADMIN_MODULES.Feature:
            allow = user.isAdmin
            break;
          default:
            allow = false;
        }
        if (allow) {
          return allow;
        }
        else {
          this.router.navigate(['']);
          return false;
        }

      }));
    }
    else {
      let allow = false;
      switch (module) {
        case ADMIN_MODULES.Users:
          allow = user.isAdmin
            || user.permissions.includes(PERMISSIONS.Users_ReadWrite_All)
          break;
        case ADMIN_MODULES.Roles:
          allow = user.isAdmin
            || user.permissions.includes(PERMISSIONS.Roles_ReadWrite_All)
            || user.permissions.includes(PERMISSIONS.Roles_Read_All)
          break;
        case ADMIN_MODULES.Clients:
          allow = user.isAdmin
            || user.permissions.includes(PERMISSIONS.Clients_ReadWrite_All)
            || user.permissions.includes(PERMISSIONS.Clients_Read_All)
            || user.permissions.includes(PERMISSIONS.Clients_Create_Only);
          break;
        case ADMIN_MODULES.EmailGroups:
          allow = user.isAdmin || user.permissions.includes(PERMISSIONS.Users_ReadWrite_All);
          break;
        case ADMIN_MODULES.FileAssets:
          allow = user.isAdmin || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All);
          break;
        case ADMIN_MODULES.Forms:
          allow = user.isAdmin || user.isRestrictedAdmin || user.permissions.includes(PERMISSIONS.Forms_ReadWrite_All);
          break;
        case ADMIN_MODULES.Products:
          allow = user.isAdmin
          || user.permissions.includes(PERMISSIONS.Products_ReadWrite_All)
          || user.permissions.includes(PERMISSIONS.Products_Read_All);
          break;
        case ADMIN_MODULES.Intake:
            return user.isAdmin
            || user.permissions.includes(PERMISSIONS.Sales_Intake_Combo)
          break;
        case ADMIN_MODULES.Help:
          allow = user.isAdmin
            || user.permissions.includes(PERMISSIONS.HelpTopics_Create_Only)
            || user.permissions.includes(PERMISSIONS.HelpTopics_ReadWrite_All);
          break;
          case ADMIN_MODULES.Report:
            allow = user.isAdmin
            break;
          case ADMIN_MODULES.Feature:
            allow = user.isAdmin
            break;
        default:
          allow = false;
      }
      if (allow) {
        return allow;
      }
      else {
        this.router.navigate(['']);
        return false;
      }
    }
  }

}
