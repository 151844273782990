
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ProjectsService } from 'src/app/services/projects.service';
import { FormsService } from '../../../services/forms.service';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';

declare var $: any;

@Component({
  selector: 'app-edit-form-details',
  templateUrl: './edit-form-details.component.html'
})
export class EditFormDetailsComponent implements OnInit {

  @Input() data: any;
  @Output() successUpdate = new EventEmitter
  todaysdate: string;
  editFormDetails: FormGroup;
  isSubmitted: boolean;
  isError: boolean;
  maxLength: any;
  errorMsg: any;
  regexValidationMessage: any;
  constructor( private projectsService: ProjectsService,
    private formsService: FormsService) { }

  ngOnChanges() {

  if(this.data && this.editFormDetails){
    this.editFormDetails.controls['dueDate'].setValue(this.getDueDate());
    this.editFormDetails.controls['formName'].setValue(this.data.title);
  }
}
  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.todaysdate = this.projectsService.getTodaysDate();
    this.maxLength = MAX_LENGTHS;
    this.initiateForm();
  }

  getDueDate(){
    const todaysdate = this.projectsService.getTodaysDate();
    let result = todaysdate;
    if(this.data && this.data.dueDate){
      const duedate = this.data.dueDate.split('T')[0];
      if (new Date(duedate).getTime() > new Date(todaysdate).getTime())
        result = duedate;
    }
    return result;
  }

  initiateForm() {
    this.editFormDetails = new FormGroup({
      'formName': new FormControl('', [Validators.required, Validators.maxLength(this.maxLength.Form.Name), NoWhitespaceValidator.validateWhiteSpaces, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      'dueDate': new FormControl(this.getDueDate())
    });
  }

  handleCancelForm() {
    $('#editeFormDetails').modal('hide');
    this.editFormDetails.patchValue({ 'dueDate': this.getDueDate() });
  }

  clickSaveForm() {
    this.isSubmitted = true;
    if(this.editFormDetails.invalid) {
      return;
    }
    this.callUpdate();
  }

  private callUpdate(){
    this.isSubmitted = false;
    const body = {
      "id": this.data.id,
      "title": this.editFormDetails.get('formName').value,
      "dueDate": this.editFormDetails.get('dueDate').value,
      "rowVersion": this.data.rowVersion
    }
    this.formsService.updateResponseDetails(body, this.data.id).subscribe(
      () => {
        this.isError = false;
        const successMessage = `The form '${this.data.title}' details has been updated`;
        this.successUpdate.emit({ updateApproverSuccessMsg: successMessage });
        $('#editFormDetailforms').modal('hide');
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }
}
