import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MasterService } from './master.service';
import { IMasterList, IStatus, IProjectStatusResloved } from '../shared/status';


@Injectable({
    providedIn: 'root'
})
export class MasterProjectStatusResolver implements Resolve<IProjectStatusResloved> {

    constructor(private masterService: MasterService) { }

    resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<IProjectStatusResloved> {

        const masterStatusData = this.masterService.getProjectStatusList();
        if (masterStatusData.length > 0) {
            return of({ projectStatus: masterStatusData });
        }

        return this.masterService.callMasterListData()
            .pipe(
                map((data) => {
                    this.masterService.setMasterListData(data);
                    return ({ projectStatus: this.masterService.getProjectStatusList() });
                }
                ));
    }

}