import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FileUploadHeader } from './constants';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor{

    endPoint = environment.msalbc2Config.webApiEndpoint;

    intercept(request:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>>{

        if (request.headers.has(FileUploadHeader)) {
            const headers = request.headers.delete(FileUploadHeader);
            return next.handle(request.clone({ headers , url:this.endPoint+request.url}));
        }
        else  if (request.url.indexOf('prod-25.westus.logic.azure.com') !== -1) {
            let url = request.url;
            request = request.clone({
                setHeaders:{
                    'Content-Type': 'application/json'
                    },
                url:url
            });
        }
        else {
          let url = request.url;
          if (request.url.indexOf('assets/configuration') < 0) {
            url = this.endPoint + url;

          } else {
           
          }
            request = request.clone({
                setHeaders:{
                    'Content-Type': 'application/json'
                    },
                url:url
            });
        }
        return next.handle(request).pipe(
            catchError(error => {
                throw error;
            })
        );

    }
}
