import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/internal/operators/take';
import * as $ from 'jquery';
import { ICreateForm } from 'src/app/admin/manageForms/models/createForm';
import { ManageFormsService } from 'src/app/admin/manageForms/services/manageForms.service';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { IPageTemplate, IPageTemplateResponse, IPageTemplateResponseList } from 'src/app/shared/page-template';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { environment } from 'src/environments/environment';
import { ClientCommitmentDTO } from '../models/client-commitment-d-t-o';
import { UserService } from 'src/app/services/user.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-commitments-intake-list',
  templateUrl: './commitments-intake-list.component.html',
  styleUrls: ['./commitments-intake-list.component.css'],
})
export class CommitmentsIntakeListComponent implements OnInit, OnChanges {
  @Input() data: Array<ClientCommitmentDTO> = [];
  @Input() pageTemplate: IPageTemplate;
  @Input() public queryParam: IQuery;
  @Input() public pagerObject: IPager;
  @Input() public customFormFieldListJson: any;
  @Input() listFormFieldJson: string;
  showUpdateCommitmentsIntakeDrawer = false;

  @Output() private search = new EventEmitter<IQuery>();
  @Input() public pageTemplateNameTitle = '';

  getFulfillmentSuggestionsClicked = false;
  getOpportunitySuggestionsClicked = false;
  originCommitments: Array<any> = [];
  commimentsTobeUpdated: Array<ClientCommitmentDTO> = [];
  commimentsTobeUntracked: Array<ClientCommitmentDTO> = [];

  showChildElement = false;
  currentIndex: number;
  enableSaveButton: boolean = false;
  statusClass: string;

  private zindexCounter = 0;

  public responseId = 0;
  public projectId = '';
  public fulfillmentTitle = '';
  public isCalledForMap = true;
  public idForDelete = 0;
  public showCopyModal = false;
  public showMapFulfilmentModal = false;
  public showDelegateModal = false;
  public canMapOrUnmap = false;
  selectedForCopy: ClientCommitmentDTO;
  errorMsg = '';
  successMsg = '';

  riskReasons = [
    {
      key: '',
      text: '',
    },
  ];

  userData: any;

  constructor(
    private router: Router,
    private manageForm: ManageFormsService,
    private userService: UserService,
    private excelService: ExportToExcelService,
    private clientCommitmentsService: ClientCommitmentsService,
    private pageTemplateService: PageTemplateService,
    private permission: PermissionsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.commimentsTobeUpdated = [];
      this.commimentsTobeUntracked = [];
      this.originCommitments = [];
      this.enableSaveButton = false;
      changes.data.currentValue.forEach((element) => {
        const item = {
          id: element.id,
          isRisk: element.isRisk,
          isLinked: element.isLinked,
          riskReason: element.riskReason,
          isTracked: element.customeFormFieldListJsonAnswer?.isTracked
        };
        this.originCommitments.push(item);
      });
    }

    if (
      changes.customFormFieldListJson &&
      changes.customFormFieldListJson.currentValue
    ) {
      const riskReason = changes.customFormFieldListJson.currentValue.find(
        (el) => el.formfieldapikey === 'riskReason'
      );
      if (riskReason && riskReason.value) {
        riskReason.value.forEach((element) => {
          this.riskReasons.push({ key: element.value, text: element.value });
        });
      }
    }

    $('#refreshTask').addClass('fa-rotation');
  }

  ngOnInit(): void {
    this.listFormFieldJson = '';
    this.userData = this.userService.getUserListData();
    const roles = [
      'Division Leader',
      'BU Leader',
      'Delivery Owner',
      'Delivery Leader',
      'CCT Admin- Read Only',
    ];
    this.canMapOrUnmap = !this.userData.roles.some((r) => roles.includes(r.roleTitle));
    $('#refreshTask').addClass('fa-rotation');
  }

  onFieldChanged(event: any, row: any, fieldName: string) {
    this.errorMsg = "";
    switch (fieldName) {
      case 'isTracked':
        this.onTrackChecked(row, fieldName);
        break;
      case 'isRisk':
        this.onRiskChecked(row, fieldName);
        break;
      case 'riskReason':
        this.riskReasonChanged(event, row, fieldName);
        break;
      default:
        break;
    }
    this.trackingCommitmentToUpdate(row);
    this.validateDependentFields(row);
  }

  onTrackChecked(row: any, fieldName: string) {
    row.customeFormFieldListJsonAnswer[fieldName] = !row.customeFormFieldListJsonAnswer[fieldName];
  }

  onRiskChecked(row: any, fieldName: string) {
    row.customeFormFieldListJsonAnswer[fieldName] = !row.customeFormFieldListJsonAnswer[fieldName];
    this.updateDependentField(row, fieldName, 'riskReason');
    if (!row.customeFormFieldListJsonAnswer[fieldName] || row.customeFormFieldListJsonAnswer[fieldName] === 'false') {
      row.riskReason = '';
    }
  }

  riskReasonChanged(event, row, fieldName: string): any {
    row.customeFormFieldListJsonAnswer[fieldName] = event.target.value
  }

  trackingCommitmentToUpdate(row): void {
    const origin = this.originCommitments.find(o => o.id === row.id);
    if (!origin || origin.id !== row.id) {
      return;
    }

    this.commimentsTobeUpdated = this.commimentsTobeUpdated.filter(
      (el) => el.id !== row.id
    );
    this.commimentsTobeUntracked = this.commimentsTobeUntracked.filter(
      (el) => el.id !== row.id
    );

    // Untrack
    let hasChanged = origin.isTracked && !row.selected;
    if (hasChanged) {
      row.statusA = 'untrack';
      this.commimentsTobeUntracked.push({ ...row });
    }
    else {
      const isTrackChanged = origin.isTracked !== row.selected;
      const riskSelected = !origin.isRisk && row.isRisk && row.riskReason && row.riskReason.length > 0;
      const riskUnselected = origin.isRisk && !row.isRisk;
      const riskReasonChanged = origin.isRisk && row.isRisk && row.riskReason !== origin.riskReason;
      hasChanged = isTrackChanged || riskSelected || riskUnselected || riskReasonChanged;
      if (hasChanged) {
        row.statusA = 'update';
        this.commimentsTobeUpdated.push({ ...row });
      }
    }

    this.enableSaveButton = this.commimentsTobeUpdated.length > 0 || this.commimentsTobeUntracked.length > 0;
  }

  checkDependentFieldHasError(row: any, parentFieldKey: string, childFieldKey: string) {
    const { customFormFieldListJson } = this;
    if (customFormFieldListJson.length === 0) {
      return false;
    }

    const dependentChildField = customFormFieldListJson.find(e => e.formfieldapikey === childFieldKey);
    if (!dependentChildField?.dependentParentField) {
      return false;
    }

    const formFieldListJsonAnswer = row.customeFormFieldListJsonAnswer;
    if (!formFieldListJsonAnswer[parentFieldKey] && formFieldListJsonAnswer[parentFieldKey] !== 'true') {
      return false;
    }

    const childFieldIndex = customFormFieldListJson.findIndex((e) => e.formfieldapikey === childFieldKey);
    return !formFieldListJsonAnswer[childFieldKey] && customFormFieldListJson[childFieldIndex].isMandatory == true;
  }

  private validateDependentFields(row: any) {
    const { customFormFieldListJson } = this;
    if (customFormFieldListJson.length === 0) {
      return;
    }

    for (const parentField of customFormFieldListJson) {
      const parentFieldKey = parentField['formfieldapikey'];
      if (!parentFieldKey) {
        continue;
      }

      const formFieldListJsonAnswer = row.customeFormFieldListJsonAnswer;
      if (!formFieldListJsonAnswer[parentFieldKey] && formFieldListJsonAnswer[parentFieldKey] !== 'true') {
        continue;
      }

      const childFieldKey = parentField.dependentChildField;
      if (!childFieldKey) {
        continue;
      }

      const dependentChildField = customFormFieldListJson.find(e => e.formfieldapikey === childFieldKey);
      const isDependentFieldAnswered = formFieldListJsonAnswer[dependentChildField.formfieldapikey];
      if (!dependentChildField.isMandatory || isDependentFieldAnswered) {
        continue;
      }

      // Handle the case where the dependent field is a "other" field.
      let isOtherFieldAnswered;
      if (dependentChildField.showOtherOptionField && formFieldListJsonAnswer[dependentChildField.formfieldapikey] === 'other') {
        isOtherFieldAnswered = formFieldListJsonAnswer[dependentChildField.otherOptionFieldApiKey];
      }

      if (!isOtherFieldAnswered) {
        this.errorMsg = 'Please provide all required highlighted fields.';
        this.showUpdateCommitmentsIntakeDrawer = false;
        $('.container-page').removeClass('overflow-hidden');
      }
    }
  }

  updateDependentField(row, parentField: string = '', childField: string = '') {
    if (!parentField || !childField) {
      return;
    }

    if (!row.customeFormFieldListJsonAnswer[parentField] || row.customeFormFieldListJsonAnswer[parentField] === 'false') {
      row.customeFormFieldListJsonAnswer[childField] = '';
    }
  }

  refresh() {
    $('#refreshTask').addClass('fa-rotation');
    this.callSearch();
  }

  transformDataSourceString(text: string) {
    if (!text) return '';

    if (text === 'ecrm') {
      return 'eCRM';
    } else {
      return text.slice(0, 1).toUpperCase() + text.slice(1);
    }
  }

  getOpportunitySuggestions(item: any): any {
    this.clientCommitmentsService.getMappingSuggestions(item.id, {
      commitmentDataSource: "ecrm"
    }).subscribe(
      (res: any) => {
        this.data.forEach(element => {
          if (element.id === item.id) {
            element.opportunitySuggestions = res.body;
          }
        });

      },
      (err) => {
        console.log(err);
      }
    );

    this.getFulfillmentSuggestionsClicked = false;
    this.getOpportunitySuggestionsClicked = true;
    this.currentIndex = item.id;
  }

  getFulfillmentSuggestions(item: any): any {
    this.clientCommitmentsService.getMappingSuggestions(item.id, {
      commitmentDataSource: "clarity"
    }).subscribe(
      (res: any) => {
        this.data.forEach(element => {
          if (element.id === item.id) {
            element.fulfillmentSuggestions = res.body;
          }
        });

      },
      (err) => {
        console.log(err);
      }
    );
    this.getFulfillmentSuggestionsClicked = true;
    this.getOpportunitySuggestionsClicked = false;
    this.currentIndex = item.id;
  }

  removeSuggestions(item: any): any {
    // Just remove on UI
    this.currentIndex = -1;
  }

  getClarityProjectDetail(id: string): any { }

  getStatusCSSClass(val: any) {
    if (val == 'Overdue' || val == 'Failed') {
      this.statusClass = 'badge-table-status red';
    } else if (val == 'On Hold' || val == 'Delayed') {
      this.statusClass = 'badge-table-status hold';
    } else if (val == 'Cancel') {
      this.statusClass = 'badge-table-status gray';
    } else if (val == 'Rejected') {
      this.statusClass = 'badge-table-status rejected';
    } else if (val == 'Completed' || val == 'Success') {
      this.statusClass = 'badge-table-status green';
    } else if (val == 'Under Review' || val == 'Testing') {
      this.statusClass = 'badge-table-status keppel';
    } else if (val == 'In Progress' || val == 'InProgress') {
      this.statusClass = 'badge-table-status yellow';
    } else if (val == 'Not Started' || val == 'NotStarted' || val == 'Active') {
      this.statusClass = 'badge-table-status blue';
    }

    return this.statusClass;
  }

  onSave(): void {
    if (this.data.some((item) => item.isRisk && item.riskReason.length === 0)) {
      return;
    }
    this.showUpdateCommitmentsIntakeDrawer = true;
    $('.container-page').addClass('overflow-hidden');
  }

  public openMapFulfillment(
    responseId: number,
    fulfillmentTitle: string,
    isCalledForMap: boolean,
    projectId: string,
    canMapOrUnmap: boolean): void {
    this.successMsg = '';
    this.canMapOrUnmap = canMapOrUnmap;
    this.projectId = projectId;
    this.responseId = responseId;
    this.isCalledForMap = isCalledForMap;
    this.fulfillmentTitle = fulfillmentTitle;
    this.showMapFulfilmentModal = true;
    $('#containerPage').addClass('overflow-hidden');
  }

  public closeMapFulfilmentDrawer(): void {
    $('#containerPage').removeClass('overflow-hidden');
    this.showMapFulfilmentModal = false;
  }

  public sort(event: any): void {
    if (event) {
      this.queryParam.orderBy = event.isAsc ? event.key : event.key + ' desc';
      this.callSearch();
    }
  }

  public changePage(page: number): void {
    this.queryParam.pageNumber = page;
    this.callSearch();
  }

  public onCopyClicked(response: ClientCommitmentDTO): void {
    this.selectedForCopy = response;
    this.showCopyModal = true;
  }

  private callSearch(): void {
    this.search.emit(this.queryParam);
  }

  public onComponentSubmited(): void {
    this.showCopyModal = false;
    this.queryParam.pageNumber = 1;
    this.callSearch();
  }

  public onMapFulfilmentComponentSubmited(): void {
    $('#containerPage').removeClass('overflow-hidden');
    this.showMapFulfilmentModal = false;
    if (this.isCalledForMap) {
      this.successMsg = 'Fulfillment is mapped successfully';
    } else {
      this.successMsg = 'Fulfillment is unmapped successfully';
    }
    this.callSearch();
  }

  public onViewClicked(pageResponseId: number): void {
    const pageResponse = this.data.find((r) => r.id === pageResponseId);
    const responseDetails: any = {
      clientId: pageResponse.clientId,
      clientTitle: pageResponse.clientTitle,
      organizationId: pageResponse.organizationId,
      organizationTitle: pageResponse.organizationTitle,
      productId: pageResponse.productId,
      productTitle: pageResponse.productTitle,
    };
    this.pageTemplateService.formVal = responseDetails;
    this.router.navigate([
      `/self-service/pageTemplate/${environment.clientCommitmentPageTemplateId}/response`,
      pageResponseId,
    ]);
  }

  public onEditClicked(pageResponseId: number): void {
    this.pageTemplateService
      .getPageTemplateResponseById(pageResponseId)
      .subscribe((response) => {
        const data = response as IPageTemplateResponse;
        const filedSets = JSON.parse(data.formJSON);
        const fieldSets2 = filedSets.components.filter((data) => {
          if (data.type == 'panel' || data.type == 'fieldset') {
            data.type = 'panel';
            data.legend = data.title;
            return true;
          }
        });
        filedSets.components = fieldSets2;
        const dataForm: ICreateForm = {
          id: pageResponseId,
          formDes: data.description,
          templateOption: '',
          formTemplate: '',
          formName: data.title,
          initialDataJSON: data.formJSON,
          json: JSON.stringify(filedSets),
          isExist: true,
          isResponse: true,
          rowVersion: data.rowVersion,
          answerJSON: data.answerJSON,
          assignedToName: data.currentAssignedTo,
          currentApproverName: data.currentApproverName,
          dueDate: data.dueDate,
          responseStatusId: data.pageTemplateResponseStatusId,
          type: 'page',
          pageTemplateId: data.pageTemplateId,
        };
        this.manageForm.saveFormValues(dataForm);
        this.router.navigate(['/admin/form/template']);
      });
  }

  public exportExcel(): void {
    const exportResponses = [];
    this.clientCommitmentsService.exportPageTemplateResponseList(environment.clientCommitmentPageTemplateId, this.queryParam)
      .pipe(take(1))
      .subscribe(
        responses => {
          const exportResponseList = responses.body as IPageTemplateResponseList;
          const len = Object.keys(exportResponseList).length;
          for (let i = 0; i < len; i++) {
            const exportResponse = exportResponseList[i];
            exportResponses[i] = [];
            exportResponses[i]['Id'] = exportResponse['id'];
            exportResponses[i]['Page Template Id'] = exportResponse['pageTemplateId'];
            exportResponses[i]['Title'] = exportResponse['title'];
            exportResponses[i]['Description'] = exportResponse['description'];

            const answerJSON = JSON.parse(exportResponse.answerJSON);
            if (answerJSON && this.customFormFieldListJson.length > 0) {
              for (let item of this.customFormFieldListJson) {
                if (item['formfieldtype'] === 'select' && item['showOtherOptionField'] === true && answerJSON[item['formfieldapikey']] === 'other') {
                  exportResponses[i][item['formfieldlabel']] = `${answerJSON[item['formfieldapikey']]} - ${answerJSON[item['otherOptionFieldApiKey']]}`;
                } else {
                  exportResponses[i][item['formfieldlabel']] = typeof (answerJSON[item['formfieldapikey']]) === 'object' ? answerJSON[item['formfieldapikey']].join(",") : answerJSON[item['formfieldapikey']]
                }
              }
            }

            exportResponses[i]['Current Assignee'] = exportResponse['editAccessWith'];
            exportResponses[i]['Current Approver Name'] = exportResponse['currentApproverName'];
            exportResponses[i]['Approval Schema'] = exportResponse['approvalSchema'];
            // exportResponses[i]['Status'] = exportResponse['pageTemplateResponseStatusTitle'];
            exportResponses[i]['Client Title'] = exportResponse['clientTitle'];
            exportResponses[i]['Product Title'] = exportResponse['productTitle'];
            exportResponses[i]['Organization Title'] = exportResponse['organizationTitle'];
            exportResponses[i]['Start Date'] = exportResponse['startDate'];
            exportResponses[i]['Due Date'] = exportResponse['dueDate'];
            exportResponses[i]['Submitted By'] = exportResponse['submittedByName'];
            exportResponses[i]['Submitted On'] = exportResponse['createdOn'];
            exportResponses[i]['Modified By'] = exportResponse['modifiedByName'];
            exportResponses[i]['Modified On'] = exportResponse['modifiedOn'];
          }

          this.excelService.exportAsExcelFile(exportResponses, `${this.pageTemplateNameTitle}_responses`);
        },
        (error) => { this.errorMsg = error; });
  }

  public onDeleteModalClicked(id: number): void {
    this.idForDelete = id;
  }

  public openDelegateModal(pageResponseId: number) {
    this.responseId = pageResponseId;
    this.showDelegateModal = true;
  }

  successDelegateClick(event: any) {
    if (event.success) {
      this.successMsg = event.msg;
    } else {
      this.errorMsg = event.msg;
    }
    this.refresh();
  }

  canDelegate(item: ClientCommitmentDTO): boolean {
    const validRole = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id)
      || this.permission.userData.isAdmin
      || (this.userData.isRestrictedAdmin && this.userData.roles.some(r => r.roleId === 10));

    const validStatus = (validRole || this.userData.id == item.submittedById)
      && (item.pageTemplateResponseStatusId == 1 || item.pageTemplateResponseStatusId == 2)
      && !item.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse
      && item.accessLevel === 'Write';

    return validStatus;
  }

  submitRequest() {
    this.successMsg = '';
    const updatedList = [
      ...this.commimentsTobeUpdated,
      ...this.commimentsTobeUntracked,
    ];
    const data = JSON.parse(JSON.stringify(updatedList));
    data.forEach((item) => {
      item['requestType'] = 'update';
      item.answerJSON = JSON.stringify(item.customeFormFieldListJsonAnswer);
      delete item.customeFormFieldListJsonAnswer;
      delete item.mappedOpportunities;
      delete item.mappedFulfillments;
    });
    this.clientCommitmentsService
      .createMultiplePageResponses(data)
      .subscribe(
        () => {
          this.successMsg = 'Selected Response(s) is updated successfully.';

          this.closeUpdateCommitmentModal();
          this.queryParam.pageNumber = 1;
          $('#refreshTask').addClass('fa-rotation');
          this.callSearch();
        },
        (error) => {
          this.errorMsg = error;
          this.closeUpdateCommitmentModal();
        }
      );

    $('.container-page').removeClass('overflow-hidden');
  }

  closeUpdateCommitmentModal() {
    this.showUpdateCommitmentsIntakeDrawer = false;
    $('.container-page').removeClass('overflow-hidden');
  }

  public addZIndex(i: number): void {
    document.getElementById('actions-' + i).style.zIndex = (++this.zindexCounter).toString();
  }
}
