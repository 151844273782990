<main class="dashboard-container">
  <!-- below class (analytics-container-with-sidebar) will be use if sidebar persist -->
  <div>
    <section class="top-container">
      <article class="left-container">
        <!-- <h1>Welcome {{userDetails.name}},</h1> -->
        <p>Last login on <span>{{LastLoginTimeStamp}}</span></p>
      </article>
      <article class="right-container">
        <div class="icon-container">
          <!-- <a href="#" class="action-link"><span class="material-icons">settings</span></a> -->
        </div>
      </article>
    </section>
    <section class="dashboard-content-area">
      <article class="dashboard-tab-container">
        <ul role="tablist" class="nav nav-tabs scoped-tabs">
          <li class="nav-item">
            <a id="tab-1" role="tab" aria-selected="true" routerLink="/dashboard/mydashboard" routerLinkActive="active" data-toggle="tab"
               class="nav-link "> My Dashboard </a>
          </li>
          <li class="nav-item" *ngIf="!isRespondent">
            <a id="tab-2" role="tab" routerLink="/dashboard/analyticsSummary" routerLinkActive="active" aria-selected="false" data-toggle="tab"
            class="nav-link " > Analytics </a>
          </li>
          <li class="nav-item" *ngIf="canAccessReports">
            <a id="tab-3" role="tab" routerLink="/dashboard/report" routerLinkActive="active" aria-selected="false" data-toggle="tab"
            class="nav-link active show"> Reports </a>
          </li>
        </ul>
        <div id="myTabContent" class="tab-content">

          <div id="tab14" role="tabpanel" aria-labelledby="tab-3" class="tab-pane fade active show">
            <div class="tabl-heading">             
                <h2>Reports</h2>              
            </div>
            <div class="summary-container reports-filter">
           
                <div class="col-lg-2 pl-0 col-md-4">
                  <label for="extractType" class="asterisk">Report Name</label>
                  <div class="fdl-select">
                  <select [ngModel]="selectedValue" id="reportList" class="form-control" name="reportList" aria-invalid="false" (change)="onReportSelect($event)" ngModel>
                    <option value="">Please select</option>
                    <option *ngFor="let sys of systemreportList" value="{{sys.id}}">{{ sys.reportName}}</option>
                    <optgroup label="BU Customized Reports(s)" *ngIf="bureportList">
                        <option value="{{report.id}}" *ngFor="let report of bureportList"> {{ report.reportName}}</option>
                    </optgroup>
                </select>
              </div>
              </div>
     
              <div class="fdl-2">
                <button *ngIf="userDetails.isAdmin" type="button" class="btn fdl-btn btn-primary-orange" (click)="createReport();"><span class="material-icons">add</span> Create New Report</button>
              </div>
        
            
          </div>
          <div class="reports-name-container" *ngIf="selectedReportId != 0 && selctedReportDetails != null && selctedReportDetails != undefined && urlSafe != undefined && urlSafe != '' && urlSafe != null">
            <h2>{{selctedReportDetails.reportName}}</h2>
            <div class="btn-new-window fl-2">
              <button type="button" class="btn fdl-btn btn-primary-dark ml-3"  
(click)="openSite()">Open report in new window</button>

</div>
          </div>

          <section class="tab-content-container p-0" *ngIf="urlSafe != undefined && urlSafe != '' && urlSafe != null">
            <div class="bi-analytics-container">          
           
                <iframe height="750px" width="100%" [src]="urlSafe" frameborder="0" allowFullScreen="true"></iframe>                  
            </div>
          </section>
          </div>
        </div>

      </article>
    </section>
  </div>

  
</main>
