import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { ProjectsService } from '../../../services/projects.service';

@Component({
  selector: 'app-mutipleadd',
  templateUrl: './mutipleadd.component.html',
  styleUrls: ['./mutipleadd.component.css']
})
export class MutipleaddComponent implements OnInit {
  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() respondentSelected = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<{ data: any, fieldName: string, selectRespondent: string }>()
  @Input() isFdl: boolean = false;
  @Input() fdlData: any;
  @Input() formControlData: FormGroup;
  @Input() isSubmitted: boolean = false;
  @Input() buttonText: string = "Add Respondent";
  @Input() addUserButtonText: string = "Add New Users";
  filedArray: any[];
  @Input() filedJson: any;
  @Input() filedDetails: string[];
  @Input() elementName: any;
  @Input() isAddButtonEnable: boolean = true;
  @Input() isAddNewUserButtonEnable: boolean = false;
  @Input() preFilledData: any;
  @Input() typeofelements: any;
  @Input() cssWidth: string = "320px";
  @Input() isDisable: boolean = false;
  // START | PBI-168948
  @Input() duplicateTestField: string = "";
  @Input() allowDuplicates = true;
  duplicated = [];
  @Input() extraButtons: any[];
  @Output() extraButtonClick = new EventEmitter<{ action: string, index: number }>();
  @Output() duplicateFound = new EventEmitter<{ indexes: number[], data: any[], hasDuplicates: boolean }>()
  @Input() overrideRightMargin: boolean = false;
  private _hasDuplicates: boolean = false;

  get hasDuplicates() {
    return this._hasDuplicates
  }

  // END | PBI-168948
  noOfElements: number = 1;
  elementForm: FormGroup;
  constructor(private fb: FormBuilder, private _projectService: ProjectsService,
    private cdr: ChangeDetectorRef) { }

  onRespondentChange(data: any, fieldName: any, selectRespondent: string = '0'){
    if (data === "") {
      this.onClear.emit({data, fieldName, selectRespondent });
    }
  }

  isErrorOn(value: boolean = true) {
    
   // console.log(this.elementName)
    this.isSubmitted = value;

  }
  ngOnInit(): void {

  /**************************************************************/
    this.resetForm()
    /***************************************************/
    //this.newElement();
    //let formArray: any = {};
  
    //formArray[this.elementName] = this.fb.array([]);
    //this.formControlData = this.fb.group(formArray)
    //if (this.preFilledData) {
    //} else {
    //  this.addElement();
    //}
    //if (this.filedDetails)
    //  console.log(this.filedDetails);
  }
  
  resetForm() {
    let formArray: any = {};
    this.noOfElements = 1;
    formArray[this.elementName] = this.fb.array([]);

    this.elementForm = this.fb.group(formArray);
    this.formReady.emit(this.elementForm);
    if (this.filedJson && this.filedJson.dataToEdit && this.filedJson.dataToEdit.length > 0) {

      var dataArray = [];
      dataArray[this.elementName] = []
      for (let i in this.filedJson.dataToEdit) {
        this.addElements();
        dataArray[this.elementName].push(this.filedJson.dataToEdit[i]);
        //for (let key in this.filedJson.dataToEdit[i]) {

        //  dataArray[this.elementName][0][key] = this.filedJson.dataToEdit[i][key];
        //}
      }
      let formArray2: any = {};
      formArray2[this.elementName] = dataArray;
      this.formControlData.patchValue(formArray2)
    } else {
      this.addElements();
    }
  }

  updateFormJsonDetails() {
    if (this.filedDetails)
      console.log(this.filedDetails);
  }

  isInputValid() {
    var validFlag: boolean = true;
    if (!this.isFdl) {
      return true;
    }
    // this.requestor = this.formControlData.value[this.elementName] && this._projectService.fetchIdForRequest(this.orgUsersList, this.submitFormRequest.value[this.elementName]);
    if (this.formControlData.value[this.elementName]) {
      var dataArray = [];
      dataArray[this.elementName]=[]
      var isNameBlank=true
      for (let k in this.formControlData.value[this.elementName][this.elementName]) {
        let requestor: any = this._projectService.fetchIdForRequest(this.fdlData.data, this.formControlData.value[this.elementName][this.elementName][k].name);
        if (this.formControlData.value[this.elementName][this.elementName][k].name !== '') {
          isNameBlank = false
          this.isSubmitted = true;
        }
        if (requestor == '-1') {
          dataArray[this.elementName].push({ name: '', 'emails': '', id:'', 'isAdmin': false, 'isRestrictedAdmin': false })
          validFlag = false;

        } else {
          dataArray[this.elementName].push({ 
            name: this.formControlData.value[this.elementName][this.elementName][k].name, 
            'emails': this.formControlData.value[this.elementName][this.elementName][k].emails, 
            'id': this.formControlData.value[this.elementName][this.elementName][k].id,
            'isAdmin': this.formControlData.value[this.elementName][this.elementName][k].isAdmin,
            'isRestrictedAdmin': this.formControlData.value[this.elementName][this.elementName][k].isRestrictedAdmin
          })
        }
      }

      let elementName: any = this.elementName;
      let formArray: any = {};
      formArray[this.elementName] = dataArray;
      this.formControlData.patchValue(formArray);
      let checkNameIsRequired: any = this.filedDetails.filter((data) => {
        if (data['name'] == 'name' && (data['isRequired'] || !isNameBlank)) {
          return true;
        }
      });

      if (checkNameIsRequired.length < 1) {
        return true;
      }
    }
    return validFlag;
  }

  mapFieldData() {
    let temparray:any =[]
    for (let k in this.filedDetails) {
      let emailField='no'
      if (this.filedDetails[k]['emailField']) {
        emailField = this.filedDetails[k]['emailField']
      }
      temparray[this.filedDetails[k]['name']] = { 'type': this.filedDetails[k]['type'], 'fdlEmail': emailField };
    }
    // temparray[this.filedDetails[k]['name']] = { 'type': this.filedDetails[k]['type'], 'fdlEmail': emailField };
    this.filedArray = temparray;
  }

  selectRespondent(data: any, fieldName: any, selectRespondent: string = '0') {
    this.mapFieldData();
    if (data.item && data.item.id) {
      var dataArray = this.formControlData.value[this.elementName];
      dataArray[this.elementName][selectRespondent][fieldName] = data.item.name;

      if (this.filedArray[fieldName]['fdlEmail'] != 'no') {
        dataArray[this.elementName][selectRespondent][this.filedArray[fieldName]['fdlEmail']] = data.item.email;
      }
      dataArray[this.elementName][selectRespondent]['id'] = data.item.id;
      dataArray[this.elementName][selectRespondent]['isAdmin'] = data.item.isAdmin;
      dataArray[this.elementName][selectRespondent]['isRestrictedAdmin'] = data.item.isRestrictedAdmin;
   
      let formArray: any = {};
      formArray[this.elementName] = dataArray;
      this.formControlData.patchValue(formArray);
      if (this.respondentSelected) {
        // TODO Send all the entries to filter with
        this.respondentSelected.emit({
          data,
          fieldName,
          selectRespondent
        });
      }
     // this.curr = setNo;
    }
    this.validateDuplicatedElements();
  }

  // START | PBI-168948
  onExtraButtonClick(action: string, index: number) {
    this.extraButtonClick.emit({ action, index });
  }

  validateDuplicatedElements() {
    this.clearDuplicateError();
    if (!this.allowDuplicates && this.duplicateTestField !== "") {
      const source = this.elements().value.slice();
      for (let i = 0; i < source.length; i++) {
        for (let j = 0; j < source.length; j++) {
          if (j != i) {
            if (source[i][this.duplicateTestField] === source[j][this.duplicateTestField]) {
              if (!this.duplicated.includes(i)) {
                this.duplicated.push(i)
              }
              if (!this.duplicated.includes(j)) {
                this.duplicated.push(j)
              }
            }
          }
        }
      }
      if (this.duplicated.length > 0) {
        this.markWithDuplicateError(this.duplicated);
        this._hasDuplicates = true;
      }
      this.duplicateFound.emit({
        indexes: this.duplicated,
        data: source,
        hasDuplicates: this.duplicated.length > 0
      });
    }
  }

  validateDuplicateForEntry(entry, refArray, refArrayIndex) {
    for (let i = 0; i < refArray.length; i++) {
      if (i == refArrayIndex) continue;
      const ent = refArray[0];
      for (const value of Object.keys(entry)) {
        if (entry[value]) {
          if (entry[value] === ent[value]) {
            return i;
          }
        }
      }
    }
    return -1;
  }

  markWithDuplicateError(indexes) {
    const values = this.elements();
    for (const index of indexes) {
      values.at(index).setErrors({ duplicate: true });
    }
  }

  clearDuplicateError() {
    this.duplicated = [];
    this._hasDuplicates = false;
  }

  // END | PBI-168948
  /***********************Temp Code*****************************/
  elements(): FormArray {
     this.formReady.emit(this.elementForm);
     return this.elementForm.get(this.elementName) as FormArray
  }

  newSkill(dataToPopulate: any = ''): FormGroup {
    if (dataToPopulate != '')
      return this.fb.group(dataToPopulate)
    else
      return this.fb.group(this.filedJson.json)
  }

  addElements(dataToPopulate: any = '') {
    this.formReady.emit(this.elementForm);
    if (dataToPopulate != '') {
      if (this.elements().length === 1) {
        this.elements().removeAt(0, { 
          emitEvent: false
        });
      }
      this.noOfElements = 0;
    }
    if (dataToPopulate != '')
      this.elements().push(this.newSkill(dataToPopulate));
    else
      this.elements().push(this.newSkill(dataToPopulate));
    this.noOfElements = this.noOfElements+1
  }
  
  insertElement(data: any, position: number) {
    if (position >= this.elements().length) {
      this.addElements(data);
      return;
    }
    this.formReady.emit(this.elementForm);
    this.elements().removeAt(position);
    this.elements().insert(position, this.newSkill(data));
    this.noOfElements += 1
  }

  isPositionForced(button: any) {
    if (button) {
      if (typeof button.forceAt === "number") {
        return true;
      }
    }
    return false;
  }

  removeSkill(i: number) {
    this.elements().removeAt(i);
    this.noOfElements = this.noOfElements - 1
    this.cdr.detectChanges();
    this.validateDuplicatedElements();
  }
  /**************************************************************/
}
