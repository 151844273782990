import { Component, OnInit } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';
import { InmemoryDataService, form } from '../../inmemory-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-spbuilder',
  templateUrl: 'spbuilder.component.html',
})
export class SpbuilderComponent implements OnInit {
  form = [];
  formJson = {};
  formName: string = '';
  constructor(private dataService: InmemoryDataService,  private router: Router) {}

  ngOnInit(): void {}
  onChange(data: { form: {} }) {
    this.formJson = data.form;
  }

  saveForm() {
    let newForm: form = {
      formId: uuidv4(),
      formName: this.formName,
      formJson: JSON.stringify(this.formJson),
    };

    this.dataService.createForm(newForm);
    this.router.navigate(['/backend/renderer']);
  }
}
