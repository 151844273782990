import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FieldBase } from './../fieldClass/field-base';
import { FieldControlServiceService } from './../../field-control-service.service';
import { FormsService } from 'src/app/services/forms.service';
import { SurveyService } from 'src/app/services/survey.service';
import { IResponseForSurvey } from "src/app/shared/survey";
import { Title } from '@angular/platform-browser';
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { SubHeaderService } from '../../SharedModule/services/subHeader.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
})
export class ViewComponent implements OnInit, OnDestroy {
  private renewlTokenCounter: number;
  private subscription: Subscription;
  formJson: any;
  title = 'test';
  fields: FieldBase<any>[];
  form: FormGroup;
  payLoad: string;
  error = false;
  readonly = false;
  formData: any;
  conntectedResponses;

  formId: number;
  // fieldData: any = {data: {}};

  responseForSurvey: IResponseForSurvey;

  constructor(
    private route: ActivatedRoute,
    private formService: FormsService,
    private formField: FieldControlServiceService,
    private surveyService: SurveyService,
    private titleService: Title,
    private _subHeaderService: SubHeaderService,
    private router: Router) { }
  ngOnDestroy(): void {
    sessionStorage.removeItem("surveyToken");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  cssClassName(obj: string, type: string, key: string) {
    if (obj != 'undefined' && obj != null && obj != '') {
      obj = obj + ' ' + 'custom-' + type + '-inline ' + 'c' + key;
    } else {
      obj = 'custom-' + type + '-inline ' + 'c' + key;
    }
    return obj;
  }
  processJson(data: any) {
    if (data && data.formJSON) {
      this.titleService.setTitle(`Form - ${data.title}`);
      this.formJson = JSON.parse(data.formJSON);
      this.conntectedResponses = data.conntectedResponses;
      this.formJson.components.filter(component => component.type !== "button").forEach(element => {

        if (element.status != 'Completed') {
          element.status == 'Pending';

        }
      }
      );


      for (let i = 0; i < this.formJson.components.length; i++) {
        if (this.formJson.components[i].type !== "button") {
          let fieldData: any = { data: {} };

          this.formJson.components[i].fieldData = fieldData;
          for (let j = 0; j < this.formJson.components[i].components.length; j++) {
            this.formJson.components[i].components[j].customClass = this.cssClassName(this.formJson.components[i].components[j].customClass, this.formJson.components[i].components[j].type, this.formJson.components[i].components[j].key);

            if (this.formJson.components[i].components[j].type == 'columns') {
              for (let k = 0; k < this.formJson.components[i].components[j].columns.length; k++) {
                for (let l = 0; l < this.formJson.components[i].components[j].columns[k].components.length; l++) {
                  this.formJson.components[i].components[j].columns[k].components[l].customClass = this.cssClassName(this.formJson.components[i].components[j].columns[k].components[l].customClass, this.formJson.components[i].components[j].columns[k].components[l].type, this.formJson.components[i].components[j].columns[k].components[l].key);
                }
              }

            } if (this.formJson.components[i].components[j].type == 'table') {
              for (let k = 0; k < this.formJson.components[i].components[j].rows.length; k++) {

                for (let l = 0; l < this.formJson.components[i].components[j].rows[k].length; l++) {
                  for (let m = 0; m < this.formJson.components[i].components[j].rows[k][l].components.length; m++) {
                    this.formJson.components[i].components[j].rows[k][l].components[m].customClass = this.cssClassName(this.formJson.components[i].components[j].rows[k][l].components[m].customClass, this.formJson.components[i].components[j].rows[k][l].components[m].type, this.formJson.components[i].components[j].rows[k][l].components[m].key);

                  }
                }
              }

            }
          }
        } else {
          this.formJson.components[i].disableOnInvalid = false;
        }
      }
    }
  }
  renewToken() {
    this.surveyService.renewToken().subscribe(data => {
      sessionStorage.setItem("surveyToken", data.token);
      localStorage.setItem("drmsToken", 'Bearer ' + data.token);
      this.startTokenRenewCountdown();
    });
  }
  startTokenRenewCountdown() {
    const minutes = interval(60000);
    const fiftyFiveMinutes = minutes.pipe(take(54));
    this.subscription = fiftyFiveMinutes
      .subscribe(null, null, () => {
        this.renewToken();
      });
  }
  ngOnInit() {

    if (this.route.snapshot.data.isUnauthenticated && !this.surveyService.isValidatedOtp()) {
      let navigationToUrl = this.router.url.substring(1, this.router.url.lastIndexOf("/"));
      this.router.navigate([navigationToUrl]);
      return;
    }
    this.fields = [];
    if (this.route.snapshot.data.isUnauthenticated) {
      this.renewlTokenCounter = 0;
      this.startTokenRenewCountdown();
      this.responseForSurvey = this.surveyService.getResponseForSurvey();
      this.processJson(this.responseForSurvey);
      this.formData = this.responseForSurvey;
    } else {
      this.route.params.subscribe((params) => {
        this.formId = params['id'];
        if (params['edit'] == 'preview') {
          $('.navbar-nav').css('display', 'none');
        }
        if (this.router.url.indexOf('intake') !== -1) {
          this._subHeaderService.updateSideBarNavigation(false);

        }
        if (params['edit'] != undefined && (params['edit'] == 'responses' || params['edit'] == 'preview')) {
          this.formService.getResponseJson(this.formId).subscribe(
            data => {
              this.processJson(data);
              this.formData = data;
            }
          );
        } else {
          this.readonly = true;
          let version = this.formService.version;
          let modifiedOn = this.formService.versionModifiedOn;
          if (params['edit'] != undefined && (params['edit'] == 'history')) {
            if (this.route.snapshot.data.title.indexOf("FormTemplate") > -1) {
              this.formService.getFormsVersionHistory(this.formId, version, modifiedOn).subscribe(
                data => {
                  this.processJson(data);
                  this.formData = data;
                  this.formData['isReadOnly'] = true;
                });
            }
            else {
              this.formService.getResponsesVersionHistory(this.formId, version).subscribe(
                data => {
                  this.processJson(data);
                  this.formData = data;
                  this.formData['isReadOnly'] = true;
                });
            }
          }
          else {
            this.formService.getFormJson(this.formId).subscribe(
              data => {
                this.processJson(data);
                this.formData = data;
                this.formData['isReadOnly'] = true;
              });
          }
        }
      });
    }
  
  }
  onSubmit() {
    if (this.form.valid)
      this.payLoad = JSON.stringify(this.form.getRawValue());
    else {
      this.error = true;

    }
  }
}
