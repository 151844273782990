import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Filter, UserFilter, UserFilterData } from './../shared/user.filter';

@Injectable({
  providedIn:'root'
} )
export class UserFilterService{

  FILTER_MAP_KEY = 'userFilter';
  KEY_EXPIRY: number = -1; // expiry in hrs. Replace with constant from environment.

  constructor(private userService: UserService){}

  saveFilter(route: ActivatedRoute, filterForm: FormGroup) : void{
    let filterData: UserFilterData;
    let filterArray : Filter[] = [];
    Object.keys(filterForm.controls).forEach((key: string) => {
        const abstractControl = filterForm.controls[key];
        if (abstractControl.value) {
          filterArray.push(new Filter(key, abstractControl.value));
        }
    });
    const routeUrl = this.getRouteUrl(route);
    filterData = new UserFilterData(routeUrl, filterArray);
    let userFilter = this.getUserFilterFromLocalStorage();
    let userFilterData = [];

    if(userFilter != null){
      userFilterData = userFilter.userFilterData;

      let index = userFilterData.findIndex(element=>{
        return element.routerKey === routeUrl;
      })
      if(index > -1){
        userFilterData.splice(index,1);
      }
    }
    userFilterData.push(filterData);
    let expiry = this.getExpiryTime();
    localStorage.setItem(this.getFilterMapKey(), JSON.stringify(new UserFilter(userFilterData,expiry)));
  }

  getExpiryTime(): Date|null{
    if(this.KEY_EXPIRY > 0){
      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + this.KEY_EXPIRY);
      return currentDate;
    }
    return null;
  }

  getFilterMapKey(){
    return this.FILTER_MAP_KEY+"_"+this.userService.getUserListData()?.id;
  }

  loadAndApplyFilters(route:ActivatedRoute, filterForm: FormGroup, queryParam: any){
    let filterExists = false;
    let patchedVal = {};
    const userFilter :Filter[]  = route.snapshot.data['userFilter'];
    if(userFilter && userFilter.length > 0){
      filterExists = true;
      userFilter.forEach(element =>{
        patchedVal[element.key] = element.value;
        queryParam[element.key] = element.value;
      });
    }
    filterForm.patchValue(patchedVal);
    return filterExists;
  }

  getUserFilterFoRoute(route:string): Filter[]{
    let filterArray : Filter[] = [];
    let userFilter : UserFilter= this.getUserFilterFromLocalStorage();
    if(userFilter && userFilter.userFilterData?.length >0 ){
      let userFilterForRoute = userFilter.userFilterData.filter(element =>{
        return element.routerKey === route;
      });
      if(userFilterForRoute && userFilterForRoute.length >0){
        filterArray = userFilterForRoute[0].filters;
      }
    }
    return filterArray;
  }

  getUserFilterFromLocalStorage() : UserFilter{
    let userFilterString = localStorage.getItem(this.getFilterMapKey());
    if(userFilterString){
      let now = new Date();
      let userFilter : UserFilter =  <UserFilter>JSON.parse(userFilterString);
      if(userFilter && userFilter.expiry && now > userFilter.expiry){
        this.clearFilter();
        return null;
      }
      return userFilter;
    }
    return null;
  }

  clearFilter(){
    localStorage.removeItem(this.getFilterMapKey());
  }

  getRouteUrl(route: ActivatedRoute){
    return route.snapshot['_routerState'].url;
  }
}
