import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { form, InmemoryDataService } from '../../inmemory-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { stringify } from 'querystring';
@Component({
  selector: 'app-exceldetails',
  templateUrl: './exceldetails.component.html',
  styleUrls: ['./exceldetails.component.css']
})
export class ExceldetailsComponent implements OnInit {

  excelId: string;
  workbook: XLSX.WorkBook;
  data: any;
  sheets: any;
  constructor(
    private route: ActivatedRoute,
    private dataService: InmemoryDataService,
    private router: Router
  ) { }
  optionArray(option: string) {
    let tempOptionArray = option.split(/(?:\r\n|\r|\n)/g);
    let options = [];
    for (let j = 0; j < tempOptionArray.length; j++) {
      let temp = tempOptionArray[j].split('##');
      let json: any;
      if (temp.length < 2) {
        json = {
          "label": temp[0],
          "value": temp[0]
        }
      } else {
        json = {
          "label": temp[0],
          "value": temp[1]
        }
      }
      options.push(json);
    }
    return options;


  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      
      this.excelId = params['id'];
      let array = [];
      this.workbook = this.dataService.getXls();
      
      this.sheets = this.workbook.SheetNames;
      let excelSheetsField = [];
      for (let k = 0; k < this.sheets.length; k++) {
        let sheetsField = [];
        
        const wsname: string = this.workbook.SheetNames[k];
        const ws: XLSX.WorkSheet = this.workbook.Sheets[wsname];
        this.data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let JsonObjectColumns1 = [];
        let JsonObjectColumns2 = [];
        let rows = 0;

        for (let i = 1; i < this.data.length; i++) {
          let required = false;
          let JsonObject: any;
          let tooltipMsg = this.data[i][8];
          if (this.data[i][5] == 'Yes') {
            required = true;
          }
          
          if (this.data[i].length > 0) {
            switch (this.data[i][2]) {
              case 'text':
                JsonObject = {
                  "label": this.data[i][0], "spellcheck": true,
                  "tableView": true,
                  "customDefaultValue": "function name(){}",
                  "validate": {
                    "required": required,
                    "custom": this.data[i][1],
                    "minLength": this.data[i][4],
                    "maxLength": this.data[i][5]

                  },
                  "key": this.data[i][1],
                  "properties": {
                    "zjxhcjkz": "sdada"
                  },
                  "type": "textfield",
                  "input": true
                };
                break;

              case 'select':
                let options = this.optionArray(this.data[i][10]);
                //  let optionsData = this.data[i][10];
                //  let tempOptionArray = optionsData.split(/(?:\r\n|\r|\n)/g);
                JsonObject = {
                  "label": "Select",
                  "widget": "html5",
                  "tableView": true,
                  "defaultValue": this.data[i][7],
                  "data": {
                    "values": options,
                  },
                  "selectThreshold": 0.3,
                  "validate": {
                    "unique": false,
                    "multiple": false
                  },
                  "key": "select1",
                  "type": "select",
                  "indexeddb": {
                    "filter": {}
                  },
                  "input": true
                }
                break;

              case 'number':
                JsonObject = {
                  "label": this.data[i][0], "spellcheck": true,
                  "tableView": false,
                  "mask": true,
                  "delimiter": false,
                  "requireDecimal": false,
                  "inputFormat": "plain",
                  "customDefaultValue": "function name(){}",
                  "validate": {
                    "required": required,
                    "custom": this.data[i][1],
                    "minLength": this.data[i][4],
                    "maxLength": this.data[i][5]

                  },
                  "key": this.data[i][1],
                  "type": "number",
                  "input": true
                };
                break;

              case 'checkbox':
                let optionsChekbox = this.optionArray(this.data[i][10]);
                let optionsDataCheckbox = this.data[i][10];
                JsonObject = {
                  "label": this.data[i][0],
                  "tableView": false,
                  "defaultValue": false,
                  "validate": {
                    "unique": false,
                    "multiple": false,
                    "required": required
                  },
                  "values": optionsChekbox,
                  "key": "checkbox",
                  "type": "selectboxes",
                  "input": true,
                  "inputType": "checkbox"

                }
                break;

              case 'textarea':
                JsonObject = {
                  "label": this.data[i][0], "spellcheck": true,
                  "tableView": true,
                  "autoExpand": false,
                  "customDefaultValue": "function name(){}",
                  "validate": {
                    "required": required,
                    "custom": this.data[i][1],
                    "minLength": this.data[i][4],
                    "maxLength": this.data[i][5]

                  },
                  "key": this.data[i][1],
                  "properties": {
                    "reason_desc": "abcdefghi"
                  },
                  "type": "textarea",
                  "input": true
                };
                break;

              case 'email':
                JsonObject = {
                  "label": this.data[i][0], "spellcheck": true,
                  "tableView": true,
                  "customDefaultValue": "function name(){}",
                  "validate": {
                    "required": required,
                    "custom": this.data[i][1],
                    "minLength": this.data[i][4],
                    "maxLength": this.data[i][5]

                  },
                  "key": this.data[i][1],
                  "type": "email",
                  "input": true
                };
                break;

              case 'radio':
                let radioOptions = this.optionArray(this.data[i][10]);
                let radioOptionsData = this.data[i][10];
                let tempOptionArrays = radioOptionsData.split(/(?:\r\n|\r|\n)/g);
                JsonObject = {
                  "label": this.data[i][0],
                  "optionsLabelPosition": "right",
                  "tableView": false,
                  "inline": false,
                  "values": radioOptions,
                  "validate": {
                    "unique": false,
                    "multiple": false
                  },
                  "key": "radio1",
                  "type": "radio",
                  "input": true
                }
                break;


            }
            JsonObject['key'] = this.data[i][0].replace(/\W+/g, " ");
            if (tooltipMsg !== undefined) {
              JsonObject["tooltip"] = tooltipMsg;
            }
            if (this.data[i][11] || this.data[i][12] || this.data[i][13]) {
              JsonObject.conditional = {};
              if (this.data[i][11]) JsonObject.conditional.show = this.data[i][11];
              if (this.data[i][12]) JsonObject.conditional.when = JsonObject['key'];
              if (this.data[i][13]) JsonObject.conditional.eq = this.data[i][13];
            }
            
            if (this.data[i][14] == 'No') {
              if (rows % 2 == 0) {
                rows = 0;
                JsonObjectColumns1.push(JsonObject)
              } else {
                // 
                JsonObjectColumns2.push(JsonObject)
              }
              rows = rows + 1;
            } else {

              rows = 0;

              sheetsField.push({
                "label": "Columns",
                "columns": [{
                  "components": JsonObjectColumns1,
                  "width": 6,
                  "offset": 0,
                  "push": 0,
                  "pull": 0
                }, {
                  "components": JsonObjectColumns2,
                  "width": 6,
                  "offset": 0,
                  "push": 0,
                  "pull": 0
                }],
                "tableView": false,
                "key": "columns",
                "type": "columns",
                "input": false,
                "path": "columns"
              });
              JsonObjectColumns2 = [];
              JsonObjectColumns1 = [];
              sheetsField.push(JsonObject);
            }
            

          }

        }
        if (JsonObjectColumns1.length > 0 || JsonObjectColumns2.length > 0) {
          sheetsField.push({
            "label": "Columns",
            "columns": [{
              "components": JsonObjectColumns1,
              "width": 6,
              "offset": 0,
              "push": 0,
              "pull": 0
            }, {
              "components": JsonObjectColumns2,
              "width": 6,
              "offset": 0,
              "push": 0,
              "pull": 0
            }],
            "tableView": false,
            "key": "columns",
            "type": "columns",
            "input": false,
            "path": "columns"
          });
        }
        let formJson = {
          "legend": wsname,
          "tableView": false,
          "key": wsname,
          "type": "fieldset",
          "label": wsname,
          "input": false, "components": sheetsField
        };
        excelSheetsField.push(formJson)

        excelSheetsField.push({
          "type": "button",
          "label": "Submit",
          "key": "submit",
          "disableOnInvalid": false,
          "input": true,
          "tableView": false,
          "validate": {
            "unique": false,
            "multiple": false
          }
        })

      }
      let formJson = { "components": excelSheetsField };
      let newForm: form = {
        formId: uuidv4(),
        formName: 'excel',
        formJson: JSON.stringify(formJson),
      };
      this.dataService.createForm(newForm);
      this.router.navigate(['/backend/renderer']);


      /*
      "legend": "Basic Information",
                  "tableView": false,
                  "key": "dfcdxvdsx",
                  "type": "fieldset",
                  "label": "Basic Information",
                  "input": false,
      */


      /* const wsname: string = this.workbook.SheetNames[this.excelId];
        this.sheets = this.workbook .SheetNames;
        const ws: XLSX.WorkSheet = this.workbook.Sheets[wsname];
    this.data = XLSX.utils.sheet_to_json(ws, { header: 1 });
         //   this.formJson={"components":array};
       let k=1;
       
      for(let i=0;i<this.data.length;i++){
      if(this.data[i].length>0){
      	
    	
      if(k>3){
        let JsonObject = {"type": "textfield","label":this.data[i][0] ,"key": "firstName"+i,"input": true};
        array.push(JsonObject);
        
      }
      k++;
      }
       
    }
      let formJson={"components":array};
        let newForm: form = {
        formId: uuidv4(),
        formName: wsname,
        formJson: JSON.stringify(formJson),
      };
      this.dataService.createForm(newForm);
      this.router.navigate(['/backend/renderer']);
  	
       // const json = this.dataService.getFormJson(this.formId);
      */
    });
  }

}
