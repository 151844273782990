<div class="heading-container fdl-2">
  <h1>Request Templates</h1>
</div>

<div class="form-container">
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>

  <form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
    <h2 class="h4 collapse-arrow collapsed" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse" href="#collapseFilter">Search</h2>
    <div class="filter-form-container-column2 search-filter collapse" [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">
      <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('title').valid && (filterForm.get('title').touched || filterForm.get('title').dirty || isSubmitted)}">
        <span *ngIf="!filterForm.get('title').valid && filterForm.get('title').errors?.pattern && (filterForm.get('title').dirty || isSubmitted)"><i class="material-icons">warning</i>Please enter a valid Template Name</span>
        <fdl-type-ahead label="Template Name:" formControlName="title" [collection]="templateSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
        </fdl-type-ahead>
      </div>
      <div class="buttom-wrapper">
        <button type="button" class="btn fdl-btn btn-primary-dark" aria-label="Search" (click)="filterList()">
          Search
        </button>
      </div>
      <div class="ml-2 buttom-wrapper" *ngIf="filtered">
        <button type="button" class="btn fdl-btn btn-primary-dark" aria-label="Reset" (click)="clearFilterList()">
          Reset
        </button>
      </div>
    </div>
  </form>

  <div class="data-table-container">
    <div class="justify-content-between d-flex">
      <div class="headline-container">
        <h2 class="h4">Template List</h2>
        <p>Total {{pagerObject.totalCount}} Templates</p>
      </div>
      <div class="d-flex">
        <div class="refresh-icon-link mt-1 mr-2">
          <!-- add class of 'fa-rotation' on click of the refresh icon -->
          <a title="Refresh Project List" id="refreshTask" (click)="refresh()">
            <i aria-hidden="true" class="fa fa-refresh"></i>
          </a>
        </div>
        <app-prefernce-icon></app-prefernce-icon>
      </div>
    </div>
    <div class="overflow-auto">
      <app-data-table
        *ngIf="pageTemplateList"
        [rows]="pageTemplateList.pageTemplates"
        [keys]="keys"
        [displayColumns]="displayColumns"
        (sortingClicked)="applySorting($event)"
        [showCheckbox]="false"
        (buttonClicked)="navigate($event)"
        [listname]="listname"
      >
      </app-data-table>
    </div>
    <div>
      <app-pagination
        *ngIf="pagerObject && pagerObject.totalPages > 1 &&pageTemplateList && pageTemplateList.pageTemplates.length > 0"
        [pagerObject]="pagerObject"
        (pagingChanged)="changePage($event)"
      >
      </app-pagination>
    </div>
  </div>
</div>
