import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ICats,
  ICreateTopic,
  ITopics,
} from '../admin/manageForms/models/createTopic';
import { IQuery } from '../shared/query';
import { IHelpCategory } from '../shared/helpCategory';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  private catAPIURL = '/helpcategory';
  private createtopicUrl = 'helptopics';
  private topicUrl = 'helptopics';
  private recTopicApi = '/helptopics/recommended';
  private popUlarTopicApi = '/helptopics/popular';
  private helpCategoryAPIURL = 'helpCategories';
  constructor(private http: HttpClient) {}

  getHelpCategoryDetailsById(helpCategoryId: number) {
    return this.http.get<IHelpCategory>(
      `${this.helpCategoryAPIURL}/${helpCategoryId}`
    );
  }
  getHelpCategories(queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.helpCategoryAPIURL, {
      params,
      observe: 'response',
    });
  }
  gecategory() {
    let url = this.catAPIURL;
    return this.http.get<ICats>(url);
  }
  createTopic(body) {
    return this.http.post<any>(this.createtopicUrl, body);
  }
  getTopic(queryParams) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }

    return this.http.get<any>(this.createtopicUrl, {
      params,
      observe: 'response',
    });
  }
  updateTopic(body) {
    return this.http.put<any>(this.createtopicUrl + '/' + body.id, body);
  }
  getTopicByCatId(Id: any, queryParams: IQuery) {
    let url = this.helpCategoryAPIURL;
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get<ITopics>(url + '/' + Id + '/topics', { params });
  }
  recPopularTopic() {
    let url = this.recTopicApi;

    return this.http.get<ICreateTopic>(url);
  }

  recommendedTopic(Id: any, IsCommonCategory: any) {
    let url = this.recTopicApi;

    return this.http.get<ICreateTopic>(url + '/' + Id + '?' + IsCommonCategory);
  }

  getPopularArticle() {
    let url = this.popUlarTopicApi;

    return this.http.get<ICreateTopic>(url);
  }

  getCommonTopics() {
    let url = 'helptopics/commontopics';
    return this.http.get<ICreateTopic>(url);
  }

  getTopicById(Id: any) {
    let url = this.createtopicUrl;

    return this.http.get<ICreateTopic>(url + '/' + Id);
  }
  deleteHelpTopic(Id: number): Observable<ICreateTopic> {
    return this.http.delete<ICreateTopic>(`${this.createtopicUrl}\\${Id}`);
  }

  exportPDF(id: number, params: any) {
    const exportToPdfUrl = `${this.topicUrl}/${id}/pdf`;
    return this.http.post<any>(exportToPdfUrl, params,
    {
      observe: 'response', responseType: 'blob' as 'json'
    });
  }
}
