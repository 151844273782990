
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { CustomComponentsService } from 'src/app/services/custom-components.service'
declare var $: any;
@Component({
  selector: 'app-button-click',
  templateUrl: './button-click.component.html',
  styleUrls: ['./button-click.component.css']
})
export class ButtonClickComponent implements OnInit {
  @Input()
  value: string
  @Input()
  id: string
  @Input()
  type: string
  @Input()
  data: any
  @Input()
  input: boolean

  @Input()
  key: string

  @Input()
  properties: any
  @Input()
  label: string

  @Input()
  apiurl: string
  @Input()
  customClass: string
  datatoload: any;
  @Output() dataSave = new EventEmitter<{}>();
  constructor(private customComponentsService: CustomComponentsService) { }

  ngOnInit(): void {
    this.customClass = "sdfsdf";
       
  }
  callAPI() {
    console.log(this.properties);
    
    this.datatoload = this.customComponentsService.getData();
    this.customComponentsService.getApiData(this.apiurl + this.datatoload.data[this.properties['targetKey']] + '&ignore=' + Math.random()).subscribe(apiData => {
      for (let k in this.properties) {
        if (k.indexOf('field_') != -1) {
          let answerKey = k.replace('field_', '');
          this.datatoload.data[answerKey] = eval(this.properties[k])
          

        }

      }
      this.datatoload.data['random'] = Math.random();
      this.customComponentsService.setData(this.datatoload);
      $('#refereshClick').trigger('click');
    });
  //  return this.http.get<any>(this.apiurl); console.log(this.apiurl)
  }

}
