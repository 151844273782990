import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsService } from 'src/app/services/forms.service';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectsDTOService } from '../../Services/projectsDTO.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { DataTableComponent } from 'src/app/SharedModule/Components/data-table/data-table.component';
import { IStatus } from 'src/app/shared/status';
import { MasterService } from 'src/app/services/master.service';
import { IForms } from 'src/app/shared/form';
import { REGEX_PATTERNS } from 'src/app/core/constants';
import { UtilService } from 'src/app/services/util.service';
import { ProjectDrawerService } from 'src/app/SharedModule/Components/project-drawer-container/project-drawer.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { DateFormatPipe } from 'src/app/SharedModule/Pipes/date-format.pipe';
import { ClientsService } from 'src/app/services/clients.service';
import { plLocale } from 'ngx-bootstrap/chronos/i18n/pl';
declare var $: any;


@Component({
  selector: 'app-normal-survey',
  templateUrl: './normal-survey.component.html',
  styleUrls: ['./normal-survey.component.css'],
  providers: [DateFormatPipe]
})
export class NormalSurveyComponent implements OnInit {
  dueDateMsg: string = '';
  formsUsersDetails: any = {};
  normalSurveyForm: FormGroup
  normalForm: FormArray;
  updatedProjectData: any = {};
  currentSet: number = 0;
  formSets: any = [];
  responderUserId: number = null;
  approverUserId: number = null;
  successMsg: string;
  isSubmitted: boolean = false;
  currentIndex: string = '0';
  selectForms: boolean = false;
  msgTitle: string;
  responseStatusData;
  assignedForms: IForms;
  isError: boolean;
  errorMsg: string;
  @ViewChild('selectedRows') allRowsSelection: DataTableComponent;
  columns = [
    { prop: 'title', name: 'Form Name', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.form_response_survey_name_validator }, width: 250 },
    { prop: 'description', name: 'Description', inlineEditing: { 'type': 'default', 'pattern': REGEX_PATTERNS.description_comment }, width: 250 },
    { prop: 'dueDate', name: 'Due Date (mm/dd/yyyy)', inlineEditing: { 'type': 'default' }, width: 250 }
  ];
  isCreateUserAllowed: boolean = false;
  productSelection: boolean = false;
  initiateProjId: number = -1; //blank -1, incorrect -1
  ResponderApproverList;
  prodFormsList;
  clientUsersList;
  initiateDGF: boolean = false;
  initiateDGF_clientId: number = 0; //blank 0, incorrect -1

  constructor(private _formService: FormsService,
    private _router: Router,
    private _projectDto: ProjectsDTOService,
    private _projectService: ProjectsService,
    private _masterService: MasterService,
    private _utilService: UtilService,
    private _drawerService: ProjectDrawerService,
    private _permissionService: PermissionsService,
    private _datePipe: DateFormatPipe,
    private _clientService: ClientsService) { }


  ngOnInit(): void {

    this.isCreateUserAllowed = this._permissionService.isCreateUserAllowed();
    /**** to fetch response status from master service ****/
    let list: IStatus[] = this._masterService.getResponseStatusList();
    this.responseStatusData = list.find(data => data.title.trim().toLowerCase() === "not started");

    /***** created project details ******/
    this.updatedProjectData = this._projectDto.getProjectFacts();

    if (this._router.url.indexOf('initiateDGF') > -1) this.initiateDGF = true;

    if (Object.keys(this.updatedProjectData).length !== 0 && !this.initiateDGF) {
      let allApiData = this._formService.formsUsersData();
      this.formsUsersDetails.forms = allApiData?.forms?.forms;
      this.formsUsersDetails.users = allApiData.users;
      this.initiatingFormGroup();
    }
    else {
      /****************** Begin Initiate DGF ****************/
      this.productSelection = true;
      this.noClientProjectSelection();
      this.initiatingFormGroup();
      this._projectService.clientVisibility$.subscribe(clientId => {
        this.initiateDGF_clientId = clientId;
        this.formSets.length = 0;
        this.currentSet = 0
        this.initiatingFormGroup();
        if (this.initiateDGF_clientId > 0) {
          this._projectDto.setInitiateDgfData({ clientId: this.initiateDGF_clientId });
          this.formsUsersDetails.forms = this._formService.formsUsersData()?.orgForms?.forms;
          return this.clientRespApproverApi(this.initiateDGF_clientId, this._formService.formsUsersData())
        }
        else if (this.initiateDGF_clientId === -1) {
          this.noClientProjectSelection();
        }
        else {
          this._projectService.projVisibility$.subscribe(projectId => {
            this.initiateProjId = projectId;
            this.formSets.length = 0;
            this.currentSet = 0
            this.initiatingFormGroup();
            if (this.initiateProjId > -1) {
              this._projectService.getProjectById(this.initiateProjId).subscribe(
                project => {
                  const projectDetails = project;
                  if (projectDetails && projectDetails.clientId) this._projectDto.setInitiateDgfData(projectDetails);
                  return this.apiToGetFormsUsers(projectDetails);
                }
              )
            }
            else {
              this.noClientProjectSelection();
            }
          });
        }
      })
      /****************** End Initiate DGF **************/
    }
  }

  /****************** Begin Initiate DGF ****************/

  initiatingFormGroup() {
    this.formSets[this.currentSet] = {
      "title": 'Form Set' + " " + (this.currentSet + 1),
      "assignedFormsList": [],
      "assignForm": new FormGroup({
        'formResponder': new FormControl('', [Validators.required]),
        'formApprover': new FormControl('')
      })
    }
  }
  noClientProjectSelection() {
    this.productSelection = true;
    let initiateApiData = this._formService.formsUsersData();
    this.formsUsersDetails.forms = initiateApiData?.orgForms?.forms;
    this.formsUsersDetails.users = initiateApiData.orgUsers;
  }
  clientRespApproverApi(clientId, b) {
    this._clientService.getClientDetailsById(clientId, "respondents").subscribe(
      usersByClient => {
        this.clientUsersList = usersByClient;
        const allUsers = (this.clientUsersList !== undefined) ? b.orgUsers.concat(this.clientUsersList) : b.orgUsers;
        this.ResponderApproverList = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);
        this._formService.formsUsersDetail({ orgForms: b.orgForms, prodForms: b.prodForms, allUsers: this.ResponderApproverList, orgUsers: b.orgUsers, emailGroup: b.emailGroup });
        this.formsUsersDetails.users = this._formService.formsUsersData().allUsers;
      })
  }

  apiToGetFormsUsers(projectDetails) {
    let b = this._formService.formsUsersData();
    if (projectDetails.productId && projectDetails.productId !== null) {
      this.productSelection = false;
      this._formService.getFormsByproductId(projectDetails.productId, projectDetails.clientId).subscribe(
        formsByProduct => {
          this.prodFormsList = formsByProduct;
          this._formService.formsUsersDetail({ orgForms: b.orgForms, prodForms: this.prodFormsList, orgUsers: b.orgUsers, emailGroup: b.emailGroup });
          this.formsUsersDetails.forms = this._formService.formsUsersData().prodForms?.forms;
        })
    }
    else {
      this.productSelection = true;
      this.formsUsersDetails.forms = this._formService.formsUsersData().orgForms?.forms;
    }

    if (projectDetails.clientId && projectDetails.clientId !== null) {
      this.clientRespApproverApi(projectDetails.clientId, b);
    }
    else {
      this.formsUsersDetails.users = this._formService.formsUsersData().orgUsers;
    }
    /****************** End Initiate DGF ****************/
  }


  closeFormset(formsetToBeRemoved) {
    this.currentSet--;
    this.formSets.splice(formsetToBeRemoved, 1);
    this.formSets.map((formset, index) => {
      return formset.title = "Form Set " + (index + 1);
    });
  }

  anotherSet() {
    this.allRowsSelection.allSelected = false;
    this.isSubmitted = false;
    this.currentSet++;
    let newFormSet = {
      "title": 'Form Set' + " " + (this.currentSet + 1), "assignedFormsList": [], 'showAssignedForms': false, "assignForm": new FormGroup({
        'formResponder': new FormControl('', Validators.required), 'formApprover': new FormControl('')
      })
    }
    this.formSets.push(newFormSet);
  }


  selectFormBtn(counter) {
    setTimeout(() => {
      $('.assign-modal-table').scrollTop(0)
    }, 400)
    this.allRowsSelection.allSelected = false;
    this.currentIndex = counter;
    this.formSets[this.currentIndex].showData = false;

    let selectedTempFormId = [];
    for (let key in this.formSets[counter].assignedFormsList) {
      selectedTempFormId.push(this.formSets[counter].assignedFormsList[key].id);
    }

    /***************** allow multiple forms to publish  ****************/
    if (this.formsUsersDetails && this.formsUsersDetails.forms) {
      for (let form in this.formsUsersDetails.forms) {
        this.formsUsersDetails.forms[form].selected = false;
        this.formsUsersDetails.forms[form].dueDate = this._datePipe.transform(this.formsUsersDetails.forms[form].dueDate);
        for (let selectedForm in selectedTempFormId) {
          if (selectedTempFormId[selectedForm] === this.formsUsersDetails.forms[form].id) {
            this.formsUsersDetails.forms[form].selected = true;
          }
        }
      }
    }
    $('#selectForms').modal('show');
  }

  cancelForms() {
    this.formSets[this.currentIndex].assignedFormsList = [];
    for (let key in this.formsUsersDetails.forms) {
      if (this.formsUsersDetails.forms[key].selected == true) {
        this.formsUsersDetails.forms[key].selected = false;
      }
    }
  }

  assignSelectedForms() {
    this.formSets[this.currentIndex].showData = true;
  }


  navigationForm() {
    if (Object.keys(this.updatedProjectData).length === 0 && this.initiateDGF) {
      this._router.navigate(["response/list"]);
    }
    else this._router.navigate(['/projects/view', this.updatedProjectData.id]);
  }


  submitForm() {
    let proj_id;
    let responses: any = [];
    this.selectForms = false;
    this.dueDateMsg = '';
    for (let p = 0; p < this.formSets.length; p++) {
      if (this.formSets[p].assignedFormsList.length === 0) {
        this.msgTitle = this.formSets[p].title;
        this.selectForms = true;
        this.isSubmitted = false;
        return false;
      }


      if (!this.formSets[p].assignForm.valid) {
        this.isSubmitted = true;
        return false;
      }

      else {
        this.responderUserId = this._projectService.fetchIdForRequest(this.formsUsersDetails.users, this.formSets[p].assignForm.value['formResponder']);
        if (this.formSets[p].assignForm.value['formApprover']) {
          this.approverUserId = this._projectService.fetchIdForRequest(this.formsUsersDetails.users, this.formSets[p].assignForm.value['formApprover']);
        }
        for (let i in this.formSets[p].assignedFormsList) {
          let due_date = this._utilService.isValidDate(this.formSets[p].assignedFormsList[i].dueDate);
          if (!due_date) {
            this.msgTitle = this.formSets[p].title;
            this.dueDateMsg = "Due Date cannot be INVALID, BLANK or in the PAST, please enter a valid date in MM/DD/YYYY format for " + "<b>" + this.msgTitle + "</b>";
            return false;
          }

          if (this.formSets[p].assignedFormsList[i].id) {
            responses.push({
              "assignedToId": this.responderUserId,
              "currentApproverId": this.approverUserId || null,
              "responseStatusId": this.responseStatusData.id,
              "startDate": new Date().toISOString(),
              "dueDate": this._datePipe.transform(this.formSets[p].assignedFormsList[i].dueDate).replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "T00:00:00Z",
              "formId": this.formSets[p].assignedFormsList[i].id,
              "title": this.formSets[p].assignedFormsList[i].title,
              "description": this.formSets[p].assignedFormsList[i].description
            })
          }
        }
      }
    }
    const NormalRequest = {
      "clientId": this.initiateDGF_clientId === -1 ? 0 : this.initiateDGF_clientId,
      "responses": responses
    }
    if (Object.keys(this.updatedProjectData).length === 0 && this.initiateDGF) {
      proj_id = this.initiateProjId === -1 ? 0 :this.initiateProjId
    }
    else proj_id = this.updatedProjectData.id;
    this._formService.publishForms_user(NormalRequest, proj_id).subscribe(
      forms => {
        this.isError = false;
        this.assignedForms = forms;
        this._projectDto.setPublishForms(true);
        this._projectService.projVisibility$.next(-1);
       // this._projectService.projVisibility$.complete();
        this._projectService.clientVisibility$.next(0);
       // this._projectService.clientVisibility$.complete();
        this.navigationForm();
      },
      error => { this.isError = true, this.errorMsg = error });
  }
  /********************DRAWER CHANGES BEGIN*************************/
  openAddDrawer(index, ctrlName) {
    this._drawerService.tempData = { index, ctrlName };
    this._drawerService.open();
  }
  setFormControlValue(index, ctrlName, entityName) {
    if (index != null && ctrlName != null && entityName != null) {
      if (this.formSets.length > 0) {
        const form = this.formSets[index].assignForm;
        if (form && form.controls[ctrlName])
          form.controls[ctrlName].setValue(entityName);
      }
    }
  }
  /* To update responder-approver list in case suggestion is updated by parent */
  updateUsers() {
    let allApiData = this._formService.formsUsersData();
    this.formsUsersDetails.users = allApiData.users;
  }
  /********************DRAWER CHANGES END*************************/
}



