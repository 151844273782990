import { Component, OnInit } from '@angular/core';
import { IPager } from 'src/app/shared/pagination';

@Component({
  selector: 'app-project-workflow-console-list',
  templateUrl: './console-list.component.html',
  styleUrls: [ './console-list.component.css' ]
})
export class ConsoleListComponent implements OnInit {

  /**
   * ALL THE DATA AND IMPLEMENTATIONS IN THIS CLASS AND RELATED HTML FILE
   * ARE ONLY FOR DESIGN, SHOWCASE AND TESTING OF THIS FEATURE
   */

  displayColumns = [ "Implementation Name", "Business Units", "Products", "Tasks", "Phases", "Implementation Owner", "Execution Date", "Assigned To", "Status" ]
  keys = [ "implementationName", "businessUnits", "products", "tasks", "phases", "implementationOwner", "executionDate", "assignedTo", "status" ]
  implementations: any
  pages: any
  currentPage = 1
  pagerObject: IPager = {
    totalPages: 4,
    totalCount: 100,
    pageSize: 10,
    currentPage: 1
  }
  refreshedDate: Date = new Date()
  canDelete = (_: any) => true

  constructor() { }

  ngOnInit(): void {
    fetch('assets/ProjectImplementationConsoleDummyData.json')
      .then(response => response.json())
      .then(data => {
        this.implementations = []
        for (let i = 0; i < data.length; i += this.pagerObject.pageSize) {
          this.implementations.push(data.slice(i, i + this.pagerObject.pageSize))
        }
      });
  }
  
  changePage(page) {
    this.currentPage = page
  }

  toggleView(grid: boolean) {
    
  }

}
