import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { IQuery } from '../shared/query';

@Injectable({
  providedIn: 'root'
})
export class ClientCommitmentsService {
  private _clientcommitmentBaseUrl: string = "clientcommitments";

  constructor(private http: HttpClient) { }

  public getPageTemplateResponseList(pageTemplateId: number, queryParams: IQuery) {
    // let params = new HttpParams();

    // for (const key of Object.keys(queryParams)) {
    //   let value = queryParams[key];
    //   params = params.append(key, value);
    // }

    const options = { 'refresh': 'true' };

    return this.http.post(`${this._clientcommitmentBaseUrl}/responses/${pageTemplateId}`, { parameter: queryParams }, { headers: options, observe: 'response' });
  }

  // START: Temporay code. Will be updated after API is ready
  public getMappingSuggestions(pageResponseId: number, queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    const options = { 'refresh': 'true' };

    return this.http.get(`${this._clientcommitmentBaseUrl}/responses/${pageResponseId}/mapping/suggestions`, { headers: options, params: params, observe: 'response' });
  }

  public getClarityProjectDetail(pageTemplateId: number, queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    const options = { 'refresh': 'true' };

    return this.http.get(`${this._clientcommitmentBaseUrl}/${pageTemplateId}/responses`, { headers: options, params: params, observe: 'response' });
  }

  // END: Temporay code.

  public deleteLatestCommitmentReviewByClientId(clientId: number) {
    return this.http.delete(`${this._clientcommitmentBaseUrl}/clientdashboard/commitments/${clientId}/deleteLatestCommitmentReview`);
  }

  public getFormFieldSuggestionByUser(pageTemplateId: number) {
    return this.http.get(`${this._clientcommitmentBaseUrl}/suggestionsbyuser/${pageTemplateId}`);
  }

  public getClientsForCopy() {
    const params = new HttpParams();
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._clientcommitmentBaseUrl}/clientdashboard/getrmclients`, { headers: options, params: params, observe: 'response' });
  }

  public copy(responseId: number, clientId: number) {
    return this.http.post(`${this._clientcommitmentBaseUrl}/${responseId}/copy`, { clientId: clientId });
  }

  public getProjectDetailForMapFulfilment(pageTemplateId: number, projectId: string) {
    return this.http.get(`${this._clientcommitmentBaseUrl}/pagetemplates/${pageTemplateId}/projects/${projectId}`);
  }

  public mapPageTemplateResponse(pageTemplateId: number, pageResponseId: number, projectId: string) {
    return this.http.post(`${this._clientcommitmentBaseUrl}/pagetemplates/${pageTemplateId}/pagetemplateresponses/${pageResponseId}/map`, { projectId: projectId });
  }

  public unmapPageTemplateResponse(pageTemplateId: number, pageResponseId: number, projectId: string) {
    return this.http.delete(`${this._clientcommitmentBaseUrl}/pagetemplates/${pageTemplateId}/pagetemplateresponses/${pageResponseId}/projects/${projectId}/unmap`);
  }

  public getPageTemplateById(id: number) {
    const params = new HttpParams();
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._clientcommitmentBaseUrl}/pagetemplates/${id}`, { headers: options, params: params, observe: 'response' });
  }

  public getPageTemplateResponseById(id: number) {
    return this.http.get(`${this._clientcommitmentBaseUrl}/${id}`);
  }

  public deletePageResponse(id: number) {
    return this.http.delete(`${this._clientcommitmentBaseUrl}/${id}`);
  }

  createMultiplePageResponses(responseData) {
    return this.http.put<any>(`${this._clientcommitmentBaseUrl}/CreatePageTemplateResponseForMultipleResponses`, responseData);
  }

  public exportPageTemplateResponseList(id: number, queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }

    const options = { 'refresh': 'true' };
    return this.http.get(`clientcommitments/pagetemplate/${id}/exportResponses/`, { headers: options, params: params, observe: 'response' });
  }
}