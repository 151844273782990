import { Component, OnInit } from '@angular/core';
import { SurveyService } from 'src/app/services/survey.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ResponsesService } from 'src/app/services/responses.service';
import { ResponsesStatuses } from 'src/app/core/constants';
@Component({
  templateUrl: './survey-confirmation.component.html'
})
export class SurveyConfirmationComponent implements OnInit {
  id: any;
  surveyId: any;

  constructor(private surveyService: SurveyService,
              private responsesService: ResponsesService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe((params) => {
      if (params['id']) {
        this.surveyId = params['id'];
      }
    });
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.id = params['id'];
      }
    });
    this.responsesService.getResponseById(this.id).subscribe(
      result => {
        if (this.route?.snapshot?.data?.isUnauthenticated && (result.responseStatusTitle !== ResponsesStatuses.UnderReview || result.surveyId !== this.surveyId)) { //Different to UnderReview or different surveyId
          this.router.navigate(["../../expired"], { relativeTo: this.route });
          return;
        }
      },
      err => { //Response not found
        this.router.navigate(["../../expired"], { relativeTo: this.route });
      }
    );
  }
}
