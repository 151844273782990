import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICreateTopic, ICreateTopicForm } from '../../../admin/manageForms/models/createTopic';
import { HelpService } from '../../../services/help.service';
import { IUser } from 'src/app/shared/user';
import { UserService } from '../../../services/user.service';
import { PERMISSIONS } from '../../../core/constants';
import { IQuery } from '../../../shared/query';
import { environment } from '../../../../environments/environment';
import { ManageHelpService } from '../../../admin/help/services/manageHelp.service';
import { PdfGenrateComponent } from 'src/app/pdf-genrate/pdf-genrate.component';
declare var $: any;
@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./topic-list.component.css']
})
export class TopicListComponent implements OnInit {
  catId: any;
  topics: ICreateTopic[];
  searchString: any = '';
  i: number = 0;
  userData: IUser;
  popularTopic: ICreateTopic;
  catName: any;
  queryParam: IQuery;
  currentPageNumber = 1;
  isError: boolean = false;
  successMsg: any = '';
    name: any;
  deleteId: any;
  isAdmin: boolean = false;
  isEdit: boolean = false;
  isDomainAdmin: boolean = false;
  @ViewChild('pdfGenerated') addPdfGenerated: PdfGenrateComponent;
  
  constructor(private route: ActivatedRoute, 
    private router: Router, 
    private helpService: HelpService, 
    private userService: UserService, 
    private _manageHelp: ManageHelpService) { }

  ngOnInit(): void {
    this.userData = this.userService.getUserListData();
    this.isAdmin = this.userData.isAdmin;
    this.isDomainAdmin = this.userData.isFiservDivisionAdmin;
    this.isEdit = this.userService.getPermissions()["showhelpArticlelink"];
    
    this.route.params.subscribe((params) => {
      this.catId = params['id'];
      this.helpService.getHelpCategoryDetailsById(this.catId).subscribe(result => {
        this.catName = result.name
        this.getTopicList(this.catId)
        this.initQueryParams();
        this.helpService.recommendedTopic(this.catId, result.isCommonCategory).subscribe(suggestions => {
          this.popularTopic = suggestions;
        })
      })
    });
  }

  onClick_delete() {
    this.helpService.deleteHelpTopic(this.deleteId).subscribe(
      () => {
        this.isError = false;
        this.getTopicList(this.catId);
        this.successMsg = `The Resource '${this.name}' has been deleted.`;
      },
      error => {
        this.isError = true;
      }
    );
  }
  deleteTopic(id: any, name: any): void {
    this.name = name;
    this.deleteId = id;
    $('#deleteModal').modal('show');
  }

  downloadPDF(id: number) {
    this.helpService.getTopicById(id).subscribe(
      res => {
        this._manageHelp.downloadedTopic = res;
        const formJson = JSON.parse(this._manageHelp.downloadedTopic.formJSON);
        this.expandTheCollapsedFormPanel(formJson);
        this.addPdfGenerated.triggerPDF({ 
          submission: {}, 
          form: formJson, 
          id: this._manageHelp.downloadedTopic.id, 
          title: this._manageHelp.downloadedTopic.title 
        });
      },
      error => { }
    );
  }

  expandTheCollapsedFormPanel(formJson){
    if (!formJson.components) {
      return;
    }

    for(const component of formJson.components) {
      if(component.type === 'panel' && component.collapsed){
        component.collapsed = false;
        this.expandTheCollapsedFormPanel(component);
      }
    }
  }

  onPdfLoad(event) {
    this.exportHelpTopicToPdf(event);
  }

  exportHelpTopicToPdf(event) {
    const {formHtml, formData} = event;
    const body = {
      htmlString: formHtml
    };

    const id = formData.id;
    this.helpService.exportPDF(id, body).subscribe(
      (res) => {
        const newBlob = new Blob([res.body], { type: 'application/pdf' });
        const nav = (window.navigator as any);
        if (nav && nav.msSaveOrOpenBlob) {
          nav.msSaveOrOpenBlob(newBlob);
          return;
        }

        this.openPdfFile(res.body, formData.title);
        this._manageHelp.downloadedTopic = {} as ICreateTopic;
      },
      (error) => {}
    );
  }

  private openPdfFile(fileBlob: Blob, title: string){
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(fileBlob);
    const filename = `${title}.pdf`;
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  canCreateHelp(): boolean {
    if (this.userData && this.userData.permissions.includes(PERMISSIONS.HelpTopics_ReadWrite_All) && this.isEdit) {
      return true;
    }

    return this.userData && this.userData.isAdmin;
  }
  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }
  getTopicList(categryId: any) {
    this.initQueryParams();
    const category = this.helpService.getTopicByCatId(categryId, this.queryParam).subscribe(suggestions => {

      this.topics = suggestions.helpTopics;
      for (var i = 0; i < this.topics.length; i++) {
        if (this.isAdmin)
          this.topics[i].editAccessWith = true;
        else if (this.isDomainAdmin && !this.topics[i].isCommonCategory)           
          this.topics[i].editAccessWith = true;
        else if (this.topics[i].cretedById == this.userData.id) 
          this.topics[i].editAccessWith = true;
        else
          this.topics[i].editAccessWith = false;
      }
    })
  }
  navigate(id: number) {
    this.router.navigate(['/help/topic/' + id]);
  }
  navigateTo(value: any) {
    let a: ICreateTopicForm;
    this._manageHelp.formVal = a;
    this.router.navigate(['/self-service/helpTopic/create']);
  }
}
