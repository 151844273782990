import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { IMasterList, IStatus } from '../shared/status';
import { Observable } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  private masterAPIURL = 'master';
  private masterList: IMasterList;
  public guid = '';
  public apiUrl = {
    'clients/suggestions': null,
    'products/suggestions': null,
    'users/suggestions': null,
    'projects/suggestions': null,
    'responses/suggestions': null,
    'organizations/suggestions': null,
    'projects?pageNumber': null,
    'products?pageNumber': null,
    'clients?pageNumber': null,
    'forms?pageNumber': null,
    'responses?pageNumber': null,
    'forms?title': null,
  }

  private allFilters: Map<string, string> = new Map();
  private allFiltersForResponsesHistory: Map<string, string> = new Map();
  private cancelled: boolean = false;
  private historyCancelled: boolean = false;

  constructor(private http: HttpClient) { }

  callMasterListData(): Observable<IMasterList> {
    return this.http.get<IMasterList>(this.masterAPIURL);
  }

  setMasterListData(data: IMasterList) {
    this.masterList = data;
  }
  setGuid(data: string) {
    this.guid = data;
  }
  getGuid() {
    return this.guid;
  }
  getProjectStatusList(): IStatus[] {
    return this.masterList ? this.masterList.projectStatuses : [];
  }

  getResponseStatusList(): IStatus[] {
    return this.masterList ? this.masterList.responseStatuses : [];
  }

  loadAndApplyFilters(queryParam: any, filterForm: FormGroup): boolean {
    let filterExists =false;
    if(!this.cancelled || this.allFilters.size === 0){
      this.allFilters = new Map();
      return filterExists;
    }
    let patchedVal = {};
    filterExists=true;
    for (let [key, value] of this.allFilters) {
      patchedVal[key] = value;
      queryParam[key] = value;
    }
    filterForm.patchValue(patchedVal);
    return filterExists;
  }

  saveFilters(filterForm: FormGroup): void {
    let filterMap = new Map();
    Object.keys(filterForm.controls).forEach((key: string) => {
        const abstractControl = filterForm.controls[key];
        if (abstractControl.value) {
          filterMap.set(key, abstractControl.value);
        }
    });
    this.allFilters = filterMap;
  }

  isCancelledClicked(cancelled: boolean): void {
    this.cancelled = cancelled;
  }

  resetFiltersCancelledFlag(): void {
    this.cancelled = false;
  }

  loadAndApplyFiltersDup(filterForm: FormGroup, flow: string): void {
    if(!this.historyCancelled || this.allFiltersForResponsesHistory.size === 0){
      this.allFiltersForResponsesHistory = new Map();
      return;
    }
    let patchedVal = {};

    for (let [key, value] of this.allFiltersForResponsesHistory) {
      patchedVal[key] = value;
    }
    filterForm.patchValue(patchedVal);
  }

  saveFiltersDup(filterForm: FormGroup, flow: string): void {
    let filterMap = new Map();
    Object.keys(filterForm.controls).forEach((key: string) => {
        const abstractControl = filterForm.controls[key];
        if (abstractControl.value) {
          filterMap.set(key, abstractControl.value);
        }
    });
    this.allFiltersForResponsesHistory = filterMap;
  }

  isCancelledClickedHisotry(cancelled: boolean): void {
    this.historyCancelled = cancelled;
  }

  resetFiltersCancelledFlagHistory(): void {
    this.historyCancelled = false;
  }

}
