<div class="heading-container">
  <h1>Success! </h1>
</div>
<div class="main-container-sub responsive-container">

    <section class="data-table-container col-12">
      <!-- breadcrumb -->
      <!-- Heading main -->   
      <form class="pl-0">          
              <section role="region" aria-labelledby="card3Title2" class=" thankyou-container">
                <div class="messagebox">
                  <div class="greeting-message">
                    <span class="material-icons">check_circle</span> {{content['confirmationMessageThanks']}}
                  </div>                 
                  <p style="font-size: 16px;padding-left: 63px;">{{content['confirmationMessageResponse']}} {{id}}) {{content['confirmationMessageSubmitted']}} </p>   
                </div>
              </section>          
        </form>    
    </section>  
</div>
