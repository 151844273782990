import { Component, OnInit } from '@angular/core';
import { ManageHelpArticleService } from '../services/manageHelp.service';
import { Router } from '@angular/router';
import { manageFormsConst } from 'src/app/core/constants';
import { DrmsFileService } from '../../../../../services/file.service';
import { ICreateTopic, ICreateTopicForm } from '../../../../../admin/manageForms/models/createTopic';
import { HelpService } from '../../../../../services/help.service';
import { UserService } from '../../../../../services/user.service';
declare var $: any;

@Component({
  selector: 'app-create-topic-template',
  templateUrl: './create-topic-template.component.html',
  styleUrls: ['./create-topic-template.component.css']
})
export class CreateTopicTemplateComponent implements OnInit {

  form = {};
  isError: boolean = false;
  successMsg: any = '';
  errorMsg: string = '';
  nextState: string = '';
  isResponse: boolean = false;
  selectedFormsId: any = [];
  initiateJson: any;
  formbuilder = {
    builder: {
      basic: {
        components: {
          htmlelement: true,
          content: true,
          textfield: false,
          number: false,
          textarea: false,
          password: false,
          checkbox: false,
          selectbox: false,
          select: false,
          day: false,
          radio: false,
          button: false,
          selectboxes: false
        }
      },
      custom: {
        title: 'Custom Components',
        default: false,
        weight: 0,
        components: {
          link: {
            title: 'HyperLink',
            key: 'link',
            icon: 'link',
            schema: {
              label: 'HyperLink',
              tableView: false,
              key: 'link',
              type: 'htmlelement',
              input: false,
              "tag": "a",
              "attrs": [
                {
                  "attr": "href",
                  "value": ""
                },
                {
                  "attr": "target",
                  "value": "_blank"
                }
              ],
              "content": "click here",
              "refreshOnChange": false
            }
          }
        }
      },
      advanced: false,
      layout: {
        components: {
          panel: true,
          tabs: false,
          well: false,
          table: false,
          fieldset: true,
          columns: true,
          hidden: false,
        }
      },
      data: false,
      premium: false,
    },
    fileService: new DrmsFileService()
  };
  formJson = {};
  formName: string = '';
  selectedFormsData: any = [];
  orgName: string = '';
  productName: string = '';
  templateOpton: string = '';
  templateName: string = '';
  formDescription: string = '';
  searchString: string = '';
  formDetails: ICreateTopicForm;
  showSuccessMsg: boolean = false;
  copyFormJson: any;
  topicName: string;
  category: any;
  isEdit: boolean = false;
  topics: ICreateTopic;
  json: any;
  organizationDetails: any;
  topicDetails: ICreateTopicForm;
  constructor(private _manageHelp: ManageHelpArticleService,
    private _router: Router,
    private helpService: HelpService,
    private _userService: UserService) { }
  
  initiateForm() {
    if (this._manageHelp.formVal) {
      this.formDetails = JSON.parse(JSON.stringify(this._manageHelp.formVal));
      this.productName = this._manageHelp.formVal.productName;
      this.category = this._manageHelp.formVal.topicCatName;
      this.formDescription = this._manageHelp.formVal.topicDes;
      this.topicName = this._manageHelp.formVal.topicName;
      this.formJson = this.form = this.copyFormJson = this._manageHelp.formVal.json ?
      JSON.parse(this._manageHelp.formVal.json) :
      {
        title: this.productName,
        type: 'form',
        display: 'form',
        components: [{
          "label": "HTML",
          "attrs": [
            {
              "attr": "",
              "value": ""
            }
          ],
          "content":"Dummy content",
          "refreshOnChange": false,
          "key": "html",
          "type": "htmlelement",
          "input": false,
          "tableView": false
        }]
      };
    }
  }
  ngOnInit(): void {
    this.organizationDetails = Object.assign({}, this._userService.getUserListData());
    if (this._router.url.indexOf('edit') !== -1) {
      this.isEdit = true
      //this.route.params.subscribe((params) => {
      //const category = this.helpService.getTopicById(params.id).subscribe(suggestions => {

      //  this.topics = suggestions;
      //  this.json = JSON.parse(this.topics.formJSON);
      //  let topicDetails: ICreateTopicForm = {
      //    id: params.id,
      //    topicDes: this.topics.description,
      //    orgId: this.organizationDetails.organizationId,
      //    orgName: this.organizationDetails.organizationTitle,
      //    productName: this.topics.entityName,
      //    productId: this.topics.entityId,
      //    topicName: this.topics.title,
      //    json: this.topics.formJSON,
      //    topicCate: this.topics.helpCategoryName,
      //    topicCatName: this.topics.helpCategoryName,
      //    organization: this.organizationDetails.organizationTitle
      //  }
      //  this._manageHelp.formVal = topicDetails;
      //  this.initiateForm();
      //});
      //})
    }
    this.initiateForm();
    //this.saveInterval = setInterval(() => {
    //  this.submitForm(false, true);
    //}, this.intervalTime);
  }
  onChange(data: { form: {} }) {
    this.formJson =  data.form;
  }

  editForm() {
    this._manageHelp.formVal.json = JSON.stringify(this.form);
    this._manageHelp.isReturn = true
    if (!this.isEdit){
      this._router.navigate(['/self-service/helpTopic/create']);
    }
    else{
      this._router.navigate(['/self-service/helpTopic/edit/' + this._manageHelp.formVal.id]);
    }
  }

  cancel() {
    let a: ICreateTopicForm;
    this._manageHelp.formVal = a;
    this._router.navigate(['/self-service/helpTopic']);
  }

  validateFormJson(formJson: any) {
    let json: any;
    this.isError = false;
    this.errorMsg = '';
    if (formJson === undefined) {
      this.isError = true;
      this.errorMsg = manageFormsConst.errorMessages.undefinedFormJsonMessage;
      return;
    }

    if (formJson.components !== undefined) {
      json = formJson['components'][0];
      if (json.components.length === 0) {
        this.isError = true;
        this.errorMsg = this._manageHelp.formVal.topicName + manageFormsConst.errorMessages.blankFormMessage;
        return;
      }
    }
    
    this.isError = true;
    this.errorMsg = this._manageHelp.formVal.topicName + manageFormsConst.errorMessages.blankFormMessage;
    return;
  }

  ngOnDestroy() {
   // clearInterval(this.saveInterval);
  }

  /*********  Preview & Manage Asset Changes  ***********/
  previewForm() {
    if (this.showDataLossModal) {
      this.nextState = "preview";
      $('#dataLoss').modal('show');
    }
    else {
      this.proceedToPreview();
    }
  }

  get showDataLossModal() {
    return !(JSON.stringify(this.copyFormJson) === JSON.stringify(this.formJson));
  }

  proceedToPreview() {

  }

  proceed() {

  }

  submitForm(val: boolean = true) {
    let body = {
      "title": this._manageHelp.formVal.topicName,
      "description": this._manageHelp.formVal.topicDes,
      "formJSON": JSON.stringify(this.form),
      "helpCategoryId": this._manageHelp.formVal.topicCate,
      "isPublishable": this._manageHelp.formVal.isPublishable,
      "isRecommended": this._manageHelp.formVal.isRecommended
    }

    body['entityLevel'] = this._manageHelp.formVal.entityLevel;
    if(this._manageHelp.formVal.entityLevel === "Product")
      body['productId'] = this._manageHelp.formVal.productId;
    if(this._manageHelp.formVal.entityLevel === "Form")
      body['formId'] = this._manageHelp.formVal.formId;
    if(this._manageHelp.formVal.entityLevel === "Organization")
      body['organizationId'] = this._manageHelp.formVal.organizationId;

    if (this.isEdit) {
      body['id'] = this._manageHelp.formVal.id;
      body["helpCategoryId"] = this._manageHelp.formVal.topicCate,
      this.helpService.updateTopic(body).subscribe(data => {
        let a: ICreateTopicForm;
        this._manageHelp.successMessage = `Article '${this._manageHelp.formVal.topicName} ' has been updated.`;
        this._manageHelp.formVal = a;
        this._router.navigate(['/self-service/helpTopic/view/' + body['id']]);
      });
    } else {
      this.helpService.createTopic(body).subscribe(data => {
        let a: ICreateTopicForm;
        this._manageHelp.formVal = a;
        this._router.navigate(['/self-service/helpTopic']);
      });
    }
  }
}
