import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { IDashboardAnalyticsSummary } from '../shared/dashboardAnalyticsSummaryDTO';
import { ISuggestions } from '../shared/suggestion';
import { IForms } from '../shared/form';
import { CustomHttpParamEncoder } from '../shared/custom-encoder';
import { IDashboardAnalyticsProject } from "../shared/dashboardAnalyticsProjectDTO";
import { IDashboardAnalyticsForm } from "../shared/dashboardAnalyticsFormDTO";


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private dashboardAnalyticsSummaryAPIURL = 'DashBoard/DashboardSummary';
    private dashboardAnalyticsProjectAPIURL = 'DashBoard/DashboardProjects';
    private dashboardAnalyticsFormAPIURL = 'DashBoard/DashboardForms';
    private dashboardSummary: IDashboardAnalyticsSummary;
  
   constructor(private http: HttpClient) {
    }
//#region Dashboard Analtics Summary
    getDashBoardAnalyticsSuammary(): Observable<IDashboardAnalyticsSummary> {
        return this.http.get<IDashboardAnalyticsSummary>(this.dashboardAnalyticsSummaryAPIURL);
    }
  
    getdashboardAnalyticsSummaryData() {
        return this.dashboardSummary;
    }

    setdashboaradAnalyticsSummaryData(data: IDashboardAnalyticsSummary) {
        this.dashboardSummary = data;
    }
    //#endregion

    //#region Dashboard Analtics Projects
    getDashBoardAnalyticsProjects(): Observable<IDashboardAnalyticsProject> {
        return this.http.get<IDashboardAnalyticsProject>(this.dashboardAnalyticsProjectAPIURL);
    }
    //#endregion

    //#region Dashboard Analtics Projects
    getDashBoardAnalyticsForms(): Observable<IDashboardAnalyticsForm> {
        return this.http.get<IDashboardAnalyticsForm>(this.dashboardAnalyticsFormAPIURL);
    }
    //#endregion
}
