<nav role="navigation" class="navbar navbar-expand navbar-light navbar-internal">
  <a class="navbar-brand cursor-default">
    <!--<img alt="i360" src="assets/images/i360_Logo_White.png">-->
    <img alt="fiserv" src="assets/images/Fiserv Logo.png">
  </a>


  <div class="" id="inverseNavbarSupportedContent" style="margin-right: 0;">

    <ul class="navbar-nav navbar-main justify-content-end">
      <li class="nav-item dropdown right-side-dropdown-menu">

        <div class="nav-item form-responses-width user-dropdown-container">
          <div href="javascript:void(0);" class="nav-link dropdown-toggle user-dropdown" id="inverseNavbarUserDropdown">
            <img *ngIf="loggedInName" src="assets/images/thumbnail-150x150.png" alt="" class="thumbnail img-w28 mr-1">
            <span class="uname">
              {{loggedInName}} 

            </span>


          </div>

        </div> 
      </li>
    </ul>
  </div>


</nav>
