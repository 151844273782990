import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from '../../../core/constants';
import { FormsService } from '../../../services/forms.service';
import { Directive, HostListener } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-put-on-hold',
  templateUrl: './put-on-hold.component.html',
  styleUrls: ['./put-on-hold.component.css']
})
export class PutOnHoldComponent implements OnInit {
  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.disabled = true;
  }
  @Input() data: any;
  @Output() successUpdateApprover = new EventEmitter
  commentValue: any;
  approvalForm: FormGroup;
  isSubmitted: boolean;
  _maxLengths: any;
    isError: boolean;
  errorMsg: any;
  action: any;
  isRejectedError: any;
  regexValidationMessage: any;
  constructor(private formsService: FormsService) { }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.initHoldCommentsForm();
  }
  submitApprovalForm(isrejectedFlow: any = '') {
    this.isSubmitted = true;
    let approverSentBackLevel = 0;
    let eventName = this.action;
    if (this.approvalForm.valid) {
      this.commentValue = this.approvalForm.get('comments').value;
      // this.submitCommentHandle(eventName);

      let subscriber;

      let body = {
        "status": this.action,
        "comments": this.approvalForm.get('comments').value,
        "category":"Comment"
      };
      subscriber = this.formsService.holdResponse(body, this.data.id);

      subscriber.subscribe(
        () => {
          this.isError = false;

          let successMessage = `The form '${this.data.title}' has been  `;
          if (this.action == "cancel")
              successMessage += 'cancelled.';
          else
              successMessage += 'put on hold.';
       //   this._manageForm.approvalMsg = successMessage;
       //   this.callResponseListService();
          this.successUpdateApprover.emit({ updateApproverSuccessMsg: successMessage });
          $('#rejectedComments').modal('hide');


        },
        error => { this.isError = true; this.errorMsg = error; }
      );
    }

  }
  initHoldCommentsForm(action: any = '') {
    this.action = action;
    this._maxLengths = MAX_LENGTHS;
    let validatorArray = [Validators.maxLength(this._maxLengths.Approval.Comments), Validators.pattern(REGEX_PATTERNS.description_comment)]

    validatorArray.push(Validators.required);
    //Comments are mandatory when response is rejected
    this.approvalForm = new FormGroup({
      comments: new FormControl('', validatorArray)
    });
    this.isSubmitted = false;
  }

  submitCommentHandle(pageName: any = 'No') {

    if (this.commentValue?.trim() != '') {
      let body = {
        "responseId": this.data.id,
        "comment": this.commentValue,
        "pageName": (pageName || ''),
        "category": "Comment"
      };
      if (pageName != 'No') {

        body.pageName = pageName;


      }
      this.formsService.postResponseComment(body, this.data.id).subscribe(
        (data) => {

          this.commentValue = '';
        },

      );
    }
  }
}
