import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root',
})
export class InmemoryDataService {
  forms: form[] = [];
wb: XLSX.WorkBook;
  constructor() {}

  createForm(form: form) {
    this.forms = [...this.forms, form];
  }
	setXls(data:XLSX.WorkBook){
		this.wb = data;
	}
	getXls(){
		return this.wb;
	
		
	}
  getAllForms(): form[] {
    return this.forms;
  }
  

  getFormJson(formId: string): string {
    if(this.forms.length> 0){
    return this.forms.filter((f) => f.formId === formId)[0].formJson;
    } else {
      return null;
    }
  }
  updateFormJson(formId: string,form:form){
    if(this.forms.length> 0){
    this.forms.filter((f) => f.formId === formId)[0].formJson=form.formJson;
    } 
  }
}

export interface form {
  formName: string;
  formId: string;
  formJson: string;
}

