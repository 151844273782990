<!-- Add class of "back-action-container" when having back button in place like below -->
<div class="heading-container fdl-2" >
  <div class="back-action-container">
  <div class="back-button">
    <a href="javascript:void(0);" (click)="goBack()" >
      <span class="material-icons">
        arrow_back
        </span>
      {{returnPage}}
    </a>
  </div>
<h1 *ngIf="!isEdit">{{subHeader}}</h1>
<h1 *ngIf="isEdit">{{createEmailTemplate.get('templateName').value}}</h1>
</div>

</div>

<div class="form-container separate-form-container">
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>

  <p *ngIf="!isEdit"> Please complete below required information to create a new email template.</p>

  <form [formGroup]="createEmailTemplate" autocomplete="off">
    <div class="form-row" *ngIf="(isAdmin && !isEdit)">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="organization" class="asterisk">Organization:</label>
        <fdl-type-ahead formControlName="organization" [collection]="organizationSuggestion" optionField="title"
          placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"
          [(ngModel)]="organizationDetails.organizationTitle" (onSelect)="onOrganizationSelect($event)">
        </fdl-type-ahead>
        <div class="has-error">
          <span
            *ngIf="!createEmailTemplate.get('organization').valid && createEmailTemplate.get('organization').errors?.required && isSubmitted">
            <i class="material-icons">warning</i> Please select an Organization.
          </span>
          <span *ngIf="!createEmailTemplate.get('organization').valid && createEmailTemplate.get('organization').errors?.pattern && createEmailTemplate.get('organization').dirty">
            <i class="material-icons">warning</i>Please enter a valid Organization Name
        </span>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="(isEdit || !isAdmin)">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="organization" class="asterisk">Organization:</label>
        <input type="text" name="text" [(ngModel)]="organizationDetails.organizationTitle" readonly class="form-control"
          id="organization" formControlName="organization">
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="validationCustom01">Template status:</label>
        <fieldset>
          <div class="radio-group d-flex">
            <div class="radio mr-3">
              <input type="radio" id="templateStatusOne" name="isEnabled" value="1" formControlName="isEnabled">
              <label for="templateStatusOne"><span>Enabled</span></label>
            </div>
            <div class="radio">
              <input type="radio" id="templateStatusTwo" name="isEnabled" value="0" formControlName="isEnabled">
              <label for="templateStatusTwo"><span>Disabled</span></label>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="form-row" *ngIf="!isEdit">
      <div class="col-md-6 mb-3 typeahead-wrapper"
        [ngClass]="{'has-error': !createEmailTemplate.get('eventName').valid && (createEmailTemplate.get('eventName').touched || isSubmitted)}">
        <label for="eventName" class="asterisk">Event Name:</label>
        <fdl-type-ahead formControlName="eventName" [collection]="eventSuggestion" optionField="displayName"
          placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"
          [(ngModel)]="selectedEventName" (onSelect)="onEventSelect($event)">
        </fdl-type-ahead>
        <div class="has-error">
          <span
            *ngIf="!createEmailTemplate.get('eventName').valid && createEmailTemplate.get('eventName').errors?.required && (createEmailTemplate.get('eventName').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please select Event Name.
          </span>
          <span *ngIf="invalidEventName || !createEmailTemplate.get('eventName').valid && createEmailTemplate.get('eventName').errors?.pattern && createEmailTemplate.get('eventName').dirty">
            <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
          </span>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="isEdit">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="eventName" class="asterisk">Event Name:</label>
        <input type="text" name="text" [(ngModel)]="readOnlyEventName.displayName" readonly class="form-control"
          id="displayName" formControlName="eventName">
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12 mb-3">
        <label for="AdditionalData">Event Description:</label>
        <textarea type="text" class="form-control" name="description" readonly [(ngModel)]="selectedEventDescription"
          formControlName="description"></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-6 mb-3 typeahead-wrapper" *ngIf="!isEdit"
        [ngClass]="{'has-error': !createEmailTemplate.get('entityLevel').valid && (createEmailTemplate.get('entityLevel').touched || isSubmitted)}">
        <label for="entityLevel" class="asterisk">Entity Level:</label>
        <div class="fdl-select">
          <select name="entityLevel" class="form-control" aria-invalid="false" formControlName="entityLevel" [(ngModel)]='selectedEntityLevelValue' (change)="onEntityLevelSelect()">
            <option value="">Please select</option>
            <option *ngFor="let level of entityLevel | keyvalue" [value]="level.value">
              {{level.value}}
            </option>
          </select>
        </div>
        <div class="has-error">
          <span
            *ngIf="!createEmailTemplate.get('entityLevel').valid && (createEmailTemplate.get('entityLevel').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please select entity level.
          </span>
        </div>
      </div>
      <div class="col-md-6 mb-3 typeahead-wrapper" *ngIf="isEdit">
        <label for="entityLevel" class="asterisk">Entity Level:</label>
        <input name="entityLevel" type="text" class="form-control" formControlName="entityLevel" [(ngModel)]='selectedEntityLevelValue' readonly>
      </div>

      <div class="col-md-6 mb-3 typeahead-wrapper"
        *ngIf="isEdit && (selectedEntityLevelValue != 'Organization' && selectedEntityLevelValue != 'System')">
        <label for="entityName" class="asterisk">Entity Name:</label>
        <input *ngIf="selectedEntityLevelValue=='Product'" name="entityName" type="text" class="form-control"
          formControlName="entityNameProduct" [(ngModel)]='readOnlyEntityName' readonly>
        <input *ngIf="selectedEntityLevelValue=='Form'" name="entityName" type="text" class="form-control"
          formControlName="entityNameForm" [(ngModel)]='readOnlyEntityName' readonly>
        <input *ngIf="selectedEntityLevelValue=='PageTemplate'" name="entityName" type="text" class="form-control"
          formControlName="entityNamePageTemplate" [(ngModel)]='readOnlyEntityName' readonly>
      </div>

      <div class="col-md-6 mb-3 typeahead-wrapper" *ngIf="!isEdit">
        <label
          *ngIf="selectedEntityLevelValue && selectedEntityLevelValue != 'Organization' && selectedEntityLevelValue != 'System'"
          for="EntityName" class="asterisk">Entity Name:</label>
        <fdl-type-ahead *ngIf="selectedEntityLevelValue=='Product'" formControlName="entityNameProduct"
          [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
          [optionsLimit]="100" [minLength]="0" (onSelect)="onEntityProductSelect($event)"
          [(ngModel)]="productText" (input)="typeaheadLoadingProducts()">
        </fdl-type-ahead>
        <fdl-type-ahead *ngIf="selectedEntityLevelValue=='Form'" formControlName="entityNameForm"
          [collection]="formSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
          [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityFormSelect($event)">
        </fdl-type-ahead>
        <fdl-type-ahead *ngIf="selectedEntityLevelValue=='PageTemplate'" formControlName="entityNamePageTemplate"
          [collection]="pageTemplateSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
          [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityPageTemplateSelect($event)">
        </fdl-type-ahead>
        <div class="has-error">
          <span
            *ngIf="invalidProductName || selectedEntityLevelValue=='Product' && !createEmailTemplate.get('entityNameProduct').valid && createEmailTemplate.get('entityNameProduct').errors?.required && (createEmailTemplate.get('entityNameProduct').dirty || isSubmitted)">
            <i class="material-icons">warning</i> Please select a Product.
          </span>
          <!-- <span
            *ngIf="!isEntityNameValid(productText)">
            <i class="material-icons">warning</i> {{regexValidationMessage.PRODUCT_NAME_VALIDATION_MESSAGE}}
          </span> -->
          <span
            *ngIf="invalidFormName || selectedEntityLevelValue=='Form' && !createEmailTemplate.get('entityNameForm').valid && createEmailTemplate.get('entityNameForm').errors?.required && (createEmailTemplate.get('entityNameForm').dirty || isSubmitted)">
            <i class="material-icons">warning</i> Please select a Form.
          </span>
          <span
          *ngIf="!createEmailTemplate.get('entityNameForm').valid && createEmailTemplate.get('entityNameForm').errors?.pattern && createEmailTemplate.get('entityNameForm').dirty">
          <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
        </span>
          <span
            *ngIf="invalidPageTemplateName || selectedEntityLevelValue=='PageTemplate' && !createEmailTemplate.get('entityNamePageTemplate').valid && createEmailTemplate.get('entityNamePageTemplate').errors?.required && (createEmailTemplate.get('entityNamePageTemplate').dirty || isSubmitted)">
            <i class="material-icons">warning</i> Please select a Page Template.
          </span>
          <span
            *ngIf="!createEmailTemplate.get('entityNamePageTemplate').valid && createEmailTemplate.get('entityNamePageTemplate').errors?.pattern && createEmailTemplate.get('entityNamePageTemplate').dirty">
            <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
          </span>
        </div>
      </div>
    </div>
    <div class="form-row" *ngIf="!isEdit">
      <div class="col-md-6 mb-3">
        <label for="validationCustom01">Copy from existing template?:</label>
        <fieldset>
          <div class="radio-group d-flex">
            <div class="radio mr-3">
              <input type="radio" id="templateCopyOne" name="isCopy" value="1" formControlName="isCopyEnabled"
                (change)="handleChange($event)">
              <label for="templateCopyOne"><span>Yes</span></label>
            </div>
            <div class="radio">
              <input type="radio" id="templateCopyTwo" name="isCopy" value="0" formControlName="isCopyEnabled"
                (change)="handleChange($event)">
              <label for="templateCopyTwo"><span>No</span></label>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6 mb-3 typeahead-wrapper" *ngIf="isCopyEnabled">
        <label for="CopyTemplate">Existing Template:</label>
        <fdl-type-ahead formControlName="copyFromTemplate" [collection]="emailTemplateSuggestion" optionField="title"
          placeholder="Type to search" [scrollable]="true" [optionsLimit]="500"
          (onSelect)="onCopyTemplateSelect($event)" [minLength]="0">
        </fdl-type-ahead>
        <div class="has-error">
          <span
          *ngIf="!createEmailTemplate.get('copyFromTemplate').valid && createEmailTemplate.get('copyFromTemplate').errors?.pattern && createEmailTemplate.get('copyFromTemplate').dirty">
          <i class="material-icons">warning</i> {{regexValidationMessage.EMAIL_TEMPLATE_NAME_VALIDATION_MESSAGE}}
        </span>
        </div>
      </div>
    </div>
    <div class="form-row ">
      <div class="col-md-6 mb-3 typeahead-wrapper"
        [ngClass]="{'has-error': !createEmailTemplate.get('templateName').valid && (createEmailTemplate.get('templateName').touched || isSubmitted)}">
        <label for="TemplateName" class="asterisk">Template Name:</label>
        <input type="text" class="form-control" name="TemplateName" formControlName="templateName">
        <div class="has-error">
          <span
            *ngIf="!createEmailTemplate.get('templateName').valid && createEmailTemplate.get('templateName').errors?.required && (createEmailTemplate.get('templateName').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please enter Template Name.
          </span>
          <span *ngIf="!createEmailTemplate.get('templateName').valid && createEmailTemplate.get('templateName').errors?.pattern && (createEmailTemplate.get('templateName').touched || isSubmitted)">
          <i class="material-icons">warning</i> {{regexValidationMessage.EMAIL_TEMPLATE_NAME_VALIDATION_MESSAGE}}
          </span>
        </div>
      </div>
    </div>
    <div class="biller-survey white-box"></div>
    <br>
    <div class="biller-survey white-box border-0">
      <span>Email Content</span>
    </div>
    <br>
    <div class="form-row">
      <div class="col-md-12 mb-3 typeahead-wrapper"
        [ngClass]="{'has-error': !createEmailTemplate.get('subject').valid && (createEmailTemplate.get('subject').touched || isSubmitted)}">
        <label for="Subject" class="asterisk">Subject:</label>
        <div class="pull-right" style="display:inline">
          <a href="javascript:void(0)" data-toggle="modal" data-target="#copySystemVariables">
            System Variables
          </a>
        </div>
        <textarea type="text" class="form-control" name="Subject" formControlName="subject"></textarea>
        <div class="has-error">
          <span
            *ngIf="!createEmailTemplate.get('subject').valid && createEmailTemplate.get('subject').errors?.required && (createEmailTemplate.get('subject').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please enter Subject.
          </span>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12 mb-3 typeahead-wrapper"
        [ngClass]="{'has-error': !createEmailTemplate.get('body').valid && (createEmailTemplate.get('body').touched || isSubmitted)}">

        <label for="Body" class="asterisk">Email Body:</label>
        <div class="pull-right" style="display:inline">
          <a href="javascript:void(0)" data-toggle="modal" data-target="#copySystemVariables">
            System Variables
          </a>
        </div>
        <angular-editor formControlName="body" [config]="config"></angular-editor>
        <div class="has-error">
          <span
            *ngIf="!createEmailTemplate.get('body').valid && (createEmailTemplate.get('body').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please enter email body.
          </span>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="isAdmin">
      <div class="col-md-12 mb-3">
        <label for="Disclaimer">Disclaimer:</label>
        <angular-editor formControlName="disclaimer" [config]="disclaimerConfig"></angular-editor>
        <div class="has-error">
          <span
            *ngIf="!createEmailTemplate.get('disclaimer').valid && createEmailTemplate.get('disclaimer').errors?.pattern && (createEmailTemplate.get('disclaimer').touched || isSubmitted)">
              <i class="material-icons">warning</i> {{regexValidationMessage.DISCLAIMER_VALIDATOR_VALIDATION_MESSAGE}}
          </span>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="isAdmin">
      <div class="col-md-12 mb-3">
        <label for="PrivacyPolicy">Privacy Policy:</label>
        <angular-editor formControlName="privacyPolicy" [config]="privacyPolicyConfig"></angular-editor>
        <div class="has-error">
          <span
            *ngIf="!createEmailTemplate.get('privacyPolicy').valid && createEmailTemplate.get('privacyPolicy').errors?.pattern && (createEmailTemplate.get('privacyPolicy').touched || isSubmitted)">
              <i class="material-icons">warning</i> {{regexValidationMessage.DISCLAIMER_VALIDATOR_VALIDATION_MESSAGE}}
          </span>
        </div>
      </div>
    </div>
    <span>Distribution List</span>
    <div class="col-md-12 box mb-0" *ngIf="!isDrawerType">
      <div class="col-md-12 p-0  fdl-2">
        <label aria-describedby="addAccount" class="w-100">Select/De-Select "To Names":
          <span class="material-icons btn-secondary f-18" data-toggle="tooltip" [title]="toEmailToolTipMessage">info</span>
        </label>
        <button class="btn fdl-btn btn-secondary-light " data-toggle="modal" data-target="#toNameModal" id="addAccount"
          (click)="openUserFieldsDrawer()">
          Select To
          Names
        </button>

        <!-- Selected Names buttons -->
        <div class="filter-wrapper mt-2">
          <div class="filter-tag" *ngFor="let ufield of selectedToForDisplay">
            <div class="tag" data-toggle="tooltip" data-placement="top" title="{{ufield.description}}">
              {{ufield.displayName}} <a href="javascript:void(0)" (click)="removeRole(ufield.id)" aria-label="Close"
                class="tag-close">
                <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-12 box mb-0 mt-3">
      <div class="col-md-12 p-0  fdl-2">
        <label aria-describedby="addAccount2" class="w-100">Select/De-Select "CC Names":</label>
        <button class="btn fdl-btn btn-secondary-light " data-toggle="modal" data-target="#ccNameModal" id="addAccount2"
          (click)="openUserFieldsCCDrawer()">
          Select CC
          Names
        </button>
        <!-- Selected Names buttons -->
        <div class="filter-wrapper mt-2">
          <div class="filter-tag" *ngFor="let ufieldCC of selectedCCForDisplay">
            <div class="tag" data-toggle="tooltip" data-placement="top" title="{{ufieldCC.description}}">
              {{ufieldCC.displayName}} <a href="javascript:void(0)" (click)="removeUserFieldsCC(ufieldCC.id)"
                aria-label="Close" class="tag-close">
                <i tabindex="0" aria-hidden="true" class="material-icons">close</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="form-row">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="AdditionalToEmail">Additional "To" Names:
          <span class="material-icons btn-secondary f-18" data-toggle="tooltip" [title]="toEmailToolTipMessage">info</span>
        </label>
        <input type="text" class="form-control" id="additionalToEmail" formControlName="additionalToEmail">
        <span class="color-600">For multiple emails, please use semicolon (;) as separator.</span>
        <div class="has-error">
          <span *ngIf="!createEmailTemplate.get('additionalToEmail').valid && createEmailTemplate.get('additionalToEmail').errors?.pattern
                    && (createEmailTemplate.get('additionalToEmail').touched || isSubmitted)">
            <i class="material-icons">warning</i> {{regexValidationMessage.SEMICOLONSEPERATEDEMAIL_VALIDATION_MESSAGE}}
          </span>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6 mb-3 typeahead-wrapper">
        <label for="AdditionalCCEmail">Additional "CC" Names:</label>
        <input type="text" class="form-control" id="additionalCCEmail" formControlName="additionalCCEmail">
        <span class="color-600">For multiple emails, please use semicolon (;) as separator.</span>
        <div class="has-error">
          <span *ngIf="!createEmailTemplate.get('additionalCCEmail').valid && createEmailTemplate.get('additionalCCEmail').errors?.pattern
                     && (createEmailTemplate.get('additionalCCEmail').touched || isSubmitted)">
            <i class="material-icons">warning</i> {{regexValidationMessage.SEMICOLONSEPERATEDEMAIL_VALIDATION_MESSAGE}}
          </span>
        </div>
      </div>
    </div>
    <div class="fdl-2 button-container" *ngIf="!isDrawerType">
      <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()" aria-label="Cancel">Cancel</button>
      <button type="button" class="btn fdl-btn btn-primary-orange" (click)="emailTemplateSubmit()">Submit</button>
    </div>
  </form>


</div>

<!-- Copy syste variables model -->
<div class="modal fade bd-example-modal-lg" id="copySystemVariables" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard="false" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">System Variables</h4>
        <button type="button" class="close" (click)="crossModal()" data-dismiss="modal" aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">

        <form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
          <h2 class="h4">Search</h2>
          <div class="filter-form-container-column2">
            <div class="filter-form-control pr-4 typeahead-wrapper">
              <fdl-type-ahead label="Variable Name:" formControlName="VariableName" [collection]="systemVariables"
                optionField="emailVariableName" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
              <!-- <fdl-type-ahead id="variableInput" label="Variable Name:" formControlName="VariableName" [collection]="systemVariables"
                optionField="emailVariableName" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (ngModelChange)="filterList()">
              </fdl-type-ahead> -->
            </div>
            <div>
              <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
                (click)="filterList()">Search</button>
              <button *ngIf="filteredVariableNames" type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset"
                (click)="clearFilterVariableNameList()">Reset</button>
            </div>
          </div>
        </form>

        <div class="overflow-300 assign-modal-table table-responsive">
          <app-data-table [rows]="systemVariablesAux" [keys]="keys" [enableSorting]="false"
            [displayColumns]="displayColumns" [showCheckbox]="false" [showActionButton]="true" [actionName]="actionName"
            (actionBtnClicked)="copyVariable($event)" [showMenu]="false">
          </app-data-table>
        </div>

      </div>
      <div class="modal-footer fdl-2 button-container">
        <input type="text" style="height: 2.0rem; width: 275px; background-color: #eeeeee; border: none;"
          id="idCopiedSysVar" [ngModel]="copiedSysVar" readonly="readonly" />
        <p class="text-success mt-2" *ngIf="showCopyMsg">Variable has been copied.</p>
        <button type="button" class="btn fdl-btn btn-secondary-light " (click)="crossModal()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>

  </div>
</div>

<!-- Drawer Replaced with modal To Names-->

<div class="modal fade bd-example-modal-lg" id="toNameModal" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard="false" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Select "TO Names" to Assign</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="overflow-300 assign-modal-table table-responsive">
          <p>Below are the available names to assign. You can select multiple names for a single template.</p>

          <form [formGroup]="userFieldsForm">
            <div class="col-md-6 mb-3" class="has-error"
              *ngIf="userFieldsForm.invalid && (userFieldsForm.dirty || isRoleFormSubmitted)">
              <span>
                <i class="material-icons">warning</i> Please assign at least one name.
              </span>
            </div>
            <fieldset class="table table-hover  table-label f-12">
              <legend class="border-0"></legend>
              <table class="table table-hover  table-label f-12" aria-describedby="tblDesc">
                <tbody>
                  <tr role="row" *ngFor="let control of userFieldsForm.controls.userFields['controls']; let i = index">
                    <td role="cell">
                      <div class="checkbox" formArrayName="userFields">
                        <div>
                          <input type="checkbox" [formControlName]="i" id="{{'chk'+ i}}" />
                          <label style="padding-left: 25px;" title="{{userFields[i].description}}"
                            for="{{'chk'+ i}}"><span>{{userFields[i].displayName}}</span></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </form>
        </div>
      </div>
      <div class="modal-footer fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerCancel()" data-dismiss="modal">Close</button>
        <button type="button" (click)="drawerSubmit()" data-dismiss="modal" class="btn fdl-btn btn-primary-orange float-right">
          Assign
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade bd-example-modal-lg" id="ccNameModal" tabindex="-1" role="dialog" data-backdrop="static"
  data-keyboard="false" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Select "CC Names" to Assign</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close popup">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="overflow-300 assign-modal-table table-responsive">
          <p>Below are the available names to assign. You can select multiple names for a single template.</p>

          <form [formGroup]="userFieldsCCForm">
            <fieldset>
              <legend class="border-0"></legend>
              <table class="table table-hover  table-label f-12" aria-describedby="tblDesc">
                <tbody>
                  <tr role="row"
                    *ngFor="let control of userFieldsCCForm.controls.userFields['controls']; let i = index">
                    <td role="cell">
                      <div class="checkbox" formArrayName="userFields">
                        <div>
                          <input type="checkbox" [formControlName]="i" id="{{'chkcc'+ i}}" />
                          <label style="padding-left: 25px;" title="{{userFields[i].description}}"
                            for="{{'chkcc'+ i}}"><span>{{userFields[i].displayName}}</span></label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </form>
        </div>
      </div>
      <div class="modal-footer fdl-2 button-container">
        <button class="btn fdl-btn btn-secondary-light " (click)="drawerUserFieldsCCCancel()" data-dismiss="modal">Close</button>
        <button type="button" (click)="drawerUserFieldCCSubmit()" data-dismiss="modal"
        class="btn fdl-btn btn-primary-orange float-right">
          Assign
        </button>
      </div>
    </div>

  </div>
</div>
