import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ICreateTopic } from 'src/app/admin/manageForms/models/createTopic';
import { ContentService } from 'src/app/services/content.service';
import { HelpService } from 'src/app/services/help.service';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { ISelfServiceFeature, ISelfServiceFeatureList } from 'src/app/shared/page-template';
import { ManageHelpArticleService } from '../manageHelpArticle/components/services/manageHelp.service';

@Component({
  selector: 'self-service-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class SelfServiceHomeComponent implements OnInit {
  popularTopic: ICreateTopic;
  errorMsg: string = '';
  infoMsg: string = '';
  userData: any;
  selfServiceFeatures: ISelfServiceFeatureList;
  isInfoMsg: boolean = false;
  selfServiceFeatureList: any;
  selfServiceSubFeatureList: any;
  content: any = [];

  constructor(private helpService: HelpService, private permission: PermissionsService, private pageTemplateService: PageTemplateService, private userService: UserService, private contentService: ContentService,
    private manageHelpService: ManageHelpArticleService) {}

  ngOnInit(): void {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    this.userData = this.userService.getUserListData();
    this.helpService.getCommonTopics().subscribe(suggestions => {
      this.popularTopic = suggestions;
    })
    this.initPageTemplateLinks();
    this.manageHelpService.returnPage = '/self-service/home';
  }

  initPageTemplateLinks() {
    this.pageTemplateService.getSelfServiceFeatureList()
    .pipe(take(1))
    .subscribe(
      features => {
        this.selfServiceFeatures = features.body as ISelfServiceFeatureList;
        this.selfServiceFeatureList = [];
        this.selfServiceSubFeatureList = [];

        for (let i in this.selfServiceFeatures) {
          this.selfServiceFeatures[i].featureCount = 0;
          this.selfServiceFeatures[i].features.forEach(feature => {feature.viewAccess = false;});

          for (let j in this.selfServiceFeatures[i].features) {
            if(this.selfServiceFeatures[i].features[j].roles.some(a => this.userData.roles.some(r => r.roleId === a.roleId))) {
              this.selfServiceFeatures[i].features[j].viewAccess = true;
              this.selfServiceFeatures[i].featureCount = this.selfServiceFeatures[i].featureCount + 1;
              this.isInfoMsg = true;
            }

            if(this.selfServiceFeatures[i].features[j].isFooterLink && this.selfServiceFeatures[i].name === 'Other Requests') {
              this.selfServiceSubFeatureList.push(this.selfServiceFeatures[i].features[j]);
            }

            if(this.selfServiceFeatures[i].features[j].isPageTemplate && this.selfServiceFeatures[i].features[j].pageTemplateOrganizationId) {
              if(!(this.userData.isAdmin)) {
                if(this.selfServiceFeatures[i].features[j].pageTemplateOrganizationId != this.userData.organization) {
                  this.selfServiceFeatures[i].features[j].viewAccess = false;
                }

                if(this.userData.isRestrictedAdmin && this.selfServiceFeatures[i].features[j].pageTemplateOrganizationId != this.userData.organization) {
                  let groups = this.userData.groups;
                  this.selfServiceFeatures[i].features[j].viewAccess = false;
                  for (let g in groups) {
                    if(groups[g].organizations.some(org => org.organizationId === this.selfServiceFeatures[i].features[j].pageTemplateOrganizationId)) {
                      this.selfServiceFeatures[i].features[j].viewAccess = true;
                      break;
                    }
                  }
                }
              }
            }
          }

          if(this.selfServiceFeatures[i].name === 'Other Requests') {
            let cnt = 0;
            let len = Object.keys(this.selfServiceFeatures[i].features).length;
            if (len > 5) cnt = 5;
            else cnt = len;

            for(let k = 0; k < cnt ; k++) {
              if(!(this.selfServiceFeatures[i].features[k].isFooterLink)) {
                this.selfServiceSubFeatureList.push(this.selfServiceFeatures[i].features[k]);
              }
            }

            let feature: ISelfServiceFeature = {
              id:  this.selfServiceFeatures[i].id,
              name: this.selfServiceFeatures[i].name,
              description: this.selfServiceFeatures[i].description,
              isActive: this.selfServiceFeatures[i].isActive,
              createdOn: this.selfServiceFeatures[i].createdOn,
              modifiedOn: this.selfServiceFeatures[i].modifiedOn,
              cretedById: this.selfServiceFeatures[i].cretedById,
              modifieById: this.selfServiceFeatures[i].modifieById,
              modifiedByName: this.selfServiceFeatures[i].modifiedByName,
              createdByName: this.selfServiceFeatures[i].createdByName,
              features: this.selfServiceSubFeatureList,
              featureCount: this.selfServiceFeatures[i].featureCount,
              sequenceNumber: this.selfServiceFeatures[i].sequenceNumber,
            };
            this.selfServiceFeatureList.push(feature)
          }
          else {
            this.selfServiceFeatureList.push(this.selfServiceFeatures[i])
          }
        }

        if(!this.isInfoMsg){
          this.infoMsg = "You don't have permission to view this page. Please contact the system administrator.";
        }
      },
    (error)=> {
      this.errorMsg = error;
    });
  }
}
