import { SpbuilderComponent } from "./FormBuilder/spbuilder/spbuilder.component";

import { NgModule } from "@angular/core";
import { SprenderComponent } from "./FormBuilder/sprender/sprender.component";
import { ViewComponent } from "./formui/view/view.component";
import { SurveyVerificationComponent } from './formui/survey/survey-verification/survey-verification.component';
import { ExcelComponent } from "./FormBuilder/excel/excel.component";
import { BackendMainComponent } from "./FormBuilder/backend-main/backend-main.component";
import { FormResponsesComponent } from "./form-responses/form-responses.component";
import { ConfirmationComponent } from "./formui/confirmation/confirmation.component";
import { DirectAccessGuard } from "./SharedModule/Auth/direct-access.guard";
import { ResponsesMainComponent } from "./responses-main/responses-main.component";
import { MsalGuard, MsalRedirectComponent } from "@azure/msal-angular";
import { SurveyConfirmationComponent } from './formui/survey/survey-confirmation/survey-confirmation.component';
import { SurveyExpiredComponent } from './formui/survey/survey-expired/survey-expired.component';
import { SurveyGenerateOtpComponent } from './formui/survey/survey-generate-otp/survey-generate-otp.component';
import { ResponsesHistoryComponent } from './responses-history/responses-history.component';
import { PublishFormsComponent } from './Projects/Components/publish-forms/publish-forms.component';
import { ReloadComponent } from './formui/reload/reload.component';
import { UserInitializeGuard } from './core/user-initialize.guard';
import { PublishFormsAccessGuard } from './Projects/publish-forms-access.guard';
import { MasterResponseStatusResolver } from './services/master-response-status-reslover.service';
import { SubmitRequestComponent } from './submit-request/submit-request.component';
import { SurveyResolver } from './formui/survey/survey-resolver.service';
import { SubmitRequestAccessGuard } from './submit-request/submit-request-access.guard';
import { MultipleExecutionGuard } from './services/multiple-execution.guard';
import { PdfGenrateComponent } from './pdf-genrate/pdf-genrate.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { Routes, RouterModule } from "@angular/router";
import { UserFilterResolver } from "./services/user.filter.resolver.service";
import { ResponseTimelineComponent } from "./response-timeline/response-timeline.component";
import { ResponseCompareComponent } from "./response-compare/response-compare.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'ff',
    component: DashboardComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: "survey/:id",
    children: [
      {
        path: "",
        redirectTo: 'generate-otp', pathMatch: 'full'
      },
      {
        path: "generate-otp",
        component: SurveyGenerateOtpComponent,
        data: { title: "Verify Survey" }
      },
      {
        path: "verification",
        component: SurveyVerificationComponent,
        data: { title: "Verify Survey" }
      },
      {
        path: "respond",
        component: ViewComponent,
        resolve: { resolvedData: SurveyResolver },
        data: { title: " Respond Survey", isUnauthenticated: true },
      },
      {
        path: "confirmation/:id",
        component: SurveyConfirmationComponent,
        data: { title: "Confirm Survey", isUnauthenticated: true }
      },
      {
        path: "expired",
        component: SurveyExpiredComponent,
        data: { title: "Expired Survey", isUnauthenticated: true }
      }
    ]
  },
  {
    path: "backend",
    component: BackendMainComponent,
    children: [
      { path: "excel", component: ExcelComponent },
      { path: "builder", component: SpbuilderComponent },
      { path: "renderer", component: SprenderComponent }
    ]
  },
  {
    path: "reload/:id",
    component: ReloadComponent,
    data: { title: "responses" },
    canActivate: [DirectAccessGuard]
  },
  {
    path: "response",
    component: ResponsesMainComponent,
    canActivateChild: [MsalGuard],
    children: [
      {
        path: "",
        redirectTo: "list",
        pathMatch: "full"
      },
      {
        path: "confirmation/:id",
        component: ConfirmationComponent,
        data: { title: "Response Confirmation" },

      },
      {
        path: "compare/:id",
        component: ResponseCompareComponent,
        data: { title: "Forms Compare"  },

      },
      {
        path: "list",
        component: FormResponsesComponent,
        data: { title: "Form Responses List" },
        resolve: {
          resolveData: MasterResponseStatusResolver,
          userFilter: UserFilterResolver
        },
        canActivate: [UserInitializeGuard]
      },
      {
        path: "view/:edit/:id",
        component: ViewComponent,
        data: { title: "responses" },
        resolve: { resolveData: MasterResponseStatusResolver },
        canActivate: [UserInitializeGuard],
        children: [
          {
            path: "pdf",
            component: PdfGenrateComponent,
          }]
      },

      {
        path: "history/:edit/:id",
        component: ResponsesHistoryComponent,
        data: { title: "Form Responses History List" },
        canActivate: [DirectAccessGuard],
        resolve: { resolveData: MasterResponseStatusResolver },
      },
      {
        path: "timeline/:edit/:id",
        component: ResponseTimelineComponent,
        data: { title: "Form Responses Timeline" },
       canActivate: [UserInitializeGuard],
      //  resolve: { resolveData: MasterResponseStatusResolver },
        
        }
    ]
  },
  {
    path: "initiateDGF/publish-forms",
    component: PublishFormsComponent,
    data: { title: 'Publish Forms', guards: [MsalGuard, PublishFormsAccessGuard] },
    canActivate: [MultipleExecutionGuard],
  },
  {
    path: "submit-request",
    component: SubmitRequestComponent,
    data: { title: 'Submit request', guards: [MsalGuard, SubmitRequestAccessGuard] },
    resolve: { resolveData: MasterResponseStatusResolver },
    canActivate: [MultipleExecutionGuard]
  },
  {
    path: "intake/view/:id",
    component: ViewComponent,

    data: { title: 'Submit request', guards: [MsalGuard, SubmitRequestAccessGuard] },
    resolve: { resolveData: MasterResponseStatusResolver },
    canActivate: [MultipleExecutionGuard]
  }
]
const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
