import { Component, OnDestroy, OnInit, Input} from '@angular/core';
import { PreferenceSettingService } from '../preference-setting/preference-setting.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-prefernce-icon',
  templateUrl: './prefernce-icon.component.html',
  styleUrls: ['./prefernce-icon.component.css']
})
export class PrefernceIconComponent implements OnInit, OnDestroy {

  @Input() addViewProjectClass: boolean = false;
  @Input() listname: any = '';
  constructor(private _preferenceSettingService: PreferenceSettingService ) { }
  
  
  ngOnInit(): void {
  }
  preferenceSetting() {
    if(this.addViewProjectClass) {
      $('.responsive-container').addClass('position-static');
      $('.right-section').addClass('position-static');
      $('#collapseThree').addClass('position-static');
      $('#containerPage').scrollTop(0);
    }
   
    if(this.listname=='')
      this._preferenceSettingService.generateSetting$.next('1');
    else
      this._preferenceSettingService.generateSetting$.next(this.listname);

  }
  ngOnDestroy(): void {
    this._preferenceSettingService.generateSetting$.next('-1');
  }
}

