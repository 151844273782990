<div class="heading-container fdl-2" >
    <div class="back-action-container">
        <div class="back-button" *ngIf="!(_formData)">
            <a href="javascript:void(0);" (click)="goBack()">
            <span class="material-icons">arrow_back</span>
                {{returnPage}}
            </a>
        </div>
    <h1>Form Extraction Template</h1>
    </div>
</div>

<div class="form-container">
    <!-- Error Msg Alert -->
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>
    <!-- Success Msg Alert -->
    <div class="alert alert-success alert-dismissible" *ngIf="isSuccess">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isSuccess=false">×</button>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p [innerHtml]="successMsg"></p>
    </div>

    <p>Please complete below required information to upload a new form extraction template.</p>

    <form class="form form-border"  [formGroup]="fileTemplateForm" autocomplete="off">
        <h3 class="h5 heading-level-3 mt-0">Select Extraction Template</h3>
        <div class="form-row">
            <div class="col-md-6 mb-3 typeahead-wrapper">
                <label for="formName" class="asterisk">Form Template Name</label>
                <fdl-type-ahead id="formName" formControlName="formName" [collection]="formNameSuggestion" optionField="title"
                    placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"
                    (onSelect)="onFormSelect($event)">
                </fdl-type-ahead>
                <div class="has-error">
                    <span *ngIf="(fileTemplateForm.controls.formName.errors?.required || fileTemplateForm.controls.formName.errors?.validateWhiteSpaces)
                        && (fileTemplateForm.controls.formName.dirty || submitted)">
                      <i class="material-icons">warning</i> Please select form template name.
                    </span>
                    <span *ngIf="fileTemplateForm.controls.formName.invalid && fileTemplateForm.controls.formName.errors?.pattern
                        && (fileTemplateForm.controls.formName.dirty || submitted)">
                      <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
                    </span>

                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-6 mb-3"
                [ngClass]="{'has-error': !fileTemplateForm.get('templateType').touched && submitted}">
                <label for="Template" class="asterisk">Template Type</label>
                <div class="fdl-select">
                    <select id="Template" formControlname="templateType" class="form-control" aria-invalid="false" (change)="onTemplateSelect($event)">
                        <option value="">Please select</option>
                        <option *ngFor="let template of templates | keyvalue" [value]="template.value">
                            {{template.value}}
                        </option>
                    </select>
                </div>
                <div class="has-error">
                    <span *ngIf="!fileTemplateForm.get('templateType').touched && submitted">
                        <i class="material-icons">warning</i> Please select template type.
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="selectedTemplateType" class="form-row"
            [ngClass]="{'has-error': (!fileTemplateForm.get('fileName').valid || fileTemplateForm.controls.fileName.errors?.required || !validFile) && (fileTemplateForm.controls.fileName.touched || submitted)}">
            <div class="col-md-6 mb-3">
                <label for="fileName" class="asterisk" *ngIf="selectedTemplateType!='Pdf'">Select {{selectedTemplateType}} file (for customised {{selectedTemplateType}} template)</label>
                <label for="fileName" class="asterisk" *ngIf="selectedTemplateType=='Pdf'">Select Word file (for customised {{selectedTemplateType}} template)</label>
                <div class="custom-file">
                    <label for="fileName" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="selectedFileName"> {{selectedFileName}}</label>
                    <input id="fileName" type="file" class="custom-file-input form-control" multiple="false" formControlname="fileName" (change)="onFileChange($event)" #inputFileControl>
                </div>
                <div class="has-error" *ngIf="(!fileTemplateForm.controls.fileName.valid || fileTemplateForm.controls.fileName.errors?.required || !validFile || !validCommentFileName) && (fileTemplateForm.controls.fileName.touched || submitted)">
                        <span *ngIf="selectedTemplateType!='Pdf' && (!fileTemplateForm.controls.fileName.valid || fileTemplateForm.controls.fileName.errors?.required || !validFile) && (fileTemplateForm.controls.fileName.touched || submitted)">
                            <i class="material-icons">warning</i> Please select valid {{ selectedTemplate }} file.
                        </span>
                        <span *ngIf="selectedTemplateType=='Pdf' && (!fileTemplateForm.controls.fileName.valid || fileTemplateForm.controls.fileName.errors?.required || !validFile) && (fileTemplateForm.controls.fileName.touched || submitted)">
                            <i class="material-icons">warning</i> Please select valid Word file.
                        </span>
                        <span *ngIf="(!fileTemplateForm.controls.fileName.errors?.required && validFile && !validCommentFileName) && (fileTemplateForm.controls.fileName.touched || submitted)">
                          <i class="material-icons">warning</i> {{regexValidationMessage.FILENAME_VALIDATION_MESSAGE}}
                      </span>
                </div>
            </div>
        </div>
        <div class="d-inline" *ngIf="selectedTemplateType">
            <p><a href="javascript:void(0);" (click)="downloadTemplate()">Click here</a> to download sample Form Extraction template.</p>
        </div>

        <div class="form-row">
            <div class="col-md-6 mb-3">
              <label for="templateName" class="asterisk">Template Name</label>
              <input id="templateName" type="text" class="form-control" name="templateName" formControlName="templateName" [(ngModel)]="selectedTemplateName">
              <div class="has-error" *ngIf="fileTemplateForm.controls.templateName.invalid">
                <span *ngIf="(fileTemplateForm.controls.templateName.errors?.required || fileTemplateForm.controls.templateName.errors?.validateWhiteSpaces)
                    && (fileTemplateForm.controls.templateName.touched || submitted)">
                  <i class="material-icons">warning</i> Please enter template name.
                </span>
                <span *ngIf="fileTemplateForm.controls.templateName.invalid && fileTemplateForm.controls.templateName.errors?.pattern
                    && (fileTemplateForm.controls.templateName.touched || submitted)">
                  <i class="material-icons">warning</i> {{regexValidationMessage.FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE}}
                </span>
                <span *ngIf="fileTemplateForm.controls.templateName.errors?.maxlength && (fileTemplateForm.controls.templateName.touched || submitted)">
                  <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.File.Alias}}, please correct it.
                </span>
              </div>
            </div>
        </div>

        <div class="fdl-2 button-container">
            <button *ngIf="!(_formData)" type="button" aria-label="Cancel" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
            <button *ngIf="_formData" type="button" aria-label="Cancel" class="btn fdl-btn btn-secondary-light" (click)="NotNow()">Not Now</button>
            <button type="submit" aria-label="Default" class="btn fdl-btn btn-primary-orange float-right" (click)="Submit()">Submit</button>
        </div>
    </form>
</div>