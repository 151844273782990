import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfGenerateService {
  public generatePdf$ = new BehaviorSubject<string>('-1');
  constructor() { }

  getgeneratePdf(): Observable<string> {
    return this.generatePdf$.asObservable();
  }
  setgeneratePdf(pdf: string) {
    this.generatePdf$.next(pdf);
  }
}
