import { Component, OnInit, AfterViewInit, AfterViewChecked } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {

    breadcrumbs: Array<{ label: string; url: string }>;

    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.breadcrumbs = [];
    }

    // ngAfterViewInit(): void {
    //     this.router.events
    //         .pipe(
    //             filter(event => event instanceof NavigationEnd)).subscribe(event => {
    //                 let currentRoute = this.route.root, url = "";
    //                 this.breadcrumbs = [];
    //                 do {
    //                     const childrenRoutes = currentRoute.children;
    //                     currentRoute = null;
    //                     childrenRoutes.forEach(route => {

    //                         if (route.outlet === "primary") {
    //                             const routeSnapshot = route.snapshot;
    //                             url += "/" + routeSnapshot.url.map(segment => segment.path).join("/");
    //                             if (route.snapshot.data && route.snapshot.data.breadCrumb) {
    //                                 this.breadcrumbs.push({
    //                                     label: route.snapshot.data.breadCrumb,
    //                                     url: url
    //                                 });
    //                             }
    //                             currentRoute = route;
    //                         }
    //                     });
    //                 } while (currentRoute);
    //             });
    // }


}