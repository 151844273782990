<div class="modal fade bd-example-modal-lg" id="emailGroupMembers" tabindex="-1" role="dialog"
data-backdrop="static" data-keyboard="false" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
   <div class="modal-dialog modal-md" role="document">
       <div class="modal-content">
           <div class="modal-header">
               <h4 class="modal-title" id="myLargeModalLabel">{{emailGroupTitle}}</h4>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close popup"><span
                       aria-hidden="true">×</span></button>
           </div>
           <div class="modal-body">
          <p class="strong f-18" >{{emailGroupDetails.length}} members </p>
             <div class="overflow-300 assign-modal-table table-responsive">
                <app-data-table [rows]="emailGroupDetails"
                        [keys]="keys" [enableSorting]="false"  [showCheckbox]="false"
                       [displayColumns]="displayColumns" [showMenu]="false">
                       </app-data-table>
               </div>
           </div>
           <div class="modal-footer fdl-2">
               <button type="button" class=" btn btn-primary-blue ml-2" data-dismiss="modal">OK</button>
           </div>
       </div>

   </div>
</div>