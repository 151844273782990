import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { PermissionsService } from '../services/permissions.service';
import { map } from 'rxjs/operators';
import { allowedPermissions, PERMISSIONS } from '../core/constants';
import { IUser } from '../shared/user';

@Injectable({
  providedIn: 'root'
})
export class SubmitRequestAccessGuard implements CanActivate {
  constructor(private userService: UserService, private permissionService: PermissionsService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.userService.getUserListData();
    if (!user) {
      return this.userService.callUserListData().pipe(map((data) => {
        this.userService.setUserListData(data);
        this.permissionService.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm]
          }
        });

        this.userService.setPermissions(usersPermission);
        return this.canPublishIntakeForms(data);

      }));
    }
    else {
      return of(this.canPublishIntakeForms(user));
    }
  }
  private canPublishIntakeForms(user: IUser): boolean {
    return user.isAdmin
      || user.permissions.includes(PERMISSIONS.Sales_Intake_Combo)
      
  }
}
