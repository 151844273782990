<div class="help-container main-container-sub responsive-container">

  <div class="heading-container align-items-start">
    <div class="back-action-container">
      <div class="back-button" *ngIf="!isClientUser">
        <a href="javascript:void(0);" (click)="back()">
          <span class="material-icons">
            arrow_back
          </span> Resource Center
        </a>
      </div>
      <h1>{{topics.title}}</h1>
    </div>

    <!--<div class="d-flex create-new-topic-btn fdl-2">-->
      <!-- remove onclick  -->
      <!--<button type="button" class="btn fdl-btn btn-primary-orange" (click)="navigateTo()">
        <span class="material-icons">add</span>
        Create New Article
      </button>
    </div>-->
  </div>

  <div class="row m-0 height-100">
      <!--Starts For Mobile and ipad only -->
      <div class="mobile-menu navbar-light">
        <div class="nav-item dropdown show navigation">
        <button type="button" data-toggle="collapse" data-target="#helpNavbarCollapse" aria-controls="helpNavbarCollapse" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler collapsed">
          <span class="navbar-toggler-icon"></span>
          <strong class="ml-2">Recommended Article Menu</strong>
        </button>
        <!--Starts On click popover show  -->
        <div id="helpNavbarCollapse" class="navbar-collapse collapse" >
          <ul class="navbar-nav mr-auto" *ngIf="!isClientUser">
            <li class="nav-item form-responses-width"   *ngFor="let row1 of popularTopic; let i = index;">
              <a  href="javascript:void(0);" routerLink="/help/topic/{{row1.id}}/category" routerLinkActive="active" class="description-image nav-link">
                {{row1.title}}
              </a>
            </li> 
          </ul>
          <ul class="navbar-nav mr-auto" *ngIf="isClientUser">
            <li class="nav-item form-responses-width">
              <a href="javascript:void(0);" routerLink="/help/topic/{{clientDashboardUserManualId}}/category" routerLinkActive="active" class="description-image nav-link">Client Commitment Tracker Dashboard - User Manual</a>
            </li>
            <li class="nav-item form-responses-width" >
              <a href="javascript:void(0);" routerLink="/help/topic/{{clientDashboardFAQId}}/category" routerLinkActive="active" class="description-image nav-link">Client Commitment Tracker Dashboard - FAQ</a>
            </li>
          </ul>
        </div>
        <!--Ends On click popover show  -->
      </div>
      </div>
        <!--Ends For Mobile and ipad only -->
    
        <!--Starts For Desktop only -->
    <!-- Sidebar Item -->
    <aside class="side-navbar">
      <div class="main">
        <div role="navigation" class="left-nav"> 
          <div class="help-recommended-topics">
            <h4>Recommended Article</h4>
            <div class="help-body" *ngIf="!isClientUser">
              <a *ngFor="let row of popularTopic; let i = index;" href="javascript:void(0);" routerLink="/help/topic/{{row.id}}/category" routerLinkActive="active" class="description-image">{{row.title}}</a>
            </div>
            <div class="help-body" *ngIf="isClientUser">
              <a href="javascript:void(0);" routerLink="/help/topic/{{clientDashboardUserManualId}}/category" routerLinkActive="active" class="description-image">Client Commitment Tracker Dashboard - User Manual</a>
              <a href="javascript:void(0);" routerLink="/help/topic/{{clientDashboardFAQId}}/category" routerLinkActive="active" class="description-image">Client Commitment Tracker Dashboard - FAQ</a>
            </div>
          </div>
        </div>
      </div>
    </aside>
    <section class="right-section">
      <div>
        <div class="alert alert-success alert-dismissible" *ngIf="successMessage!=''">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="successMessage!=''">×</button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{successMessage}}
          
          </p>
        </div>
        <div class="form-container help-accordion-container help-form-io-container">
          <formio [form]="json" #formio></formio>

        </div>
      </div>
    </section>
  </div>
</div>
