
<div class="refresh-icon-link mt-1" style="display:none">
  <a href="javascript:void(0);" data-toggle="modal" class="clickable" data-target="#columnSettings" title="Column Settings" (click)="showColumnSettings()">
    <span class="material-icons">settings</span>
  </a>
</div>

<div *ngIf="showColumnSettingsDrawer">
  <div class="modal full-width fade position-right no-backdrop show" id="columnSettings" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style="display: block;top: 0" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header white-modal-header">
          <h4 class="modal-title right-arrow-image"><span>Column Settings</span></h4>
          <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
                  aria-label="Close popup" (click)="close()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="row tag-line-wrapper">
            <div class="col-md-12">
              <div class="tag-line">
                  <!-- <p>
                    Add or remove columns. To change the column order, drag and drop a field, or use the keyboard
                    shortcuts, Ctrl+Up or Ctrl+Down.
                  </p> -->
                  <p>
                    Add or remove columns. 
                  </p>
                <p class="mb-0">Please note that you can add up to {{maxColumnCount}} columns at a time.</p>
              </div>
            </div>
          </div>
          <div class="alert alert-dismissible alert-danger" *ngIf="isError">
            <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="errorMsg"></p>
          </div>
          <!-- Column Field starts -->
          <!-- Column Field starts -->
          <div class="row column-field-wrapper" *ngFor="let columnPref of columnPreferences; index as counter;">
              <!-- Refer https://dev.azure.com/Fiserv-FTS/Impl360/_workitems/edit/226535/ -->
              <!-- <div class="col-lg-1 drag-icon-container">
                <button class="drag-icon"> </button>
              </div> -->
            <div class="col-lg-11 form-field-container">
              <div class="filter-form-control">
                <ng-select [(ngModel)]="columnPref.id" class="ng-select" (click)="changePreference()">
                  <ng-option *ngFor="let column of prefColumnOptions" [value]="column.id">{{column.title}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-lg-1 cross-icon-container">
              <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup"
                      class="close" (click)="removeColumn(counter)">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <div class="row column-field-wrapper" *ngIf="showAddColumnOptions">
            <div class="col-lg-12 ">
              <a class="add-col-link" (click)="addColumn()">
                <span class="material-icons">add</span>
                <span>Add a Column</span>
              </a>
            </div>
          </div>
        </div>
        <div class="modal-footer fdl-2">
          <button class="btn fdl-btn btn-secondary-light" (click)="close()">Cancel</button>
          <button class="btn btn-primary-orange m-0" (click)="savePreference()">Save</button>
        </div>
      </div>
    </div>
    <!-- end of column settings changes-->
  </div>
</div>
