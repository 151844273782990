<div class="heading-container fdl-2">
    <h1>Users</h1>
    <button type="button" *ngIf="showCreateButton" class="btn fdl-btn btn-primary-orange" (click)="createUser();">
        <span class="material-icons">add</span>
        Create New User</button>
</div>
<div class="form-container">
<div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
        <span class="iconLabel" aria-label="sucess"></span>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p>{{successMsg}}
      </p>
</div>
<div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
</div>
<form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
    <h2 class="h4 collapse-arrow collapsed" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse" href="#collapseFilter">Search</h2>
    <div class="filter-form-container-column2 filter-form-margin collapse" [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">
        <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('Name').valid && (filterForm.get('Name').touched || filterForm.get('Name').dirty || isSubmitted)}">
          <div class="has-error">
            <span *ngIf="!filterForm.get('Name').valid && filterForm.get('Name').errors?.pattern && (filterForm.get('Name').touched || filterForm.get('Name').dirty || isSubmitted)">
            <i class="material-icons">warning</i> Please enter a valid Name</span>
          </div>
          <fdl-type-ahead class="filter-typeahead" label="Name:" formControlName="Name" [collection]="userSuggestion"  optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
        </div>
        <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('Email').valid && (filterForm.get('Email').touched || filterForm.get('Email').dirty  || isSubmitted)}">
          <div class="has-error">
            <span *ngIf="!filterForm.get('Email').valid && filterForm.get('Email').errors?.pattern && (filterForm.get('Email').touched || filterForm.get('Email').dirty  || isSubmitted)">
            <i class="material-icons">warning</i> Please enter a valid Email </span>
          </div>
          <fdl-type-ahead class="filter-typeahead" label="Email:" formControlName="Email" [collection]="emailSuggestion"  optionField="email" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
        </div>
        <div class="filter-form-control pr-4">
                <label for="Type">User Type:</label>
                <div class="fdl-select">
                    <select name="Type" class="form-control" aria-invalid="false" formControlName="Type">
                        <option value="">All</option>
                        <option *ngFor="let type of UserTypes" [value]="type">{{type}}</option>
                    </select>
                </div>
            </div>
            <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('ClientTitleWithDuns').valid && (filterForm.get('ClientTitle').touched || filterForm.get('ClientTitle').dirty || isSubmitted)}">
              <div class="has-error">
                <span *ngIf="!filterForm.get('ClientTitleWithDuns').valid && filterForm.get('ClientTitleWithDuns').errors?.pattern && (filterForm.get('ClientTitleWithDuns').touched || filterForm.get('ClientTitleWithDuns').dirty || isSubmitted)">
                <i class="material-icons">warning</i> Please enter a valid Client Name </span>
              </div>
              <fdl-type-ahead class="filter-typeahead" label="Client Name/DUNS:" formControlName="ClientTitleWithDuns" [collection]="clientSuggestion" optionField="titleWithDuns" placeholder="Type to search"
                  [scrollable]="true" [optionsLimit]="100" [minLength]="0" (input)="typeaheadLoadingClients()">
              </fdl-type-ahead>
            </div>
            <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('RoleTitle').valid && (filterForm.get('RoleTitle').touched || filterForm.get('RoleTitle').dirty  || isSubmitted)}">
              <div class="has-error">
                <span *ngIf="!filterForm.get('RoleTitle').valid && filterForm.get('RoleTitle').errors?.pattern && (filterForm.get('RoleTitle').touched || filterForm.get('RoleTitle').dirty || isSubmitted)">
                 <i class="material-icons">warning</i> Please enter a valid Role </span>
               </div>
               <fdl-type-ahead class="filter-typeahead" label="Role:" formControlName="RoleTitle" [collection]="roleSuggestions" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>
            <div class="filter-form-control pr-4 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('OrganizationTitle').valid && (filterForm.get('OrganizationTitle').touched || filterForm.get('OrganizationTitle').dirty || isSubmitted)}" *ngIf="isAdmin">
              <div class="has-error">
                <span *ngIf="!filterForm.get('OrganizationTitle').valid && filterForm.get('OrganizationTitle').errors?.pattern && (filterForm.get('OrganizationTitle').touched || filterForm.get('OrganizationTitle').dirty || isSubmitted)">
                  <i class="material-icons">warning</i> Please enter a valid Organization Name </span>
              </div>
              <fdl-type-ahead class="filter-typeahead" label="Organization Name:" formControlName="OrganizationTitle" [collection]="organizationSuggestion"  optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>
        <div class="buttom-wrapper">
            <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search"
                (click)="filterList()">Search</button>
        </div>
        <div class="ml-2 buttom-wrapper" *ngIf="filtered">
          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset"
              (click)="clearFilterList()">Reset</button>
      </div>
    </div>
</form>
<div class="data-table-container">
  <div class="justify-content-between d-flex">
    <div class="headline-container">
      <h2 class="h4">User List</h2>
      <p>Total {{pagerObject.totalCount}} Users</p>
    </div>
    <div class="d-flex">
      <div class="refresh-icon-link mt-1">
        <!-- add class of 'fa-rotation' on click of the refresh icon -->
        <a title="Refresh User List" id="refreshTask" (click)="refresh()">
          <i aria-hidden="true" class="fa fa-refresh"></i>
        </a>
      </div>
      <div class="dropdown downloadDropdownMenuButton" *ngIf="isAdmin">
        <button *ngIf="isAdmin"
                type="button" title="Download Extract" class="btn btn fdl-btn btn-primary-dark btn-addcomment"
                id="downloadDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          <span class="material-icons material-icons-wrapper">
            get_app
          </span>
        </button>

        <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
          <a class="dropdown-item" (click)="downLoadType({'type':'xlsx'})">Export List</a>
        </div>
      </div>
      <app-prefernce-icon></app-prefernce-icon>
    </div>
    </div>
    <div class="table-scroll-x">
      <app-data-table *ngIf="userList"
                      [rows]="userList.users"
                      [keys]="keys"
                      [displayColumns]="displayColumns"
                      (buttonClicked)="navigate($event)"
                      (deleteBtnClicked)="navigate($event)"
                      (sortingClicked)="applySorting($event)"
                      [showCheckbox]="false"
                      [showEdit]="showCreateButton"
                      [showDelete]="isAdmin"
                      type="User"
                      [listname]="listname">
      </app-data-table>

      <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && userList && userList.users.length > 0"
                      [pagerObject]="pagerObject"
                      (pagingChanged)="changePage($event)"></app-pagination>

    </div>
  </div>
</div>
