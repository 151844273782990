import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { PreferenceService } from '../../../services/preference.service';
import { UserService } from '../../../services/user.service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { PreferenceSettingService } from './preference-setting.service';
declare var $: any;
@Component({
  selector: 'app-preference-setting',
  templateUrl: './preference-setting.component.html',
  styleUrls: ['./preference-setting.component.css']
})
export class PreferenceSettingComponent implements OnInit {
  @Input() maxColumnCount: number = 12;
  @Input() listname: any;
  @Output() refereshColums = new EventEmitter<{  data: any }>();
  columnPreferences: any;
  prefColumnOptions: any;
  listName: any;
  preferenceId: any;
  userId: any;
  refreshGrid: boolean = false;
 
  showAddColumnOptions: boolean = false;
  isError: boolean = false;
  userData: any;
  errorMsg: any = '';
  showColumnSettingsDrawer: boolean = false;
  @Input() addViewProjectClass: boolean = false;

  constructor(private userService: UserService, private preferenceService: PreferenceService, private _preferenceSettingService: PreferenceSettingService) { }

  ngOnInit(): void {
    this.userData = this.userService.getUserListData();
    this.callPreferenceGetService();
    this._preferenceSettingService.generateSetting$.subscribe(trigger => {
      if (trigger != '-1') {
        $('.container-page').addClass('overflow-hidden');
        if (trigger == '1') {
          this.showColumnSettingsDrawer = true;
          $('#preferenceString').trigger('click')
        } else if (trigger == this.listname){
          this.showColumnSettingsDrawer = true;
          $('#preferenceString').trigger('click')
        }
     
        this._preferenceSettingService.generateSetting$.next('-1');
      }
    });
  }
  /** start of column settings changes**/
  callPreferenceGetService() {
    const userId = this.userData.id;
    const listName = this.listname;
    const fetchPreferenceByUserId = this.preferenceService.getPreferenceByUserId(userId, listName);
    const fetchAllPreferenceByListName = this.preferenceService.getAllPreferenceByListName(listName);
    this.showAddColumnOptions = true;
    forkJoin(fetchPreferenceByUserId, fetchAllPreferenceByListName)
      .subscribe((response: any) => {
        const columnPrefs = response[0];
        const columnList = response[1];
        this.refreshPreference(columnPrefs, columnList);
      }, error => {
        this.isError = true; this.errorMsg = error;
      }
      );
  }

  showColumnSettings() {
    $('.container-page').addClass('overflow-hidden');
    this.showColumnSettingsDrawer = true;
  }

  savePreference() {
    const hasEmpty = this.preferenceService.hasEmptyColumns(this.columnPreferences);
    if (hasEmpty) {
      this.isError = true;
      this.errorMsg = "Empty column selected.";
      return;
    }

    const preferences = this.preferenceService.getPreferencesToBeSaved(this.columnPreferences, this.prefColumnOptions)
    const isDuplicate = this.preferenceService.checkForDuplicates(preferences);
    if(isDuplicate) {
      this.isError = true;
      this.errorMsg = "Duplicate column selected";
      return;
    }
    const params: any = {
      userId: this.userData.id,
      listName: this.listName,
      columnOptions: JSON.stringify(preferences)
    };
    if (this.userId != 0) {
      params.id = this.preferenceId;
      this.updatePreference(params);
    } else {
      this.createPreference(params);
    }
    $('.container-page').removeClass('overflow-hidden');
    this.showColumnSettingsDrawer = false;
  }

  createPreference(params: any) {
    this.preferenceService.savePreference(params).pipe(take(1))
      .subscribe(
        (preference: any) => {
          this.refreshPreference(preference);
        }, error => {
          this.isError = true; this.errorMsg = error;
        }
      );
  }

  changePreference() {
    this.clearError();
  }

  updatePreference(params: any) {
    this.preferenceService.updatePreference(params).pipe(take(1))
      .subscribe(
        (preference: any) => {
          this.refreshPreference(preference);
        }, error => {
          this.isError = true; this.errorMsg = error;
        }
      );
  }

  refreshPreference(columnPrefs: any, columnList?: any) {
    this.isError = false;
    const columnOptions = JSON.parse(columnPrefs.columnOptions);
    this.listName = columnPrefs.listName;
    this.userId = columnPrefs.userId;
    this.preferenceId = columnPrefs.id;
    this.columnPreferences = this.preferenceService.convertToDropDownItems(columnOptions);
    if (this.columnPreferences && this.columnPreferences.length < this.maxColumnCount) {
      this.showAddColumnOptions = true;
    } else {
      this.showAddColumnOptions = false;
    }
    if (columnList) {
      const listOfoptions = JSON.parse(columnList.allColumnOptions);
      this.prefColumnOptions = this.preferenceService.convertToDropDownItems(listOfoptions);
    }
    const columnPreferences = this.preferenceService.refreshGrid(columnOptions);
   // this.initDataTableColumns(columnPreferences);
    // this.keys = preferences.keys;
    // this.displayColumns = preferences.displayColumns;
    this.refereshColums.emit({ data: columnPreferences })
    this.refreshGrid = true;
  }

  close() {
    $('.container-page').removeClass('overflow-hidden');
    this.showColumnSettingsDrawer = false;
    if(this.addViewProjectClass) {
      $('.responsive-container').removeClass('position-static');
      $('.right-section').removeClass('position-static');
      $('#collapseThree').removeClass('position-static');
    }
    this.callPreferenceGetService();
  }

  removeColumn(counter: any) {
    this.clearError();
    const minColumnCount = PreferenceService.minColumnCount;
    const columnPrefsCount = this.columnPreferences.length;
    this.showAddColumnOptions = true;
    if (columnPrefsCount > minColumnCount) {
      this.columnPreferences.splice(counter, 1);
    }
  }

  addColumn() {
    this.clearError();
    const columnObject = Object.assign({}, this.columnPreferences[0]);
    let columnPrefsCount = this.columnPreferences.length;
    this.showAddColumnOptions = true;
    if (columnPrefsCount < this.maxColumnCount) {
      this.columnPreferences.push(columnObject);
    }
    columnPrefsCount = this.columnPreferences.length;
    if (columnPrefsCount === this.maxColumnCount) {
      this.showAddColumnOptions = false;
    }
  }

  clearError() {
    this.isError = false;
    this.errorMsg = '';
  }

}
