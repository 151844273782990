import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MasterService } from './master.service';
import { IMasterList, IStatus, IResponseStatusResloved } from '../shared/status';


@Injectable({
    providedIn: 'root'
})

export class MasterResponseStatusResolver implements Resolve<IResponseStatusResloved> {

    constructor(private masterService: MasterService) { }

    resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<IResponseStatusResloved> {

        const masterStatusData = this.masterService.getResponseStatusList();
        if (masterStatusData.length > 0) {
            return of({ responseStatus: masterStatusData });
        }

        return this.masterService.callMasterListData()
            .pipe(
                map((data) => {
                    this.masterService.setMasterListData(data);
                    return ({ responseStatus: this.masterService.getResponseStatusList() });
                }
                ));
    }

}