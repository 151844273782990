<div class="typeahead-input-wrapper" [formGroup]="form">
  <input type="hidden" [formControlName]="formctrlname" name='formControl' class="form-control">
  <input id="{{ids}}_dropdown_input" class="form-control" [(ngModel)]="dropDownValue" [ngModelOptions]="{standalone: true}"
    placeholder="Select option" (click)="onClick()" (keyup)="onKey($event)">

   <span class="material-icons" (click)="onClick()" id="{{ids}}_dropdown_expand">expand_more</span>

  <ul id="{{ids}}_dropdown" class="typeahead-dropdown-menu d-none">
    <li id="{{ids}}_dropdown_otions_{{product.id}}" (click)="clickOption($event,product)" *ngFor="let product of filterOtions"
      [value]="product.id">{{ product.title ? product.title : product.name}}</li>
</ul>
</div>