import { Component, OnInit, Renderer2, ViewChild, ElementRef } from "@angular/core";
import { DecimalPipe } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";

import { UserService } from "../../services/user.service";
import { DashboardService } from "../../services/dashboard.service";
import { DashboardCommonLogic } from "../shared/dashboard-common-logic";
import { single, multi } from './component';
import { IDashboardAnalyticsProject } from "src/app/shared/dashboardAnalyticsProjectDTO";

import { IProjectStatusResloved } from '../../shared/status';
import { Console, group } from "console";
import moment, { Moment } from 'moment';
import { IUser } from "../../shared/user";

@Component({
  selector: 'app-analytics-projects',
  templateUrl: './analytics-projects.component.html'
})
export class AnalyticsProjectsComponent extends DashboardCommonLogic
  implements OnInit {

  //Class Level Variables
  isReady: boolean = false;
  label: string;
  userDetails: any;
  dashboardAnalyticsProject: IDashboardAnalyticsProject;
  LastLoginTimeStamp: any;
  projectStausCountMonthwise: any = [];
  projectByProduct: any = [];
  projectByClient: any = [];

  totalProjects: number;
  completedProjects: number;
  inProgressProjects: number;
  notStartedProjects: number;
  overdueProjects: number;

  title = 'TestCharts';
  multi: any = [];
  single: any[];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  legendPosition: string = 'below';
  showXAxisLabel = false;
  xAxisLabel = 'Months';
  showYAxisLabel = false;
  yAxisLabel = 'Counts';
  productColorScheme = {
    domain: ['#FF6600']
  };
  autoScale = true;
  today: any;
  //Response by User Type, doughnut chart
  view: any[] = [680, 400];
  projectByStatus: any = [];
  showLabels = true;
 
  projectColorScheme = {
    domain: ['#FF6600']
  };
  verticalBarColorScheme = {
    domain: ['#2e8540', '#fdb81e', '#0071bc', '#cd2026']
  };



  constructor(
    private r: Router,
    private userService: UserService,
    private dashboardService: DashboardService
  ) {
    super(r);
  }

  ngOnInit() {
    this.userDetails = this.userService.getUserListData();
    this.today = moment().format("dddd D MMMM YYYY HH:mm");
    this.userService.callUserListData().subscribe((data) => {
      this.getLoginTimestamp(data);
    });
    this.dashboardService.getDashBoardAnalyticsProjects().subscribe(data => {
      this.dashboardAnalyticsProject = data;

      //Project By Status
      this.dashboardAnalyticsProject.dashbordProjectByStatusData.forEach(x =>
        this.projectByStatus.push({ name: x.title, value: x.count, label: x.label }));

      //Extracted Project Counts Status wise 
      this.notStartedProjects = this.dashboardAnalyticsProject.dashbordProjectByStatusData.filter(x => (x.title == 'Not Started'), take(1)).map(x => x.count)[0];
      this.inProgressProjects = this.dashboardAnalyticsProject.dashbordProjectByStatusData.filter(x => (x.title == 'In Progress'), take(1)).map(x => x.count)[0];
      this.overdueProjects = this.dashboardAnalyticsProject.dashbordProjectByStatusData.filter(x => (x.title == 'Overdue'), take(1)).map(x => x.count)[0];
      this.completedProjects = this.dashboardAnalyticsProject.dashbordProjectByStatusData.filter(x => (x.title == 'Completed'), take(1)).map(x => x.count)[0];

      this.notStartedProjects = this.notStartedProjects==undefined ? 0 : this.notStartedProjects;
      this.inProgressProjects  =this.inProgressProjects==undefined ? 0 : this.inProgressProjects;
      this.overdueProjects = this.overdueProjects==undefined ? 0 : this.overdueProjects;
      this.completedProjects = this.completedProjects==undefined ? 0 : this.completedProjects;

      this.totalProjects = this.notStartedProjects + this.inProgressProjects + this.overdueProjects + this.completedProjects;

      //Project Till Date By Status
      let groupedProjectStatusMonthwise = this.dashboardAnalyticsProject.dashboardprojectByStatusMonthwiseData.reduce(function (x, y) {
        let groupKey = y.monthName + '-' + y.year;
        (x[groupKey] ? x[groupKey] : (x[groupKey] = null || [])).push(y);
        return x;
      }, {});

      for (const key in groupedProjectStatusMonthwise) {
        let projectStausCount = [];
        for (let i = 0; i <= groupedProjectStatusMonthwise[key].length - 1; i++) {
          let curr = groupedProjectStatusMonthwise[key][i];
          projectStausCount.push({ name: curr.title, value: curr.count });
        }
        this.projectStausCountMonthwise.push({ name: key, series: projectStausCount });
      }
      this.projectStausCountMonthwise = this.finalProjectStausCountMonthwise();
      
      //Project By Product
      this.dashboardAnalyticsProject.dashbordProjectByProductData.forEach(x =>
        this.projectByProduct.push({ name: x.title, value: x.count }));

      //Project By Client
      this.dashboardAnalyticsProject.dashbordProjectByClientData.forEach(x =>
        this.projectByClient.push({ name: x.title, value: x.count }));

      this.isReady = true;
    });
  }

  getLoginTimestamp(user: IUser) {
    this.userService.getLoginData(user.userId).subscribe((data) => {
      this.LastLoginTimeStamp = moment.utc(data.body).local().format("dddd D MMMM YYYY HH:mm");
    },
      (error) => console.log("Error"));
  }

  //Set the value of arc of pie charts
  pieChartProjectBysStatusLabel(series: any[], name: string): string {
    const item = series.filter((data) => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    return name;
  }

   //Set the project by Status pie chart custom colors
  projectCustomColors() {
    let result: any[] = [];
    for (let i = 0; i < this.projectByStatus.length; i++) {
      switch (this.projectByStatus[i].name)
      {
        case "Completed":
          result.push({"name": this.projectByStatus[i].name,"value": "#2e8540", "label": this.projectByStatus[i].label});
          break;
        case "In Progress":
          result.push({"name": this.projectByStatus[i].name,"value": "#fdb81e", "label": this.projectByStatus[i].label});
          break;
        case "Not Started":
          result.push({"name": this.projectByStatus[i].name,"value": "#0071bc", "label": this.projectByStatus[i].label});
          break;
        case "Overdue":
          result.push({"name": this.projectByStatus[i].name,"value": "#cd2026", "label": this.projectByStatus[i].label});
          break;
      }
    }
    return result;
  }

  //Organize the response status wise.
  finalProjectStausCountMonthwise() {
  let emptyArray: any[] =[];
  emptyArray.push({"name": "Completed", "value": 0 });
  emptyArray.push({"name": "In Progress", "value": 0 });
  emptyArray.push({"name": "Not Started", "value": 0 });
  emptyArray.push({"name": "On Hold", "value": 0 });
  emptyArray.push({"name": "Overdue", "value": 0 });
  emptyArray.push({"name": "Rejected", "value": 0 });
  emptyArray.push({"name": "Under Review", "value": 0 });

  let main: any[] = [];
  let item: any[];
  for (let j=0; j < this.projectStausCountMonthwise.length; j++)
  {
    let result : any[] =[];
    for (let i = 0; i < emptyArray.length; i++) {
      switch (emptyArray[i].name)
      {
        case "Completed":
          {
            item = this.filter(this.projectStausCountMonthwise[j].series, emptyArray[i].name);
            if (item.length > 0) 
              result.push({"name": item[0].name,"value": item[0].value});
            else
              result.push({"name": emptyArray[i].name,"value": 0});
          break;
          }
        case "In Progress":
            {
              item = this.filter(this.projectStausCountMonthwise[j].series, emptyArray[i].name);
              if (item.length > 0) 
                result.push({"name": item[0].name,"value": item[0].value});
              else
                result.push({"name": emptyArray[i].name,"value": 0});
            break;
            }
        case "Not Started":
            {
              item = this.filter(this.projectStausCountMonthwise[j].series, emptyArray[i].name);
              if (item.length > 0) 
                result.push({"name": item[0].name,"value": item[0].value});
              else
                result.push({"name": emptyArray[i].name,"value": 0});
            break;
            }
        case "Overdue":
          {
            item = this.filter(this.projectStausCountMonthwise[j].series, emptyArray[i].name);
            if (item.length > 0) 
              result.push({"name": item[0].name,"value": item[0].value});
            else
              result.push({"name": emptyArray[i].name,"value": 0});
            break;
          }
      }
    }
    main.push({"name": this.projectStausCountMonthwise[j].name, series: result});
  }
  return main;
}

filter(series: any[], name: string){
    const item = series.filter((data) => data.name === name);
    return item;
}
}
