<div class="heading-container fdl-2">
  <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span>
        Clients
      </a>
    </div>
    <h1>{{clientData?.title || 'N/A'}}</h1>
    <div *ngIf="clientData && clientData.logo">
      <img [src]="clientData.logo" style="max-width: 320px; max-height: 100px;">
    </div>
  </div>
  </div>
  <div class="row m-0 mb-0">
    <div class="accordion collapse-view col-md-12 accordian-responses grid-wrapper-section">   
      <div class="biller-survey d-flex justify-content-between align-items-start fdl-2">
      
        <div class="">
            <ul class="">
                <li class=" full-width">
                    Description:
                    <span class="info">
                        {{clientData?.description || 'N/A'}}
                    </span>
                </li>

                <li class=" full-width">
                  DUNS ID:
                <span class="info">
                  {{clientData?.dunsId || 'N/A'}}
                </span>
                </li>

                <li class=" full-width">
                    GUD ID:
                  <span class="info">
                    {{clientData?.gud || 'N/A'}}
                  </span>
                </li>

                <li>
                    Address: 
                    <span class="info">
                        {{_ClientAddress}}
                    </span>
                </li>
            </ul>
            <ul class="">
                <li class=" full-width">
                    Relationship Manager Name:
                    <span class="info">
                        {{clientData?.relationshipManagerName || 'N/A'}}
                    </span>
                </li>

                <li class=" full-width">
                    Client Segment:
                  <span class="info">
                    {{clientData?.clientSegment || 'N/A'}}
                  </span>
                </li>  

                <li class=" full-width">
                    Region:
                  <span class="info">
                    {{clientData?.region || 'N/A'}}
                  </span>
                </li>

                <li class=" full-width">
                    Health:
                  <span class="info">
                    {{clientData?.health || 'N/A'}}
                  </span>
                </li>                      
            </ul>
        </div>
        <button type="button" class="btn fdl-btn btn-primary-dark  pull-right" *ngIf="isAdmin" (click)="goToEdit();">Edit</button>
      </div>
    </div>
  </div>
<div class="form-container separate-form-container">
  <div class="alert alert-success alert-dismissible" *ngIf="showUpdationMessage">
    <button type="button" class="close" data-dismiss="alert" aria-label="close"
      (click)="showUpdationMessage=false">×</button>
    <span class="iconLabel" aria-label="sucess"></span>
    <i class="material-icons" aria-hidden="true">check_circle</i>
    <p>The client details are updated successfully.

    </p>
  </div>
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>
  <form>

    <div class="row mb-2">
      <div class="intake-request-form intake-request-form-wrapper client-form-wrapper"
        style="flex: 0 0 100%; max-width: 100%;">
        <div class="box">
          <h3 class="h5 heading-level-3 mt-0 mb-2 d-flex justify-content-between">Client Contact Person Details
          </h3>

          <div class="assign-modal-table view">
            <app-data-table *ngIf="clientData?.contacts" [rows]="clientData?.contacts" [keys]="clientContactKeys"
              [displayColumns]="clientContactHeaders" [enableSorting]="false" [showCheckbox]="false" [showMenu]="false">
            </app-data-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2" *ngIf="isAdmin">
      <div class="intake-request-form intake-request-form-wrapper client-form-wrapper"
        style="flex: 0 0 100%; max-width: 100%;">
        <div class="box">
          <h3 class="h5 heading-level-3 mt-0 mb-2">eCRM</h3>
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="searchTags">eCRMAccountId(s)</label>
              <div class="form-row" *ngIf="!(clientData?.ecrm.length > 0)">
                <div class="col-md-12 mb-3">
                  <div class="tag-wrapper" style="">
                    <div class="filter-wrapper">
                      <div class="filter-tag">
                        <div class="tag" data-toggle="tooltip" data-placement="top">
                          No records available.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="clientData?.ecrm.length > 0">
                <div class="col-md-12 mb-3">
                  <div class="tag-wrapper" style="">
                    <div class="filter-wrapper">
                      <div class="filter-tag" *ngFor="let ecrm of clientData?.ecrm">
                        <div class="tag" data-toggle="tooltip" data-placement="top"
                          title="{{ecrm.subSystemClientRefrenceValue}}">
                          {{ecrm.subSystemClientRefrenceValue}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2" *ngIf="isAdmin">
      <div class="intake-request-form intake-request-form-wrapper client-form-wrapper"
        style="flex: 0 0 100%; max-width: 100%;">
        <div class="box">
          <h3 class="h5 heading-level-3 mt-0 mb-2">Clarity</h3>
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="searchTags">CompanyId(s)</label>
              <div class="form-row" *ngIf="!(clientData?.clarity.length > 0)">
                <div class="col-md-12 mb-3">
                  <div class="tag-wrapper" style="">
                    <div class="filter-wrapper">
                      <div class="filter-tag">
                        <div class="tag" data-toggle="tooltip" data-placement="top">
                          No records available.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="clientData?.clarity?.length > 0">
                <div class="col-md-12 mb-3">
                  <div class="tag-wrapper" style="">
                    <div class="filter-wrapper">
                      <div class="filter-tag" *ngFor="let clarity of clientData?.clarity">
                        <div class="tag" data-toggle="tooltip" data-placement="top"
                          title="{{clarity.subSystemClientRefrenceValue}}">
                          {{clarity.subSystemClientRefrenceValue}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2" *ngIf="isAdmin">
      <div class="intake-request-form intake-request-form-wrapper client-form-wrapper"
        style="flex: 0 0 100%; max-width: 100%;">
        <div class="box">
          <h3 class="h5 heading-level-3 mt-0 mb-2">Client Alias</h3>
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="form-row" *ngIf="!(clientData?.alias?.length > 0)">
                <div class="col-md-12 mb-3">
                  <div class="tag-wrapper" style="">
                    <div class="filter-wrapper">
                      <div class="filter-tag">
                        <div class="tag" data-toggle="tooltip" data-placement="top">
                          No records available.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row" *ngIf="clientData?.alias?.length > 0">
                <div class="col-md-12 mb-3">
                  <div class="tag-wrapper" style="">
                    <div class="filter-wrapper">
                      <div class="filter-tag" *ngFor="let alias of clientData?.alias">
                        <div class="tag" data-toggle="tooltip" data-placement="top"
                          title="{{alias.subSystemClientRefrenceValue}}">
                          {{alias.subSystemClientRefrenceValue}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fdl-2 button-container">
      <div>
        <button type="button" class="btn fdl-btn btn-secondary-light " (click)="goBack()">Cancel</button>
      </div>
    </div>
  </form>
</div>