<div class="heading-container fdl-2">
    <h1>User Groups</h1>
    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="createUserGroup();">
        <span class="material-icons">add</span>
        Create New User Group</button>
</div>
<div class="form-container">
<div class="alert alert-success alert-dismissible" *ngIf="successMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="setSuccessMsg()">×</button>
        <span class="iconLabel" aria-label="sucess"></span>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p>{{successMsg}}
      </p>
</div>
<div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
</div>
<form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
    <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
    <div class="filter-form-container-column2 collapse" id="collapseFilter">
        <div class="filter-form-control pr-4 typeahead-wrapper">
          <div class="has-error">
            <span *ngIf="filterForm.get('Name').errors?.pattern">
              <i class="material-icons">warning</i> Please enter a valid Name
            </span>
          </div>
          <fdl-type-ahead class="filter-typeahead" label="Name:" formControlName="Name" [collection]="groupSuggestion"  
            optionField="name" placeholder="Type to search" [scrollable]="true" [(ngModel)]="groupText" (input)="typeaheadLoadingGroups()" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
        </div>
        <div>
          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterList()">Search</button>
        </div>
        <div class="ml-2" *ngIf="filtered">
          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset" (click)="clearFilterList()">Reset</button>
        </div>
    </div>
</form>

<div class="data-table-container">
  <div class="justify-content-between d-flex">
    <div class="headline-container">
      <h2 class="h4">User Group List</h2>
      <p>Total {{pagerObject.totalCount}} User Groups</p>
    </div>
    <div class="d-flex">
      <div class="refresh-icon-link mt-1 mr-2">
        <!-- add class of 'fa-rotation' on click of the refresh icon -->
        <a title="Refresh User Group List" id="refreshTask" (click)="refresh()">
          <i aria-hidden="true" class="fa fa-refresh"></i>
        </a>
      </div>
      <app-prefernce-icon></app-prefernce-icon>
    </div>
    </div>
    <div class="table-responsive">
      <app-data-table *ngIf="groupList"
                      [rows]="groupList.groups"
                      [keys]="keys"
                      [displayColumns]="displayColumns"
                      (buttonClicked)="navigate($event)"
                      (deleteBtnClicked)="navigate($event)"
                      (sortingClicked)="applySorting($event)"
                      [showCheckbox]="false"
                      [showEdit]="true"
                      [showDelete]="true"
                      type="UserGroup"
                      [listname]="listname">
      </app-data-table>
      <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && groupList && groupList.groups.length > 0"
                      [pagerObject]="pagerObject"
                      (pagingChanged)="changePage($event)"
                    ></app-pagination>
    </div>
  </div>
</div>
