import { Injectable } from "@angular/core";
import { HttpClient, HttpClientModule, HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";

import { tap } from 'rxjs/internal/operators/tap';
import { HttpHeaders } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { UserService } from 'src/app/services/user.service';
import { IForm } from 'src/app/shared/form';
import { catchError } from 'rxjs/operators';
import { InterceptorSkipHeader } from 'src/app/core/constants';
import { ICreateTopicForm } from "../../../../../admin/manageForms/models/createTopic";
//import { ICreateTopicForm } from "../../../../../admin/manageForms/models/createTopic";
///import { ICreateTopicForm } from "../../manageForms/models/createTopic";

@Injectable({
  providedIn: 'root'
})
export class ManageHelpArticleService {

  private _formVal: ICreateTopicForm;
  private _successMessage: string = ''
  private _flowName: string = ''
  private _returnPage: string = '';
  private _isReturn: boolean = false;

  constructor(private http: HttpClient, private _userService: UserService) { }

  get returnPage() {
      return this._returnPage;
  }

  set returnPage(data: any) {
      this._returnPage = data;
  }

  get flowName(): string {
    return this._flowName;
  }
  set flowName(value: string) {
    this._flowName = value;
  }
  get successMessage(): string {
    return this._successMessage;
  }
  set successMessage(value: string) {
    this._successMessage = value;
  }
  get formVal(): ICreateTopicForm {
    return this._formVal;
  }
  set formVal(value: ICreateTopicForm) {
    this._formVal = value;
  }
  get isReturn() {
    return this._isReturn;
  }
  set isReturn(data: any) {
      this._isReturn = data;
  }
}
