import { Injectable, ErrorHandler } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler{

  constructor(private _loggingService: LoggingService) { }

  handleError(error: any): void {
    this._loggingService.logException(error);
  }

  
}
