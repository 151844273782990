<div class="heading-container fdl-2">
    <h1>Workflow Executions</h1>
</div>
<div class="form-container">
    <form class="form form-border mb-3 collapse-view"  [formGroup]="filterForm" autocomplete="off">
        <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
        <div class="filter-form-container-column2 collapse" id="collapseFilter">
            <div class="filter-form-control pr-4 typeahead-wrapper">
                <fdl-type-ahead
                    label="Organization Name:"
                    formControlName="OrgTitle"
                    [collection]="organizationSuggestion"
                    optionField="title"
                    placeholder="Type to search"
                    [scrollable]="true"
                    [optionsLimit]="500"
                    [minLength]="0"
                    (onSelect)="onOrgSelect($event)"
                ></fdl-type-ahead>
            </div>
            <div class="filter-form-control pr-4 typeahead-wrapper">
                <fdl-type-ahead
                    label="Product Name:"
                    formControlName="Product"
                    [collection]="productSuggestion"
                    optionField="title"
                    placeholder="Type to search"
                    [scrollable]="true"
                    [optionsLimit]="500"
                    [minLength]="0"
                ></fdl-type-ahead>
            </div>
            <!-- <div class="filter-form-control pr-4 typeahead-wrapper">
                <fdl-type-ahead
                    label="Client Name:"
                    formControlName="ClientTitle"
                    [collection]="clientSuggestion"
                    optionField="title"
                    placeholder="Type to search"
                    [scrollable]="true"
                    [optionsLimit]="500"
                    [minLength]="0"
                ></fdl-type-ahead>
            </div> -->
            <div class="filter-form-control pr-4 typeahead-wrapper">
                <fdl-type-ahead
                    label="Form Name:"
                    formControlName="FormTitle"
                    [collection]="formSuggestion"
                    optionField="title"
                    placeholder="Type to search"
                    [scrollable]="true"
                    [optionsLimit]="500"
                    [minLength]="0"
                ></fdl-type-ahead>
            </div>
            <div class="filter-form-control pr-4 typeahead-wrapper">
                <fdl-type-ahead
                    label="Job Name:"
                    formControlName="JobTitle"
                    [collection]="jobSuggestion"
                    optionField="name"
                    placeholder="Type to search"
                    [scrollable]="true"
                    [optionsLimit]="500"
                    [minLength]="0"
                ></fdl-type-ahead>
            </div>
            <div class="filter-form-control typeahead-wrapper pr-4">
                <label for="correlationId">Correlation Id:</label>
                <div class="">
                    <input type="string" name="correlationId" placeholder="Type to search" class="form-control" aria-invalid="false" formControlName="CorrelationId">
                </div>
            </div>

            <div class="filter-form-control typeahead-wrapper pr-4">
                <label for="referenceId">Reference Id:</label>
                <div class="">
                    <input type="string" name="referenceId" placeholder="Type to search" class="form-control" aria-invalid="false" formControlName="ReferenceId">
                </div>
            </div>

            <div>
                <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterList()">Search</button>
            </div>
            <div class="ml-2" *ngIf="filtered">
              <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="clearFilterList()">Reset</button>
            </div>
        </div>
    </form>

    <div class="data-table-container">
        <div class="justify-content-between d-flex">
            <div class="headline-container">
                <h2 class="h4">Workflow Execution List</h2>
                <p>Total {{pagerObject.totalCount}} Workflow Executions</p>
            </div>

            <div class="d-flex">
                <div class="refresh-icon-link mt-1 mr-2">
                    <!-- add class of 'fa-rotation' on click of the refresh icon -->
                    <a  title="Refresh Workflow Execution List" id="refreshTask">
                    <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
                    </a>
                </div>
                <app-prefernce-icon></app-prefernce-icon>
            </div>
        </div>

        <div class="table-scroll-x">
            <app-data-table
                *ngIf="workflowList.workflows"
                [rows]="workflowList.workflows"
                [keys]="keys"
                [displayColumns]="displayColumns"
                (buttonClicked)="navigate($event)"
                (sortingClicked)="applySorting($event)"
                [showCheckbox]="false"
                [listname]="listname"
                type="Workflow Execution"
            ></app-data-table>
          <app-pagination
                *ngIf="pagerObject && pagerObject.totalPages > 1 && workflowList.workflows && workflowList.workflows.length > 0"
                [pagerObject]="pagerObject"
                (pagingChanged)="changePage($event)"
            ></app-pagination>
        </div>
    </div>
</div>
