
<div class="modal fade bd-example-modal-lg" id="rejectedComments" tabindex="-1" role="dialog" aria-hidden="true" #content>
  <div class="modal-dialog modal-lg mt-120" role="document"  style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          Please add your comments:<span class="asterisk"></span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-dismissible alert-danger" *ngIf="isRejectedError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isRejectedError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>
        <form [formGroup]="approvalForm" autocomplete="off" *ngIf="approvalForm">
          <div class="row">
            <div class="col-md-12 mb-3"
                 [ngClass]="{'has-error': !approvalForm.get('comments').valid && (approvalForm.get('comments').touched || approvalForm.get('comments').dirty || isSubmitted) }">
              <textarea type="text" name="comments" id="comments" formControlName="comments" class="form-control"
                        rows="2"></textarea>
              <span *ngIf="!approvalForm.get('comments').valid && approvalForm.get('comments').errors?.required && (approvalForm.get('comments').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please provide comments.
              </span>
              <span *ngIf="!approvalForm.get('comments').valid && approvalForm.get('comments').errors?.maxlength && (approvalForm.get('comments').touched || isSubmitted)">
                <i class="material-icons">warning</i> Maximum input character limit is
                {{_maxLengths.Approval.Comments}}, please correct it.
              </span>
              <span *ngIf="!approvalForm.get('comments').valid && approvalForm.get('comments').errors?.pattern && (approvalForm.get('comments').touched || approvalForm.get('comments').dirty)">
                <i class="material-icons">warning</i> {{ regexValidationMessage.DESCRIPTION_COMMENT_VALIDATION_MESSAGE }} </span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer fdl-2">
        <button type="button" class="btn fdl-btn btn-secondary-light " data-dismiss="modal">
          Cancel
        </button>

        <button type="button"  class=" btn fdl-btn btn-primary-orange ml-2" (click)="submitApprovalForm('hold')">
          Submit
        </button>
      </div>
    </div>
  </div>


</div>
