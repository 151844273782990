import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
    templateUrl: "./toggle-button.component.html",
    selector: "app-toggle-button",
    styleUrls: [ "./toggle-button.component.css" ]
})
export class ToggleButtonComponent implements OnInit {

    @Output() onChange = new EventEmitter<boolean>()
    @Input() selectedIcon: string;
    @Input() deselectedIcon: String;
    @Input() selected: boolean = false

    ngOnInit(): void {
    }

    toggle(status: boolean) {
        this.selected = status
        this.onChange.emit(status)
    }

}