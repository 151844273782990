<div class="col-md-12 row p-0 justify-content-end m-0" *ngIf="template==1">
    <nav>
        <ul class="pagination mb-0">
            <li class=" page-item" [ngClass]="{disabled:pagerObject.currentPage === 1}">
                <a class="page-link" href="javascript:void(0);" (click)="goToFirst()">
                    <span>
                        <i class="material-icons">chevron_left</i>{{content['paginationFirstButton']}}
                    </span>
                </a> 
            </li>
            <li class=" page-item" [ngClass]="{disabled:pagerObject.currentPage === 1}">              
                <a  class="page-link" href="javascript:void(0);" (click)="prevPage()">
                    <span>
                        <i class="material-icons">chevron_left</i>
                        {{content['paginationPrevButton']}}
                    </span>
                </a>
            </li>
            <li class=" page-item" [ngClass]="{active:pagerObject.currentPage === count}" *ngFor="let count of pageCountArray">
                <a class="page-link" href="javascript:void(0);" (click)="setPage(count)" >
                {{count}}
            </a>
            </li>
            
            <li  class=" page-item" [ngClass]="{disabled:pagerObject.currentPage === pagerObject.totalPages}">
                <a  href="javascript:void(0);" class="next page-link" (click)="nextPage()">{{content['paginationNextButton']}}
                  
                    <span class="sr-only">{{content['paginationNextButton']}}</span>
                    <i class="material-icons">chevron_right</i>
                </a>
            </li>
            
            <li class=" page-item" [ngClass]="{disabled:pagerObject.currentPage === pagerObject.totalPages}">
                <a class="page-link"  href="javascript:void(0);" (click)="goToLast()">
                    <span>{{content['paginationLastButton']}}
                        <i class="material-icons">chevron_right</i>
                    </span>
                </a>
            </li>
        </ul>
    </nav>
</div>
<ul class="dots-indicator" *ngIf="template==2">

  <li class=""  *ngFor="let count of pageCountArray">
    <a class="" [ngClass]="{active:pagerObject.currentPage === count}" href="javascript:void(0);" (click)="setPage(count)">
     
    </a>
  </li>
 
</ul>
