import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreferenceSettingService {
  public generateSetting$ = new BehaviorSubject<string>('-1');
  constructor() { }

  getgenerateSetting(): Observable<string> {
    return this.generateSetting$.asObservable();
  }
  setgenerateSetting(pdf: string) {
    this.generateSetting$.next(pdf);
  }
}
