import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { ISuggestions } from 'src/app/shared/suggestion';
import { ICreatePageTemplates } from 'src/app/admin/managePages/models/createPageTemplate';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { MasterService } from 'src/app/services/master.service';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
declare var $: any;
@Component({
  selector: 'page-template-list',
  templateUrl: './page-template-list.component.html',
})
export class PageTemplateListComponent implements OnInit {
  isError: boolean = false;
  errorMsg: string = '';
  pagerObject: IPager;
  queryParam: IQuery;
  displayColumns = ['ID', 'Name', 'Description'];
  keys = ['id', 'title', 'description'];
  currentPageNumber: number = 1;
  sortingObject;
  templateSuggestion: ISuggestions;
  pageTemplateList: ICreatePageTemplates;
  filterForm: FormGroup;
  filterExists: boolean = false;
  listname: any;
  filtered: boolean = false;
  isSubmitted = false;
  regexValidationMessage: any;

  constructor(private _router: Router,
    private route: ActivatedRoute,
    private userFilterService: UserFilterService,
    private projectService: ProjectsService,
    private pageTemplateService: PageTemplateService,
    private masterService: MasterService,
    private preferenceService: PreferenceService
  ) { }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  filterList() {
    this.isSubmitted = true;
    if(this.filterForm.valid) {
      this.userFilterService.saveFilter(this.route, this.filterForm);
      this.initQueryParams();
      let filterValues = this.filterForm.value;
      if (filterValues['title'])
        this.queryParam.title = filterValues['title']
      this.setOrderBy(this.sortingObject);
      this.callRefreshPageTemplateListService();

      this.filtered = true
    }
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.userFilterService.clearFilter()
    this.callRefreshPageTemplateListService();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.callRefreshPageTemplateListService();
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      title: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),

    });
  }
  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.initQueryParams();
    this.initFilterForm();
    this.getTemplateSuggestions()
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    this.listname = PreferenceService.pageTemplateList;
    this.callRefreshPageTemplateListService();

    this.filtered = this.filterExists
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callRefreshPageTemplateListService();
  }

  navigate(event) {
    if (event.action === "view") {
      this.pageTemplateService.sideNav = true;
      this._router.navigate(['self-service/pageTemplate/' + event.data + "/responses"]);
    }
  }

  getTemplateSuggestions() {
    this.projectService.getSuggestions("pagetemplate", '').subscribe(
      suggestions => this.templateSuggestion = suggestions,
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  callRefreshPageTemplateListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.pageTemplateService.getPageTemplateList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        pages => {
          this.isError = false;
          this.pageTemplateList = pages.body as ICreatePageTemplates;
          if (pages.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(pages.headers.get('X-pagination')) as IPager);
         
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
         
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  refresh() {
    this.callRefreshPageTemplateListService();
  }

}
