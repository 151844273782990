import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ManageHelpArticleService } from '../services/manageHelp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isUndefined } from 'util';
import { ICreateTopic, ICreateTopicForm } from '../../../../../admin/manageForms/models/createTopic';
import { ISuggestion, ISuggestions } from '../../../../../shared/suggestion';
import { PermissionsService } from '../../../../../services/permissions.service';
import { ProjectsService } from '../../../../../services/projects.service';
import { HelpService } from '../../../../../services/help.service';
import { UserService } from '../../../../../services/user.service';
import { EditEntityLevel, MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from '../../../../../core/constants';
import { NoWhitespaceValidator } from '../../../../../shared/no-whitespace.validator';
import { EmailTemplateService } from '../../../manageEmailTemplates/services/email-template.service';

@Component({
  selector: 'app-create-topic',
  templateUrl: './create-topic.component.html',
  styleUrls: ['./create-topic.component.css']
})
export class CreateTopicComponent implements OnInit {
  createFormGroup: FormGroup;
  formVal: ICreateTopicForm;
  topics: ICreateTopic;
  showCopyArticle: boolean = false;
  canUseExistingArticle: boolean = false;
  isAdmin: boolean = false;
  isDomainAdmin: boolean = false;
  isEdit: boolean = false;
  isSubmitted: boolean = false;
  invalidCatName: boolean = false;
  invalidOrganizationName: boolean = false;
  invalidProductName: boolean = false;
  invalidFormName: boolean = false;
  isReady: boolean = false;
  isError: boolean = false;

  _maxLength: any;
  productName: string;

  catSuggestion: ISuggestion[];
  organizationSuggestion: ISuggestion[];
  productSuggestion: ISuggestions;
  formSuggestion: ISuggestions;
  helpArticleSuggestion: ISuggestion[];

  organizationDetails: any;
  json: any;

  flowName: string = "";
  returnPage: string = "";
  errorMsg: string = "";

  selectedEntityLevelValue: any;
  productId: any;
  formId: any;
  selectedOrgId: number;
  entityLevel: any;
  articleList: any = [];
  productText: string;
  regexValidationMessage: any;

  constructor(
    private _router: Router,
    private fb: FormBuilder,
    private _permissionService: PermissionsService,
    private projectService: ProjectsService,
    private route: ActivatedRoute,
    private helpService: HelpService,
    private manageHelpService: ManageHelpArticleService,
    private emailTemplateService: EmailTemplateService,
    private _userService: UserService,
  ) { }

  initiateForm() {
    if (this.isEdit == true || (this.manageHelpService != undefined && this.manageHelpService.isReturn)) {
      this.formVal = this.manageHelpService.formVal;
      this.selectedOrgId = this.formVal.organizationId;
      this.selectedEntityLevelValue = this.formVal.entityLevel;
      this.manageHelpService.isReturn = false;
    }

    this.getProductSuggestions();
    this.getFormSuggestions();
    this.getCategorySuggestions();
    this.getTopicSuggestions();

    this.createFormGroup = this.fb.group({
      formNameCtrl: new FormControl(this.formVal ? this.formVal.topicName : "", [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator), NoWhitespaceValidator.validateWhiteSpaces, Validators.maxLength(MAX_LENGTHS.Form.Name)]),
      formDesCtrl: new FormControl(this.formVal ? this.formVal.topicDes : "", [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator), NoWhitespaceValidator.validateWhiteSpaces, Validators.maxLength(MAX_LENGTHS.Form.Description)]),
      jsonCtrl: new FormControl(),
      catNameCtrl: new FormControl(this.formVal ? this.formVal.topicCatName : "", [Validators.required, Validators.pattern(REGEX_PATTERNS.name_title_validator)]),
      organization: new FormControl(this.formVal?.entityLevel === 'Organization' ? this.formVal?.entityName : this.formVal?.organizationName || this.organizationDetails.organizationTitle, [Validators.required, Validators.pattern(REGEX_PATTERNS.organization_name_validator)]),
      isRecommendedCtrl: new FormControl(this.formVal?.isRecommended === "true" ? "1" : "0"),
      isPublishable: new FormControl(this.formVal ? (this.formVal?.isPublishable === "true" ? "true" : "false") : "true", [Validators.required]),
      entityNameProduct: new FormControl(this.formVal?.entityLevel === 'Product' ? this.formVal?.entityName : "", [Validators.required]),
      entityNameForm: new FormControl(this.formVal?.entityLevel === 'Form' ? this.formVal?.entityName : "", [Validators.required, Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      UseExistingArticleCtlr: new FormControl(false, [Validators.required]),
      articleNameCtrl: new FormControl("", [Validators.required]),
      entityLevel: new FormControl("", [Validators.required])
    });

    this.isReady = true;
  }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.showCopyArticle = this._userService.getPermissions()["showhelpArticlelink"];
    if (this.manageHelpService != undefined && !this.manageHelpService.isReturn){
      let a: ICreateTopicForm;
      this.manageHelpService.formVal = a;
      this.manageHelpService.isReturn = false;
    }
    this.initProperties();

    if (this._router.url.indexOf('edit') !== -1) {
      this.isEdit = true;
      if (this.manageHelpService.formVal) {
        this.initiateForm();
      }
      else {
        this.route.params.subscribe((params) => {
          const category = this.helpService.getTopicById(params.id).subscribe(suggestions => {
            this.topics = suggestions;
            this.json = JSON.parse(this.topics.formJSON);
            let topicDetails: ICreateTopicForm = {
              id: params.id,
              topicDes: this.topics.description,
              organizationId: this.topics.entityLevel === "Organization" ? this.topics.entityId : null,
              orgName: this.topics.entityLevel === "Organization" ? this.topics.entityName : "",
              productName: this.topics.entityLevel === "Product" ? this.topics.entityName : "",
              productId: this.topics.entityLevel === "Product" ? this.topics.entityId : null,
              formId: this.topics.entityLevel === "Form" ? this.topics.entityId : null,
              topicName: this.topics.title,
              json: this.topics.formJSON,
              topicCate: this.topics.helpCategoryName,
              topicCatName: this.topics.helpCategoryName,
              organization: this.topics.entityLevel === "Organization" ? this.topics.entityName : "",
              isPublishable: this.topics.isPublishable.toString(),
              isRecommended: this.topics.isRecommended.toString(),
              entityLevel: this.topics.entityLevel,
              entityName: this.topics.entityName,
              entityId: this.topics.entityId,
              organizationName: this.topics.organizationName
            }
            this.manageHelpService.formVal = topicDetails;
            if (params.flowname)
              this.manageHelpService.flowName = params.flowname;
            else
              this.manageHelpService.flowName = '';

            this.initiateForm();
          });
        })
      }
    }
    else {
      this.initiateForm();
    }

    const productSuggestion = this.projectService.getProductSuggestions("products", [{paramName: "orgId", value: this.selectedOrgId}], 100);
    const organization = this.projectService.getSuggestions('organizations', '')
    const category = this.projectService.getSuggestions('helpCategories', this.selectedOrgId, "orgId")

    forkJoin([
      productSuggestion,
      category,
      organization
    ]).subscribe(suggestions => {
      this.productSuggestion = suggestions[0];
      this.catSuggestion = suggestions[1] as unknown as ISuggestion[];
      this.organizationSuggestion = suggestions[2] as unknown as ISuggestion[];;
    });

    this.returnTo()
  }

  initProperties() {
    this._maxLength = MAX_LENGTHS;
    this.entityLevel = Object.values(EditEntityLevel).filter(value => typeof value !== 'number');
    this.selectedEntityLevelValue = "";
    this.isAdmin = this._permissionService.userData.isAdmin;
    this.organizationDetails = Object.assign({}, this._userService.getUserListData());
    this.selectedOrgId = this.organizationDetails.organization;

    for (var i = 0; i < this._permissionService.userData.roles.length; i++) {
      if (this._permissionService.userData.roles[i].roleId == 2) {
        this.isDomainAdmin = true;
      }
    }
  }

  defineArticle(articleId: number){
    return this.helpService.getTopicById(articleId).toPromise()
  }

  returnTo(){
    switch (this.manageHelpService.returnPage) {
      case '/self-service/home':
        this.returnPage = 'Self Service'
        break;
      case '/self-service/helpTopic':
        this.returnPage = 'Resource Articles'
      case '/help/category':
        this.returnPage = 'Resource Center'
        break;
      default:
        this.returnPage = 'Resource Articles'
    }
  }

  async saveForm() {
    this.isSubmitted = true;
    this.invalidProductName = false;
    this.invalidFormName = false;
    this.invalidCatName = false;
    let orgName = this.createFormGroup.get('organization').value;
    let productName = this.createFormGroup.get('entityNameProduct').value;
    let formName = this.createFormGroup.get('entityNameForm').value;

    if(this.createFormGroup.get('UseExistingArticleCtlr').value === ''){return;}

    let articleId = this.createFormGroup.get('articleNameCtrl').value;
    let articleSelected = {}

    if(this.createFormGroup.get('UseExistingArticleCtlr').value){
      articleId = this.articleList.find(x => x.title === articleId).id
      articleSelected = await (await this.defineArticle(articleId)).formJSON
    }

    if (this.selectedEntityLevelValue === "") {return;}
    if (this.createFormGroup.get('formNameCtrl').value === "") {return;}
    if (this.createFormGroup.get('formDesCtrl').value === "") {return;}

    let catName = this.createFormGroup.get('catNameCtrl').value;
    let foundCatObj: number;
    if (catName && catName.trim().length > 0) {
      foundCatObj = this.projectService.fetchIdForRequest(this.catSuggestion, catName);
      if (foundCatObj < 1) {
        this.invalidCatName = true;
        return;
      }
    } else {
      return;
    }

    if (this.selectedEntityLevelValue !== "") {
      if (this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue === 'Product') {
        this.productId = this.projectService.fetchIdForRequest(this.productSuggestion, productName);
        if (this.productId < 1) { this.invalidProductName = true; return; }
      }

      if (this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue === 'Form') {
        this.formId = this.emailTemplateService.fetchIdForRequestByTitle(this.formSuggestion, formName);
        if (this.formId < 1) { this.invalidFormName = true; return; }
      }

      if (this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue === 'Organization') {
        if (orgName && orgName.trim().length > 0) {
          this.selectedOrgId = this.projectService.fetchIdForRequest(this.organizationSuggestion, orgName);
          if (this.selectedOrgId < 1) {
            this.invalidOrganizationName = true;
            return;
          }
        }
      }
    }

    let json = ''
    if (!isUndefined(this.formVal) && this.formVal.json != undefined) {
      json = this.formVal.json;
    }

    if (this.isEdit) {
      this.manageHelpService.formVal.topicName = this.createFormGroup.get('formNameCtrl').value;
      this.manageHelpService.formVal.topicDes = this.createFormGroup.get('formDesCtrl').value;
      this.manageHelpService.formVal.json = json;
      this.manageHelpService.formVal.topicCate = foundCatObj;
      this.manageHelpService.formVal.topicCatName = catName;
      this.manageHelpService.formVal.organization = orgName;
      this.manageHelpService.formVal.organizationId = this.selectedOrgId;
      this.manageHelpService.formVal.productName = productName;
      this.manageHelpService.formVal.productId = this.productId;
      this.manageHelpService.formVal.formName = formName;
      this.manageHelpService.formVal.formId = this.formId;
      this.manageHelpService.formVal.entityLevel = this.createFormGroup.get('entityLevel').value;
      this.manageHelpService.formVal.isPublishable = this.createFormGroup.get('isPublishable').value;
      this.manageHelpService.formVal.isRecommended = this.createFormGroup.get('isRecommendedCtrl').value === "0" ? "false" : "true";
      this._router.navigate(['/self-service/helpTopic/templateedit/' + this.manageHelpService.formVal.id]);
    }
    else {
      let values: ICreateTopicForm = {
        topicName: this.createFormGroup.get('formNameCtrl').value,
        topicDes: this.createFormGroup.get('formDesCtrl').value,
        organization: orgName,
        productName: productName,
        formName: formName,
        organizationId: this.selectedOrgId,
        productId: this.productId,
        formId: this.formId,
        json: Object.entries(articleSelected).length != 0 ? articleSelected : json,
        topicCate: foundCatObj,
        topicCatName: catName,
        isPublishable: this.createFormGroup.get('isPublishable').value,
        isRecommended: this.createFormGroup.get('isRecommendedCtrl').value == "0" ? "false" : "true",
        entityLevel: this.createFormGroup.get('entityLevel').value
      }
      this.manageHelpService.formVal = values;
      this._router.navigate(['/self-service/helpTopic/template']);
    }
  }

  back() {
    let a: ICreateTopicForm;
    this.manageHelpService.formVal = a;
    this.manageHelpService.isReturn = false;
    this.manageHelpService.returnPage = '';
    this._router.navigate(['/self-service/helpTopic']);
  }

  getFormSuggestions() {
    this.projectService.getSuggestions('forms', this.selectedOrgId, "orgId")
      .subscribe(
        suggestions => {this.formSuggestion = suggestions;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }

  getCategorySuggestions() {
    this.projectService.getSuggestions('helpCategories', this.selectedOrgId, "orgId")
      .subscribe(
        suggestions => {this.catSuggestion = suggestions as unknown as ISuggestion[];
        },
        (error) => { this.isError = true; this.errorMsg = error; }
      );
  }

  getTopicSuggestions() {
    let orgId = this._permissionService.userData.isAdmin ? 0 : this.selectedOrgId
    this.projectService.getSuggestions('helpTopics', orgId, "orgId")
      .subscribe(suggestions => {
        this.helpArticleSuggestion = suggestions as unknown as ISuggestion[];
        this.canUseExistingArticle = this.helpArticleSuggestion && this.helpArticleSuggestion.length > 0;
        this.articleList = suggestions;
      },
      (error) => { this.isError = true; this.errorMsg = error; });
  }


  onEntityProductSelect(event: any) {
    if(event.item.id > 0)
    {
      this.invalidProductName = false;
    }
  }

  onEntityFormSelect(event: any) {
    if(event.item.id > 0)
    {
      this.invalidFormName = false;
    }
  }

  setEntityValues() {
    this.createFormGroup.get('entityNameProduct').setValue("");
    this.createFormGroup.get('entityNameForm').setValue("");
    this.productId = "";
    this.formId  = "";
    this.invalidProductName = false;
    this.invalidFormName = false;
    this.invalidOrganizationName = false;
    this.getFormSuggestions();
    this.getProductSuggestions();
    this.getCategorySuggestions();
  }

  onOrgSelect(event: any) {
    if(event.item.id > 0)
    {
      this.invalidOrganizationName = false;
      this.selectedOrgId = event.item.id;
      this.createFormGroup.get('entityLevel').setValue("");
      this.setEntityValues();
    }
  }

  getProductSuggestions(){
		let suggestionSubscriber: any;
		let params = [];
		if (this.productText && this.productText.length > 2) {
			params.push({paramName: "title", value: this.productText});
			params.push({paramName: "orgId", value: this.selectedOrgId});
			suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
		}
		else {
			params.push({paramName: "orgId", value: this.selectedOrgId});
			suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
		}

		suggestionSubscriber.subscribe(
			data=> {
			this.productSuggestion = data;
			},
			error => { this.isError = true; this.errorMsg = error; }
		);
	}

	typeaheadLoadingProducts() {
		this.getProductSuggestions();
	}
}
