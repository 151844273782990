<!------    Another Set Button ------->
<div class="col-md-12 float-right text-right pr-0 fdl-2">
  <button type="button" class="pull-right btn fdl-btn btn-primary-orange ml-2 pl-2 pr-2 mb-3 add-set" (click)="openAddDrawer(1, 'formApprover')"><span class="material-icons f-22"> group_add </span> Create New User </button>
  <button  class="pull-right btn fdl-btn btn-primary-orange ml-2 pl-2 pr-2 mb-3 add-set" type="button" (click)="anotherSet();">
    <span class="material-icons f-22"> library_add </span>
    Create Another Set
  </button>


  <div>
  </div>
</div>
<div class="clearfix"></div>
<div class="alert alert-dismissible alert-danger" *ngIf="selectForms">
 <button type="button" class="close" data-dismiss="alert" aria-label="close"
     (click)="selectForms=false">×</button>
 <i class="material-icons">warning</i>
 <p>Please assign forms by using "Select Forms" of {{msgTitle}}</p>
</div>
<div class="alert alert-dismissible alert-danger" *ngIf="dueDateMsg">
    <button type="button" class="close" data-dismiss="alert" aria-label="close"
        (click)="dueDateMsg=''">×</button>
    <i class="material-icons">warning</i>
    <p [innerHTML]="dueDateMsg"></p>
</div>
<div class="alert alert-dismissible alert-danger" *ngIf="isError">
 <button type="button" class="close" data-dismiss="alert" aria-label="close"
     (click)="isError=false">×</button>
 <i class="material-icons">warning</i>
 <p [innerHtml]="errorMsg"></p>
</div>
<div class="clearfix"></div>

<div class="col-md-12 box pt-1" id="formSet" *ngFor="let formSet of formSets; let i=index; let first = first">
    <a *ngIf="!first" href="javascript:void(0);" class="close pull-right mt-3" aria-label="close" (click)="closeFormset(i);">&times;</a>
    <h3 class="h5 heading-level-3 mt-0">{{formSet.title}}</h3>


    <p class="mt-1" style="    font-weight: 500;    color: #000000d1;">Select/De-select Forms to Assign:</p>
    <button class="btn fdl-btn btn-primary-dark" id="selectForms_{{i}}" type="button" data-toggle="modal" (click)="selectFormBtn(i)">
      Select Forms
  </button>

    <div class="col-md-12 p-0 mt-3" *ngIf="formSets[i].showData">
        <p class="mb-0" *ngIf="formSets[i].assignedFormsList.length > 0"> <b>Note:</b> To edit the Form Name, Description or Due Date, double-click the respective record.</p>
        <fdl-data-grid [rows]="formSets[i].assignedFormsList" [columns]="columns" emptyMessage="No Data Available"
            columnMode="standard">
        </fdl-data-grid>
    </div>


    <form [formGroup]="formSet.assignForm" autocomplete="off">

      <div class="row">

        <div class="col-md-6 mb-3">
          <p class="mt-2" style="    font-weight: 500;    color: #000000d1;">Select Respondent Type:</p>
          <fieldset>
            <legend class="sr-only"></legend>

            <div class="radio-group pt-0 d-flex">
              <div class="radio mr-4">
                <input type="radio" id="userSelection_{{i}}" value="user" name="unauthenticatedType"
                       formControlName="unauthenticatedType" (change)="settingUnauthenticatedSurveyType(i);">
                <label for="userSelection_{{i}}"><span>Registered User</span></label>
              </div>
              <div class="radio mr-4">
                <input type="radio" id="nonuserSelection_{{i}}" value="nonuser" name="unauthenticatedType"
                       formControlName="unauthenticatedType" (change)="settingUnauthenticatedSurveyType(i);">
                <label for="nonuserSelection_{{i}}"><span>Guest</span></label>
              </div>
              <!-- <div class="radio mr-4">
            <input type="radio" id="groupSelection_{{i}}" value="group" name="unauthenticatedType"
                formControlName="unauthenticatedType" (change)="settingUnauthenticatedSurveyType(i);">
            <label for="groupSelection_{{i}}"><span>DRMS User Group</span></label>
        </div>-->
              <div class="radio mr-4">
                <input type="radio" id="bulkSelection_{{i}}" value="bulk" name="unauthenticatedType"
                       formControlName="unauthenticatedType" (change)="settingUnauthenticatedSurveyType(i);">
                <label for="bulkSelection_{{i}}"><span>Bulk Distribution</span></label>
              </div>
            </div>
          </fieldset>
        </div>


      </div>
      <!-- formSet.assignForm -->
      <div class="row align-items-start" *ngIf="formSet.selectedUnauthenticatedType === 'bulk'">
        <div class="col-md-6">
          <label for="excelinput" class="asterisk">Select Bulk Distribution File:</label>
          <div class="custom-file"
          [ngClass]="{'has-error': (!formSet.assignForm.get('formTemplate').valid ||
          formSet.assignForm.controls.formTemplate.errors?.required ||
              !validFile || !validFileName) && (formSet.assignForm.controls.formTemplate.touched || isSubmitted)}">
            <label for="excelinput_{{i}}" class="custom-file-label text-truncate pr-80" data-toggle="tooltip"
                   data-placement="top" [title]="formSet.templateName">{{formSet.templateName}}</label>
            <input type="file" class="custom-file-input form-control" multiple="false" (click)="templateInputClicked(i)" (change)="onTemplateChange($event,i)" id="excelinput_{{i}}" #templateInput />
            <div class="has-error">
              <span *ngIf="(formSet.assignForm.get('formTemplate').errors?.required || !validFile) && (formSet.assignForm.get('formTemplate').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select valid Excel file.
              </span>
              <span *ngIf="formSet.assignForm.get('formTemplate').errors?.invalidExt && (formSet.assignForm.get('formTemplate').touched || isSubmitted)">
                <i class="material-icons">warning</i> Please select a valid file.
              </span>
              <span class="has-error" *ngIf="(!validFileType) && (formSet.controls.formTemplate.touched || isSubmitted)">
                  <i class="material-icons">warning</i> Please select valid file type.
              </span>
              <span *ngIf="(formSet.assignForm.get('formTemplate').errors?.required || !validFileName) && (formSet.assignForm.get('formTemplate').touched || isSubmitted)">
                <i class="material-icons">warning</i> {{regexValidationMessage.FILENAME_VALIDATION_MESSAGE}}
              </span>
            </div>
          </div>
          <div class="d-inline">
            <p><a href="javascript:void(0);" (click)="downloadTemplate()">Click here</a> to download Bulk Distribution template.</p>
          </div>
        </div>
        <div class="form-typeahead-wrapper">
          <app-mutipleadd #addApprover  *ngIf="formSet.formType !='intake'" buttonText="Add Approver" [isAddButtonEnable]="false" (formReady)="formInitialized('formApprover',i ,$event)" [isSubmitted]="isSubmitted" [isFdl]="true" [fdlData]="formsUsersDetails.usersData" [filedJson]="filedformApproverJson" elementName="formApprover" [filedDetails]="filedformApproverDetails2" [formControlData]="formSet.assignForm"></app-mutipleadd>
          <app-mutipleadd #addResponder *ngIf="formSet.selectedUnauthenticatedType!='bulk'" [isAddButtonEnable]="false" (formReady)="formInitialized('formResponder',i ,$event)" [isSubmitted]="isSubmitted" [isFdl]="false" [fdlData]="formsUsersDetails.usersData" [filedJson]="dummyJson" elementName="formResponder" [filedDetails]="filedDetails" [formControlData]="formSet.assignForm"></app-mutipleadd>
          <!--<div class="pr-2 action-link-single guest" [ngClass]="{'pt-3': !(formSet.approverEmail !=='')}" *ngIf="isCreateUserAllowed">
            <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Approver" (click)="openAddDrawer(i, 'formApprover')"><i class="material-icons f-30">add_circle_outline</i></a>
          </div>-->

        </div>


      </div>

      <div *ngIf="formSet.selectedUnauthenticatedType === 'user'">
        <div class="row mb-3">
          <div class="form-typeahead-wrapper" [ngClass]="{'has-error': ( formSet.assignForm.get('formResponder').errors?.duplicate) }">

            <app-mutipleadd #addResponder [isAddButtonEnable]="true" (formReady)="formInitialized('formResponder',i ,$event)" [isSubmitted]="isSubmitted" [isFdl]="true" [fdlData]="formsUsersDetails.usersData" [filedJson]="filedJson" elementName="formResponder" [filedDetails]="filedDetails" [formControlData]="formSet.assignForm"></app-mutipleadd>
            <!--<div class="pr-2 action-link-single" [ngClass]="{'pt-3': !(formSet.respondentEmail !== '')}" *ngIf="isCreateUserAllowed">
      <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Form Respondent" (click)="openAddDrawer(i, 'formResponder')"><i class="material-icons f-30">add_circle_outline</i></a>
    </div>-->
            <span *ngIf="formSet.assignForm.get('formResponder').errors?.duplicate">
              <i class="material-icons">warning</i>Duplicate respondent not allowed.
            </span>
          </div>



          <div *ngIf="formSet.formType !='intake'" class="form-typeahead-wrapper p-0" [ngClass]="{'has-error': ( formSet.assignForm.get('formApprover').errors?.duplicate || formSet.assignForm.get('formApprover').errors?.duplicate2) }">
            <app-mutipleadd #addApprover  *ngIf="formSet.formType !='intake'" buttonText="Add Approver" [isAddButtonEnable]="true" (formReady)="formInitialized('formApprover',i ,$event)" [isSubmitted]="isSubmitted" [isFdl]="true" [fdlData]="formsUsersDetails.usersData" [filedJson]="filedformApproverJson" elementName="formApprover" [filedDetails]="filedformApproverDetails" [formControlData]="formSet.assignForm"></app-mutipleadd>
            <!--<div class="pr-2 action-link-single combo-element" [ngClass]="{'pt-3': !(formSet.approverEmail !=='')}" *ngIf="isCreateUserAllowed">
      <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Approver" (click)="openAddDrawer(i, 'formApprover')"><i class="material-icons f-30">add_circle_outline</i></a>
    </div>-->
            <span *ngIf="formSet.assignForm.get('formApprover').errors?.duplicate">
              <i class="material-icons">warning</i>The Approver can not be duplicated at the same level.
            </span>
            <span *ngIf="formSet.assignForm.get('formApprover').errors?.duplicate2">
              <i class="material-icons">warning</i>Duplicate Approver
            </span>
            <span *ngIf="formSet.assignForm.get('formApprover').errors?.invalid">
              <i class="material-icons">warning</i>The Level should be an integer number greater than or equal to 1.
            </span>
          </div>

            <div class="form-typeahead-wrapper p-0 ml-3"  *ngIf="formSet.formType !='intake'" style="left: 85px;top: -11px;">
              <label class="mt-2" >Select Approver Criteria:<span _ngcontent-edj-c316="" data-toggle="tooltip" title="Select Approval Criteria if Multiple Approvers at same level." class="material-icons btn-secondary f-18"> info</span></label>


              <div >

                <div class="fdl-select">
                  <select class="form-control" formControlName="approverSchema"
                          name="approverSchema">
                    <option value="OR">ANY</option>
                    <option value="AND">ALL</option>

                  </select>
                </div>
              </div>


          </div>

        </div>

      </div>


      <div *ngIf="formSet.selectedUnauthenticatedType === 'nonuser'">

        <div [ngClass]="{'has-error': ( formSet.assignForm.get('guestResponder').errors?.duplicate) }">
          <app-mutipleadd #addResponder [isAddButtonEnable]="true" (formReady)="formInitialized('guestResponder',i, $event)" [filedJson]=" guestfieldJson" elementName="guestResponder" [filedDetails]="guestUserForm" [formControlData]="formSet.assignForm"></app-mutipleadd>
          <span *ngIf="formSet.assignForm.get('guestResponder').errors?.duplicate">
            <i class="material-icons">warning</i>Duplicate respondent not allowed.
          </span>

        </div>
        <div class="row">

          <div *ngIf="formSet.formType !='intake'" class="form-typeahead-wrapper" [ngClass]="{'has-error': ( formSet.assignForm.get('formApprover').errors?.duplicate) }">
            <app-mutipleadd #addApprover  *ngIf="formSet.formType !='intake'" buttonText="Add Approver" [isAddButtonEnable]="true" (formReady)="formInitialized('formApprover',i ,$event)" [isSubmitted]="isSubmitted" [isFdl]="true" [fdlData]="formsUsersDetails.usersData" [filedJson]="filedformApproverJson" elementName="formApprover" [filedDetails]="filedformApproverDetails" [formControlData]="formSet.assignForm"></app-mutipleadd>

            <!--<div class="pr-2 action-link-single guest" [ngClass]="{'pt-3': !(formSet.approverEmail !=='')}" *ngIf="isCreateUserAllowed">
      <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Approver" (click)="openAddDrawer(i, 'formApprover')"><i class="material-icons f-30">add_circle_outline</i></a>
    </div>-->
            <span *ngIf="formSet.assignForm.get('formApprover').errors?.duplicate">
              <i class="material-icons">warning</i>The Approver can not be duplicated at the same level.
            </span>
            <span *ngIf="formSet.assignForm.get('formApprover').errors?.invalid">
              <i class="material-icons">warning</i>The Level should be an integer number greater than or equal to 1.
            </span>
          </div>
          <div class="form-typeahead-wrapper p-0"  *ngIf="formSet.formType!='intake'" style="left: 75px;top: -11px;">
            <label class="mt-2">Select Approver Criteria:<span _ngcontent-edj-c316="" data-toggle="tooltip" title="Select Approval Criteria if Multiple Approvers at same level." class="material-icons btn-secondary f-18"> info</span></label>


            <div>

              <div class="fdl-select">
                <select class="form-control" formControlName="approverSchema"
                        name="approverSchema">
                  <option value="OR">ANY</option>
                  <option value="AND">ALL</option>

                </select>
              </div>
            </div>


          </div>
        </div>
      </div>


      <div class="form-set-form-container row align-items-start" *ngIf="formSet.selectedUnauthenticatedType === 'group'">

        <div class="col-md-5 typeahead-wrapper" [ngClass]="{'has-error': !formSet.assignForm.get('formRespondentEmailGroup').valid && (formSet.assignForm.get('formRespondentEmailGroup').touched || isSubmitted)}">
          <label for="formResponder" class="asterisk">Respondent Email Group:</label>
          <fdl-type-ahead formControlName="formRespondentEmailGroup" optionField="title" placeholder="Type to search"
                          [collection]="formsUsersDetails.emailGroup" (onSelect)="selectEmailGroup($event, i)"
                          [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
          <span *ngIf="!formSet.assignForm.get('formRespondentEmailGroup').valid && formSet.assignForm.get('formRespondentEmailGroup').errors?.required && (formSet.assignForm.get('formRespondentEmailGroup').touched || isSubmitted)">
            <i class="material-icons">warning</i> Please enter an email group.
          </span>
          <div *ngIf="isViewDetailsLink[i]" class="mt-1"> <a href="javascript:void(0)" (click)="viewLink()"><b>View group members</b></a></div>
        </div>
        <div class="col-md-5 typeahead-wrapper">
          <label for="formApprover">Approver:</label>
          <fdl-type-ahead formControlName="formApprover" optionField="name" placeholder="Type to search"
          (onSelect)="selectApprover($event, i)" (noResults)="noApproverMatched($event,i)"
                          [collection]="formsUsersDetails.users" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
          <span  style="display: block;padding-top: 5px;color: #000000;padding-left: 10px;font-weight: 500;">
            {{formSet.approverEmail}}
          </span>

        </div>
        <!--<div class="pr-2 align-self-center"   [ngClass]="{'pt-3': !(formSet.approverEmail !=='')}" *ngIf="isCreateUserAllowed">
          <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Approver" (click)="openAddDrawer(i, 'formApprover')"><i class="material-icons f-30">add_circle_outline</i></a>
        </div>-->
      </div>
    </form>
</div>


<div class="fdl-2 button-container">

  <button type="button" class="btn fdl-btn btn-secondary-light " aria-label="Default" (click)="navigationForm()">Cancel</button>
  <div>
    <div class="btn-group btn-split">
        <a href="javascript:void(0)" class="btn fdl-btn btn-primary-orange" (click)="submitForm()">Submit</a>
    </div>
  </div>
  </div>

<!---------------------Select Form Modal----------------------->
<select-forms #selectedRows [allRowsSelection]="allRowsSelection" [productSection]="true" [productTitle]="productTitle" [formList]="formsUsersDetails.forms"
    [formSets]="formSets" [currentIndex]="currentIndex" (onFormDataLoaded)="LoadRespondents($event)"></select-forms>

<!----------------------Email Group Members Modal---------------->
<emailgroup-members [emailGroupTitle]="selectedEmailGroup['title']"  [emailGroupDetails]="emailgroupMembers"></emailgroup-members>
