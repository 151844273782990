<div class="heading-container">
  <h1>{{content['responseHeaderDashboard']}}</h1>
</div>

<div class="main-container-sub form-responces-page responsive-container col-settings-drawer-alignment">
  <div class="form-container">
    <div class="row">
      <section class="col-lg-12 p-0">
        <div class="alert alert-success alert-dismissible" *ngIf="approvalMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="approvalMsg=''">×</button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{approvalMsg}}

          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="isPublishForms">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isPublishForms=false">×</button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{message}}

          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="delegatedFormMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="delegatedFormMsg=''">×</button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{delegatedFormMsg}}

          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="updateApproverMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="updateApproverMsg=''">×</button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{updateApproverMsg}}

          </p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="reinitiatedFormMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close"
          (click)="reinitiatedFormMsg=''">
    ×
  </button>
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{reinitiatedFormMsg}}

          </p>
        </div>
        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="deletedFormMsg">
          <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
          <span class="iconLabel" aria-label="sucess">
          </span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p>
            {{deletedFormMsg}}

          </p>
        </div>
        <form class="form-border mb-3 box collapse-view" [formGroup]="filterForm" autocomplete="off">
          <h2 class="h4  collapse-arrow" [ngClass]="[filterExists ? '':'collapsed']" data-toggle="collapse" href="#collapseFilter">{{content['searchFormResponse']}}</h2>
          <div id="filter-typeahead" class="filter-form-container-column2 search-filter filter-flex-wrap collapse two-line-filter" [ngClass]="[filterExists ? 'show':'']" id="collapseFilter">
            <div class="filter-form-control typeahead-wrapper pr-3">
              <label for="Status">ID:</label>
              <div class="" [ngClass]="{'has-error': !filterForm.get('id').valid && (filterForm.get('id').touched || filterForm.get('id').dirty)}">
                <input type="number" min="0" name="id" [placeholder]="content['placeholderSearchFormResponse']" class="form-control" aria-invalid="false" formControlName="id" />
                <span *ngIf="!filterForm.get('id').valid && filterForm.get('id').errors?.pattern && (filterForm.get('id').touched || filterForm.get('id').dirty)">
                  <i class="material-icons">warning</i>Please enter a valid ID</span>
              </div>

            </div>
            <div class="filter-form-control pr-3 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('title').valid && (filterForm.get('title').touched || filterForm.get('title').dirty)}">
              <span *ngIf="!filterForm.get('title').valid && filterForm.get('title').errors?.pattern && (filterForm.get('title').touched || filterForm.get('title').dirty)">
                <i class="material-icons">warning</i>Please enter a valid Form Name</span>
              <fdl-type-ahead [label]="content['formNameColumnDashboard'] + ':'" formControlName="title" [collection]="responsesSuggestion" optionField="title"
                              [placeholder]="content['placeholderSearchFormResponse']" [scrollable]="true" [optionsLimit]="500" [minLength]="0" class="filter-typeahead">
              </fdl-type-ahead>
            </div>
            <div class="filter-form-control pr-3 typeahead-wrapper" [ngClass]="{'has-error': !filterForm.get('formTemplate').valid && (filterForm.get('formTemplate').touched || filterForm.get('formTemplate').dirty)}">
              <span *ngIf="!filterForm.get('formTemplate').valid && filterForm.get('formTemplate').errors?.pattern && (filterForm.get('formTemplate').touched || filterForm.get('formTemplate').dirty)">
                <i class="material-icons">warning</i>Please enter a valid Form Template Name</span>
              <fdl-type-ahead [label]="content['formTemplateNameColumnDashboard'] + ':'" formControlName="formTemplate" [collection]="formTemplateSuggestion" optionField="title"
                              [placeholder]="content['placeholderSearchFormResponse']" [scrollable]="true" [optionsLimit]="500" [minLength]="0"  class="filter-typeahead">
              </fdl-type-ahead>
            </div>
            <div id="form-responses-project-filter" class="filter-form-control pr-3 typeahead-wrapper" *ngIf="showProjectFilter"
            [ngClass]="{'has-error': !filterForm.get('ProjectTitle').valid && (filterForm.get('ProjectTitle').touched || filterForm.get('ProjectTitle').dirty)}">
              <span *ngIf="!filterForm.get('ProjectTitle').valid && filterForm.get('ProjectTitle').errors?.pattern && (filterForm.get('ProjectTitle').touched || filterForm.get('ProjectTitle').dirty)">
                <i class="material-icons">warning</i>Please enter a valid Project Name</span>
              <fdl-type-ahead label="Project Name:" formControlName="ProjectTitle" [collection]="projectSuggestion" optionField="title"
                              [placeholder]="content['placeholderSearchFormResponse']" [scrollable]="true" [optionsLimit]="500" [minLength]="0" class="filter-typeahead">
              </fdl-type-ahead>
            </div>
            <div id="form-responses-client-filter" class="filter-form-control pr-3 typeahead-wrapper" *ngIf="isClientFilterAllowed"
            [ngClass]="{'has-error': !filterForm.get('ClientTitleWithDuns').valid && (filterForm.get('ClientTitleWithDuns').touched || filterForm.get('ClientTitleWithDuns').dirty)}">
              <span *ngIf="!filterForm.get('ClientTitleWithDuns').valid && filterForm.get('ClientTitleWithDuns').errors?.pattern && (filterForm.get('ClientTitleWithDuns').touched || filterForm.get('ClientTitleWithDuns').dirty)">
                <i class="material-icons">warning</i> Please enter a valid Client Name</span>
              <fdl-type-ahead label="Client Name/DUNS:" formControlName="ClientTitleWithDuns" [collection]="clientSuggestion" optionField="titleWithDuns"
                              [placeholder]="content['placeholderSearchFormResponse']" [scrollable]="true" [optionsLimit]="100" [minLength]="0" (input)="typeaheadLoadingClients()" class="filter-typeahead">
              </fdl-type-ahead>
            </div>
            <div id="form-responses-product-filter" class="filter-form-control pr-3 typeahead-wrapper" *ngIf="showProductFilter"
            [ngClass]="{'has-error': !filterForm.get('ProductTitle').valid && (filterForm.get('ProductTitle').touched || filterForm.get('ProductTitle').dirty)}">
              <span *ngIf="!filterForm.get('ProductTitle').valid && filterForm.get('ProductTitle').errors?.pattern && (filterForm.get('ProductTitle').touched || filterForm.get('ProductTitle').dirty)">
                <i class="material-icons">warning</i>Please enter a valid Product Name</span>
              <fdl-type-ahead label="Product Name:" formControlName="ProductTitle" [collection]="productSuggestion" optionField="title"
                              [placeholder]="content['placeholderSearchFormResponse']" [scrollable]="true" [optionsLimit]="100" [minLength]="0" class="filter-typeahead"
                              (input)="typeaheadLoadingProducts()">
              </fdl-type-ahead>
            </div>
            <div class="filter-form-control pr-3 typeahead-wrapper" *ngIf="showAssignedToFilter"
            [ngClass]="{'has-error': !filterForm.get('AssignedTo').valid && (filterForm.get('AssignedTo').touched || filterForm.get('AssignedTo').dirty)}">
              <span *ngIf="!filterForm.get('AssignedTo').valid && filterForm.get('AssignedTo').errors?.pattern && (filterForm.get('AssignedTo').touched || filterForm.get('AssignedTo').dirty)">
                <i class="material-icons">warning</i>Please enter a valid Respondent Name</span>
              <fdl-type-ahead [label]="content['formResponseSearchRespondentName']" formControlName="AssignedTo" [collection]="userSuggestion" optionField="name"
                              [placeholder]="content['placeholderSearchFormResponse']" [scrollable]="true" [optionsLimit]="500" [minLength]="0"  class="filter-typeahead">
              </fdl-type-ahead>
            </div>

            <div id="form-responses-package-filter" class="filter-form-control pr-3 typeahead-wrapper"
            [ngClass]="{'has-error': !filterForm.get('package').valid && (filterForm.get('package').touched || filterForm.get('package').dirty)}">
              <span *ngIf="!filterForm.get('package').valid && filterForm.get('package').errors?.pattern && (filterForm.get('package').touched || filterForm.get('package').dirty)">
                <i class="material-icons">warning</i>Please enter a valid Package</span>
              <fdl-type-ahead label="Package:" formControlName="package" [collection]="packageSuggestion" optionField="title"
                              [placeholder]="content['placeholderSearchFormResponse']" [scrollable]="true" [optionsLimit]="500" [minLength]="0"  class="filter-typeahead">
              </fdl-type-ahead>
            </div>

            <div class="filter-form-control pr-3">
              <label for="Status">Status:</label>
              <div class="fdl-select">
                <select name="Status" class="form-control" aria-invalid="false" formControlName="ResponseStatusTitle">
                  <option value="">All</option>
                  <option *ngFor="let status of formStatus" [value]="status.title">{{status.title}}</option>
                </select>
              </div>
            </div>
            <div id="form-responses-organization-filter" class="filter-form-control pr-3 typeahead-wrapper" *ngIf="isAdmin"
            [ngClass]="{'has-error': !filterForm.get('OrganizationTitle').valid && (filterForm.get('OrganizationTitle').touched || filterForm.get('OrganizationTitle').dirty)}">
              <span *ngIf="!filterForm.get('OrganizationTitle').valid && filterForm.get('OrganizationTitle').errors?.pattern && (filterForm.get('OrganizationTitle').touched || filterForm.get('OrganizationTitle').dirty)">
                <i class="material-icons">warning</i>Please enter a valid Organization Name</span>
              <fdl-type-ahead label="Organization Name:" formControlName="OrganizationTitle" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search"
                              [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onOrgSelect($event)"  class="filter-typeahead">
              </fdl-type-ahead>
            </div>
            <div class="search-action action-button ml-3" style="margin-top: 37px;">
              <button type="button" class="btn fdl-btn btn-primary-dark " aria-label="Search" (click)="filterList()">{{content['searchFormResponse']}}</button>
            </div>
            <div class="search-action ml-2" style="margin-top: 37px;" *ngIf="filtered">
              <button type="button" class="btn fdl-btn btn-primary-dark" aria-label="Reset" (click)="clearFilterList()">Reset</button>
            </div>
          </div>
        </form>

        <div class="data-table-container form-response-list">

          <div class="justify-content-between d-flex">
          <div class="headline-container">
            <h2 class="h4">{{content['headerFormResponse']}}</h2>
            <p *ngIf="pagerObject">Total {{pagerObject.totalCount}} {{content['headerFormResponse']}}</p>

          </div>
          <div class="d-flex">
            <div class="refresh-icon-link mt-1">
              <!-- add class of 'fa-rotation' on click of the refresh icon -->
              <a title="Refresh Form Response List" id="refreshTask">
                <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
              </a>
            </div>
            <div class="dropdown downloadDropdownMenuButton" *ngIf="showComment">
              <button *ngIf="showComment"
                      type="button" title="Download Extract" class="btn btn fdl-btn btn-primary-dark btn-addcomment"
                      id="downloadDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <span class="material-icons material-icons-wrapper">
                  get_app
                </span>
              </button>

              <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
                <a class="dropdown-item" (click)="downLoadType({'type':'xlsx'})">Export List</a>
              </div>

            </div>
            <app-prefernce-icon></app-prefernce-icon>
          </div>
        </div>
        <!--<div class="filter-control-wrapper">
          <div class="settings-wrapper">
            <div class="cta-view-all-action-btn-container">
              <a href="javascript:void(0);" data-toggle="modal" class="clickable" data-target="#columnSettings">
                Column Settings
              <span class="material-icons">navigate_next</span>
              </a>
            </div>
          </div>
        </div>-->
          <div class="table-responsive form-responces s-overflow-auto">

            <app-data-table *ngIf="responseList" [rows]="responseList.forms" [keys]="keys" [displayColumns]="displayColumns" (buttonClicked)="navigate($event)"
                            (sortingClicked)="applySorting($event)" [showEdit]="isAdmin" [showViewHistory]="isViewHistoryAllowed" [showDelegate]="true" [showChangeApprover]="isChangeApproverAllowed"
                            [showCheckbox]="false" [showViewTimeline]="isViewTimelineAllowed"
                            [showDelete]="isDeleteResponseAllowed" [showReinitiate]="isReinitiateAllowed" [showRequestExtract]="true" [showEditFormDetails]="isEditFormDetailsAllowed"
                            (deleteBtnClicked)="navigate($event)"
                            [showViewProjectTimeline]="showProjectFilter"
                            [listname]="listname"
                            [canDeleteEntity]="allowDelete" [customFormFieldListJson]="customFormFieldListJson"
            type="Response">

            </app-data-table>
            <div>
              <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && responseList && responseList.forms.length > 0" [pagerObject]="pagerObject"
                              (pagingChanged)="changePage($event)"></app-pagination>
            </div>
          </div>
        </div>
        <app-delegate type="viewFormResponses" (successDelegate)="successDelegateClick($event)" [delegateFormData]="responseData" [mode]="mode"></app-delegate>
        <app-update-approver type="viewFormResponses" (successUpdateApprover)="successUpdateApproverClick($event)" [updateApproverFormData]="responseData"></app-update-approver>
        <app-put-on-hold (successUpdateApprover)="successUpdateApproverClick($event)" [data]="responseData" #putOnHold></app-put-on-hold>

        <app-edit-form-details (successUpdate)="successUpdateApproverClick($event)" [data]="responseData" #editformdetails></app-edit-form-details>
      </section>
    </div>
  </div>

  <!-- <app-footer></app-footer> -->
</div>
<!-- start of column settings changes-->
