import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { REGEX_PATTERNS } from 'src/app/core/constants';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';

@Component({
  templateUrl: './set-organization.component.html'
})
export class SetOrganizationComponent implements OnInit {
  organizationSuggestions: ISuggestions;
  isError: boolean;
  errorMsg: string;
  _messageOrg: string;
  isOrganizationSet: boolean = false;
  invalidOrganization: boolean = false;
  organizationDetails = {
    organizationTitle: "",
    organizationId: -1
  };
  setOrganizationForm: FormGroup;
  isSubmitted = false;
  filterForm: FormGroup;

  constructor(private _projectService: ProjectsService,
    private _permissionService: PermissionsService,
    private _userService: UserService) { }

  ngOnInit(): void {

    this.organizationDetails.organizationTitle = this._permissionService.userData.organizationTitle;
    this.initForm();
    this._projectService.getSuggestions('organizations', '')
      .subscribe(suggestions => {
        this.organizationSuggestions = suggestions;
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  initForm() {
    this.setOrganizationForm = new FormGroup({
      organization: new FormControl((this.organizationDetails.organizationTitle || ''), [Validators.required, Validators.pattern(REGEX_PATTERNS.organization_name_validator), NoWhitespaceValidator.validateWhiteSpaces]),
    });
  }

  selectOrganization(event: any) {
    this.organizationDetails = {
      "organizationTitle": event?.item?.title,
      "organizationId": event?.item?.id
    }
    if(event.item.id > 0)
      this.invalidOrganization = false;
  }

  saveOrganization() {
    this.isSubmitted = true;
    if (!this.setOrganizationForm.get('organization').valid || this.setOrganizationForm.get('organization').errors?.pattern){
      return;
    }
    this.organizationDetails['organizationId'] = this._projectService.fetchIdForRequest(this.organizationSuggestions, this.organizationDetails.organizationTitle);
    if (this.organizationDetails['organizationId'] === -1) {
      this.invalidOrganization = true;
    }
    else {
      this.invalidOrganization = false;
      this.isOrganizationSet = this._userService.organizationDetails(this.organizationDetails);

      if (this.isOrganizationSet)
        this._messageOrg = `The organization has been set to <b>${this.organizationDetails.organizationTitle}</b>.`;
      else
        this._messageOrg = `Failed to set the organization to <b>${this.organizationDetails.organizationTitle}</b>.`;
    }
  }

}
