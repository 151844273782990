import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { ISuggestions } from 'src/app/shared/suggestion';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { HelpArticleService } from '../../helpArticle.service';
import { IHelpArticle, IHelpArticles } from '../../../../../shared/helpArticle';
import { UserService } from 'src/app/services/user.service';
import { ManageHelpArticleService } from '../services/manageHelp.service';
import { PreferenceService } from 'src/app/services/preference.service';
import { UserFilterService } from "src/app/services/user.filter.service";
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { IUser } from 'src/app/shared/user';
declare var $: any;

@Component({
  selector: 'app-helpArticle-list',
  templateUrl: './helpArticle-list.component.html',
})
export class HelpArticleListComponent implements OnInit {


  filterForm: FormGroup;
  helpArticleList: IHelpArticles;
  queryParam: IQuery;
  currentPageNumber = 1;
  pagerObject: IPager;
  sortingObject;
  isError: boolean=false;
  showComment: boolean = true;
  showDeleteButton: boolean = false;
  errorMsg: '';
  displayColumns = ['Article Name', 'Article Description', 'Resource Category', 'Entity Level', 'Entity Name', 'Status'];
  keys = ['title', 'description', 'helpCategoryName', 'entityLevel', 'entityName','isPublishable'];
  helpArticleSuggestion: ISuggestions;
  helpCategorySuggestion: ISuggestions;
  organizationSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  formSuggestion: ISuggestions;
  successMsg: string;
  isEdit: boolean = false;
  isAdmin: boolean = false;
  selectedOrgId: number = 0;
  selectedCategoryId: number = 0;
  organizationDetails: any;
  listname:string = PreferenceService.resourceArticleList;
  filtered: boolean = false
  filterExists: boolean = false;
  productText: string;
  regexValidationMessage: any;
  currentUser: IUser;

  constructor(private router: Router,
    private projectService: ProjectsService, private helpArticleService: HelpArticleService, private userService: UserService,
    private manageHelpService: ManageHelpArticleService,
    private userFilterService: UserFilterService,
    private route: ActivatedRoute)
    { }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.selectedCategoryId = 0;
    this.currentUser = this.userService.getUserListData();
    this.isAdmin = this.currentUser.isAdmin;
    this.isEdit = this.userService.getPermissions()["showhelpArticlelink"];
    if(!this.isAdmin) {
      this.organizationDetails = Object.assign({}, this.currentUser);
      this.selectedOrgId = this.organizationDetails.organization;
    }

    this.initFilterForm();
    this.initQueryParams();
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    //this.getHelpArticles();
    this.getOrgSuggestions();
    this.getCategorySuggestions();
    this.getTopicSuggestions();
    this.filterList();
    const filterValues = this.filterForm.value;
    //this.filtered = this.filterExists;
    if(filterValues['catogeryName'] !== '' ||
        filterValues['name'] !== '' ||
        filterValues['organizationName'] !== '' ||
        filterValues['entityNameProduct'] !== '' ||
        filterValues['entityLevel'] !== '' ||
        filterValues['entityNameForm'] !== '' ||
        !filterValues['publishstatus']
    ){
      this.filtered = true;
    }
    else {
      this.filtered = false;
    }

  }

  getOrgSuggestions() {
    this.projectService.getSuggestions('organizations', '')
      .subscribe(
        suggestions => {this.organizationSuggestion = suggestions;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.successMsg = this.helpArticleService._isHelpArticleCreated ? `The Resource Article '${this.helpArticleService._newCreatedHelpArticle}' has been created.`: '' ;
    }, 0)

    //this.successMsg = this.helpArticleService._isHelpArticleCreated ? `The Resource Article '${this.helpArticleService._newCreatedHelpArticle}' has been created.`: '' ;
  }

  ngOnDestroy(): void {
    this.helpArticleService._isHelpArticleCreated = false;
    this.helpArticleService._newCreatedHelpArticle = '';
    this.successMsg = "";
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      name: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)]),
      publishstatus: new FormControl('true'),
      catogeryName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)]),
      organizationName: new FormControl('', [Validators.pattern(REGEX_PATTERNS.organization_name_validator)]),
      entityLevel: new FormControl(""),
      entityNameProduct: new FormControl(""),
      entityNameForm: new FormControl("")
    });
  }

  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
    this.helpArticleService.exportHelpArticles(this.queryParam).subscribe(
      res => {
        console.log(res.body);
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'Resource Articles.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    );
  }

  getCategorySuggestions() {
    this.projectService.getSuggestions('helpCategories', this.selectedOrgId, "orgId")
      .subscribe(suggestions => {
        this.helpCategorySuggestion = suggestions;
        this.isError = false;
      },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  getTopicSuggestions() {
    this.projectService.getSuggestions('helpTopics', this.selectedOrgId, "orgId")
      .subscribe(suggestions => {
        this.helpArticleSuggestion = suggestions;
        this.isError = false;
      },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  getTopicSuggestionsbyOrgId() {
    this.projectService.getSuggestions('helpTopics', this.selectedOrgId, "orgId")
      .subscribe(suggestions => {
        this.helpArticleSuggestion = suggestions;
        this.isError = false;
      },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  getHelpArticles() {
    $('#refreshTask').addClass('fa-rotation');
    this.helpArticleService.getHelpArticles(this.queryParam)
      .pipe(take(1))
      .subscribe(
        projects => {
          this.isError = false;
          this.helpArticleList = projects.body as IHelpArticles;
          for (let item of this.helpArticleList.helpTopics) {
             // Bug 283250: Only super admin OR author can edit/remove articles
            if ((this.isAdmin) || (item.cretedById === this.currentUser.id)) {
              item.showDeleteButton = true;
            }
            else {
              item.showDeleteButton = false;
            }
          }
          if (projects.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(projects.headers.get('X-pagination')) as IPager);
          }
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  filterList() {
    this.userFilterService.saveFilter(this.route, this.filterForm);
    this.initQueryParams();
    const filterValues = this.filterForm.value;

    if (filterValues['organizationName'])
      this.queryParam.organizationTitle = filterValues['organizationName'];
    if (filterValues['name'])
      this.queryParam.title = filterValues['name'];
    if (filterValues['publishstatus'])
      this.queryParam.isPublishable = filterValues['publishstatus'];
    if (filterValues['catogeryName'])
      this.queryParam.HelpCategory = filterValues['catogeryName'];
    if (filterValues['entityLevel'] === "Product")
      this.queryParam.productTitle = filterValues['entityNameProduct'];
    if (filterValues['entityLevel'] === "Form")
      this.queryParam.formTitle = filterValues['entityNameForm'];

    this.setOrderBy(this.sortingObject);
    this.getHelpArticles();

    if (filterValues['organizationName'] === "") {
      this.selectedOrgId = 0;
      this.getProductSuggestions();
      this.getFormSuggestions();
    }
    if(this.filterForm.dirty){
      this.filtered = true;
    }
    else {
      this.filtered = false;
    }
    //this.filtered = true
  }

  clearFilterList(){
    this.filtered = false;
    //this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.filterList();
  }

  refresh() {
    this.getHelpArticles();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.getHelpArticles();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + ' desc';
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.getHelpArticles();
  }

  createHelpArticle() {
    this.manageHelpService.returnPage = '/self-service/helpTopic';
    this.router.navigate(['/self-service/helpTopic/create']);
  }

  navigate(event) {
    if (event.action === 'view') {
      this.router.navigate(['/self-service/helpTopic/view', event.data]);
    }
    else if (event.action === 'delete') {
      this.successMsg = '';
      const deleteHelpCategory = this.helpArticleList.helpTopics.filter(item => item.id === event.data)[0];
      this.helpArticleService.deleteHelpArticle(event.data).subscribe(
        () => {
          this.isError = false;
          this.getHelpArticles();
          this.getTopicSuggestions();
          this.successMsg = `The Resource '${deleteHelpCategory.title}' has been deleted.`;
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
    }
  }

  onOrgSelect(event: any) {
    if(event.item.id > 0)
    {
      this.selectedOrgId = event.item.id;
      this.filterForm.get('name').setValue("");
      this.filterForm.get('catogeryName').setValue("");
      this.filterForm.get('entityNameProduct').setValue("");
      this.filterForm.get('entityNameForm').setValue("");
      this.filterForm.get('entityLevel').setValue("");
      this.getCategorySuggestions();
      this.getTopicSuggestionsbyOrgId();
    }
  }

  onCategorySelect(event: any) {
    if(event.item.id > 0)
    {
      this.selectedCategoryId = event.item.id;
      this.getTopicSuggestions();
    }
  }

  onEntitySelect() {
    this.filterForm.get('entityNameProduct').setValue("");
    this.filterForm.get('entityNameForm').setValue("");
    let entityLevel = this.filterForm.value.entityLevel

    if(entityLevel === "Product") {
      this.getProductSuggestions();
    }
    else if(entityLevel === "Form") {
      this.getFormSuggestions();
    }
  }

  getFormSuggestions() {
    this.projectService.getSuggestions('forms', this.selectedOrgId, "orgId")
      .subscribe(
        suggestions => {this.formSuggestion = suggestions;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }

  getProductSuggestions(){
		let suggestionSubscriber: any;
		let params = [];
    let productText = this.filterForm.value.productText
		if (productText && productText.length > 2) {
			params.push({paramName: "title", value: productText});
			params.push({paramName: "orgId", value: this.selectedOrgId});
			suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
		}
		else {
			params.push({paramName: "orgId", value: this.selectedOrgId});
			suggestionSubscriber = this.projectService.getProductSuggestions("products", params, 100);
		}

		suggestionSubscriber.subscribe(
			data=> {
			this.productSuggestion = data;
			},
			error => { this.isError = true; this.errorMsg = error; }
		);
	}

	typeaheadLoadingProducts() {
		this.getProductSuggestions();
	}

}
