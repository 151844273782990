import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { take } from "rxjs/operators";
import { DashboardCommonLogic } from "../shared/dashboard-common-logic";
import { UserService } from "../../services/user.service";
import moment from 'moment';
import { IUser } from "../../shared/user";
import { environment } from "src/environments/environment";
import { IReport, IReports } from "src/app/shared/report";
import { IQuery } from "src/app/shared/query";
import { ReportsService } from "src/app/services/report.services";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { UserFilterService } from "src/app/services/user.filter.service";
import { ISuggestions } from "src/app/shared/suggestion";

@Component({
  selector: "app-dashboard-report",
  templateUrl: "./dashboard-report.component.html"
})
export class DashboardReportComponent extends DashboardCommonLogic
  implements OnInit {
    reportList: IReports;
    systemreportList: any;
    bureportList: any;
    selctedReportDetails: IReport;
    queryParam: IQuery;
    currentPageNumber: number = 1;
    today: string;
    userDetails: any;
    isError: boolean= false;
    errorMsg: string = '';
    LastLoginTimeStamp: any;   
    reportUrl: string = '';
    urlSafe: SafeResourceUrl;
    selectedReportId: number = 0;
    isValidURL: boolean= false;
    filterForm: FormGroup;
    filterExists: boolean = false;
    selectedValue: any;
    reportSuggestion: ISuggestions;
    isRespondent: boolean = false;
    canAccessReports: boolean = false;

    roleToAccessReports = [
      "Division Leader",
      "BU Leader", 
      "Delivery Owner", 
      "Delivery Leader", 
      "Relationship Manager", 
      "Sales Executive", 
      "Account Team",
      "CCT Admin", 
      "Restricted Admin"
    ];

    constructor(
    private r: Router,
    private userService: UserService,
    private reportService: ReportsService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private userFilterService: UserFilterService
  ) {
    super(r);
  }

  initQueryParams() {
    this.queryParam = Object.assign({},{
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }
  
  ngOnInit() {
    this.selectedValue = "";
    this.userDetails = this.userService.getUserListData();
    this.today = moment().format("dddd D MMMM YYYY HH:mm");
    this.userService.callUserListData().subscribe((data) => {  
      this.setUpUserAndPermissions(data);    
      this.getLoginTimestamp(data);
    });
    this.initFilterForm();
    this.initQueryParams();
    this.getReportList();  
    this.getSuggestions();
    // this.selectFilter();
  }

  private setUpUserAndPermissions(user: IUser): void {
    this.userService.setUserListData(user);
    this.userDetails = this.userService.getUserListData();
    if (!this.userDetails) {
      return;
    }
    
    let isNonrespondent = this.userDetails.permissionsForUI.some(group => group.groupName === "Projects");
    if ((!this.userDetails.isAdmin && !isNonrespondent) || (this.userDetails.type == "Client")) {
      this.isRespondent = true;
    }
    this.canAccessReports = !this.isRespondent || this.userDetails.roles.some(r => this.roleToAccessReports.indexOf(r.roleTitle) >= 0);
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      reportId: new FormControl('')
    });
  }
 
  selectFilter() {
    this.filterExists = this.userFilterService.loadAndApplyFilters(this.route, this.filterForm, this.queryParam);
    if (this.filterExists) {
      let asset: any = this.systemreportList?.filter(
        item => item.id == this.filterForm.value['reportId']
      )[0];
      if (!asset) {
        asset = this.bureportList?.filter(
          item => item.id == this.filterForm.value['reportId']
        )[0];
      }
      this.selectedValue = asset.id;
      let event = { "target": {"value" : this.filterForm.value['reportId'] }};
      this.onReportSelect(event);
    }
  }

  getSuggestions() {
    this.reportService.getSuggestions('reports','')
    .subscribe(suggestions => {
      this.reportSuggestion = suggestions;
    },
    (error)=> { this.isError = true; this.errorMsg = error; });
  }

  getReportList() {
    this.reportService.getReports(this.queryParam)
      .pipe(take(1))
      .subscribe(
        reports => {
          this.isError = false;
          this.reportList = reports.body as IReports;
          this.bureportList = [];
          this.systemreportList = [];
          for(let i = 0; i < this.reportList.reports.length ; i++) {
            if (this.reportList.reports[i].visibility == true) {
              if ((this.reportList.reports[i].organizationId != null && this.reportList.reports[i].organizationId != undefined ) ||
              (this.reportList.reports[i].productId != null && this.reportList.reports[i].productId != undefined ) ||
              (this.reportList.reports[i].formId != null && this.reportList.reports[i].formId != undefined ) ) {
                this.bureportList.push(this.reportList.reports[i]);
              } else {
                this.systemreportList.push(this.reportList.reports[i]);
              }
            }            
          }
          this.selectFilter();
          this.showDashboard();
        },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }

  getLoginTimestamp(user: IUser) {
    this.userService.getLoginData(user.userId).subscribe((data) => {
      this.LastLoginTimeStamp = moment.utc(data.body).local().format("dddd D MMMM YYYY HH:mm");
    },
      (error) => console.log("Error"));
  }

  onReportSelect(event) {
    this.selectedReportId = event.target.value;
    this.filterForm.controls['reportId'].setValue(event.target.value);
    this.userFilterService.saveFilter(this.route, this.filterForm);
    this.reportService.getReportDataById(this.selectedReportId)
    .pipe(take(1))
      .subscribe(
        report => {
          this.isError = false;
          this.selctedReportDetails = report;
          this.isValidURL = this.isvalidURL ( this.selctedReportDetails.reportURL);
          if (this.isValidURL) {
            this.reportUrl = this.selctedReportDetails.reportURL;
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.reportUrl);
          } else {
            this.reportUrl = '';
            this.urlSafe = '';
          }
        },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }
  

  openSite() {
    window.open(this.reportUrl, '_blank');
  }

  isvalidURL(str) {

    let isValid = true;
   // isValid = !!REGEX_PATTERNS.url.test(str);
   //'https://app.powerbi.com/reportEmbed?reportId=4cd1cc53-c030-40c2-813c-daf3a9932716&autoAuth=true&ctid=11873a1f-4c8d-450d-8dfb-e37a2e2557f8&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZC1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9'
    if(isValid) {
      const url = new URL(str);
      if (url.host != 'app.powerbi.com') {
        isValid = false;
      }
    }  
    return isValid;
  }

  createReport(){
    this.r.navigate(['self-service/report/create']);
  }

  showDashboard() {
    this.route.params
    .pipe(take(1))
    .subscribe((params) => {
      if(params['id']) {
        let paramReportId = params['id'];

        let asset: any = this.systemreportList?.filter(
          item => item.id == paramReportId
        )[0];
        if (!asset) {
          asset = this.bureportList?.filter(
            item => item.id == paramReportId
          )[0];
        }
        this.selectedValue = asset.id;
        let event = { "target": {"value" : paramReportId }};

        this.onReportSelect(event)
      }
    });
  }
}
