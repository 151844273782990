import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions.service';
import { take } from 'rxjs/operators';
import { ClientsService } from 'src/app/services/clients.service';
import { IClient, IClientAddresses } from 'src/app/shared/client';
import { ClientsDTOService } from 'src/app/shared/clientsDTO.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-view-client',
  templateUrl: './view-client.component.html',
  styles: [
  ]
})
export class ViewClientComponent implements OnInit, OnDestroy {
  

  constructor(private router: Router, private permission: PermissionsService,
    private route: ActivatedRoute, private clientServcie: ClientsService,
    private clientDto: ClientsDTOService, private masterService: MasterService) { }
  
  // showEditButton:boolean = false;
  isAdmin:boolean = false;
  clientId: number;
  clientData: IClient;
  clientAddress: IClientAddresses;
  showUpdationMessage:boolean = false;
  isError: boolean;
  errorMsg: string;

  clientContactHeaders: any = ["Name", "Email", "Phone"];
  clientContactKeys: any = ["name", "email", "phone"];

  ngOnInit(): void {
    // this.showEditButton = this.permission.isEditClientAllowed();
    this.isAdmin = this.permission.userData.isAdmin;
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.clientId = params['id'];
        if (this.clientId > -1) {
          this.getClientData();
          this.getClientAddress();
        }
      });
      this.showUpdationMessage = this.clientDto._isClientUpdated;
  }

  getClientData() {
    this.clientServcie.getClientDataById(this.clientId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.clientData = data;
        },
        (error)=> { this.isError = true; this.errorMsg = error; });
  }

  getClientAddress(){
    this.clientServcie.getClientAddress(this.clientId)
    .pipe(take(1))
    .subscribe(
      data =>{
        let addresses = data ? data.filter(clientAddr => clientAddr.addressType.toLowerCase() === "primary"): [];
        if(addresses.length > 0) this.clientAddress = addresses[0];
      },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }
  
  goToEdit(){
    this.clientDto._isEditMode = true;
    this.clientDto.setClientData(this.clientData);
    this.clientDto.setClientAddress(this.clientAddress);
    this.router.navigate(['admin/client/edit']);
  }

  ngOnDestroy(): void {
    this.clientDto._isClientUpdated = false;
  }

  get _ClientAddress() {
    let address = 'N/A';
    if (this.clientAddress) {
      let addressObject = this.clientAddress;
      address = addressObject.streetAddress1;
      if(addressObject.streetAddress2){
        address += ', ' + addressObject.streetAddress2;
      }
      address += ', ' + addressObject.city + ', ' + addressObject.state;
      if(addressObject.zipCode){
        address += ', ' + addressObject.zipCode;
      }
      if(addressObject.country){
        address += ', ' + addressObject.country;
      }
    }
    return address;
  }

  goBack(){
    this.masterService.isCancelledClicked(true);
    this.router.navigate(['admin/client']);
  }
}
