<div class="heading-container fdl-2" >
    <div class="back-action-container">
        <div class="back-button">
            <a href="javascript:void(0);" (click)="goBack()">
                <span class="material-icons"> arrow_back </span> 
                {{returnPage}}
            </a>
        </div>
    <h1 *ngIf="requestType == ''">{{bulkRequestType}} Bulk Upload</h1>
    <h1 *ngIf="requestType">Pre-Populate {{requestType}}</h1>
    </div>
</div>

<div class="form-container">
    <!-- Error Msg Alert -->
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button> 
        <i class="material-icons">warning</i>
        <p [innerHtml]="errorMsg"></p>
    </div>
    <!-- Success Msg Alert -->
    <div class="alert alert-success alert-dismissible" *ngIf="isSuccess">
        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isSuccess=false">×</button>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p [innerHtml]="successMsg"></p>
    </div>

    <form class="form form-border"  [formGroup]="fileTemplateForm" autocomplete="off">
        <h3 class="h5 heading-level-3 mt-0">Upload Excel File</h3>
        <div class="form-row"
            [ngClass]="{'has-error': (!fileTemplateForm.get('fileName').valid || fileTemplateForm.controls.fileName.errors?.required || !validFile) && (fileTemplateForm.controls.fileName.touched || submitted)}">
            <div class="col-md-6 mb-3">
                <div class="custom-file">
                    <label for="fileName" class="custom-file-label text-truncate pr-80" data-toggle="tooltip" data-placement="top" [title]="selectedFileName"> {{selectedFileName}}</label>
                    <input id="fileName" type="file" class="custom-file-input form-control" multiple="false" formControlname="fileName" (change)="onFileChange($event)" #inputFileControl>
                </div>
                <div class="has-error">
                    <span class="has-error" *ngIf="(!validFileName) && (fileTemplateForm.controls.fileName.touched || submitted)">
                        <i class="material-icons">warning</i> {{regexValidationMessage.FILENAME_VALIDATION_MESSAGE}}
                    </span>
                    <span class="has-error" *ngIf="(!validFile) && (fileTemplateForm.controls.fileName.touched || submitted)">
                        <i class="material-icons">warning</i> Please select valid Excel file.
                    </span>
                    <span class="has-error" *ngIf="(!validFileType) && (fileTemplateForm.controls.fileName.touched || submitted)">
                        <i class="material-icons">warning</i> Please select valid file type.
                    </span>
                </div>
            </div> 
        </div>
        <div class="d-inline">
            <p><a href="javascript:void(0);" (click)="downloadTemplate()">Click here</a> to download Bulk Upload template.</p>
        </div>
        <div class="fdl-2 button-container">
            <button type="button" aria-label="Cancel" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
            <button type="submit" aria-label="Default" class="btn fdl-btn btn-primary-orange float-right" (click)="Submit()">Submit</button>
        </div>
    </form>
</div>