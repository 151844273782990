import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { ISuggestion } from 'src/app/shared/suggestion';
import { ClientCommitmentDTO } from '../models/client-commitment-d-t-o';

@Component({
  selector: 'app-commitment-copy',
  templateUrl: './commitment-copy.component.html',
  styleUrls: ['./commitment-copy.component.css']
})
export class CommitmentCopyComponent implements OnInit, OnChanges {
  @Input() selectedForCopy: ClientCommitmentDTO;
  clientId: number | null = null;
  responseId = 0;
  title: string = '';
  description: string = '';

  @Output()
  private submit = new EventEmitter<void>();

  public clients: ISuggestion[] = [];

  constructor(private clientCommitmentsService: ClientCommitmentsService) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedForCopy && changes.selectedForCopy.currentValue) {
      const response =  changes.selectedForCopy.currentValue;
      this.responseId = response.id;
      const answerJson = JSON.parse(response.answerJSON);
      this.title = answerJson.commitmentTitleIntake;
      this.description = answerJson.commitmentDescriptionIntake;
    }
  }

  ngOnInit(): void {
    this.loadClients();
  }

  private loadClients(): void {
    this.clientCommitmentsService.getClientsForCopy().subscribe(data => {
      this.clients = data.body as unknown as ISuggestion[];
    },
      () => { });
  }

  public onSubmit(): void {
    this.clientCommitmentsService.copy(this.responseId, this.clientId ?? 0).subscribe(() => {
      this.submit.emit();
    });
  }
}
