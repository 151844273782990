<div class="heading-container fdl-2">
  <h1>Report</h1>
</div>

<div class="form-container separate-form-container">
<div style="text-align:right;padding-bottom:10px">
  <button type="button" class="btn fdl-btn btn-primary-dark ml-3"  
  (click)="openSite()">Open page in new window</button>

</div>
<iframe height="750px" width="100%" [src]="urlSafe" frameborder="0" allowFullScreen="true"></iframe>
<!--<iframe [src]="reportUrl"  height="500px" width="100%" target="_self"></iframe>-->
<!--<iframe src="https://app.powerbi.com/reportEmbed?reportId=51e7e9cd-634b-4f8c-b8b6-6c1b2be9e04a&appId=4514194c-b944-43de-a134-0e1c0f70c1d8&autoAuth=true&ctid=11873a1f-4c8d-450d-8dfb-e37a2e2557f8&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZC1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" height="500px" width="100%"  target="_self"></iframe>-->
</div>

