import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ProjectsService } from "src/app/services/projects.service";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IQuery } from "src/app/shared/query";
import { IPager } from "src/app/shared/pagination";
import { MasterService } from "src/app/services/master.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormsService } from "src/app/services/forms.service";
import { PermissionsService } from "src/app/services/permissions.service";
import { ManageFormsService } from "src/app/admin/manageForms/services/manageForms.service";
import { IHistorySummary, IHistorySummarys } from "src/app/shared/history-summary";
import { Location } from "@angular/common";
import { Sort } from "src/app/shared/sort";

declare var $: any;

@Component({
  selector: "app-forms-history",
  templateUrl: "./forms-history.component.html"
})
export class FormsHistoryComponent implements OnInit, OnDestroy {
  isError: boolean;
  filterForm: FormGroup;
  formHistoryList: IHistorySummary[];
  filteredFormHistoryList: IHistorySummary[];
  sortedFormHistoryList: IHistorySummary[];
  queryParam: IQuery;
  pagerObject: IPager;
  isAdmin = false;
  displayColumns = ["Version", "Modified Date", "Modified By"];
  keys = ["version", "modifiedOn", "modifyBy"];
  currentPageNumber: number = 1;
  formTemplateName: string = "";

  sortingObject;
  errorMsg: string = "";
  approvalMsg: string = "";
  formId: any;
  restoreData_id: number;
  restoreData_on: string;
  version: string;

  filterFormsVersions: FormGroup;

//   isFilterRequired: boolean = false;

  constructor(
    private projectService: ProjectsService,
    private masterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private formsService: FormsService,
    private permissionService: PermissionsService,
    private _manageForm: ManageFormsService,
    private location: Location
  ) {}

  ngOnInit(): void {
    const loc = this.location.path();
    this.formId = loc.substring(loc.lastIndexOf("/") + 1);
    this.formTemplateName = this._manageForm.historyName;
    this.isAdmin = this.permissionService.userData.isAdmin;
    if (this.isAdmin || this.permissionService.isPublishCountViewAllowed()) {
      this.displayColumns.push("# of Times Published");
      this.keys.push("publishCount");
    }
    this.initFilterFormsVersions();
    this.masterService.loadAndApplyFiltersDup(
      this.filterFormsVersions,
      "FormsHistory"
    );
    this.callFormHistoryListService();
  }

  initFilterFormsVersions() {
    this.filterFormsVersions = new FormGroup({
      fromDate: new FormControl(""),
      toDate: new FormControl("")
    });
  }

  callFormHistoryListService() {
    this.formsService
      .getFormsHistory(this.formId)
      .pipe(take(1))
      .subscribe(
        responses => {
          this.isError = false;
          this.formHistoryList = responses;
          this.filteredFormHistoryList = JSON.parse(
            JSON.stringify(this.formHistoryList)
          );
          this.sortedFormHistoryList = JSON.parse(JSON.stringify(this.formHistoryList));
          this.pagerObject = {
            totalCount: this.filteredFormHistoryList.length,
            pageSize: environment.pageSize,
            currentPage: this.currentPageNumber,
            totalPages: Math.ceil(this.filteredFormHistoryList.length / environment.pageSize)
          };
          this.filteredFormHistoryList = this.filteredFormHistoryList.slice(0, this.pagerObject.pageSize);
          this.masterService.resetFiltersCancelledFlag();
          const isFilterRequired = Object.keys(this.filterFormsVersions.value).every(filter => this.filterFormsVersions.value[filter] === "");
          if(!isFilterRequired) {
              this.filterFormsHistory();
          }
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
  }

  navigate(event) {
    if (event.action === "view") {
      this.masterService.saveFiltersDup(
        this.filterFormsVersions,
        "formsHistory"
      );
      this.formsService.version = event.data.version;
      this.formsService.versionModifiedOn = event.data.modifiedOn;
      this.router.navigate(['/admin/form/history/view', event.data.id]);
    }

    if (event.action === "revert") {
      this.restoreData_id = event.data.id;
      this.version = event.data.version;
      this.formsService.versionModifiedOn = event.data.modifiedOn;
       $('#restoreForm').modal('show');
    }

    else if (event.action === "viewCompare") {
      this.masterService.saveFiltersDup(
        this.filterFormsVersions,
        "formsHistory"
      );
      this.formsService.version = event.data.version;
      this.formsService.versionModifiedOn = event.data.modifiedOn;
      this.router.navigate(['/admin/form/compare', event.data.id]);
    }
}

  applySorting(event) {
    this.sortingObject = event;
    const sort = new Sort();
    const order = this.sortingObject.isAsc ? "asc": "desc";
    this.sortedFormHistoryList.sort(sort.startSort(this.sortingObject.key, order, this.sortingObject.key))
    this.filterFormsHistory();
  }

  changePage(page) {
    this.pagerObject.currentPage = page;
    this.filterFormsHistory();
  }

  filterFormsHistory() {
    let filterValues = this.filterFormsVersions.value;
    this.isError = false;
    this.errorMsg = '';
    let fromDate = new Date (filterValues["fromDate"]);
    let toDate = new Date (filterValues["toDate"]);
    if (fromDate.getTime() > toDate.getTime()) 
    {
      this.errorMsg = "'To Date' which is in past of 'From date' not allowed.";
      this.isError = true;
      return;
    }

    this.filteredFormHistoryList = JSON.parse(JSON.stringify(this.sortedFormHistoryList));
    if (filterValues["fromDate"]) {
      this.filteredFormHistoryList = this.filteredFormHistoryList.filter(
        response =>
          new Date(response.modifiedOn).getTime() >
          new Date(filterValues["fromDate"]).getTime()
      );
    }

    if (filterValues["toDate"]) {
      let toDate = new Date(new Date(filterValues["toDate"]).getTime() + 24 * 60 * 60 * 999.99);
      this.filteredFormHistoryList = this.filteredFormHistoryList.filter(
        response =>
          new Date(response.modifiedOn).getTime() <
          new Date(toDate).getTime()
      );
    }
    this.pagerObject.totalPages = Math.ceil(this.filteredFormHistoryList.length / environment.pageSize);
    this.pagerObject.totalCount = this.filteredFormHistoryList.length;
    this.pagerObject = JSON.parse(JSON.stringify(this.pagerObject));
    const startIdx = this.pagerObject.pageSize * (this.pagerObject.currentPage - 1);
    const endIdx = (this.pagerObject.pageSize * this.pagerObject.currentPage);
    this.filteredFormHistoryList = this.filteredFormHistoryList.slice(startIdx, endIdx);
    
  }

  goBack() {
    this.masterService.isCancelledClicked(true);
    this.router.navigate(["../../../list"], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this._manageForm.setDelegateSuccessMsg("");
    this._manageForm.approvalMsg = "";
  }

  restoreOk(){
    this.formsService
        .updateFormsHistoryVersion(this.restoreData_id, this.version, this.formsService.versionModifiedOn)
        .pipe(take(1))
        .subscribe(
          responses => {
            this.isError = false;
            this.approvalMsg = "Form has been reverted to previous version.";
            this.masterService.resetFiltersCancelledFlag();
            this.callFormHistoryListService();
          },
          error => {
            this.isError = true;
            this.errorMsg = error;
          }
        );
  }
}
