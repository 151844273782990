<div class="heading-container fdl-2">
    <h1>Set Organization</h1>
</div>

<div class="form-container separate-form-container">
    <div class="alert alert-success alert-dismissible" *ngIf="isOrganizationSet">
        <button type="button" class="close" data-dismiss="alert" aria-label="close"
            (click)="isOrganizationSet=false">×</button>
        <span class="iconLabel" aria-label="sucess"></span>
        <i class="material-icons" aria-hidden="true">check_circle</i>
        <p [innerHtml]="_messageOrg">
        </p>
    </div>

    <p>Please select to set new Organization.</p>
    <div class="pt-2">
        <form [formGroup]="setOrganizationForm">
            <div class="form-row">
                <div class="col-md-6 mb-3 typeahead-wrapper" [ngClass]="{'has-error': invalidOrganization || (!setOrganizationForm.get('organization').valid && (setOrganizationForm.get('organization').touched || isSubmitted))}">
                    <label for="organization">Organization:</label>
                    <fdl-type-ahead formControlName="organization" [(ngModel)]="organizationDetails.organizationTitle" (onSelect)="selectOrganization($event)" [collection]="organizationSuggestions" 
                        optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
                    <div class="has-error">
                        <span *ngIf="invalidOrganization || 
                        (!setOrganizationForm.get('organization').valid && setOrganizationForm.get('organization').errors?.pattern && setOrganizationForm.get('organization').dirty) ">
                            <i class="material-icons">warning</i>Please enter a valid Organization Name</span>
                    </div>
                    <div class="has-error">
                        <span *ngIf="((setOrganizationForm.get('organization').errors?.required || setOrganizationForm.get('organization').errors?.validateWhiteSpaces) && (setOrganizationForm.get('organization').touched || isSubmitted))">
                        <i class="material-icons">warning</i> Please enter an Organization Name</span>
                    </div>
                </div>
            </div>
        </form>

        <div class="fdl-2 button-container justify-content-end">
            <button type="submit" class="btn fdl-btn btn-primary-orange " (click)="saveOrganization()">Save</button>
        </div>
    </div>
</div>