import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { WorkflowService } from 'src/app/services/workflow.service';
import { MasterService } from 'src/app/services/master.service';
import { FeaturesService } from 'src/app/services/feature.service';
import { IFeature, ISubFeature } from 'src/app/shared/selfservice-feature';
import { IWorkflowContext } from 'src/app/shared/workflow';
import {Location} from '@angular/common';

@Component({
  selector: 'subfeature-view',
  templateUrl: './subfeature-view.component.html'
})
export class SubfeatureViewComponent implements OnInit {
  
  featureId: number;
  subFeatureId: number;
  featureData: IFeature;
  subFeatureData: ISubFeature;
  isError: boolean;
  errorMsg: string;

  constructor(private route: ActivatedRoute,
    private masterService: MasterService,
    private featureService: FeaturesService,
    private location: Location) { }

  ngOnInit(): void { 
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.featureId = params['id1'];
        this.subFeatureId = params['id2'];
        console.log('feature id', this.featureId)
        console.log('subfeature id', this.subFeatureId)
        if (this.featureId > -1) {
          this.getSubFeatureData();
        }
      });
  }

  getSubFeatureData() {
    this.featureService.getFeaturesDetailsById(this.featureId)
    .pipe(take(1))
    .subscribe(
        data => {
            this.featureData = data;
            console.log('feature data', this.featureData)
            this.subFeatureData = data.features.find(el => el.id == this.subFeatureId);
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  goBack(){
    this.masterService.isCancelledClicked(true);
    this.location.back()
  }
}
