import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-survey-respond',
  templateUrl: './survey-respond.component.html'
})
export class SurveyRespondComponent implements OnInit,OnDestroy {

  constructor() { }
  ngOnDestroy(): void {
    sessionStorage.removeItem('surveyToken');
  }

  ngOnInit(): void {    
  }
}
