<!-- Add class of "back-action-container" when having back button in place like below -->
<div class="heading-container fdl-2" >
  <div class="back-action-container">
  <div class="back-button">
    <a href="javascript:void(0);" (click)="goBack()">
      <span class="material-icons">
        arrow_back
        </span>
      {{returnPage}}
    </a>
  </div>
  <h1>Add New Report Configuration</h1>
</div>

</div>

<div class="form-container  separate-form-container">
  <div class="alert alert-dismissible alert-danger" *ngIf="isError">
    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
    <i class="material-icons">warning</i>
    <p [innerHtml]="errorMsg"></p>
  </div>

  <app-report-form [isEditMode]="false" [entities]="entities"></app-report-form>

</div>
