import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ISuggestions } from 'src/app/shared/suggestion';
import { WorkflowService } from 'src/app/services/workflow.service';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { IWorkflow, IWorkflowContext, IWorkflowContextList } from 'src/app/shared/workflow';
import { MasterService } from 'src/app/services/master.service';
import { EntityLevel, WorkflowContextEntityLevel } from 'src/app/core/constants';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { EmailTemplateService } from '../../manageEmailTemplates/services/email-template.service';

@Component({
  selector: 'create-workflow-context',
  templateUrl: './create-workflow-context.component.html',
  styleUrls: ['./create-workflow-context.component.css']
})
export class CreateWorkflowContextComponent implements OnInit {

  orgSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  formSuggestion: ISuggestions;
  pageTemplateSuggestion: ISuggestions;
  workflowContext: FormGroup;
  workflowList: IWorkflowContextList;
  workflowPostData: any;
  entityLevel: any;
  organizationDetails: any;
  selectedEntityLevelValue: any;
  invalidProductName: boolean;
  invalidFormName: boolean;
  invalidOrgName: boolean;
  invalidPageName: boolean;
  selectedOrgId: number;
  workflowId: number;
  workflowData: IWorkflow;

  isAdmin: boolean;
  isSubmitted: boolean;
	isError: boolean;
  isSuccess: boolean;
	errorMsg: string;
  successMsg: string;
  _maxLength: any;

  queryParam: IQuery;
  currentPageNumber: number;
  pagerObject:IPager;
  sortingObject;

  productId: any;
  formId: any;
  orgId: any;
  pageTemplateId: any;

  displayColumns = ['Workflow Name', 'Entity Level', 'Entity Name', 'IsActive', 'Created On', 'Created By'];
  keys = ['workflowName', 'entityLevel', 'entityName', 'isActive', 'createdOn', 'createdByName']; 
  
  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectsService,
    private userService: UserService,
    private router: Router,
    private masterService: MasterService,
    private fb: FormBuilder,
    private workflowService: WorkflowService,
    private emailTemplateService: EmailTemplateService
  ) {};

  ngOnInit(): void {
    this.initProperties();
    this.getOrganizationSuggestions();
    this.getProductSuggestions();
    this.getFormSuggestions();
    this.getFormSuggestions();
    this.getPageTemplateSuggestions();
    this.initFilterForm();
    this.initQueryParams();
    this.getWorkflowData();
    this.callWorkflowListService();
  };

  initProperties() {
    this.entityLevel = Object.values(WorkflowContextEntityLevel).filter(value => typeof value !== 'number');
    this.organizationDetails = Object.assign({}, this.userService.getUserListData());
    this.isAdmin = this.userService.getUserListData().isAdmin;
    this.selectedEntityLevelValue = "";
    this.selectedOrgId = this.organizationDetails.organization;
    this.isSubmitted = false;
    this.isError = false;
    this.isSuccess = false;
    this.invalidProductName = false;
    this.invalidFormName = false;
    this.invalidOrgName= false;
    this.invalidPageName= false;
    this.errorMsg = "";
    this.successMsg = "";
    this.productId = "";
    this.formId = "";
    this.orgId = "";
    this.pageTemplateId = "";
    this.workflowPostData = "";
    this.currentPageNumber = 1;
  }

  initQueryParams() {
    this.queryParam = Object.assign({},{
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.workflowContext = this.fb.group({
      entityNameOrg: [''],
      entityLevel: ['', [Validators.required]],
      entityNameProduct: [''],
      entityNameForm: [''],
      entityNamePage: ['']
    });
	}

  getWorkflowData(){
    this.route.params
    .pipe(take(1))
    .subscribe
    (
      (params) => {
        this.workflowId = params['id'];
        if (this.workflowId > -1) {
            this.workflowService.getWorkflowById(this.workflowId)
              .pipe(take(1))
              .subscribe(
                data => {this.workflowData = data;}
              );
        }
      }
    );
  }

  getOrganizationSuggestions() {
    this.projectService.getSuggestions("organizations", "")
      .subscribe(
        suggestions => {this.orgSuggestion = suggestions;
        },
        (error) => { this.isError = true; this.errorMsg = error; }
      );
  }

  getProductSuggestions() {
    this.projectService.getSuggestions('products', this.selectedOrgId, "orgId")
      .subscribe(
        suggestions => {this.productSuggestion = suggestions;
        },
        (error) => { this.isError = true; this.errorMsg = error; }
      );
  }

  getFormSuggestions() {
    this.projectService.getSuggestions('forms', this.selectedOrgId, "orgId")
      .subscribe(
        suggestions => {this.formSuggestion = suggestions;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }

  getPageTemplateSuggestions() {
    this.projectService.getSuggestions('pagetemplate', "")
      .subscribe(
        suggestions => {this.pageTemplateSuggestion = suggestions;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }

  callWorkflowListService() {
    this.workflowService.getWorkflowContextList (this.workflowId.toString(), this.queryParam)
      .pipe(take(1))
      .subscribe(
        workflows => {
          this.isError = false;
          this.workflowList = workflows.body as IWorkflowContextList;
          if(workflows.headers.get('X-pagination')){
            this.pagerObject = Object.assign({},JSON.parse(workflows.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
        },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }

  navigate(event) {
    let Id = event.data;
    switch (event.action) {
      case "view":
        this.router.navigate(['/self-service/workflowContext', event.data,'view']);
        break;
      case "delete":
        this.deleteWorkflowContext(Id);
				break;
      default:
        break;
    }
  }

  setOrderBy(sortingData){
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  deleteWorkflowContext(id: number) {
		this.isSuccess = false;
		this.successMsg = "";
		this.workflowService.deleteWorkflowContext(id).subscribe(
      () => {
        this.callWorkflowListService();
				this.isSuccess = true;
				this.successMsg = `Workflow Context Mapping is deleted successfully.`;
			},
			error => {
				this.isError = true;
				this.errorMsg = error;
			}
		);
	}

  applySorting(event){
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callWorkflowListService();
  }

  changePage(page){
    this.queryParam.pageNumber = page;
    this.callWorkflowListService();
  }

  goBack() {
    this.router.navigate(['/self-service/workflow']);
  }

  Cancel() {
    this.workflowContext.reset();
    this.workflowContext.get('entityLevel').setValue("");
  }

 Submit() {
		this.isSubmitted = true;

    if (this.isAdmin) {
      if (this.orgId != "" && this.productId == "" && this.formId == "" && this.pageTemplateId == "") { this.createPostData(this.orgId, "org") }
      else if (this.selectedEntityLevelValue.trim().length === 0) { this.invalidOrgName = true; return; }
    }

    if (!this.isAdmin && this.selectedEntityLevelValue.trim().length === 0) { return; }

    // if (this.workflowContext.valid) {
    if (this.selectedEntityLevelValue.trim().length > 0) {
      if (this.selectedEntityLevelValue == 'Product') {
        let productName = this.workflowContext.value.entityNameProduct;
        this.productId = this.projectService.fetchIdForRequest(this.productSuggestion, productName);
        if (this.productId < 1) { this.invalidProductName = true; return;  }
        else { this.createPostData(this.productId, "product") }
      }

      if (this.selectedEntityLevelValue == 'Form') {
        let formName = this.workflowContext.value.entityNameForm;
        this.formId = this.emailTemplateService.fetchIdForRequestByTitle(this.formSuggestion, formName);
        if (this.formId < 1) { this.invalidFormName = true; return; }
        else { this.createPostData(this.formId, "form") }
      }

      if (this.selectedEntityLevelValue == 'PageTemplate') {
        let pageName = this.workflowContext.value.entityNamePage;
        this.pageTemplateId = this.projectService.fetchIdForRequest(this.pageTemplateSuggestion, pageName);
        if (this.pageTemplateId < 1) { this.invalidPageName = true; return; }
        else { this.createPostData(this.pageTemplateId, "page") }
      }
    }

      // if (this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue == 'Organization') {
      //   let organizationName = this.workflowContext.value.entityNameOrg;
      //   this.orgId = this.emailTemplateService.fetchIdForRequestByTitle(this.orgSuggestion, organizationName);
      //   if (this.orgId < 1) { this.invalidOrgName = true; return; }
      //   else { this.createPostData(this.orgId, "org") }
      // }

    if (this.workflowPostData != "") {
      this.workflowService.createWorkflowContext(this.workflowPostData)
      .subscribe(
				() => {
          this.Cancel();
          this.initProperties();
          this.getOrganizationSuggestions();
          this.getProductSuggestions();
          this.getFormSuggestions();
          this.onCreateComplete();
          this.callWorkflowListService();
				},
				error => {
					this.isError = true;
					this.errorMsg = error;
				}
      );
    }
    // else {
    //   this.isSuccess = false;
    //   this.isError = true;
    //   this.errorMsg= "Please fix below errors";
    // }
  }

  private createPostData(id: number, type: string): void {
    this.workflowPostData = "";
    this.workflowPostData = {
      "WorkflowId": this.workflowId,
      "OrganizationId": (type === "org")? id: null,
      "ProductId": (type === "product")? id: null,
      "ClientId": (type === "client")? id: null,
      "FormId": (type === "form")? id: null,
      "PageTemplateId": (type === "page")? id: null,
      "IsActive": true
    };
  }

  private onCreateComplete(): void {
    this.isSuccess = true;
    this.successMsg = `Workflow Context mapping is created successfully.`;
  }

  onEntitySelect(){
    this.invalidProductName = false;
    this.invalidFormName = false;
    this.invalidPageName = false;
    this.workflowContext.get('entityNameProduct').setValue("");
    this.workflowContext.get('entityNameForm').setValue("");
    this.workflowContext.get('entityNamePage').setValue("");
    // this.invalidOrgName = false;
    // this.orgId = "";
    this.productId = "";
    this.formId = "";
    this.pageTemplateId = "";
  }

  onEntityProductSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidProductName = false;
    }
  }

  onEntityFormSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidFormName = false;
    }
  }

  onEntityPageSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidPageName = false;
    }
  }

  onEntityOrgSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidOrgName = false;
      this.orgId = event.item.id;
      this.selectedOrgId = event.item.id;
      this.getProductSuggestions();
      this.getFormSuggestions();
      this.workflowContext.get('entityLevel').setValue("");
      this.workflowContext.get('entityNameProduct').setValue("");
      this.workflowContext.get('entityNameForm').setValue("");
      this.workflowContext.get('entityNamePage').setValue("");
      this.onEntitySelect();
    }
  }
}