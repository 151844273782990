import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-referesh',
  templateUrl: './referesh.component.html',
  styleUrls: ['./referesh.component.css']
})
export class RefereshComponent implements OnInit {
  pageTemplateId: any;
  isPageTemplate: any;

  constructor(private router: Router,
    private route: ActivatedRoute, private location: Location) { }

  ngOnInit(): void {
    this.pageTemplateId = this.route.snapshot.params["id"];
    this.isPageTemplate = this.route.snapshot.params["pageTemplate"];

    if (this.isPageTemplate !='pageTemplateR') {
      this.location.back();
    }
    else {
      let url: string = '/self-service/pageTemplate/' + this.pageTemplateId + "/responses";
      this.router.navigate([url]);
    }
  }
}