import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { CustomComponentsService } from 'src/app/services/custom-components.service';
import * as $ from 'jquery';
declare var $: any;
@Component({
  selector: 'app-sensitive-information',
  templateUrl: './sensitive-information.component.html',
  styleUrls: ['./sensitive-information.component.css']
})
export class SensitiveInformationComponent implements OnInit {
  value2: any;
  isEditableValue: boolean = false;
  isReadOnly: boolean = false;
  @Input() value: string
  @Input() id: string
  @Input() type: string
  @Input() data: any
  @Input() validate: any
  @Input() input: boolean
  @Input() key: string
  @Input() label: string
  @Output() valueChange= new EventEmitter<string>()
  @Input() values: {}
  @Input() disabled: boolean
  @Input() hidden: boolean
  @Input() buttonStyle: string
  @Input() typeOfSensitiveInformation: any;
  @Input() inline: boolean;
  mask: any;
  arrayMap: any = [];
  apiCalled: boolean;
  apiCalledDone: any;
  errorMessage: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && changes.disabled.currentValue == "disabled") {
      this.isReadOnly = true;
    }
    if (changes.typeOfSensitiveInformation && changes.typeOfSensitiveInformation.currentValue == "disabled") {
      this.changeFn("");
    }
  }

  constructor(private commonService: CustomComponentsService) { }

  ngOnInit(): void {
    this.value2 = '';
    this.data = this.commonService.getData();
  }

  showGroup() {
    return !this.hidden ;
  }

  isActive(val: string) {
    return this.value && (val === this.value)
  }

  maskValue(p) {
    let str: any = this.value = p;
    const first = str.substring(0, 2);
    const last = str.substring(str.length - 4);
    const mask = "****";
    this.mask = mask + last;
    this.value2 = this.value;
  }

  blur(p) {
    this.maskValue(p)
    this.isEditableValue = false;
  }

  changeFn(payload) {
    this.value = payload
    this.arrayMap['SSN'] = { "key": "SSN_Internal", "name": "Social Security Numbers" };
    this.arrayMap['CARD'] = { "key": "Card_Internal", "name": "Credit Card Numbers" };
    this.arrayMap['UsTaxId'] = { "key": "SSN_Internal", "name": "US Tax Id" };
    this.arrayMap['NonUsTaxId'] = { "key": "GenericText_Internal", "name": "Non-US Tax Id" };
    this.arrayMap['PHI'] = { "key": "GenericText_Internal", "name": "Personal Health Information" };
    this.arrayMap['FBAN'] = { "key": "GenericText_Internal", "name": "Financial/Banking Account Number" };
    this.arrayMap['DL'] = { "key": "GenericText_Internal", "name": "Driver's Licenses" };
    this.arrayMap['NID'] = { "key": "GenericText_Internal", "name": "National IDs" };
    this.arrayMap['PASSPORT'] = { "key": "GenericText_Internal", "name": "Passport Number" };
    this.arrayMap['OTHER'] = { "key": "GenericText_Internal", "name": "OTHER" };
    let element = this.arrayMap[this.typeOfSensitiveInformation];
    let selectedResponseDataElement = element.name;
    let cryptIdName = element.key;
    let responseId = this.commonService.getResponseId();
    this.commonService.saveData$.next({ 'key': this.key, "responseId": responseId, 'cryptIdName': cryptIdName, 'selectedResponseDataElement': selectedResponseDataElement,"value":payload });
    this.valueChange.emit(payload);
  }

  toggleEdit() {
    this.errorMessage = '';
    this.apiCalled = true;
    const fieldData = this.commonService.getSensitiveData()
    if (this.value != null && this.value != '' && !this.apiCalledDone && (fieldData && fieldData[this.key] && fieldData[this.key].id != 0)) {
      const responseId = this.commonService.getResponseId();
      let body = { "id": fieldData[this.key].id , "responseId": responseId, "key": this.key };
      const respondentEmailAddress = this.commonService.getRespondentEmailAddress();
      if (respondentEmailAddress) {
        body["emailAddress"] = respondentEmailAddress;
      }
      this.commonService.getFieldData(body).subscribe(data => {
        if (data.value) {
          this.apiCalledDone = true;
          this.value2 = data.value
          this.value = this.value2;
          this.isEditableValue = !this.isEditableValue;
          $('#' + this.id).focus();
          this.valueChange.emit(this.value);
        } else {
          this.errorMessage = "Sorry, We are facing issue in fetching the value. Please try again after some time."
        }
      }, error => { })
    } else {
      this.isEditableValue = !this.isEditableValue;
      this.valueChange.emit(this.value);
    }
  }

  isEditable() {
  
    this.value2 = this.value;
    let hasError = true;
    if ($('#' + this.id).hasClass("is-invalid")) {
      hasError = true;
    } else {
      hasError = false;
    }
    if (this.value && this.value != '')
      this.maskValue(this.value)
    return this.isEditableValue || !this.value || (this.value && this.value == '' || hasError)
  }

  userNamengmodelchange(value) {
    console.log(this.value2);
    this.value = value.target.value
    this.valueChange.emit(value.target.value);
    console.log(value);
  }
}
