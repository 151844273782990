import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultipleExecutionGuard implements CanActivate, CanActivateChild {
  constructor(private injector: Injector) {

  }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let guards = next.data.guards || [];
    for (let guard of guards) {
      let instance: CanActivate = this.injector.get(guard);
      let result = await instance.canActivate(next, state);
      if (result instanceof Observable) {
        result = await result.toPromise();
      }

      if (result === false || result instanceof UrlTree) {
        return result;
      }
    }

    return true;
  }
  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let guards = childRoute.data.guards || [];
    for (let guard of guards) {
      let instance: CanActivateChild = this.injector.get(guard);
      let result = await instance.canActivateChild(childRoute, state);
      if (result === false || result instanceof UrlTree) {
        return result;
      }
    }

    return true;
  }
}
