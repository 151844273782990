import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

import { INoticeBoard } from "../shared/noticeboard";

@Injectable({
    providedIn: 'root'
})
export class NoticeBoardService {

  private noticeAPIURL = 'configurations/getnoticeboardconfig';


    constructor(private http: HttpClient) { }

    getNoticeboardData(): Observable<INoticeBoard> {
        return this.http.get<INoticeBoard>(this.noticeAPIURL);
    }


}

