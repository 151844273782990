import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { PermissionsService } from '../services/permissions.service';
import { map } from 'rxjs/operators';
import { allowedPermissions, PERMISSIONS } from '../core/constants';
import { IUser } from '../shared/user';
import { MasterService } from '../services/master.service';

@Injectable({
  providedIn: 'root'
})
export class PublishFormsAccessGuard implements CanActivate {
  constructor(private userService: UserService, private permissionService: PermissionsService, private masterService: MasterService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.userService.getUserListData();
    let status_id = this.masterService.getResponseStatusList();
    if (status_id.length < 1) {
      this.masterService
        .callMasterListData()
        .subscribe(data => this.masterService.setMasterListData(data));


    }
    if (!user) {

      return this.userService.callUserListData().pipe(map((data) => {
        this.userService.setUserListData(data);
        this.permissionService.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm]
          }
        });

        this.userService.setPermissions(usersPermission);
        return this.canPublishForms(data);

      }));
    }
    else {
      return of(this.canPublishForms(user));
    }
  }

  private canPublishForms(user: IUser): boolean {
    return user.isAdmin
      || user.permissions.includes(PERMISSIONS.Responses_Edit_Manager)
      || user.permissions.includes(PERMISSIONS.Responses_ReadWrite_All)
      || user.permissions.includes(PERMISSIONS.Responses_ReadWrite_Manager)
  }

}
