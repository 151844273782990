import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { ISuggestions } from 'src/app/shared/suggestion';
import { Router } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { HelpCategoryService } from '../../helpCategory.service';
import { IhelpCategories, IHelpCategory } from 'src/app/shared/helpCategory';
import { UserService } from 'src/app/services/user.service';
import { HelpService } from 'src/app/services/help.service';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';

declare var $: any;

@Component({
  selector: 'app-helpCategory-list',
  templateUrl: './helpCategory-list.component.html',
})
export class HelpCategoryListComponent implements OnInit {


  filterForm: FormGroup;
  helpCategoryList: IhelpCategories;
  queryParam: IQuery;
  currentPageNumber = 1;
  pagerObject: IPager;
  sortingObject;
  isError: boolean=false;
  showComment: boolean = true;
  showDeleteButton: boolean = false;
  errorMsg: string = '';
  displayColumns = ['Category Name', 'Category Description'];
  keys = ['name', 'description'];
  helpCategorySuggestion: ISuggestions;
  successMsg: string;
  isEdit: boolean = false;
  isAdmin: boolean = false;
  filtered: boolean = false;
  regexValidationMessage: any;

  constructor(private router: Router,
    private projectService: ProjectsService, private helpCategoryService: HelpCategoryService, private userService: UserService, private helpService: HelpService)
    { }

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.isAdmin = this.userService.getUserListData().isAdmin;
    this.isEdit = this.userService.getPermissions()["showhelpArticlelink"];
    this.initFilterForm();
    this.initQueryParams();
    this.getHelpCategories();
    this.getSuggestions();
  }

  ngAfterViewInit(): void {
    this.successMsg = this.helpCategoryService._isHelpCategoryCreated ? `The Resource Category '${this.helpCategoryService._newCreatedHelpCategory}' has been created.`: '' ;
  }

  ngOnDestroy(): void {
    this.helpCategoryService._isHelpCategoryCreated = false;
    this.helpCategoryService._newCreatedHelpCategory = '';
    this.successMsg = "";
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      name: new FormControl('', [Validators.pattern(REGEX_PATTERNS.name_title_validator)])
    });
  }


  downLoadType(event: any) {
    if (event.type == 'xlsx') {
      this.exportExcel();
    }
  }

  exportExcel() {
   /*  this.helpCategoryService.exportHelpCategories(this.queryParam).subscribe(
      res => {
        console.log(res.body);
        let newBlob = new Blob([res.body], { type: "application/vnd.ms-excel" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          const data = window.URL.createObjectURL(newBlob);
          const filename = 'Organizations.xlsx';
          const link = document.createElement('a');
          link.href = data;
          link.download = filename;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      error => { this.isError = true; this.errorMsg = error; }
    ); */
  }

  getSuggestions() {
    this.projectService.getSuggestions('helpCategories', '')
      .subscribe(suggestions => {
        this.helpCategorySuggestion = suggestions;
        this.isError = false;
      },
      (error) => { this.isError = true; this.errorMsg = error; });
  }

  refresh() {
    this.getHelpCategories();
  }

  getHelpCategories() {
    $('#refreshTask').addClass('fa-rotation');
    this.helpCategoryService.getHelpCategories(this.queryParam)
      .pipe(take(1))
      .subscribe(
        projects => {
          this.isError = false;
          this.helpCategoryList = projects.body as IhelpCategories;
          for (let item of this.helpCategoryList.helpCategories) {
            if (this.isAdmin || (!this.isAdmin && this.isEdit && !item.isCommonCategory)) {
              item.showDeleteButton = true;
            }
            else
              item.showDeleteButton = false;
          }
          if (projects.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(projects.headers.get('X-pagination')) as IPager);
          }
          $('#refreshTask').removeClass('fa-rotation');
        },
        (error) => { this.isError = true; this.errorMsg = error; });
  }

  filterList() {
    if(this.filterForm.valid) {
      this.initQueryParams();
      const filterValues = this.filterForm.value;

      if (filterValues.name) {
        this.queryParam.name = filterValues['name'];
      }

      this.setOrderBy(this.sortingObject);
      this.getHelpCategories();

      this.filtered = true
    }
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.getHelpCategories();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.getHelpCategories();
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + ' desc';
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.getHelpCategories();
  }

  createHelpCategory() {
    this.helpCategoryService.tempData = "Resource Category";
    this.router.navigate(['/self-service/helpCategory/create']);
  }
  createHelpArticle() {
    this.router.navigate(['self-service/helpTopic/create']);
  }
  navigate(event) {
    if (event.action === 'view') {
      this.router.navigate(['/self-service/helpCategory/view', event.data]);
    }
    else if (event.action === 'delete') {

      this.successMsg = '';
      const deleteHelpCategory = this.helpCategoryList.helpCategories.filter(item => item.id === event.data)[0];
      this.helpService.getTopicByCatId(event.data, this.queryParam).subscribe(
        result => {

          var count = result.helpTopics.length;
          if(count > 0)
          {

            this.isError = true;
            this.errorMsg = `The Resource '${deleteHelpCategory.name}' can not be deleted as it is already in use.`;
          }
          else
          {
this.helpCategoryService.deleteHelpCategory(event.data).subscribe(
        () => {
          this.isError = false;
          this.getHelpCategories();
          this.getSuggestions();
          this.successMsg = `The Resource '${deleteHelpCategory.name}' has been deleted.`;
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );

          }
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );


    }
  }

}
