<div class="heading-container fdl-2">
    <article class="left-container">
        <h1>Project Implementations Console - Dashboard</h1>
        <p>
           <span>Last refreshed on: {{ refreshedDate.toLocaleString() }}</span>
        </p>
    </article>
    <button type="button" class="btn fdl-btn btn-primary-orange">
        <span class="material-icons">add</span>
        Add New Implementation
    </button>
</div>
<div class="main-container-sub responsive-container col-settings-drawer-alignment">
    <div class="mb-3 box project-workflow dashboard-header">
        <div class="header-wrapper">
            <h5>Implementations Summary</h5>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
        </div>
        <app-toggle-button 
            class="mt-2" 
            (onChange)="toggleView($event)"
            [selectedIcon]="'apps'"
            [deselectedIcon]="'menu'"
            [selected]="true"></app-toggle-button>
    </div>
    <div class="form-container">
        <div class="row">
            <section class="col-lg-12 p-0">
                <form class="form-border mb-3 box collapse-view" autocomplete="off">
                    <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search
                    </h2>
                    <div class="filter-form-container collapse" id="collapseFilter">
                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <fdl-type-ahead placeholder="Implementation Name" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
                        </div>
                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <fdl-type-ahead placeholder="Business Unit" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
                        </div>
                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <fdl-type-ahead placeholder="Product" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
                        </div>
                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <fdl-type-ahead placeholder="Task" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
                        </div>
                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <fdl-type-ahead placeholder="Implementation Owner" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
                        </div>
                        <div class="filter-form-control pr-4 typeahead-wrapper">
                            <fdl-type-ahead placeholder="Status" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
                        </div>
                        <div>
                          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search">Search</button>
                        </div>
                    </div>
                </form>
                <div class="data-table-container">
                    <div class="justify-content-between d-flex">
                        <div class="headline-container">
                            <h2 class="h4">Implementation List</h2>
                            <p>Total {{ pagerObject.totalCount }} implementations</p>
                        </div>
                    </div>
                    <div class="table-responsive form-responses s-overflow-auto">
                        <div>
                            <app-data-table
                                *ngIf="implementations"
                                [rows]="implementations[currentPage - 1]"
                                [showActionButton]="true"
                                [displayColumns]="displayColumns"
                                [keys]="keys"
                                [showCheckbox]="false"
                                [showViewEdit]="true"
                                [showCopy]="true"
                                [showMoveToPhase]="true"
                                [showDelete]="true"
                                [canDeleteEntity]="canDelete"
                                type="Project Implementation"></app-data-table>
                        </div>
                        <div *ngIf="pagerObject">
                          <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && implementations && implementations.length > 0" [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>