import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-unmap-fulfillment-modal',
  templateUrl: './unmap-fulfillment-modal.component.html',
  styleUrls: ['./unmap-fulfillment-modal.component.css']
})
export class UnmapFulfillmentModalComponent {

  @Input()
  public responseId = 0;

  @Input()
  public projectId = '';

  @Input()
  public projectName = '';

  @Output()
  private unmap = new EventEmitter<void>();

  constructor(
    private clientCommitmentsService: ClientCommitmentsService) { }

  public onUnmapClicked(): void {
    this.clientCommitmentsService.unmapPageTemplateResponse(
      environment.clientCommitmentPageTemplateId,
      this.responseId,
      this.projectId).subscribe(() => {
        this.unmap.emit();
      });
  }
}
