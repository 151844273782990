<div *ngIf="isInitiateDGF" class="heading-container">
    <h1>Initiate Data Gathering</h1>
</div>

<div
    class="publish-forms-container main-container-sub responsive-container pt-0    embedded-publish-forms-container pt-3 separate-form-container pb-2">
    <section class="right-section assign-container col-12 pl-2 pr-2" style="max-width: 100%;">
        <form class="form-container">
            <div class="page-heading-container mb-3">
                <div>
                    <h2 class="h4">Assign and Launch Data Gathering Form</h2>
                    <p>Please select data gathering form and respondent to initiate data gathering.</p>
                </div>
            </div>

            <div class="alert alert-dismissible alert-danger" *ngIf="isError">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="isError=false">×</button>
                <i class="material-icons">warning</i>
                <p [innerHtml]="errorMsg"></p>
            </div>

            <div class="alert alert-success alert-dismissible" *ngIf="showSuccessMsg">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="showSuccessMsg=false">×</button>
                <span class="iconLabel" aria-label="sucess"></span>
                <i class="material-icons" aria-hidden="true">check_circle</i>
                <p>{{successMsg}}

                </p>
            </div>

            <form class="row mt-3" [formGroup]="publishForm" autocomplete="off">
                <!--  <div class="col-md-4" *ngIf="isInitiateDGF && isProjectDisabled">
                    <label for="projectName">Select Project:</label>
                    <input type="text" class="form-control" disabled="" formControlName="projectName"
                        id="projectName">
                </div>
            <div class="col-md-4 typeahead-wrapper" *ngIf="isInitiateDGF && !isProjectDisabled">                
                <label for="projectName">Select Project:</label>
                <fdl-type-ahead formControlName="projectName" 
                    optionField="title" placeholder="Type to search" (onSelect)="selectProject($event)" [collection]="projectSuggestion" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>    
            </div>

            <div *ngIf="isInitiateDGF" class="col-xs-1 pt-4 strong"><div class="pt-1">OR</div></div>
        -->
                <div class="col-md-4" *ngIf="isInitiateDGF && isClientDisabled">
                    <label for="clientName">Select Client Name/DUNS:</label>
                    <input type="text" class="form-control" disabled="" formControlName="clientName" id="clientName">
                </div>
                <div class="col-md-4 typeahead-wrapper" *ngIf="isInitiateDGF && !isClientDisabled">
                    <label for="clientName">Select Client Name/DUNS:</label>
                    <fdl-type-ahead [(ngModel)]="clientText" formControlName="clientName" optionField="titleWithDuns"
                        placeholder="Type to search" (onSelect)="selectClient($event)" [collection]="clientSuggestion"
                        [scrollable]="true" [optionsLimit]="100" [minLength]="0"
                        (input)="typeaheadLoadingClients()"></fdl-type-ahead>
                    <div class="has-error">
                        <span
                            *ngIf="invalidClientName || (!publishForm.get('clientName').valid && publishForm.get('clientName').errors?.pattern && (publishForm.get('clientName').dirty))">
                            <i class="material-icons">warning</i> Please enter a valid Client Name </span>
                    </div>
                </div>

                <div class="col-md-2 pl-0 pt-4 align-self-center"
                    *ngIf="isInitiateDGF && !isClientDisabled && createClientAllowed">
                    <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Add New Client"
                        (click)="openClientAddDrawer()"><i class="material-icons f-30">add_circle_outline</i></a>
                </div>

            </form>

            <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
            <app-anonymous-survey *ngIf="formsetDetails" #surveyComponent></app-anonymous-survey>

        </form>
    </section>


    <!-- Drawer Container -->
    <div *ngIf="client_loadDrawer">
        <app-project-drawer-container componentName="client" state="CLIENT"></app-project-drawer-container>
    </div>

    <!-- Drawer Container -->
    <div *ngIf="loadDrawer">

        <app-project-drawer-container *ngIf="!drawerType" componentName="user"
            state="RESPONDENT"></app-project-drawer-container>
        <app-project-drawer-container *ngIf="drawerType" componentName="user"
            state="RESPONDENT_FOR_EDIT_PROJECT_DGF_CREATE_NEW_USER"></app-project-drawer-container>
    </div>

    <!-- <app-footer></app-footer> -->
</div>