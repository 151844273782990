import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ISuggestions } from 'src/app/shared/suggestion';
import { WorkflowService } from 'src/app/services/workflow.service';
import { IQuery } from 'src/app/shared/query';
import { IPager } from 'src/app/shared/pagination';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { IWorkflow, IWorkflowList } from 'src/app/shared/workflow';
import { MasterService } from 'src/app/services/master.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { PreferenceService } from 'src/app/services/preference.service';
declare var $: any;

@Component({
  selector: 'workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.css']
})
export class  WorkflowComponent implements OnInit {

  constructor(
    private router: Router,
    private projectService: ProjectsService,
    private workflowService: WorkflowService,
    private masterService: MasterService,
    private userService: UserService,
    private preferenceService: PreferenceService
  )  {};

  filterForm: FormGroup;
  workflowList: IWorkflowList;

	isError: boolean;
	errorMsg: string;
  successMsg: string;

  queryParam: IQuery;
  currentPageNumber: number;
  pagerObject:IPager;
  sortingObject;
  workflowSuggestion: ISuggestions;
  listname: any;

  displayColumns = ['Name', 'Description', 'FlowId', 'Environment', 'Is System Workflow', 'Created On', 'Created By'];
  keys = ['name', 'description', 'flowId', 'environment', 'isSystemWorkflow', 'createdOn', 'createdByName'];

  isAdmin: boolean;
  filtered: boolean = false

  ngOnInit(): void {
    this.initProperties();
    this.initFilterForm();
    this.initQueryParams();
    this.masterService.loadAndApplyFilters(this.queryParam, this.filterForm);
    this.listname = PreferenceService.workflowList;
    this.getWorkflowSuggestions();
    this.callWorkflowListService();
  };

  refresh() {
    this.callWorkflowListService();
  }

  initProperties() {
    this.isError = false;
    this.errorMsg = "";
    this.successMsg = this.workflowService.isWorkflowCreated;
    this.currentPageNumber = 1;
    this.isAdmin = this.userService.getUserListData().isAdmin;
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      WorkflowName: new FormControl(''),
      FlowId: new FormControl('')
    });
  }

  initQueryParams() {
    this.queryParam = Object.assign({},{
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize
    });
  }

  filterList(){
    this.initQueryParams();
    let filterValues = this.filterForm.value;
    if (filterValues['WorkflowName'])
      this.queryParam.jobTitle = filterValues['WorkflowName']
    if (filterValues['FlowId'])
      this.queryParam.id = filterValues['FlowId']

    this.setOrderBy(this.sortingObject);
    this.callWorkflowListService();
    this.filtered = true
  }

  clearFilterList(){
    this.filtered = false
    this.initQueryParams()
    this.initFilterForm();
    this.callWorkflowListService();
  }

  callWorkflowListService() {
    $('#refreshTask').addClass('fa-rotation');
    this.workflowService.getWorkflowList (this.queryParam)
      .pipe(take(1))
      .subscribe(
        workflows => {
          this.isError = false;
          this.workflowList = workflows.body as IWorkflowList;
          if(workflows.headers.get('X-pagination')){
            this.pagerObject = Object.assign({},JSON.parse(workflows.headers.get('X-pagination')) as IPager);
          }
          this.masterService.resetFiltersCancelledFlag();
          $('#refreshTask').removeClass('fa-rotation');
        },
      (error)=> { this.isError = true; this.errorMsg = error; });
  }

  navigate(event) {
    let Id = event.data;
		let workflow: IWorkflow = this.workflowList.workflows.filter(
			item => item.id == Id
		)[0];
    switch (event.action) {
      case "view":
        this.router.navigate(['/self-service/workflow', event.data,'createWorkflowContext']);
        break;
      case "delete":
        this.deleteWorkflow(Id, workflow.name);
				break;
      default:
        break;
    }
  }

  setOrderBy(sortingData){
    if(sortingData){
      this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
    }
  }

  deleteWorkflow(id: number, title: string) {
		this.successMsg = "";
		this.workflowService.deleteWorkflow(id).subscribe(
      () => {
        this.callWorkflowListService();
				this.successMsg = `Workflow - '${title}' - is deleted successfully.`;
			},
			error => {
				this.isError = true;
				this.errorMsg = error;
			}
		);
	}

  applySorting(event){
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.callWorkflowListService();
  }

  changePage(page){
    this.queryParam.pageNumber = page;
    this.callWorkflowListService();
  }

  createWorkflow() {
    this.router.navigate(['/self-service/workflow/create']);
  }

  getWorkflowSuggestions() {
    this.projectService.getSuggestions('workflow', '')
    .subscribe(suggestions => {
      this.workflowSuggestion = suggestions;
    },
      error => { this.isError = true; this.errorMsg = error; });
  }

  setSuccessMsg() {
    this.workflowService.isWorkflowCreated = "";
    this.successMsg = "";
  }
}
