<div>
    <div class="heading-container">
        <h1>Confirm your Identity</h1>
    </div>


    <div class=" main-container-sub pt-3 separate-form-container m-0 pb-2">
        <div class="row" >
            <div class="right-section assign-container col-12 pl-2 " style="max-width: 100%;flex: 0 0 100%;">
                <form class="form-container">
                    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
                        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
                        <i class="material-icons">warning</i>
                        <p [innerHtml]="errorMsg"></p>
                    </div>

                    <div class="alert alert-success alert-dismissible" *ngIf="isSuccess">
                        <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isSuccess=false">×</button>
                        <i class="material-icons" aria-hidden="true">check_circle</i>
                        <p [innerHtml]="successMsg"></p>
                    </div>


                    <p class="otp-underlined otp-generate mb-3 strong">
                        A message with verification code has been sent to your email. Enter the code to verify your identity.
                    </p>
                    <div class="clearfix"></div>
                    <div class="row otp-input" [formGroup]="otpForm">
                        <div class="col-md-4 form-group" [ngClass]="{'has-error': (!otpForm.get('otp').valid && (otpForm.get('otp').touched || isSaved)) || invalidOtpMessage}">
                            <label for="otp">One-time Password</label>
                            <input type="text" id="otp" formControlName="otp" placeholder=" " class="form-control">
                         
                            <span *ngIf="!otpForm.get('otp').valid && (otpForm.get('otp').errors?.required || otpForm.get('otp').errors?.validateWhiteSpaces) && (otpForm.get('otp').touched || isSaved)">
                            <i class="material-icons">warning</i> Please enter OTP.</span>

                            <span *ngIf="invalidOtpMessage">
                                <i class="material-icons">warning</i>{{invalidOtpMessage}}</span>
                        </div>
                    </div>
                    <p class="refresh-text strong mt-2 mb-1">
                        Need a new verification code?
                    </p>

                    <div class="refresh-click">
                        <div class="refresh-icon text-black-50 mr-1"><i class="material-icons"></i></div>

                        <a href="javascript:void(0)" (click)="resendOtp()" >Resend Code</a>
                    </div>

                    <div class="fdl-2 button-container justify-content-end">
                        <button class="btn fdl-btn btn-primary-orange" (click)="validateOtp()">Next</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
