import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { IEmailTemplate, IEmailTemplateDetails, IEmailTemplateVariable, IEmailTemplateDetailsList } from '../models/email-template';
import { ISuggestions, ISuggestion } from 'src/app/shared/suggestion';
import { debug } from 'console';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {
    private EmailTemplateAPIURL = 'emailtemplates';
    private exportEmailTemplateAPIURL = 'emailtemplates/ExportEmailTemplates';
    private _tempData: string = "";
    private emailnotification = 'emailnotification/send';
    private createMultipleTemplate = 'emailtemplates/CreateMultipleTemplate';
    private sendEmailnotification = 'emailnotification/sendEmail';
    constructor(private http: HttpClient) {
    }

    get tempData() {
        return this._tempData;
    }

    set tempData(msg: any) {
        this._tempData = msg;
    }

    getEmailTemplates(queryParams){
        let params = new HttpParams();
        let httpOptions = {
          headers: new HttpHeaders({
            refresh: "true"
          })
        };

        for (let key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }
        return this.http.get(this.EmailTemplateAPIURL, { headers: httpOptions.headers, params: params, observe: 'response' });
    }

    exportEmailTemplates(queryParams) {
      let params = new HttpParams();
      let httpOptions = {
        headers: new HttpHeaders({
          refresh: "true"
        })
      };
      for (let key of Object.keys(queryParams)) {
        let value = queryParams[key];
        params = params.append(key, value);
      }
      return this.http.get(this.exportEmailTemplateAPIURL, { headers: httpOptions.headers, params: params, observe: 'response', responseType: 'blob' });
    }


    getEmailTemplateDetailsById(emailTemplateId: number) {
        return this.http.get<IEmailTemplateDetails>(this.EmailTemplateAPIURL + "/" + emailTemplateId);
    }

    createEmailTemplate(data){
        return this.http.post<IEmailTemplate>(this.EmailTemplateAPIURL, data);
    }

    updateEmailTemplate(emailTemplateId, data){
        return this.http.put<IEmailTemplate>(`${this.EmailTemplateAPIURL}/${emailTemplateId}`, data);
    }

    deleteEmailTemplate(emailTemplateId: number): Observable<IEmailTemplate> {
        return this.http.delete<IEmailTemplate>(`${this.EmailTemplateAPIURL}\\${emailTemplateId}`);
    }

    getEventNameSuggestions(entity, titleValue, paramName = 'title') {
        const suggestionAPIURL = `${entity}/suggestions?${paramName}=${titleValue}`;
        return this.http.get<ISuggestions>(suggestionAPIURL);
    }

    getTemplateNameSuggestions(entity, titleValue, paramName = 'title') {
        const suggestionAPIURL = `${entity}/suggestions?${paramName}=${titleValue}`;
        return this.http.get<ISuggestions>(suggestionAPIURL);
    }

    getUserFields(){
        return this.http.get<ISuggestion[]>(this.EmailTemplateAPIURL + "/" + 'userfields');
    }

    fetchIdForRequest(suggestions: any, selectedVal: string): number {
        let suggestionArr = [];
        suggestionArr = suggestions;
        let foundObj = suggestionArr.find(suggestion => suggestion.displayName ? (suggestion.displayName === selectedVal) : (suggestion.emailEvent === selectedVal));
        return foundObj? foundObj.id : -1;
    }

    fetchIdForRequestByTitle(suggestions: any, selectedVal: string): number {
        let suggestionArr = [];
        suggestionArr = suggestions;
        let foundObj = suggestionArr.find(suggestion => suggestion.title? (suggestion.title === selectedVal): (suggestion.title === selectedVal));
        return foundObj? foundObj.id : -1;
    }
    getSystemVariables(){
        return this.http.get<IEmailTemplateVariable[]>(this.EmailTemplateAPIURL + "/" + 'variables');
    }

    sendEmail(data){
        return this.http.put<any>(this.sendEmailnotification, data);
    }

    createMultipleEmailTemplates(data){
        return this.http.post<IEmailTemplateDetailsList>(this.createMultipleTemplate, data);
    }
}