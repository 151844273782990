import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { HelpCategoryService } from 'src/app/self-service/components/manageHelpCategory/helpCategory.service';
import { IHelpCategory } from 'src/app/shared/helpCategory';
import { FeaturesService } from 'src/app/services/feature.service';
import { IFeature, ISubFeature } from 'src/app/shared/selfservice-feature';
import { UserService } from 'src/app/services/user.service';
import { IQuery } from 'src/app/shared/query';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-view-feature',
  templateUrl: './feature-view.component.html'
})
export class ViewFeatureComponent implements OnInit {

    constructor(private router: Router,
        private route: ActivatedRoute,
        private helpCategoryService: HelpCategoryService,
        private featureService: FeaturesService,
        private userService: UserService,
        private masterService: MasterService) { }

    helpCategoryId: number;
    helpCategoryData: IHelpCategory;

    featureData: IFeature;
    subFeatureData: ISubFeature[];
    featureId: number;
    sortingObject;

    showUpdationMessage = false;
    isError: boolean;
    errorMsg: string;
    isAdmin: boolean;

    displayColumns = ['Name', 'Subfeature URL'];
    keys = ['name', 'selfServiceSubFeatureURL'];
    deletedFormMsg: string = "";

    queryParam: IQuery;

    ngOnInit(): void {
        this.isAdmin = this.userService.getUserListData().isAdmin;
        this.initQueryParams()
        this.route.params
        .pipe(take(1))
        .subscribe((params) => {
            this.featureId = params['id'];
            if (this.featureId > -1) {
                this.getFeatureData();
            }
        });

        this.showUpdationMessage = this.featureService.isFeatureUpdated;
    }

    ngOnDestroy(): void {
        this.featureService.isFeatureUpdated = false;
    }

    initQueryParams() {
      this.queryParam = {};
    }

    getFeatureData() {
        this.featureService.getFeaturesDetailsById(this.featureId)
        .pipe(take(1))
        .subscribe(
            data => {
                this.featureData = data;
            },
            (error) => { this.isError = true; this.errorMsg = error; });

        this.featureService.getSubFeaturesWithParams(this.featureId, this.queryParam)
        .pipe(take(1), map(subfeatures => {
          let subs = subfeatures.body as ISubFeature[];
          return subs.map(subfeature => ({
              ...subfeature, name: subfeature.name ? subfeature.name : (subfeature.isPageTemplate) ? subfeature.pageTemplateTitle : `No name available in subfeature: ${subfeature.id}`,
              selfServiceSubFeatureURL: subfeature.selfServiceSubFeatureURL ? subfeature.selfServiceSubFeatureURL : (subfeature.isPageTemplate) ? `/self-service/pageTemplate/${subfeature.pageTemplateId}/responses` :`No URL available in subfeature: ${subfeature.id}`
          }));
        }))
        .subscribe(
            data => {
                this.subFeatureData = data;
            },
            (error) => { this.isError = true; this.errorMsg = error; });
    }

    goBack() {
      this.masterService.isCancelledClicked(true);
      this.router.navigate(['admin/feature/list']);
    }

    goToEdit() {
        this.router.navigate([`admin/feature/edit/${this.featureId}`]);
    }

    navigate(event){
        if (event.action === "view") {
          this.router.navigate(['/admin/feature/view', this.featureId, event.data]);
        }
        else if (event.action === "delete") {
          this.deletedFormMsg = '';
          let deleteReport = this.subFeatureData.filter(item => item.id === event.data)[0];
          this.featureService.deleteSubFeature(deleteReport.id).subscribe(
            () => {
              this.isError = false;
              this.getFeatureData();
              this.deletedFormMsg = `The report '${deleteReport.name}' has been deleted.`
            },
            error => {
              this.isError = true;
              this.errorMsg = error;
            }
          )
        }
    }

      refresh(){
        this.getFeatureData();
      }

    setOrderBy(sortingData){
      if(sortingData){
        this.queryParam.orderBy = (sortingData.isAsc)? sortingData.key: sortingData.key + " desc";
      }
    }
  
    applySorting(event){
      this.sortingObject = event;
      this.setOrderBy(this.sortingObject);
      this.refresh();
    }
}
