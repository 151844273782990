<section class="status-bar col-md-12">
  <div class="section-container ">
    <div class="left-section">
      <h1 class="h1">
        Dashboard
      </h1>
      <!-- Popover -->
      <div class="filter-container">
        <a id="cartPopover" class="btn cart-popover" (click)="togglePopoverContainer()">
          <i class="material-icons" [title]="filterTooltip">filter_list</i>
        </a>

        <div id="dashboard-filter" class="filter-popover-container" [style.display]="isDashboardFilterPopover ? 'block' : 'none'">
          <form class="form-group row mb-0" [formGroup]="filterForms" autocomplete="off">
            <a href="javascript:void(0)" id="filterClose" aria-label="Close" class="filter-close">
              <i class="material-icons" tabindex="0" aria-hidden="true" (click)='togglePopoverContainer()'>close</i>
            </a>

            <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper">
              <fdl-type-ahead label="Form:" formControlName="title" [collection]="responsesSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>

            <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper" *ngIf="showProjectFilter">
              <fdl-type-ahead label="Project:" formControlName="projectTitle" [collection]="projectSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>

            <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper" *ngIf="isClientFilterAllowed">
              <fdl-type-ahead label="Client:" formControlName="clientTitle" [collection]="clientSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>

            <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper" *ngIf="showProductFilter">
              <fdl-type-ahead label="Product:" formControlName="productTitle" [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>

            <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper" *ngIf="userType !== 'respondent'">
              <fdl-type-ahead label="Assigned To:" formControlName="assignedTo" [collection]="assignedToSuggestion" optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>

            <div class="col-lg col-md col-sm-4 mb-3 typeahead-wrapper" *ngIf="isAdmin">
              <fdl-type-ahead label="Organization:" formControlName="organizationTitle" [collection]="organizationSuggestion"
                              optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
              </fdl-type-ahead>
            </div>

            <div class="col-lg-12">
              <div class="fdl-2 mt-3 ">
                <button type="button" class="btn font-weight-normal btn-primary-blue" aria-label="Filter" (click)="filterResponsesClickHandler()">Filter</button>
                <button type="button" class="btn btn-link ml-1" aria-label="Default" (click)="resetResponsesClickHandler()">Reset</button>
              </div>
            </div>
          </form>
        </div>

      </div>
      <!-- Popover -->

    </div>
    <div class="right-section">
      <div class="card-switcher" *ngIf="userType !== 'respondent'">
        <span class="switch" [ngClass]="{'active': switchVal === 'project'}" (click)="switchVal='project'; activate('project')">Projects</span>
        <span class="switch" [ngClass]="{'active': switchVal === 'form'}" (click)="switchVal='form'; activate('form')">Forms</span>
      </div>
      <ul class="status-container" *ngIf="filteredForms">
        <li class="staus-block" [ngClass]="{'selected': statusBlockClicked === counter.key}" *ngFor="let counter of filteredForms.counters; let i = index"
            (click)="statusBlockClicked = counter.key; filterOnStatusBlock(counter.key);">
          <span class="text-white">{{counter.value}}</span>
          <span class="status">{{counter.key}}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="filte-wrapper">
        <div class="filter-tag" *ngFor="let tag of tagsArray; let i = index">
          <div class="tag" data-toggle="tooltip" data-placement="top" title="{{tag.value}}">
            {{tag.value}}
            <a href="javascript:void(0)" aria-label="Close" class="tag-close" (click)="removeTagClickHandler(tag.key)">
              <i class="material-icons" tabindex="0" aria-hidden="true">close</i>
            </a>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="main-container pb-0">
  <div class="no-recoards-found" *ngIf="filteredForms && filteredForms.forms.length === 0">
    <span>There are no Forms found</span>
  </div>
  <div class="row card-container" *ngIf="filteredForms">
    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6" *ngFor="let form of filteredForms.forms; let i = index">
      <section role="region" aria-labelledby="card3Title1" class="cards-3">
        <span class="badge mb-2" [ngClass]="calculateClasses(form.responseStatusTitle)">{{populateStatusOfForm(form)}}</span>
        <h4 id="card3Title1" class="text-truncate" data-toggle="tooltip" data-placement="top" title="{{form.title}}">{{form.title}}</h4>
        <!--<p class="text-truncate" data-toggle="tooltip" data-placement="top" title="{{form.projectTitle ? form.projectTitle : form.description}}">{{form.projectTitle ? form.projectTitle : form.description}}</p>-->
        <div class="text-truncate" style="height: 1.5rem;">
          <small class="w-100" *ngIf="form.clientTitle">Client Name: </small><span class="text-truncate" *ngIf="form.clientTitle">{{form.clientTitle ? form.clientTitle : ''}}</span>
        </div>
        <div class="row duration-info">
          <div class="col-md">
            <small class="w-100">Product Name:</small>
            <span data-toggle="tooltip" data-placement="top" title="{{form.productTitle}}">{{form.productTitle ? form.productTitle : 'N/A'}}</span>
          </div>
          <div class="col-md">
            <small class="w-100">Arrival Date:</small>
            <span data-toggle="tooltip" data-placement="top" title="{{form.startDate | date}}">{{form.startDate | dateFormat}}</span>
          </div>
          <div class="col-md" *ngIf="form.dueDate!='0001-01-01T00:00:00Z' && form.dueDate!='0001-12-31T00:00:00Z'">
            <small class="w-100">Due Date:</small>
            <span data-toggle="tooltip" data-placement="top" title="{{form.dueDate | date}}">{{form.dueDate | dateFormat}}</span>
          </div>
        </div>

        <div class="c-footer fdl-2">
          <div class="c-footer-section">
            <button class="btn btn-primary-blue" (click)="cardBtnClickHandler(form)">
              {{dashBoardBtnTxtOnStat(form.responseStatusTitle)}}
            </button>
          </div>
          <div class="profile c-footer-section">
            <span class="profile-disc">
              <img src="assets/images/thumbnail-150x150.png" alt="" class="thumbnail img-w28">
              <span class="profile-text" data-toggle="tooltip" data-placement="top" title="{{form.editAccessWith}}">{{form.editAccessWith}}</span>
            </span>
          </div>
        </div>

      </section>
    </div>
    <div class="card-footer pagination-wrapper">
      <div class="dashboard-pagination col-md-2 col-sm-2"></div>
      <div class="dashboard-pagination col-md-8 col-sm-8" *ngIf="paginationArr && paginationArr.length > 1">
        <a href="javascript:void(0);" (click)="firstPage()" [class.disabled]="isFirstPage()">«</a>
        <a href="javascript:void(0);" (click)="previousPage()" [class.disabled]="isFirstPage()">‹</a>
        <a href="javascript:void(0);" [class.selected]="pageNumber === paginate" *ngFor="let paginate of paginationArr" (click)="paginateClickHandler(paginate)" [innerHtml]="paginate"></a>
        <a href="javascript:void(0);" (click)="nextPage()" [class.disabled]="isLastPage()">›</a>
        <a href="javascript:void(0);" (click)="lastPage()" [class.disabled]="isLastPage()">»</a>
      </div>
      <div class="col-md-2 col-sm-4 pt-1 pull-right">
        <button class="btn btn-link float-right d-inline-block" (click)="viewAllResponsesClickHandler()" *ngIf="formList && formList.forms.length !== 0">View All Forms</button>
      </div>
    </div>

  </div>
  <!-- <app-footer></app-footer> -->
</div>
