import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { WorkflowService } from 'src/app/services/workflow.service';
import { MasterService } from 'src/app/services/master.service';
import { IWorkflowIntegrations, IWorkflowExecution } from 'src/app/shared/workflow';


@Component({
  selector: 'workflow-integrations',
  templateUrl: './workflow-integrations.component.html'
})
export class WorkFlowIntegrationsComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,  
    private workflowService: WorkflowService,
    private masterService: MasterService) { }

  workflowExecutionId: number
  workflowData: IWorkflowExecution
  workflowIntegrationData: IWorkflowIntegrations

  ngOnInit(): void {
    this.route.params
      .pipe(take(1))
      .subscribe((params) => {
        this.workflowExecutionId = params['id'];
        if (this.workflowExecutionId > -1) {
          this.getWorkflowData();
        }
      });

      this.getIntegrationsData();
  }

  getWorkflowData() {
    this.workflowService.getWorkflowExecutionById(this.workflowExecutionId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.workflowData = data;
        }
      );
  }

  getIntegrationsData() {
    this.workflowService.getWorkflowIntegrationsById(this.workflowExecutionId)
      .pipe(take(1))
      .subscribe(
        data => {
          this.workflowIntegrationData = data;
        }
      );
  }

  goBack(){
    this.masterService.isCancelledClicked(true);
    this.router.navigate(['/self-service/workflowExecution']);
  }
}
