<div class="heading-container">
  <div class="back-action-container">
      <div class="back-button">
        <a href="javascript:void(0);" (click)="back()">
          <span class="material-icons">
            arrow_back
          </span>
          {{returnPage}}
        </a>
      </div>
    <h1 *ngIf="!isEdit">Create New Article</h1>
    <h1 *ngIf="isEdit">Edit Article</h1>
  </div>
</div>

<div class="create-new-topic-container main-container-sub responsive-container separate-form-container pb-2" *ngIf="isReady">
  <section class="right-section assign-container col-12 pl-2 pr-2" style="max-width: 100%;">
    <form [formGroup]="createFormGroup" autocomplete="off">
      <div class="page-heading-container mb-3">
        <div>
          <p>Please complete below required information to create a new help Article.</p>
        </div>
      </div>

      <div class="form-row  mt-3" *ngIf="isAdmin">
        <div class="col-md-3 mb-3 typeahead-wrapper" [ngClass]="{'has-error': (invalidOrganizationName || (!createFormGroup.get('organization').valid && (createFormGroup.get('organization').touched  || isSubmitted))) }">
          <label for="organization" class="asterisk">Organization: </label>
          <fdl-type-ahead formControlName="organization" [collection]="organizationSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onOrgSelect($event)"></fdl-type-ahead>
          <div class="has-error">
            <span *ngIf="invalidOrganizationName || (!createFormGroup.get('organization').valid && createFormGroup.get('organization').errors?.pattern && createFormGroup.get('organization').dirty)"><i class="material-icons">warning</i> Please enter the valid organization name.</span>
            <span *ngIf="!createFormGroup.get('organization').valid && createFormGroup.get('organization').errors?.required && (createFormGroup.get('organization').dirty || isSubmitted)"><i class="material-icons">warning</i> Please select organization name. </span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-3 mb-3" [ngClass]="{'has-error': !createFormGroup.get('formNameCtrl').valid && (createFormGroup.get('formNameCtrl').touched || isSubmitted)}">
          <label for="topicName" class="asterisk">Article Name: </label><input type="text" id="topicName" formControlName="formNameCtrl" class="form-control">
          <div class="has-error">
            <span *ngIf="!createFormGroup.get('formNameCtrl').valid && (createFormGroup.get('formNameCtrl').errors?.required || createFormGroup.get('formNameCtrl').errors?.validateWhiteSpaces) && (createFormGroup.get('formNameCtrl').touched || isSubmitted)">
              <i class="material-icons">warning</i> Please enter a Article name.
            </span>
            <span *ngIf="!createFormGroup.get('formNameCtrl').valid && createFormGroup.get('formNameCtrl').errors?.pattern && createFormGroup.get('formNameCtrl').touched">
              <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
            </span>
            <span *ngIf="!createFormGroup.get('formNameCtrl').valid && createFormGroup.get('formNameCtrl').errors?.maxlength && createFormGroup.get('formNameCtrl').touched">
              <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Form.Name}}, please correct it.
            </span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-8 mb-3" [ngClass]="{'has-error': !createFormGroup.get('formDesCtrl').valid && (createFormGroup.get('formDesCtrl').errors?.required || createFormGroup.get('formDesCtrl').errors?.validateWhiteSpaces) && (createFormGroup.get('formDesCtrl').touched  || isSubmitted)}">
          <label for="description" class="asterisk">Description: </label>
          <textarea class="form-control" id="formDes" formControlName="formDesCtrl"></textarea>
          <div class="has-error">
            <span *ngIf="!createFormGroup.get('formDesCtrl').valid && (createFormGroup.get('formDesCtrl').errors?.required || createFormGroup.get('formDesCtrl').errors?.validateWhiteSpaces) && (createFormGroup.get('formDesCtrl').touched  || isSubmitted)">
              <i class="material-icons">warning</i> Please enter the Article description.
            </span>
            <span *ngIf="!createFormGroup.get('formDesCtrl').valid && createFormGroup.get('formDesCtrl').errors?.pattern && createFormGroup.get('formDesCtrl').touched">
              <i class="material-icons">warning</i> {{regexValidationMessage.NAME_TITLE_VALIDATION_MESSAGE}}
            </span>
            <span *ngIf="!createFormGroup.get('formDesCtrl').valid && createFormGroup.get('formDesCtrl').errors?.maxlength && createFormGroup.get('formDesCtrl').touched">
              <i class="material-icons">warning</i> Maximum input character limit is {{_maxLength.Form.Description}}, please correct it.
            </span>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-3 mb-3 typeahead-wrapper" [ngClass]="{'has-error': !createFormGroup.get('catNameCtrl').valid && (createFormGroup.get('catNameCtrl').touched ||isSubmitted)}">
          <label for="topicLevel" class="asterisk">Article Category: </label>
          <fdl-type-ahead formControlName="catNameCtrl" [collection]="catSuggestion" optionField="name" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0"></fdl-type-ahead>
          <div class="has-error">
            <span *ngIf="invalidCatName || (!createFormGroup.get('catNameCtrl').valid && createFormGroup.get('catNameCtrl').errors?.pattern && createFormGroup.get('catNameCtrl').dirty)">
              <i class="material-icons">warning</i> Please enter a valid Article Category
            </span>
            <span *ngIf="!createFormGroup.get('catNameCtrl').valid && (createFormGroup.get('catNameCtrl').touched ||isSubmitted) && !invalidCatName">
              <i class="material-icons">warning</i> Please select category name.
            </span>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-3 mb-3 typeahead-wrapper" [ngClass]="{'has-error': (!createFormGroup.get('entityLevel').valid || selectedEntityLevelValue == '') && (createFormGroup.get('entityLevel').touched || isSubmitted)}">
            <label for="entityLevel" class="asterisk">
                Entity Level:
            </label>
            <div class="fdl-select">
                <select name="entityLevel" class="form-control" aria-invalid="false" formControlName="entityLevel" [(ngModel)]='selectedEntityLevelValue' (change)="setEntityValues()" ngModel>
                    <option value="">Please select</option>
                    <option *ngFor="let level of entityLevel | keyvalue" [value]="level.value">
                        {{level.value}}
                    </option>
                </select>
            </div>
            <div class="has-error">
                <span *ngIf="(!createFormGroup.get('entityLevel').valid || selectedEntityLevelValue == '') && (createFormGroup.get('entityLevel').touched || isSubmitted)">
                    <i class="material-icons">warning</i> Please select entity level.
                </span>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="col-md-3 mb-3 typeahead-wrapper">
            <label *ngIf="selectedEntityLevelValue == 'Product' || selectedEntityLevelValue == 'Form'" for="EntityName" class="asterisk">
                Entity Name:
            </label>
            <fdl-type-ahead *ngIf="selectedEntityLevelValue == 'Product'" formControlName="entityNameProduct"
                [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                [optionsLimit]="100" [minLength]="0" (onSelect)="onEntityProductSelect($event)"
                [(ngModel)]="productText" (input)="typeaheadLoadingProducts()">
            </fdl-type-ahead>
            <fdl-type-ahead *ngIf="selectedEntityLevelValue == 'Form'" formControlName="entityNameForm"
                [collection]="formSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true"
                [optionsLimit]="500" [minLength]="0" (onSelect)="onEntityFormSelect($event)">
            </fdl-type-ahead>
            <div class="has-error">
                <span *ngIf="(invalidProductName || !createFormGroup.get('entityNameProduct').valid && createFormGroup.get('entityNameProduct').dirty) && selectedEntityLevelValue == 'Product'">
                    <i class="material-icons">warning</i> Please enter a valid Product Name
                </span>
                <span *ngIf="(invalidFormName || !createFormGroup.get('entityNameForm').valid && createFormGroup.get('entityNameForm').errors?.pattern && createFormGroup.get('entityNameForm').dirty) && selectedEntityLevelValue == 'Form'">
                    <i class="material-icons">warning</i> Please enter a valid Form Name
                </span>
                <span *ngIf="(invalidOrganizationName || !createFormGroup.get('organization').valid) && (createFormGroup.get('organization').touched || isSubmitted) && selectedEntityLevelValue == 'Organization'">
                    <i class="material-icons">warning</i> Please enter a valid Organization Name
                </span>
            </div>
        </div>
    </div>

    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="Template">Publish:</label>
        <div class="radio-group d-flex">
          <div class="radio mr-3">
            <input type="radio" id="one" formControlName="isPublishable" value="true" />   <label for="one"><span>Yes</span></label>
          </div>
          <div class="radio">
            <input type="radio" id="two" formControlName="isPublishable" value="false" />  <label for="two"><span>No</span></label>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="(isAdmin || isDomainAdmin)">
      <div class="col-md-6 mb-3">
        <label for="Template">Do you want to recommend this Article ?</label>
        <div class="radio-group d-flex">
          <div class="radio mr-3">
            <input type="radio" name="isRecommendedCtrl" id="isRecommendedOne" formControlName="isRecommendedCtrl" value="1" />
            <label for="isRecommendedOne"><span>Yes</span></label>
          </div>
          <div class="radio">
            <input type="radio" name="isRecommendedCtrl" id="isRecommendedTwo" formControlName="isRecommendedCtrl" value="0" />
            <label for="isRecommendedTwo"><span>No</span></label>

          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="(isAdmin || isDomainAdmin || showCopyArticle) && !isEdit">
      <div class="col-md-6 mb-3">
        <label for="Template">Do you want to use existing resource article template?</label>
        <div class="radio-group d-flex">
          <div class="radio mr-3">
            <input type="radio" name="UseExistingArticleCtlr" id="ExistingArticleYes" formControlName="UseExistingArticleCtlr" [value]="true" *ngIf="canUseExistingArticle" />
            <input type="radio" name="UseExistingArticleCtlr" id="ExistingArticleYes" formControlName="UseExistingArticleCtlr" [value]="true" disabled="disabled" *ngIf="canUseExistingArticle === false" />
            <label for="ExistingArticleYes"><span>Yes</span></label>
          </div>
          <div class="radio">
            <input type="radio" name="UseExistingArticleCtlr" id="ExistingArticleNo" formControlName="UseExistingArticleCtlr" [value]="false" />
            <label for="ExistingArticleNo"><span>No</span></label>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="createFormGroup.get('UseExistingArticleCtlr').value">
      <div class="col-md-3 mb-3 typeahead-wrapper" [ngClass]="{'has-error': (!createFormGroup.get('articleNameCtrl').valid && createFormGroup.get('UseExistingArticleCtlr').value) && (createFormGroup.get('articleNameCtrl').touched || isSubmitted)}">
        <label for="articleName" class="asterisk">
          Resource Article Name:
        </label>
        <fdl-type-ahead formControlName="articleNameCtrl" [collection]="helpArticleSuggestion" optionField="title"
                          placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
          <div class="has-error">
            <span *ngIf="(!createFormGroup.get('articleNameCtrl').valid && createFormGroup.get('UseExistingArticleCtlr').value) && (createFormGroup.get('articleNameCtrl').touched || isSubmitted)">
              <i class="material-icons">warning</i> Please select article name.
            </span>
          </div>
      </div>
    </div>


      <!--<div class="form-row mt-3">
    <div class="col-md-3 m">
      <label for="searchTags" class="asterisk">Search Tags</label>
      <div class="custom-add-tag">
        <button class="add-tag-btn">Add Tag</button>
        <input type="text" multiple="false" class="form-control" id="searchTags">
      </div>
    </div>
  </div>
  <div class="form-row mt-3">
    <div class="col-md-3 m">
      <div class="tag-wrapper">
        <div class="filter-wrapper">
          <div class="filter-tag">
            <div data-toggle="tooltip" data-placement="top" class="tag" title="User created the record.">
              Tag 1<a href="javascript:void(0)" aria-label="Close" class="tag-close"><i tabindex="0" aria-hidden="true" class="material-icons">close</i></a>
            </div>
          </div>
          <div class="filter-tag">
            <div data-toggle="tooltip" data-placement="top" class="tag" title="User created the record.">
              Tag 2<a href="javascript:void(0)" aria-label="Close" class="tag-close"><i tabindex="0" aria-hidden="true" class="material-icons">close</i></a>
            </div>
          </div>
          <div class="filter-tag">
            <div data-toggle="tooltip" data-placement="top" class="tag" title="User created the record.">
              Tag 3<a href="javascript:void(0)" aria-label="Close" class="tag-close"><i tabindex="0" aria-hidden="true" class="material-icons">close</i></a>
            </div>
          </div>

          <div class="filter-tag">
            <div data-toggle="tooltip" data-placement="top" class="tag" title="User created the record.">
              Tag 4<a href="javascript:void(0)" aria-label="Close" class="tag-close"><i tabindex="0" aria-hidden="true" class="material-icons">close</i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row mt-3">
    <div class="col-md-3 m">
      <label for="excelinput" class="asterisk">Upload a File</label>
      <div class="custom-file">
        <label for="excelinput" data-toggle="tooltip" data-placement="top" class="custom-file-label text-truncate pr-80" title=""></label>
        <input type="file" id="excelinput" multiple="false" class="custom-file-input form-control">
      </div>
      <div class="has-error"></div>
    </div>
  </div>-->
      <!-- app-data-table Table starts from here  -->
      <!--<div class="table-responsive mh200 overflow-inherit">-->
      <!--<div class="table-heading" style="font: normal normal normal 16px/16px Segoe UI; letter-spacing: 0px; color: #212121; margin-top: 25px; margin-bottom: 15px;">My Uploads</div>
    <table aria-describedby="tblDesc" class="f-12 table table-hover table-label">
      <thead>
        <tr role="row">
          <th role="columnheader" scope="col">
            <a href="javascript:void(0);">File Name</a>
          </th>
          <th role="columnheader" scope="col">
            <a href="javascript:void(0);">Format</a>
          </th>
          <th role="columnheader" scope="col">
            <span>Uploaded By</span>
          </th>
          <th role="columnheader" scope="col">
            <a href="javascript:void(0);">Uploaded On</a>
          </th>
          <th role="columnheader" scope="col">
            <a href="javascript:void(0);">Action</a>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr role="row">
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="5963">My Topic</label>
          </td>
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="MGtest17">JPEG</label>
          </td>
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="MGtest17">James Norman</label>
          </td>
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="MG Fiserv Form Respondent;MG Client Form Respondent">11/23/2021 13:12</label>
          </td>

          <td role="cell">
            <div class="kebab-menu dropleft">
              <a href="javascript:void(0);" data-toggle="dropdown" id="headerSwitcher" aria-expanded="false"><span class="material-icons"> more_vert </span></a>
            </div>
          </td>
        </tr>
        <tr role="row">
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="5963">My Topic</label>
          </td>
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="MGtest17">JPEG</label>
          </td>
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="MGtest17">James Norman</label>
          </td>
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="MG Fiserv Form Respondent;MG Client Form Respondent">11/23/2021 12:12</label>
          </td>

          <td role="cell">
            <div class="kebab-menu dropleft">
              <a href="javascript:void(0);" data-toggle="dropdown" id="headerSwitcher" aria-expanded="false"><span class="material-icons"> more_vert </span></a>
            </div>
          </td>
        </tr>
        <tr role="row">
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="5963">My Topic</label>
          </td>
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="MGtest17">JPEG</label>
          </td>
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="MGtest17">James Norman</label>
          </td>
          <td role="cell">
            <label for="myCheckboxZero" data-toggle="tooltip" data-placement="top" title="MG Fiserv Form Respondent;MG Client Form Respondent">11/23/2021 12:00</label>
          </td>

          <td role="cell">
            <div class="kebab-menu dropleft">
              <a href="javascript:void(0);" data-toggle="dropdown" id="headerSwitcher" aria-expanded="false"><span class="material-icons"> more_vert </span></a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>-->
      <!-- app-data-table Table ends from here  -->
      <!--<div class="row">
    <div class="col-md-2 align-self-start">
      <button type="button" aria-label="Search" class="btn fdl-btn btn-primary-dark"><span class="material-icons mr-2" style="font-size: 20px;">add</span>Add a Sub-Topic</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <p class="grey-color">Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vel orci vitae mi fermentum fermentum. Nunc et rutrum sem. Sed faucibus iaculis urna, ut facilisis ligula.</p>
    </div>
  </div>-->

      <div class="fdl-2 button-container">
        <button type="button" aria-label="Default" class="btn fdl-btn btn-secondary-light" (click)="back()">Cancel</button>
        <div>
          <div class="btn-group btn-split">
            <button type="button" class="btn fdl-btn btn-primary-orange mr-3" (click)="saveForm()">Next</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
