import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appProjectDrawerHost]'
})
export class ProjectDrawerHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) {}

}
