import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AdminEntityLevel, EditEntityLevel } from "src/app/core/constants";
import { PermissionsService } from "src/app/services/permissions.service";
import { IReport, ReportResolved } from "src/app/shared/report";

@Component({
  templateUrl: './report-edit.component.html'
})
export class ReportEditComponent implements OnInit {

  isError: boolean = false;
  errorMsg: string = '';
  successMsg: string = '';

  isEditMode: boolean = false;
  isAdmin: boolean = false;

  entities: (string | AdminEntityLevel | EditEntityLevel)[] = [];
  editReportForm: FormGroup

  pageTitle: string = '';

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private permissionService: PermissionsService
  ){}

  ngOnInit(): void {
    this.isEditMode = true;
    this.isAdmin = this.permissionService.userData.isAdmin;
    const resolvedData: ReportResolved = this.route.snapshot.data['resolvedData'];
    this.populateEntityLevels();
    this.setEntityLevels(resolvedData.report);
  }

  
  initForm(): void {
    this.editReportForm = this.fb.group({
      organization: [{ value: '', disabled: true }],
      entityLevel: [{ value: '', disabled: true }, [Validators.required]],
      entityName: [{ value: '', disabled: true }],
      reportName: [''],
      reportDescription: [''],
      reportURL: [''],
      isVisible: false
    });
  }

  populateEntityLevels(): void {
  
    if(this.isAdmin) {
      this.entities = Object.values(AdminEntityLevel).filter(value => typeof value !== 'number');
    } else {
    this.entities = Object.values(EditEntityLevel).filter(value => typeof value !== 'number');
    }
  }

  setEntityLevels(data: IReport): void {
    if(data.organizationId != null &&  data.organizationId != undefined ){
      data.level = 'organization';
      data.entityName = data.organizationTitle;
     } else if (data.productId != null &&  data.productId != undefined ) {
       data.level = 'product';
       data.entityName = data.productTitle;
     } else if (data.formId != null &&  data.formId != undefined ) {
       data.level = 'form';
       data.entityName = data.formTitle;
     }  else {
       data.level = 'system';
     }
     this.pageTitle =  data.entityName;
  }

  onError(event: any): void {
    this.isError = true;
    this.errorMsg = event;
  }
}
