import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAX_LENGTHS, MimeTypeConstants, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { ProjectsService } from 'src/app/services/projects.service';
import { Location } from '@angular/common';
import { ClientsService } from 'src/app/services/clients.service';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { FileValidators } from "../manageFileUploads/validators/file-validators";

@Component({
  selector: 'self-service-bulk-upload',
  templateUrl: './bulk-upload.component.html'
})
export class BulkUploadComponent implements OnInit, OnDestroy {

  bulkRequestType: string;
  fileTemplateForm: FormGroup;

  submitted: boolean = false;
	isError: boolean = false;
  isSuccess: boolean = false;
  validFile: boolean = false;
  validFileType : boolean = true;
  validFileName : boolean = true;
	errorMsg: string = "";
  successMsg: string = "";
  _maxLength: any;
  selectedFileName: string;
  returnPage: string;
  requestType: string = '';
  regexValidationMessage: any;
  @ViewChild('inputFileControl') inputFile: ElementRef;

  constructor(
    private location: Location,
    private router: Router,
    private projectService: ProjectsService,
    private clientService: ClientsService,
    private pageTemplateService: PageTemplateService,
    private fb: FormBuilder
  )  {this._maxLength = MAX_LENGTHS;};

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.returnPage = "Self Service" ;

    if(this.pageTemplateService.pageTemplateId > 0) {
      let result = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
      let str = result.split('%20');

      for(let i=0; i<str.length; i++)
        this.requestType = this.requestType + str[i] + " ";

      this.bulkRequestType = "Requests";
      this.returnPage = this.requestType;
    }
    else if (this.router.url.indexOf('client') !== -1)
      this.bulkRequestType = 'Client';
    else if (this.router.url.indexOf('project') !== -1)
      this.bulkRequestType = 'Project';
    else if (this.router.url.indexOf('form') !== -1)
      this.bulkRequestType = 'Form';

    this.selectedFileName = "";
    this.initFilterForm();
  }

  ngOnDestroy(): void {
    this.resetFiles();
    this.resetError();
    this.isSuccess = false;
    this.successMsg = "";
    this.pageTemplateService.pageTemplateId = 0;
  }

  initFilterForm() {
		this.fileTemplateForm = this.fb.group({
			fileName: [null, [Validators.required]]
		});
	}


  goBack() {
    this.location.back();
  }

  Submit() {
		this.resetError();
    this.submitted = true;
    if (this.selectedFileName && this.validFileName && this.validFile) {
      this.isError = false;
      this.errorMsg= "";
      const submitData = this.setFormParams();
      if (this.bulkRequestType === 'Client'){
        this.clientService.bulkRequest(submitData)
        .subscribe(
          () => {
            this.resetFiles();
            this.onCreateComplete();
          },
          error => {
            this.isError = true;
            this.errorMsg = error;
          }
        );
      }
      else if (this.bulkRequestType === 'Requests'){
        let pageTemplateId = this.pageTemplateService.pageTemplateId;
        this.pageTemplateService.bulkRequest(pageTemplateId, submitData)
        .subscribe(
          () => {
            this.resetFiles();
            this.onCreateComplete();
            this.goBack();
          },
          error => {
            this.isError = true;
            this.errorMsg = error;
          }
        );
      }
      else {
        this.projectService.bulkRequest(this.bulkRequestType, submitData)
        .subscribe(
          () => {
            this.resetFiles();
            this.onCreateComplete();
          },
          error => {
            this.isError = true;
            this.errorMsg = error;
          }
        );
      }
    }
  }

  private setFormParams(){
    let formData = {
			file: this.fileTemplateForm.controls.fileName.value, // this.fileTemplateForm.get("fileName")
    }
    return formData;
  }

  private onCreateComplete(): void {
    if(this.requestType != '')
      this.pageTemplateService.isPrePopulateRequest = true;
    else {
      this.isSuccess = true;
      this.successMsg = `${this.bulkRequestType}(s) has been uploaded successfully.`
    }
    this.resetError();
    this.submitted = false;
  }

  onFileChange(event) {
    this.resetError();
    this.selectedFileName = "";
    let fileName = this.fileTemplateForm.get("fileName");
		if (fileName.untouched) fileName.markAsTouched();

    const target: DataTransfer = <DataTransfer>event.target;
		let fileList: FileList = target.files;
    let maxFileSize = MAX_LENGTHS.File.Size;
    if (fileList.item(0).size <= (maxFileSize * 1024 * 1024)) {
      if (fileList.length > 0) {
        const file = fileList.item(0);
        this.selectedFileName = file.name;
        fileName.setValue(file);
        this.validFileName= FileValidators.ValidateFileName(file.name);
        this.validFile = this.validateFileExtension(this.selectedFileName, 'xlsx');

        if (this.validFile == true){
          this.validFileType = FileValidators.CheckAllowedContentType(event, MimeTypeConstants.XLSX);
        }
      }
      else {
        this.resetFiles();
        fileName.setValue(null);
      }
    }
    else {
			this.isError = true;
			this.errorMsg = "File size exceeds maximum size limit i.e. 50 MB";
			this.inputFile.nativeElement.value = "";
		}
  }

  validateFileExtension(name: String, fileExt: String) {
    let ext: string = name.substring(name.lastIndexOf('.') + 1);
    return (ext.toLowerCase().includes(fileExt.valueOf()));
  }

  resetFiles() {
    this.selectedFileName = "";
    this.inputFile.nativeElement.value = "";
    this.fileTemplateForm.controls['fileName'].setValue(null);
    this.fileTemplateForm.controls['fileName'].markAsUntouched();
    this.submitted = false;
    this.validFile = false;
  }

  resetError() {
    this.isError = false;
    this.errorMsg= "";
  }

  downloadTemplate() {
    let link = document.createElement("a");
    link.setAttribute('type', 'hidden');
    if (this.bulkRequestType === 'Client') {
      link.download = "Client_Bulk_Creation.xlsx";
      link.href = "assets/Client_Bulk_Creation.xlsx";
    }
    else if (this.bulkRequestType === 'Project') {
      link.download = "Project_Bulk_Creation.xlsx";
      link.href = "assets/Project_Bulk_Creation.xlsx";
    }
    else if (this.bulkRequestType === 'Form') {
      link.download = "Form_Bulk_Creation.xlsx";
      link.href = "assets/Form_Bulk_Creation.xlsx";
    }
    else if (this.bulkRequestType === 'Requests') {
      link.download = "Requests_Bulk_Creation.xlsx";
      link.href = "assets/Requests_Bulk_Creation.xlsx";
    }
    link.click();
    link.remove();
  }
}
