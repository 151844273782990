export class UserFilter{
  constructor(public userFilterData : UserFilterData[], public expiry:Date){}
}

export class UserFilterData{
  constructor(public routerKey:string, public filters: Filter[]){};
}

export class Filter{
  constructor(public key: string, public value: string){}
}
