import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { MAX_LENGTHS } from 'src/app/core/constants';
import { ClientsService } from 'src/app/services/clients.service';
import { FormsService } from 'src/app/services/forms.service';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { IPageTemplate, IPageTemplateResponse } from 'src/app/shared/page-template';
declare var $: any;


@Component({
  selector: 'app-commitments-delegate',
  templateUrl: './commitments-delegate.component.html',
  styleUrls: ['./commitments-delegate.component.css']
})
export class CommitmentsDelegateComponent implements OnInit, OnChanges {
  @Input() pageTemplate: IPageTemplate;
  @Input() pageTemplateResponseId: number;
  @Input() selectedResponseId: number;
  @Output() private delegateCompleted = new EventEmitter<{success: boolean, msg: string}>();
  
  delegateForm: FormGroup;
  isDelegateError: boolean = false;
  delegateErrorMsg: string;
  _maxLengths: any;
  regexValidationMessage: any;
  respondentSuggestion: any;
  isDelegateSubmitted: boolean = false;
  invalidRespondent: boolean = true;
  selectedRespondentId: number = 0;
  selectedRespondentName: string;
  delegateResponseTitle: string;
  respondentEmail: string;
  respondentUserList: any;
  userData: any;
  canRequest: boolean = false;
  organizationDetails: any;
  userGroupOrgIds: string = "";
  pageTemplateAdmin: boolean = false;
  pageTemplateReadAdmin: boolean = false;
  isError: boolean = false;
  isAdmin = false;
  errorMsg = '';
  successMsg = '';
  isLoading = false;

  private fb: FormBuilder;
    
  constructor(private pageTemplateService: PageTemplateService,
    private projectService: ProjectsService,
    private userService: UserService,
    private formService: FormsService,
    private clientsService: ClientsService,
    private permission: PermissionsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
  }

  ngOnInit(): void {
    this.initiateDelegateForm();
    this.userData = this.userService.getUserListData();
    this.organizationDetails = Object.assign({}, this.userService.getUserListData());
    this.getRespondents();
    this.isAdmin = this.userData.isAdmin;
    this.canRequest = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id)
      || this.permission.userData.isAdmin || (this.userData.isRestrictedAdmin && this.userData.roles.some(r => r.roleId === 10));
  }

  initiateDelegateForm() {
    this._maxLengths = MAX_LENGTHS;
    let validatorArray = [Validators.maxLength(this._maxLengths.Approval.Comments)];
    validatorArray.push(Validators.required);

    this.delegateForm = new FormGroup({
      'respondentName': new FormControl(''),
      'comments': new FormControl('', validatorArray)
    })
  }

  getRespondents() {
    let usersByOrganization;
    let usersByClient
    let respondentApiArr = [];
    this.isLoading = true;

    this.pageTemplateService.getPageTemplateResponseById(this.pageTemplateResponseId)
      .pipe(take(1))
      .subscribe(
        response => {
          let pageResponse = response as IPageTemplateResponse;

          if (this.canRequest) {
            if (this.userGroupOrgIds !== "" && this.userData.isRestrictedAdmin) {
              respondentApiArr.push(this.formService.getUsersByOrganizationIds(this.userGroupOrgIds));
            }
            else {
              respondentApiArr.push(this.formService.getUsersForSelectedOrg(0));
            }
          }
          else {
            if (this.userData.type === 'Client') {
              respondentApiArr.push(this.projectService.getSuggestions("users", ""));
            }
            else {
              respondentApiArr.push(this.formService.getFiservUserForSelectedOrg(this.organizationDetails.organization));
            }
          }

          if (!(this.pageTemplateAdmin || this.userData.isAdmin || (this.userGroupOrgIds === "" && this.userData.isRestrictedAdmin)) && pageResponse.clientId > 0 && this.pageTemplate.allowClientUser && this.userData.type === 'Fiserv') {
            respondentApiArr.push(this.clientsService.getClientDetailsById(pageResponse.clientId, "respondents"));
          }

          forkJoin(respondentApiArr).subscribe(userData => {
            usersByOrganization = userData[0];
            usersByClient = userData[1];
            const allUsers = (usersByClient !== undefined) ? usersByOrganization.concat(usersByClient) : usersByOrganization;
            this.respondentUserList = allUsers.sort((a, b) => a.name > b.name ? 1 : -1);
            this.isLoading = false;
          },
            (error) => { this.isError = true; this.errorMsg = error; this.isLoading = false;});
        },
        (error) => { this.errorMsg = error; this.isLoading = false;});
  }

  onRespondentSelect(event) {
    this.respondentEmail = "";
    this.selectedRespondentId = event.item.id;
    this.selectedRespondentName = event.item.name;
    this.invalidRespondent = false;

    let respondent = this.projectService.fetchDetailsForUserByUserId(this.respondentUserList, this.selectedRespondentId);
    this.respondentEmail = respondent.email;
  }

  submitDelegate() {
    this.isDelegateSubmitted = true;

    this.selectedRespondentName = this.delegateForm.get('respondentName').value;
    if (this.selectedRespondentName) {
      let respId = this.projectService.fetchIdForRequest(this.respondentUserList, this.selectedRespondentName);
      if (respId > 0) { this.selectedRespondentId = respId; this.invalidRespondent = false; }
      else { this.invalidRespondent = true; return; }
    }

    if (!this.delegateForm.valid) return;

    let data: any = {
      assignedToId: this.selectedRespondentId,
      comments: this.delegateForm.get('comments').value
    };

    this.pageTemplateService.delegateResponse(this.pageTemplateResponseId, data)
      .subscribe(
        (data) => {
          this.cancelDelegate();
          this.successMsg = `Request has been delegated successfully.`;
          this.delegateCompleted.emit({success: true, msg: this.successMsg});
        },
        error => {
          this.isDelegateError = true;
          this.delegateErrorMsg = error;
          this.delegateCompleted.emit({success: false, msg: this.delegateErrorMsg});
          this.isDelegateSubmitted = false;
        }
      );
  }

  cancelDelegate() {
    this.isDelegateSubmitted = false;
    this.invalidRespondent = true;
    this.isDelegateError = false;
    this.delegateErrorMsg = "";
    this.respondentEmail = "";
    this.pageTemplateResponseId = 0;
    this.delegateForm.reset();
    $('#delegateCommitment').modal('hide');
  }

}
