<div class="help-landing-container heading-container">
    <h1>Quick Search</h1>
</div>
<div class="help-landing-page main-container-sub responsive-container separate-form-container">
    <section class="right-section assign-container col-12 pl-2 pr-2" style="max-width: 100%;">
        <div class="p-0" autocomplete="off">
            <div class="landing-search-wrapper">
                <div class="search-container">
                    <div style="width: 200px;" class="help-search fdl-select ">
                        <select *ngIf="searchTypeValues" style="border-right: unset;" name="category" aria-invalid="false" class="form-control" [(ngModel)]="searchType" (change)="onSearchTypeChanged()">
                            <option *ngFor="let state of searchTypeValues" [ngValue]="state.value">{{ state.text }}</option>
                          </select>
                    </div>        
                    <div class="help-search">
                        <input style="border-left: unset; background-color: #e8e8e8;" type="text" [(ngModel)]="searchText" (ngModelChange)="onSearchTextChanged()"
                            placeholder="Type to search">
                            <button style="cursor: pointer" *ngIf="searchText" (click)="reset()"><i class="material-icons icon-after">clear</i></button>
                    </div>
                </div>
            </div> 
            <div style="margin-top: -2em" class="text-center mb-5">
                <small class="">{{searchFieldHelpText}}.</small>
            </div>
            
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="!isError && successMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
            <span class="iconLabel" aria-label="sucess">
            </span>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p>
              {{successMsg}}
  
            </p>
        </div>
        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
            <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="errorMsg"></p>
          </div>

        <div class="data-table-container">
            <div class="justify-content-between d-flex">
            <div class="headline-container">
              <p *ngIf="pagerObject">Total {{pagerObject.totalCount | number}} items</p>
            </div>
            <div class="refresh-icon-link mt-1">
                <!-- add class of 'fa-rotation' on click of the refresh icon -->
                <a title="Refresh List" id="refreshTask">
                  <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
                </a>
              </div>
        </div>
        <div class="table-responsive form-responces s-overflow-auto">
            <div *ngIf="searchType === quickSearchType.PROJECT">
              <app-data-table *ngIf="searchSuggestions && searchSuggestions.length > 0"
                [rows]="searchSuggestions"
                [keys]="keys"
                [enableSorting]="true"
                [showCheckbox]="false"
                [showViewEdit]="isEditProjectAllowed"
                [showDelete]="false"
                [canDeleteEntity]="canDeleteProject"
                [canUpdateProjectStatus]="canUpdateProjectStatus"
                [showViewProjectTimeline]="true"
                [displayColumns]="displayColumns"
                [type]="displayType"
                (sortingClicked)="applySorting($event)"
                (buttonClicked)="projectAction($event)"
                (deleteBtnClicked)="projectAction($event)">
              </app-data-table>
            </div>

            <div *ngIf="searchType === quickSearchType.RESPONSE">
                <app-data-table *ngIf="searchSuggestions && searchSuggestions.length > 0"
                                [rows]="searchSuggestions"
                                [keys]="keys"
                                [showEdit]="isAdmin"
                                [showViewHistory]="isViewHistoryAllowed"
                                [showDelegate]="true"
                                [showChangeApprover]="isChangeApproverAllowed"
                                [showCheckbox]="false"
                                [showViewTimeline]="isViewTimelineAllowed"
                                [showDelete]="isDeleteResponseAllowed"
                                [showReinitiate]="isReinitiateAllowed"
                                [showRequestExtract]="true"
                                [showEditFormDetails]="isEditFormDetailsAllowed"
                                [showViewProjectTimeline]="showProjectFilter"
                                [canDeleteEntity]="allowDelete"
                                [enableSorting]="true"
                                (deleteBtnClicked)="responseAction($event)"
                                (sortingClicked)="applySorting($event)"
                                (buttonClicked)="responseAction($event)"
                                [displayColumns]="displayColumns"
                                [showCheckbox]="false"
                                [type]="displayType">
                </app-data-table>
            </div>

            <div *ngIf="searchType === quickSearchType.ARTICLE">
                <app-data-table *ngIf="searchSuggestions && searchSuggestions.length > 0"
                                [rows]="searchSuggestions"
                                [keys]="keys"
                                [showViewEdit]="true"
                                [showDelete]="true"
                                [enableSorting]="true"
                                (sortingClicked)="applySorting($event)"
                                (deleteBtnClicked)="acticleAction($event)"
                                (buttonClicked)="acticleAction($event)"
                                [displayColumns]="displayColumns"
                                [showCheckbox]="false" [type]="displayType">
                </app-data-table>
            </div>

            <div class="text-center" *ngIf="(!searchSuggestions || searchSuggestions.length === 0)">
                No records available.
            </div>

            <div *ngIf="pagerObject">
                <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 " [pagerObject]="pagerObject" (pagingChanged)="changePage($event)"></app-pagination>
            </div>
        </div>
    
        </div>
    </section>
</div>
<app-put-project-status (updateStatusSuccess)="onUpdateProjectStatusSuccess($event)" [data]="projectData" #putProjectStatus></app-put-project-status>
<app-put-on-hold (successUpdateApprover)="successUpdateApproverClick($event)" [data]="responseData" #putOnHold></app-put-on-hold>
<app-edit-form-details (successUpdate)="successUpdateApproverClick($event)" [data]="responseData" #editformdetails></app-edit-form-details>
<app-update-approver type="viewFormResponses" (successUpdateApprover)="successUpdateApproverClick($event)" [updateApproverFormData]="responseData"></app-update-approver>
<app-delegate type="viewFormResponses" (successDelegate)="successDelegateClick($event)" [delegateFormData]="responseData" [mode]="responseMode"></app-delegate>