import { Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Subject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IPager } from '../shared/pagination';
import { IQuery } from '../shared/query';
import { IUser } from '../shared/user';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, tap, switchMap, map, takeUntil } from 'rxjs/operators';
import { UserNotificationService } from '../services/user-notification.service';
import { UserService } from '../services/user.service';
import { UserNotificationSeverityValues, UserNotificationState, UserNotificationStateValues, UserNotificationType } from '../core/constants';
declare var $: any;

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.css']
})
export class UserNotificationComponent implements OnInit, OnDestroy {
  userData: IUser;
  successMsg = '';
  errorMsg = '';
  isError = false;
  notifications: any;
  queryParam: IQuery;
  pagerObject: IPager;
  currentPageNumber = 1;
  pageSize = 10;
  isLoading = false;
  displayColumns = ['Title', 'Message', 'Type', 'Severity', 'Date', 'Status'];
  keys = ['title', 'messages', 'type', 'severity', 'modifiedOn', 'state'];
  displayType = 'Project';
  sortingObject: any;
  states = UserNotificationStateValues;
  severities = UserNotificationSeverityValues;
  searchText = '';
  private destroy$ = new Subject<boolean>();
  private search$ = new Subject<any>();
  searchSub = this.search$.pipe(
    debounceTime(500),
    tap(_ => this.isLoading = true),
    switchMap((cmd: any) => {
      return this.doSearch(cmd.isRefresh);
    })
  ).subscribe(_ => this.isLoading = false);

  constructor(private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private userNotificationService: UserNotificationService) { }

  ngOnInit(): void {
    this.initQueryParams();
    this.search$.next({ isRefresh: false });
  }

  refresh() {
    $('#refreshTask').addClass('fa-rotation');
    this.initQueryParams();
    this.search(true);
  }

  initQueryParams() {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize,
      searchText: this.searchText
    });
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    this.search(true);
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
  }

  search(refresh = false) {
    const command = { isRefresh: refresh };
    this.search$.next(command);
  }

  onActionClicked(value: any): any {
    let task;
    if (value.action === "dismiss") {
      task = this.userNotificationService.updateNotificationState(value.data, UserNotificationState.Dismissed.toString());
    } else if (value.action === 'markAsRead') {
      task = this.userNotificationService.updateNotificationState(value.data, UserNotificationState.Read.toString());
    } else if (value.action === 'view') {
      task = this.userNotificationService.updateNotificationState(value.data, UserNotificationState.Read.toString());
      const selectedItem = this.notifications.find(item => item.id === value.data);
      console.log(selectedItem);
      if (selectedItem.type === UserNotificationType.Response) {
        this.router.navigate(['/response/view/responses/' + selectedItem.entityId]);
      } else if (selectedItem.type === UserNotificationType.Project) {
        this.router.navigate(['/projects/view/' + selectedItem.entityId]);
      } else if (selectedItem.type === UserNotificationType.Article) {
        this.router.navigate(['/projects/view/' + selectedItem.entityId]);
      }
    }

    task.pipe(switchMap(_ => {
      this.refresh();
      return EMPTY;
    }), takeUntil(this.destroy$))
      .subscribe();
  }

  changePage(page) {
    this.queryParam.pageNumber = page;
    this.search();
  }

  onSearchTextChanged() {
    if (!this.isError) {
      this.initQueryParams();
      this.search();
    }
  }

  private doSearch(isRefresh = false) {
    return this.userNotificationService.getUserNotifications(this.queryParam, isRefresh)
      .pipe(
        map(res => {
          this.notifications = res.body.items;
          this.notifications.forEach(element => {
            element.state = (element.state === 'New' || element.state === 'UnRead') ? 'Unread' : element.state;
          });

          if (res.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(res.headers.get('X-pagination')) as IPager);
          }
          $('#refreshTask').removeClass('fa-rotation');
        }),
        takeUntil(this.destroy$));
  }

  reset(): void {
    this.initQueryParams();
    this.searchText = '';
    this.search();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
