<div class="heading-container fdl-2">
  <h1>Resource Articles</h1>


  <button type="button" class="btn fdl-btn btn-primary-orange" (click)="createHelpArticle();" *ngIf="isEdit || isAdmin">
    <span class="material-icons">add</span>
    Create New Article
  </button>
  <!--<button type="button" class="btn fdl-btn btn-secondry-orange" (click)="createhelpArticle();">
    <span class="material-icons">add</span>
    Create New Article
  </button>-->
</div>
  <div class="form-container">
    <div class="alert alert-success alert-dismissible" *ngIf="successMsg">
      <button type="button" class="close" data-dismiss="alert" aria-label="close">×</button>
      <span class="iconLabel" aria-label="sucess"></span>
      <i class="material-icons" aria-hidden="true">check_circle</i>
      <p>
        {{successMsg}}

      </p>
    </div>
    <div class="alert alert-dismissible alert-danger" *ngIf="isError">
      <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false">×</button>
      <i class="material-icons">warning</i>
      <p [innerHtml]="errorMsg"></p>
    </div>
    <form class="form form-border mb-3 collapse-view" [formGroup]="filterForm" autocomplete="off">
      <h2 class="h4 collapse-arrow collapsed" data-toggle="collapse" href="#collapseFilter">Search</h2>
      <div class="filter-form-container-column2 collapse search-filter" id="collapseFilter">
        <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="isAdmin">
          <div class="has-error">
            <span *ngIf="!filterForm.get('organizationName').valid && filterForm.get('organizationName').errors?.pattern && filterForm.get('organizationName').dirty"><i class="material-icons">warning</i>Please enter a valid Organization Name</span>
          </div>
          <fdl-type-ahead label="Organization:" formControlName="organizationName" [collection]="organizationSuggestion" optionField="title"
                          placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onOrgSelect($event)">
          </fdl-type-ahead>
        </div>

        <div class="filter-form-control pr-4 typeahead-wrapper">
          <div class="has-error">
            <span *ngIf="!filterForm.get('catogeryName').valid && filterForm.get('catogeryName').errors?.pattern && filterForm.get('catogeryName').dirty"><i class="material-icons">warning</i>Please enter a valid Resource Category</span>
          </div>
          <fdl-type-ahead label="Resource Category:" formControlName="catogeryName" [collection]="helpCategorySuggestion" optionField="name"
                          placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0" (onSelect)="onCategorySelect($event)">
          </fdl-type-ahead>
        </div>

        <div class="filter-form-control pr-4 typeahead-wrapper">
          <div class="has-error">
            <span *ngIf="!filterForm.get('name').valid && filterForm.get('name').errors?.pattern && filterForm.get('name').dirty"><i class="material-icons">warning</i>Please enter a valid Resource Article Name</span>
          </div>
          <fdl-type-ahead label="Resource Article Name:" formControlName="name" [collection]="helpArticleSuggestion" optionField="title"
                          placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
        </div>

        <div class="filter-form-control pr-4">
          <label for="entityLevel">Entity Level:</label>
          <div class="fdl-select">
            <select name="entityLevel" class="form-control" aria-invalid="false" formControlName="entityLevel" (change)="onEntitySelect()">
              <option value="">Please Select</option>
              <option value="Product">Product</option>
              <option value="Form">Form</option>
            </select>
          </div>
        </div>

        <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="filterForm.get('entityLevel').value == 'Product'">
          <fdl-type-ahead label="Product Name:" formControlName="entityNameProduct" [collection]="productSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="100" [minLength]="0" (input)="typeaheadLoadingProducts()">
          </fdl-type-ahead>
        </div>
        <div class="filter-form-control pr-4 typeahead-wrapper" *ngIf="filterForm.get('entityLevel').value == 'Form'">
          <fdl-type-ahead label="Form Name:" formControlName="entityNameForm" [collection]="formSuggestion" optionField="title" placeholder="Type to search" [scrollable]="true" [optionsLimit]="500" [minLength]="0">
          </fdl-type-ahead>
        </div>

        <div class="filter-form-control pr-4">
          <label for="Status">Status</label>
          <div class="fdl-select">
            <select name="Status" class="form-control" aria-invalid="false" formControlName="publishstatus">
              <option value="true">Published</option>
              <option value="false">UnPublished</option>
            </select>
          </div>
        </div>

        <div class="buttom-wrapper">
          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Search" (click)="filterList()">Search</button>
        </div>
        <div class="ml-2 buttom-wrapper" *ngIf="filtered">
          <button type="button" class="btn fdl-btn btn-primary-dark ml-3" aria-label="Reset" (click)="clearFilterList()">Reset</button>
        </div>
      </div>
    </form>
    <div class="data-table-container">
      <div class="justify-content-between d-flex">
        <div class="headline-container">
          <h2 class="h4">Resource Article List</h2>
          <p>Total {{pagerObject.totalCount}} Resource Articles</p>
        </div>
        <div class="d-flex">
          <div class="refresh-icon-link mt-1 mr-2">
            <!-- add class of 'fa-rotation' on click of the refresh icon -->
            <a title="Refresh Resource Article List" id="refreshTask">
              <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
            </a>
          </div>
          <div class="dropdown downloadDropdownMenuButton" *ngIf="showComment">
            <button *ngIf="showComment"
                    type="button" title="Download Extract" class="btn btn fdl-btn btn-primary-dark btn-addcomment"
                    id="downloadDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <span class="material-icons material-icons-wrapper">
                get_app
              </span>
            </button>

            <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
              <a class="dropdown-item" (click)="downLoadType({'type':'xlsx'})">Export List</a>
            </div>
          </div>
          <app-prefernce-icon></app-prefernce-icon>
        </div>
        </div>

        <div class="table-scroll-x">
          <app-data-table *ngIf="helpArticleList" [rows]="helpArticleList.helpTopics" [keys]="keys" [displayColumns]="displayColumns" (sortingClicked)="applySorting($event)"
                          [showCheckbox]="false" [showViewEdit]="true" [showDelete]="true"
                          (deleteBtnClicked)="navigate($event)" type="Resource Article"
                          (buttonClicked)="navigate($event)"
                          [listname]="listname">
          </app-data-table>
        </div>
        <div>
          <app-pagination *ngIf="pagerObject && pagerObject.totalPages > 1 && helpArticleList && helpArticleList.helpTopics.length > 0" [pagerObject]="pagerObject"
                          (pagingChanged)="changePage($event)"></app-pagination>
        </div>
      </div>
  </div>

