import { Injectable } from '@angular/core';
import { IUser } from './user';
import { IReport } from './report';

@Injectable({
  providedIn: 'root'
})

export class ReportsDTOService {

  reportData: IReport
  
  private reportUpdated: boolean = false;
  private isEdit: boolean = false;
  private reportCreated: boolean = false;
  private newlyCreatedReport: string = '';

  constructor() { }

  setReportData(data: IReport){
      this.reportData = data;
  }

  getReportData(): IReport{
      return this.reportData;
  }

  set _isReportUpdated(value){
    this.reportUpdated= value;
  }

  get _isReportUpdated(){
    return this.reportUpdated;
  }

  set _isEditMode(value){
    this.isEdit = value
  }

  get _isEditMode(){
    return this.isEdit;
  }

  set _isReportCreated(value){
    this.reportCreated = value
  }

  set _newCreatedReport(value){
    this.newlyCreatedReport =  value;
  }

  get _isReportCreated(){
    return this.reportCreated;
  }

  get _newCreatedReport(){
    return this.newlyCreatedReport;
  }
}
