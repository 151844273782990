import { Pipe, PipeTransform } from '@angular/core';
import { ISuggestion } from 'src/app/shared/suggestion';

@Pipe({
  name: 'helpFilter'
})
export class HelpFilterPipe implements PipeTransform {

  transform(items: any[], field: any[], value: string): any[] {
    if (!items) {
      return [];
    }

    if (!items) {
      return [];
    }

    if (field.length<1) {
      return items;
    }
    if (!value) {
      return items;
    }
  
    let filteredList: any[] = [];
    let filterdItem: any = [];
    
    

    let filteredItems = items.filter((singleItem, index) => {
      for (let i = 0; i < field.length; i++) {
        let isMatched = singleItem[field[i]] && singleItem[field[i]].toLowerCase().includes(value.toLowerCase());
        if (isMatched && !filterdItem[singleItem['id']]) {

          filteredList.push(singleItem);
          filterdItem[singleItem['id']] = singleItem['id'];
        }
      }
    });
    return filteredList;
    }
  

   
  
}
