import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ISuggestions } from 'src/app/shared/suggestion';
import { WorkflowEntityLevel, MAX_LENGTHS, REGEX_PATTERNS, EditEntityLevel, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { NoWhitespaceValidator } from 'src/app/shared/no-whitespace.validator';
import { WorkflowService } from 'src/app/services/workflow.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UserService } from 'src/app/services/user.service';
import { EmailTemplateService } from '../../manageEmailTemplates/services/email-template.service';

@Component({
  selector: 'create-workflow',
  templateUrl: './create-workflow.component.html',
  styleUrls: ['./create-workflow.component.css']
})
export class CreateWorkflowComponent implements OnInit {
  orgSuggestion: ISuggestions;
  productSuggestion: ISuggestions;
  formSuggestion: ISuggestions;
  pageTemplateSuggestion: ISuggestions;
  workflowPostData: any;

  entityLevel: any;
  organizationDetails: any;
  selectedEntityLevelValue: any;
  invalidProductName: boolean;
  invalidFormName: boolean;
  invalidOrgName: boolean;
  invalidPageName: boolean;
  selectedOrgId: number;

  workflow: FormGroup;
  isSubmitted: boolean;
	isError: boolean;
	errorMsg: string;
  _maxLength: any;

  productId: any;
  formId: any;
  orgId: any;
  pageTemplateId: any;
  regexValidationMessage: any;

  displayColumns = ['Name', 'Description', 'FlowId', 'Environment', 'IsSystemWorkflow', 'Created On', 'Created By'];
  keys = ['name', 'description', 'flowId', 'environment', 'isSystemWorkflow', 'createdOn', 'createdByName'];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private workflowService: WorkflowService,
    private projectService: ProjectsService,
    private userService: UserService,
    private emailTemplateService: EmailTemplateService
  )  {this._maxLength = MAX_LENGTHS;};

  ngOnInit(): void {
    this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
    this.initProperties();
    this.getOrganizationSuggestions();
    this.getProductSuggestions();
    this.getFormSuggestions();
    this.getPageTemplateSuggestions();
    this.initFilterForm();
    this.setEntityValues();
  };

  initProperties() {
    this.entityLevel = Object.values(WorkflowEntityLevel).filter(value => typeof value !== 'number');
    this.organizationDetails = Object.assign({}, this.userService.getUserListData());
    this.selectedOrgId = this.organizationDetails.organization;
    this.selectedEntityLevelValue = "";
    this.isSubmitted = false;
    this.isError = false;
    this.errorMsg = "";
  }

  getOrganizationSuggestions() {
    this.projectService.getSuggestions("organizations", "")
      .subscribe(
        suggestions => {this.orgSuggestion = suggestions;
        },
        (error) => { this.isError = true; this.errorMsg = error; }
      );
  }

  getProductSuggestions() {
    this.projectService.getSuggestions('products', this.selectedOrgId, "orgId")
      .subscribe(
        suggestions => {this.productSuggestion = suggestions;
        },
        (error) => { this.isError = true; this.errorMsg = error; }
      );
  }

  getFormSuggestions() {
    this.projectService.getSuggestions('forms', this.selectedOrgId, "orgId")
      .subscribe(
        suggestions => {this.formSuggestion = suggestions;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }

  getPageTemplateSuggestions() {
    this.projectService.getSuggestions('pagetemplate', "")
      .subscribe(
        suggestions => {this.pageTemplateSuggestion = suggestions;
        },
        error => { this.isError = true; this.errorMsg = error; }
      );
  }

  initFilterForm() {
		this.workflow = this.fb.group({
		  Name: [
				"",
				[
					Validators.required,
          Validators.pattern(REGEX_PATTERNS.name_title_validator),
					Validators.maxLength(MAX_LENGTHS.Form.Name),
					NoWhitespaceValidator.validateWhiteSpaces
				]
			],
      Description: [
				"",
				[
					Validators.required,
          Validators.pattern(REGEX_PATTERNS.name_title_validator),
					Validators.maxLength(MAX_LENGTHS.Form.Description)
				]
			],
      Environment: ["",Validators.required],
      FlowUrl: ["",Validators.required],
      FlowId: ["",Validators.required],
      IsSystemWorkflow: [false],
      IsPageTemplateFlow: [false],
      entityLevel: ["", [Validators.required]],
      entityNameProduct: [""],
      entityNameForm: [""],
      entityNameOrg: [""],
      entityNamePage: [""]
		});
	}

 Submit() {
		this.isSubmitted = true;

    if (this.workflow.value.IsSystemWorkflow === false) {
      if (this.selectedEntityLevelValue === "" ) {return;}

      if (this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue === 'Product') {
        let productName = this.workflow.value.entityNameProduct;
        this.productId = this.projectService.fetchIdForRequest(this.productSuggestion, productName);
        if (this.productId < 1) { this.invalidProductName = true; return; }
      }

      if (this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue === 'Form') {
        let formName = this.workflow.value.entityNameForm;
        this.formId = this.emailTemplateService.fetchIdForRequestByTitle(this.formSuggestion, formName);
        if (this.formId < 1) { this.invalidFormName = true; return; }
      }

      if (this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue === 'PageTemplate') {
        let pageName = this.workflow.value.entityNamePage;
        this.pageTemplateId = this.projectService.fetchIdForRequest(this.pageTemplateSuggestion, pageName);
        if (this.pageTemplateId < 1) { this.invalidPageName = true; return; }
      }

      if (this.selectedEntityLevelValue.trim().length > 0 && this.selectedEntityLevelValue === 'Organization') {
        let organizationName = this.workflow.value.entityNameOrg;
        this.orgId = this.emailTemplateService.fetchIdForRequestByTitle(this.orgSuggestion, organizationName);
        if (this.orgId < 1) { this.invalidOrgName = true; return; }
      }
    }

    if (this.workflow.get('Name').value === "" || this.workflow.get('Description').value === "" || this.workflow.get('FlowId').value === "" ||
        this.workflow.get('FlowUrl').value === "" || this.workflow.get('Environment').value === ""
    ) { return; }

    this.createPostData();
    this.workflowService.createWorkflow(this.workflowPostData)
    .subscribe(
      () => {
        this.onCreateComplete();
        this.goBack();
      },
      error => {
        this.isError = true;
        this.errorMsg = error;
      }
    );
  }

  private createPostData(): void {
    this.workflowPostData = {
      "Name": this.workflow.get('Name').value,
      "Description": this.workflow.get('Description').value,
      "IsActive": true,
      "FlowId": this.workflow.get('FlowId').value,
      "FlowUrl": this.workflow.get('FlowUrl').value,
      "Environment": this.workflow.get('Environment').value,
      "IsSystemWorkflow": this.workflow.get('IsSystemWorkflow').value,
      "IsPageTemplateFlow": this.workflow.get('IsPageTemplateFlow').value,
      "EntityLevel": this.selectedEntityLevelValue,
      "OrganizationId": this.orgId,
      "ProductId": this.productId,
      "FormId": this.formId,
      "PageTemplateId": this.pageTemplateId
    };
  }

  private onCreateComplete(): void {
    this.isError = false;
    this.errorMsg = "";
    this.workflowService.isWorkflowCreated = `Workflow - '${this.workflowPostData.Name}' is created successfully.`;
    this.setEntityValues();
  }

  goBack() {
    this.workflow.reset();
    this.isSubmitted = false;
    this.router.navigate(['/self-service/workflow']);
  }

  onEntityProductSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidProductName = false;
    }
  }

  onEntityFormSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidFormName = false;
    }
  }

  onEntityOrgSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidOrgName = false;
    }
  }

  onEntityPageTemplateSelect(event: any){
    if(event.item.id > 0)
    {
      this.invalidPageName = false;
    }
  }

  onCheckBoxSelect() {
    this.workflow.get('entityLevel').setValue("");
    this.setEntityValues();
  }

  setEntityValues() {
    this.workflow.get('entityNameProduct').setValue("");
    this.workflow.get('entityNameForm').setValue("");
    this.workflow.get('entityNameOrg').setValue("");
    this.workflow.get('entityNamePage').setValue("");
    this.orgId = "";
    this.productId = "";
    this.formId  = "";
    this.pageTemplateId = "";
    this.invalidProductName = false;
    this.invalidFormName = false;
    this.invalidOrgName = false;
    this.invalidPageName = false;
  }

  onPageCheckBoxSelect() {
    if (this.workflow.get('IsPageTemplateFlow').value)
      this.entityLevel = Object.values(WorkflowEntityLevel).filter(value => typeof value !== 'number');
    else
      this.entityLevel = Object.values(EditEntityLevel).filter(value => typeof value !== 'number');
  }
}
