import { Component, OnInit, Inject, OnDestroy, ElementRef, ViewChild, HostListener } from '@angular/core';
import { MasterService } from './services/master.service';
import { UserService } from './services/user.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart, RoutesRecognized } from '@angular/router';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, InteractionStatus, PopupRequest, RedirectRequest, AuthenticationResult, AuthError } from '@azure/msal-browser';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { allowedPermissions } from './core/constants';
import { PermissionsService } from './services/permissions.service';
import { b2cPolicies } from './app.config';
import { Subject, Subscription, timer } from 'rxjs';
import { FormsService } from 'src/app/services/forms.service';
import { UserFilterService } from './services/user.filter.service';
import { NoticeBoardService } from './services/noticeboard.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  title = "spform";
  isAuthenticated = false;
  loggedInUser: string = "";
  loggedIn: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  titleTimeOut = 'angular-idle-timeout';
  showProjectLink: boolean = false;
  showAdminLink: boolean = false;
  showResponsesLink: boolean = false;
  isIframe: boolean = false;
  initiateDGFSubmitForm: boolean = false;
  submitRequestForm: boolean = false;
  isAnonymous: boolean = true;
  notice: any = {};
  noticeClosed: boolean = false;
  isBlankHeader: boolean = false;
  todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];
  done = ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog'];
  isCommitmentDashboardPage: boolean = false;


  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private masterService: MasterService, private userService: UserService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private titleService: Title, private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private idle: Idle, private keepalive: Keepalive,
    private permissionService: PermissionsService,
    private formService: FormsService,
    private userFilterService: UserFilterService,
    private noticeBoardService: NoticeBoardService) {

    this.setIdleTimeOut(idle, keepalive);
    this.isAnonymous = window.location.href.indexOf('survey') > -1;
  }
  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
  getQueryParam(name) {
    var hashes = location.search.replace('?', '').split('&');
    for (var i = 0; i < hashes.length; i++) {
      var hash = hashes[i].split('=');
      if (hash[0].toLowerCase() === name.toLowerCase()) {
        return hash[1];
      }
    }
    return null;
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  ngOnInit(): void {
    //Avoid re load for accuire token silent
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (result && result.payload && result.payload['account']) {
          //this.authService.instance.setActiveAccount(result.payload['account']);
          //this.loggedIn = true;
        }
      });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        const activeAcc = this.authService.instance.getActiveAccount();
        const accounts = this.authService.instance.getAllAccounts();
        if (!activeAcc && accounts.length > 0) {
          this.authService.instance.setActiveAccount(accounts[0]);
        }
        if (!this.isAnonymous) {
          accounts.length ? this.checkoutAccount() : this.authService.loginRedirect();
        }
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {

        if (result && result.payload) {
          localStorage.setItem('drmsToken', 'Bearer ' + result.payload['accessToken']);
          sessionStorage.removeItem('surveyToken');
        }

      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {

        if (result.error instanceof AuthError) {
          // Check for forgot pwd error
          // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
          if (result.error.message.includes('AADB2C90118')) {

            // login request with reset authority
            let resetPasswordFlowRequest = {
              scopes: ["openid"],
              authority: b2cPolicies.authorities.resetPassword.authority,
            };

            this.login(resetPasswordFlowRequest);
          }
          else {
            this.login();
          }
        }
      });

    if (this.isAnonymous) {
      this.loggedIn = false;
    }
    else if (this.isInDocumentPages()) {
      this.isBlankHeader = true;
    }
    else {
      this.checkoutAccount();
    }

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.titleService.setTitle(event["title"] ? event["title"] : "Fiserv");
        if (event["title"] === 'Client Dashboard') {
          this.isCommitmentDashboardPage = true;
        }
        else {
          this.isCommitmentDashboardPage = false;
        }
      });
  }

  //#region ** #283257 ticket number **
  // These empty functions force window and containerPage div to realize scroll function faster.
  // (The scroll function which is written in footer component)
  public onContainerPageScroll(): void {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
  }
  //#endregion

  private isInDocumentPages() {
    const documentDownloadUrls = [
      '/document-files/',
      '/documents/',
      '/folders/documents/',
      '/document-folders/'
    ];

    for (const docUrl of documentDownloadUrls) {
      if (window.location.href.indexOf(docUrl) > -1) {
        return true;
      }
    }

    return false;
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  checkoutAccount() {
    //this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    const activeAcc = this.authService.instance.getActiveAccount();
    this.loggedIn = activeAcc != null || this.authService.instance.getAllAccounts().length > 0;
    if (this.loggedIn) {
      this.setLoginTimestamp();
      this.getNotifications();
      this.idle.watch();
      this.timedOut = false;
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        if (event['url'] === '/') {
          this.router.navigate(["dashboard"]);
        }
        else if (event['url'].indexOf('/auth') > -1) {
          //this.router.navigate(["dashboard/project"]);
          this.router.navigate(["dashboard/mydashboard"]);
        }
      });
    }
  }

  setLoginTimestamp() {
    this.userService.callUserListData().subscribe(user => {
      let drmsToken = localStorage.getItem("drmsToken").substr(-20);
      this.userService.setLoginData(user, drmsToken).subscribe((data) => {

        console.log("userdetails entered in DB");
      },
        (error) => console.log("Error:" + error));
    });
  }

  getNotifications() {
    this.noticeBoardService.getNoticeboardData().subscribe(noticedata => {
      this.notice = noticedata;
    });
  }

  callService() {
    this.masterService
      .callMasterListData()
      .subscribe(data => this.masterService.setMasterListData(data));

    this.userService.callUserListData().subscribe(data => {
      this.userService.setUserListData(data);
      this.permissionService.setUserData(data);
      let usersPermission = [];
      data.permissions.forEach((ele) => {
        let permission: any = allowedPermissions[ele];
        for (let perm in permission) {
          if (permission[perm])
            usersPermission[perm] = permission[perm]
        }
      });
      //console.log(usersPermission);
      this.userService.setPermissions(usersPermission);
      this.loggedInUser = data.name;
      this.showProjectLink = this.userService.getPermissions()['showprojectlink'] || data.isAdmin;
      this.showAdminLink = data.isAdmin || this.doShowAdminLink();
      this.showResponsesLink = this.userService.getPermissions()['showresponseslink'] || data.isAdmin;
      this.initiateDGFSubmitForm = (this.userService.getPermissions()['initiateDGFSubmitForm']
        && (data.organizationTitle == 'Card Services' || data.organizationTitle == 'Output Solutions'))
        || data.isAdmin;

      let intakePermission: boolean = false;
      for (let p in data.roles) {
        let role = data.roles[p].roleTitle.toLowerCase();
        if (role.indexOf("intake") != -1) {
          intakePermission = true;
        }
      }
      this.submitRequestForm = intakePermission || data.isAdmin;
      let isNonrespondent = data.permissionsForUI.some(group => group.groupName === "Projects");
      this.userService.isClient = false || data.type !== "Fiserv";
      if (this.router.url === "/" || this.router.url.indexOf("dashboard") !== -1) {
        if (!data.isAdmin && !isNonrespondent) {
          this.userService.isRespondent = true;
          //this.router.navigate(["dashboard/form"]);
          this.router.navigate(["dashboard/mydashboard"]);
        }
        else {
          this.userService.isRespondent = false;
          //this.router.navigate(["dashboard/project"]);
          this.router.navigate(["dashboard/mydashboard"]);
        };
      }
    });
  }

  setIdleTimeOut(idle: Idle, keepalive: Keepalive) {
    // idle timeout of 900 seconds, for testing purposes.
    idle.setIdle(900);
    // timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    /*idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement,'keydown scroll mousewheel')
    ]);*/

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // dad46f23-cf7d-478a-a173-879a5923bce0
      //this.router.navigate([]);
      this.formService.triggerSave$.next('1');
      timer(0, 5000).subscribe(() => {
        localStorage.removeItem("drmsToken");
        this.userFilterService.clearFilter();
        this.authService.logout();
      });
      //this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  doShowAdminLink() {
    return (this.userService.getPermissions()['showuserlink'] || this.userService.getPermissions()['showformlink']
      || this.userService.getPermissions()['showclientlink']);
  }

  closeNotice() {
    this.noticeClosed = true;
  }
}
