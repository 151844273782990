import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { EMPTY, forkJoin, of, Subject } from 'rxjs';
import { catchError, map, take, takeUntil, switchMap, tap } from 'rxjs/operators';
import { REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from 'src/app/core/constants';
import { MasterService } from 'src/app/services/master.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { ResponsesService } from 'src/app/services/responses.service';
import { UserFilterService } from 'src/app/services/user.filter.service';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { IStatus } from 'src/app/shared/status';
import { ISuggestions } from 'src/app/shared/suggestion';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-update-inflight-forms',
  templateUrl: './update-inflight-forms.component.html',
  styles: []
})

export class UpdateInflightFormsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public inflightForms: any;
  @Input() public formId: any;
  @Output() public cancelClicked = new EventEmitter<boolean>();
  public inflightFormsKeys: any = ["id", "title", "responseStatusTitle", "assignedToName", "respondent", 'approver'];
  public inflightFormsHeaders: any = ['Response ID', 'Title', 'Status', 'Current Assigned to', 'Respondent', 'Approver'];
  public validRoles = ["Admin", "Fiserv Division Admin", "Fiserv Portfolio Manager", "Fiserv Implementation Manager"];
  public filterForm: FormGroup;
  public queryParam: IQuery;
  public currentPageNumber: number = 1;
  public showResetFilterButton: boolean;
  public isError: boolean;
  public errorMsg: string;
  public successMsg: string;
  public pagerObject: any;
  public formStatus: IStatus[];
  public isSearching = false;
  public isSuggestionLoading = false;
  public isUpdating = false;
  public isUpdateAllowed = false;
  public hasSelectedItems = false;
  public usersSuggestion: ISuggestions;
  public responsesSuggestion: ISuggestions;
  private $destroy = new Subject<boolean>();
  private defaultFilterModel = {
    id: null,
    title: null,
    assignedTo: null,
    status: 'All',
    respondent: null,
    approver: null
  };
  regexValidationMessage = REGEX_VALIDATION_MESSAGE;

  constructor(private projectService: ProjectsService,
    private masterService: MasterService,
    private responseService: ResponsesService,
    private permissionService: PermissionsService,
    private userFilterService: UserFilterService,
    private formBuilder: FormBuilder) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.resetFilterForm();
    this.showResetFilterButton = false;
    this.doSearch();
  }


  public ngOnInit(): void {
    this.isUpdateAllowed = this.permissionService.isUpdateInflightResponsesAllowed();
    this.getResponseStatus();
    if (!this.filterForm) {
      this.initFilterForm();
    }
    this.getSuggestions();
  }

  private initFilterForm() {
    if (this.filterForm) return;
    this.buildFilterForm();
    this.filterForm.valueChanges.subscribe(value => {
      this.showResetFilterButton = Object.values(value).some(item => item !== null);
    });
  }

  private buildFilterForm(): void {
    this.filterForm = new FormGroup({
      id: new FormControl('', [Validators.pattern(REGEX_PATTERNS.Numeric)]),
      title: new FormControl('', [Validators.pattern(REGEX_PATTERNS.form_response_survey_name_validator)]),
      assignedTo: new FormControl('', [Validators.pattern(REGEX_PATTERNS.user_name_validator)]),
      respondent: new FormControl('', [Validators.pattern(REGEX_PATTERNS.user_name_validator)]),
      approver: new FormControl('', [Validators.pattern(REGEX_PATTERNS.user_name_validator)]),
      status: new FormControl('All')
    });
  }

  private resetFilterForm(): void {
    if (!this.filterForm) return;
    this.buildFilterForm();
  }

  public doSearch(): void {
    if(this.filterForm && !this.filterForm.valid ) return;
    this.initQueryParams();
    this.getQueryParamFromFilterForm();
    this.getResponses();
  }

  private getQueryParamFromFilterForm(): void {
    if (!this.filterForm) return;

    let values = this.filterForm.value;

    if (values.id) {
      this.queryParam.id = values.id;
    }
    if (values.respondent) {
      this.queryParam.respondent = values.respondent
    }
    if (values.approver) {
      this.queryParam.approver = values.approver
    }
    if (values.title) {
      this.queryParam.title = values.title
    }
    if (values.status && values.status !== 'All') {
      this.queryParam.responseStatusTitle = values.status;
    }
    if (values.assignedTo) {
      this.queryParam.assignedTo = values.assignedTo;
    }
  }

  public resetFilter(): void {
    this.resetFilterState();
    this.userFilterService.clearFilter()
    this.getResponses();
  }

  private initQueryParams(): void {
    this.queryParam = Object.assign({}, {
      pageNumber: this.currentPageNumber,
      pageSize: environment.pageSize,
      formId: this.formId,
      isInflightOnly: true
    });
  }

  private getResponses(): any {
    this.isSearching = true;
    this.inflightForms = [];
    this.projectService.getResponseList(this.queryParam).pipe(
      take(1),
      map((res: any) => {
        this.transformResponses(res.body.forms)
        const pager = res.headers.get('X-pagination');
        if (pager) {
          this.pagerObject = Object.assign({}, JSON.parse(pager) as IPager);
        }
        this.masterService.resetFiltersCancelledFlag();
        this.isSearching = false;
        this.hasSelectedItems = this.inflightForms.length > 0;
      }),
      catchError((error) => {
        this.setErrorState(error);
        this.isSearching = false;
        return EMPTY;
      }),
      takeUntil(this.$destroy))
      .subscribe();
  }

  private transformResponses(res: any): void {
    if (res) {
      const temp = [];
      res.forEach(item => {
        item.selected = true;
        item.respondent = item.respondents && item.respondents.length > 0 ? item.respondents[0].assignedTo?.name : '';
        item.approver = item.approvers && item.approvers.length > 0 ? item.approvers[0].user?.name : '';
        temp.push(item);
      });
      this.inflightForms = temp;
    }
  }

  private getResponseStatus(): void {
    this.formStatus = this.masterService.getResponseStatusList();
    if (this.formStatus.length === 0) {
      this.masterService.callMasterListData().pipe(
        map((data) => {
          this.masterService.setMasterListData(data);
          this.formStatus = data.responseStatuses;
        }),
        takeUntil(this.$destroy))
        .subscribe();
    }
  }

  public changePage(page) {
    this.queryParam.pageNumber = page;
    this.getResponses();
  }

  public onUpdate(): void {
    this.isUpdating = true;
    const body = {
      formId: this.formId,
      responseIds: this.inflightForms.filter(item => item.selected).map(item => item.id)
    };

    this.responseService.updateInflightResponses(this.formId, body).pipe(
      switchMap((_) => {
        this.getResponses();
        $('#confirmInflightForms').modal('hide');
        this.setSuccessState('Form responses have been updated successfully');
        this.isUpdating = false;
        return of();
      }),
      catchError((err) => {
        this.setErrorState(err);
        this.isUpdating = false;
        return EMPTY;
      }),
      takeUntil(this.$destroy))
      .subscribe();
  }

  public onSelectedChanged(selectedItemCount: any): void {
    this.hasSelectedItems = selectedItemCount > 0;
  }

  private getSuggestions(): void {
    if (this.isUpdateAllowed) {
      this.isSuggestionLoading = true;
      forkJoin({
        users: this.projectService.getSuggestions("users", ''),
        res: this.projectService.getSuggestions("responses", '')})
        .pipe(tap(_ => this.isSuggestionLoading = true)
        ,catchError((err) => {
          this.setErrorState(err);
          this.isSuggestionLoading = false;
          return EMPTY;
        }),
        takeUntil(this.$destroy)
      ).subscribe(({users, res}) => {
        this.usersSuggestion = users;
        this.responsesSuggestion = res;
        this.isSuggestionLoading = false;
      });
    }
  }

  public disableUpdateBtn(): boolean {
    return (!this.isUpdateAllowed || !this.hasSelectedItems || this.isSearching);
  }

  public onCloseListModal(): void {
    $('#updateInflightForms').modal('hide');
  }

  public onConfirm(): void {
    $('#confirmInflightForms').modal('show');
  }

  public onCloseConfirmModal(): void {
    $('#confirmInflightForms').modal('hide');
  }

  private resetFilterState(): void {
    this.initQueryParams();
    this.resetFilterForm();
  }

  private setErrorState(msg: string): void {
    this.isError = true;
    this.errorMsg = msg;
  }

  private setSuccessState(msg: string): void {
    this.isError = false;
    this.successMsg = msg;
  }

  public ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }
}
