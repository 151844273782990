<div class="heading-container fdl-2">
    <div class="back-action-container">
    <div class="back-button">
      <a href="javascript:void(0);" (click)="goBack()">
        <span class="material-icons"> arrow_back </span> 
        Workflow Executions
      </a>
    </div>
    <h1>{{workflowData?.name || 'N/A'}}</h1>
    </div>
</div>

<div class="form-container separate-form-container">
    <form>
        <div class="biller-survey white-box fdl-2">
            <div>
                <ul>
                    <li>
                        Start Time:
                        <span class="info">{{workflowData?.startTime | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                    </li>
                    <li>
                        End Time:
                        <span class="info">{{workflowData?.endTime | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                    </li>
                    <li>
                        Created On:
                        <span class="info">{{workflowData?.createdOn | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</span>
                    </li>
                    <li>
                        Created By:
                        <span class="info">{{workflowData?.createdByName || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        Reference Id:
                        <span class="info">{{workflowData?.refrenceId || 'N/A'}}</span>
                    </li>
                    <li>
                        Reference Type:
                        <span class="info">{{workflowData?.refrenceType || 'N/A'}}</span>
                    </li>
                    <li>
                        FlowRunId:
                        <span class="info">{{workflowData?.flowRunId || 'N/A'}}</span>
                    </li>
                    <li>
                        Correlation Id:
                        <span class="info">{{workflowData?.correlationId || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        FlowRunUrl:
                        <span class="info"><a href="{{workflowData?.flowRunUrl || '#'}}" target="_blank">{{workflowData?.flowRunUrl || 'N/A'}}</a></span>
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        Request:
                        <span class="info">{{workflowData?.request || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li>
                        ExitMessage:
                        <span class="info">{{workflowData?.exitMessage || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </form>
    <form>
    <div id="accordion" class="accordion collapse-view">
            <div class="page-heading-container fdl-2 mt-4" data-toggle="collapse" href="#collapseOne">
              <h2 class="h5 font-weight-bold">Workflow Execution Integrations Data</h2>
            </div>  
        <div class="biller-survey white-box fdl-2" id ="collapseOne">
            <div>
                <ul>
                    <li>
                        Process:
                        <span class="info">{{workflowIntegrationData?.process || 'N/A'}}</span>
                    </li>
                    <li>
                        Request UIID:
                        <span class="info">{{workflowIntegrationData?.requestUIID || 'N/A'}}</span>
                    </li>
                    <li>
                        Status:
                        <span class="info">{{workflowIntegrationData?.status || 'N/A'}}</span>
                    </li>
                    <li>
                        Response Id:
                        <span class="info">{{workflowIntegrationData?.responseId || 'N/A'}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="biller-survey white-box border-0 fdl-2" id ="collapseOne">
            <div class="">
                <ul class="">
                    <li class=" full-width">
                    Target System Request:
                    <span class="info">
                        {{workflowIntegrationData?.targetSystemRequest || 'N/A'}}
                    </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="biller-survey white-box border-0 fdl-2" id ="collapseOne">
            <div class="">
                <ul class="">
                    <li class=" full-width">
                    Target System Response:
                    <span class="info">
                        {{workflowIntegrationData?.targetSystemResponse || 'N/A'}}
                    </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="biller-survey white-box border-0 fdl-2" id ="collapseOne">
            <div class="">
                <ul class="">
                    <li class=" full-width">
                    ErrorMessage:
                    <span class="info">
                        {{workflowIntegrationData?.errorMessage || 'N/A'}}
                    </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    </form>
    <div class="fdl-2 button-container">
        <div>
            <button type="button" class="btn fdl-btn btn-secondary-light" (click)="goBack()">Cancel</button>
        </div>
    </div>
</div>