import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class FdlFilterUIHepler {
    showSearchIconWhenResetFilter() {
        document.querySelectorAll('#collapseFilter .input-group-btn').forEach(el => {  
          if (el.children.length > 0) {
            const firstElement = el.children.item(0);
            if (firstElement.children.length > 0) {
              firstElement.children.item(0).innerHTML='search';
            }
          }
        });
      }
}