<div class="help-landing-container self-service-container heading-container">
    <h1>{{content['selfServiceHeader']}}</h1>
</div>

<!--  !!!Important: Since we are leveraging HELP module keep the help classes as per the this HTML for the reusability  -->
<div class="help-landing-page self-service-landing-page main-container-sub responsive-container separate-form-container pt-4">
    <section id="self-service-home-options" class="right-section assign-container col-12 pl-2 pr-2" style="max-width: 100%;">
        <form  class="form-container ng-untouched ng-pristine ng-valid">
            <!-- Error Msg Alert -->
            <div class="alert alert-dismissible alert-danger" *ngIf="errorMsg">
                <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="errorMsg=''">×</button> 
                <i class="material-icons">warning</i>
                <p [innerHtml]="errorMsg"></p>
            </div>
             <!-- Information Msg Alert -->
             <div class="alert alert-dismissible alert-info" *ngIf="infoMsg">
                <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="infoMsg=''">×</button> 
                <i class="material-icons">info</i>
                <p [innerHtml]="infoMsg"></p>
            </div>
            <div class="landing-card-list-container">
             <!-- START Self Service Listing Starts   -->
                <ul class="landing-card-list">
                     <li class="list" *ngFor="let feature of selfServiceFeatureList" [ngClass]="feature.featureCount > 0 ? '' : 'd-none'">
                        <div class="nav-link">
                            <h2>{{feature.name}}</h2>
                            <p>{{feature.description}}</p>
                            <ul class="topic">
                                <li *ngFor="let subfeature of feature.features" [ngClass]="subfeature.viewAccess && !(subfeature.isFooterLink) ? '' : 'd-none'">
                                    <a *ngIf="!(subfeature.isPageTemplate) && !(subfeature.isFooterLink) && subfeature.viewAccess" href="javascript:void(0);" routerLinkActive="active" routerLink="{{subfeature.selfServiceSubFeatureURL}}">{{subfeature.name}}</a>
                                    <a *ngIf="subfeature.isPageTemplate && !(subfeature.isFooterLink) && subfeature.viewAccess" href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/pageTemplate/{{subfeature.pageTemplateId}}/responses">{{subfeature.pageTemplateTitle}}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="d-flex" *ngFor="let subfeature of feature.features">
                            <a *ngIf="subfeature.isFooterLink && subfeature.viewAccess" href="javascript:void(0);" routerLinkActive="active" routerLink="{{subfeature.selfServiceSubFeatureURL}}" class="view-all-link"> {{subfeature.name}} 
                                <span class="material-icons">navigate_next </span> </a>
                        </div>
                    </li>

                    <!-- <li class="list" *ngIf="showRequestExtractsLink || showformExtractionTemplateLink">
                        <div class="nav-link">
                            <h2>Forms & Responses</h2>
                            <ul class="topic">
                                <li *ngIf="showRequestExtractsLink"><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/requestExtracts/extractionRequest">Request Form Response Extract</a></li>
                                <li *ngIf="showformExtractionTemplateLink"><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/formExtracts/formExtractionTemplate">Upload Form Extraction Template</a></li>
                            </ul>
                        </div>
                        <div class="d-flex">
                            <a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/requestExtracts" class="view-all-link"> View All Response Extracts<span class="material-icons">navigate_next </span> </a>
                        </div>
                    </li>

                    <li class="list" *ngIf="showFormLink">
                        <div class="nav-link">
                            <h2>Notifications</h2>
                            <ul class="topic">
                                <li><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/emailTemplate/create">Create Email Template</a></li>
                                <li><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/emailTemplate/list">Manage Email Template</a></li>
                            </ul>
                        </div>
                        <div class="d-flex">
                            <a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/emailTemplate/list" class="view-all-link"> View All Templates<span class="material-icons">navigate_next </span> </a>
                        </div>
                    </li>

                    <li class="list" *ngIf="showManageAssetLink">
                        <div class="nav-link">
                            <h2>File Uploads</h2>
                            <p>Upload and Manage file Uploads</p>
                            <ul class="topic mt-3">
                                <li><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/fileUploads">Upload a file</a></li>
                            </ul>
                        </div>
                        <div class="d-flex">
                            <a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/fileUploads" class="view-all-link"> View All Uploads<span class="material-icons">navigate_next </span> </a>
                        </div>
                    </li>

                    <li class="list" *ngIf="showReportLink">
                        <div class="nav-link">
                            <h2>Power BI Report</h2>
                            <p>Configure custom BU specific Power BI report</p>
                            <ul class="topic mt-3">
                                <li><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/report/create">Configure Report</a></li>
                            </ul>
                        </div>
                        <div class="d-flex">
                            <a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/report" class="view-all-link">View All Reports<span class="material-icons">navigate_next </span> </a>
                        </div>
                    </li>

                    <li class="list" *ngIf="isAdmin">
                        <div class="nav-link">
                            <h2>Data Migration</h2>
                            <ul class="topic">
                                <li><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/bulkUpload/client">Bulk Client Creation</a></li>
                                <li><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/bulkUpload/project">Bulk Project Creation</a></li>
                                <li><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/bulkUpload/form">Bulk Form Publish</a></li>
                            </ul>
                        </div>
                    </li>

                    <li class="list" *ngIf="showHelpCategoryLink || showHelpArticleLink || showResourceCenterLink">
                        <div class="nav-link">
                            <h2>Resource Center</h2>
                            <ul class="topic">
                                <li *ngIf="showHelpCategoryLink || showHelpArticleLink || showResourceCenterLink"><a href="javascript:void(0);" routerLinkActive="active" routerLink="/help/category" >Resource Center</a></li> 
                                <li *ngIf="showHelpCategoryLink"><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/helpCategory/create" >Create Resource Category</a></li>
                                <li *ngIf="showHelpCategoryLink"><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/helpCategory">Manage Resource Category</a></li> 
                                <li *ngIf="showHelpCategoryLink || showHelpArticleLink"><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/helpTopic/create">Create Resource Articles</a></li> 
                                <li *ngIf="showHelpCategoryLink || showHelpArticleLink"><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/helpTopic">Manage Resource Articles</a></li> 
                            </ul>
                        </div>
                        <div class="d-flex" *ngIf="showHelpCategoryLink">
                            <a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/helpCategory" class="view-all-link"> View All Resource Categories<span class="material-icons">navigate_next </span> </a>
                        </div>
                    </li>

                    <li class="list" *ngIf="pageTemplateList.length > 0">
                        <div class="nav-link">
                            <h2>Other Requests</h2>
                            <ul class="topic">
                               <li *ngFor="let page of pageTemplateList"><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/pageTemplate/{{page.id}}/responses">{{page.title}}</a></li>
                            </ul>
                        </div>
                        <div class="d-flex">
                            <a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/pageTemplates" class="view-all-link"> View All Request Templates <span class="material-icons">navigate_next </span> </a>
                        </div>
                    </li>

                    <li class="list" *ngFor="let page of uniquePageTemplateList">
                        <div class="nav-link">
                            <h2>{{page.title}}</h2>
                            <p>{{page.description}}</p>
                            <ul class="topic">
                               <li><a href="javascript:void(0);" routerLinkActive="active" routerLink="/self-service/pageTemplate/{{page.id}}/responses">{{page.title}}</a></li>
                            </ul>
                        </div>
                    </li> -->
                </ul>
                   <!-- ENDS Self Service Listing Starts   -->
            </div>
        </form>
    </section>
    <!-- STARTS Help Topic Content Area -->

    <section class="landing-page-articles-section">
        <div class="landing-page-article-header">
            <div class="help-wrapper">
                <h2>                
                {{content['selfServiceHelpTitle']}} &amp; {{content['selfServiceSupportTitle']}} <span class="material-icons" >help
                </span></h2>  
                <p >{{content['selfServiceHelpMessage']}} <a href="mailto:drms-implementation360@fiserv.com">drms-implementation360@fiserv.com</a>
                </p>
                </div>    
          <div id="self-service-help-topics" class="article-container">
            <article *ngFor="let row of popularTopic; let i = index;">
              <h3>{{row.helpCategoryName}}</h3>
              <ul class="article-list">
                <li *ngFor="let topics of row.helpTopics; let k = index;">
                  <a href="javascript:void(0);" routerLink="/help/topic/{{topics.id}}" routerLinkActive="active" class="description-image">{{topics.title}}</a>
                </li>
              </ul>
            </article>
          </div>
        </div>
      </section>
     <!-- END Help Topic Content Area -->
</div>