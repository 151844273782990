



<div class="custom-search-input">



   <label *ngIf="label" class="control-label" for="type-ahead-input-2">{{label}}</label>
 <div class="input-group">

  <input  #search type="text" [(ngModel)]="value" value="{{val}}"
         [typeahead]="titleArray" [typeaheadMinLength]="0" [typeaheadOptionsLimit]="500" placeholder="{{placeholder}}"
         (typeaheadOnSelect) = "onSelect($event);" (typeaheadOnBlur)= "onSelect($event);" class="form-control search-query form-control fdl-ng-typeahead">
 
  <span class="input-group-btn">
  
    <button *ngIf="!val || val==''" type="button" class="btn"><i class="material-icons">search</i>  {{val}}</button>
    <!--Use this close icon as soon as searched the data to clear the search -->
    <button *ngIf="val && val!=''" type="button" class="btn" (click)="clearVal()"><i class="material-icons">close</i></button>
  </span>
</div>
</div>
