<main class="dashboard-container">
  <!-- below class (analytics-container-with-sidebar) will be use if sidebar persist -->
  <div>
    <section class="top-container">
      <article class="left-container">
        <!-- <h1>Welcome {{userDetails.name}},</h1> -->
        <p>Last login on <span>{{LastLoginTimeStamp}}</span></p>
      </article>
      <article class="right-container">
        <div class="icon-container">
          <!-- <a href="#" class="action-link"><span class="material-icons">settings</span></a> -->
        </div>
      </article>
    </section>
    <section class="dashboard-content-area">
      <article class="dashboard-tab-container">
        <ul role="tablist" class="nav nav-tabs scoped-tabs">
          <li class="nav-item">
            <a id="tab-1" role="tab" aria-selected="true" routerLink="/dashboard/mydashboard" routerLinkActive="active" data-toggle="tab"
               class="nav-link "> My Dashboard </a>
          </li>
          <li class="nav-item">
            <a id="tab-2" role="tab" routerLink="/dashboard/analyticsSummary" routerLinkActive="active" aria-selected="false" data-toggle="tab"
               class="nav-link active show"> Analytics </a>
          </li>
          <li class="nav-item">
            <a id="tab-3" role="tab" routerLink="/dashboard/report" routerLinkActive="active" aria-selected="false" data-toggle="tab"
               class="nav-link" active show> Reports </a>
          </li>
        </ul>
        <div id="myTabContent" class="tab-content">

          <div id="tab14" role="tabpanel" aria-labelledby="tab-2" class="tab-pane fade active show">
            <div class="tabl-heading">
              <ul class="analytics-tab-container">
                <li class="list" style="display: flex;"><a href="#" routerLink="/dashboard/analyticsSummary" routerLinkActive="active"> Summary </a></li>
                <li class="list"><a href="#" routerLink="/dashboard/analyticsProjects" routerLinkActive="active">Projects</a></li>
                <li class="list"><a href="#" routerLink="/dashboard/analyticsForms" routerLinkActive="active">Responses</a></li>
              </ul>
            </div>
            <div class="summary-container">
              <!-- <h3>Summary</h3> -->
              <ul class="summary-tiles">
                <li class="list orange">
                  <p class="label">Products</p>
                  <div class="value"><p>{{productsCount}}</p></div>
                </li>
                <li class="list orange">
                  <p class="label">Users</p>
                  <div class="value">
                    <p>
                      {{usersCount}}
                    </p>
                  </div>
                </li>
                <li class="list orange">
                  <p class="label">Projects</p>
                  <div class="value"><p> {{projectsCount}}</p></div>
                </li>
                <li class="list orange">
                  <p class="label">Responses</p>
                  <div class="value"><p> {{responsesCount}}</p></div>
                </li>
              </ul>
            </div>
            <section class="tab-content-container">
              <article class="column-container column-3">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Clients</h3>
                      <!-- <div class="action-icon">
                           <a href="#"><span class="material-icons">settings</span></a>
                      </div> -->
                    </div>
                    <div class="title-header-container">
                      <div class="value"> {{clientsCount}}</div>
                    </div>
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady">


                  <ngx-charts-line-chart [view]="[containerRef.offsetWidth, 400]"
                                         [scheme]="colorScheme"
                                         [results]="multiClient"
                                         [gradient]="gradient"
                                         [xAxis]="showXAxis"
                                         [yAxis]="showYAxis"
                                         [legend]="showLegend"
                                         [legendPosition]="legendPosition"
                                         [legendTitle]="''"
                                         [showXAxisLabel]="showXAxisLabel"
                                         [showYAxisLabel]="showYAxisLabel"
                                         [xAxisLabel]="xAxisLabel"
                                         [yAxisLabel]="yAxisLabel">
                  </ngx-charts-line-chart>



                </div>
              </article>
              <article class="column-container column-3">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Products</h3>
                      <!-- <div class="action-icon">
                           <a href="#"><span class="material-icons">settings</span></a>
                      </div> -->
                    </div>
                    <div class="title-header-container">
                      <div class="value">{{productsCount}}</div>
                    </div>
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady">

                  <ngx-charts-line-chart [view]="[containerRef.offsetWidth, 400]"
                                         [scheme]="colorScheme"
                                         [results]="multiProduct"
                                         [gradient]="gradient"
                                         [xAxis]="showXAxis"
                                         [yAxis]="showYAxis"
                                         [legend]="showLegend"
                                         [legendPosition]="legendPosition"
                                         [legendTitle]="''"
                                         [showXAxisLabel]="showXAxisLabel"
                                         [showYAxisLabel]="showYAxisLabel"
                                         [xAxisLabel]="xAxisLabel"
                                         [yAxisLabel]="yAxisLabel">
                  </ngx-charts-line-chart>


                </div>
              </article>
              <article class="column-container column-3" style="margin-right: 0;">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Form Templates</h3>
                      <!-- <div class="action-icon">
                           <a href="#"><span class="material-icons">settings</span></a>
                      </div> -->
                    </div>
                    <div class="title-header-container">
                      <div class="value">{{formsCount}}</div>
                    </div>
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady">
                  <ngx-charts-line-chart [view]="[containerRef.offsetWidth, 400]"
                                         [scheme]="colorScheme"
                                         [results]="multiForm"
                                         [gradient]="gradient"
                                         [xAxis]="showXAxis"
                                         [yAxis]="showYAxis"
                                         [legend]="showLegend"
                                         [legendPosition]="legendPosition"
                                         [legendTitle]="''"
                                         [showXAxisLabel]="showXAxisLabel"
                                         [showYAxisLabel]="showYAxisLabel"
                                         [xAxisLabel]="xAxisLabel"
                                         [yAxisLabel]="yAxisLabel">
                  </ngx-charts-line-chart>

                </div>
              </article>
              <article class="column-container column-3">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Responses by User Type</h3>
                    </div>
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady">
                  <ngx-charts-pie-chart [view]="[containerRef.offsetWidth, 400]"
                                        [results]="user"
                                        [doughnut]="true"
                                        [legend]="showLegend"
                                        [legendPosition]="legendPosition"
                                        [legendTitle]="''"
                                        [trimLabels]="false"
                                        [labels]="showLabels"
                                        [animations]="false"
                                        [customColors]="userCustomColors()"
                                        [labelFormatting]="pieChartUserLabel.bind(this, user)">
                  </ngx-charts-pie-chart>
                </div>
              </article>
              <article class="column-container column-3">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Responses by Respondent Type</h3>
                    </div>
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady">
                  <ngx-charts-pie-chart [view]="[containerRef.offsetWidth, 400]"
                                        [results]="respondentType"
                                        [doughnut]="true"
                                        [legend]="showLegend"
                                        [legendPosition]="legendPosition"
                                        [legendTitle]="''"
                                        [trimLabels]="false"
                                        [labels]="showLabels"
                                        [animations]="false"
                                        [customColors]="respondantCustomColors()"
                                        [labelFormatting]="pieChartRespondentTypeLabel.bind(this, respondentType)">
                  </ngx-charts-pie-chart>
                </div>
              </article>
              <article class="column-container column-3" style="margin-right: 0;">
                <div class="tile-container">
                  <div class="tile-header">
                    <div class="title-header-container">
                      <h3>Responses by Form Type</h3>
                    </div>
                  </div>
                </div>
                <div class="analytics-display" #containerRef *ngIf="isReady">
                  <ngx-charts-pie-chart [view]="[containerRef.offsetWidth, 400]"
                                        [results]="formType"
                                        [doughnut]="true"
                                        [legend]="showLegend"
                                        [legendPosition]="legendPosition"
                                        [legendTitle]="''"
                                        [trimLabels]="false"
                                        [labels]="showLabels"
                                        [animations]="false"
                                        [customColors]="formCustomColors()"
                                        [labelFormatting]="pieChartFormTypeLabel.bind(this, formType)">
                  </ngx-charts-pie-chart>
                </div>
              </article>

            </section>
          </div>
        </div>
      </article>
    </section>
  </div>
  <section class="filter-panel-container">
    <nav class="sidebar d-none active">
      <div class="sidebar-header">
        <button id="closeFilter" class="btn btn-secondary-transparent filter-icon-horizontal">
          <span class="filter-nav-text"> Filters</span><span class="right-arrow-back"></span>
        </button>
      </div>
      <form  class="form form-border pd-25 ng-untouched ng-pristine ng-valid">
        <div>
          <div class="filter-form-control row">
            <div class="form-group col-lg-12">
              <div class="custom-search-input">
                <div class="input-group">
                  <input type="text" id="search" placeholder="Search"
                         class="search-query form-control" />
                  <span class="input-group-btn">
                    <button type="button" class="btn">
                      <i class="material-icons icon-after">search</i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="project">Project</label>
              <div class="fdl-select border-0">
                <select id="project" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="program">Program</label>
              <div class="fdl-select border-0">
                <select id="program" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="product">Product</label>
              <div class="fdl-select border-0">
                <select id="product" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="version">Version</label>
              <div class="fdl-select border-0">
                <select id="version" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="implementationState">Implementation State</label>
              <div class="fdl-select border-0">
                <select id="implementationState" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="projectStatus">Project Status</label>
              <div class="fdl-select border-0">
                <select id="projectStatus" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row">
            <div class="col-lg-12 mb-3">
              <label for="implemntationManager">Implementation Manager</label>
              <div class="fdl-select border-0">
                <select id="implemntationManager" aria-invalid="false" class="form-control">
                  <option>All</option>
                  <option></option>
                </select>
              </div>
            </div>
          </div>
          <div class="filter-form-control row fdl-2">
            <div class="col-lg-6 mb-3 float-left">
              <button type="button" tabindex="0" aria-label="Filter"
                      class="btn btn-primary-orange">
                Filter
              </button>
            </div>
            <a href="" class="btn btn-secondary-transparent text-right pt-0 pb-3"
               style="display: flex; align-items: center;">
              Reset<span class="material-icons icon-after float-right ml-41"> chevron_right </span>
            </a>
          </div>
        </div>
      </form>
    </nav>
    <nav class="sidebar-wrapped d-none">
      <button type="button" id="sidebarCollapse"
              class="btn btn-secondary-transparent left-arrow-back sidebar-collapse-btn"></button>
      <button class="btn btn-secondary-transparent filter-icon-vertical">
        <span class="filter-right-nav-text">
          Filters
        </span>
      </button>
    </nav>
  </section>
</main>
